<mat-toolbar>
	<h3>{{ title }}</h3>
	<span class="example-spacer"></span>
	<button id="closeBtn" (click)="closeDialog()">
		<app-icon [name]="'close'" [color]="'white'" [size]="'50'"></app-icon>
	</button>
</mat-toolbar>
<div class="history-container">
	<div class="search-section" fxLayoutAlign="space-between center">
		<div [formGroup]="searchForm" class="searchbar-wrapper">
			<mat-form-field appearance="outline" class="search-bar">
				<app-icon
					matPrefix
					[name]="'search-normal-1'"
					[color]="'#231F20'"
					class="search-icon-history"
				></app-icon>
				<input
					*ngIf="historyData.type == 'PAY_ELECTIVE'"
					matInput
					placeholder="Search by Action..."
					formControlName="search"
				/>
				<input *ngIf="historyData.type == 'TPP'" matInput placeholder="Search..." formControlName="search" />
			</mat-form-field>
		</div>
	</div>
	<div fxLayout="row" fxLayoutAlign="start center" class="history-header-container">
		<h4 id="createdAt" fxFlex="25">Created At</h4>
		<h4 id="status" fxFlex="25">Status</h4>
		<h4 *ngIf="historyData.type == 'PAY_ELECTIVE'" id="status" fxFlex="25">Action</h4>
		<h4 id="reason" fxFlex="25">Reason</h4>
	</div>
	<div class="scroll-container">
		<div fxLayout="row" fxLayoutAlign="center" class="history-item-container" *ngIf="!filteredData.length">
			<p>No history data to display</p>
		</div>
		<div
			fxLayout="row"
			fxLayoutAlign="start center"
			class="history-item-container"
			*ngFor="let data of filteredData"
		>
			<span id="createdAt" fxFlex="25">{{ data.createdAt | date : "medium" }}</span>
			<div id="status" class="status data-item" fxFlex="25" fxLayoutAlign="start center">
				<span class="dot" [ngClass]="this.toStatusColorClass(data.status)"> </span>
				<span>{{ data.status }}</span>
			</div>

			<span *ngIf="historyData.type == 'PAY_ELECTIVE'" id="action" fxFlex="25" style="word-break: break-word">
				{{ data.action }}
			</span>
			<span
				id="reason"
				class="reason-column data-item"
				[ngClass]="{ 'pay-elective': historyData.type == 'PAY_ELECTIVE' }"
				fxFlex="45"
				style="word-break: break-word"
			>
				<p
					class="reason data"
					*ngIf="!data.details || (data.details && isEmptyObject(data.details.attributes))"
				>
					{{ data.reason }}
				</p>

				<p
					class="reason data details"
					*ngIf="data.details && data.details?.attributes && !isEmptyObject(data.details.attributes)"
				>
					<span *ngIf="data.details.message">{{ data.details.message }}</span>

					<span *ngFor="let attribute of data.details?.attributes | keyvalue">
						<span class="details-items" *ngIf="!isArray(attribute.value!.toString())">
							<strong>{{ attribute.key!.toString() | formatString }}</strong>
							: {{ attribute.value }}
						</span>

						<span *ngIf="isArray(attribute.value!.toString())">
							<span *ngFor="let tag of getArrayValue(attribute.value!.toString())" class="details-items">
								<strong> {{ attribute.key!.toString() | formatString }}: </strong>
								{{ tag | formatString }}
							</span>
						</span>
					</span>
				</p>
			</span>
		</div>
	</div>
</div>
