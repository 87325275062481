import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
	selector: "app-hide-events-dialog",
	templateUrl: "./hide-events-dialog.component.html",
	styleUrls: ["./hide-events-dialog.component.scss"]
})
export class HideEventsDialogComponent {
	selectedEvents = 0;

	constructor(
		private dialogRef: MatDialogRef<HideEventsDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	ngOnInit() {
		if (this.data) this.selectedEvents = this.data;
	}

	close(confirm: boolean) {
		this.dialogRef.close(confirm);
	}
}
