<div class="milestone-card-container flex flex-align-center-center">
	<div *ngFor="let card of datasource" class="milestone-card">
		<div class="info-box flex">
			<div class="card-thumbnail flex flex-align-center-center" [ngClass]="getIconClass(card.milestoneLabel)">
				<app-icon
					[name]="getIconName(card.milestoneLabel)"
					[color]="'var(--color-text-default)'"
					[size]="'29'"
					[lineWidth]="'1.5px'"
				></app-icon>
			</div>
			<div class="card-text">
				<div class="card-label">{{ getCardLabel(card.milestoneLabel) | titlecase }}</div>
				<div class="card-value">{{ getCardText(card) }}</div>
			</div>
		</div>
	</div>
</div>
