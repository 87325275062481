<modal-wrapper
	title="{{ this.bankName === '' ? 'Add' : 'Edit' }} Source of Funds"
	(onTitleButtonAction)="showBread()"
	[modalStyle]="'dark'"
	[noBreadCrumbs]="true"
	[exitIconBool]="true"
	[subHeaderColour]="'transparent'"
>
	<div id="sourceOfFundsPageContainer" class="form-content">
		<!-- SOURCE OF FUNDS FIELDS -->
		<div id="sourceOfFundsFieldsContainer" class="form-section">
			<ng-container [formGroup]="globalForm">
				<div id="fundingProvisionInputsContainer" class="o-grid-3 full-width">
					<input-wrapper label="Funding methods" class="radio-checkbox-container">
						<mat-checkbox id="pullOptionInput" color="primary" formControlName="fundingPull"
							>PULL</mat-checkbox
						>
						<mat-checkbox id="pushOptionInput" color="primary" formControlName="fundingPush"
							>PUSH</mat-checkbox
						>
					</input-wrapper>

					<input-wrapper label="Provision Compensation" class="radio-checkbox-container">
						<mat-radio-group id="provisionCompensationInput" formControlName="provisionCompensation">
							<mat-radio-button color="primary" value="STANDARD">STANDARD</mat-radio-button>
							<mat-radio-button color="primary" value="OFFSET">OFFSET</mat-radio-button>
						</mat-radio-group>
					</input-wrapper>
				</div>

				<!-- Separator -->
				<div id="divider" class="separator"></div>

				<div id="genericInputsContainer" class="o-grid-3 full-width">
					<input-wrapper label="Account Name *">
						<input id="accountNameInput" type="text" placeholder="Account Name" formControlName="name" />
						<!--<div
						input-errors
						*ngIf="globalForm.controls.name.touched && globalForm.controls.name.errors?.required"
					>
						Source account name is required
					</div>-->
					</input-wrapper>

					<input-wrapper class="select" label="Select a country">
						<!--	[disabled]="editMode || !canEditSourceOfFunds"-->
						<input-autocomplete
							id="countrySelectInput"
							[options]="country$ | async"
							label="All countries"
							formControlName="country"
							[disabled]="readonly"
						>
						</input-autocomplete>
					</input-wrapper>

					<input-wrapper label="Funding currency">
						<input id="currencyInput" type="text" formControlName="currency" />
					</input-wrapper>
				</div>
			</ng-container>
		</div>

		<!-- Separator -->
		<div id="divider" class="separator"></div>

		<!-- BANK ACCOUNT FIELDS -->
		<bank-account-fields
			[country]="selectedCountry"
			[bankName]="bankName"
			[values]="bankAccountValues"
			[editing]="editMode"
			[canEdit]="!readonly"
			*ngIf="selectedCountry"
		>
		</bank-account-fields>

		<div id="buttonsContainer" class="buttons-container sof-buttons">
			<button id="cancelButton" mat-stroked-button class="button-start" (click)="showBread()">Cancel</button>
			<button
				id="saveButton"
				mat-raised-button
				color="dark"
				class="button-end"
				(click)="save()"
				[disabled]="!enableSave"
			>
				Save
			</button>
		</div>
	</div>
</modal-wrapper>
