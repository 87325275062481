import { Component, Input, OnDestroy } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { PayGroup } from "src/app/shared/models/pay-groups";
import { EmployeeAccountSummaryByPayGroupFacadeService } from "../../../services/facade/employee-account-summary-by-pay-group-facade.service";
import { BeneficiaryAccountStatusByPayGroup } from "../../../services/facade/model/BeneficiaryAccountStatusByPayGroup";

@Component({
	selector: "app-pay-elective-pay-group-list-items",
	templateUrl: "./pay-elective-pay-group-list-items.component.html",
	styleUrls: ["./pay-elective-pay-group-list-items.component.scss"]
})
export class PayElectivePayGroupListItemsComponent implements OnDestroy {
	receivedCustomerId!: string;
	receivedPayGroups!: PayGroup[];

	@Input()
	set selectedCustomerId(value: string) {
		this.receivedCustomerId = value;

		if (this.receivedPayGroups.length && value !== "") {
			this.getAccountSummaryByPayGroupGivenCurrentPayGroup();
		}
	}

	@Input() pageIndex = 0;
	@Input() pageSize: number = 10;

	@Input()
	set paygroups(value: PayGroup[]) {
		this.receivedPayGroups = value;
		this.loadingBeneficiaryDetails = true;

		if (this.receivedCustomerId && value.length) {
			this.getAccountSummaryByPayGroupGivenCurrentPayGroup();
		}
	}

	destroy$: Subject<void> = new Subject();
	beneficiaryAccountStatusByPayGroup$!: Observable<BeneficiaryAccountStatusByPayGroup[]>;

	beneficiaryAccountStatusByPayGroup!: BeneficiaryAccountStatusByPayGroup[];
	loadingBeneficiaryDetails = true;

	constructor(private payGroupAccountSummaryFacade: EmployeeAccountSummaryByPayGroupFacadeService) {}

	private getAccountSummaryByPayGroupGivenCurrentPayGroup() {
		this.payGroupAccountSummaryFacade
			.getAccountSummaryByPayGroupGiven(this.receivedCustomerId, this.receivedPayGroups)
			.pipe(take(1), takeUntil(this.destroy$))
			.subscribe({
				next: beneficiaryAccountStatusByPayGroup => {
					this.beneficiaryAccountStatusByPayGroup = beneficiaryAccountStatusByPayGroup;
				},
				complete: () => {
					this.loadingBeneficiaryDetails = false;
				}
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
