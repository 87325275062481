import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CustomerLegalEntitySelectComponent } from "./customer-legal-entity-select.component";
import { FlexLayoutModule } from "ngx-flexible-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { InputAutocompleteModule } from "../input-autocomplete/input-autocomplete.module";
import { InputSelectModule } from "../input-select/input-select.module";
import { InputWrapperModule } from "../input-wrapper/input-wrapper.module";
import { SharedModule } from "@shared/shared.module";
import { WpayAutoCompleteModule } from "../wpay-ui/base-components/wpay-auto-complete/wpay-auto-complete.module";

@NgModule({
	declarations: [CustomerLegalEntitySelectComponent],
	imports: [
		CommonModule,
		MatIconModule,
		MatButtonModule,
		InputAutocompleteModule,
		InputWrapperModule,
		MatFormFieldModule,
		MatInputModule,
		FlexLayoutModule,
		FormsModule,
		ReactiveFormsModule,
		InputSelectModule,
		SharedModule,
		InputAutocompleteModule,
		WpayAutoCompleteModule
	],
	exports: [CustomerLegalEntitySelectComponent]
})
export class CustomerLegalEntitySelectModule {}
