import { Component, Inject } from "@angular/core";
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from "@angular/material/snack-bar";

@Component({
	selector: "toast",
	templateUrl: "./toast.component.html",
	styleUrls: ["./toast.component.scss"]
})
export class ToastComponent {
	constructor(
		public snackBarRef: MatSnackBarRef<ToastComponent>,
		@Inject(MAT_SNACK_BAR_DATA) public data: { message: string; snackType: string }
	) {}

	get getIcon() {
		switch (this.data.snackType) {
			case "success":
				return "check_circle_outline";
			case "danger":
				return "error_outline";
			case "warning":
				return "warning";
			case "info":
				return "info";
			default:
				return "info";
		}
	}
}
