import { Injectable, OnDestroy } from "@angular/core";
import { LegalEntityApi } from "../../_models/legal-entity-api.interface";
import { ContactPerson, ContactPersonObject } from "../../../../shared/models/contact-person.interface";
import { Customer } from "../../../../shared/models/customer.interface";
import { BehaviorSubject, Subject, lastValueFrom } from "rxjs";
import { LegalEntity } from "../../../../shared/models/legal-entity.interface";
import { SelectOption } from "../../../../shared/models/select-option.interface";
import { formatDataForSelect } from "../../../../shared/utils/format-select-data.util";
import { Country } from "../../../../shared/models/country.interface";
import { createCustomerLogoPath } from "../../../../shared/utils/customer-logo-path.util";
import { takeUntil } from "rxjs/operators";
import { FormGroup } from "@angular/forms";
import { CountriesService } from "@shared/services/countries/countries.service";

@Injectable()
export class CommonLegalEntityService implements OnDestroy {
	legalEntities$: Subject<{
		value: LegalEntity[];
		type: "CREATE" | "UPDATE" | "COPY";
	}> = new Subject<{
		value: LegalEntity[];
		type: "CREATE" | "UPDATE" | "COPY";
	}>();
	selectedCustomer$: Subject<Customer> = new Subject<Customer>();
	countryChanged$: BehaviorSubject<Country> = new BehaviorSubject<Country>({} as Country);
	isCopyMode$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	private destroy$: Subject<void> = new Subject<void>();
	private _legalEntities: LegalEntity[] = [];
	private _customers: Customer[] = [];
	private _countries: Country[] = [];
	private _isCopyMode: boolean = false;
	private _customerSelected: Customer = {} as Customer;
	private _countryCodeSelected: string = "";

	constructor(private countriesService: CountriesService) {}

	set legalEntities(legalEntities: LegalEntity[]) {
		this._legalEntities = legalEntities;
	}

	get legalEntities() {
		return this._legalEntities;
	}

	set customers(customers: Customer[]) {
		this._customers = customers;
	}

	get customers() {
		return this._customers;
	}

	set countries(countries: Country[]) {
		this._countries = countries;
	}

	get countries() {
		return this._countries;
	}

	set isCopyMode(value: boolean) {
		this.isCopyMode$.next(value);
		this._isCopyMode = value;
	}

	get isCopyMode() {
		return this._isCopyMode;
	}

	set customerSelected(customerSelected: Customer) {
		this._customerSelected = customerSelected;
	}

	get customerSelected() {
		return this._customerSelected;
	}

	set countryCodeSelected(countryCodeSelected: string) {
		this._countryCodeSelected = countryCodeSelected;
	}

	get countryCodeSelected() {
		return this._countryCodeSelected;
	}

	formatLegalEntityDataApi(legalEntityFormData: any, creationContactData?: ContactPerson[]): LegalEntityApi {
		return {
			id: legalEntityFormData.id,
			contactPersons: creationContactData || legalEntityFormData.contactPersons,
			status: legalEntityFormData.status,
			version: legalEntityFormData.version,
			data: {
				externalId: legalEntityFormData.externalId,
				payrollId: legalEntityFormData.payrollId,
				name: legalEntityFormData.name,
				address: legalEntityFormData.address,
				timeZone: legalEntityFormData.timeZone,
				country:
					legalEntityFormData.country.length > 2
						? this.getCountryCode(legalEntityFormData.country)
						: legalEntityFormData.country,
				region: legalEntityFormData.region
			},
			customerId: legalEntityFormData.customerId
		};
	}

	getCountryCode(countryName: string): string {
		return this._countries.find(country => {
			return country.country.name === countryName;
		})!.country.code;
	}

	filterLegalEntities(legalEntities: LegalEntity[]): LegalEntity[] {
		let legalEntitiesFiltered: LegalEntity[] = [];

		// First filter by customer
		legalEntitiesFiltered = legalEntities.filter(le => le.customerId === this._customerSelected?.id);

		// Secondly filter by country if it is selected
		if (this._countryCodeSelected) {
			legalEntitiesFiltered = legalEntitiesFiltered.filter(le => le.data.country === this._countryCodeSelected);
		}

		return legalEntitiesFiltered;
	}

	setupCountriesForSelect(countries?: Country[]): SelectOption[] {
		const c: Country[] = countries || this._countries;
		//Create select options from countries data
		return c.map((country: Country) => {
			const option: SelectOption = {
				value: country?.country.code,
				text: country?.country.name,
				imagePath: `assets/svg-country-flags/svg/${country?.country.code.toLowerCase()}.svg`
			};

			return option;
		});
	}

	setupFilterFormEvents(filterForm: FormGroup, selectOptionsCustomer: SelectOption[]) {
		//Set functionality for when selecting external id should filter and show customer logo on new legal entity header

		//If customer set initial value on customer reference select
		if (this._customers.length > 0) {
			filterForm.get("externalId")?.setValue(selectOptionsCustomer[0]);
			this._customerSelected = this._customers[0];
			this.selectedCustomer$.next(this._customerSelected);
		}

		filterForm
			.get("externalId")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe(value => {
				if (value?.value) {
					const customer = this._customers.find(customer => customer.id === value.value);
					this._customerSelected = typeof customer !== "undefined" ? customer : ({} as Customer);
					this.selectedCustomer$.next(this._customerSelected);
				}
			});

		//Set functionality for when selecting country should fill country inside legal entity form
		filterForm
			.get("country")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe(value => {
				this.countryCodeSelected = value;
				this.countryChanged$.next(value);
			});
	}

	selectedCustomerForLegalEntity(legalEntity: LegalEntity): Customer | undefined {
		//Find selected customer to show logo on legal entity item
		let customerSelected: Customer | undefined = this._customers.find(
			(customer: Customer) => customer.id === legalEntity.customerId
		);

		if (customerSelected) {
			customerSelected = createCustomerLogoPath(customerSelected);
		}

		return customerSelected;
	}

	selectedCountryForLegalEntity(legalEntity: LegalEntity): SelectOption[] {
		//Set Selected country for select autocomplete

		const country: Country[] = this.countries.filter(country => country.country.code === legalEntity.data?.country);
		return this.setupCountriesForSelect(country);
	}

	contactPersonObject(objectId: string, contactData: ContactPerson[]) {
		let contactPersons: ContactPerson[] = [];

		contactData.forEach(contactPerson => {
			contactPersons.push(contactPerson);
		});
		let contactPersonObject: ContactPersonObject = {
			objectId: objectId,
			contactPersons: contactPersons
		};
		return contactPersonObject;
	}

	getCountry(countryCode: string): Country | undefined {
		return this._countries?.find((country: Country) => country?.country?.code === countryCode);
	}

	async getCountrySelected(): Promise<Country | undefined> {
		const countries: Country[] = await lastValueFrom(this.countriesService.getCountries());
		return countries.find((country: Country) => country?.country?.code === this.countryCodeSelected);
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
