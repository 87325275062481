import { Component, Output, EventEmitter, Input } from "@angular/core";

@Component({
	selector: "app-net-payment-header",
	templateUrl: "./net-payment-header.component.html",
	styleUrls: ["./net-payment-header.component.scss"]
})
export class NetPaymentHeaderComponent {
	@Input() title = "";
	@Input() subtitle = "";
	@Input() flag = "";
	@Output() goBackEvent = new EventEmitter<void>();

	constructor() {}

	goBack(): void {
		this.goBackEvent.emit();
	}
}
