<app-breadcrumbs
	[headerTitle]=" this.serviceDefinitionType === 'net' ? 'NETS SERVICE DEFINITION':'TPP SERVICE DEFINITION'"
/>
<div class="main-container">
	<app-customer-list
		[onlyWithLegalEnities]="true"
		(newCustomerSelectedEvent)="customerSelected($event)"
	></app-customer-list>
	<router-outlet></router-outlet>
</div>
