import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ToastService } from "@shared/services/toast/toast.service";
import { take } from "rxjs/operators";
import { PayoutsService } from "@shared/services/payouts/payouts.service";
import { TransactionReport, TransactionReportWithPagination } from "src/app/shared/models/payouts.types";

@Component({
	selector: "app-payout-status-history-dialog",
	templateUrl: "./payout-status-history-dialog.component.html",
	styleUrls: ["./payout-status-history-dialog.component.scss"]
})
export class PayoutStatusHistoryDialogComponent implements OnInit {
	dataSource: TransactionReport[] = [];
	tableColumns: string[] = ["EMPLOYEE ID", "FULL NAME", "TRANSACTION ID", "STATUS", "REASON", "CREATED DATE"];

	searchForm!: FormGroup;
	transactionsWithReprocessedInformation: TransactionReport[] = [];

	constructor(
		private formBuilder: FormBuilder,
		private payoutsService: PayoutsService,
		public dialogRef: MatDialogRef<PayoutStatusHistoryDialogComponent>,
		private datePipe: DatePipe,
		private toastService: ToastService,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	ngOnInit(): void {
		this.initForm();

		if (this.data?.payoutTransactions?.length > 0) {
			this.loadTransactionHistory(
				this.data.payoutTransactions[0]?.payoutId,
				this.data.payoutTransactions[0]?.payMethodId,
				"2000-01-01",
				"2050-12-31"
			);
		} else {
			this.toastService.showError("An issue occured while trying to fecth the transaction history");
		}
	}

	loadTransactionHistory(payoutId: string, paymethodId: string, rangeStart: string, rangeEnd: string) {
		this.payoutsService
			.getTransactionHistory(payoutId, paymethodId, rangeStart, rangeEnd)
			.pipe(take(1))
			.subscribe((transactions: TransactionReportWithPagination) => {
				this.transactionsWithReprocessedInformation.push(transactions.items[0]);
				for (let i = 1; i < transactions.items.length; i++) {
					if (transactions.items[i].transactionId !== transactions.items[i - 1].transactionId) {
						const reprocessedTransaction: TransactionReport = {
							...transactions.items[i],
							status: {
								name: "REPROCESSED",
								message: ""
							}
						};

						this.transactionsWithReprocessedInformation.push(reprocessedTransaction);
					}
					this.transactionsWithReprocessedInformation.push(transactions.items[i]);
				}

				this.dataSource = this.transactionsWithReprocessedInformation;
			});
	}

	initForm() {
		this.searchForm = this.formBuilder.group({
			search: [""]
		});
		this.searchForm.get("search")?.valueChanges.subscribe(searchValue => {
			this.dataSource = this.filterResult(searchValue);
		});
	}

	filterResult(searchTerm: string): any {
		let filterSearch: TransactionReport[] = [];

		if (searchTerm) {
			filterSearch = this.transactionsWithReprocessedInformation.filter(
				(result: TransactionReport) =>
					result.status.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
					result.employeeExternalId.toLowerCase().includes(searchTerm.toLowerCase()) ||
					result.employeeFirstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
					result.employeeLastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
					this.datePipe
						.transform(result.createdAt, "MMM d, y ")!
						.toString()
						.toLowerCase()
						.includes(searchTerm.toLowerCase())
			);
		} else {
			filterSearch = this.transactionsWithReprocessedInformation;
		}

		return filterSearch;
	}

	isArray(value: string | string[]): boolean {
		return Array.isArray(value);
	}

	getArrayValue(value: string | string[]): string[] {
		if (this.isArray(value)) {
			return Array.isArray(value) ? value : [value];
		}
		return [];
	}

	isEmptyObject(object: any): boolean {
		return Object.keys(object).length === 0;
	}

	closeDialog(): void {
		this.dialogRef.close(false);
	}
}
