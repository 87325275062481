import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InputSelectComponent } from "@shared/components/input-select/input-select.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatTooltipModule } from "@angular/material/tooltip";
import { SharedModule } from "@shared/shared.module";
import { IconModule } from "../icon/icon.module";

@NgModule({
	declarations: [InputSelectComponent],
	imports: [
		CommonModule,
		MatFormFieldModule,
		MatSelectModule,
		FormsModule,
		ReactiveFormsModule,
		MatTooltipModule,
		SharedModule,
		IconModule
	],
	exports: [InputSelectComponent]
})
export class InputSelectModule {}
