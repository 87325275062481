<div
	class="dropdown-container"
	[ngStyle]="{
		'max-width': (dropDownHeaderWidth$ | async) + 'px'
	}"
>
	<span #dropdownHeader class="arrow" [ngClass]="{ clicked: (showDropDownbool | async) }" (click)="showDropDown()">
		<app-icon class="breadcrumb-arrow-icon" [name]="'arrow-right-1'" [lineWidth]="'3px'"> </app-icon>
	</span>

	<div
		[ngStyle]="{
			display: (showDropDownbool | async) ? 'flex' : 'none',
			'margin-left': (dropDownMarginWidth$ | async) + 'px'
		}"
		class="selection"
		fxFlexAlign="center center"
	>
		<div *ngIf="addSearch" class="search-section" fxLayoutAlign="center center">
			<div [formGroup]="searchForm" class="searchbar-wrapper">
				<mat-form-field appearance="outline" fxLayoutAlign="start center" class="search-bar">
					<app-icon matPrefix [name]="'search-normal-1'" [size]="'15'" class="search-icon-history"></app-icon>
					<input matInput placeholder="Search by customer" formControlName="search" />
				</mat-form-field>
			</div>
		</div>

		<span *ngIf="!(options$ | async)">Loading...</span>

		<div
			class="option-container"
			[fxLayout]="'column'"
			[fxFlexAlign]="'start center'"
			*ngFor="let opt of options$ | async; let i = index; last as isLast"
		>
			<div
				[ngClass]="{ first: opt.link === '' }"
				class="option"
				(click)="goTo(opt)"
				[matTooltip]="opt.externalId!"
				[matTooltipPosition]="'above'"
				[matTooltipClass]="opt.externalId ? 'tooltip-option' : 'tool-noDisplay'"
			>
				<div class="option-text" [ngStyle]="{ padding: opt.imagePath ? '0px 45px' : '0px 60px' }">
					<img class="option-flag" *ngIf="opt.imagePath" src="{{ opt.imagePath }}" /><span class="text">{{
						opt.text
					}}</span>
				</div>
			</div>
			<mat-divider class="divider" *ngIf="!isLast"></mat-divider>
		</div>
	</div>
</div>
