<app-breadcrumbs *ngIf="!showCreateForm || showCreateForm " [headerTitle]="'SERVICE PROVIDER'" />

<div class="main-container">
	<!-- Create new Service Provider buttons when customer list is full -->
	<div class="button-container" *ngIf="!showCreateForm && serviceProviderLength">
		<button
			*ngIf="isSuperUser"
			id="customCTA"
			mat-button
			class="c-button-dashed c-button-fill wpay-dashed-big-cta"
			(click)="toggleShowproviderSettingsForm()"
		>
			<app-icon id="add-service-provider" [name]="'add'"></app-icon>
			<span>Create new Service Provider</span>
		</button>
	</div>

	<!-- Create new Service Provider buttons when customer list is empty -->
	<no-items-layout
		*ngIf="!showCreateForm && serviceProviderLength === 0"
		message="No created service providers yet"
		buttonText="Create new service provider"
		(onClickButton)="toggleShowproviderSettingsForm()"
	>
	</no-items-layout>

	<service-provider-creation *ngIf="showCreateForm"></service-provider-creation>

	<!-- hide list while creating -->
	<app-service-provider-list
		*ngIf="!showCreateForm"
		(length)="getServiceProviderLength($event)"
	></app-service-provider-list>
</div>
