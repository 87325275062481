<app-breadcrumbs [headerTitle]="'TPP CATALOGUE'" />
<div class="main-container">
	<ng-container *ngIf="tppServicesTypes$ | async as tppServicesTypes">
		<ng-container *ngIf="tppServicesTypes.size > 0">
			<button
				*ngIf="(tppServicesTypes$ | async) && canCreateNewCatService"
				id="newService"
				mat-fab
				class="fab-button-style"
				(click)="addNewService()"
			>
				<app-icon
					id="add-tpp-catalog-icon"
					[name]="'add'"
					[color]="'white'"
					[size]="'30'"
					[lineWidth]="'2px'"
				></app-icon>
			</button>
		</ng-container>
	</ng-container>

	<div id="countrySelectionContainer" [formGroup]="form">
		<input-wrapper label="COUNTRY">
			<input-autocomplete
				id="country"
				formControlName="country"
				label="Select a country"
				[options]="country$ | async"
				[defaultValue]="form.get('country')?.value"
			>
			</input-autocomplete>
		</input-wrapper>
	</div>

	<ng-container *ngIf="tppServicesTypes$ | async as tppServicesTypes; else noItems">
		<ng-container *ngIf="tppServicesTypes.size > 0; else noItems">
			<div class="list-title" id="listTitle">List of services</div>
			<ng-container *ngFor="let item of tppServicesTypes | keyvalue; let i = index">
				<div class="c-text-label">
					{{ item.key | tppTypesFormat }}
				</div>
				<ng-container *ngFor="let tppService of item.value">
					<div id="infoContainer" class="info-container flex flex-row" *ngIf="showDesc(tppService.id)">
						<div class="flex flex-align-center-start flex-1" style="--max-width: 90%">
							<p class="info-container-text">
								<strong>*{{ tppService.name.toUpperCase() }}:</strong>
								{{ tppService.description }}
							</p>
						</div>
						<div id="deleteRoleIconContainer" class="flex-1" style="--max-width: 10%">
							<app-icon
								id="deleteRoleIcon"
								class="close-icon"
								size="40"
								name="close"
								[color]="'#272833'"
								class="flex flex-align-stretch-end"
								(click)="showInfoChange(tppService.id, false, $event)"
							></app-icon>
						</div>
					</div>
					<mat-card id="card" class="card" (click)="selectTppService(tppService)">
						<div
							id="cardContainer"
							class="flex flex-row flex-align-center-start flex-1"
							style="--max-width: 40%"
						>
							<p id="cardText" class="card-text">{{ tppService.name }}</p>
							<app-icon
								id="cardIcon"
								class="card-icon"
								[name]="'info-circle'"
								[color]="'#EAEAEA'"
								[size]="'20'"
								(click)="showInfoChange(tppService.id, true, $event)"
							></app-icon>
						</div>
					</mat-card>
				</ng-container>
			</ng-container>
		</ng-container>

		<mat-paginator
			*ngIf="totalPageCount"
			[length]="totalPageCount"
			[pageSizeOptions]="[5, 10, 25, 100]"
			[pageSize]="pageSize"
			[pageIndex]="pageIndex"
			(page)="paginate($event)"
			aria-label="Select page"
		>
		</mat-paginator>
	</ng-container>

	<ng-template #noItems>
		<no-items-layout
			[message]="'No created services yet'"
			[buttonText]="canCreateNewCatService ? 'Add new service' : ''"
			[disabled]="disableButton || !canCreateNewCatService"
			(onClickButton)="addNewService()"
		>
		</no-items-layout>
	</ng-template>
</div>
