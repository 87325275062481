import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { HistoryLogSidePanelService } from "@shared/components/history-log-side-panel/services/history-log-side-panel.service";
import { GlobalDashboardEventsService } from "@modules/global-dashboard/services/global-dashboard-events.service";

import { EventsTableDetailItem } from "@modules/global-dashboard/models/global-dashboard-interface";

@Component({
	selector: "app-landing-page-events-table",
	templateUrl: "./landing-page-events-table.component.html",
	styleUrls: ["./landing-page-events-table.component.scss"]
})
export class LandingPageEventsTableComponent {
	constructor(
		private globalDashboardEventsService: GlobalDashboardEventsService,
		private historyLogService: HistoryLogSidePanelService
	) {}

	@Input() set events(events: EventsTableDetailItem[]) {
		this.dataSourceEvents.data = events;
	}

	@Input() set isClient(client: boolean) {
		this.headers = [
			"select",
			client ? "Paygroups" : "Customers",
			"Paycycle/run",
			"Payments",
			"Milestone",
			"Status",
			"Deadline",
			"More"
		];
	}

	@Output() updateStatus: EventEmitter<void> = new EventEmitter<void>();
	@Output() hideEvent: EventEmitter<EventsTableDetailItem> = new EventEmitter<EventsTableDetailItem>();
	@Output() selectEvent: EventEmitter<EventsTableDetailItem[]> = new EventEmitter<EventsTableDetailItem[]>();

	dataSourceEvents: MatTableDataSource<EventsTableDetailItem> = new MatTableDataSource();
	headers: string[] = [];
	selectedEvents: EventsTableDetailItem[] = [];

	updateMilestoneEventHistoryLogSubject(element: EventsTableDetailItem) {
		this.historyLogService.setElementData(element);
	}
	routeToPayments(row: EventsTableDetailItem): void {
		if (row.milestoneDetails.type === "NETS" || row.milestoneDetails.type === "PAYMENT") {
			this.globalDashboardEventsService.routeToSpecifiedScreen(
				"payments",
				row.payGroupDetails.id,
				row.payCycleDetails.id,
				row.group
			);
		} else if (row.milestoneDetails.type === "G2N_FILE" || row.milestoneDetails.type === "DATA_INPUT") {
			this.globalDashboardEventsService.routeToSpecifiedScreen(
				"paymentOrders",
				row.payGroupDetails.id,
				row.payCycleDetails.id,
				row.group
			);
		}
	}

	isHidingDisabled(row: EventsTableDetailItem): boolean {
		if (row.milestoneDetails.type == "FUNDING_DATE") {
			return row.milestoneDetails.status == "COMPLETED";
		} else {
			return row.milestoneDetails.status != "PENDING";
		}
	}

	hideEventHandler(row: EventsTableDetailItem): void {
		this.hideEvent.emit(row);
	}

	selectEventHandler(row: EventsTableDetailItem, checked: boolean): void {
		if (checked) {
			this.selectedEvents.push(row);
		} else {
			this.selectedEvents = this.selectedEvents.filter(item => item !== row);
		}
		this.selectEvent.emit(this.selectedEvents);
	}
}
