import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ErrorManagementComponent } from "./containers/error-management/error-management.component";
import { ModalWrapperModule } from "@shared/components/modal-wrapper/modal-wrapper.module";
import { ErrorListFiltersComponent } from "./components/error-list-filters/error-list-filters.component";
import { ErrorListComponent } from "./components/error-list/error-list.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { InputAutocompleteModule } from "@shared/components/input-autocomplete/input-autocomplete.module";
import { InputSelectModule } from "@shared/components/input-select/input-select.module";
import { PaginationModule } from "@shared/components/pagination/pagination.module";
import { TooltipModule } from "@shared/components/tooltip/tooltip.module";
import { SharedModule } from "../../shared/shared.module";
import { PipesModule } from "../../shared/pipes/pipes.module";
import { InputWrapperModule } from "@shared/components/input-wrapper/input-wrapper.module";
import { EntityDataService, EntityDefinitionService, EntityMetadataMap } from "@ngrx/data";
import { ErrorManagementEntityService } from "./services/error-management-entity.service";
import { ErrorManagementResolver } from "./services/error-management.resolver";
import { ErrorManagementDataService } from "./services/error-management-data.service";
import { ErrorManagementRoutingModule } from "./error-management-routing.module";
import { ErrorTypesModule } from "./error-types/error-types.module";
import { OutputMessageDialogComponent } from "./components/output-message-dialog/output-message-dialog.component";
import { MatDialogModule } from "@angular/material/dialog";
import { BreadcrumbsModule } from "@shared/components/breadcrumbs/breadcrumbs.module";

const entityMetadata: EntityMetadataMap = {
	ErrorManagement: {
		entityDispatcherOptions: {}
	}
};

@NgModule({
	declarations: [
		ErrorManagementComponent,
		ErrorListFiltersComponent,
		ErrorListComponent,
		OutputMessageDialogComponent
	],
	imports: [
		CommonModule,
		ErrorManagementRoutingModule,
		ModalWrapperModule,
		InputWrapperModule,
		MatInputModule,
		MatFormFieldModule,
		FormsModule,
		ReactiveFormsModule,
		InputSelectModule,
		InputAutocompleteModule,
		MatButtonModule,
		MatTableModule,
		PaginationModule,
		MatPaginatorModule,
		MatTooltipModule,
		PipesModule,
		SharedModule,
		TooltipModule,
		MatSelectModule,
		ErrorTypesModule,
		MatDialogModule,
		BreadcrumbsModule
	],

	providers: [ErrorManagementEntityService, ErrorManagementResolver, ErrorManagementDataService]
})
export class ErrorManagementModule {
	constructor(
		eds: EntityDefinitionService,
		private entityDataService: EntityDataService,
		private errorManagementDataService: ErrorManagementDataService
	) {
		eds.registerMetadataMap(entityMetadata);
		entityDataService.registerService("ErrorManagement", errorManagementDataService);
	}
}
