<table mat-table [dataSource]="dataSource" class="user-management-table">
	<!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

	<!-- email Column -->
	<ng-container matColumnDef="email">
		<th mat-header-cell *matHeaderCellDef class="first-collumn">EMAIL</th>
		<td mat-cell *matCellDef="let element" class="first-collumn">
			<span class="active-mark"></span>
			<span class="text">{{ element.email }}</span>
		</td>
	</ng-container>

	<!-- Name Column -->
	<ng-container matColumnDef="firstName">
		<th mat-header-cell *matHeaderCellDef class="second-collumn">FIRST NAME</th>
		<td mat-cell *matCellDef="let element" class="second-collumn">
			<span class="text">{{ element.firstName }}</span>
		</td>
	</ng-container>

	<!-- Weight Column -->
	<ng-container matColumnDef="lastName">
		<th mat-header-cell *matHeaderCellDef class="third-collumn">LAST NAME</th>
		<td mat-cell *matCellDef="let element" class="third-collumn">
			<span class="text">{{ element.lastName }}</span>
		</td>
	</ng-container>

	<!-- Role Column -->
	<ng-container matColumnDef="role">
		<th mat-header-cell *matHeaderCellDef class="fourth-collumn">ROLE</th>
		<td mat-cell *matCellDef="let element" class="fourth-collumn roles-container" #rolesContainer>
			<div
				class="role-item"
				*ngFor="let role of element.roles | roles; let i = index"
				[ngClass]="{ hidden: i >= maxRolesToDisplay }"
			>
				<span [ngClass]="'colors-rol-' + role.value" *ngIf="element.roles.length === 1" class="role-text">
					{{ role.text }}</span
				>

				<span [ngClass]="'colors-rol-' + role.value" *ngIf="element.roles.length > 1" class="role-text">{{
					role.placeholder
				}}</span>
			</div>

			<div class="count-roles role-item" *ngIf="element.roles.length > maxRolesToDisplay">
				+{{ element.roles.length - maxRolesToDisplay }}
			</div>
		</td>
	</ng-container>

	<!-- Actions actions -->
	<ng-container matColumnDef="actions">
		<th mat-header-cell *matHeaderCellDef class="fifth-collumn"></th>
		<td mat-cell *matCellDef="let element" class="fifth-collumn">
			<div class="action-buttons">
				<div class="edit-icon" id="editUserIcon" (click)="editUser(element)">
					<app-icon class="icon-menu-item" size="20" name="edit-2" color="#767676"></app-icon>
				</div>
				<div id="changeUserStatus" class="inactive user-slide">
					<mat-slide-toggle
						[matTooltip]="!element.blocked ? 'Inactivate user' : 'Activate user'"
						(change)="changeStatus($event, element)"
						[ngModel]="!element.blocked"
						(ngModelChange)="element.blocked = $event"
						class="toggle-default"
					></mat-slide-toggle>
				</div>
				<div class="more-icon-style">
					<app-icon class="bank-more" [color]="'#292D32'" [name]="'more'" [lineWidth]="'1.5'"></app-icon>
					<div class="log-option box" (click)="showUserLog(element)">
						<div class="select-style flex flex-row flex-align-center-center">
							<span>History Log</span>
						</div>
					</div>
				</div>
			</div>
		</td>
	</ng-container>

	<tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
	<tr class="make-visible" mat-row *matRowDef="let row; columns: tableColumns"></tr>
</table>
