<div *ngIf="{ inactive: inactivePaymentMethodsArray } as data">
	<div
		class="payment-method-container"
		*ngFor="let inactivePaymentMethodsArray of data.inactive; let i = index"
		(click)="
			onClickEditBankAccount(
				inactivePaymentMethodsArray.payMethod.accountId,
				inactivePaymentMethodsArray.payMethod.length
			)
		"
	>
		<div class="drag-container" [ngStyle]="{ visibility: 'hidden' }">
			<app-icon [name]="'menu'"></app-icon>
		</div>
		<div class="left-box">
			<!-- Badge -->
			<div class="badge" [ngClass]="{ 'card-color': inactivePaymentMethodsArray.payMethod.type === 'CARD' }">
				<!-- Card icon -->
				<span *ngIf="inactivePaymentMethodsArray.payMethod.type === 'CARD'; else noCard"
					><app-icon class="bank-card-icon" [name]="'card'" [size]="'30'"></app-icon>
				</span>

				<!-- Bank icon -->
				<ng-template #noCard>
					<app-icon
						id="accountBalance"
						class="bank-icon"
						[name]="'bank-figma'"
						[size]="'30'"
						[lineWidth]="'2'"
					></app-icon>
				</ng-template>
			</div>

			<!-- Bank data -->
			<div class="data-box">
				<div class="title">{{ inactivePaymentMethodsArray.details.bankName }}</div>
				<!-- <div class="subtitle"></div> -->
			</div>

			<!-- If it is a card -->
			<ng-container *ngIf="inactivePaymentMethodsArray.payMethod.type === 'CARD'">
				<!-- Card number -->
				<div class="data-box">
					<div class="title-2">Card number</div>
					<div class="subtitle-2">**** **** **** {{ inactivePaymentMethodsArray.details.lastFour }}</div>
				</div>

				<!-- Card holder name -->
				<div class="data-box">
					<div class="title-2">Card holder name</div>
					<div class="subtitle-2">
						{{ inactivePaymentMethodsArray.details.holder.firstName }}
						{{ inactivePaymentMethodsArray.details.holder.lastName }}
					</div>
				</div>
			</ng-container>
		</div>

		<div class="middle-box">
			<!-- Payment Types -->
			<div class="data-container">
				<mat-checkbox
					id="netCheck"
					color="primary"
					[checked]="!!inactivePaymentMethodsArray.payMethod.paymentTypes?.includes(net)!"
					disabled
					>NET
				</mat-checkbox>
				<mat-checkbox
					id="ttpCheck"
					color="primary"
					[checked]="!!inactivePaymentMethodsArray.payMethod.paymentTypes?.includes(ttp)!"
					disabled
					>TPP
				</mat-checkbox>
				<mat-checkbox
					id="ewaCheck"
					color="primary"
					[checked]="!!inactivePaymentMethodsArray.payMethod.paymentTypes?.includes(ewa)!"
					disabled
					>EWA
				</mat-checkbox>
			</div>
		</div>

		<div class="right-box">
			<!-- If it is not a card -->
			<ng-container *ngIf="inactivePaymentMethodsArray.payMethod.type !== 'CARD'">
				<!-- Status -->
				<div class="data-container">
					<!-- Badge -->
					<div
						class="provider-status"
						*ngIf="
							inactivePaymentMethodsArray.payMethod.providersStatuses[providerName];
							else showNonProviderBasedStatus
						"
					>
						<div
							class="status-badge"
							id="statusBadge"
							[ngClass]="{
								created:
									inactivePaymentMethodsArray.payMethod.providersStatuses[providerName].name ===
									'CREATED',
								active:
									inactivePaymentMethodsArray.payMethod.providersStatuses[providerName].name ===
									'ACTIVE',
								inactive:
									inactivePaymentMethodsArray.payMethod.providersStatuses[providerName].name ===
									'INACTIVE'
							}"
						></div>

						<!-- Label -->
						<div id="beneficiaryStatus" class="status-label">
							{{
								inactivePaymentMethodsArray.payMethod.providersStatuses[providerName].name ===
								"INACTIVE"
									? "Failed"
									: (inactivePaymentMethodsArray.payMethod.providersStatuses[providerName].name
									  | payElectiveStatus
									  | titlecase)
							}}
						</div>
					</div>

					<ng-template #showNonProviderBasedStatus class="provider-status">
						<div
							class="status-badge"
							id="statusBadge"
							[ngClass]="{
								created: inactivePaymentMethodsArray.payMethod.status.name === 'CREATED',
								active: inactivePaymentMethodsArray.payMethod.status.name === 'ACTIVE',
								inactive: inactivePaymentMethodsArray.payMethod.status.name === 'INACTIVE'
							}"
						></div>

						<!-- Label -->
						<div id="beneficiaryStatus" class="status-label">
							{{
								inactivePaymentMethodsArray.payMethod.status.name === "INACTIVE"
									? "Failed"
									: (inactivePaymentMethodsArray.payMethod.status.name
									  | payElectiveStatus
									  | titlecase)
							}}
						</div>
					</ng-template>
				</div>

				<div class="data-container"></div>
			</ng-container>
		</div>
	</div>
</div>
