import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CustomerSetupPage } from "./customer-setup.page";
import { CustomerEditionComponent } from "./_components/customer-edition/customer-edition.component";
import { CustomerCreationComponent } from "./_components/customer-creation/customer-creation.component";

const routes: Routes = [
	{
		path: "",
		component: CustomerSetupPage
	},
	{
		path: "edit",
		component: CustomerEditionComponent
	},
	{
		path: "create",
		component: CustomerCreationComponent
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class CustomerSetupRoutingModule {}
