import { DatePipe, Location } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { Observable, Subject, Subscription } from "rxjs";
import { map, takeUntil } from "rxjs/operators";

import { BreadcrumbService } from "@shared/components/breadcrumbs/services/breadcrumb.service";
import {
	comfirmationPopUpTypes,
	confirmationConfig
} from "@shared/components/wpay-ui/modal/templates/confirmation-pop-up/model/confirmation-pop-up.interface";
import { MilestonesService } from "@shared/services/milestones/milestones.service";
import { take } from "rxjs/operators";
import { ModalLauncherService } from "@shared/components/wpay-ui/modal/modal-launcher.service";
import { PaygroupsService } from "@shared/services/paygroups/paygroups.service";
import { Customer } from "src/app/shared/models/customer.interface";
import { LegalEntityType } from "src/app/shared/models/legal-entity.interface";
import { createISOStringFromDateWithoutMilleseconds } from "src/app/shared/utils/date.util";
import { ContactPersonService } from "../../../../shared/services/contact-person/contact-person.service";
import { PermissionsService } from "../../../../shared/services/permissions/permissions.service";
import { ContactPerson } from "../../../../shared/models/contact-person.interface";
import {
	GoLiveFormField,
	IPayGroupRawForm,
	PayGroup,
	PaygroupHistoryOptions,
	statusType
} from "@shared/models/pay-groups";
import { SelectOption } from "@shared/models/select-option.interface";
import { getCustomerEntityGroupState } from "src/app/store";
import { AppState } from "../../../../store/models/state.model";
import { CommonService } from "../../services/common.service";

@Component({
	selector: "app-pay-group",
	templateUrl: "./pay-group.component.html",
	styleUrls: ["./pay-group.component.scss"]
})
export class PayGroupComponent implements OnInit, OnDestroy {
	isUpdateExistingPayGroup: boolean = false;
	customer!: Customer;
	legalEntity!: LegalEntityType;

	payGroup!: PayGroup;

	selectedLegalEntityId: string = "";

	subscription: Subscription = new Subscription();

	customerEntityGroupSubscription: Subscription = new Subscription();

	destroy$: Subject<void> = new Subject<void>();

	currencies$: Observable<SelectOption[]> = new Observable<SelectOption[]>();
	frequency: SelectOption[] = [];
	runStartDay: SelectOption[] = [];
	calendarYearStartBiWeek: SelectOption[] = [];
	calendarYearStartFourWeek: SelectOption[] = [];
	statuses: SelectOption[] = [];
	paymentMethods: SelectOption[] = [];
	payrollFrequencies: SelectOption[] = [];

	addContactClicked$: Subject<void> = new Subject<void>();
	isCreateContactValid: boolean = false;
	contacts: ContactPerson[] = [];
	existingContacts!: ContactPerson[];

	contactFormisValid: boolean = true;

	canCreatePayGroupGoLiveDate = false;
	canEditPayGroupGoLiveDate = false;
	userCanEditPayGroupStatus = false;

	canEditPayGroups: boolean = false;
	canCreatePayrgoups: boolean = false;
	canEditContacts: boolean = false;
	canViewHistory: PaygroupHistoryOptions = {
		statusHistory: false,
		goLiveDateHistory: false,
		treasuryGoLiveDateHistory: false
	};

	form!: FormGroup;
	showHistoryPanel = false;
	type!: statusType;
	externalCrumb = "Create new pay group";

	currentGoLiveDate!: string;
	currentTreasuryGoLiveDate!: string;

	isEdit = false;

	confimationPopUpConfig: confirmationConfig = {
		type: comfirmationPopUpTypes.WARNING,
		confirmButtonText: "YES, CHANGE IT.",
		backButtonText: "NO, THANKS.",
		contextMessage: "",
		confirmationMessage: "Are you sure you want to change it?"
	};

	constructor(
		private formBuilderService: UntypedFormBuilder,
		private commonService: CommonService,
		private readonly location: Location,
		private router: Router,
		private store: Store<AppState>,
		private contactPersonService: ContactPersonService,
		private permissions: PermissionsService,
		private modalLauncherService: ModalLauncherService,
		private breadCrumbService: BreadcrumbService,
		private payGroupService: PaygroupsService,
		private datePipe: DatePipe,
		private milestonesService: MilestonesService
	) {}

	ngOnInit(): void {
		this.initSubscriptions();
		this.getDropDownData();
		this.getPermissions();
	}

	getPermissions(): void {
		this.permissions
			.canCreatePayGroupGoLiveDate()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => {
				this.canCreatePayGroupGoLiveDate = res;
			});

		this.permissions
			.canEditPayGroupGoLiveDate()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => {
				this.canEditPayGroupGoLiveDate = res;
			});

		this.permissions
			.canEditPayGroupStatus()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => {
				this.userCanEditPayGroupStatus = res;
			});

		this.permissions
			.canCreatePayGroups()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => (this.canCreatePayrgoups = res));

		this.permissions
			.canEditPayGroups()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => (this.canEditPayGroups = res));

		this.permissions
			.canEditContactPersons()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => (this.canEditContacts = res));

		this.setupFrom();
	}
	setupFrom(): void {
		this.customerEntityGroupSubscription = this.store
			.pipe(takeUntil(this.destroy$), select(getCustomerEntityGroupState))
			.subscribe(state => {
				if (state && state.payGroupId) {
					this.form = {} as IPayGroupRawForm;
					this.initializeComponentFromStateChange(state.payGroupId);
					this.isEdit = true;
				} else if (!history.state.payGroup) {
					this.selectedLegalEntityId = history.state.legalEntityId;
					this.existingContacts = [];
					this.initForm();
				}
			});
	}

	getDropDownData(): void {
		this.payrollFrequencies = this.commonService.getPayrollFrequencues();
		this.frequency = this.commonService.getFrequencyOptions();
		this.statuses = this.commonService.getStatusOptions();
		this.paymentMethods = this.commonService.getSupportedPayMethodOptions();
		this.currencies$ = this.commonService.getCurrencies();
		this.calendarYearStartBiWeek = this.commonService.getCalendarYearStartWeekBiWeekly();
		this.calendarYearStartFourWeek = this.commonService.getCalendarYearStartWeekFourWeekly();
		this.runStartDay = this.commonService.getRunStartDayOptions();
	}

	initializeComponentFromStateChange(paygroupId: string): void {
		this.payGroupService
			.getPaygroupWithPaygroupId(paygroupId)
			.pipe(take(1))
			.subscribe({
				next: res => {
					this.selectedLegalEntityId = res.legalEntityId;
					this.payGroup = res;
					this.isUpdateExistingPayGroup = true;
					this.getContactPersons(res.id!);
					this.initFormWithValues(res);
				}
			});
	}

	formIsSetup(): boolean {
		return this.form && Object.keys(this.form).length > 0;
	}
	initFormWithValues(payGroup: PayGroup): void {
		this.form = this.formBuilderService.group({
			externalId: [payGroup.externalId, Validators.required],
			legalEntityId: [payGroup.legalEntityId],
			name: [payGroup.data.name, Validators.required],
			frequency: [payGroup.data.frequency, Validators.required],
			currency: [payGroup.data.currency, Validators.required],
			supportedPayMethodTypes: [payGroup.supportedPayMethodTypes[0], Validators.required],
			status: [payGroup.status, Validators.required],
			treasuryStatus: [payGroup.treasuryStatus],
			goLiveAt: [payGroup.data.goLiveAt ? payGroup.data.goLiveAt : null],
			treasuryGoLiveAt: [payGroup.data.treasuryGoLiveAt ? payGroup.data.treasuryGoLiveAt : null],
			avoidPennyTest: [payGroup.data.avoidPennyTest === true ? "true" : "false", Validators.required],
			integrationFrequency: [
				payGroup.integrationFrequency
					? this.getIntergrationValue(payGroup.integrationFrequency)
					: this.getIntergrationValue(0),
				Validators.required
			],
			id: [payGroup.id],
			version: [payGroup.version]
		}) as IPayGroupRawForm;

		this.externalCrumb = payGroup.data.name;
		this.currentGoLiveDate = this.payGroup.data.goLiveAt;
		this.currentTreasuryGoLiveDate = this.payGroup.data.treasuryGoLiveAt;

		this.paygroupFrequencyOptions(payGroup);
		this.disableControls(payGroup);
		this.setupEditOnlyValueChanges();
		this.setupValueChanges();
	}

	paygroupFrequencyOptions(payGroup: PayGroup) {
		if (
			(this.form.get("frequency")?.value && this.form.get("frequency")?.value === "WEEKLY") ||
			(this.form.get("frequency")?.value && this.form.get("frequency")?.value === "BI_WEEKLY") ||
			(this.form.get("frequency")?.value && this.form.get("frequency")?.value === "FOUR_WEEKLY")
		) {
			this.addRunStartDayControl(payGroup);
		}

		if (
			this.form.get("frequency")?.value &&
			(this.form.get("frequency")?.value === "BI_WEEKLY" || this.form.get("frequency")?.value === "FOUR_WEEKLY")
		) {
			this.addCalendarYearStartWeekControl(payGroup);
		}
	}

	addRunStartDayControl(payGroup: PayGroup): void {
		this.form.addControl(
			"runStartDay",
			this.formBuilderService.control({
				value: payGroup?.data.runStartDay,
				disabled: this.form.get("status")?.value === "LIVE" ? true : false
			})
		);
	}

	addCalendarYearStartWeekControl(payGroup: PayGroup): void {
		this.form.addControl(
			"calendarYearStartWeek",
			this.formBuilderService.control({
				value: payGroup?.data.calendarYearStartWeek,
				disabled: this.form.get("status")?.value === "LIVE" ? true : false
			})
		);
	}

	getPayrollFrequencies(): void {
		this.payrollFrequencies = this.commonService.getPayrollFrequencues();
	}

	initForm(): void {
		this.form = this.formBuilderService.group({
			externalId: ["", Validators.required],
			legalEntityId: [this.selectedLegalEntityId],
			name: ["", Validators.required],
			frequency: ["", Validators.required],
			currency: ["", Validators.required],
			supportedPayMethodTypes: ["", Validators.required],
			status: ["", Validators.required],
			treasuryStatus: [],
			goLiveAt: [""],
			treasuryGoLiveAt: [""],
			avoidPennyTest: [false, Validators.required],
			integrationFrequency: [this.getIntergrationValue(0), Validators.required]
		}) as IPayGroupRawForm;

		if (!this.canCreatePayGroupGoLiveDate) {
			this.form.get("goLiveAt")?.disable();
			this.form.get("treasuryGoLiveAt")?.disable();
		}

		this.setupValueChanges();
	}

	setupEditOnlyValueChanges(): void {
		this.form
			.get("goLiveAt")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: date => {
					this.confimationPopUpConfig.contextMessage = `You are changing the Go Live Date to <b>${this.datePipe.transform(
						date,
						"MMMM d, yyyy"
					)}</b>`;

					const dialogRef = this.modalLauncherService.showConfirmationPopUp(this.confimationPopUpConfig);

					dialogRef.afterClosed().subscribe((result: string) => {
						if (result) {
							this.currentGoLiveDate = createISOStringFromDateWithoutMilleseconds(date);
							this.form.get("goLiveAt")?.patchValue(this.currentGoLiveDate, { emitEvent: false });
						} else {
							this.form.get("goLiveAt")?.patchValue(this.currentGoLiveDate, { emitEvent: false });
						}
					});
				}
			});

		this.form
			.get("treasuryGoLiveAt")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: date => {
					this.confimationPopUpConfig.contextMessage = `You are changing the Treasury Go Live Date to <b>${this.datePipe.transform(
						date,
						"MMMM d, yyyy"
					)}</b>`;

					const dialogRef = this.modalLauncherService.showConfirmationPopUp(this.confimationPopUpConfig);

					dialogRef.afterClosed().subscribe((result: string) => {
						if (result) {
							this.currentTreasuryGoLiveDate = createISOStringFromDateWithoutMilleseconds(date);
							this.form
								.get("treasuryGoLiveAt")
								?.patchValue(this.currentTreasuryGoLiveDate, { emitEvent: false });
						} else {
							this.form
								.get("treasuryGoLiveAt")
								?.patchValue(this.currentTreasuryGoLiveDate, { emitEvent: false });
						}
					});
				}
			});
	}

	setupValueChanges(): void {
		this.form
			.get("name")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: name => {
					if (name.length > 0) {
						this.externalCrumb = name;
					} else {
						this.externalCrumb = "Create new pay group";
					}
				}
			});

		this.form
			.get("status")
			?.valueChanges.pipe(
				takeUntil(this.destroy$),

				map(value => {
					if (value === "LIVE") {
						this.form.get("goLiveAt")?.setValidators([Validators.required]);
						this.form.get("goLiveAt")?.markAsTouched();
						this.form.updateValueAndValidity();
					} else {
						this.form.get("goLiveAt")?.clearValidators();
						this.form.get("runStartDay")?.enable();
						this.form.get("calendarYearStartWeek")?.enable();
						this.form.updateValueAndValidity();
					}
					this.form.get("goLiveAt")?.updateValueAndValidity({ emitEvent: false });
				})
			)
			.subscribe();

		this.form
			.get("treasuryStatus")
			?.valueChanges.pipe(
				takeUntil(this.destroy$),

				map(value => {
					if (value === "LIVE") {
						this.form.get("treasuryGoLiveAt")?.setValidators([Validators.required]);
						this.form.get("treasuryGoLiveAt")?.markAsTouched();
						this.form.updateValueAndValidity();
					} else {
						this.form.get("treasuryGoLiveAt")?.clearValidators();
						this.form.updateValueAndValidity();
					}
					this.form.get("treasuryGoLiveAt")?.updateValueAndValidity({ emitEvent: false });
				})
			)
			.subscribe();

		this.form
			.get("integrationFrequency")
			?.valueChanges.pipe(
				takeUntil(this.destroy$),
				map(selection => {
					if (selection === "custom") {
						const dialogRef = this.modalLauncherService.showPayrollIntegrationHistoryModal(
							Number(this.payrollFrequencies[this.payrollFrequencies.length - 1].value)
						);

						dialogRef.afterClosed().subscribe((result: string) => {
							if (result) {
								this.form
									.get("integrationFrequency")
									?.patchValue(this.getIntergrationValue(Number(result)), { emitEvent: false });
							} else {
								this.form
									.get("integrationFrequency")
									?.patchValue(this.payGroup.integrationFrequency.toString(), {
										emitEvent: false
									});
							}
						});
					}
				})
			)
			.subscribe();
	}

	disableControls(payGroup: PayGroup): void {
		if (!this.canEditPayGroups) {
			this.form.disable();
		}

		if (payGroup.data.goLiveAt) this.canViewHistory.goLiveDateHistory = true;
		if (payGroup.data.treasuryGoLiveAt) this.canViewHistory.treasuryGoLiveDateHistory = true;
		if (payGroup.status) this.canViewHistory.statusHistory = true;

		if (
			((!payGroup.data.hasOwnProperty("goLiveAt") || payGroup.data.goLiveAt === "") &&
				this.canCreatePayGroupGoLiveDate) ||
			this.canEditPayGroupGoLiveDate
		) {
			this.canUpdateGoLiveDate(payGroup.id, "goLiveAt");
		} else {
			this.form.get("goLiveAt")?.disable({ emitEvent: false });
		}

		if (
			((!payGroup.data.hasOwnProperty("treasuryGoLiveAt") || payGroup.data.treasuryGoLiveAt === "") &&
				this.canCreatePayGroupGoLiveDate) ||
			this.canEditPayGroupGoLiveDate
		) {
			this.canUpdateGoLiveDate(payGroup.id, "treasuryGoLiveAt");
		} else {
			this.form.get("treasuryGoLiveAt")?.disable({ emitEvent: false });
		}
	}

	canUpdateGoLiveDate(payGroupId: string, property: string): void {
		this.milestonesService
			.getUnorderedMilestones("", payGroupId)
			.pipe(take(1))
			.subscribe({
				next: milestoneList => {
					if (milestoneList.totalCount > 0) this.form.get(property)?.disable({ emitEvent: false });
				}
			});
	}

	changeFrquency(event: string): void {
		this.form.removeControl("calendarYearStartWeek");
		this.form.removeControl("runStartDay");

		if (event === "FOUR_WEEKLY" || event === "BI_WEEKLY" || event === "WEEKLY") {
			this.form.addControl("runStartDay", this.formBuilderService.control(""));

			if (event === "FOUR_WEEKLY" || event === "BI_WEEKLY") {
				this.form.addControl("calendarYearStartWeek", this.formBuilderService.control(""));
			}
		}
	}

	getIntergrationValue(integrationFrequency: number): string {
		if (this.payrollFrequencies.find(freq => freq.value === integrationFrequency.toString())) {
			return integrationFrequency.toString();
		} else {
			this.payrollFrequencies[this.payrollFrequencies.length - 1].value !== "custom"
				? this.payrollFrequencies.pop()
				: null;

			this.payrollFrequencies.push({
				value: integrationFrequency.toString(),
				text: `${integrationFrequency.toString()} hours`
			});

			return integrationFrequency.toString();
		}
	}

	onAddContactClicked(): void {
		this.addContactClicked$.next();
	}

	createContactFormData(event: { data: ContactPerson[]; valid: boolean }): void {
		this.form.markAsDirty();
		this.contactFormisValid = event.valid;
		this.contacts = event.data;
	}

	savePayGroup(): void {
		const formValues = this.form.getRawValue();

		if (!this.isEdit) {
			if (formValues.treasuryGoLiveAt && formValues.treasuryGoLiveAt !== "") {
				const treasuryGoLiveAtValue = formValues[GoLiveFormField.TreasuryGoLiveAt];
				const convertedTreasuryGoLiveAt = createISOStringFromDateWithoutMilleseconds(treasuryGoLiveAtValue);
				formValues[GoLiveFormField.TreasuryGoLiveAt] = convertedTreasuryGoLiveAt;
			}

			if (formValues.goLiveAt && formValues.goLiveAt !== "") {
				const goLiveAtValue = formValues[GoLiveFormField.GoLiveAt];
				const convertedGoLiveAt = createISOStringFromDateWithoutMilleseconds(goLiveAtValue);
				formValues[GoLiveFormField.GoLiveAt] = convertedGoLiveAt;
			}
		}
		formValues.legalEntityId = this.selectedLegalEntityId;
		this.commonService.savePayGroup(formValues, this.contacts, this.isUpdateExistingPayGroup);
	}

	getContactPersons(id: string): void {
		this.contactPersonService
			.getContactPersons(id)
			.pipe(takeUntil(this.destroy$))
			.subscribe(result => {
				this.existingContacts = result;
			});
	}

	initSubscriptions(): void {
		this.subscription = this.commonService.payGroupStored
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => (res ? this.router.navigate(["pay-groups/list"]) : void 0));
	}

	setPanelState(state: boolean, type: string): void {
		this.showHistoryPanel = state;
		this.type = this.mapStatusType(type);
	}

	private mapStatusType(type: string): statusType {
		switch (type) {
			case "STATUS":
				return statusType.STATUS;
			case "TREASURYGOLIVE":
				return statusType.TREASURYGOLIVE;
			default:
				return statusType.GOLIVE; // Default value
		}
	}

	cancel(): void {
		this.breadCrumbService.updateCustomerEntityGroupState();
		this.location.back();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();

		if (this.subscription) this.subscription.unsubscribe();
		if (this.customerEntityGroupSubscription) this.customerEntityGroupSubscription.unsubscribe();
	}
}
