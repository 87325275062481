import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReportingComponent } from "./reporting.component";
import { InputWrapperModule } from "@shared/components/input-wrapper/input-wrapper.module";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatPaginatorModule } from "@angular/material/paginator";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlexLayoutModule } from "ngx-flexible-layout";
import { CustomerLegalEntitySelectModule } from "@shared/components/customer-legal-entity-select/customer-legal-entity-select.module";
import { SharedModule } from "@shared/shared.module";
import { ReportingRoutingModule } from "./reporting-routing.module";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BreadcrumbsModule } from "@shared/components/breadcrumbs/breadcrumbs.module";

@NgModule({
	declarations: [ReportingComponent],
	imports: [
		CommonModule,
		InputWrapperModule,
		MatButtonModule,
		MatIconModule,
		MatFormFieldModule,
		MatInputModule,
		MatDatepickerModule,
		MatPaginatorModule,
		FormsModule,
		ReactiveFormsModule,
		FlexLayoutModule,
		CustomerLegalEntitySelectModule,
		SharedModule,
		ReportingRoutingModule,
		MatTooltipModule,
		BreadcrumbsModule
	]
})
export class ReportingModule {}
