<div
	*ngIf="!isKpiCardZero"
	class="card-container"
	[ngStyle]="{ 'margin-right': isLast ? '51px' : '0px', cursor: isOnGlobalDashboard ? 'pointer' : 'auto' }"
	[ngClass]="{
		'dark-mode': darkStyle,
		'light-mode': !darkStyle,
		'hovered-style': hover || selected,
		loading: showLoadingAnimation
	}"
	(mouseover)="hover = true"
	(mouseout)="hover = false"
	(click)="kpiSelected.emit(kpiName)"
	id="{{ kpiName }}-card"
>
	<div class="type-container" *ngIf="!showLoadingAnimation">
		<div class="info-container">
			<span class="number" id="{{ kpiName }}-card-value">{{ kpiCard.kpiType[kpiName].count }}</span>
			<span class="type">{{ kpiName }}</span>
		</div>
		<div class="icon-container" [ngClass]="{ card: kpiName === 'Missing Cards' }">
			<app-icon
				name="{{ kpiCard.icon }}"
				[ngClass]="{ bank: kpiCard.icon === 'bank-figma' }"
				[lineWidth]="'1.5px'"
				[size]="'26'"
				[color]="darkStyle ? 'var(--color-white)' : 'var(--no-items-poker-face-color)'"
			></app-icon>
		</div>
	</div>

	<div class="percentage-container" *ngIf="!showLoadingAnimation">
		<div class="range">
			<span>0%</span>
			<span>100%</span>
		</div>
		<div class="bar" id="{{ kpiName }}-bar-value">
			<div class="filled" [ngStyle]="{ width: kpiCard.kpiType[kpiName].percentage + '%' }"></div>
		</div>
	</div>
</div>
