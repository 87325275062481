import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";

import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatToolbarModule } from "@angular/material/toolbar";
import { FlexLayoutModule } from "ngx-flexible-layout";
import { SharedModule } from "@shared/shared.module";
import { PipesModule } from "src/app/shared/pipes/pipes.module";
import { IconModule } from "../../icon/icon.module";
import { InputWrapperModule } from "../../input-wrapper/input-wrapper.module";
import { ActionConfirmModalComponent } from "./templates/action-confirm-dialog/action-confirm-dialog.component";
import { CancelBookingComponent } from "./templates/cancel-booking/cancel-booking.component";
import { ConfirmationPopUpComponent } from "./templates/confirmation-pop-up/confirmation-pop-up.component";
import { DescriptionModalComponent } from "./templates/description-modal/description-modal.component";
import { PayElectiveHistoryComponent } from "./templates/pay-elective-history/pay-elective-history.component";
import { PaygroupStatusHistoryComponent } from "./templates/paygroup-status-history/paygroup-status-history/paygroup-status-history.component";
import { PayrollIntegrationModalComponent } from "./templates/payroll-integration-modal/payroll-integration-modal.component";

@NgModule({
	declarations: [
		DescriptionModalComponent,
		PayElectiveHistoryComponent,
		ActionConfirmModalComponent,
		CancelBookingComponent,
		PaygroupStatusHistoryComponent,
		PayrollIntegrationModalComponent,
		ConfirmationPopUpComponent
	],
	exports: [
		DescriptionModalComponent,
		CancelBookingComponent,
		PayrollIntegrationModalComponent,
		ConfirmationPopUpComponent
	],
	imports: [
		CommonModule,
		MatDialogModule,
		MatToolbarModule,
		MatButtonModule,
		MatFormFieldModule,
		MatInputModule,
		ReactiveFormsModule,
		MatIconModule,
		IconModule,
		FlexLayoutModule,
		PipesModule,
		SharedModule,
		InputWrapperModule,
		MatDividerModule
	]
})
export class WpayModalModule {}
