import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "sliding-panel",
	templateUrl: "./sliding-panel.component.html",
	styleUrls: ["./sliding-panel.component.scss"],
	host: {
		"[class.exit]": "exit"
	}
})
export class SlidingPanelComponent implements OnInit {
	@Input() exit: boolean = false;

	constructor() {}

	ngOnInit(): void {}
}
