<app-breadcrumbs
	[headerTitle]="'SERVICE PROVIDERS'"
	[externalCrumbs]="[
		{ crumb: this.providerName, dropDownData: providersDropDownData, url: '1' },
		{ crumb: this.countryName, flag: this.countryCode, dropDownData: countriesDropDownData }
	]"
	(onRoutingCrumbClicked)="routingCrumbClicked($event)"
	(onExternalDropDownCrumbItemSelected)="externalCrumbDropDownSelection($event)"
/>

<div class="main-container">
	<mat-tab-group #tabs>
		<mat-tab label="Service Capabilities">
			<div *ngIf="showWarning" @fade class="warning">Please remember to update the costs</div>
			<div *ngIf="hasProviderCountry" class="content-container">
				<app-service-providers-capabilities-edit></app-service-providers-capabilities-edit>
				<div class="actions">
					<button id="cancelBtn" class="cancel-btn" (click)="goBack()" action-buttons mat-stroked-button>
						Cancel
					</button>
					<button
						id="updateBtn"
						[disabled]="
							!showWarning ||
							!(serviceProviderCountryEditService.editCurrenciesFormState$ | async) ||
							!(serviceProviderCountryEditService.editCapabilitiesFormState$ | async)
						"
						action-buttons
						mat-flat-button
						(click)="updateCapabilities(tabs)"
						color="dark"
					>
						Update
					</button>
				</div>
			</div>
		</mat-tab>
		<mat-tab #costTab>
			<ng-template mat-tab-label>
				<span
					matBadge="&#8288;"
					matBadgePosition="after"
					matBadgeSize="small"
					[matBadgeHidden]="!showWarning"
					(click)="updateCapabilities(tabs)"
					>Service Provider Costs</span
				>
			</ng-template>
			<div *ngIf="hasProviderCountry" class="content-container">
				<h2 class="header-text-label">Headcount configuration</h2>

				<app-headcount-configuration-edit [isSuperUser]="isSuperUser"></app-headcount-configuration-edit>
				<app-service-providers-costs-edit></app-service-providers-costs-edit>
				<div class="actions">
					<button id="previousBtn" class="cancel-btn" (click)="goBack()" action-buttons mat-stroked-button>
						Cancel
					</button>
					<button
						id="updateBtn"
						action-buttons
						mat-flat-button
						[disabled]="
							!costUpdate ||
							!(serviceProviderCountryEditService.editCurrenciesFormState$ | async) ||
							!(serviceProviderCountryEditService.editCapabilitiesFormState$ | async) ||
							!(serviceProviderCountryEditService.editCostFormState$ | async)
						"
						(click)="updateProviderCountry()"
						color="dark"
					>
						Update
					</button>
				</div>
			</div>
		</mat-tab>
	</mat-tab-group>
</div>
