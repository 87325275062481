<div class="tpp-process-box" [ngClass]="{ 'fixed-tpp-process-box': fixedContainer }" fxLayout="row" fxLayoutGap="25px">
	<div
		class="filters-container"
		[formGroup]="form"
		fxFlex="70"
		fxLayoutAlign="start"
		fxLayout="row"
		fxLayoutGap="24px"
	>
		<button (click)="refreshPayouts()" id="refreshButton" class="refresh-button" mat-icon-button>
			<app-icon
				class="refresh-payouts-icon"
				[name]="'rotate-right'"
				[color]="'var(--color-text-default)'"
				[lineWidth]="'1.5px'"
				[size]="'28'"
			></app-icon>
		</button>
		<input-wrapper class="custom-wrapper">
			<wpay-multi-select-auto-complete
				formControlName="statusPayment"
				id="processStatus"
				label="All status"
				[dropDownType]="'statuses'"
				[options]="statusOptions"
				(multipleSelectOptionListClosed)="selectionChange()"
				(selectionCleared)="selectAllStatusses()"
				[defaultValue]="defualtStatusFilterValue.length ? defualtStatusFilterValue : allSelectableValues"
				class="select-input"
			>
			</wpay-multi-select-auto-complete>
		</input-wrapper>

		<input-wrapper class="custom-wrapper" fxFlex="30">
			<input-select
				id="cancelledRuns"
				formControlName="selectedRun"
				[options]="runSelection"
				[value]="runSelection[0].value"
				class="select-input"
			>
			</input-select>
		</input-wrapper>
	</div>

	<div class="button-container" *ngIf="buttonState === 'process' || buttonState === 'inactive'" fxLayoutAlign="end">
		<ng-template
			[appUserRolePermissions]
			[action]="'hide'"
			[roles]="['TS_IMPLEMENTATION_LEAD', 'TS_OPERATION_CONSULTANT', 'TS_VO', 'CLIENT']"
		>
			<button
				mat-raised-button
				disabled="{{ buttonState === 'inactive' }}"
				*ngIf="canProcessBooking"
				class="process-button"
				(click)="openDialog('book the payments')"
				id="processBtn"
			>
				<span>Process Booking</span>
			</button>
		</ng-template>
	</div>

	<div class="button-container" *ngIf="buttonState === 'reprocess'" fxLayoutAlign="end">
		<ng-template
			[appUserRolePermissions]
			[action]="'hide'"
			[roles]="[
				'TS_IMPLEMENTATION_LEAD',
				'TS_IMPLEMENTATION_CONSULTANT',
				'TS_OPERATION_CONSULTANT',
				'TS_OCB',
				'TS_VO',
				'CLIENT'
			]"
		>
			<button
				mat-raised-button
				class="process-button"
				[disabled]="payoutIdList.length < 1"
				(click)="openDialog('reprocess the payments')"
				id="processBtn"
			>
				<span>Reprocess Booking</span>
			</button>
		</ng-template>
	</div>

	<div class="button-container" *ngIf="buttonState === 'cancel'" fxLayoutAlign="end">
		<ng-template [appUserRolePermissions] [action]="'hide'" [roles]="['TS_IMPLEMENTATION_LEAD', 'TS_VO', 'CLIENT']">
			<div class="button-container" fxLayoutAlign="end">
				<button mat-raised-button class="process-button" (click)="cancelBookingClicked.emit()" id="processBtn">
					<span>CANCEL BOOKING</span>
				</button>
			</div>
		</ng-template>
	</div>
</div>
