import { animate, keyframes, state, style, transition, trigger } from "@angular/animations";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { PayGroup } from "src/app/shared/models/pay-groups";
import { Employee } from "../../../../../shared/models/employee.interface";
import { getCustomerEntityGroupState } from "../../../../../store";
import { AppState } from "../../../../../store/models/state.model";
import { PayElectiveBankAccountsService } from "../../../services/pay-elective-bank-accounts.service";
import { Method } from "@modules/employee-data/types/accounts";
import { DropDownDataExpanded } from "@shared/models/breadcrumbs.interface";
import { FormatStringPipe } from "src/app/shared/pipes/common/format-string.pipe";

@Component({
	selector: "app-pay-elective-list-item",
	templateUrl: "./pay-elective-list-item.component.html",
	styleUrls: ["./pay-elective-list-item.component.scss"]
})
export class PayElectiveListItemComponent implements OnInit, OnDestroy {
	@Input() set payElectiveValue(val: Employee) {
		this.payElective = val;
	}

	@Input() set providerForPayElective(val: string) {
		this.provider = val;
	}

	@Input() set employeesDropDownData(val: DropDownDataExpanded[]) {
		this._employeesDropDownData = val;
	}

	@Input() set isFromGlobalDashboard(val: boolean) {
		this._isFromGlobalDashboard = val;
	}

	@Input() zIndex = 0;

	provider: string = "";
	selectedCustomerId: string = "";
	selectedLegalEntityId: string = "";
	selectedPayGroupId: string = "";
	payElective!: Employee;

	payGroup$: Observable<PayGroup> = new Observable<PayGroup>();
	destroy$: Subject<void> = new Subject();

	showAccounts: boolean = false;

	//State
	customerName: string = "";
	legalName: string = "";
	country: string = "";

	private _isFromGlobalDashboard = false;
	private _employeesDropDownData!: DropDownDataExpanded[];

	constructor(private router: Router, private store: Store<AppState>) {}

	ngOnInit(): void {
		this.store.pipe(takeUntil(this.destroy$), select(getCustomerEntityGroupState)).subscribe(state => {
			this.selectedCustomerId = state.customerId;
			this.selectedLegalEntityId = state.legalEntityId;
			this.selectedPayGroupId = state.payGroupId;
		});
	}

	updatePaymethodData(updatedMethods: Method[]) {
		this.payElective.payMethods = updatedMethods;
	}

	countFailedBeneficaries(): number {
		if (this.provider && this.provider.length) {
			return this.provider && this.provider != "all"
				? this.payElective.payMethods?.filter(
						method =>
							method.providersStatuses[this.provider]?.name === "INACTIVE" &&
							method.providersStatuses.hasOwnProperty(this.provider) &&
							method.status.name === "ACTIVE"
				  ).length!
				: 0;
		} else {
			return 0;
		}
	}

	checkHasAccounts(employee: Employee): boolean {
		return !!employee.payMethods?.some(
			method => method.type.toString() === "BANK" && method?.status.name.toString() === "ACTIVE"
		);
	}

	checkHasCards(employee: Employee): boolean {
		return !!employee.payMethods?.some(
			method => method?.type.toString() === "CARD" && method?.status.name.toString() === "ACTIVE"
		);
	}

	checkHasPayMethods(employee: Employee): boolean {
		return employee.payMethods
			? employee.payMethods?.filter(method => method.status.name === "ACTIVE").length > 0
			: false;
	}

	toggle(employee: Employee) {
		if (this.checkHasPayMethods(employee)) {
			this.showAccounts = !this.showAccounts;

			//Needs to fecth latest pM
		}
	}

	showDot(): boolean {
		return this.countFailedBeneficaries() > 0 ? false : true;
	}

	showStatus(): string {
		const failedBeneficiariesCount = this.countFailedBeneficaries();

		if (failedBeneficiariesCount > 0) {
			return failedBeneficiariesCount > 1
				? `${failedBeneficiariesCount} failed beneficiaries`
				: `${failedBeneficiariesCount} failed beneficiary`;
		}

		if (
			failedBeneficiariesCount === 0 &&
			(this.payElective.status === "INACTIVE" || this.payElective.status === "CREATED")
		) {
			return "";
		}

		return this.payElective.status;
	}

	selectElective(): void {
		let route = "employee-data";

		if (this.router.url === "/global-dashboard/master-data") route = "global-dashboard";

		this.router.navigate([`/${route}/employee`], {
			state: {
				employee: this.payElective,
				customerName: this.customerName,
				employeesDropDownData: this._employeesDropDownData
			}
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
