<div fxLayout="row" class="role-area" [ngClass]="{ 'role-area-errors': hasClientConflictError }" (click)="showList()">
	<app-icon class="icon-style" size="20" name="user-square" color="#2E62FF"></app-icon>
	<div *ngIf="roles" fxLayout="row" class="role-container">
		<div
			*ngFor="let role of roles; index as i"
			class="role-item"
			[ngClass]="'colors-rol-' + role.value"
			fxLayout="row"
		>
			<span class="role-text">{{ role.text }}</span>
			<app-icon
				id="deleteRoleIcon"
				class="close-icon"
				(click)="deleteSelection(role, $event)"
				size="24"
				name="close"
				[color]="colorsIcons[i]"
			></app-icon>
		</div>
	</div>
</div>
<div class="input-errors" *ngIf="hasClientConflictError">
	Role Conflict: The role Client is exclusive and cannot coexist with other roles.
</div>
<mat-selection-list
	*ngIf="openList"
	[ngStyle.lg]="{ 'max-height': '260px' }"
	[ngStyle.sm]="{ 'max-height': '173px' }"
	class="role-list-container"
	[multiple]="false"
>
	<mat-list-option class="role-list-option" *ngFor="let option of options" (click)="addSelection(option)">{{
		option.text
	}}</mat-list-option>
</mat-selection-list>
