import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
	selector: "app-toggle-button",
	templateUrl: "./toggle-button.component.html",
	styleUrls: ["./toggle-button.component.scss"]
})
export class ToggleButtonComponent implements OnInit {
	@Output() toggled: EventEmitter<number> = new EventEmitter<number>();

	constructor() {}

	leftText: string = "";
	@Input() set leftOptionText(text: string) {
		this.leftText = text;
	}

	rightText: string = "";
	@Input() set rightOptionText(text: string) {
		this.rightText = text;
	}

	currentValue: boolean = false;
	@Input() set startWith(position: number) {
		this.currentValue = Boolean(position);
	}

	ngOnInit(): void {}

	toggle() {
		this.currentValue = !this.currentValue;
		this.toggled.emit(Number(this.currentValue));
	}
}
