import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CustomerSetupPage } from "./customer-setup.page";
import { FlexLayoutModule } from "ngx-flexible-layout";
import { MatCardModule } from "@angular/material/card";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { ExpansionPanelModule } from "@shared/components/expansion-panel/expansion-panel.module";
import { FormsSharedModule } from "@shared/modules/forms-shared.module";
import { CustomerSetupRoutingModule } from "./customer-setup-routing.module";
import { CustomerCreationComponent } from "./_components/customer-creation/customer-creation.component";
import { CustomerEditionComponent } from "./_components/customer-edition/customer-edition.component";
import { DeleteDialogModule } from "@shared/components/delete-dialog/delete-dialog.module";
import { PaginationModule } from "@shared/components/pagination/pagination.module";
import { NoItemsLayoutModule } from "@shared/components/no-items-layout/no-items-layout.module";
import { MatPaginatorModule } from "@angular/material/paginator";
import { ContactsModule } from "@shared/components/contacts/contacts.module";
import { ModalWrapperModule } from "@shared/components/modal-wrapper/modal-wrapper.module";
import { CustomerListModule } from "@shared/components/customer-list/customer-list.module";
import { SharedModule } from "@shared/shared.module";
import { DashboardPageModule } from "../config/dashboard.module";
import { BreadcrumbsModule } from "@shared/components/breadcrumbs/breadcrumbs.module";

@NgModule({
	declarations: [CustomerSetupPage, CustomerCreationComponent, CustomerEditionComponent],
	imports: [
		CommonModule,
		CustomerSetupRoutingModule,
		FormsSharedModule,
		FlexLayoutModule,
		MatCardModule,
		MatIconModule,
		MatButtonModule,
		ExpansionPanelModule,
		DeleteDialogModule,
		PaginationModule,
		NoItemsLayoutModule,
		MatPaginatorModule,
		ContactsModule,
		ModalWrapperModule,
		CustomerListModule,
		SharedModule,
		DashboardPageModule,
		BreadcrumbsModule
	]
})
export class CustomerSetupPageModule {}
