import { Action } from "@ngrx/store";

export enum ProviderActionType {
	SET_SELECTED = "[ProviderSelected] Set Selected"
}
export class UpdateProvider implements Action {
	readonly type = ProviderActionType.SET_SELECTED;

	//add an optional payload
	constructor(public payload: { provider: string }) {}
}
export type ProviderSetAction = UpdateProvider;
