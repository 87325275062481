import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { environment } from "src/environments/environment";
import { TtpServiceDefintionClientMapper } from "./mapper/TtpServiceDefinitionClientMapper";
import { ApiSearchTPPServiceDefinitionsRequestV1 } from "./model/request/ApiSearchTppServiceDefinitionsRequest";
import { ApiTPPServiseDefinitionsSearchResponseV1 } from "./model/response/ApiTppServiceDefinitionsSearchResponseV1";

@Injectable({
	providedIn: "root"
})
export class TtpServiceDefinitionClientService {
	constructor(private readonly http: HttpClient) {}

	search(request: ApiSearchTPPServiceDefinitionsRequestV1): Observable<ApiTPPServiseDefinitionsSearchResponseV1> {
		const params = TtpServiceDefintionClientMapper.mapSearchRequest(request);

		return this.http.get<ApiTPPServiseDefinitionsSearchResponseV1>(
			`${environment.apiUri}/v1/tpp-service-definitions`,
			{ params: params }
		);
	}
}
