<div class="header" fxLayoutAlign="center center" fxLayoutGap="5px">
	<app-icon [name]="'danger'" [size]="'32px'" [color]="'white'" fxFlex="5" (click)="onCancel()"></app-icon>
	<h3 class="dialog-text">{{ "Warning!" | uppercase }}</h3>
</div>

<div class="content">
	<div class="warning-text-container" mat-dialog-content fxLayoutAlign="center center">
		<span class="warning-text">
			Are you sure you want to <br />
			inactivate this {{ type }} ?
		</span>
	</div>

	<mat-divider></mat-divider>

	<mat-dialog-actions class="actions" fxLayout="row" mat-dialog-actions fxLayoutAlign="end none">
		<div class="actions-container">
			<button class="dialog-action-button" action-buttons mat-stroked-button (click)="onCancel()">
				No, Thanks
			</button>
			<button
				class="dialog-action-button"
				action-buttons
				color="dark"
				mat-flat-button
				(click)="onConfirm()"
				cdkFocusInitial
			>
				Yes
			</button>
		</div>
	</mat-dialog-actions>
</div>
