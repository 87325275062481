import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
	selector: "expansion-panel-section",
	templateUrl: "./expansion-panel-section.component.html",
	styleUrls: ["./expansion-panel-section.component.scss"],
	host: {
		"[class.lines]": "lines"
	}
})
export class ExpansionPanelSectionComponent implements OnInit {
	// Inputs
	@Input() lines: boolean = true;
	@Input() addContactButton: boolean = false;
	@Input() addAccountFieldButton: boolean = false;

	// Optional Inputs
	@Input() title?: string;
	@Input() addContactButtonDisabled?: boolean;
	@Input() addAccountFieldButtonDisabled?: boolean;

	// Outputs
	@Output() addContactClicked: EventEmitter<any> = new EventEmitter<any>();
	@Output() addAccountFieldClicked: EventEmitter<any> = new EventEmitter<any>();

	constructor() {}

	ngOnInit(): void {}

	addContact() {
		this.addContactClicked.emit();
	}

	addAccountField() {
		this.addAccountFieldClicked.emit();
	}
}
