import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { EmployeeDataComponent } from "./employee-data.component";
import { PayElectivePayGroupsComponent } from "./_components/pay-elective-pay-groups/pay-elective-pay-groups.component";
import { PayElectiveMasterDataComponent } from "./_components/pay-elective-master-data/pay-elective-master-data.component";
import { PayElectiveComponent } from "./_components/pay-elective/pay-elective.component";
import { FileManagerComponent } from "@modules/file-management/_components/file-manager/file-manager.component";

const routes: Routes = [
	{
		path: "",
		component: EmployeeDataComponent
	},
	{
		path: "paygroups",
		component: PayElectivePayGroupsComponent
	},
	{
		path: "master-data",
		component: PayElectiveMasterDataComponent
	},
	{
		path: "employee",
		component: PayElectiveComponent
	},
	{
		path: "master-data-history",
		component: FileManagerComponent
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class EmployeeDataRoutingModule {}
