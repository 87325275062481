<sliding-panel class="sliding-panel-payment-calendar" [exit]="exit">
	<div class="top-section">
		<!-- Header -->
		<div class="header">
			<div class="title-section">
				<div
					class="dot"
					[ngClass]="{
						tpp1: milestoneGroup.group === 'TPP1',
						tpp2: milestoneGroup.group === 'TPP2',
						tpp3: milestoneGroup.group === 'TPP3',
						tpp4: milestoneGroup.group === 'TPP4',
						net: milestoneGroup.group === 'NETS'
					}"
				></div>
				<div class="text">
					<span *ngIf="milestoneGroup.type === 'TPP'">GROUP</span> {{ milestoneGroup.group }}
				</div>
			</div>

			<div class="cta-container">
				<div class="reset-button" id="resetButton" (click)="resetAll()">Reset all</div>
				<div class="close-button" id="closeButton" (click)="cancel(false)">
					<app-icon
						[name]="'close'"
						[color]="'var(--color-text-default)'"
						[size]="'45'"
						[lineWidth]="'1px'"
					></app-icon>
				</div>
			</div>
		</div>

		<app-legal-entity-timezone-disclaimer
			[milestoneDate]="_date"
			[payGroup]="payGroup"
		></app-legal-entity-timezone-disclaimer>

		<!-- Form -->
		<div class="form-container">
			<!-- Title -->
			<div class="title-form">Set a date</div>

			<!-- Inputs -->
			<div class="inputs-container o-grid-2 full-width" *ngIf="eventForm" [formGroup]="eventForm">
				<!-- Pay date offset -->
				<input-wrapper label="Pay date">
					<div id="displayDateWrapper" fxLayout="row">
						<app-icon
							class="icon-payments-creation"
							[name]="'calendar_month'"
							[color]="'var(--color-primary-1000)'"
						></app-icon>
						<input id="displayDate" type="text" formControlName="displayDate" />
					</div>
				</input-wrapper>

				<!-- Event time -->
				<input-wrapper label="Event time">
					<div class="div-time-container" fxLayout="row">
						<app-icon class="icon-time" [name]="'clock-1'" [color]="'var(--color-primary-1000)'"></app-icon>

						<input id="time" type="time" formControlName="time" placeholder="Event time" />
					</div>
					<div input-errors *ngIf="eventForm.controls.time.errors?.required">Event time is required</div>
				</input-wrapper>
			</div>
		</div>
	</div>

	<!-- CTA -->
	<div class="cta-container">
		<button id="cancelButton" class="cancel-button" action-buttons mat-stroked-button (click)="cancel(false)">
			Cancel
		</button>
		<button
			id="saveButton"
			class="save-button"
			action-buttons
			mat-flat-button
			color="dark"
			(click)="save()"
			[disabled]="eventForm ? eventForm.invalid : true"
		>
			Save
		</button>
	</div>
</sliding-panel>
