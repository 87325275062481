import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ToastComponent } from "./toast.component";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
	declarations: [ToastComponent],
	imports: [CommonModule, MatButtonModule, MatIconModule],
	exports: [ToastComponent]
})
export class ToastModule {}
