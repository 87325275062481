<div class="content-container">
	<!-- Definitions -->
	<div class="definition-container" *ngIf="hasPaymentTypes; else hasNotPaymentTypes">
		<div class="definition-item-container" *ngFor="let definitionItem of definitionsList; let i = index">
			<div class="header">
				<div class="left-side">
					<!-- Payment Type Name -->
					<span class="type-name" [ngClass]="{ disabled: !objectValidation }">
						{{ definitionItem.type | underscoreRemover }}
					</span>
				</div>
				<div class="right-side">
					<!-- Button -->

					<button
						*ngIf="canCreateServiceDefinition"
						id="{{ 'addNewCapabilityButton' + i }}"
						mat-button
						class="add-new-capability-btn c-button-dashed"
						color="blue-sky"
						(click)="addNewDefinition(i)"
						[disabled]="!objectValidation"
					>
						<div fxLayout="row">
							<app-icon class="add-plus-icon" [name]="'add'" [color]="'#2e62ff'"></app-icon>
							<span>Add new capability</span>
						</div>
					</button>
				</div>
			</div>

			<div class="content">
				<!-- Existing definitions list -->
				<ng-container
					#hasDefinitions
					*ngIf="definitionItem.definitions && definitionItem.definitions.length > 0; else noDefinitions"
				>
					<div
						class="definition-item"
						*ngFor="let definition of definitionItem.definitions; let defIndex = index"
					>
						<div class="definition-item-content">
							<!-- Type Selector -->
							<div class="selector-col">
								<input-wrapper label="Service Provider">
									<input-select
										id="{{ 'capabilityProviderInput' + defIndex }}"
										label="Provider"
										[value]="definition.provider.providerCountryId"
										[options]="definitionItem.availableProvidersOptions"
										(onSelectionChange)="selectProvider(i, defIndex, $event)"
										[_disabled]="
											!canCreateServiceDefinition ||
											(isDisabled && serviceDefExists && !this.isNewCapability(defIndex))
										"
									>
									</input-select>
								</input-wrapper>
							</div>

							<!-- Arrow separator -->
							<div class="utility-col">
								<app-icon [name]="'arrow-right'"></app-icon>
							</div>

							<!-- Account Selector -->
							<div class="selector-col">
								<input-wrapper label="Payout Account Type">
									<input-select
										id="{{ 'capabilityTypeInput' + defIndex }}"
										[ngClass]="{
											'input-errors':
												duplicatePayoutRoute.includes(i) &&
												duplicatePayoutAccount.includes(defIndex)
										}"
										label="Type"
										[value]="definition.payoutAccount"
										[_disabled]="
											!definition.provider.providerCountryId ||
											!canCreateServiceDefinition ||
											(isDisabled && serviceDefExists && !this.isNewCapability(defIndex))
										"
										[options]="
											definition.provider.providerCountryId
												? definitionItem.availableTypesOptions[
														definition.provider.providerCountryId
												  ]
												: [{ text: 'Hey', value: 'There' }]
										"
										(onSelectionChange)="selectType(i, defIndex, $event)"
									>
									</input-select>

									<div
										input-erros
										class="input-message-errors"
										*ngIf="
											duplicatePayoutRoute.includes(i) &&
											duplicatePayoutAccount.includes(defIndex)
										"
									>
										This combination of Payout Account and Route already exists. Please choose a
										different combination.
									</div>
								</input-wrapper>
							</div>

							<!-- Arrow separator -->
							<div class="utility-col">
								<app-icon [name]="'arrow-right'"></app-icon>
							</div>

							<!-- Route Selector -->
							<div class="selector-col">
								<input-wrapper label="Route">
									<input-select
										id="{{ 'capabilityRouteInput' + defIndex }}"
										[ngClass]="{
											'input-errors':
												duplicatePayoutRoute.includes(i) &&
												duplicatePayoutAccount.includes(defIndex)
										}"
										label="Route"
										[value]="definition.route"
										[_disabled]="
											!definition.payoutAccount ||
											!canCreateServiceDefinition ||
											(isDisabled && serviceDefExists && !this.isNewCapability(defIndex))
										"
										[options]="
											definition.payoutAccount
												? definitionItem.availableRoutesOptions[
														definition.provider.providerCountryId
												  ][definition.payoutAccount]
												: null
										"
										(onSelectionChange)="selectRoute(i, defIndex, $event)"
									>
									</input-select>

									<div
										input-erros
										class="input-message-errors"
										*ngIf="
											duplicatePayoutRoute.includes(i) &&
											duplicatePayoutAccount.includes(defIndex)
										"
									>
										This combination of Payout Account and Route already exists. Please choose a
										different combination.
									</div>
								</input-wrapper>
							</div>

							<!-- Delete Button -->
							<div class="button-col">
								<button
									*ngIf="canDeleteServiceDefinition || isNewCapability(defIndex)"
									mat-button
									color="warn"
									id="deleteDefinitionBtn"
									(click)="deleteDefinition(definitionItem, definition, i, defIndex)"
								>
									<app-icon [name]="'trash'" [size]="'24'" [color]="'#f54336'"></app-icon>
								</button>
							</div>
						</div>
						<div
							fxLayout="row"
							class="info-row"
							fxLayoutAlign="flex-start center"
							*ngIf="definition.provider.providerCountryId || definitionItem.definitions.length == 1"
						>
							<mat-slide-toggle
								class="toggle-default"
								[ngClass]="{ 'toggle-checked': definition.default, opacity: isDisabledForViewOnly }"
								color="primary"
								[checked]="definition.default"
								[disabled]="
									definition.default ||
									(isDisabled && serviceDefExists && !this.isNewCapability(defIndex)) ||
									isDisabledForViewOnly
								"
								(change)="selectDefault(i, defIndex, definition)"
							>
								<div fxLayout="column">
									<span class="toggle-text">Default<br />route</span>
								</div>
							</mat-slide-toggle>
							<div
								*ngIf="definition.providerType === 'card'"
								class="badge-item badge-card"
								fxLayout="row"
								fxLayoutAlign=" center"
							>
								<app-icon class="card-icon" [size]="'16'" [name]="'card'" [lineWidth]="'2'"></app-icon>
								<div class="payment-text">CARD</div>
							</div>
							<div
								*ngIf="definition.providerType === 'bank'"
								class="badge-item badge-bank"
								fxLayout="row"
								fxLayoutAlign=" center"
							>
								<app-icon [size]="'19'" [name]="'bank-figma'" [lineWidth]="'2'"></app-icon>
								<div class="payment-text">BANK</div>
							</div>
						</div>
					</div>
				</ng-container>

				<!-- No existing definitions message -->
				<ng-template #noDefinitions>
					<div class="definition-item">
						<div class="definition-item-content no-item">
							No capabilities and service providers assignment
						</div>
					</div>
				</ng-template>
			</div>
		</div>
	</div>

	<!-- No definitions -->
	<ng-template #hasNotPaymentTypes>
		<div class="definition-container">
			<h3 class="no-payment-types-error">No payment types associated to country, Please create one</h3>
		</div>
	</ng-template>

	<div *ngIf="!legalEntityHasPayGroups">
		<div class="definition-container">
			<h3 class="no-payment-types-error">No Pay Groups associated to Legal Entity, Please create one</h3>
		</div>
	</div>

	<!-- CTA buttons -->
	<div class="bottom-button-container">
		<button
			id="continueButton"
			mat-raised-button
			color="dark"
			(click)="checkDefinitions()"
			[disabled]="shouldDisableButton()"
		>
			Continue
		</button>
	</div>
</div>
