import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BreadcrumbsRoutingModule } from "./breadcrumbs-routing.module";
import { BreadcrumbsComponent } from "./containers/breadcrumbs.component";
import { IconModule } from "../icon/icon.module";
import { MatMenuModule } from "@angular/material/menu";
import { MatDividerModule } from "@angular/material/divider";
import { InputWrapperModule } from "../input-wrapper/input-wrapper.module";
import { MatFormFieldModule } from "@angular/material/form-field";
import { ReactiveFormsModule } from "@angular/forms";
import { BreadcrumbsModalModule } from "./components/breadcrumbs-modal/breadcrumbs-modal.module";
import { MatTooltipModule } from "@angular/material/tooltip";
import { SharedModule } from "@shared/shared.module";

@NgModule({
	declarations: [BreadcrumbsComponent],
	exports: [BreadcrumbsComponent],
	imports: [
		CommonModule,
		SharedModule,
		BreadcrumbsRoutingModule,
		IconModule,
		MatMenuModule,
		MatDividerModule,
		InputWrapperModule,
		MatFormFieldModule,
		ReactiveFormsModule,
		BreadcrumbsModalModule,
		MatTooltipModule
	]
})
export class BreadcrumbsModule {}
