<mat-form-field
	(click)="openDropDown()"
	class="multi-select-autocomplete"
	appearance="fill"
	[ngClass]="{
		'no-label': !label,
		'label-disappears': labelDisappears,
		'has-image': control.value?.imagePath,
		dropped: panelOpen
	}"
>
	<!-- Label -->
	<mat-label
		class="placeholder multiple-input"
		*ngIf="label && labelDisappears && (control.value?.length === 0 || control.value === null) && !indicateLoading"
		>{{ label }} {{ control.value?.text }}</mat-label
	>

	<mat-label class="placeholder loading multiple-input" *ngIf="indicateLoading">{{ showLoadingTest }}</mat-label>

	<mat-label class="placeholder" *ngIf="label && !labelDisappears && !indicateLoading">{{ label }}</mat-label>

	<!-- Icon image -->
	<div class="icon" *ngIf="control.value?.imagePath"><img [src]="control.value?.imagePath" /></div>

	<!-- Icon element status-->
	<div
		*ngIf="control.value?.value === 'active' || control.value?.value === 'inactive'"
		class="status-circle"
		[ngClass]="{
			'status-circle--active': control.value?.value === 'active',
			'status-circle--inactive': control.value?.value === 'inactive'
		}"
	></div>

	<ng-container [formGroup]="searchForm">
		<div class="input-cantianer" #optionContainer id="{{ dropDownType }}-input-value"></div>

		<!-- Input type ahead -->
		<input
			[ngStyle]="{ display: 'none' }"
			class="type-ahead"
			type="text"
			autocomplete="on"
			matInput
			[formControl]="control"
			[matAutocomplete]="auto"
			(input)="handleEmptyInput($event)"
		/>
		<!-- Clear icon -->
		<div
			class="clear-icon-container"
			*ngIf="control.value && control.value.length > 0"
			id="{{ dropDownType }}-clear-icon"
		>
			<app-icon
				[name]="'close-circle-bold'"
				[fill]="'rgba(0, 0, 0, 0.54)'"
				[color]="'transaprent'"
				(click)="clearButtonClicked($event)"
			></app-icon>
		</div>

		<!-- Arrow down icon -->
		<app-icon
			id="multi-select-dropdown-btn"
			class="multi-select-drop-down-arrow"
			[color]="'rgba(0, 0, 0, 0.54)'"
			[lineWidth]="'2.5'"
			[name]="'arrow-down'"
			[size]="'16'"
			[ngClass]="{ 'up-arrow': panelOpen }"
		></app-icon>

		<mat-autocomplete
			#auto="matAutocomplete"
			class="global-dashboard-auto-complete-container"
			[displayWith]="inputAutocompleteService.displayMultipleFn"
			(opened)="onTouched($event)"
			(closed)="onClosed(true)"
		>
			<div class="option-search-container" fxLayout="row" fxLayoutAlign="start start">
				<app-icon matPrefix [name]="'search-normal-1'" [size]="'15'" class="search-icon" fxFlex="5"></app-icon>
				<input
					matInput
					[placeholder]="'Search by ' + dropDownType"
					formControlName="search"
					class="input"
					fxFlex="95"
					#searchInput
					(keydown.enter)="openDropDown(false)"
				/>
			</div>

			<div *ngIf="panelOpen" #scrollContainer class="scrollable-container" (scroll)="onScroll()">
				<!-- Options -->

				<mat-option
					*ngFor="let option of filteredOptions$ | async as options; let i = index; last as isLast"
					[value]="option"
					[disabled]="option.disabled"
					class="custom-option"
				>
					<!-- Without tooltip message -->

					<div class="option-container" #optionElement (click)="optionClicked($event, option)">
						<mat-checkbox
							class="checkbox"
							[checked]="option.selected === true"
							(change)="optionElement.click()"
							(click)="optionElement.click()"
							*ngIf="option.value !== 'noOptions'"
						></mat-checkbox>
						<!--<div class="icon" *ngIf="option.imagePath"><img src="{{ option.imagePath }}" /></div>-->
						<div
							*ngIf="option.value === 'active' || option.value === 'inactive'"
							class="status-circle"
							[ngClass]="{
								'status-circle--active': option.value === 'active',
								'status-circle--inactive': option.value === 'inactive'
							}"
						></div>
						{{ option.text }} <span *ngIf="option.externalId">&nbsp;- {{ option.externalId }}</span>
					</div>

					<mat-divider *ngIf="showDivider(option, options, i)" class="divide-selected-options"></mat-divider>
				</mat-option>
			</div>
		</mat-autocomplete>
	</ng-container>
</mat-form-field>
