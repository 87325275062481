import { Injectable } from "@angular/core";
import { forkJoin, Observable, of } from "rxjs";
import { delay, map, switchMap } from "rxjs/operators";
import { TppAccount } from "src/app/shared/models/tpp-account.interface";
import { ApiSearchTPPServiceDefinitionsRequestV1 } from "../../../api/payment-providers/tpp-service-definition/model/request/ApiSearchTppServiceDefinitionsRequest";
import { ApiTPPServiseDefinitionsSearchResponseV1 } from "../../../api/payment-providers/tpp-service-definition/model/response/ApiTppServiceDefinitionsSearchResponseV1";
import { TtpServiceDefinitionClientService } from "../../../api/payment-providers/tpp-service-definition/ttp-service-definition-client.service";
import { TppAccountsService } from "../../../tpp-accounts/tpp-accounts.service";
import {
	BeneficiaryAccountAggregate,
	BeneficiaryProviderAssignment,
	SubServiceAggregate,
	SubServiceSummaryAggregate,
	SubServiceSummaryByProviderAggregate,
	TppBeneficarysByPaygroupCountAggregate,
	TppServicesByByPayGroupAggregation,
	TtpServiceAggregate,
	TtpServiceByServiceTypeAggregate
} from "./model/TppServicesByByPayGroupAggregation";
import { TppServiceByServiceTypeMapperService } from "../mapper/tpp-service-by-service-type-mapper.service";
import {
	isBeneficiaryValid,
	isBenficiaryValidatable
} from "../../../../models/service-definition/TppServiceDefinitionViewModel";

@Injectable({
	providedIn: "root"
})
export class ServiceDefinitionAggregatorService {
	constructor(
		private client: TtpServiceDefinitionClientService,
		private ttpAccountsClient: TppAccountsService,
		private serviceByServiceTypeMapper: TppServiceByServiceTypeMapperService
	) {}

	fetchAndTransformToAggregation(payGroupId: string): Observable<TppServicesByByPayGroupAggregation> {
		// Backend API's are not normalized
		// multiple transformations are required to convert the response into a usable format.

		var serviceDefinitions$ = this.getServiceDefinitions(payGroupId);

		return serviceDefinitions$.pipe(
			switchMap((response: ApiTPPServiseDefinitionsSearchResponseV1) => {
				var servicesByServiceType = this.serviceByServiceTypeMapper.map(response);
				var accountsLookups$: Observable<TppAccount[]>[] =
					this.findAccountForTtpServices(servicesByServiceType);

				return forkJoin([
					forkJoin(accountsLookups$).pipe(delay(50)),
					of(servicesByServiceType).pipe(delay(100))
				]);
			}),
			map((streams: [TppAccount[][], TtpServiceByServiceTypeAggregate[]]) => {
				var ttpAccounts = streams[0];
				var servicesByServiceType = streams[1];

				// at this point the serviceByServiceType will only have beneficiaryIds
				// hydrate them from the ttpAccounts
				this.assignBeneficiaryAccountsToServiceByType(ttpAccounts, servicesByServiceType);

				// given detailed beneficiary data exists calculate the subServiceSummary
				var beneficarySummary = this.addSubServiceSummaryToServiceByServiceType(servicesByServiceType);

				var serviceDefinitionAggregation = {
					beneficiaryCountForPayGroup: beneficarySummary,
					servicesByServiceType: servicesByServiceType
				} as TppServicesByByPayGroupAggregation;

				return serviceDefinitionAggregation;
			})
		);
	}

	private getServiceDefinitions(payGroupId: string): Observable<ApiTPPServiseDefinitionsSearchResponseV1> {
		var request = {
			payGroupIds: payGroupId,
			pageNumber: 0,
			pageSize: -1 // -1 means "all"
		} as ApiSearchTPPServiceDefinitionsRequestV1;

		return this.client.search(request);
	}

	private findAccountForTtpServices(services: TtpServiceByServiceTypeAggregate[]): Observable<TppAccount[]>[] {
		var delayMs = 0;
		var accountsLookups$: Observable<TppAccount[]>[] = [];
		services.forEach((service: TtpServiceByServiceTypeAggregate) => {
			service.services.forEach((ttpService: TtpServiceAggregate) => {
				delayMs = delayMs + 100;

				accountsLookups$.push(
					this.ttpAccountsClient.searchTPPAccountsByTppServiceId(ttpService.id, 0, -1).pipe(delay(delayMs))
				);
			});
		});

		return accountsLookups$;
	}

	private assignBeneficiaryAccountsToServiceByType(
		ttpAccounts: TppAccount[][],
		serviceByServiceType: TtpServiceByServiceTypeAggregate[]
	) {
		serviceByServiceType.forEach((serviceType: TtpServiceByServiceTypeAggregate) => {
			serviceType.services.forEach((ttpService: TtpServiceAggregate) => {
				ttpService.subService.forEach((subService: SubServiceAggregate) => {
					var res = this.findAccountByNameAndIdInAccounts(subService.beneficiary.id, ttpAccounts);
					subService.beneficiary.name = res.name;
				});
			});
		});
	}

	private findAccountByNameAndIdInAccounts(id: string, ttpAccounts: TppAccount[][]): BeneficiaryAccountAggregate {
		var beneficiaryAccount: BeneficiaryAccountAggregate = {} as BeneficiaryAccountAggregate;

		ttpAccounts.forEach((accounts: TppAccount[]) => {
			accounts.forEach((account: TppAccount) => {
				if (account.id == id) {
					beneficiaryAccount = {
						id: account.id,
						name: account.name
					} as BeneficiaryAccountAggregate;
				}
			});
		});

		return beneficiaryAccount;
	}

	private addSubServiceSummaryToServiceByServiceType(
		serviceByServiceType: TtpServiceByServiceTypeAggregate[]
	): TppBeneficarysByPaygroupCountAggregate {
		var allSummarys = new Array<Map<string, SubServiceSummaryByProviderAggregate>>();

		var allSubServiceSummary = [] as SubServiceSummaryAggregate[];

		serviceByServiceType.forEach((service: TtpServiceByServiceTypeAggregate) => {
			service.services.forEach((ttpService: TtpServiceAggregate) => {
				var summary = this.calculateSubServiceSummary(ttpService.subService);

				var subServiceSummaryByProviderName = [] as SubServiceSummaryByProviderAggregate[];
				summary.forEach((subServiceSummary: SubServiceSummaryByProviderAggregate, providerName: string) => {
					subServiceSummary.providerName = providerName;
					subServiceSummaryByProviderName.push(subServiceSummary);
				});

				ttpService.subServiceSummaryByProviderName = subServiceSummaryByProviderName;

				allSummarys.push(summary);

				var totalSubServiceBeneficiaries = 0;
				var oneOrMoreSubServicesMissingConfiguration = false;
				var totalPartiallyConfiguredSubServices = 0;

				ttpService.subService.forEach((subService: SubServiceAggregate) => {
					if (subService.beneficiary.hasOwnProperty("id")) {
						totalSubServiceBeneficiaries++;
					}
					if (subService.isConfigured == false) {
						oneOrMoreSubServicesMissingConfiguration = true;
					}
					if (subService.isPartiallyConfigured == true) {
						totalPartiallyConfiguredSubServices++;
					}
				});

				var subServiceSummary = {
					totalSubServiceBeneficiaries: totalSubServiceBeneficiaries,
					oneOrMoreSubServicesMissingConfiguration: oneOrMoreSubServicesMissingConfiguration,
					totalPartiallyConfiguredSubServices: totalPartiallyConfiguredSubServices
				} as SubServiceSummaryAggregate;
				allSubServiceSummary.push(subServiceSummary);

				ttpService.subServiceSummaryAggregate = subServiceSummary;
			});
		});

		var totalSubServiceBeneficiaries = 0;
		var totalPartiallyConfiguredSubServices = 0;
		var oneOrMoreSubServicesMissingConfiguration = false;

		allSubServiceSummary.forEach((subServiceSummary: SubServiceSummaryAggregate) => {
			totalSubServiceBeneficiaries =
				totalSubServiceBeneficiaries + subServiceSummary.totalSubServiceBeneficiaries;
			totalPartiallyConfiguredSubServices =
				totalPartiallyConfiguredSubServices + subServiceSummary.totalPartiallyConfiguredSubServices;

			if (subServiceSummary.oneOrMoreSubServicesMissingConfiguration) {
				oneOrMoreSubServicesMissingConfiguration = true;
			}
		});

		return {
			totalBeneficiaries: totalSubServiceBeneficiaries,
			oneOrMoreSubServicesMissingConfiguration: oneOrMoreSubServicesMissingConfiguration
		} as TppBeneficarysByPaygroupCountAggregate;
	}

	private calculateSubServiceSummary(
		subServices: SubServiceAggregate[]
	): Map<string, SubServiceSummaryByProviderAggregate> {
		var subServiceSummaryByProvider = new Map<string, SubServiceSummaryByProviderAggregate>();

		var servicesByProvider = this.aggregateServicesByProvider(subServices);
		servicesByProvider.forEach((providerSubServices: SubServiceAggregate[], providerName: string) => {
			var calculator = new SubServiceBeneficiaryCalculator();
			var result = calculator.calculate(providerSubServices, providerName);

			subServiceSummaryByProvider.set(providerName, result);
		});

		return subServiceSummaryByProvider;
	}

	private aggregateServicesByProvider(subServices: SubServiceAggregate[]): Map<string, SubServiceAggregate[]> {
		var subServicesByProvider = new Map<string, SubServiceAggregate[]>();
		subServices.forEach((subService: SubServiceAggregate) => {
			if (subService.beneficiary.hasOwnProperty("providers") && subService.beneficiary.providers.length > 0) {
				subService.beneficiary.providers.forEach((provider: BeneficiaryProviderAssignment) => {
					if (subServicesByProvider.get(provider.providerName)) {
						var existingServices = subServicesByProvider.get(provider.providerName);
						if (existingServices != undefined) {
							existingServices.push(subService);
						}
					} else {
						subServicesByProvider.set(provider.providerName, [subService]);
					}
				});
			} else {
				if (subServicesByProvider.get("NO_PROVIDER_ASSIGNMENT")) {
					var existingServices = subServicesByProvider.get("NO_PROVIDER_ASSIGNMENT");
					if (existingServices != undefined) {
						existingServices.push(subService);
					}
				} else {
					subServicesByProvider.set("NO_PROVIDER_ASSIGNMENT", [subService]);
				}
			}
		});
		return subServicesByProvider;
	}
}

class SubServiceBeneficiaryCalculator {
	totalFailedBeneficiaries: number = 0;
	totalValidBeneficiaries: number = 0;
	totalProcessingBeneficiaries: number = 0;
	totalBeneficiarysWhichCanBeValidated: number = 0;

	calculate(providerSubServices: SubServiceAggregate[], providerName: string): SubServiceSummaryByProviderAggregate {
		providerSubServices.forEach((subService: SubServiceAggregate) => {
			if (subService.beneficiary.hasOwnProperty("providers") && subService.beneficiary.providers.length > 0) {
				subService.beneficiary.providers.forEach((provider: BeneficiaryProviderAssignment) => {
					if (provider.providerName == providerName) {
						if (provider.status == "CREATED") {
							this.totalProcessingBeneficiaries++;
						} else if (!isBeneficiaryValid(provider.status)) {
							this.totalFailedBeneficiaries++;
						} else if (isBeneficiaryValid(provider.status)) {
							this.totalValidBeneficiaries++;
						}

						if (isBenficiaryValidatable(provider.status)) {
							this.totalBeneficiarysWhichCanBeValidated++;
						}
					}
				});
			}
		});

		return {
			totalFailedBeneficiaries: this.totalFailedBeneficiaries,
			totalValidBeneficiaries: this.totalValidBeneficiaries,
			totalProcessingBeneficiaries: this.totalProcessingBeneficiaries,
			totalBeneficiariesWhichCanBeValidated: this.totalBeneficiarysWhichCanBeValidated
		} as SubServiceSummaryByProviderAggregate;
	}
}
