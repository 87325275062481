import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CalendarComponent } from "./calendar.component";
import { PipesModule } from "@shared/pipes/pipes.module";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { SharedModule } from "@shared/shared.module";
import { FlexLayoutModule } from "ngx-flexible-layout";
import { MatDividerModule } from "@angular/material/divider";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { TooltipModule } from "../tooltip/tooltip.module";
import { HoverObjectModule } from "../hover-object/hover-object.module";

@NgModule({
	declarations: [CalendarComponent],
	exports: [CalendarComponent],
	imports: [
		CommonModule,
		PipesModule,
		MatIconModule,
		MatButtonModule,
		SharedModule,
		FlexLayoutModule,
		MatDividerModule,
		MatMenuModule,
		MatProgressSpinnerModule,
		TooltipModule,
		HoverObjectModule
	]
})
export class CalendarModule {}
