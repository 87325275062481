import { Injectable } from "@angular/core";
import { compareAsc } from "date-fns";
import { Observable, Subject } from "rxjs";
import { CalendarDay } from "@shared/components/calendar/_models/calendar-day.model";
import { MONTH_NAMES } from "src/app/shared/constants/months";
import { PaycycleWeek } from "src/app/shared/models/calendar-navigation";
import { Milestone, PayCycle } from "src/app/shared/models/pay-cycle.interface";
import { PayCycleService } from "@shared/services/pay-cycle/pay-cycle.service";

@Injectable({
	providedIn: "root"
})
export class CalendarService {
	tempMonthName: Record<string, string>[] = MONTH_NAMES;
	organisedMonthNames: string[] = [];
	middle: number = 0;
	private setMilestone$ = new Subject<Milestone[]>();

	constructor(private payCycleService: PayCycleService) {}

	generateCalendarDays(selectedDate: Date) {
		const calendar: Array<CalendarDay> = [];

		const startingDateOfCalendar = this.getStartDateForCalendar(selectedDate);
		const endingDateOfCalendar = this.getEndDateForCalendar(selectedDate);
		// dateToAdd is an intermediate variable that will get increased in the following for loop
		let dateToAdd = startingDateOfCalendar;

		for (var i = 0; i < endingDateOfCalendar.getDate(); i++) {
			calendar.push(new CalendarDay(new Date(dateToAdd)));
			dateToAdd = new Date(dateToAdd.setDate(dateToAdd.getDate() + 1));
		}

		return calendar;
	}

	organizeMonths(date: Date): string[] {
		for (let n = 0; n < 2; n++) {
			for (let i = 0; i < 12; i++) {
				this.tempMonthName.push(MONTH_NAMES[i]);
			}
		}

		this.organisedMonthNames = [];
		let divSize = 9;
		this.middle = divSize / 2 - (divSize % 2) / 2;

		let monthCount = date.getMonth();
		let monthName = this.tempMonthName[date.getMonth()].full;

		this.organisedMonthNames[this.middle] = monthName;

		for (let i = this.middle - 1; i >= 0; i--) {
			let n = monthCount + 12;
			this.organisedMonthNames[i] = this.tempMonthName[n - (this.middle - i)].full;
		}

		for (let i = this.middle + 1; i < divSize; i++) {
			let n = monthCount + 12;
			this.organisedMonthNames[i] = this.tempMonthName[n + (i - this.middle)].full;
		}

		this.middle = 0;

		return this.organisedMonthNames;
	}

	getNavigationMenuYears() {
		var max = new Date().getFullYear() + 5;
		var min = max - 9;
		var years: Array<number> = [];

		for (let i = max; i >= min; i--) {
			years.push(i);
		}
		return years.reverse();
	}

	private getStartDateForCalendar(selectedDate: Date) {
		const date = new Date(selectedDate);
		return new Date(date.getFullYear(), date.getMonth(), 1);
	}

	private getEndDateForCalendar(selectedDate: Date) {
		const date = new Date(selectedDate);
		return new Date(new Date(date.getFullYear(), date.getMonth() + 1, 0));
	}

	organizeWeeks(selectedPayCycle: PayCycle, payCycles: PayCycle[]): PaycycleWeek[] {
		let payCycleWeeks: PaycycleWeek[] = [];

		// const regularPayCycles = payCycles.filter(x => {
		// 	return x.type === "REGULAR";
		// });

		// let index = regularPayCycles.findIndex(x => {
		// 	return x.id === selectedPayCycle.id;
		// });

		let index = payCycles.findIndex(x => {
			return x.id === selectedPayCycle.id;
		});

		payCycleWeeks.push({
			label: createLabel(selectedPayCycle.start, selectedPayCycle.end),
			startDate: selectedPayCycle.start,
			endDate: selectedPayCycle.end,
			type: selectedPayCycle.type,
			id: selectedPayCycle.id
		});

		// use string literals

		function createLabel(startDate: string, endDate: string): string {
			let label: string = "";

			let isCrossWeek = false;

			if (new Date(startDate).getMonth() !== new Date(endDate).getMonth()) {
				isCrossWeek = true;
			}

			if (isCrossWeek) {
				label = `${MONTH_NAMES[new Date(startDate).getMonth()].short} ${new Date(startDate).getDate()} - ${
					MONTH_NAMES[new Date(endDate).getMonth()].short
				} ${new Date(endDate).getDate()}`;
			} else {
				label = `${MONTH_NAMES[new Date(startDate).getMonth()].short} ${new Date(
					startDate
				).getDate()} -  ${new Date(endDate).getDate()}`;
			}

			return label;
		}

		// check if we have weeks before selected
		for (let i = 1; i < 5; i++) {
			if (payCycles[index - i]) {
				payCycleWeeks.unshift({
					label: createLabel(payCycles[index - i].start, payCycles[index - i].end),
					startDate: payCycles[index - i].start,
					endDate: payCycles[index - i].end,
					type: payCycles[index - i].type,
					id: payCycles[index - i].id
				});
			}
		}
		// check if we have weeks after selected

		for (let i = 1; i < 5; i++) {
			if (payCycles[index + i]) {
				payCycleWeeks.push({
					label: createLabel(payCycles[index + i].start, payCycles[index + i].end),
					startDate: payCycles[index + i].start,
					endDate: payCycles[index + i].end,
					type: payCycles[index + i].type,
					id: payCycles[index + i].id
				});
			}
		}

		return payCycleWeeks;
	}

	setMilestones(data: Milestone[]) {
		this.setMilestone$.next(data);
	}

	getMilestoneObservable(): Observable<any> {
		return this.setMilestone$.asObservable();
	}

	getResetPayCycleIndex(orderedPayCycles: PayCycle[], payCycleSelected: PayCycle): number {
		let index = 0;

		//if not reset to where needs to be
		orderedPayCycles.filter((item, i) => {
			// Compare the two dates and return 1 if the first date is after the second, -1 if the first date is before the second or 0 if dates are equal.

			if (compareAsc(new Date(item.start).getFullYear(), new Date().getFullYear()) === 1 && !payCycleSelected) {
				//first cycle is only in the next year
				index = 0;
			} else if (compareAsc(new Date(item.start).getMonth(), new Date().getMonth()) === 0 && !payCycleSelected) {
				//there are cycles before today but we should start with the current month
				index = i;
			} else if (
				// first cycle starts after today but in the same year
				compareAsc(new Date(item.start).getMonth(), new Date().getMonth()) === 1 &&
				!payCycleSelected
			) {
				index = 0;
			}
		});

		return index;
	}
}
