<div
	[matTooltip]="options.text"
	[matTooltipDisabled]="doesNameFitContainer()"
	[matTooltipPosition]="matToolTipPosition"
	class="truncated"
	[style.width]="options.width"
	[ngStyle]="{ 'max-width': options.maxWidth ? options.maxWidth : options.width }"
	#truncateContainer
	fxFlex
>
	{{ options.text }}
</div>
