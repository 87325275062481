import { Injectable } from "@angular/core";
import { User } from "@auth0/auth0-angular";
import { UserService } from "@modules/user-management/_services/user.service";
import { BehaviorSubject, Observable, catchError, mergeMap, of, switchMap, take } from "rxjs";
import { MilestoneEventHistoryLogData, TransactionHistoryLogData } from "../models/history-log-interface";
import { Milestone } from "@shared/models/pay-cycle.interface";
import { environment } from "@environments/environment";
import { HttpClient } from "@angular/common/http";
import { EventsTableDetailItem } from "@modules/global-dashboard/models/global-dashboard-interface";
import { TransactionReportWithPagination } from "@shared/models/payouts.types";

@Injectable({
	providedIn: "root"
})
export class HistoryLogSidePanelService {
	constructor(private userService: UserService, private http: HttpClient) {}

	initialData: MilestoneEventHistoryLogData = {
		milestoneId: "",
		milestoneType: "",
		customerExternalId: "",
		customerName: "",
		payGroupId: "",
		paygroupName: "",
		cycleName: "",
		group: ""
	};

	transactionHistoryInitialData: TransactionHistoryLogData = {
		paymethodId: "",
		payoutId: ""
	};

	milestoneEventHistoryLogSubject = new BehaviorSubject<MilestoneEventHistoryLogData>(this.initialData);
	transactionHistoryLogSubject = new BehaviorSubject<TransactionHistoryLogData>(this.transactionHistoryInitialData);

	setElementData(element: EventsTableDetailItem): void {
		const milestoneHistoryLogHeaderData = {
			milestoneId: element.milestoneDetails.id,
			milestoneType: element.milestoneDetails.type,
			customerExternalId: element.customerDetails.externalId,
			customerName: element.customerDetails.name,
			payGroupId: element.payGroupDetails.id,
			paygroupName: element.payGroupDetails.name,
			cycleName: element.payCycleDetails.name,
			group: element.group
		};
		this.milestoneEventHistoryLogSubject.next(milestoneHistoryLogHeaderData);
	}

	setTransactionData(payoutId: string, paymethodId: string): void {
		const transactionData = {
			payoutId: payoutId,
			paymethodId: paymethodId
		};

		this.transactionHistoryLogSubject.next(transactionData);
	}

	getSearchFilterTransformedKeys(
		userId: string,
		createdAt: string
	): Observable<{ user: string; ordinalDate: string; timeDate: string }> {
		return this.getUserNameFromId(userId).pipe(
			take(1),
			switchMap(user => {
				const createdAtOrdinal = this.getOrdinalDate(createdAt);
				const createdAtTime = this.getTimeFromDate(createdAt);

				return of({ user, ordinalDate: createdAtOrdinal, timeDate: createdAtTime });
			})
		);
	}

	getSearchFilterTransformedKeysTranscations(
		createdAt: string
	): Observable<{ ordinalDate: string; timeDate: string }> {
		const createdAtOrdinal = this.getOrdinalDate(createdAt);
		const createdAtTime = this.getTimeFromDate(createdAt);

		return of({ ordinalDate: createdAtOrdinal, timeDate: createdAtTime });
	}

	getOrdinalDate(value: string): string {
		const dateObject = new Date(value);
		const month = dateObject.toLocaleString("en-GB", { month: "long" });
		const day = dateObject.getDate();

		// Add ordinal suffix to the day
		const dayOrdinal = this.getOrdinal(day);

		// Format the date
		return `${month} ${dayOrdinal}`;
	}
	getOrdinal(n: number): string {
		const suffixes = ["th", "st", "nd", "rd"];
		const v = n % 100;
		return n + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
	}

	getTimeFromDate(value: string): string {
		const dateObject = new Date(value);
		const hours = dateObject.getHours();
		const minutes = dateObject.getMinutes();

		// Format the time
		return `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
	}

	getUserNameFromId(userId: string): Observable<string> {
		return this.userService.getUserById(userId).pipe(
			take(1),
			mergeMap((res: User | null) => (res ? this.fullNameFromId(res) : this.systemName())),
			catchError(() => this.systemName()) // Handle errors if necessary
		);
	}

	fullNameFromId(user: User): Observable<string> {
		const fullName = `${user.firstName} ${user.lastName}`;
		return of(fullName);
	}

	systemName(): Observable<string> {
		return of("System");
	}

	getMilestone(milestoneId: string): Observable<Milestone> {
		return this.http.get<Milestone>(`${environment.apiUri}/v1/calendars/milestones/${milestoneId}`);
	}

	getTransactionHistory(
		payoutId: string,
		paymethodId: string,
		rangeStart: string,
		rangeEnd: string
	): Observable<TransactionReportWithPagination> {
		return this.http.get<TransactionReportWithPagination>(
			`${environment.apiUri}/v1/transactions/report?rangeStart=${rangeStart}&rangeEnd=${rangeEnd}&payoutIds=${payoutId}&payMethodIds=${paymethodId}`
		);
	}

	resetElementData(): void {
		this.milestoneEventHistoryLogSubject.next(this.initialData);
		this.transactionHistoryLogSubject.next(this.transactionHistoryInitialData);
	}
}
