import { Action } from "@ngrx/store";
import { ClientSpecificSubService } from "src/app/shared/models/tpp-service.interface";

export enum tppGroupConfigStepOneSelectActionType {
	SET_SELECTED = "[TPPFormSubServiceSelected] Set Selected"
}
export class UpdateTppGroupConfigStepOneData implements Action {
	readonly type = tppGroupConfigStepOneSelectActionType.SET_SELECTED;

	//add an optional payload
	constructor(
		public payload: {
			tppGroupConfigStepOneData: {
				paymentCurrency: string;
				existingAccount: string;
				sourceAccountId: string;
				tppGroup: string;
			};
		}
	) {}
}
export type tppGroupConfigStepOneSelectAction = UpdateTppGroupConfigStepOneData;
