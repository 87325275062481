import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatStepper } from "@angular/material/stepper";
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";

import { ServiceProviderService } from "@shared/services/service-provider/service-provider.service";
import { ToastService } from "@shared/services/toast/toast.service";
import { LegalEntity } from "src/app/shared/models/legal-entity.interface";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import {
	Definitions,
	PaymentType,
	PaymentTypeCost,
	PaymentTypeDefinition,
	ProviderCountry
} from "src/app/shared/models/service-definition.interface";
import { getCustomerEntityGroupState } from "src/app/store";
import { AppState } from "src/app/store/models/state.model";
import { DefinitionsPOST } from "../../_models/definition-post.interface";
import { DefinitionsPUT } from "../../_models/definition-put.interface";
import { DefinitionsService } from "../../_services/definitions/definitions.service";
import { LegalEntityService } from "@shared/services/legal-entity/legal-entity.service";
import { PaygroupsService } from "@shared/services/paygroups/paygroups.service";
import { PermissionsService } from "@shared/services/permissions/permissions.service";

@Component({
	selector: "app-service-definition-entry",
	templateUrl: "./service-definition-entry.component.html",
	styleUrls: ["./service-definition-entry.component.scss"]
})
export class ServiceDefinitionEntryComponent implements OnInit, OnDestroy {
	public selectedCustomer!: SelectOption | null;
	public selectedCountry!: SelectOption | null;
	public selectedLegalEntity!: LegalEntity | null;
	public providerPaymentTypes!: PaymentType[];
	public serviceProviders!: ProviderCountry[];

	public definitions!: Definitions | null;
	public paymentTypeDefinitions!: PaymentTypeDefinition[];
	public paymentTypeCosts!: PaymentTypeCost[];
	public isOriginalObject: boolean = true;

	// Steps state
	public stepCounter = 1;
	public stepOneComplete = false;
	public stepTwoComplete = false;
	public stepThreeComplete = false;

	public firstActive: string = "Service capabilities and providers";
	public secondActive: string = "";
	public thirdActive: string = "";
	public fourthActive: string = "";

	public refreshDefinitions: boolean = false;

	public hideBread: boolean = false;

	showEntityList: boolean = false;
	value!: string;

	reloadingStepper: boolean = false;

	legalEntityHasPayGroups: boolean = true;
	canEditSettlementAccounts: boolean = false;

	private destroy$: Subject<void> = new Subject<void>();
	@ViewChild("stepper") stepper!: MatStepper;

	constructor(
		private changeDetector: ChangeDetectorRef,
		private serviceProviderService: ServiceProviderService,
		private toastService: ToastService,
		private definitionsService: DefinitionsService,
		private router: Router,
		private store: Store<AppState>,
		private legalEntityService: LegalEntityService,
		private payGroupsService: PaygroupsService,
		private permissions: PermissionsService
	) {}

	ngOnInit() {
		this.permissions
			.canEditSettlementAccounts()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => (this.canEditSettlementAccounts = res));

		this.store.pipe(takeUntil(this.destroy$), select(getCustomerEntityGroupState)).subscribe(state => {
			this.selectedCustomer = {
				value: state.customerId,
				text: state.customerName!
			};
			this.changeDetector.detectChanges();

			this.selectedCountry = {
				text: "",
				value: ""
			};

			if (state.legalEntityId === "") {
				this.selectedLegalEntity = null;
			}

			if (state.legalEntityId !== "") {
				this.setSelectedLegalEntity(state.legalEntityId);
			}
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	setSelectedLegalEntity(id: string) {
		this.reloadingStepper = true;
		this.legalEntityService
			.getLegalEntityById(id)
			.pipe(take(1))
			.subscribe({
				next: entity => {
					this.selectedLegalEntity = entity;
					this.reloadingStepper = false;
					this.changeDetector.detectChanges();
				}
			});
	}

	hasCustomers(event: boolean) {
		if (event) {
			this.showEntityList = true;
		}
	}

	setPath(data: { legalEntity: LegalEntity; customer: SelectOption; country: SelectOption }) {
		this.selectedLegalEntity = data.legalEntity;
		this.selectedCustomer = data.customer;
		this.selectedCountry = data.country;

		this.payGroupsService
			.getPaygroupWithLegalEntityIds([this.selectedLegalEntity.id])
			.pipe(take(1))
			.subscribe({
				next: res => {
					if (!res.items.length) {
						this.legalEntityHasPayGroups = false;
					} else {
						this.legalEntityHasPayGroups = true;
					}
				}
			});
	}

	setTypeDefinitions(definitions: Definitions) {
		this.definitions = null;
		setTimeout(() => {
			this.definitions = definitions;
		}, 100);
		this.paymentTypeDefinitions = definitions.paymentTypeDefinitions as any;
		this.stepOneComplete = true;
		this.changeDetector.detectChanges();
		this.nextStep();
	}

	removeTypeDefinitions() {
		this.paymentTypeDefinitions = [];
	}

	saveDefinitions() {
		if (this.definitions && this.definitions.id) {
			const definitions: DefinitionsPUT = this.definitionsService.formatPUT(this.definitions);

			this.serviceProviderService
				.updateDefinitions(definitions)
				.pipe(take(1))
				.subscribe({
					next: _ => {
						if (this.definitions) {
							this.definitions.version += 1;
							this.changeDetector.detectChanges();
							//this.nextStep();
						}
					},
					error: error => {}
				});
		} else if (this.definitions) {
			const definitions: DefinitionsPOST = this.definitionsService.formatPOST(this.definitions);

			this.serviceProviderService
				.createDefinition(definitions)
				.pipe(take(1))
				.subscribe({
					next: _ => {
						this.setDefinitionCreated();
					},
					error: (error: HttpErrorResponse) => {
						if (error.status === 200) {
							//	this.setDefinitionCreated();
						} else {
						}
					}
				});
		}
	}

	setDefinitionCreated() {
		if (this.definitions) {
			this.definitions.version = 1;
			setTimeout(() => {
				this.refreshDefinitions = !this.refreshDefinitions;
			}, 1);

			this.serviceProviderService
				.getDefinitionsByEntityId(this.selectedLegalEntity?.id as string)
				.pipe(take(1))
				.subscribe((definitions: Definitions) => {
					this.definitions = definitions;
				});

			this.nextStep();
		}
	}

	setPaymentTypes(paymentTypes: PaymentType[]) {
		this.providerPaymentTypes = [];
		this.providerPaymentTypes = paymentTypes;
	}

	setProviders(serviceProviders: ProviderCountry[]) {
		this.serviceProviders = [];
		this.serviceProviders = serviceProviders;
	}

	hideBreadCrumbs(val: boolean): void {
		this.hideBread = val;
	}

	nextStep() {
		this.stepper.next();
		this.counterInc();
	}

	prevStep() {
		this.stepper.previous();
		this.counterDec();
	}

	showSelector() {
		this.selectedLegalEntity = null;
	}

	goHome(): void {
		this.router.navigate(["/service-definition/net"]);
	}

	setOriginalObject(isOriginalObject: boolean) {
		this.isOriginalObject = isOriginalObject;
		this.changeDetector.detectChanges();
	}

	finishProcess() {
		let completedProcess = this.definitionsService.completedProcess;

		if (completedProcess !== "") {
			this.toastService.showSuccess(`Service Definition Successfully ${completedProcess}`);
			this.showSelector();
			this.definitionsService.completedProcess = "";
		} else {
			if (this.canEditSettlementAccounts) {
				this.toastService.showSuccess(`Service Definition Successfully Updated`);
			}

			this.showSelector();
		}
	}

	counterInc(): void {
		this.stepCounter++;

		this.setActiveTab();
	}

	counterDec(): void {
		this.stepCounter--;

		this.setActiveTab();
	}

	setActiveTab(): void {
		switch (this.stepCounter) {
			case 1: {
				this.firstActive = "Service capabilities and providers";
				this.secondActive = "";
				this.thirdActive = "";
				break;
			}
			case 2: {
				this.firstActive = "";
				this.secondActive = "Source of funds";
				this.thirdActive = "";
				break;
			}
			case 3: {
				this.firstActive = "";
				this.secondActive = "";
				this.thirdActive = "Settlement Account";
				break;
			}
			default: {
				this.firstActive = "";
				this.secondActive = "";
				this.thirdActive = "Settlement Account";
				break;
			}
		}
	}
}
