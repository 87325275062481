import { Pipe, PipeTransform } from "@angular/core";
import { MilestoneTypeName } from "../../models/pay-cycle.interface";

@Pipe({
	name: "milestoneTypeToIcon"
})
export class MilestoneTypeToIconPipe implements PipeTransform {
	transform(milestoneType: MilestoneTypeName): string {
		switch (
			milestoneType // init value of title: Net payment
		) {
			case "FUNDING_DATE":
				return "money-recive";
				break;
			case "G2N_FILE":
				return "document-copy";
				break;
			case "PAYMENT":
				return "wallet-add";
			case "NETS":
				return "wallet-add";
				break;
			case "DATA_INPUT":
				return "document-copy";
		}
		return "";
	}
}
