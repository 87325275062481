import { CurrencyPipe } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators, UntypedFormBuilder } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { map, take, takeUntil } from "rxjs/operators";
import { CurrencyService } from "@shared/services/currency/currency.service";
import { ToastService } from "@shared/services/toast/toast.service";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { IPennyTestForm, PennyTest } from "./_models/penny-test.interface";
import { PennyTestApiService } from "./_services/penny-test-api.service";
import { PermissionsService } from "@shared/services/permissions/permissions.service";

@Component({
	selector: "app-penny-test",
	templateUrl: "./penny-test.component.html",
	styleUrls: ["./penny-test.component.scss"]
})
export class PennyTestComponent implements OnInit, OnDestroy {
	currencies$: Observable<SelectOption[]> = new Observable<SelectOption[]>();
	destroy$: Subject<void> = new Subject();
	pennyTestCurrencyForm!: FormGroup;
	isEdit: boolean = false;
	canEditPennyTest = false;

	selectedCurrency: string = "";
	constructor(
		private currencyService: CurrencyService,
		private toastService: ToastService,
		private pennyTestApiService: PennyTestApiService,
		private readonly formBuilder: UntypedFormBuilder,
		private permissions: PermissionsService
	) {}

	ngOnInit(): void {
		this.permissions
			.canEditPennyTest()
			.pipe(take(1))
			.subscribe(res => {
				this.canEditPennyTest = res;
				this.getCurrencies();
				this.initForm();
			});
	}

	initForm(): void {
		this.pennyTestCurrencyForm = this.formBuilder.group({
			currency: ["", Validators.required],
			amount: ["", Validators.required]
		}) as IPennyTestForm;

		if (!this.canEditPennyTest) {
			this.pennyTestCurrencyForm.get("amount")?.disable();
		}

		this.pennyTestCurrencyForm
			.get("currency")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe(val => {
				this.pennyTestCurrencyForm.markAsPristine();

				this.selectedCurrency = val;

				this.pennyTestApiService
					.getPennyTestCurrencyConfigByCurrency(val)
					.pipe(
						takeUntil(this.destroy$),
						map(data => {
							if (data.amount > 0) {
								this.isEdit = true;
								this.pennyTestCurrencyForm
									.get("amount")
									?.patchValue(data.amount.toFixed(2), { emitEvent: false });
							} else {
								this.isEdit = false;
								this.pennyTestCurrencyForm
									.get("amount")
									?.patchValue(data.amount.toFixed(2), { emitEvent: false });
							}
						})
					)
					.subscribe();
			});
	}

	getCurrencies() {
		this.currencies$ = this.currencyService.getCurrencyOptions();
	}

	savePennyTest() {
		const apiPennyTest: PennyTest = this.pennyTestApiService.formatPennyTestDataApi(
			this.pennyTestCurrencyForm.getRawValue()
		);

		//convert input amount value to two decimal values
		this.pennyTestCurrencyForm.get("amount")?.patchValue(apiPennyTest.amount);

		if (this.isEdit) {
			this.pennyTestApiService
				.updatePennyTestCurrencyConfig(this.selectedCurrency, apiPennyTest)
				.pipe(takeUntil(this.destroy$))
				.subscribe(result => {
					if (result.status === 200) {
						this.toastService.showSuccess(
							"Penny test for " + this.selectedCurrency + " was updated correctly"
						);
					} else {
						this.toastService.showWarning(
							"Penny test for " + this.selectedCurrency + " did not update correctly"
						);
					}
				});
		} else {
			this.pennyTestApiService
				.createPennyTestCurrencyConfig(apiPennyTest)
				.pipe(takeUntil(this.destroy$))
				.subscribe(result => {
					this.isEdit = true;

					if (result.status === 200) {
						this.toastService.showSuccess(
							"Penny test for " + this.selectedCurrency + " was saved correctly"
						);
					} else {
						this.toastService.showWarning(
							"Penny test for " + this.selectedCurrency + " did not save correctly"
						);
					}
				});
		}

		this.pennyTestCurrencyForm.markAsPristine();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
