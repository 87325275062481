import { Component, OnInit } from "@angular/core";
import { ServiceProviderService } from "../../../../shared/services/service-provider/service-provider.service";
import { Observable, Subject } from "rxjs";
import { SelectOption } from "../../../../shared/models/select-option.interface";
import { ErrorManagementService } from "../../services/error-management.service";
import { ErrorManagementEntityService } from "../../services/error-management-entity.service";
import { ErrorModel, ErrorModelPagination } from "../../models/error-management.models";
import { map, takeUntil, tap } from "rxjs/operators";
import { ErrorTypesEntityService } from "../../error-types/services/error-types-entity.service";
import { formatDataForSelect } from "../../../../shared/utils/format-select-data.util";
import { PageEvent } from "@angular/material/paginator";
import { ErrorManagementDataService } from "../../services/error-management-data.service";

@Component({
	selector: "app-error-management",
	templateUrl: "./error-management.component.html",
	styleUrls: ["./error-management.component.scss"]
})
export class ErrorManagementComponent implements OnInit {
	providers$: Observable<SelectOption[]> = new Observable<SelectOption[]>();
	errors$: Observable<any> = new Observable<ErrorModel[]>();
	errorTypes$: Observable<SelectOption[]> = new Observable<SelectOption[]>();

	pageSize: number = 0;
	pageIndex: number = 0;
	totalPageCount: number = 0;

	pageDestroy$: Subject<void> = new Subject();

	constructor(
		private serviceProviderService: ServiceProviderService,
		private errorManagementService: ErrorManagementService,
		private errorManagementEntityService: ErrorManagementEntityService,
		private errorManagementDataService: ErrorManagementDataService,
		private errorTypesEntityService: ErrorTypesEntityService
	) {}

	ngOnInit(): void {
		this.providers$ = this.serviceProviderService.getProvidersAsSelectOptions();

		this.getErrorTypes();
		this.errors$ = this.errorManagementEntityService.entities$.pipe(
			tap(data => {
				if (!data.length) {
					this.getAllErrors();
				}
			})
		);
		this.initSubscription();
	}

	getErrorTypes() {
		this.errorTypes$ = this.errorTypesEntityService.getAll().pipe(
			map(data => {
				const options = data.map(error => {
					const option: SelectOption = {
						value: error.type,
						text: error.type
					};

					return formatDataForSelect(option);
				});
				return options;
			})
		);
	}

	getAllErrors() {
		this.errorManagementEntityService.getWithQuery({}).subscribe(results => {
			this.errorManagementEntityService.addAllToCache(results);
		});
	}

	storeError(error: ErrorModel) {
		this.errorManagementEntityService
			.update(error as Partial<ErrorModelPagination>)
			.pipe(
				tap(_ => {
					this.getErrorTypes();
					this.errorManagementService.errorTypeUpdateSuccess.emit();
				})
			)
			.subscribe();
	}

	filterErrors(searchFilters: { searchString: string; errorType: string; provider: string }) {
		this.errorManagementEntityService
			.getWithQuery({
				errorTypes: searchFilters.errorType,
				inputMessages: searchFilters.searchString,
				outputMessages: searchFilters.searchString,
				providerNames: searchFilters.provider
			})
			.subscribe(results => {
				this.errorManagementEntityService.addAllToCache(results);
			});
	}

	initSubscription(): void {
		this.errorManagementDataService._pageIndex
			.pipe(takeUntil(this.pageDestroy$))
			.subscribe(pageIndex => (this.pageIndex = +pageIndex));
		this.errorManagementDataService._pageSize
			.pipe(takeUntil(this.pageDestroy$))
			.subscribe(pageSize => (this.pageSize = +pageSize));
		this.errorManagementDataService._totalPageCount
			.pipe(takeUntil(this.pageDestroy$))
			.subscribe(totalPageCount => (this.totalPageCount = +totalPageCount));
	}

	paginate(pagination: PageEvent) {
		this.errorManagementDataService.setPageIndex = pagination.pageIndex.toString();
		this.errorManagementDataService.setPageSize = pagination.pageSize.toString();
		this.getAllErrors();
	}

	ngOnDestroy(): void {
		//Called once, before the instance is destroyed.
		//Add 'implements OnDestroy' to the class.
		this.pageDestroy$.unsubscribe();
	}
}
