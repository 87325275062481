import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { shareReplay, takeUntil, take } from "rxjs/operators";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { CountriesService } from "@shared/services/countries/countries.service";
import { CurrencyService } from "@shared/services/currency/currency.service";
import { Store, select } from "@ngrx/store";
import { AppState } from "src/app/store/models/state.model";
import { getSelectedTPPServiceState } from "../../../../store";
import { TppCommonServiceService } from "../../_services/common/tpp-common-service.service";
import { BankAccountFieldsComponent } from "@shared/components/bank-account-fields/bank-account-fields.component";
import { TppAccount } from "src/app/shared/models/tpp-account.interface";
import { BankAccountsService } from "@shared/services/bank-accounts/bank-accounts.service";
import { TppServiceManagementService } from "../../_services/management/tpp-service-management.service";
import { ToastService } from "@shared/services/toast/toast.service";
import { TppServiceBeneficiaryDTO } from "src/app/shared/models/tpp-service.interface";

@Component({
	selector: "app-tpp-add-beneficiary",
	templateUrl: "./tpp-add-beneficiary.component.html",
	styleUrls: ["./tpp-add-beneficiary.component.scss"]
})
export class TppAddBeneficiaryComponent implements OnInit, OnDestroy, AfterViewInit {
	@Input() selectedCountry: string | undefined;
	@Input() canEdit: boolean = true;

	@Output() newBeneficiary: EventEmitter<TppServiceBeneficiaryDTO> = new EventEmitter<TppServiceBeneficiaryDTO>();

	//ViewChild
	@ViewChild(BankAccountFieldsComponent) bankAccountFieldsComponent: BankAccountFieldsComponent | undefined;
	bankAccountFields!: BankAccountFieldsComponent;

	newBeneficiaryForm!: FormGroup;
	countries$!: Observable<SelectOption[]>;
	currencies$: Observable<SelectOption[]> = new Observable<SelectOption[]>();
	formIsPristine: boolean = true;
	formIsComplete: boolean = false;
	formIsValid: boolean = false;
	serviceId: string = "";

	public bankAccountValues: Record<string, string | number | boolean> | undefined;
	selectedCountryName!: SelectOption | string;
	accountBeneficiary!: TppAccount;
	bankName: string = "";
	btText: String = "SAVE";

	destroy$: Subject<void> = new Subject();

	constructor(
		private formBuilder: FormBuilder,
		private countriesService: CountriesService,
		private currencyService: CurrencyService,
		private tppCommonService: TppCommonServiceService,
		private store: Store<AppState>,
		private bankAccountsService: BankAccountsService,
		private tppServiceManagementService: TppServiceManagementService,
		private toastService: ToastService,
		private cdr: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		this.countries$ = this.countriesService.getCountryOptions().pipe(shareReplay(1));
		this.currencies$ = this.currencyService.getCurrencyOptions().pipe(shareReplay(1));

		this.store.pipe(takeUntil(this.destroy$), select(getSelectedTPPServiceState)).subscribe(state => {
			this.serviceId = state.selectedTPPService.tppService!.id;
		});

		this.countries$.pipe(take(1)).subscribe(x => {
			this.selectedCountryName = x.find(country => country.value === this.selectedCountry)!;
			this.initializeForm();
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	ngAfterViewInit() {
		this.bankAccountFields = this.bankAccountFieldsComponent!;
		this.cdr.detectChanges();
	}

	initializeForm(): void {
		this.newBeneficiaryForm = this.formBuilder.group({
			account_name: ["", Validators.required],
			country: ["", Validators.required],
			currency: ["", Validators.required]
		});

		if (!this.canEdit) {
			this.newBeneficiaryForm.disable();
		}

		this.newBeneficiaryForm.patchValue({
			country: this.selectedCountryName
		});

		this.editBeneficiaryAccount();

		this.bankAccountsService.formUpdated$.subscribe(response => {
			if (this.bankAccountFieldsComponent)
				this.formIsPristine = this.bankAccountFieldsComponent?.bankAccountForm.pristine!;
		});

		this.bankAccountsService.formUpdatedValidity$.subscribe(response => {
			if (response) this.formIsComplete = true;
		});

		this.newBeneficiaryForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
			next: res => {
				this.formIsPristine = this.newBeneficiaryForm.pristine;
				if (this.newBeneficiaryForm.valid) {
					this.formIsValid = true;
				}
			}
		});
	}

	editBeneficiaryAccount(): void {
		if (Object.keys(this.tppServiceManagementService.beneficiaryAccount).length > 0) {
			this.accountBeneficiary = this.tppServiceManagementService.beneficiaryAccount;
			this.newBeneficiaryForm.patchValue({
				account_name: this.accountBeneficiary.name,
				currency: this.accountBeneficiary.currency
			});
			this.bankAccountValues = this.generateRecordsForBankAccount(this.accountBeneficiary.bankAccount.fields);
			this.selectedCountryName = this.accountBeneficiary.bankAccount.country;
			this.bankName = this.accountBeneficiary.bankAccount.bankName;
			this.btText = "UPDATE";
		}
	}

	generateRecordsForBankAccount(fields: { key: string; value: string }[]): Record<string, string | number | boolean> {
		let bankAccountRecords: Record<string, string | number | boolean> = {};
		fields.forEach((item: { key: string; value: string }) => (bankAccountRecords[item.key] = item.value));
		return bankAccountRecords;
	}

	backFromModal() {
		this.tppServiceManagementService.setBeneficairyAccountState(false);
	}

	saveBeneficiary(): void {
		const rawFormData: { account_name: string; country: SelectOption; currency: string } =
			this.newBeneficiaryForm.getRawValue();

		if (this.btText === "SAVE")
			this.tppCommonService
				.saveNewBeneficiary(
					rawFormData,
					this.bankAccountFieldsComponent!.bankAccountForm.controls,
					this.serviceId
				)
				.subscribe({
					next: data => {
						this.newBeneficiary.emit(data);
						this.toastService.showSuccess("Account created successfully");
						this.tppServiceManagementService.setBeneficairyAccountState(false);
					},
					error: _ => {
						this.toastService.showError("Error creating your account, please try again");
					}
				});

		if (this.btText === "UPDATE")
			this.tppCommonService
				.updateNewBeneficairy(
					rawFormData,
					this.bankAccountFieldsComponent!.bankAccountForm.controls,
					this.accountBeneficiary
				)
				.subscribe({
					next: data => {
						this.newBeneficiary.emit(data);
						this.toastService.showSuccess("Account updated successfully");
						this.tppServiceManagementService.setBeneficairyAccountState(false);
					},
					error: _ => {
						this.toastService.showError("Error updating your account, please try again");
					}
				});
	}
}
