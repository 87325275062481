import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ErrorManagementComponent } from "./containers/error-management/error-management.component";
import { ErrorManagementResolver } from "./services/error-management.resolver";

const routes: Routes = [
	{
		path: "",
		component: ErrorManagementComponent
		// resolve: { errorManagement: ErrorManagementResolver }
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ErrorManagementRoutingModule {}
