import { DOCUMENT } from "@angular/common";
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { BreadcrumbService } from "@shared/components/breadcrumbs/services/breadcrumb.service";

import { HighlightCountry } from "@modules/client-landing/_models/client_landing-interface";
import { MenuService } from "@modules/config/_services/menu/menu.service";
import { UserState } from "@modules/user-management/_models/user-management-api.interface";
import { CustomerInfoOnSideNav } from "@modules/config/_models/dashboard.model";
import { WorldMapService } from "@shared/services/world-map/world-map.service";
import { UserRole } from "@shared/constants/roles";
import { Menu } from "@shared/models/menu.interface";
import { SelectOption } from "@shared/models/select-option.interface";
import { MediaChange, MediaObserver } from "ngx-flexible-layout";
import { Observable, Subject, filter, takeUntil, tap } from "rxjs";
import { AuthorizationService } from "src/app/core/services/authorization-service";

@Component({
	selector: "app-side-nav",
	templateUrl: "./side-nav.component.html",
	styleUrls: ["./side-nav.component.scss"]
})
export class SideNavComponent implements OnInit, OnDestroy {
	constructor(
		public menuService: MenuService,
		private router: Router,
		private mediaObserver: MediaObserver,
		private breadCrumbsService: BreadcrumbService,
		private authorizationService: AuthorizationService,
		private worldMapService: WorldMapService
	) {}

	@Input() version = "";
	@Input() user!: UserState;
	@Input() customerLogo!: CustomerInfoOnSideNav;
	@Input() roles: string[] = [];
	@Input() set menuOptions(menu: Menu[]) {
		this.menu = menu;
	}

	@Input() set clientLanding(isLanding: boolean) {
		this.isClientPage = isLanding;
		if (isLanding) this.setupSelectionListeners();
	}

	@Input() set legalEntityCountries(list: SelectOption[]) {
		this.worldMapService.setCountryOptions(list);
		this.countrylist = list;
	}

	@Output() isBigScreen = new EventEmitter<boolean>();
	@Output() isSideNavOpen = new EventEmitter<boolean>();
	@Output() isMenuOpen = new EventEmitter<boolean>();
	@Output() legalEntitiesSelected = new EventEmitter<string[]>();

	bigScreen = false;
	sideNavOpen = true;
	menuOpen = true;
	menu!: Menu[];
	isClientPage = false;
	media$!: Observable<MediaChange[]>;
	hover = false;
	countrylist!: SelectOption[];
	cloudPayLogo = "../../../assets/logos/CPlogo-white.png";
	cloudPayClientLogo = "../../../assets/logos/CPlogo-client-white.png";
	private destroy$: Subject<void> = new Subject<void>();

	ngOnInit(): void {
		this.toggleSideNavOnBreakPointChange();
		this.onMenuVisibilityChange();
	}

	private onMenuVisibilityChange(): void {
		this.menuService
			.getVisibility()
			.pipe(
				takeUntil(this.destroy$),
				tap(isOpen => {
					this.menuOpen = isOpen;
					this.isMenuOpen.emit(isOpen);
				})
			)
			.subscribe();
	}

	setupSelectionListeners(): void {
		this.worldMapService.highLightSelectOption$
			.pipe(takeUntil(this.destroy$))
			.subscribe((selection: HighlightCountry) => {
				const element: HTMLElement = document.getElementById(selection.country) as HTMLElement;
				element.style.backgroundColor = selection.highlight ? "#004ad7" : "transparent";
			});

		this.worldMapService.countrySelected$.pipe(takeUntil(this.destroy$)).subscribe((code: string) => {
			const selectedCountry = this.countrylist.find(country => country.value === code);
			if (selectedCountry) this.legalEntitiesSelected.emit(selectedCountry?.valueArray);
		});
	}

	setHoverStyle(country: string, highlight: boolean) {
		this.worldMapService.setHighLightCountrySelectOption(country, highlight);
	}

	toggleSizeNaveState(): void {
		this.sideNavOpen = !this.sideNavOpen;
		this.isSideNavOpen.emit(this.sideNavOpen);
		this.menuService.expanded = this.sideNavOpen;
	}

	openNavItem(category: Menu): void {
		this.sideNavOpen = !this.sideNavOpen;
		this.isSideNavOpen.emit(this.sideNavOpen);
		this.menuService.expanded = this.sideNavOpen;
		const url: string = category.sections[0].navigations[0].url;

		this.router.navigate([url]);
	}

	private toggleSideNavOnBreakPointChange(): void {
		this.media$ = this.mediaObserver.asObservable().pipe(filter((changes: MediaChange[]) => true));

		this.media$.pipe(takeUntil(this.destroy$)).subscribe((changes: MediaChange[]) => {
			changes.forEach(change => {
				this.bigScreen = change.mqAlias === "xxl" || change.mqAlias === "cloudpay-laptop";
				this.sideNavOpen = change.mqAlias === "xxl" || change.mqAlias === "cloudpay-laptop";

				this.isBigScreen.emit(this.bigScreen);
				this.isSideNavOpen.emit(this.sideNavOpen);
			});
		});
	}

	menuItemClicked(): void {
		this.breadCrumbsService.resetCustomerEntityGroupState();
	}

	isClientRole(): boolean {
		return this.roles.includes(UserRole.CLIENT);
	}

	logout(): void {
		this.authorizationService.logout();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
