import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from "@angular/core";
import { NG_VALUE_ACCESSOR, FormControl, ControlValueAccessor, Validators } from "@angular/forms";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, map, pairwise, startWith, take } from "rxjs/operators";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { InputAutocompleteService } from "./_services/input-autocomplete.service";
import { SelectAndFilterOptions } from "@shared/models/select-and-filter-options.interface";

@Component({
	selector: "input-autocomplete",
	templateUrl: "./input-autocomplete.component.html",
	styleUrls: ["./input-autocomplete.component.scss"],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => InputAutocompleteComponent),
			multi: true
		}
	]
})
export class InputAutocompleteComponent implements ControlValueAccessor, OnInit {
	// Inputs
	@Input() set options(options: SelectOption[] | null | undefined) {
		if (options && options.length > 0) {
			this.allOptions = options;

			this.allOptions.map(o => {
				o.disabled = this.countriesDisabled?.includes(o.value);
			});

			//Listen to value changes on input text to filter results
			this.control.valueChanges
				.pipe(
					distinctUntilChanged(),
					map(value => {
						if (typeof value === "string") {
							if (this.multiple && this.hasMultipleCurrencyCodes(value)) {
								return (value = this.getLastCurrencyCode(value));
							} else {
								return value;
							}
						} else {
							return value;
						}
					}),
					startWith(""),
					map(value => {
						if (!!value) {
							return typeof value === "string" ? value : value.text;
						}
					}),
					map((text: any) => {
						if (text) {
							return this.filter(options, text);
						} else {
							return options;
						}
					})
				)
				.subscribe(value => {
					this.filteredOptions$.next(value);
				});
		} else {
			this.allOptions = [];
		}
	}

	_defaultValue: string | string[] | undefined = "";

	// Optional Inputs

	_disabled: boolean = false;

	@Input()
	set disabled(value: boolean) {
		value ? this.control.disable() : this.control.enable();
		this._disabled = value;
	}

	get disabled(): boolean {
		return this._disabled;
	}

	@Input() label?: string;
	@Input() labelDisappears?: boolean = true;
	@Input() required?: boolean;
	@Input() countriesDisabled?: string[];
	@Input() set defaultValue(value: string | string[] | undefined) {
		if (!!value) {
			this.writeValueById(value!);
			this._defaultValue = value;
		}
	}
	@Input() multiple: boolean = false;
	@Output() inputTextChange: EventEmitter<string> = new EventEmitter<string>();

	control: FormControl = new FormControl();
	optionsObject: { [key: string]: { imagePath: string; text: string } } = {};
	filteredOptions$: Subject<SelectOption[]> = new Subject<SelectOption[]>();
	destroy$: Subject<void> = new Subject();
	selectedOptions: SelectOption[] = [];

	//DOM selectors
	@ViewChild(MatAutocompleteTrigger) autocomplete!: MatAutocompleteTrigger;
	@ViewChild("input") input!: ElementRef<HTMLInputElement>;

	private allOptions!: SelectOption[];

	onChange: any = () => {};
	onTouched: any = () => {};

	constructor(public inputAutocompleteService: InputAutocompleteService) {}

	set compareWith(fn: (o1: any, o2: any) => boolean) {
		throw new Error("Method not implemented.");
	}

	protected setProperty(key: string, value: any): void {
		throw new Error("Method not implemented.");
	}

	ngOnInit() {
		// if (this.defaultValue) {
		// 	this.writeValueById(this.defaultValue);
		// }

		if (this.required) {
			this.control.setValidators([Validators.required]);
			this.control.updateValueAndValidity();
		}

		/* Setting to disabled pay cycle selector. Remove when BE implementation is finished */
		this.control = this.inputAutocompleteService.setDisabledState(this._disabled, this.control);

		this.control.valueChanges
			.pipe(debounceTime(750), distinctUntilChanged(), pairwise())
			.subscribe(([prev, next]: [string, any]) => {
				if (next) {
					const nextValue = typeof next === "string" ? next : next.text;
					if (prev !== nextValue) {
						this.inputTextChange.emit(nextValue);
					}
				}
			});
	}

	hasMultipleCurrencyCodes(codesString: string) {
		return codesString.split(", ").length > 0;
	}

	getLastCurrencyCode(codesString: string) {
		const codesArray = codesString.split(/\s*,\s*/);
		return codesArray[codesArray.length - 1];
	}

	writeValueById(defaultValue: string | string[]) {
		this.filteredOptions$.pipe(take(1)).subscribe((filteredOptions: SelectOption[]) => {
			const output: SelectAndFilterOptions = this.inputAutocompleteService.writeValueById(
				filteredOptions,
				defaultValue
			);
			filteredOptions = output.filteredOptions;

			if (output.selectOptions) {
				if (Array.isArray(output.selectOptions)) {
					this.control.setValue(null);
					this.control.setValue(output.selectOptions[0].text);

					this.selectedOptions = output.selectOptions;
				} else {
					this.control.setValue(null);
					this.control.setValue(output.selectOptions!.text);
				}
			}
		});
	}

	writeValue(value: any): void {
		this.control.setValue(value);
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	openDropDown() {
		this.filteredOptions$.next(this.allOptions);
		if (!this._disabled) {
			this.highLightInputText();
			this.control.setErrors(null);
			this.autocomplete.openPanel();
		}
	}

	highLightInputText() {
		if (this.input && this.input.nativeElement) {
			const autoComplete = document.getElementById(this.input.nativeElement.id) as HTMLInputElement;
			autoComplete.setSelectionRange(0, 200);
		}
	}

	onClosed() {
		if (this.multiple && this.selectedOptions.length > 0) {
			this.control.patchValue(this.selectedOptions);
		}

		this.onChange(this.getCodes(this.selectedOptions));
	}

	handleEmptyInput($event: any) {
		//When value empty output null value
		if ($event.target.value === "") {
			this.onChange(null);
		}
	}

	getCodes(selectedOptions: SelectOption[]): string[] {
		let codes: string[] = [];
		selectedOptions.map(opt => codes.push(opt.value));
		return codes;
	}

	private filter(options: SelectOption[], text: string): SelectOption[] {
		//filter options objects by text property
		const filterValue = text.toLowerCase();
		let newOptions = options.filter(option => option.text.toLowerCase().includes(filterValue));

		if (!newOptions.length) {
			newOptions = options.filter(option => option.value === text);
		}

		return newOptions;
	}

	optionClicked(event: Event, option: SelectOption) {
		event.stopPropagation();
		this.toggleSelection(option);
	}

	toggleSelection(option: SelectOption) {
		option.selected = !option.selected;
		this.selectedOptions = this.inputAutocompleteService.formatSelectedOptions(option, this.selectedOptions);
		this.control.setValue(this.selectedOptions);
		this.onChange(this.getCodes(this.selectedOptions));
	}

	clearInput(event: Event) {
		if (!this._disabled) {
			this.allOptions.map(o => (o.selected = false));
			this.filteredOptions$.next(this.allOptions);
			this.selectedOptions = [];
			this.control.setValue(null);
			this.control.markAsTouched();
			this.onChange(this.control.value);
		}
	}
}
