import { DatePipe } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { map, take, takeUntil } from "rxjs/operators";
import { UserService } from "@modules/user-management/_services/user.service";
import { PaygroupsService } from "@shared/services/paygroups/paygroups.service";
import { PayGroupHistory, statusType } from "src/app/shared/models/pay-groups";

@Component({
	selector: "app-paygroup-status-history",
	templateUrl: "./paygroup-status-history.component.html",
	styleUrls: ["./paygroup-status-history.component.scss"]
})
export class PaygroupStatusHistoryComponent implements OnInit, OnDestroy {
	@Input() set _type(type: statusType) {
		this.type = type;
		this.getHistoryData(type);
	}

	@Input() set _payGroupId(id: string) {
		this.paygroupId = id;
	}

	@Output() cancelDetails: EventEmitter<boolean> = new EventEmitter<boolean>();

	showResult = false;
	type!: statusType;
	searchForm!: FormGroup;
	historyList: PayGroupHistory[] = [];
	filteredData: PayGroupHistory[] = [];
	paygroupId: string = "";
	exit: boolean = false;

	private destroy$: Subject<void> = new Subject<void>();

	constructor(
		private paygroupsService: PaygroupsService,
		private formBuilder: FormBuilder,
		private datePipe: DatePipe,
		public userService: UserService
	) {}

	ngOnInit(): void {
		this.initForm();
	}

	getHistoryData(type: statusType): void {
		this.paygroupsService
			.getPayGroupHistory(this.paygroupId, type)
			.pipe(
				takeUntil(this.destroy$),
				map(result => {
					this.showResult = false;
					if (result?.history?.length > 0) {
						result.history = result.history.sort(
							(a: PayGroupHistory, b: PayGroupHistory) =>
								new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
						);

						return result.history;
					}
					return [];
				})
			)
			.subscribe({
				next: history => {
					if (history.length) {
						this.fecthUserDetails(history);
					} else {
						this.cancel();
					}
				}
			});
	}

	fecthUserDetails(history: PayGroupHistory[]) {
		history.forEach((history: PayGroupHistory) => {
			this.getUserName(history.createdBy.value).subscribe({
				next: userName => {
					history.createdBy.value = userName;
					this.historyList.push(history);
					this.filteredData.push(history);
				},
				complete: () => {
					this.showResult = true;
				}
			});
		});
	}

	getUserName(id: string): Observable<string> {
		return this.userService.getUserById(id).pipe(
			take(1),
			map(user => (user.firstName ? user.firstName + " " + user.lastName : "A user"))
		);
	}

	initForm() {
		this.searchForm = this.formBuilder.group({
			search: [""]
		});
		this.searchForm.get("search")?.valueChanges.subscribe(searchValue => {
			if (searchValue !== "") {
				this.filteredData = this.filterResult(searchValue);
			} else {
				this.filteredData = this.historyList;
			}
		});
	}

	filterResult(searchTerm: string): PayGroupHistory[] {
		let filterSearch: PayGroupHistory[] = [];

		//User Name

		filterSearch = this.historyList.filter((result: PayGroupHistory) =>
			result.createdBy.value.toLowerCase().includes(searchTerm.toLowerCase())
		);

		if (filterSearch.length > 0) {
			return filterSearch;
		}

		//user role

		filterSearch = this.historyList.filter((result: PayGroupHistory) =>
			result.createdBy.type.toLowerCase().includes(searchTerm.toLowerCase())
		);

		if (filterSearch.length > 0) {
			return filterSearch;
		}

		//createdAt

		filterSearch = this.historyList.filter((result: PayGroupHistory) =>
			this.datePipe
				.transform(result.createdAt, "H:mm MMM d, y ")!
				.toString()
				.toLowerCase()
				.includes(searchTerm.toLowerCase())
		);

		if (filterSearch.length > 0) {
			return filterSearch;
		}

		//Status

		filterSearch = this.historyList.filter(
			(result: PayGroupHistory) =>
				result.status && result.status.toString().toLowerCase().includes(searchTerm.toLowerCase())
		);

		if (filterSearch.length > 0) {
			return filterSearch;
		}

		//GoLiveAt

		filterSearch = this.historyList.filter(
			(result: PayGroupHistory) =>
				result.goLiveAt &&
				this.datePipe
					.transform(result.goLiveAt, "MMM d, y")!
					.toString()
					.toLowerCase()
					.includes(searchTerm.toLowerCase())
		);

		if (filterSearch.length > 0) {
			return filterSearch;
		}

		return filterSearch;
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	cancel() {
		this.cancelDetails.emit(true);
	}
}
