import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { BankAccountFieldsComponent } from "@shared/components/bank-account-fields/bank-account-fields.component";
import { BankAccountsService } from "@shared/services/bank-accounts/bank-accounts.service";
import { CountriesService } from "@shared/services/countries/countries.service";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { BankAccountPost, BankAccountPut } from "../../../_models/bank-account.interface";
import { SourceAccountPost, SourceAccountPut, SourceFoundAccount } from "../../../_models/source-accounts.interface";
import { FundSourceService } from "../../../_services/fund-source/fund-source.service";
import { ToastService } from "@shared/services/toast/toast.service";
import { CurrencyService } from "@shared/services/currency/currency.service";
import { map, take, takeUntil } from "rxjs/operators";
import { Currency } from "src/app/shared/models/currency.interface";
import { TppGroupConfigurationStore } from "../../../_services/store/tpp-group-configuration.service";
import { TPPServiceDefinitionState } from "../../../../../shared/models/tpp-service.interface";
import { MenuService } from "@modules/config/_services/menu/menu.service";

@Component({
	selector: "app-add-source-of-fund",
	templateUrl: "./add-source-of-fund.component.html",
	styleUrls: ["./add-source-of-fund.component.scss"]
})
export class AddSourceOfFundComponent implements OnInit, OnDestroy {
	@Output() closeAddNewSourceOfFund = new EventEmitter<string>();
	@Input() legalEntityId: string = "";
	@Input() currency: string = "";
	@Input() countryCode: string = "";
	@Input() sourceOfFundId: string = "";
	@Input() groupToConfig: string = "";
	@Input() readonly: boolean = false;

	serviceDefinition$: Observable<TPPServiceDefinitionState> = new Observable<TPPServiceDefinitionState>();

	enableSave: boolean = false;
	country$!: Observable<SelectOption[]>;
	globalForm!: FormGroup;
	currenciesSelectOptions!: SelectOption[];
	selectedCountry: string = "";
	public editMode: boolean = false;
	canEditSourceOfFunds: boolean = false;
	public bankAccountValues: Record<string, string | number | boolean> | undefined;
	destroy$: Subject<void> = new Subject();
	sourceOfFundsAccount!: SourceFoundAccount;
	bankName: string = "";

	//ViewChild
	@ViewChild(BankAccountFieldsComponent) bankAccountFieldsComponent: BankAccountFieldsComponent | undefined;

	constructor(
		private formbuilder: FormBuilder,
		private countriesService: CountriesService,
		private bankAccountsService: BankAccountsService,
		private sourceAccountService: FundSourceService,
		private toastService: ToastService,
		private tppGroupConfigStore: TppGroupConfigurationStore,
		private currencyService: CurrencyService,
		private menuService: MenuService
	) {}

	ngOnInit(): void {
		this.serviceDefinition$ = this.tppGroupConfigStore.select(state => state);
		this.initForm();
	}

	showBread() {
		const currency = this.currency ? this.currency : this.globalForm.get("currency")!.value;
		this.closeAddNewSourceOfFund.next(currency);
		this.menuService.setVisible(true);
	}

	initForm() {
		this.globalForm = this.formbuilder.group({
			country: ["", Validators.required],
			fundingPull: ["", Validators.required],
			fundingPush: ["", Validators.required],
			provisionCompensation: ["", Validators.required],
			name: ["", Validators.required],
			currency: ["", Validators.required]
		});

		if (this.readonly) {
			this.globalForm.disable();
		}

		this.globalForm.patchValue({
			fundingPull: true,
			fundingPush: true
		});

		if (this.countryCode) {
			this.selectedCountry = this.countryCode;
			this.globalForm.patchValue({
				country: this.countryCode
			});
		}

		if (this.currency) {
			this.globalForm.patchValue({
				currency: this.currency
			});
		}

		this.getAllCurrencies();
		this.setCountrySelection();
		this.getSourceOfFund();

		this.globalForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(response => {
			this.checkMultipleFormValidity();
		});

		this.globalForm
			.get("country")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe(value => {
				this.selectedCountry = value;
			});

		this.globalForm
			.get("fundingPull")!
			.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe(val => {
				this.checkMultipleFormValidity();
			});

		this.globalForm
			.get("fundingPush")!
			.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe(val => {
				this.checkMultipleFormValidity();
			});

		this.bankAccountsService.formUpdated$.subscribe(response => {
			this.checkMultipleFormValidity();
		});
	}

	checkMultipleFormValidity() {
		if (this.bankAccountFieldsComponent) {
			if (
				this.bankAccountFieldsComponent!.bankAccountForm.valid &&
				this.globalForm.valid &&
				(this.globalForm.get("fundingPull")!.value || this.globalForm.get("fundingPush")!.value)
			) {
				this.enableSave = true;
			} else {
				this.enableSave = false;
			}
		}
	}

	getSourceOfFund() {
		if (this.sourceOfFundId) {
			this.sourceAccountService
				.getSourceFundAccount(this.sourceOfFundId)
				.pipe(take(1))
				.subscribe(res => {
					this.sourceOfFundsAccount = res;

					this.updateFormsData(this.sourceOfFundsAccount);
				});
		}
	}

	getAllCurrencies() {
		this.currencyService
			.getAllCurrencies()
			.pipe(
				take(1),
				map((res: Currency[]) => {
					return res.map(currency => {
						return {
							text: currency.name,
							value: currency.code
						};
					});
				})
			)
			.subscribe(res => {
				this.currenciesSelectOptions = res;
			});
	}

	setCountrySelection() {
		this.country$ = this.countriesService.getCountryOptions();
	}

	save() {
		if (this.sourceOfFundsAccount) {
			this.sourceAccountService.updateFundSource(this.prepareSourceFundAccountToUpdate()).subscribe(data => {
				this.toastService.showSuccess("Bank account update successfully");

				const currency = this.currency ? this.currency : this.globalForm.get("currency")!.value;
				this.closeAddNewSourceOfFund.next(currency);
			});
		} else {
			this.sourceAccountService.createFundSource(this.prepareSourceFundAccountToCreate()).subscribe(data => {
				this.toastService.showSuccess("Bank account created successfully");

				//update state
				this.tppGroupConfigStore.setGroupSourceOfFundId(this.groupToConfig, data.id);
				const currency = this.currency ? this.currency : this.globalForm.get("currency")!.value;
				this.closeAddNewSourceOfFund.next(currency);
			});
		}
	}

	prepareSourceFundAccountToCreate(): SourceAccountPost {
		const sourceAccount: SourceAccountPost = {
			legalEntityId: this.legalEntityId,
			paymentType: "TPP",
			currency: this.globalForm.get("currency")?.value,
			data: {
				name: this.globalForm?.get("name")?.value,
				provisionCompensation: this.globalForm?.get("provisionCompensation")?.value,
				fundingMethods: [],
				bankAccount: {} as BankAccountPost
			}
		};

		const fundingMethods: Array<string> = [];
		if (this.globalForm?.get("fundingPull")?.value) fundingMethods.push("PULL");
		if (this.globalForm?.get("fundingPush")?.value) fundingMethods.push("PUSH");
		sourceAccount.data.fundingMethods = fundingMethods;

		const bankAccount: { fields: { key: string; value: string }[] } | undefined =
			this.bankAccountFieldsComponent?.getBankAccount();
		if (bankAccount && this.selectedCountry) {
			sourceAccount.data.bankAccount = {
				...bankAccount,
				country: this.selectedCountry
			};
		}

		return sourceAccount;
	}

	prepareSourceFundAccountToUpdate(): SourceAccountPut {
		const sourceAccountUpdate: SourceAccountPut = {
			id: this.sourceOfFundsAccount.id,
			version: this.sourceOfFundsAccount.version,
			data: {
				name: this.globalForm?.get("name")?.value,
				provisionCompensation: this.globalForm?.get("provisionCompensation")?.value,
				fundingMethods: [],
				bankAccount: {} as BankAccountPut
			}
		};

		const fundingMethods: Array<string> = [];
		if (this.globalForm?.get("fundingPull")?.value) fundingMethods.push("PULL");
		if (this.globalForm?.get("fundingPush")?.value) fundingMethods.push("PUSH");
		sourceAccountUpdate.data.fundingMethods = fundingMethods;

		const bankAccount: { fields: { key: string; value: string }[] } | undefined =
			this.bankAccountFieldsComponent?.getBankAccount();
		if (bankAccount && this.selectedCountry) {
			sourceAccountUpdate.data.bankAccount = {
				...bankAccount,
				country: this.selectedCountry,
				version: this.sourceOfFundsAccount.data.bankAccount.version,
				id: this.sourceOfFundsAccount.data.bankAccount.id,
				bankName: this.sourceOfFundsAccount.data.bankAccount.bankName
			};
		}
		return sourceAccountUpdate;
	}

	updateFormsData(sourceOfFundsAccount: SourceFoundAccount) {
		this.globalForm.patchValue({
			currency: sourceOfFundsAccount.currency,
			provisionCompensation: sourceOfFundsAccount.data.provisionCompensation,
			name: sourceOfFundsAccount.data.name,
			country: sourceOfFundsAccount.data.bankAccount.country,
			fundingPull: sourceOfFundsAccount.data.fundingMethods.includes("PULL"),
			fundingPush: sourceOfFundsAccount.data.fundingMethods.includes("PUSH")
		});
		this.bankName = sourceOfFundsAccount.data.bankAccount.bankName ?? "";
		this.bankAccountValues = this.generateRecordsForBankAccount(sourceOfFundsAccount.data.bankAccount.fields);
	}

	generateRecordsForBankAccount(fields: { key: string; value: string }[]): Record<string, string | number | boolean> {
		let bankAccountRecords: Record<string, string | number | boolean> = {};
		fields.forEach((item: { key: string; value: string }) => (bankAccountRecords[item.key] = item.value));
		return bankAccountRecords;
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
