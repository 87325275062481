<div class="details-list-container" [ngStyle]="{ 'max-height': totalHeight + 'px' }">
	<div class="column-labels">
		<span fxFlex="5"> <div class="placeholder"></div> </span>
		<span class="label" fxFlex="10">ID</span>
		<span class="label" fxFlex="10">NAME</span>
		<span class="label" fxFlex="10">SURNAME</span>
		<span class="label" fxFlex="15">AMOUNT</span>
		<span class="label" fxFlex="15">REFERENCE</span>
		<span class="label" fxFlex="13">VALUE DATE</span>
		<span class="label" fxFlex="20">STATE</span>
	</div>

	<app-tpp-payment-payout-item-detail-list-item
		*ngFor="let payout of payouts; let i = index; last as isLast"
		[_payout]="payout"
		[_reprocessPayoutIdsList]="reprocessPayoutIdsList"
		[_isLast]="isLast"
		[_isCancelledRun]="isCancelledRun"
		[_isInActivatedPayout]="isInActivatedPayout"
		(inActivatePayouts)="inActivatePayouts.emit($event)"
		(pushToReprocessList)="pushToReprocessList.emit($event)"
		(popFromReprocessList)="popFromReprocessList.emit($event)"
	></app-tpp-payment-payout-item-detail-list-item>
</div>
