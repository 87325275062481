import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ServiceProviderSetupPage } from "./service-provider-setup.page";
import { AddCountryComponent } from "./_pages/add-country/add-country.component";
import { EditCountryComponent } from "./_pages/edit-country/edit-country.component";

const routes: Routes = [
	{
		path: "",
		component: ServiceProviderSetupPage
	},

	{
		path: "add-country",
		component: AddCountryComponent
	},
	{
		path: "edit-country",
		component: EditCountryComponent
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ServiceProviderSetupRoutingModule {}
