import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { ApiService } from "@modules/pay-groups/services/api.service";
import { CustomerCacheService } from "@shared/services/customer/customer-cache.service";
import { LegalEntityService } from "@shared/services/legal-entity/legal-entity.service";
import { Customer } from "src/app/shared/models/customer.interface";
import { PayGroup } from "src/app/shared/models/pay-groups";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { createCustomerLogoPathUseImgCache } from "src/app/shared/utils/customer-logo-path.util";
import { formatDataForSelect } from "src/app/shared/utils/format-select-data.util";

@Injectable({
	providedIn: "root"
})
export class LegalEntitySelectionDataProviderService {
	includeSelectAllOption = true;

	constructor(
		private customerCache: CustomerCacheService,
		private legalEntityService: LegalEntityService,
		private payGroupService: ApiService
	) {}

	enableSelectAll() {
		this.includeSelectAllOption = true;
	}

	getCustomerSelectionOptions(): Observable<SelectOption[]> {
		return this.customerCache.getAllCustomers().pipe(
			switchMap((customers: Customer[]) => {
				var mappedOptions = customers.flatMap(customer => {
					// Assume its okay to show potentially stale images in typehead (until page refresh) for performance reasons.
					const customerLogoPath = createCustomerLogoPathUseImgCache(customer);
					const option: SelectOption = {
						value: customer.id,
						text: `${customer.externalId} / ${customer.name}`,
						imagePath: customerLogoPath.imagePath
					};

					return formatDataForSelect(option);
				});
				return [mappedOptions];
			}),
			map((selectOptions: SelectOption[]) =>
				this.mapConditionallyAllSelectionOption(selectOptions, "All customers")
			)
		);
	}

	getLegalEntitySelectionOptions(
		customerId: string,
		onlyLegalEntitiesWithPaygroups: boolean
	): Observable<SelectOption[]> {
		return this.legalEntityService
			.getLegalEntitiesForCustomerAsSelectOption(customerId, onlyLegalEntitiesWithPaygroups)
			.pipe(
				map((options: SelectOption[]) => {
					return this.mapConditionallyAllSelectionOption(options, "All legal entities");
				})
			);
	}

	getPaygroupSelectionOptions(
		legalEntityId: string,
		byPayGroupStatuses: string,
		customerId: string
	): Observable<SelectOption[]> {
		return this.payGroupService
			.getFirstFiftyPayGroupsByLegalEntityId(legalEntityId, byPayGroupStatuses, customerId)
			.pipe(
				map((paygroups: PayGroup[]) => {
					var payGroupSelectOptions = paygroups.map(paygroup => {
						const option: SelectOption = {
							value: paygroup.id!,
							text: `${paygroup.externalId} / ${paygroup.data.name}`
						};

						return formatDataForSelect(option);
					});
					payGroupSelectOptions = this.mapConditionallyAllSelectionOption(
						payGroupSelectOptions,
						"All Pay Groups"
					);

					return payGroupSelectOptions;
				})
			);
	}

	mapConditionallyAllSelectionOption(options: SelectOption[], text: string): SelectOption[] {
		if (this.includeSelectAllOption && options) {
			const allOption: SelectOption = {
				value: "ALL_OPTIONS",
				text: text
			};

			options.unshift(allOption);
		}

		return options;
	}
}
