import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { Observable, Subject, Subscription, of } from "rxjs";
import { map, takeUntil } from "rxjs/operators";

import { ApiService } from "@modules/pay-groups/services/api.service";
import { DropDownDataExpanded, ExternalCrumb, breadCrumbButtonIcon } from "@shared/models/breadcrumbs.interface";
import { PermissionsService } from "@shared/services/permissions/permissions.service";
import { PayGroup } from "@shared/models/pay-groups";
import { ServiceProviderService } from "@shared/services/service-provider/service-provider.service";
import { CustomerEntitySelection } from "@shared/models/customer-entity-selection.interface";
import { Employee, EmployeeReportPayGroupData, PayElectivePayGroupDataCounts } from "@shared/models/employee.interface";
import { SelectOption } from "@shared/models/select-option.interface";
import { getCustomerEntityGroupState, getGlobalDashboardFilterState, getPagination } from "@store/index";
import { AppState } from "@store/models/state.model";
import { PayElectiveApiService } from "../../services/pay-elective-api.service";
import { PayElectiveBankAccountsService } from "../../services/pay-elective-bank-accounts.service";
import { AccountFilter } from "./_models/account-filter.interface";
import { BreadcrumbsComponent } from "@shared/components/breadcrumbs/containers/breadcrumbs.component";
import { PayElectiveListSearchComponent } from "./pay-elective-list-search/pay-elective-list-search.component";
import { PaginationOptions } from "@shared/constants/pagination";
import { PaginationData } from "@store/models/pagination.model";
import { PaginationService } from "@shared/components/pagination/_services/pagination.service";

@Component({
	selector: "app-pay-elective-master-data",
	templateUrl: "./pay-elective-master-data.component.html",
	styleUrls: ["./pay-elective-master-data.component.scss"]
})
export class PayElectiveMasterDataComponent implements OnInit, OnDestroy {
	@ViewChild(BreadcrumbsComponent) breadCrumbs!: BreadcrumbsComponent;
	@ViewChild(PayElectiveListSearchComponent) payElectiveSearch!: PayElectiveListSearchComponent;

	destroy$: Subject<void> = new Subject();
	employees$: Observable<Employee[]> = new Observable<Employee[]>();
	selectedCustomerId: string = "";
	selectedLegalEntityId: string = "";
	selectedPayGroupId: string = "";
	status: string = "";
	provider: string = "";
	providers$: Observable<SelectOption[]> = new Observable<SelectOption[]>();
	payGroup$: Observable<PayGroup> = new Observable<PayGroup>();
	_customerName!: string;
	_payGroupName!: string;
	_country!: string;

	canCreateEmployee: boolean = false;

	providersAccountErrors: Map<string, { errorCount: number }> = new Map();

	//pagination
	pageIndex: number = 0;
	pageSize: number = 10;
	totalPageCount!: number;
	counts!: PayElectivePayGroupDataCounts;
	employeeReportCounts!: EmployeeReportPayGroupData;
	totalEmployees: number = 0;
	employeesDropDownData!: DropDownDataExpanded[];
	isFromGlobalDashboard = false;
	externalCrumbs: ExternalCrumb[] = [];

	//Search and Filter
	searched: string = "";
	hasBank: boolean | null = null;
	hasCard: boolean | null = null;
	breadCrumbIcon: breadCrumbButtonIcon = {
		name: "add",
		lineWidth: "2.5",
		color: "var(--color-primary-1000)",
		size: "16"
	};
	canSeeMap = false;
	defaultStatus: string = "all";
	paginationType: PaginationOptions;

	private stateSubscription!: Subscription;

	constructor(
		private payElectiveApiService: PayElectiveApiService,
		private store: Store<AppState>,
		private router: Router,
		private serviceProviderService: ServiceProviderService,
		private permissions: PermissionsService,
		private payElectiveAccountService: PayElectiveBankAccountsService,
		private payGroupApiService: ApiService,
		private paginationService: PaginationService
	) {
		this.paginationType = PaginationOptions.EMPLOYEES;
	}

	ngOnInit(): void {
		this.getPEState();

		this.permissions
			.canCreateEmployee()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => (this.canCreateEmployee = res));

		this.permissions
			.canSeeMapAndIsClient()
			.pipe(takeUntil(this.destroy$))
			.subscribe((res: { isClient: boolean; canSeeMap: boolean }) => {
				this.canSeeMap = res.canSeeMap;
			});

		this.payElectiveAccountService.reProcessComplete$.pipe(takeUntil(this.destroy$)).subscribe(completed => {
			if (completed) {
				this.getCounts(this.selectedCustomerId, this.selectedLegalEntityId, this.selectedPayGroupId, true);
			}
		});

		this.setupPaginationSubscription();
	}
	resetVeriables() {
		this.provider = "";
	}

	getPEState() {
		this.store.pipe(takeUntil(this.destroy$), select(getCustomerEntityGroupState)).subscribe(state => {
			if (state && state.customerId && state.legalEntityId && state.payGroupId) {
				this.selectedCustomerId = state.customerId;
				this.selectedLegalEntityId = state.legalEntityId;
				this.selectedPayGroupId = state.payGroupId;
				this.resetVeriables();
				this.getPayGroup(state.payGroupId);

				if (this.router.url === "/global-dashboard/master-data") {
					this.getSelectedKPI(state.customerId, state.legalEntityId, state.payGroupId);
					this.isFromGlobalDashboard = true;
				} else {
					this.getCounts(state.customerId, state.legalEntityId, state.payGroupId, true);
					this.isFromGlobalDashboard = false;
				}
			}
		});
	}
	getSelectedKPI(customerId: string, legalEntityId: string, paygroupId: string): void {
		this.store.pipe(takeUntil(this.destroy$), select(getGlobalDashboardFilterState)).subscribe(state => {
			if (state?.globalDashboard?.kpiSelected !== undefined) {
				this.setupPageWithGlobalDashboardState(
					state.globalDashboard.kpiSelected,
					customerId,
					legalEntityId,
					paygroupId
				);
			} else {
				this.router.navigate(["/global-dashboard"]);
			}
		});
	}

	setupPageWithGlobalDashboardState(
		kpiSelected: string,
		customerId: string,
		legalEntityId: string,
		paygroupId: string
	): void {
		switch (kpiSelected) {
			case "Failed Beneficiaries":
				{
					this.defaultStatus = "INACTIVE";
					this.status = "INACTIVE";
					this.getCounts(customerId, legalEntityId, paygroupId, true);
					this.externalCrumbs = [
						{ crumb: "Failed Beneficiaries", url: "/global-dashboard" },
						{ crumb: "Employee Data" }
					];
				}
				break;
			case "Missing Cards":
				{
					this.hasCard = false;
					this.getCounts(customerId, legalEntityId, paygroupId, false);
					this.externalCrumbs = [
						{ crumb: "Missing Cards", url: "/global-dashboard" },
						{ crumb: "Employee Data" }
					];
				}
				break;
			case "Missing Bank Acc.":
				{
					this.hasBank = false;
					this.getCounts(customerId, legalEntityId, paygroupId, false);
					this.externalCrumbs = [
						{ crumb: "Missing Bank Acc.", url: "/global-dashboard" },
						{ crumb: "Employee Data" }
					];
				}
				break;
			default:
				this.getCounts(customerId, legalEntityId, paygroupId, true);
		}
	}

	selectionChange(data: CustomerEntitySelection): void {
		this.selectedCustomerId = data.customerId;
		this.selectedLegalEntityId = data.legalEntityId;
		this.selectedPayGroupId = data.payGroupId;
		this.getEmployees();
	}

	getPayGroup(payGroupId: string): void {
		this.payGroup$ = this.payGroupApiService.getPayGroup(payGroupId);
	}

	fetchEmployeeData(): void {
		this.employees$ = this.payElectiveApiService
			.getEmployeesByPaygroupId(
				this.selectedPayGroupId,
				this.pageSize,
				this.pageIndex,
				this.provider,
				this.status,
				this.searched,
				this.hasBank,
				this.hasCard
			)
			.pipe(
				takeUntil(this.destroy$),
				map(response => {
					this.totalPageCount = response.totalPages;
					this.totalEmployees = response.totalCount;
					this.getEmloyeesListForBreadCrumbDropDown(this.selectedPayGroupId);
					this.setChildZindex(response.items.length);
					return response.items;
				})
			);
	}

	/**
	 * Set z-index of other components from employee list
	 * @param items: number of items displayed on the employee list
	 */
	setChildZindex(items: number): void {
		const zIndex = 5;

		if (this.breadCrumbs) {
			this.breadCrumbs.zIndex = zIndex + items;
		}
		this.payElectiveSearch.zIndex = zIndex + items;
	}

	getEmployees(): void {
		if (!this.selectedPayGroupId) {
			this.employees$ = of([]);
		} else {
			this.fetchEmployeeData();
		}
	}

	getList(searchValue: string): void {
		this.searched = searchValue;
		this.fetchEmployeeData();
	}

	getFilteredList(accFilter: AccountFilter): void {
		this.hasBank = accFilter.bankFilter;
		this.hasCard = accFilter.cardFilter;
		this.fetchEmployeeData();
	}

	getCounts(customerId: string, legalEntityId: string, paygroupId: string, fecthEmployees: boolean) {
		this.payElectiveApiService
			.getEmployeeReport(customerId, paygroupId)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: res => {
					this.employeeReportCounts = res.items[0] ? res.items[0] : ({} as EmployeeReportPayGroupData);
					this.providersAccountErrors.clear();
					this.getTypeDefinitions(legalEntityId, fecthEmployees);
				}
			});
	}

	getTypeDefinitions(entityId: string, fecthEmployees: boolean) {
		this.providers$ = this.serviceProviderService.getProviderNamesByEntityId(entityId).pipe(
			takeUntil(this.destroy$),
			map(_providers => {
				if (_providers.length > 0) {
					_providers.filter(provider => {
						if (provider.text.toLowerCase().includes("checkout")) {
							this.providersAccountErrors.set(provider.value, {
								errorCount:
									this.employeeReportCounts.statusCountsByProvider?.["CHECKOUT"]?.INACTIVE ?? 0
							});

							if (this.provider === "") {
								this.provider = provider.value;
							}
						}
						if (provider.text.toLowerCase().includes("ebury")) {
							this.providersAccountErrors.set(provider.value, {
								errorCount: this.employeeReportCounts.statusCountsByProvider?.["EBURY"]?.INACTIVE ?? 0
							});
							if (this.provider === "") {
								this.provider = provider.value;
							}
						}
						if (provider.text.toLowerCase().includes("transfermate")) {
							this.providersAccountErrors.set(provider.value, {
								errorCount:
									this.employeeReportCounts.statusCountsByProvider?.["TRANSFERMATE"]?.INACTIVE ?? 0
							});
							if (this.provider === "") {
								this.provider = provider.value;
							}
						}
					});
				}

				if (fecthEmployees) this.getEmployees();
				return _providers;
			})
		);
	}

	filtersChanged(searchString: string): void {
		this.status = searchString;
		this.getEmployees();
		this.resetPagination();
	}

	providerChanged(provider: string): void {
		this.provider = provider;
		this.getEmployees();
		this.resetPagination();
	}

	createPayElective() {
		if (this.isFromGlobalDashboard) {
			this.router.navigate(["/global-dashboard/employee"], {
				state: {
					employee: null,
					customerName: this._customerName,
					employeesDropDownData: this.employeesDropDownData
				}
			});
		} else {
			this.router.navigate(["/employee-data/employee"], {
				state: {
					employee: null,
					customerName: this._customerName,
					employeesDropDownData: this.employeesDropDownData
				}
			});
		}
	}

	getEmloyeesListForBreadCrumbDropDown(payGroupId: string): void {
		this.payElectiveApiService
			.getEmployeesByPaygroupId(
				payGroupId,
				-1,
				0,
				this.provider,
				this.status,
				this.searched,
				this.hasBank,
				this.hasCard
			)
			.pipe(
				takeUntil(this.destroy$),
				map(res =>
					res.items.map(
						user =>
							({
								id: user.id,
								name: `${user.data.firstName} ${user.data.lastName}`,
								externalId: user.externalId
							} as DropDownDataExpanded)
					)
				)
			)
			.subscribe({
				next: resultArray => {
					this.employeesDropDownData = resultArray;
				}
			});
	}

	goHome(): void {
		this.router.navigate(["/employee-data"]);
	}

	ngOnDestroy(): void {
		if (this.stateSubscription) {
			this.stateSubscription.unsubscribe();
		}

		this.destroy$.next();
		this.destroy$.complete();
	}

	private setupPaginationSubscription(): void {
		this.store.pipe(select(getPagination), takeUntil(this.destroy$)).subscribe(pagination => {
			if (pagination && pagination.hasOwnProperty("EMPLOYEES")) {
				const employeePagination = pagination["EMPLOYEES"];
				this.pageSize = employeePagination.pageSize;
				this.pageIndex = employeePagination.pageIndex;
				this.fetchEmployeeData();
			}
		});
	}

	private resetPagination(): void {
		const paginationSelection: PaginationData = {
			pageSize: 10,
			pageIndex: 0
		};

		this.paginationService.updatePaginationSate(this.paginationType, paginationSelection);
	}
}
