<sliding-panel class="sliding-panel" [exit]="exit">
	<div class="main-container">
		<div class="header-container">
			<div class="title-container">
				<div class="title-container-left">
					<span class="panel-title-text" *ngIf="historyType === HistoryLogType.MILESTONE"
						>{{ this.panelTitle }}
						<span class="panel-title-text sub-title-text">
							<app-icon
								class="type-icon"
								[name]="(milestoneIdentifierData.milestoneType | milestoneTypeString).icon"
								[size]="'28px'"
								[color]="'var(--color-disabled)'"
							>
							</app-icon>

							{{ (milestoneIdentifierData.milestoneType | milestoneTypeString).name }}</span
						></span
					>

					<span class="panel-title-text-black" *ngIf="historyType === HistoryLogType.TRANSACTION">
						{{ this.panelTitle }}
					</span>
				</div>
				<div class="title-container-exit" (click)="resetSubjects()">
					<span>
						<app-icon
							class="close-btn"
							[name]="'close'"
							[color]="'var(--color-text-default)'"
							[size]="'45'"
							[lineWidth]="'1px'"
						></app-icon>
					</span>
				</div>
			</div>
			<div class="customer-cycle-info-container" *ngIf="historyType === HistoryLogType.MILESTONE">
				<span class="customer-cycle-info-title-text"
					>{{ milestoneIdentifierData.customerExternalId }} - {{ milestoneIdentifierData.customerName }} -
					{{ milestoneIdentifierData.payGroupId | paygroupidToLegalEntityId | async }}
					/ {{ milestoneIdentifierData.paygroupName }} / {{ milestoneIdentifierData.cycleName }} / Group
					{{ milestoneIdentifierData.group }}</span
				>
			</div>
		</div>

		<form [formGroup]="searchForm">
			<mat-form-field id="historySearch" appearance="outline">
				<app-icon [name]="'search-normal-1'" matPrefix style="padding-right: 10px"></app-icon>
				<input matInput placeholder="Search by" formControlName="searchControl" />
			</mat-form-field>
		</form>

		<ng-container *ngIf="statusHistoryRecordsFilterCopy.length > 0 && HistoryLogType.MILESTONE">
			<div class="log-records-main-container" *ngFor="let historyRecord of statusHistoryRecordsFilterCopy">
				<div class="log-records-container">
					<div class="log-records-container-left">
						<div class="log-records-container-time">
							<span class="log-item-time">
								{{ historyRecord.createdAt | date : "HH:mm" }}
								{{ historyRecord.createdAt | date }}</span
							>
						</div>
						<div class="log-records-container-status">
							<span
								[ngClass]="{'status-dot-complete': historyRecord.statusTo === 'COMPLETE',
                    'status-dot-inprogress' :historyRecord.statusTo === 'IN_PROGRESS',
                    'status-dot-pending' :historyRecord.statusTo === 'PENDING',
                    'status-dot-errors' :historyRecord.statusTo === 'ERROR',
                 }"
							></span>

							<span class="history-log-item log-item-black">
								{{ historyRecord.statusTo }}
							</span>
						</div>
					</div>
					<div class="log-records-container-right">
						<span class="history-log-item log-item-black">
							<span
								class="log-item-blue"
								*ngIf="historyRecord.userId | userFullName | async as fullName; else emptyUser"
							>
								{{ fullName }}
							</span>
							<ng-template #emptyUser>
								<span class="log-item-blue">System</span>
							</ng-template>

							has changed the status to <span class="log-item-blue">{{ historyRecord.statusTo }}</span> on
							<span class="log-item-blue">{{ historyRecord.createdAt | ordinalDate }}</span> at
							<span class="log-item-blue">{{ historyRecord.createdAt | date : "HH:mm" }}</span
							>.
						</span>
					</div>
				</div>
				<mat-divider />
			</div>
		</ng-container>

		<ng-container
			*ngIf="statusHistoryRecordsFilterCopyTransactions.length > 0 && historyType === HistoryLogType.TRANSACTION"
		>
			<div
				class="log-records-main-container"
				*ngFor="let historyRecord of statusHistoryRecordsFilterCopyTransactions"
			>
				<div class="log-records-container">
					<div class="log-records-container-left">
						<div class="log-records-container-time">
							<span class="log-item-time">
								{{ historyRecord.createdAt | date : "HH:mm" }}
								{{ historyRecord.createdAt | date }}</span
							>
						</div>
						<div class="log-records-container-status">
							<span
								[ngClass]="{
									'status-green':
										(historyRecord.status.name | toString) === 'SUCCESS' ||
										(historyRecord.status.name | toString) === 'PROCESSED',
                                       
									'status-red':
										(historyRecord.status.name | toString) === 'FAILED' ||
										(historyRecord.status.name | toString) === 'ATTEMPTS_LIMIT_EXCEEDED' ||
										(historyRecord.status.name | toString) === 'FAILED_TO_BOOK' ||
										(historyRecord.status.name | toString) === 'ERROR',
									'status-pending':
										(historyRecord.status.name | toString) === 'PENDING' ||
										(historyRecord.status.name | toString) === 'PROCESSING',

									'status-booked': (historyRecord.status.name | toString) === 'BOOKED' || (historyRecord.status.name | toString) === 'REPROCESSED' ,
									'status-cancelled': (historyRecord.status.name | toString) === 'CANCELLED',
									'status-created': (historyRecord.status.name | toString) === 'CREATED',
                                    'status-inactive': (historyRecord.status.name | toString) === 'INACTIVE',
								}"
							></span>

							<span class="history-log-item log-item-black">
								{{ historyRecord.status.name | transactionStatuses }}
							</span>
						</div>
					</div>
					<div class="log-records-container-right">
						<div>
							<span class="history-log-item log-item-grey">
								Transaction ID: {{ historyRecord.transactionId }}
							</span>
						</div>
						<div>
							<span class="history-log-item log-item-black">
								<span
									class="log-item-blue"
									*ngIf="
										historyRecord.createdBy !== '' &&
											(historyRecord.status.name.toString() === 'INACTIVE' ||
												historyRecord.status.name.toString() === 'REPROCESSED');
										else emptyUser
									"
								>
									{{ historyRecord.createdBy | userFullName | async }}
								</span>
								<ng-template #emptyUser>
									<span class="log-item-blue">System</span>
								</ng-template>

								has changed the transaction state of
								<span class="log-item-blue"
									>{{ historyRecord.employeeFirstName + " " + historyRecord.employeeLastName }}
								</span>
								with the id <span class="log-item-blue">{{ historyRecord.employeeExternalId }}</span> to
								<span class="log-item-blue">{{ historyRecord.status.name | transactionStatuses }}</span
								>.
							</span>
						</div>

						<div>
							<span
								class="history-log-item log-item-grey"
								*ngIf="
									!historyRecord.status.details ||
									(historyRecord.status.details &&
										isEmptyObject(historyRecord.status.details.attributes))
								"
								><span *ngIf="historyRecord.status.message"
									>Reason: {{ historyRecord.status.message }}</span
								>
							</span>
							<span
								class="history-log-item log-item-grey"
								*ngIf="
									historyRecord.status.details &&
									historyRecord.status.details?.attributes &&
									!isEmptyObject(historyRecord.status.details.attributes)
								"
							>
								Reason:

								<span
									class="history-log-item log-item-grey"
									*ngFor="let attribute of historyRecord.status.details?.attributes | keyvalue"
								>
									<span>
										<strong>{{ attribute.key!.toString() | formatString }}</strong>
										: {{ attribute.value }}
									</span>

									<span *ngIf="isArray(attribute.value!.toString())">
										<span
											*ngFor="let tag of getArrayValue(attribute.value!.toString())"
											class="details-items"
										>
											<strong> {{ attribute.key!.toString() | formatString }}: </strong>
											{{ tag | formatString }}
										</span>
									</span>
								</span>
							</span>
						</div>
					</div>
				</div>
				<mat-divider />
			</div>
		</ng-container>

		<ng-container
			*ngIf="
				this.statusHistoryRecordsFilterCopyTransactions.length === 0 &&
				statusHistoryRecordsFilterCopy.length === 0
			"
		>
			<no-items-layout message="No records to display"></no-items-layout>
		</ng-container>
	</div>
</sliding-panel>
