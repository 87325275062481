import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Subject, lastValueFrom } from "rxjs";
import { debounceTime, distinctUntilChanged, takeUntil } from "rxjs/operators";
import { RoleHistory, User } from "../../_models/user-management-api.interface";
import { UserService } from "../../_services/user.service";

@Component({
	selector: "app-user-log-list",
	templateUrl: "./user-log-list.component.html",
	styleUrls: ["./user-log-list.component.scss"]
})
export class UserLogListComponent implements OnInit, OnDestroy {
	@Input() user!: User;
	roles: string[] = [];
	searchFormLog!: FormGroup;
	destroy$ = new Subject<void>();
	logHistoryList: RoleHistory[] = [];

	@Output() closeLog: EventEmitter<boolean> = new EventEmitter<boolean>();

	constructor(private userService: UserService, private fb: FormBuilder) {}

	ngOnInit(): void {
		if (this.user.roleHistory) {
			this.roles = this.userService.roles;
			this.introducdeAutorNameToLog();
		}

		this.initSearchFormLog();
	}

	initSearchFormLog() {
		this.searchFormLog = this.fb.group({
			searchLog: [""]
		});

		this.searchFormLog
			.get("searchLog")
			?.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(400), distinctUntilChanged())
			.subscribe(searchValueLog => {
				this.searchBy(searchValueLog);
			});
	}

	getAuthorOfChanges(id: string): Promise<User> {
		return lastValueFrom(this.userService.getUserById(id));
	}

	introducdeAutorNameToLog() {
		this.user.roleHistory?.map(async (log: RoleHistory) => {
			if (log.author === "AuthService") {
				log.authorName = "System";
			} else {
				const userInfo: User = await this.getAuthorOfChanges(log.author);
				log.authorName = `${userInfo.firstName} ${userInfo.lastName}`;
			}

			log.roleAction = this.setActionRole(log);

			return log;
		});

		if (this.user.roleHistory && this.user.roleHistory.length) {
			this.user.roleHistory.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
		}

		this.logHistoryList = this.user.roleHistory ?? [];
	}

	searchBy(authorName: string) {
		if (this.logHistoryList && this.logHistoryList.length > 0 && authorName)
			this.user.roleHistory = this.logHistoryList.filter((log: RoleHistory) =>
				log.authorName?.toLowerCase().includes(authorName.toLowerCase())
			);

		if (!authorName) this.user.roleHistory = this.logHistoryList;
	}

	setActionRole(log: RoleHistory): string {
		let rolAction: string = "";
		if (log.rolesAdded.length > 0 && log.rolesRemoved.length <= 0) rolAction = "Added role";
		if (log.rolesAdded.length <= 0 && log.rolesRemoved.length > 0) rolAction = "Removed role";
		if (log.rolesAdded.length > 0 && log.rolesRemoved.length > 0) rolAction = "Changed role";
		if (log.rolesAdded.length === 0 && log.rolesRemoved.length === 0) rolAction = "Altered role";
		if (log.type === "USER_CREATED") rolAction = "Created User";

		return rolAction;
	}

	ngOnDestroy(): void {
		//Called once, before the instance is destroyed.
		//Add 'implements OnDestroy' to the class.
		this.destroy$.complete();
	}
}
