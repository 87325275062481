import { PayCycle } from "src/app/shared/models/pay-cycle.interface";

export interface PayCycleYearPageable {
	year: number;
	hasNextYear: boolean;
	hasPreviousYear: boolean;
	payCycles: PayCycle[];
	filteredPayCycle: FilteredPayCycle;
}

export interface FilteredPayCycle {
	cycle: PayCycle;
	index: number; // References the index of payCycle in `payCycles` array.
	strategy: ActiveCycleStategy;
}

export enum ActiveCycleStategy {
	HAVING_ACTIVE_STATUS = "Having Active Status",
	FIRST_CYCLE_END_DATE_BEFORE_TODAY = "First Cycle End Date Before Today",
	FIRST_CYCLE_IN_CURRENT_MONTH = "First Cycle in Current Month",
	FIRST_CYCLE_IN_YEAR = "First Cycle in Year",
	LAST_CYCLE_IN_YEAR = "Last Cycle in Year",
	BY_PAY_CYCLE_ID = "By Pay Cycle Id",
	BY_PAY_CYCLE_ID_FALL_BACK_TO_LAST_CYCLE_IN_YEAR = "By Pay Cycle Id Fall Back to Last Cycle in Year"
}

export interface PayCyclePageError {
	message: string;
	type: ErrorType;
}

export enum ErrorType {
	NO_ACTIVE_PAYCYCLES
}

export function aNoPayCyclesExistError(): PayCyclePageError {
	return {
		type: ErrorType.NO_ACTIVE_PAYCYCLES,
		message: "This Pay Group is missing a calendar."
	};
}

export function isPayCylcePageError(value: PayCycleYearPageable | PayCyclePageError): boolean {
	if ("message" in value && "type" in value) return true;
	return false;
}

export function isPayCycleYearPageable(value: PayCycleYearPageable | PayCyclePageError): boolean {
	return !isPayCylcePageError(value);
}
