<div class="filters" [formGroup]="form">
	<mat-form-field class="filters-search" id="error-search-field" appearance="outline">
		<app-icon
			id="search"
			[name]="'search-normal-1'"
			[size]="'20'"
			[lineWidth]="'2px'"
			[color]="'var(--color-search-icon)'"
			matPrefix
		></app-icon>
		<input
			id="searchErrors"
			class="filters-search-input"
			matInput
			placeholder="Search Errors"
			formControlName="searchString"
		/>
	</mat-form-field>

	<div class="filters-wrapper">
		<!-- Error Types -->
		<input-wrapper id="providers" class="filters-input flex-1" style="--max-width: 60%">
			<input-select
				[label]="'Error Type'"
				[resetOption]="true"
				[placeholder]="'Error Type'"
				formControlName="errorType"
				[options]="errorTypes | async"
			>
			</input-select>
		</input-wrapper>

		<!-- providers -->
		<input-wrapper id="providers" class="filters-input flex-1" style="--max-width: 60%">
			<input-select
				[label]="'Providers'"
				[resetOption]="true"
				[placeholder]="'Providers'"
				formControlName="provider"
				[options]="providers | async"
			>
			</input-select>
		</input-wrapper>
	</div>
</div>
