import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { CustomerEntitySelection } from "src/app/shared/models/customer-entity-selection.interface";
import { CustomerEntityGroupSelect } from "src/app/store/models/customerEnitityGroupSelection.model";
import { extractOptionValue } from "../../wpay-ui/base-components/wpay-auto-complete/util/AutoCompleteUtil";
import { SelectOption } from "@shared/models/select-option.interface";

@Injectable({
	providedIn: "root"
})
export class CustomerEntitySelectFormService {
	readonly CUSTOMER_ID = "customerId";
	readonly LEGAL_ENTITY_ID = "legalEntityId";
	readonly PAY_GROUP_ID = "payGroupId";
	readonly PAY_GROUP_SEARCH = "payGroupSearch";

	constructor(private formBuilder: FormBuilder) {}

	aEmptyForm(): FormGroup {
		return this.formBuilder.group({
			customerId: [""], // <string | SelectOption>
			legalEntityId: [""], // <string | SelectOption>
			payGroupId: [""], // <string | SelectOption>
			payGroupSearch: [""]
		});
	}

	aFormFromState(state: CustomerEntityGroupSelect) {
		return this.formBuilder.group({
			customerId: [state.customerId], // <string | SelectOption>
			legalEntityId: [state.legalEntityId], // <string | SelectOption>
			payGroupId: [state.payGroupId], // <string | SelectOption>
			payGroupSearch: [""]
		});
	}

	/**
	 * Given the form group that represents the selected filtered Customer Entity combination.
	 * Sanitize the values (Strip) and return a CustomerEntitySelection object.
	 * @param form
	 * @returns A CustomerEntitySelection object, where "ALL_OPTIONS" is replaced with a blank string.
	 */
	toCustomerEntitySelection(form: FormGroup): CustomerEntitySelection {
		return {
			customerId: extractOptionValue(form.get(this.CUSTOMER_ID)?.value, this.CUSTOMER_ID),
			legalEntityId: extractOptionValue(form.get(this.LEGAL_ENTITY_ID)?.value, this.LEGAL_ENTITY_ID),
			payGroupId: extractOptionValue(form.get(this.PAY_GROUP_ID)?.value, this.PAY_GROUP_ID),
			payGroupSearchQuery: extractOptionValue(form.get(this.PAY_GROUP_SEARCH)?.value, this.PAY_GROUP_SEARCH)
		} as CustomerEntitySelection;
	}

	getCustomerName(customerId: string, options: SelectOption[]): string {
		if (customerId === "ALL_OPTIONS" || !customerId) {
			return "";
		} else {
			const customerName: string = options.filter(option => option.value === customerId)[0].text.split(" - ")[1];
			return customerName;
		}
	}
}
