<sliding-panel class="sliding-panel-view-history-log-details">
	<!-- Header -->
	<div class="header">
		<div class="title-section">
			<div class="text">
				<span>History Log</span>
			</div>
		</div>

		<div class="close-button" (click)="closeLog.emit()">
			<app-icon
				[name]="'close'"
				[color]="'var(--color-text-default)'"
				[size]="'65'"
				[lineWidth]="'1px'"
			></app-icon>
		</div>
	</div>

	<div class="log-search" [formGroup]="searchFormLog">
		<mat-form-field id="searchLogfield" appearance="outline">
			<app-icon [name]="'search-normal-1'" size="17" matPrefix></app-icon>
			<input id="serchLog" matInput placeholder="Search by" formControlName="searchLog" />
		</mat-form-field>
	</div>

	<div class="role-history-container">
		<ng-container *ngIf="user.roleHistory && user.roleHistory.length > 0; else noItems">
			<div *ngFor="let history of user.roleHistory">
				<div class="log-contanier flex flex-row">
					<div class="flex flex-column flex-align-center-center flex-1" style="--max-width: 25%">
						<p class="date-style">{{ history.createdAt | date : "HH:mm MMMM dd, yyyy" }}</p>
						<div class="rolAction-style flex flex-row flex-align-center-center">
							<app-icon
								*ngIf="history.roleAction === 'Created User'"
								[color]="'#767676'"
								[size]="'11'"
								[name]="'user-add'"
							></app-icon>
							<app-icon
								*ngIf="history.roleAction === 'Added role'"
								[color]="'#767676'"
								[size]="'11'"
								[name]="'add_circle_outline'"
							></app-icon>
							<app-icon
								*ngIf="history.roleAction === 'Removed role'"
								[color]="'#767676'"
								[size]="'10'"
								[name]="'slash_outline'"
							></app-icon>
							<app-icon
								*ngIf="history.roleAction === 'Changed role'"
								[color]="'#767676'"
								[size]="'10'"
								[name]="'shuffle_outline'"
							></app-icon>

							<div class="action-style">{{ history.roleAction }}</div>
						</div>
					</div>
					<div class="container-log-body flex-1" style="--max-width: 75%">
						<span class="ticket-style">Ticket {{ history.ticketNr }}</span>

						<div class="user-created-container" *ngIf="history.type === 'USER_CREATED'">
							<span class="author-color"
								><b>{{ history.authorName }}</b></span
							>&nbsp;

							<span class="text-color">created user</span>&nbsp;

							<span class="author-color"
								><b>{{ user.firstName }} {{ user.lastName }}</b></span
							>

							&nbsp;<span>with the</span>&nbsp;

							<ng-container *ngFor="let rolAdd of history.rolesAdded | roles; let last = last">
								<span
									*ngIf="history.rolesAdded.length === 1"
									[ngClass]="'colors-rol-' + rolAdd.value"
									class="role-item"
									>{{ rolAdd.text }}
								</span>
								<ng-container *ngIf="history.rolesAdded.length > 1">
									<span *ngIf="last" class="text-color">&nbsp;and &nbsp;</span>
									<span
										[ngClass]="'colors-rol-' + rolAdd.value"
										class="role-item"
										[matTooltip]="rolAdd.text"
										>{{ rolAdd.placeholder }}
									</span>
								</ng-container>
							</ng-container>
							<span *ngIf="history.rolesAdded.length === 1">role</span>
							<span *ngIf="history.rolesAdded.length > 1">roles</span>
						</div>

						<div class="role-changes-container" *ngIf="history.type === 'ROLES_CHANGED'">
							<span class="author-color"
								><b>{{ history.authorName }}</b></span
							>&nbsp;

							<ng-container *ngIf="history.rolesAdded.length > 0 && history.rolesRemoved.length <= 0">
								<span class="text-color">added the</span>&nbsp;
								<ng-container *ngFor="let rolAdd of history.rolesAdded | roles; let last = last">
									<span
										*ngIf="history.rolesAdded.length === 1"
										[ngClass]="'colors-rol-' + rolAdd.value"
										class="role-item"
										>{{ rolAdd.text }}
									</span>
									<ng-container *ngIf="history.rolesAdded.length > 1">
										<span *ngIf="last" class="text-color">&nbsp;and &nbsp;</span>
										<span
											[ngClass]="'colors-rol-' + rolAdd.value"
											class="role-item"
											[matTooltip]="rolAdd.text"
											>{{ rolAdd.placeholder }}
										</span>
									</ng-container>
								</ng-container>
							</ng-container>

							<ng-container *ngIf="history.rolesRemoved.length > 0 && history.rolesAdded.length <= 0">
								<span class="text-color">removed the</span>&nbsp;
								<ng-container *ngFor="let rolRemove of history.rolesRemoved | roles; let last = last">
									<span
										*ngIf="history.rolesRemoved.length === 1"
										[ngClass]="'colors-rol-' + rolRemove.value"
										class="role-item"
										>{{ rolRemove.text }}
									</span>
									<ng-container *ngIf="history.rolesRemoved.length > 1">
										<span *ngIf="last" class="text-color">&nbsp;and &nbsp;</span>
										<span
											[ngClass]="'colors-rol-' + rolRemove.value"
											class="role-item"
											[matTooltip]="rolRemove.text"
											>{{ rolRemove.placeholder }}
										</span>
									</ng-container>
								</ng-container>
							</ng-container>

							<ng-container *ngIf="history.rolesRemoved.length > 0 && history.rolesAdded.length > 0">
								<span class="text-color">removed the</span>&nbsp;
								<ng-container *ngFor="let rolRemove of history.rolesRemoved | roles; let last = last">
									<ng-container *ngIf="history.rolesRemoved.length > 1">
										<span class="text-color" *ngIf="last">and &nbsp;</span>
									</ng-container>
									<span
										[ngClass]="'colors-rol-' + rolRemove.value"
										class="role-item"
										[matTooltip]="rolRemove.text"
										>{{ rolRemove.placeholder }}
									</span>
								</ng-container>
								&nbsp;<span class="text-color">role and added the</span>&nbsp;
								<ng-container *ngFor="let rolAdd of history.rolesAdded | roles; let last = last">
									<ng-container *ngIf="history.rolesAdded.length > 1">
										<span *ngIf="last" class="text-color">and &nbsp;</span>
									</ng-container>
									<span
										[ngClass]="'colors-rol-' + rolAdd.value"
										class="role-item"
										[matTooltip]="rolAdd.text"
										>{{ rolAdd.placeholder }}
									</span>
								</ng-container>
							</ng-container>

							<ng-container *ngIf="history.rolesRemoved.length === 0 && history.rolesAdded.length === 0">
								<span class="text-color">altered the roles for</span>&nbsp;
							</ng-container>

							<span *ngIf="history.rolesRemoved.length !== 0 || history.rolesAdded.length !== 0"
								>role to </span
							><span class="author-color"
								><b>{{ user.firstName }} {{ user.lastName }}</b></span
							>
						</div>
						<div class="reason-style" *ngIf="history.reason">Reason: {{ history.reason }}</div>
					</div>
				</div>
			</div>
		</ng-container>

		<ng-template #noItems>
			<div class="no-records-found">
				<no-items-layout class="no-records-found-message" message="No records found"></no-items-layout>
			</div>
		</ng-template>
	</div>
</sliding-panel>
