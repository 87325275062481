import { Injectable } from "@angular/core";
import {
	TppServicesByByPayGroupAggregation,
	TtpServiceAggregate,
	TtpServiceByServiceTypeAggregate
} from "../facade/model/TppServicesByByPayGroupAggregation";
import * as _ from "lodash";
import { ApiTPPServiseDefinitionsSearchResponseV1 } from "@shared/services/api/payment-providers/tpp-service-definition/model/response/ApiTppServiceDefinitionsSearchResponseV1";
import {
	BeneficiaryStatus,
	BeneficiaryStatusByProviderSummary,
	TotalsByProvider
} from "src/app/shared/models/service-definition/TtpBeneficiaryStatusByProviderSummary";

@Injectable({
	providedIn: "root"
})
export class BeneficiaryStatusByProviderAggregationFilterService {
	constructor() {}

	filterAsViewModel(
		services: TppServicesByByPayGroupAggregation,
		response: ApiTPPServiseDefinitionsSearchResponseV1,
		providerName: string
	) {
		return this.toBeneficiaryStatusByProvider(services, response, providerName);
	}

	private toBeneficiaryStatusByProvider(
		services: TppServicesByByPayGroupAggregation,
		response: ApiTPPServiseDefinitionsSearchResponseV1,
		providerName: string
	): BeneficiaryStatusByProviderSummary {
		const statusesCounts = response.items[0].statusesCounts;

		// missing or empty provider name, use the first provider name
		if (!providerName) providerName = this.firstProviderNameOrEmpty(statusesCounts);

		const PROVIDER_ERRORS_EXCLUDING_SELECTED_PROVIDER = this.toServiceProviderErrors(statusesCounts).filter(
			p => p.providerName != providerName
		);

		return {
			beneficiaryStatus: this.toBeneficiaryStatus(services, providerName),
			totalsByProvider: PROVIDER_ERRORS_EXCLUDING_SELECTED_PROVIDER,
			namesOfProvidersAssignedToPaygroup: this.toListOfProviderNames(statusesCounts)
		} as BeneficiaryStatusByProviderSummary;
	}

	private toBeneficiaryStatus(services: TppServicesByByPayGroupAggregation, providerName: string): BeneficiaryStatus {
		var target = {
			totalValidBeneficiaries: 0,
			totalFailedBeneficiaries: 0,
			totalProcessingBeneficiaries: 0
		} as BeneficiaryStatus;

		services.servicesByServiceType.forEach((service: TtpServiceByServiceTypeAggregate) => {
			service.services.forEach((service: TtpServiceAggregate) => {
				var matchingProviderSummary = service.subServiceSummaryByProviderName.find(s => {
					return s.providerName === providerName;
				});
				if (matchingProviderSummary) {
					target.totalFailedBeneficiaries += matchingProviderSummary.totalFailedBeneficiaries;
					target.totalValidBeneficiaries += matchingProviderSummary.totalValidBeneficiaries;
					target.totalProcessingBeneficiaries += matchingProviderSummary.totalProcessingBeneficiaries;
				}
			});
		});

		return target;
	}

	private firstProviderNameOrEmpty(statusCounts: any): string {
		const totalsByProvider = this.toServiceProviderErrors(statusCounts);
		var providerNames: string[] = totalsByProvider.map(p => p.providerName);
		if (providerNames.length > 0) return providerNames[0];

		return "";
	}

	private toListOfProviderNames(statusCounts: any): string[] {
		const totalsByProvider = this.toServiceProviderErrors(statusCounts);

		return totalsByProvider.map(p => p.providerName);
	}

	private toServiceProviderErrors(statusesCounts: any): TotalsByProvider[] {
		return _.transform(
			statusesCounts,
			function (result: TotalsByProvider[], childObject: any, parentKey: string) {
				var beneficiaryByProvider = {} as TotalsByProvider;
				var providerName = parentKey;
				beneficiaryByProvider.providerName = providerName;
				beneficiaryByProvider.totalErrors = childObject.INACTIVE;
				beneficiaryByProvider.totalValidBeneficiaries = childObject.ACTIVE;

				result.push(beneficiaryByProvider);
			},
			[]
		);
	}
}
