<ng-container *ngIf="accessFields && accessFields.length > 0">
	<div class="form-section-title">Access fields</div>

	<div class="o-grid-3 full-width">
		<ng-container [formGroup]="accessFieldsForm">
			<input-wrapper *ngFor="let accessField of accessFields; let i = index" [label]="accessField.label">
				<input
					id="{{ accessField.key }}"
					[type]="accessField | fieldTypes"
					[placeholder]="accessField.name"
					[formControlName]="accessField.key"
				/>
				<div
					input-errors
					*ngIf="
						accessFieldsForm.controls[accessField.key].touched &&
						accessFieldsForm.controls[accessField.key].errors?.required
					"
				>
					{{ accessField.name }} is required
				</div>
			</input-wrapper>
		</ng-container>
	</div>
</ng-container>
