<div id="toast" class="snack-container">
	<div>
		<mat-icon
			[ngClass]="{
				success: data.snackType === 'success',
				danger: data.snackType === 'danger',
				warning: data.snackType === 'warning',
				info: data.snackType === 'info'
			}"
			>{{ getIcon }}</mat-icon
		>
	</div>
	<div>
		<div [innerHTML]="data.message"></div>
	</div>
</div>
