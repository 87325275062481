import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import {
	TppSubService,
	TppSubServiceDetails,
	TppSubServiceDetailsWithPagination,
	getEmployeeDataParams
} from "../../models/tpp-data.interface";
import { PageEvent } from "@angular/material/paginator";

@Component({
	selector: "app-view-subservice-details-side-pop-up",
	templateUrl: "./view-subservice-details-side-pop-up.component.html",
	styleUrls: ["./view-subservice-details-side-pop-up.component.scss"]
})
export class ViewSubserviceDetailsSidePopUpComponent implements OnInit {
	searchForm!: FormGroup;

	eventForm!: FormGroup;

	canEdit: boolean = true;

	exit: boolean = false;

	subService!: TppSubService;

	pageSize: number = 10;
	pageIndex: number = 0;
	totalCount: number = 0;
	totalPages: number = 0;

	@Input() set _subService(subService: TppSubService) {
		this.subService = subService;
	}

	@Input() set _pageSize(size: number) {
		this.pageSize = size;
	}

	@Input() set _pageIndex(index: number) {
		this.pageIndex = index;
	}

	subServiceDetails!: TppSubServiceDetails[];

	@Input() set _subserviceToViewDetails(details: TppSubServiceDetailsWithPagination) {
		this.subServiceDetails = details.items;
		this.totalPages = details.totalPages;
		this.pageIndex = details.pageNumber;
		this.totalCount = details.totalCount;
	}
	@Output() emitNewPaginition: EventEmitter<getEmployeeDataParams> = new EventEmitter<getEmployeeDataParams>();
	@Output() saveDetails: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
	@Output() cancelDetails: EventEmitter<boolean> = new EventEmitter<boolean>();

	constructor(private fromBuilder: FormBuilder) {}

	ngOnInit(): void {
		this.initSearch();
	}
	initSearch() {
		this.searchForm = this.fromBuilder.group({
			search: [""]
		});

		this.searchForm.get("search")?.valueChanges.subscribe(searchValue => {
			//filter data - emit
		});

		//temp
		this.eventForm = this.fromBuilder.group({
			search: [""]
		});
	}
	save() {
		this.saveDetails.emit();
	}

	cancel() {
		this.cancelDetails.emit(true);
	}

	paginate(pagination: PageEvent) {
		let paginition: getEmployeeDataParams = {
			subService: this.subService,
			pageSize: pagination.pageSize,
			pageNumber: pagination.pageIndex
		};

		this.emitNewPaginition.emit(paginition);
	}
}
