<!-- Items -->
<mat-card (click)="selectPayGroup()" class="card">
	<!-- Logo -->

	<!-- Customer Information -->
	<div class="information-container">
		<!-- Title -->

		<div
			class="left-side"
			[matTooltip]="payGroup.legalEntity.data.externalId + '/' + payGroup.data.name + '/' + payGroup.externalId"
			[matTooltipPosition]="'above'"
			[matTooltipClass]="'paygroup-list-item'"
		>
			<div *ngIf="payGroup.customer.imagePath" class="logo-container">
				<img src="{{ payGroup.customer.imagePath }}" class="logo" />
			</div>

			<span class="information-title legal-entity-flag">
				<img src="assets/svg-country-flags/svg/{{ payGroup.legalEntity.data.country.toLowerCase() }}.svg" />
			</span>

			<span class="information-title legal-entity-id"> {{ payGroup.legalEntity.data.externalId }}</span>
			<span class="information-title">/</span>
			<span class="information-title paygroup-name">{{ payGroup.data.name }}</span>
			<span class="information-title">/</span>
			<span class="information-title paygroup-id"> {{ payGroup.externalId }}</span>
		</div>
		<div class="status-date-container">
			<div class="information-subtitle" fxLayout="row">
				<span
					class="dot"
					[ngClass]="{
						implementation: payGroup.status === 'IMPLEMENTATION',
						live: payGroup.status === 'LIVE',
						inactive: payGroup.status === 'INACTIVE'
					}"
				>
				</span>
				<div class="information-subtitle-text">{{ payGroup.status }}</div>
			</div>
			<div class="information-subtitle" fxLayout="column" fxLayoutAlign="flex-start">
				<div class="date-title">Go Live Date</div>
				<div class="information-subtitle-text">{{ payGroup.data.goLiveAt | date }}</div>
			</div>
		</div>
	</div>
</mat-card>
