<div class="table-container" role="table" aria-label="Destinations">
	<!-- Table -->

	<!-- Header -->
	<div class="flex-table header" role="rowgroup">
		<div class="flex-row" role="columnheader">Payment Types</div>
		<div class="flex-row" role="columnheader">Headcount</div>
		<div class="flex-row" role="columnheader">PYT Acc</div>
		<div class="flex-row" role="columnheader">PYT Route</div>
		<div class="flex-row" role="columnheader">PYT Currency</div>
		<div class="flex-row" role="columnheader">Payment Fee</div>
		<div class="flex-row" role="columnheader">Monthly Fee</div>
		<div class="notes" role="columnheader">Add Notes</div>
	</div>
	<app-cost-edit-form [isSuperUser]="isSuperUser"></app-cost-edit-form>
</div>
