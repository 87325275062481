<div
	[fxLayout]="layout"
	class="tooltip-container"
	[ngClass]="{
		'position-top': position === 'top',
		'position-bottom': position === 'bottom',
		'position-right': position === 'right',
		'calendar-bottom': position === 'calendar-bottom',
		'calendar-top': position === 'calendar-top'
	}"
>
	<div
		*ngIf="position === 'bottom' || position === 'calendar-bottom'"
		class="triangle-top"
		[ngClass]="{
			addBorder: containerBorder
		}"
		[ngStyle]="{
			'border-bottom-color': contanierColor,
			'margin-left': trianlePosition,
			'background-color': containerBorder ? contanierColor : 'transparent'
		}"
	></div>
	<div
		*ngIf="position === 'right'"
		class="triangle-left"
		[ngClass]="{
			addBorder: containerBorder
		}"
		[ngStyle]="{
			'border-right-color': contanierColor,
			'margin-top': trianlePosition,
			'background-color': containerBorder ? contanierColor : 'transparent'
		}"
	></div>
	<div
		class="tooltip-content"
		[ngClass]="{ paragraph: paragraphStyle, flex: flexStyle, 'addBorder-content': containerBorder }"
		[ngStyle]="{
			color: fontColor,
			'font-size': fontSize,
			'background-color': contanierColor,
			padding: containerPadding,
			'min-width': containerMinWidth + 'px',
			'margin-left': containerMarginLeft,
			'margin-top': containerMarginTop
		}"
	>
		<ng-content> </ng-content>
	</div>
	<div
		*ngIf="position === 'top' || position === 'calendar-top'"
		class="triangle-bottom"
		[ngClass]="{
			addBorder: containerBorder
		}"
		[ngStyle]="{
			'border-top-color': contanierColor,
			'margin-left': trianlePosition,
			'background-color': containerBorder ? contanierColor : 'transparent'
		}"
	></div>
</div>
