import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppState } from "@auth0/auth0-angular";
import { Store } from "@ngrx/store";
import { BeneficiaryAccountStatusByPayGroup } from "@modules/employee-data/services/facade/model/BeneficiaryAccountStatusByPayGroup";
import { UpdateCustomerEntityGroupAction } from "src/app/store/actions/customerEntityGroupSelect.action";

@Component({
	selector: "app-pay-elective-pay-group-list-item",
	templateUrl: "./pay-elective-pay-group-list-item.component.html",
	styleUrls: ["./pay-elective-pay-group-list-item.component.scss"]
})
export class PayElectivePayGroupListItemComponent implements OnInit {
	@Input()
	accountStatusByPayGroup!: BeneficiaryAccountStatusByPayGroup;

	matTooltip = "";

	constructor(private router: Router, private store: Store<AppState>) {}

	ngOnInit(): void {
		this.matTooltip =
			this.accountStatusByPayGroup.externalLegalEntityId.toUpperCase() +
			"/" +
			this.accountStatusByPayGroup.payGroupName.toUpperCase() +
			"/" +
			this.accountStatusByPayGroup.externalPayGroupId.toUpperCase();
	}

	handlePayGroupClick() {
		this.dispatchPayGroupClickEvent();
		this.navigateToElectivesMasterData();
	}

	private dispatchPayGroupClickEvent() {
		this.store.dispatch(
			new UpdateCustomerEntityGroupAction({
				customerId: this.accountStatusByPayGroup.customerId,
				customerName: this.accountStatusByPayGroup.customerName,
				legalEntityId: this.accountStatusByPayGroup.legalEntityId,
				payGroupId: this.accountStatusByPayGroup.payGroupId
			})
		);
	}

	private navigateToElectivesMasterData() {
		this.router.navigate(["employee-data/master-data"], {
			state: {
				country: this.accountStatusByPayGroup.countryCode,
				customerName: this.accountStatusByPayGroup.customerName,
				legalName: this.accountStatusByPayGroup.legalEntityName
			}
		});
	}
}
