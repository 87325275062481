import { Injectable } from "@angular/core";
import { ServiceProviderService } from "@shared/services/service-provider/service-provider.service";
import { Definition, Definitions, DefinitionsData } from "src/app/shared/models/service-definition.interface";
import { PaymentRouteName, PaymentTypeName, PayoutAccountName } from "src/app/shared/models/service-provider.type";
import { DefinitionPOST, DefinitionsPOST, PaymentTypeDefinitionPOST } from "../../_models/definition-post.interface";
import { DefinitionPUT, DefinitionsPUT, PaymentTypeDefinitionPUT } from "../../_models/definition-put.interface";
import { DefinitionRoute } from "../../_models/definitions-by-type.interface";

@Injectable({
	providedIn: "root"
})
export class DefinitionsService {
	constructor(private serviceProviderService: ServiceProviderService) {}

	private _completedProcess: string = "";

	get completedProcess(): string {
		return this._completedProcess;
	}

	set completedProcess(value: string) {
		this._completedProcess = value;
	}

	formatPOST(definitions: Definitions): DefinitionsPOST {
		const definitionsPOST: DefinitionsPOST = {
			legalEntityId: definitions.legalEntityId,
			paymentTypeDefinitions: []
		};

		console.log(definitions, "defs");

		definitions.paymentTypeDefinitions.map((paymentTypeDefinition: DefinitionsData) => {
			// Set Payment Type Definition
			const paymentTypeDefinitionPOST: PaymentTypeDefinitionPOST = {
				paymentType: paymentTypeDefinition.paymentType,
				definitions: [],
				settings: paymentTypeDefinition.settings
			};

			// Set Definitions
			paymentTypeDefinition.definitions.map((defintion: Definition) => {
				const definitionPOST: DefinitionPOST = {
					route: defintion.route as PaymentRouteName,
					provider: defintion.provider,
					payoutAccount: defintion.payoutAccount as PayoutAccountName,
					default: defintion.default,
					settlementAccountIds: defintion.settlementAccountIds as string[],
					sourceAccountIds: defintion.sourceAccountIds as string[],
					priority: 1 // Temporal
				};

				paymentTypeDefinitionPOST.definitions.push(definitionPOST);
			});

			definitionsPOST.paymentTypeDefinitions.push(paymentTypeDefinitionPOST);
		});

		return definitionsPOST;
	}

	formatPUT(definitions: Definitions): DefinitionsPUT {
		const definitionsPUT: DefinitionsPUT = {
			id: definitions.id,
			legalEntityId: definitions.legalEntityId,
			version: definitions.version,
			paymentTypeDefinitions: []
		};

		definitions.paymentTypeDefinitions.map((paymentTypeDefinition: DefinitionsData) => {
			// Set Payment Type Definition
			const paymentTypeDefinitionPUT: PaymentTypeDefinitionPUT = {
				paymentType: paymentTypeDefinition.paymentType,
				definitions: [],
				settings: paymentTypeDefinition.settings
			};

			// Set Definitions
			paymentTypeDefinition.definitions.map((defintion: Definition) => {
				const definitionPUT: DefinitionPUT = {
					route: defintion.route as PaymentRouteName,
					provider: defintion.provider,
					payoutAccount: defintion.payoutAccount as PayoutAccountName,
					default: defintion.default,
					priority: 1, // Temporal
					settlementAccountIds: defintion.settlementAccountIds as string[],
					sourceAccountIds: defintion.sourceAccountIds as string[]
				};

				paymentTypeDefinitionPUT.definitions.push(definitionPUT);
			});

			definitionsPUT.paymentTypeDefinitions.push(paymentTypeDefinitionPUT);
		});

		return definitionsPUT;
	}

	formatDELETE(definitions: Definitions, definitionItem: any, definition: DefinitionRoute): Definitions {
		const currentType: PaymentTypeName = definitionItem.type;
		const currentProviderCountryId: string | undefined = definition.provider?.providerCountryId;
		const currentPayoutAccount: string | undefined = definition.payoutAccount;
		const currentRoute: string | undefined = definition.route;

		definitions.paymentTypeDefinitions.map((paymentTypeDefinition: DefinitionsData) => {
			if (paymentTypeDefinition.paymentType === currentType) {
				// Find index for the equal object
				const indexs: (number | undefined)[] = paymentTypeDefinition.definitions
					.map((d, i) => {
						if (
							d.provider?.providerCountryId === currentProviderCountryId &&
							d.payoutAccount === currentPayoutAccount &&
							d.route === currentRoute
						) {
							return i;
						} else {
							return undefined;
						}
					})
					.filter((index: number | undefined) => typeof index !== "undefined" && index >= 0);

				// Remove objects from the array
				if (typeof indexs[0] !== "undefined" && indexs[0] > -1) {
					paymentTypeDefinition.definitions.splice(indexs[0], 1);
				}
			}
		});

		// If some definitions array from paymentTypeDefinitions is empty, then we find index for the equal object
		const indexs: (number | undefined)[] = definitions.paymentTypeDefinitions
			.map((ptd, i) => {
				if ((ptd.definitions && ptd.definitions.length === 0) || !ptd.definitions) {
					return i;
				} else {
					return undefined;
				}
			})
			.filter((index: number | undefined) => typeof index !== "undefined" && index >= 0);

		// Remove objects from the array
		if (typeof indexs[0] !== "undefined" && indexs[0] > -1) {
			definitions.paymentTypeDefinitions.splice(indexs[0], 1);
		}

		return definitions;
	}
}
