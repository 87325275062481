import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map, shareReplay, take } from "rxjs/operators";

import { RoleFormatService } from "@shared/components/contacts/_services/roles-format.service";
import { Role, User, UserPagination } from "@modules/user-management/_models/user-management-api.interface";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { environment } from "src/environments/environment";
@Injectable({
	providedIn: "root"
})
export class UserApiService {
	constructor(private http: HttpClient, private roleFormat: RoleFormatService) {}

	usersSelectOption: Observable<SelectOption[]> = new Observable<SelectOption[]>();
	users: Observable<User[]> = new Observable<User[]>();

	positions: SelectOption[] = [
		{
			value: "CB Payroll Contact",
			text: "CB Payroll Contact"
		},
		{
			value: "CP Payroll Escalation",
			text: "CP Payroll Escalation"
		},
		{
			value: "Service Provider",
			text: "Service Provider"
		},

		{
			value: "Service Provider Escalation",
			text: "Service Provider Escalation"
		},

		{
			value: "Main Client",
			text: "Main Client"
		},

		{
			value: "Client Back Up",
			text: "Client Back Up"
		},

		{
			value: "Client Escalation",
			text: "Client Escalation"
		}
	];

	getRolesAsSelectOptions(): Observable<SelectOption[]> {
		return this.http.get<Role[]>(`${environment.apiUri}/v1/roles`).pipe(
			take(1),
			shareReplay(),
			map(roles => {
				return roles.flatMap(role => {
					const mappedRole = this.roleFormat.transform(role.name);

					let option: SelectOption;
					option = {
						value: role.name,
						text: mappedRole
					};

					return option;
				});
			})
		);
	}

	fetchUsers(): Observable<User[]> {
		return this.http.get<UserPagination>(`${environment.apiUri}/v1/users?pageSize=-1`).pipe(
			take(1),
			shareReplay(),
			map(users => {
				this.users;
				this.users = of(users.items);
				return users.items;
			})
		);
	}

	showUsersWithRoles(role: string) {
		return this.users.pipe(
			map(users => {
				return users.flatMap(user => {
					let option: SelectOption;
					return user.firstName && user.roles.includes(role)
						? (option = {
								value: user.id!,
								text: `${user.firstName} ${user.lastName}`
						  })
						: [];
				});
			})
		);
	}

	fetchFilteredUsersAsSelectOptions(users: User[], position: string): Observable<SelectOption[]> {
		return of(users).pipe(
			take(1),
			map(users => {
				return users.flatMap(user => {
					let option: SelectOption;
					return user.roles.includes(position)
						? (option = {
								value: user.id!,
								text: `${user.firstName} ${user.lastName}`
						  })
						: [];
				});
			})
		);
	}

	retrievUserFromList(users: User[], id: string): User {
		const user = users.find(user => user.id === id)!;
		return user;
	}
}
