import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { distinctUntilChanged, filter, map, take, takeUntil } from "rxjs/operators";
import { Router } from "@angular/router";

import { SelectOption } from "src/app/shared/models/select-option.interface";
import { DefinitionService, TPPServiceDefinitionState } from "src/app/shared/models/tpp-service.interface";
import { DataNewSourOfFun } from "../../../_models/defintion-group";
import { SourceFoundAccount } from "../../../_models/source-accounts.interface";
import { TppServiceDefinitionService } from "../../../_services/tpp-service-definition/tpp-service-definition.service";
import { TPPGroupSelectOptions } from "src/app/shared/models/tpp-service.interface";
import { InputAutocompleteComponent } from "@shared/components/input-autocomplete/input-autocomplete.component";
import { TppGroupConfigService } from "../../../_services/tpp-group-config/tpp-group-config.service";
import { TppGroupConfigurationStore } from "../../../_services/store/tpp-group-configuration.service";
import { PermissionsService } from "@shared/services/permissions/permissions.service";
import { OnDestroy } from "@angular/core";

const PAYMENT_TYPE = "TPP";

@Component({
	selector: "app-tpp-service-source-fund",
	templateUrl: "./tpp-service-source-fund.component.html",
	styleUrls: ["./tpp-service-source-fund.component.scss"]
})
export class TppServiceSourceFundComponent implements OnInit, AfterViewInit, OnDestroy {
	@Output() openSourceOfFund = new EventEmitter<boolean>();
	@Output() continue = new EventEmitter<string>();
	@Output() groupChanged = new EventEmitter<string>();
	@Output() currencyChanged = new EventEmitter<string>();

	selectedLegalEntityId: string = "";
	@Input() set _selectedLegalEntityId(id: string) {
		this.selectedLegalEntityId = id;
	}
	selectedPayGroupId: string = "";
	_previousPaygroupId: string = "";

	@Input() set _selectedPayGroupId(id: string) {
		if (id !== this._previousPaygroupId) {
			this._previousPaygroupId = id;
			this.resetForm();
		}
		this.selectedPayGroupId = id;
	}

	serviceDefinition$: Observable<TPPServiceDefinitionState> = new Observable<TPPServiceDefinitionState>();

	currencies: Observable<SelectOption[]> = new Observable<SelectOption[]>();
	@Input() set _currencies(options: Observable<SelectOption[]>) {
		this.currencies = options;
	}

	_groupToConfig: string = "";

	@Input() set groupToConfig(group: string) {
		this._groupToConfig = group;
	}

	get groupToConfig(): string {
		return this._groupToConfig;
	}

	_sourceAccountSelectOptions: SelectOption[] = [];

	@Input() set sourceAccountSelectOptions(value: SelectOption[]) {
		this._sourceAccountSelectOptions = value;
	}

	_subServiceNameList: string[] = [];
	@Input() set subServiceNameList(val: string[]) {
		this._subServiceNameList = val;
	}

	@ViewChild("existingAccount") autocomplete!: InputAutocompleteComponent;

	tppServicesText = "TPP Services";
	groupName: string = "";
	sourceOfFundId: string = "";
	form!: FormGroup;
	formTppGroup!: FormGroup;
	tppGroupSelectOptions: SelectOption[] = [];
	sourceOfFundsList!: SourceFoundAccount[];
	btnDisabled: boolean = true;
	btnViewSourceOffAccount = false;
	disableFormTppInputs = true;
	selectedCurrency: string = "";
	addSourceAccountButtonEnabled: boolean = false;
	editSourceAccountButtonEnabled: boolean = false;

	groupChosen: boolean = false;
	private destroy$: Subject<void> = new Subject<void>();
	dataFormSourceFund!: DataNewSourOfFun;
	tppSubServicesNameList: string[] = [];
	stepOneData!: {
		paymentCurrency: string;
		existingAccount: string;
		tppGroup: string;
	};

	canEditSourceOfFundsTPP = false;

	get sourceAccountSelectOptions(): SelectOption[] {
		return this._sourceAccountSelectOptions;
	}

	get displayGroupName(): string {
		return !!this._groupToConfig ? `Group ${this._groupToConfig}` : "Group Name";
	}

	constructor(
		private router: Router,
		private formbuilder: FormBuilder,
		private tppServiceDefinitionService: TppServiceDefinitionService,
		private tppGroupConfigService: TppGroupConfigService,
		private tppGroupConfigStore: TppGroupConfigurationStore,
		private permissions: PermissionsService
	) {}

	ngOnInit(): void {
		this.permissions
			.canEditSourceOfFundsTPP()
			.pipe(take(1))
			.subscribe(res => {
				this.canEditSourceOfFundsTPP = res;
				this.serviceDefinition$ = this.tppGroupConfigStore.select(state => state);
				this.initForm();
				this.initTppGroupForm();
				this.checkServiceData(false);
				this.setGroupSelectOptions();
			});
	}

	ngAfterViewInit(): void {
		this.getSourceAccountSelectOptions();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	getSourceAccountSelectOptions(): void {
		this.tppGroupConfigService
			.getSourceAccountSelectOptions()
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: res => {
					this.sourceAccountSelectOptions = res;

					this.setExistingSourceAccount();
				}
			});
	}

	setExistingSourceAccount(): void {
		const config = this.tppGroupConfigStore.getConfigByGroupName(this._groupToConfig);

		if (config && config.sourceAccountId !== "") {
			this.formTppGroup.get("existingAccount")?.setValue(
				this._sourceAccountSelectOptions.find(res => {
					return res.value === config?.sourceAccountId;
				})?.text,
				{ emitEvent: false }
			);
			this.editSourceAccountButtonEnabled = true;
		}

		this.updateNextButtonEnabler();
	}

	updateNextButtonEnabler(): void {
		if (this.canEditSourceOfFundsTPP && this.formTppGroup.status === "VALID" && this.form.status === "VALID") {
			this.btnDisabled = false;
		} else if (!this.canEditSourceOfFundsTPP && this.form.status === "VALID") {
			this.btnDisabled = false;
		} else {
			this.btnDisabled = true;
		}
	}

	initForm(): void {
		this.form = this.formbuilder.group({
			tppGroup: ["", Validators.required]
		});

		this.formTppGroup = this.formbuilder.group({
			paymentCurrency: ["", Validators.required],
			existingAccount: ["", Validators.required]
		});

		if (!this.canEditSourceOfFundsTPP) {
			this.formTppGroup.disable();
		}

		// this value changes not triggered
		this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
			this.editSourceAccountButtonEnabled = false;
			this.groupChanged.emit(value.tppGroup);
			this.setGroupName();
			this.groupToConfig = value.tppGroup;
			this.tppGroupConfigStore.setGroup(value.tppGroup);

			this.formTppGroup.get("existingAccount")!.setValue("");
			this.sourceOfFundId = "";
			this.sourceAccountSelectOptions = [];
			this.groupChosen = true;

			setTimeout(() => {
				this.checkServiceData(true);
			}, 1000);

			this.updateNextButtonEnabler();
		});
	}

	setGroupName(): void {
		this.groupName = !!this.form.get("ttpGroup")?.value
			? `Group ${this.form.get("ttpGroup")?.value}`
			: "Grouping Name";
	}

	initTppGroupForm(): void {
		const group = this.tppGroupConfigStore.getConfigByGroupName(this._groupToConfig);

		if (group) {
			this.selectedCurrency = group.paymentCurrency;
			this.sourceOfFundId = group.sourceAccountId;
			this.formbuilder.group({
				paymentCurrency: [group.paymentCurrency, Validators.required],
				existingAccount: [group.settlementAccountId, Validators.required]
			});
		}

		this.formTppGroup
			.get("paymentCurrency")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe(value => {
				if (value) {
					this.formTppGroup.get("existingAccount")!.setValue("");
					this.sourceOfFundId = "";

					this.addSourceAccountButtonEnabled = true;
					this.currencyChanged.emit(value);

					this.tppGroupConfigStore.setFundingCurrency(this._groupToConfig, value);

					this.selectedCurrency = value;
				}
			});

		this.formTppGroup
			.get("existingAccount")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe(value => {
				if (value) {
					this.editSourceAccountButtonEnabled = true;
					this.sourceOfFundId = value;
					this.tppGroupConfigStore.setGroupSourceOfFundId(this._groupToConfig, value);
					const sourceOfFundAccount: string = this.getIdListSortFund(value);
					this.tppServiceDefinitionService.setDataFormSourceFund(
						PAYMENT_TYPE,
						this.selectedCurrency,
						sourceOfFundAccount,
						this.groupName,
						this.selectedLegalEntityId
					);
				}
			});

		this.formTppGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(values => {
			this.updateNextButtonEnabler();
		});
	}

	checkServiceData(checkConfiguredData: boolean): void {
		const config = this.tppGroupConfigStore.getConfigByGroupName(this._groupToConfig);
		if (config) {
			if (!checkConfiguredData) {
				this.form.get("tppGroup")?.patchValue(config.group);
				this._groupToConfig = config.group;
			}

			this.selectedCurrency = config.fundingCurrency;
			this.formTppGroup.get("paymentCurrency")?.patchValue(this.selectedCurrency);
			this.currencyChanged.emit(this.selectedCurrency);
		} else {
			this.formTppGroup.reset();
		}
	}

	setGroupSelectOptions(): void {
		this.serviceDefinition$
			.pipe(
				takeUntil(this.destroy$),
				distinctUntilChanged(), // only emit if the value has changed
				filter(serviceDefinition => !!serviceDefinition.serviceDefinitionConfig.services.length),
				map(serviceDefinition => {
					return serviceDefinition.serviceDefinitionConfig.services;
				}),

				map(services => {
					this.tppGroupSelectOptions = this.filterTPPGroupSelectOptions(services!);
					// pass paygroup
					if (this.tppGroupSelectOptions.length === 0) {
						this.router.navigate(["/service-definition/tpp/select-service"]);
					}
				})
			)
			.subscribe();
	}

	filterTPPGroupSelectOptions(services: DefinitionService[]): SelectOption[] {
		const tppGroupArray = this.getTPPGroupArrayFromServices(services);
		const tppGroupSelectOption: SelectOption[] = TPPGroupSelectOptions.filter((item: SelectOption) =>
			tppGroupArray.includes(item.value)
		);
		return tppGroupSelectOption;
	}

	getTPPGroupArrayFromServices(groupServices: DefinitionService[]): string[] {
		const tppGroups: string[] =
			!!groupServices && groupServices.length ? groupServices.map(service => service.group!) : [];
		const uniqueGroupNames = [...new Set(tppGroups)];
		return uniqueGroupNames;
	}

	getNameListSortFund(id: string): string {
		return this.sourceOfFundsList.filter((item: SourceFoundAccount) => item.id === id)[0].data.name ?? "";
	}

	getIdListSortFund(name: string): string {
		if (name && this.sourceOfFundsList)
			return this.sourceOfFundsList.filter((item: SourceFoundAccount) => item.data.name === name)[0].id ?? "";
		else return "";
	}

	updateSourceOfFundAccount(): void {
		this.showSourceOfFundAccount(false);
	}

	addNewSourceOfFunds(): void {
		this.showSourceOfFundAccount(true);
	}

	showSourceOfFundAccount(addSF: boolean): void {
		let sourceOfFundAccountId: string = this.getIdListSortFund(this.formTppGroup.get("existingAccount")?.value);
		if (addSF) sourceOfFundAccountId = "";

		this.tppServiceDefinitionService.setDataFormSourceFund(
			PAYMENT_TYPE,
			this.selectedCurrency,
			sourceOfFundAccountId ?? "",
			this.groupName,
			this.selectedLegalEntityId
		);
		this.openSourceOfFund.emit(addSF);
	}

	next(): void {
		this.continue.emit(this.form.get("tppGroup")!.value);
	}

	setTppGroupChose(groupName: string): void {
		const currentGroupName = this.form.get("tppGroup")!.value;

		this.form.patchValue({ tppGroup: groupName });
		this.groupName = groupName;
	}

	resetForm(): void {
		if (this.formTppGroup) {
			this.formTppGroup.reset();
		}
	}
}
