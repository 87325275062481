import { Directive, Input, OnDestroy, Renderer2, TemplateRef, ViewContainerRef } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { Subject, takeUntil } from "rxjs";

import { getUserDataState } from "@store/index";
import { AppState } from "@store/models/state.model";

@Directive({
	selector: "[appUserRolePermissions]"
})
export class UserRolePermissionsDirective implements OnDestroy {
	@Input("action") set action(newAction: "hide" | "disabled" | "show") {
		this.newAction = newAction;
	}

	@Input("roles") set roles(userRoles: string[]) {
		if (userRoles.length) this.setupUserRoleSubscription(userRoles);
	}

	private newAction!: string;
	private destroy$: Subject<void> = new Subject();

	constructor(
		private templateRef: TemplateRef<any>,
		private viewContainerRef: ViewContainerRef,
		private store: Store<AppState>,
		private renderer: Renderer2
	) {}

	private setupUserRoleSubscription(userRoles: string[]): void {
		this.store.pipe(select(getUserDataState), takeUntil(this.destroy$)).subscribe(userState => {
			if (userState?.roles) {
				this.configureComponent(userRoles, userState.roles);
			} else {
				this.viewContainerRef.clear();
			}
		});
	}

	private configureComponent(userRoles: string[], stateRoles: string[]): void {
		//Most permissive
		//When to hide/disable: If all the state roles are in the list
		//When to show: If atleast one state role is in the list

		switch (this.newAction) {
			case "hide":
				this.hideComponent(userRoles, stateRoles);
				break;
			case "show":
				this.showComponent(userRoles, stateRoles);
				break;
			case "disabled":
				this.disableComponent(userRoles, stateRoles);
				break;
			default:
				break;
		}
	}

	private hideComponent(bannedRoles: string[], userRoles: string[]): void {
		this.areAllRolesBanned(bannedRoles, userRoles)
			? this.viewContainerRef.clear()
			: this.viewContainerRef.createEmbeddedView(this.templateRef);
	}

	private showComponent(allowedRoles: string[], userRoles: string[]): void {
		this.isOneRoleAllowed(allowedRoles, userRoles)
			? this.viewContainerRef.createEmbeddedView(this.templateRef)
			: this.viewContainerRef.clear();
	}

	private disableComponent(bannedRoles: string[], userRoles: string[]): void {
		if (this.areAllRolesBanned(bannedRoles, userRoles)) {
			this.viewContainerRef.createEmbeddedView(this.templateRef);
			this.renderer.setStyle(
				this.viewContainerRef.element?.nativeElement?.previousSibling,
				"pointer-events",
				"none"
			);
			this.renderer.setStyle(this.viewContainerRef.element?.nativeElement?.previousSibling, "opacity", "0.3");
		}
	}

	private areAllRolesBanned(bannedRoles: string[], userRoles: string[]): boolean {
		return userRoles.every(role => bannedRoles.includes(role));
	}

	private isOneRoleAllowed(allowedRoles: string[], userRoles: string[]): boolean {
		return userRoles.some(role => allowedRoles.includes(role));
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
