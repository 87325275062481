import { Pipe, PipeTransform } from "@angular/core";
import { FileStatus } from "../../models/file-manager";

@Pipe({
	name: "fileStatus"
})
export class FileStatusPipe implements PipeTransform {
	transform(value: FileStatus): string | undefined {
		switch (value) {
			case "SUCCESS":
				return "SUCCESSFUL";
			case "FAILED":
				return "HAS ERRORS";
			case "PENDING":
				return "PENDING";
			case "ERROR":
				return "ERROR";
		}
		return void 0;
	}
}
