import { HttpClient, HttpParams } from "@angular/common/http";
import { EventEmitter, Injectable, Output } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";
import { map, take } from "rxjs/operators";
import { PayMethodDetail, PayMethodsPagination } from "@modules/employee-data/types/accounts";

import { environment } from "../../../../environments/environment";
import {
	PayoutPagination,
	TransactionPagination,
	PayoutProcessingRoute,
	PayoutData,
	MilestoneReport,
	Payout,
	TransactionReportWithPagination,
	Transaction,
	PayoutStatusName
} from "../../models/payouts.types";
import { PaymentsApiService } from "@modules/dashboard/services/payments-api.service";

@Injectable({
	providedIn: "root"
})
export class PayoutsService {
	private reprocessIds$ = new ReplaySubject<Map<string, string[]>>();

	@Output() reloadPayoutData: EventEmitter<void> = new EventEmitter<void>();

	constructor(private http: HttpClient, private paymentApiService: PaymentsApiService) {}

	getPayoutsWithPagination(
		payGroupId: string,
		payCycleId: string,
		transactionStatusses: string[],
		pageSize: string,
		pageNumber: string
	): Observable<PayoutPagination> {
		return this.http.get<PayoutPagination>(
			`${environment.apiUri}/v1/payouts/nets?payGroupIds=${payGroupId}&payCycleIds=${payCycleId}&transactionStatuses=${transactionStatusses}&pageSize=${pageSize}&pageNumber=${pageNumber}&includeTransactions=true`
		);
	}

	getPayouts(payGroupId: string, payCycleId: string): Observable<PayoutPagination> {
		return this.http.get<PayoutPagination>(
			`${environment.apiUri}/v1/payouts/nets?payGroupIds=${payGroupId}&payCycleIds=${payCycleId}&pageSize=1000`
		);
	}

	getBookingState(payouts: any[]): string {
		let hasCreated: number = 0;
		let hasProcessed: number = 0;
		let hasFailed: number = 0;
		let hasBooked: number = 0;
		let totalTransactions: number = -1;

		if (payouts?.length > 0) {
			//Run through payout
			payouts.forEach(payout => {
				// Get their most recent tranactions
				payout.transactions = this.paymentApiService.filterTransactions(payout.transactions!);

				// Run through transactions -> Inactive payouts can't alter state of button
				if (payout.status.name.toString().toUpperCase() !== "INACTIVE") {
					payout.transactions.forEach((transaction: Transaction) => {
						if (totalTransactions === -1) {
							//Prevents that 'process' becomes the default
							totalTransactions++;
						}

						totalTransactions++;
						//Set booleans
						if (transaction.status.name.toString().toUpperCase() === "CREATED") {
							hasCreated++;
						} else if (transaction.status.name.toString().toUpperCase() === "PROCESSED") {
							hasProcessed++;
						} else if (
							transaction.status.name.toString().toUpperCase() === "FAILED" ||
							transaction.status.name.toString().toUpperCase() === "ATTEMPTS_LIMIT_EXCEEDED" ||
							transaction.status.name.toString().toUpperCase() === "ERROR"
						) {
							hasFailed++;
						} else if (transaction.status.name.toString() === "BOOKED") {
							hasBooked++;
						}
					});
				}
			});

			//Set button state based on totals

			if (hasCreated === totalTransactions) {
				return "process";
			} else if (hasProcessed === totalTransactions) {
				return "inactive";
			} else if (hasBooked === totalTransactions) {
				return "cancel";
			} else if (hasFailed === totalTransactions) {
				return "cancel";
			} else if (hasProcessed + hasFailed === totalTransactions && hasBooked === 0) {
				return "inactive";
			} else if (hasBooked + hasFailed === totalTransactions && hasProcessed === 0) {
				return "cancel";
			} else {
				return "inactive";
			}
		}

		return "inactive";
	}

	getPayoutsForEmployeeInPayCycle(payGroupId: string, payCycleId: string, employeeId: string) {
		// A single employee having alot of payments in a single paycycle is unlikely hence 1000 is a safe number.
		return this.http.get<PayoutPagination>(
			`${environment.apiUri}/v1/payouts/nets?payGroupIds=${payGroupId}&payCycleIds=${payCycleId}&employeeIds=${employeeId}&pageSize=1000`
		);
	}

	getPayCycleStatusByIdAndGroup(payCycleId: string, type: string): Observable<any> {
		return this.http.get<PayoutPagination>(
			`${environment.apiUri}/v1/pay-cycles/${payCycleId}/statuses?milestoneGroup=${type}`
		);
	}

	getPaymentTypes(payCycleId: string, stage: string): Observable<string[]> {
		return this.http.get<string[]>(
			`${environment.apiUri}/v1/pay-cycles/${payCycleId}/payment-types?stage=${stage}`
		);
	}

	processPayments(payCycleId: string, payGroupId: string, triggerType: string, bankRoute: string, cardRoute: string) {
		const route: PayoutProcessingRoute = {};

		if (bankRoute !== "" && bankRoute) {
			route["BANK"] = bankRoute;
		}
		if (cardRoute !== "" && cardRoute) {
			route["CARD"] = cardRoute;
		}

		const postParams: { route: PayoutProcessingRoute; triggerType?: string } = {
			route: route
		};

		if (triggerType !== "Book Payments") {
			postParams.triggerType = triggerType;
		}

		return this.http.post(
			`${environment.apiUri}/v2/pay-cycles/${payCycleId}/pay-groups/${payGroupId}/activation`,
			postParams
		);
	}

	reProcessPayments(
		triggerType: string,
		bankRoute: string,
		cardRoute: string,
		reProcesMapPayoutsAndTxId: Map<string, string[]>
	) {
		const route: PayoutProcessingRoute = {};

		if (bankRoute !== "" && bankRoute) {
			route["BANK"] = bankRoute;
		}
		if (cardRoute !== "" && cardRoute) {
			route["CARD"] = cardRoute;
		}

		return this.http.post(`${environment.apiUri}/v2/payouts/nets/reprocess`, {
			ids: this.getTransactionIdsFormMapPayouts(reProcesMapPayoutsAndTxId),
			triggerType: triggerType,
			route: route
		});
	}

	getTransactionIdsFormMapPayouts(reProcesMapPayoutsAndTxId: Map<string, string[]>): string[] {
		let transactionsIds: string[] = [];
		reProcesMapPayoutsAndTxId.forEach((value: string[]) => {
			if (value.length > 0) transactionsIds = transactionsIds.concat(value);
		});
		return transactionsIds;
	}

	inactiveNetPayout(payCycleId: string, employeeId: string) {
		return this.http.post(
			`${environment.apiUri}/v1/payouts/nets/pay-cycles/${payCycleId}/employee/${employeeId}/inactivate`,
			null
		);
	}

	searchPayoutsTransactions(payoutId: string): Observable<TransactionPagination> {
		let params = new HttpParams();

		params = params.append("payoutIds", payoutId);
		params = params.append("pageSize", "100");
		params = params.append("pageNumber", "0");

		return this.http.get<TransactionPagination>(`${environment.apiUri}/v1/payouts/nets/transactions`, { params });
	}

	getTransactionHistory(
		payoutId: string,
		paymethodId: string,
		rangeStart: string,
		rangeEnd: string
	): Observable<TransactionReportWithPagination> {
		return this.http.get<TransactionReportWithPagination>(
			`${environment.apiUri}/v1/transactions/report?rangeStart=${rangeStart}&rangeEnd=${rangeEnd}&payoutIds=${payoutId}&payMethodIds=${paymethodId}`
		);
	}

	getEmployeePaymentMethods(employeeId: string): Observable<PayMethodDetail[]> {
		return this.getPaymentMethods(employeeId).pipe(
			map(res => {
				return res.items;
			})
		);
	}

	getPaymentMethods(employeeId: string): Observable<PayMethodsPagination> {
		return this.http.get<PayMethodsPagination>(
			`${environment.apiUri}/v1/employees/${employeeId}/pay-methods/details?payMethodTypes=CARD,BANK&pageSize=1000`
		);
	}

	reprocessIdsObservable(): Observable<Map<string, string[]>> {
		return this.reprocessIds$.asObservable();
	}

	reload() {
		this.reloadPayoutData.emit();
	}

	updateReprocessIds(reProcesMapPayoutsAndTxId: Map<string, string[]>): void {
		this.reprocessIds$.next(reProcesMapPayoutsAndTxId);
	}

	selectAllReprocessIds(allTransactions: Transaction[]): void {
		let reprocessIds: Map<string, string[]> = new Map<string, string[]>();

		allTransactions.forEach(transaction => {
			if (reprocessIds.has(transaction.payoutId)) {
				// If the key exists, add the transaction.id to the array
				reprocessIds.get(transaction.payoutId)!.push(transaction.id);
			} else {
				// If the key does not exist, create a new array with the transaction.id
				reprocessIds.set(transaction.payoutId, [transaction.id]);
			}
		});

		this.reprocessIds$.next(reprocessIds);
	}

	clearReprocessIds(): void {
		this.reprocessIds$.next(new Map<string, string[]>());
	}

	getPayoutStatus(payout: Payout, transactions: Transaction[]): PayoutStatusName {
		if (payout.transactions && payout.transactions.length > 0 && payout.status.name.toLowerCase() !== "inactive") {
			transactions = this.paymentApiService.filterTransactions(transactions);
			let transactionStatusses: string[] = [];
			transactions.forEach(transaction => transactionStatusses.push(transaction.status.name));
			return this.paymentApiService.getMostPessimisticStatus(transactionStatusses, payout.status.name);
		} else {
			return payout.status.name;
		}
	}
}
