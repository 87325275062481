import { CommonModule } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { Router } from "@angular/router";
import { IDashboardFiltersFormGroup } from "@modules/global-dashboard/models/global-dashboard-interface";
import { select, Store } from "@ngrx/store";
import { Customer } from "@shared/models/customer.interface";
import { CustomerService } from "@shared/services/customer/customer.service";
import { SharedModule } from "@shared/shared.module";
import { UpdateDashboardFilterAction } from "@store/actions/globalDashboardFilterSelect.action";
import { UpdateClientGlobalFilterEnable } from "@store/actions/userData.action";
import { getGlobalDashboardFilterState } from "@store/index";
import { GlobalDashboardFilterSelect } from "@store/models/globalDashboardFilterSelection.model";
import { AppState } from "@store/models/state.model";
import { Subject, take, takeUntil } from "rxjs";

@Component({
	selector: "app-global-dashboard-button",
	templateUrl: "./global-dashboard-button.component.html",
	styleUrls: ["./global-dashboard-button.component.scss"],
	standalone: true,
	imports: [CommonModule, SharedModule, MatButtonModule]
})
export class GlobalDashboardButtonComponent implements OnInit, OnDestroy {
	filters!: FormGroup;
	customerId!: string;

	private destroy$: Subject<void> = new Subject<void>();

	constructor(
		private router: Router,
		private store: Store<AppState>,
		private formBuilder: FormBuilder,
		private customerService: CustomerService
	) {
		this.setupFiltersForm();
	}

	ngOnInit(): void {}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	getClientCustomerID(): void {
		this.customerService
			.getAllCustomers()
			.pipe(take(1), takeUntil(this.destroy$))
			.subscribe({
				next: (res: Customer[]) => {
					this.customerId = res[0].id;
				}
			});
	}

	routeToGlobalDashboard(): void {
		this.store.pipe(take(1), takeUntil(this.destroy$), select(getGlobalDashboardFilterState)).subscribe({
			next: (state: GlobalDashboardFilterSelect) => {
				if (state.globalDashboard) {
					this.filters.get("customers")?.patchValue([this.customerId]);
					this.filters.get("legalEntities")?.patchValue([]);

					this.store.dispatch(
						new UpdateDashboardFilterAction({
							globalDashboard: {
								kpiSelected: state.globalDashboard.kpiSelected,
								filters: this.filters.getRawValue()
							}
						})
					);

					this.store.dispatch(
						new UpdateClientGlobalFilterEnable({
							userData: { isClientGlobalDashboard: true }
						})
					);
				}

				this.router.navigate(["/global-dashboard"]);
			}
		});
	}

	private setupFiltersForm(): void {
		this.filters = this.formBuilder.group({
			customers: new FormControl([]),
			legalEntities: new FormControl([]),
			paygroups: new FormControl([]),
			statuses: new FormControl([]),
			groups: new FormControl([]),
			deadline: new FormControl(""),
			milestoneStartDate: new FormControl(""),
			milestoneTypes: new FormControl([])
		}) as IDashboardFiltersFormGroup;

		this.getClientCustomerID();
	}
}
