import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, takeUntil } from "rxjs/operators";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { saveAs } from "file-saver";
import { PageEvent } from "@angular/material/paginator";

import { UserCreationDialogComponent } from "./_components/user-creation-dialog/user-creation-dialog.component";
import {
	UserPagination,
	ISearchUserForm,
	ISelectForm,
	User,
	IStatusForm,
	StatusOptions
} from "./_models/user-management-api.interface";
import { UserService } from "./_services/user.service";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { ToastService } from "@shared/services/toast/toast.service";
import { InputSelectService } from "../../shared/services/input-select.service";
import { breadCrumbButtonIcon } from "@shared/models/breadcrumbs.interface";

@Component({
	selector: "app-user-management",
	templateUrl: "./user-management.component.html",
	styleUrls: ["./user-management.component.scss"]
})
export class UserManagementComponent implements OnInit, OnDestroy {
	userPagination$!: Observable<UserPagination>;
	searchForm!: FormGroup;
	selectForm!: FormGroup;
	statusForm!: FormGroup;

	destroy$ = new Subject<void>();
	statusOption: SelectOption[] = StatusOptions;
	queryString: string = "";
	showLog: boolean = false;

	userLog!: User;

	/**Pagination*/
	pageSize: number = 10;
	pageNumber: number = 0;

	breadCrumbIcon: breadCrumbButtonIcon = {
		name: "add",
		lineWidth: "2.5",
		color: "var(--color-primary-1000)",
		size: "16"
	};

	constructor(
		private fb: FormBuilder,
		private dialog: MatDialog,
		private userService: UserService,
		private toastService: ToastService,
		private inputSelectService: InputSelectService
	) {}

	ngOnInit(): void {
		this.initSearchForm();
		this.initSelectForm();
		this.initStatuForm();
		this.getUsersDataTable();
	}

	initSearchForm(): void {
		this.searchForm = this.fb.group({
			search: []
		}) as ISearchUserForm;

		this.searchForm
			.get("search")
			?.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(400), distinctUntilChanged())
			.subscribe(searchValue => {
				this.queryString = searchValue;
				this.filterUser();
			});
	}

	initSelectForm() {
		this.selectForm = this.fb.group({
			select: []
		}) as ISelectForm;

		this.selectForm
			.get("select")
			?.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(400), distinctUntilChanged())
			.subscribe(selectValue => {
				selectValue ? this.filterUser() : this.getUsersDataTable();
			});
	}

	initStatuForm() {
		this.statusForm = this.fb.group({
			status: []
		}) as IStatusForm;

		this.statusForm
			.get("status")
			?.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(400), distinctUntilChanged())
			.subscribe(statusValue => {
				statusValue ? this.filterUser() : this.getUsersDataTable();
			});
	}

	getUsersDataTable() {
		this.userPagination$ = this.userService.getUsersList(
			this.pageSize.toString(),
			this.pageNumber.toString(),
			this.queryString
		);
	}

	get rolesList() {
		return this.userService.roles;
	}

	filterUser() {
		const roleValue = this.selectForm.controls.select.value ? this.selectForm.controls.select.value : null;
		const status = this.statusForm.controls.status.value ? this.statusForm.controls.status.value : null;
		const name = this.searchForm.controls.search.value ? this.searchForm.controls.search.value : null;
		this.userPagination$ = this.userService.filterUsers(
			this.pageSize.toString(),
			this.pageNumber.toString(),
			roleValue,
			status,
			name
		);
	}

	addNewUser() {
		this.openUserDialgog().subscribe((data: User | null) => {
			if (data) {
				this.userService.createNewUser(data).subscribe(
					(data: any) => {
						this.toastService.showSuccess("User Stored Successfully");
						this.getUsersDataTable();
						this.clearAllFilters();
						this.inputSelectService.resetSelectOption();
					},
					err => {
						this.toastService.showError("Error Storing User");
					}
				);
			}
		});
	}

	editUser(user: User) {
		this.openUserDialgog(user).subscribe((data: User | null) => {
			if (data) {
				this.userService.updateUser(data).subscribe({
					next: _ => {
						this.toastService.showSuccess("User Updated Successfully");
						this.getUsersDataTable();
						this.clearAllFilters();
						this.inputSelectService.resetSelectOption();
					},
					error: _ => {
						this.toastService.showError("Error Updating User");
					}
				});
			}
		});
	}

	showUserLogInfo(user: User) {
		this.userLog = user;
		this.showLog = true;
	}

	closeLog() {
		this.showLog = false;
	}

	clearAllFilters(): void {
		this.searchForm.get("search")?.patchValue("", { emitEvent: false });
		this.selectForm.get("select")?.patchValue("", { emitEvent: false });
		this.statusForm.get("status")?.patchValue("", { emitEvent: false });
	}

	openUserDialgog(user: User | null = null) {
		const dialogConfig = new MatDialogConfig();

		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.panelClass = "delete-dialog-container";
		dialogConfig.width = "1033px";
		if (user) dialogConfig.data = user;

		const dialogRef = this.dialog.open(UserCreationDialogComponent, dialogConfig);
		return dialogRef.afterClosed();
	}

	toggleUser(user: User) {
		this.userService.changeUserStatus(user).subscribe(
			response => {
				this.toastService.showSuccess(
					user.blocked ? "User inactivated Successfully" : "User activated Successfully"
				);
				this.getUsersDataTable();
				this.clearAllFilters();
				this.inputSelectService.resetSelectOption();
				this.getUsersDataTable();
			},
			err => {
				this.toastService.showError("Error inactivating User");
			}
		);
	}

	paginate(pagination: PageEvent) {
		this.pageSize = pagination.pageSize;
		this.pageNumber = pagination.pageIndex;
		this.filterUser();
	}

	downloadReport(): void {
		this.userService.getUsersReport().subscribe(buffer => {
			const data: Blob = new Blob([buffer], {
				type: "text/csv;charset=utf-8"
			});
			saveAs(data, "users-report.csv");
		});
	}

	ngOnDestroy(): void {
		//Called once, before the instance is destroyed.
		//Add 'implements OnDestroy' to the class.
		this.destroy$.complete();
	}
}
