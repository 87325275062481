import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Observable, Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CurrencyService } from "@shared/services/currency/currency.service";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { ServiceProviderCountryCapability } from "src/app/shared/models/service-provider-country-types";
import { PaymentTypeName } from "../../../../../shared/models/service-provider.type";
import { ServiceProviderCountryCreateService } from "../../../_services/service-provider-country-create/service-provider-country-create.service";
import { atLeastOneValidator } from "../../../_validators/at-least-one/at-least-one.service";
import { CostsNotesDialogComponent } from "../../costs-notes-dialog/costs-notes-dialog.component";

@Component({
	selector: "app-cost-form",
	templateUrl: "./cost-form.component.html",
	styleUrls: ["./cost-form.component.scss"]
})
export class CostFormComponent implements OnInit, OnDestroy {
	createdCapabilites: ServiceProviderCountryCapability[] = [];

	costForm!: FormGroup;

	subscription: Subscription = new Subscription();
	subCosts: Subscription = new Subscription();
	submitFormSubscription: Subscription = new Subscription();
	destroy$: Subject<void> = new Subject();

	currencyOptions$: Observable<SelectOption[]> = new Observable<SelectOption[]>();

	constructor(
		private readonly formBuilder: FormBuilder,
		private readonly serviceProviderCountryCreateService: ServiceProviderCountryCreateService,
		private readonly currencyService: CurrencyService,
		public dialog: MatDialog
	) {}

	ngOnInit(): void {
		this.initForm();
		this.currencyOptions$ = this.currencyService.getCurrencyOptions();

		this.initSubscriptions();

		this.onFormChanges();
	}

	initForm(): void {
		this.costForm = this.formBuilder.group({});
	}

	createCapabilityGroups(capabilities: ServiceProviderCountryCapability[]): void {
		capabilities.filter(capability => {
			const group = this.formBuilder.group({
				paymentType: [{ value: capability.paymentType.toString().replace("_", " "), disabled: true }],
				costs: this.formBuilder.array([])
			});

			const costArray = group.get("costs") as FormArray;

			capability.capabilities.filter(capability => {
				capability.costs.filter(cost => {
					const costControl = this.formBuilder.group(
						{
							billingType: [
								{
									value:
										!cost.billingType?.to && !cost.billingType?.from
											? "unlimited"
											: `${cost.billingType?.from} - ${
													cost.billingType?.to === null ? "unlimited" : cost.billingType?.to
											  }`,
									disabled: true
								}
							],
							payoutAccount: [{ value: capability.payoutAccount, disabled: true }],
							route: [{ value: capability.route, disabled: true }],
							currency: [cost.currency],
							paymentFee: [cost.paymentFee, [Validators.required, Validators.min(0)]],
							monthlyFee: [cost.monthlyFee, [Validators.required, Validators.min(0)]],
							notes: [{ value: cost.notes, disabled: true }]
						},
						{
							validator: atLeastOneValidator
						}
					);
					costArray.push(costControl);
				});
			});

			this.costForm.addControl(capability.paymentType.toString(), group);
		});
	}

	getCostFormGroupArray(paymentType: PaymentTypeName) {
		return this.costForm.get(`${paymentType}.costs`) as FormArray;
	}

	onFormChanges() {
		this.costForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.serviceProviderCountryCreateService.updateCostFormState(this.costForm.valid);
		});
	}

	initSubscriptions(): void {
		this.subCosts = this.serviceProviderCountryCreateService.costData$
			.pipe(takeUntil(this.destroy$))
			.subscribe((capabilities: ServiceProviderCountryCapability[] | null) => {
				if (capabilities) {
					capabilities.filter(c => {
						const control = this.costForm.get(c.paymentType.toString());
						if (control) {
							this.costForm.removeControl(c.paymentType.toString());
						}
					});
					this.createdCapabilites = capabilities;
					this.createCapabilityGroups(capabilities);
				}
			});

		this.submitFormSubscription = this.serviceProviderCountryCreateService.createCostData$
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.serviceProviderCountryCreateService.createProviderCountry(this.costForm.getRawValue());
			});
	}

	openDialog(type: PaymentTypeName, notes: string, index: number): void {
		const dialogRef = this.dialog.open(CostsNotesDialogComponent, {
			width: "650px",
			data: { notes }
		});
		dialogRef.afterClosed().subscribe(result => {
			if (!!result) {
				const control = this.getCostFormGroupArray(type).at(index);
				control.get("notes")?.patchValue(result);
			}
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
