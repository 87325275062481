import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ContactsComponent } from "./contacts.component";
import { InputWrapperModule } from "../input-wrapper/input-wrapper.module";
import { FormsSharedModule } from "@shared/modules/forms-shared.module";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatIconModule } from "@angular/material/icon";
import { SharedModule } from "@shared/shared.module";
import { FlexLayoutModule } from "ngx-flexible-layout";
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
	declarations: [ContactsComponent],
	imports: [
		CommonModule,
		InputWrapperModule,
		FormsSharedModule,
		MatIconModule,
		MatButtonModule,
		MatCheckboxModule,
		SharedModule,
		FlexLayoutModule,
		MatTooltipModule
	],
	exports: [ContactsComponent]
})
export class ContactsModule {}
