import { Component, OnDestroy, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { map, mergeMap, shareReplay, take, takeUntil, tap } from "rxjs/operators";
import { breadcrumbDataObject } from "@shared/components/modal-wrapper/_models/breadcrumb.interface";
import { ServiceProviderService } from "@shared/services/service-provider/service-provider.service";
import { TppCommonServiceService } from "@shared/services/tpp-common-service/tpp-common-service.service";
import { TppServicesService } from "@shared/services/tpp-services/tpp-services.service";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { ServiceProviderCountrySetup } from "src/app/shared/models/service-provider-country-types";
import { SettlementAccount } from "src/app/shared/models/settlement-account.interface";
import {
	DefinitionService,
	TPPGroupConfig,
	TPPServiceDefinition,
	TPPServiceDefinitionState
} from "src/app/shared/models/tpp-service.interface";
import { getCustomerEntityGroupState } from "src/app/store";
import { AppState } from "src/app/store/models/state.model";
import { DataNewSourOfFun } from "../../_models/defintion-group";
import { TppServiceDefinitionService } from "../../_services/tpp-service-definition/tpp-service-definition.service";
import { Observable, Subject, forkJoin, noop, Subscription } from "rxjs";
import { CurrencyService } from "@shared/services/currency/currency.service";
import { FundSourceService } from "../../_services/fund-source/fund-source.service";
import { SourceFoundAccount } from "../../_models/source-accounts.interface";
import { ServiceProviderCurrencies } from "src/app/shared/models/service-provider-types.interface";
import { TppGroupConfigurationStore } from "../../_services/store/tpp-group-configuration.service";
import { formatDataForSelect } from "../../../../shared/utils/format-select-data.util";
import { PaygroupsService } from "@shared/services/paygroups/paygroups.service";
import { PayGroup } from "src/app/shared/models/pay-groups";
import { BreadcrumbsService } from "@shared/services/breadcrumbs/breadcrumbs.service";
import { StateService } from "@shared/services/state/state.service";
import { ToastService } from "@shared/services/toast/toast.service";
import { Router } from "@angular/router";
import { PermissionsService } from "@shared/services/permissions/permissions.service";
import { TppGroupConfigService } from "@modules/service-definition/_services/tpp-group-config/tpp-group-config.service";

@Component({
	selector: "app-tpp-service-group-config",
	templateUrl: "./tpp-service-group-config.component.html",
	styleUrls: ["./tpp-service-group-config.component.scss"]
})
export class TppServiceGroupConfigComponent implements OnInit, OnDestroy {
	breadCrumbArray: breadcrumbDataObject[] = [];
	selectedPayGroupName: string = "";
	selectedCountryCode: string = "";
	selectedCustomerName: string = "";
	selectedLegalEntityId: string = "";
	selectedPayGroupId: string = "";
	tppServicesText = "TPP Services";
	tppGroupText = "TPP Group";

	customerName: string = "";
	payGroupName: string = "";

	enableAddSourceOfFund: boolean = false;
	lastPage: boolean = false;
	dataFormSourceFund!: DataNewSourOfFun;
	enableAddSettlementAccount: boolean = false;
	addSettlementProviderId: string = "";
	availableProvidersForSelectedCountry: ServiceProviderCountrySetup[] = [];
	availableSettlementAccounts!: SettlementAccount[];
	addSettlementProvider!: ServiceProviderCountrySetup | undefined;

	serviceProvidersOptions: SelectOption[] = [];
	serviceProviderId: string = "";
	settlementAccountId: string = "";
	paymentCurrencyOptions: SelectOption[] | undefined = [];

	settlementAccountOptions: SelectOption[] = [];
	selectedSettlementAccount!: SettlementAccount | undefined;
	selectedServiceDefinition!: TPPServiceDefinition;
	tppSubServicesNameList: string[] = [];
	isSettlementEdit!: boolean;
	providerCountryId: string = "";

	serviceDefinition$: Observable<TPPServiceDefinitionState> = new Observable<TPPServiceDefinitionState>();
	currencies: Observable<SelectOption[]> = new Observable<SelectOption[]>();
	sourceAccountSelectOptions: SelectOption[] = [];
	serviceProvidersCurrencies: ServiceProviderCurrencies[] = [];
	groupToConfig: string = "";
	definitionSet: boolean = false;

	paymentCurrency: string = "";
	sourceOfFundId: string = "";

	destroy$: Subject<void> = new Subject();

	configHasBeenSet = false;

	private stateSubscription!: Subscription;
	canEditSourceOfFundsTPP = false;
	canEditSettlementAccountTPP = false;
	settlementProviderName = "";
	constructor(
		private store: Store<AppState>,
		private tppServiceDefinitionService: TppServiceDefinitionService,
		private serviceProviderService: ServiceProviderService,
		private tppCommonService: TppCommonServiceService,
		private tppServicesService: TppServicesService,
		private currencyService: CurrencyService,
		private fundSourceService: FundSourceService,
		private tppGroupConfigStore: TppGroupConfigurationStore,
		private tppGroupConfigService: TppGroupConfigService,
		private breadCrumbService: BreadcrumbsService,
		private payGroupsService: PaygroupsService,
		private stateService: StateService,
		private toast: ToastService,
		private router: Router,
		private permissions: PermissionsService
	) {}

	ngOnInit(): void {
		this.permissions.canEditSourceOfFundsTPP().subscribe(res => {
			this.canEditSourceOfFundsTPP = res;
			this.setupPage();
		});

		this.permissions.canEditSettlementAccountTPP().subscribe(res => {
			this.canEditSettlementAccountTPP = res;
		});

		this.stateSubscription = this.stateService.customerStateChanged.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.tppGroupConfigStore.reset();

			this.setupPage(); // Rerun primary functions when state changed

			this.lastPage = false; //Get us back to SOF
			this.enableAddSourceOfFund = false;
		});
	}

	setupPage() {
		this.resetVariables();

		// this will run on customer/paygroup switch
		this.store.pipe(select(getCustomerEntityGroupState), takeUntil(this.destroy$)).subscribe(state => {
			if (this.selectedPayGroupId !== state.payGroupId && state.payGroupId) {
				this.selectedLegalEntityId = state.legalEntityId;
				this.selectedPayGroupId = state.payGroupId;

				this.payGroupsService
					.getPaygroupWithPaygroupId(this.selectedPayGroupId)
					.pipe(takeUntil(this.destroy$))
					.subscribe((paygroup: PayGroup) => {
						this.selectedCountryCode = paygroup.legalEntity.data.country;
						this.selectedPayGroupName = paygroup.data.name;
						this.selectedCustomerName = paygroup.customer.name;

						this.getRequiredData();
					});

				this.breadCrumbService
					.getBreadCrumb(state.payGroupId)
					.pipe(takeUntil(this.destroy$))
					.subscribe(res => {
						this.customerName = res.customerName;
						this.payGroupName = res.paygroupName;
						this.breadCrumbArray = [
							{
								display: true,
								crumb: this.customerName,
								type: "customer",
								link: "/service-definition/tpp"
							},
							{
								display: true,
								crumb: this.payGroupName,
								type: "paygroup",
								state: ["showSelectPGPage", "true"],
								link: "/service-definition/tpp/select-paygroup"
							},
							{
								display: true,
								crumb: this.tppServicesText,
								link: "/service-definition/tpp/select-paygroup",
								state: ["showSelectPGPage", "false"]
							},
							{
								display: true,
								crumb: this.tppGroupText
							}
						];
					});
			}
		});
	}

	getRequiredData() {
		this.serviceDefinition$ = this.tppGroupConfigStore.select(state => state);

		this.serviceDefinition$
			.pipe(
				take(1),
				takeUntil(this.destroy$),
				map(serviceDefinition => {
					let config;
					return (config = serviceDefinition.serviceDefinitionConfig.groupConfig!.find(
						(config: TPPGroupConfig) => (config.group === this.groupToConfig ? config : noop())
					)!);
				}),
				tap(config => (!config ? this.getTPPServiceDefinitionServices() : noop()))
			)
			.subscribe();

		this.getCurrencies();
		this.getServiceProviderOptions();
	}

	resetVariables() {
		this.groupToConfig = "";
		this.paymentCurrency = "";
		this.tppSubServicesNameList = [];
		this.sourceAccountSelectOptions = [];
	}

	providerSelected(providerId: string) {
		this.serviceProviderId = providerId;
	}

	settlementAccountSelected(settlementAccountId: string) {
		this.settlementAccountId = settlementAccountId;
	}

	getCurrencies() {
		this.currencies = this.currencyService.getCurrencyOptions().pipe(shareReplay());
	}

	getSourceAccounts(currency: string) {
		this.paymentCurrency = currency;
		this.fundSourceService
			.searchSourceOfFunds(this.selectedLegalEntityId, currency)
			.pipe(
				take(1),
				map((res: SourceFoundAccount[]) => {
					return res.map(account => {
						const option: SelectOption = {
							value: account.id,
							text: account.data.name
						};

						return formatDataForSelect(option);
					});
				}),
				map(selectOptions => {
					this.tppGroupConfigService.setSelectOptions(selectOptions);
					this.sourceAccountSelectOptions = selectOptions;
				})
			)
			.subscribe();
	}

	getServiceProviderOptions() {
		this.serviceProviderService
			.getTppProviderByCountry(this.selectedCountryCode)
			.pipe(
				take(1),
				map(res => {
					const providers = res.items;
					const serviceProvidersOptions = providers.map(item => {
						return {
							text: item.provider.data.name!,
							value: item.id!
						};
					});

					this.serviceProvidersCurrencies = providers.map(item => {
						return {
							providerCountryId: item.id!,
							currencies: item.provider.data.currencies!
						};
					});

					return {
						serviceProvidersOptions
					};
				})
			)
			.subscribe(({ serviceProvidersOptions }) => {
				this.serviceProvidersOptions = serviceProvidersOptions;
			});
	}

	getSettlementAccounts(event: { providerCountryId: string; currency: string }) {
		const group = this.tppGroupConfigStore.getConfigByGroupName(this.groupToConfig);
		this.tppCommonService
			.searchSettlementAccountsByLegalEntityId(this.selectedLegalEntityId, event.currency)
			.pipe(
				take(1),
				map((res: SettlementAccount[]) => {
					this.availableSettlementAccounts = [];
					this.settlementAccountOptions = [];
					this.providerCountryId = group?.providerCountryId!;
					const filteredArray = res.flatMap(obj =>
						obj.providerCountryId === group?.providerCountryId ? obj : []
					);
					this.availableSettlementAccounts = filteredArray;
					return this.availableSettlementAccounts;
				}),
				map(accounts => {
					return accounts.map(account => {
						const option: SelectOption = {
							value: account.id!,
							text: account.name
						};

						return formatDataForSelect(option);
					});
				}),
				map(selectOptions => (this.settlementAccountOptions = selectOptions))
			)
			.subscribe();
	}

	getTPPServiceDefinitionServices() {
		if (this.selectedPayGroupId) {
			this.tppServiceDefinitionService
				.getServiceDefinition(this.selectedPayGroupId)
				.pipe(
					takeUntil(this.destroy$),
					tap(serviceDefinition => {
						this.tppGroupConfigStore.setConfig(serviceDefinition);
						this.configHasBeenSet = true;
						this.serviceDefinition$ = this.tppGroupConfigStore.select(state => state);
					})
				)
				.subscribe(
					success => {
						// Handle the successful response
					},
					error => {
						if (error.status === 404) {
							// Handle the 404 error here
							this.router.navigate(["/service-definition/tpp/select-paygroup"]);
							//this.router.navigate(["/dashboard/service-definition/paygroup-select-tpp"]);
							this.toast.showError("Service Definition not found");
						} else {
							// Handle other errors here
							console.error("An error occurred: ", error);
						}
					}
				);
		}
	}

	checkStoredData() {
		this.serviceDefinition$
			.pipe(
				take(1),
				map(serviceDefinition => this.tppGroupConfigStore.getConfigByGroupName(this.groupToConfig)!),
				map((config: TPPGroupConfig) => {
					if (!!config) {
						this.serviceProviderId = config.providerCountryId ? config.providerCountryId : "";
						this.settlementAccountId = config.settlementAccountId ? config.settlementAccountId : "";

						if (config.fundingCurrency) {
							this.tppCommonService
								.searchSettlementAccountsByLegalEntityId(
									this.selectedLegalEntityId,
									config.fundingCurrency
								)
								.pipe(
									take(1),
									map((res: SettlementAccount[]) => {
										this.availableSettlementAccounts = [];
										this.settlementAccountOptions = [];

										const filteredArray = res.flatMap(obj =>
											obj.providerCountryId === config.providerCountryId ? obj : []
										);
										this.availableSettlementAccounts = filteredArray;
										return this.availableSettlementAccounts;
									}),
									map(accounts => {
										return accounts.map(account => {
											const option: SelectOption = {
												value: account.id!,
												text: account.name
											};
											return formatDataForSelect(option);
										});
									}),
									map(selectOptions => (this.settlementAccountOptions = selectOptions))
								)
								.subscribe();
						}
					}
				})
			)
			.subscribe();
	}

	getSubServicesNameList(tppGroup: string) {
		this.tppSubServicesNameList = [];

		this.groupToConfig = tppGroup;
		this.checkStoredData();
		this.serviceDefinition$
			.pipe(
				take(1),
				map(serviceDefinition =>
					serviceDefinition.serviceDefinitionConfig.services.filter((service: DefinitionService) =>
						service.group === tppGroup ? service : noop()
					)
				),
				mergeMap((services: DefinitionService[]) => {
					return forkJoin(
						services.map(service => {
							return this.tppServicesService.getTPPSubserviceById(service.subServiceId).pipe(
								map(subService => {
									if (!this.tppSubServicesNameList.includes(subService.name)) {
										this.tppSubServicesNameList.push(subService.name);
									}
								})
							);
						})
					);
				})
			)
			.subscribe();
	}

	resetPaymentCurrenciesOptions(providerId: string) {
		const providerCurrencies = this.serviceProvidersCurrencies.find(provider => {
			return provider.providerCountryId === providerId;
		})?.currencies;

		this.paymentCurrencyOptions = providerCurrencies?.map(currency => {
			return {
				text: currency,
				value: currency
			};
		});
	}

	showSelector() {}

	openSourceOfFund(isCreatingSourceOfFund: boolean): void {
		this.sourceOfFundId = "";

		this.serviceDefinition$
			.pipe(
				take(1),
				map(
					serviceDefinition =>
						serviceDefinition.serviceDefinitionConfig.groupConfig!.find((config: TPPGroupConfig) =>
							config.group === this.groupToConfig ? config : noop()
						)!
				),
				tap((config: TPPGroupConfig) => {
					if (!isCreatingSourceOfFund) {
						if (config && config.sourceAccountId) {
							this.sourceOfFundId = config.sourceAccountId;
						}

						this.paymentCurrency = config.paymentCurrency;

						this.dataFormSourceFund = this.tppServiceDefinitionService.getDataFormSourceFund();
					}

					this.enableAddSourceOfFund = true;
				})
			)
			.subscribe();
	}

	closeAddNewSourceOfFund(currency: string): void {
		this.getSourceAccounts(currency);

		this.enableAddSourceOfFund = false;
	}

	next(groupToConfig: string): void {
		this.lastPage = true;
		this.groupToConfig = groupToConfig;
	}

	openSettlementAccount(event: { open: boolean; edit: boolean; provider: string }): void {
		const group = this.tppGroupConfigStore.getConfigByGroupName(this.groupToConfig);
		this.settlementProviderName = event.provider;
		if (event.edit) {
			this.availableSettlementAccounts.find((account: SettlementAccount) => {
				if (account.id === group?.settlementAccountId) {
					this.selectedSettlementAccount = account;
					this.selectedCountryCode = account.bankAccount.country;

					if (!this.selectedSettlementAccount) {
						this.selectedSettlementAccount = {} as SettlementAccount;
					}

					this.enableAddSettlementAccount = event.open;
					this.isSettlementEdit = event.edit;
				}
			});
		} else {
			this.enableAddSettlementAccount = event.open;
			this.isSettlementEdit = event.edit;
		}
	}

	closeAddSettlementAccount(event: { isClose: boolean; providerCountryId: string }): void {
		this.enableAddSettlementAccount = event.isClose;
		const group = this.tppGroupConfigStore.getConfigByGroupName(this.groupToConfig);
		this.getSettlementAccounts({ providerCountryId: group?.providerCountryId!, currency: group?.fundingCurrency! });
	}

	returnToSourceAccount(event: { lastPage: boolean; group: string }): void {
		this.currencies = this.currencies;
		this.lastPage = !event.lastPage;
	}

	ngOnDestroy() {
		if (this.stateSubscription) {
			this.stateSubscription.unsubscribe();
		}
		this.tppServiceDefinitionService.resetDataSourceOfFund();
		this.destroy$.next();
		this.destroy$.complete();
	}

	resetPage() {
		this.ngOnInit();
	}
}
