import { EventEmitter, Injectable } from "@angular/core";
import { ContactPerson, ContactPersonObject } from "src/app/shared/models/contact-person.interface";
import { Customer } from "src/app/shared/models/customer.interface";

@Injectable({
	providedIn: "root"
})
export class CommonService {
	constructor() {}

	customerCreated$: EventEmitter<void> = new EventEmitter();

	getCustomerApiDto(customer: Customer, logoId: string, contactData?: ContactPerson[]): Customer {
		return {
			id: customer.id,
			logoId: logoId,
			address: customer.address,
			name: customer.name,
			externalId: customer.externalId,
			registrationId: customer.registrationId,
			contactPersons: customer.contactPersons || contactData,
			version: customer.version
		} as Customer;
	}

	contactPersonObject(objectId: string, contactData: ContactPerson[]) {
		if (!contactData) contactData = [];
		let contactPersons: ContactPerson[] = [];

		contactData.forEach(contactPerson => {
			contactPersons.push(contactPerson);
		});
		let contactPersonObject: ContactPersonObject = {
			objectId: objectId,
			contactPersons: contactPersons
		};
		return contactPersonObject;
	}

	customerCreated() {
		this.customerCreated$.next();
	}
}
