import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";

import { FileManagementService } from "../../../../../shared/services/file-management/file-management.service";
import { FileManagerTask, FileTypes } from "../../../../../shared/models/file-manager";
import { FileErorrDialogComponent } from "../../file-error-dialog/file-erorr-dialog/file-erorr-dialog.component";

@Component({
	selector: "app-files-list",
	templateUrl: "./files-list.component.html",
	styleUrls: ["./files-list.component.scss"]
})
export class FilesListComponent implements OnInit {
	_files!: FileManagerTask[];
	@Input() set files(value: FileManagerTask[]) {
		this._files = value;
	}

	fileSubscription!: Subscription;
	fileDownload$!: Subscription;

	constructor(private fileManagementApiService: FileManagementService, public dialog: MatDialog) {}

	ngOnInit(): void {}

	onViewFile(taskId: string): void {
		const dialogRef = this.dialog.open(FileErorrDialogComponent, {
			width: "995px",
			height: "570px",
			panelClass: "dialog-container",
			data: {
				taskId: taskId,
				errorType: FileTypes.PAY_ELECTIVES
			}
		});

		dialogRef.afterClosed().subscribe((result: boolean) => {
			if (result) {
				//this.location.back();
			}
		});
	}

	downloadFile(fileId: string, fileName: string): void {
		this.fileSubscription = this.fileManagementApiService.getFileObject(fileId).subscribe((response: Blob) => {
			let downloadLink = document.createElement("a");
			downloadLink.href = window.URL.createObjectURL(response);
			if (fileName) downloadLink.setAttribute("download", fileName);
			document.body.appendChild(downloadLink);
			downloadLink.click();
		});
	}

	ngOnDestroy() {
		if (this.fileSubscription) this.fileSubscription.unsubscribe();
	}
}
