import { Pipe, PipeTransform } from "@angular/core";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { TppAccount } from "src/app/shared/models/tpp-account.interface";

@Pipe({
	name: "selectOptionsFromTppaccount"
})
export class SelectOptionsFromTppaccountPipe implements PipeTransform {
	transform(tppAccounts: TppAccount[]): SelectOption[] {
		const selectOptions: SelectOption[] = tppAccounts.map((item: TppAccount) => ({
			text: item.name,
			value: item.name
		}));
		return selectOptions;
	}
}
