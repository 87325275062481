import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InputAutocompleteComponent } from "./input-autocomplete.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatInputModule } from "@angular/material/input";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatIconModule } from "@angular/material/icon";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { SharedModule } from "@shared/shared.module";

@NgModule({
	declarations: [InputAutocompleteComponent],
	imports: [
		CommonModule,
		MatFormFieldModule,
		FormsModule,
		ReactiveFormsModule,
		MatTooltipModule,
		MatInputModule,
		MatIconModule,
		MatAutocompleteModule,
		MatCheckboxModule,
		SharedModule
	],
	exports: [InputAutocompleteComponent]
})
export class InputAutocompleteModule {}
