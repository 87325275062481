import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TooltipComponent } from "./tooltip.component";
import { FlexLayoutModule } from "ngx-flexible-layout";

@NgModule({
	declarations: [TooltipComponent],
	imports: [CommonModule, FlexLayoutModule],
	exports: [TooltipComponent]
})
export class TooltipModule {}
