import { Component, OnInit } from "@angular/core";

@Component({
	selector: "app-bank-accounts",
	templateUrl: "./bank-accounts.page.html",
	styleUrls: ["./bank-accounts.page.scss"]
})
export class BankAccountsPage implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
