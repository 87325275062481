import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CurrencyService } from "@shared/services/currency/currency.service";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { CurrencyCode } from "src/app/shared/models/service-provider.type";
import { PermissionsService } from "../../../../shared/services/permissions/permissions.service";
import { CurrencyAndFundingData } from "../../../../shared/models/service-provider-country-types";
import { ServiceProviderCountryEditService } from "../../_services/service-provider-country-edit/service-provider-country-edit.service";
import { CapabiltiesEditFormComponent } from "./capabilties-edit-form/capabilties-edit-form.component";

@Component({
	selector: "app-service-providers-capabilities-edit",
	templateUrl: "./service-providers-capabilities-edit.component.html",
	styleUrls: ["./service-providers-capabilities-edit.component.scss"]
})
export class ServiceProvidersCapabilitiesEditComponent implements OnInit, OnDestroy {
	@ViewChild(CapabiltiesEditFormComponent) capabilitiesComponent!: CapabiltiesEditFormComponent;

	currenciesForm!: FormGroup;
	currencyOptions$: Observable<SelectOption[]> = new Observable<SelectOption[]>();
	destroy$: Subject<void> = new Subject();

	isSuperUser: boolean = false;

	constructor(
		private readonly formBuilder: FormBuilder,
		private readonly serviceProviderCountryEditService: ServiceProviderCountryEditService,
		private readonly currencyService: CurrencyService,
		private permissions: PermissionsService
	) {}

	ngOnInit(): void {
		this.permissions
			.canEditServiceProviders()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => {
				console.log("isSuperUser: ", res);
				this.isSuperUser = res;

				this.initForm();
			});
		this.currencyOptions$ = this.currencyService.getCurrencyOptions(true);

		this.initSubscriptions();
		this.onFormChanges();
	}

	initForm(): void {
		const currencyAndFunding: CurrencyAndFundingData =
			this.serviceProviderCountryEditService.getServiceProviderCountryCurrenciesAndFundingMethods();

		this.currenciesForm = this.formBuilder.group({
			currencies: [{ value: currencyAndFunding.currencies, disabled: !this.isSuperUser }, Validators.required],
			pushMethod: [
				{
					value: currencyAndFunding.fundingMethods?.includes("PUSH") ? true : false,
					disabled: !this.isSuperUser
				}
			],
			pullMethod: [
				{
					value: currencyAndFunding.fundingMethods?.includes("PULL") ? true : false,
					disabled: !this.isSuperUser
				}
			]
		});

		this.currenciesForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.serviceProviderCountryEditService.formChanges();
		});
	}

	onFormChanges(): void {
		this.currenciesForm.valueChanges.subscribe(() => {
			const isPushChecked = this.currenciesForm.get("pullMethod")?.value;
			const isPullChecked = this.currenciesForm.get("pushMethod")?.value;
			if (!isPullChecked && !isPushChecked) {
				this.currenciesForm.setErrors({ checkbox: "error" });
			} else {
				this.currenciesForm.setErrors(null);
			}
		});

		//tell add pages that the form has changed

		this.currenciesForm.statusChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe((status: "VALID" | "INVALID" | "PENDING" | "DISABLED") => {
				this.serviceProviderCountryEditService.initCurrenciesChanged$.emit();
				const valid: boolean = status === "VALID";
				this.serviceProviderCountryEditService.currenciesEditChanged(valid);
				this.capabilitiesComponent.onCheckForm();
			});
	}

	initSubscriptions(): void {
		this.serviceProviderCountryEditService.updateCurrenciesData$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.serviceProviderCountryEditService.updateCurrencies(this.currenciesForm.getRawValue());
		});

		this.serviceProviderCountryEditService.initCostsChanged$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.onCheckForm();
		});
	}

	onCheckForm(): void {
		const status: string = this.currenciesForm.status;
		const isValid: boolean = status === "VALID";
		setTimeout(() => this.serviceProviderCountryEditService.currenciesEditChanged(isValid), 100);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
