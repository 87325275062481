export default async (name: string, context: CanvasRenderingContext2D) => {
	const character = name[0].toUpperCase();

	context.canvas.width = 130; //horizontal resolution (?) - increase for better looking text
	context.canvas.height = 130; //vertical resolution (?) - increase for better looking text
	context.canvas.style.width = "130px"; //actual width of canvas
	context.canvas.style.height = "130px"; //actual height of canvas

	context.scale(1, 1);
	context.fillStyle = "#2e3a59";
	context.fillRect(0, 0, context.canvas.width, context.canvas.height);
	context.font = "bold 100px monospace";
	context.textAlign = "center";
	context.fillStyle = "#FFF";
	context.fillText(character, context.canvas.width / 2, context.canvas.height / 1.3);

	//create image
	const dataURL = context.canvas.toDataURL();
	const blob = await (await fetch(dataURL)).blob();
	return new File([blob], name, { type: "image/jpeg", lastModified: new Date().getDate() });
};
// const createDefaultLogo async(name: string): Promise<File> => {

// }
