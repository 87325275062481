import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { PermissionsService } from "../../shared/services/permissions/permissions.service";
import { CreateServiceProviderService } from "./_services/create-service-provider/create-service-provider.service";

@Component({
	selector: "app-service-provider-setup",
	templateUrl: "./service-provider-setup.page.html",
	styleUrls: ["./service-provider-setup.page.scss"]
})
export class ServiceProviderSetupPage implements OnInit, OnDestroy {
	showCreateForm: boolean = false;
	subscription: Subscription = new Subscription();
	serviceProviderLength: number | undefined;
	cancelCreateSubscription: Subscription = new Subscription();
	destroy$: Subject<void> = new Subject();

	isSuperUser: boolean = false;

	constructor(
		private readonly createServiceProviderService: CreateServiceProviderService,
		private readonly permissions: PermissionsService
	) {}

	ngOnInit(): void {
		this.permissions
			.canEditServiceProviders()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => (this.isSuperUser = res));

		this.subscription = this.createServiceProviderService.providerAdded$
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.toggleShowproviderSettingsForm();
			});

		this.cancelCreateSubscription = this.createServiceProviderService.cancelCreate$
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.toggleShowproviderSettingsForm();
			});
	}

	toggleShowproviderSettingsForm(): void {
		this.showCreateForm = !this.showCreateForm;
	}

	getServiceProviderLength(length: number) {
		this.serviceProviderLength = length;
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
