<div *ngIf="fields?.length" class="flex flex-column">
	<div
		class="field-item flex flex-row flex-align-center-space-between flex-1"
		style="--max-width: 100%"
		*ngFor="let field of fields; let i = index"
	>
		<input-wrapper class="flex-1" style="--max-width: 20%" label="Key">
			<input type="text" [disabled]="true" value="{{ field.key }}" />
		</input-wrapper>
		<input-wrapper class="flex-1" style="--max-width: 20%" label="Name">
			<input type="text" [disabled]="true" value="{{ field.name }}" />
		</input-wrapper>
		<input-wrapper class="flex-1" style="--max-width: 20%" label="Type">
			<input type="text" [disabled]="true" value="{{ field.type }}" />
		</input-wrapper>
		<input-wrapper class="last flex-1" style="--max-width: 6%" label="Required">
			<mat-slide-toggle [disabled]="true" [checked]="field.required"></mat-slide-toggle>
		</input-wrapper>
		<input-wrapper class="last flex-1" style="--max-width: 6%" label="Secret">
			<mat-checkbox id="secret" class="secret-checkbox" [disabled]="true" [checked]="field.secret"></mat-checkbox>
		</input-wrapper>
		<input-wrapper class="last delete flex-1" style="--max-width: 3%">
			<button matTooltip="remove fields" (click)="removeField(field.key)" class="remove-button">
				<app-icon [name]="'trash'" [color]="'var(--color-danger-500)'"></app-icon>
			</button>
		</input-wrapper>
	</div>
	<mat-divider></mat-divider>
</div>
