import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatDialogModule } from "@angular/material/dialog";
import { MatInputModule } from "@angular/material/input";
import { MatCardModule } from "@angular/material/card";
import { ExpansionPanelModule } from "@shared/components/expansion-panel/expansion-panel.module";
import { FormsSharedModule } from "@shared/modules/forms-shared.module";
import { LegalEntitySetupRoutingModule } from "./legal-entity-setup-routing.module";
import { LegalEntitySetupPage } from "./legal-entity-setup.page";
import { CreateLegalEntityComponent } from "./_components/create-legal-entity/create-legal-entity.component";
import { UpdateLegalEntityComponent } from "./_components/update-legal-entity/update-legal-entity.component";
import { PaginationModule } from "@shared/components/pagination/pagination.module";
import { NoItemsLayoutModule } from "@shared/components/no-items-layout/no-items-layout.module";
import { LegalEntityEntryComponent } from "./_components/legal-entity-entry/legal-entity-entry.component";
import { CustomerListModule } from "@shared/components/customer-list/customer-list.module";
import { MatPaginatorModule } from "@angular/material/paginator";
import { ContactsModule } from "@shared/components/contacts/contacts.module";
import { FlexLayoutModule } from "ngx-flexible-layout";
import { CustomerLegalEntitySelectModule } from "@shared/components/customer-legal-entity-select/customer-legal-entity-select.module";
import { CommonLegalEntityService } from "./_services/common/common-legal-entity.service";
import { SharedModule } from "@shared/shared.module";
import { ModalWrapperModule } from "@shared/components/modal-wrapper/modal-wrapper.module";
import { LegalEntityAmlHistoryComponent } from "./_components/legal-entity-aml-history/legal-entity-aml-history.component";
import { LegalEntityAmlHistoryDialogComponent } from "./_components/legal-entity-aml-history-dialog/legal-entity-aml-history-dialog.component";
import { FullAmlFormComponent } from "./_components/full-aml-form/full-aml-form.component";
import { HistoryViewModule } from "@shared/components/history-view/history-view.module";
import { DashboardPageModule } from "../config/dashboard.module";
import { BreadcrumbsModule } from "@shared/components/breadcrumbs/breadcrumbs.module";
import { LegalEntityItemModule } from "@shared/components/wpay-ui/legal-entity-item/legal-entity-item.module";

@NgModule({
	declarations: [
		LegalEntitySetupPage,
		CreateLegalEntityComponent,
		UpdateLegalEntityComponent,
		LegalEntityEntryComponent,
		LegalEntityAmlHistoryComponent,
		LegalEntityAmlHistoryDialogComponent,
		FullAmlFormComponent
	],
	imports: [
		CommonModule,
		CustomerLegalEntitySelectModule,
		LegalEntitySetupRoutingModule,
		FormsSharedModule,
		MatIconModule,
		MatButtonModule,
		MatDialogModule,
		MatInputModule,
		MatCardModule,
		ExpansionPanelModule,
		MatProgressSpinnerModule,
		PaginationModule,
		NoItemsLayoutModule,
		CustomerListModule,
		MatPaginatorModule,
		ContactsModule,
		FlexLayoutModule,
		ModalWrapperModule,
		SharedModule,
		HistoryViewModule,
		DashboardPageModule,
		BreadcrumbsModule,
		LegalEntityItemModule
	],
	providers: [CommonLegalEntityService]
})
export class LegalEntitySetupPageModule {}
