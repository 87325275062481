import { Injectable } from "@angular/core";

@Injectable({
	providedIn: "root"
})
export class FeatureService {
	private features: Map<string, boolean> = new Map();

	constructor() {
		this.loadFeatures();
	}

	loadFeatures(): void {
		this.features.set("netPaymentOrderScreen", false);
	}

	isFeatureEnabled(featureName: string): boolean {
		return this.features.get(featureName) ?? false;
	}

	setFeatureFlag(featureName: string, isEnabled: boolean): void {
		this.features.set(featureName, isEnabled);
	}
}
