<div class="header" fxLayoutAlign="center center" fxLayoutGap="5px">
	<app-icon [name]="'danger'" [size]="'32px'" [color]="'white'" fxFlex="5" (click)="onCancel()"></app-icon>
	<h3 class="dialog-text">{{ "Warning!" | uppercase }}</h3>
</div>

<div class="content">
	<div
		class="warning-text-container"
		mat-dialog-content
		fxLayoutAlign="center center"
		*ngIf="!reProcesMapPayoutsAndTxId.size"
	>
		<span class="warning-text">
			Are you sure you want to <br />
			{{ processPaymentMenuSelection | lowercase }} ?
		</span>
	</div>

	<div class="reprocess-container warning-text-container" *ngIf="reProcesMapPayoutsAndTxId.size">
		<div class="reprocess-warning-text">
			You are about to reprocess

			<span class="bold">{{ reProcesMapPayoutsAndTxId.size }}</span>
			<span class="bold" *ngIf="reProcesMapPayoutsAndTxId.size === 1"> transaction</span>
			<span class="bold" *ngIf="reProcesMapPayoutsAndTxId.size > 1"> transactions</span>
		</div>
		<span class="reprocess-warning-text"> Are you sure you want to continue? </span>
	</div>

	<mat-divider></mat-divider>

	<mat-dialog-actions class="actions" fxLayout="row" mat-dialog-actions fxLayoutAlign="end none">
		<div class="actions-container">
			<button class="dialog-action-button" action-buttons mat-stroked-button (click)="onCancel()">CANCEL</button>
			<button
				class="dialog-action-button"
				action-buttons
				color="dark"
				mat-flat-button
				(click)="onConfirm()"
				cdkFocusInitial
				[disabled]="isProcessing"
			>
				CONTINUE
			</button>
		</div>
	</mat-dialog-actions>
</div>

<!--
	Important for iteration 2 of epic

	<span class="status bold" *ngFor="let group of groupedTransaction; last as isLast"> </span>

	<span *ngIf="!isLast">{{ group.count }} {{ group.status | transactionStatuses | lowercase }}, </span>
				<span *ngIf="isLast">
					<span *ngIf="groupedTransaction.length > 1"> and </span>{{ group.count }}
					{{ group.status | transactionStatuses | lowercase }}
				</span>

-->
