<app-breadcrumbs [headerTitle]="'PAY GROUPS'">
	<div class="button-container">
		<ng-template
			[appUserRolePermissions]
			[action]="'show'"
			[roles]="['TS_IMPLEMENTATION_LEAD', 'TS_OPERATION_LEAD', 'SUPER_ADMIN']"
		>
			<button
				class="download-btn-paygroup"
				mat-raised-button
				color="primary"
				id="submit"
				(click)="downloadPaygroups()"
				action-buttons
				[disabled]=""
			>
				<app-icon id="download-icon-paygroup" [name]="'document-download'" [color]="'white'"></app-icon>Download
				Report
			</button>
		</ng-template>
	</div>
</app-breadcrumbs>

<div class="paygroups-list-container">
	<customer-legal-entity-select
		(searchChanged)="filterPaygroups($event)"
		[enableSelectAll]="true"
		[gridClass]="2"
		[enablePayGroupSearch]="true"
	></customer-legal-entity-select>

	<div class="pay-group-list-filters-container" fxLayoutAlign="space-between center">
		<app-pay-group-list-filters
			[legalEntitySelectionOptions]="legalEntitySelectionOptions"
			(filtersChanged)="filtersChanged($event)"
		></app-pay-group-list-filters>
	</div>

	<div class="list-container" *ngIf="payGroups$ | async as payGroups">
		<!-- If there are items -->
		<ng-container *ngIf="payGroups.length; else noItems">
			<app-pay-group-list-item *ngFor="let payGroup of payGroups" [payGroup]="payGroup"></app-pay-group-list-item>
		</ng-container>

		<div
			class="floating-button-container"
			*ngIf="selectedLegalEntityId && selectedLegalEntityId !== '' && canCreatePayGroups"
		>
			<app-floating-action-button (createEvent)="createClicked()"></app-floating-action-button>
		</div>
	</div>

	<ng-container>
		<app-pagination
			*ngIf="totalPageCount"
			[length]="totalPageCount"
			[pageSizeOptions]="[5, 10, 25, 100]"
			[pageSize]="pageSize"
			[pageIndex]="pageIndex"
			[paginationType]="paginationType"
			aria-label="Select page"
		>
		</app-pagination>
	</ng-container>

	<!-- If there are not items -->
	<ng-template #noItems>
		<no-items-layout message="No items created yet"></no-items-layout>
	</ng-template>
</div>
