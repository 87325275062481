import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "@auth0/auth0-angular";

const routes: Routes = [
	{
		path: "",
		redirectTo: "/payments",
		pathMatch: "full"
	},
	{
		path: "",
		loadChildren: () => import("@modules/config/dashboard.module").then(m => m.DashboardPageModule),
		canActivate: [AuthGuard]
	},
	{
		path: "wpay-ui",
		loadChildren: () => import("@modules/wpay-ui/wpay-ui.module").then(m => m.WpayUiModule)
	},

	{
		path: "**",
		redirectTo: "/payments",
		pathMatch: "full"
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {}
