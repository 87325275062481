<modal-wrapper
	[title]="'Calendar setup file history'"
	(onTitleButtonAction)="backFromModal()"
	[fileUpload]="true"
	[modalStyle]="'dark'"
	[noBreadCrumbs]="true"
	[exitIconBool]="true"
>
	<div class="file-man-container">
		<app-calendar-file-list
			(totalCalendarFilesCount)="calendarFilesTotalCount($event)"
			(currentCalendarFilesCount)="calendarFilesCurrentCount($event)"
		/>
	</div>
	<div
		class="load-more-btn-container flex flex-row flex-align-center-center"
		*ngIf="isShowLoadMore && !calendarFileList?.fetchingFiles"
	>
		<button class="load-more-btn" (click)="calendarFileList?.loadMoreFilesData(calendarFileList!.perPage)">
			Load more
		</button>
	</div>

	<div
		class="max-record-container flex flex-row flex-align-center-center"
		*ngIf="reachedAllCalendarFilesRecords && !calendarFileList?.fetchingFiles"
	>
		<span class="max-record">You have reached the end of the records</span>
	</div>
</modal-wrapper>
