import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { shareReplay, takeUntil } from "rxjs/operators";
import { CountriesService } from "@shared/services/countries/countries.service";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { PermissionsService } from "../../../../shared/services/permissions/permissions.service";

@Component({
	selector: "app-add-provider-country",
	templateUrl: "./add-provider-country.component.html",
	styleUrls: ["./add-provider-country.component.scss"]
})
export class AddProviderCountryComponent implements OnInit, OnDestroy {
	countryForm!: FormGroup;
	isSuperUser: boolean = false;

	destroy$: Subject<void> = new Subject();

	@Input() providerId: string = "";
	@Input() providerName: string = "";

	countrySelectOptions$!: Observable<SelectOption[]>;

	constructor(
		private readonly formBuilder: FormBuilder,
		private readonly countryService: CountriesService,
		private router: Router,
		private permissions: PermissionsService
	) {}

	ngOnInit(): void {
		this.permissions
			.canEditServiceProviders()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => {
				console.log("isSuperUser: ", res);
				this.isSuperUser = res;

				this.initForm();
			});
		this.countrySelectOptions$ = this.countryService.getCountryOptions().pipe(shareReplay());
		this.initForm();
	}

	initForm(): void {
		this.countryForm = this.formBuilder.group({
			country: []
		});
		if (!this.isSuperUser) {
			this.countryForm.disable();
		}
	}

	createCountry() {
		const countrySelected = this.countryForm.get("country")?.value;

		this.countrySelectOptions$.pipe(takeUntil(this.destroy$)).subscribe((countries: SelectOption[]) => {
			countries.filter(country => {
				if (country.value === countrySelected) {
					this.router.navigate(["/service-provider-setup/add-country"], {
						state: {
							providerId: this.providerId,
							providerName: this.providerName,
							countryName: country.text,
							countryCode: country.value
						}
					});
				}
			});
		});
	}

	canCreateCountry(): Observable<boolean> {
		return this.permissions.canEditServiceProviders();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
