import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DashboardPage } from "./dashboard.page";
import { DashboardPageRoutingModule } from "./dashboard-routing.module";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatListModule } from "@angular/material/list";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { SharedModule } from "@shared/shared.module";
import { LayoutModule } from "@angular/cdk/layout";
import { FlexLayoutModule } from "ngx-flexible-layout";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BreadcrumbsModule } from "@shared/components/breadcrumbs/breadcrumbs.module";
import { SideNavComponent } from "./_components/side-nav/side-nav.component";

@NgModule({
	declarations: [DashboardPage, SideNavComponent],
	imports: [
		BreadcrumbsModule,
		DashboardPageRoutingModule,
		CommonModule,
		SharedModule,
		MatSidenavModule,
		MatListModule,
		MatExpansionModule,
		MatIconModule,
		MatToolbarModule,
		MatFormFieldModule,
		MatInputModule,
		MatButtonModule,
		MatMenuModule,
		MatProgressSpinnerModule,
		LayoutModule,
		FlexLayoutModule,
		MatTooltipModule
	],
	exports: [DashboardPage, SideNavComponent]
})
export class DashboardPageModule {}
