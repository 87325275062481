import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ModalLauncherFactory } from "./factory/ModalLauncherFactory";
import { DescriptionModalComponent } from "./templates/description-modal/description-modal.component";
import { PayElectiveHistoryModalData } from "./templates/pay-elective-history/model/PayElectiveHistoryModalData";
import { PayElectiveHistoryComponent } from "./templates/pay-elective-history/pay-elective-history.component";
import {
	ActionConfirmModalComponent as ActionConfirmModalComponent,
	ActionConfirmModalData as ActionConfirmModalData
} from "./templates/action-confirm-dialog/action-confirm-dialog.component";
import { cancelBookingDialog } from "./templates/cancel-booking/model/cancel-booking.interface";
import { CancelBookingComponent } from "./templates/cancel-booking/cancel-booking.component";

import { PaygroupStatusHistoryComponent } from "./templates/paygroup-status-history/paygroup-status-history/paygroup-status-history.component";
import { PayGroupHistoryReport } from "src/app/shared/models/pay-groups";
import { PayrollIntegrationModalComponent } from "./templates/payroll-integration-modal/payroll-integration-modal.component";
import { ConfirmationPopUpComponent } from "./templates/confirmation-pop-up/confirmation-pop-up.component";
import { confirmationConfig } from "./templates/confirmation-pop-up/model/confirmation-pop-up.interface";

@Injectable({
	providedIn: "root"
})
export class ModalLauncherService {
	constructor(private dialog: MatDialog) {}

	showDescriptionModal(title: String, description: String): MatDialogRef<DescriptionModalComponent> {
		const data = {
			title: title,
			description: description,
			style: {
				panelClass: "description-dialog"
			}
		};
		return new ModalLauncherFactory().data(data).displayModal(DescriptionModalComponent, this.dialog);
	}

	showPayElectiveHistoryModal(modalData: PayElectiveHistoryModalData): MatDialogRef<DescriptionModalComponent> {
		const style = {
			panelClass: "select-modalbox",
			width: "900px"
		};
		return new ModalLauncherFactory()
			.data(modalData)
			.style(style)
			.displayModal(PayElectiveHistoryComponent, this.dialog);
	}

	showActionConfirmModal(dialogData: ActionConfirmModalData): MatDialogRef<ActionConfirmModalComponent> {
		const style = {
			panelClass: "confirm-dialog",
			width: "900px"
		};
		return new ModalLauncherFactory()
			.data(dialogData)
			.style(style)
			.displayModal(ActionConfirmModalComponent, this.dialog);
	}

	showCancelBookingModal(dialogData: cancelBookingDialog): MatDialogRef<CancelBookingComponent> {
		const style = {
			panelClass: "confirm-dialog",
			width: "900px"
		};
		return new ModalLauncherFactory()
			.data(dialogData)
			.style(style)
			.displayModal(CancelBookingComponent, this.dialog);
	}

	showPaygroupHistoryModal(dialogData: PayGroupHistoryReport): MatDialogRef<PaygroupStatusHistoryComponent> {
		const style = {
			panelClass: "confirm-dialog",
			width: "900px"
		};
		return new ModalLauncherFactory()
			.data(dialogData)
			.style(style)
			.displayModal(PaygroupStatusHistoryComponent, this.dialog);
	}

	showPayrollIntegrationHistoryModal(dialogData: number): MatDialogRef<PaygroupStatusHistoryComponent> {
		const style = {
			panelClass: "confirm-dialog",
			width: "680px"
		};
		return new ModalLauncherFactory()
			.data(dialogData)
			.style(style)
			.displayModal(PayrollIntegrationModalComponent, this.dialog);
	}

	showConfirmationPopUp(dialogData: confirmationConfig): MatDialogRef<ConfirmationPopUpComponent> {
		const style = {
			panelClass: "confirm-dialog",
			width: "666px"
		};
		return new ModalLauncherFactory()
			.data(dialogData)
			.style(style)
			.displayModal(ConfirmationPopUpComponent, this.dialog);
	}
}
