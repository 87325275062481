import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { MenuService } from "@modules/config/_services/menu/menu.service";
import { TppAccount } from "src/app/shared/models/tpp-account.interface";
import { SubService, SubServiceStoreDataForm } from "src/app/shared/models/tpp-service.interface";
import { TppService } from "src/app/shared/models/tpp-service.interface";

@Injectable({
	providedIn: "root"
})
export class TppServiceManagementService {
	private isNewTppService$ = new BehaviorSubject<boolean>(true);
	private showBeneficiaryAccountForm = new Subject<boolean>();
	private tppService!: TppService;
	private newSubServiceForm!: SubServiceStoreDataForm;
	private _countryCode: string = "";
	private _beneficiaryAccountSelected!: TppAccount;
	private isNewSubService$ = new Subject<boolean>();

	constructor(private menuService: MenuService) {}

	get tppServiceData() {
		return this.tppService;
	}

	setTppServiceDetail(tppService: TppService) {
		this.tppService = tppService;
	}

	setIsNewTppService(isNew: boolean) {
		this.isNewTppService$.next(isNew);
	}

	getIsNewTppService(): Observable<boolean> {
		return this.isNewTppService$.asObservable();
	}

	resetTppService() {
		this.isNewTppService$ = new BehaviorSubject<boolean>(true);
		this.tppService = {} as TppService;
		if (this.newSubServiceForm) this.newSubServiceForm = {} as SubServiceStoreDataForm;
	}

	existSubServicePaymentDefinition(subServicePaymentDefinition: SubService, subServices: SubService[]): boolean {
		const exist = subServices.find((item: SubService) => item.name === subServicePaymentDefinition.name);
		if (exist) return true;
		else return false;
	}

	setNewSubserviceData(formValues: SubServiceStoreDataForm) {
		this.newSubServiceForm = formValues;
	}

	getNewSubserviceForm() {
		return this.newSubServiceForm;
	}

	set countryCodeServiceTPP(countryCode: string) {
		this._countryCode = countryCode;
	}

	get countryCodeServiceTPP() {
		return this._countryCode;
	}

	setBeneficairyAccountState(enable: boolean) {
		if (enable) {
			this.menuService.setVisible(false);
		} else {
			this.menuService.setVisible(true);
		}

		this.showBeneficiaryAccountForm.next(enable);
	}

	getBeneficiaryAccountState(): Observable<boolean> {
		return this.showBeneficiaryAccountForm.asObservable();
	}

	set beneficiaryAccount(beneficiaryAccountSelected: TppAccount) {
		this._beneficiaryAccountSelected = beneficiaryAccountSelected;
	}

	get beneficiaryAccount() {
		return this._beneficiaryAccountSelected;
	}

	setIsNewSubService(isNew: boolean) {
		this.isNewSubService$.next(isNew);
	}

	getIsNewSubService(): Observable<boolean> {
		return this.isNewSubService$.asObservable();
	}
}
