import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { ErrorModel } from "../../models/error-management.models";
import { FormBuilder, FormGroup } from "@angular/forms";

@Component({
	selector: "app-output-message-dialog",
	templateUrl: "./output-message-dialog.component.html",
	styleUrls: ["./output-message-dialog.component.scss"]
})
export class OutputMessageDialogComponent implements OnInit {
	form!: FormGroup;
	readonly: boolean = false;
	constructor(
		private dialogRef: MatDialogRef<OutputMessageDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: { readonly: boolean; errorData: ErrorModel },
		private fb: FormBuilder
	) {}

	ngOnInit(): void {
		this.readonly = this.data.readonly;
		this.form = this.fb.group({
			id: [this.data.errorData.id],
			version: [this.data.errorData.version],
			inputMessage: [{ value: this.data.errorData.inputMessage, disabled: true }],
			outputMessage: [this.data.errorData.outputMessage],
			errorType: [this.data.errorData.errorType]
		});
	}

	close() {
		this.dialogRef.close();
	}

	save() {
		this.dialogRef.close(this.form.getRawValue());
	}
}
