<div class="event-container" *ngIf="milestone" (mouseleave)="toggleDropDown(false)">
	<div
		id="dashboardEventItem{{ milestone.type }}"
		class="dashboard-event-item"
		[ngClass]="{
			skeleton: componentDataLoading,
			'skeleton-reveal': !componentDataLoading
		}"
		(click)="onMilestoneClick(milestone!.type)"
	>
		<!-- left side -->

		<div class="left-side">
			<div class="milestone-icon" [fxLayout]="'column'">
				<app-icon
					[size]="'30'"
					[color]="'var(--color-disabled)'"
					[name]="milestone.type | milestoneTypeToIcon"
				></app-icon>
			</div>
			<div [fxLayout]="'column'">
				<div class="title">{{ milestone | milestoneTypes }}</div>
				<div class="subtitle">{{ milestone.date | suffixAndAbbreviatedDate }}</div>
			</div>
		</div>

		<!-- Right side -->
		<div
			class="right-side"
			id="status-container-{{ milestone.type }}"
			*ngIf="milestone.status != 'UNDEFINED' && milestonesSelection"
		>
			<div class="info-container" *ngIf="milestoneReport">
				<div class="amount column">
					<span class="title"> TOTAL PAYMENT </span>

					<span class="value">
						{{ milestoneReport.totalPayoutAmount.value | number : "1.2-2"
						}}{{ milestoneReport.totalPayoutAmount.currency | iso3CountryToCurrencySymbol }}
						<span *ngIf="milestoneReport.totalFailedAmount.value > 0" class="failed">
							({{ milestoneReport.totalFailedAmount.value | number : "1.2-2"
							}}{{ milestoneReport.totalFailedAmount.currency | iso3CountryToCurrencySymbol }})
						</span>
					</span>
				</div>

				<div class="currency column" fxLayoutGap="8px">
					<span class="title"> PAYMENT. CCY </span>
					<span class="value">
						{{ milestoneReport.totalPayoutAmount.currency }}
					</span>
				</div>
			</div>
			<div
				class="status-container"
				[ngClass]="{
					'complete-style': milestone.status === 'COMPLETE' || milestone.status === 'Completed',
					'inProgress-style': milestone.status === 'IN_PROGRESS' || milestone.status === 'In progress',
					'pending-style': milestone.status === 'PENDING' || milestone.status === 'Pending',
					'errors-style': milestone.status === 'ERROR' || milestone.status === 'Has errors',
					selected: showDropDown
				}"
				(click)="$event.stopPropagation()"
			>
				<mat-form-field (click)="toggleDropDown()" [formGroup]="milestoneStatusForm" appearance="fill">
					<input
						matInput
						[readonly]="true"
						type="text"
						class="status-input"
						[formControlName]="'milestoneStatus'"
						[value]="milestone?.status"
					/>

					<app-icon
						matSuffix
						class="drop-file-trigger"
						[lineWidth]="'2.5'"
						[name]="'arrow-down'"
						[size]="'16'"
						[ngClass]="{ selected: showDropDown }"
					></app-icon>
				</mat-form-field>
			</div>
		</div>
	</div>
	<div class="status-options-container" *ngIf="showDropDown">
		<div class="status-options">
			<div
				class="status-option"
				(click)="setStatusSelection(mil.value)"
				*ngFor="let mil of milestonesSelection; last as last"
			>
				<span
					id="options-center-{{ milestone.type }}"
					class="option-text"
					[ngClass]="{
						'complete-option': mil.value === 'COMPLETE',
						'inProgress-option': mil.value === 'IN_PROGRESS',
						'pending-option': mil.value === 'PENDING',
						'errors-option': mil.value === 'ERROR'
					}"
					>{{ mil.text }}
				</span>
			</div>
		</div>
	</div>
</div>
