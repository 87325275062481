import { AbstractControl } from "@angular/forms";

export const atLeastOneValidator = (control: AbstractControl): { [key: string]: boolean } | null => {
	const confirmControlA: number = control.get("paymentFee")?.value;
	const confirmcontrolB: number = control.get("monthlyFee")?.value;

	let errors: any = null;

	if (confirmControlA === 0 && confirmcontrolB === 0) {
		errors = { atLeastOne: true };
	}

	return errors;
};
