export type FundingMethodName = "PUSH" | "PULL";

export enum PaymentTypeName {
	"NETS",
	"TAX",
	"OTHER_STATS",
	"3D_PARTY",
	"CP_NOW",
	"WEZAAM",
	"TPP"
}

export type PaymentTypeInterface = keyof typeof PaymentTypeName;

export enum PayoutAccountName {
	"DIRP",
	"ICP",
	"ICA",
	"ICPA",
	"ICAE",
	"OICA"
}

export type PayoutAccountInterface = keyof typeof PayoutAccountName;

export type PaymentRoute = {
	name: PaymentRouteName;
	description: PaymentRouteName;
	paymentMethodType: string;
};

export enum PaymentRouteName {
	"ACH",
	"BACS",
	"CHAPS",
	"CHEQUE",
	"DIRECT_DEBIT",
	"FASTER_PAYMENTS",
	"FPS",
	"GIRO",
	"ICP_BANK_ACCESS",
	"ICP_FILE_UPLOAD",
	"INSTANT_SEPA",
	"PAY_2_BANK",
	"PAY_2_CARD",
	"REVERSE_WIRE",
	"SEPA",
	"URGENT_WIRE",
	"VIRTUAL_CARD",
	"VIRTUAL_WALLET",
	"WIRE",
	"WITHDRAWAL"
}

export type ProvisionCompensation = "STANDARD" | "OFFSET";

export type CurrencyCode =
	| "XPF"
	| "CHE"
	| "BTN"
	| "IEP"
	| "MNT"
	| "ZWL"
	| "RSD"
	| "AZM"
	| "GYD"
	| "YUM"
	| "STN"
	| "MWK"
	| "BIF"
	| "PKR"
	| "SOS"
	| "TOP"
	| "ZMK"
	| "MMK"
	| "SCR"
	| "ZMW"
	| "UYU"
	| "JMD"
	| "XFO"
	| "KHR"
	| "VUV"
	| "XAF"
	| "AYM"
	| "SIT"
	| "BOB"
	| "MYR"
	| "BZD"
	| "KWD"
	| "MGF"
	| "IQD"
	| "ATS"
	| "MAD"
	| "UGX"
	| "IDR"
	| "DOP"
	| "LKR"
	| "FJD"
	| "KGS"
	| "BYB"
	| "UYI"
	| "TND"
	| "ESP"
	| "LYD"
	| "RON"
	| "AFA"
	| "XCD"
	| "CHF"
	| "TMT"
	| "LAK"
	| "XBB"
	| "XDR"
	| "CVE"
	| "TJS"
	| "AWG"
	| "KES"
	| "MZN"
	| "EEK"
	| "CUC"
	| "ZWN"
	| "CAD"
	| "AMD"
	| "WST"
	| "ARS"
	| "GMD"
	| "HUF"
	| "ITL"
	| "TZS"
	| "IRR"
	| "SDG"
	| "NOK"
	| "ALL"
	| "XBD"
	| "PHP"
	| "HNL"
	| "SAR"
	| "FIM"
	| "RUB"
	| "XPD"
	| "KPW"
	| "KMF"
	| "ILS"
	| "PLN"
	| "NZD"
	| "MKD"
	| "SVC"
	| "XXX"
	| "SHP"
	| "VND"
	| "SDD"
	| "PYG"
	| "XFU"
	| "LUF"
	| "TRL"
	| "MVR"
	| "PGK"
	| "ROL"
	| "XAG"
	| "BRL"
	| "BEF"
	| "GHS"
	| "TRY"
	| "ETB"
	| "VEB"
	| "BOV"
	| "SBD"
	| "BBD"
	| "GWP"
	| "COP"
	| "CUP"
	| "AOA"
	| "PTE"
	| "ISK"
	| "MGA"
	| "BAM"
	| "YER"
	| "UZS"
	| "XUA"
	| "GTQ"
	| "VES"
	| "BMD"
	| "MUR"
	| "RUR"
	| "JPY"
	| "FKP"
	| "CZK"
	| "EGP"
	| "JOD"
	| "UAH"
	| "GHC"
	| "SLL"
	| "BYR"
	| "GRD"
	| "PAB"
	| "XOF"
	| "DJF"
	| "COU"
	| "TMM"
	| "XTS"
	| "CLP"
	| "RWF"
	| "TPE"
	| "LSL"
	| "NAD"
	| "XPT"
	| "BHD"
	| "XBC"
	| "XBA"
	| "MDL"
	| "MRU"
	| "PEN"
	| "NGN"
	| "GEL"
	| "GNF"
	| "BND"
	| "ZAR"
	| "DKK"
	| "XSU"
	| "DEM"
	| "USN"
	| "OMR"
	| "KRW"
	| "NLG"
	| "BDT"
	| "SSP"
	| "CHW"
	| "MTL"
	| "KZT"
	| "SZL"
	| "AFN"
	| "KYD"
	| "MOP"
	| "VEF"
	| "HKD"
	| "LTL"
	| "DZD"
	| "MZM"
	| "ZWD"
	| "SRD"
	| "LVL"
	| "LRD"
	| "AUD"
	| "ERN"
	| "LBP"
	| "SEK"
	| "SKK"
	| "THB"
	| "FRF"
	| "HTG"
	| "ZWR"
	| "EUR"
	| "CYP"
	| "AZN"
	| "STD"
	| "SGD"
	| "MRO"
	| "BGN"
	| "INR"
	| "USS"
	| "USD"
	| "MXN"
	| "XAU"
	| "BGL"
	| "GBP"
	| "BSD"
	| "MXV"
	| "TWD"
	| "AED"
	| "SYP"
	| "ADP"
	| "QAR"
	| "GIP"
	| "SRG"
	| "TTD"
	| "CLF"
	| "BYN"
	| "CRC"
	| "HRK"
	| "NPR"
	| "CDF"
	| "CNY"
	| "CSD"
	| "NIO"
	| "ANG"
	| "BWP";

export enum PaymentName {
	NETS = "NETS",
	TAX = "TAX",
	OTHER_STATS = "OTHER_STATS",
	ThreeD_PARTY = "3D_PARTY",
	CP_NOW = "CP_NOW",
	WEZAAM = "WEZAAM",
	TPP = "TPP"
}

export enum PaymentMethodType {
	BANK = "BANK",
	CARD = "CARD",
	VIRTUAL_WALLET = "VIRTUAL_WALLET"
}
