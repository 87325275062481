import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ClientLandingComponent } from "./_container/client-landing.component";

const routes: Routes = [
	{
		path: "",
		component: ClientLandingComponent
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ClientLandingRoutingModule {}
