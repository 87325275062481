import { SelectOption } from "src/app/shared/models/select-option.interface";

export class FullAmlStatuses {
	/**
	 * ['BACKEND VALUE', 'HUMAN READABLE VALUE']
	 */
	static statuses = new Map<string, string>([
		["AML_WAITING", "On Review"],
		["AML_APPROVED", "Approved"],
		["AML_DECLINED", "Rejected"]
	]);

	static toHumanReadable(status: string): string {
		var mapValue = FullAmlStatuses.statuses.get(status);

		if (mapValue == undefined) {
			throw new Error(`There is no status with the key: ${status}`);
		}
		return mapValue;
	}

	static toSelectionOptions(): SelectOption[] {
		return this.map(FullAmlStatuses.statuses);
	}

	static isValid(status: string): boolean {
		var res = FullAmlStatuses.statuses.get(status);

		// there are no AML statuses that match the specified `key`
		if (typeof res == "undefined") return false;
		return true;
	}

	private static map(keyValues: Map<string, string>): SelectOption[] {
		var selectionOptions: SelectOption[] = [];

		keyValues.forEach((value, key) => {
			const option: SelectOption = {
				value: key,
				text: value
			};
			selectionOptions.push(option);
		});
		return selectionOptions;
	}
}
