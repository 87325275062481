import { Component, OnInit, Output, EventEmitter, ViewChild, Input } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { map, shareReplay, switchMap, take, takeUntil } from "rxjs/operators";
import { BankAccountFieldsComponent } from "@shared/components/bank-account-fields/bank-account-fields.component";
import { CountriesService } from "@shared/services/countries/countries.service";
import { CurrencyService } from "@shared/services/currency/currency.service";
import { PermissionsService } from "@shared/services/permissions/permissions.service";
import { ToastService } from "@shared/services/toast/toast.service";
import { TppSettlementAccountService } from "@shared/services/tpp-settlement-account/tpp-settlement-account.service";
import { AccountField } from "src/app/shared/models/account-field.interface";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { ServiceProviderCountrySetup } from "src/app/shared/models/service-provider-country-types";
import { SettlementAccount } from "src/app/shared/models/settlement-account.interface";
import { SettlementAccountPost, SettlementAccountPut } from "../../../_models/settlement-accounts.interface";
import { TPPRoute } from "../../../_models/type-route.interface";
import { TppGroupConfigService } from "../../../_services/tpp-group-config/tpp-group-config.service";
import { TppGroupConfigurationStore } from "../../../_services/store/tpp-group-configuration.service";
import { MenuService } from "@modules/config/_services/menu/menu.service";
import { ServiceProviderService } from "@shared/services/service-provider/service-provider.service";

@Component({
	selector: "app-add-settlement-account",
	templateUrl: "./add-settlement-account.component.html",
	styleUrls: ["./add-settlement-account.component.scss"]
})
export class AddSettlementAccountComponent implements OnInit {
	@Output() closeAddSettlementAccount: EventEmitter<{ isClose: boolean; providerCountryId: string }> =
		new EventEmitter<{
			isClose: boolean;
			providerCountryId: string;
		}>();

	@Output() resetAvailableAccounts: EventEmitter<{ providerCountryId: string; currency: string }> = new EventEmitter<{
		providerCountryId: string;
		currency: string;
	}>();

	@Input() provider!: ServiceProviderCountrySetup | undefined;

	@Input() availableSettlementAccounts!: SettlementAccount[];
	@Input() selectedSettlementAccount!: SettlementAccount | undefined;
	@Input() isSettlementEdit!: boolean;
	@Input() payGroupId!: string;
	@Input() settlementProviderName!: string;
	@Input() selectedLegalEntity!: string;
	@Input() readonly: boolean = false;

	@Input() providerCountryId!: string;
	@Input() groupToConfig!: string;
	settlementAccountEditCountry!: string;

	private _selectedCountryCode!: string;

	@Input()
	set selectedCountryCode(value: string) {
		this._selectedCountryCode = value;
	}

	get selectedCountryCode(): string {
		return this._selectedCountryCode;
	}

	@ViewChild(BankAccountFieldsComponent) bankAccountFieldsComponent: BankAccountFieldsComponent | undefined;

	private destroy$: Subject<void> = new Subject<void>();

	bankAccountValues: Record<string, string | number | boolean> | undefined;
	accessFieldsValues: Record<string, string | number | boolean> | undefined;
	selectedCountry: string | undefined;

	enableSave: boolean = false;
	countries$!: Observable<SelectOption[]>;
	currencies$: Observable<SelectOption[]> = new Observable<SelectOption[]>();
	countryOptions: SelectOption[] = [];

	globalForm!: FormGroup;

	selectOptionsCountries$: Observable<SelectOption[]> | undefined;
	editMode: boolean = false;
	selectedRoute: TPPRoute = {} as TPPRoute;

	canEditSettlementAccounts: boolean = false;
	ready: boolean = false;
	bankName: string = "";

	pageIsLoaded: boolean = false;

	public accessFields: AccountField[] = [];
	public accessFieldsForm!: FormGroup;

	providerCountriesList: string[] = [];

	constructor(
		private formBuilder: FormBuilder,
		private countriesService: CountriesService,
		private currencyService: CurrencyService,
		private permissions: PermissionsService,
		private settlementAccountService: TppSettlementAccountService,
		private toast: ToastService,
		private tppGroupConfigService: TppGroupConfigService,
		private tppGroupConfigStore: TppGroupConfigurationStore,
		private menuService: MenuService,
		private serviceProviderService: ServiceProviderService
	) {}

	ngOnInit(): void {
		this.permissions
			.canEditSettlementAccountTPP()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => (this.canEditSettlementAccounts = res));

		this.setGenericFields();
		this.initGlobalForm();
		this.setAccessFields();
	}

	initGlobalForm(): void {
		this.globalForm = this.formBuilder.group({
			accountName: ["", Validators.required],
			country: [{ value: "" }, Validators.required],
			currency: [{ value: "" }, Validators.required]
		});

		if (this.isSettlementEdit && this.readonly) {
			this.globalForm.disable();
		}

		this.globalForm
			.get("country")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe((country: string) => {
				let countryCode!: string;

				this.selectedCountryCode = country!;

				if (this.selectedCountryCode !== countryCode!) {
					this.bankAccountValues = {};
					this.accessFieldsValues = {};
				}
			});

		if (this.isSettlementEdit) {
			this.globalForm.get("accountName")?.patchValue(this.selectedSettlementAccount!.name);
			this.globalForm.get("currency")?.patchValue(this.selectedSettlementAccount!.currency);

			this.globalForm.get("country")?.patchValue(this.selectedCountryCode);
			this.settlementAccountEditCountry = this.selectedSettlementAccount!.bankAccount.country;
		} else {
			this.globalForm.get("country")?.patchValue(this.selectedCountryCode);
		}
	}

	closeSettlementAccount(): void {
		this.closeAddSettlementAccount.emit({ isClose: false, providerCountryId: this.providerCountryId });
		this.menuService.setVisible(true);
	}

	save(): void {
		let settlementAccountDataUpdate: SettlementAccountPut;
		let settlementAccountDataCreate: SettlementAccountPost;

		let accessFields = this.accessFieldsForm.value;
		let bankAccount = this.bankAccountFieldsComponent!.bankAccountForm.value;

		if (this.isSettlementEdit) {
			const sortCodeValue = this.bankAccountFieldsComponent?.bankAccountForm?.get("SORT_CODE")?.value;

			if (
				this.bankAccountFieldsComponent?.bankAccountForm &&
				sortCodeValue !== null &&
				sortCodeValue !== undefined
			) {
				bankAccount["sort_code"] = this.bankAccountFieldsComponent!.bankAccountForm.get("SORT_CODE")!.value;
			}

			bankAccount["account_number"] =
				this.bankAccountFieldsComponent!.bankAccountForm.get("ACCOUNT_NUMBER")!.value;

			bankAccount["IBAN"] = this.bankAccountFieldsComponent!.bankAccountForm.get("IBAN")!.value;
		}

		let settlementAccountData: SettlementAccount = {
			legalEntityId: this.selectedLegalEntity,
			currency: this.globalForm.get("currency")!.value,
			name: this.globalForm.get("accountName")!.value,
			accessFields: Object.entries(accessFields).map(([key, value]) => ({
				key: key.toUpperCase()!,
				value: typeof value === "undefined" ? "" : (value as string)
			})),
			providerCountryId: this.providerCountryId,
			bankAccount: {
				bankName: this.bankAccountFieldsComponent!.bankAccountForm.get("bankName")!.value,
				country: this.isSettlementEdit ? this.settlementAccountEditCountry : this.selectedCountryCode,
				fields: Object.entries(bankAccount)
					.map(([key, value]) => ({
						key: key!.toUpperCase(),
						value: typeof value === "undefined" ? "" : (value as string)
					}))
					.filter(entry => entry.value !== "")
			}
		};

		if (this.isSettlementEdit) {
			settlementAccountData.version = this.selectedSettlementAccount!.version;
			settlementAccountData.id = this.selectedSettlementAccount!.id;

			(settlementAccountData.bankAccount.id = this.selectedSettlementAccount!.id!),
				(settlementAccountData.bankAccount.version = this.selectedSettlementAccount!.bankAccount.version!),
				(settlementAccountDataUpdate = settlementAccountData);

			this.settlementAccountService
				.updateSettlementAccount(settlementAccountDataUpdate)
				.pipe(take(1))
				.subscribe(res => {
					if (res) {
						this.resetSettlementAccountOptions();
						this.toast.showSuccess("Successfully Edited Settlement Account");

						this.closeSettlementAccount();
					}
				});
		} else {
			settlementAccountDataCreate = settlementAccountData;

			this.settlementAccountService
				.createSettlementAccount(settlementAccountDataCreate)
				.pipe(take(1))
				.subscribe(res => {
					if (res) {
						this.resetSettlementAccountOptions();

						//this.tppGroupConfigService.settlementAccountUpdated(this.groupToConfig, res.id!);
						this.tppGroupConfigStore.setGroupSettlementAccountId(this.groupToConfig, res.id!);
						this.toast.showSuccess("Successfully Created Settlement Account");
						this.closeSettlementAccount();
					}
				});
		}
	}

	resetSettlementAccountOptions() {
		this.resetAvailableAccounts.emit({
			providerCountryId: this.providerCountryId,
			currency: this.globalForm.get("currency")!.value
		});
	}

	setGenericFields() {
		this.serviceProviderService
			.getServiceProviderCountryList(-1, 0, this.settlementProviderName)
			.pipe(
				switchMap(res => {
					this.providerCountriesList = res;
					return this.countriesService.getCountryOptions().pipe(
						shareReplay(1),
						map((countries: SelectOption[]) => countries.filter(c => res.includes(c.value)))
					);
				})
			)
			.subscribe(filteredCountries => {
				setTimeout(() => {
					this.countryOptions = filteredCountries;
				});
			});

		this.currencies$ = this.currencyService.getCurrencyOptions().pipe(shareReplay(1));
	}

	setAccessFields() {
		this.selectedSettlementAccount?.accessFields?.map((accessField: { key: string; value: string }) => {
			this.accessFieldsValues = this.accessFieldsValues || {};
			this.accessFieldsValues[accessField.key] = accessField.value;
		});

		if (this.isSettlementEdit) {
			this.bankName = this.selectedSettlementAccount!.bankAccount.bankName!;

			this.selectedSettlementAccount?.bankAccount?.fields?.map(
				(bankAccountField: { key: string; value: string }) => {
					this.bankAccountValues = this.bankAccountValues || {};
					this.bankAccountValues[bankAccountField.key] = bankAccountField.value;
				}
			);

			if (this.selectedSettlementAccount?.bankAccount?.bankName) {
				this.bankAccountValues = this.bankAccountValues || {};
				this.bankAccountValues.bankName = this.selectedSettlementAccount?.bankAccount?.bankName;
			}
		}

		this.initializeAccessFields();
	}

	initializeAccessFields(): void {
		this.accessFieldsForm = this.formBuilder.group({});

		if (this.readonly) {
			this.accessFieldsForm.disable();
		}

		if (this.providerCountryId) {
			this.settlementAccountService
				.getProviderAccountDetails(this.providerCountryId)
				.pipe(take(1))
				.subscribe((fields: AccountField[]) => {
					fields.map((field: AccountField) => {
						// Format fields
						const lower = field.name.toLowerCase();
						field.name = field.name.charAt(0).toUpperCase() + lower.slice(1);
						field.label = field.required ? field.name.toUpperCase() + " *" : field.name.toUpperCase();

						// Set up form control
						const value: string | number | boolean | null = this.accessFieldsValues
							? this.accessFieldsValues[field.key]
							: null;
						this.accessFieldsForm.addControl(
							field.key,
							new FormControl({ value, disabled: this.readonly }, this.getFieldValidators(field))
						);
					});

					this.accessFields = fields;
					this.ready = true;
				});
		}
		// if (!this.canEdit) {
		// 	this.accessFieldsForm.get(field.key)?.disable();
		// }
	}

	getFieldValidators(field: AccountField): ValidatorFn[] {
		let validators: ValidatorFn[] = [];

		if (field.required) {
			validators.push(Validators.required);
		}

		return validators;
	}

	getAccessFields(): { key: string; value: string }[] | undefined {
		return Object.keys(this.accessFieldsForm.controls).map((field: string) => {
			return {
				key: field,
				value: this.accessFieldsForm.controls[field].value
			};
		});
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
