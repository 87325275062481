import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { Observable, Subject, lastValueFrom, of } from "rxjs";
import { mergeMap, take, takeUntil } from "rxjs/operators";

import { DeleteDialogComponent } from "@shared/components/delete-dialog/delete-dialog.component";
import { ContactPersonService } from "@shared/services/contact-person/contact-person.service";
import { CustomerCacheService } from "@shared/services/customer/customer-cache.service";
import { CustomerService } from "@shared/services/customer/customer.service";
import { ImagesService } from "@shared/services/images/images.service";
import { LegalEntityService } from "@shared/services/legal-entity/legal-entity.service";
import { ToastService } from "@shared/services/toast/toast.service";
import { ContactPerson } from "src/app/shared/models/contact-person.interface";
import { Customer } from "src/app/shared/models/customer.interface";
import { Image } from "src/app/shared/models/image.interface";
import { createCustomerLogoPath } from "src/app/shared/utils/customer-logo-path.util";
import { getCustomerEntityGroupState } from "src/app/store";
import { AppState } from "src/app/store/models/state.model";
import { PermissionsService } from "../../../../shared/services/permissions/permissions.service";
import generateLogoUtil from "../../../../shared/utils/generate-logo-util";
import { CommonService } from "../../_services/common/common.service";
import { BreadcrumbService } from "@shared/components/breadcrumbs/services/breadcrumb.service";

@Component({
	selector: "customer-edition",
	templateUrl: "./customer-edition.component.html",
	styleUrls: ["./customer-edition.component.scss"]
})
export class CustomerEditionComponent implements OnInit, OnDestroy {
	@Output() currentIndex: EventEmitter<number> = new EventEmitter<number>();
	@Output() deletedCustomer: EventEmitter<string> = new EventEmitter<string>();

	customer: Customer = {} as Customer;

	@ViewChild("logoCanvas") myCanvas!: ElementRef<HTMLCanvasElement>;

	customerForm!: FormGroup;
	contactData: ContactPerson[] = [];
	existingContacts!: ContactPerson[];
	editContactClicked$: Subject<void> = new Subject<void>();
	isChanged: boolean = false;
	context!: CanvasRenderingContext2D;

	imagePath?: string;
	imagefile?: File;

	contactFormisValid: boolean = true;

	// URLs
	safeLogoUrl!: SafeUrl;
	safeSubtitleImgUrl!: SafeUrl;
	subtitleImgUrl?: string;

	canEditCustomerSetup: boolean = false;
	canEditContactPersons: boolean = false;

	fetchedContacts = false;

	private destroy$: Subject<void> = new Subject<void>();

	constructor(
		private formBuilder: FormBuilder,
		private imageService: ImagesService,
		private customerService: CustomerService,
		private commonService: CommonService,
		private contactPersonService: ContactPersonService,
		private toastService: ToastService,
		private dialog: MatDialog,
		private legalEntityService: LegalEntityService,
		private router: Router,
		private sanitizer: DomSanitizer,
		private permissions: PermissionsService,
		private store: Store<AppState>,
		private customerCache: CustomerCacheService,
		private breadCrumbService: BreadcrumbService
	) {}

	ngOnInit(): void {
		this.initializeCustomerFormWithCurrentValue();

		this.permissions
			.canEditCustomerSetup()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => (this.canEditCustomerSetup = res));

		this.permissions
			.canEditContactPersons()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => (this.canEditContactPersons = res));

		this.store.pipe(takeUntil(this.destroy$), select(getCustomerEntityGroupState)).subscribe(state => {
			if (state?.customerId) {
				this.setupComponentFor(state.customerId);
			} else {
				this.existingContacts = [];
			}
		});
	}

	ngAfterViewInit(): void {
		this.context = this.myCanvas.nativeElement.getContext("2d")!;
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	onEditContactClicked() {
		this.editContactClicked$.next();
	}

	editContactFormData(event: { data: ContactPerson[]; valid: boolean }, editForm: FormGroup): void {
		this.customerForm.markAsDirty();
		if (event.data.length) {
			editForm.get("contactPersons")?.patchValue(event.data);
		} else {
			editForm.get("contactPersons")?.patchValue([]);
		}
		this.contactFormisValid = event.valid;
	}

	async updateCustomerForm(editForm: FormGroup) {
		if (editForm.valid) {
			var customerFormValue: Customer = {
				...editForm.getRawValue()
			};

			// Image removed from form,set default logo.
			if (customerFormValue.image == null && customerFormValue.imagePath == null) {
				var file = await this.createDefaultLogo(customerFormValue.name);
				this.imagefile = file;
				this.setCurrentImagePath(file);
			}

			if (this.customerForm.valid) this.updateCustomer(customerFormValue);
		}
	}

	cancelEditCustomer() {
		this.isChanged = false;
		this.breadCrumbService.resetCustomerEntityGroupState();
		this.router.navigate(["/customers"]);
	}

	onImageUploadEdit(image: Blob) {
		if (image) {
			if (image.size > 8388608) {
				this.customerForm.get("imagePath")?.setValue(null);
				this.customerForm.get("image")?.setValue(null);

				this.toastService.showError("Max file size of 8mb has been exceeded, please select a smaller image.");
			} else {
				this.setCurrentImagePath(image);
				this.customerForm.get("image")?.patchValue(image);
			}
		} else {
			this.customerForm.get("imagePath")?.setValue(null);
			this.customerForm.get("image")?.setValue(null);
		}
	}

	private setupComponentFor(customerId: string) {
		this.customerService
			.getCustomer(customerId)
			.pipe(takeUntil(this.destroy$))
			.subscribe(customer => {
				this.customer = customer;
				this.customer = createCustomerLogoPath(customer);
				this.getContactPersons(this.customer.id);
				this.setupFormGivenCustomerInitialized();
				this.onFormLogoChange();
				this.generateSAfeLogoURL();
			});
	}

	private initializeCustomerFormWithCurrentValue() {
		this.customerForm = this.formBuilder.group({
			externalId: [this.customer.externalId],
			id: [{ value: this.customer.id, disabled: true }, Validators.required],
			name: [this.customer.name, Validators.required],
			registrationId: [this.customer.registrationId],
			address: [this.customer.address, Validators.required],
			contactPersons: [this.customer.contactPersons ? this.customer.contactPersons : []],
			image: [null],
			logo: [this.customer.logo],
			imagePath: [this.customer.imagePath],
			// status: [this.customer.status],
			version: [this.customer.version]
		});
	}

	private setupFormGivenCustomerInitialized(): void {
		this.initializeCustomerFormWithCurrentValue();
		this.customerForm.get("id")?.disable();
		this.customerForm.updateValueAndValidity();

		this.customerForm
			.get("imagePath")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe(() => (this.isChanged = true));

		if (!this.canEditCustomerSetup) {
			this.customerForm.disable();
		}
	}

	private generateSAfeLogoURL(): void {
		this.safeLogoUrl = this.customer.imagePath
			? this.sanitizer.bypassSecurityTrustUrl(this.customer.imagePath)
			: "";
		this.safeSubtitleImgUrl = this.subtitleImgUrl ? this.sanitizer.bypassSecurityTrustUrl(this.subtitleImgUrl) : "";
	}

	private onFormLogoChange() {
		this.customerForm
			.get("image")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe(file => {
				this.customerForm.markAsDirty();
				if (file) this.imagefile = file;
			});
	}

	private getContactPersons(customerId: string): void {
		this.contactPersonService
			.getContactPersons(customerId)
			.pipe(take(1))
			.subscribe((contacts: ContactPerson[]) => {
				this.existingContacts = contacts;
			});
	}

	private updateCustomer(customerFormValue: Customer) {
		this.ifImageUploadChangeUploadCustomerLogo()
			.pipe(
				mergeMap((updatedCustomer: Customer | null) => {
					var customerWasUpdated = updatedCustomer != null;
					if (customerWasUpdated) {
						customerFormValue.version = updatedCustomer!.version;
					}

					return this.ifPresentAddContactPersons(customerFormValue);
				}),
				mergeMap((updatedCustomer: Customer | null) => {
					var customerWasUpdated = updatedCustomer != null;
					if (customerWasUpdated) {
						customerFormValue.version = updatedCustomer!.version;
					}

					return this.customerService.updateCustomer(customerFormValue);
				})
			)
			.subscribe({
				next: () => {
					this.customerCache.refreshCache();
					this.handleCustomerUpdateSuccess();
				},
				error: (error: any) => {
					this.onUpdateCustomerError(error);
				}
			});
	}

	private ifImageUploadChangeUploadCustomerLogo(): Observable<Customer | null> {
		if (this.imagefile) {
			return this.imageService.updateCustomerLogo(this.imagefile, this.customer.logo?.id!).pipe(
				mergeMap((image: Image) => {
					return this.customerService.getCustomer(this.customer.id);
				})
			);
		}
		return of(null);
	}

	private ifPresentAddContactPersons(customer: Customer): Observable<Customer | null> {
		if (!customer.contactPersons) {
			return of(null);
		}

		return this.contactPersonService
			.addContactPerson(this.commonService.contactPersonObject(customer.id, customer.contactPersons))
			.pipe(
				mergeMap((contacts: ContactPerson[]) => {
					contacts.filter((contact: ContactPerson) =>
						contact.userId
							? this.contactPersonService.linkContactToCustomer(customer.id, contact.userId).subscribe()
							: void 0
					);

					return this.customerService.getCustomer(this.customer.id);
				})
			);
	}

	private onUpdateCustomerError(error: any) {
		var errorMessage = "Unable to update customer: ";

		if (error.hasOwnProperty("message")) {
			errorMessage += error.message;
		} else {
			errorMessage += error;
		}
		this.toastService.showError(errorMessage);
	}

	private setCurrentImagePath(file: Blob) {
		const reader = new FileReader();
		reader.onload = () => {
			this.imagePath = reader.result as string;
		};
		reader.readAsDataURL(file);
	}

	private handleCustomerUpdateSuccess() {
		this.toastService.showSuccess("Customer Updated Successfully");
		this.commonService.customerCreated();
		this.breadCrumbService.resetCustomerEntityGroupState();
		this.router.navigate(["/customers"]);
	}

	// UNUSED
	removeCustomerForm(editForm: FormGroup, i: number) {
		this.customerService.deleteCustomer(editForm.getRawValue());
	}

	contactFormState(contactFormState: FormGroup) {
		if (contactFormState.status === "INVALID") {
			this.customerForm.setErrors({ invalidContactPerson: true });
		} else {
			this.customerForm.setErrors(null);
		}
	}

	deleteCustomer(customer: Customer) {
		lastValueFrom(this.legalEntityService.getLegalEntitiesForCustomer(customer.id)).then(result => {
			if (result.totalCount == 0) {
				const dialogRef = this.dialog.open(DeleteDialogComponent, {
					height: "441px",
					width: "666px",
					data: {
						text: "Are you sure you want to remove this customer?"
					},
					panelClass: "delete-dialog-container"
				});
				dialogRef.afterClosed().subscribe(result => {
					if (result) {
						this.customerService.deleteCustomer(customer.id).subscribe({
							next: () => {
								this.breadCrumbService.resetCustomerEntityGroupState();
								this.router.navigate(["/customers"]);
							},
							error: () => this.toastService.showError("Error deleting customer")
						});
					}
				});
			} else {
				this.toastService.showError("Unable to Delete: Customer currently has associated legal entities");
			}
		});
	}

	createDefaultLogo(name: string): Promise<File> {
		return generateLogoUtil(name, this.context);
	}
}
