import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ImageUploadComponent } from "./image-upload.component";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { ImageUploadDirective } from "./directives/image-upload.directive";
import { SharedModule } from "@shared/shared.module";

@NgModule({
	declarations: [ImageUploadComponent, ImageUploadDirective],
	imports: [CommonModule, MatButtonModule, MatIconModule, SharedModule],
	exports: [ImageUploadComponent]
})
export class ImageUploadModule {}
