import { Component, EventEmitter, Input, Output } from "@angular/core";
import { PayGroup } from "../../../../../shared/models/pay-groups";

@Component({
	selector: "app-list-items",
	templateUrl: "./list-items.component.html",
	styleUrls: ["./list-items.component.scss"]
})
export class ListItemsComponent {
	@Input() paygroups: PayGroup[] = [];

	@Output() paygroupSelected = new EventEmitter<PayGroup>();

	selectPaygroup(value: PayGroup) {
		this.paygroupSelected.emit(value);
	}
}
