<div class="view-subservice-list-page-container">
	<div class="column-labels" *ngIf="paymentType === 'PAY_GROUP'">
		<span class="label" fxFlex="16">SUBSERVICE</span>
		<span class="label" fxFlex="14">REFERENCE</span>
		<span class="label" fxFlex="14">AMOUNT</span>
		<span class="label" fxFlex="14">VALUE DATE</span>
		<span class="label" fxFlex="20">UPLOADED BY</span>
	</div>

	<div class="column-labels" *ngIf="paymentType === 'EMPLOYEE' && idConvertedToExternalIds">
		<span class="label" fxFlex="10">EMPLOYEE ID</span>
		<span class="label" fxFlex="16">FULL NAME</span>
		<span class="label" fxFlex="16">REFERENCE</span>

		<span class="label" fxFlex="10">AMOUNT</span>
		<span class="label" fxFlex="10">VALUE DATE</span>
		<span class="label" fxFlex="20">UPLOADED BY</span>
	</div>

	<app-view-subservice-details-list-item
		*ngFor="let details of subServiceDetails"
		[details]="details"
	></app-view-subservice-details-list-item>
</div>
