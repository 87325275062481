export interface CalendarFile {
	attempts: number;
	attributes: CalendarAttributes;
	author: { type: string; value: string };
	executeAt: string;
	fileId: string;
	id: string;
	meta: {};
	processedAt: string;
	processedItems: number;
	requestId: string;
	status: string;
	topic: string;
	totalItems: number;
	fileType: CalendarFilesTypes;
	updatedAt: string;
}

export interface TemplateDownloadedFile {
	id: string;
	type: string;
	status: StatusData;
	attributes: CalendarAttributes;
	createdBy: string;
	createdAt: string;
	updatedBy: string;
	updatedAt: string;
	fileType: CalendarFilesTypes;
}

export enum CalendarFilesTypes {
	UPLOAD = "upload",
	DOWNLOAD = "download"
}

export interface CalendarAttributes {
	FILE_NAME?: string;
	MANAGED_BY?: string;
}

export interface StatusData {
	name: CalendarFileStatus;
	messages: Array<string>;
}

export interface CalendarFilePagination {
	items: Array<CalendarFile>;
	pageNumber: number;
	totalCount: number;
	totalPages: number;
}

export interface DownloadTemplateFilePagination {
	items: Array<TemplateDownloadedFile>;
	pageNumber: number;
	totalCount: number;
	totalPages: number;
}

export type CalendarErrorFilePagination = {
	totalCount: number;
	totalPages: number;
	pageNumber: number;
	items: [
		{
			taskId: string;
			externalLegalEntityId: string;
			externalCustomerId: string;
			externalPayGroupId: string;
			externalPayCycleId: string;
			paymentType: string;
			message: string;
		}
	];
};

export enum CalendarFileStatus {
	CREATED = "CREATED",
	PROCESSED = "PROCESSED",
	FAILED = "FAILED",
	PROCESSING = "PROCESSING"
}
