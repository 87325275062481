import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { downloadTPPFile, FilesStatus, tppFile } from "../../models/tpp-data.interface";

@Component({
	selector: "app-tpp-file-history-list-item",
	templateUrl: "./tpp-file-history-list-item.component.html",
	styleUrls: ["./tpp-file-history-list-item.component.scss"]
})
export class TppFileHistoryListItemComponent implements OnInit {
	file!: tppFile;
	statusClass: string = "success";

	downloadInfo!: downloadTPPFile;

	extraSubserviceCounter: number = 0;
	extraSubserviceMatTooltip: string[] = [];

	showTooltip: boolean = false;

	@Input() set _file(files: tppFile) {
		this.file = files;

		//get extra subservice count
		if (this.file.meta && this.file.meta.SERVICES && this.file.meta.SERVICES.length) {
			this.extraSubserviceCounter = this.file.meta.SERVICES.length - 2;
		}

		//create extra subservice tooltip
		if (this.extraSubserviceCounter > 0) {
			const allSubserviceCount: number = this.file.meta!.SERVICES.length - 1;

			for (var i = 0; i < this.extraSubserviceCounter; i++) {
				this.extraSubserviceMatTooltip.push(this.file.meta!.SERVICES[allSubserviceCount - i]);
			}
		}

		if (this.file.status === FilesStatus.PENDING) {
			this.statusClass = "pending";
		} else if (this.file.status === FilesStatus.FAILED) {
			this.statusClass = "failed";
		}

		if (!this.file.userReset) {
			this.downloadInfo = {
				fileId: this.file.fileId!,
				fileName: this.file.fileName!
			};
		}
	}

	shouldBeFaded: boolean = false;

	@Input() set _opacity(blur: boolean) {
		this.shouldBeFaded = blur;
	}

	@Output() viewFileErrors: EventEmitter<string> = new EventEmitter<string>();

	@Output() downloadFile: EventEmitter<downloadTPPFile> = new EventEmitter<downloadTPPFile>();

	constructor() {}

	ngOnInit(): void {}
}
