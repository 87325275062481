import { Injectable } from "@angular/core";
import { Observable, forkJoin, of } from "rxjs";
import { TppServicesByByPayGroupAggregation } from "./facade/model/TppServicesByByPayGroupAggregation";
import { TppAdapter } from "./TppAdapter";
import { TppPayGroupBeneficiaryLinkClientService } from "../../api/payment-providers/tpp-paygroup-beneficiary-link/tpp-pay-group-beneficiary-link-client.service";
import { ReprocessTPPPayGroupBeneficiaryLinkRequestV1Params } from "../../api/payment-providers/tpp-paygroup-beneficiary-link/model/request/ReprocessTPPPayGroupBeneficiaryLinkRequestV1Params";
import { ServiceDefinitionAggregatorService } from "./facade/service-definition-aggregator.service";
import { ApiTPPServiseDefinitionsSearchResponseV1 } from "../../api/payment-providers/tpp-service-definition/model/response/ApiTppServiceDefinitionsSearchResponseV1";
import { ApiSearchTPPServiceDefinitionsRequestV1 } from "../../api/payment-providers/tpp-service-definition/model/request/ApiSearchTppServiceDefinitionsRequest";
import { TtpServiceDefinitionClientService } from "../../api/payment-providers/tpp-service-definition/ttp-service-definition-client.service";
import { delay, map, switchMap } from "rxjs/operators";
import { TppServicesByByPayGroupViewModel } from "../../../models/service-definition/TppServiceDefinitionViewModel";
import { ServiceDefinitionAggregationFilterService } from "./facade/service-definition-aggregation-filter.service";
import { BeneficiaryStatusFilterType } from "@modules/service-definition/_components/tpp-service-selection/beneficary-status-by-provider-summary/beneficary-status-by-provider-summary.component";
import { BeneficiaryStatusByProviderSummary } from "src/app/shared/models/service-definition/TtpBeneficiaryStatusByProviderSummary";
import { BeneficiaryStatusByProviderAggregationFilterService } from "./facade/beneficiary-status-by-provider-aggregation-filter.service";
import { TppPayGroupBeneficiaryLinkReportV1Params } from "../../api/payment-providers/tpp-paygroup-beneficiary-link/model/request/TppPayGroupBeneficiaryLinkReportV1Params";
import * as _ from "lodash";
import { PayElectiveHistoryModalData } from "@shared/components/wpay-ui/modal/templates/pay-elective-history/model/PayElectiveHistoryModalData";
import { ApiTPPBeneficiaryLinkReportV1 } from "../../api/payment-providers/tpp-paygroup-beneficiary-link/model/response/ApiTPPBeneficiaryLinkReportV1";
import { HistoryViewData } from "src/app/shared/models/history-view.interface";

@Injectable({
	providedIn: "root"
})
export class TppAdapterImpl implements TppAdapter {
	constructor(
		private beneficiaryLinkClient: TppPayGroupBeneficiaryLinkClientService,
		private servicedefinitionClient: TtpServiceDefinitionClientService,
		private serviceDefinitionAggregator: ServiceDefinitionAggregatorService,
		private serviceDefinitionAggregationFilter: ServiceDefinitionAggregationFilterService,
		private beneficiaryStatusAggregationFilter: BeneficiaryStatusByProviderAggregationFilterService
	) {}

	/**
	 * Complexity can be reduced when backend implements:
	 *  - @see https://wezaam.atlassian.net/browse/WPAY-2722
	 */
	findServiceDefinitionsByPayGroupId(
		payGroupId: string,
		providerName: string,
		statusFilter: BeneficiaryStatusFilterType
	): Observable<TppServicesByByPayGroupViewModel> {
		return this.serviceDefinitionAggregator.fetchAndTransformToAggregation(payGroupId).pipe(
			map((services: TppServicesByByPayGroupAggregation) => {
				return this.serviceDefinitionAggregationFilter.filterAsViewModel(services, providerName, statusFilter);
			})
		);
	}

	/**
	 * Complexity can be reduced when backend implements:
	 *  - @see https://wezaam.atlassian.net/browse/WPAY-2722
	 */
	findBeneficiaryStatusByProviderWithPayGroupId(
		payGroupId: string,
		providerName: string
	): Observable<BeneficiaryStatusByProviderSummary> {
		var serviceDefinitions$ = this.findServiceDefinitions(payGroupId).pipe(delay(50));

		return serviceDefinitions$.pipe(
			switchMap((response: ApiTPPServiseDefinitionsSearchResponseV1) => {
				var tppServicesByPayGroup$ = this.serviceDefinitionAggregator
					.fetchAndTransformToAggregation(payGroupId)
					.pipe(delay(100));

				return forkJoin([of(response), tppServicesByPayGroup$]);
			}),
			map(
				([serviceDefinitions, tppServicesByPayGroup]: [
					ApiTPPServiseDefinitionsSearchResponseV1,
					TppServicesByByPayGroupAggregation
				]) => {
					return this.beneficiaryStatusAggregationFilter.filterAsViewModel(
						tppServicesByPayGroup,
						serviceDefinitions,
						providerName
					);
				}
			)
		);
	}

	/**
	 * Get the payment providers used for TPP Payouts for this PayGroup.
	 */
	findAllTppPaymentProvidersAssignedToPayGroupId(payGroupId: string): Observable<string[]> {
		return this.findServiceDefinitions(payGroupId).pipe(
			map((response: ApiTPPServiseDefinitionsSearchResponseV1) => {
				if (!response.items || response.items.length === 0) return [];

				return this.toProviderNamesList(response.items[0].statusesCounts);
			})
		);
	}

	/**
	 * Knows how to validate a TPP Beneficiary.
	 */
	validateBeneficiaries(beneficiaryIds: string[]): Observable<any> {
		var request = {
			tppPayGroupBeneficiaryLinkIds: beneficiaryIds
		} as ReprocessTPPPayGroupBeneficiaryLinkRequestV1Params;

		return this.beneficiaryLinkClient.reprocessTPPPayGroupBeneficiaryLinks(request);
	}

	getTppBeneficiaryHistory(
		payGroupId: string,
		providerName: string,
		beneficiaryId: string,
		settlementAccountId: string
	): Observable<PayElectiveHistoryModalData> {
		var request = {
			payGroupId: payGroupId,
			providerName: providerName,
			beneficiaryId: beneficiaryId,
			settlementAccountId: settlementAccountId
		} as TppPayGroupBeneficiaryLinkReportV1Params;

		return this.beneficiaryLinkClient.getTppPayGroupBeneficiaryLinkReport(request).pipe(
			map((response: ApiTPPBeneficiaryLinkReportV1[]) => {
				var result = [] as HistoryViewData[];

				response.forEach((item: ApiTPPBeneficiaryLinkReportV1) => {
					result.push({
						user: "WPAY SYSTEM",
						createdAt: new Date(item.createdAt),
						status: item.status.status,
						action: "",
						reason: item.status.message,
						providerName: item.providerName,
						details: item.status.details && item.status.status !== "CREATED" ? item.status.details : null
					} as HistoryViewData);
				});

				return {
					title: "TPP Beneficiary History",
					providerName: providerName,
					type: "TPP",
					data: result
				} as PayElectiveHistoryModalData;
			})
		);
	}

	private findServiceDefinitions(payGroupId: string): Observable<ApiTPPServiseDefinitionsSearchResponseV1> {
		var request = {
			payGroupIds: payGroupId,
			pageNumber: 0,
			pageSize: -1 // -1 means "all"
		} as ApiSearchTPPServiceDefinitionsRequestV1;

		return this.servicedefinitionClient.search(request);
	}

	private toProviderNamesList(statusesCounts: any): string[] {
		return _.transform(
			statusesCounts,
			function (result: string[], childObject: any, parentKey: string) {
				var providerName = parentKey;
				result.push(providerName);
			},
			[]
		);
	}
}
