import { EventEmitter, Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { take, timeout } from "rxjs/operators";
import { Store, select } from "@ngrx/store";

import { FileManagementService } from "./file-management.service";
import { ToastService } from "../toast/toast.service";
import { ErrorDialogComponent } from "@shared/components/error-dialog/error-dialog.component";
import { PayElectiveBankAccountsApiService } from "@modules/employee-data/services/pay-elective-bank-accounts-api.service";
import { AppState } from "@store/models/state.model";
import { getCustomerEntityGroupState } from "@store/index";
import { CalendarFilesHistoryService } from "@modules/calendar-setup-file-history/services/calendar-files-history.service";

@Injectable({
	providedIn: "root"
})
export class FileManagementUploadService {
	updateFileList$: EventEmitter<void> = new EventEmitter<void>();
	updateCalendarFileList$: EventEmitter<void> = new EventEmitter<void>();
	updateMasterDataFiles$: EventEmitter<void> = new EventEmitter<void>();
	payGroupId!: string;

	constructor(
		public dialog: MatDialog,
		private fileManagementApiService: FileManagementService,
		private toastService: ToastService,
		private payElectiveBankAccountsApiService: PayElectiveBankAccountsApiService,
		private store: Store<AppState>,
		private calendarFilesHistoryService: CalendarFilesHistoryService
	) {
		this.store
			.pipe(take(1), select(getCustomerEntityGroupState))
			.subscribe(({ payGroupId }) => (this.payGroupId = payGroupId));
	}

	openDialog(errors: Record<string, string>[]): void {
		this.dialog.open(ErrorDialogComponent, {
			width: "750px",
			height: "75%",
			panelClass: "upload-modalbox",
			data: {
				errors
			}
		});
	}

	uploadMasterFile(fileData: FormData) {
		this.fileManagementApiService
			.uploadPayElectiveFile(fileData)
			.pipe(
				timeout(5000) // set a default timeout so the user does not stare at the screen for 30 seconds
				// finalize(() => (this.loading = !this.loading))
			)
			.subscribe({
				next: _ => void 0,
				error: (err: any) => {
					// error thrown by timeout. This might change in the future, depending on the back-end implementation
					if (err.name === "TimeoutError") {
						this.toastService.showInfo(
							"The upload process has started, but is taking longer then usual. You can periodically refresh the file list."
						);
						this.updateMasterDataFiles$.emit();
					} else {
						switch (err.status) {
							case 200:
								// uploaded with no errors
								this.toastService.showSuccess(
									"The file has successfully been submitted for upload process"
								);
								this.updateMasterDataFiles$.emit();
								this.payElectiveBankAccountsApiService
									.reProcessAll(this.payGroupId)
									.pipe(take(1))
									.subscribe();

								break;
							case 422:
								// return errors to view

								this.openDialog(err.error);
								break;
							case 500:
								this.toastService.showError(
									"Upload failed, please check that your file's data is correctly formatted"
								);
								break;
							default:
								break;
						}
					}
				}
			});
	}

	uploadG2NFile(fileData: FormData) {
		this.fileManagementApiService
			.uploadG2NFile(fileData)
			.pipe(
				timeout(60000) // set a default timeout so the user does not stare at the screen for 30 seconds
				// finalize(() => (this.loading = !this.loading))
			)
			.subscribe({
				next: _ => void 0,
				error: (err: any) => {
					// error thrown by timeout. This might change in the future, depending on the back-end implementation
					if (err.name === "TimeoutError") {
						this.toastService.showInfo(
							"The upload process has started, but is taking longer then usual. You can periodically refresh the file list."
						);
						this.updateFileList$.emit();
					} else {
						if (err.status === 200) {
							this.toastService.showInfo("The file has successfully been submitted for upload process");
							this.updateFileList$.emit();
						} else if (err.status.toString().substring(0, 1) === "4") {
							this.openDialog(err.error);
						}
						return;
					}
				}
			});
	}

	uploadCalendarFile(fileData: FormData) {
		this.calendarFilesHistoryService
			.uploadCalendarFile(fileData)
			.pipe(
				timeout(5000) // set a default timeout so the user does not stare at the screen for 30 seconds
			)
			.subscribe({
				next: _ => void 0,
				error: (err: any) => {
					// error thrown by timeout. This might change in the future, depending on the back-end implementation
					if (err.name === "TimeoutError") {
						this.toastService.showInfo(
							"The upload process has started, but is taking longer then usual. You can periodically refresh the file list."
						);
						this.updateCalendarFileList$.emit();
					} else {
						switch (err.status) {
							case 200:
								// uploaded with no errors
								this.toastService.showSuccess(
									"The file has successfully been submitted for upload process"
								);
								this.updateCalendarFileList$.emit();
								break;
							case 403:
								this.toastService.showError(err.error.message);
								break;
							case 422:
								// return errors to view

								this.openDialog(err.error);
								break;
							default:
								break;
						}
					}
				}
			});
	}

	closeDialog() {
		this.updateFileList$.next();
		this.dialog.closeAll();
	}
}
