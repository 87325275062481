<div class="cancel-dialog-conatiner">
	<div class="cancel-dialog-header">
		<span class="header-title">CANCEL BOOKING</span>

		<app-icon
			(click)="close()"
			id="closeIcon"
			[size]="'60'"
			[name]="'close'"
			color="var(--color-basic-100)"
			class="close"
			[lineWidth]="'1px'"
		>
		</app-icon>
	</div>
	<div fxLayout="column" class="cancel-dialog-content" mat-dialog-content>
		<span class="modal-text"
			><app-icon class="info-icon" [name]="'info-circle'" [size]="'24'" [color]="'var(--pending-color)'">
			</app-icon>
			Notice that by doing this, all transactions will be cancelled.</span
		>
		<br />
		<span class="modal-text">You are canceling transactions from:</span>
		<br />

		<span class="provider-informarion-container">
			<span class="modal-text bold">{{ providerMessages[0].provider }}:</span>
			{{ providerMessages[0].message }} <br /><br />
		</span>

		<!--Temp Work around-->
		<!--<span *ngFor="let providerMessage of providerMessages" class="provider-informarion-container">
			<span class="modal-text bold">{{ providerMessage.provider }}:</span> &nbsp; {{ providerMessage.message }}
			<br /><br />
		</span>-->

		<mat-dialog-content [formGroup]="form">
			<textarea placeholder="Type the reason here..." formControlName="reason" id="reason" class="reason">
			</textarea>
		</mat-dialog-content>
		<span class="wordCount">{{ wordCount }} / {{ totalWordCount }} </span>
	</div>
	<div fxLayoutAlign="end" class="cancel-dialog-actions">
		<button class="back" (click)="onBackClick()">BACK</button>
		<button
			class="continue"
			(click)="onCancelClick()"
			[ngClass]="{ disabled: disableContinue }"
			[disabled]="disableContinue"
			cdkFocusInitial
		>
			CANCEL BOOKING
		</button>
	</div>
</div>
