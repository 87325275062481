<div>
	<ng-template [appUserRolePermissions] [action]="'hide'" [roles]="['TS_VO']">
		<div class="content-header">
			<button class="refresh-button" mat-icon-button (click)="loadFilesData(userEmail, -1, pageIndex, true)">
				<app-icon
					[name]="'rotate-right'"
					[color]="'var(--color-disabled)'"
					[lineWidth]="'2px'"
					[size]="'24'"
				></app-icon>
			</button>
			<input
				hidden
				type="file"
				#uploader
				(change)="uploadCalendarFile(uploader.files)"
				(click)="uploader.value = ''"
			/>
			<button class="upload-calendar-file" mat-raised-button (click)="uploader.click()">
				<span class="uplaod-text">
					<app-icon
						[name]="'document-upload'"
						[color]="'var(--color-basic-100)'"
						[lineWidth]="'2px'"
						[size]="'24'"
					></app-icon>
					<span class="text-spacing body-small-bold white">UPLOAD CALENDAR FILE</span>
				</span>
			</button>
		</div>
	</ng-template>
	<div class="table-header-g2n">
		<div id="name" class="table-header-item">MANAGED BY</div>
		<div id="date" class="table-header-item">DATE</div>
		<div id="date" class="table-header-item">ACTION</div>
		<div id="type" class="table-header-item">FILE NAME</div>
		<div id="status" class="table-header-item">STATUS</div>
	</div>

	<ng-container class="table-body" *ngIf="!fetchingFiles && (files$ | async) as files; else noItemsFound">
		<div *ngIf="!files.items?.length">
			<no-items-layout message="No files have been uploaded"></no-items-layout>
		</div>

		<div class="data-row row-item-section" *ngFor="let file of files?.items; let i = index">
			<div class="table-body">
				<div [matTooltip]="file.attributes['MANAGED_BY']!" class="table-row-item">
					{{ file.attributes["MANAGED_BY"] }}
				</div>
				<div class="table-row-item" *ngIf="file.updatedAt; else noDate">
					{{ file.updatedAt | date : "medium" }}
				</div>

				<ng-template #noDate>
					<div class="table-row-item">-</div>
				</ng-template>

				<div class="table-row-item">
					<ng-container *ngIf="file.fileType === 'download'">
						Template
						<app-icon [name]="'document-download'" [lineWidth]="'2px'" [size]="'24'"></app-icon>
					</ng-container>
					<ng-container *ngIf="file.fileType === 'upload'">
						File
						<app-icon [name]="'document-upload'" [lineWidth]="'2px'" [size]="'24'"></app-icon>
					</ng-container>
				</div>

				<div class="table-row-item" [matTooltip]="file.attributes['FILE_NAME']!">
					{{ file.attributes["FILE_NAME"] }}
				</div>

				<div
					class="table-row-item"
					[matTooltip]="file.status | calendarFileStatus"
					[ngClass]="{
						'status-green': file.status === 'PROCESSED' || file.status === 'SUCCESS',
						'status-red': file.status === 'FAILED',
						'status-pending': file.status === 'PROCESSING' || file.status === 'PENDING',
						'status-created': file.status === 'CREATED'
					}"
				>
					{{ file.status | calendarFileStatus }}
				</div>
				<div class="table-row-item visible">
					<app-icon
						*ngIf="file.status === 'FAILED'"
						[name]="'eye'"
						[matTooltip]="'Show errors'"
						(click)="viewErrors(file.id)"
					></app-icon>
				</div>
				<div class="table-row-item visible">
					<app-icon
						*ngIf="file.status === 'SUCCESS' || file.status === 'PROCESSED'"
						[name]="'vector'"
						[size]="'24.65'"
						[matTooltip]="'Download file'"
						(click)="
							file.fileType === 'upload'
								? downloadFile(file.id, file.attributes['FILE_NAME'])
								: downloadTemplate(file.id, file.attributes['FILE_NAME'])
						"
					></app-icon>
				</div>
			</div>
		</div>
	</ng-container>

	<ng-template #noItemsFound>
		<no-items-layout *ngIf="!fetchingFiles" [message]="'No calendar file history found'"></no-items-layout>

		<div class="pulsate loader" *ngIf="fetchingFiles">Fetching calendar files...</div>
	</ng-template>
</div>

<app-calendar-file-history-errors
	(toggle)="toggleFileErrosPopup()"
	[taskId]="errorTaskId"
	*ngIf="isVisible"
></app-calendar-file-history-errors>
