<div class="header">
	{{ title }}
</div>

<div class="content">
	<mat-dialog-content [formGroup]="userForm">
		<div class="flex flex-row">
			<!---UserID-->
			<input-wrapper class="space" label="Email *">
				<div class="wrapper-icon-email">
					<app-icon class="icon-input-email" size="20" name="sms-search" color="#2E62FF"></app-icon>
					<input id="email" type="text" class="input-style" formControlName="email" />
				</div>

				<div
					input-errors
					*ngIf="
						(userForm.get('email')?.dirty || userForm.get('email')?.touched) &&
						userForm.get('email')?.errors?.required
					"
				>
					Email is required
				</div>
			</input-wrapper>
			<!---phone-->
			<input-wrapper label="Phone Number">
				<input id="phoneNumber" type="text" formControlName="phoneNumber" />
				<div
					input-errors
					*ngIf="
						(userForm.get('phoneNumber')?.dirty || userForm.get('phoneNumber')?.touched) &&
						userForm.get('phoneNumber')?.errors?.required
					"
				>
					Phone is required
				</div>
			</input-wrapper>
		</div>
		<div class="flex flex-row">
			<!--FirstName-->
			<input-wrapper class="space" label="First Name *">
				<input id="firstName" type="text" formControlName="firstName" />
				<div
					input-errors
					*ngIf="
						(userForm.get('firstName')?.dirty || userForm.get('firstName')?.touched) &&
						userForm.get('firstName')?.errors?.required
					"
				>
					First name is required
				</div>
			</input-wrapper>
			<!--FirstName-->
			<input-wrapper label="Last Name *">
				<input id="lastName" type="text" formControlName="lastName" />
				<div
					input-errors
					*ngIf="
						(userForm.get('lastName')?.dirty || userForm.get('lastName')?.touched) &&
						userForm.get('lastName')?.errors?.required
					"
				>
					Last name is required
				</div>
			</input-wrapper>
		</div>

		<div class="flex flex-row" *ngIf="rolesList">
			<!-- Legal entity selector -->
			<input-wrapper class="input-wrapper" label="Role *">
				<app-select
					[roles]="roles | roles"
					[options]="rolesList | roles"
					(updateSelectedOptions)="updateRoles($event)"
					(disableSaveButton)="updateHasClientConflictErrors($event)"
				></app-select>
			</input-wrapper>
		</div>

		<div *ngIf="data" class="flex flex-row">
			<input-wrapper class="space" label="Collaborate ticket number*">
				<input id="ticketNr" type="text" formControlName="ticketNr" />
				<div
					input-errors
					*ngIf="
						(userForm.get('ticketNr')?.dirty || userForm.get('ticketNr')?.touched) &&
						userForm.get('ticketNr')?.errors?.required
					"
				>
					Ticket Number
				</div>
			</input-wrapper>

			<input-wrapper label="Reason for change*">
				<input id="reason" type="text" formControlName="reason" />
				<div
					input-errors
					*ngIf="
						(userForm.get('reason')?.dirty || userForm.get('reason')?.touched) &&
						userForm.get('reason')?.errors?.required
					"
				>
					Reason
				</div>
			</input-wrapper>
		</div>
	</mat-dialog-content>

	<mat-dialog-actions class="actions flex flex-row flex-align-stretch-end">
		<button id="closeButtonNewEditUser" class="mat-stroked-button cancel-btn" (click)="close()">Close</button>
		<button
			id="saveButtonNewEditUser"
			class="mat-stroked-button submit-btn"
			(click)="save()"
			[disabled]="btnDisabled || hasClientConflictError"
		>
			Save
		</button>
	</mat-dialog-actions>
</div>
