import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { InputWrapperModule } from "@shared/components/input-wrapper/input-wrapper.module";
import { ImageUploadModule } from "@shared/components/image-upload/image-upload.module";
import { InputSelectModule } from "@shared/components/input-select/input-select.module";
import { InputSelectCountryModule } from "@shared/components/input-select-country/input-select-country.module";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { InputAutocompleteModule } from "@shared/components/input-autocomplete/input-autocomplete.module";

// Contains all modules for setting up a form

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		InputWrapperModule,
		ImageUploadModule,
		InputSelectModule,
		InputAutocompleteModule,
		InputSelectCountryModule,
		MatFormFieldModule,
		MatSelectModule
	],
	exports: [
		FormsModule,
		ReactiveFormsModule,
		InputWrapperModule,
		ImageUploadModule,
		InputSelectModule,
		InputSelectCountryModule,
		InputAutocompleteModule,
		MatFormFieldModule,
		MatSelectModule
	]
})
export class FormsSharedModule {}
