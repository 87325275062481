import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BankAccountFieldsComponent } from "./bank-account-fields.component";
import { InputAutocompleteModule } from "../input-autocomplete/input-autocomplete.module";
import { FormsSharedModule } from "@shared/modules/forms-shared.module";
import { FlexLayoutModule } from "ngx-flexible-layout";

@NgModule({
	declarations: [BankAccountFieldsComponent],
	imports: [CommonModule, InputAutocompleteModule, FormsSharedModule, FlexLayoutModule],
	exports: [BankAccountFieldsComponent]
})
export class BankAccountFieldsModule {}
