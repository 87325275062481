<div class="table-container" role="table" aria-label="Destinations">
	<!-- Table -->

	<!-- Header -->
	<div class="flex-table header" role="rowgroup">
		<div class="flex-row" role="columnheader">Payment Types</div>
		<div class="flex-row" role="columnheader">Headcount</div>
		<div class="flex-row" role="columnheader">PAYMENT ACCOUNT TYPE</div>
		<div class="flex-row" role="columnheader">PAYMENT ROUTE</div>
		<div class="flex-row" role="columnheader">SP BILLING CURRENCY</div>
		<div class="flex-row" role="columnheader">SP TRANSACTION FEE</div>
		<div class="flex-row" role="columnheader">SP MONTHLY FEE</div>
		<div class="notes" role="columnheader">Add Notes</div>
	</div>
	<app-cost-form></app-cost-form>
</div>
