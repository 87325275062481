import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import {
	AMLHistory,
	AMLHistoryPagination,
	LegalEntityApi
} from "@modules/legal-entity-setup/_models/legal-entity-api.interface";
import { environment } from "src/environments/environment";
import { LegalEntity, LegalEntityPagination, LegalEntityType } from "../../models/legal-entity.interface";
import { SelectOption } from "../../models/select-option.interface";
import { formatDataForSelect } from "../../utils/format-select-data.util";

@Injectable({
	providedIn: "root"
})
export class LegalEntityService {
	constructor(private http: HttpClient) {}

	getLegalEntities(): Observable<LegalEntity[]> {
		return this.http.get<LegalEntityPagination>(`${environment.apiUri}/v1/legal-entities&pageSize=99`).pipe(
			//we don't need the pagination data so only return the items array from the object
			map((res: LegalEntityPagination) => {
				return res.items;
			})
		);
	}

	getLegalEntity(id: string): Observable<LegalEntityType> {
		return this.http.get<LegalEntityType>(`${environment.apiUri}/v1/legal-entities/${id}`);
	}

	//this will update to use pagination
	//country = 'ZA' || 'ESP'
	getLegalEntitiesForCustomerAndCountry(
		customerId: string,
		pageSize: number,
		pageIndex: number,
		countryCode?: string
	): Observable<LegalEntityPagination> {
		return this.http.get<LegalEntityPagination>(
			`${environment.apiUri}/v1/legal-entities?customerId=${customerId}&country=${
				countryCode ? countryCode : ""
			}&pageSize=${pageSize}&pageNumber=${pageIndex}`
		);
	}

	getLegalEntitiesForCustomerAndCountryWithoutPagination(
		customerId: string,
		countryCode?: string
	): Observable<LegalEntity[]> {
		return this.http
			.get<LegalEntityPagination>(
				`${environment.apiUri}/v1/legal-entities?customerId=${customerId}&country=${
					countryCode ? countryCode : ""
				}&pageSize=-1`
			)
			.pipe(
				//we don't need the pagination data so only return the items array from the object
				map((res: LegalEntityPagination) => {
					return res.items;
				})
			);
	}

	getLegalEntitiesItems(
		customerId: string,
		pageNumber: number,
		pageSize: number,
		searchQuery: string,
		legalEntityIds: string[],
		countryCode?: string
	): Observable<LegalEntityPagination> {
		return this.http
			.get<LegalEntityPagination>(
				`${environment.apiUri}/v1/legal-entities?customerId=${customerId}
				&pageSize=${pageSize}
				&pageNumber=${pageNumber}
				&queryString=${searchQuery}
				&legalEntityIds=${legalEntityIds}
				&country=${countryCode ? countryCode : ""}`
			)
			.pipe(
				//we don't need the pagination data so only return the items array from the object
				map((res: LegalEntityPagination) => {
					return res;
				})
			);
	}

	searchLegalEntities(
		customerId: string,
		pageNumber: number,
		pageSize: number,
		countryCode?: string,
		searchQuery?: string
	): Observable<LegalEntityPagination> {
		let params = new HttpParams();
		params = params.append("customerId", customerId);
		params = params.append("country", countryCode ? countryCode : "");
		params = params.append("pageSize", pageSize);
		params = params.append("pageNumber", pageNumber);
		params = params.append("queryString", searchQuery ? searchQuery : "");

		return this.http.get<LegalEntityPagination>(`${environment.apiUri}/v1/legal-entities`, { params });
	}

	getLegalEntitiesForCustomer(customerId: string): Observable<LegalEntityPagination> {
		let params = new HttpParams();
		params = params.append("customerId", customerId);
		params = params.append("pageSize", -1);

		return this.http.get<LegalEntityPagination>(`${environment.apiUri}/v1/legal-entities`, { params });
	}
	getLegalEntitiesForCustomers(customerIds: string[]): Observable<LegalEntityPagination> {
		return this.http.get<LegalEntityPagination>(
			`${environment.apiUri}/v1/legal-entities?customerIds=${customerIds}&pageSize=-1`
		);
	}

	getLegalEntitiesViaCustomers(
		customerIds: string,
		legalEnitityIds: string[],
		pageSize: number,
		pageNumber: number
	): Observable<LegalEntityPagination> {
		return this.http.get<LegalEntityPagination>(
			`${environment.apiUri}/v1/legal-entities?customerIds=${customerIds}&pageSize=${pageSize}&pageNumber=${pageNumber}&legalEntityIds=${legalEnitityIds}`
		);
	}

	getLegalEntitiesForCustomerAsSelectOption(
		customerId: string,
		onlyLegalEntitiesWithPaygroups: boolean = false
	): Observable<SelectOption[]> {
		return this.getLegalEntitiesForCustomer(customerId).pipe(
			map(entities => {
				const nonZeroEntities = entities.items.filter(obj => obj.payGroupCount !== 0);
				return onlyLegalEntitiesWithPaygroups ? nonZeroEntities : entities.items;
			}),

			map((entities: LegalEntity[]) => {
				return entities.flatMap(entity => {
					const option: SelectOption = {
						value: entity.id,
						text: `${entity.data.country} - ${entity.data.externalId} - ${entity.data.name}`,
						imagePath: `assets/svg-country-flags/svg/${entity.data?.country.toLowerCase()}.svg`
					};
					return entity.status === "AML_APPROVED" || entity.status === "SOFT_AML_APPROVED"
						? formatDataForSelect(option)
						: [];
				});
			})
		);
	}

	getLegalEntitiesForCustomersAsSelectOption(customerIds: string[]): Observable<SelectOption[]> {
		let selection: SelectOption[] = [];

		if (customerIds && customerIds.length > 0) {
			return this.getLegalEntitiesForCustomers(customerIds).pipe(
				map((entities: LegalEntityPagination) => {
					entities.items.forEach(legalEntity => {
						selection.push({
							value: legalEntity.id,
							text: legalEntity.data.name,
							imagePath:
								"assets/svg-country-flags/svg/" + legalEntity.data.country.toLowerCase() + ".svg",
							placeholder: legalEntity.data.name,
							externalId: legalEntity.data.externalId,
							additionalInformation: new Map<string, string>([
								["customerId", legalEntity.customerId],
								["payGroupCount", legalEntity.payGroupCount.toString()]
							])
						});
					});

					return selection;
				})
			);
		} else {
			return of(selection);
		}
	}

	createLegalEntity(legalEntity: LegalEntityApi): Observable<LegalEntity> {
		return this.http.post<LegalEntity>(`${environment.apiUri}/v1/legal-entities`, legalEntity);
	}

	updateLEgalEntity(legalEntity: LegalEntityApi): Observable<LegalEntity> {
		return this.http.put<LegalEntity>(`${environment.apiUri}/v1/legal-entities`, legalEntity);
	}

	deleteLegalEntity(legalEntityId: string): Observable<LegalEntity> {
		return this.http.delete<LegalEntity>(`${environment.apiUri}/v1/legal-entities/${legalEntityId}`);
	}

	// deleteLegalEntityTest(legalEntity: LegalEntity) {
	// 	const deleteLegalEntity = this.legalEntities.find(deleteLegalEntity => deleteLegalEntity.id === legalEntity.id);
	// 	if (deleteLegalEntity) {
	// 		const index = this.legalEntities.indexOf(deleteLegalEntity);
	// 		this.legalEntities.splice(index, 1);
	// 	}
	// }

	getAMLHistory(legalEntityId: string): Observable<AMLHistory[]> {
		let params = new HttpParams();

		params = params.append("legalEntityId", legalEntityId);

		return this.http.get<AMLHistoryPagination>(`${environment.apiUri}/v1/aml/history`, { params }).pipe(
			map((response: AMLHistoryPagination) => {
				const amlHistoryList: AMLHistory[] = response.items;
				amlHistoryList.map((item: AMLHistory) => (item.createdAt = new Date(item.createdAt)));
				return amlHistoryList;
			})
		);
	}

	getLegalEntityById(id: string): Observable<LegalEntity> {
		return this.http.get<LegalEntity>(`${environment.apiUri}/v1/legal-entities/${id}`);
	}
}
