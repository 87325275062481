import { NgModule } from "@angular/core";
import { CommonModule, CurrencyPipe } from "@angular/common";
import { PennyTestComponent } from "./penny-test.component";
import { SharedModule } from "@shared/shared.module";
import { PennyTestRoutingModule } from "./penny-test-routing.module";
import { FlexLayoutModule } from "ngx-flexible-layout";
import { InputAutocompleteModule } from "@shared/components/input-autocomplete/input-autocomplete.module";
import { InputSelectModule } from "@shared/components/input-select/input-select.module";
import { InputWrapperModule } from "@shared/components/input-wrapper/input-wrapper.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BreadcrumbsModule } from "@shared/components/breadcrumbs/breadcrumbs.module";

@NgModule({
	declarations: [PennyTestComponent],
	imports: [
		CommonModule,
		PennyTestRoutingModule,
		SharedModule,
		FlexLayoutModule,
		InputAutocompleteModule,
		InputSelectModule,
		InputWrapperModule,
		FormsModule,
		ReactiveFormsModule,
		BreadcrumbsModule
	],

	providers: [CurrencyPipe]
})
export class PennyTestModule {}
