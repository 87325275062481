import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { UserRole } from "@shared/constants/roles";
import { getUserDataState } from "@store/index";
import { AppState } from "../../../store/models/state.model";

@Injectable({
	providedIn: "root"
})
export class PermissionsService {
	constructor(private store: Store<AppState>) {}

	//Side menu permissions
	canViewGlobalDashboard(userRoles: string[]): boolean {
		const roles = [
			UserRole.SUPER_ADMIN,
			UserRole.CLIENT,
			UserRole.TS_IMPLEMENTATION_LEAD,
			UserRole.TS_OPERATION_CONSULTANT,
			UserRole.TS_OPERATION_LEAD,
			UserRole.TS_MANAGER,
			UserRole.TS_OCB,
			UserRole.TS_VO
		];
		return roles.some(i => userRoles.includes(i));
	}

	canViewClientSummary(userRoles: string[]): boolean {
		const roles = [UserRole.CLIENT];
		return roles.some(i => userRoles.includes(i));
	}

	canViewEmployeeData(userRoles: string[]): boolean {
		const roles = [
			UserRole.SUPER_ADMIN,
			UserRole.CLIENT,
			UserRole.TS_IMPLEMENTATION_CONSULTANT,
			UserRole.TS_IMPLEMENTATION_LEAD,
			UserRole.TS_OPERATION_CONSULTANT,
			UserRole.TS_OPERATION_LEAD,
			UserRole.TS_MANAGER,
			UserRole.TS_OCB,
			UserRole.TS_VO
		];
		return roles.some(i => userRoles.includes(i));
	}

	canViewConfiguration(userRoles: string[]): boolean {
		const roles = [
			UserRole.SUPER_ADMIN,
			UserRole.TS_IMPLEMENTATION_CONSULTANT,
			UserRole.TS_IMPLEMENTATION_LEAD,
			UserRole.TS_OPERATION_CONSULTANT,
			UserRole.TS_OPERATION_LEAD,
			UserRole.TS_MANAGER,
			UserRole.TS_OCB,
			UserRole.TS_VO
		];
		return roles.some(i => userRoles.includes(i));
	}

	canViewCustomers(userRoles: string[]): boolean {
		const roles = [
			UserRole.SUPER_ADMIN,
			UserRole.TS_IMPLEMENTATION_CONSULTANT,
			UserRole.TS_IMPLEMENTATION_LEAD,
			UserRole.TS_OPERATION_CONSULTANT,
			UserRole.TS_OPERATION_LEAD,
			UserRole.TS_MANAGER,
			UserRole.TS_OCB,
			UserRole.TS_VO
		];
		return roles.some(i => userRoles.includes(i));
	}

	canViewServiceDefinition(userRoles: string[]): boolean {
		const roles = [
			UserRole.SUPER_ADMIN,
			UserRole.TS_IMPLEMENTATION_CONSULTANT,
			UserRole.TS_IMPLEMENTATION_LEAD,
			UserRole.TS_OPERATION_CONSULTANT,
			UserRole.TS_OPERATION_LEAD,
			UserRole.TS_MANAGER,
			UserRole.TS_OCB,
			UserRole.TS_VO
		];
		return roles.some(i => userRoles.includes(i));
	}

	canViewCalendar(userRoles: string[]): boolean {
		const roles = [
			UserRole.SUPER_ADMIN,
			UserRole.TS_IMPLEMENTATION_CONSULTANT,
			UserRole.TS_IMPLEMENTATION_LEAD,
			UserRole.TS_OPERATION_CONSULTANT,
			UserRole.TS_OPERATION_LEAD,
			UserRole.TS_MANAGER,
			UserRole.TS_OCB,
			UserRole.TS_VO
		];
		return roles.some(i => userRoles.includes(i));
	}

	canViewUserManagement(userRoles: string[]): boolean {
		const roles = [UserRole.SUPER_ADMIN, UserRole.TS_ADMIN];
		return roles.some(i => userRoles.includes(i));
	}

	canViewReporting(userRoles: string[]): boolean {
		const roles = [
			UserRole.SUPER_ADMIN,
			UserRole.TS_IMPLEMENTATION_LEAD,
			UserRole.TS_OPERATION_LEAD,
			UserRole.TS_MANAGER
		];
		return roles.some(i => userRoles.includes(i));
	}

	canViewSystem(userRoles: string[]): boolean {
		const roles = [
			UserRole.SUPER_ADMIN,
			UserRole.TS_IMPLEMENTATION_CONSULTANT,
			UserRole.TS_IMPLEMENTATION_LEAD,
			UserRole.TS_OPERATION_CONSULTANT,
			UserRole.TS_OPERATION_LEAD,
			UserRole.TS_OCB,
			UserRole.TS_VO
		];
		return roles.some(i => userRoles.includes(i));
	}

	canViewServiceProvider(userRoles: string[]): boolean {
		const roles = [UserRole.SUPER_ADMIN];
		return roles.some(i => userRoles.includes(i));
	}

	canViewPennyTest(userRoles: string[]): boolean {
		const roles = [
			UserRole.SUPER_ADMIN,
			UserRole.TS_IMPLEMENTATION_CONSULTANT,
			UserRole.TS_IMPLEMENTATION_LEAD,
			UserRole.TS_OPERATION_CONSULTANT,
			UserRole.TS_OPERATION_LEAD,
			UserRole.TS_OCB,
			UserRole.TS_VO
		];
		return roles.some(i => userRoles.includes(i));
	}

	canViewTppCatalogue(userRoles: string[]): boolean {
		const roles = [
			UserRole.SUPER_ADMIN,
			UserRole.TS_IMPLEMENTATION_CONSULTANT,
			UserRole.TS_IMPLEMENTATION_LEAD,
			UserRole.TS_OPERATION_CONSULTANT,
			UserRole.TS_OPERATION_LEAD,
			UserRole.TS_OCB,
			UserRole.TS_VO
		];
		return roles.some(i => userRoles.includes(i));
	}

	canViewBankFields(userRoles: string[]): boolean {
		const roles = [UserRole.SUPER_ADMIN];
		return roles.some(i => userRoles.includes(i));
	}

	canViewErrorManagement(userRoles: string[]): boolean {
		const roles = [UserRole.SUPER_ADMIN];
		return roles.some(i => userRoles.includes(i));
	}

	//Payments
	//Reset Payroll Run

	keepCustomerSelection(): Observable<boolean> {
		const roles = ["CLIENT"];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	canResetRuns(): Observable<boolean> {
		const roles = ["SUPER_ADMIN", "TS_OPERATION_CONSULTANT", "TS_OPERATION_LEAD", "TS_OPERATION_MANAGER"];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	//Change status for Funding Date
	canEditFundingDateStatus(): Observable<boolean> {
		const roles = ["SUPER_ADMIN", "TS_OPERATION_CONSULTANT", "TS_OPERATION_LEAD", "TS_OCB"];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	//DASHBOARD NETS

	//Switch Route
	canSwitchRoute(): Observable<boolean> {
		const roles = ["SUPER_ADMIN", "TS_OPERATION_LEAD"];

		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	//View History
	canViewTransactionHistoryNETS(): Observable<boolean> {
		const roles = ["SUPER_ADMIN", "TS_OCB", "TS_IMPLEMENTATION_LEAD", "TS_OPERATION_CONSULTANT", "TS_VO"];

		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	//Upload File
	canUploadG2NFiles(): Observable<boolean> {
		const roles = [
			"SUPER_ADMIN",
			"TS_IMPLEMENTATION_CONSULTANT",
			"TS_IMPLEMENTATION_LEAD",
			"TS_OPERATION_CONSULTANT",
			"TS_OPERATION_LEAD"
		];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	canTriggerPaymentsNETS(): Observable<boolean> {
		const roles = [
			"SUPER_ADMIN",
			"TS_IMPLEMENTATION_CONSULTANT",
			"TS_IMPLEMENTATION_LEAD",
			"TS_OPERATION_CONSULTANT",
			"TS_OPERATION_LEAD",
			"TS_OCB"
		];

		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	//Reprocess Payment
	canReprocessPaymentsNETS(): Observable<boolean> {
		const roles = [
			"SUPER_ADMIN",
			"TS_IMPLEMENTATION_CONSULTANT",
			"TS_IMPLEMENTATION_LEAD",
			"TS_OPERATION_CONSULTANT",
			"TS_OPERATION_LEAD",
			"TS_OCB"
		];

		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	//Inactivate Payment
	canInactivatePayment(): Observable<boolean> {
		const roles = [
			"SUPER_ADMIN",
			"TS_IMPLEMENTATION_CONSULTANT",
			"TS_IMPLEMENTATION_LEAD",
			"TS_OPERATION_CONSULTANT",
			"TS_OPERATION_LEAD"
		];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	//DASHBOARD TPP

	//Upload File
	canUploadDataInputFile(): Observable<boolean> {
		const roles = ["SUPER_ADMIN", "TS_IMPLEMENTATION_CONSULTANT", "TS_OPERATION_CONSULTANT", "TS_OPERATION_LEAD"];

		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	canViewTransactionHistoryTPP(): Observable<boolean> {
		const roles = ["SUPER_ADMIN", "TS_OCB", "TS_IMPLEMENTATION_LEAD", "TS_OPERATION_CONSULTANT"];

		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	canInactivatePaymentTPP(): Observable<boolean> {
		const roles = [
			"SUPER_ADMIN",
			"TS_IMPLEMENTATION_CONSULTANT",
			"TS_IMPLEMENTATION_LEAD",
			"TS_OPERATION_CONSULTANT",
			"TS_OPERATION_LEAD"
		];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	canTriggerBookings(): Observable<boolean> {
		const roles = [
			"SUPER_ADMIN",
			"TS_IMPLEMENTATION_CONSULTANT",
			"TS_IMPLEMENTATION_LEAD",
			"TS_OPERATION_CONSULTANT",
			"TS_OPERATION_LEAD",
			"TS_OCB"
		];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	//Reprocess Payment
	canReprocessBookings(): Observable<boolean> {
		const roles = [
			"SUPER_ADMIN",
			"TS_IMPLEMENTATION_CONSULTANT",
			"TS_IMPLEMENTATION_LEAD",
			"TS_OPERATION_CONSULTANT",
			"TS_OPERATION_LEAD",
			"TS_OCB"
		];

		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	//EMPLOYEE DATA

	canCreateEmployee(): Observable<boolean> {
		const roles = [
			"SUPER_ADMIN",
			"TS_IMPLEMENTATION_CONSULTANT",
			"TS_IMPLEMENTATION_LEAD",
			"TS_OPERATION_CONSULTANT",
			"TS_OPERATION_LEAD",
			"TS_OCB"
		];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	canEditEmployee(): Observable<boolean> {
		const roles = ["SUPER_ADMIN"];

		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	canCreateEmployeeBankAccount(): Observable<boolean> {
		const roles = [
			"SUPER_ADMIN",
			"TS_IMPLEMENTATION_CONSULTANT",
			"TS_IMPLEMENTATION_LEAD",
			"TS_OPERATION_CONSULTANT",
			"TS_OPERATION_LEAD",
			"TS_OCB"
		];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	canReprocessPayMethods(): Observable<boolean> {
		const roles = ["SUPER_ADMIN", "TS_OCB"];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	//Can upload masterfile
	canUploadEmployeesFiles(): Observable<boolean> {
		const roles = [
			"SUPER_ADMIN",
			"TS_IMPLEMENTATION_CONSULTANT",
			"TS_IMPLEMENTATION_LEAD",
			"TS_OPERATION_CONSULTANT",
			"TS_OPERATION_LEAD",
			"TS_OCB"
		];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	//CUSTOMERS

	canCreateCustomers(): Observable<boolean> {
		const roles = ["SUPER_ADMIN", "TS_IMPLEMENTATION_CONSULTANT", "TS_IMPLEMENTATION_LEAD"];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	canEditCustomerSetup(): Observable<boolean> {
		const roles = [
			"SUPER_ADMIN",
			"TS_IMPLEMENTATION_CONSULTANT",
			"TS_IMPLEMENTATION_LEAD",
			"TS_OPERATION_LEAD",
			"TS_OPERATION_CONSULTANT",
			"TS_OCB"
		];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	canEditCustomerCompliance(): Observable<boolean> {
		const roles = ["SUPER_ADMIN", "TS_IMPLEMENTATION_CONSULTANT", "TS_IMPLEMENTATION_LEAD"];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	//LEGAL ENTITIES
	canEditLegalEntityCompliance(): Observable<boolean> {
		const roles = ["SUPER_ADMIN"];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	canCreateLegalEntities(): Observable<boolean> {
		const roles = ["SUPER_ADMIN", "TS_IMPLEMENTATION_CONSULTANT", "TS_IMPLEMENTATION_LEAD"];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	canEditLegalEntitySetup(): Observable<boolean> {
		const roles = [
			"SUPER_ADMIN",
			"TS_IMPLEMENTATION_CONSULTANT",
			"TS_IMPLEMENTATION_LEAD",
			"TS_OPERATION_LEAD",
			"TS_OPERATION_CONSULTANT",
			"TS_OCB",
			"TS_VO",
			"CLIENT"
		];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	//PAY GROUPS
	canCreatePayGroups(): Observable<boolean> {
		const roles = [
			"SUPER_ADMIN",
			"TS_IMPLEMENTATION_CONSULTANT",
			"TS_IMPLEMENTATION_LEAD",
			"TS_OPERATION_LEAD",
			"TS_OPERATION_CONSULTANT",
			"TS_OCB"
		];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	canEditPayGroups(): Observable<boolean> {
		const roles = [
			"SUPER_ADMIN",
			"TS_IMPLEMENTATION_CONSULTANT",
			"TS_IMPLEMENTATION_LEAD",
			"TS_OPERATION_LEAD",
			"TS_OPERATION_CONSULTANT",
			"TS_OCB"
		];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	canEditPayGroupGoLiveDate(): Observable<boolean> {
		const roles = ["TS_IMPLEMENTATION_LEAD", "TS_OPERATION_LEAD", "SUPER_ADMIN"];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	canEditPayGroupStatus(): Observable<boolean> {
		const roles = ["TS_IMPLEMENTATION_LEAD", "TS_IMPLEMENTATION_CONSULTANT", "TS_OPERATION_LEAD", "SUPER_ADMIN"];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	canCreatePayGroupGoLiveDate(): Observable<boolean> {
		const roles = ["TS_IMPLEMENTATION_LEAD", "TS_IMPLEMENTATION_CONSULTANT", "TS_OPERATION_LEAD", "SUPER_ADMIN"];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	//CONTACT PERSON
	canEditContactPersons(): Observable<boolean> {
		const roles = [
			"SUPER_ADMIN",
			"TS_IMPLEMENTATION_CONSULTANT",
			"TS_IMPLEMENTATION_LEAD",
			"TS_OPERATION_LEAD",
			"TS_OPERATION_CONSULTANT",
			"TS_OCB"
		];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	isConsultantManager(): Observable<boolean> {
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return ngState.roles!.includes("TS_OPERATION_MANAGER");
			})
		);
	}

	// NETS SERVICE DEFINITIONS
	canCreateServiceDefinitions(): Observable<boolean> {
		const roles = [
			"TS_IMPLEMENTATION_LEAD",
			"TS_OPERATION_LEAD",
			"TS_OPERATION_CONSULTANT",
			"TS_IMPLEMENTATION_CONSULTANT",
			"SUPER_ADMIN",
			"TS_OCB"
		];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	canDeleteServiceDefinitions(): Observable<boolean> {
		const roles = ["TS_IMPLEMENTATION_LEAD", "TS_OPERATION_LEAD", "SUPER_ADMIN"];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	canEditServiceDefinitionCustomerCost(): Observable<boolean> {
		const roles = [
			"TS_IMPLEMENTATION_LEAD",
			"TS_OPERATION_LEAD",
			"TS_OPERATION_CONSULTANT",
			"TS_IMPLEMENTATION_CONSULTANT",
			"SUPER_ADMIN",
			"TS_OCB"
		];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	canEditSettlementAccounts(): Observable<boolean> {
		const roles = [
			"TS_IMPLEMENTATION_LEAD",
			"TS_OPERATION_LEAD",
			"TS_OPERATION_CONSULTANT",
			"TS_IMPLEMENTATION_CONSULTANT",
			"SUPER_ADMIN",
			"TS_OCB"
		];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	canEditSourceOfFunds(): Observable<boolean> {
		const roles = [
			"TS_IMPLEMENTATION_LEAD",
			"TS_OPERATION_LEAD",
			"TS_OPERATION_CONSULTANT",
			"TS_IMPLEMENTATION_CONSULTANT",
			"SUPER_ADMIN",
			"TS_OCB"
		];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	canAddSettlementAccounts(): Observable<boolean> {
		const roles = [
			"TS_IMPLEMENTATION_LEAD",
			"TS_OPERATION_LEAD",
			"TS_OPERATION_CONSULTANT",
			"TS_IMPLEMENTATION_CONSULTANT",
			"SUPER_ADMIN",
			"TS_OCB"
		];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	canAddSourceOfFunds(): Observable<boolean> {
		const roles = [
			"TS_IMPLEMENTATION_LEAD",
			"TS_OPERATION_LEAD",
			"TS_OPERATION_CONSULTANT",
			"TS_IMPLEMENTATION_CONSULTANT",
			"SUPER_ADMIN",
			"TS_OCB"
		];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	//TPP Service Definition
	//Can edit list of service paygroup
	canEditListOfServices(): Observable<boolean> {
		const roles = [
			"SUPER_ADMIN",
			"TS_OCB",
			"TS_IMPLEMENTATION_CONSULTANT",
			"TS_IMPLEMENTATION_LEAD",
			"TS_OPERATION_LEAD",
			"TS_OPERATION_CONSULTANT",
			"TS_VO",
			"CLIENT"
		];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	//Can add adhoc service
	canAddAdhocService(): Observable<boolean> {
		const roles = [
			"SUPER_ADMIN",
			"TS_OCB",
			"TS_IMPLEMENTATION_CONSULTANT",
			"TS_IMPLEMENTATION_LEAD",
			"TS_OPERATION_LEAD",
			"TS_OPERATION_CONSULTANT"
		];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	//Can edit adhoc service
	canEditAdhocService(): Observable<boolean> {
		const roles = [
			"SUPER_ADMIN",
			"TS_OCB",
			"TS_IMPLEMENTATION_CONSULTANT",
			"TS_IMPLEMENTATION_LEAD",
			"TS_OPERATION_CONSULTANT",
			"TS_OPERATION_LEAD"
		];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	//Can Edit Sub Service
	canEditSubServices(): Observable<boolean> {
		const roles = [
			"SUPER_ADMIN",
			"TS_OCB",
			"TS_IMPLEMENTATION_CONSULTANT",
			"TS_IMPLEMENTATION_LEAD",
			"TS_OPERATION_LEAD",
			"TS_OPERATION_CONSULTANT"
		];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}
	//Can edit Source of Funds
	canEditSourceOfFundsTPP(): Observable<boolean> {
		const roles = [
			"SUPER_ADMIN",
			"TS_OCB",
			"TS_IMPLEMENTATION_CONSULTANT",
			"TS_IMPLEMENTATION_LEAD",
			"TS_OPERATION_LEAD",
			"TS_OPERATION_CONSULTANT"
		];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}
	//Can edit Settlement Account
	canEditSettlementAccountTPP(): Observable<boolean> {
		const roles = [
			"SUPER_ADMIN",
			"TS_OCB",
			"TS_IMPLEMENTATION_CONSULTANT",
			"TS_IMPLEMENTATION_LEAD",
			"TS_OPERATION_LEAD",
			"TS_OPERATION_CONSULTANT"
		];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	// Can delete subservice
	canDeleteSubServiceDef(): Observable<boolean> {
		const roles = ["SUPER_ADMIN", "TS_OCB"];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}
	// Can edit beneficiary
	canEditBeneficiaryDef(): Observable<boolean> {
		const roles = [
			"SUPER_ADMIN",
			"TS_OCB",
			"TS_IMPLEMENTATION_CONSULTANT",
			"TS_IMPLEMENTATION_LEAD",
			"TS_OPERATION_LEAD",
			"TS_OPERATION_CONSULTANT"
		];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}
	// Can add beneficiary
	canAddBeneficiaryDef(): Observable<boolean> {
		const roles = [
			"SUPER_ADMIN",
			"TS_OCB",
			"TS_IMPLEMENTATION_CONSULTANT",
			"TS_IMPLEMENTATION_LEAD",
			"TS_OPERATION_LEAD",
			"TS_OPERATION_CONSULTANT"
		];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	//CALENDAR_EVENTS
	canCreateCalendarEvents(): Observable<boolean> {
		const roles = [
			"TS_IMPLEMENTATION_LEAD",
			"TS_OPERATION_LEAD",
			"TS_OPERATION_CONSULTANT",
			"TS_IMPLEMENTATION_CONSULTANT",
			"SUPER_ADMIN",
			"TS_OCB"
		];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	canEditCycle(): Observable<boolean> {
		const roles = [
			"TS_IMPLEMENTATION_LEAD",
			"TS_OPERATION_LEAD",
			"TS_OPERATION_CONSULTANT",
			"TS_IMPLEMENTATION_CONSULTANT",
			"SUPER_ADMIN",
			"TS_OCB"
		];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	canEditCalendarEvents(): Observable<boolean> {
		const roles = [
			"TS_IMPLEMENTATION_LEAD",
			"TS_OPERATION_LEAD",
			"TS_OPERATION_CONSULTANT",
			"TS_IMPLEMENTATION_CONSULTANT",
			"SUPER_ADMIN",
			"TS_OCB"
		];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	canCreateCalendarOffCycles(): Observable<boolean> {
		const roles = [
			"TS_IMPLEMENTATION_LEAD",
			"TS_OPERATION_LEAD",
			"TS_OPERATION_CONSULTANT",
			"TS_IMPLEMENTATION_CONSULTANT",
			"SUPER_ADMIN",
			"TS_OCB"
		];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	// SERVICE PROVIDER
	canEditServiceProviders(): Observable<boolean> {
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return ngState.roles!.includes("SUPER_ADMIN");
			})
		);
	}

	// PENNY TEST
	canEditPennyTest(): Observable<boolean> {
		const roles = ["SUPER_ADMIN"];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	// TPP CATALOG
	// Can create new service
	canCreateNewCatService(): Observable<boolean> {
		const roles = ["SUPER_ADMIN"];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	// Can edit service

	canEditCatService(): Observable<boolean> {
		const roles = ["SUPER_ADMIN"];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	// Can delete subservice
	canDeleteSubServiceCat(): Observable<boolean> {
		const roles = ["SUPER_ADMIN"];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}
	// Can edit beneficiary
	canEditBeneficiaryCat(): Observable<boolean> {
		const roles = ["SUPER_ADMIN"];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}
	// Can add beneficiary
	canAddBeneficiaryCat(): Observable<boolean> {
		const roles = ["SUPER_ADMIN"];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	//Bank Fields
	//Can edit bank fields
	canEditBankFields(): Observable<boolean> {
		const roles = ["SUPER_ADMIN"];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	//Can add bank fields
	canAddBankFields(): Observable<boolean> {
		const roles = ["SUPER_ADMIN"];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	canRemoveBankField(): Observable<boolean> {
		const roles = ["SUPER_ADMIN"];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	canCopyBankFields(): Observable<boolean> {
		const roles = ["SUPER_ADMIN"];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	// Error Management
	// Can Edit error
	canEditError(): Observable<boolean> {
		const roles = ["SUPER_ADMIN"];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	//Reporting
	// Can download report
	canDownloadReport(): Observable<boolean> {
		const roles = ["SUPER_ADMIN", "TS_IMPLEMENTATION_LEAD", "TS_OPERATION_LEAD"];
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles.some(i => ngState.roles!.includes(i));
			})
		);
	}

	disableAccessToEditableFormField(roles: Array<string>): Observable<boolean> {
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles!.some(role => ngState.roles!.includes(role)) && ngState.roles?.length === 1;
			})
		);
	}

	enableAccessToEditableFormField(roles: Array<string>): Observable<boolean> {
		return this.store.pipe(
			select(getUserDataState),
			map(ngState => {
				return roles!.some(role => ngState.roles!.includes(role));
			})
		);
	}

	//Global Dashboard

	canSeeMapAndIsClient(): Observable<{ isClient: boolean; canSeeMap: boolean }> {
		return this.store.pipe(
			select(getUserDataState),
			map(user => {
				return {
					isClient: user.roles ? user.roles.includes(UserRole.CLIENT) : false,
					canSeeMap: user.canSeeMap === true
				};
			})
		);
	}

	enableClientGlobalFilter(): Observable<{ isClientGlobalDashboard?: boolean }> {
		return this.store.pipe(
			select(getUserDataState),
			map(user => {
				return {
					isClientGlobalDashboard: user.isClientGlobalDashboard ? user.isClientGlobalDashboard : false
				};
			})
		);
	}
}
