import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "tppTypesFormat"
})
export class TppTypesFormatPipe implements PipeTransform {
	transform(value: string): string {
		const words = value.split("_");
		return words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(" ");
	}
}
