import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subject, Subscription } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { PaymentRouteName, PaymentTypeName, PayoutAccountName } from "src/app/shared/models/service-provider.type";
import { ProviderCountryTemplateService } from "../../../_services/provider-country-template/provider-country-template.service";
import { ServiceProviderCountryCreateService } from "../../../_services/service-provider-country-create/service-provider-country-create.service";

@Component({
	selector: "app-capabilities-form",
	templateUrl: "./capabilities-form.component.html",
	styleUrls: ["./capabilities-form.component.scss"]
})
export class CapabilitiesFormComponent implements OnInit, OnDestroy {
	capabilitiesForm!: FormGroup;

	subscription: Subscription = new Subscription();

	destroy$: Subject<void> = new Subject();

	constructor(
		private readonly formBuilder: FormBuilder,
		public readonly serviceProviderCountryCreateService: ServiceProviderCountryCreateService,
		private readonly providerCountryTemplateService: ProviderCountryTemplateService
	) {}

	ngOnInit(): void {
		this.initForm();

		this.subscription = this.serviceProviderCountryCreateService.createCapabilityData$
			.pipe(takeUntil(this.destroy$))
			.subscribe(stepper => {
				this.serviceProviderCountryCreateService.updateCapabilities(this.capabilitiesForm.getRawValue());
				this.serviceProviderCountryCreateService.emitCapabilities();
				stepper.next();
			});

		this.serviceProviderCountryCreateService.cancelReset$
			.pipe(
				takeUntil(this.destroy$),
				map(() => {
					this.capabilitiesForm.reset();
				})
			)
			.subscribe();
	}

	initForm(): void {
		this.capabilitiesForm = this.formBuilder.group({});

		//this should update to use the template data

		this.getPaymentTypes().filter(type => {
			const group = this.formBuilder.group({
				paymentType: [type],
				selected: [false],
				payoutAccountRoutes: this.formBuilder.array([])
			});

			this.capabilitiesForm.addControl(type.toString(), group);
			this.addAccountRoute(type);
			group
				.get("selected")
				?.valueChanges.pipe(takeUntil(this.destroy$))
				.subscribe(selected => {
					if (selected) {
						this.getCapabilitiesFormGroupArray(type).clear();
						const newGroup = this.formBuilder.group({
							payoutAccount: [null, Validators.required],
							route: [null, Validators.required],
							eta: ["", Validators.required]
						});
						this.getCapabilitiesFormGroupArray(type).push(newGroup);
					} else {
						this.getCapabilitiesFormGroupArray(type).clear();
						const newGroup = this.formBuilder.group({
							payoutAccount: [null],
							route: [null],
							eta: ["", Validators.required]
						});
						this.getCapabilitiesFormGroupArray(type).push(newGroup);
					}
				});
		});
		// this.setAccountRoutesAndTypes();
		this.onFormChanges();
	}

	getPaymentTypes(): PaymentTypeName[] {
		return this.providerCountryTemplateService.paymentTypes;
	}

	getPayoutAccouts(paymentType: PaymentTypeName): SelectOption[] {
		return this.providerCountryTemplateService.mappedPayoutAccount.get(paymentType)!;
	}

	getRoutes(payoutAccountName: PayoutAccountName): SelectOption[] {
		return this.providerCountryTemplateService.mappedRoutes.get(payoutAccountName)!;
	}

	addAccountRoute(paymentType: PaymentTypeName, addValidation: boolean = false) {
		if (!addValidation) {
			const newGroup = this.formBuilder.group({
				payoutAccount: [null],
				route: [null],
				eta: ["", Validators.required]
			});
			this.getCapabilitiesFormGroupArray(paymentType).push(newGroup);
		} else {
			const newGroup = this.formBuilder.group({
				payoutAccount: [null, Validators.required],
				route: [null, Validators.required],
				eta: ["", Validators.required]
			});
			this.getCapabilitiesFormGroupArray(paymentType).push(newGroup);
		}
	}

	groupIsValid(paymentType: PaymentTypeName): boolean {
		return this.getCapabilitiesFormGroupArray(paymentType).valid;
	}

	typeSelected(paymentType: PaymentTypeName): boolean {
		return this.capabilitiesForm.get(`${paymentType}.selected`)?.value;
	}

	updateValidation(paymentType: PaymentTypeName, index: number) {
		this.getCapabilitiesFormGroupArray(paymentType)
			.at(index)
			.get("payoutAccount")
			?.setValidators(Validators.required);
		this.getCapabilitiesFormGroupArray(paymentType).at(index).get("route")?.setValidators(Validators.required);
	}

	resetRow(paymentType: PaymentTypeName, index: number) {
		this.getCapabilitiesFormGroupArray(paymentType)
			.at(index)
			.get("payoutAccount")
			?.patchValue(null, Validators.required);
		this.getCapabilitiesFormGroupArray(paymentType).at(index).get("route")?.patchValue(null, Validators.required);
		this.getCapabilitiesFormGroupArray(paymentType).at(index).get("eta")?.patchValue("", Validators.required);
	}

	removeRow(paymentType: PaymentTypeName, index: number) {
		this.getCapabilitiesFormGroupArray(paymentType).removeAt(index);
	}

	getCapabilitiesFormGroupArray(paymentType: PaymentTypeName) {
		return this.capabilitiesForm.get(`${paymentType}.payoutAccountRoutes`) as FormArray;
	}

	onFormChanges() {
		this.capabilitiesForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((data: any) => {
			//tell add pages that the form has changed
			this.serviceProviderCountryCreateService.formChanges();
			// Initial value (only it will change if there is an invalid subform)
			let isValid: boolean = true;

			// Get status of form
			if (data && typeof data === "object") {
				let anySelected: boolean = false;

				Object.keys(data).map(key => {
					const paymentType: {
						paymentType: string;
						selected: boolean;
						payoutAccountRoutes: {
							payoutAccount: PayoutAccountName;
							route: PaymentRouteName;
							eta: string;
						}[];
					} = data[key];

					if (paymentType.selected) {
						anySelected = true;
						const anyPayoutAccountInvalid: boolean = paymentType.payoutAccountRoutes.some(
							p => p.payoutAccount === null
						);
						const anyRouteInvalid: boolean = paymentType.payoutAccountRoutes.some(p => p.route === null);
						const etaInvalid: boolean = paymentType.payoutAccountRoutes.some(p => p.eta === "");

						if (anyPayoutAccountInvalid || anyRouteInvalid || etaInvalid) {
							isValid = false;
						}
					}
				});

				// If there are not any paymentType selected de form should be considered invalid
				isValid = !anySelected ? false : isValid;
			}

			// Set status of form
			this.serviceProviderCountryCreateService.updateCapabilitiesFormState(isValid);
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
