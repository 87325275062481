import { Observable, Observer } from "rxjs";

// Define a function to get the primary color of an image as an Observable
export const getPrimaryColorObservable = (imageSrc: string): Observable<string> => {
	return new Observable((observer: Observer<string>) => {
		const img = new Image();
		img.crossOrigin = "Anonymous";

		img.onload = function () {
			const canvas = document.createElement("canvas");
			const context = canvas.getContext("2d");

			if (!context) {
				observer.error(new Error("Canvas context not supported"));
				return;
			}

			canvas.width = img.width;
			canvas.height = img.height;

			context.drawImage(img, 0, 0);

			const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
			const pixels = imageData.data;

			let transparentPixelCount = 0;
			let coloredPixelCount = 0;

			// Iterate through each pixel
			for (let i = 3; i < pixels.length; i += 4) {
				const alpha = pixels[i];
				if (alpha < 255) {
					transparentPixelCount++;
				} else {
					coloredPixelCount++;
				}
			}

			let primaryColor = "";

			// If there are more transparent pixels than colored pixels, return white
			if (transparentPixelCount > coloredPixelCount) {
				primaryColor = "var(--color-basic-100)";
			} else {
				const colorCounts: { [color: string]: number } = {};

				for (let i = 0; i < pixels.length; i += 4) {
					const r = pixels[i];
					const g = pixels[i + 1];
					const b = pixels[i + 2];
					const color = `rgb(${r},${g},${b})`;

					colorCounts[color] = (colorCounts[color] || 0) + 1;
				}

				let maxCount = 0;
				for (const color in colorCounts) {
					if (colorCounts[color] > maxCount) {
						maxCount = colorCounts[color];
						primaryColor = color;
					}
				}

				if (transparentPixelCount > maxCount) {
					primaryColor = "var(--color-basic-100)";
				}
			}

			observer.next(primaryColor);
			observer.complete();
		};

		img.onerror = function () {
			observer.error(new Error("Failed to load image"));
		};

		img.src = imageSrc;
	});
};
