import { Injectable } from "@angular/core";
import { Customer } from "../../../../shared/models/customer.interface";
import { SelectOption } from "../../../../shared/models/select-option.interface";
import { formatDataForSelect } from "../../../../shared/utils/format-select-data.util";
import { createCustomerLogoPath } from "../../../../shared/utils/customer-logo-path.util";
import { CommonLegalEntityService } from "../common/common-legal-entity.service";

@Injectable()
export class SetupCustomersService {
	constructor(private commonLegalEntityService: CommonLegalEntityService) {}

	setupCustomersForSelect(): SelectOption[] {
		// Set the properly logo path
		const customers = this.commonLegalEntityService.customers.map((customer: Customer) =>
			createCustomerLogoPath(customer)
		);

		//Create select options from customer data
		return customers.map((customer: Customer) => {
			const option: SelectOption = {
				value: customer?.id,
				text: customer?.externalId + " - " + customer?.name,
				imagePath: customer?.imagePath
			};

			return formatDataForSelect(option);
		});
	}
}
