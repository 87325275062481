import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { select, Store } from "@ngrx/store";
import { Subject, Subscription } from "rxjs";
import { Location } from "@angular/common";
import { takeUntil } from "rxjs/operators";

import { Employee } from "../../../../../shared/models/employee.interface";
import { PayGroup } from "../../../../../shared/models/pay-groups";
import { getCustomerEntityGroupState } from "../../../../../store";
import { AppState } from "../../../../../store/models/state.model";
import { PayElectiveCommonService } from "../../../services/pay-elective-common.service";
import { PermissionsService } from "../../../../../shared/services/permissions/permissions.service";
import { BreadcrumbService } from "@shared/components/breadcrumbs/services/breadcrumb.service";

@Component({
	selector: "app-pay-elective-employee",
	templateUrl: "./pay-elective-employee.component.html",
	styleUrls: ["./pay-elective-employee.component.scss"]
})
export class PayElectiveEmployeeComponent implements OnInit, OnDestroy {
	form!: FormGroup;

	@Output() employeeCreated: EventEmitter<string> = new EventEmitter<string>();
	@Output() employeeNameAltered: EventEmitter<string> = new EventEmitter<string>();

	@Input() payGroup!: PayGroup;
	@Input() _employee!: Employee;

	@Input() set employee(val: Employee) {
		if (val) {
			this._employee = val;
			this.isEdit = true;
			this.initForm();
		}
	}

	customerId: string = "";
	legalEntityId: string = "";

	isEdit: boolean = false;

	entityPayGroupSelection: Subscription = new Subscription();
	destroy$: Subject<void> = new Subject();

	firstNameInput: string = "";
	lastNameInput: string = "";
	isDisabled = false;

	constructor(
		private fromBuilder: FormBuilder,
		private payElectiveCommonService: PayElectiveCommonService,
		private store: Store<AppState>,
		private readonly _location: Location,
		private permissionsService: PermissionsService,
		private breadCrumbService: BreadcrumbService
	) {}

	ngOnInit(): void {
		this.store.pipe(takeUntil(this.destroy$), select(getCustomerEntityGroupState)).subscribe(state => {
			this.customerId = state.customerId;
			this.legalEntityId = state.legalEntityId;
		});
		if (this._employee) {
			this.isEdit = true;
			this.permissionsService.canEditEmployee().subscribe(enable => (this.isDisabled = !enable));
		} else {
			this.permissionsService.canCreateEmployee().subscribe(enable => (this.isDisabled = !enable));
		}

		this.initForm();
		this.initSubscriptions();
	}

	initForm(): void {
		this.form = this.fromBuilder.group({
			externalId: [
				{ value: "", disabled: this.isDisabled },
				this.isDisabled ? null : [Validators.required, Validators.minLength(1)]
			],
			firstName: [
				{ value: "", disabled: this.isDisabled },
				this.isDisabled
					? null
					: [
							Validators.required,
							Validators.minLength(1),
							Validators.maxLength(100),
							Validators.pattern(/^[a-zA-Z0-9À-ÖØ-öø-ÿ'-\s]+$/)
					  ]
			],
			lastName: [
				{ value: "", disabled: this.isDisabled },
				this.isDisabled
					? null
					: [
							Validators.required,
							Validators.minLength(1),
							Validators.maxLength(100),
							Validators.pattern(/^[a-zA-Z0-9À-ÖØ-öø-ÿ'-\s]+$/)
					  ]
			],
			enityReference: [{ value: this.payGroup.legalEntity.data.externalId, disabled: this.isDisabled }],
			payGroupExternalId: [{ value: this.payGroup.externalId, disabled: this.isDisabled }],
			payGroupId: [{ value: this.payGroup.id, disabled: this.isDisabled }]
		});

		if (this.isEdit) {
			this.form.get("externalId")?.patchValue(this._employee.externalId);
			this.form.get("firstName")?.patchValue(this._employee.data.firstName);
			this.form.get("lastName")?.patchValue(this._employee.data.lastName);

			this.firstNameInput = this._employee.data.firstName;
			this.lastNameInput = this._employee.data.lastName;

			this.form.addControl("id", this.fromBuilder.control(this._employee.id));
			this.form.addControl("version", this.fromBuilder.control(this._employee.version));
		}

		this.form
			.get("firstName")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe(input => {
				this.firstNameInput = input;
				this.employeeNameAltered.emit(this.firstNameInput + " " + this.lastNameInput);
			});

		this.form
			.get("lastName")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe(input => {
				this.lastNameInput = input;
				this.employeeNameAltered.emit(this.firstNameInput + " " + this.lastNameInput);
			});
	}

	cancel(): void {
		this._location.historyGo(-1);
	}

	onChangePayGroup(): void {
		this.payElectiveCommonService.openDialog(
			this.customerId,
			this.legalEntityId,
			this.payGroup,
			this._employee?.id!
		);
	}

	storePayElective(): void {
		this.payElectiveCommonService.storePayElective(this.form.getRawValue(), this.isEdit);
		this.employeeCreated.emit();
	}

	initSubscriptions(): void {
		this.entityPayGroupSelection = this.payElectiveCommonService.entityPayGroupSelection$
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => {
				if (res) {
					this.form.get("enityReference")?.patchValue(res.legalEntity.data.externalId);
					this.form.get("payGroupExternalId")?.patchValue(res.externalId);
					this.form.get("payGroupId")?.patchValue(res.id);
				}
			});

		this.breadCrumbService.paygroupUpdated$.pipe(takeUntil(this.destroy$)).subscribe({
			next: res => {
				if (res) {
					this.ngOnInit();
				}
			}
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
