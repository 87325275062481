import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";

import { CommonService } from "@modules/customer-setup/_services/common/common.service";
import { ContactPersonService } from "@shared/services/contact-person/contact-person.service";
import { CurrencyService } from "@shared/services/currency/currency.service";
import { ServiceProviderService } from "@shared/services/service-provider/service-provider.service";
import { ToastService } from "@shared/services/toast/toast.service";
import { AccountField } from "src/app/shared/models/account-field.interface";
import { ContactPerson } from "src/app/shared/models/contact-person.interface";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import {
	ServiceProviderFullObject,
	ServiceProviderObject
} from "src/app/shared/models/service-provider-types.interface";
import { PermissionsService } from "../../../../shared/services/permissions/permissions.service";
import { EditServiceProviderService } from "../../_services/edit-service-provider/edit-service-provider.service";

@Component({
	selector: "app-service-provider-edit",
	templateUrl: "./service-provider-edit.component.html",
	styleUrls: ["./service-provider-edit.component.scss"]
})
export class ServiceProviderEditComponent implements OnInit {
	@Input() serviceProvider!: ServiceProviderObject;
	@Input() contactPersonData: ContactPerson[] = [];

	@Output() closeExpansionPanel: EventEmitter<void> = new EventEmitter<void>();

	availableProvidersOptions$: Observable<SelectOption[]> = new Observable<SelectOption[]>();

	providerSettingsForm!: FormGroup;
	currencyOptions$: Observable<SelectOption[]> = new Observable<SelectOption[]>();
	addContactClicked$: Subject<void> = new Subject<void>();
	serviceProviderCreated$: Subject<boolean> = new Subject<boolean>();
	settlementAccountForm!: FormGroup;
	settletmentAccountFormStatus!: boolean;
	accountFieldArray!: [];

	contactData: ContactPerson[] = [];
	existingContacts!: ContactPerson[];
	contactFormisValid: boolean = true;

	destroy$: Subject<void> = new Subject();

	loading: boolean = false;
	isChanged: boolean = false;
	isSuperUser: boolean = false;

	get accountFields(): Array<AccountField> {
		return this.providerSettingsForm.get("accountFields")!.value as Array<AccountField>;
	}

	get accountField(): FormArray {
		return this.providerSettingsForm.get("accountFields")?.value as FormArray;
	}

	constructor(
		private readonly formBuilder: FormBuilder,
		private readonly currencyService: CurrencyService,
		private readonly serviceProviderService: ServiceProviderService,
		private readonly editServiceProviderService: EditServiceProviderService,
		private readonly toastService: ToastService,
		private commonService: CommonService,
		private contactPersonService: ContactPersonService,
		private permissions: PermissionsService
	) {}

	ngOnInit(): void {
		this.permissions
			.canEditServiceProviders()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => {
				this.isSuperUser = res;
				this.loadProviderSetup();
			});
	}
	loadProviderSetup(): void {
		this.currencyOptions$ = this.currencyService.getCurrencyOptions(true);
		this.initForm();
		this.getProviderDetails();
		this.getContactPersons(this.serviceProvider.id);
		this.availableProvidersOptions$ = this.serviceProviderService.getProvidersAsSelectOptions();
	}

	initForm(): void {
		this.providerSettingsForm = this.formBuilder.group({
			id: [this.serviceProvider.id],
			version: [],
			fullName: [{ value: "", disabled: true }],
			name: [{ value: "", disabled: true }, Validators.required],
			currencies: [[], Validators.required],
			accountFields: [[]],
			logoId: []
		});

		this.providerSettingsForm
			.get("name")
			?.valueChanges.pipe()
			.subscribe(val => {
				this.availableProvidersOptions$.pipe(take(1)).subscribe(options => {
					console.log("options", options);
					console.log("selected value", val);

					options.filter(option =>
						option.value === val
							? this.providerSettingsForm.get("fullName")?.patchValue(option.text)
							: void 0
					);
				});
			});

		if (!this.isSuperUser) {
			this.providerSettingsForm.disable();
		}
	}

	getProviderDetails(): void {
		this.serviceProviderService
			.getServiceProviderById(this.serviceProvider.id)
			.subscribe(data => this.updateServiceProviderForm(data));
	}

	updateServiceProviderForm(data: ServiceProviderFullObject): void {
		this.providerSettingsForm.get("version")?.setValue(data.version, { emitEvent: false });
		this.providerSettingsForm.get("name")?.setValue(data.data.name, { emitEvent: false });
		this.providerSettingsForm.get("fullName")?.setValue(data.data.fullName, { emitEvent: false });
		this.providerSettingsForm.get("currencies")?.setValue(data.data.currencies, { emitEvent: false });

		this.providerSettingsForm.get("accountFields")?.setValue(data.data.accountFields, { emitEvent: false });
		this.providerSettingsForm.get("logoId")?.setValue(data.logoId, { emitEvent: false });
	}

	onRemoveField(key: string): void {
		let current: AccountField[] = this.providerSettingsForm.get("accountFields")?.value;

		current = current.filter(account => account.key !== key);

		this.providerSettingsForm.get("accountFields")?.patchValue([...current]);
	}

	onAddContactClicked(): void {
		this.addContactClicked$.next();
	}

	cancelCreate(): void {
		this.closeExpansionPanel.emit();
		this.loadProviderSetup();
	}

	updateProvider(): void {
		this.loading = true;
		//event-emitter - clean - form.reset()
		let joinedAccountArray = this.settlementAccountForm.get("accountFields")?.value.concat(this.accountField);

		this.providerSettingsForm.get("accountFields")?.patchValue(joinedAccountArray);

		const formData = this.providerSettingsForm.getRawValue();
		const serviceProvider = this.editServiceProviderService.getServiceProviderUpdateDTO(formData);

		this.serviceProviderService.updateServiceProvider(serviceProvider).subscribe(data => {
			let contactPersonObject = this.commonService.contactPersonObject(data.id, this.contactData);
			this.contactPersonService.addContactPerson(contactPersonObject).subscribe(result => {
				console.log(result);
			});
			this.updateServiceProviderForm(data);
			this.providerSettingsForm.markAsPristine();
			this.providerSettingsForm.markAsUntouched();
			this.settlementAccountForm.reset();

			this.toastService.showSuccess("Service Provider Has Been Updated Successfully");
			this.closeExpansionPanel.emit();
		});
	}

	getContactPersons(providerId: string): void {
		this.contactPersonService
			.getContactPersons(providerId)
			.pipe(takeUntil(this.destroy$))
			.subscribe(result => {
				this.existingContacts = result;
			});
	}

	contactFormData(event: { data: ContactPerson[]; valid: boolean }): void {
		this.providerSettingsForm.markAsDirty();
		this.contactFormisValid = event.valid;
		this.contactData = event.data;
	}

	onInputChange(isChanged: boolean): void {
		this.providerSettingsForm.markAsDirty();
		this.isChanged = isChanged;
	}

	settlementAccount(settlementAccountForm: FormGroup): void {
		this.settlementAccountForm = settlementAccountForm;
		this.settletmentAccountFormStatus = settlementAccountForm.invalid;
		this.providerSettingsForm.markAsDirty();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
