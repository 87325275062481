import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
	PayElectivePayGroupBeneficiaryPagination,
	PayElectivePayGroupPagination
} from "src/app/shared/models/employee.interface";
import { environment } from "../../../../environments/environment";
import { Pagination } from "../../models/pagination.interface";

@Injectable({
	providedIn: "root"
})
export class ReportsService {
	constructor(private http: HttpClient) {}

	getG2NErrorsByPayGroupId(payGroupId: string): Observable<Pagination> {
		return this.http.get<Pagination>(
			`${environment.apiUri}/v1/uploading/pay-groups/reports?topics=EMPLOYEES&payGroupIds=${payGroupId}&pageSize=-1`
		);
	}

	getTransactionsErrorsByPayGroupId(
		customerId: string,
		legalEntityId: string,
		payGroupId: string,
		employeeId: string,
		rangeStart: string,
		rangeEnd: string
	): Observable<Pagination> {
		return this.http.get<Pagination>(
			`${environment.apiUri}/v1/transactional/report?customerIds=${customerId}&legalEntityIds=${legalEntityId}&payGroupIds=${payGroupId}&employeeIds=${employeeId}&rangeStart=${rangeStart}&rangeEnd=${rangeEnd}`
		);
	}

	getBeneficiariesReports(
		payGroupIds: string,
		customerIds: string
	): Observable<PayElectivePayGroupBeneficiaryPagination> {
		let params = new HttpParams();

		params = params.append("payGroupIds", payGroupIds);
		params = params.append("customerIds", customerIds);

		return this.http.get<PayElectivePayGroupBeneficiaryPagination>(
			`${environment.apiUri}/v3/employees/reports/beneficiaries`,
			{ params }
		);
	}
}
