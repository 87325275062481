import { MenuService } from "@modules/config/_services/menu/menu.service";
import { breadcrumbDataObject } from "./_models/breadcrumb.interface";
import { Location } from "@angular/common";
import { debounceTime, distinctUntilChanged, filter, takeUntil } from "rxjs/operators";
import { Observable, of, Subject, Subscription } from "rxjs";
import { DropdownService } from "./_services/dropdown.service";
import { BreadcrumbsService } from "@shared/services/breadcrumbs/breadcrumbs.service";
import { format } from "date-fns";
import { MediaChange, MediaObserver } from "ngx-flexible-layout";
import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild
} from "@angular/core";
import { Router } from "@angular/router";

class HeightAndWidth {
	height: number = 0;
	width: number = 0;
}

@Component({
	selector: "modal-wrapper",
	templateUrl: "./modal-wrapper.component.html",
	styleUrls: ["./modal-wrapper.component.scss"],
	host: {
		"[class.menu-not-expanded]": "!menuService.expanded",
		"[class.menu-not-visible]": "!menuService.visible"
	}
})
export class ModalWrapperComponent implements OnInit, OnDestroy, AfterViewInit {
	//Overall Modal styling
	@Input() modalStyle: string = "light";
	@Input() noBreadCrumbs = false;
	@Input() subHeaderColour: string = "white";
	@Input() modalheaderPreferredHeight: string = "0rem";
	@Input() noModalPadding = false;
	@Input() noModalMargin = false;

	//Specific input for dark modal
	@Input() exitIconBool = false;
	@Input() titleRows: number = 1;

	//Button next to page title - defualt back button
	@Input() titleButtonType: string = "arrow-left";
	@Input() addTitleButton = true;

	//Title
	@Input() title: string = "";
	@Input() subTitle: string = "";

	//Date
	@Input() displayDate = false;

	//Breadcrumb
	displayBreadcrumbCount: number = 0; //number of crumbs to display
	breadcrumbList: string = ""; //Mat tooltip
	showAllCrumbs = false; //show more than 3 crumbs at a time
	allBreadcrumb$: Observable<breadcrumbDataObject[]> = of([]); //Observable of crumbs

	@Input() set breadcrumb(val: breadcrumbDataObject[]) {
		if (val.length) {
			this.setBreadcrumbs(val);
		}
	}

	@Input() set dynamicallyHideSubHeader(hide: boolean) {
		this.hideSubHeader = hide;
	}

	hideSubHeader = false;

	//Add flag to breadcrumb list
	@Input() addFlag!: string;
	@Input() flagPosition: number = 1;

	//Button next to breadcrumbs
	@Input() addButton = false;
	@Input() btnText: string = "";
	@Input() btnClass: string = "";

	//Permission and upload veriables
	@Input() fileUpload = false;
	@Input() hideButton = false;
	canCreateOffCycle = true;

	//ViewChildren
	@ViewChild("breadcrumbs") breadCrumbHeader!: ElementRef;

	//Emitters
	@Output() onTitleButtonAction: EventEmitter<null> = new EventEmitter();

	@Output() onHeaderButtonAction: EventEmitter<null> = new EventEmitter();

	///Extras///

	//When you have a modal wrapper within a modal wrapper
	//Set this veriable to true on both modalwrappers
	@Input() modalFullHeight = false;

	//Hide the entire modal excl subheader
	@Input() hideHeader = false;

	date = format(new Date(), "MMMM dd yyyy");
	marginSize: string = "0px";

	viewPortSizeSubject$: Subject<HeightAndWidth> = new Subject<HeightAndWidth>();
	viewPortSizeObserver$!: Observable<HeightAndWidth>;
	mediaListener$!: Subscription;
	media$!: Observable<MediaChange[]>;
	destroy$: Subject<void> = new Subject<void>();

	constructor(
		public menuService: MenuService,
		private router: Router,
		private readonly _location: Location,
		private dropDownService: DropdownService,
		private breadcrumbService: BreadcrumbsService,
		private mediaObserver: MediaObserver
	) {}

	ngOnInit(): void {
		if (this.modalStyle === "dark") {
			this.menuService.setVisible(false); //hide sidenav
		} else {
			this.menuService.setVisible(true); //show sidenav
		}

		this.viewPortSizeObserver$ = this.viewPortSizeSubject$.pipe(
			takeUntil(this.destroy$),
			debounceTime(50),
			distinctUntilChanged()
		);

		this.media$ = this.mediaObserver.asObservable().pipe(
			filter((changes: MediaChange[]) => true) // silly noop filter
		);

		this.media$.pipe(takeUntil(this.destroy$)).subscribe((changes: MediaChange[]) => {
			changes.forEach((change: MediaChange) => {
				if (change.mqAlias === "cloudpay-laptop") {
					// this.marginSize = "191px";
				} else {
					this.marginSize = "0px";
				}
			});
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	ngAfterViewInit(): void {
		this.viewPortSizeSubject$.next(this.getHeightAndWidth());
	}

	@HostListener("window:resize", ["$event"])
	onResize() {
		this.viewPortSizeSubject$.next(this.getHeightAndWidth());
	}

	closeDropDowns() {
		this.dropDownService.onDropDownClick.emit(false);
	}

	setBreadcrumbs(bread: breadcrumbDataObject[]): void {
		this.breadcrumbList = "";
		this.displayBreadcrumbCount = 0;

		this.allBreadcrumb$ = of(bread);

		if (bread.length) {
			for (let i = 0; i <= bread.length - 1; i++) {
				if (bread[i].display === true && bread[i].crumb) {
					if (i < bread.length - 1) {
						this.breadcrumbList += bread[i].crumb + " > ";
					} else {
						this.breadcrumbList += bread[i].crumb;
					}
					this.displayBreadcrumbCount++;
				}
			}
		}
	}

	rangeCheck(currentIndex: number): boolean {
		if (currentIndex >= this.displayBreadcrumbCount - 3 || this.showAllCrumbs) {
			return true;
		} else {
			return false;
		}
	}

	getFirstToDisplayIndex(): number {
		if (this.showAllCrumbs || this.displayBreadcrumbCount < 4) {
			return 0;
		} else {
			return this.displayBreadcrumbCount - 3;
		}
	}

	flagCheck(i: number): boolean {
		if (i === this.flagPosition && this.addFlag) {
			if (this.displayBreadcrumbCount < 4 || this.showAllCrumbs) {
				return true;
			}

			return false;
		} else {
			return false;
		}
	}

	headerAction(): void {
		this.onTitleButtonAction.emit();
	}

	headerButtonAction(): void {
		this.onHeaderButtonAction.emit();
	}

	headerHomeAction(): void {
		this.router.navigate(["/payments"]);
	}

	isNumber(str: string): boolean {
		if (str.trim() === "") {
			return false;
		}

		return !Number.isNaN(Number(str));
	}

	goback(crumb: breadcrumbDataObject): void {
		if (crumb.link && crumb.link != null && !crumb.state) {
			if (this.isNumber(crumb.link)) {
				this._location.historyGo(-crumb.link);
			} else if (crumb.link === "master-data") {
				this.router.navigate(["/employee-data/paygroups"]);
			} else {
				this.router.navigate([crumb.link]);
			}
		}

		if (crumb.link && crumb.link != null && !this.isNumber(crumb.link) && crumb.state) {
			const stateName: string = crumb.state[0];
			const stateValue: string = crumb.state[1];

			this.router.navigate([crumb.link], { state: { [stateName]: stateValue } });
		}

		if (crumb.emit) {
			this.breadcrumbService.crumbClicked.emit(crumb.emit);
		}
	}

	showAll(): void {
		this.showAllCrumbs = !this.showAllCrumbs;
	}

	private getHeightAndWidth(): HeightAndWidth {
		const newValues = new HeightAndWidth();
		if (this.breadCrumbHeader) {
			newValues.height = this.breadCrumbHeader.nativeElement.offsetHeight;
			newValues.width = this.breadCrumbHeader.nativeElement.offsetWidth;
		}
		return newValues;
	}
}
