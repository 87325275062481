import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { Employee } from "src/app/shared/models/employee.interface";
import { PayCycle } from "src/app/shared/models/pay-cycle.interface";
import { PayGroup } from "src/app/shared/models/pay-groups";
import { Payout } from "src/app/shared/models/payouts.types";
import { Store } from "@ngrx/store";

import { AppState } from "src/app/store/models/state.model";
import { PayElectiveTransactionsService } from "../../../services/pay-elective-transactions.service";
import { ToastService } from "@shared/services/toast/toast.service";
import {
	PayCyclePageError as PayCyclePageError,
	PayCycleYearPageable,
	isPayCycleYearPageable,
	isPayCylcePageError
} from "../../../services/facade/paycycle/model/PayCycleYearPageable";
import { takeUntil } from "rxjs/operators";

@Component({
	selector: "app-pay-elective-transaction",
	templateUrl: "./pay-elective-transaction.component.html",
	styleUrls: ["./pay-elective-transaction.component.scss"]
})
export class PayElectiveTransactionComponent implements OnInit, OnDestroy {
	@Input() payGroup!: PayGroup;
	@Input() routerStatePayCycleId!: string;

	@Input() set selectedEmployee(val: Employee) {
		this.employee = val;
		if (this.routerStatePayCycleId || this.routerStatePayCycleId === "") {
			this.initalizeComponent();
		}
	}

	isComponentLoading: boolean = true;
	employeeId!: string;
	selectedYear: Date = new Date();
	payCycleYearPageable!: PayCycleYearPageable;
	selectedPayCycle!: PayCycle;
	currentSelectedPayCycleIndex: number = 0;
	payouts!: Payout[];
	noPayCyclesForPayGroup: boolean = false;
	employee!: Employee;

	private destroy$: Subject<void> = new Subject<void>();

	constructor(private transactionService: PayElectiveTransactionsService, private toastService: ToastService) {}
	ngOnInit(): void {
		this.initalizeComponent();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	previousYear() {
		if (this.payCycleYearPageable.hasPreviousYear) {
			this.selectedYear = new Date(this.selectedYear.getFullYear() - 1, this.selectedYear.getMonth(), 1);
			this.isComponentLoading = true;

			this.transactionService
				.getPayCycleForYear(this.payGroup.id, this.selectedYear.getFullYear())
				.pipe(takeUntil(this.destroy$))
				.subscribe((result: PayCycleYearPageable | PayCyclePageError) => {
					this.handleResult(result);
				});
		}
	}

	nextYear() {
		this.selectedYear = new Date(this.selectedYear.getFullYear() + 1, this.selectedYear.getMonth(), 1);
		this.isComponentLoading = true;

		this.transactionService
			.getPayCycleForYear(this.payGroup.id, this.selectedYear.getFullYear())
			.pipe(takeUntil(this.destroy$))
			.subscribe((result: PayCycleYearPageable | PayCyclePageError) => {
				this.handleResult(result);
			});
	}

	nextPayCycle() {
		if (this.hasNextPayCycle()) {
			var payCyclesForSelectedYear = this.payCycleYearPageable.payCycles;

			this.currentSelectedPayCycleIndex = this.currentSelectedPayCycleIndex + 1;
			this.selectedPayCycle = payCyclesForSelectedYear[this.currentSelectedPayCycleIndex];

			this.updatePayOutsForSelectedPayCycle();
		}
	}

	hasNextPayCycle() {
		return this.currentSelectedPayCycleIndex + 1 < this.payCycleYearPageable.payCycles.length;
	}

	previousPayCylce() {
		if (this.hasPreviousPayCycle()) {
			var payCyclesForSelectedYear = this.payCycleYearPageable.payCycles;

			this.currentSelectedPayCycleIndex = this.currentSelectedPayCycleIndex - 1;
			this.selectedPayCycle = payCyclesForSelectedYear[this.currentSelectedPayCycleIndex];

			this.updatePayOutsForSelectedPayCycle();
		}
	}

	hasPreviousPayCycle() {
		return this.currentSelectedPayCycleIndex > 0;
	}

	private initalizeComponent() {
		if (!this.employee.id) {
			this.toastService.showError(
				"Unable to display transactions for this employee. Something unexpected happened. (Missing employee id input)"
			);
			return;
		}
		if (!this.payGroup) {
			this.toastService.showError(
				"Unable to display transactions for this employee. Something unexpected happened. (Missing paygroup input)"
			);
			return;
		}

		this.employeeId = this.employee.id;
		this.updatePayCyclesGivenRouterHistory();
	}

	private updatePayCyclesGivenRouterHistory() {
		if (this.routerStatePayCycleId && this.routerStatePayCycleId !== "") {
			this.transactionService
				.fromPayCylce(this.routerStatePayCycleId, this.payGroup.id)
				.subscribe((result: PayCycleYearPageable | PayCyclePageError) => {
					this.handleResult(result);
				});
		} else {
			this.transactionService
				.getActivePayCycle(this.payGroup.id)
				.subscribe((result: PayCycleYearPageable | PayCyclePageError) => {
					this.handleResult(result);
				});
		}
	}

	private handleResult(result: PayCycleYearPageable | PayCyclePageError) {
		this.isComponentLoading = false;

		if (isPayCylcePageError(result)) {
			var error = result as PayCyclePageError;
			if (error.message !== "This Pay Group is missing a calendar.") {
				this.toastService.showError(error.message);
			}

			this.noPayCyclesForPayGroup = true;
		} else if (isPayCycleYearPageable(result)) {
			var pageable = result as PayCycleYearPageable;
			this.mapPageableToComponentFields(pageable);
			this.updatePayOutsForSelectedPayCycle();
		}
	}

	private mapPageableToComponentFields(result: PayCycleYearPageable) {
		this.selectedPayCycle = result.filteredPayCycle.cycle;
		this.currentSelectedPayCycleIndex = result.filteredPayCycle.index;
		this.payCycleYearPageable = result;
		var yearNumber = result.year;
		this.selectedYear = new Date(yearNumber, 0, 1);
	}

	private updatePayOutsForSelectedPayCycle() {
		this.transactionService
			.getPayoutForEmployee(this.payGroup.id, this.selectedPayCycle.id, this.employeeId)
			.pipe(takeUntil(this.destroy$))
			.subscribe(payouts => (this.payouts = payouts));
	}
}
