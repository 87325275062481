import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PayGroup } from "@shared/models/pay-groups";
import { convertMilestoneDateToUTC, mergeDateAndTime } from "@shared/utils/date.util";
import { MilestonesService } from "@shared/services/milestones/milestones.service";
import { ToastService } from "@shared/services/toast/toast.service";
import { WEEKEND_OFFSETS } from "src/app/shared/constants/weekend-offset-options";
import {
	MilestoneGroup,
	MilestoneUpdate,
	MilestoneUpdateDTO,
	PayCycle
} from "src/app/shared/models/pay-cycle.interface";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { WEEKEND_OPTIONS } from "../../_constants/weekend-options.constants";
import { Subject, takeUntil } from "rxjs";

@Component({
	selector: "payment-calendar-milestone-creation",
	templateUrl: "./payment-calendar-milestone-creation.component.html",
	styleUrls: ["./payment-calendar-milestone-creation.component.scss"]
})
export class PaymentCalendarMilestoneCreationComponent implements OnInit, OnDestroy {
	eventForm: FormGroup | undefined;
	weekendOptions: SelectOption[] = WEEKEND_OPTIONS as SelectOption[];
	exit: boolean = false;
	payDate: Date = new Date();
	milestonesToCreate: MilestoneUpdateDTO = { milestones: [] };

	weekendOffets: string[] = WEEKEND_OFFSETS;
	private destroy$: Subject<void> = new Subject();
	_date!: Date;

	@Input() set date(val: Date) {
		if (val) {
			this.payDate = new Date(val.toISOString());
			this._date = val;
			const date: string = this.refactorDate(val);
			this.eventForm?.controls.displayDate.patchValue(date);
		}
	}

	@Input() milestoneGroup!: MilestoneGroup;
	@Input() payGroup!: PayGroup;
	@Input() payCycle: PayCycle | undefined;

	@Output() onClose: EventEmitter<{ reload: boolean }> = new EventEmitter<{ reload: boolean }>();

	constructor(
		private formBuilder: FormBuilder,
		private milestonesService: MilestonesService,
		private toastService: ToastService
	) {}

	ngOnInit(): void {
		this.initForm();
	}

	initForm(): void {
		const date: string = this.refactorDate(this._date);

		this.eventForm = this.formBuilder.group({
			displayDate: [{ value: date, disabled: true }],
			time: ["00:00", [Validators.required]]
			// offset: ["NEXT", [Validators.required]]
		});

		this.eventForm
			.get("time")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe(time => (this._date = mergeDateAndTime(new Date(date), time)));
	}

	refactorDate(date: Date): string {
		const weekday: string = date.toLocaleDateString("US", { weekday: "long" });
		const month: string = date.toLocaleDateString("US", { month: "long" });
		const day: string = date.toLocaleDateString("US", { day: "numeric" });
		const year: string = date.toLocaleDateString("US", { year: "numeric" });

		return this.capitalize(weekday) + ", " + this.capitalize(month) + " " + day + ", " + year;
	}

	capitalize(sentence: string): string {
		return sentence[0].toUpperCase() + sentence.substring(1).toLowerCase();
	}

	resetAll() {
		this.eventForm?.controls.time.patchValue("01:00");
	}

	cancel(reload: boolean) {
		this.exit = true;
		setTimeout(() => {
			this.onClose.emit({ reload: reload });
		}, 655); // like in css
	}

	save() {
		if (this._date && this.payCycle) {
			const milestoneDate = convertMilestoneDateToUTC(this._date, this.payGroup.legalEntity.data.timeZone);

			if (this.milestoneGroup.type === "TPP") {
				const paymentMilestone: MilestoneUpdate = {
					payCycleId: this.payCycle.id,
					payGroupId: this.payCycle.payGroupId,
					type: "PAYMENT",
					group: this.milestoneGroup.group,
					date: milestoneDate,
					status: "PENDING",
					params: []
				};

				const FundingMilestone: MilestoneUpdate = {
					payCycleId: this.payCycle.id,
					payGroupId: this.payCycle.payGroupId,
					type: "FUNDING_DATE",
					group: this.milestoneGroup.group,
					date: this.setWorkDay(milestoneDate, "FUNDING"),
					status: "PENDING",
					params: []
				};

				const dataInputMilestone: MilestoneUpdate = {
					payCycleId: this.payCycle.id,
					payGroupId: this.payCycle.payGroupId,
					type: "DATA_INPUT",
					group: this.milestoneGroup.group,
					date: this.setWorkDay(milestoneDate, "G2N"),
					status: "PENDING",
					params: []
				};

				this.milestonesToCreate.milestones.push(dataInputMilestone);
				this.milestonesToCreate.milestones.push(FundingMilestone);
				this.milestonesToCreate.milestones.push(paymentMilestone);
			} else {
				const NetMilestone: MilestoneUpdate = {
					payCycleId: this.payCycle.id,
					payGroupId: this.payCycle.payGroupId,
					type: "NETS",
					group: this.milestoneGroup.group,
					date: milestoneDate,
					status: "PENDING",
					params: []
				};

				const FundingMilestone: MilestoneUpdate = {
					payCycleId: this.payCycle.id,
					payGroupId: this.payCycle.payGroupId,
					type: "FUNDING_DATE",
					group: this.milestoneGroup.group,
					date: this.setWorkDay(milestoneDate, "FUNDING"),
					status: "PENDING",
					params: []
				};

				const G2NMilestone: MilestoneUpdate = {
					payCycleId: this.payCycle.id,
					payGroupId: this.payCycle.payGroupId,
					type: "G2N_FILE",
					group: this.milestoneGroup.group,
					date: this.setWorkDay(milestoneDate, "G2N"),
					status: "PENDING",
					params: []
				};

				this.milestonesToCreate.milestones.push(G2NMilestone);
				this.milestonesToCreate.milestones.push(FundingMilestone);
				this.milestonesToCreate.milestones.push(NetMilestone);
			}

			this.milestonesService.createMilestones(this.milestonesToCreate).subscribe({
				next: _ => {
					this.toastService.showSuccess("Milestone created");
					this.cancel(true);
				},
				error: _ => this.toastService.showError("Milestone creation failed")
			});
		}
	}

	setWorkDay(initDate: string, type: "FUNDING" | "G2N") {
		const date: Date = new Date(initDate);
		const dayOfWeek: number = date.getUTCDay();
		let dayNumber: number = date.getUTCDate();

		switch (dayOfWeek) {
			case 3: // Wednesday
				dayNumber = this.setDate(date, type, 6, 8);
				date.setUTCDate(dayNumber);

				break;
			case 4: // Thursday
				dayNumber = this.setDate(date, type, 6, 8);
				date.setUTCDate(dayNumber);

				break;
			case 5: // Friday
				dayNumber = this.setDate(date, type, 4, 8);
				date.setUTCDate(dayNumber);

				break;
			case 6: // Saturday
				dayNumber = this.setDate(date, type, 4, 8);
				date.setUTCDate(dayNumber);

				break;
			default:
				// Other days
				dayNumber = this.setDate(date, type, 4, 6);
				date.setUTCDate(dayNumber);

				break;
		}

		return date.toISOString();
	}

	setDate(date: Date, type: "FUNDING" | "G2N", fundingDays: number, g2nDays: number): number {
		if (type === "FUNDING") {
			return date.getUTCDate() - fundingDays;
		} else {
			return date.getUTCDate() - g2nDays;
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
