<app-breadcrumbs
	*ngIf="!isFromGlobalDashboard"
	[headerTitle]="'EMPLOYEE DATA'"
	[buttonText]="canCreateEmployee ? 'ADD NEW EMPLOYEE' : ''"
	(onHeaderButtonAction)="createPayElective()"
	[addResponsiveness]="true"
	[buttonIconObj]="breadCrumbIcon"
	[addOverviewTitle]="canSeeMap"
/>

<app-breadcrumbs
	*ngIf="isFromGlobalDashboard && (payGroup$ | async) as paygroup"
	[headerTitleWithFlag]="[paygroup.customer.name, paygroup.legalEntity.data.country, paygroup.data.name]"
	[buttonText]="canCreateEmployee ? 'ADD NEW EMPLOYEE' : ''"
	[addOverviewTitle]="true"
	[externalCrumbs]="externalCrumbs"
	(onHeaderButtonAction)="createPayElective()"
	[buttonIconObj]="breadCrumbIcon"
	[addOverviewTitle]="canSeeMap"
/>

<div class="main-container">
	<div class="pe-subHeader">
		<app-pay-elective-list-search
			[selectedProvider]="provider"
			[providersAccountErrors]="providersAccountErrors"
			(searchChanges)="getList($event)"
			(filterChanges)="getFilteredList($event)"
			[counts]="counts"
			[hasMissingCardPreSelected]="hasCard"
			[hasMissingBankPreSelected]="hasBank"
			[employeeReportCounts]="employeeReportCounts"
			[totalEmployees]="totalEmployees"
			[selectedPayGroupId]="selectedPayGroupId"
			(providerChanged)="providerChanged($event)"
			(filtersChanged)="filtersChanged($event)"
			[_provivers$]="providers$"
			[defaultStatus]="defaultStatus"
			(refreshEmployeeList)="
				getCounts(this.selectedCustomerId, this.selectedLegalEntityId, this.selectedPayGroupId, true)
			"
		></app-pay-elective-list-search>
	</div>
	<div class="flex flex-column">
		<div class="list-container" *ngIf="employees$ | async as payElectives">
			<!-- If there are items -->
			<ng-container *ngIf="payElectives.length; else noItems">
				<app-pay-elective-list-item
					*ngFor="let payElective of payElectives; let ind = index"
					[payElectiveValue]="payElective"
					[providerForPayElective]="provider"
					[employeesDropDownData]="employeesDropDownData"
					[isFromGlobalDashboard]="isFromGlobalDashboard"
					[zIndex]="payElectives.length - ind + 2"
				></app-pay-elective-list-item>
			</ng-container>
			<div class="pagination-container" [ngStyle]="{ 'z-index': payElectives.length + 2 }">
				<app-pagination
					*ngIf="totalPageCount"
					[length]="totalPageCount"
					[pageSizeOptions]="[5, 10, 25, 100]"
					[pageSize]="pageSize"
					[pageIndex]="pageIndex"
					[paginationType]="paginationType"
					aria-label="Select page"
				>
				</app-pagination>
			</div>
		</div>

		<ng-template #noItems>
			<no-items-layout message="No items created yet"></no-items-layout>
		</ng-template>
	</div>
</div>
