import { AccountType } from "@modules/employee-data/types/accounts";
import { Employee } from "./employee.interface";
import { CurrencyCode, PaymentRouteName } from "./service-provider.type";
import { MilestoneGroupType } from "./pay-cycle.interface";

export interface Amount {
	value: number;
	currency: CurrencyCode;
}

export interface PayoutStatus {
	name: PayoutStatusName;
	message: string;
}

export enum PayoutStatusName {
	CREATED = "CREATED",
	FAILED = "FAILED",
	SUCCESS = "SUCCESS",
	PENDING = "PENDING",
	PROCESSED = "PROCESSED",
	PROCESSING = "PROCESSING",
	INACTIVE = "INACTIVE",
	CANCELLED = "CANCELLED",
	ERROR = "ERROR",
	FAILED_TO_BOOK = "FAILED_TO_BOOK",
	PAID_TO_FALLBACK = "PAID_TO_FALLBACK",
	DEPENDENT_ERROR = "DEPENDENT_ERROR",
	BOOKED = "BOOKED",
	UNKNOWN = "UNKNOWN"
}

export interface Payout {
	id: string;
	version: number;
	employee: Employee;
	payGroupId: string;
	payCycleId: string;
	amount: Amount;
	status: PayoutStatus;
	createdAt: Date;
	processedAt?: Date;
	executeAt: Date;
	attempts: number;
	transactions?: Transaction[];
}

export interface PayoutPagination {
	totalCount: number;
	totalPages: number;
	pageNumber: number;
	items: Payout[];
}

export interface PayoutData {
	payCycleId: string;
	totalTransactionCount: number;
	failedTransactionCount: number;
	totalHeadCount: number;
	totalPayoutAmount: {
		value: number;
		currency: string;
	};
	totalFailedAmount: {
		value: number;
		currency: string;
	};
	totalFunding: {
		value: number;
		currency: string;
	};
}

export interface TransactionPagination {
	totalCount: number;
	totalPages: number;
	pageNumber: number;
	items: Transaction[];
}
export interface Transaction {
	accountId: string;
	accountType: string;
	amount: Amount;
	attributes: {
		FALLBACK_TRANSACTION_ID?: string;
	};
	attempts: number;
	createdAt: string;
	executeAt: string;
	id: string;
	payMethodId: string;
	paymentProvider: string;
	payoutId: string;
	processedAt: string | null;
	providerId: string;
	settlementAccountId: string;
	status: TransactionStatus;
	type: string;
	version: number;
}

export interface TransactionReportWithPagination {
	totalCount: number;
	totalPages: number;
	pageNumber: number;
	items: TransactionReport[];
}

export interface TransactionReport {
	id: string;
	transactionId: string;
	customerId: string;
	customerExternalId: string;
	legalEntityId: string;
	legalEntityExternalId: string;
	payGroupId: string;
	payGroupExternalId: string;
	payMethodId: string;
	employeeId: string;
	employeeExternalId: string;
	employeeFirstName: string;
	employeeLastName: string;
	providerName: string;
	accountType: AccountType;
	transactionType: MilestoneGroupType;
	status: TransactionStatus;
	createdAt: string;
	createdBy: string;
	route: PaymentRouteName;
}

export interface TransactionStatus {
	name: string;
	message: string;
	details?: Details | null;
	error?: Details | null;
}

export interface Details {
	attributes: Record<string, string | string[]>;
	code: "string";
	message: "string";
}

export interface ProcessButtonDialogRefData {
	processPaymentMenuSelection: string;
	payCycleId: string;
	payGroupId: string;
	isReprocessButton: boolean;
	bankRoute: string;
	cardRoute: string;
	reProcesMapPayoutsAndTxId: Map<string, string[]>;
	isTPP?: boolean;
	allFailedTransaction: Transaction[];
}

export interface InactivateToggleDialogRefData {
	payout: Payout;
	type: string;
}

export interface PayoutProcessingRoute {
	[key: string]: string;
}

export interface MilestoneReport {
	totalTransactionCount: number;
	failedTransactionCount: number;
	totalHeadCount: number;
	totalPayoutAmount: MilestoneReportTotals;
	totalFailedAmount: MilestoneReportTotals;
	totalFundingAmount: MilestoneReportTotals;
}

export interface MilestoneReportTotals {
	value: number;
	currency: string;
}
