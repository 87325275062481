<form [formGroup]="headcountForm">
	<div
		fxLayout="column"
		class="container"
		*ngFor="let type of createdCapabilites$ | async"
		[formGroupName]="type.paymentType"
	>
		<div
			class="config-container"
			formArrayName="config"
			*ngFor="let config of getConfigFormGroupArray(type.paymentType).controls; let i = index"
		>
			<!-- Breadcrumb -->
			<span class="config-header">
				{{ type.paymentType.toString().replace("_", " ") }} > {{ getAccountRoute(type.paymentType, i) }}
			</span>

			<!-- Range form container -->
			<div [formGroupName]="i">
				<div
					class="range-content"
					formArrayName="billingTypes"
					*ngFor="
						let billingType of getBillingTypesFormGroupArray(type.paymentType, i).controls;
						let f = index;
						last as lastRoute
					"
				>
					<!-- Card -->
					<mat-card class="card mat-elevation-z0" fxFlex [formGroupName]="f">
						<mat-card-content fxLayout="row" fxLayoutGap="25px" fxLayoutAlign="space-between center">
							<div fxLayout="column" fxFlex>
								<div fxLayout="row" fxLayoutAlign="space-between center">
									<div fxLayout="row" fxLayoutGap="35px" fxLayoutAlign="space-between center">
										<input-wrapper label="from">
											<input id="from" class="text-input" formControlName="from" type="number" />
										</input-wrapper>
										<input-wrapper label="to">
											<input id="to" class="text-input" formControlName="to" type="number" />
										</input-wrapper>
										<input-wrapper class="checkbox">
											<label class="headcount"
												><input
													id="fixed"
													[checked]="!isFixed(type.paymentType, i, f)"
													type="checkbox"
												/>
												<span class="prior-label">unlimited headcount</span>
											</label>
										</input-wrapper>
									</div>
								</div>
							</div>
						</mat-card-content>
					</mat-card>

					<!-- CTA -->
					<div class="cta-container">
						<span
							id="addRangeBtn"
							*ngIf="lastRoute && this.isSuperUser"
							(click)="addConfig(type.paymentType, i)"
							class="button-config add"
						>
							<app-icon id="add-range-icon" [name]="'add'"></app-icon>
							<span>Add New Range</span>
						</span>

						<span
							id="removeRangeBtn"
							*ngIf="lastRoute && f > 0"
							(click)="removeConfig(type.paymentType, i)"
							class="button-config remove"
						>
							<mat-icon>remove</mat-icon>
							<span>Remove Last Range</span>
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>
