import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatDividerModule } from "@angular/material/divider";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { SharedModule } from "@shared/shared.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatFormFieldModule } from "@angular/material/form-field";
import { BreadcrumbsModalComponent } from "./breadcrumbs-modal.component";

@NgModule({
	declarations: [BreadcrumbsModalComponent],
	imports: [
		CommonModule,
		MatDividerModule,
		MatProgressSpinnerModule,
		SharedModule,
		FormsModule,
		ReactiveFormsModule,
		MatTooltipModule,
		MatFormFieldModule
	],
	exports: [BreadcrumbsModalComponent]
})
export class BreadcrumbsModalModule {}
