import { Injectable } from "@angular/core";
import { ReprocessTPPPayGroupBeneficiaryLinkRequestV1Params } from "./model/request/ReprocessTPPPayGroupBeneficiaryLinkRequestV1Params";
import { environment } from "src/environments/environment";
import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { TppPayGroupBeneficiaryLinkReportV1Params } from "./model/request/TppPayGroupBeneficiaryLinkReportV1Params";
import { ApiTPPBeneficiaryLinkReportV1 } from "./model/response/ApiTPPBeneficiaryLinkReportV1";

@Injectable({
	providedIn: "root"
})
export class TppPayGroupBeneficiaryLinkClientService {
	constructor(private readonly http: HttpClient) {}

	/**
	 * Refresh the beneficiary status for the given beneficiaryId's
	 */
	reprocessTPPPayGroupBeneficiaryLinks(
		request: ReprocessTPPPayGroupBeneficiaryLinkRequestV1Params
	): Observable<HttpResponse<any>> {
		return this.http.post<any>(`${environment.apiUri}/v1/tpp-pay-group-beneficiary-links/reprocess`, request);
	}

	/**
	 *  Get the audit history of uploading this beneficiary to the specified provider.
	 */
	getTppPayGroupBeneficiaryLinkReport(
		request: TppPayGroupBeneficiaryLinkReportV1Params
	): Observable<ApiTPPBeneficiaryLinkReportV1[]> {
		let params = new HttpParams();
		params = params.set("payGroupId", request.payGroupId);
		params = params.set("providerName", request.providerName);
		params = params.set("beneficiaryId", request.beneficiaryId);
		params = params.set("settlementAccountId", request.settlementAccountId);

		return this.http.get<any>(`${environment.apiUri}/v1/tpp-beneficiary-link/report`, { params: params });
	}
}
