import { HttpErrorResponse } from "@angular/common/http";
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	TemplateRef,
	ViewChild,
	ViewContainerRef
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { mergeMap, take } from "rxjs/operators";
import { EMPTY } from "rxjs";

import { DeleteDialogComponent } from "@shared/components/delete-dialog/delete-dialog.component";
import { ServiceProviderService } from "@shared/services/service-provider/service-provider.service";
import { ToastService } from "@shared/services/toast/toast.service";
import { LegalEntity } from "src/app/shared/models/legal-entity.interface";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import {
	Definition,
	Definitions,
	DefinitionsData,
	ProviderCountry,
	ProviderCost
} from "src/app/shared/models/service-definition.interface";
import {
	Capability,
	PaymentProvider,
	PaymentSources,
	ServiceProviderCountryCapability
} from "src/app/shared/models/service-provider-country-types";
import { PaymentTypeCapabilities } from "src/app/shared/models/service-provider.interface";
import { PaymentRouteName, PaymentTypeName, PayoutAccountName } from "src/app/shared/models/service-provider.type";
import { PermissionsService } from "../../../../../shared/services/permissions/permissions.service";
import { PaymentTypePipe } from "../../../pipes/payment-type.pipe";
import { DefinitionsPOST, PaymentTypeDefinitionPOST } from "../../../_models/definition-post.interface";
import { DefinitionsPUT, PaymentTypeDefinitionPUT } from "../../../_models/definition-put.interface";
import {
	DefinitionRoute,
	DefinitionsByType,
	RouteOptions,
	TypeOptions
} from "../../../_models/definitions-by-type.interface";
import { DefinitionsService } from "../../../_services/definitions/definitions.service";
import { UserRole } from "@shared/constants/roles";

@Component({
	selector: "app-type-routes",
	templateUrl: "./type-routes.component.html",
	styleUrls: ["./type-routes.component.scss"],
	providers: [PaymentTypePipe]
})
export class TypeRoutesComponent implements OnInit, AfterViewInit {
	// Inputs
	@Input() legalEntity!: LegalEntity;
	@Input() refreshDefinitions!: Boolean;
	@Input() legalEntityHasPayGroups!: boolean;

	// Outputs
	@Output() paymentTypeDefinitions: EventEmitter<Definitions> = new EventEmitter();
	@Output() selectedPaymentProviders: EventEmitter<ProviderCountry[]> = new EventEmitter();
	@Output() isOriginalObject: EventEmitter<boolean> = new EventEmitter();

	canCreateServiceDefinition: boolean = false;
	canDeleteServiceDefinition: boolean = false;

	@ViewChild("noDefinitions") noDefinitions!: TemplateRef<any>;

	@ViewChild("hasDefinitions") hasDefinitions!: ViewContainerRef;

	// Public variables

	serviceDefExists: boolean = false;
	currentServiceDef!: Definitions;
	isDisabled = false;
	isDisabledForViewOnly = false;

	public definitions!: Definitions;
	public definitionsList: DefinitionsByType[] = [];
	public hasPaymentTypes: boolean = true;
	public objectValidation: boolean = true;
	public duplicatePayoutRoute: number[] = [];
	public duplicatePayoutAccount: number[] = [];
	private sortOrder: string[] = ["NETS", "TAX", "3D_PARTY", "EWA"];
	private paymentTypeCapabilities!: PaymentTypeCapabilities[];

	hasCapabilities: boolean = true;

	constructor(
		private serviceProviderService: ServiceProviderService,
		private toastService: ToastService,
		private dialog: MatDialog,
		private definitionsService: DefinitionsService,
		private changeDetector: ChangeDetectorRef,
		private paymentTypePie: PaymentTypePipe,
		private permissions: PermissionsService
	) {}

	ngOnInit() {
		this.permissions
			.canCreateServiceDefinitions()
			.pipe(take(1))
			.subscribe(res => {
				this.canCreateServiceDefinition = res;
			});

		this.permissions
			.canDeleteServiceDefinitions()
			.pipe(take(1))
			.subscribe(res => {
				this.canDeleteServiceDefinition = res;
			});

		this.permissions
			.disableAccessToEditableFormField([UserRole.TS_VO, UserRole.CLIENT])
			.subscribe(isDisabledForViewOnly => (this.isDisabledForViewOnly = isDisabledForViewOnly));

		this.serviceProviderService.getPaymentProvidersSettings().subscribe((res: PaymentTypeCapabilities[]) => {
			this.paymentTypeCapabilities = res;
		});

		this.serviceProviderService
			.getDefinitionsByEntityId(this.legalEntity.id)
			.pipe(take(1))
			.subscribe(res => {
				this.currentServiceDef = res;

				if (this.currentServiceDef.id) {
					this.serviceDefExists = true;
				} else {
					this.serviceDefExists = false;
				}

				this.definitions = res;
				this.generateTypeList();
			});

		this.permissions
			.disableAccessToEditableFormField([UserRole.TS_IMPLEMENTATION_CONSULTANT, UserRole.TS_OPERATION_CONSULTANT])
			.subscribe(isDisabled => (this.isDisabled = isDisabled));
	}

	ngAfterViewInit(): void {
		if (this.noDefinitions) {
			this.onElseRendered();
		}

		if (this.hasDefinitions) {
			this.onDefinitionsRendered();
		}
	}

	generateTypeList() {
		const list: DefinitionsByType[] = [];
		let paymentSources: PaymentSources[] = [];

		if (this.definitions.paymentTypes.length === 0) {
			this.hasPaymentTypes = false;
		}

		this.definitions.paymentTypes.forEach((type: PaymentTypeName) => {
			let typeDefinitions: DefinitionRoute[] = [];
			this.serviceProviderService
				.getPaymentProviders(this.legalEntity.data.country, type)
				.pipe(take(1))
				.subscribe((paymentProviders: PaymentProvider[]) => {
					paymentSources = [];
					paymentProviders.forEach((paymentProvider: PaymentProvider) => {
						paymentProvider.paymentTypeCapabilities.forEach(
							(paymentCapability: ServiceProviderCountryCapability) => {
								paymentCapability.capabilities.map((element: Capability) => {
									let paymentSource: PaymentSources = {
										paymentType: paymentCapability.paymentType,
										payoutAccount: element.payoutAccount,
										route: element.route,
										provider: paymentProvider.provider.providerCountryId
									};

									paymentSources.push(paymentSource);
								});
							}
						);
					});

					this.definitions.paymentTypeDefinitions.forEach((paymentTypeDefinition: DefinitionsData) => {
						if (paymentTypeDefinition.paymentType === type) {
							typeDefinitions = typeDefinitions.concat(
								paymentTypeDefinition.definitions.filter((definition: Definition) => {
									const paymentSource = paymentSources?.find((source: any) => {
										return (
											source.paymentType === paymentTypeDefinition.paymentType &&
											source.provider === definition.provider?.providerCountryId &&
											source.payoutAccount === definition.payoutAccount &&
											source.route === definition.route
										);
									});

									return !!paymentSource; // If paymentSource has sources, we keep this definition
								}) as unknown as DefinitionRoute[]
							);
						}
					});

					const availableProvidersOptions: SelectOption[] = this.getProviderOptions(paymentProviders);
					const availableTypesOptions: TypeOptions = this.getTypesOptions(paymentProviders);
					const availableRoutesOptions: RouteOptions = this.getRoutesOptions(paymentProviders);

					this.addProviderTypeToDefinition(typeDefinitions, type);

					if ((type as unknown as string) === "NETS") {
						list.push({
							type: type as unknown as string, // Really it is a PaymentTypeName
							definitions: typeDefinitions,
							paymentProviders: paymentProviders,
							availableProvidersOptions: availableProvidersOptions,
							availableTypesOptions: availableTypesOptions,
							availableRoutesOptions: availableRoutesOptions
						} as DefinitionsByType);
					}

					list.sort((a, b) => this.sortOrder.indexOf(a.type) - this.sortOrder.indexOf(b.type));
				});
		});

		this.definitionsList = list;
	}

	addProviderTypeToDefinition(definitions: DefinitionRoute[], paymentType: PaymentTypeName) {
		definitions.forEach((definition: DefinitionRoute) => {
			definition.providerType = this.paymentTypePie.transform(
				definition,
				PaymentTypeName[parseInt(PaymentTypeName[paymentType])],
				this.paymentTypeCapabilities
			);
		});
	}

	selectProvider(elementIndex: number, defIndex: number, value: string) {
		this.definitionsList[elementIndex].definitions[defIndex].provider.providerCountryId = value;
		this.definitionsList[elementIndex].definitions[defIndex].payoutAccount = undefined;
		this.definitionsList[elementIndex].definitions[defIndex].route = undefined;
	}

	selectType(elementIndex: number, defIndex: number, value: string) {
		this.duplicatePayoutAccount = [];
		this.definitionsList[elementIndex].definitions[defIndex].payoutAccount = value;
		this.definitionsList[elementIndex].definitions[defIndex].route = undefined;
	}

	selectRoute(elementIndex: number, defIndex: number, value: string) {
		this.duplicatePayoutRoute = [];
		this.objectValidation = true;
		this.definitionsList[elementIndex].definitions[defIndex].route = value;
		this.checkToBeDefauultRoute(elementIndex, defIndex);
		const payoutAccount = this.definitionsList[elementIndex].definitions[defIndex].payoutAccount;
		const providerCountryId = this.definitionsList[elementIndex].definitions[defIndex].provider.providerCountryId;
		this.definitionsList[elementIndex].definitions[defIndex].providerType = this.paymentTypePie.transform(
			this.definitionsList[elementIndex].definitions[defIndex],
			this.definitionsList[elementIndex].type,
			this.paymentTypeCapabilities
		);
		this.findDuplicates(elementIndex, defIndex, payoutAccount, value, providerCountryId);
		this.isOriginalObject.emit(false);
	}

	checkToBeDefauultRoute(elementIndex: number, defIndex: number) {
		var element = this.definitionsList[elementIndex].definitions.find(
			(definiton: DefinitionRoute) =>
				definiton.providerType ===
					this.paymentTypePie.transform(
						this.definitionsList[elementIndex].definitions[defIndex],
						this.definitionsList[elementIndex].type,
						this.paymentTypeCapabilities
					) && definiton.default === true
		);
		if (!element) this.definitionsList[elementIndex].definitions[defIndex].default = true;
		else this.definitionsList[elementIndex].definitions[defIndex].default = false;
	}

	changeDefaultRoute(elementIndex: number, defIndex: number, definition: DefinitionRoute) {
		this.definitionsList[elementIndex].definitions.map((item: DefinitionRoute) => {
			if (item.providerType === definition.providerType) item.default = false;

			return item;
		});

		this.definitionsList[elementIndex].definitions[defIndex].default = true;
	}

	selectDefault(elementIndex: number, defIndex: number, definition: DefinitionRoute) {
		this.changeDefaultRoute(elementIndex, defIndex, definition);

		// Check if this definition is already created and update it
		if (!!this.definitionsList[elementIndex].definitions[defIndex].costs) {
			this.definitions.paymentTypeDefinitions.map((paymentTypeDefinition: DefinitionsData) => {
				const definitionList: DefinitionsByType | undefined = this.definitionsList.find(dl => {
					const type: PaymentTypeName = dl.type as unknown as PaymentTypeName;
					return type === paymentTypeDefinition.paymentType;
				});

				if (!!definitionList) {
					paymentTypeDefinition.definitions.map((definition: Definition) => {
						const definitionModified: DefinitionRoute | undefined = definitionList.definitions.find(
							(d: DefinitionRoute) => {
								const payoutAccountString: string = definition.payoutAccount as unknown as string;
								const routeString: string = definition.route as unknown as string;

								return (
									payoutAccountString === d.payoutAccount &&
									routeString === d.route &&
									definition.provider?.providerCountryId === d.provider?.providerCountryId
								);
							}
						);

						definition.default = definitionModified?.default || definition.default;
					});
				}
			});

			const updatedDefinitions: DefinitionsPUT = this.definitionsService.formatPUT(this.definitions);

			this.serviceProviderService
				.updateDefinitions(updatedDefinitions)
				.pipe(take(1))
				.subscribe({
					next: _ => {
						this.definitions.version = this.definitions.version + 1;
						this.duplicatePayoutRoute = [];
						this.duplicatePayoutAccount = [];
						this.objectValidation = true;
						this.isOriginalObject.emit(false);
						this.toastService.showSuccess("The definitions were updated successfully");
						this.changeDetector.detectChanges();
					},
					error: (error: HttpErrorResponse) => this.toastService.showError("The definitions were NOT updated")
				});
		}
	}

	getProviderOptions(paymentProviders: PaymentProvider[]): SelectOption[] {
		let options: SelectOption[] = [];

		paymentProviders.map((paymentProvider: PaymentProvider) => {
			const option: SelectOption = {
				value: paymentProvider.provider.providerCountryId,
				text: paymentProvider.provider.name
			};

			options.push(option);
		});

		return options;
	}

	getTypesOptions(paymentProviders: PaymentProvider[]): TypeOptions {
		let options: TypeOptions = {};

		paymentProviders.map((paymentProvider: PaymentProvider) => {
			options[paymentProvider.provider.providerCountryId] = Object.keys(paymentProvider.payoutAccountRoutes).map(
				(key: string) => {
					const option: SelectOption = {
						value: key,
						text: key
					};

					return option;
				}
			);
		});

		return options;
	}

	getRoutesOptions(paymentProviders: PaymentProvider[]): RouteOptions {
		let options: RouteOptions = {};

		paymentProviders.map((paymentProvider: PaymentProvider) => {
			Object.keys(paymentProvider.payoutAccountRoutes).map((key: string) => {
				let option: TypeOptions = {};
				Object.keys(paymentProvider.payoutAccountRoutes).map((key: string) => {
					const newKey: PayoutAccountName = key as unknown as PayoutAccountName;

					option[newKey] = paymentProvider.payoutAccountRoutes[newKey].map((keyRoute: PaymentRouteName) => {
						const value: string = keyRoute as unknown as string;
						const o: SelectOption = {
							value: value,
							text: value.replace("_", " ")
						};

						return o;
					});
				});

				options[paymentProvider.provider.providerCountryId] = option;
			});
		});

		return options;
	}

	addNewDefinition(index: number) {
		this.objectValidation = false;
		const isFrstDefinition: boolean = this.definitionsList[index].definitions.length === 0;

		this.definitionsList[index].definitions.push({
			default: isFrstDefinition,
			provider: {} as ProviderCountry,
			route: undefined,
			payoutAccount: undefined,
			costs: undefined,
			settlementAccountIds: undefined,
			sourceAccountIds: undefined
		});
	}

	deleteDefinition(definitionItem: any, definition: DefinitionRoute, index: number, defIndex: number) {
		// Check if this definition is already created and
		// saved on BBDD to show or not confirmation's pop-up
		if (definition.costs && definition.costs.length > 0) {
			this.showConfirmationPopUp(definitionItem, definition, index, defIndex);
		} else {
			this.removeDefinitioFromCurrentObject(index, defIndex);
		}
	}

	showConfirmationPopUp(definitionItem: any, definition: DefinitionRoute, index: number, defIndex: number) {
		const dialogRef = this.dialog.open(DeleteDialogComponent, {
			height: "441px",
			width: "666px",
			data: {
				text: "Are you sure you want to delete this saved definition?"
			},
			panelClass: "delete-dialog-container"
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				const definitions: Definitions = this.definitionsService.formatDELETE(
					this.definitions,
					definitionItem,
					definition
				);
				this.updateDefinitions(definitions, index, defIndex);
			}
		});
	}

	removeDefinitioFromCurrentObject(index: number, defIndex: number): void {
		this.duplicatePayoutRoute = [];
		this.duplicatePayoutAccount = [];
		this.objectValidation = true;

		const wasDefaultRoute = this.definitionsList[index].definitions[defIndex].default;
		const removedDefinitionType = this.definitionsList[index].definitions[defIndex].providerType;
		this.definitionsList[index].definitions.splice(defIndex, 1);
		this.isOriginalObject.emit(false);
		this.changeDetector.detectChanges();

		if (wasDefaultRoute)
			this.resetDefaultRoute(this.definitionsList[index].definitions, index, removedDefinitionType);
	}

	resetDefaultRoute(definitions: DefinitionRoute[], index: number, providerType?: string): void {
		let cardCount = 0;
		let bankCount = 0;
		let hasDefaultRouteForType = false;

		definitions.forEach(def => {
			if (def.providerType === providerType && !hasDefaultRouteForType) {
				def.default = true;
				hasDefaultRouteForType = true;
			}

			if (def.providerType === "card") {
				cardCount++;
			} else if (def.providerType === "bank") {
				bankCount++;
			}
		});

		// If there is only one object with a given providerType, set its default property to true
		if (cardCount === 1) {
			this.definitionsList[index].definitions.find(obj => obj.providerType === "card")!.default = true;
		}

		if (bankCount === 1) {
			this.definitionsList[index].definitions.find(obj => obj.providerType === "bank")!.default = true;
		}
	}

	updateDefinitions(definitions: Definitions, index: number, defIndex: number) {
		const updatedDefinitions: DefinitionsPUT = this.definitionsService.formatPUT(definitions);

		this.serviceProviderService
			.updateDefinitions(updatedDefinitions)
			.pipe(take(1))
			.subscribe({
				next: _ => {
					this.removeDefinitioFromCurrentObject(index, defIndex);
					this.toastService.showSuccess("The definition was deleted successfully");
				},
				error: (error: HttpErrorResponse) => this.toastService.showError("The definition was NOT deleted")
			});
	}

	checkDefinitions() {
		const selectedPaymentProviders: any[] = [];

		// Format definitions in the way backend expects them
		const paymentTypeDefinitions: DefinitionsData[] = this.definitionsList.map(
			(definitionItem: DefinitionsByType) => {
				return {
					paymentType: definitionItem.type as unknown as PaymentTypeName,
					definitions: definitionItem.definitions.map((definition: DefinitionRoute) => {
						// Add payment provider to selected payment provider list if its unique
						const paymentProvider: PaymentProvider | undefined = definitionItem.paymentProviders.find(
							(provider: PaymentProvider) => {
								return provider.provider.providerCountryId === definition.provider.providerCountryId;
							}
						);

						let unique = true;
						if (
							selectedPaymentProviders.some(
								e => e.provider.providerCountryId === paymentProvider?.provider.providerCountryId
							)
						) {
							unique = false;
						}

						if (unique) {
							selectedPaymentProviders.push(paymentProvider);
						}

						// Return formatted object
						const definitionFormatted: Definition = {
							route: definition.route as PaymentRouteName | undefined,
							payoutAccount: definition.payoutAccount as PayoutAccountName | undefined,
							provider: {
								providerCountryId: definition.provider.providerCountryId,
								name: definition.provider.providerCountryId
							},
							costs: definition.costs,
							settlementAccountIds: definition.settlementAccountIds || [],
							sourceAccountIds: definition.sourceAccountIds || [],
							default: definition.default
						};

						return definitionFormatted;
					}),
					settings: [] as { key: string; value: string }[],
					legalEntityId: undefined as unknown as string,
					costs: undefined as unknown as ProviderCost[]
				};
			}
		);

		// Check if at least one of the definitions is complete
		let oneDefinitionComplete = false;
		for (const key in paymentTypeDefinitions) {
			if (paymentTypeDefinitions.hasOwnProperty(key)) {
				const typeDefinition = paymentTypeDefinitions[key];
				typeDefinition.definitions.forEach((definition: any) => {
					if (definition.payoutAccount && definition.provider?.providerCountryId && definition.route) {
						oneDefinitionComplete = true;
					}
				});
			}
		}

		// Check that no definitions are correct
		let allDefinitionsCorrect = true;
		for (const key in paymentTypeDefinitions) {
			if (paymentTypeDefinitions.hasOwnProperty(key)) {
				const typeDefinition = paymentTypeDefinitions[key];
				typeDefinition.definitions.forEach((definition: any) => {
					if (!definition.payoutAccount || !definition.provider?.providerCountryId || !definition.route) {
						allDefinitionsCorrect = false;
					}
				});
			}
		}

		if (oneDefinitionComplete) {
			if (allDefinitionsCorrect) {
				this.definitions.paymentTypeDefinitions = paymentTypeDefinitions.filter(
					(def: any) => def.definitions.length
				);

				let servDefinition: Definitions = {} as Definitions;

				if (this.serviceDefExists) {
					//edit

					let paymentTypeDefinitions: PaymentTypeDefinitionPUT[] = [];

					this.definitions.paymentTypeDefinitions.forEach(definition => {
						paymentTypeDefinitions.push({
							paymentType: definition.paymentType,
							definitions: definition.definitions,
							settings: definition.settings
						});
					});

					let definitionEdit: DefinitionsPUT = {
						id: this.currentServiceDef.id,
						version: this.currentServiceDef.version,
						legalEntityId: this.currentServiceDef.legalEntityId,
						paymentTypeDefinitions: paymentTypeDefinitions
					};

					if (this.canCreateServiceDefinition) {
						this.serviceProviderService
							.updateDefinitions(definitionEdit)
							.pipe(
								mergeMap(_ => {
									return this.serviceProviderService.getDefinitionsByEntityId(
										definitionEdit.legalEntityId!
									);
								})
							)
							.subscribe({
								next: (serviceDef: Definitions) => {
									servDefinition = serviceDef;

									this.paymentTypeDefinitions.emit(servDefinition);
									this.selectedPaymentProviders.emit(
										selectedPaymentProviders.map((provider: any) => ({
											providerCountryId: provider.provider.providerCountryId,
											name: provider.provider.name
										}))
									);
								}
							});
					} else {
						this.paymentTypeDefinitions.emit(this.definitions);
						this.selectedPaymentProviders.emit(
							selectedPaymentProviders.map((provider: any) => ({
								providerCountryId: provider.provider.providerCountryId,
								name: provider.provider.name
							}))
						);
					}
				} else {
					//create
					let paymentTypeDefinitions: PaymentTypeDefinitionPOST[] = [];

					this.definitions.paymentTypeDefinitions.forEach(definition => {
						paymentTypeDefinitions.push({
							paymentType: definition.paymentType,
							definitions: definition.definitions,
							settings: definition.settings
						});
					});

					let definitionCreateion: DefinitionsPOST = {
						legalEntityId: this.currentServiceDef.legalEntityId,
						paymentTypeDefinitions: paymentTypeDefinitions
					};

					let servDefinition: Definitions = {} as Definitions;

					if (this.canCreateServiceDefinition) {
						this.serviceProviderService
							.createDefinition(definitionCreateion)
							.pipe(
								mergeMap(res => {
									if (res) {
										return this.serviceProviderService.getDefinitionsByEntityId(
											definitionCreateion.legalEntityId
										);
									}
									return EMPTY;
								})
							)
							.subscribe({
								next: (serviceDef: Definitions) => {
									servDefinition = serviceDef;

									this.paymentTypeDefinitions.emit(servDefinition);
									this.selectedPaymentProviders.emit(
										selectedPaymentProviders.map(provider => ({
											providerCountryId: provider.provider.providerCountryId,
											name: provider.provider.name
										}))
									);

									this.definitionsService.completedProcess = "Created";
								}
							});
					} else {
						this.paymentTypeDefinitions.emit(this.definitions);
						this.selectedPaymentProviders.emit(
							selectedPaymentProviders.map((provider: any) => ({
								providerCountryId: provider.provider.providerCountryId,
								name: provider.provider.name
							}))
						);
					}
				}
			} else {
				this.toastService.showError("Please fill in all fields for all the routes");
			}
		} else {
			this.toastService.showError("At least one route must be complete");
		}
	}

	findDuplicates(
		elementIndex: number,
		defIndex: number,
		payoutAccount: string | undefined,
		route: string,
		providerCountryId: string
	): void {
		let duplicatePayoutAccount = this.definitionsList[elementIndex].definitions.filter(
			(filteredDefinitions: DefinitionRoute) =>
				filteredDefinitions.payoutAccount === payoutAccount &&
				filteredDefinitions.route === route &&
				filteredDefinitions.provider.providerCountryId === providerCountryId
		);

		if (duplicatePayoutAccount.length > 1) {
			this.objectValidation = false;
			this.duplicatePayoutAccount.push(defIndex);
			this.duplicatePayoutRoute.push(elementIndex);
		} else {
			this.objectValidation = true;
		}
	}

	onElseRendered(): void {
		this.hasCapabilities = false;
	}

	onDefinitionsRendered(): void {
		this.hasCapabilities = true;
	}

	isNewCapability(index: number): boolean {
		return this.definitionsList[0].definitions[index].settlementAccountIds ? false : true;
	}

	shouldDisableButton(): boolean {
		return (
			!this.hasPaymentTypes || !this.objectValidation || !this.hasCapabilities || !this.legalEntityHasPayGroups
		);
	}
}
