import { AbstractControl, FormGroup } from "@angular/forms";
import { SelectOption } from "./select-option.interface";
import { SubServicePaymentDefinition } from "./tpp-account.interface";
export interface TPPGroupDTO {
	beneficiaryId?: string;
	group?: string;
	beneficiaryName?: string;
	externalId?: string;
	subService: SubServicePaymentDefinition;
}

export interface TppServiceBase {
	name: string;
	country?: string;
	payGroupId?: string;
	description: string;
	type: string;
	status: string;
}

export interface TppService extends TppServiceBase {
	id: string;
	version: number;
	services?: SubService[];
}

export interface TppServiceWithGroup extends TppService {
	groups?: string[];
}

export interface TppServiceCreation extends TppServiceBase {}

export interface TppServicePagination {
	totalCount: number;
	totalPages: number;
	pageNumber: number;
	items: TppService[];
}

export interface TppSubServicePagination {
	totalCount: number;
	totalPages: number;
	pageNumber: number;
	items: SubService[];
}

export interface TppServiceDefinitionPagination {
	totalCount: number;
	totalPages: number;
	pageNumber: number;
	items: TPPServiceDefinitionDTO[];
}

export interface TppServiceCreation extends ServiceCreation {
	services: SubService[];
}

export interface ServiceCreation extends TppServiceBase {
	country?: string;
	payGroupId?: string;
}

export interface IServiceCreationFormGroup extends FormGroup {
	value: TppServiceCreation;
	controls: {
		country: AbstractControl;
		name: AbstractControl;
		description: AbstractControl;
		type: AbstractControl;
		status: AbstractControl;
	};
}

export interface SubServiceStoreDataForm {
	name?: string;
	beneficiaryName?: string;
	paymentOriginator?: string;
	referenceType?: string;
	frequency?: string;
}

export interface SubserviceCreation {
	name: string;
	tppServiceId: string;
	beneficiaryId?: string;
	paymentOriginator: string;
	referenceType: string;
	frequency: string;
}

export interface SubService extends SubserviceCreation {
	id: string;
	version: string;
	externalId?: string;
}

export interface SubServicePagination {
	totalCount: number;
	totalPages: number;
	pageNumber: number;
	items: SubService[];
}

export interface SettingType {
	id: string;
	name: string;
}

export interface ISubServicefigFormGroup extends FormGroup {
	value: SubService;
	controls: {
		id?: AbstractControl;
		name: AbstractControl;
		beneficiaryId: AbstractControl;
		paymentOriginator: AbstractControl;
		referenceType: AbstractControl;
		frequency: AbstractControl;
	};
}

export interface TppServiceBeneficiaryDTO {
	id?: string;
	version?: string;
	service: string;
	country: string;
	name: string;
	currency: string;
	bankAccount: {
		id?: string;
		version?: string;
		bankName?: string;
		country: string;
		currency: string;
		fields: Record<string, string>[];
	};
}

export interface SettingType {
	id: string;
	name: string;
}

export interface ClientSpecificSubService {
	serviceId: string;
	subServiceId: string;
	group?: string;
	beneficiaryId?: string;
	frequency: string;
	managedIn?: string;
	beneficiaryName?: string;
	externalId?: string;
}

export interface TPPServiceDefinitionDTO {
	payGroupId: string;
	version?: number;
	services: ClientSpecificSubService[];
	groupConfig?: TPPGroupConfig[];
}

/** DATA OPTIONS **/
export const TPPFrequency: SelectOption[] = [
	{
		value: "PER_RUN",
		text: "Per Run"
	},
	{
		value: "MAIN_RUN",
		text: "Main run"
	},
	{
		value: "MONTHLY",
		text: "Monthly"
	},
	{
		value: "QUARTERLY",
		text: "Quarterly"
	},
	{
		value: "YEARLY",
		text: "Yearly"
	}
];

export const TPPReferenceType: SelectOption[] = [
	{
		value: "STABLE",
		text: "Stable"
	},
	{
		value: "PER_RUN",
		text: "Per run"
	}
];

export const TPPPaymentOriginator: SelectOption[] = [
	{
		value: "EMPLOYEE",
		text: "Employee"
	},
	{
		value: "PAY_GROUP",
		text: "Pay Group"
	}
];

export const TPPGroupSelectOptions: SelectOption[] = [
	{
		value: "TPP1",
		text: "Group TPP1"
	},
	{
		value: "TPP2",
		text: "Group TPP2"
	},
	{
		value: "TPP3",
		text: "Group TPP3"
	},
	{
		value: "TPP4",
		text: "Group TPP4"
	}
];
//TPP TYPES
export interface TPPGroupConfig {
	group: string;
	fundingCurrency: string;
	sourceAccountId: string;
	settlementAccountId: string;
	paymentCurrency: string;
	providerCountryId: string;
	providerName?: string;
}

export interface TPPServiceDefinition {
	payGroupId: string;
	version?: number;
	services: DefinitionService[];
	groupConfig?: TPPGroupConfig[];
}
export interface TPPServiceDefinitionState {
	serviceDefinitionConfig: TPPServiceDefinition;
}

export interface DefinitionService {
	serviceId: string;
	subServiceId: string;
	group?: string;
	beneficiaryId?: string;
	frequency: string;
	managedIn?: string;
}

export interface adHocSubServiceStagedData {
	values: any;
	form: FormGroup;
	subService: SubService;
	forAdhocService: boolean;
	selectedPayGroupId: string;
	managedInSelection: SelectOption;
	tppServiceId: string;
	beneficiaryId: string | null;
}

export interface stagedDataValues {
	beneficiaryName?: string;
	name: string;
	externalId: string;
	paymentOriginator: string;
	referenceType: string;
	frequency: string;
}

export interface catalogueSubServiceStagedData {
	values: stagedDataValues;
	form: FormGroup;
	subService: SubService;
	forAdhocService: boolean;
	tppServiceId: string;
	beneficiaryId: string | null;
}
