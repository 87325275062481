<div class="payment-orders-filters">
	<div class="filters-container">
		<div class="refresh-container">
			<button (click)="refresh()" title="refresh" class="refresh-button" mat-icon-button>
				<app-icon
					class="refresh-payouts-icon"
					[name]="'rotate-right'"
					[color]="'var(--color-text-default)'"
					[lineWidth]="'1.5px'"
					[size]="'28'"
				></app-icon>
			</button>
		</div>
		<div class="search-container">
			<input-wrapper class="select">
				<mat-form-field id="btnEmployeeSearch" appearance="outline">
					<app-icon [name]="'search-normal-1'" matPrefix style="padding-right: 10px"></app-icon>
					<input formControlName="employeeSearch" matInput placeholder="Search by employee" />
				</mat-form-field>
			</input-wrapper>
		</div>
	</div>
	<div class="buttons-container">
		<button class="menu-buttons payroll-integration" mat-button>
			<span class="button-text">
				<app-icon
					class="button-icon"
					[name]="'receipt-add'"
					[color]="'var(--color-upload-button-dark-grey)'"
					[lineWidth]="'2px'"
				></app-icon>
				<span class="text-spacing body-small-bold">PAYROLL INTEGRATION</span>
			</span>
		</button>
		<button
			class="menu-buttons payment-order-context"
			[cdkMenuTriggerFor]="menu"
			mat-button
			(cdkMenuClosed)="isPaymentOrderMenuOpen = false"
			(cdkMenuOpened)="isPaymentOrderMenuOpen = true"
		>
			<span class="button-text">
				<app-icon
					class="button-icon"
					[name]="'folder'"
					[color]="'var(--color-white)'"
					[lineWidth]="'2px'"
				></app-icon>
				<span class="text-white text-spacing body-small-bold">PAYMENT ORDERS</span>
				<app-icon
					class="button-icon last"
					[name]="'arrow-down'"
					[color]="'var(--color-white)'"
					[lineWidth]="'2px'"
					[ngClass]="{ 'up-arrow': isPaymentOrderMenuOpen }"
				></app-icon>
			</span>
		</button>
		<ng-template #menu>
			<div class="sub-menu" cdkMenu>
				<button class="payment-order-actions" cdkMenuItem>
					<span class="button-text">
						<app-icon
							class="button-icon"
							[name]="'document-upload'"
							[color]="'var(--color-upload-button-dark-grey)'"
							[lineWidth]="'1px'"
							[size]="'24'"
						></app-icon>
						<span class="text-spacing">Upload Template</span>
					</span>
				</button>
				<button class="payment-order-actions" cdkMenuItem (click)="goToFileHistory()">
					<span class="button-text">
						<app-icon
							class="button-icon"
							[name]="'eye'"
							[color]="'var(--color-upload-button-dark-grey)'"
							[lineWidth]="'1px'"
							[size]="'24'"
						></app-icon>
						<span class="text-spacing">Payment Orders Files</span>
					</span>
				</button>
			</div>
		</ng-template>
	</div>
</div>
