import { EventEmitter, Injectable, Output } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { PayGroup } from "src/app/shared/models/pay-groups";
import { breadcrumbContextPreserver } from "@shared/components/modal-wrapper/_models/breadcrumb.interface";

@Injectable({
	providedIn: "root"
})
export class BreadcrumbsService {
	constructor(private http: HttpClient) {}

	@Output() crumbClicked: EventEmitter<boolean> = new EventEmitter<boolean>(false);

	getBreadCrumb(payGroupId: string): Observable<breadcrumbContextPreserver> {
		let payGroup$: Observable<PayGroup> = this.http.get<PayGroup>(
			`${environment.apiUri}/v1/pay-groups/${payGroupId}`
		);

		return payGroup$.pipe(
			take(1),
			map(res => {
				// not sure why this is nullable
				// removing this causes issues with the modal-dropdown.component.ts
				// hence optional co-elescing.
				const CUSTOMER_NAME = res.customer?.name;

				return {
					customerName: CUSTOMER_NAME,
					country: res.legalEntity.data.country,
					paygroupName: res.data.name
				};
			})
		);
	}
}
