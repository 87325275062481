import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { ContactPerson, ContactPersonObject } from "src/app/shared/models/contact-person.interface";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: "root"
})
export class ContactPersonService {
	constructor(private http: HttpClient) {}

	addContactPerson(ContactPersonObject: ContactPersonObject): Observable<ContactPerson[]> {
		return this.http.post<ContactPerson[]>(`${environment.apiUri}/v1/contact-persons`, ContactPersonObject);
	}

	getContactPersons(objectID: string): Observable<ContactPerson[]> {
		return this.http.get<ContactPerson[]>(`${environment.apiUri}/v1/contact-persons/${objectID}`);
	}

	linkContactToCustomer(customerId: string, userId: string) {
		return this.http.post(`${environment.apiUri}/v1/customers/${customerId}/user-permission/${userId}`, null);
	}

	removeContactFromCustomer(customerId: string, userId: string) {
		return this.http.delete(`${environment.apiUri}/v1/customers/${customerId}/user-permission/${userId}`);
	}

	removeContact(objectLevel: string, objectId: string, userId: string) {
		return this.http.delete(`${environment.apiUri}/v1/${objectLevel}/${objectId}/user-permission/${userId}`);
	}

	linkContactToEntity(legalEntityId: string, userId: string) {
		return this.http.post(
			`${environment.apiUri}/v1/legal-entities/${legalEntityId}/user-permission/${userId}`,
			null
		);
	}

	removeContactFromEntity(legalEntityId: string, userId: string) {
		return this.http.delete(`${environment.apiUri}/v1/legal-entities/${legalEntityId}/user-permission/${userId}`);
	}

	linkContactToPayGroup(payGroupId: string, userId: string) {
		return this.http.post(`${environment.apiUri}/v1/pay-groups/${payGroupId}/user-permission/${userId}`, null);
	}

	removeContactFromPayGroup(payGroupId: string, userId: string) {
		return this.http.delete(`${environment.apiUri}/v1/pay-groups/${payGroupId}/user-permission/${userId}`);
	}
}
