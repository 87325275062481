import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "app-tooltip",
	templateUrl: "./tooltip.component.html",
	styleUrls: ["./tooltip.component.scss"]
})
export class TooltipComponent implements OnInit {
	@Input() fontSize: string = "10px";
	@Input() fontColor: string = "#FFFF";
	@Input() contanierColor: string = "#231F20";
	@Input() position: string = "top";
	@Input() trianlePosition: string = "15px";
	@Input() paragraphStyle: boolean = false;
	@Input() containerBorder: boolean = false;
	@Input() containerPadding: string = "12px";
	@Input() containerMinWidth: number = 0;
	@Input() containerMarginLeft: string = "0px";
	@Input() containerMarginTop: string = "0px";

	@Input() flexStyle: boolean = false;

	layout: string = "column";

	constructor() {}

	ngOnInit(): void {
		if (
			this.position === "top" ||
			this.position === "bottom" ||
			this.position === "calendar-bottom" ||
			this.position === "calendar-top"
		)
			this.layout = "column";
		else this.layout = "row";
	}
}
