import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "../../../../environments/environment";

import { PennyTest } from "../_models/penny-test.interface";

@Injectable({
	providedIn: "root"
})
export class PennyTestApiService {
	constructor(private http: HttpClient) {}

	formatPennyTestDataApi(pennyTestFormData: any): PennyTest {
		return {
			id: pennyTestFormData.currency,
			amount: pennyTestFormData.amount.toFixed(2) //ensure decimal values
		};
	}

	getPennyTestCurrencyConfigByCurrency(id: string): Observable<any> {
		return this.http.get<PennyTest>(`${environment.apiUri}/v1/payouts/penny-test/${id}/config`);
	}

	createPennyTestCurrencyConfig(pennyTest: PennyTest): Observable<any> {
		return this.http.post<PennyTest>(`${environment.apiUri}/v1/payouts/penny-test/config`, pennyTest, {
			observe: "response"
		});
	}

	updatePennyTestCurrencyConfig(id: string, pennyTest: PennyTest): Observable<any> {
		return this.http.put<PennyTest>(`${environment.apiUri}/v1/payouts/penny-test/${id}/config`, pennyTest, {
			observe: "response"
		});
	}
}
