<div class="header flex flex-row">
	<h3 class="dialog-text flex-1" style="--max-width: 95%">HARD AML COMPLIANCE HISTORY LOG</h3>
	<app-icon
		id="master-close-icon"
		[name]="'close'"
		[size]="'70px'"
		[color]="'white'"
		[lineWidth]="'0.8px'"
		(click)="closeDialog()"
		class="flex-1"
		style="--max-width: 5%"
	></app-icon>
</div>
<div class="history-container">
	<app-legal-entity-aml-history [legalInteityId]="legalInteityId"></app-legal-entity-aml-history>
</div>
