<div class="provider-list" *ngIf="serviceProviders.length > 0">
	<mat-accordion displayMode="flat" *ngFor="let providerItem of serviceProviders; let i = index">
		<mat-expansion-panel
			(opened)="getContactPersons(providerItem.provider.id)"
			#matExpansionPanels
			id="expansion-panel-{{ providerItem.provider.id }}"
			[expanded]="getSelectedProvider() === providerItem.provider.id"
		>
			<!-- Header -->
			<mat-expansion-panel-header>
				<!-- <span matTooltip="remove provider" class="delete-icon-wrapper">
					<mat-icon>delete_outline</mat-icon>
				</span> -->
				<expansion-panel-header-title [title]="providerItem.provider.fullName"> </expansion-panel-header-title>
			</mat-expansion-panel-header>
			<app-service-provider-edit
				[serviceProvider]="providerItem.provider"
				[contactPersonData]="contactData"
				(closeExpansionPanel)="closeExpansionPanel(i)"
			></app-service-provider-edit>
			<app-add-provider-country
				[providerId]="providerItem.provider.id"
				[providerName]="providerItem.provider.fullName"
			></app-add-provider-country>
			<ng-container *ngIf="providerItem.providerCountries.items">
				<app-available-provider-countries
					[provider]="providerItem.provider"
					[availableCountries]="providerItem.providerCountries.items"
				></app-available-provider-countries>
			</ng-container>
		</mat-expansion-panel>
	</mat-accordion>
</div>

<mat-paginator
	*ngIf="totalPageCount"
	[length]="totalPageCount"
	[pageSizeOptions]="[5, 10, 25, 100]"
	[pageSize]="pageSize"
	[pageIndex]="pageIndex"
	(page)="paginate($event)"
	aria-label="Select page"
	[color]="'primary'"
>
</mat-paginator>
