import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { PayElectiveHistoryModalData } from "@shared/components/wpay-ui/modal/templates/pay-elective-history/model/PayElectiveHistoryModalData";
import { HistoryViewData } from "src/app/shared/models/history-view.interface";
import { HistoryService } from "../../history/history.service";

@Injectable({
	providedIn: "root"
})
export class PayElectiveAdapterService {
	constructor(private historyService: HistoryService) {}

	getHistoryModalData(payMethodId: string, providerName: string): Observable<PayElectiveHistoryModalData> {
		return this.historyService.getBankAccountHistory(payMethodId).pipe(
			map((historyView: HistoryViewData[]) => {
				return {
					title: "History",
					providerName: providerName,
					type: "PAY_ELECTIVE",
					data: historyView
				} as PayElectiveHistoryModalData;
			})
		);
	}
}
