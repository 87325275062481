import { Injectable } from "@angular/core";
import { SelectOption } from "src/app/shared/models/select-option.interface";

@Injectable({
	providedIn: "root"
})
export class RoleFormatService {
	constructor() {}

	transform(role: string): string {
		if (!role) return role;

		switch (role) {
			case "TS_IMPLEMENTATION_CONSULTANT":
				return "TS Implementation Consultant";

			case "TS_IMPLEMENTATION_LEAD":
				return "TS Implementation Lead";

			case "TS_OPERATION_CONSULTANT":
				return "TS Operation Consultant";

			case "TS_OPERATION_LEAD":
				return "TS Operation Lead";

			case "TS_MANAGER":
				return "TS Manager";

			case "TS_ADMIN":
				return "TS Admin";

			case "TS_OCB":
				return "TS Booker";

			case "TS_VO":
				return "TS View Only";

			default:
				return this.convertToTitleCase(role); // fallback on un-mapped roles
		}
	}

	public convertToTitleCase(string: string): string {
		var result = string.replace(/_/g, " ");
		return result
			.toLowerCase()
			.split(" ")
			.map(function (word) {
				return word.charAt(0).toUpperCase() + word.slice(1);
			})
			.join(" ");
	}
}
