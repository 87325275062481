import { EventEmitter, Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map, take } from "rxjs/operators";
import { ContactPerson, ContactPersonObject } from "src/app/shared/models/contact-person.interface";
import {
	ServiceProviderCreateDto,
	ServiceProviderCreateForm
} from "src/app/shared/models/service-provider-types.interface";
import { FundingMethodName } from "src/app/shared/models/service-provider.type";
import { ServiceProviderService } from "../../../../shared/services/service-provider/service-provider.service";
import { SelectOption } from "../../../../shared/models/select-option.interface";
import { formatDataForSelect } from "../../../../shared/utils/format-select-data.util";

@Injectable({
	providedIn: "root"
})
export class CreateServiceProviderService {
	providerAdded$: EventEmitter<void> = new EventEmitter();
	cancelCreate$: EventEmitter<void> = new EventEmitter();

	constructor(private serviceProviderService: ServiceProviderService) {}

	contactPersonObject(objectId: string, contactData: ContactPerson[]) {
		let contactPersons: ContactPerson[] = [];

		contactData.forEach(contactPerson => {
			contactPersons.push(contactPerson);
		});
		let contactPersonObject: ContactPersonObject = {
			objectId: objectId,
			contactPersons: contactPersons
		};
		return contactPersonObject;
	}

	providerAdded() {
		this.providerAdded$.next();
	}

	cancelCreate() {
		this.cancelCreate$.next();
	}

	hasValue(obj: any, key: string, value: boolean) {
		return obj.hasOwnProperty(key) && obj[key] === value;
	}

	getServiceProviderCreateDTO(formData: ServiceProviderCreateForm): ServiceProviderCreateDto {
		const serviceProvider: ServiceProviderCreateDto = {
			...formData
		};

		return serviceProvider;
	}
}
