import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { PaginationService } from "@shared/components/pagination/_services/pagination.service";
import { PaginationOptions } from "@shared/constants/pagination";
import { CustomerCacheService } from "@shared/services/customer/customer-cache.service";
import { LegalEntityService } from "@shared/services/legal-entity/legal-entity.service";
import { PaginationData } from "@store/models/pagination.model";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, finalize, map, take, takeUntil } from "rxjs/operators";
import { Customer } from "src/app/shared/models/customer.interface";
import { LegalEntity, LegalEntityPagination } from "src/app/shared/models/legal-entity.interface";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { formatDataForSelect } from "src/app/shared/utils/format-select-data.util";
import { getCustomerEntityGroupState, getPagination } from "src/app/store";
import { UpdateCustomerEntityGroupAction } from "src/app/store/actions/customerEntityGroupSelect.action";
import { CustomerEntityGroupSelect } from "src/app/store/models/customerEnitityGroupSelection.model";
import { AppState } from "src/app/store/models/state.model";
import { PermissionsService } from "../../../../shared/services/permissions/permissions.service";
import { CommonLegalEntityService } from "../../_services/common/common-legal-entity.service";
import { SetupCustomersService } from "../../_services/setup-customers/setup-customers.service";
import { CountriesService } from "@shared/services/countries/countries.service";

@Component({
	selector: "app-legal-entity-entry",
	templateUrl: "./legal-entity-entry.component.html",
	styleUrls: ["./legal-entity-entry.component.scss"],
	providers: [SetupCustomersService]
})
export class LegalEntityEntryComponent implements OnInit, OnDestroy {
	canCreateLegal = false;
	selectOptionsCountries: SelectOption[] = [];
	allCountries: SelectOption[] = [];
	searchQuery = "";
	countryCode = "";
	pageIndex!: number;
	pageSize!: number;
	totalPageCount!: number;
	customerName = "";
	customerId = "";
	selectedCustomer!: Customer;
	entitySelectForm!: FormGroup;
	legalEntitiesList: LegalEntity[] = [];
	busy = true;

	paginationType: PaginationOptions = PaginationOptions.LEGAL_ENTITIES;

	private destroy$ = new Subject<void>();

	readonly ALL_OPTION: SelectOption = {
		value: "ALL_OPTIONS",
		text: "All countries"
	};

	constructor(
		private store: Store<AppState>,
		private legalEntityService: LegalEntityService,
		public commonLegalEntityService: CommonLegalEntityService,
		private router: Router,
		private permissions: PermissionsService,
		private customerCacheService: CustomerCacheService,
		private paginationService: PaginationService,
		private countriesService: CountriesService
	) {}

	ngOnInit(): void {
		this.permissions
			.canCreateLegalEntities()
			.pipe(takeUntil(this.destroy$))
			.subscribe(canCreate => {
				this.canCreateLegal = canCreate;
				this.getCountries();
				this.fetchCustomerFromState();
				this.initForm();
			});
	}

	getCountries(): void {
		this.countriesService
			.getCountries()
			.pipe(take(1), takeUntil(this.destroy$))
			.subscribe(countries => {
				countries.filter(country => {
					this.allCountries.push(
						formatDataForSelect({
							text: country.country.name,
							value: country.country.code,
							imagePath: `assets/svg-country-flags/svg/${country?.country.code.toLowerCase()}.svg`
						})
					);
				});
				this.setupPaginationSubscription();
			});
	}

	fetchCustomerFromState(): void {
		this.store
			.pipe(takeUntil(this.destroy$), select(getCustomerEntityGroupState))
			.subscribe((state: CustomerEntityGroupSelect) => {
				if (state?.customerId) {
					this.customerId = state.customerId;
					this.customerName = state.customerName ? state.customerName : "";
					this.getCustomer(state.customerId);
					this.getLegalEntitiesDropdownItems(state.customerId);
				}
			});
	}

	setupPaginationSubscription(): void {
		this.store.pipe(select(getPagination), takeUntil(this.destroy$)).subscribe({
			next: pagination => {
				if (pagination && pagination.hasOwnProperty("LEGAL_ENTITIES")) {
					const legalsPagination = pagination["LEGAL_ENTITIES"];

					this.pageSize = legalsPagination.pageSize;
					this.pageIndex = legalsPagination.pageIndex;

					this.getLegalEntities(
						this.customerId,
						this.pageSize,
						this.pageIndex,
						this.searchQuery,
						this.countryCode
					);
				}
			}
		});
	}

	getCustomer(customerId: string) {
		this.customerCacheService
			.getCustomerById(customerId)
			.pipe(takeUntil(this.destroy$))
			.subscribe(customer => {
				this.selectedCustomer = customer;
			});
	}

	initForm() {
		this.entitySelectForm = new UntypedFormGroup({
			legal_countries: new UntypedFormControl("", Validators.required),
			legal_search: new UntypedFormControl("")
		});

		this.entitySelectForm
			.get("legal_countries")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe((code: string) => {
				this.legalEntitiesList = [];
				this.searchQuery = "";
				this.formControlReset("legal_search");
				code === "ALL_OPTIONS" ? (this.countryCode = "") : (this.countryCode = code);

				this.resetPagination();
			});

		this.entitySelectForm
			.get("legal_search")
			?.valueChanges.pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.destroy$))
			.subscribe((search: string) => {
				this.legalEntitiesList = [];
				this.countryCode = "";
				this.searchQuery = search;
				this.formControlReset("legal_countries");
				this.resetPagination();
			});
	}

	formControlReset(control: string): void {
		const isLegal = control === "legal_countries";
		this.entitySelectForm.get(control)?.setValue(isLegal ? "All Legal Entities" : "", { emitEvent: false });
	}

	getLegalEntities(
		customerId: string,
		pageSize: number,
		pageIndex: number,
		searchQuery: string,
		countryCode: string
	) {
		this.legalEntityService
			.getLegalEntitiesItems(customerId, pageIndex, pageSize, searchQuery, [], countryCode)
			.pipe(
				finalize(() => (this.busy = false)),
				map((res: LegalEntityPagination) => {
					this.totalPageCount = res.totalPages;
					return res.items;
				})
			)
			.subscribe(legalEntities => {
				this.legalEntitiesList = legalEntities;
			});
	}

	getLegalEntitiesDropdownItems(customerId: string) {
		this.legalEntityService
			.getLegalEntitiesForCustomer(customerId)
			.pipe(
				map((res: LegalEntityPagination) => {
					return res.items;
				}),
				finalize(() => (this.busy = false))
			)
			.subscribe(legalEntities => {
				if (legalEntities.length) {
					this.prepareDropDownOptions(legalEntities);
				} else {
					this.selectOptionsCountries = [];
				}
			});
	}

	prepareDropDownOptions(legalEntities: LegalEntity[]): void {
		// Create a Map to store unique values based on the 'value' key
		const uniqueValuesMap = new Map<string, SelectOption>();

		// Loop through legalEntities and add unique values to the Map
		legalEntities.forEach((entity: LegalEntity) => {
			const option: SelectOption = {
				value: entity.data.country,
				text: this.countryCodeToName(entity?.data?.country),
				imagePath: `assets/svg-country-flags/svg/${entity.data?.country.toLowerCase()}.svg`
			};

			const formattedOption = formatDataForSelect(option);

			// Add to Map only if the 'value' key is not already present
			if (!uniqueValuesMap.has(formattedOption.value)) {
				uniqueValuesMap.set(formattedOption.value, formattedOption);
			}
		});

		// Convert Map values back to an array
		this.selectOptionsCountries = Array.from(uniqueValuesMap.values());

		this.selectOptionsCountries.unshift(this.ALL_OPTION);
	}

	countryCodeToName(code: string): string {
		return this.allCountries.find(country => {
			return country.value === code;
		})!.text;
	}

	legalEntitySelected(legalEntity: LegalEntity) {
		const newState = {
			customerId: this.customerId,
			customerName: this.customerName,
			legalEntityId: legalEntity.id,
			payGroupId: ""
		};

		this.store.dispatch(new UpdateCustomerEntityGroupAction(newState));
		this.router.navigate(["legal-entities/update"]);
	}

	createLegalEntity(): void {
		this.router.navigate(["legal-entities/create"], {
			state: { customer: this.selectedCustomer }
		});
	}

	resetPagination(): void {
		this.busy = true;
		const paginationSelection: PaginationData = {
			pageSize: this.pageSize,
			pageIndex: 0
		};

		this.paginationService.updatePaginationSate(this.paginationType, paginationSelection);
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
