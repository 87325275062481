<div id="itemButton" class="event-item" (click)="cardClickedEvent.next()">
	<!-- left side -->
	<div class="left-side">
		<div class="milestone-icon" [fxLayout]="'column'">
			<app-icon
				[size]="'30'"
				[color]="'var(--color-disabled)'"
				[name]="milestone!.type | milestoneTypeToIcon"
			></app-icon>
		</div>
		<div [fxLayout]="'column'">
			<div class="title">{{ milestone! | milestoneTypes }}</div>
			<div class="subtitle">{{ milestone!.date | suffixAndAbbreviatedDate }}</div>
		</div>
	</div>

	<!-- Right side -->
	<div class="right-side">
		<!-- Details CTA -->
		<div class="details-cta" id="detailsButton" (click)="viewIconClickedEvent.next(); $event.stopPropagation()">
			<app-icon class="icon material-icons-outlined" [lineWidth]="'1.5px'" [name]="'eye'"></app-icon>
		</div>
	</div>
</div>
