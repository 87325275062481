import { EventEmitter, Injectable, Output } from "@angular/core";

@Injectable({
	providedIn: "root"
})
export class MilestoneGroupsService {
	@Output() close: EventEmitter<void> = new EventEmitter<void>();

	@Output() onResetMilestones: EventEmitter<string> = new EventEmitter<string>(false);

	@Output() onResetMilestonesResult: EventEmitter<boolean> = new EventEmitter<boolean>(false);

	@Output() onResetMilestonesStatus: EventEmitter<boolean> = new EventEmitter<boolean>(false);

	constructor() {}
}
