<ng-container *ngIf="dataSourceTransaction">
	<div class="title flex flex-row flex-align-center-center">HISTORY BREAKDOWN</div>
	<table mat-table [dataSource]="dataSourceTransaction" class="mat-elevation-z2">
		<!-- Position date -->
		<ng-container matColumnDef="date">
			<th mat-header-cell *matHeaderCellDef class="table-data-sytle color-header">DATE</th>
			<td
				mat-cell
				*matCellDef="let element"
				class="table-data-sytle color-date"
				[ngClass]="{ failed: isTransactionFailedOrUnableToDetermine(element.status.name) }"
			>
				{{ element.date | date : "dd/MM/yyyy" }}
			</td>
		</ng-container>

		<!-- Name payment_method -->
		<ng-container matColumnDef="paymentMethod">
			<th mat-header-cell *matHeaderCellDef class="table-data-sytle color-header">PAYMENT METHOD</th>
			<td
				mat-cell
				*matCellDef="let element"
				class="table-data-sytle color-data"
				[ngClass]="{ failed: isTransactionFailedOrUnableToDetermine(element.status.name) }"
			>
				<div class="flex flex-row flex-align-center-center">
					<div class="badge-item badge-card" *ngIf="element.paymentMethodType === 'CARD'">
						<app-icon class="bank-card-icon" [name]="'card'"></app-icon>
					</div>
					<div class="badge-item badge-bank" *ngIf="element.paymentMethodType === 'BANK'">
						<app-icon class="bank-icon" [name]="'bank-figma'" [lineWidth]="'2'"></app-icon>
					</div>
					<b>{{ element.paymentMethod }}</b>
				</div>
			</td>
		</ng-container>

		<!-- Weight amount -->
		<ng-container matColumnDef="amount">
			<th mat-header-cell *matHeaderCellDef class="table-data-sytle color-header">AMOUNT</th>
			<td
				mat-cell
				*matCellDef="let element; let i = index"
				class="table-data-sytle color-data"
				[ngClass]="{ failed: isTransactionFailedOrUnableToDetermine(element.status.name) }"
			>
				<div class="flex flex-row flex-align-center-start">
					<div class="amount-info flex flex-1" style="--max-witdh: 35%">
						<b>{{ element.amount | number }} {{ element.currency }}</b>
						<div class="showhim">
							<app-icon
								style="--max-witdh: 20%"
								class="icon-info flex flex-1"
								[name]="'info-circle'"
								[color]="'#767676'"
								[size]="'17'"
							>
							</app-icon>
							<app-tooltip class="showme" [position]="'top'">
								<app-pay-elective-transaction-calculation
									[element]="element"
									[indexElement]="i"
									[amountList]="amountList"
								>
								</app-pay-elective-transaction-calculation>
							</app-tooltip>
						</div>
					</div>
				</div>
			</td>
		</ng-container>

		<!-- Symbol type -->
		<ng-container matColumnDef="type">
			<th mat-header-cell *matHeaderCellDef class="table-data-sytle color-header">TYPE</th>
			<td
				mat-cell
				*matCellDef="let element"
				class="table-data-sytle color-data"
				[ngClass]="{ failed: isTransactionFailedOrUnableToDetermine(element.status.name) }"
			>
				{{ element.type }}
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-header"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
	</table>
	<div class="footer flex flex-row flex-align-center-space-around">
		TOTAL PAYMENT:&nbsp;{{ payout.amount.value | number }}{{ payout.amount.currency }}
	</div>
</ng-container>
