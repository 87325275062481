import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Employee, EmployeePagination } from "../../models/employee.interface";

@Injectable({
	providedIn: "root"
})
export class EmployeeService {
	private employeeListState: Employee[] = [];

	private sortField = "data.lastName";
	private sortDir = "ASC";

	constructor(private http: HttpClient) {}

	get employeeListByPaygroupId(): Employee[] {
		return this.employeeListState;
	}

	set employeeListByPaygroupId(employees: Employee[]) {
		this.employeeListState = employees;
	}

	getEmployeesByPaygroupId(id: string): Observable<EmployeePagination> {
		return this.http.get<EmployeePagination>(
			`${environment.apiUri}/v1/employees?payGroupIds=${id}&pageSize=-1&sortField=${this.sortField}&sortDir=${this.sortDir}`
		);
	}

	getEmployeesById(id: string): Observable<Employee> {
		return this.http.get<Employee>(`${environment.apiUri}/v1/employees/${id}`);
	}

	getEmployeesByIds(ids: string): Observable<EmployeePagination> {
		return this.http.get<EmployeePagination>(`${environment.apiUri}/v1/employees?ids=${ids}`);
	}

	createNewEmployee(employee: Employee): Observable<Employee> {
		return this.http.post<Employee>(`${environment.apiUri}/v1/employees`, employee);
	}

	updateEmployee(employee: Employee): Observable<Employee> {
		return this.http.put<Employee>(`${environment.apiUri}/v1/employees`, employee);
	}

	searchEmployees(searchTerm: string): any {
		let filteredEmployees: Employee[] = [];
		if (searchTerm) {
			const filterSearch = this.employeeListState?.filter(
				(employee: Employee) =>
					employee.data.firstName.toLowerCase().includes(searchTerm) ||
					employee.data.lastName.toLowerCase().includes(searchTerm) ||
					employee.externalId?.toLowerCase().includes(searchTerm)
			);

			if (filterSearch && filterSearch.length > 0) {
				filteredEmployees = filterSearch;
			}

			return filteredEmployees;
		}
	}
}
