<div class="hide-events-container flex flex-column">
	<div mat-dialog-title class="header">
		<app-icon class="warning-icon" name="warning" [lineWidth]="'1px'" [size]="'24'" [color]="'white'"></app-icon>
		WARNING
	</div>
	<mat-dialog-content class="flex flex-column flex-1 xs-title">
		<div [ngPlural]="selectedEvents" class="confirmation-message">
			Are you sure you want to hide the
			<span class="event-count" *ngIf="selectedEvents > 1">{{ selectedEvents }} </span>
			<ng-template ngPluralCase="=1">event</ng-template>
			<ng-template ngPluralCase="other">events</ng-template>
			selected?
		</div>
		<div>Any error data related to these events will not be counted.</div>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button class="mat-stroked-button cancel-btn" (click)="close(false)">NO, THANKS</button>
		<button class="mat-stroked-button submit-btn" (click)="close(true)">YES, SURE</button>
	</mat-dialog-actions>
</div>
