import { trigger, transition, style, animate } from "@angular/animations";
import { Location } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatStepper } from "@angular/material/stepper";
import { Router } from "@angular/router";

import { Subject, Subscription } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { ServiceProviderService } from "@shared/services/service-provider/service-provider.service";
import { WarningDialogComponent } from "../../_components/warning-dialog/warning-dialog.component";
import { ProviderCountryTemplateService } from "../../_services/provider-country-template/provider-country-template.service";
import { ServiceProviderCountryCreateService } from "../../_services/service-provider-country-create/service-provider-country-create.service";
import { DropDownDataExpanded } from "@shared/models/breadcrumbs.interface";
import { ServiceProviderCountryItem, ServiceProviderListItem } from "@shared/models/service-provider-types.interface";
import { CountriesService } from "@shared/services/countries/countries.service";
import { ToastService } from "@shared/services/toast/toast.service";

@Component({
	selector: "app-add-country",
	templateUrl: "./add-country.component.html",
	styleUrls: ["./add-country.component.scss"],
	animations: [
		trigger("fade", [transition("void => *", [style({ opacity: 0 }), animate(1000, style({ opacity: 1 }))])])
	]
})
export class AddCountryComponent implements OnInit, OnDestroy {
	formChangeSubscription: Subscription = new Subscription();

	showWarning: boolean = false;

	firstFormGroup!: FormGroup;
	secondFormGroup!: FormGroup;
	isEditable: boolean = false;
	countryName: string = "";
	countryCode: string = "";
	providerId: string = "";
	providerName: string = "";

	destroy$: Subject<void> = new Subject();

	currenciesValid: boolean = false;
	capabilitiesValid: boolean = false;
	costsValid: boolean = false;

	countriesDropDownData: DropDownDataExpanded[] = [];
	providersDropDownData: DropDownDataExpanded[] = [];

	constructor(
		private readonly serviceProviderService: ServiceProviderService,
		private readonly location: Location,
		private readonly providerCountryTemplateService: ProviderCountryTemplateService,
		public readonly serviceProviderCountryCreateService: ServiceProviderCountryCreateService,
		public dialog: MatDialog,
		private countriesService: CountriesService,
		private toast: ToastService,
		private router: Router
	) {}

	ngOnInit(): void {
		this.countryName = history.state.countryName;
		this.countryCode = history.state.countryCode;
		this.providerId = history.state.providerId;
		this.providerName = history.state.providerName;
		this.getSettingsTemplate();
		this.serviceProviderCountryCreateService.countryCode = this.countryCode;
		this.serviceProviderCountryCreateService.providerId = this.providerId;

		this.formChangeSubscription = this.serviceProviderCountryCreateService.changes$
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.showWarning = true;
			});

		this.serviceProviderService
			.getServiceProviderList(-1, 0)
			.pipe()
			.subscribe((result: ServiceProviderListItem[]) => {
				const selectedProvider = result.find(res => {
					return res.provider.id === this.providerId;
				});

				selectedProvider?.providerCountries.items.forEach(element => {
					this.countriesDropDownData.push({ id: element.country.code, name: element.country.name });
				});

				result.forEach(element => {
					this.providersDropDownData.push({ id: element.provider.id, name: element.provider.name });
				});
			});
	}

	goBack() {
		this.openDialog();
	}

	goHome(): void {
		this.router.navigate(["/service-provider-setup"]);
	}

	openDialog(): void {
		const dialogRef = this.dialog.open(WarningDialogComponent, {
			width: "650px",
			panelClass: "warning-modalbox"
		});
		dialogRef.afterClosed().subscribe((result: boolean) => {
			if (result) {
				this.location.back();
			}
		});
	}

	getSettingsTemplate() {
		this.serviceProviderService
			.getProviderSettingsTemplate()
			.pipe(takeUntil(this.destroy$))
			.subscribe(result => {
				this.providerCountryTemplateService.setTemplateSettings(result);
			});
	}

	hasCapabilities(): boolean {
		return !!this.providerCountryTemplateService.getTemplateData();
	}

	nextStep(stepper: MatStepper) {
		this.serviceProviderCountryCreateService.createCapabilityData$.next(stepper);
		this.serviceProviderCountryCreateService.createCurrencyData$.next();
	}

	createCountry() {
		this.serviceProviderCountryCreateService.createCostForm();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	routingCrumbClicked(routingCrumbClicked: boolean): void {
		if (routingCrumbClicked) {
			this.serviceProviderService.selectedProvider = "";
		}
	}

	externalCrumbDropDownSelection(id: string) {
		if (id.length === 2) {
			this.resetFromCountry(id);
		} else {
			this.resetFromProvider(id);
		}
	}

	resetFromCountry(id: string): void {
		this.countriesService
			.getCountryOptions()
			.pipe(take(1))
			.subscribe({
				next: result => {
					this.countryName = result.find(res => {
						return res.value === id;
					})!.text;
					this.countryCode = id;
					this.providerId = this.providerId;
					this.providerName = this.providerName;
					this.getSettingsTemplate();
				}
			});
	}

	resetFromProvider(id: string): void {
		this.serviceProviderService
			.getServiceProviderList(-1, 0)
			.pipe()
			.subscribe((result: ServiceProviderListItem[]) => {
				const availableCountries = result.find(res => {
					return res.provider.id === id;
				})!.providerCountries.items;

				const firstCountry = result.find(res => {
					return res.provider.id === id;
				})?.providerCountries.items[0];

				if (firstCountry) {
					this.countriesDropDownData = [];
					this.serviceProviderService.selectedProvider = id;
					this.providerId = id;

					this.providerName = result.find(res => {
						return res.provider.id === id;
					})!.provider.name;

					this.countryCode = firstCountry.country.code;
					this.countryName = firstCountry.country.name;

					availableCountries.forEach((element: ServiceProviderCountryItem) => {
						this.countriesDropDownData.push({ id: element.country.code, name: element.country.name });
					});

					this.getSettingsTemplate();
				} else {
					this.toast.showError("Selected Provider has no countries configured");
				}
			});
	}
}
