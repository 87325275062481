import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { GlobalDashboardComponent } from "./_containers/global-dashboard.component";
import { PayElectiveMasterDataComponent } from "@modules/employee-data/_components/pay-elective-master-data/pay-elective-master-data.component";
import { PayElectiveComponent } from "@modules/employee-data/_components/pay-elective/pay-elective.component";
import { DashboardCalendarComponent } from "@modules/dashboard/_components/dashboard-calendar/dashboard-calendar.component";
import { FileHistoryComponent } from "@modules/dashboard/_components/file-history/file-history.component";
import { TppDataInputComponent } from "@modules/dashboard/tpp-payments/containers/tpp-data-input/tpp-data-input.component";
import { TppFileHistoryComponent } from "@modules/dashboard/tpp-payments/containers/tpp-file-history/tpp-file-history.component";
import { FileErrorsComponent } from "@modules/dashboard/_components/file-errors/file-errors.component";
import { PayoutsComponent } from "@modules/dashboard/_components/payouts/payouts.component";
import { TppPaymentsComponent } from "@modules/dashboard/tpp-payments/containers/tpp-payments/tpp-payments.component";
import { PaygroupListComponent } from "@modules/dashboard/_components/paygroup-list/paygroup-list.component";
import { NetPaymentOrdersComponent } from "@modules/net-payment-orders/net-payment-orders.component";

const routes: Routes = [
	{
		path: "",
		component: GlobalDashboardComponent
	},
	{
		path: "master-data",
		component: PayElectiveMasterDataComponent
	},
	{
		path: "employee",
		component: PayElectiveComponent
	},
	{
		path: "dashboard",
		component: DashboardCalendarComponent
	},
	{
		path: "paygroups",
		component: PaygroupListComponent
	},
	{
		path: "file-history",
		component: FileHistoryComponent
	},
	{
		path: "tpp-data-input",
		component: TppDataInputComponent
	},
	{
		path: "tpp-file-history",
		component: TppFileHistoryComponent
	},
	{
		path: "payouts",
		component: PayoutsComponent
	},
	{
		path: "tpp-payments",
		component: TppPaymentsComponent
	},
	{
		path: "payment-orders",
		component: NetPaymentOrdersComponent
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class GlobalDashboardRoutingModule {}
