import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { LegalEntityService } from "@shared/services/legal-entity/legal-entity.service";
import { AMLHistory } from "../../_models/legal-entity-api.interface";

@Component({
	selector: "app-legal-entity-aml-history",
	templateUrl: "./legal-entity-aml-history.component.html",
	styleUrls: ["./legal-entity-aml-history.component.scss"]
})
export class LegalEntityAmlHistoryComponent implements OnInit {
	@Input() legalInteityId!: string;
	amlHistoryList$!: Observable<AMLHistory[]>;

	searchForm!: FormGroup;

	constructor(private legalEntityService: LegalEntityService, private formBuilder: FormBuilder) {}

	ngOnInit(): void {
		this.searchAmlHistoryForm();
		this.getAMLHistory();
	}

	getAMLHistory() {
		this.amlHistoryList$ = this.legalEntityService.getAMLHistory(this.legalInteityId).pipe(
			map((amlHistorylist: AMLHistory[]) => {
				amlHistorylist.map((amlHistoryItem: AMLHistory) => {
					amlHistoryItem.status = this.renameStatusAMLHistory(amlHistoryItem);
					return amlHistoryItem;
				});
				return amlHistorylist;
			})
		);
	}

	renameStatusAMLHistory(amlHistoryItem: AMLHistory): string {
		const APPROVED: string = "APPROVED";
		const AML_APPROVED: string = "AML_APPROVED";
		const REJECTED: string = "REJECTED";
		const AML_DECLINED: string = "AML_DECLINED";
		const ON_REVIEW: string = "ON REVIEW";
		const AML_WAITING: string = "AML_WAITING";

		if (amlHistoryItem.status === AML_APPROVED) return APPROVED;
		if (amlHistoryItem.status === AML_DECLINED) return REJECTED;
		if (amlHistoryItem.status === AML_WAITING) return ON_REVIEW;

		return amlHistoryItem.status;
	}

	searchAmlHistoryForm() {
		this.searchForm = this.formBuilder.group({
			search: [""]
		});

		this.searchForm
			.get("search")
			?.valueChanges.pipe()
			.subscribe((result: string) => {
				this.amlHistoryList$ = this.legalEntityService.getAMLHistory(this.legalInteityId).pipe(
					map((amlHistorylist: AMLHistory[]) => {
						const amlHistoryListFilter = amlHistorylist.filter((item: AMLHistory) => {
							const name: string = (item.firstName + " " + item.lastName).toUpperCase();
							return name.includes(result.toUpperCase());
						});

						amlHistoryListFilter.map((amlHistoryItem: AMLHistory) => {
							amlHistoryItem.status = this.renameStatusAMLHistory(amlHistoryItem);
							return amlHistoryItem;
						});

						return amlHistoryListFilter;
					})
				);
			});
	}
}
