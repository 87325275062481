import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AccessFieldsComponent } from "./access-fields.component";
import { FormsSharedModule } from "@shared/modules/forms-shared.module";
import { PipesModule } from "src/app/shared/pipes/pipes.module";

@NgModule({
	declarations: [AccessFieldsComponent],
	imports: [CommonModule, FormsSharedModule, PipesModule],
	exports: [AccessFieldsComponent]
})
export class AccessFieldsModule {}
