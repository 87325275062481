import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { ModalStyle } from "../model/ModalStyle";

export class ModalLauncherFactory {
	private readonly modalOptions: any;

	constructor() {
		this.modalOptions = {
			data: {},
			style: null
		};
	}

	data(modalData: any): ModalLauncherFactory {
		this.modalOptions.data = modalData;
		return this;
	}

	style(modalStyle: ModalStyle): ModalLauncherFactory {
		this.modalOptions.style = modalStyle;
		return this;
	}

	displayModal(componentClass: any, dialog: MatDialog): MatDialogRef<any> {
		const config = this.toConfig();
		return dialog.open(componentClass, config);
	}

	private toConfig(): MatDialogConfig {
		if (this.modalOptions.style == null) {
			return {
				data: this.modalOptions.data
			} as MatDialogConfig;
		} else {
			return {
				panelClass: this.modalOptions.style.panelClass,
				width: this.modalOptions.style.width,
				data: this.modalOptions.data
			} as MatDialogConfig;
		}
	}
}
