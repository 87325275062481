import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PayGroup } from "../../../../../shared/models/pay-groups";

@Component({
	selector: "app-paygroup",
	templateUrl: "./paygroup.component.html",
	styleUrls: ["./paygroup.component.scss"]
})
export class PaygroupComponent implements OnInit {
	@Input() payGroups: PayGroup[] = [];
	@Output() paygroupSelected = new EventEmitter<PayGroup>();

	constructor() {}

	ngOnInit(): void {}

	selectPayGroup(payGroup: PayGroup) {
		this.paygroupSelected.emit(payGroup);
	}
}
