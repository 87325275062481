import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { comfirmationPopUpTypes, confirmationConfig } from "./model/confirmation-pop-up.interface";

@Component({
	selector: "app-confirmation-pop-up",
	templateUrl: "./confirmation-pop-up.component.html",
	styleUrls: ["./confirmation-pop-up.component.scss"]
})
export class ConfirmationPopUpComponent implements OnInit {
	type = comfirmationPopUpTypes.SUCCESS;
	confirmButtonText?: string;
	backButtonText?: string;
	contextMessage?: string;
	confirmationMessage?: string;
	headerIcon = "";
	headerColor?: string;
	viewBox = "0 0 24 24";
	size = "30px";

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: confirmationConfig,
		public dialogRef: MatDialogRef<confirmationConfig>
	) {}
	ngOnInit(): void {
		this.data.type
			? ((this.type = this.data.type), this.setHeaderColor(this.data.type))
			: ((this.type = comfirmationPopUpTypes.SUCCESS), this.setHeaderColor(comfirmationPopUpTypes.SUCCESS));
		this.data.confirmButtonText
			? (this.confirmButtonText = this.data.confirmButtonText)
			: (this.confirmButtonText = "CONTINUE");
		this.data.backButtonText ? (this.backButtonText = this.data.backButtonText) : (this.backButtonText = "CANCEL");
		this.data.contextMessage ? (this.contextMessage = this.data.contextMessage) : (this.contextMessage = "");
		this.data.confirmationMessage
			? (this.confirmationMessage = this.data.confirmationMessage)
			: (this.confirmationMessage = "Are you sure?");
	}

	setHeaderColor(type: comfirmationPopUpTypes): void {
		//Currenlty this component is only being use to display a Warning pop up
		//Feel free to change success and info colors/icons to fit you needs

		switch (type) {
			case comfirmationPopUpTypes.SUCCESS:
				{
					this.headerIcon = "tick-circle";
					this.headerColor = "var(--color-success-400)";
				}
				break;
			case comfirmationPopUpTypes.WARNING:
				{
					this.headerIcon = "figma-warning";
					this.headerColor = "var(--inactive-text-red)";
					this.viewBox = "0 0 40 40";
					this.size = "38px";
				}
				break;
			case comfirmationPopUpTypes.INFO:
				{
					this.headerIcon = "info-circle";
					this.headerColor = "var(--color-primary-400)";
				}
				break;
		}
	}

	cancel(): void {
		this.dialogRef.close(false);
	}

	confirm(): void {
		this.dialogRef.close(true);
	}
}
