import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";

import { Store, select } from "@ngrx/store";
import { PermissionsService } from "@shared/services/permissions/permissions.service";
import { ToastService } from "@shared/services/toast/toast.service";
import { Pagination } from "@shared/models/pagination.interface";
import { getGlobalDashboardFilterState } from "@store/index";
import { GlobalDashboardFilterSelect } from "@store/models/globalDashboardFilterSelection.model";
import { AppState } from "@store/models/state.model";
import { Milestone, MilestoneGroupName, PayCycle } from "src/app/shared/models/pay-cycle.interface";
import { PayGroup } from "src/app/shared/models/pay-groups";
import { TppDataInputApi } from "../../../services/tpp-data-input-api.service";
import {
	TppSubService,
	TppSubServiceDetailsWithPagination,
	getEmployeeDataParams
} from "../../models/tpp-data.interface";

@Component({
	selector: "app-tpp-data-input",
	templateUrl: "./tpp-data-input.component.html",
	styleUrls: ["./tpp-data-input.component.scss"]
})
export class TppDataInputComponent implements OnInit, OnDestroy {
	//State variabled
	payGroup!: PayGroup;
	payCycle!: PayCycle;
	payCycleId: string = "";
	payCycleName: string = "";
	milestones!: Milestone[];
	milestone!: Milestone;
	showDropDown: boolean = false;

	subserviceToView!: TppSubService;
	subserviceToViewDetails!: TppSubServiceDetailsWithPagination;

	showPopup: boolean = false;

	subServices!: TppSubService[];

	pagination!: Pagination;

	pageSize: number = 10;
	pageIndex: number = 0;
	totalPageCount: number = 0;

	popUpPageSize: number = 10;
	popUpPageIndex: number = 0;
	canUploadFile = false;
	kpiSelected!: string;
	pageTitle = "Payment Orders";

	private destroy$: Subject<void> = new Subject<void>();

	constructor(
		private router: Router,
		private tppDataInputApi: TppDataInputApi,
		private toast: ToastService,
		private permissions: PermissionsService,
		private store: Store<AppState>
	) {}

	ngOnInit(): void {
		this.permissions
			.canUploadDataInputFile()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => {
				this.canUploadFile = res;
				this.getStatesFromMilestone();
			});
	}

	getStatesFromMilestone() {
		if (history.state) {
			this.payGroup = history.state.payGroup;
			this.payCycle = history.state.payCycle;
			this.milestones = history.state.milestones;
			this.milestone = history.state.milestone;

			this.payCycleId = this.payCycle?.id;
			this.payCycleName = this.payCycle.name;
			this.loadSubServices(
				this.pageSize,
				this.pageIndex,
				history.state.milestone.id,
				history.state.payCycle.id,
				history.state.milestone.group
			);
		}

		this.store.pipe(take(1), select(getGlobalDashboardFilterState)).subscribe({
			next: (state: GlobalDashboardFilterSelect) => {
				this.kpiSelected = state.globalDashboard.kpiSelected;
				if (this.router.url.includes("global-dashboard") && this.kpiSelected === "Failed Payment Orders")
					this.pageTitle = "Failed Payment orders";
			}
		});
	}

	loadSubServices(
		pageSize: number,
		pageIndex: number,
		milestoneId: string,
		payCycleId: string,
		tppType: MilestoneGroupName
	): void {
		this.pageSize = pageSize;
		this.pageIndex = pageIndex;

		this.tppDataInputApi
			.getPayCycleSubServices(milestoneId, payCycleId, tppType)
			.pipe(takeUntil(this.destroy$))
			.subscribe(subServices => {
				this.subServices = subServices;
			});

		this.pagination = this.tppDataInputApi.getPaginition();
	}

	goToTppFiles(): void {
		let route = "payments";

		if (this.router.url === "/global-dashboard/tpp-data-input") route = "global-dashboard";

		this.router.navigate([`/${route}/tpp-file-history`], {
			state: {
				payGroup: this.payGroup,
				payCycle: this.payCycle,
				milestones: this.milestones,
				milestone: this.milestone,
				subServices: this.subServices
			}
		});
	}

	showInputDropDown(val: boolean): void {
		this.showDropDown = !val;
	}

	uploadFile(file: any) {
		if (file[0].type !== "text/csv") {
			this.throwUploadError();
		} else {
			this.uploadCSVFile(file);
		}
	}

	throwUploadError() {
		this.toast.showError("Incorrect file format. CSV required.");
	}

	uploadCSVFile(file: any): void {
		this.tppDataInputApi
			.uploadFile(file, this.payCycleId, this.milestone.group)
			.pipe(takeUntil(this.destroy$))
			.subscribe(_ => this.goToTppFiles());
	}

	viewDetails(subservice: TppSubService, pageSize: number, pageNumber: number) {
		this.popUpPageSize = pageSize;
		this.popUpPageIndex = pageNumber;

		this.subserviceToView = subservice;

		this.subserviceToViewDetails = {} as TppSubServiceDetailsWithPagination;

		if (this.subserviceToView.paymentBy === "EMPLOYEE") {
			this.tppDataInputApi
				.getSubserviceDetails(
					this.payCycleId,
					this.milestone.id!,
					subservice,
					this.popUpPageSize,
					this.popUpPageIndex
				)
				.pipe(takeUntil(this.destroy$))
				.subscribe(details => {
					this.subserviceToViewDetails = details;
				});
		} else {
			this.subserviceToViewDetails = this.convertToSubServiceDetials(subservice);
		}

		this.showPopup = true;
	}

	paginateEmployees(data: getEmployeeDataParams) {
		this.viewDetails(data.subService, data.pageSize, data.pageNumber);
	}

	convertToSubServiceDetials(subservice: TppSubService): TppSubServiceDetailsWithPagination {
		return {
			items: [
				{
					paymentType: subservice.paymentBy,
					subserviceName: subservice.subService.name,
					reference: subservice.reference ? subservice.reference : "",
					amount: subservice.amount,
					valueDate: subservice.valueDate,
					uploaded: subservice.uploadedBy
				}
			],
			totalCount: 0,
			totalPages: 0,
			pageNumber: 0
		};
	}

	hideDetails(hide: boolean) {
		this.subserviceToViewDetails = {} as TppSubServiceDetailsWithPagination;
		this.showPopup = !hide;
	}

	saveDetails(data: FormGroup) {
		//Save new data
		this.showPopup = false;
	}

	goBack(): void {
		let route = "payments";

		if (this.router.url.includes("global-dashboard")) route = "global-dashboard";

		if ((this.kpiSelected === "Failed Payment Orders" || this.kpiSelected === "") && route === "global-dashboard") {
			this.router.navigate([`/${route}`]);
		} else {
			this.router.navigate([`/${route}/dashboard`], {
				state: { expandMilestoneGroupWithName: this.milestone.group }
			});
		}
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
