import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "payElectiveStatus"
})
export class PayElectiveStatusPipe implements PipeTransform {
	transform(value: string | undefined): string | undefined {
		if (value) {
			switch (value.toLowerCase()) {
				case "active":
					return "Success";
				case "inactive":
					return "Failed";
				case "validating":
					return "Validating";
				case "created":
				case "processing":
					return "Processing";
			}
		}

		return void 0;
	}
}
