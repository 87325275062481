<app-breadcrumbs [addOverviewTitle]="true" />

<div class="payments-paygroup-list-container">
	<div class="main-container">
		<div fxLayout="column">
			<customer-legal-entity-select
				[enableSelectAll]="true"
				[customerPaygroupStatus]="isClientRole ? 'LIVE' : 'LIVE, IMPLEMENTATION'"
				[onlyLegalEntitiesWithPaygroups]="true"
			>
			</customer-legal-entity-select>

			<div fxLayout="column" *ngIf="payGroups$ | async as paygroups">
				<ng-container *ngIf="paygroups.length && !busy; else noPayGroups">
					<div class="paygroup-items-container">
						<div class="paygroup-items-header" fxLayout="row" fxLayoutAlign="start center">
							<h3>PAYGROUPS LIST</h3>
						</div>
						<app-list-items
							(paygroupSelected)="paygroupSelected($event)"
							[paygroups]="paygroups"
						></app-list-items>
					</div>
					<app-pagination
						*ngIf="totalPageCount"
						[length]="totalPageCount"
						[pageSizeOptions]="[5, 10, 25, 100]"
						[pageSize]="pageSize"
						[pageIndex]="pageIndex"
						[paginationType]="paginationType"
						aria-label="Select Page"
					>
					</app-pagination>
				</ng-container>
				<ng-template #noPayGroups>
					<no-items-layout
						*ngIf="!busy && !paygroups.length && !selectedLegalEntityId"
						message="No legal entities / pay groups associated with this customer."
						buttonText="Setup A Legal Entity"
						(onClickButton)="navigateToEntitySetup()"
					>
					</no-items-layout>

					<no-items-layout
						*ngIf="!busy && !paygroups.length && selectedLegalEntityId"
						message="No legal entities / pay groups match your current filter."
					>
					</no-items-layout>
				</ng-template>
			</div>
		</div>
	</div>
</div>
