import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";

import { environment as env } from "@environments/environment";
import {
	CalendarErrorFilePagination,
	CalendarFile,
	CalendarFilePagination,
	DownloadTemplateFilePagination
} from "../model/calendar-file.model";
import { ToastService } from "@shared/services/toast/toast.service";

@Injectable({
	providedIn: "root"
})
export class CalendarFilesHistoryService {
	constructor(private httpClient: HttpClient, private toast: ToastService) {}

	createCalendarReportTask(): Observable<string> {
		return this.httpClient.post<string>(`${env.apiUri}/v1/reports/tasks/calendars`, {});
	}

	getReportCalendarDetails(id: string): Observable<Blob> {
		let params = new HttpParams();
		params = params.append("fileId", id);
		params = params.append("fileGroup", "CALENDARS");

		return this.httpClient.get<Blob>(`${env.apiUri}/v1/reports/tasks/calendars/${id}`, {
			responseType: "blob" as "json"
		});
	}

	searchInAllCalendarFiles(
		taskId: string,
		searchParameter = "",
		pageNumber = 0,
		pageSize = 10
	): Observable<CalendarErrorFilePagination> {
		const params = new HttpParams({
			fromString: `taskId=${taskId}&searchParameter=${searchParameter}&pageNumber=${pageNumber}&pageSize=${pageSize}`
		});

		return this.httpClient.get<CalendarErrorFilePagination>(`${env.apiUri}/v1/calendar/tasks/report`, { params });
	}

	fetchCalendarUploadedFilesHistoryByUser(
		userId: string,
		userEmail: string,
		perPage: number,
		pageIndex: number
	): Observable<CalendarFilePagination> {
		//const encoded = encodeURI(userId);

		if (userId) {
			return this.httpClient.get<CalendarFilePagination>(
				`${env.apiUri}/v1/global/tasks/files?userId=${userId}&topic=CALENDARS&pageSize=${perPage}&pageIndex=${pageIndex}`
			);
		} else {
			this.toast.showError(`No user was found by email: ${userEmail}`);
			return of({} as CalendarFilePagination);
		}
	}

	fetchCalendarTemplateDownloadRequests(): Observable<DownloadTemplateFilePagination> {
		return this.httpClient.get<DownloadTemplateFilePagination>(
			`${env.apiUri}/v1/reports/tasks/calendars?pageSize=-1&pageNumber=0&sortField=createdAt&sortDir=DESC`
		);
	}

	uploadCalendarFile(fileData: any): Observable<Blob> {
		return this.httpClient.post<Blob>(`${env.apiUri}/v1/calendars/files`, fileData);
	}

	searchFileIdByTaskId(taskId: string): Observable<any> {
		return this.httpClient.get<any>(`${env.apiUri}/v1/global/task/${taskId}/files`);
	}

	fetchFileAfterUploaded(fileId: string, fileGroup = "EMPLOYEES"): Observable<any> {
		return this.httpClient.get<Blob>(
			`${env.apiUri}/v1/private-resources/files/content?fileId=${fileId}&fileGroup=${fileGroup}`,
			{
				responseType: "blob" as "json"
			}
		);
	}
}
