import { Action } from "@ngrx/store";
import {
	CustomerEntityGroupActionType,
	CustomerEntityGroupSelectAction
} from "../actions/customerEntityGroupSelect.action";
import { CustomerEntityGroupSelect } from "../models/customerEnitityGroupSelection.model";
import { createRehydrateReducer } from "./state-persistance";

// Define the key for storing the state in local storage
const localStorageCustomerKey = "customerEntityGroupSelectState";

// Define the initial state of the reducer
const initialState: CustomerEntityGroupSelect = {
	customerId: "",
	customerName: "",
	legalEntityId: "",
	payGroupId: ""
};

// Define the reducer function
const customerEntityGroupSelectReducerFn = (
	state: CustomerEntityGroupSelect = initialState,
	action: Action
): CustomerEntityGroupSelect => {
	switch (action.type) {
		case CustomerEntityGroupActionType.SET_SELECTED:
			// Cast the action to CustomerEntityGroupSelectAction to access its payload
			const selectAction = action as CustomerEntityGroupSelectAction;

			return {
				...state,
				customerId: selectAction.payload.customerId,
				customerName: selectAction.payload.customerName,
				legalEntityId: selectAction.payload.legalEntityId,
				payGroupId: selectAction.payload.payGroupId
			};

		default:
			return state;
	}
};

// Create the final reducer by combining the rehydrate logic with the custom reducer function
export const customerEntityGroupSelectReducer = createRehydrateReducer<CustomerEntityGroupSelect, Action>(
	localStorageCustomerKey,
	customerEntityGroupSelectReducerFn
);
