import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AppState } from "@auth0/auth0-angular";
import { Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { breadcrumbDataObject } from "@shared/components/modal-wrapper/_models/breadcrumb.interface";
import { PayMethodDetail } from "@modules/employee-data/types/accounts";
import { ApiService } from "@modules/pay-groups/services/api.service";
import { PayoutsService } from "@shared/services/payouts/payouts.service";
import { PayGroup } from "src/app/shared/models/pay-groups";
import { Payout, Transaction, TransactionPagination } from "src/app/shared/models/payouts.types";
import { UpdateCustomerEntityGroupAction } from "src/app/store/actions/customerEntityGroupSelect.action";
import { UpdateProvider } from "src/app/store/actions/providerSelect.action";
import { PermissionsService } from "../../../../../shared/services/permissions/permissions.service";
import { PaymentsApiService } from "../../../services/payments-api.service";
import { InactivateToggleDialogComponent } from "../payout-list-item/inactivate-toggle-dialog/inactivate-toggle-dialog.component";

@Component({
	selector: "app-payout-list-item",
	templateUrl: "./payout-list-item.component.html",
	styleUrls: ["./payout-list-item.component.scss"]
})
export class PayoutListItemComponent implements OnInit {
	@Input() payout!: Payout;
	@Input() last: boolean = false;
	@Input() cycleName: string = "";
	@Input() set _isCancelledRun(cancelled: boolean) {
		this.isCancelledRun = cancelled;
	}

	isCancelledRun: boolean = false;
	reProcesMapPayoutsAndTxId: Map<string, string[]> = new Map();
	payGroup$: Observable<PayGroup> = new Observable<PayGroup>();
	payoutTransactions!: Transaction[];

	//State
	customerName = "";
	payGroupName = "";
	country = "";
	customerId = "";
	legalEntityId = "";
	payGroupId = "";
	externalId = "";
	paymentProvider = "";
	breadcrumbArray: breadcrumbDataObject[] = [];
	payMethodTypes: string[] = [];
	showAccounts = false;
	payMethods: Observable<PayMethodDetail[]> = new Observable<PayMethodDetail[]>();
	activeToggle = true;
	isCheckBoxChecked = false;
	disableToggle = false;
	canDisablePayout = false;
	canReprocessPayment = false;
	errorCounter = 0;
	hasReprocessing = false;
	reprocessIds: string[] = [];
	isDisabled = false;

	private destroy$: Subject<void> = new Subject();

	constructor(
		private payoutsService: PayoutsService,
		private router: Router,
		private payGroupApiService: ApiService,
		private store: Store<AppState>,
		private permissions: PermissionsService,
		private paymentApiService: PaymentsApiService,
		private dialog: MatDialog,
		private payoutService: PayoutsService
	) {}

	ngOnInit(): void {
		this.permissions
			.canInactivatePayment()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => (this.canDisablePayout = res));

		this.permissions
			.canReprocessPaymentsNETS()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => (this.canReprocessPayment = res));

		if (this.payout.status.name.toString() === "INACTIVE") {
			this.activeToggle = false;
		}

		this.loadPayMethods();

		this.getReprocessIds();

		this.loadTransactionHistory();

		this.permissions
			.disableAccessToEditableFormField(["TS_IMPLEMENTATION_LEAD", "TS_OPERATION_LEAD"])
			.subscribe(isDisabled => (this.isDisabled = isDisabled));
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	loadPayMethods(): void {
		if (this.payout.transactions) {
			this.paymentProvider = this.payout.transactions[0].paymentProvider;

			this.payout.transactions.forEach(x => {
				this.payMethodTypes.push(x.accountType);
			});
		}
	}

	showAccountsToggle(): void {
		this.showAccounts = !this.showAccounts;
	}

	inactivateEmployeeCheck(payout: Payout, event: any): void {
		// check if all transactions have failed
		this.payoutsService.searchPayoutsTransactions(payout.id).subscribe((response: TransactionPagination) => {
			var item = response.items.find((item: Transaction) => {
				if (item.status.name == "SUCCESS") return true;
				else return false;
			});

			if (item) {
				event.source.checked = true;
			} else {
				event.source.checked = true;
				this.inactivateEmployee(payout);
			}
		});
	}

	inactivateEmployee(payout: Payout): void {
		this.openDialog(payout);
	}

	goToHistory(payCycleId: string): void {
		this.payGroup$ = this.payGroupApiService.getPayGroup(this.payout.payGroupId);

		this.payGroup$.pipe(takeUntil(this.destroy$)).subscribe(res => {
			this.country = res.legalEntity.data.country;
			this.customerName = res.customer.name;
			this.payGroupName = res.data.name;
			this.customerId = res.customer.id;
			this.legalEntityId = res.legalEntityId;
			this.externalId = res.externalId;

			this.store.dispatch(
				new UpdateProvider({
					provider: this.paymentProvider
				})
			);

			this.store.dispatch(
				new UpdateCustomerEntityGroupAction({
					customerId: this.customerId,
					legalEntityId: this.legalEntityId,
					payGroupId: this.payout.payGroupId,
					customerName: this.customerName
				})
			);

			let route = "employee-data";

			if (this.router.url === "/global-dashboard/payouts") route = "global-dashboard";

			this.router.navigate([`/${route}/employee`], {
				state: {
					employee: this.payout.employee,
					country: this.country,
					customerName: this.customerName,
					legalName: this.payGroupName,
					payCycleRunName: this.cycleName,
					payCycleId: payCycleId
				}
			});
		});
	}

	setIds(event: any, payoutId: string): void {
		let transactionsID: string[] = [];

		if (this.activeToggle) {
			if (event.checked) {
				this.isCheckBoxChecked = true;
				this.payoutTransactions.forEach((tx: Transaction) => {
					if (this.paymentApiService.canReprocess(tx.status.name.toString())) {
						transactionsID.push(tx.id);
					}
				});
				this.reProcesMapPayoutsAndTxId.set(payoutId, transactionsID);
				this.updateReprocessIdsObservable(this.reProcesMapPayoutsAndTxId);
			} else {
				this.isCheckBoxChecked = false;
				this.reProcesMapPayoutsAndTxId.delete(payoutId);
				this.updateReprocessIdsObservable(this.reProcesMapPayoutsAndTxId);
			}
		}
	}

	updateReprocessIdsObservable(reProcesMapPayoutsAndTxId: Map<string, string[]>): void {
		this.payoutsService.updateReprocessIds(reProcesMapPayoutsAndTxId);
	}

	getReprocessIds(): void {
		this.payoutsService
			.reprocessIdsObservable()
			.pipe(takeUntil(this.destroy$))
			.subscribe((res: Map<string, string[]>) => {
				this.reProcesMapPayoutsAndTxId = res;

				if (this.reProcesMapPayoutsAndTxId.has(this.payout.id)) {
					this.isCheckBoxChecked = true;
				} else this.isCheckBoxChecked = false;
			});
	}

	loadTransactionHistory(): void {
		var hasASuccess: boolean = false;
		this.payoutTransactions = this.payout.transactions!;
		this.payoutTransactions.forEach(transaction => {
			if (
				transaction.status.name.toLocaleLowerCase() === "failed" ||
				transaction.status.name.toLocaleLowerCase() === "attempts_limit_exceeded" ||
				transaction.status.name.toLocaleLowerCase() === "error" ||
				transaction.status.name.toLocaleLowerCase() === "failed_to_book"
			) {
				this.errorCounter++;
			} else if (
				transaction.status.name.toLocaleLowerCase() === "success" ||
				transaction.status.name.toLocaleLowerCase() === "processed"
			) {
				hasASuccess = true;
			}
		});

		if (
			hasASuccess ||
			this.payout.status.name.toString() === "INACTIVE" ||
			this.payout.status.name.toString() === "PROCESSING"
		) {
			this.disableToggle = true;
		}
	}

	openDialog(payout: Payout): void {
		let dialogRefData;

		dialogRefData = {
			payout: payout
		};

		const dialogRef = this.dialog.open(InactivateToggleDialogComponent, {
			width: "666px",
			height: "441px",
			panelClass: "dialog-container",
			data: dialogRefData
		});

		dialogRef.afterClosed().subscribe((result: Payout) => {
			if (result) {
				this.payoutService
					.inactiveNetPayout(result.payCycleId, result.employee.id!)
					.pipe(takeUntil(this.destroy$))
					.subscribe(res => {
						this.payoutsService.reload();
						this.loadPayMethods();
					});
			}
		});
	}
}
