import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PaymentsComponent } from "./payments.component";
import { PaygroupListComponent } from "./_components/paygroup-list/paygroup-list.component";
import { DashboardCalendarComponent } from "./_components/dashboard-calendar/dashboard-calendar.component";
import { FileHistoryComponent } from "./_components/file-history/file-history.component";
import { TppDataInputComponent } from "./tpp-payments/containers/tpp-data-input/tpp-data-input.component";
import { TppFileHistoryComponent } from "./tpp-payments/containers/tpp-file-history/tpp-file-history.component";
import { FileErrorsComponent } from "./_components/file-errors/file-errors.component";
import { PayoutsComponent } from "./_components/payouts/payouts.component";
import { TppPaymentsComponent } from "./tpp-payments/containers/tpp-payments/tpp-payments.component";
import { PayElectiveComponent } from "../employee-data/_components/pay-elective/pay-elective.component";

const routes: Routes = [
	{
		path: "",
		component: PaymentsComponent
	},
	{
		path: "paygroups",
		component: PaygroupListComponent,
		children: []
	},
	{
		path: "dashboard",
		component: DashboardCalendarComponent
	},
	{
		path: "file-history",
		component: FileHistoryComponent
	},
	{
		path: "tpp-data-input",
		component: TppDataInputComponent
	},
	{
		path: "tpp-file-history",
		component: TppFileHistoryComponent
	},
	{
		path: "file-errors",
		component: FileErrorsComponent
	},
	{
		path: "payouts",
		component: PayoutsComponent
	},
	{
		path: "tpp-payments",
		component: TppPaymentsComponent
	},
	{
		path: "employee-data",
		component: PayElectiveComponent
	},
	{
		path: "payment-orders",
		loadChildren: () =>
			import("@modules/net-payment-orders/net-payment-orders.module").then(m => m.NetPaymentOrdersModule)
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class PaymentsRoutingModule {}
