import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { PaymentTypeName, PayoutAccountName } from "src/app/shared/models/service-provider.type";
import { Capability } from "../../../../../shared/models/service-provider-country-types";
import { ProviderCountryTemplateService } from "../../../_services/provider-country-template/provider-country-template.service";
import { ServiceProviderCountryEditService } from "../../../_services/service-provider-country-edit/service-provider-country-edit.service";

@Component({
	selector: "app-capabilties-edit-form",
	templateUrl: "./capabilties-edit-form.component.html",
	styleUrls: ["./capabilties-edit-form.component.scss"]
})
export class CapabiltiesEditFormComponent implements OnInit, OnDestroy {
	@Input() isSuperUser: boolean = false;
	capabilitiesForm!: FormGroup;

	paymentTypes: PaymentTypeName[] = [];
	payoutAccounts: SelectOption[] = [];
	routes: SelectOption[] = [];
	mappedTypes: Map<PaymentTypeName, Capability[]> = new Map<PaymentTypeName, Capability[]>([]);
	mappedRoutes: Map<PayoutAccountName, SelectOption[]> = new Map<PayoutAccountName, SelectOption[]>([]);

	destroy$: Subject<void> = new Subject();

	@Output() onFormChecked: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private readonly formBuilder: FormBuilder,
		private readonly serviceProviderCountryEditService: ServiceProviderCountryEditService,
		private readonly providerCountryTemplateService: ProviderCountryTemplateService
	) {}

	ngOnInit(): void {
		this.initForm();
		this.initSubscriptions();
		this.onFormChanges();
	}

	initForm(): void {
		this.capabilitiesForm = this.formBuilder.group({});

		this.paymentTypes = this.providerCountryTemplateService.getPaymentTypes();

		this.paymentTypes.filter(type => {
			this.mappedTypes.set(
				type,
				this.serviceProviderCountryEditService?.getPaymentTypeCapabilities()?.find(c => c.paymentType === type)
					?.capabilities!
			);
		});

		// console.log(this.mappedTypes);

		for (const [type, capability] of this.mappedTypes.entries()) {
			const group = this.formBuilder.group({
				paymentType: [type],
				selected: [{ value: !!capability, disabled: !this.isSuperUser }],
				payoutAccountRoutes: this.formBuilder.array([])
			});

			this.capabilitiesForm.addControl(type.toString(), group);

			group
				.get("selected")
				?.valueChanges.pipe(takeUntil(this.destroy$))
				.subscribe(selected => {
					if (selected) {
						this.getCapabilitiesFormGroupArray(type).clear();
						this.addAccountRoute(type, true);
					} else {
						this.removeRow(type, 0, true);
						const newGroup = this.formBuilder.group({
							payoutAccount: [{ value: "", disabled: true }],
							route: [{ value: "", disabled: true }],
							eta: [{ value: "", disabled: true }]
						});

						this.getCapabilitiesFormGroupArray(type).push(newGroup);
						// this.addAccountRoute(type, true);
					}
				});

			// To init form with data from api or with default data
			if (capability) {
				capability.filter(c => {
					const newGroup = this.formBuilder.group({
						payoutAccount: [{ value: c.payoutAccount, disabled: true }],
						route: [{ value: c.route.toString().replace("_", " "), disabled: true }],
						eta: [{ value: c.eta, disabled: true }]
					});
					this.getCapabilitiesFormGroupArray(type).push(newGroup);
				});
			} else {
				const newGroup = this.formBuilder.group({
					payoutAccount: [{ value: "", disabled: true }],
					route: [{ value: "", disabled: true }],
					eta: [{ value: "", disabled: true }]
				});

				this.getCapabilitiesFormGroupArray(type).push(newGroup);
			}
		}
	}

	addAccountRoute(paymentType: PaymentTypeName, addValidation: boolean) {
		if (addValidation) {
			const newGroup = this.formBuilder.group({
				payoutAccount: [null, Validators.required],
				route: [null, Validators.required],
				eta: [null, Validators.required]
			});

			this.getCapabilitiesFormGroupArray(paymentType).push(newGroup);
		} else {
			const newGroup = this.formBuilder.group({
				payoutAccount: [null],
				route: [null],
				eta: [null]
			});

			this.getCapabilitiesFormGroupArray(paymentType).push(newGroup);
		}
	}

	getPayoutAccouts(paymentType: PaymentTypeName): SelectOption[] {
		return this.providerCountryTemplateService.mappedPayoutAccount.get(paymentType)!;
	}

	getRoutes(payoutAccountName: PayoutAccountName): SelectOption[] {
		return this.providerCountryTemplateService.mappedRoutes.get(payoutAccountName)!;
	}

	resetRow(paymentType: PaymentTypeName, index: number) {
		this.getCapabilitiesFormGroupArray(paymentType)
			.at(index)
			.get("payoutAccount")
			?.patchValue(null, Validators.required);
		this.getCapabilitiesFormGroupArray(paymentType).at(index).get("route")?.patchValue(null, Validators.required);
		this.getCapabilitiesFormGroupArray(paymentType).at(index).get("eta")?.patchValue("", Validators.required);

		// Form validation
	}

	removeRow(paymentType: PaymentTypeName, index: number, removeType: boolean) {
		const payoutAccount = this.getCapabilitiesFormGroupArray(paymentType).at(index).get("payoutAccount")?.value;
		const route = this.getCapabilitiesFormGroupArray(paymentType).at(index).get("route")?.value;
		this.serviceProviderCountryEditService.removeCapabilities(paymentType, payoutAccount, route, removeType);
		this.getCapabilitiesFormGroupArray(paymentType).removeAt(index);
		console.log(this.capabilitiesForm.getRawValue());
	}

	typeSelected(paymentType: PaymentTypeName): boolean {
		return !!this.capabilitiesForm.get(`${paymentType}.selected`)?.value;
	}

	getCapabilitiesFormGroupArray(paymentType: PaymentTypeName) {
		return this.capabilitiesForm.get(`${paymentType}.payoutAccountRoutes`) as FormArray;
	}

	onFormChanges(): void {
		this.capabilitiesForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((data: any) => {
			//tell edit page that the form has changed
			this.serviceProviderCountryEditService.formChanges();
			//Capabilities form change
			this.serviceProviderCountryEditService.initCapabilitiesChanged$.emit();

			// Get the status of form
			const isValid: boolean = this.getFormStatus();
			console.log(isValid);
			// Set status of form
			setTimeout(() => this.serviceProviderCountryEditService.capabilitiesEditChanged(isValid), 100);

			// Emit that form was checked
			this.onFormChecked.emit();
		});
	}

	onCheckForm(): void {
		// Get data of form
		const data: any = this.capabilitiesForm.getRawValue();

		// Get the status of form
		const isValid: boolean = this.getFormStatus();

		// Set status of form
		setTimeout(() => this.serviceProviderCountryEditService.capabilitiesEditChanged(isValid), 100);
	}

	getFormStatus(): boolean {
		//TODO: I will look at this method again but I think it has been over complicated and should just return the form status instead

		// console.log("formStatus", this.capabilitiesForm.valid);
		// // Initial value (only it will change if there is an invalid subform)
		// let isValid: boolean = true;
		// let selected: boolean = false;

		// for (const [type, capability] of this.mappedTypes.entries()) {
		// 	if (!!capability) {
		// 		selected = true;
		// 		const anyPayoutAccountInvalid: boolean = !!capability.length;
		// 		const anyRouteInvalid: boolean = !!capability.length;

		// 		if (anyPayoutAccountInvalid || anyRouteInvalid) {
		// 			isValid = false;
		// 		}
		// 	}
		// 	// If there are not any paymentType selected de form should be considered invalid
		// }
		// isValid = !selected ? false : isValid;

		return this.capabilitiesForm.valid;
	}

	initSubscriptions(): void {
		this.serviceProviderCountryEditService.updateCapabilityData$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.serviceProviderCountryEditService.updateCapabilities(this.capabilitiesForm.getRawValue());
		});

		this.serviceProviderCountryEditService.initCostsChanged$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.onCheckForm();
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
