import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Location } from "@angular/common";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { shareReplay, takeUntil, map, take } from "rxjs/operators";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { CountriesService } from "@shared/services/countries/countries.service";
import { CurrencyService } from "@shared/services/currency/currency.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { AppState } from "src/app/store/models/state.model";
import { getSelectedTPPServiceState, getTppBeneficiary } from "../../../../../store";
import { BankAccountFieldsComponent } from "@shared/components/bank-account-fields/bank-account-fields.component";
import { TppAccountsService } from "@shared/services/tpp-accounts/tpp-accounts.service";
import { TppAccount } from "src/app/shared/models/tpp-account.interface";
import { BankAccountsService } from "@shared/services/bank-accounts/bank-accounts.service";
import { TppCommonServiceService } from "@shared/services/tpp-common-service/tpp-common-service.service";
import { TppService } from "src/app/shared/models/tpp-service.interface";

@Component({
	selector: "app-tpp-service-config-edit-beneficiary",
	templateUrl: "./tpp-service-config-edit-beneficiary.component.html",
	styleUrls: ["./tpp-service-config-edit-beneficiary.component.scss"]
})
export class TppServiceConfigEditBeneficiaryComponent implements OnInit {
	newBeneficiaryForm!: FormGroup;
	countries$!: Observable<SelectOption[]>;
	currencies$: Observable<SelectOption[]> = new Observable<SelectOption[]>();
	formIsPristine: boolean = true;
	serviceId: string = "";
	public selectedCountry: string | undefined;
	public bankAccountValues: Record<string, string | number | boolean> | undefined;
	selectedCountryName!: SelectOption;
	bankName!: string;
	formsInitialized: boolean = false;

	data: string = "";

	destroy$: Subject<void> = new Subject();

	selectedAccount!: TppAccount;
	loadingComplete: boolean = false;

	title: string = "Add Beneficiary";
	mode: string = "add";

	subServiceId: string = "";
	paygroupId: string = "";

	//ViewChild
	@ViewChild(BankAccountFieldsComponent) bankAccountFieldsComponent: BankAccountFieldsComponent | undefined;

	bankAccountFieldsValid: boolean = true;
	bankAccountFieldsPristine: boolean = true;

	disableButton: boolean = false;
	enabledButtonColor: boolean = false;

	readOnlyMode = false;

	constructor(
		private readonly _location: Location,
		private formBuilder: FormBuilder,
		private countriesService: CountriesService,
		private currencyService: CurrencyService,
		private router: Router,
		private tppCommonService: TppCommonServiceService,
		private store: Store<AppState>,
		private bankAccountsService: BankAccountsService
	) {}

	ngOnInit(): void {
		if (history.state) {
			if (history.state.mode === "edit") {
				this.title = "Edit beneficiary";
			}

			this.readOnlyMode = history.state.readonly ? history.state.readonly : false;
			this.subServiceId = history.state.subServiceId;
			this.mode = history.state.mode;
		}

		this.countries$ = this.countriesService.getCountryOptions().pipe(shareReplay(1));
		this.currencies$ = this.currencyService.getCurrencyOptions().pipe(shareReplay(1));

		this.store.pipe(takeUntil(this.destroy$), select(getSelectedTPPServiceState)).subscribe(state => {
			this.serviceId = state.selectedTPPService.tppService!.id;
			this.paygroupId = state.selectedTPPService.selectedPayGroup!.id;
			this.selectedCountry = state.selectedTPPService.tppService?.country;
		});

		this.countries$.pipe(take(1)).subscribe(countries => {
			this.selectedCountryName = countries.find(country => country.value === this.selectedCountry)!;
		});

		this.store.pipe(takeUntil(this.destroy$), select(getTppBeneficiary)).subscribe(state => {
			this.selectedAccount = state.beneficiary;
		});

		this.bankName = this.selectedAccount.bankAccount.bankName;

		this.bankAccountsService.formUpdated$.pipe(takeUntil(this.destroy$)).subscribe((result: boolean) => {
			this.bankAccountFieldsPristine = result;
			this.checkForms();
		});

		this.bankAccountsService.formUpdatedValidity$.pipe(takeUntil(this.destroy$)).subscribe((result: boolean) => {
			this.bankAccountFieldsValid = result;
			this.checkForms();
		});

		this.initializeForm();
	}

	initializeForm(): void {
		this.newBeneficiaryForm = this.formBuilder.group({
			account_name: ["", Validators.required],
			country: ["", Validators.required],
			currency: ["", Validators.required]
		});

		if (this.readOnlyMode) {
			this.newBeneficiaryForm.disable();
		}

		this.newBeneficiaryForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(val => {
			this.formIsPristine = this.newBeneficiaryForm.pristine;

			this.checkForms();
		});

		this.newBeneficiaryForm.patchValue({
			country: this.selectedCountryName
		});

		if (this.mode === "edit") {
			this.newBeneficiaryForm.get("account_name")?.patchValue(this.selectedAccount.name);
			this.newBeneficiaryForm.get("country")?.patchValue(this.selectedAccount.country);
			this.newBeneficiaryForm.get("currency")?.patchValue(this.selectedAccount.currency);

			const record = this.selectedAccount.bankAccount.fields.reduce((acc, curr) => {
				acc[curr.key] = curr.value;
				return acc;
			}, {} as Record<string, string | number | boolean>);

			this.bankAccountValues = record;
		} else {
			this.newBeneficiaryForm.get("country")?.patchValue(this.selectedCountry);
		}
	}

	checkForms() {
		if (this.newBeneficiaryForm.valid && this.bankAccountFieldsValid) {
			this.disableButton = false;
			this.enabledButtonColor = false;
		} else {
			this.disableButton = true;
			this.enabledButtonColor = true;
		}
	}

	backFromModal() {
		this._location.back();
		//this.router.navigate(["/dashboard/service-definition/service-configuration-tpp"]);
	}

	saveBeneficiary(): void {
		//split forms
		const rawFormData: { account_name: string; country: string; currency: string } =
			this.newBeneficiaryForm.getRawValue();

		if (this.mode === "edit") {
			const accountId = this.selectedAccount.id;
			const accountVersion = this.selectedAccount.version;

			const benId = this.selectedAccount.bankAccount.id;
			const benVersion = this.selectedAccount.bankAccount.version;

			this.tppCommonService.saveNewBeneficiary(
				rawFormData,
				this.bankAccountFieldsComponent!.bankAccountForm.controls,
				this.serviceId,
				true,
				this.subServiceId,
				this.paygroupId,
				accountId,
				accountVersion,
				benId,
				benVersion
			);
		} else {
			this.tppCommonService.saveNewBeneficiary(
				rawFormData,
				this.bankAccountFieldsComponent!.bankAccountForm.controls,
				this.serviceId,
				false,
				this.subServiceId,
				this.paygroupId
			);
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
