import { Pipe, PipeTransform } from "@angular/core";
import { TppService } from "src/app/shared/models/tpp-service.interface";

@Pipe({
	name: "orderServicesType"
})
export class OrderServicesTypePipe implements PipeTransform {
	transform(tppServices: TppService[]): Map<string, TppService[]> {
		const tppServicesTypes: Map<string, TppService[]> = new Map();
		tppServices.forEach((service: TppService) => {
			if (tppServicesTypes.has(service.type)) {
				if (tppServicesTypes.get(service.type) != undefined) {
					const tppServiceTypeList: TppService[] | undefined = tppServicesTypes
						.get(service.type)
						?.concat([service]);
					if (tppServiceTypeList) tppServicesTypes.set(service.type, tppServiceTypeList);
				}
			} else {
				tppServicesTypes.set(service.type, [service]);
			}
		});

		return tppServicesTypes;
	}
}
