import { Component, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { CalendarFileListComponent } from "../calendar-file-list/calendar-file-list.component";
@Component({
	selector: "app-calendar-setup-file-history",
	templateUrl: "./calendar-setup-file-history.component.html",
	styleUrls: ["./calendar-setup-file-history.component.scss"]
})
export class CalendarSetupFileHistoryComponent {
	@ViewChild(CalendarFileListComponent) calendarFileList!: CalendarFileListComponent;

	isShowLoadMore = false;
	calendarFilesCount = 0;
	reachedAllCalendarFilesRecords = false;

	constructor(private router: Router) {}

	backFromModal(): void {
		this.router.navigate(["/calendar"]);
	}

	/**
	 * Get total number of calendar records
	 * @param count: Total number of calendar records
	 */
	calendarFilesTotalCount(count: number): void {
		this.isShowLoadMore = this.calendarFilesCount < count && count !== 0;
		this.reachedAllCalendarFilesRecords = !this.isShowLoadMore && count !== 0;
	}

	/**
	 * Get current number of calendar records displayed
	 * @param count: Current number of calendar records displayed
	 */
	calendarFilesCurrentCount(count: number): void {
		this.calendarFilesCount = count;
	}
}
