export const EVENT_TIMES: string[] = [
	"8:00 AM",
	"9:00 AM",
	"10:00 AM",
	"11:00 AM",
	"12:00 PM",
	"13:00 PM",
	"14:00 PM",
	"15:00 PM",
	"16:00 PM",
	"17:00 PM",
	"18:00 PM",
	"19:00 PM",
	"20:00 Pm"
];
