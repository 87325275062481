import { Location } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { Observable, Subject, Subscription, forkJoin } from "rxjs";
import { map, take, takeUntil } from "rxjs/operators";

import { ApiService } from "@modules/pay-groups/services/api.service";
import { CustomerEntitySelection } from "src/app/shared/models/customer-entity-selection.interface";
import { PayGroup } from "src/app/shared/models/pay-groups";
import { FileManagementUploadService } from "../../../../shared/services/file-management/file-management-upload.service";
import { FileManagementService } from "../../../../shared/services/file-management/file-management.service";
import { PermissionsService } from "../../../../shared/services/permissions/permissions.service";
import { ToastService } from "../../../../shared/services/toast/toast.service";
import {
	FileManagerPlaceholdersPagination,
	FileManagerTask,
	FileManagerTaskPagination,
	FileStatus,
	FileTypes
} from "../../../../shared/models/file-manager";
import { getCustomerEntityGroupState } from "../../../../store";
import { AppState } from "../../../../store/models/state.model";
import { PayElectiveBankAccountsApiService } from "../../../employee-data/services/pay-elective-bank-accounts-api.service";
@Component({
	selector: "app-file-manager",
	templateUrl: "./file-manager.component.html",
	styleUrls: ["./file-manager.component.scss"]
})
export class FileManagerComponent implements OnInit, OnDestroy {
	files$!: Observable<FileManagerTaskPagination>;
	fileResults!: FileManagerTask[];
	placeHolder$!: Observable<FileManagerPlaceholdersPagination>;
	pageIndex: number = 0;
	pageSize: number = 0;
	totalPageCount!: number;
	destroy$: Subject<void> = new Subject();
	selectedCustomerId!: string;
	selectedLegalEntityId!: string;
	selectedPayGroupId!: string;
	selectedPayGroup$!: Observable<PayGroup>;
	canUploadFiles: boolean = false;
	fetchingFiles = true;
	defaultFileManagerCount = 20;
	isShowLoadMore = false;

	private _updateFilesSubscription = new Subscription();

	constructor(
		private fileManagementApiService: FileManagementService,
		private commonFileManagerService: FileManagementUploadService,
		private toastService: ToastService,
		private store: Store<AppState>,
		private router: Router,
		private apiService: ApiService,
		private permissions: PermissionsService,
		private readonly _location: Location,
		private payElectiveBankAccountsApiService: PayElectiveBankAccountsApiService
	) {}

	ngOnInit(): void {
		// Get data
		this.store.pipe(takeUntil(this.destroy$), select(getCustomerEntityGroupState)).subscribe(state => {
			this.selectedCustomerId = state.customerId;
			this.selectedLegalEntityId = state.legalEntityId;
			this.selectedPayGroupId = state.payGroupId;
		});

		this.pageSize = this.defaultFileManagerCount;

		// Get Files
		this.getExistingFiles({ payGroupId: this.selectedPayGroupId } as CustomerEntitySelection);

		// Subscription to get files
		this._updateFilesSubscription = this.commonFileManagerService.updateMasterDataFiles$.subscribe(() => {
			this.getExistingFiles({ payGroupId: this.selectedPayGroupId } as CustomerEntitySelection);
		});

		this.permissions.canUploadEmployeesFiles().subscribe(res => (this.canUploadFiles = res));
	}

	getExistingFiles(data: CustomerEntitySelection): void {
		this.selectedPayGroupId = data.payGroupId ? data.payGroupId : "";
		if (!!this.selectedPayGroupId) {
			this.getFiles(this.selectedPayGroupId, this.pageSize, this.pageIndex);
			this.selectedPayGroup$ = this.apiService.getPayGroup(this.selectedPayGroupId);
		}
	}

	uploadPayElectiveFile(files: any): void {
		try {
			const file: File = files[0];

			let formData = new FormData();
			formData.append("file", file);
			formData.append("payGroupId", this.selectedPayGroupId);
			formData.append("fileType", "EXCEL");
			formData.append("sheetIndex", "0");
			formData.append("headerIndex", "4");

			this.commonFileManagerService.uploadMasterFile(formData);
		} catch (error) {
			this.toastService.showError("There was an error loading the file. Please try again");
		}
	}

	refreshFileManager(): void {
		this.pageSize = this.defaultFileManagerCount;
		this.getFiles(this.selectedPayGroupId, this.pageSize, this.pageIndex);
	}

	refreshIntegration(): void {
		this.fileManagementApiService
			.refreshPayrollIntegration(this.selectedPayGroupId)
			.pipe(take(1))
			.subscribe({
				next: res => {
					switch (res.status) {
						case 200:
							this.toastService.showSuccess(
								"Request to trigger payroll integration was sent sucessfully"
							);
							this.refreshFileManager();
							break;
						default:
							this.toastService.showError(
								"An error has occured while trying to send a request to trigger the payroll integration"
							);
							break;
					}
				},
				error: _ => {
					this.toastService.showError(
						"An error has occured while trying to send a request to trigger the payroll integration"
					);
				},
				complete: () => {
					this.reprocessAll();
				}
			});
	}

	getFiles(paygroupId: string, pageSize: number, pageNumber: number): void {
		this.fetchingFiles = true;
		this.files$ = this.fileManagementApiService.getFilesPayElectivesyPayGroupID(paygroupId, pageSize, pageNumber);
		this.placeHolder$ = this.fileManagementApiService.getFilesPlaceholder(paygroupId);
		const requests = forkJoin([this.files$, this.placeHolder$]);

		requests.pipe(take(1)).subscribe({
			next: (results: [FileManagerTaskPagination, FileManagerPlaceholdersPagination]) => {
				const [fileResults, placeholderResults] = results;

				this.totalPageCount = fileResults.totalCount;

				// By using JSON.parse(JSON.stringify(fileResults)) we create a new object with the same values as fileResults, effectively making a deep copy.
				// This prevents changes to fileResults from affecting initialFileResults, ensuring that initialFileResults retains the original data.
				const initialFileResults = JSON.parse(JSON.stringify(fileResults));
				let placeholderResultsCount = 0;

				placeholderResults.items.forEach(placeholder => {
					if (
						this.fileManagementApiService.isValidIntegrationTaskId(placeholder, initialFileResults.items) &&
						this.fileManagementApiService.isValidCreatedAt(
							placeholder,
							initialFileResults.items,
							this.pageIndex
						)
					) {
						fileResults.items.push({
							taskId: "",
							createdAt: new Date(placeholder.createdAt),
							fileId: "",
							fileName: "-",
							fileType: FileTypes.PAY_ELECTIVES,
							status: FileStatus.PENDING
						});
						placeholderResultsCount++;
					}
				});

				this.fileResults = this.orderFilesByDesc(fileResults.items).slice(0, this.pageSize);
				this.isShowLoadMore = this.fileResults.length < this.totalPageCount + placeholderResultsCount;
				this.fetchingFiles = false;
			}
		});
	}

	orderFilesByDesc(array: FileManagerTask[]): FileManagerTask[] {
		if (!Array.isArray(array)) {
			return array;
		}

		return array.sort((a, b) => {
			const dateA = new Date(a.createdAt);
			const dateB = new Date(b.createdAt);

			return dateB.getTime() - dateA.getTime();
		});
	}

	/**
	 * Show more master file records
	 */
	loadMoreFilesData(): void {
		this.pageSize += this.defaultFileManagerCount;
		this.getExistingFiles({ payGroupId: this.selectedPayGroupId } as CustomerEntitySelection);
	}

	onPayElectives(): void {
		this.router.navigate(["/employee-data/master-data"]);
	}

	onDashboard(): void {
		this.router.navigate(["/employee-data/paygroups"]);
	}

	backFromModal(): void {
		this._location.historyGo(-1);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
		if (this._updateFilesSubscription) {
			this._updateFilesSubscription.unsubscribe();
		}
	}

	private reprocessAll(): void {
		this.payElectiveBankAccountsApiService.reProcessAll(this.selectedPayGroupId).pipe(take(1)).subscribe();
	}
}
