<table class="paygroups-table table" mat-table [dataSource]="dataSourceLegalEntities" multiTemplateDataRows matSort>
	<tr mat-header-row *matHeaderRowDef="legalEntityColumns"></tr>
	<tr
		mat-row
		*matRowDef="let element; columns: legalEntityColumns"
		#customerRow
		(click)="element.legalEntityDetails.paygroupCount > 0 ? toggleLegalEntity(element) : null"
		[ngClass]="{ expanded: isLegalEntityExpanded(element), collapsed: !isLegalEntityExpanded(element) }"
	></tr>
	<tr mat-row *matRowDef="let row; columns: ['expandedDetail']"></tr>

	<!--First Layer-->
	<ng-container class="table-container">
		<ng-container matColumnDef="Legal Entities">
			<th mat-header-cell *matHeaderCellDef>Legal Entities</th>
			<td mat-cell class="customer-data customerName first-collumn" *matCellDef="let element">
				<span
					[matTooltip]="element.legalEntityDetails.name"
					[matTooltipPosition]="'above'"
					[matTooltipClass]="'tooltip-failedTrans'"
					class="text"
					>{{ element.legalEntityDetails.name }}</span
				>
			</td>
		</ng-container>

		<ng-container matColumnDef="Paygroups">
			<th mat-header-cell *matHeaderCellDef>Paygroups</th>
			<td class="customer-data paygroupCount second-collumn" mat-cell *matCellDef="let element">
				<span class="text" *ngIf="element.legalEntityDetails.paygroupCount > 1"
					>{{ element.legalEntityDetails.paygroupCount }} Paygroups</span
				>
				<span class="text" *ngIf="element.legalEntityDetails.paygroupCount < 2"
					>{{ element.legalEntityDetails.paygroupCount }} Paygroup</span
				>
			</td>
		</ng-container>

		<ng-container matColumnDef="expand">
			<th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
			<td mat-cell *matCellDef="let element" class="button-container customer-data third-collumn">
				<button
					#expand
					mat-icon-button
					class="drop-down-arrow-button"
					aria-label="expand row"
					[ngClass]="{ hidden: element.legalEntityDetails.paygroupCount === 0 }"
					element="element"
				>
					<app-icon
						class="drop-down-arrow"
						[ngClass]="{ down: isLegalEntityExpanded(element) }"
						[name]="'arrow-down'"
						[size]="'18px'"
						[lineWidth]="'2.5px'"
					></app-icon>
				</button>
			</td>
		</ng-container>
	</ng-container>

	<!--Second Layer-->
	<ng-container class="paygroup-container" matColumnDef="expandedDetail">
		<td
			mat-cell
			*matCellDef="let element"
			class="paygroup-row"
			[ngClass]="{
				'paygroups-expanded': isLegalEntityExpanded(element)
			}"
			[attr.colspan]="paygroupColumns.length"
		>
			<div
				class="paygroup-detail"
				*ngIf="element.legalEntityDetails.payGroups?.length"
				[@detailExpand]="expandedState(element)"
			>
				<div class="inner-table">
					<table
						class="paygroup-table"
						#innerTables
						mat-table
						[dataSource]="element.legalEntityDetails.payGroups"
						multiTemplateDataRows
						matSort
						(mouseenter)="setHoveredPaygroup(element.externalId)"
						(mouseleave)="setHoveredPaygroup('')"
					>
						<ng-container matColumnDef="first-padding">
							<th class="hide" mat-header-cell *matHeaderCellDef></th>
							<td class="padding first-collumn" mat-cell *matCellDef="let element">
								<span class="first padding text">{{ element.name }}</span>
							</td>
						</ng-container>

						<ng-container matColumnDef="Paygroups">
							<th class="hide" mat-header-cell *matHeaderCellDef></th>
							<td class="paygroup-data paygroupName second-collumn" mat-cell *matCellDef="let element">
								<span
									class="payGroup-name text"
									[matTooltip]="element.externalId + ' ' + element.name"
									[matTooltipPosition]="'above'"
									[matTooltipClass]="'tooltip-failedTrans'"
								>
									<img src="assets/svg-country-flags/svg/{{ element.country?.toLowerCase() }}.svg" />

									{{ element.externalId }} {{ element.name }}</span
								>
							</td>
						</ng-container>
						<ng-container matColumnDef="second-padding">
							<th class="hide" mat-header-cell *matHeaderCellDef></th>
							<td mat-cell *matCellDef="let element" class="padding third-collumn">
								<span
									class="view-dashboard-button"
									[ngClass]="{ show: element.externalId === hoveredPaygroupId }"
									>View Dashboard<app-icon
										name="arrow-right"
										[color]="'var(--color-primary-1000)'"
										class="arrow"
										>→</app-icon
									></span
								>
							</td>
						</ng-container>

						<tr mat-header-row *matHeaderRowDef="paygroupColumns"></tr>
						<tr
							class="paygroup-table-row"
							mat-row
							*matRowDef="let row; columns: paygroupColumns"
							(click)="routeToPaymentsPage(row)"
							(mouseenter)="setHoveredPaygroup(row.externalId)"
							(mouseleave)="setHoveredPaygroup('')"
						></tr>
					</table>
				</div>
			</div>
		</td>
	</ng-container>
</table>
