import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../../environments/environment";
import { SelectOption } from "../../../shared/models/select-option.interface";
import { map } from "rxjs/operators";
import { formatDataForSelect } from "../../../shared/utils/format-select-data.util";

@Injectable({
	providedIn: "root"
})
export class ErrorManagementService {
	errorTypeUpdateSuccess: EventEmitter<void> = new EventEmitter<void>();
	constructor(private http: HttpClient) {}

	// getErrorTypes(): Observable<SelectOption[]> {
	// 	return this.http.get<ErrorType>(`${environment.apiUri}/v1/provider-messages/error-types`).pipe(
	// 		map(data => {
	// 			const options = data.errorTypes.map(error => {
	// 				const option: SelectOption = {
	// 					value: error,
	// 					text: error
	// 				};

	// 				return formatDataForSelect(option);
	// 			});
	// 			return options;
	// 		})
	// 	);
	// }
}
