import { Component, Input, EventEmitter, OnInit, Output } from "@angular/core";
import { LegalEntity } from "src/app/shared/models/legal-entity.interface";

@Component({
	selector: "app-legal-entity-item",
	templateUrl: "./legal-entity-item.component.html",
	styleUrls: ["./legal-entity-item.component.scss"]
})
export class LegalEntityItemComponent {
	@Input() legalEntity: LegalEntity = {} as LegalEntity;
	@Output() newLegalEntitySelectedEvent = new EventEmitter<LegalEntity>();

	legalEntitySelected() {
		this.newLegalEntitySelectedEvent.emit(this.legalEntity);
	}
}
