import { Component, Input, OnInit } from "@angular/core";
import { PayGroupHistory, statusType } from "src/app/shared/models/pay-groups";

@Component({
	selector: "app-paygroup-status-history-item",
	templateUrl: "./paygroup-status-history-item.component.html",
	styleUrls: ["./paygroup-status-history-item.component.scss"]
})
export class PaygroupStatusHistoryItemComponent {
	@Input() historyItem!: PayGroupHistory;
	@Input() type!: statusType;
	@Input() isLast!: boolean;
}
