<app-breadcrumbs
	[headerTitle]="'CUSTOMERS'"
	[externalCrumbs]="[{ crumb: this.customerName !== undefined ? this.customerName : 'Create new customer' }]"
/>
<div class="content-container">
	<div class="information-container">
		<div class="container-section">
			<div class="title">
				<!--Custyomre setup-->
				Customer setup
			</div>

			<form [formGroup]="customerForm" class="o-grid-4">
				<!-- Customer reference -->
				<input-wrapper label="Customer reference" class="first-input-row">
					<input type="text" placeholder="Customer reference" id="externalId" formControlName="externalId" />
					<div id="externalId" input-errors *ngIf="customerForm.controls.externalId?.errors?.required">
						Customer reference is required
					</div>
				</input-wrapper>

				<!-- WPay Customer Reference -->
				<input-wrapper label="WPay Customer Reference">
					<input type="text" placeholder="WPay Customer Reference" id="id" formControlName="id" />
				</input-wrapper>

				<!-- Customer name -->
				<input-wrapper label="Customer name *">
					<input type="text" placeholder="Customer name" id="name" formControlName="name" />
					<div
						id="name"
						input-errors
						*ngIf="
							(customerForm.controls.name.touched || customerForm.controls.name.dirty) &&
							customerForm.controls.name.errors?.required
						"
					>
						Customer name is required
					</div>
				</input-wrapper>

				<!-- Customer address -->
				<input-wrapper label="Customer address *" class="last-input-row">
					<input type="text" placeholder="Customer address" id="address" formControlName="address" />
					<div
						id="address"
						input-errors
						*ngIf="
							(customerForm.controls.address.touched || customerForm.controls.address.dirty) &&
							customerForm.controls.address.errors?.required
						"
					>
						Customer address is required
					</div>
				</input-wrapper>

				<!-- Other Customer Info -->
				<input-wrapper label="Other customer info" class="first-input-row">
					<input
						type="text"
						placeholder="Other customer info"
						id="registrationId"
						formControlName="registrationId"
					/>
					<div id="registrationId" input-errors *ngIf="customerForm.controls.registrationId.errors?.required">
						Customer registration number is required
					</div>
				</input-wrapper>

				<!-- Customer logo -->
				<input-wrapper label="Customer logo">
					<image-upload [imagePath]="imagePath" (image)="onImageUpload($event)"></image-upload>
					<div input-errors *ngIf="customerForm.controls.image.errors?.required">
						Customer image is required
					</div>
				</input-wrapper>
				<!-- we will use the canvas to generate a logo if one is not uploaded  -->
				<canvas #logoCanvas></canvas>
			</form>
		</div>
		<hr />
		<div class="container-section">
			<div class="title">
				<!--Custyomre setup-->
				Contact person (Optional)
			</div>
			<div class="button-container">
				<ng-template [appUserRolePermissions] [action]="'hide'" [roles]="['TS_VO', 'CLIENT']">
					<button class="btn-new-contact" mat-button (click)="onAddContactClicked()">
						<app-icon
							id="add-customer-creation"
							[name]="'add'"
							[color]="'var(--color-primary-1000)'"
							[size]="'20px'"
						></app-icon
						>Create new contact person
					</button>
				</ng-template>
			</div>
			<app-contacts
				(contactFormData)="contactFormData($event)"
				[addIsClicked$]="addContactClicked$"
				[objectLevel]="'customers'"
			></app-contacts>
		</div>

		<hr />
		<div class="container-section">
			<div class="btn-container">
				<ng-template [appUserRolePermissions] [action]="'hide'" [roles]="['TS_VO', 'CLIENT']">
					<button
						id="cancel"
						class="cancel-btn"
						(click)="cancelCreateCustomer()"
						action-buttons
						mat-stroked-button
					>
						Cancel
					</button>

					<button
						id="submit"
						class="submit-btn"
						(click)="saveCustomerForm()"
						action-buttons
						mat-flat-button
						[disabled]="customerForm.invalid || !contactFormisValid"
					>
						Save
					</button>
				</ng-template>
			</div>
		</div>
	</div>
</div>
