<div [formGroup]="searchForm" class="search-section flex flex-align-center-space-between">
	<mat-form-field class="search-field" id="master-search-field" appearance="outline">
		<app-icon
			id="master-search-icon"
			[name]="'search-normal-1'"
			[size]="'25'"
			[lineWidth]="'2px'"
			[color]="'var(--color-search-icon)'"
			matPrefix
		></app-icon>
		<input id="searchEmployees" matInput placeholder="Search by..." formControlName="search" />
	</mat-form-field>
</div>

<div class="history-header-container flex flex-row flex-align-center-start">
	<div id="employee" class="flex flex-1" style="--max-width: 22%">Employee N°</div>
	<div id="firstName" class="flex flex-1" style="--max-width: 30.5%">Full Name</div>
	<div id="description" class="flex flex-1" style="--max-width: 45%">Description</div>
</div>
<div class="scroll-container">
	<div *ngIf="reportData$ | async as reportData">
		<ng-container *ngIf="!reportData.employeeErrors.length; else errorTemplate">
			<div class="history-item-container flex flex-row flex-align-center-start">
				<p>There is no data to display</p>
			</div>
		</ng-container>
		<ng-template #errorTemplate>
			<div
				*ngIf="!(filteredEmployees$ | async)?.length"
				class="history-item-container flex flex-row flex-align-center-start"
			>
				<p>No Employees match your search</p>
			</div>
			<ng-container *ngFor="let data of filteredEmployees$ | async">
				<div
					*ngIf="(filteredEmployees$ | async)?.length"
					class="history-item-container flex flex-row flex-align-center-start"
					[class.error]="
						data.validationErrors.length ||
						data.employee.id === 'EMPLOYEE_NOT_FOUND' ||
						!!data.executionError
					"
				>
					<div
						class="data-row item-employee flex flex-align-center"
						*ngIf="data.employee.id !== 'EMPLOYEE_NOT_FOUND'; else notFoundTemplate"
					>
						<div id="employee" class="flex flex-align-center-start flex-1" style="--max-width: 21.5%">
							{{ data.employee.externalId }}
						</div>
						<div id="firstName" class="flex flex-align-center-start flex-1" style="--max-width: 30.3%">
							{{ data.employee.firstName }} {{ data.employee.lastName }}
						</div>
						<div id="description" class="flex flex-column flex-1" style="--max-width: 45%">
							<div class="flex flex-column" *ngFor="let error of data.validationErrors">
								<span>{{ error.field ? error.field + " - " : "" }}{{ error.message }} </span>
							</div>
							<div class="flex flex-column" *ngIf="data.executionError">
								<span>{{ data.executionError.message }}</span>
							</div>
						</div>
					</div>
					<ng-template #notFoundTemplate>
						<div id="employee" class="first-collumn flex flex-align-center-start">
							{{ data.executionError.message.split(" ").pop() }}
						</div>
						<div id="firstName" class="second-collumn flex flex-align-center-start"></div>
						<div id="description" class="third-collumn flex flex-column">
							<div>Employee Not Found</div>
						</div>
					</ng-template>
				</div>
				<div
					class="history-item-container flex flex-row"
					[class.warning]="
						data.warnings.length || data.employee.id === 'EMPLOYEE_NOT_FOUND' || !!data.warnings
					"
					*ngIf="data.warnings && data.warnings.length > 0"
				>
					<div
						class="data-row flex flex-align-center"
						*ngIf="data.employee.id !== 'EMPLOYEE_NOT_FOUND'; else notFoundTemplate"
					>
						<div id="employee" class="flex flex-align-center-start flex-1" style="--max-width: 21.5%">
							{{ data.employee.externalId }}
						</div>
						<div id="firstName" class="flex flex-align-center-start flex-1" style="--max-width: 30.3%">
							{{ data.employee.firstName }} {{ data.employee.lastName }}
						</div>
						<div id="description" class="flex flex-column flex-1" style="--max-width: 45%">
							<div class="flex flex-column" *ngFor="let warning of data.warnings">
								<span
									><span *ngIf="warning.code">{{ warning.code }}-</span>{{ warning.message }}</span
								>
							</div>
						</div>
					</div>
					<ng-template #notFoundTemplate>
						<div id="employee" class="first-collumn flex flex-align-center-start" *ngIf="data.warnings">
							{{ data.warnings[0].message.split(" ").pop() }}
						</div>
						<div id="firstName" class="second-collumn flex flex-align-center-start"></div>
						<div id="description" class="third-collumn flex flex-column">
							<div>Employee Not Found</div>
						</div>
					</ng-template>
				</div>
			</ng-container>
		</ng-template>
	</div>
</div>
