import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { CardDetails } from "@modules/global-dashboard/models/global-dashboard-interface";

@Component({
	selector: "app-kpi-cards",
	templateUrl: "./kpi-cards.component.html",
	styleUrls: ["./kpi-cards.component.scss"],
	animations: [
		trigger("paddingChange", [
			state(
				"default",
				style({
					padding: "2rem 0rem"
				})
			),
			state(
				"reduced",
				style({
					padding: "0.5rem 0rem"
				})
			),
			transition("default <=> reduced", [animate("300ms ease-in-out")])
		]),
		trigger("expandCollapse", [
			transition(":enter", [
				style({ "min-height": "0", opacity: 0 }),
				animate("300ms ease-out", style({ "min-height": "17rem", opacity: 1 }))
			]),
			transition(":leave", [animate("300ms ease-in", style({ height: "0", opacity: 0 }))])
		])
	]
})
export class KpiCardsComponent {
	@Input() set kpiCardsData(cards: CardDetails[]) {
		this.kpiCards = cards;
		this.isKPICardsZero = true;
		this.kpiCards.forEach(card => {
			const kpiName = this.getKpiCardName(card);
			if (kpiName && card.kpiType[kpiName].count > 0) this.isKPICardsZero = false;
		});
	}
	@Input() set selectedKpi(kpi: string) {
		this.kpiSelected = kpi;
	}

	@Input() isOnGlobalDashboard = false;

	showLoadingCards = true;

	@Input() set showLoader(load: boolean) {
		this.showLoadingCards = load;
	}

	@Output() kpiSelectedEvent: EventEmitter<string> = new EventEmitter<string>();

	@ViewChild("parent") slider!: ElementRef;

	isKPICardsZero = true;
	kpiSelected: string = "";
	mouseDown = false;
	startX: any;
	scrollLeft: any;
	kpiCards!: CardDetails[];

	getKpiCardName(card: CardDetails): string {
		return Object.keys(card.kpiType)[0];
	}

	startDragging(e: any, flag: boolean): void {
		this.mouseDown = flag;
		this.startX = e.pageX - this.slider.nativeElement.offsetLeft;
		this.scrollLeft = this.slider.nativeElement.scrollLeft;
	}
	stopDragging(e: any, flag: boolean): void {
		this.mouseDown = flag;
	}
	moveEvent(e: any): void {
		e.preventDefault();
		if (!this.mouseDown) {
			return;
		}
		const x = e.pageX - this.slider.nativeElement.offsetLeft;
		const scroll = x - this.startX;
		this.slider.nativeElement.scrollLeft = this.scrollLeft - scroll;
	}
}
