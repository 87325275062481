import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { TppCatalogueComponent } from "./tpp-catalogue.component";
import { TppServiceComponent } from "./_components/tpp-service/tpp-service.component";
import { TppAddBeneficiaryComponent } from "./_components/tpp-add-beneficiary/tpp-add-beneficiary.component";

const routes: Routes = [
	{ path: "", component: TppCatalogueComponent },
	{
		path: "add-new-service",
		component: TppServiceComponent
	},
	{
		path: "edit-service",
		component: TppServiceComponent
	},
	{
		path: "add-new-beneficiary",
		component: TppAddBeneficiaryComponent
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class TppCatalogueRoutingModule {}
