import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { MilestoneReport } from "@shared/models/payouts.types";

@Component({
	selector: "app-payment-top-cards",
	templateUrl: "./payment-top-cards.component.html",
	styleUrls: ["./payment-top-cards.component.scss"]
})
export class PaymentTopCardsComponent implements AfterViewInit {
	@ViewChild("payment", { static: false }) payment!: ElementRef;

	@Input() milestoneReport!: MilestoneReport;
	@Input() title!: string;
	@Input() subTitle!: string;
	@Input() flag!: string;
	@Input() expandMilestoneGroupWithName!: string;
	@Input() kpiSelected!: string;

	@Input() set checkDistance(check: number) {
		if (this.stickyComponent && this.staticComponent) this.setStickyState();
	}

	@Input() set _updating(update: boolean) {
		this.updating = update;
	}

	@Output() emitSticky: EventEmitter<boolean> = new EventEmitter<boolean>();

	headcountTooltip: string = "";
	transactionTooltip: string = "";
	totalPaymentTooltip: string = "";
	showTooltipHead: boolean = false;
	showTooltipPayment: boolean = false;
	showTooltipTransactions: boolean = false;
	minWidth: number = 341;
	updating: boolean = false;

	@ViewChild("sticky", { static: false }) stickyComponent!: ElementRef;
	@ViewChild("static", { static: false }) staticComponent!: ElementRef;

	constructor(private router: Router) {}

	setStickyState(): void {
		const stickyBotton = this.getSize(this.stickyComponent.nativeElement as HTMLElement).bottom;
		const staticBottom = this.getSize(this.staticComponent.nativeElement as HTMLElement).bottom;

		if (staticBottom < stickyBotton) this.emitSticky.emit(true);
		else this.emitSticky.emit(false);
	}

	getSize(component: HTMLElement): DOMRect {
		return component.getBoundingClientRect();
	}

	ngAfterViewInit() {
		this.getWidth();
	}

	getWidth() {
		if (this.payment) {
			const elementWidth = this.payment.nativeElement.offsetWidth;
			this.minWidth = elementWidth;
		}
	}

	regress(): void {
		let route = "payments";

		if (this.router.url.includes("global-dashboard")) route = "global-dashboard";

		if ((this.kpiSelected === "Failed Transactions" || this.kpiSelected === "") && route === "global-dashboard") {
			this.router.navigate([`/${route}`]);
		} else {
			this.router.navigate([`/${route}/dashboard`], {
				state: { expandMilestoneGroupWithName: this.expandMilestoneGroupWithName }
			});
		}
	}
}
