<app-breadcrumbs
	[headerTitle]="'GLOBAL DASHBOARD'"
	[externalCrumbs]="[
		{
			crumb:
				this.kpiSelected && this.kpiSelected !== 'Customers' && this.kpiSelected !== 'Paygroups'
					? this.kpiSelected
					: ''
		}
	]"
	[addOverviewTitle]="true"
	[gdHeaderFlag]="headerFlag"
	[clientSelectedCountry]="clientSelectedCountry"
	[clientCountryList]="newClientCountryList!"
	[clientCustomerId]="clientCustomerId!"
/>

<app-kpi-cards
	sub-header
	[showLoader]="(this.fetchingDataForFilters | async)!"
	(kpiSelectedEvent)="setSelectedKPI($event)"
	[kpiCardsData]="kpiCards"
	[selectedKpi]="kpiSelected"
	[isOnGlobalDashboard]="true"
></app-kpi-cards>

<app-filters-container
	*ngIf="filters"
	[customers]="customersSelectOptions.value"
	[legalEnities]="legalEntitiesSelectOptions.value"
	[payGroups]="payGroupsSelectOptions.value"
	[groups]="groupSelectOptions.value"
	[statuses]="statusSelectOptions.value"
	[selectedKpi]="kpiSelected"
	[stateFilters]="filters"
	(fetchNewData)="patchNewData($event)"
	(applyFilters)="applyFilters()"
></app-filters-container>

<div class="table-wrapper" *ngIf="!(fetchingDataTable | async)">
	<app-table-top-section
		[tableTitle]="tableTitle"
		[firstOptionName]="'Events'"
		[secondOptionName]="isClient ? 'Paygroups' : 'Customers'"
		[showSlideButton]="
			kpiSelected === '' || kpiSelected === 'Customers' || kpiSelected === 'Paygroups' ? true : false
		"
		[tableIcon]="icon"
		[selectedKPI]="kpiSelected"
		[eventsSelected]="selectedEvents.length"
		(switchToCustomerTable)="switchToCustomerTable($event)"
		(hideEvents)="hideEvent()"
	></app-table-top-section>

	<app-landing-page-events-table
		*ngIf="dataLandingPageEvents && dataLandingPageEvents.length > 0"
		[events]="dataLandingPageEvents"
		[isClient]="isClient"
		(updateStatus)="milestoneDropdownFilterUpdate()"
		(selectEvent)="selectEvents($event)"
		(hideEvent)="hideEvent($event)"
	></app-landing-page-events-table>

	<app-landing-page-customer-table
		*ngIf="dataLandingPageCustomers && dataLandingPageCustomers.length > 0"
		[customers]="dataLandingPageCustomers"
		(fetchLegalEntities)="fetchLegalEntitiesData($event)"
		(fetchPaygroups)="fetchPaygroupsDataForCustomerTable($event)"
	></app-landing-page-customer-table>

	<app-landing-page-paygroup-table
		*ngIf="dataLandingPagePaygroups && dataLandingPagePaygroups.length > 0"
		[legalEntities]="dataLandingPagePaygroups"
		(fetchPaygroups)="fetchPaygroupsDataForPaygroupTable($event)"
	></app-landing-page-paygroup-table>

	<app-failed-beneficiaries-table
		*ngIf="dataFailedBeneficiaries && dataFailedBeneficiaries.length > 0 && selected('Failed Beneficiaries')"
		[failedBeneficiaries]="dataFailedBeneficiaries"
	></app-failed-beneficiaries-table>

	<app-missing-cards-table
		*ngIf="dataMissingCards && dataMissingCards.length > 0 && selected('Missing Cards')"
		[missingCards]="dataMissingCards"
	></app-missing-cards-table>

	<app-missing-bank-accounts-table
		*ngIf="dataMissingBankAccounts && dataMissingBankAccounts.length > 0 && selected('Missing Bank Acc.')"
		[missingBankAccounts]="dataMissingBankAccounts"
	></app-missing-bank-accounts-table>

	<app-failed-transactions-table
		*ngIf="dataFailedTransactions && dataFailedTransactions.length > 0 && selected('Failed Transactions')"
		[failedTransactions]="dataFailedTransactions"
	></app-failed-transactions-table>

	<app-failed-payment-orders-table
		*ngIf="dataFailedPaymentOrders && dataFailedPaymentOrders.length > 0 && selected('Failed Payment Orders')"
		[failedPaymentOrders]="dataFailedPaymentOrders"
	></app-failed-payment-orders-table>

	<no-items-layout
		*ngIf="noEntriesFound && !encounteredErrorWhileFecthingData && !showDefaultTableView"
		message="No records found"
	></no-items-layout>

	<no-items-layout
		*ngIf="encounteredErrorWhileFecthingData && !showDefaultTableView"
		message="We've encountered an error while fecthing the data"
	></no-items-layout>

	<no-items-layout *ngIf="showDefaultTableView" message="Select filters to see corresponding list"></no-items-layout>
</div>

<div *ngIf="fetchingDataTable | async" class="table-wrapper loader">
	<div class="top-section-loader">
		<div class="icon-container"></div>
		<div class="title"></div>
	</div>
	<div class="table-section-loader"></div>
</div>

<div
	*ngIf="!(fetchingDataTable | async) && !encounteredErrorWhileFecthingData"
	class="global-dashboard-paginator-container flex flex-row flex-align-center-end"
>
	<mat-paginator
		class="pagination"
		*ngIf="pagination"
		[length]="pagination.totalPageCount"
		[pageSizeOptions]="[5, 10, 25, 100, 500]"
		[pageSize]="pagination.pageSize"
		[pageIndex]="pagination.pageIndex"
		(page)="paginate($event)"
		aria-label="Select page"
	>
	</mat-paginator>
</div>

<!-- <app-milestone-history-log *ngIf="showHistoryLogPanel" /> -->
<app-history-log-side-panel [historyType]="HistoryLogType.MILESTONE" *ngIf="showHistoryLogPanel" />
