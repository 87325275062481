import { Injectable, OnDestroy } from "@angular/core";
import { map } from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import { Country } from "../../../../shared/models/country.interface";
import { SelectOption } from "../../../../shared/models/select-option.interface";
import { formatDataForSelect } from "../../../../shared/utils/format-select-data.util";
import { CountriesService } from "../../../../shared/services/countries/countries.service";
import {
	FundSource,
	FundSourceAddNewSource,
	FundSourceCreateDto,
	FundSourceCreateForm,
	FundSourcePagination,
	SourceAccount,
	SourceAccountPagination
} from "src/app/shared/models/source-of-funds.interface";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import {
	CurrencyCode,
	FundingMethodName,
	PaymentTypeName,
	ProvisionCompensation
} from "src/app/shared/models/service-provider.type";
import {
	SourceAccountPost,
	SourceAccountPut,
	SourceFoundAccount,
	SourceFoundAccountPagination
} from "../../_models/source-accounts.interface";

@Injectable({
	providedIn: "root"
})
export class FundSourceService implements OnDestroy {
	destroy$: Subject<void> = new Subject<void>();
	private _addFundSource = new Subject<FundSourceAddNewSource>();
	private _existingFundSource = new Subject<FundSource>();

	fundSource$ = this._addFundSource.asObservable();
	existingFundSource$ = this._existingFundSource.asObservable();

	constructor(private countryService: CountriesService, private readonly http: HttpClient) {}

	getCountriesForSelect(): Observable<SelectOption[]> {
		return this.countryService.getCountries().pipe(map(countries => this.setupCountriesForSelect(countries)));
	}

	setupCountriesForSelect(countries: Country[]): SelectOption[] {
		//Create select options from countries data
		return countries.map((country: Country) => {
			const option: SelectOption = {
				value: country?.country.code,
				text: country?.country.name,
				imagePath: `assets/svg-country-flags/svg/${country?.country.code.toLowerCase()}.svg`
			};

			return formatDataForSelect(option);
		});
	}

	addNewFundSource(fundSource: FundSourceAddNewSource) {
		this._addFundSource.next(fundSource);
	}

	addExistingFundSource(fundSource: FundSource) {
		this._existingFundSource.next(fundSource);
	}

	getSourceOfFundsByEntityIdAndType(legalEntityId: string, paymentType: PaymentTypeName): Observable<FundSource[]> {
		return this.http
			.get<FundSourcePagination>(
				`${environment.apiUri}/v1/accounts/source?legalEntityId=${legalEntityId}&paymentTypes=${paymentType}`
			)
			.pipe(
				//we don't need the pagination data so only return the items array from the object
				map((res: FundSourcePagination) => {
					return res.items;
				})
			);
	}

	searchSourceOfFunds(legalEntityId: string, currencies: string): Observable<SourceFoundAccount[]> {
		return this.http
			.get<SourceFoundAccountPagination>(
				`${environment.apiUri}/v1/accounts/source?legalEntityId=${legalEntityId}&currencies=${currencies}&pageSize=-1`
			)
			.pipe(
				//we don't need the pagination data so only return the items array from the object
				map((res: SourceFoundAccountPagination) => {
					return res.items;
				})
			);
	}

	getSourceFundAccount(id: string): Observable<SourceFoundAccount> {
		return this.http.get<SourceFoundAccount>(`${environment.apiUri}/v1/accounts/source/${id}`);
	}

	getAccountsByLegalEntityId(legalEntityId: string): Observable<SourceAccount[]> {
		return this.http
			.get<SourceAccountPagination>(
				`${environment.apiUri}/v1/accounts/source?legalEntityId=${legalEntityId}&pageSize=-1`
			)
			.pipe(
				//we don't need the pagination data so only return the items array from the object
				map((res: SourceAccountPagination) => {
					return res.items;
				})
			);
	}

	createFundSourceFormData(newSource: FundSourceAddNewSource): FundSource {
		return {
			id: "",
			version: 0,
			customerId: "",
			paymentType: newSource.paymentType as PaymentTypeName,
			currency: newSource.currency as CurrencyCode,
			name: "",
			data: {
				name: "",
				accountNumber: "",
				iban: "",
				bankData: {
					code: "",
					name: "",
					swift: "",
					address: ""
				},
				provisionCompensation: "STANDARD",
				fundingMethods: [newSource.pushMethod, newSource.pullMethod]
			}
		};
	}

	createFundSourceDto(form: FundSourceCreateForm, legalEntityId: string): FundSourceCreateDto {
		return {
			legalEntityId,
			paymentType: form.paymentType as PaymentTypeName,
			currency: form.currency as CurrencyCode,
			name: form.accountName,
			data: {
				name: form.accountName,
				accountNumber: form.accountNumber,
				iban: form.iban,
				bankData: {
					code: form.code,
					name: form.bankName,
					swift: form.swift,
					address: form.bankAddress
				},
				provisionCompensation: form.compensation as ProvisionCompensation,
				fundingMethods: form.fundingMethods
			}
		};
	}

	createUpdateFundSourceDto(form: FundSourceCreateForm, legalEntityId: string): FundSourceCreateDto {
		return {
			id: form.id,
			version: form.version,
			legalEntityId,
			paymentType: form.paymentType as PaymentTypeName,
			currency: form.currency as CurrencyCode,
			name: form.accountName,
			data: {
				name: form.accountName,
				accountNumber: form.accountNumber,
				iban: form.iban,
				bankData: {
					code: form.code,
					name: form.bankName,
					swift: form.swift,
					address: form.bankAddress
				},
				provisionCompensation: form.compensation as ProvisionCompensation,
				fundingMethods: form.fundingMethods
			}
		};
	}

	createFundSource(fundSource: SourceAccountPost): Observable<any | SourceFoundAccount> {
		return this.http.post<SourceFoundAccount>(`${environment.apiUri}/v1/accounts/source`, fundSource);
	}

	updateFundSource(fundSource: SourceAccountPut): Observable<SourceFoundAccount> {
		return this.http.put<SourceFoundAccount>(`${environment.apiUri}/v1/accounts/source`, fundSource);
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
