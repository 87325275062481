<div class="disclaimer-container">
	<app-icon
		class="icon"
		[name]="'figma-info-circle'"
		[color]="'var(--color-primary-1000)'"
		[lineWidth]="'0.25px'"
		[fill]="'var(--color-primary-1000)'"
	></app-icon>
	<span class="text">
		Your calendar events will be set in <span class="text bold">{{ fullTimeZone }}</span>
	</span>
</div>
