<div class="dialog-header">
	<mat-icon class="header-icon">info_outline</mat-icon>
	<h3>Errors</h3>
</div>
<div fxLayout="column" class="container" fxLayoutAlign="space-between none">
	<div class="mat-dialog-content">
		<mat-list>
			<ng-container *ngIf="Array.isArray(data.errors); else notErrorsArray">
				<mat-list-item *ngFor="let error of data.errors; let index = index">
					<span class="error-text">{{ index + 1 }}. {{ error.message }}</span>
				</mat-list-item>
			</ng-container>
			<ng-template #notErrorsArray>
				<mat-list-item>
					<span class="error-text">{{ error.message }}</span>
				</mat-list-item>
			</ng-template>
		</mat-list>
	</div>

	<div align="end" class="mat-dialog-actions" mat-dialog-actions>
		<button action-buttons mat-button (click)="onCancel()" id="closeBtn" cdkFocusInitial>Close</button>
	</div>
</div>
