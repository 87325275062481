import { Component, Input } from "@angular/core";

@Component({
	selector: "app-map-popup",
	templateUrl: "./map-popup.component.html",
	styleUrls: ["./map-popup.component.scss"]
})
export class MapPopupComponent {
	@Input() countryName!: string;
	@Input() countryCode!: string;
}
