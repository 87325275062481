import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { KpiCardsComponent } from "./kpi-cards.component";
import { CardComponent } from "./card/card.component";
import { SharedModule } from "@shared/shared.module";

@NgModule({
	declarations: [KpiCardsComponent, CardComponent],

	imports: [CommonModule, SharedModule],

	exports: [KpiCardsComponent]
})
export class KpiCardsModule {}
