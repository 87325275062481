import { animate, keyframes, state, style, transition, trigger } from "@angular/animations";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { BehaviorSubject, Observable, Subject, Subscription } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { DeleteDialogComponent } from "@shared/components/delete-dialog/delete-dialog.component";
import { ToastService } from "@shared/services/toast/toast.service";
import { Employee } from "src/app/shared/models/employee.interface";
import { PermissionsService } from "../../../../../shared/services/permissions/permissions.service";
import { PayGroup } from "../../../../../shared/models/pay-groups";
import { PayElectiveAccountRankingService } from "../../../services/pay-elective-account-ranking.service";
import { PayElectiveBankAccountsService } from "../../../services/pay-elective-bank-accounts.service";
import { PayMethodDetail, PaymentType } from "../../../types/accounts";

@Component({
	selector: "app-pay-elective-bank-accounts",
	templateUrl: "./pay-elective-bank-accounts.component.html",
	styleUrls: ["./pay-elective-bank-accounts.component.scss"],
	animations: [
		trigger("fabToggler", [
			state(
				"false",
				style({
					transform: "rotate(0deg)"
				})
			),
			state(
				"true",
				style({
					transform: "rotate(90deg)"
				})
			),
			transition("* <=> *", animate("200ms cubic-bezier(0.1, 0.0, 0.1, 0)"))
		]),
		trigger("Grow", [
			transition(":enter", [
				animate(
					"300ms ease-in-out",
					keyframes([
						style({ transform: "scale(0)" }),
						style({ transform: "scale(1.04)" }),
						style({ transform: "scale(1)" })
					])
				)
			]),
			transition(":leave", [
				animate(
					"400ms ease-in-out",
					keyframes([
						style({ transform: "scale(1)" }),
						style({ transform: "scale(1.04)" }),
						style({ transform: "scale(0)" })
					])
				)
			])
		])
	]
})
export class PayElectiveBankAccountsComponent implements OnInit, OnDestroy {
	_employee!: Employee;

	@Input() set employee(val: Employee) {
		if (val) {
			this._employee = val;

			if (this._employee.id) {
				this.loadPaymentMethods(this._employee.id);
			}
		}
	}

	provider!: string;

	@Input() set _provider(val: string) {
		this.provider = val;
	}

	providerText: string = "No Provider";

	@Input() payGroup!: PayGroup;

	net: PaymentType = PaymentType.NET;
	ewa: PaymentType = PaymentType.EWA;
	ttp: PaymentType = PaymentType.TPP;
	fallback: PaymentType = PaymentType.FALLBACK;

	private destroy$: Subject<void> = new Subject();
	private close: Subscription = new Subscription();
	private refresh: Subscription = new Subscription();

	private onBankAccountReordered: Subscription = new Subscription();

	creatingBankAccount: boolean = false;
	bankAccountDetails!: PayMethodDetail | null;
	newBankAccount: boolean = true;

	showAccountInfo: boolean = false;
	showInactive: boolean = false;
	rankUpdateNotificationVisible: boolean = false;

	private paymentMethodsDataSource$: BehaviorSubject<PayMethodDetail[]> = new BehaviorSubject<PayMethodDetail[]>([]);

	activePaymentMethodsNET: Observable<PayMethodDetail[]> = new Observable<PayMethodDetail[]>();
	activePaymentMethodsTPP!: Observable<PayMethodDetail[]>;
	activePaymentMethodsNone: Observable<PayMethodDetail[]> = new Observable<PayMethodDetail[]>();
	inactivePaymentMethods!: Observable<PayMethodDetail[]>;

	payMethodsLength!: number;
	country!: string;

	canCreateBankAccount: boolean = false;

	constructor(
		private payElectiveBankAccountsService: PayElectiveBankAccountsService,
		private toastService: ToastService,
		private dialog: MatDialog,
		private permissions: PermissionsService,
		private accountRankingService: PayElectiveAccountRankingService
	) {}

	ngOnInit(): void {
		this.permissions
			.canCreateEmployeeBankAccount()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => (this.canCreateBankAccount = res));

		this.country = this.payGroup.legalEntity.data.country;

		this.refresh = this.payElectiveBankAccountsService.refresh
			.pipe(takeUntil(this.destroy$))
			.subscribe(refresh => (refresh ? this.refreshPaymentMethods() : null));

		this.onBankAccountReordered = this.accountRankingService.bankAccountsReordered
			.pipe(takeUntil(this.destroy$))
			.subscribe(refresh => (refresh ? this.refreshPaymentMethods() : null));

		this.close = this.payElectiveBankAccountsService.close
			.pipe(takeUntil(this.destroy$))
			.subscribe(close => (this.creatingBankAccount = false));

		this.accountRankingService
			.showRankUpdateBannerObservable()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => {
				if (res === true) {
					this.rankUpdateNotificationVisible = true;

					setTimeout(() => {
						this.rankUpdateNotificationVisible = false;
					}, 7000);
				}
			});
	}

	isConsultantManager(): Observable<boolean> {
		return this.permissions.isConsultantManager();
	}

	loadPaymentMethods(employeeId: string): void {
		this.payElectiveBankAccountsService
			.getEmployeePaymentMethods(employeeId)
			.pipe(
				takeUntil(this.destroy$),
				map(res => {
					const data = res.flatMap(val => {
						if (this.hasProviderStatuses(val) && this.provider) {
							this.providerText = this.provider;
							return val.payMethod.providersStatuses.hasOwnProperty(this.provider) ? val : [];
						} else {
							return val;
						}
					});

					this.paymentMethodsDataSource$.next(data);

					this.setData();
				})
			)
			.subscribe();
	}

	setData(): void {
		this.activePaymentMethodsNET = this.paymentMethodsDataSource$.asObservable().pipe(
			map(res => {
				return res.filter(pM =>
					!this.hasProviderStatuses(pM) || !this.provider
						? pM.payMethod.paymentTypes.includes(this.net) && pM.payMethod.status.name !== "INACTIVE"
						: pM.payMethod.paymentTypes.includes(this.net) &&
						  pM.payMethod.providersStatuses.hasOwnProperty(this.provider) &&
						  pM.payMethod.status.name !== "INACTIVE"
				);
			})
		);

		this.activePaymentMethodsTPP = this.paymentMethodsDataSource$.asObservable().pipe(
			map(res => {
				const pM = res.filter(pM =>
					!this.hasProviderStatuses(pM) || !this.provider
						? !pM.payMethod.paymentTypes.includes(this.net) &&
						  (pM.payMethod.paymentTypes.includes(this.ttp) ||
								pM.payMethod.paymentTypes.includes(this.fallback)) &&
						  pM.payMethod.status.name !== "INACTIVE"
						: !pM.payMethod.paymentTypes.includes(this.net) &&
						  (pM.payMethod.paymentTypes.includes(this.ttp) ||
								pM.payMethod.paymentTypes.includes(this.fallback)) &&
						  pM.payMethod.providersStatuses.hasOwnProperty(this.provider) &&
						  pM.payMethod.status.name !== "INACTIVE"
				);
				return pM;
			})
		);

		this.activePaymentMethodsNone = this.paymentMethodsDataSource$.asObservable().pipe(
			map(res => {
				const pM = res.filter(pM =>
					!this.hasProviderStatuses(pM) || !this.provider
						? !pM.payMethod.paymentTypes.includes(this.net) &&
						  !pM.payMethod.paymentTypes.includes(this.ttp) &&
						  !pM.payMethod.paymentTypes.includes(this.fallback) &&
						  pM.payMethod.status.name !== "INACTIVE"
						: !pM.payMethod.paymentTypes.includes(this.net) &&
						  !pM.payMethod.paymentTypes.includes(this.ttp) &&
						  !pM.payMethod.paymentTypes.includes(this.fallback) &&
						  pM.payMethod.providersStatuses.hasOwnProperty(this.provider) &&
						  pM.payMethod.status.name !== "INACTIVE"
				);
				return pM;
			})
		);

		this.inactivePaymentMethods = this.paymentMethodsDataSource$.asObservable().pipe(
			map(res => {
				const pM = res.filter(pM =>
					!this.hasProviderStatuses(pM) || !this.provider
						? pM.payMethod.status.name === "INACTIVE"
						: pM.payMethod.providersStatuses.hasOwnProperty(this.provider) &&
						  pM.payMethod.status.name === "INACTIVE"
				);

				return pM;
			})
		);
	}

	hasProviderStatuses(pM: PayMethodDetail): boolean {
		return Object.keys(pM.payMethod.providersStatuses).length > 0;
	}

	refreshPaymentMethods(): void {
		this.loadPaymentMethods(this._employee.id!);
	}

	toggleCreatingBankAccount() {
		this.bankAccountDetails = null;
		this.newBankAccount = true;
		this.creatingBankAccount = !this.creatingBankAccount;
	}

	deleteBankAccount(accountId: string) {
		const dialogRef = this.dialog.open(DeleteDialogComponent, {
			height: "441px",
			width: "666px",
			data: {
				text: "Are you sure you want to remove the bank account?"
			},
			panelClass: "delete-dialog-container"
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.payElectiveBankAccountsService.deleteEmployeeBankAccount(this._employee.id!, accountId).subscribe({
					next: (res: any) => {
						this.refreshPaymentMethods();
						this.toastService.showSuccess("Successfully Deleted Bank Account");
					},
					error: (error: any) => this.toastService.showError(error)
				});
			}
		});
	}

	toggle(event: any) {
		this.showInactive = event.checked;
	}

	editBankAccount(event: { accountId: string; payMethodsLength: number }) {
		if (this._employee.id) {
			this.payElectiveBankAccountsService
				.getEmployeePaymentMethods(this._employee.id)
				.subscribe((result: PayMethodDetail[]) => {
					let index = result.findIndex(x => x.payMethod.accountId == event.accountId);
					this.bankAccountDetails = result[index];

					this.newBankAccount = false;
					this.creatingBankAccount = !this.creatingBankAccount;
					this.payMethodsLength = event.payMethodsLength;
				});
		}

		this.showInactive = false;
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();

		this.refresh.unsubscribe();
		this.onBankAccountReordered.unsubscribe();
		this.close.unsubscribe();

		this.toggleCreatingBankAccount();
	}
}
