import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IconComponent } from "./icon.component";
import { AngularSvgIconModule } from "angular-svg-icon";
import { FlexLayoutModule } from "ngx-flexible-layout";

@NgModule({
	declarations: [IconComponent],
	imports: [CommonModule, AngularSvgIconModule.forRoot(), FlexLayoutModule],
	exports: [IconComponent]
})
export class IconModule {}
