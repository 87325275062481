import { Injectable } from "@angular/core";
import {
	TPPServiceDefinitionState,
	TPPServiceDefinition,
	TPPGroupConfig
} from "../../../../shared/models/tpp-service.interface";
import { Store } from "../../../../store/generic/store.service";
import { SelectOption } from "@shared/models/select-option.interface";
import { Subject } from "rxjs";

const initialState: TPPServiceDefinitionState = {
	serviceDefinitionConfig: {
		payGroupId: "",
		version: 0,
		services: [],
		groupConfig: []
	}
};

@Injectable({
	providedIn: "root"
})
export class TppGroupConfigurationStore extends Store<TPPServiceDefinitionState> {
	constructor() {
		super(initialState);
	}

	setConfig(serviveDefinition: TPPServiceDefinition) {
		this.setState(state => ({
			serviceDefinitionConfig: { ...state.serviceDefinitionConfig, ...serviveDefinition }
		}));
	}

	reset() {
		this.setState(state => ({
			serviceDefinitionConfig: { ...state.serviceDefinitionConfig, initialState }
		}));
	}

	setGroup(groupName: string) {
		const config = this.getConfigByGroupName(groupName);

		if (!config) {
			this.setState(state => {
				state.serviceDefinitionConfig.groupConfig?.push({
					group: groupName,
					settlementAccountId: "",
					paymentCurrency: "",
					sourceAccountId: "",
					fundingCurrency: "",
					providerCountryId: ""
				});
				return state;
			});
		}
	}

	getConfigByGroupName(groupName: string) {
		return this.state.serviceDefinitionConfig.groupConfig!.find(
			(group: TPPGroupConfig) => group.group === groupName
		);
	}

	setGroupPaymentCurrency(groupName: string, currency: string) {
		const config = this.getConfigByGroupName(groupName);

		config!.paymentCurrency = currency;

		this.setState(state => {
			state.serviceDefinitionConfig.groupConfig?.filter(existingConfig => {
				if (existingConfig.group === config!.group) {
					existingConfig = config!;
				}
			});
			return state;
		});
	}

	setGroupSourceOfFundId(groupName: string, sourceOfFundAccountId: string) {
		const config = this.getConfigByGroupName(groupName);

		config!.sourceAccountId = sourceOfFundAccountId;

		this.setState(state => {
			state.serviceDefinitionConfig.groupConfig?.filter(existingConfig => {
				if (existingConfig.group === config!.group) {
					existingConfig = config!;
				}
			});
			return state;
		});
	}

	setGroupSettlementAccountId(groupName: string, settlementAccountId: string) {
		const config = this.getConfigByGroupName(groupName);

		config!.settlementAccountId = settlementAccountId;

		this.setState(state => {
			state.serviceDefinitionConfig.groupConfig?.filter(existingConfig => {
				if (existingConfig.group === config!.group) {
					existingConfig = config!;
				}
			});
			return state;
		});
	}

	setProviderId(groupName: string, providerId: string) {
		const config = this.getConfigByGroupName(groupName);

		config!.providerCountryId = providerId;

		this.setState(state => {
			state.serviceDefinitionConfig.groupConfig?.filter(existingConfig => {
				if (existingConfig.group === config!.group) {
					existingConfig = config!;
				}
			});
			return state;
		});
	}

	setFundingCurrency(groupName: string, fundingCurrency: string) {
		const config = this.getConfigByGroupName(groupName);

		config!.fundingCurrency = fundingCurrency;

		this.setState(state => {
			state.serviceDefinitionConfig.groupConfig?.filter(existingConfig => {
				if (existingConfig.group === config!.group) {
					existingConfig = config!;
				}
			});
			return state;
		});
	}
}
