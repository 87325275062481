import { EventEmitter, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ReplaySubject } from "rxjs";
import { ServiceProviderService } from "@shared/services/service-provider/service-provider.service";
import { ToastService } from "@shared/services/toast/toast.service";
import {
	CapabilitiesFormData,
	CapabilityCreateObject,
	Cost,
	CostFormData,
	CurrencyAndFundingData,
	CurrencyAndFundingFormData,
	HeadcountFormData,
	ServiceProviderCountryCapability,
	ServiceProviderCountrySetup
} from "src/app/shared/models/service-provider-country-types";
import {
	CurrencyCode,
	FundingMethodName,
	PaymentRouteName,
	PaymentTypeName,
	PayoutAccountName
} from "src/app/shared/models/service-provider.type";
import { ServiceProviderCountry } from "../../models/ServiceProviderCountry";

@Injectable({
	providedIn: "root"
})
export class ServiceProviderCountryEditService {
	costChanged$: EventEmitter<void> = new EventEmitter();
	addCost$: EventEmitter<Cost> = new EventEmitter();

	changes$: EventEmitter<void> = new EventEmitter();

	initCapabilitiesChanged$: EventEmitter<void> = new EventEmitter();
	initCurrenciesChanged$: EventEmitter<void> = new EventEmitter();
	initCostsChanged$: EventEmitter<void> = new EventEmitter();

	costData$: EventEmitter<ServiceProviderCountryCapability[] | null> = new EventEmitter();
	createCostData$: EventEmitter<void> = new EventEmitter();

	editCurrenciesFormState$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
	editCapabilitiesFormState$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
	editCostFormState$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

	updateCurrenciesData$: ReplaySubject<void> = new ReplaySubject<void>(1);
	updateCapabilityData$: ReplaySubject<void> = new ReplaySubject<void>(1);
	updateProviderCountry$: EventEmitter<void> = new EventEmitter<void>();

	providerCountry!: ServiceProviderCountry;
	costFormChanged: boolean = false;

	_currencies: CurrencyCode[] = [];
	_capabilities: CapabilityCreateObject[] = [];
	_costs: Cost[] = [];

	constructor(
		private readonly serviceProviderService: ServiceProviderService,
		private readonly router: Router,
		private readonly toastService: ToastService
	) {}

	setProviderCountry(data: ServiceProviderCountrySetup) {
		// To avoid an error from the api
		this.providerCountry = new ServiceProviderCountry(
			data?.id || null,
			data?.version || null,
			data?.provider,
			data?.country!,
			data?.paymentCurrencies || null,
			data?.fundingMethods || null,
			data?.paymentTypeCapabilities
		);
	}

	getPaymentTypeCapabilities(): ServiceProviderCountryCapability[] {
		return this.providerCountry?.getPaymentTypeCapabilities()!;
	}

	getServiceProviderCountryCurrenciesAndFundingMethods(): CurrencyAndFundingData {
		return {
			currencies: this.providerCountry.getCurrencies(),
			fundingMethods: this.providerCountry.getFundingMethods()
		};
	}

	updateCurrencies(form: CurrencyAndFundingFormData) {
		this._currencies = [];

		this._currencies = form.currencies;
		const fundingMethods: FundingMethodName[] = [];
		if (form.pullMethod) {
			fundingMethods.push("PULL");
		}
		if (form.pushMethod) {
			fundingMethods.push("PUSH");
		}
		this.providerCountry.setCurrencyAndFunding(this._currencies, fundingMethods);
	}

	updateCapabilities(capabilitiesFormData: CapabilitiesFormData) {
		this.providerCountry.updatePaymentTypeCapabilitiesData(capabilitiesFormData);
	}

	formChanges() {
		this.changes$.emit();
	}

	updateCostsForm(formData: HeadcountFormData) {
		this.providerCountry.updateHeadcostConfig(formData);
		this.emitCosts();
	}

	emitCosts() {
		this.costData$.emit(this.providerCountry.getPaymentTypeCapabilities());
	}

	currenciesEditChanged(formState: boolean) {
		this.editCurrenciesFormState$.next(formState);
	}

	capabilitiesEditChanged(formState: boolean) {
		this.editCapabilitiesFormState$.next(formState);
	}

	costFormEditChanged(formState: boolean) {
		this.editCostFormState$.next(formState);
	}

	removeCapabilities(
		paymentType: PaymentTypeName,
		payoutAccount: PayoutAccountName,
		route: PaymentRouteName,
		removeType: boolean
	): void {
		this.providerCountry.removeCapabilities(paymentType, payoutAccount, route, removeType);
	}

	updateProviderCountry(costForm: CostFormData) {
		const updateDTO = this.providerCountry.updateCost(costForm);

		this.serviceProviderService.updateServiceProviderCountry(updateDTO).subscribe({
			next: _ => {
				this.router.navigate(["/service-provider-setup"]);
				this.toastService.showSuccess("Provider Country Updated Successfully!");
			},
			error: _ => {
				this.toastService.showError("There was a problem creating you costs, please try again");
			}
		});
	}
}
