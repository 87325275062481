import { Injectable } from "@angular/core";
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from "@ngrx/data";
import { ErrorTypesModule } from "../error-types.module";
import { ErrorTypeModel } from "../../models/error-management.models";

@Injectable({
	providedIn: "root"
})
export class ErrorTypesEntityService extends EntityCollectionServiceBase<ErrorTypeModel> {
	constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
		super("ErrorManagementTypes", serviceElementsFactory);
	}
}
