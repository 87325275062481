import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ServiceProviderService } from "@shared/services/service-provider/service-provider.service";
import { ServiceProviderCountryItem } from "@shared/models/service-provider-types.interface";

@Component({
	selector: "app-available-country-card",
	templateUrl: "./available-country-card.component.html",
	styleUrls: ["./available-country-card.component.scss"]
})
export class AvailableCountryCardComponent implements OnInit {
	@Input() countryName: string = "";
	@Input() countryCode: string = "";
	@Input() providerId: string = "";
	@Input() noCountry: boolean = false;
	@Input() providerName: string = "";
	@Input() availableCountries: ServiceProviderCountryItem[] = [];

	constructor(private readonly router: Router, private serviceProviderService: ServiceProviderService) {}

	ngOnInit(): void {}

	selectCountry() {
		this.router.navigate(["/service-provider-setup/edit-country"], {
			state: {
				providerId: this.providerId,
				providerName: this.providerName,
				countryName: this.countryName,
				countryCode: this.countryCode,
				availableCountries: this.availableCountries
			}
		});

		this.serviceProviderService.selectedProvider = this.providerId;
	}
}
