import { Injectable, OnDestroy } from "@angular/core";
import { defer, from, Observable, Subject } from "rxjs";
import { map, take } from "rxjs/operators";
import { PayCycleService } from "@shared/services/pay-cycle/pay-cycle.service";
import { PayoutsService } from "@shared/services/payouts/payouts.service";
import { Payout, PayoutPagination } from "src/app/shared/models/payouts.types";
import { ActivePayCycleFacadeService } from "./facade/paycycle/active-pay-cycle-facade.service";
import { PayCyclePageError, PayCycleYearPageable } from "./facade/paycycle/model/PayCycleYearPageable";

@Injectable({
	providedIn: "root"
})
export class PayElectiveTransactionsService implements OnDestroy {
	payouts$!: Observable<Payout[]>;
	destroy$: Subject<void> = new Subject<void>();

	transactionStatusTypes = {
		pendingOrSuccessTypes: ["SUCCESS", "CREATED", "PROCESSING", "PENDING", "PROCESSED"],
		failedPayoutTypes: ["FAILED", "ATTEMPTS_LIMIT_EXCEEDED", "ERROR"]
	};

	constructor(private activePayCycleFacade: ActivePayCycleFacadeService, private payoutsService: PayoutsService) {}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	getActivePayCycle(payGroupId: string): Observable<PayCycleYearPageable | PayCyclePageError> {
		return this.toObservable(this.activePayCycleFacade.getActivePayCycle(payGroupId));
	}

	getPayCycleForYear(payGroupId: string, year: number): Observable<PayCycleYearPageable | PayCyclePageError> {
		return this.toObservable(this.activePayCycleFacade.getPagablePayCylceGiven(payGroupId, year));
	}

	fromPayCylce(payCycleId: string, payGroupId: string): Observable<PayCycleYearPageable | PayCyclePageError> {
		return this.toObservable(this.activePayCycleFacade.getPageablePayCycleFrom(payCycleId, payGroupId));
	}

	getPayoutForEmployee(payGroupId: string, payCycleId: string, employeeId: string): Observable<Payout[]> {
		return this.payoutsService
			.getPayoutsForEmployeeInPayCycle(payGroupId, payCycleId, employeeId)
			.pipe(map((response: PayoutPagination) => response.items));
	}

	transactionStatusIsPendingOrSuccess(transactionStatus: string): boolean {
		return this.transactionStatusTypes.pendingOrSuccessTypes.includes(transactionStatus);
	}

	transactionStatusIsFailure(transactionStatus: string): boolean {
		return this.transactionStatusTypes.failedPayoutTypes.includes(transactionStatus);
	}

	unsupportedTransactionStatus(transactionStatus: string): boolean {
		return (
			!this.transactionStatusIsPendingOrSuccess(transactionStatus) &&
			!this.transactionStatusIsFailure(transactionStatus)
		);
	}

	private toObservable(promise: Promise<any>) {
		return defer(() => from(promise).pipe(take(1)));
	}
}
