import { SelectOption } from "../models/select-option.interface";
import { formatDataForSelect } from "./format-select-data.util";

export const formatStringsForSelect = (strings: string[]): SelectOption[] => {
	return strings.map(string => {
		const option = {
			text: string,
			value: string
		} as SelectOption;

		return formatDataForSelect(option);
	});
};

export const capitalize = (sentence: string): string => {
	return sentence[0].toUpperCase() + sentence.substring(1).toLowerCase();
};
