import { Injectable } from "@angular/core";
import { isAfter, isBefore, isToday } from "date-fns";
import { PayCycleService } from "@shared/services/pay-cycle/pay-cycle.service";
import { PayCycle } from "src/app/shared/models/pay-cycle.interface";
import { lastValueFrom } from "rxjs";
import {
	FilteredPayCycle,
	ActiveCycleStategy as ActiveCycleStrategy,
	PayCycleYearPageable,
	ErrorType,
	PayCyclePageError as PayCyclePageError,
	aNoPayCyclesExistError,
	isPayCycleYearPageable,
	isPayCylcePageError
} from "./model/PayCycleYearPageable";

/**
 * Facade for sorting and filtering pay cycles.
 *
 * Complexity can be reduce when tickets are implemented:
 *
 * @see https://wezaam.atlassian.net/browse/WPAY-2580
 * @see https://wezaam.atlassian.net/browse/WPAY-2578
 *
 * @see PayCycleYearPageable
 * @see PayCyclePageError
 */
@Injectable({
	providedIn: "root"
})
export class ActivePayCycleFacadeService {
	constructor(private payCycleService: PayCycleService) {}

	async getActivePayCycle(payGroupId: string): Promise<PayCycleYearPageable | PayCyclePageError> {
		var payCyclePageable = await this.calculateActivePayCycle(payGroupId);

		if (payCyclePageable == null) return Promise.resolve(aNoPayCyclesExistError());

		await this.addPriorAndNextYear(payCyclePageable, payGroupId);

		return Promise.resolve(payCyclePageable);
	}

	async getPagablePayCylceGiven(payGroupId: string, year: number): Promise<PayCycleYearPageable | PayCyclePageError> {
		var payCycles = await lastValueFrom(this.getPayCyclesForYear(payGroupId, year));
		if (!payCycles) return Promise.resolve(aNoPayCyclesExistError());

		var payCyclePageable = this.toActiveCycle(payCycles, year);
		if (!payCyclePageable) return Promise.resolve(aNoPayCyclesExistError());

		await this.addPriorAndNextYear(payCyclePageable, payGroupId);

		return Promise.resolve(payCyclePageable);
	}

	async getPageablePayCycleFrom(
		payCycleId: string,
		payGroupId: string
	): Promise<PayCycleYearPageable | PayCyclePageError> {
		var cylceWithMatchingId = await lastValueFrom(this.payCycleService.getPayCycleById(payCycleId));
		var payCylceYear = new Date(cylceWithMatchingId.start).getFullYear();
		var result = await this.getPagablePayCylceGiven(payGroupId, payCylceYear);

		if (isPayCylcePageError(result)) return Promise.resolve(result as PayCyclePageError);
		result = result as PayCycleYearPageable;

		return (result = await this.getPayCyclePageableFrom(result, payCycleId));
	}

	private async calculateActivePayCycle(payGroupId: string): Promise<PayCycleYearPageable | null> {
		const GO_LIVE_YEAR = 2021;
		const currentYear = new Date().getFullYear();
		let yearOffSet = currentYear;

		var activePayCycle: PayCycleYearPageable | null = null;

		let payCycles = await lastValueFrom(this.getPayCyclesForYear(payGroupId, currentYear));

		while (activePayCycle == null && yearOffSet >= GO_LIVE_YEAR) {
			activePayCycle = this.toActiveCycle(payCycles, yearOffSet);

			if (activePayCycle == null) {
				// Guard against infinite loop
				if (yearOffSet <= GO_LIVE_YEAR) return null;

				yearOffSet = yearOffSet - 1;
				payCycles = await lastValueFrom(this.getPayCyclesForYear(payGroupId, yearOffSet));
			}
		}

		return activePayCycle;
	}

	private async addPriorAndNextYear(payCyclePageable: PayCycleYearPageable, payGroupId: string) {
		const year = payCyclePageable.year;

		const yearAfterActivePayCycle = year + 1;
		var payCyclesAfterActiveYear = await this.hasPayCyclesIn(payGroupId, yearAfterActivePayCycle);
		payCyclePageable.hasNextYear = payCyclesAfterActiveYear;

		const yearBeforeCurrentActivePayCycle = year - 1;
		var payCyclesBeforeActiveYear = await this.hasPayCyclesIn(payGroupId, yearBeforeCurrentActivePayCycle);
		payCyclePageable.hasPreviousYear = payCyclesBeforeActiveYear;
	}

	private async hasPayCyclesIn(payGroupId: string, year: number): Promise<boolean> {
		const payCycles = await lastValueFrom(this.getPayCyclesForYear(payGroupId, year));

		return payCycles.length > 0;
	}

	private getPayCyclesForYear(payGroupId: string, year: number) {
		const fromDate = new Date(year, 0, 1);
		const toDate = new Date(year, 11, 31);

		return this.payCycleService.findPayCylesSortAscBy([payGroupId], fromDate, toDate);
	}

	private toActiveCycle(payCycles: PayCycle[], year: number): PayCycleYearPageable | null {
		if (payCycles.length == 0) return null;

		const activeCycle = {
			payCycles: payCycles,
			filteredPayCycle: this.getActivePayCycleOrFallBackStrategy(payCycles, year),
			year: year
		} as PayCycleYearPageable;

		return activeCycle;
	}

	private getActivePayCycleOrFallBackStrategy(payCycles: PayCycle[], filterYear: number): FilteredPayCycle {
		// Last cycle if filterYear is in the future
		var currentYear = new Date().getFullYear();
		if (currentYear > filterYear) {
			return {
				cycle: payCycles[payCycles.length - 1],
				index: payCycles.length - 1,
				strategy: ActiveCycleStrategy.LAST_CYCLE_IN_YEAR
			};
		}

		// First cycle if filterYear is in the past
		if (currentYear < filterYear) {
			return {
				cycle: payCycles[0],
				index: 0,
				strategy: ActiveCycleStrategy.FIRST_CYCLE_IN_YEAR
			};
		}

		// Active cycle if it exists
		// Sorting order doesn't follow natural dates commented out until fixed.
		// const activeCycleIndex = payCycles.findIndex((it: PayCycle) => it.status == 'ACTIVE');
		// if (activeCycleIndex > -1) return {
		//   cycle: payCycles[activeCycleIndex],
		//   index: activeCycleIndex,
		//   strategy: ActiveCycleStrategy.HAVING_ACTIVE_STATUS
		// }

		const today = new Date();
		const firstCycleIndexNotEndDateEffective = payCycles.findIndex(
			(it: PayCycle) => isBefore(today, new Date(it.end)) || isToday(new Date(it.end))
		);

		if (firstCycleIndexNotEndDateEffective > -1) {
			return {
				cycle: payCycles[firstCycleIndexNotEndDateEffective],
				index: firstCycleIndexNotEndDateEffective,
				strategy: ActiveCycleStrategy.FIRST_CYCLE_END_DATE_BEFORE_TODAY
			};
		}

		// First cycle in current month
		const currentMonth = this.getMonth(today);

		const currentMonthPayCycle = payCycles.findIndex(
			(it: PayCycle) => this.getMonth(new Date(it.start)) == currentMonth
		);
		if (currentMonthPayCycle > -1)
			return {
				cycle: payCycles[currentMonthPayCycle],
				index: currentMonthPayCycle,
				strategy: ActiveCycleStrategy.FIRST_CYCLE_IN_CURRENT_MONTH
			};

		// Fall back catch all last in the year
		return {
			cycle: payCycles[payCycles.length - 1],
			index: payCycles.length - 1,
			strategy: ActiveCycleStrategy.LAST_CYCLE_IN_YEAR
		};
	}

	private async getPayCyclePageableFrom(result: PayCycleYearPageable, payCylceId: string) {
		var payCycleMatchingIdIndex = result.payCycles.findIndex(it => it.id == payCylceId);

		if (payCycleMatchingIdIndex != -1) {
			result.filteredPayCycle.cycle = result.payCycles[payCycleMatchingIdIndex];
			result.filteredPayCycle.index = payCycleMatchingIdIndex;
			result.filteredPayCycle.strategy = ActiveCycleStrategy.BY_PAY_CYCLE_ID;

			return Promise.resolve(result);
		} else {
			// If no paycycles then fall back to get active pay cylce
			// The backend may consider paycycles to be in the same year if certain dates overlap a year.
			var response = await this.getActivePayCycle(payCylceId);
			if (isPayCylcePageError(response)) return Promise.resolve(response as PayCyclePageError);

			result = response as PayCycleYearPageable;
			result.filteredPayCycle.strategy = ActiveCycleStrategy.BY_PAY_CYCLE_ID_FALL_BACK_TO_LAST_CYCLE_IN_YEAR;

			return Promise.resolve(result);
		}
	}

	getMonth(date: Date) {
		// getMonth() is zero-based so add 1 to get the current month

		return date.getMonth() + 1;
	}
}
