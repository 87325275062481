import { EventEmitter, Injectable, Output } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { take } from "rxjs/operators";
import { breadcrumbSelection } from "@shared/components/modal-wrapper/_models/breadcrumb.interface";
import { ApiService } from "@modules/pay-groups/services/api.service";
import { Customer } from "src/app/shared/models/customer.interface";
import { UpdateCustomerEntityGroupAction } from "src/app/store/actions/customerEntityGroupSelect.action";
import { UpdateProvider } from "src/app/store/actions/providerSelect.action";
import { AppState } from "src/app/store/models/state.model";
import { CustomerService } from "../customer/customer.service";
import { ToastService } from "../toast/toast.service";
@Injectable({
	providedIn: "root"
})
export class StateService {
	/**
	 * @deprecated: Use the redux `getCustomerEntityGroupState` selector instead.
	 *
	 * Candiate for removal requires refactoring dependent to use the NG-RX store instead.
	 *
	 * Example preferred usage:
	 *
	 * 		this.store.pipe(takeUntil(this.destroy$), select(getCustomerEntityGroupState)).subscribe(state => {
	 *      		do something with state here
	 *		});
	 */
	@Output() customerStateChanged: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private payGroupService: ApiService,
		private store: Store<AppState>,
		private router: Router,
		private customerService: CustomerService,
		private toast: ToastService
	) {}

	/**
	 * Indicates a customer has been selected (clears the current entity and paygroup from state).
	 */
	setCustomer(customer: Customer) {
		this.store.dispatch(
			new UpdateCustomerEntityGroupAction({
				customerId: customer.id,
				customerName: customer.name,
				legalEntityId: "",
				payGroupId: ""
			})
		);
		this.customerStateChanged.emit();
	}

	setCustomerById(customerId: string) {
		this.customerService
			.getCustomer(customerId)
			.pipe(take(1))
			.subscribe(customer => {
				this.store.dispatch(
					new UpdateCustomerEntityGroupAction({
						customerId: customer.id,
						customerName: customer.name,
						legalEntityId: "",
						payGroupId: ""
					})
				);
				this.customerStateChanged.emit();
			});
	}

	setPaygroups(customerId: string) {
		const statuses: string = "LIVE, IMPLEMENTATION";
		const PAGE_SIZE = 1000;
		const PAGE_INDEX = 0;
		this.payGroupService.getPayGroups(customerId, "", statuses, "", "", PAGE_SIZE, PAGE_INDEX).subscribe(res => {
			if (res.items.length === 0) {
				this.toast.showError("This customer has no Pay Groups.");
			} else {
				this.store.dispatch(
					new UpdateCustomerEntityGroupAction({
						customerId: customerId,
						legalEntityId: res.items[0].legalEntityId,
						payGroupId: res.items[0].id,
						customerName: res.items[0].customer.name
					})
				);
			}
			this.customerStateChanged.emit();
		});
	}

	setLegalEntity(option: breadcrumbSelection) {
		this.payGroupService.getPayGroup(option.id!).subscribe(res => {
			this.store.dispatch(
				new UpdateCustomerEntityGroupAction({
					customerId: res.customer.id,
					legalEntityId: res.legalEntityId,
					payGroupId: option.id!,
					customerName: res.customer.name
				})
			);
			//Pay electives employee info on PG chnages - route back to master
			if (option.link === "master-data") {
				this.resetProvider();
				this.router.navigate(["/employee-data/master-data"]);
			}
			this.customerStateChanged.emit();
		});
	}

	resetProvider() {
		this.store.dispatch(
			new UpdateProvider({
				provider: ""
			})
		);
	}
}
