import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { TppServiceDefinitionService } from "@modules/service-definition/_services/tpp-service-definition/tpp-service-definition.service";
import { take } from "rxjs/operators";

import { ToastService } from "@shared/services/toast/toast.service";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import {
	BeneficiaryStatusByProviderSummary,
	TotalsByProvider
} from "src/app/shared/models/service-definition/TtpBeneficiaryStatusByProviderSummary";

export type SelectedBeneficiaryStatusByProviderFilters = {
	providerName: string;
	status: BeneficiaryStatusFilterType;
};

export type BeneficiaryStatusFilterType = "ALL" | "FAILED" | "SUCCESS" | "PROCESSING";

@Component({
	selector: "app-beneficary-status-by-provider-summary",
	templateUrl: "./beneficary-status-by-provider-summary.component.html",
	styleUrls: ["./beneficary-status-by-provider-summary.component.scss"]
})
export class BeneficaryStatusByProviderSummaryComponent implements OnInit {
	@Input() payGroupId!: string;
	@Input() summary!: BeneficiaryStatusByProviderSummary;

	defaultSelectedProviderName!: string;

	@Output() providerSelected = new EventEmitter<SelectedBeneficiaryStatusByProviderFilters>();

	providerFailedClass = "provider-error-highlight";
	providerSelectOptions: SelectOption[] = [];

	DEFAULT_PROVIDER_SELECT_OPTION!: string;
	DEFAULT_STATUS_SELECT_OPTION: string = "ALL";

	readonly STATUS_SELECT_OPTIONS: SelectOption[] = [
		{ value: "ALL", text: "All" },
		{ value: "FAILED", text: "Failed" },
		{ value: "SUCCESS", text: "Success" },
		{ value: "PROCESSING", text: "Processing" }
	];

	selectedFiltersForm!: FormGroup;

	constructor(
		private formBuilder: FormBuilder,
		private toastService: ToastService,
		private serviceDefService: TppServiceDefinitionService
	) {}

	ngOnInit(): void {
		this.initializeSelectOptions();
	}

	shouldShowProviderFailedClass(provider: TotalsByProvider): boolean {
		if (provider.totalErrors > 0) return true;
		return false;
	}

	private initializeSelectOptions() {
		//COMMENTED OUT FOR REVIEWING PURPOSES:
		// this.providerSelectOptions = this.summary.namesOfProvidersAssignedToPaygroup.map(providerName => {
		// 	return { value: providerName, text: providerName } as SelectOption;
		// });

		this.serviceDefService
			.serchTPPServiceDefintion(this.payGroupId, "", "", "", "0", "-1")
			.pipe(take(1))
			.subscribe({
				next: res => {
					const groupConfig = res.items[0]?.groupConfig;

					// Extract unique provider names
					const uniqueProviderNames = [...new Set(groupConfig!.map(item => item.providerName))];
					this.providerSelectOptions = uniqueProviderNames.map(
						providerName => ({ value: providerName, text: providerName } as SelectOption)
					);

					this.defaultSelectedProviderName = this.providerSelectOptions[0]?.text;

					this.DEFAULT_PROVIDER_SELECT_OPTION = this.defaultSelectedProviderName;

					const PROVIDER_NAME =
						this.providerSelectOptions.length == 0 ? "" : this.providerSelectOptions[0].value;

					this.selectedFiltersForm = this.formBuilder.group({
						providerFilter: [PROVIDER_NAME],
						beneficiaryStatusFilter: [this.STATUS_SELECT_OPTIONS[0].value]
					});

					if (this.defaultSelectedProviderName != "") {
						this.validateInputProviderNameIsInSummary();
					}

					this.selectedFiltersForm.get("providerFilter")?.valueChanges.subscribe(value => {
						this.emitFormFilterChange();
					});

					this.selectedFiltersForm.get("beneficiaryStatusFilter")?.valueChanges.subscribe(value => {
						this.emitFormFilterChange();
					});
				}
			});
	}

	private validateInputProviderNameIsInSummary() {
		if (this.providerSelectOptions.length == 0) return;

		var matchingOption = this.providerSelectOptions.find(opts => {
			return opts.value === this.defaultSelectedProviderName;
		});

		// Allow users to continue to user the form but show an error message
		// Better than a stop the world scenario.
		if (!matchingOption) {
			this.toastService.showError(
				`Something unexpected happened. 
				No provider found with name: '${this.defaultSelectedProviderName}' in beneficiary summary.`
			);
			console.error(`No provider found with name ${this.defaultSelectedProviderName}, in summary:`);
			console.error(this.summary);
		}
	}

	private emitFormFilterChange() {
		this.providerSelected.emit({
			providerName: this.selectedFiltersForm.get("providerFilter")?.value,
			status: this.selectedFiltersForm.get("beneficiaryStatusFilter")?.value
		});
	}
}
