import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "paymentByFormat"
})
export class PaymentByFormatPipe implements PipeTransform {
	transform(value: string): string {
		switch (value) {
			case "EMPLOYEE":
				return "Employee";
			case "PAY_GROUP":
				return "Paygroup";
			default:
				return value;
		}
	}
}
