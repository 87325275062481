import { Component, Input, OnInit } from "@angular/core";
import { PayElectiveTransactionsService } from "@modules/employee-data/services/pay-elective-transactions.service";
import { TransactionData } from "@modules/employee-data/types/accounts";

@Component({
	selector: "app-pay-elective-transaction-calculation",
	templateUrl: "./pay-elective-transaction-calculation.component.html",
	styleUrls: ["./pay-elective-transaction-calculation.component.scss"]
})
export class PayElectiveTransactionCalculationComponent implements OnInit {
	@Input() element!: TransactionData;
	@Input() indexElement!: number;
	@Input() amountList: number[] = [];

	list: number[] = [];
	rest!: number;
	percentage: string = "";

	constructor(private payElectiveTransactionService: PayElectiveTransactionsService) {}

	ngOnInit(): void {
		// Use for testing the highlighting / tool tip behaviour
		// this.element.status = {
		// 	name: "FAILED",
		// 	message: "Some fictional payout issue occurred."
		// }

		this.getValuesFromAmountList();
		this.getRest();
		this.getPercentage();
	}

	getValuesFromAmountList() {
		this.list = this.amountList.filter((amount: number, index) => index < this.indexElement);
	}

	getRest() {
		let summ: number = 0;
		this.list.forEach((amount: number) => {
			summ = summ + amount;
		});
		this.rest = this.element.payoutAmount - summ;
	}

	getPercentage() {
		const percentage = ((this.element.amount / this.element.payoutAmount) * 100).toString();
		if (percentage.indexOf(".") != -1) this.percentage = percentage.slice(0, percentage.indexOf(".") + 2);
		else this.percentage = percentage;
	}

	transactionStatusIsPendingOrSuccess(): boolean {
		return this.payElectiveTransactionService.transactionStatusIsPendingOrSuccess(this.element.status!.name);
	}

	transactionStatusIsFailure(): boolean {
		return this.payElectiveTransactionService.transactionStatusIsFailure(this.element.status!.name);
	}

	unsupportedTransactionStatus(): boolean {
		return this.payElectiveTransactionService.unsupportedTransactionStatus(this.element.status!.name);
	}
}
