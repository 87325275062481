import { Injectable } from "@angular/core";
import { AbstractControl } from "@angular/forms";

import { TppServiceBeneficiaryDTO } from "src/app/shared/models/tpp-service.interface";
import { TppApiServiceService } from "../api/tpp-api-service.service";
import { ToastService } from "@shared/services/toast/toast.service";

import { TppAccount } from "src/app/shared/models/tpp-account.interface";
import { TppServiceManagementService } from "../management/tpp-service-management.service";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
	providedIn: "root"
})
export class TppCommonServiceService {
	constructor(
		private tppApiService: TppApiServiceService,
		private toastService: ToastService,
		private tppServiceManagementService: TppServiceManagementService
	) {}

	saveNewBeneficiary(
		formData: { account_name: string; country: SelectOption; currency: string },
		fields: Record<string, AbstractControl>,
		serviceId: string
	): Observable<TppServiceBeneficiaryDTO> {
		const rawFormData = formData;

		const data: TppServiceBeneficiaryDTO = {
			service: serviceId,
			country: rawFormData.country.value,
			name: rawFormData.account_name,
			currency: rawFormData.currency,
			bankAccount: {
				bankName: fields.bankName.value,
				country: rawFormData.country.value,
				currency: rawFormData.currency,
				fields: []
			}
		};

		data.bankAccount.fields = this.setBackAccountFileds(fields);

		return this.tppApiService.createTPPAccount(data);
	}

	updateNewBeneficairy(
		rawFormData: { account_name: string; country: SelectOption; currency: string },
		fields: Record<string, AbstractControl>,
		accountBeneficiary: TppAccount
	): Observable<TppServiceBeneficiaryDTO> {
		const data: TppServiceBeneficiaryDTO = {
			id: accountBeneficiary.id,
			version: accountBeneficiary.version ? accountBeneficiary.version : "",
			name: rawFormData.account_name,
			currency: rawFormData.currency,
			service: accountBeneficiary.service,
			country: rawFormData.country.value,
			bankAccount: {
				id: accountBeneficiary.bankAccount.id,
				version: accountBeneficiary.bankAccount.version ? accountBeneficiary.bankAccount.version : "",
				bankName: fields.bankName.value,
				country: rawFormData.country.value,
				currency: rawFormData.currency,
				fields: []
			}
		};

		data.bankAccount.fields = this.setBackAccountFileds(fields);

		return this.tppApiService.updateTPPAcctoun(data);
	}

	setBackAccountFileds(fields: Record<string, AbstractControl>): { key: string; value: string }[] {
		const fieldsArray: { key: string; value: string }[] = [];
		Object.keys(fields).forEach(field => {
			if (
				field !== "bankName" &&
				field !== "account_name" &&
				field !== "country" &&
				field !== "currency" &&
				field !== "client_id" &&
				fields[field].value !== null &&
				fields[field].value !== undefined &&
				fields[field].value !== ""
			) {
				fieldsArray.push({
					key: field,
					value: fields[field].value
				});
			}
		});

		return fieldsArray;
	}
}
