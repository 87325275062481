<div class="parent-container" fxLayout="column">
	<div class="header-content" fxLayout="row">
		<span fxFlex="5" fxLayoutAlign="start center">
			<div
				*ngIf="
					!isCancelledRun &&
					canReprocessPayment &&
					(payout.transactions[0].status.name.toString() === 'FAILED' ||
						payout.transactions[0].status.name.toString() === 'ERROR' ||
						payout.transactions[0].status.name.toString() === 'FAILED_TO_BOOK' ||
						payout.transactions[0].status.name.toString() === 'ATTEMPTS_LIMIT_EXCEEDED')
				"
				class="checkbox-container"
			>
				<mat-checkbox
					color="primary"
					class="checkbox"
					[checked]="reprocessIds.includes(payout.id)"
					(change)="setIds($event, [payout.id])"
					[disabled]="!isInActivatedPayout"
				></mat-checkbox>
			</div>
		</span>

		<span class="employee-id table-row-item" fxFlex="10" fxLayoutAlign="start center">
			{{ payout.employeeId }}
		</span>

		<span class="employee-name table-row-item" fxFlex="10" fxLayoutAlign="start center">
			{{ payout.employeeName }}
		</span>

		<span class="employee-surname table-row-item" fxFlex="10" fxLayoutAlign="start center">
			{{ payout.employeeSurname }}
		</span>

		<span fxFlex="15" fxLayoutAlign="start center" class="amount table-row-item">
			{{ payout.amount.value }}{{ payout.amount.currency | currencySymbol }}
		</span>

		<span fxFlex="15" fxLayoutAlign="start center" class="reference table-row-item">
			{{ payout.reference }}
		</span>

		<span fxFlex="13" class="value-date table-row-item" fxLayoutAlign="start center">
			{{ payout.valueDate | date : "MMM d" }}{{ payout.valueDate | date : "d" | datePosition | lowercase }}
		</span>

		<span class="table-status" *ngIf="!isCancelledRun" fxFlex="19.55" fxLayoutAlign="start center">
			<span
				[ngClass]="{
					'status-green':
						payout.transactions[0].status.name.toString() === 'SUCCESS' ||
						payout.transactions[0].status.name.toString() === 'PROCESSED',
					'status-red':
						payout.transactions[0].status.name.toString() === 'FAILED' ||
						payoutTransactions[0].status.name.toString() === 'FAILED_TO_BOOK' ||
						payout.transactions[0].status.name.toString() === 'ATTEMPTS_LIMIT_EXCEEDED' ||
						payout.transactions[0].status.name.toString() === 'ERROR',
					'status-booked': (payoutTransactions[0].status.name | toString) === 'BOOKED',
					'status-pending':
						payout.transactions[0].status.name.toString() === 'PENDING' ||
						payout.transactions[0].status.name.toString() === 'PROCESSING',
					'status-created': payout.transactions[0].status.name.toString() === 'CREATED'
				}"
				>{{ payout.transactions[0].status.name.toString() | transactionStatuses }}</span
			>
		</span>

		<span class="table-status" *ngIf="isCancelledRun" fxFlex="19.55" fxLayoutAlign="start center">
			<span class="status-cancelled">CANCELLED</span>
		</span>

		<span fxFlex="1">
			<mat-form-field *ngIf="canViewTransactionHistory" class="view-details" fxLayoutAlign="start center">
				<app-icon class="more-dots" [name]="'more'" #trigger [lineWidth]="'1'"></app-icon>

				<mat-select class="options-dropdown" trigger="trigger" #alarmSelect>
					<mat-option (click)="showHistory()"> History log </mat-option>
					<!--<mat-option (click)="goToEmployeeHistory()"> Employee History </mat-option>-->
				</mat-select>
			</mat-form-field>
		</span>
	</div>

	<mat-divider *ngIf="!isLast"></mat-divider>
</div>
