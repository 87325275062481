import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { Observable, Subject, debounceTime, distinctUntilChanged, of, switchMap, takeUntil } from "rxjs";

import { CalendarErrorFilePagination } from "../model/calendar-file.model";
import { CalendarFilesHistoryService } from "../services/calendar-files-history.service";

@Component({
	selector: "app-calendar-file-history-errors",
	templateUrl: "./calendar-file-history-errors.component.html",
	styleUrls: ["./calendar-file-history-errors.component.scss"]
})
export class CalendarFileHistoryErrorsComponent implements OnInit, OnDestroy {
	@Input() taskId!: string;
	@Output() toggle: EventEmitter<boolean> = new EventEmitter<boolean>();

	exit = true;
	perPage = 10;
	pageIndex = 0;
	totalPageCount = 0;
	historyErrors$!: Observable<CalendarErrorFilePagination>;
	destroy$ = new Subject<void>();
	searchKey = "";

	private search$: Subject<string> = new Subject();

	constructor(private calendarFilesHistoryService: CalendarFilesHistoryService) {}

	ngOnInit(): void {
		this.fetchFileHistoryErrorsList(this.taskId);

		this.search$
			.pipe(
				takeUntil(this.destroy$),
				debounceTime(500),
				distinctUntilChanged(),
				switchMap(searchKey => {
					this.searchKey = searchKey;
					return this.calendarFilesHistoryService.searchInAllCalendarFiles(this.taskId, this.searchKey);
				})
			)
			.subscribe(results => {
				this.historyErrors$ = of(results);
				this.totalPageCount = results.totalCount;
				this.pageIndex = results.pageNumber;
			});
	}

	cancel(): void {
		this.exit = false;
	}

	paginate(pagination: PageEvent): void {
		this.perPage = pagination.pageSize;
		this.pageIndex = pagination.pageIndex;
		this.historyErrors$ = this.calendarFilesHistoryService.searchInAllCalendarFiles(
			this.taskId,
			"",
			this.pageIndex,
			this.perPage
		);
	}

	fetchFileHistoryErrorsList(taskId: string): void {
		this.calendarFilesHistoryService
			.searchInAllCalendarFiles(taskId, "", this.pageIndex, this.perPage)
			.subscribe(data => {
				this.totalPageCount = data.totalCount;
				this.historyErrors$ = of(data);
			});
	}

	searchHistoryErrors(event: Event): void {
		this.search$.next((event.target as HTMLInputElement).value);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
