import { Action } from "@ngrx/store";
import { ClientSpecificSubService } from "src/app/shared/models/tpp-service.interface";

export enum tppFormSubServicesSelectActionType {
	SET_SELECTED = "[TPPFormSubServiceSelected] Set Selected"
}
export class UpdateSelectedTPPFormSubServices implements Action {
	readonly type = tppFormSubServicesSelectActionType.SET_SELECTED;

	//add an optional payload
	constructor(public payload: { selectedTPPFormSubServices: ClientSpecificSubService[] }) {}
}
export type tppFormSubServicesSelectAction = UpdateSelectedTPPFormSubServices;
