import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { CommonService } from "@modules/dashboard/services/common.service";
import { PermissionsService } from "@shared/services/permissions/permissions.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { PayoutProcessDialogComponent } from "../../../_components/payouts/payout-process/payout-process-dialog/payout-process-dialog/payout-process-dialog.component";

@Component({
	selector: "app-tpp-process-payments",
	templateUrl: "./tpp-process-payments.component.html",
	styleUrls: ["./tpp-process-payments.component.scss"]
})
export class TppProcessPaymentsComponent implements OnInit, OnDestroy {
	@Output() processBookingClicked: EventEmitter<void> = new EventEmitter<void>();
	@Output() reprocessBookingClicked: EventEmitter<void> = new EventEmitter<void>();
	@Output() cancelBookingClicked: EventEmitter<void> = new EventEmitter<void>();
	@Output() filterByStatus: EventEmitter<string[]> = new EventEmitter<string[]>();
	@Output() runChanged: EventEmitter<string> = new EventEmitter<string>();
	@Output() refresh: EventEmitter<void> = new EventEmitter<void>();

	destroy$ = new Subject<void>();

	bookingsHasBeeenProcessed!: boolean;

	@Input() set _bookingsHasBeeenProcessed(hasBeenProcessed: boolean) {
		this.bookingsHasBeeenProcessed = hasBeenProcessed;
	}

	@Input() defualtStatusFilterValue: string[] = [];

	form!: FormGroup;

	payoutIdList!: string[];

	@Input() set _payoutIdList(list: string[]) {
		this.payoutIdList = list;
	}

	buttonState: string = "inactive";
	@Input() set _buttonState(state: string) {
		this.buttonState = state;
	}

	runSelection!: SelectOption[];
	fixedContainer = false;

	@Input() set _runSelection(selection: SelectOption[]) {
		this.runSelection = selection;
	}

	@Input() set fixedContainerStyle(fixed: boolean) {
		this.fixedContainer = fixed;
	}

	canProcessBooking = false;
	allSelectableValues: string[] = [];

	constructor(
		private dialog: MatDialog,
		private formBuilder: FormBuilder,
		private permissions: PermissionsService,
		private commonService: CommonService
	) {}

	statusOptions: SelectOption[] = [];

	ngOnInit(): void {
		this.statusOptions = this.commonService.getStatusOptions();
		this.allSelectableValues = this.commonService.getAllSelectableValues();

		this.permissions
			.canTriggerBookings()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => {
				this.canProcessBooking = res;
				this.setupRunForm();
			});
	}

	setupRunForm() {
		this.form = this.formBuilder.group({
			selectedRun: [this.runSelection[0].value],
			statusPayment: [this.defualtStatusFilterValue]
		});

		this.form
			.get("selectedRun")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe(milestoneId => {
				this.runChanged.emit(milestoneId);
			});
	}

	selectionChange(): void {
		this.commonService.storeSelectionChange(
			this.form.get("statusPayment")?.value ? this.form.get("statusPayment")?.value : []
		);
	}

	selectAllStatusses(): void {
		this.form.get("statusPayment")?.patchValue(this.allSelectableValues);
		this.selectionChange();
	}

	openDialog(text: string): void {
		let dialogRefData;

		dialogRefData = {
			processPaymentMenuSelection: text,
			isTPP: true
		};

		const dialogRef = this.dialog.open(PayoutProcessDialogComponent, {
			width: "666px",
			height: "441px",
			panelClass: "dialog-container",
			data: dialogRefData
		});

		dialogRef.afterClosed().subscribe((result: boolean) => {
			if (result) {
				if (text === "book the payments") {
					this.processBookingClicked.emit();
				} else {
					this.reprocessBookingClicked.emit();
				}
			}
		});
	}

	refreshPayouts() {
		this.refresh.emit();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
