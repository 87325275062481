import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
	selector: "image-upload",
	templateUrl: "./image-upload.component.html",
	styleUrls: ["./image-upload.component.scss"]
})
export class ImageUploadComponent {
	//Inputs
	@Input() imageFile!: Blob;
	@Input() isDisabled: boolean | undefined = false;
	@Input() imagePath!: string | undefined;
	// Outputs
	@Output() image: EventEmitter<Blob> = new EventEmitter<Blob>();

	changeText: boolean = false;
	isHovering: boolean = false;

	constructor() {}

	onImageSelected(event: any) {
		if (event.target.files) {
			const file: Blob = event.target.files[0];

			if (file.size < 8388608) {
				this.createImagePath(file);
			}
			this.image.emit(file);
		} else {
			this.image.emit(undefined);
		}
	}

	onImageDrop(event: any) {
		const file: Blob = event[0];
		if (file.size < 8388608) {
			this.createImagePath(file);
		}
		this.image.emit(file);
	}

	toggleHover(event: boolean) {
		this.isHovering = event;
	}

	createImagePath(file: Blob) {
		const reader = new FileReader();
		reader.onload = () => {
			this.imagePath = reader.result as string;
		};
		reader.readAsDataURL(file);
	}
}
