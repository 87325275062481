import { Injectable } from "@angular/core";
import { forkJoin, Observable, of, Subject } from "rxjs";
import { debounceTime, delay, elementAt, map, take, takeUntil, tap } from "rxjs/operators";
import { ApiService } from "@modules/pay-groups/services/api.service";
import { ReportsService } from "@shared/services/reports/reports.service";
import { EmployeeCardAndAccountCounts } from "src/app/shared/models/counts";
import {
	PayElectivePayGroupData,
	PayElectivePayGroupPagination,
	PayElectivePayGroupBeneficiaryPagination,
	PayElectivePayGroupBeneficiaryData
} from "src/app/shared/models/employee.interface";
import { PayGroup } from "src/app/shared/models/pay-groups";
import { PayElectiveApiService } from "../pay-elective-api.service";
import {
	BeneficiaryAccountStatusByPayGroup,
	BeneficiaryErrorStatistics
} from "./model/BeneficiaryAccountStatusByPayGroup";

/**
 * Abstracts all the complexity of building the view model,
 * since at the moment the API's are not doing the heavy lifting and we need to join data from multiple sources.
 *
 * To migrate, request the backend to create the ViewModel for us, call the new api and remove this service.
 */
@Injectable({
	providedIn: "root" // TODO this should be provided in the module not the ROOT!
})
export class EmployeeAccountSummaryByPayGroupFacadeService {
	beneficiaryStatistics$: Observable<BeneficiaryAccountStatusByPayGroup[]> = new Observable();
	destroy$: Subject<void> = new Subject();

	constructor(
		private payElectiveApiService: PayElectiveApiService,
		private apiService: ApiService,
		private reportsService: ReportsService
	) {}

	payElectivePayGroupPaginationItems: PayElectivePayGroupBeneficiaryData[] = [];

	getAccountSummaryByPayGroupGiven(
		customerId: string,

		paygroups: PayGroup[]
	): Observable<BeneficiaryAccountStatusByPayGroup[]> {
		const payGroupsList = paygroups.map(element => element.id);

		// create array of legals from paygroups, then make a set object which removes duplicates and then set it back to an array
		const legalEntityIdList = [...new Set(paygroups.map(element => element.legalEntityId))];

		if (paygroups.length !== 0) {
			this.beneficiaryStatistics$ = this.payElectiveApiService
				.getEmployeesPayGroupData(customerId, legalEntityIdList, payGroupsList)
				.pipe(
					takeUntil(this.destroy$),
					map((payElectivePayGroupPagination: PayElectivePayGroupBeneficiaryPagination) => {
						const payElectivePayGroupData: PayElectivePayGroupBeneficiaryData[] =
							payElectivePayGroupPagination.items;

						this.payElectivePayGroupPaginationItems = payElectivePayGroupData;

						let accountByStatusList = this.map(payElectivePayGroupData, paygroups);

						let accountByStatusListOther = this.payGroupMapperWithNoBeneficiaries(
							payElectivePayGroupData,
							paygroups
						);

						return [...accountByStatusList, ...accountByStatusListOther];
					}),
					tap((accountStatusByPayGroup: BeneficiaryAccountStatusByPayGroup[]) => {
						var delayMs = 0;
						accountStatusByPayGroup.forEach((item: BeneficiaryAccountStatusByPayGroup) => {
							this.addBeneficiaryStatisticsTo(
								item,
								this.payElectivePayGroupPaginationItems,
								(delayMs += 50)
							);
						});
					})
				);
		}
		return this.beneficiaryStatistics$;
	}

	destroy() {
		this.destroy$.complete();
	}

	private map(
		payElectivePayGroups: PayElectivePayGroupBeneficiaryData[],
		payGroups: PayGroup[]
	): BeneficiaryAccountStatusByPayGroup[] {
		const accountByStatus: Array<BeneficiaryAccountStatusByPayGroup> = [];

		payElectivePayGroups.forEach((item: PayElectivePayGroupBeneficiaryData) => {
			let filteredPayGroups: PayGroup[] = payGroups.filter((result: PayGroup) => result.id === item.payGroupId);
			if (filteredPayGroups.length < 1) return;

			let payGroup: PayGroup = filteredPayGroups[0];
			var accountStatusByPayGroup = new BeneficiaryAccountStatusByPayGroup(
				payGroup.customer.id,
				payGroup.legalEntityId,
				payGroup.id,
				payGroup.externalId,
				payGroup.legalEntity.data.externalId,
				payGroup.data.name,
				payGroup.legalEntity.data.country,
				payGroup.customer.name,
				payGroup.legalEntity.data.name,
				payGroup.status,
				item.employeesCount,
				item.beneficiariesCount,
				null
			);
			accountByStatus.push(accountStatusByPayGroup);
		});

		return accountByStatus;
	}

	private payGroupMapperWithNoBeneficiaries(
		payElectivePayGroups: PayElectivePayGroupBeneficiaryData[],
		payGroups: PayGroup[]
	): BeneficiaryAccountStatusByPayGroup[] {
		const accountByStatus: Array<BeneficiaryAccountStatusByPayGroup> = [];
		const payIds: Array<string> = [];

		payElectivePayGroups.forEach((element: PayElectivePayGroupBeneficiaryData) => payIds.push(element.payGroupId));

		payGroups.forEach((item: PayGroup) => {
			if (!payIds.includes(item.id)) {
				var accountStatusByPayGroup = new BeneficiaryAccountStatusByPayGroup(
					item.customer.id,
					item.legalEntityId,
					item.id,
					item.externalId,
					item.legalEntity.data.externalId,
					item.data.name,
					item.legalEntity.data.country,
					item.customer.name,
					item.legalEntity.data.name,
					item.status,
					0,
					0,
					null
				);
				accountByStatus.push(accountStatusByPayGroup);
			}
		});
		return accountByStatus;
	}

	private addBeneficiaryStatisticsTo(
		accountStatus: BeneficiaryAccountStatusByPayGroup,
		payGroupData: PayElectivePayGroupBeneficiaryData[],
		delayBetweenRequestsMs: number
	) {
		this.getTotalAccountWithMissingPaymentMethods(accountStatus.payGroupId)
			.pipe(
				take(1),
				delay(delayBetweenRequestsMs),

				map((data: EmployeeCardAndAccountCounts) => {
					const employeeCardAndAccounts = data;

					const index = payGroupData.findIndex(res => {
						return res.payGroupId === accountStatus.payGroupId;
					});

					var payMethodsInactive = 0;
					if (Array.isArray(payGroupData) && payGroupData[index]) {
						payMethodsInactive = payGroupData[index].statusCounts.INACTIVE;
					}

					var stats = new BeneficiaryErrorStatistics(
						payMethodsInactive, // payMethodsErrorCount
						employeeCardAndAccounts.employeesWithoutCards,
						employeeCardAndAccounts.employeesWithoutBanks
					);
					accountStatus.stats = stats;
					return stats;
				})
			)
			.subscribe((mapped: BeneficiaryErrorStatistics) => {
				accountStatus.stats = mapped;
			});
	}

	private getTotalAccountWithMissingPaymentMethods(payGroupId: string): Observable<EmployeeCardAndAccountCounts> {
		return this.apiService.getEmployeeCountWithNoBandOrCard(payGroupId).pipe(takeUntil(this.destroy$)); // calls counts paymethod counts
	}
}
