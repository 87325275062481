import { EventEmitter, Injectable } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { BankAccountsService } from "@shared/services/bank-accounts/bank-accounts.service";
import { ToastService } from "@shared/services/toast/toast.service";
import {
	CountryBankAccount,
	CountryBankAccountCreateDto,
	CountryBankAccountField,
	CountryBankAccountFormField,
	CountryBankAccountUpdateDto
} from "src/app/shared/models/bank-account.interface";

@Injectable({
	providedIn: "root"
})
export class BankAccountsCommonService {
	selectedCountryCode: string = "";
	selectedCountryVersion: number = 0;
	fieldsToCopy: CountryBankAccountField[] = [];
	countyBankAccountFields$: EventEmitter<CountryBankAccount> = new EventEmitter();
	copyCountyBankAccountFields$: EventEmitter<CountryBankAccountField[]> = new EventEmitter();

	setSelectedCountry$: EventEmitter<string> = new EventEmitter();

	constructor(
		private readonly bankAccountService: BankAccountsService,
		private readonly toastService: ToastService
	) {}

	getFieldsByCountryCode(countryCode: string) {
		this.bankAccountService
			.getBankAccountFieldsByCountryCode(countryCode)
			.pipe(take(1))
			.subscribe({
				next: bankAccount => {
					this.countyBankAccountFields$.emit(bankAccount);
					this.selectedCountryVersion = bankAccount.version;
				},
				error: () => {
					//because the back end returns a 400 error when the country has no fields we end up in this block
					this.selectedCountryVersion = 0;
				}
			});
	}

	getFieldsToCopyByCountryCode(countryCode: string): Observable<CountryBankAccount> {
		return this.bankAccountService.getBankAccountFieldsByCountryCode(countryCode);
	}

	setSelectedCountry(country: string) {
		this.setSelectedCountry$.emit(country);
	}

	setCopiedFields() {
		this.copyCountyBankAccountFields$.emit(this.fieldsToCopy);
	}

	updateCountryAccounts(formData: CountryBankAccountFormField[]) {
		const fields: CountryBankAccountField[] = formData.map(data => {
			let object: CountryBankAccountField = {
				key: data.key,
				name: data.name,
				type: data.type,
				unique: data.unique,
				rules: {},
				keyAliases: data.keyAliases
			};
			if (data.minLength || data.minLength === 1) {
				object.rules.MIN = +data.minLength;
			}
			if (data.maxLength || data.maxLength === 1) {
				object.rules.MAX = +data.maxLength;
			}
			if (data.exactLength || data.exactLength === 1) {
				object.rules.EXACT_LENGTH = +data.exactLength;
			}
			if (data.required) {
				object.rules.REQUIRED = data.required;
			}
			return object;
		});

		if (this.selectedCountryVersion) {
			console.log(fields);
			const updateFields: CountryBankAccountUpdateDto = {
				version: this.selectedCountryVersion,
				country: this.selectedCountryCode,
				fields
			};

			this.bankAccountService
				.updateBankAccountFields(updateFields)
				.pipe(take(1))
				.subscribe(res => {
					this.selectedCountryVersion = res.version;
					this.toastService.showSuccess("Fields Updated Successfully");
				});
		} else {
			const createFields: CountryBankAccountCreateDto = {
				country: this.selectedCountryCode,
				fields
			};

			this.bankAccountService
				.createBankAccountFields(createFields)
				.pipe(take(1))
				.subscribe(res => {
					this.selectedCountryVersion = res.version;
					this.toastService.showSuccess("Fields Stored Successfully");
				});
		}
	}

	removeEmpty(obj: CountryBankAccountField[]) {
		return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
	}
}
