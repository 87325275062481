import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { FlexLayoutModule } from "ngx-flexible-layout";

import { InputSelectModule } from "../input-select/input-select.module";
import { InputWrapperModule } from "../input-wrapper/input-wrapper.module";
import { EntityPaygroupSelectDialogComponent } from "./entity-paygroup-select-dialog.component";

@NgModule({
	declarations: [EntityPaygroupSelectDialogComponent],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatIconModule,
		MatButtonModule,
		InputWrapperModule,
		InputSelectModule,
		FlexLayoutModule
	],
	exports: [EntityPaygroupSelectDialogComponent]
})
export class EntityPaygroupSelectDialogModule {}
