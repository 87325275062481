import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { select, Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { TPP_GROUPS } from "src/app/shared/constants/tpp-groups";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { SubServicePaymentDefinition, TppAccount } from "src/app/shared/models/tpp-account.interface";
import { ClientSpecificSubService } from "src/app/shared/models/tpp-service.interface";
import { getSelectedTPPServiceState } from "src/app/store";
import { AppState } from "src/app/store/models/state.model";
import { TppServiceDefinitionService } from "../../../_services/tpp-service-definition/tpp-service-definition.service";
import { PermissionsService } from "@shared/services/permissions/permissions.service";

@Component({
	selector: "app-sub-service-item-frequency",
	templateUrl: "./sub-service-item-frequency.component.html",
	styleUrls: ["./sub-service-item-frequency.component.scss"]
})
export class SubServiceItemFrequencyComponent implements OnInit, OnDestroy {
	@Input() countryCode: string = "";
	@Input() index: number = 0;
	@Input() subService!: SubServicePaymentDefinition;
	@Input() subServicesFormData: ClientSpecificSubService[] = [];

	@Input() beneficiarySelectOptions: SelectOption[] = [];
	@Input() frequencySelectOptions: SelectOption[] = [];
	@Input() tppAccounts!: TppAccount[];

	@Input() serviceDefServices!: ClientSpecificSubService[];

	@Input() payGroupId!: string;

	@Output() sendSubServiceData = new EventEmitter<{
		action: string;
		subService: SubServicePaymentDefinition;
	}>();

	@Output() patchChanges = new EventEmitter<{
		managed_in?: string;
		frequency?: string;
		subService: SubServicePaymentDefinition;
		changedInput: string;
	}>();

	colorEye: string = "#F3F3F3"; //#231F20
	colorTrash: string = "#F3F3F3"; //#ED4C5C

	beneficiaryId: string = "";

	form!: FormGroup;

	firstInputLabel: string = "";

	secondInputLabel: string = "";

	tppGroupOptions: SelectOption[] = TPP_GROUPS;

	managedInSelectOptions: SelectOption[] = [];

	selectedCountry: string = "";

	private destroy$: Subject<void> = new Subject<void>();

	containerClass: string = "";
	textClass: string = "";
	subServicesData!: ClientSpecificSubService[];

	selectedManagedIn: string = "";
	selectedFrequency: string = "";

	managedInEnabled: boolean = true;
	canEditSubServices = false;

	constructor(
		private formBuilder: FormBuilder,
		private store: Store<AppState>,
		private tppServiceDefinitionService: TppServiceDefinitionService,
		private permissions: PermissionsService
	) {}

	ngOnInit(): void {
		this.permissions
			.canEditSubServices()
			.pipe(take(1))
			.subscribe(res => {
				this.canEditSubServices = res;
				this.initForm();
			});

		this.store.pipe(takeUntil(this.destroy$), select(getSelectedTPPServiceState)).subscribe(state => {
			this.selectedCountry = state.selectedTPPService.tppService?.country!;
		});

		this.subServicesData = this.subServicesFormData;

		this.firstInputLabel = "Frequency";
		this.secondInputLabel = "Managed In";

		if (this.subService.tppGroup === "TPP1") {
			this.containerClass = "group-1-container";
			this.textClass = "group-1-text";
		} else if (this.subService.tppGroup === "TPP2") {
			this.containerClass = "group-2-container";
			this.textClass = "group-2-text";
		} else if (this.subService.tppGroup === "TPP3") {
			this.containerClass = "group-3-container";
			this.textClass = "group-3-text";
		} else if (this.subService.tppGroup === "TPP4") {
			this.containerClass = "group-4-container";
			this.textClass = "group-4-text";
		}

		this.initForm();
	}

	initForm(): void {
		this.form = this.formBuilder.group({
			frequency: ["", Validators.required],
			managed_in: ["", Validators.required]
		});

		if (!this.canEditSubServices) {
			this.form.disable();
		}

		this.form
			.get("frequency")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe(value => {
				this.patchChanges.emit({ frequency: value, subService: this.subService, changedInput: "frequency" });

				if (value === "MAIN_RUN" || value === "Main run") {
					this.form.get("managed_in")?.patchValue("Every Main run");

					this.managedInSelectOptions = [];

					this.managedInEnabled = false;
				} else if (value === "PER_RUN" || value === "Per run") {
					this.form.get("managed_in")?.patchValue("Every run");

					this.managedInEnabled = false;
					this.managedInSelectOptions = [];
				} else {
					this.setSelectOptions(value);

					this.managedInEnabled = true;
				}
			});

		this.form
			.get("managed_in")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe(value => {
				this.patchChanges.emit({ managed_in: value, subService: this.subService, changedInput: "managed_in" });
			});

		this.checkSavedData();
	}

	setSelectOptions(frequency: string, managedIn?: string) {
		this.tppServiceDefinitionService
			.getFrequencyManagedIn(this.payGroupId, frequency.toUpperCase())
			.pipe(take(1))
			.subscribe(result => {
				if (result.length > 0) {
					if (managedIn && managedIn.length) {
						this.managedInSelectOptions = result;

						this.selectedManagedIn = this.managedInSelectOptions.find(
							option => option.value === this.removeSuffix(managedIn)
						)!.text;
						this.form.get("managed_in")?.patchValue(this.selectedManagedIn);
						//this.form.get("managed_in")?.patchValue(managedIn);
					} else {
						this.managedInSelectOptions = result;
						this.selectedManagedIn = result[0].text;
						this.form.get("managed_in")?.patchValue(this.formatDropDownText(result[0].text));
					}
				}
			});
	}

	checkSavedData() {
		this.sendSubServiceData.emit({ action: "check", subService: this.subService });

		const frequencyAvailable = this.subServicesData?.some((res: ClientSpecificSubService) => {
			return !!res.frequency;
		});

		if (frequencyAvailable) {
			const savedData = this.tppServiceDefinitionService.getSavedDataStepTwo(
				this.subServicesData,
				this.subService,
				this.frequencySelectOptions
			);
			const frequencyIndex = this.frequencySelectOptions.findIndex(
				element => element.value === savedData.frequency
			);

			if (frequencyIndex !== -1) {
				this.form
					.get("frequency")
					?.patchValue(this.formatDropDownText(this.frequencySelectOptions[frequencyIndex].value));

				if (
					this.frequencySelectOptions[frequencyIndex].value !== "MAIN_RUN" &&
					this.frequencySelectOptions[frequencyIndex].value !== "PER_RUN"
				) {
					this.setSelectOptions(this.frequencySelectOptions[frequencyIndex].value, savedData.managedIn);
				}
			}
		} else {
			this.noServiceSavedDataAlternative();
		}
	}

	removeSuffix(str: string): string {
		const lastTwoChars = str.slice(-2);
		if (lastTwoChars === "st" || lastTwoChars === "nd" || lastTwoChars === "rd" || lastTwoChars === "th") {
			return str.slice(0, -2);
		} else {
			return str;
		}
	}

	formatDropDownText(text: string): string {
		if (text) {
			let format: string = text.toLowerCase().replace("_", " ");
			format = format.charAt(0).toUpperCase() + format.slice(1);
			return format;
		}
		return text;
	}

	noServiceSavedDataAlternative() {
		let index: number = this.serviceDefServices.findIndex((subService: ClientSpecificSubService) => {
			return subService.subServiceId === this.subService.id;
		});

		if (index !== -1) {
			let frequency = this.serviceDefServices[index].frequency;

			if (frequency) {
				this.form.get("frequency")?.patchValue(this.formatDropDownText(frequency));
			}

			if (this.serviceDefServices[index].managedIn) {
				let managedIn = this.serviceDefServices[index].managedIn;
				this.setSelectOptions(frequency, this.serviceDefServices[index].managedIn);

				this.form.get("managed_in")?.patchValue(this.formatDropDownText(managedIn!.toString()));

				this.selectedManagedIn = managedIn!.toString();
			}
		} else {
			this.managedInEnabled = true;
			this.form.get("frequency")?.patchValue(this.formatDropDownText(this.subService.frequency!));
		}
	}

	show() {}

	delete() {}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
