import { trigger, transition, style, animate } from "@angular/animations";
import { Location } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatTabGroup } from "@angular/material/tabs";
import { Router } from "@angular/router";
import { Subject, Subscription } from "rxjs";
import { map, take, takeUntil } from "rxjs/operators";
import { ServiceProviderService } from "@shared/services/service-provider/service-provider.service";
import { ProviderPagination } from "src/app/shared/models/service-provider-country-types";
import { PermissionsService } from "../../../../shared/services/permissions/permissions.service";
import { ProviderCountryTemplateService } from "../../_services/provider-country-template/provider-country-template.service";
import { ServiceProviderCountryEditService } from "../../_services/service-provider-country-edit/service-provider-country-edit.service";
import { DropDownDataExpanded } from "@shared/models/breadcrumbs.interface";
import { ServiceProviderCountryItem, ServiceProviderListItem } from "@shared/models/service-provider-types.interface";
import { CountriesService } from "@shared/services/countries/countries.service";
import { ToastService } from "@shared/services/toast/toast.service";

@Component({
	selector: "app-edit-country",
	templateUrl: "./edit-country.component.html",
	styleUrls: ["./edit-country.component.scss"],
	animations: [
		trigger("fade", [transition("void => *", [style({ opacity: 0 }), animate(1000, style({ opacity: 1 }))])])
	]
})
export class EditCountryComponent implements OnInit, OnDestroy {
	formChangeSubscription: Subscription = new Subscription();
	costFormChangeSubscription: Subscription = new Subscription();
	destroy$: Subject<void> = new Subject();

	countryName: string = "";
	countryCode: string = "";
	providerId: string = "";
	providerName: string = "";

	showWarning: boolean = false;
	costUpdate: boolean = false;

	currenciesValid: boolean = false;
	capabilitiesValid: boolean = false;
	costsValid: boolean = false;
	hasProviderCountry: boolean = false;
	isSuperUser: boolean = false;

	countriesDropDownData: DropDownDataExpanded[] = [];
	providersDropDownData: DropDownDataExpanded[] = [];

	constructor(
		private readonly location: Location,
		private readonly serviceProviderService: ServiceProviderService,
		public readonly serviceProviderCountryEditService: ServiceProviderCountryEditService,
		private readonly providerCountryTemplateService: ProviderCountryTemplateService,
		private permissions: PermissionsService,
		private countriesService: CountriesService,
		private toast: ToastService,
		private router: Router
	) {}

	ngOnInit(): void {
		this.permissions
			.canEditServiceProviders()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => (this.isSuperUser = res));

		history.state.availableCountries.forEach((element: ServiceProviderCountryItem) => {
			this.countriesDropDownData.push({ id: element.country.code, name: element.country.name });
		});

		this.serviceProviderService
			.getServiceProviderList(-1, 0)
			.pipe()
			.subscribe((result: ServiceProviderListItem[]) => {
				result.forEach(element => {
					this.providersDropDownData.push({ id: element.provider.id, name: element.provider.name });
				});
			});

		this.serviceProviderCountryEditService.costFormChanged = false;
		this.countryName = history.state.countryName;
		this.countryCode = history.state.countryCode;
		this.providerId = history.state.providerId;
		this.providerName = history.state.providerName;
		this.getSettingsTemplate();

		this.formChangeSubscription = this.serviceProviderCountryEditService.changes$
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.showWarning = true;
			});
		this.costFormChangeSubscription = this.serviceProviderCountryEditService.initCostsChanged$
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.costUpdate = true;
			});
	}

	getSettingsTemplate() {
		this.serviceProviderService
			.getProviderSettingsTemplate()
			.pipe(takeUntil(this.destroy$))
			.subscribe(result => {
				this.providerCountryTemplateService.setTemplateSettings(result);
				this.getCountryProvider();
			});
	}

	goBack(): void {
		this.location.back();
	}

	goHome(): void {
		this.router.navigate(["/service-provider-setup"]);
	}

	getCountryProvider() {
		this.hasProviderCountry = false;
		this.serviceProviderService
			.getProviderCountrySetup(this.providerId, this.countryCode)
			.pipe(takeUntil(this.destroy$))
			.subscribe((data: ProviderPagination) => {
				this.serviceProviderCountryEditService.setProviderCountry(data.items[0]);
				this.hasProviderCountry = !!data.items;
			});
	}

	costFormChanged(): boolean {
		return this.serviceProviderCountryEditService.costFormChanged;
	}

	updateCapabilities(tabGroup: MatTabGroup) {
		if (!tabGroup || !(tabGroup instanceof MatTabGroup)) return;

		const tabCount = tabGroup._tabs.length;
		tabGroup.selectedIndex = (tabGroup.selectedIndex! + 1) % tabCount;
		this.serviceProviderCountryEditService.updateCapabilityData$.next();
		this.serviceProviderCountryEditService.updateCurrenciesData$.next();
	}

	updateProviderCountry() {
		this.serviceProviderCountryEditService.updateProviderCountry$.next();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	routingCrumbClicked(routingCrumbClicked: boolean): void {
		if (routingCrumbClicked) {
			this.serviceProviderService.selectedProvider = "";
		}
	}

	externalCrumbDropDownSelection(id: string) {
		if (id.length === 2) {
			this.resetFromCountry(id);
		} else {
			this.resetFromProvider(id);
		}
	}

	resetFromCountry(id: string): void {
		this.countriesService
			.getCountryOptions()
			.pipe(take(1))
			.subscribe({
				next: result => {
					this.countryName = result.find(res => {
						return res.value === id;
					})!.text;
					this.countryCode = id;
					this.providerId = this.providerId;
					this.providerName = this.providerName;
					this.getSettingsTemplate();
				}
			});
	}

	resetFromProvider(id: string): void {
		this.serviceProviderService
			.getServiceProviderList(-1, 0)
			.pipe()
			.subscribe((result: ServiceProviderListItem[]) => {
				const availableCountries = result.find(res => {
					return res.provider.id === id;
				})!.providerCountries.items;

				const firstCountry = result.find(res => {
					return res.provider.id === id;
				})?.providerCountries.items[0];

				if (firstCountry) {
					this.countriesDropDownData = [];
					this.serviceProviderService.selectedProvider = id;
					this.providerId = id;

					this.providerName = result.find(res => {
						return res.provider.id === id;
					})!.provider.name;

					this.countryCode = firstCountry.country.code;
					this.countryName = firstCountry.country.name;

					availableCountries.forEach((element: ServiceProviderCountryItem) => {
						this.countriesDropDownData.push({ id: element.country.code, name: element.country.name });
					});

					this.getSettingsTemplate();
				} else {
					this.toast.showError("Selected Provider has no countries configured");
				}
			});
	}
}
