import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WorldMapService } from "@shared/services/world-map/world-map.service";
import { SelectOption } from "@shared/models/select-option.interface";
import L from "leaflet";
import { Observable, forkJoin, map } from "rxjs";
import { GeoCountry } from "../_models/client_landing-interface";
import { PopupService } from "./popup.service";

@Injectable({
	providedIn: "root"
})
export class MarkerService {
	countries: string = "assets/leaflet-countries/countries.json";

	constructor(
		private http: HttpClient,
		private worldMapService: WorldMapService,
		private popupService: PopupService
	) {}

	makeCountryMarkers(mapComponent: L.Map): void {
		forkJoin([this.worldMapService.getCountryOptionsAsObservable(), this.getWorldCoordinates()])
			.pipe(
				map((data: [SelectOption[], GeoCountry[]]) => {
					const clientCountries: SelectOption[] = data[0];
					const countries: GeoCountry[] = data[1];

					return countries.filter(country =>
						clientCountries.some(clientCountry => clientCountry.value === country.key)
					);
				})
			)
			.subscribe((clientCountries: GeoCountry[]) => {
				for (const c of clientCountries) {
					const circle = L.circleMarker([c.latitude, c.longitude], { className: "blinking" });
					circle.bindPopup(fl => this.popupService.makeCountryPopup(c.key, c.name));
					circle.addTo(mapComponent);
				}
			});
	}

	private getWorldCoordinates(): Observable<GeoCountry[]> {
		return this.http.get(this.countries).pipe(
			map(res => {
				return res as GeoCountry[];
			})
		);
	}
}
