<app-breadcrumbs
	[headerTitle]="'TPP SERVICE DEFINITION'"
	[showAllCrumbs]="true"
	[externalCrumbs]="[{ crumb: '' + selectedService.name + '', dropDownData: this.servicesDropDownData }]"
	(onDropDownCrumbItemSelected)="regress()"
	(onExternalDropDownCrumbItemSelected)="reSelectService($event)"
/>
<div class="main-container">
	<div
		id="subServiceDescriptionContainer"
		class="info-container"
		fxLayout="row"
		*ngIf="showInfo && definitionStep === 1"
	>
		<div id="subServiceDescriptionTextContainer" fxFlex="90" fxLayoutAlign="start center">
			<span id="selectedServiceNameText" class="info-container-text">
				* {{ selectedService.name.toUpperCase() }}:
			</span>
			<span id="selectedServiceDescriptionText" class="info-container-sub-text">
				{{ selectedService.description }}
			</span>
		</div>
		<div id="closeIconContainer" fxFlex="10">
			<app-icon
				id="closeIcon"
				class="close-icon"
				size="40"
				name="close"
				[color]="'#272833'"
				fxLayoutAlign="end"
				(click)="showInfoChange()"
			></app-icon>
		</div>
	</div>

	<div id="subServiceConfigSteps" fxLayout="column" fxLayoutGap="20px">
		<div
			id="payGroupFrequencyContainer"
			class="paygroup-frequency-container"
			fxLayoutAlign="center center"
			*ngIf="definitionStep === 2"
		>
			<span id="payGroupFrequencyTitle" class="paygroup-frequency-text">PAYGROUP FREQUENCY:</span>
			<span id="payGroupFrequency" class="paygroup-frequency-text-small">{{ selectedPayGroupFrequency }}</span>
		</div>

		<div id="stepTitleContainer" fxLayoutAlign="row" class="title-container">
			<div id="stepTitleContainerChild" fxLayout="start center" fxFlex="95">
				<span id="stepTitleText" class="container-header-text"> {{ title }} </span>
			</div>
			<div
				id="serviceDescriptionsClosedContainer"
				class="info-icon-container"
				fxLayout="end end"
				fxFlex="5"
				*ngIf="!showInfo && definitionStep === 1"
				(click)="showInfoChange()"
			>
				<app-icon
					id="infoIcon"
					class="icon-info"
					[name]="'info-circle'"
					[color]="'#0B5FFF'"
					[size]="'25'"
				></app-icon>
			</div>
		</div>

		<div id="serviceConfigStepOneContent" *ngIf="formsInitialized && definitionStep === 1">
			<app-sub-service-item
				*ngFor="let subService of subServices; index as i"
				[countryCode]="selectedCountry"
				[subService]="subService"
				[tppGroupDTO]="tppGroupDTO"
				(sendSubServiceData)="getSubServiceData($event)"
				(patchChanges)="patchChanges($event)"
				[index]="i"
				[beneficiarySelectOptions]="beneficiarySelectOptions"
				[tppAccounts]="tppAccounts"
				[serviceDefServices]="serviceDefinitionServices"
				(skipStepOneAdhoc)="skipStepOne($event)"
				[selectedPayGroupId]="selectedPayGroupId"
				[selectedService]="selectedService"
			></app-sub-service-item>
		</div>

		<div id="serviceConfigStepTwoContent" *ngIf="formsInitialized && definitionStep === 2">
			<app-sub-service-item-frequency
				*ngFor="let subService of selectedFormSubServices; index as i"
				[countryCode]="selectedCountry"
				[subService]="subService"
				[subServicesFormData]="subServicesFormData"
				(sendSubServiceData)="getSubServiceData($event)"
				[frequencySelectOptions]="frequencySelectOptions"
				(patchChanges)="patchChanges($event)"
				[index]="i"
				[payGroupId]="selectedPayGroupId"
				[serviceDefServices]="serviceDefinitionServices"
			></app-sub-service-item-frequency>
		</div>
	</div>

	<div id="stepNavigationButtonsContainer" fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="10px">
		<button
			id="previousButton"
			class="cancel-btn"
			(click)="goPrevious()"
			action-buttons
			mat-flat-button
			[disabled]="false"
		>
			Previous
		</button>

		<button
			id="nextButton"
			mat-button
			class="submit-btn"
			(click)="goNext()"
			[disabled]="!formDataAvailable"
			*ngIf="definitionStep === 1"
		>
			{{ nextButtonTitle }}
		</button>
		<button id="finishButton" mat-button class="submit-btn" (click)="goNext()" *ngIf="definitionStep === 2">
			{{ nextButtonTitle }}
		</button>
	</div>
	<div class="margin-row"></div>
</div>
