import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EventDetailsContainerComponent } from "./event-details-container.component";
import { SharedModule } from "@shared/shared.module";
import { CustomerListModule } from "@shared/components/customer-list/customer-list.module";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatDividerModule } from "@angular/material/divider";
import { CustomerLegalEntitySelectModule } from "@shared/components/customer-legal-entity-select/customer-legal-entity-select.module";
import { NoItemsLayoutModule } from "@shared/components/no-items-layout/no-items-layout.module";
import { ModalWrapperModule } from "@shared/components/modal-wrapper/modal-wrapper.module";
import { CalendarModule } from "@shared/components/calendar/calendar.module";
import { MilestoneDetailsRowModule } from "@shared/components/milestone-details-row/milestone-details-row.module";
import { MatRadioModule } from "@angular/material/radio";
import { FormsSharedModule } from "@shared/modules/forms-shared.module";
import { MatIconModule } from "@angular/material/icon";
import { SlidingPanelModule } from "@shared/components/sliding-panel/sliding-panel.module";
import { MatButtonModule } from "@angular/material/button";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { FlexLayoutModule } from "ngx-flexible-layout";
import { MatDialogModule } from "@angular/material/dialog";
import { InputWrapperModule } from "@shared/components/input-wrapper/input-wrapper.module";
import { PipesModule } from "src/app/shared/pipes/pipes.module";
import { CalendarEventsModule } from "./calendar-events/calendar-events.module";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MilestoneStatusDropdownComponent } from "src/app/standalone/milestone-status-dropdown/milestone-status-dropdown.component";

@NgModule({
	declarations: [EventDetailsContainerComponent],
	imports: [
		CommonModule,
		CustomerListModule,
		MatPaginatorModule,
		MatDividerModule,
		CustomerLegalEntitySelectModule,
		NoItemsLayoutModule,
		ModalWrapperModule,
		CalendarModule,
		MilestoneDetailsRowModule,
		MatRadioModule,
		FormsSharedModule,
		MatIconModule,
		SlidingPanelModule,
		MatButtonModule,
		MatDatepickerModule,
		MatInputModule,
		SharedModule,
		FlexLayoutModule,
		MatDialogModule,
		InputWrapperModule,
		PipesModule,
		CalendarEventsModule,
		MatTooltipModule,
		MilestoneStatusDropdownComponent
	],
	exports: [EventDetailsContainerComponent]
})
export class EventDetailsModule {}
