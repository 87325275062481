<sliding-panel class="sliding-panel-calendar-error-files">
	<div class="header">
		<div class="title-section">
			<div class="text">
				<span>CALENDAR FILE ERRORS</span>
			</div>
		</div>

		<div class="close-button" (click)="toggle.emit()">
			<app-icon [name]="'close'" [color]="'var(--color-basic-1100)'" [size]="'65'" [lineWidth]="'1px'"></app-icon>
		</div>
	</div>

	<div class="log-search">
		<mat-form-field id="searchErrorFile" appearance="outline">
			<app-icon [name]="'search-normal-1'" size="16" matPrefix></app-icon>
			<input id="serchLog" matInput placeholder="Search by" (keyup)="searchHistoryErrors($event)" />
		</mat-form-field>
	</div>

	<div class="table-header">
		<span>CUSTOMER IDS</span>
		<span>CYCLE ID</span>
		<span>PAYMENT</span>
		<span>ERROR DESCRIPTION</span>
	</div>
	<ng-container *ngIf="historyErrors$ | async as errorFiles">
		<div class="table-rows" *ngIf="errorFiles.items.length > 0; else noItems">
			<ng-container *ngFor="let error of errorFiles.items">
				<div class="row-item">
					<div class="item-group">
						<span>{{ error.externalCustomerId }}</span>
						<span>/ {{ error.externalLegalEntityId }}</span>
						<span>/ {{ error.externalPayGroupId }}</span>
					</div>
					<div class="item-date">{{ error.externalPayCycleId }}</div>
					<div class="item">{{ error.paymentType }}</div>
					<div class="item">{{ error.message }}</div>
				</div>
			</ng-container>
		</div>
		<ng-container>
			<mat-paginator
				[length]="totalPageCount"
				[pageSizeOptions]="[5, 10, 25, 100]"
				[pageSize]="perPage"
				[pageIndex]="pageIndex"
				(page)="paginate($event)"
				aria-label="Items per page"
				[color]="'primary'"
			>
			</mat-paginator>
		</ng-container>
	</ng-container>

	<ng-template #noItems>
		<div class="no-records-found">
			<no-items-layout class="no-records-found-message" message="No records found"></no-items-layout>
		</div>
	</ng-template>
</sliding-panel>
