import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "frequencyFormat"
})
export class FrequencyFormatPipe implements PipeTransform {
	transform(value: string): string {
		switch (value) {
			case "PER_RUN":
				return "Per run";

			case "MAIN_RUN":
				return "Main run";

			case "MONTHLY":
				return "Monthly";

			case "QUARTERLY":
				return "Quarterly";

			case "YEARLY":
				return "Yearly";

			default:
				return value;
		}
	}
}
