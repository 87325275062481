import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PaygroupStatusHistoryItemComponent } from "./paygroup-status-history-item/paygroup-status-history-item.component";
import { FlexLayoutModule } from "ngx-flexible-layout";
import { PipesModule } from "src/app/shared/pipes/pipes.module";
import { SlidingPanelModule } from "../sliding-panel/sliding-panel.module";
import { SharedModule } from "@shared/shared.module";
import { PaygroupStatusHistoryComponent } from "./paygroup-status-history.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { NoItemsLayoutModule } from "../no-items-layout/no-items-layout.module";

@NgModule({
	declarations: [PaygroupStatusHistoryItemComponent, PaygroupStatusHistoryComponent],
	exports: [PaygroupStatusHistoryComponent],
	imports: [
		SharedModule,
		FlexLayoutModule,
		CommonModule,
		PipesModule,
		SlidingPanelModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		NoItemsLayoutModule
	]
})
export class PaygroupStatusHistoryModule {}
