import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { StateService } from "@shared/services/state/state.service";
import { Customer } from "src/app/shared/models/customer.interface";

@Component({
	selector: "app-payments",
	templateUrl: "./payments.component.html",
	styleUrls: ["./payments.component.scss"]
})
export class PaymentsComponent implements OnInit {
	constructor(private router: Router, private stateService: StateService) {}
	ngOnInit(): void {}

	customerSelected(customer: Customer) {
		this.stateService.setCustomer(customer);
		this.router.navigate(["/payments/paygroups"]);
	}
}
