<mat-expansion-panel class="panel mat-elevation-z0" [expanded]="expanded">
	<mat-expansion-panel-header class="settlement-accounts-panel-header">
		<mat-panel-title>Access fields configuration</mat-panel-title>
	</mat-expansion-panel-header>
	<div class="content flex flex-column flex-align-stretch-space-between">
		<div class="add-button-container button-gap">
			<button id="addFieldButton" (click)="onAddClicked()" mat-button class="c-button-dashed c-button-fill">
				<app-icon id="add-field-plus" [name]="'add'"></app-icon>
				<span class="button-text">ADD NEW FIELD</span>
			</button>
		</div>
		<form [formGroup]="accountFieldsForm" class="flex-1" style="--max-width: 100%">
			<div formArrayName="accountFields">
				<div
					*ngFor="let accountField of accountField.controls; let i = index"
					[formGroupName]="i"
					class="field-item-{{ i }} flex flex-row flex-align-center-space-between"
				>
					<input-wrapper label="Key *" class="flex-1" style="--max-width: 20%">
						<input type="text" id="key-{{ i }}" formControlName="key" />
						<div
							id="key-error-{{ i }}"
							input-errors
							*ngIf="accountField.get('key')?.touched && accountField.get('key')?.errors?.required"
						>
							Please provide a Key
						</div>
					</input-wrapper>
					<input-wrapper label="Name *" class="flex-1" style="--max-width: 20%">
						<input id="name-{{ i }}" type="text" formControlName="name" />
						<div
							id="key-name-{{ i }}"
							input-errors
							*ngIf="accountField.get('name')?.touched && accountField.get('name')?.errors?.required"
						>
							Please Provide a Name
						</div>
					</input-wrapper>
					<input-wrapper label="Type *" class="flex-1" style="--max-width: 20%">
						<input-select
							id="type-{{ i }}"
							[options]="selectOptionsFieldTypes"
							formControlName="type"
							[required]="true"
						>
						</input-select>
						<div
							id="key-type-{{ i }}"
							input-errors
							fxFlex="20"
							*ngIf="accountField.get('type')?.touched && accountField.get('type')?.errors?.required"
						>
							Please Select a Type
						</div>
					</input-wrapper>
					<input-wrapper class="last flex-1" label="Required" style="--max-width: 6%">
						<mat-slide-toggle
							id="required-{{ i }}"
							formControlName="required"
							class="mat-slide-toggle"
							color="primary"
						></mat-slide-toggle>
					</input-wrapper>
					<input-wrapper class="last flex-1" label="Secret" style="--max-width: 6%">
						<mat-checkbox id="secret-{{ i }}" class="secret-checkbox" formControlName="secret">
						</mat-checkbox>
					</input-wrapper>
					<input-wrapper class="last delete flex-1" style="--max-width: 3%">
						<button
							id="delete-{{ i }}"
							matTooltip="remove fields"
							(click)="cancelAccountFields(i)"
							class="remove-button"
						>
							<app-icon [name]="'trash'" [color]="'var(--color-danger-500)'"></app-icon>
						</button>
					</input-wrapper>
				</div>
			</div>
		</form>
		<app-settlement-account-field-item
			[fields]="fields"
			(fieldRemoved)="fieldRemoved($event)"
		></app-settlement-account-field-item>
	</div>
</mat-expansion-panel>
