import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { DeleteDialogComponent } from "./delete-dialog.component";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { FlexLayoutModule } from "ngx-flexible-layout";
import { SharedModule } from "@shared/shared.module";

@NgModule({
	declarations: [DeleteDialogComponent],
	imports: [CommonModule, MatIconModule, MatButtonModule, MatDialogModule, FlexLayoutModule, SharedModule],
	exports: [DeleteDialogComponent]
})
export class DeleteDialogModule {}
