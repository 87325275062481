import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { Observable, Subject, Subscription } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { DeleteDialogComponent } from "@shared/components/delete-dialog/delete-dialog.component";
import { breadcrumbDataObject } from "@shared/components/modal-wrapper/_models/breadcrumb.interface";
import { TppServiceManagementService } from "@modules/tpp-catalogue/_services/management/tpp-service-management.service";
import { BreadcrumbsService } from "@shared/services/breadcrumbs/breadcrumbs.service";
import { StateService } from "@shared/services/state/state.service";
import { ToastService } from "@shared/services/toast/toast.service";
import { TppServicesService } from "@shared/services/tpp-services/tpp-services.service";
import { TPPGroupDTO, TppService } from "src/app/shared/models/tpp-service.interface";
import { getCustomerEntityGroupState } from "src/app/store";
import { AppState } from "src/app/store/models/state.model";
import { TppServiceDefinitionService } from "../../_services/tpp-service-definition/tpp-service-definition.service";
import { TtpServiceSelectionService } from "../../_services/tpp-services/ttp-service-selection.service";
import {
	TppServicesByByPayGroupViewModel,
	TtpService
} from "src/app/shared/models/service-definition/TppServiceDefinitionViewModel";
import { DropDownDataExpanded } from "src/app/shared/models/breadcrumbs.interface";
import { UpdateSelectedTPPService } from "@store/actions/tppServiceSelect.action";

@Component({
	selector: "app-tpp-service-adhoc-services",
	templateUrl: "./tpp-service-adhoc-services.component.html",
	styleUrls: ["./tpp-service-adhoc-services.component.scss"]
})
export class TppServiceAdhocServicesComponent implements OnInit, OnDestroy {
	countryName: string = "";
	tppService!: TppService;
	breadCrumbArray: breadcrumbDataObject[] = [];
	serviceName: string = "";
	btnDisabled: boolean = true;
	showBeneficiaryForm: boolean = false;
	serviceToEdit = {} as TppService;

	private destroy$: Subject<void> = new Subject<void>();
	private stateSubscription!: Subscription;

	serviceInfo!: TppService;

	//Bradcrumb veriables
	selectedCustomerId: string = "";
	selectedLegalEntityId: string = "";
	selectedPayGroupId: string = "";
	_country: string = "";
	_customerName: string = "";
	_payGroupName: string = "";
	tppGroupDTO!: TPPGroupDTO;
	isServiceEdit: boolean = false;

	SELECT_TTP_SERVICE_ROUTE = "/service-definition/tpp/select-service";
	servicesDropDownData!: DropDownDataExpanded[];

	constructor(
		private router: Router,
		private tppServiceManagementService: TppServiceManagementService,
		private store: Store<AppState>,
		private tppServicesService: TppServicesService,
		private stateService: StateService,
		private breadCrumbService: BreadcrumbsService,
		public dialog: MatDialog,
		private toast: ToastService,
		private tppServiceDefitionService: TppServiceDefinitionService,
		private tppServiceSelectionService: TtpServiceSelectionService
	) {}

	ngOnInit(): void {
		if (this.tppServiceSelectionService.getServicesArray()?.servicesByServiceType.length) {
			this.filterServicesForDropDown(this.tppServiceSelectionService.getServicesArray());
		}
		this.getServiceStates();

		this.stateSubscription = this.stateService.customerStateChanged.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.getServiceStates();
		});

		this.serviceToEdit = this.tppServicesService.getSelectedEditAdHocService();

		if (this.serviceToEdit && this.serviceToEdit.id) {
			this.isServiceEdit = true;
		}
	}
	getServiceStates() {
		//This gets the state veriables
		this.store.pipe(takeUntil(this.destroy$), select(getCustomerEntityGroupState)).subscribe(state => {
			// the user changed the customer, legal entity or paygroup via breadcrumbs
			if (this.selectedCustomerId != "") {
				// no paygroup redirect to select paygroup
				if (state.payGroupId == "") {
					this.router.navigate(["/service-definition/tpp/select-paygroup"]);
				}
			}

			this.selectedCustomerId = state.customerId;
			this.selectedLegalEntityId = state.legalEntityId;
			this.selectedPayGroupId = state.payGroupId;
		});

		//Breadcrumb service
		this.breadCrumbService
			.getBreadCrumb(this.selectedPayGroupId)
			.pipe(take(1))
			.subscribe(res => {
				this._country = res.country;
				this._customerName = res.customerName;
				this._payGroupName = res.paygroupName;

				this.breadCrumbArray = [
					{
						display: true,
						type: "customer",
						crumb: this._customerName,
						link: "/service-definition/tpp"
					},
					{
						display: true,
						type: "paygroup",
						crumb: this._payGroupName,
						link: "/service-definition/tpp/select-paygroup"
					},
					{
						display: true,
						crumb: "TPP Services",
						link: this.SELECT_TTP_SERVICE_ROUTE
					},
					{ display: true, crumb: "" }
				];
			});

		this.tppServiceManagementService.resetTppService();

		this.tppServiceManagementService.setIsNewTppService(true);

		this.tppServicesService.serviceNameChanged.pipe().subscribe(x => {
			this.serviceName = x;

			this.breadCrumbArray = [
				{
					display: true,
					type: "customer",
					crumb: this._customerName,
					link: "/service-definition/tpp"
				},
				{
					display: true,
					type: "paygroup",
					crumb: this._payGroupName,
					link: "/service-definition/tpp/select-paygroup"
				},
				{
					display: true,
					crumb: "TPP Services",
					link: this.SELECT_TTP_SERVICE_ROUTE
				},
				{ display: true, crumb: this.serviceName }
			];
		});

		this.tppServiceManagementService
			.getBeneficiaryAccountState()
			.subscribe((enableBeneficiaryAccountForm: boolean) => {
				return (this.showBeneficiaryForm = enableBeneficiaryAccountForm);
			});
	}

	getNewServiceCreation(event: TppService): void {
		this.serviceInfo = event;

		this.serviceName = event.name;
		this.tppService = event;
		this.breadCrumbArray[3] = { display: true, crumb: this.serviceName };
	}

	openDialog(): void {
		if ((!this.serviceToEdit || !this.serviceToEdit.id) && this.serviceInfo) {
			const dialogRef = this.dialog.open(DeleteDialogComponent, {
				panelClass: "select-modalbox",
				width: "660px",
				height: "398px",
				data: {
					text: "This service and all sub services will be deleted",
					btnNoText: "Cancel",
					btnYesText: "Continue"
				}
			});

			dialogRef.afterClosed().subscribe(result => {
				if (result) {
					this.removeAnySavedData();
				}
			});
		} else {
			this.router.navigate([this.SELECT_TTP_SERVICE_ROUTE]);
		}
	}

	removeAnySavedData(): void {
		if (this.serviceInfo) {
			let isDeleted: Observable<boolean> = this.tppServiceDefitionService.removeCurrentSavedData(
				this.serviceInfo,
				true,
				this.selectedPayGroupId
			);

			isDeleted.pipe(takeUntil(this.destroy$)).subscribe(success => {
				if (success) {
					this.toast.showSuccess("Service was successfully removed");

					this.router.navigate([this.SELECT_TTP_SERVICE_ROUTE]);
				} else {
					this.toast.showError("There was an error removing the service");
				}
			});
		} else {
			this.router.navigate([this.SELECT_TTP_SERVICE_ROUTE]);
		}
	}

	ngOnDestroy(): void {
		if (this.stateSubscription) {
			this.stateSubscription.unsubscribe();
		}

		this.tppServiceManagementService.countryCodeServiceTPP = this._country;
		this.destroy$.next();
		this.destroy$.complete();
	}

	save() {
		this.tppServicesService.finishButtonConfigureService(this.selectedPayGroupId);
	}

	finish(event: boolean) {
		this.btnDisabled = !event;
	}

	filterServicesForDropDown(services: TppServicesByByPayGroupViewModel) {
		const providedData = services.servicesByServiceType;

		const filteredServices = providedData.reduce((acc: TtpService[], category) => {
			const filteredServicesInCategory = category.services.filter(service => service.isAdhoc);
			if (filteredServicesInCategory.length > 0) {
				acc.push(...filteredServicesInCategory);
			}
			return acc;
		}, []);

		this.servicesDropDownData = filteredServices.map(res => ({
			id: res.id,
			name: res.name
		}));
	}

	regress(): void {
		this.openDialog();
	}

	reSelectService(id: string) {
		this.tppServicesService
			.findTppServiceByServiceId(id)
			.pipe(takeUntil(this.destroy$))
			.subscribe((service: TppService) => {
				this.tppServicesService.setSelectedEditAdHocService(service);
				this.store.dispatch(
					new UpdateSelectedTPPService({
						selectedTPPService: {
							countryCode: "",
							tppService: service,
							selectedServices: [service.type]
						}
					})
				);

				this.ngOnInit();
			});
	}
}
