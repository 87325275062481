import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { SelectOption } from "@shared/models/select-option.interface";
import { SetStatusAction } from "@store/actions/statusNetPayment.action";
import { AppState } from "@store/models/state.model";

@Injectable({
	providedIn: "root"
})
export class CommonService {
	constructor(private store: Store<AppState>) {}

	getStatusOptions(): SelectOption[] {
		return [
			{ text: "Booked", value: "BOOKED" },
			{ text: "Created", value: "CREATED" },
			{ text: "Error", value: "ERROR" },
			{ text: "Failed", value: "FAILED,ATTEMPTS_LIMIT_EXCEEDED" },
			{ text: "Failed to book", value: "FAILED_TO_BOOK" },
			{ text: "Processed", value: "PROCESSED" },
			{ text: "Processing", value: "PROCESSING,PENDING" }
		];
	}

	getAllSelectableValues(): string[] {
		return [
			"statuses",
			"BOOKED",
			"CREATED",
			"ERROR",
			"FAILED,ATTEMPTS_LIMIT_EXCEEDED",
			"FAILED_TO_BOOK",
			"PROCESSED",
			"PROCESSING,PENDING"
		];
	}

	storeSelectionChange(statusArray: string[]): void {
		this.store.dispatch(
			new SetStatusAction({
				statusPayment: statusArray.length ? this.checkIfAllIsSelected(statusArray) : []
			})
		);
	}

	checkIfAllIsSelected(selection: string[]): string[] {
		return selection.includes("statuses") ? [""] : selection;
	}
}
