import { Pipe, PipeTransform } from "@angular/core";
import { PaymentType, PayMethodDetail } from "../types/accounts";

@Pipe({
	name: "employeeTransactionsFallback"
})
export class EmployeeTransactionsFallbackPipe implements PipeTransform {
	net: PaymentType = PaymentType.NET;
	ttp: PaymentType = PaymentType.TPP;
	ewa: PaymentType = PaymentType.EWA;

	transform(paymentMethodArray: PayMethodDetail | null, supportedPayMethodTypes: string[]): boolean {
		let show: boolean = false;

		if (supportedPayMethodTypes.includes("BANK")) show = true;

		if (paymentMethodArray) {
			if (
				supportedPayMethodTypes.includes("CARD") &&
				(paymentMethodArray.payMethod.paymentTypes?.includes(this.ttp) ||
					paymentMethodArray.payMethod.paymentTypes?.includes(this.ewa))
			)
				show = true;
			if (paymentMethodArray.payMethod.type === "CARD") show = false;
		} else {
			show = true;
		}
		return show;
	}
}
