import { Injectable, OnDestroy } from "@angular/core";
import { LegalEntityService } from "../../../../shared/services/legal-entity/legal-entity.service";
import { FormGroup } from "@angular/forms";
import { LegalEntityApi } from "../../_models/legal-entity-api.interface";
import { CommonLegalEntityService } from "../common/common-legal-entity.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Customer } from "../../../../shared/models/customer.interface";
import { ContactPersonService } from "@shared/services/contact-person/contact-person.service";

@Injectable()
export class CopyLegalEntityService implements OnDestroy {
	private destroy$: Subject<void> = new Subject<void>();

	private entityCreated = new Subject<{ id: string; version: number }>();
	_newEntity = this.entityCreated.asObservable();

	constructor(
		private legalEntityService: LegalEntityService,
		private commonLegalEntityService: CommonLegalEntityService,
		private contactPersonService: ContactPersonService
	) {}

	copyLegalEntity(legalEntityForm: FormGroup) {
		// Format form data to api DTO
		const apiLegalEntity: LegalEntityApi = this.commonLegalEntityService.formatLegalEntityDataApi(
			legalEntityForm.getRawValue(),
			undefined
		);

		//Create Legal entity from copy and assign local value
		this.legalEntityService
			.createLegalEntity(apiLegalEntity)
			.pipe(takeUntil(this.destroy$))
			.subscribe(newLegalEntity => {
				console.log(apiLegalEntity.contactPersons);
				if (apiLegalEntity.contactPersons.length > 0) {
					let contactPersonObject = this.commonLegalEntityService.contactPersonObject(
						newLegalEntity.id,
						apiLegalEntity.contactPersons
					);
					console.log(contactPersonObject);
					this.contactPersonService
						.addContactPerson(contactPersonObject)
						.subscribe(result => console.log(result));
				}
				this.entityCreated.next({ id: newLegalEntity.id, version: newLegalEntity.version });
				this.commonLegalEntityService.legalEntities.push(newLegalEntity);
				this.commonLegalEntityService.legalEntities$.next({
					value: this.commonLegalEntityService.legalEntities,
					type: "COPY"
				});
			});
	}

	setCopyFormState(form: FormGroup) {
		form.enable();
		form.get("externalId")?.setValue(null);
		form.get("name")?.setValue(null);
		form.get("isCopyForm")?.setValue(true);
		form.get("id")?.disable();
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
