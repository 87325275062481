import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ErrorModel } from "../../models/error-management.models";
import { BehaviorSubject, Observable, Subscription, combineLatest, noop, of } from "rxjs";
import { FormBuilder, FormGroup } from "@angular/forms";
import { map, mergeMap, take, tap } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { ErrorManagementService } from "../../services/error-management.service";
import { SelectOption } from "../../../../shared/models/select-option.interface";
import { OutputMessageDialogComponent } from "../output-message-dialog/output-message-dialog.component";
import { PermissionsService } from "@shared/services/permissions/permissions.service";

@Component({
	selector: "app-error-list",
	templateUrl: "./error-list.component.html",
	styleUrls: ["./error-list.component.scss"]
})
export class ErrorListComponent implements OnInit {
	@Input() errors: Observable<ErrorModel[]> = new Observable<ErrorModel[]>();
	@Input() errorTypes!: Observable<SelectOption[]>;
	@Output() updateError: EventEmitter<ErrorModel> = new EventEmitter<ErrorModel>();

	textEvent: BehaviorSubject<string> = new BehaviorSubject<string>("");

	errorTypeUpdatedSubscription: Subscription = new Subscription();

	showInput: boolean = false;
	updatingElement: string = "";

	form!: FormGroup;
	canEditError = false;

	tableColumns: string[] = ["errorSystem", "serviceProvider", "errorType", "wpayMessage"];
	constructor(
		private fb: FormBuilder,
		private errorManagementService: ErrorManagementService,
		public dialog: MatDialog,
		private permissions: PermissionsService
	) {}

	ngOnInit(): void {
		this.permissions
			.canEditError()
			.pipe(take(1))
			.subscribe(res => {
				this.canEditError = res;
			});

		this.errorTypeUpdatedSubscription = this.errorManagementService.errorTypeUpdateSuccess
			.pipe(
				tap(() => {
					this.updatingElement = "";
					this.showInput = false;
				})
			)
			.subscribe();
	}

	openOutputDialog(errorModel: ErrorModel) {
		const dialogRef = this.dialog.open(OutputMessageDialogComponent, {
			data: {
				readonly: false,
				errorData: errorModel
			},

			width: "70%"
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.updateError.emit(result);
			}
		});
	}
	openInputDialog(errorModel: ErrorModel) {
		this.dialog.open(OutputMessageDialogComponent, {
			data: {
				readonly: true,
				errorData: errorModel
			},

			width: "70%"
		});
	}

	checkText(inputText: string) {
		this.textEvent.next(inputText);
		if (!!this.textEvent.value) {
			this.textEvent
				.pipe(
					mergeMap(text => {
						return combineLatest([of(text), this.errorTypes]);
					}),

					map(([input, errors]) => {
						if (!input) {
							noop();
							return "";
						}
						const duplicateErrorType = errors.find(
							error => error.text.toLowerCase() === input.toLowerCase()
						);
						if (duplicateErrorType) {
							input = duplicateErrorType.text;
						}
						return input;
					})
				)
				.subscribe(errorType => this.updateFormAndSubmit(errorType));
		}
	}

	updateFormAndSubmit(input: string): void {
		if (input) {
			this.form.get("errorType")?.patchValue(input);
			this.updateError.emit(this.form.getRawValue());
			this.textEvent.next("");
		}
	}
	updateErrorTag(element: ErrorModel) {
		this.updatingElement = element.id;
		this.showInput = true;

		this.form = this.fb.group({
			id: [element.id],
			version: [element.version],
			outputMessage: [element.outputMessage],
			errorType: [element.errorType]
		});
	}

	ngOnDestroy(): void {
		this.errorTypeUpdatedSubscription.unsubscribe();
	}
}
