<form [formGroup]="capabilitiesForm">
	<div class="flex-table row" role="rowgroup" *ngFor="let type of paymentTypes" [formGroupName]="type">
		<div class="flex-row rowspan">
			<input-wrapper>
				<label
					><input id="selected" type="checkbox" formControlName="selected" />
					<span class="prior-label">{{ type.toString().replace("_", " ") }}</span>
				</label>
			</input-wrapper>
		</div>
		<div class="column">
			<ng-container
				formArrayName="payoutAccountRoutes"
				*ngFor="
					let accountRoute of getCapabilitiesFormGroupArray(type).controls;
					let i = index;
					last as lastRoute
				"
			>
				<div class="flex-row" [formGroupName]="i">
					<div class="flex-cell account-type" role="cell">
						<input-wrapper>
							<input-select
								id="payoutAccount"
								formControlName="payoutAccount"
								label="Select Payout Account Type"
								[options]="getPayoutAccouts(type)"
								[_disabled]="!typeSelected(type)"
								[required]="true"
							>
							</input-select>
							<div
								id="payoutAccount"
								input-errors
								*ngIf="
									accountRoute.get('payoutAccount')?.touched &&
									accountRoute.get('payoutAccount')?.errors?.required
								"
							>
								Select a payout account type
							</div>
						</input-wrapper>
						<span
							*ngIf="lastRoute"
							(click)="addAccountRoute(type, true)"
							[ngClass]="
								!!accountRoute.get('payoutAccount')?.value && !!accountRoute.get('route')?.value
									? 'account-type--link'
									: 'account-type--disable'
							"
						>
							<app-icon id="add-payment-icon" [name]="'add'"></app-icon>
							<span>Add New Payout</span>
						</span>
					</div>
					<div class="flex-cell" role="cell">
						<input-wrapper>
							<input-select
								id="route"
								label="Select Payment Routes"
								formControlName="route"
								[options]="getRoutes(accountRoute.get('payoutAccount')?.value)"
								[required]="true"
								[_disabled]="!accountRoute.get('payoutAccount')?.value"
							>
							</input-select>
							<div
								id="route"
								input-errors
								*ngIf="
									accountRoute.get('route')?.touched && accountRoute.get('route')?.errors?.required
								"
							>
								Select a payment route
							</div>
						</input-wrapper>
					</div>
					<div class="flex-cell" role="cell" fxLayout="row" fxLayoutAlign="space-between flex-start">
						<fieldset class="eta-input-container" [disabled]="!accountRoute.get('route')?.value">
							<input-wrapper>
								<input id="eta" formControlName="eta" placeholder="2w 4d 6h 45m" type="text" />
								<div
									id="eta"
									input-errors
									*ngIf="
										(accountRoute.get('eta')?.touched &&
											accountRoute.get('eta')?.errors?.required) ||
										(accountRoute.get('route')?.value && !accountRoute.get('eta')?.value)
									"
								>
									Please set the ETA
								</div>
							</input-wrapper>
						</fieldset>
						<div class="actions" id="deleteButton" *ngIf="getCapabilitiesFormGroupArray(type).length > 1">
							<app-icon
								class="delete-button"
								(click)="removeRow(type, i, false)"
								[name]="'trash'"
								[color]="'var(--color-danger-500)'"
							></app-icon>
						</div>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
</form>
