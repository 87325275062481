<app-net-payment-header
	[title]="title"
	[subtitle]="subtitle"
	[flag]="flag"
	(goBackEvent)="goBack()"
></app-net-payment-header>
<app-milestone-cards [datasource]="milestoneCounts"></app-milestone-cards>
<app-net-payment-actions
	(refreshHandler)="refreshPaymentOrders()"
	(goToFileHistoryHandler)="goToFileHistory()"
></app-net-payment-actions>
<app-net-payment-data [datasource]="reportData"></app-net-payment-data>
