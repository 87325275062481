// This code defines a function called createRehydrateReducer that creates a Redux reducer with rehydration capability.
import { ActionReducer, Action } from "@ngrx/store";
import { TabStateService } from "@shared/services/tab-state/tab-state.service";
import { IDBreadCrumb } from "@shared/models/breadcrumbs.interface";
import { CalendarStoreSelect } from "@shared/models/calendar-navigation";
import { CustomerEntityGroupSelect } from "@store/models/customerEnitityGroupSelection.model";
import { GlobalDashboardFilterSelect } from "@store/models/globalDashboardFilterSelection.model";
import { PaginationState } from "@store/models/pagination.model";

// Define the createRehydrateReducer function that takes in three parameters:
// - key: a string representing the key used to store the state in the local storage
// - initialState: the initial state of the reducer
// - reducer: the reducer function that handles state updates based on actions

export function createRehydrateReducer<S, A extends Action = Action>(
	key: string,
	reducer: ActionReducer<S, A>
): ActionReducer<S, A> {
	switch (key) {
		case "customerEntityGroupSelectState":
			return rehidrateCustomerState<S, A>(reducer);

		case "GlobalDashboardFilterSelectState":
			return rehidrateGlobalDashboardState<S, A>(reducer);

		case "PaycycleSelectedSelectState":
			return rehidratePayCycleSelectedState<S, A>(reducer);

		case "PaginationState":
			return rehidratePaginationState<S, A>(reducer);
		default:
			return rehidrateBreadcrumbIdentifier<S, A>(reducer);
	}
}

function rehidrateCustomerState<S, A extends Action = Action>(reducer: ActionReducer<S, A>): ActionReducer<S, A> {
	const tabStateService = new TabStateService();
	tabStateService.initialise();
	// Retrieve the stored state from the local storage using the provided key
	const state: S = tabStateService.getCustomerEntityGroupSelect() as S;

	// Return a new reducer function that wraps the original reducer and includes rehydration logic
	return (currentState: S | undefined, action: A) => {
		// Invoke the original reducer with the current state or the rehydrated state (if currentState is undefined)
		const newState = reducer(currentState || state, action);

		// Store the updated state in the local storage using the provided key
		tabStateService.setCustomerEntityGroupSelect(newState as CustomerEntityGroupSelect);

		// Return the updated state
		return newState;
	};
}
function rehidrateBreadcrumbIdentifier<S, A extends Action = Action>(
	reducer: ActionReducer<S, A>
): ActionReducer<S, A> {
	const tabStateService = new TabStateService();
	tabStateService.initialise();
	// Retrieve the stored state from the local storage using the provided key
	const state: S = tabStateService.getBreadcrumbIdentifier() as S;

	// Return a new reducer function that wraps the original reducer and includes rehydration logic
	return (currentState: S | undefined, action: A) => {
		// Invoke the original reducer with the current state or the rehydrated state (if currentState is undefined)
		const newState = reducer(currentState || state, action);

		// Store the updated state in the local storage using the provided key
		tabStateService.setBreadcrumbIdentifier(newState as IDBreadCrumb[]);

		// Return the updated state
		return newState;
	};
}

function rehidrateGlobalDashboardState<S, A extends Action = Action>(
	reducer: ActionReducer<S, A>
): ActionReducer<S, A> {
	const tabStateService = new TabStateService();
	tabStateService.initialise();
	// Retrieve the stored state from the local storage using the provided key
	const state: S = tabStateService.getGlobalDashboardStateSelect() as S;

	// Return a new reducer function that wraps the original reducer and includes rehydration logic
	return (currentState: S | undefined, action: A) => {
		// Invoke the original reducer with the current state or the rehydrated state (if currentState is undefined)
		const newState = reducer(currentState || state, action);

		// Store the updated state in the local storage using the provided key
		tabStateService.setGlobalDashboardStateSelect(newState as GlobalDashboardFilterSelect);

		// Return the updated state
		return newState;
	};
}

function rehidratePayCycleSelectedState<S, A extends Action = Action>(
	reducer: ActionReducer<S, A>
): ActionReducer<S, A> {
	const tabStateService = new TabStateService();
	tabStateService.initialise();
	// Retrieve the stored state from the local storage using the provided key
	const state: S = tabStateService.getPaycycleSelectedStateSelect() as S;

	// Return a new reducer function that wraps the original reducer and includes rehydration logic
	return (currentState: S | undefined, action: A) => {
		// Invoke the original reducer with the current state or the rehydrated state (if currentState is undefined)
		const newState = reducer(currentState || state, action);

		// Store the updated state in the local storage using the provided key
		tabStateService.setPaycycleSelectedStateSelect(newState as CalendarStoreSelect);

		// Return the updated state
		return newState;
	};
}

function rehidratePaginationState<S, A extends Action = Action>(reducer: ActionReducer<S, A>): ActionReducer<S, A> {
	const tabStateService = new TabStateService();
	tabStateService.initialise();
	// Retrieve the stored state from the local storage using the provided key
	const state: S = tabStateService.getPaginationState() as S;

	// Return a new reducer function that wraps the original reducer and includes rehydration logic
	return (currentState: S | undefined, action: A) => {
		// Invoke the original reducer with the current state or the rehydrated state (if currentState is undefined)
		const newState = reducer(currentState || state, action);

		// Store the updated state in the local storage using the provided key
		tabStateService.setPaginationState(newState as PaginationState);

		// Return the updated state
		return newState;
	};
}
