import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { WpayTextTruncateComponent } from "./wpay-text-truncate.component";
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
	declarations: [WpayTextTruncateComponent],
	imports: [CommonModule, MatTooltipModule],
	exports: [WpayTextTruncateComponent]
})
export class WpayTextTruncateModule {}
