<!-- Logo -->
<div *ngIf="logoUrl" class="logo-container">
	<img [src]="safeLogoUrl" class="logo" />
</div>

<!-- Information -->
<div *ngIf="title || subtitle || subtitleImgUrl" class="information-container">
	<!-- Title -->
	<div *ngIf="title" class="information-title">{{ title }}</div>

	<!-- Subtitle -->
	<div *ngIf="subtitle || subtitleImgUrl" class="information-subtitle">
		<img *ngIf="subtitleImgUrl" [src]="safeSubtitleImgUrl" class="information-subtitle-image" />
		<div *ngIf="subtitle" class="information-subtitle-text">{{ subtitle }}</div>
	</div>
</div>

<!-- Actions  -->
<div class="action-container">
	<!-- Copy button -->
	<!--<button
    [disabled]="isCopyDisabled"
    *ngIf="copyAction"
    mat-stroked-button
    (click)="copyItem($event)"
    class="action-delete-button c-button-small"
  >
    <mat-icon class="action-copy-icon">content_copy</mat-icon>
    COPY
  </button>-->
	<!-- Delete button -->
	<button
		[disabled]="isDeleteDisabled"
		*ngIf="deleteAction"
		mat-stroked-button
		(click)="deleteItem($event)"
		class="action-delete-button c-button-small"
	>
		<!-- <mat-icon class="action-delete-icon">delete_outline</mat-icon>
    DELETE
  </button> -->

		<mat-icon class="action-delete-icon">delete_outline</mat-icon>
	</button>
</div>
