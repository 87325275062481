<!-- Items -->
<div class="milestone-container" fxLayout="column" (click)="toggleMilestoneExpansion()">
	<div class="content" #contentDiv [ngClass]="{ 'milestone-expanded': isMilestoneExpanded }">
		<div
			class="header-content"
			[ngClass]="{ 'milestone-expanded': isMilestoneExpanded }"
			fxLayout="row"
			fxLayoutAlign="start center"
		>
			<div class="left-side">
				<app-icon
					class="arrow-milestone-container"
					[ngClass]="{
						'close-group-drop-down': isMilestoneExpanded
					}"
					id="{{ group.group + '_KeyboardArrowDown' }}"
					[name]="'arrow-right-1'"
					[size]="'20'"
					[lineWidth]="'3px'"
					[color]="'var(--color-role-titl)'"
					s
				>
				</app-icon>

				<!-- Milestone Group Badge -->
				<div class="group-container">
					<span class="group">{{ group.text }} Payments</span>
				</div>
			</div>

			<div class="right-side">
				<div
					class="milestone-summary"
					[fxLayout]="'column'"
					[fxFlexAlign]="'space-between center'"
					*ngFor="let milestone of group.milestone"
					fxFlex="23.5"
					[ngStyle]="isMilestoneExpanded ? { visibility: 'hidden' } : { visibility: 'visible' }"
				>
					<div class="milestoneType" [fxLayout]="'row'">
						<span>{{ milestone | milestoneTypes | uppercase }}</span>
					</div>

					<div class="milestoneDate" *ngIf="milestone.date" [fxLayout]="'row'">
						{{ milestone.date | date : "MMM" }} {{ milestone.date | date : "d" }}
						<div class="position">{{ milestone.date | date : "d" | datePosition | lowercase }}</div>
					</div>
				</div>

				<button
					*ngIf="canResetRun && group.milestone && group.milestone.length > 0"
					mat-button
					class="reset-button"
					(click)="resetMilestones($event)"
					[ngClass]="{
						disabled: !canResetPayCycle(),
						'milestone-expanded': isMilestoneExpanded,
						'milestone-collapsed': !isMilestoneExpanded
					}"
					[matTooltip]="tooltipText"
					[matTooltipPosition]="'above'"
					[matTooltipClass]="!canResetPayCycle() ? 'show-reset-tooltip' : 'hide-reset-tooltip'"
				>
					<span id="button-context"
						><app-icon
							id="reset-icon-g2n"
							class="reset-g2n"
							[name]="'group-11'"
							[lineWidth]="'1.5px'"
							matPrefix
						></app-icon>
						<span id="reset-text">Reset</span>
					</span>
				</button>
			</div>
		</div>

		<div class="sub-content">
			<!-- Events -->
			<ng-container *ngIf="group.milestone && group.milestone.length > 0; else noItems">
				<div class="event-items">
					<app-calendar-events
						id="singleEvent{{ milestone.type }}"
						*ngFor="let milestone of group.milestone"
						[_milestone]="milestone"
						[_milestones]="group.milestone"
						[_payGroup]="payGroup"
						[_payCycle]="payCycleSelected!"
						[_showMilestonesState]="isMilestoneExpanded"
						[events]="triggerResetPayoutsAsObservable"
						(updatePayCycle)="onUpdatePayCycle.emit($event)"
						(updateMilestones)="onUpdateMilestone.emit(milestones)"
					></app-calendar-events>
				</div>
			</ng-container>

			<!-- No existing milestones message -->
			<ng-template #noItems>
				<ng-container *ngIf="!!payCycleSelected">
					<div class="event-no-item">
						<div class="event-no-item-content">
							<span
								>Please configure this milestone on the
								<span class="goToCalendar" (click)="goToCustomerPage()">Calendar Page</span>
							</span>
						</div>
					</div>
				</ng-container>
			</ng-template>
		</div>
	</div>
</div>
