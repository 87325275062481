// Util function to format data to select component data

import { SelectOption } from "../models/select-option.interface";

export const formatDataForSelect = (option: SelectOption): SelectOption => {
	return {
		value: option.value,
		text: option.text,
		tooltipMessage: option.tooltipMessage,
		imagePath: option.imagePath
	};
};
