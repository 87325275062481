import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EntityDataService, EntityDefinitionService, EntityMetadataMap } from "@ngrx/data";
import { ErrorTypesDataService } from "./services/error-types-data.service";
import { ErrorTypesEntityService } from "./services/error-types-entity.service";
import { ErrorTypeModel } from "../models/error-management.models";

const entityMetadata: EntityMetadataMap = {
	ErrorManagementTypes: {
		entityDispatcherOptions: {},
		selectId: (errorType: ErrorTypeModel) => errorType.type
	}
};

@NgModule({
	declarations: [],
	imports: [CommonModule],

	providers: [ErrorTypesEntityService, ErrorTypesDataService]
})
export class ErrorTypesModule {
	constructor(
		eds: EntityDefinitionService,
		private entityDataService: EntityDataService,
		private errorManagementDataService: ErrorTypesDataService
	) {
		eds.registerMetadataMap(entityMetadata);
		entityDataService.registerService("ErrorManagementTypes", errorManagementDataService);
	}
}
