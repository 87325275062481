<!-- Top Calendar -->
<app-breadcrumbs
	[headerTitle]="'TODAY,' + (todaysDate | date : 'MMMM d, y' | uppercase)"
	[buttonText]="canCreateCalendarOffCycles ? 'CREATE NEW OFFCYCLE' : ''"
	(onHeaderButtonAction)="canCreateCalendarOffCycles ? createOffcycle() : null"
	[buttonIconObj]="breadCrumbIcon"
/>
<app-calendar
	*ngIf="payGroup"
	sub-header
	(onDayClicked)="dayClick($event)"
	(onNoPayCyclesFound)="noPayCycleSelected($event)"
	(onPayCycleChanges)="payCycleChange($event)"
	(onEditCycle)="editCycle($event)"
	[_payGroup]="payGroup"
></app-calendar>
<div class="calendar-main-container">
	<ng-container *ngIf="payCycleSelected">
		<app-calendar-event-milestone
			*ngFor="let group of milestoneGroups"
			[milestones]="milestones"
			[group]="group"
			[payGroup]="payGroup"
			[payCycle]="payCycleSelected"
			[milestoneGroupSelected]="selectedMilestoneGroups"
			[firstUnconfiguredMilestoneGroup]="firstUnconfiguredMilestoneGroup"
			(onMilestoneGroupSelected)="setMilestoneGroup($event)"
			(viewIconClickedEvent)="openDetails($event)"
			(cardClickedEvent)="openDetails($event)"
		>
		</app-calendar-event-milestone>
	</ng-container>

	<ng-container *ngIf="!payCycleSelected">
		<div class="event-no-item">
			<div class="event-no-item-content">The dates covered have no pay cycles.</div>
		</div>
	</ng-container>

	<!-- NET Creation -->
	<payment-calendar-milestone-creation
		*ngIf="showNetCreation"
		[payCycle]="payCycleSelected"
		[payGroup]="payGroup"
		[date]="dateSelected"
		[milestoneGroup]="milestoneGroupsToCreate!"
		(onClose)="onCloseCreation($event)"
	></payment-calendar-milestone-creation>

	<!-- NET Edition -->
	<payment-calendar-milestone-edition
		*ngIf="showMilestoneEdition"
		[payCycle]="payCycleSelected"
		[milestone]="milestoneSelected"
		[payGroup]="payGroup"
		(onClose)="onCloseEdition($event)"
	></payment-calendar-milestone-edition>
</div>
