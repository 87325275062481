import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CardDetails } from "@modules/global-dashboard/models/global-dashboard-interface";

@Component({
	selector: "app-card",
	templateUrl: "card.component.html",
	styleUrls: ["card.component.scss"]
})
export class CardComponent {
	//Inputs
	@Input() set loadingCard(load: boolean) {
		this.showLoadingAnimation = load;
	}

	@Input() set kpiType(kpi: string) {
		if (kpi) this.kpiName = kpi;
	}

	@Input() isOnGlobalDashboard = false;

	@Input() set card(card: CardDetails) {
		this.kpiCard = card;
		this.darkStyle = card.theme === "dark" ? true : false;
		this.isKpiCardZero = card.kpiType[this.kpiName] && card.kpiType[this.kpiName]!.count! == 0;
	}
	@Input() set selectedKpi(selected: boolean) {
		this.selected = selected;
	}
	@Input() set last(last: boolean) {
		this.isLast = last;
	}

	//Outputs
	@Output() kpiSelected: EventEmitter<string> = new EventEmitter<string>();

	//Public
	darkStyle: boolean = true;
	kpiCard!: CardDetails;
	selected: boolean = false;
	isLast: boolean = false;
	hover: boolean = false;
	kpiName = "";
	showLoadingAnimation = true;
	isKpiCardZero = false;
}
