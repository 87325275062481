import { tppServiceSelectAction, tppServiceSelectActionType } from "../actions/tppServiceSelect.action";
import { Action } from "@ngrx/store";
import { tppServiceSelect } from "../models/tppServiceSelection.model";

const initialState: { selectedTPPService: tppServiceSelect | null } = { selectedTPPService: null };

export const tppServiceSelectReducer = (
	state = initialState,
	action: Action
): { selectedTPPService: tppServiceSelect | null } => {
	const selectAction = action as tppServiceSelectAction;

	switch (selectAction.type) {
		case tppServiceSelectActionType.SET_SELECTED:
			return {
				...state,
				selectedTPPService: selectAction.payload.selectedTPPService
			};

		default:
			return state;
	}
};
