<div class="dialog-header">
	<mat-icon>warning_amber</mat-icon>
	<h3>Warning</h3>
</div>
<div fxLayout="column" class="mat-dialog-content" mat-dialog-content>
	<p>Are you Sure?</p>
	<br />
	<p>All your changes will be lost</p>
</div>
<div align="end" class="mat-dialog-actions" mat-dialog-actions>
	<button action-buttons mat-stroked-button (click)="onCancel()">No, Thanks</button>
	<button action-buttons color="dark" mat-flat-button (click)="onConfirm()" cdkFocusInitial>Yes, Sure</button>
</div>
