import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { TooltipPosition } from "@angular/material/tooltip";

export interface WpayTextTruncate {
	text: string;
	width: string;
	maxWidth?: string;
	toolTipPosition?: "left" | "right" | "above" | "below" | "before" | "after";
}

/**
 * Specify some text and a width and this component will truncate the text to fit within the width using elipsis.
 * - If the text overflows then a tooltip will be displayed on hover.
 */
@Component({
	selector: "app-wpay-text-truncate",
	templateUrl: "./wpay-text-truncate.component.html",
	styleUrls: ["./wpay-text-truncate.component.scss"]
})
export class WpayTextTruncateComponent implements OnInit, AfterViewInit {
	@Input() options!: WpayTextTruncate;
	@ViewChild("truncateContainer") truncateContainer!: ElementRef;

	matToolTipPosition: TooltipPosition = "above";

	constructor(private cd: ChangeDetectorRef) {}

	ngOnInit(): void {
		if (this.options.toolTipPosition) this.matToolTipPosition = this.options.toolTipPosition as TooltipPosition;
	}

	doesNameFitContainer() {
		if (!this.truncateContainer) return true; // handle view not yet init
		return this.truncateContainer.nativeElement.scrollWidth > this.truncateContainer.nativeElement.clientWidth
			? false
			: true;
	}

	ngAfterViewInit() {
		this.cd.detectChanges();
	}
}
