<div class="hide-events-container flex flex-column">
	<div mat-dialog-title class="header">DOWNLOAD REPORT</div>
	<mat-dialog-content class="flex flex-column flex-1 xs-title">
		<div class="confirmation-message">Which type of report would you like to download?</div>
		<div>
			<input-select
				id="status"
				[placeholder]="'Select an option'"
				[options]="reportOptions"
				[disableOptionCentering]="true"
				(onSelectionChange)="chooseTypeReport($event)"
			></input-select>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions [align]="'end'">
		<button class="mat-stroked-button cancel-btn" (click)="close(false)">CANCEL</button>
		<button class="mat-stroked-button submit-btn" (click)="download()">DOWNLOAD</button>
	</mat-dialog-actions>
</div>
