import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of, ReplaySubject } from "rxjs";
import { Transaction } from "src/app/shared/models/payouts.types";
import { environment } from "src/environments/environment";
import { FilesStatus, tppFile, tppFileWithPagination } from "../tpp-payments/models/tpp-data.interface";

@Injectable({
	providedIn: "root"
})
export class TppFileAPI {
	constructor(private http: HttpClient) {}

	getFiles(
		payGroupId: string,
		payCycleId: string,
		milestoneId: string,
		topics: string,
		pageSize: number,
		pageNumber: number
	): Observable<tppFileWithPagination> {
		return this.http.get<tppFileWithPagination>(
			`${environment.apiUri}/v1/pay-groups/${payGroupId}/files?pageSize=${pageSize}&pageNumber=${pageNumber}&payCycleIds=${payCycleId}&milestoneIds=${milestoneId}&topics=${topics}`
		);
	}
}
