import { Injectable } from "@angular/core";
import {
	IDashboardFiltersFormGroup,
	globalDashboardState
} from "@modules/global-dashboard/models/global-dashboard-interface";
import { PaginationOptions } from "@shared/constants/pagination";
import { IDBreadCrumb } from "@shared/models/breadcrumbs.interface";
import { CalendarStore, CalendarStoreSelect } from "@shared/models/calendar-navigation";
import { LocalStorageStore } from "@shared/models/local-storage";
import { CustomerEntityGroupSelect } from "@store/models/customerEnitityGroupSelection.model";
import { GlobalDashboardFilterSelect } from "@store/models/globalDashboardFilterSelection.model";
import { PaginationState } from "@store/models/pagination.model";
import { Observable, of } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class TabStateService {
	private tabId!: string;
	private clearInterval: number = 24 * 60 * 60 * 1000; // 24 hours in milliseconds;

	//defualt values
	private initialCustomerState: CustomerEntityGroupSelect = {
		customerId: "",
		customerName: "",
		legalEntityId: "",
		payGroupId: ""
	};
	private initialGlobalDashboardState: GlobalDashboardFilterSelect = {
		globalDashboard: {
			kpiSelected: "",
			filters: {} as IDashboardFiltersFormGroup
		}
	};

	private initialPayCycleSelectState: CalendarStoreSelect = {
		selectedMonth: {} as CalendarStore
	};

	private initialPaginationState: PaginationState = this.defaultPaginationState;

	private get defaultPaginationState(): PaginationState {
		const initialState: PaginationState = {};
		Object.keys(PaginationOptions).forEach(option => {
			initialState[option] = { pageSize: 10, pageIndex: 0 };
		});
		return initialState;
	}

	//Public Getters

	public getActiveRoute(): string {
		return this.getCurrentState() && this.getCurrentState().wpayActiveRoute
			? this.getCurrentState().wpayActiveRoute
			: "";
	}

	public getBreadcrumbIdentifier(): IDBreadCrumb[] {
		return this.getCurrentState() && this.getCurrentState().wpayBreadcrumbIdentifier
			? this.getCurrentState().wpayBreadcrumbIdentifier
			: [];
	}
	public getCustomerEntityGroupSelect(): CustomerEntityGroupSelect {
		return this.getCurrentState() && this.getCurrentState().customerEntityGroupSelectState
			? this.getCurrentState().customerEntityGroupSelectState
			: this.initialCustomerState;
	}

	public getGlobalDashboardStateSelect(): GlobalDashboardFilterSelect {
		return this.getCurrentState() && this.getCurrentState().globalDashboardState
			? this.getCurrentState().globalDashboardState
			: this.initialGlobalDashboardState;
	}

	public getPaycycleSelectedStateSelect(): CalendarStoreSelect {
		return this.getCurrentState() && this.getCurrentState().paycycleSelected
			? this.getCurrentState().paycycleSelected
			: this.initialPayCycleSelectState;
	}

	public getPaginationState(): PaginationState {
		return this.getCurrentState() && this.getCurrentState().pagination
			? this.getCurrentState().pagination
			: this.initialPaginationState;
	}

	//Public Setters

	public setActiveRoute(url: string): void {
		let currentState: LocalStorageStore = this.getCurrentState();
		currentState.wpayActiveRoute = url;
		currentState.timeStamp = new Date();
		this.setCurrentState(currentState);
	}

	public setBreadcrumbIdentifier(value: IDBreadCrumb[]): void {
		let currentState: LocalStorageStore = this.getCurrentState();
		currentState.wpayBreadcrumbIdentifier = value;
		currentState.timeStamp = new Date();
		this.setCurrentState(currentState);
	}

	public setCustomerEntityGroupSelect(value: CustomerEntityGroupSelect): void {
		let currentState: LocalStorageStore = this.getCurrentState();
		currentState.customerEntityGroupSelectState = value;
		currentState.timeStamp = new Date();
		this.setCurrentState(currentState);
	}

	public setGlobalDashboardStateSelect(value: GlobalDashboardFilterSelect): void {
		let currentState: LocalStorageStore = this.getCurrentState();
		currentState.globalDashboardState = value;
		currentState.timeStamp = new Date();
		this.setCurrentState(currentState);
	}

	public setPaycycleSelectedStateSelect(value: CalendarStoreSelect): void {
		let currentState: LocalStorageStore = this.getCurrentState();
		currentState.paycycleSelected = value;
		currentState.timeStamp = new Date();
		this.setCurrentState(currentState);
	}

	public setPaginationState(value: PaginationState): void {
		let currentState: LocalStorageStore = this.getCurrentState();
		currentState.pagination = value;
		currentState.timeStamp = new Date();
		this.setCurrentState(currentState);
	}

	//Public functions

	public initialise(): void {
		this.clearLocalHistoryAndSetupNewTabId();
	}

	public clearSessionStorage(): Observable<boolean> {
		sessionStorage.removeItem("tabIdentifier");
		let currentState: LocalStorageStore = this.getCurrentState();
		if (currentState) {
			currentState.timeStamp = new Date();
			this.setCurrentState(currentState);
		}
		return of(true);
	}

	//Private functions

	private clearLocalHistoryAndSetupNewTabId(): void {
		const currentTime = new Date().getTime();
		const allTabkeys = Object.keys(localStorage).filter(k => k.startsWith(`tab-`));
		let allTabValues: { timeStamp: Date; key: string }[] = [];

		allTabkeys.forEach(key => {
			allTabValues.push({
				timeStamp: JSON.parse(localStorage.getItem(key)!).timeStamp,
				key: key
			});
		});

		//Sort by date: oldest to most recent
		allTabValues.sort((a, b) => {
			const dateA = new Date(a.timeStamp!).getTime();
			const dateB = new Date(b.timeStamp!).getTime();
			return dateA - dateB;
		});

		//Always keep the most recently updated tab's info hence: -1
		for (let i = 0; i < allTabValues.length - 1; i++) {
			const storedTime = new Date(allTabValues[i].timeStamp!).getTime();

			if (currentTime - storedTime > this.clearInterval && allTabValues.length > 1) {
				localStorage.removeItem(allTabValues[i].key);
			}
		}

		this.setTabIndentifier();
	}

	private getCurrentState(): LocalStorageStore {
		return JSON.parse(localStorage.getItem(this.tabId)!);
	}

	private setCurrentState(state: LocalStorageStore): void {
		localStorage.setItem(this.tabId, JSON.stringify(state));
	}

	private setTabIndentifier(): void {
		let tabId = sessionStorage.getItem("tabIdentifier");
		tabId ? this.checkForMissingData(tabId) : this.setupLocalStorageForNewTab();
	}

	private checkForMissingData(tabId: string): void {
		this.tabId = tabId;
		if (
			!JSON.parse(localStorage.getItem(tabId)!) ||
			!JSON.parse(localStorage.getItem(tabId)!).wpayActiveRoute ||
			JSON.parse(localStorage.getItem(tabId)!).wpayActiveRoute === ""
		) {
			this.redirectToSafestRoute(this.copyDataFromMostRecentSession());
		}
	}

	private setupLocalStorageForNewTab(): void {
		this.tabId = this.generateUniqueTabIdentifier();
		sessionStorage.setItem("tabIdentifier", this.tabId);
		this.redirectToSafestRoute(this.copyDataFromMostRecentSession());
	}

	private redirectToSafestRoute(newState: LocalStorageStore): void {
		let paygroupItem: IDBreadCrumb | undefined = newState.wpayBreadcrumbIdentifier.find(
			breadcrumb => breadcrumb.key === "payGroupId"
		);

		let customerItem: IDBreadCrumb | undefined = newState.wpayBreadcrumbIdentifier.find(
			breadcrumb => breadcrumb.key === "customerName"
		);

		if (paygroupItem && customerItem && paygroupItem.spawn.split("/")[1] === customerItem.spawn.split("/")[1]) {
			newState.wpayActiveRoute = paygroupItem.spawn;
		} else if (customerItem) {
			newState.wpayActiveRoute = customerItem.spawn;
		}

		this.setCurrentState(newState);
	}

	private generateUniqueTabIdentifier(): string {
		return `tab-${Date.now()}-${Math.random().toString(36).substring(7)}`;
	}

	private copyDataFromMostRecentSession(): LocalStorageStore {
		const allDataKeys = Object.keys(localStorage).filter(k => k.startsWith(`tab-`));
		let mostRecentData: LocalStorageStore = this.getEmptyLocalStorageStore();
		for (const dataKey of allDataKeys) {
			const data: LocalStorageStore = JSON.parse(localStorage.getItem(dataKey)!);

			if (
				data &&
				data.wpayActiveRoute &&
				(!mostRecentData.timeStamp || data.timeStamp! > mostRecentData.timeStamp)
			) {
				mostRecentData = data;
			}
		}

		mostRecentData.timeStamp = new Date();
		return mostRecentData;
	}

	private getEmptyLocalStorageStore(): LocalStorageStore {
		return {
			wpayActiveRoute: "",
			customerEntityGroupSelectState: this.initialCustomerState,
			wpayBreadcrumbIdentifier: [],
			globalDashboardState: this.initialGlobalDashboardState,
			paycycleSelected: this.initialPayCycleSelectState,
			pagination: this.initialPaginationState
		};
	}
}
