import { Action } from "@ngrx/store";
import { INetPaymentState } from "../models/netPayment.model";

export enum ENetPaymentActions {
	SetStatus = "[Status NetPayment] set status",
	ResetStatus = "[Status NetPayment] reset status"
}

export class SetStatusAction implements Action {
	public readonly type = ENetPaymentActions.SetStatus;
	constructor(public payload: INetPaymentState) {}
}

export class ResetStatusAction implements Action {
	public readonly type = ENetPaymentActions.ResetStatus;
	constructor(public payload: INetPaymentState) {}
}

export type NetPaymentActions = SetStatusAction | ResetStatusAction;
