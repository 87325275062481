import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { IconModule } from "@shared/components/icon/icon.module";
import { UserRolePermissionsDirective } from "./directives/user-role-permissions.directive";

@NgModule({
	declarations: [UserRolePermissionsDirective],
	imports: [CommonModule, IconModule],
	exports: [IconModule, UserRolePermissionsDirective]
})
export class SharedModule {}
