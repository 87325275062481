import {
	tppFormSubServicesSelectAction,
	tppFormSubServicesSelectActionType
} from "../actions/tppFormSubServicesSelect.action";
import { Action } from "@ngrx/store";
import { ClientSpecificSubService } from "src/app/shared/models/tpp-service.interface";

const initialState: { selectedTPPFormSubServices: ClientSpecificSubService[] | [] } = {
	selectedTPPFormSubServices: []
};

export const tppFormSubServicesSelectReducer = (
	state = initialState,
	action: Action
): { selectedTPPFormSubServices: ClientSpecificSubService[] | [] } => {
	const selectAction = action as tppFormSubServicesSelectAction;

	switch (selectAction.type) {
		case tppFormSubServicesSelectActionType.SET_SELECTED:
			return {
				...state,
				selectedTPPFormSubServices: selectAction.payload.selectedTPPFormSubServices
			};

		default:
			return state;
	}
};
