<modal-wrapper
	*ngIf="payGroup"
	[title]="'TPP File History / ' + payGroup.data.name"
	[subTitle]="payGroup.legalEntity.data.name + ' - ' + payCycleName + ' - Group ' + milestone.group"
	(onTitleButtonAction)="goBack()"
	[addFlag]="payGroup.legalEntity.data.country"
	[titleRows]="2"
	[modalStyle]="'dark'"
	[noBreadCrumbs]="true"
	[exitIconBool]="true"
	[dynamicallyHideSubHeader]="!bannerShow"
	[subHeaderColour]="'var(--pending-color)'"
>
	<ng-container sub-header>
		<div class="reset-notify" [ngClass]="{ hideBanner: !bannerShow }" fxLayoutAlign="space-between center">
			<span class="reset-notify-text" fxLayoutAlign="start center" *ngIf="bannerShowReset">
				Run has been reset.
			</span>
			<span class="reset-notify-text" fxLayoutAlign="start center" *ngIf="bannerShowCancel">
				Run has been cancelled.
			</span>

			<button mat-button class="exit-btn-dialog" (click)="closeBanner()">
				<app-icon id="exit-dialog" name="add" [color]="'var(--color-role-tic)'"></app-icon>
			</button>
		</div>
	</ng-container>

	<div class="button-containers">
		<button (click)="loadFiles()" id="refreshButton" class="refresh-button" mat-icon-button>
			<app-icon
				[name]="'rotate-right'"
				[color]="'var(--color-text-default)'"
				[lineWidth]="'1.5px'"
				[size]="'24'"
			></app-icon>
		</button>

		<input
			id="uploadButton"
			hidden
			type="file"
			#uploader
			(change)="uploadFile(uploader.files)"
			(click)="uploader.value = ''"
		/>

		<button *ngIf="canUploadFile" class="data-input" (click)="uploader.click()">
			<app-icon
				class="data-input-icon"
				[name]="'document-upload'"
				[color]="'var(--color-white)'"
				[lineWidth]="'1.5px'"
				[size]="'24'"
			></app-icon
			><span class="data-input-text">UPLOAD FILE</span>
		</button>
	</div>

	<app-tpp-file-history-list
		*ngIf="files"
		(viewFileErrors)="viewFileErrors($event)"
		(downloadFile)="downloadFile($event)"
		[_files]="files"
		[_lastResetIndex]="lastResetIndex"
	></app-tpp-file-history-list>

	<no-items-layout *ngIf="!files || !files.length" message="No files have been uploaded"></no-items-layout>

	<mat-paginator
		*ngIf="totalCount"
		[length]="totalCount"
		[pageSizeOptions]="[5, 10, 25, 100]"
		[pageSize]="pageSize"
		[pageIndex]="pageNumber"
		(page)="paginate($event)"
		aria-label="Select page"
	>
	</mat-paginator>
</modal-wrapper>
