import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";

@Component({
	selector: "expansion-panel-header-title",
	templateUrl: "./expansion-panel-header-title.component.html",
	styleUrls: ["./expansion-panel-header-title.component.scss"]
})
export class ExpansionPanelHeaderTitleComponent implements OnInit, OnChanges {
	// Inputs
	@Input() deleteAction: boolean = false;
	@Input() editAction: boolean = false;
	@Input() copyAction: boolean = false;

	// Optional Inputs
	@Input() title?: string;
	@Input() subtitle?: string;
	@Input() logoUrl?: string;
	@Input() subtitleImgUrl?: string;
	@Input() isEditDisabled?: boolean = false;
	@Input() isCopyDisabled: boolean | null = false;
	@Input() isDeleteDisabled?: boolean = false;

	// Outputs
	@Output() delete: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() edit: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() copy: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() opened: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() closed: EventEmitter<boolean> = new EventEmitter<boolean>();

	// URLs
	safeLogoUrl!: SafeUrl;
	safeSubtitleImgUrl!: SafeUrl;

	constructor(private sanitizer: DomSanitizer) {}

	ngOnInit(): void {
		this.safeLogoUrl = this.logoUrl ? this.sanitizer.bypassSecurityTrustUrl(this.logoUrl) : "";
		this.safeSubtitleImgUrl = this.subtitleImgUrl ? this.sanitizer.bypassSecurityTrustUrl(this.subtitleImgUrl) : "";
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.logoUrl && changes.logoUrl.currentValue) {
			this.safeLogoUrl = this.logoUrl ? this.sanitizer.bypassSecurityTrustUrl(this.logoUrl) : "";
		}

		if (changes.subtitleImgUrl && changes.subtitleImgUrl.currentValue) {
			this.safeSubtitleImgUrl = this.subtitleImgUrl
				? this.sanitizer.bypassSecurityTrustUrl(this.subtitleImgUrl)
				: "";
		}
	}

	deleteItem(event: any): void {
		event.stopPropagation();
		this.delete.emit(true);
	}

	editItem(event: any): void {
		event.stopPropagation();
		this.edit.emit(true);
	}

	copyItem(event: any): void {
		event.stopPropagation();
		this.copy.emit(true);
	}
}
