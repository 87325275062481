import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
	selector: "no-items-layout",
	templateUrl: "./no-items-layout.component.html",
	styleUrls: ["./no-items-layout.component.scss"]
})
export class NoItemsLayoutComponent implements OnInit {
	// Inputs
	@Input() message: string | undefined;
	@Input() buttonText: string | undefined;
	@Input() isBackButton: boolean = false;
	@Input() disabled: boolean = false;
	@Input() minHeight: string = "calc(100vh / 2)";

	// Outputs
	@Output() onClickButton: EventEmitter<void> = new EventEmitter<void>();

	constructor() {}

	ngOnInit(): void {}

	onClick() {
		this.onClickButton.emit();
	}
}
