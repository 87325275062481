import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UnderscoreRemoverPipe } from "./underscore/underscore-remover.pipe";
import { FileTypePipe } from "./common/file-type.pipe";
import { PageCounterPipe } from "./page-counter/page-counter.pipe";
import { ChunkPipe } from "./chunk/chunk.pipe";
import { ToStringPipe } from "./common/to-string.pipe";
import { FileStatusPipe } from "./common/file-status.pipe";
import { PayoutStatusPipe } from "./common/payout-status.pipe";
import { CurrencySymbolPipe } from "./common/currency-symbol.pipe";
import { RolesPipe } from "./common/roles.pipe";
import { PayElectiveStatusPipe } from "./common/pay-elective-status.pipe";
import { ProcessPayoutMenuPipe } from "./common/process-payout-menu.pipe";
import { CheckCardPipe } from "./common/check-card.pipe";
import { CheckBankPipe } from "./common/check-bank.pipe";
import { DatePositionPipe } from "./common/date-position.pipe";
import { FieldTypesPipe } from "./common/field-types.pipe";
import { MilestoneTypesPipe } from "./common/milestone-types.pipe";
import { MilestoneTypeToIconPipe } from "./common/milestone-type-to-icon.pipe";
import { TppTypesFormatPipe } from "./common/tpp-types-format.pipe";
import { ShortDatePipe } from "./common/short-date.pipe";
import { PaymentByFormatPipe } from "./common/payment-by-format.pipe";
import { FrequencyFormatPipe } from "./common/frequency-format.pipe";
import { TransactionStatusesPipe } from "./common/transaction-statuses.pipe";
import { UserFullNamePipe } from "./common/user-full-name.pipe";
import { SuffixAndAbbreviatedDatePipe } from "./common/suffix-and-abbreviated-date.pipe";
import { MilestoneStatusesPipe } from "./common/milestone-statuses.pipe";
import { FormatStringPipe } from "./common/format-string.pipe";
import { PaymentMethodStatusPipe } from "./common/payment-method-status.pipe";
import { MilestoneTypeStringPipe } from "./common/milestone-type-string.pipe";
import { OrdinalDatePipe } from "./common/ordinal-date.pipe";
import { PaygroupidToLegalEntityIdPipe } from "./common/paygroupid-to-legal-entity-id.pipe";
@NgModule({
	declarations: [
		UnderscoreRemoverPipe,
		FileTypePipe,
		PageCounterPipe,
		ChunkPipe,
		ToStringPipe,
		FileStatusPipe,
		PayoutStatusPipe,
		CurrencySymbolPipe,
		RolesPipe,
		PayElectiveStatusPipe,
		ProcessPayoutMenuPipe,
		CheckCardPipe,
		CheckBankPipe,
		DatePositionPipe,
		FieldTypesPipe,
		MilestoneTypesPipe,
		MilestoneTypeToIconPipe,
		TppTypesFormatPipe,
		ShortDatePipe,
		PaymentByFormatPipe,
		FrequencyFormatPipe,
		TransactionStatusesPipe,
		UserFullNamePipe,
		SuffixAndAbbreviatedDatePipe,
		MilestoneStatusesPipe,
		FormatStringPipe,
		PaymentMethodStatusPipe,
		MilestoneTypeStringPipe,
		OrdinalDatePipe,
		PaygroupidToLegalEntityIdPipe
	],
	imports: [CommonModule],
	exports: [
		UnderscoreRemoverPipe,
		FileTypePipe,
		PageCounterPipe,
		ChunkPipe,
		ToStringPipe,
		FileStatusPipe,
		PayoutStatusPipe,
		CurrencySymbolPipe,
		RolesPipe,
		PayElectiveStatusPipe,
		ProcessPayoutMenuPipe,
		CheckCardPipe,
		CheckBankPipe,
		DatePositionPipe,
		FieldTypesPipe,
		MilestoneTypesPipe,
		MilestoneTypeToIconPipe,
		TppTypesFormatPipe,
		ShortDatePipe,
		PaymentByFormatPipe,
		FrequencyFormatPipe,
		TransactionStatusesPipe,
		UserFullNamePipe,
		SuffixAndAbbreviatedDatePipe,
		MilestoneStatusesPipe,
		FormatStringPipe,
		PaymentMethodStatusPipe,
		MilestoneTypeStringPipe,
		OrdinalDatePipe,
		PaygroupidToLegalEntityIdPipe
	]
})
export class PipesModule {}
