import { Pipe, PipeTransform } from "@angular/core";
import { Milestone, MilestoneDay, MilestoneTypeName } from "../../models/pay-cycle.interface";

@Pipe({
	name: "milestoneTypes"
})
export class MilestoneTypesPipe implements PipeTransform {
	transform(milestone: Milestone | MilestoneDay): string {
		if (milestone.type) {
			switch (milestone.type) {
				case "FUNDING_DATE":
					return "Funding date";

					break;
				case "G2N_FILE":
					return "Payment Orders";
					break;
				case "PAYMENT":
					return "Booking Date";
					break;
				case "DATA_INPUT":
					return "Payment Orders";
					break;
				case "NETS":
					return "Net Payment";
					break;
			}
		}
		return "";
	}
}
