import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { CdkMenuModule } from "@angular/cdk/menu";

import { SharedModule } from "@shared/shared.module";
import { CalendarActionsComponent } from "./calendar-actions.component";

@NgModule({
	declarations: [CalendarActionsComponent],
	exports: [CalendarActionsComponent],
	imports: [CommonModule, MatButtonModule, SharedModule, CdkMenuModule]
})
export class CalendarActionsModule {}
