import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "@auth0/auth0-angular";

import { DashboardPage } from "./dashboard.page";

const routes: Routes = [
	{
		path: "",
		component: DashboardPage,
		data: { breadCrumb: "Dashboard" },
		children: [
			{
				path: "global-dashboard",
				data: { breadCrumb: "" },
				loadChildren: () =>
					import("@modules/global-dashboard/global-dashboard.module").then(m => m.GlobalDashboardModule),
				canActivate: [AuthGuard]
			},

			{
				path: "client-landing",
				data: { breadCrumb: "" },
				loadChildren: () =>
					import("@modules/client-landing/client-landing.module").then(m => m.ClientLandingModule),
				canActivate: [AuthGuard]
			},

			/**
			 * Commented out for now to avoid users from being redirected to the new Single Country map screen
			 */
			// {
			// 	path: "client-summary",
			// 	data: { breadCrumb: "" },
			// 	loadChildren: () =>
			// 		import("@modules/client-summary/client-summary.module").then(m => m.ClientSummaryModule),
			// 	canActivate: [AuthGuard]
			// },

			{
				path: "payments",
				data: { breadCrumb: "Dashboard" },
				loadChildren: () => import("@modules/dashboard/payments.module").then(x => x.PaymentsModule),
				canActivate: [AuthGuard]
			},
			{
				path: "customers",
				data: { breadCrumb: "Customers" },
				loadChildren: () =>
					import("@modules/customer-setup/customer-setup.module").then(m => m.CustomerSetupPageModule),
				canActivate: [AuthGuard]
			},
			{
				path: "legal-entities",
				data: { breadCrumb: "Legal Entities" },
				loadChildren: () =>
					import("@modules/legal-entity-setup/legal-entity-setup.module").then(
						m => m.LegalEntitySetupPageModule
					),
				canActivate: [AuthGuard]
			},
			{
				path: "pay-groups",
				data: { breadCrumb: "Pay Groups" },
				loadChildren: () => import("@modules/pay-groups/pay-groups.module").then(m => m.PayGroupsModule),
				canActivate: [AuthGuard]
			},
			{
				path: "service-definition/net",
				data: { breadCrumb: "Nets Service Definition" },
				loadChildren: () =>
					import("@modules/service-definition/service-definition.module").then(
						m => m.ServiceDefinitionModule
					),
				canActivate: [AuthGuard]
			},
			{
				path: "service-definition/tpp",
				data: { breadCrumb: "TPP Service Definition" },
				loadChildren: () =>
					import("@modules/service-definition/service-definition.module").then(
						m => m.ServiceDefinitionModule
					),
				canActivate: [AuthGuard]
			},
			{
				path: "calendar",
				data: { breadCrumb: "Calendar" },
				loadChildren: () =>
					import("@modules/payment-calendar/payment-calendar.module").then(m => m.PaymentCalendarModule),
				canActivate: [AuthGuard]
			},
			{
				path: "user-management",
				data: { breadCrumb: "User Management" },
				loadChildren: () =>
					import("@modules/user-management/user-management.module").then(m => m.UserManagementModule),
				canActivate: [AuthGuard]
			},
			{
				path: "reporting",
				data: { breadCrumb: "Reporting" },
				loadChildren: () => import("@modules/reporting/reporting.module").then(m => m.ReportingModule),
				canActivate: [AuthGuard]
			},
			{
				path: "service-provider-setup",
				data: { breadCrumb: "Service Provider" },
				loadChildren: () =>
					import("@modules/service-provider-setup/service-provider-setup.module").then(
						m => m.ServiceProviderSetupPageModule
					),
				canActivate: [AuthGuard]
			},
			{
				path: "penny-test",
				data: { breadCrumb: "Penny Test" },
				loadChildren: () => import("@modules/penny-test/penny-test.module").then(m => m.PennyTestModule),
				canActivate: [AuthGuard]
			},
			{
				path: "tpp-catalogue",
				data: { breadCrumb: "TPP Catalogue" },
				loadChildren: () =>
					import("@modules/tpp-catalogue/tpp-catalogue.module").then(m => m.TppCatalogueModule),
				canActivate: [AuthGuard]
			},
			{
				path: "bank-fields",
				data: { breadCrumb: "Bank Fields" },
				loadChildren: () =>
					import("@modules/bank-accounts/bank-accounts.module").then(m => m.BankAccountsModule),
				canActivate: [AuthGuard]
			},
			{
				path: "error-management",
				data: { breadCrumb: "Error Management" },
				loadChildren: () =>
					import("@modules/error-management/error-management.module").then(x => x.ErrorManagementModule),
				canActivate: [AuthGuard]
			},
			{
				path: "employee-data",
				data: { breadCrumb: "Employee Data" },
				loadChildren: () =>
					import("@modules/employee-data/employee-data.module").then(x => x.EmployeeDataModule),
				canActivate: [AuthGuard]
			},

			{
				path: "file-management",
				loadChildren: () =>
					import("@modules/file-management/file-management.module").then(x => x.FileManagementModule),
				canActivate: [AuthGuard]
			}
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class DashboardPageRoutingModule {}
