<div fxLayout="row">
	<div *ngFor="let badge of badges; let i = index">
		<!-- show up to 2 badges -->
		<div
			*ngIf="i <= 1"
			[ngClass]="badge.containerClass"
			fxLayoutAlign="center center"
			style="margin-left: 5px; margin-right: 5px"
		>
			<span [ngClass]="badge.textClass"> TPP{{ badge.groupNumber }} </span>
		</div>
	</div>
	<!-- More than 2 tool tips show the truncate box with count and tooltip description. -->
	<div
		*ngIf="badges.length > 2"
		[matTooltipPosition]="'above'"
		matTooltip="{{ toolTipText }}"
		style="
			border-radius: 5px;
			width: 40px;
			padding-top: 5px;
			border-style: solid;
			border-color: #adaec0;
			border-width: 1px;
		"
	>
		<div fxLayout="column" fxLayoutAlign="center center">+{{ badges.length - 2 }}</div>
	</div>
</div>
