import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PaginationComponent } from "./pagination.component";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { FormsModule } from "@angular/forms";
import { PipesModule } from "src/app/shared/pipes/pipes.module";
import { SharedModule } from "@shared/shared.module";
import { InputSelectModule } from "@shared/components/input-select/input-select.module";

@NgModule({
	declarations: [PaginationComponent],
	imports: [CommonModule, MatIconModule, MatButtonModule, FormsModule, PipesModule, SharedModule, InputSelectModule],
	exports: [PaginationComponent]
})
export class PaginationModule {}
