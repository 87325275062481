import { Component, OnDestroy, OnInit } from "@angular/core";
import { Customer } from "../../shared/models/customer.interface";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Router } from "@angular/router";
import { PermissionsService } from "../../shared/services/permissions/permissions.service";

@Component({
	selector: "app-customer-setup",
	templateUrl: "./customer-setup.page.html",
	styleUrls: ["./customer-setup.page.scss"]
})
export class CustomerSetupPage implements OnInit, OnDestroy {
	canCreateCustomers: boolean = false;
	private destroy$ = new Subject<void>();

	constructor(private router: Router, private permissions: PermissionsService) {}

	ngOnInit(): void {
		this.permissions
			.canCreateCustomers()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => (this.canCreateCustomers = res));
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	navigateToCreateCustomer(): void {
		this.router.navigate(["customers/create"]);
	}

	onCustomerSelected(event$: Customer) {
		this.router.navigate(["customers/edit"]);
	}
}
