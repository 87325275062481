<div class="information-container" id="pay-group-{{ payGroup.id }}">
	<!-- Title -->
	<div fxLayout="column" class="title-container">
		<div
			class="information-title-container"
			[matTooltip]="payGroup.legalEntity.data.externalId + '/' + payGroup.data.name + '/' + payGroup.externalId"
			[matTooltipPosition]="'above'"
			[matTooltipClass]="'payment-paygroup-list-item'"
		>
			<span class="information-title legal-entity-flag">
				<img src="assets/svg-country-flags/svg/{{ payGroup.legalEntity.data.country.toLowerCase() }}.svg" />
			</span>
			<span class="information-title legal-entity-id"> {{ payGroup.legalEntity.data.externalId }}</span>
			<span class="information-title">/</span>
			<span class="information-title paygroup-name">{{ payGroup.data.name }}</span>
			<span class="information-title">/</span>
			<span class="information-title paygroup-id">{{ payGroup.externalId }}</span>
		</div>

		<div class="detail-container">
			<!-- Dot -->
			<div
				class="dot"
				[ngClass]="{
					warning: payGroup.status === 'IMPLEMENTATION',
					success: payGroup.status === 'LIVE',
					danger: payGroup.status === 'INACTIVE'
				}"
			></div>
			<div class="text-status">{{ payGroup.status }}</div>
		</div>
	</div>

	<div class="status-item" [ngClass]="{ disabled: g2nErrorCount === 0 }">
		<div class="status-icon-info">
			<div *ngIf="g2nErrorCount > 0" class="error-circle"><span>+</span></div>
			<app-icon class="icon g2n" [size]="'22'" [name]="'document-copy'"></app-icon>
			<span *ngIf="g2nErrorCount > 0" class="counts">{{ g2nErrorCount }}</span>
		</div>
		<div class="status-text">
			<span class="icon-text">G2N Files</span>
		</div>
	</div>

	<div class="status-item" [ngClass]="{ disabled: transactionErrorCount === 0 }">
		<div class="status-icon-info">
			<div class="error-circle" *ngIf="transactionErrorCount > 0"><span>+</span></div>
			<app-icon class="icon" [size]="'22'" [name]="'money-change'"></app-icon>
			<span *ngIf="transactionErrorCount > 0" class="counts">{{ transactionErrorCount }}</span>
		</div>
		<div class="status-text">
			<span class="icon-text">Transactions</span>
		</div>
	</div>

	<div class="status-item" [ngClass]="{ disabled: payMethodsErrorCount === 0 }">
		<div class="status-icon-info">
			<div class="error-circle" *ngIf="payMethodsErrorCount > 0"><span>+</span></div>
			<app-icon class="icon ben" [size]="'22'" [name]="'2user'"></app-icon>
			<span *ngIf="payMethodsErrorCount > 0" class="counts">{{ payMethodsErrorCount }}</span>
		</div>
		<div class="status-text">
			<span class="icon-text">Beneficiaries</span>
		</div>
	</div>

	<div class="status-item" [ngClass]="{ disabled: employeesWithNoCardCount === 0 }">
		<div class="status-icon-info">
			<div *ngIf="employeesWithNoCardCount > 0" class="warning-circle"><span>!</span></div>
			<app-icon class="icon card" [size]="'22'" [name]="'card'"></app-icon>
			<span *ngIf="employeesWithNoCardCount > 0" class="counts">{{ employeesWithNoCardCount }}</span>
		</div>
		<div class="status-text">
			<span class="icon-text">Cards</span>
		</div>
	</div>

	<div class="status-item" [ngClass]="{ disabled: employeesWithNoAccountCount === 0 }">
		<div class="status-icon-info">
			<div class="warning-circle" *ngIf="employeesWithNoAccountCount > 0"><span>!</span></div>
			<app-icon class="icon" [size]="'22'" [name]="'bank-figma'"></app-icon>
			<span class="counts" *ngIf="employeesWithNoAccountCount > 0">{{ employeesWithNoAccountCount }}</span>
		</div>
		<div class="status-text">
			<span class="icon-text">Bank Accounts</span>
		</div>
	</div>
</div>
