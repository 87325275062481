import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { take, map, takeUntil } from "rxjs/operators";
import { Method, PaymentType, PayMethodDetail } from "@modules/employee-data/types/accounts";
import { PayElectiveBankAccountsService } from "@modules/employee-data/services/pay-elective-bank-accounts.service";
import { PayElectiveAccountRankingService } from "@modules/employee-data/services/pay-elective-account-ranking.service";

@Component({
	selector: "app-pay-elective-payment-methods-list",
	templateUrl: "./pay-elective-payment-methods-list.component.html",
	styleUrls: ["./pay-elective-payment-methods-list.component.scss"]
})
export class PayElectivePaymentMethodsListComponent implements OnInit, OnDestroy {
	@Input() paymentType!: string;
	@Input() employeeId!: string;
	@Input() payGroupId!: string;
	@Input() providerName!: string;
	@Input() supportedPayMethodTypes!: string[];

	_paymentMethodsNets: Observable<PayMethodDetail[]> = new Observable<PayMethodDetail[]>();
	_paymentMethodsTPP: Observable<PayMethodDetail[]> = new Observable<PayMethodDetail[]>();
	_paymentMethodsNoTypes: Observable<PayMethodDetail[]> = new Observable<PayMethodDetail[]>();

	@Input() set paymentMethodsNet(val: Observable<PayMethodDetail[]>) {
		this._paymentMethodsNets = val;
		this.setNetsData();
	}
	@Input() set paymentMethodsTPP(val: Observable<PayMethodDetail[]>) {
		this._paymentMethodsTPP = val;
		this.setOtherData();
	}
	@Input() set paymentMethodNoType(val: Observable<PayMethodDetail[]>) {
		this._paymentMethodsNoTypes = val;
		this.setOtherData();
	}

	get paymentMethodsNets(): Observable<PayMethodDetail[]> {
		return this._paymentMethodsNets;
	}

	get paymentMethodsTPP(): Observable<PayMethodDetail[]> {
		return this._paymentMethodsTPP;
	}

	get paymentMethodsNoTypes(): Observable<PayMethodDetail[]> {
		return this._paymentMethodsNoTypes;
	}

	private destroy$: Subject<void> = new Subject();

	net: PaymentType = PaymentType.NET;
	ewa: PaymentType = PaymentType.EWA;
	ttp: PaymentType = PaymentType.TPP;
	fallback: PaymentType = PaymentType.FALLBACK;

	@Output() toggleCreatingBankAccountEvent$ = new EventEmitter<any>();
	@Output() editBankAccountEvent$ = new EventEmitter<{ accountId: string; payMethodsLength: number }>();

	isDragDisabled: boolean = false;

	dataToShow: BehaviorSubject<PayMethodDetail[]> = new BehaviorSubject<PayMethodDetail[]>([]);
	get showData(): Observable<PayMethodDetail[]> {
		return this.dataToShow.asObservable();
	}

	rankUpdateArray: PayMethodDetail[] = [];

	constructor(
		private payElectiveBankAccountsService: PayElectiveBankAccountsService,
		private accountRankingService: PayElectiveAccountRankingService
	) {}

	ngOnInit(): void {
		this.setData();
	}

	setData() {
		if (this.paymentType === "TPPs") {
			this.isDragDisabled = true;
			this.setOtherData();
		} else if (this.paymentType === "Nets") {
			this.setNetsData();
		}
	}

	setNetsData() {
		this.paymentMethodsNets
			.pipe(
				take(1),
				map(res => res.sort((a, b) => (a.payMethod.ranking!.rank < b.payMethod.ranking!.rank ? -1 : 1)))
			)
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => {
				this.dataToShow.next(res);
				this.rankUpdateArray = res;
			});
	}

	setOtherData() {
		//Combine TTP data and NoType data to display under 'Other'
		this.paymentMethodsTPP.pipe(takeUntil(this.destroy$)).subscribe(ttpRes => {
			this.paymentMethodsNoTypes.pipe(takeUntil(this.destroy$)).subscribe(noRes => {
				if (noRes.length!) {
					for (let i = 0; i < noRes.length; i++) {
						ttpRes.push(noRes[i]);
					}
				}
				this.dataToShow.next(ttpRes);
			});
		});
	}

	onClickEditBankAccount(accountId: string, payMethodsLength: number): void {
		this.editBankAccountEvent$.emit({
			accountId: accountId,
			payMethodsLength: payMethodsLength
		});
	}

	onClickToggleCreatingBankAccount(): void {
		this.toggleCreatingBankAccountEvent$.emit("toggle");
	}

	drop(event: CdkDragDrop<string[]>) {
		moveItemInArray(this.rankUpdateArray, event.previousIndex, event.currentIndex);
		this.accountRankingService.updateBankAccountRank(
			this.employeeId,
			this.payGroupId,
			this.rankUpdateArray,
			this.rankUpdateArray.length
		);
	}

	checkProviderStatusesExistance(payMethod: Method): boolean {
		return payMethod.providersStatuses[this.providerName] ? true : false;
	}

	getPayMethodStatusName(payMethod: Method): string {
		return payMethod.status?.name;
	}

	getProviderStatusName(payMethod: Method): string {
		return payMethod.providersStatuses[this.providerName]?.name;
	}

	payMethodStatusClass(payMethod: Method): {} {
		const statuses = ["created", "active", "inactive", "processing"];

		return statuses.includes(payMethod.status?.name.toLowerCase())
			? { [payMethod.status?.name.toLowerCase()]: true }
			: {};
	}

	payMethodProviderStatusClass(payMethod: Method): {} {
		const statuses = ["created", "active", "inactive", "processing"];

		return statuses.includes(payMethod.providersStatuses[this.providerName]?.name.toLowerCase())
			? { [payMethod.providersStatuses[this.providerName]?.name.toLowerCase()]: true }
			: {};
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
