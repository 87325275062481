import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NoItemsLayoutComponent } from "./no-items-layout.component";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { SharedModule } from "@shared/shared.module";

@NgModule({
	declarations: [NoItemsLayoutComponent],
	imports: [CommonModule, MatIconModule, MatButtonModule, SharedModule],
	exports: [NoItemsLayoutComponent]
})
export class NoItemsLayoutModule {}
