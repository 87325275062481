import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MilestoneDetailsRowComponent } from "./milestone-details-row.component";
import { MatIconModule } from "@angular/material/icon";
import { SharedModule } from "@shared/shared.module";
import { FlexLayoutModule } from "ngx-flexible-layout";
import { PipesModule } from "@shared/pipes/pipes.module";

@NgModule({
	declarations: [MilestoneDetailsRowComponent],
	exports: [MilestoneDetailsRowComponent],
	imports: [CommonModule, MatIconModule, SharedModule, FlexLayoutModule, PipesModule]
})
export class MilestoneDetailsRowModule {}
