<div *ngIf="unsupportedTransactionStatus()" class="failure-message flex flex-row flex-align-center-start">
	<p>
		<strong>Unexpected Transaction Status:</strong> <br /><br />
		Please raise a ticket the status: {{ element.status!.name }} is not yet supported.
		<br />
		<br />
		This does not necessarily mean that the payout wasnt successful.
	</p>
</div>

<div *ngIf="transactionStatusIsPendingOrSuccess() || transactionStatusIsFailure()">
	<div class="borders-white-bottom bt-margin flex flex-column align-space-between-end">
		<p>{{ element.payoutAmount | number }}{{ element.currency }}</p>

		<div *ngIf="list.length > 0">
			<ng-container *ngFor="let amount of list">
				<p class="btTop">-{{ amount | number }}{{ element.currency }}</p>
			</ng-container>
		</div>
	</div>

	<!-- type: "PERCENTAGE" -->
	<div
		*ngIf="element.ranking.type === 'PERCENTAGE'"
		class="borders-white-bottom bt-margin flex flex-row flex-align-center-space-between"
	>
		<p class="space-rigth">{{ element.ranking.amount }}%</p>
		<p>{{ rest | number }}{{ element.currency }}</p>
	</div>

	<!-- type: "PERCENTAGE_REMAINING" -->
	<div
		*ngIf="element.ranking.type === 'PERCENTAGE_REMAINING'"
		class="borders-white-bottom bt-margin flex flex-row flex-align-center-space-between"
	>
		<p class="space-rigth">{{ element.ranking.amount }}% Remaining</p>
		<p>{{ rest | number }}{{ element.currency }}</p>
	</div>

	<!-- type: "AMOUNT" -->
	<div
		*ngIf="element.ranking.type === 'AMOUNT'"
		class="borders-white-bottom bt-margin flex flex-row flex-align-center-end"
	>
		<p>{{ rest | number }}{{ element.currency }}</p>
	</div>

	<!-- type: "ALL_REMAINING" Nothing-->

	<div class="flex flex-row flex-align-center-end">
		<p class="result-size">
			<b>{{ element.amount | number }}{{ element.currency }}</b>
		</p>
	</div>
</div>
