import { HttpErrorResponse } from "@angular/common/http";
import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { FormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatExpansionPanel } from "@angular/material/expansion";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { shareReplay, take, takeUntil } from "rxjs/operators";

import { BreadcrumbService } from "@shared/components/breadcrumbs/services/breadcrumb.service";
import { Store, select } from "@ngrx/store";
import { CountriesService } from "@shared/services/countries/countries.service";
import { UserRole } from "@shared/constants/roles";
import { formatDataForSelect } from "@shared/utils/format-select-data.util";
import { getCustomerEntityGroupState } from "@store/index";
import { AppState } from "@store/models/state.model";
import { DeleteDialogComponent } from "@shared/components/delete-dialog/delete-dialog.component";
import { ContactPersonService } from "@shared/services/contact-person/contact-person.service";
import { LegalEntityService } from "@shared/services/legal-entity/legal-entity.service";
import { ToastService } from "@shared/services/toast/toast.service";
import { ContactPerson } from "src/app/shared/models/contact-person.interface";
import { LegalEntity } from "src/app/shared/models/legal-entity.interface";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { stringMatchValidator } from "src/app/shared/validators/matching-string.validator";
import { PermissionsService } from "../../../../shared/services/permissions/permissions.service";
import { ApiService } from "../../../pay-groups/services/api.service";
import { REGIONS } from "../../_constants/regions";
import { ILegalEntityEditFormGroup } from "../../_models/legal-entity-api.interface";
import { CommonLegalEntityService } from "../../_services/common/common-legal-entity.service";
import { CopyLegalEntityService } from "../../_services/copy-legal-entities/copy-legal-entity.service";
import { UpdateLegalEntityService } from "../../_services/update-legal-entitites/update-legal-entity.service";
import { LegalEntityAmlHistoryDialogComponent } from "../legal-entity-aml-history-dialog/legal-entity-aml-history-dialog.component";

@Component({
	selector: "update-legal-entity",
	templateUrl: "./update-legal-entity.component.html",
	styleUrls: ["./update-legal-entity.component.scss"],
	providers: [UpdateLegalEntityService, CopyLegalEntityService]
})
export class UpdateLegalEntityComponent implements OnInit, OnDestroy {
	@Output() onCopy: EventEmitter<LegalEntity> = new EventEmitter<LegalEntity>();
	@Output() removeCopy: EventEmitter<LegalEntity> = new EventEmitter<LegalEntity>();
	@Output() expansionPanelState: EventEmitter<boolean> = new EventEmitter<boolean>();

	@ViewChild("legalEntityEditionPanel") legalEntityEditionPanel!: MatExpansionPanel;

	editContactClicked$: Subject<void> = new Subject<void>();

	isCopied: boolean = false;
	legalEntity: LegalEntity = {} as LegalEntity;
	legalEntityId: string = "";

	legalEntityForm!: ILegalEntityEditFormGroup | undefined;
	creationContactData: ContactPerson[] = [];
	existingContacts!: ContactPerson[];
	contactFormisValid: boolean = true;
	isCreationContactValid: boolean = false;
	selectOptionsCountries: SelectOption[] = [];
	regions: SelectOption[] = REGIONS;
	showContactExpansionPanel = true;
	isChanged: boolean = false;
	selectOptionsCustomer: SelectOption[] = [];
	customerExternalId!: string;

	canEditLegalEntitySetup: boolean = false;
	canEditContactPersons: boolean = false;
	cantEditAmlStatus: boolean = true;
	selectedCountry: string = "";
	isDisabledForViewOnly = false;

	private destroy$: Subject<void> = new Subject<void>();

	constructor(
		private formBuilder: UntypedFormBuilder,
		private updateLegalEntityService: UpdateLegalEntityService,
		public commonLegalEntityService: CommonLegalEntityService,
		private contactPersonService: ContactPersonService,
		private payGroupAPIService: ApiService,
		private toastService: ToastService,
		private dialog: MatDialog,
		private legalEntityService: LegalEntityService,
		private router: Router,
		private permissions: PermissionsService,
		private store: Store<AppState>,
		private breadCrumbService: BreadcrumbService,
		private countriesService: CountriesService
	) {}

	ngOnInit(): void {
		this.fetchCountryOptions();
		this.initSetup();
	}

	fetchCountryOptions(): void {
		this.countriesService
			.getCountries()
			.pipe(take(1))
			.subscribe(countries => {
				this.commonLegalEntityService.countries = [...countries];
				countries.filter(country => {
					this.selectOptionsCountries.push(
						formatDataForSelect({
							text: country.country.name,
							value: country.country.code,
							imagePath: `assets/svg-country-flags/svg/${country?.country.code.toLowerCase()}.svg`
						})
					);
				});
			});
	}

	initSetup(): void {
		this.store.pipe(takeUntil(this.destroy$), select(getCustomerEntityGroupState)).subscribe(state => {
			if (state && state.legalEntityId && state.legalEntityId !== this.legalEntityId) {
				this.legalEntityId = state.legalEntityId;
				this.initializeComponentFromStateChange(state.legalEntityId);
				this.setupPermissionListener();
			}
		});
	}
	setupPermissionListener(): void {
		this.permissions
			.canEditLegalEntitySetup()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => (this.canEditLegalEntitySetup = res));

		this.permissions
			.canEditContactPersons()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => (this.canEditContactPersons = res));

		this.permissions
			.disableAccessToEditableFormField([UserRole.TS_VO, UserRole.CLIENT])
			.subscribe(isDisabledForViewOnly => (this.isDisabledForViewOnly = isDisabledForViewOnly));
	}

	initializeComponentFromStateChange(legalEntityId: string): void {
		this.legalEntityService
			.getLegalEntityById(legalEntityId)
			.pipe(shareReplay())
			.subscribe({
				next: res => {
					this.legalEntityForm = undefined;
					this.legalEntity = res;
					this.initializeComponent(res);
				}
			});
	}

	initializeComponent(legalEntity: LegalEntity): void {
		this.initializeAmlStatusEditability();
		this.getContactPersons(legalEntity);
	}

	private initForm(legalEntity: LegalEntity) {
		const customerSelected = this.commonLegalEntityService.selectedCustomerForLegalEntity(legalEntity);
		this.customerExternalId = customerSelected ? customerSelected?.externalId : "";
		//Set Selected country for select autocomplete
		const selectCountry: SelectOption[] = this.commonLegalEntityService.selectedCountryForLegalEntity(legalEntity);

		this.legalEntityForm = this.formBuilder.group({
			externalId: [
				{ value: legalEntity.data?.externalId, disabled: this.isDisabledForViewOnly },
				stringMatchValidator(this.customerExternalId)
			],
			id: [{ value: this.isCopied ? null : legalEntity.id, disabled: true }, Validators.required],
			name: [{ value: legalEntity.data?.name, disabled: this.isDisabledForViewOnly }, Validators.required],
			address: [{ value: legalEntity.data?.address, disabled: this.isDisabledForViewOnly }],
			country: [{ value: "", disabled: this.isDisabledForViewOnly }, Validators.required],
			timeZone: [{ value: "", disabled: this.isDisabledForViewOnly }],
			region: [{ value: legalEntity.data?.region, disabled: this.isDisabledForViewOnly }, Validators.required],
			contactPersons: [{ value: legalEntity.contactPersons, disabled: this.isDisabledForViewOnly }],
			customerId: [{ value: legalEntity.customerId, disabled: this.isDisabledForViewOnly }],
			version: [this.isCopied ? null : legalEntity.version],
			status: [legalEntity.status],
			timeZonesOptions: [{ value: "", disabled: this.isDisabledForViewOnly }]
		}) as ILegalEntityEditFormGroup;

		this.selectedCountry = selectCountry[0]?.text;
		this.legalEntityForm.get("country")?.patchValue(selectCountry[0]?.text);

		// Set initial timezone according to selected country
		this.updateLegalEntityService.setInitialTimezone(
			this.legalEntityForm,
			selectCountry[0].value,
			this.commonLegalEntityService.countries,
			legalEntity.data?.timeZone.toString()
		);

		//Set Legal entities edit form events and set timezones depending on the country
		this.updateLegalEntityService.setupLegalEntityFormEventsUpdate(
			this.legalEntityForm,
			this.commonLegalEntityService.countries
		);

		this.legalEntityForm
			.get("region")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe(changes => {
				if (changes) this.isChanged = true;
			});

		this.legalEntityForm
			.get("country")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe(changes => {
				if (changes) this.isChanged = true;
			});

		this.legalEntityForm
			.get("timeZone")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe(changes => {
				if (changes) this.isChanged = true;
			});

		if (!this.canEditLegalEntitySetup) {
			this.legalEntityForm.disable();
			this.legalEntityForm.get("timeZone")?.disable();
		}
	}

	creationContactFormData(contactPersons: ContactPerson[]): void {
		this.creationContactData = contactPersons;
		this.isCreationContactValid = !!contactPersons;
	}

	onEditContactClicked() {
		this.editContactClicked$.next();
	}

	editContactFormData(event: { data: ContactPerson[]; valid: boolean }) {
		this.legalEntityForm?.markAsDirty();
		this.contactFormisValid = event.valid;
		if (event.data) {
			this.legalEntityForm?.get("contactPersons")?.setValue(event.data);
		} else {
			this.legalEntityForm?.get("contactPersons")?.setValue([]);
		}
	}

	updateLegalEntity() {
		if (this.legalEntityForm?.valid) {
			this.updateLegalEntityService
				.updateLegalEntity(this.legalEntityForm)
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: (updatedLegalEntity: LegalEntity) => {
						this.legalEntityForm!.controls.version.patchValue(updatedLegalEntity.version);
						this.toastService.showSuccess("Legal entity has been updated");
						this.router.navigate(["legal-entities/select"]);
					},
					error: (error: HttpErrorResponse) => this.toastService.showError(error.error.message)
				});
		}
	}

	cancelEditLegalEntity() {
		this.breadCrumbService.updateCustomerEntityGroupState();
		this.router.navigate(["legal-entities/select"]);
	}

	getContactPersons(legalEntity: LegalEntity) {
		this.expansionPanelState.emit(true);
		this.contactPersonService
			.getContactPersons(legalEntity.id)
			.pipe(takeUntil(this.destroy$))
			.subscribe(result => {
				if (result.length > 0) {
					this.showContactExpansionPanel = true;
				} else {
					this.showContactExpansionPanel = false;
				}
				this.existingContacts = result;
				legalEntity.contactPersons = result;
				this.initForm(legalEntity);
			});
	}

	onClose() {
		this.expansionPanelState.emit(false);
	}

	removeCopyForm() {
		this.removeCopy.emit(this.legalEntity);
	}

	contactFormState(contactFormState: FormGroup) {
		if (contactFormState.status === "INVALID") {
			this.legalEntityForm?.setErrors({ invalidContactPerson: true });
		} else {
			this.legalEntityForm?.setErrors(null);
		}
	}

	goHome(): void {
		this.router.navigate(["/legal-entities/setup"]);
	}

	deleteCustomer(legalEntity: LegalEntity) {
		const statuses: string = "LIVE, IMPLEMENTATION";
		this.payGroupAPIService
			.getPayGroupsByLegalEntityIdPagination(legalEntity.id, statuses, 1, 0)
			.pipe(takeUntil(this.destroy$))
			.subscribe(result => {
				if (result.totalCount == 0) {
					const dialogRef = this.dialog.open(DeleteDialogComponent, {
						height: "441px",
						width: "666px",
						data: {
							text: "Are you sure you want to delete this legal entity?"
						},
						panelClass: "delete-dialog-container"
					});
					dialogRef
						.afterClosed()
						.pipe(takeUntil(this.destroy$))
						.subscribe(result => {
							if (result) {
								this.legalEntityService
									.deleteLegalEntity(legalEntity.id)
									.pipe(takeUntil(this.destroy$))
									.subscribe(() => {
										this.removeCopyForm();
									});
							} else {
								() => this.toastService.showError("Error deleting legal entity");
							}
						});
				} else {
					this.toastService.showError("Unable to Delete: Customer currently has associated legal entities");
				}
			});
	}

	contactPersonChanged() {
		this.isChanged = true;
	}

	onUpdateAmlStatus(status: string) {
		this.legalEntityForm?.markAsDirty();
		this.legalEntityForm?.get("status")?.setValue(status);
	}

	showAMLHistory() {
		const dialogRef = this.dialog.open(LegalEntityAmlHistoryDialogComponent, {
			width: "995px",
			height: "570px",
			panelClass: "dialog-container",
			data: this.legalEntity.id
		});

		dialogRef.afterClosed().subscribe((result: boolean) => {
			if (result) {
				//this.location.back();
			}
		});
	}

	onInputChange(isChanged: boolean) {
		this.isChanged = isChanged;
	}

	private initializeAmlStatusEditability() {
		this.permissions
			.canEditCustomerCompliance()
			.pipe(takeUntil(this.destroy$))
			.subscribe(canEditAmlStatus => {
				this.cantEditAmlStatus = !canEditAmlStatus;
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
