<div class="subservice-list-page-container">
	<div class="column-labels">
		<span fxFlex="5"> <div class="placeholder"></div> </span>
		<span class="label" fxFlex="8">SUBSERVICE</span>
		<span class="label" fxFlex="12">PAYMENT BY</span>
		<span class="label" fxFlex="13">REFERENCE TYPE</span>
		<span class="label" fxFlex="12">REFERENCE</span>
		<span class="label" fxFlex="10">AMOUNT</span>
		<span class="label" fxFlex="15">UPLOADED BY</span>
	</div>

	<app-tpp-subservice-list-item
		*ngFor="let subService of subServices; let i = index; last as isLast"
		[_subService]="subService"
		[hideMatDivider]="isLast"
		(emitOpenPopUp)="emitOpenPopUp.emit($event)"
	></app-tpp-subservice-list-item>
</div>
