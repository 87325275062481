<div class="country-content flex flex-column flex-align-stretch-space-between">
	<div [formGroup]="searchForm" class="search-section search-gap">
		<h3 class="title">Created Countries</h3>
		<mat-form-field appearance="outline">
			<app-icon
				id="search-icon"
				matPrefix
				[name]="'search-normal-1'"
				[size]="'20px'"
				[color]="'#231F20'"
			></app-icon>
			<input matInput placeholder="Search by country" formControlName="search" />
		</mat-form-field>
	</div>
	<div *ngIf="availableCountries?.length" class="o-grid-4">
		<div *ngFor="let country of filteredCountries">
			<app-available-country-card
				[providerName]="provider.name"
				[providerId]="provider.id"
				[countryName]="country.country.name"
				[countryCode]="country.country.code"
				[availableCountries]="availableCountries"
			></app-available-country-card>
		</div>
	</div>
	<div *ngIf="!availableCountries?.length" class="o-grid-4">
		<div>
			<app-available-country-card [noCountry]="true"></app-available-country-card>
		</div>
	</div>
</div>
