import { Injectable } from "@angular/core";
import { SelectOption } from "../model/SelectOption";

@Injectable({
	providedIn: "root"
})
export class AutoCompleteService {
	constructor() {}

	writeValueById(
		filteredOptions: SelectOption[],
		defaultValue: string
	): {
		selectOptions: SelectOption[] | SelectOption | undefined;
		filteredOptions: SelectOption[];
	} {
		if (filteredOptions.length > 0) {
			if (Array.isArray(defaultValue)) {
				let selectOptions: SelectOption[] | undefined;

				defaultValue.map((dv: string) => {
					let selectOption: SelectOption | undefined = filteredOptions.find(result => {
						if (result.value === dv) {
							result.selected = true;
							return true;
						} else {
							return false;
						}
					});

					if (selectOption?.value) {
						selectOptions = !selectOptions ? [] : selectOptions;
						selectOptions.push(selectOption);
					}
				});

				return {
					selectOptions: selectOptions,
					filteredOptions: filteredOptions
				};
			} else {
				let selectOption: SelectOption | undefined = filteredOptions.find(
					result => result.value === defaultValue
				);
				return {
					selectOptions: selectOption,
					filteredOptions: filteredOptions
				};
			}
		} else {
			return {
				selectOptions: undefined,
				filteredOptions: filteredOptions
			};
		}
	}
}
