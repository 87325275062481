import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ToastService } from "@shared/services/toast/toast.service";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { FullAmlStatuses } from "./model/FullAmlStatuses";

/**
 * An AML Form that has a default status of `AML_WAITING` and disabled by default.
 */
@Component({
	selector: "app-full-aml-form",
	templateUrl: "./full-aml-form.component.html",
	styleUrls: ["./full-aml-form.component.scss"]
})
export class FullAmlFormComponent implements OnInit, OnDestroy {
	@Input() selectedValue?: string = "AML_WAITING";
	@Input() disabled: boolean = true;
	@Output() onFormChange: EventEmitter<string> = new EventEmitter<string>();

	statusOptions: SelectOption[] = [];
	form!: FormGroup;

	private destroy$: Subject<void> = new Subject<void>();

	constructor(private formBuilder: FormBuilder, private toast: ToastService) {}

	ngOnInit(): void {
		this.onInvalidAmlStatusShowToast();
		this.statusOptions = FullAmlStatuses.toSelectionOptions();
		this.initForm();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	private onInvalidAmlStatusShowToast() {
		if (this.selectedValue != undefined) {
			if (this.invalidAmlStatus(this.selectedValue))
				this.toast.showError(
					`Oops! Something went wrong. I'm not sure what to do with this status: ${this.selectedValue}`
				);
		}
	}

	private invalidAmlStatus(status: string): boolean {
		return !FullAmlStatuses.isValid(status);
	}

	private initForm() {
		this.form = this.formBuilder.group({
			status: [{ value: this.selectedValue, disabled: this.disabled }]
		});

		this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(selectOption => {
			const backEndAmlStatus = selectOption.status;
			if (backEndAmlStatus) this.onFormChange.emit(backEndAmlStatus);
		});
	}
}
