import { Injectable } from "@angular/core";

@Injectable({
	providedIn: "root"
})
export class formatDateService {
	constructor() {}

	formatDate(date: Date): string {
		const year: string = date.toLocaleDateString("US", { year: "numeric" });
		const month: string = date.toLocaleDateString("US", { month: "2-digit" });
		const day: string = date.toLocaleDateString("US", { day: "2-digit" });

		return year + "-" + month + "-" + day;
	}
}
