import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { CurrencyService } from "@shared/services/currency/currency.service";
import { ServiceProviderService } from "@shared/services/service-provider/service-provider.service";
import {
	CreateDefinitionData,
	DefinitionCreate,
	Definitions,
	DefinitionsFormData,
	PaymentTypeCost,
	PaymentTypeDefinition,
	ProviderCountry,
	UpdateDefinitionData
} from "src/app/shared/models/service-definition.interface";
import { ServiceProviderCountrySetup } from "src/app/shared/models/service-provider-country-types";
import { CurrencyCode } from "src/app/shared/models/service-provider.type";

@Injectable({
	providedIn: "root"
})
export class ServiceDefinitionService {
	definitionProviders: ProviderCountry[] = [];

	constructor(private serviceProviderService: ServiceProviderService, private currencyService: CurrencyService) {}

	createPaymentTypeDefinitions(formData: DefinitionsFormData) {
		let paymentTypes: string[] = [];
		return formData.paymentTypeDefinitions.flatMap((definition: DefinitionCreate) => {
			const typeExists: boolean = !!paymentTypes.find(type => type === definition.paymentType);

			if (!typeExists) {
				paymentTypes.push(definition.paymentType);
			}

			return !typeExists
				? {
						paymentType: definition.paymentType,
						definitions: formData.paymentTypeDefinitions.flatMap((def: DefinitionCreate) => {
							return definition.paymentType === def.paymentType
								? {
										route: def.route,
										providerCountryId: def.providerCountry,
										payoutAccount: def.payoutAccount,
										default: def.default
								  }
								: [];
						}),
						settings: [{ key: "key", value: "value" }]
				  }
				: [];
		});
	}

	createDefinitionDto(
		legalEntityId: string,
		paymentTypeDefinitions: PaymentTypeDefinition[],
		paymentTypeCosts: PaymentTypeCost[]
	): CreateDefinitionData {
		return {
			legalEntityId,
			paymentTypeDefinitions,
			paymentTypeCosts
		} as CreateDefinitionData;
	}

	updateDefinitionDto(
		definitionId: string,
		version: number,
		legalEntityId: string,
		paymentTypeDefinitions: PaymentTypeDefinition[],
		paymentTypeCosts: PaymentTypeCost[]
	): UpdateDefinitionData {
		return {
			id: definitionId,
			version,
			legalEntityId,
			paymentTypeDefinitions,
			paymentTypeCosts
		} as UpdateDefinitionData;
	}

	loadDefinitions(legalEntityId: string): Observable<Definitions> {
		return this.serviceProviderService.getDefinitionsByEntityId(legalEntityId);
	}

	getProviderCurrencies(providerCountryId: string): Observable<CurrencyCode[]> {
		return this.serviceProviderService.getProviderByProviderCountryId(providerCountryId).pipe(
			take(1),
			map((provider: ServiceProviderCountrySetup) => {
				return provider.paymentCurrencies.sort((a, b) => (a > b ? 1 : -1));
			})
		);
	}

	getAllCurrencies(): Promise<any> {
		return new Promise(resolve => {
			this.currencyService
				.getAllCurrencies()
				.pipe(take(1))
				.subscribe((currencies: any[]) => {
					resolve(currencies.map(currency => currency.code));
				});
		});
	}
}
