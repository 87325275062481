import { Action } from "@ngrx/store";
import { CustomerEntityGroupSelect } from "../models/customerEnitityGroupSelection.model";

export enum CustomerEntityGroupActionType {
	SET_SELECTED = "[CustomerEntityGroup] Set Selected"
}
export class UpdateCustomerEntityGroupAction implements Action {
	readonly type = CustomerEntityGroupActionType.SET_SELECTED;

	//add an optional payload
	constructor(public payload: CustomerEntityGroupSelect) {}
}
export type CustomerEntityGroupSelectAction = UpdateCustomerEntityGroupAction;
