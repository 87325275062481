import { Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { isEqual } from "lodash";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { ModalLauncherService } from "@shared/components/wpay-ui/modal/modal-launcher.service";
import { ActionConfirmModalData } from "@shared/components/wpay-ui/modal/templates/action-confirm-dialog/action-confirm-dialog.component";
import { ToastService } from "@shared/services/toast/toast.service";
import { TppServicesService } from "@shared/services/tpp-services/tpp-services.service";
import { PayGroup } from "src/app/shared/models/pay-groups";
import {
	BeneficiaryAccountAssignment,
	InternalStatus,
	TppServicesByByPayGroupViewModel,
	TtpService,
	TtpServiceByServiceType,
	isBeneficiaryValid,
	isBenficiaryValidatable
} from "src/app/shared/models/service-definition/TppServiceDefinitionViewModel";
import { TppService } from "src/app/shared/models/tpp-service.interface";
import { UpdateSelectedTPPService } from "src/app/store/actions/tppServiceSelect.action";
import { AppState } from "src/app/store/models/state.model";
import { TtpServiceSelectionService } from "../../../_services/tpp-services/ttp-service-selection.service";

@Component({
	selector: "app-ttp-service-list",
	templateUrl: "./ttp-service-list.component.html",
	styleUrls: ["./ttp-service-list.component.scss"]
})
export class TtpServiceListComponent implements OnDestroy {
	@Input() services!: TppServicesByByPayGroupViewModel;
	@Input() payGroup!: PayGroup;

	isExpanded: boolean = false;

	destroy$: Subject<void> = new Subject<void>();

	@Output() reloadData: EventEmitter<boolean> = new EventEmitter<boolean>(false);

	constructor(
		private modalLauncherService: ModalLauncherService,
		private tppServicesService: TppServicesService,
		private store: Store<AppState>,
		private router: Router,
		private toast: ToastService,
		private tppServiceSelectionService: TtpServiceSelectionService
	) {}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	toggleServiceExpansionPanel(service: TtpService) {
		if (service.subServiceSummary.totalPartiallyConfiguredSubServices > 0) {
			service.isExpanded = !service.isExpanded;
			this.setOveflowProperties();
		}
	}
	setOveflowProperties(): void {
		const failedStatuses = document.querySelectorAll(".status.failed-status") as NodeListOf<HTMLElement>;

		failedStatuses.forEach(failedStatus => {
			failedStatus.addEventListener("mouseover", () => {
				const expansionPanel = failedStatus.closest(".expansion-panel");
				if (expansionPanel) {
					(expansionPanel as HTMLElement).style.overflow = "visible";
				}
				const allExpansionPanels = document.querySelectorAll(".expansion-panel");
				if (allExpansionPanels && expansionPanel) {
					allExpansionPanels.forEach(panel => {
						if (!isEqual(panel, expansionPanel)) (panel as HTMLElement).style.zIndex = "-1";
					});
				}
			});

			failedStatus.addEventListener("mouseout", () => {
				const expansionPanel = failedStatus.closest(".expansion-panel");
				if (expansionPanel) {
					(expansionPanel as HTMLElement).style.overflow = "hidden"; // Reset to the default value
				}

				const allExpansionPanels = document.querySelectorAll(".expansion-panel");
				if (allExpansionPanels && expansionPanel) {
					allExpansionPanels.forEach(panel => {
						if (!isEqual(panel, expansionPanel)) (panel as HTMLElement).style.zIndex = "1";
					});
				}
			});
		});
	}

	showDescriptionModal(serviceType: string, service: TtpService, event: any) {
		this.modalLauncherService.showDescriptionModal(serviceType + " DESCRIPTION", service.description);
		// prevent mat-expansion-panel from capturing the click event end opening the panel
		event.stopPropagation();
	}

	showElectiveHistory(beneficiary: BeneficiaryAccountAssignment): void {
		this.tppServiceSelectionService.showBeneficiaryHistoryModal(this.payGroup.id, beneficiary);
	}

	selectTppService(tppServiceId: string, serviceType: string, event: any) {
		// prevent mat-expansion-panel from capturing the click event end opening the panel
		event.stopPropagation();
		if (this.doesntHaveSubServices(tppServiceId)) {
			this.toast.showInfo(`No Sub Services Associated with Service`);
			return;
		}
		this.navigateToServiceConfiguration(tppServiceId, serviceType);
	}

	editAdHocService(tppServiceId: string, event: any) {
		// prevent mat-expansion-panel from capturing the click event end opening the panel
		this.tppServicesService
			.findTppServiceByServiceId(tppServiceId)
			.pipe(takeUntil(this.destroy$))
			.subscribe(tppService => {
				this.tppServicesService.setSelectedEditAdHocService(tppService);
				this.router.navigate(["/service-definition/tpp/adhoc-service"]);
			});
		event.stopPropagation();
	}

	isEmptyObject(obj: any) {
		return obj && Object.keys(obj).length === 0;
	}

	validateBeneficiary(linkId: string) {
		this.tppServiceSelectionService
			.validateBeneficiary(linkId)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: () => {
					this.toast.showInfo(`Successfully Sent for validation.`);
					this.reloadData.emit(true);
				},
				error: clientError => {
					this.toast.showError(`Error: ${clientError.message}`);
				}
			});
	}

	validateBeneficiaries() {
		const DIALOG_DATA = {
			description: `This action will validate all TPP beneficiaries for this paygroup.`,
			confirmText: `Validate`,
			cancelText: `Cancel`
		} as ActionConfirmModalData;
		const DIALOG_REF = this.modalLauncherService.showActionConfirmModal(DIALOG_DATA);

		DIALOG_REF.afterClosed().subscribe(result => {
			if (result)
				this.tppServiceSelectionService
					.validateBeneficiaries(this.services.servicesByServiceType)
					.pipe(takeUntil(this.destroy$))
					.subscribe({
						next: () => {
							this.toast.showInfo(`Successfully Sent for validation.`);
							this.reloadData.emit(true);
						},
						error: clientError => {
							this.toast.showError(`Error: ${clientError.message}`);
						}
					});
		});
	}

	isExpandable(service: TtpService): boolean {
		if (service.subServiceSummary.totalSubServiceBeneficiaries == 0) return false;

		// Expandable if one or more sub services are partially configured
		if (service.subServiceSummary.totalPartiallyConfiguredSubServices > 0) return true;

		return false;
	}

	isBeneficiaryValid(status: InternalStatus): boolean {
		return isBeneficiaryValid(status);
	}

	isBeneficiaryInvalid(status: InternalStatus): boolean {
		return !this.isBeneficiaryValid(status);
	}

	canValidate(status: InternalStatus): boolean {
		return isBenficiaryValidatable(status);
	}

	disableValidateAllBeneficiarys(): boolean {
		return !this.canValidateAllBeneficiaries();
	}

	isHiddenStatus(status: string) {
		// The business doesn't want to acknowledge these scenarios exist for now
		return status == "ASSIGNED_TO_ANOTHER_PROVIDER" || status == "NO_PROVIDER_ASSIGNMENT";
	}

	isArray(value: string | string[]): boolean {
		return Array.isArray(value);
	}

	getArrayValue(value: string | string[]): string[] {
		if (this.isArray(value)) {
			return Array.isArray(value) ? value : [value];
		}
		return [];
	}

	private doesntHaveSubServices(ttpServiceId: string): boolean {
		return !this.hasSubServices(ttpServiceId);
	}

	private hasSubServices(ttpServiceId: string): boolean {
		var hasSubServices = false;
		this.services.servicesByServiceType.forEach((service: TtpServiceByServiceType) => {
			service.services.forEach((service: TtpService) => {
				if (service.id === ttpServiceId) {
					hasSubServices = true;
				}
			});
		});
		return hasSubServices;
	}

	private navigateToServiceConfiguration(tppServiceId: string, serviceType: string) {
		this.tppServicesService
			.findTppServiceByServiceId(tppServiceId)
			.pipe(takeUntil(this.destroy$))
			.subscribe((service: TppService) => {
				this.store.dispatch(
					new UpdateSelectedTPPService({
						selectedTPPService: {
							countryCode: "",
							tppService: service,
							selectedServices: [serviceType],
							selectedPayGroup: this.payGroup
						}
					})
				);

				this.router.navigate(["/service-definition/tpp/service-configuration"]);
			});
	}

	private canValidateAllBeneficiaries(): boolean {
		if (this.services.beneficiaryCounts.totalBeneficiaries == 0) return false;
		if (this.services.beneficiaryCounts.totalBeneficiariesWhichCanBeValidated > 0) return true;

		return false;
	}
}
