<table class="customer table" mat-table [dataSource]="dataSourceCustomers" multiTemplateDataRows matSort>
	<!--First Layer-->
	<ng-container class="customer-container">
		<ng-container matColumnDef="column-one">
			<td mat-cell class="data-text first-collumn customerName" *matCellDef="let element">
				<span
					class="text"
					[matTooltip]="element.customerDetails.name"
					[matTooltipPosition]="'above'"
					[matTooltipClass]="'tooltip-customers'"
					attr.data-test="{{ element.customerDetails.name }}"
					>{{ element.customerDetails.name }}</span
				>
			</td>
		</ng-container>

		<ng-container matColumnDef="column-two">
			<td class="data-text second-collumn legalEntityCount" mat-cell *matCellDef="let element">
				<span
					class="text"
					*ngIf="
						element.customerDetails.legalEntityCount > 1 || element.customerDetails.legalEntityCount === 0
					"
					>{{ element.customerDetails.legalEntityCount }} Legal Entities</span
				>
				<span class="text" *ngIf="element.customerDetails.legalEntityCount === 1"
					>{{ element.customerDetails.legalEntityCount }} Legal Entity</span
				>
			</td>
		</ng-container>

		<ng-container matColumnDef="column-three">
			<td class="data-text third-collumn paygroupCount" mat-cell *matCellDef="let element">
				<span
					class="text"
					*ngIf="element.customerDetails.payGroupCount > 1 || element.customerDetails.payGroupCount === 0"
					>{{ element.customerDetails.payGroupCount }} Paygroups</span
				>
				<span class="text" *ngIf="element.customerDetails.payGroupCount === 1"
					>{{ element.customerDetails.payGroupCount }} Paygroup</span
				>
			</td>
		</ng-container>

		<ng-container matColumnDef="column-four">
			<td mat-cell *matCellDef="let element" class="button-container fourth-collumn">
				<button
					mat-icon-button
					class="drop-down-arrow-button"
					aria-label="expand row"
					[ngClass]="{ hidden: element.customerDetails.legalEntityCount === 0 }"
				>
					<app-icon
						class="drop-down-arrow"
						[ngClass]="{ down: isCustomerExpanded(element) }"
						[name]="'arrow-down'"
						[size]="'18px'"
						[lineWidth]="'2.5px'"
					></app-icon>
				</button>
			</td>
		</ng-container>
	</ng-container>

	<!--Second Layer-->
	<ng-container class="legal-entity-container" matColumnDef="legalEntity-row">
		<td mat-cell *matCellDef="let element" class="legalEntity-row" [attr.colspan]="columns.length">
			<div
				class="legal-entity-detail"
				*ngIf="element.customerDetails.legalEntityCount"
				[@detailExpand]="isCustomerExpanded(element) ? 'expanded' : 'collapsed'"
			>
				<table
					class="legalEnitity table"
					mat-table
					[dataSource]="element.customerDetails.legalEntities"
					multiTemplateDataRows
					matSort
				>
					<ng-container matColumnDef="column-one">
						<td class="data-text first-collumn" mat-cell *matCellDef="let element">
							<span class="text indent"> {{ element.name }}</span>
						</td>
					</ng-container>

					<ng-container matColumnDef="column-two">
						<td class="data-text legalEntityName second-collumn" mat-cell *matCellDef="let element">
							<span
								class="text legal-entity-name"
								[matTooltip]="element.externalId + ' - ' + element.name"
								[matTooltipPosition]="'above'"
								[matTooltipClass]="'tooltip-customers'"
								attr.data-test="{{ customerNameLocator }}-{{ element.externalId }}"
								><img
									class="flag"
									src="assets/svg-country-flags/svg/{{ element.country?.toLowerCase() }}.svg"
								/>{{ element.externalId }} {{ element.name }}</span
							>
						</td>
					</ng-container>

					<ng-container matColumnDef="column-three">
						<td class="data-text paygroupsCount third-collumn" mat-cell *matCellDef="let element">
							<span class="text" *ngIf="element.paygroupCount > 1 || element.paygroupCount === 0"
								>{{ element.paygroupCount }} Paygroups</span
							>
							<span class="text" *ngIf="element.paygroupCount === 1"
								>{{ element.paygroupCount }} Paygroup</span
							>
						</td>
					</ng-container>

					<ng-container matColumnDef="column-four">
						<td mat-cell *matCellDef="let element" class="button-container fourth-collumn">
							<button
								mat-icon-button
								class="drop-down-arrow-button"
								aria-label="expand row"
								[ngClass]="{ hidden: element.paygroupCount === 0 }"
							>
								<app-icon
									class="drop-down-arrow"
									[ngClass]="{ down: isLegalEntityExpanded(element) }"
									[name]="'arrow-down'"
									[size]="'18px'"
									[lineWidth]="'2.5px'"
								></app-icon>
							</button>
						</td>
					</ng-container>

					<!--Third Layer-->

					<ng-container class="paygroup-container" matColumnDef="paygroup-row">
						<td class="paygroup-row" mat-cell *matCellDef="let element" [attr.colspan]="columns.length">
							<div
								class="paygroup-detail"
								*ngIf="element.paygroupCount"
								[@detailExpand]="isLegalEntityExpanded(element) ? 'expanded' : 'collapsed'"
							>
								<table
									class="payGroup table"
									mat-table
									[dataSource]="selectedLegalEntityElement.payGroups!"
									multiTemplateDataRows
									matSort
								>
									<ng-container matColumnDef="column-one">
										<td class="data-text first-collumn" mat-cell *matCellDef="let element">
											<span class="text indent">{{ element.name }}</span>
										</td>
									</ng-container>

									<ng-container matColumnDef="column-two">
										<td
											[attr.colspan]="3"
											class="data-text paygroup-name second-collumn"
											mat-cell
											*matCellDef="let element"
										>
											<span
												[matTooltip]="element.externalId + ' - ' + element.name"
												[matTooltipPosition]="'above'"
												[matTooltipClass]="'tooltip-customers'"
												class="text paygroupName"
												attr.data-test="{{ customerNameLocator }}-{{ element.externalId }}"
												>{{ element.externalId }} {{ element.name }}</span
											>
										</td>
									</ng-container>
									<ng-container matColumnDef="column-three">
										<td class="data-text third-collumn" mat-cell *matCellDef="let element">
											<span
												class="view-dashboard-button"
												[ngClass]="{ show: element.externalId === hoveredPaygroupId }"
												>View Dashboard<app-icon
													name="arrow-right"
													[color]="'var(--color-primary-1000)'"
													class="arrow"
													>→</app-icon
												></span
											>
										</td>
									</ng-container>

									<ng-container matColumnDef="column-four">
										<td mat-cell *matCellDef="let element" class="button-container fourth-collumn">
											<button class="drop-down-arrow-button hidden" aria-label="expand row">
												<app-icon class="drop-down-arrow" [name]="'arrow-down'"></app-icon>
											</button>
										</td> </ng-container
									>--
									<tr
										class="paygroup-table-row"
										mat-row
										*matRowDef="let row; columns: columns"
										(click)="routeToPayments(row)"
										(mouseenter)="setHoveredPaygroup(row.externalId)"
										(mouseleave)="setHoveredPaygroup('')"
									></tr>
								</table>
							</div>
						</td>
					</ng-container>
					<tr
						mat-row
						*matRowDef="let element; columns: columns"
						class="legalEntity-table-row"
						(click)="element.paygroupCount > 0 ? toggleLegalEntity(element) : null"
					></tr>
					<tr mat-row *matRowDef="let row; columns: ['paygroup-row']"></tr>
				</table>
			</div>
		</td>
	</ng-container>

	<tr
		mat-row
		*matRowDef="let element; columns: columns"
		(click)="element.customerDetails.legalEntityCount > 0 ? toggleCustomer(element) : null"
		class="customer-table-row"
		[ngClass]="{ expanded: isCustomerExpanded(element) }"
	></tr>
	<tr mat-row *matRowDef="let element; columns: ['legalEntity-row']"></tr>
</table>
