import { Action } from "@ngrx/store";
import { CustomerEntityCountrySelect } from "../models/customerEntityCountrySelection.model";

export enum CustomerEntityCountryActionType {
	SET_SELECTED = "[CustomerEntityCountry] Set Selected"
}
export class UpdateCustomerEntityCountryAction implements Action {
	readonly type = CustomerEntityCountryActionType.SET_SELECTED;

	//add an optional payload
	constructor(public payload: CustomerEntityCountrySelect) {}
}
export type CustomerEntityCountrySelectAction = UpdateCustomerEntityCountryAction;
