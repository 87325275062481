<!-- Title -->
<app-breadcrumbs [headerTitle]="'CUSTOMERS'" />
<div class="main-container">
	<!--Fab button create new customers-->

	<button
		*ngIf="canCreateCustomers"
		id="newCustomerCreation"
		mat-fab
		class="fab-button-style"
		(click)="navigateToCreateCustomer()"
	>
		<app-icon id="add-customer-icon" [name]="'add'" [color]="'white'" [size]="'30'" [lineWidth]="'2px'"></app-icon>
	</button>

	<app-customer-list
		id="customer-list"
		[onlyApproved]="false"
		(newCustomerSelectedEvent)="onCustomerSelected($event)"
	></app-customer-list>
</div>
