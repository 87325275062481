import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormGroup, UntypedFormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, takeUntil, take } from "rxjs/operators";
import { Store } from "@ngrx/store";

import { PayCycleService } from "@shared/services/pay-cycle/pay-cycle.service";
import { MilestoneReport, Payout, Transaction } from "src/app/shared/models/payouts.types";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import {
	FundingDateMilestoneStatusOptions,
	IStatusForm,
	Milestone,
	MilestoneStatusOptions,
	MilestoneTypeName,
	MilestoneUpdate,
	MilestoneUpdateDTO,
	PayCycle
} from "@shared/models/pay-cycle.interface";
import { PayGroup } from "@shared/models/pay-groups";
import { MilestonesService } from "@shared/services/milestones/milestones.service";
import { TppPaymentsApiService } from "../../../../services/tpp-payments-api.service";
import { AppState } from "@store/models/state.model";
import { ResetStatusAction } from "@store/actions/statusNetPayment.action";
import { PermissionsService } from "@shared/services/permissions/permissions.service";
import { UserRole } from "@shared/constants/roles";
import { FeatureService } from "@shared/services/feature/feature.service";

@Component({
	selector: "app-calendar-events",
	templateUrl: "./calendar-events.component.html",
	styleUrls: ["./calendar-events.component.scss"]
})
export class CalendarEventsComponent implements OnInit, OnDestroy {
	@Input() set _payCycle(pay: PayCycle) {
		this.payCycle = pay;
	}

	@Input() set _payGroup(payGroup: PayGroup) {
		this.payGroup = payGroup;
	}

	@Input() set _milestones(value: Milestone[]) {
		this.milestones = value;
	}

	@Input() set _milestone(value: Milestone) {
		this.permissions
			.canEditFundingDateStatus()
			.pipe(take(1))
			.subscribe(res => {
				this.canEditFundingDateSatus = res;
				this.milestone = value;
				this.setupFundingSelectOption(value.type);
			});
	}

	showMilestonesState: boolean = false;

	@Input() set _showMilestonesState(val: boolean) {
		this.showMilestonesState = val;
	}

	@Input() events!: Observable<void>;

	@Output() updatePayCycle: EventEmitter<PayCycle> = new EventEmitter<PayCycle>();
	@Output() onStatus: EventEmitter<void> = new EventEmitter<void>();
	@Output() onMenu: EventEmitter<void> = new EventEmitter<void>();

	title: string = "Net payment";
	subtitle: string = "";
	statusColor: string | undefined = "";
	milestonesSelection!: SelectOption[];
	milestoneStatusForm!: FormGroup;
	payCycle!: PayCycle;
	payGroup!: PayGroup;
	milestone!: Milestone;
	milestones!: Milestone[];
	dataSource: BehaviorSubject<Payout[]> = new BehaviorSubject<Payout[]>([]);
	payoutTransactions!: Transaction[];
	milestoneReport!: MilestoneReport;
	eventsSubscription!: Subscription;
	payoutCurrency: string = "";
	hasBeenProcessed: boolean = false;
	componentDataLoading: boolean = true;
	showDropDown = false;

	payouts$: Observable<Payout[]> = this.dataSource.asObservable();
	destroy$ = new Subject<void>();
	canEditFundingDateSatus = false;

	private isDisabledField = false;
	private netPaymentOrderScreenEnabled = false;

	constructor(
		private router: Router,
		private payCycleService: PayCycleService,
		private milestonesService: MilestonesService,
		private formBuilder: UntypedFormBuilder,
		private tppPaymentsApi: TppPaymentsApiService,
		private store: Store<AppState>,
		private permissions: PermissionsService,
		private features: FeatureService
	) {
		this.permissions
			.disableAccessToEditableFormField([UserRole.TS_IMPLEMENTATION_LEAD, UserRole.TS_VO, UserRole.CLIENT])
			.subscribe(isDisabled => (this.isDisabledField = isDisabled));
	}

	ngOnInit(): void {
		this.netPaymentOrderScreenEnabled = this.features.isFeatureEnabled("netPaymentOrderScreen");

		this.permissions
			.canEditFundingDateStatus()
			.pipe(take(1))
			.subscribe(res => {
				this.canEditFundingDateSatus = res;
			});

		this.eventsSubscription = this.events.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.updateMilestoneReport(this.milestone);
		});
	}

	ngOnDestroy(): void {
		this.dataSource.unsubscribe();
		this.destroy$.next();
		this.destroy$.complete();
		this.eventsSubscription.unsubscribe();
	}

	onMilestoneClick(type: string) {
		this.handleMilestoneClickEvent(type);
		this.store.dispatch(new ResetStatusAction({ statusPayment: [] }));
	}

	private setupFundingSelectOption(milestoneType: MilestoneTypeName): void {
		if (milestoneType === "FUNDING_DATE") {
			this.milestonesSelection = FundingDateMilestoneStatusOptions;
		} else {
			this.milestonesSelection = MilestoneStatusOptions;
		}

		this.updateMilestonesStatus();
	}

	private updateMilestoneReport(milestone: Milestone) {
		this.componentDataLoading = true;

		if (milestone.type === "PAYMENT" || milestone.type === "NETS") {
			this.tppPaymentsApi
				.getMilestoneReport(milestone.id!)
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: (report: MilestoneReport) => {
						this.milestoneReport = this.updateReportCurrency(report);
						this.componentDataLoading = false;
					},
					error: () => {
						// Required to prevent infinite loading state on error
						this.componentDataLoading = false;
					}
				});
		} else {
			this.componentDataLoading = false;
		}
	}
	updateReportCurrency(report: MilestoneReport): MilestoneReport {
		const defaultCurrency = this.payGroup.data.currency;

		if (report.totalPayoutAmount.currency !== undefined && report.totalPayoutAmount.value === 0) {
			report.totalPayoutAmount.currency = defaultCurrency;
		}
		if (report.totalFailedAmount.currency !== undefined && report.totalFailedAmount.value === 0) {
			report.totalFailedAmount.currency = defaultCurrency;
		}
		if (report.totalFundingAmount.currency !== undefined && report.totalFundingAmount.value === 0) {
			report.totalFundingAmount.currency = defaultCurrency;
		}

		return report;
	}

	getTextOfMilestoneValue(statusValue?: string): string {
		return statusValue
			? FundingDateMilestoneStatusOptions.filter(option => option.value === statusValue)[0].text
			: "Not Found";
	}

	getValueOfMilestoneText(statusText: string): string {
		return FundingDateMilestoneStatusOptions.filter(option => option.text === statusText)[0].value;
	}

	toggleDropDown(show?: boolean): void {
		if ((!this.canEditFundingDateSatus && this.milestone.type === "FUNDING_DATE") || this.isDisabledField) {
			this.showDropDown = false;
		} else {
			show !== undefined ? (this.showDropDown = show) : (this.showDropDown = !this.showDropDown);
		}
	}

	setStatusSelection(status: string) {
		this.milestone.status = this.getTextOfMilestoneValue(status);
		this.milestoneStatusForm.get("milestoneStatus")?.patchValue({
			value: this.getTextOfMilestoneValue(status),
			disabled: (!this.canEditFundingDateSatus && this.milestone.type === "FUNDING_DATE") || this.isDisabledField
		});
	}

	private updateMilestonesStatus() {
		if (this.milestone?.status === "UNDEFINED") {
			this.milestone.status = "PENDING";
		} else if (this.milestone?.status === "HAS_ERRORS") {
			this.milestone.status = "ERROR";
		} else if (this.milestone?.status === "COMPLETE") {
			this.milestone.status = "COMPLETE";
		} else if (this.milestone?.status === "IN_PROGRESS") {
			this.milestone.status = "IN_PROGRESS";
		} else {
			this.milestone.status = this.milestone.status;
		}

		this.statusColor = this.milestone.status;

		this.initMilestoneStatusForm();
		this.updateMilestoneReport(this.milestone);
	}

	private initMilestoneStatusForm() {
		this.milestoneStatusForm = this.formBuilder.group({
			milestoneStatus: {
				value: this.getTextOfMilestoneValue(this.milestone.status),
				disabled:
					(!this.canEditFundingDateSatus && this.milestone.type === "FUNDING_DATE") || this.isDisabledField
			}
		}) as IStatusForm;

		this.milestoneStatusForm
			.get("milestoneStatus")
			?.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(400), distinctUntilChanged())
			.subscribe(status => {
				this.updateStatusMilestonePayCycle(this.getValueOfMilestoneText(status.value), this.milestone.type);
			});
	}

	private updateStatusMilestonePayCycle(status: string, milestoneType: string) {
		this.componentDataLoading = true;

		const milestoneUpdateDTO: MilestoneUpdateDTO = {
			milestones: []
		};

		const payDate = this.payCycleService.formatPayDateForMilestones(
			this.milestone.date,
			this.payGroup.legalEntity.data.timeZone
		);

		const milestoneToUpdate: MilestoneUpdate = {
			id: this.milestone.id,
			version: this.milestone.version,
			type: this.milestone.type,
			group: this.milestone.group,
			date: payDate,
			status: status,
			params: []
		};

		milestoneUpdateDTO.milestones.push(milestoneToUpdate);

		this.milestonesService.updateMilestones(milestoneUpdateDTO).subscribe({
			next: () => {
				this.updatePayCycle.emit(this.payCycle);
				this.componentDataLoading = false;
			},
			error: () => {
				// Required to prevent infinite loading state on error
				this.updatePayCycle.emit(this.payCycle);
				this.componentDataLoading = false;
			}
		});
	}

	private handleMilestoneClickEvent(type: string) {
		// dont redirect until component is ready as it injects data to dependants
		// assumes that any `@input()`'s to this component are resolved before this component is rendered in the view.
		if (this.componentDataLoading) return;

		let route: string = "payments";

		if (this.router.url === "/global-dashboard/dashboard") route = "global-dashboard";

		switch (type) {
			case "G2N_FILE":
				let g2nRoute = this.netPaymentOrderScreenEnabled
					? `/${route}/payment-orders`
					: `/${route}/file-history`;
				this.router.navigate([g2nRoute], {
					state: {
						payGroup: this.payGroup,
						payCycleId: this.payCycle.id,
						payCycleExternalId: this.payCycle.externalId,
						payCycle: this.payCycle,
						milestone: this.milestone,
						payrollCycleId: this.payCycle.payrollCycleId
					}
				});
				break;
			case "NETS":
				this.router.navigate([`/${route}/payouts`], {
					state: {
						payGroup: this.payGroup,
						payCycleId: this.payCycle.id,
						payCycleName: this.payCycle.name,
						milestones: this.milestones,
						milestone: this.milestone,
						milestoneReport: this.milestoneReport
					}
				});
				break;

			case "DATA_INPUT":
				this.router.navigate([`/${route}/tpp-data-input`], {
					state: {
						payGroup: this.payGroup,
						payCycle: this.payCycle,
						milestones: this.milestones,
						milestone: this.milestone
					}
				});
				break;

			case "PAYMENT":
				this.router.navigate([`/${route}/tpp-payments`], {
					state: {
						payGroup: this.payGroup,
						payCycle: this.payCycle,
						milestones: this.milestones,
						milestone: this.milestone,
						milestoneReport: this.milestoneReport
					}
				});
				break;
		}
	}
}
