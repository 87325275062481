import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TppSubService } from "../../models/tpp-data.interface";

@Component({
	selector: "app-tpp-subservice-list",
	templateUrl: "./tpp-subservice-list.component.html",
	styleUrls: ["./tpp-subservice-list.component.scss"]
})
export class TppSubserviceListComponent implements OnInit {
	subServices!: TppSubService[];

	@Output() emitOpenPopUp: EventEmitter<TppSubService> = new EventEmitter<TppSubService>();

	@Input() set _subServices(subServices: TppSubService[]) {
		this.subServices = subServices;
	}

	constructor() {}

	ngOnInit(): void {}
}
