export class BeneficiaryAccountStatusByPayGroup {
	customerId: string;
	legalEntityId: string;
	payGroupId: string;
	externalPayGroupId: string;
	externalLegalEntityId: string;

	payGroupName: string;
	countryCode: string;
	customerName: string;
	legalEntityName: string;
	status: string;
	totalEmployees: number;
	totalBeneficiaries: number;
	stats: BeneficiaryErrorStatistics | null;

	constructor(
		customerId: string,
		legalEntityId: string,
		payGroupId: string,
		externalPayGroupId: string,
		externalLegalEntityId: string,
		payGroupName: string,
		countryCode: string,
		customerName: string,
		legalEntityName: string,
		status: string,
		totalEmployees: number,
		totalBeneficiaries: number,
		stats: BeneficiaryErrorStatistics | null
	) {
		this.customerId = customerId;
		this.legalEntityId = legalEntityId;
		this.payGroupId = payGroupId;
		this.externalPayGroupId = externalPayGroupId;
		this.externalLegalEntityId = externalLegalEntityId;
		this.payGroupName = payGroupName;
		this.countryCode = countryCode;
		this.customerName = customerName;
		this.legalEntityName = legalEntityName;
		this.status = status;
		this.totalEmployees = totalEmployees;
		this.totalBeneficiaries = totalBeneficiaries;
		this.stats = stats;
	}
}

export class BeneficiaryErrorStatistics {
	// preferred names as they align with the UI
	payMethodsErrorCount: number | null; // totalBeneficiaryErrors
	employeesWithNoCardCount: number | null; // totalEmployeesWithNoCards
	employeesWithNoAccountCount: number | null; // totalEmployeesWithNoPaymentElectives

	constructor(
		payMethodsErrorCount: number | null,
		employeesWithNoCardCount: number | null,
		employeesWithNoAccountCount: number | null
	) {
		this.payMethodsErrorCount = payMethodsErrorCount;
		this.employeesWithNoCardCount = employeesWithNoCardCount;
		this.employeesWithNoAccountCount = employeesWithNoAccountCount;
	}
}
