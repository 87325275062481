import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { Store, select } from "@ngrx/store";
import { saveAs } from "file-saver";
import { Observable, Subject, forkJoin, map, mergeMap, of, takeUntil } from "rxjs";

import { ToastService } from "@shared/services/toast/toast.service";
import { getUserDataState } from "@store/index";
import { AppState } from "@store/models/state.model";
import { FileManagementUploadService } from "../../../shared/services/file-management/file-management-upload.service";
import { UserService } from "../../user-management/_services/user.service";
import {
	CalendarFile,
	CalendarFilePagination,
	CalendarFilesTypes,
	DownloadTemplateFilePagination
} from "../model/calendar-file.model";
import { CalendarFilesHistoryService } from "../services/calendar-files-history.service";

@Component({
	selector: "app-calendar-file-list",
	templateUrl: "./calendar-file-list.component.html",
	styleUrls: ["./calendar-file-list.component.scss"]
})
export class CalendarFileListComponent implements OnInit, OnDestroy {
	errorTaskId!: string;
	files$!: Observable<CalendarFilePagination>;
	destroy$: Subject<void> = new Subject();
	isVisible = false;
	userEmail!: string;
	userFullName = "";
	perPage = 20;
	pageIndex = 0;
	totalPageCount = 0;
	user!: any;
	fetchingFiles = true;
	calendarFilesCount = this.perPage;

	@Output() totalCalendarFilesCount = new EventEmitter<number>();
	@Output() currentCalendarFilesCount = new EventEmitter<number>();

	constructor(
		private fileManagementUploadService: FileManagementUploadService,
		private toastService: ToastService,
		private calendarFilesHistoryService: CalendarFilesHistoryService,
		private store: Store<AppState>,
		private userService: UserService
	) {}

	ngOnInit(): void {
		this.store.pipe(takeUntil(this.destroy$), select(getUserDataState)).subscribe(userData => {
			if (userData?.email) {
				this.fetchingFiles = true;
				this.userEmail = userData.email as string;
				this.userFullName = `${userData.firstName} ${userData.lastName}`;
				this.loadFilesData(userData.email as string);
				this.setupPageReloadListener();
			} else {
				this.fetchingFiles = false;
			}
		});
	}
	setupPageReloadListener(): void {
		this.fileManagementUploadService.updateCalendarFileList$.pipe(takeUntil(this.destroy$)).subscribe(reload => {
			this.fetchingFiles = true;
			this.loadFilesData(this.userEmail);
		});
	}

	/**
	 * Use to show more calendar records
	 * @param count: number of files/records to display
	 */
	loadMoreFilesData(count: number) {
		this.calendarFilesCount += count;
		this.currentCalendarFilesCount.emit(this.calendarFilesCount);
		this.loadFilesData(this.userEmail);
	}

	loadFilesData(userEmail: string, perPage = -1, pageIndex = 0, isRefresh = false): void {
		this.userService
			.getUsersByEmail(userEmail)
			.pipe(
				takeUntil(this.destroy$),
				map(results => results.items[0]),
				mergeMap(user =>
					forkJoin([
						this.calendarFilesHistoryService.fetchCalendarUploadedFilesHistoryByUser(
							user?.id ? user.id : "",
							userEmail,
							perPage,
							pageIndex
						),
						this.calendarFilesHistoryService.fetchCalendarTemplateDownloadRequests()
					])
				)
			)
			.subscribe((files: [CalendarFilePagination, DownloadTemplateFilePagination]) => {
				this.fetchingFiles = false;
				const uploadedFiles: CalendarFilePagination = this.configureUploadedFilesFormat(files[0]);
				const downloadedFiles: CalendarFilePagination = this.configureDownloadedFilesFormat(files[1]);
				const combinedFiles: CalendarFilePagination = uploadedFiles;

				combinedFiles.items.push(...downloadedFiles.items);

				combinedFiles.items = combinedFiles.items.sort(
					(a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
				);

				this.calendarFilesCount = isRefresh ? this.perPage : this.calendarFilesCount;
				this.currentCalendarFilesCount.emit(this.calendarFilesCount);
				this.totalCalendarFilesCount.emit(combinedFiles.items.length);
				combinedFiles.items = combinedFiles.items.filter((f, i) => i < this.calendarFilesCount);

				this.files$ = of(combinedFiles);
				this.totalPageCount = combinedFiles.totalCount;
			});
	}

	configureDownloadedFilesFormat(downloadedFiles: DownloadTemplateFilePagination): CalendarFilePagination {
		const formattedDownloadFiles: CalendarFilePagination = {
			items: [],
			pageNumber: downloadedFiles.pageNumber,
			totalCount: downloadedFiles.totalCount,
			totalPages: downloadedFiles.totalPages
		};

		downloadedFiles.items.forEach(file => {
			const downloadFile: CalendarFile = {} as CalendarFile;

			downloadFile.attributes = {
				FILE_NAME: "CALENDAR FILE_template",
				MANAGED_BY: this.userFullName
			};
			downloadFile.executeAt = file.createdAt;
			downloadFile.id = file.id;
			downloadFile.status = file.status.name;
			downloadFile.fileType = CalendarFilesTypes.DOWNLOAD;
			downloadFile.updatedAt = file.updatedAt;
			formattedDownloadFiles.items.push(downloadFile);
		});

		return formattedDownloadFiles;
	}
	configureUploadedFilesFormat(uploadedFiles: CalendarFilePagination): CalendarFilePagination {
		uploadedFiles.items.forEach(file => (file.fileType = CalendarFilesTypes.UPLOAD));

		return uploadedFiles;
	}

	viewErrors(taskId: string): void {
		this.isVisible = true;
		this.errorTaskId = taskId;
	}

	downloadTemplate(fileId: string, fileName?: string): void {
		this.calendarFilesHistoryService.getReportCalendarDetails(fileId).subscribe(buffer => {
			const data: Blob = new Blob([buffer], {
				type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
			});
			saveAs(data, fileName);
		});
	}

	downloadFile(taskId: string, fileName?: string): void {
		this.calendarFilesHistoryService
			.searchFileIdByTaskId(taskId)
			.pipe(mergeMap(({ fileId }) => this.calendarFilesHistoryService.fetchFileAfterUploaded(fileId)))
			.subscribe(buffer => {
				const data: Blob = new Blob([buffer], {
					type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
				});
				saveAs(data, fileName);
			});
	}

	uploadCalendarFile(files: any): void {
		try {
			const file: File = files[0];

			let formData = new FormData();
			formData.append("file", file);
			formData.append("fileType", "EXCEL");
			formData.append("sheetIndex", "0");
			formData.append("headerIndex", "0");

			this.fileManagementUploadService.uploadCalendarFile(formData);
		} catch (error) {
			this.toastService.showError("There was an error loading the file. Please try again");
		}
	}

	toggleFileErrosPopup(): void {
		this.isVisible = !this.isVisible;
	}

	paginate(pagination: PageEvent): void {
		this.perPage = pagination.pageSize;
		this.pageIndex = pagination.pageIndex;
		this.fetchingFiles = true;
		this.loadFilesData(this.userEmail, this.perPage, this.pageIndex);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
