import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { UserService } from "../../_services/user.service";
import { UserRole } from "@shared/constants/roles";

@Component({
	selector: "app-select",
	templateUrl: "./select.component.html",
	styleUrls: ["./select.component.scss"]
})
export class SelectComponent implements OnInit {
	@Input() roles: SelectOption[] = [];
	@Input() options: SelectOption[] = [];
	colorsRoles: string[] = [];
	colorsIcons: string[] = [];

	@Output() updateSelectedOptions = new EventEmitter<SelectOption[]>();

	@Output() disableSaveButton = new EventEmitter<boolean>();

	openList: boolean = false;
	hasClientConflictError = false;

	constructor(private userService: UserService) {}

	ngOnInit(): void {
		if (this.roles) {
			this.colorsIcons = this.userService.setIconColor(this.roles);
		}

		this.options = this.options.filter((option: SelectOption) => option.text != "All rols");

		this.clientRoleConflictCheck();
	}

	showList() {
		this.openList = !this.openList;
	}

	deleteSelection(deleteItem: SelectOption, $event: Event) {
		this.roles = this.roles.filter((item: SelectOption) => deleteItem.text != item.text);
		this.colorsIcons = this.userService.setIconColor(this.roles);
		this.updateSelectedOptions.emit(this.roles);
		$event.stopPropagation();
		this.clientRoleConflictCheck();
	}

	addSelection(item: SelectOption) {
		const repeated = this.roles.find((role: SelectOption) => item.text == role.text);
		if (!repeated) {
			if (
				(this.roles.some(item => item.value === UserRole.CLIENT) && item.value !== UserRole.CLIENT) ||
				(item.value === UserRole.CLIENT && this.hasOtherRoles())
			) {
				this.updateRoles(item);
				this.throwClientConflictError(true);
			} else {
				this.updateRoles(item);
			}
		}

		this.colorsIcons = this.userService.setIconColor(this.roles);
		this.showList();
	}

	hasOtherRoles(): boolean {
		return this.roles.some(item => item.value !== UserRole.CLIENT);
	}

	updateRoles(item: SelectOption): void {
		this.roles.push(item);
		this.updateSelectedOptions.emit(this.roles);
	}

	throwClientConflictError(bool: boolean): void {
		this.hasClientConflictError = bool;
		this.disableSaveButton.emit(bool);
	}

	clientRoleConflictCheck(): void {
		if (this.roles.some(item => item.value === UserRole.CLIENT) && this.hasOtherRoles()) {
			this.throwClientConflictError(true);
		} else {
			// unblock save and ui remove message
			this.throwClientConflictError(false);
		}
	}
}
