import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { ReferenceStatus, TppSubService, UploadMethod } from "../../models/tpp-data.interface";

@Component({
	selector: "app-tpp-subservice-list-item",
	templateUrl: "./tpp-subservice-list-item.component.html",
	styleUrls: ["./tpp-subservice-list-item.component.scss"]
})
export class TppSubserviceListItemComponent implements OnInit {
	uploadedByFile: boolean = true;
	uploadedManually: boolean = true;

	//Objects of endpoint call

	refrenceStatusColor: string = "";
	refrenceStatusIcon: string = "";

	subService!: TppSubService;

	@Input() hideMatDivider: boolean = false;

	@Input() set _subService(subService: TppSubService) {
		this.subService = subService;

		if (subService.referenceOkay) {
			this.setRefrenceStatus(subService.referenceOkay);
		} else {
			this.refrenceStatusColor = "var(--errors-color)";
			this.refrenceStatusIcon = "close-circle";
		}

		this.setUploadTypes(subService.uploadedBy);
	}

	//Output pop up state
	@Output() emitOpenPopUp: EventEmitter<TppSubService> = new EventEmitter<TppSubService>();

	constructor() {}

	ngOnInit(): void {}

	setCanAddPayment() {
		throw new Error("Method not implemented.");
	}

	setUploadTypes(uploadedMethod: UploadMethod[]) {
		this.uploadedByFile = this.subService.uploadedBy.includes(UploadMethod.FILE);
		this.uploadedManually = this.subService.uploadedBy.includes(UploadMethod.MANUAL);
	}

	setRefrenceStatus(refrenceStatus: boolean) {
		switch (refrenceStatus) {
			case true:
				{
					this.refrenceStatusColor = "var(--complete-color)";
					this.refrenceStatusIcon = "tick-circle";
				}
				break;
			case false:
				{
					this.refrenceStatusColor = "var(--pending-color)";
					this.refrenceStatusIcon = "info-circle";
				}
				break;
			default:
				{
					this.refrenceStatusColor = "var(--errors-color)";
					this.refrenceStatusIcon = "close-circle";
				}
				break;
		}
	}

	viewDetails(view: boolean): void {
		if (view) {
			this.emitOpenPopUp.emit(this.subService);
		}
	}
}
