import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MilestoneStatusHistory } from "@shared/models/pay-cycle.interface";
import { BehaviorSubject, Observable, Subject, forkJoin } from "rxjs";
import { debounceTime, distinctUntilChanged, take, takeUntil } from "rxjs/operators";
import { HistoryLogSidePanelService } from "../services/history-log-side-panel.service";
import {
	MilestoneEventHistoryLogData,
	TransactionHistoryLogData,
	HistoryLogType
} from "../models/history-log-interface";
import { TransactionReport } from "@shared/models/payouts.types";

@Component({
	selector: "app-history-log-side-panel",
	templateUrl: "./history-log-side-panel.component.html",
	styleUrls: ["./history-log-side-panel.component.scss"]
})
export class HistoryLogSidePanelComponent implements OnInit, OnDestroy {
	@Input() historyType = "";
	panelTitle = "";
	exit = false;
	searchForm!: FormGroup;
	searchText = "";

	milestoneIdentifierData!: MilestoneEventHistoryLogData;
	statusHistoryRecords!: MilestoneStatusHistory[];
	statusHistoryRecordsFilterCopy: MilestoneStatusHistory[] = [];

	transactionIdentifierData!: TransactionHistoryLogData;
	statusHistoryRecordsTransactions!: TransactionReport[];
	statusHistoryRecordsFilterCopyTransactions: TransactionReport[] = [];

	recordsFetched = false;

	HistoryLogType = HistoryLogType;

	private destroy$: Subject<void> = new Subject();

	constructor(private formBuilder: FormBuilder, private historyLogService: HistoryLogSidePanelService) {}

	ngOnInit(): void {
		this.recordsFetched = false;
		this.setupSearchForm();
		this.subscribeToHistoryLogSubject();

		if (this.historyType === HistoryLogType.MILESTONE) {
			this.getMilestoneHistoryRecords();
			this.panelTitle = "History Log /";
		}
		if (this.historyType === HistoryLogType.TRANSACTION) {
			this.getTransactionHistoryRecords();
			this.panelTitle = "Transaction History Log";
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	setupSearchForm(): void {
		this.searchForm = this.formBuilder.group({
			searchControl: [""]
		});

		this.searchForm
			.get("searchControl")
			?.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(300), distinctUntilChanged())
			.subscribe((newValue: string) => {
				this.searchText = newValue;

				this.searchHistoryRecords(this.searchText);
			});
	}

	searchHistoryRecords(searchText: string): void {
		searchText = searchText.toLowerCase();

		let observables: Observable<any>[] = [];
		if (this.historyType === HistoryLogType.MILESTONE) {
			observables = this.statusHistoryRecords.map(record =>
				this.historyLogService.getSearchFilterTransformedKeys(record.userId, record.createdAt).pipe(take(1))
			);
		}

		if (this.historyType === HistoryLogType.TRANSACTION) {
			observables = this.statusHistoryRecordsTransactions.map(record =>
				this.historyLogService.getSearchFilterTransformedKeysTranscations(record.createdAt)
			);
		}

		forkJoin(observables)
			.pipe(take(1))
			.subscribe({
				next: results => {
					if (this.historyType === HistoryLogType.MILESTONE) {
						this.statusHistoryRecordsFilterCopy = this.statusHistoryRecords.filter((record, index) => {
							const result = results[index];
							const recordText = `${result.user} has changed the status to ${record.statusTo} on ${result.ordinalDate} at ${record.statusTo} on ${result.timeDate}`;

							return recordText.toLowerCase().includes(searchText);
						});
					}
					if (this.historyType === HistoryLogType.TRANSACTION) {
						this.statusHistoryRecordsFilterCopyTransactions = this.statusHistoryRecordsTransactions.filter(
							(record, index) => {
								const result = results[index];
								const recordText = `has changed the transaction state of ${record.employeeFirstName} ${record.employeeLastName} with the id ${record.transactionId} to ${record.status.name}`;

								return recordText.toLowerCase().includes(searchText);
							}
						);
					}
				},
				error: err => {}
			});
	}

	subscribeToHistoryLogSubject(): void {
		this.getSubject()
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: res => {
					if (!res) {
						this.exit = true;
					} else {
						if (this.historyType === HistoryLogType.MILESTONE) {
							this.milestoneIdentifierData = res as MilestoneEventHistoryLogData;
						}
						if (this.historyType === HistoryLogType.TRANSACTION) {
							this.transactionIdentifierData = res as TransactionHistoryLogData;
						}
					}
				}
			});
	}

	getMilestoneHistoryRecords(): void {
		this.historyLogService
			.getMilestone(this.milestoneIdentifierData.milestoneId)
			.pipe(take(1))
			.subscribe({
				next: res => {
					this.statusHistoryRecords = res.statusHistory!.sort(
						(a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
					);

					this.statusHistoryRecordsFilterCopy = this.statusHistoryRecords;
					this.recordsFetched = true;
				}
			});
	}

	getTransactionHistoryRecords(): void {
		this.historyLogService
			.getTransactionHistory(
				this.transactionIdentifierData.payoutId,
				this.transactionIdentifierData.paymethodId,
				"2000-01-01",
				"2050-12-31"
			)
			.pipe(take(1))
			.subscribe({
				next: res => {
					const transactions = res.items;
					this.statusHistoryRecordsTransactions = transactions;

					// Create a new array to store the reprocessed transactions
					const reprocessedTransactions = [];

					for (let i = 1; i < this.statusHistoryRecordsTransactions.length; i++) {
						if (
							this.statusHistoryRecordsTransactions[i].transactionId !==
							this.statusHistoryRecordsTransactions[i - 1].transactionId
						) {
							const reprocessedTransaction: TransactionReport = {
								...this.statusHistoryRecordsTransactions[i],
								status: { name: "REPROCESSED", message: "" }
							};
							reprocessedTransactions.push(reprocessedTransaction);
						}
					}

					// Concatenate the original transactions with the reprocessed transactions
					this.statusHistoryRecordsTransactions = [
						...this.statusHistoryRecordsTransactions,
						...reprocessedTransactions
					];

					this.statusHistoryRecordsTransactions = this.statusHistoryRecordsTransactions!.sort(
						(a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
					);

					this.statusHistoryRecordsFilterCopyTransactions = this.statusHistoryRecordsTransactions;
					this.recordsFetched = true;
				}
			});
	}

	getSubject(): BehaviorSubject<MilestoneEventHistoryLogData | TransactionHistoryLogData> {
		let subject: BehaviorSubject<MilestoneEventHistoryLogData | TransactionHistoryLogData>;

		if (this.historyType === HistoryLogType.MILESTONE) {
			subject = this.historyLogService?.milestoneEventHistoryLogSubject as BehaviorSubject<
				MilestoneEventHistoryLogData | TransactionHistoryLogData
			>;
		}

		if (this.historyType === HistoryLogType.TRANSACTION) {
			subject = this.historyLogService?.transactionHistoryLogSubject as BehaviorSubject<
				MilestoneEventHistoryLogData | TransactionHistoryLogData
			>;
		}

		return subject!;
	}

	resetSubjects(): void {
		this.historyLogService.resetElementData();
	}

	isArray(value: string | string[]): boolean {
		return Array.isArray(value);
	}

	getArrayValue(value: string | string[]): string[] {
		if (this.isArray(value)) {
			return Array.isArray(value) ? value : [value];
		}
		return [];
	}

	isEmptyObject(object: any): boolean {
		return Object.keys(object).length === 0;
	}
}
