import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Booking, BookingEmployeeTypeDetails, TppPayouts } from "../../models/tpp-payment.interface";
import { Observable } from "rxjs";

@Component({
	selector: "app-tpp-payment-payout-item-detail-list",
	templateUrl: "./tpp-payment-payout-item-detail-list.component.html",
	styleUrls: ["./tpp-payment-payout-item-detail-list.component.scss"]
})
export class TppPaymentPayoutItemDetailListComponent implements OnInit {
	payouts!: TppPayouts[];

	totalHeight: number = 0;

	@Output() inActivatePayouts: EventEmitter<string[]> = new EventEmitter<string[]>();
	@Output() pushToReprocessList: EventEmitter<string[]> = new EventEmitter<string[]>();
	@Output() popFromReprocessList: EventEmitter<string[]> = new EventEmitter<string[]>();

	@Input() set _payouts(payouts: TppPayouts[]) {
		this.payouts = payouts;
		this.totalHeight = payouts.length * 77 + 45;
	}

	reprocessPayoutIdsList: string[] = [];

	@Input() set _reprocessPayoutIdsList(ids: string[]) {
		this.reprocessPayoutIdsList = ids;
	}

	isCancelledRun: boolean = false;
	@Input() set _isCancelledRun(cancelled: boolean) {
		this.isCancelledRun = cancelled;
	}

	isInActivatedPayout!: boolean;
	@Input() set _isInActivatedPayout(val: boolean) {
		this.isInActivatedPayout = val;
	}

	constructor() {}

	ngOnInit(): void {}
}
