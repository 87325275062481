<div class="payment-type-container" *ngFor="let typeRoute of typeRoutes; let typeRouteIndex = index">
	<div class="payment-type-title">
		{{ typeRoute.type }}
	</div>
	<div class="accounts-container">
		<div class="account-card" *ngFor="let route of typeRoute.routes; let routeIndex = index">
			<div class="route-container">{{ route.providerName }} > {{ route.payoutAccount }} > {{ route.route }}</div>
			<div class="content-container">
				<div class="main-col">
					<h4>Funding Currency</h4>
					<mat-form-field class="select-source-funds" appearance="outline">
						<mat-select
							[disabled]="!canEditSourceOfFunds"
							id="{{
								'fundingCurrencyInput' +
									typeRoute.type +
									route.providerName +
									route.payoutAccount +
									route.route
							}}"
							[(ngModel)]="route.selectedCurrency"
							(selectionChange)="getAvailableAccountsForRoute(typeRouteIndex, routeIndex)"
						>
							<mat-option *ngFor="let currency of route.currencies" [value]="currency">
								{{ currency }}
							</mat-option>
						</mat-select>
						<mat-select-trigger class="drop-down-trigger">
							<app-icon
								id="drop-down-arrow-select-SOF"
								[color]="'rgba(0, 0, 0, 0.54)'"
								[lineWidth]="'2.5'"
								[name]="'arrow-down'"
								[size]="'16'"
							></app-icon>
						</mat-select-trigger>
					</mat-form-field>
				</div>
				<div class="utility-col">
					<app-icon [name]="'arrow-right'"></app-icon>
				</div>
				<div class="main-col">
					<h4>Choose existing account</h4>
					<mat-form-field class="select-source-funds" appearance="outline">
						<mat-select
							id="{{
								'chooseAccountInput' +
									typeRoute.type +
									route.providerName +
									route.payoutAccount +
									route.route
							}}"
							[(ngModel)]="route.selectedAccountId"
							(selectionChange)="selectAccountForRoute(typeRouteIndex, routeIndex)"
							[disabled]="!canEditSourceOfFunds"
						>
							<mat-option *ngFor="let account of route.availableAccounts" [value]="account.id">
								{{ account.data.name }}
							</mat-option>
						</mat-select>
						<mat-select-trigger class="drop-down-trigger">
							<app-icon
								id="drop-down-arrow-select-SOF"
								[color]="'rgba(0, 0, 0, 0.54)'"
								[lineWidth]="'2.5'"
								[name]="'arrow-down'"
								[size]="'16'"
							></app-icon>
						</mat-select-trigger>
					</mat-form-field>
					<button
						*ngIf="canAddSourceOfFunds"
						id="{{
							'newAccountButton' + typeRoute.type + route.providerName + route.payoutAccount + route.route
						}}"
						mat-button
						color="primary"
						class="add-button"
						(click)="openForm(route)"
						[disabled]="
							!!route.selectedCurrency === false || !!route.account.id !== false || !canAddSourceOfFunds
						"
					>
						+ Add new source of funds
					</button>
				</div>
				<div class="buttons-col">
					<button
						id="{{
							'viewAccountButton' +
								typeRoute.type +
								route.providerName +
								route.payoutAccount +
								route.route
						}}"
						mat-button
						(click)="openForm(route)"
						*ngIf="route.account && route.account?.data?.name"
					>
						<app-icon id="viewAccountButtonBtn" [name]="'eye'"></app-icon>
					</button>
					<ng-template [appUserRolePermissions] [action]="'hide'" [roles]="['TS_VO', 'CLIENT']">
						<button
							id="{{
								'clearButton' + typeRoute.type + route.providerName + route.payoutAccount + route.route
							}}"
							mat-stroked-button
							(click)="clearRouteAccount(typeRouteIndex, routeIndex)"
							[disabled]="!canEditSourceOfFunds"
						>
							Clear
						</button>
					</ng-template>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="bottom-button-container">
	<button id="backButton" mat-stroked-button (click)="goBack()" class="button-start">Back</button>
	<button id="nextButton" mat-raised-button color="dark" (click)="goForward()" class="button-end">Next</button>
</div>

<modal-wrapper
	*ngIf="showForm"
	title="{{ !this.editMode ? 'Add' : 'Edit' }} Source of Funds"
	(onTitleButtonAction)="showBread()"
	[modalStyle]="'dark'"
	[noBreadCrumbs]="true"
	[exitIconBool]="true"
	[subHeaderColour]="'transparent'"
	[modalFullHeight]="true"
	class="add-ben-modal-wrapper"
>
	<div class="form-content">
		<!-- SOURCE OF FUNDS FIELDS -->
		<div class="form-section">
			<ng-container [formGroup]="globalForm">
				<div class="o-grid-3 full-width">
					<input-wrapper label="Funding methods" class="radio-checkbox-container">
						<mat-checkbox id="pullOptionInput" color="primary" formControlName="fundingPull"
							>PULL</mat-checkbox
						>
						<mat-checkbox id="pushOptionInput" color="primary" formControlName="fundingPush"
							>PUSH</mat-checkbox
						>
					</input-wrapper>

					<input-wrapper label="Provision Compensation" class="radio-checkbox-container">
						<mat-radio-group id="provisionCompensationInput" formControlName="provisionCompensation">
							<mat-radio-button color="primary" value="STANDARD">STANDARD</mat-radio-button>
							<mat-radio-button color="primary" value="OFFSET">OFFSET</mat-radio-button>
						</mat-radio-group>
					</input-wrapper>
				</div>

				<!-- Separator -->
				<div class="separator"></div>

				<div class="o-grid-3 full-width">
					<input-wrapper label="Account Name *">
						<input id="AccountNameInput" type="text" placeholder="Account Name" formControlName="name" />
						<div
							input-errors
							*ngIf="globalForm.controls.name.touched && globalForm.controls.name.errors?.required"
						>
							Source account name is required
						</div>
					</input-wrapper>

					<input-wrapper class="select" label="Select a country">
						<input-autocomplete
							id="countrySelectInput"
							[options]="selectOptionsCountries$ | async"
							label="All countries"
							formControlName="country"
							[disabled]="editMode || !canEditSourceOfFunds"
						>
						</input-autocomplete>
					</input-wrapper>

					<input-wrapper label="Funding currency">
						<input id="currencyInput" type="text" formControlName="currency" />
					</input-wrapper>
				</div>
			</ng-container>
		</div>

		<!-- Separator -->
		<div class="separator"></div>

		<!-- BANK ACCOUNT FIELDS -->
		<bank-account-fields
			[country]="selectedCountry"
			[bankName]="selectedRoute.account.data ? selectedRoute.account.data.bankAccount.bankName : ''"
			[values]="bankAccountValues"
			[editing]="editMode"
			[canEdit]="canEditSourceOfFunds"
		>
		</bank-account-fields>

		<div class="buttons-container sof-buttons">
			<button id="cancelButton" mat-stroked-button class="button-start" (click)="showBread()">Cancel</button>
			<button
				id="saveButton"
				mat-raised-button
				color="dark"
				class="button-end"
				(click)="save()"
				[disabled]="
					bankAccountFieldsComponent?.bankAccountForm?.invalid || globalForm.invalid || !canEditSourceOfFunds
				"
			>
				Save
			</button>
		</div>
	</div>
</modal-wrapper>
