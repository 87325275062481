import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ErrorDialogComponent } from "./error-dialog.component";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatListModule } from "@angular/material/list";

@NgModule({
	declarations: [ErrorDialogComponent],
	imports: [CommonModule, MatIconModule, MatButtonModule, MatDialogModule, MatListModule]
})
export class ErrorDialogModule {}
