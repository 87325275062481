import {
	CapabilitiesFormData,
	Capability,
	Cost,
	CostFormData,
	HeadcountFormData,
	PayoutAccountRoutesFormData,
	ServiceProviderCountryCapability,
	ServiceProviderCountryCreateDTO,
	ServiceProviderCountryUpdateDTO
} from "src/app/shared/models/service-provider-country-types";
import { ServiceProviderFullObject } from "src/app/shared/models/service-provider-types.interface";
import {
	CurrencyCode,
	FundingMethodName,
	PaymentRouteName,
	PaymentTypeName,
	PayoutAccountName
} from "src/app/shared/models/service-provider.type";

export class ServiceProviderCountry {
	private id: string | null;
	private version: string | null;
	private provider: ServiceProviderFullObject | null;
	private country: string;
	private paymentCurrencies: CurrencyCode[] | null;
	private fundingMethods: FundingMethodName[] | null;
	private paymentTypeCapabilities: ServiceProviderCountryCapability[];

	constructor(
		id: string | null,
		version: string | null,
		provider: ServiceProviderFullObject,
		country: string,
		paymentCurrencies: CurrencyCode[] | null,
		fundingMethods: FundingMethodName[] | null,
		paymentTypeCapabilities: ServiceProviderCountryCapability[]
	) {
		this.id = id;
		this.version = version;
		this.provider = provider;
		this.country = country;
		this.paymentCurrencies = paymentCurrencies;
		this.fundingMethods = fundingMethods;
		this.paymentTypeCapabilities = paymentTypeCapabilities;
	}

	getPaymentTypeCapabilities(): ServiceProviderCountryCapability[] | null {
		return this.paymentTypeCapabilities?.length ? this.paymentTypeCapabilities : null;
	}

	//set types and capabilites from the create country service
	setPaymentTypeCapabilitiesData(capabilities: CapabilitiesFormData) {
		this.paymentTypeCapabilities = [];
		const cost: Cost = {
			billingType: {
				from: 0,
				to: null
			},
			currency: "USD",
			paymentFee: 0.0,
			monthlyFee: 0.0,
			notes: ""
		};
		Object.values(capabilities).filter(capability => {
			if (capability.selected) {
				const newCapability: ServiceProviderCountryCapability = {
					paymentType: capability.paymentType,
					capabilities: capability.payoutAccountRoutes.map(routes => ({
						payoutAccount: routes.payoutAccount,
						route: routes.route,
						eta: routes.eta,
						costs: [cost]
					}))
				};
				this.paymentTypeCapabilities.push(newCapability);
			}
		});
	}

	//update the types and capabilites from the edit country service
	updatePaymentTypeCapabilitiesData(capabilities: CapabilitiesFormData) {
		Object.values(capabilities).filter(capability => {
			if (
				capability.selected &&
				!this.paymentTypeCapabilities.find(
					existingCapability => capability.paymentType === existingCapability.paymentType
				)
			) {
				capability.payoutAccountRoutes.filter(payoutAccountRoute => {
					const cost: Cost = {
						billingType: {
							from: 0,
							to: null
						},
						currency: "USD",
						paymentFee: 0.0,
						monthlyFee: 0.0,
						notes: ""
					};

					const newCapability: ServiceProviderCountryCapability = {
						paymentType: capability.paymentType,
						capabilities: capability.payoutAccountRoutes.map(routes => ({
							payoutAccount: routes.payoutAccount,
							route: routes.route,
							eta: routes.eta,
							costs: [cost]
						}))
					};
					if (
						!this.paymentTypeCapabilities.find(
							existingCapability => newCapability.paymentType === existingCapability.paymentType
						)
					) {
						this.paymentTypeCapabilities.push(newCapability);
					}
				});
			} else if (
				capability.selected &&
				this.paymentTypeCapabilities.find(
					existingCapability => capability.paymentType === existingCapability.paymentType
				)
			) {
				capability.payoutAccountRoutes.filter(payoutAccountRoute => {
					this.removeExtraCapabilites(
						capability.payoutAccountRoutes,
						this.paymentTypeCapabilities.filter(c => c.paymentType === capability.paymentType)[0]
							?.capabilities
					);

					if (
						!this.hasCapability(
							capability.paymentType,
							payoutAccountRoute.payoutAccount,
							payoutAccountRoute.route
						)
					) {
						const cost: Cost = {
							billingType: {
								from: 0,
								to: null
							},
							currency: "USD",
							paymentFee: 0.0,
							monthlyFee: 0.0,
							notes: ""
						};

						const newCapability: Capability = {
							payoutAccount: payoutAccountRoute.payoutAccount,
							route: payoutAccountRoute.route,
							eta: payoutAccountRoute.eta,
							costs: [cost]
						};
						this.paymentTypeCapabilities
							.find(c => c.paymentType === capability.paymentType)
							?.capabilities.push(newCapability);
					}
				});
			}
		});
	}

	removeExtraCapabilites(
		formCapabilities: PayoutAccountRoutesFormData[],
		currentCapabilities: PayoutAccountRoutesFormData[]
	): void {
		for (let i = currentCapabilities.length - 1; i >= 0; i--) {
			const currentCapability = currentCapabilities[i];
			let found = false;
			for (const formCapability of formCapabilities) {
				if (
					formCapability.payoutAccount === currentCapability.payoutAccount &&
					formCapability.route === currentCapability.route &&
					formCapability.eta === currentCapability.eta
				) {
					found = true;
					break;
				}
			}
			if (!found) {
				currentCapabilities.splice(i, 1);
			}
		}
	}

	removeCapabilities(
		paymentType: PaymentTypeName,
		payoutAccount: PayoutAccountName,
		route: PaymentRouteName,
		removeType: boolean
	): void {
		let index = -1;

		this.paymentTypeCapabilities
			.find(cap => cap.paymentType === paymentType)
			?.capabilities?.filter((c, i) => {
				if (
					c.payoutAccount.toString().replace(/ /g, "_") === payoutAccount.toString().replace(/ /g, "_") &&
					c.route.toString().replace(/ /g, "_") === route.toString().replace(/ /g, "_")
				) {
					index = i;
				}
			});

		if (removeType) {
			console.log("i get here");
			this.paymentTypeCapabilities = this.paymentTypeCapabilities.filter(cap => cap.paymentType !== paymentType);
		} else {
			this.paymentTypeCapabilities.find(cap => cap.paymentType === paymentType)?.capabilities.splice(index, 1);
		}
	}

	hasCapability(paymentType: PaymentTypeName, payoutAccount: PayoutAccountName, route: PaymentRouteName): boolean {
		const existingPayoutAccountRoutes: Capability[] = this.paymentTypeCapabilities.find(
			c => c.paymentType === paymentType
		)?.capabilities!;

		if (!existingPayoutAccountRoutes.length) {
			return false;
		}

		const item = existingPayoutAccountRoutes.flatMap(item => {
			return item.payoutAccount === payoutAccount &&
				item.route.toString().replace(/ /g, "_") === route.toString().replace(/ /g, "_")
				? item
				: [];
		});
		return !!item.length;
	}

	setHeadcostConfig(formData: HeadcountFormData): void {
		Object.values(formData).filter(hcd => {
			const capabilities = this.paymentTypeCapabilities.find(cap => cap.paymentType === hcd.paymentType);

			capabilities?.capabilities.filter(capability => {
				hcd.config.map(conf => {
					if (
						capability.payoutAccount === conf.payoutAccount &&
						capability.route.toString().replace(/ /g, "_") === conf.route.toString().replace(/ /g, "_")
					) {
						capability.costs.length = 0;
						conf.billingTypes.map(billingType => {
							capability.costs.push({
								billingType: { from: billingType.from, to: billingType.to },
								currency: "USD",
								paymentFee: 0.0,
								monthlyFee: 0.0,
								notes: ""
							});
						});
					}
				});
			});
		});
	}

	updateHeadcostConfig(formData: HeadcountFormData): void {
		Object.values(formData).filter(hcd => {
			const capabilities = this.paymentTypeCapabilities.find(cap => cap.paymentType === hcd.paymentType);

			capabilities?.capabilities.filter(capability => {
				hcd.config.map(conf => {
					if (
						capability.payoutAccount !== conf.payoutAccount &&
						capability.route.toString().replace(/ /g, "_") !== conf.route.toString().replace(/ /g, "_") &&
						!capability.costs
					) {
						conf.billingTypes.map(billingType => {
							capability.costs.push({
								billingType: { from: billingType.from, to: billingType.to },
								currency: "USD",
								paymentFee: 0.0,
								monthlyFee: 0.0,
								notes: ""
							});
						});
					}
				});
			});
		});
	}

	setCost(form: CostFormData): ServiceProviderCountryCreateDTO {
		const providerCountry: ServiceProviderCountryCreateDTO = {
			providerId: this.provider?.id!,
			paymentCurrencies: this.paymentCurrencies!,
			country: this.country,
			fundingMethods: this.fundingMethods!,
			paymentTypeCapabilities: []
		};
		Object.values(form).filter(data => {
			const capabilities = this.paymentTypeCapabilities.find(
				cap => cap.paymentType.toString().replace(/ /g, "_") === data.paymentType.toString().replace(/ /g, "_")
			);
			capabilities?.capabilities.map(capability => {
				capability.costs.length = 0;

				data.costs.map(cost => {
					if (
						capability.payoutAccount === cost.payoutAccount &&
						capability.route.toString().replace(/ /g, "_") === cost.route.toString().replace(/ /g, "_")
					) {
						let from: number = 0;
						let to: number | null = null;
						if (cost.billingType === "unlimited") {
							capability.costs.push({
								billingType: { from, to },
								currency: cost.currency,
								paymentFee: cost.paymentFee,
								monthlyFee: cost.monthlyFee,
								notes: cost.notes
							});
						} else {
							const billingTypeString = cost.billingType.split("-");
							from = +billingTypeString[0];
							to = billingTypeString[1] === "unlimited" ? null : +billingTypeString[1];
							capability.costs.push({
								billingType: { from: +from, to },
								currency: cost.currency,
								paymentFee: cost.paymentFee,
								monthlyFee: cost.monthlyFee,
								notes: cost.notes
							});
						}
					}
				});

				providerCountry.paymentTypeCapabilities = this.paymentTypeCapabilities.map(type => {
					return {
						paymentType: type.paymentType,
						capabilities: type.capabilities.map(capability => {
							const { route } = capability;
							return {
								...capability,
								route: route.toString().replace(/ /g, "_") as unknown as PaymentRouteName
							};
						})
					};
				});
			});
		});
		return providerCountry;
	}

	updateCost(form: CostFormData): ServiceProviderCountryUpdateDTO {
		const providerCountry: ServiceProviderCountryUpdateDTO = {
			id: this.id!,
			version: this.version!,
			providerId: this.provider?.id!,
			paymentCurrencies: this.paymentCurrencies!,
			fundingMethods: this.fundingMethods!,
			country: this.country,
			paymentTypeCapabilities: []
		};
		Object.values(form).filter(data => {
			const capabilities = this.paymentTypeCapabilities.find(cap => {
				if (cap.paymentType.toString().replace(/ /g, "_") === data.paymentType.toString().replace(/ /g, "_")) {
					return cap;
				} else {
					return null;
				}
			});

			capabilities?.capabilities.filter(capability => {
				capability.costs.length = 0;
				data.costs.map(cost => {
					cost.payoutAccount.toString().replace(/ /g, "_");
					cost.route.toString().replace(/ /g, "_");
					if (capability.payoutAccount === cost.payoutAccount && capability.route === cost.route) {
						let from: number = 0;
						let to: number | null = null;
						if (cost.billingType === "unlimited") {
							capability.costs.push({
								billingType: { from, to },
								currency: cost.currency,
								paymentFee: cost.paymentFee,
								monthlyFee: cost.monthlyFee,
								notes: cost.notes
							});
						} else {
							const billingTypeString = cost.billingType.split("-");
							from = +billingTypeString[0];
							to = billingTypeString[1] === "unlimited" ? null : +billingTypeString[1];
							capability.costs.push({
								billingType: { from: +from, to },
								currency: cost.currency,
								paymentFee: cost.paymentFee,
								monthlyFee: cost.monthlyFee,
								notes: cost.notes
							});
						}
					}
				});
				providerCountry.paymentTypeCapabilities = this.paymentTypeCapabilities.map(type => {
					return {
						paymentType: type.paymentType,
						capabilities: type.capabilities.map(capability => {
							const { route } = capability;
							return {
								...capability,
								route: route.toString().replace(/ /g, "_") as unknown as PaymentRouteName
							};
						})
					};
				});
			});
		});
		return providerCountry;
	}

	getCurrencies(): CurrencyCode[] {
		return this.paymentCurrencies!;
	}

	getFundingMethods(): FundingMethodName[] {
		return this.fundingMethods!;
	}

	setCurrencyAndFunding(currencies: CurrencyCode[], fundingMethods: FundingMethodName[]): void {
		this.paymentCurrencies = currencies;
		this.fundingMethods = fundingMethods;
	}
}
