import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTableModule } from "@angular/material/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { KpiCardsModule } from "@shared/components/wpay-ui/kpi-cards/kpi-cards.module";
import { FlexLayoutModule } from "ngx-flexible-layout";
import { BreadcrumbsModule } from "@shared/components/breadcrumbs/breadcrumbs.module";
import { CustomerLegalEntitySelectModule } from "@shared/components/customer-legal-entity-select/customer-legal-entity-select.module";
import { SlidingPanelModule } from "@shared/components/sliding-panel/sliding-panel.module";
import { TooltipModule } from "@shared/components/tooltip/tooltip.module";
import { SharedModule } from "@shared/shared.module";
import { CalendarModule } from "@shared/components/calendar/calendar.module";
import { CustomerListModule } from "@shared/components/customer-list/customer-list.module";
import { InputAutocompleteModule } from "@shared/components/input-autocomplete/input-autocomplete.module";
import { InputSelectModule } from "@shared/components/input-select/input-select.module";
import { InputWrapperModule } from "@shared/components/input-wrapper/input-wrapper.module";
import { ModalWrapperModule } from "@shared/components/modal-wrapper/modal-wrapper.module";
import { NoItemsLayoutModule } from "@shared/components/no-items-layout/no-items-layout.module";
import { PaginationModule } from "@shared/components/pagination/pagination.module";
import { PaymentTopCardsModule } from "@shared/components/payment-top-cards/payment-top-cards.module";
import { PipesModule } from "../../shared/pipes/pipes.module";
import { DashboardCalendarComponent } from "./_components/dashboard-calendar/dashboard-calendar.component";
import { CalendarEventsModule } from "./_components/dashboard-calendar/event-details-container/calendar-events/calendar-events.module";
import { EventDetailsModule } from "./_components/dashboard-calendar/event-details-container/event-details.module";
import { FileErrorsComponent } from "./_components/file-errors/file-errors.component";
import { ResetPayrollDialogComponent } from "./_components/file-history/_components/reset-payroll-dialog/reset-payroll-dialog.component";
import { FileHistoryComponent } from "./_components/file-history/file-history.component";
import { FileListComponent } from "./_components/file-history/file-list/file-list.component";
import { ListItemComponent } from "./_components/paygroup-list/list-items/list-item/list-item.component";
import { ListItemsComponent } from "./_components/paygroup-list/list-items/list-items.component";
import { PaygroupListComponent } from "./_components/paygroup-list/paygroup-list.component";
import { HistoryListComponent } from "./_components/payment-history/history-list/history-list.component";
import { PaymentHistoryComponent } from "./_components/payment-history/payment-history.component";
import { InactivateToggleDialogComponent } from "./_components/payouts/payout-list-item/inactivate-toggle-dialog/inactivate-toggle-dialog.component";
import { PayoutAccountsListComponent } from "./_components/payouts/payout-list-item/payout-accounts-list/payout-accounts-list.component";
import { PayoutListItemComponent } from "./_components/payouts/payout-list-item/payout-list-item.component";
import { PayoutStatusHistoryDialogComponent } from "./_components/payouts/payout-list-item/payout-status-history-dialog/payout-status-history-dialog.component";
import { PayoutProcessDialogComponent } from "./_components/payouts/payout-process/payout-process-dialog/payout-process-dialog/payout-process-dialog.component";
import { PayoutProcessComponent } from "./_components/payouts/payout-process/payout-process.component";
import { PayoutsComponent } from "./_components/payouts/payouts.component";
import { PaymentsRoutingModule } from "./payments-routing.module";
import { PaymentsComponent } from "./payments.component";
import { TppFileHistoryListItemComponent } from "./tpp-payments/components/tpp-file-history-list-item/tpp-file-history-list-item.component";
import { TppFileHistoryListComponent } from "./tpp-payments/components/tpp-file-history-list/tpp-file-history-list.component";
import { TppPaymentPayoutItemDetailListItemComponent } from "./tpp-payments/components/tpp-payment-payout-item-detail-list-item/tpp-payment-payout-item-detail-list-item.component";
import { TppPaymentPayoutItemDetailListComponent } from "./tpp-payments/components/tpp-payment-payout-item-detail-list/tpp-payment-payout-item-detail-list.component";
import { TppPaymentPayoutListItemComponent } from "./tpp-payments/components/tpp-payment-payout-list-item/tpp-payment-payout-list-item.component";
import { TppPaymentPayoutListComponent } from "./tpp-payments/components/tpp-payment-payout-list/tpp-payment-payout-list.component";
import { TppProcessPaymentsComponent } from "./tpp-payments/components/tpp-process-payments/tpp-process-payments.component";
import { TppSubserviceListItemComponent } from "./tpp-payments/components/tpp-subservice-list-item/tpp-subservice-list-item.component";
import { TppSubserviceListComponent } from "./tpp-payments/components/tpp-subservice-list/tpp-subservice-list.component";
import { ViewSubserviceDetailsListItemComponent } from "./tpp-payments/components/view-subservice-details-list-item/view-subservice-details-list-item.component";
import { ViewSubserviceDetailsListComponent } from "./tpp-payments/components/view-subservice-details-list/view-subservice-details-list.component";
import { ViewSubserviceDetailsSidePopUpComponent } from "./tpp-payments/components/view-subservice-details-side-pop-up/view-subservice-details-side-pop-up.component";
import { TppDataInputComponent } from "./tpp-payments/containers/tpp-data-input/tpp-data-input.component";
import { TppFileHistoryComponent } from "./tpp-payments/containers/tpp-file-history/tpp-file-history.component";
import { TppPaymentsComponent } from "./tpp-payments/containers/tpp-payments/tpp-payments.component";
import { WpayMultiSelectAutoCompleteModule } from "@shared/components/wpay-ui/base-components/wpay-multi-select-auto-complete/wpay-multi-select-auto-complete.module";
import { HistoryLogSidePanelModule } from "@shared/components/history-log-side-panel/history-log-side-panel.module";
import { NetPaymentOrdersModule } from "@modules/net-payment-orders/net-payment-orders.module";

@NgModule({
	declarations: [
		PaymentsComponent,
		PaygroupListComponent,
		ListItemsComponent,
		PaymentHistoryComponent,
		HistoryListComponent,
		DashboardCalendarComponent,
		FileHistoryComponent,
		ResetPayrollDialogComponent,
		FileListComponent,
		FileErrorsComponent,
		PayoutsComponent,
		PayoutProcessComponent,
		ListItemComponent,
		PayoutListItemComponent,
		PayoutAccountsListComponent,
		PayoutStatusHistoryDialogComponent,
		PayoutProcessDialogComponent,
		InactivateToggleDialogComponent,
		TppDataInputComponent,
		TppSubserviceListComponent,
		TppSubserviceListItemComponent,
		TppFileHistoryListComponent,
		TppFileHistoryListItemComponent,
		TppFileHistoryComponent,
		ViewSubserviceDetailsSidePopUpComponent,
		ViewSubserviceDetailsListComponent,
		ViewSubserviceDetailsListItemComponent,
		TppPaymentsComponent,
		TppPaymentPayoutListComponent,
		TppPaymentPayoutListItemComponent,
		TppPaymentPayoutItemDetailListComponent,
		TppPaymentPayoutItemDetailListItemComponent,
		TppProcessPaymentsComponent
	],
	exports: [FileErrorsComponent],
	imports: [
		CommonModule,
		MatIconModule,
		MatButtonModule,
		InputAutocompleteModule,
		InputWrapperModule,
		MatFormFieldModule,
		MatInputModule,
		MatMenuModule,
		FlexLayoutModule,
		PaginationModule,
		FormsModule,
		ReactiveFormsModule,
		CustomerListModule,
		InputSelectModule,
		MatCardModule,
		MatListModule,
		MatSelectModule,
		CustomerLegalEntitySelectModule,
		MatPaginatorModule,
		CalendarModule,
		ModalWrapperModule,
		MatTooltipModule,
		PipesModule,
		MatCheckboxModule,
		SharedModule,
		NoItemsLayoutModule,
		MatSlideToggleModule,
		TooltipModule,
		EventDetailsModule,
		CalendarEventsModule,
		SlidingPanelModule,
		PaymentsRoutingModule,
		BreadcrumbsModule,
		MatTableModule,
		KpiCardsModule,
		PaymentTopCardsModule,
		WpayMultiSelectAutoCompleteModule,
		HistoryLogSidePanelModule,
		NetPaymentOrdersModule
	]
})
export class PaymentsModule {}
