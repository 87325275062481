<div class="pagination-container">
	<span class="label">Items per page</span>

	<input-select
		class="page-drop-down"
		[options]="pageSizes"
		[dynamicValue]="getStringValue(currentPageSize)"
		(onSelectionChange)="updatePageSize($event)"
	>
	</input-select>

	<button
		mat-stroked-button
		(click)="decrementPage()"
		[ngClass]="{ disabled: currentPage === 0 }"
		[disabled]="currentPage === 0"
		class="arrow-button left"
	>
		<app-icon
			class="arrow-pagination"
			[color]="'var(--color-basic-800)'"
			[name]="'arrow-left-1'"
			[lineWidth]="'5px'"
			[size]="'12.5'"
		></app-icon>
	</button>
	<div class="page-number-container">
		<span class="range-label">{{ currentPage + 1 }} of {{ maxPages }}</span>
	</div>
	<button
		mat-stroked-button
		(click)="incrementPage()"
		[disabled]="currentPage === maxPages - 1"
		[ngClass]="{ disabled: currentPage === maxPages - 1 }"
		class="arrow-button right"
	>
		<app-icon
			class="arrow-pagination"
			[color]="'var(--color-basic-800)'"
			[name]="'arrow-right-1'"
			[lineWidth]="'5px'"
			[size]="'12.5'"
		></app-icon>
	</button>
</div>
