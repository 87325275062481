<mat-card *ngIf="!noCountry" class="card-wrapper" (click)="selectCountry()">
	<mat-card-content class="card-body">
		<div class="flag-container">
			<img class="flag" src="assets/svg-country-flags/svg/{{ countryCode | lowercase }}.svg" alt="Country Flag" />
		</div>
		<mat-card-title>{{ countryName }}</mat-card-title>
	</mat-card-content>
</mat-card>
<mat-card *ngIf="noCountry" class="card-wrapper">
	<mat-card-content class="card-body">
		<p class="no-country-text">No created countries yet</p>
	</mat-card-content>
</mat-card>
