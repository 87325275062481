import { Component, EventEmitter, Inject, OnInit, Output } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { PayoutsService } from "@shared/services/payouts/payouts.service";
import { ToastService } from "@shared/services/toast/toast.service";
import { Subject } from "rxjs";
import { ProcessButtonDialogRefData, Transaction } from "src/app/shared/models/payouts.types";

@Component({
	selector: "app-payout-process-dialog",
	templateUrl: "./payout-process-dialog.component.html",
	styleUrls: ["./payout-process-dialog.component.scss"]
})
export class PayoutProcessDialogComponent implements OnInit {
	@Output() processed: EventEmitter<void> = new EventEmitter<void>();
	destroy$: Subject<void> = new Subject();

	reProcesMapPayoutsAndTxId: Map<string, string[]> = new Map();
	allFailedTransaction: Transaction[] = [];
	groupedTransaction: { count: number; status: string }[] = [];

	processPaymentMenuSelection: string = "";
	isReprocessButton: boolean = false;
	bankRoute: string = "";
	cardRoute: string = "";

	isProcessing: boolean = false;

	constructor(
		private toastService: ToastService,
		public dialogRef: MatDialogRef<PayoutProcessDialogComponent>,
		private payoutService: PayoutsService,
		@Inject(MAT_DIALOG_DATA) public data: ProcessButtonDialogRefData
	) {}

	ngOnInit() {
		if (!this.data?.isTPP) {
			this.isReprocessButton = this.data.isReprocessButton;
			this.bankRoute = this.data.bankRoute;
			this.cardRoute = this.data.cardRoute;
			this.reProcesMapPayoutsAndTxId = this.data.reProcesMapPayoutsAndTxId;
			this.allFailedTransaction = this.data.allFailedTransaction;
			//Keep this - important for interation 2 of epic
			//this.groupTransactions(this.data.allFailedTransaction);
		}
		this.processPaymentMenuSelection = this.data.processPaymentMenuSelection;
	}

	groupTransactions(allFailedTransaction: Transaction[]): void {
		allFailedTransaction.forEach(transaction => {
			const index = this.groupedTransaction.findIndex(group => group.status === transaction.status.name);

			if (index > -1) {
				this.groupedTransaction[index].count++;
			} else {
				this.groupedTransaction.push({ count: 1, status: transaction.status.name });
			}
		});
	}

	onNoClick(): void {
		this.dialogRef.close();
	}

	onCancel(): void {
		this.dialogRef.close(false);
	}

	onConfirm(): void {
		this.isProcessing = true;
		if (!this.data.isTPP) {
			if (this.data.isReprocessButton) {
				this.payoutService
					.reProcessPayments(
						this.processPaymentMenuSelection,
						this.bankRoute,
						this.cardRoute,
						this.reProcesMapPayoutsAndTxId
					)
					.subscribe({
						next: _ => {
							this.processed.emit();
							this.toastService.showSuccess("Data has been submitted successfully");
							this.dialogRef.close(true);
							this.isProcessing = false;
						},
						error: err => console.log("we have a problem", err)
					});
			} else {
				this.payoutService
					.processPayments(
						this.data.payCycleId,
						this.data.payGroupId,
						this.processPaymentMenuSelection,
						this.bankRoute,
						this.cardRoute
					)
					.subscribe({
						next: _ => {
							this.processed.emit();
							this.toastService.showSuccess("Data has been submitted successfully");
							this.dialogRef.close(true);
							this.isProcessing = false;
						},
						error: err => console.log("we have a problem", err)
					});
			}
		} else {
			this.dialogRef.close(true);
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
