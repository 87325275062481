<modal-wrapper
	*ngIf="payGroup"
	[title]="pageTitle + ' / ' + payGroup.data.name"
	[subTitle]="payGroup.legalEntity.data.name + ' - ' + payCycleName + ' - Group ' + milestone.group"
	(onTitleButtonAction)="goBack()"
	[addFlag]="payGroup.legalEntity.data.country"
	[titleRows]="2"
	[modalStyle]="'dark'"
	[noBreadCrumbs]="true"
	[exitIconBool]="true"
	[subHeaderColour]="'var(--pending-color)'"
>
	<div class="data-input-container" (mouseleave)="showInputDropDown(true)">
		<div class="button-containers">
			<button
				(click)="loadSubServices(pageSize, pageIndex, milestone.id!, payCycle.id, milestone.group)"
				id="refreshButton"
				class="refresh-button"
				mat-icon-button
			>
				<app-icon
					[name]="'rotate-right'"
					[color]="'var(--color-text-default)'"
					[lineWidth]="'1.5px'"
					[size]="'24'"
				></app-icon>
			</button>

			<div class="ttp-buttons">
				<button class="tpp-files" (click)="goToTppFiles()">
					<app-icon
						class="tpp-files-icon"
						[name]="'eye'"
						[color]="'var(--color-upload-button-dark-grey)'"
						[lineWidth]="'1.5px'"
						[size]="'24'"
					></app-icon>
					<span class="tpp-files-text">TPP FILES</span>
				</button>
				<button
					*ngIf="canUploadFile"
					class="data-input"
					[ngClass]="{ 'data-input-icon-touched': showDropDown }"
					(click)="showInputDropDown(showDropDown)"
				>
					<app-icon
						class="data-input-icon"
						[name]="'document-1'"
						[color]="'var(--color-white)'"
						[lineWidth]="'1.5px'"
						[size]="'24'"
					></app-icon
					><span class="data-input-text">PAYMENT ORDERS</span>
				</button>
			</div>
		</div>
		<div class="data-input-options-container">
			<div class="data-input-options" *ngIf="showDropDown" (mouseleave)="showInputDropDown(showDropDown)">
				<button mat-button class="upload-template dropdown-buttons" (click)="uploader.click()">
					<app-icon
						class="upload-template-icon option-icon"
						[name]="'document-upload'"
						[color]="'var(--color-upload-button-dark-grey)'"
						[lineWidth]="'1.5px'"
						[size]="'24'"
					></app-icon>

					<span class="upload-template-text option-text">Upload template</span>
				</button>
				<!--<mat-divider class="button-divider"></mat-divider>
				<button mat-button class="download-template dropdown-buttons">
					<app-icon
						class="download-template-icon option-icon"
						[name]="'document-download'"
						[color]="'var(--color-upload-button-dark-grey)'"
						[lineWidth]="'1.5px'"
						[size]="'24'"
					></app-icon>
					<span class="download-template-text option-text">Download template</span>
				</button>-->
			</div>
		</div>
	</div>

	<input
		id="uploadButton"
		hidden
		type="file"
		#uploader
		(change)="uploadFile(uploader.files)"
		(click)="uploader.value = ''"
	/>

	<app-tpp-subservice-list
		*ngIf="subServices"
		[_subServices]="subServices"
		(emitOpenPopUp)="viewDetails($event, 10, 0)"
	></app-tpp-subservice-list>

	<no-items-layout
		*ngIf="!subServices || !subServices.length"
		message="No files have been uploaded"
	></no-items-layout>

	<app-view-subservice-details-side-pop-up
		(cancelDetails)="hideDetails(true)"
		(saveDetails)="saveDetails($event)"
		(emitNewPaginition)="paginateEmployees($event)"
		[_subService]="subserviceToView"
		[_pageSize]="popUpPageSize"
		[_pageIndex]="popUpPageIndex"
		[_subserviceToViewDetails]="subserviceToViewDetails"
		*ngIf="showPopup && (subserviceToView || subserviceToViewDetails)"
	></app-view-subservice-details-side-pop-up>
</modal-wrapper>
