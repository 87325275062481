import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Customer } from "../../shared/models/customer.interface";
import { BreadcrumbService } from "@shared/components/breadcrumbs/services/breadcrumb.service";

@Component({
	selector: "app-pay-groups",
	templateUrl: "./pay-groups.component.html",
	styleUrls: ["./pay-groups.component.scss"]
})
export class PayGroupsComponent implements OnInit {
	constructor(private router: Router, private breadCrumbService: BreadcrumbService) {}
	ngOnInit(): void {
		this.breadCrumbService.resetCustomerEntityGroupState();
	}

	customerSelected(customer: Customer) {
		this.router.navigate(["pay-groups/list"]);
	}
}
