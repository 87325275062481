<div class="process-box flex flex-row" [ngClass]="{ 'fixed-process-box': fixedContainer }">
	<div class="filters-container">
		<button (click)="refreshPayouts()" id="refreshButton" class="refresh-button" mat-icon-button>
			<app-icon
				class="refresh-payouts-icon"
				[name]="'rotate-right'"
				[color]="'var(--color-text-default)'"
				[lineWidth]="'1.5px'"
				[size]="'30'"
			></app-icon>
		</button>
		<div class="route-area flex flex-row" *ngIf="isBankListAvailable">
			<div class="route-container flex flex-row flex-1" style="--max-width: 90%">
				<div
					*ngFor="let selectedBankRoute of selectedBankRoutes; index as i"
					class="route-item flex flex-row flex-align-start flex-gap"
					style="--gap: 10px"
				>
					<app-icon
						class="bank-icon flex flex-1"
						[name]="'bank-figma'"
						[lineWidth]="'2'"
						[size]="'20'"
						style="--max-width: 20%"
					></app-icon>
					<span class="route-text flex flex-1" style="--max-width: 60%"
						>{{ selectedBankPAs[i] }} - {{ selectedBankRoute | underscoreRemover }}</span
					>
					<app-icon
						[ngStyle]="{ visibility: canSwitchRoute ? 'visible' : 'hidden' }"
						id="deleteRoleIcon"
						class="close-icon flex flex-align-end flex-1"
						style="--max-width: 20%"
						size="40"
						name="close"
						[color]="'#272833'"
						(click)="deleteSelection(PaymentMethodType.BANK, i)"
					></app-icon>
				</div>
			</div>
			<button
				*ngIf="canSwitchRoute"
				class="drop-down-button flex flex-1"
				style="--max-width: 10%"
				[matMenuTriggerFor]="bankRoutesOptions"
				(menuClosed)="openBankList = false"
				(click)="showList(PaymentMethodType.BANK)"
				[disabled]="!isBankListAvailable"
			>
				<app-icon
					[name]="'arrow-down'"
					[size]="'15'"
					[lineWidth]="'3px'"
					[ngClass]="{ 'up-arrow': openBankList }"
				></app-icon>
			</button>
		</div>

		<div class="route-area flex flex-row" *ngIf="isCardListAvailable">
			<div class="route-container flex flex-row flex-1" style="--max-width: 90%">
				<div
					*ngFor="let selectedCardRoute of selectedCardRoutes; index as i"
					class="route-item-card flex flex-row flex-align-start flex-gap"
					style="--gap: 10px"
				>
					<app-icon
						class="card-icon flex flex-1"
						[name]="'card'"
						[lineWidth]="'2'"
						[size]="'20'"
						style="--max-width: 20%"
					></app-icon>
					<span class="route-text flex flex-1" style="--max-width: 80%"
						>{{ selectedCardPAs[i] }} - {{ selectedCardRoute | underscoreRemover }}</span
					>
					<app-icon
						[ngStyle]="{ visibility: canSwitchRoute ? 'visible' : 'hidden' }"
						id="deleteRoleIcon"
						class="close-icon flex flex-align-end flex-1"
						size="40"
						name="close"
						[color]="'#272833'"
						style="--max-width: 20%"
						(click)="deleteSelection(PaymentMethodType.CARD, i)"
					></app-icon>
				</div>
			</div>
			<button
				class="drop-down-button flex flex-1"
				*ngIf="canSwitchRoute"
				[matMenuTriggerFor]="cardRoutesOptions"
				(menuClosed)="openCardList = false"
				style="--max-width: 10%"
				[disabled]="!isCardListAvailable"
				(click)="showList(PaymentMethodType.CARD)"
			>
				<app-icon
					[name]="'arrow-down'"
					[size]="'15'"
					[lineWidth]="'3px'"
					[ngClass]="{ 'up-arrow': openCardList }"
				></app-icon>
			</button>
		</div>

		<ng-container [formGroup]="form">
			<div class="custom-wrapper">
				<wpay-multi-select-auto-complete
					formControlName="statusPayment"
					id="processStatus"
					label="Select a status"
					[dropDownType]="'statuses'"
					[options]="statusOptions"
					(multipleSelectOptionListClosed)="selectionChange()"
					(selectionCleared)="selectAllStatusses()"
					[defaultValue]="defualtStatusFilterValue.length ? defualtStatusFilterValue : allSelectableValues"
					class="select-input"
				>
				</wpay-multi-select-auto-complete>
			</div>
			<div class="custom-wrapper">
				<input-select
					id="cancelledRuns"
					formControlName="selectedRun"
					[options]="_runSelection"
					[value]="_runSelection[0].value"
					class="select-input"
				>
				</input-select>
			</div>
		</ng-container>
	</div>

	<div class="button-container">
		<ng-template
			[appUserRolePermissions]
			[action]="'hide'"
			[roles]="[
				'TS_IMPLEMENTATION_LEAD',
				'TS_IMPLEMENTATION_CONSULTANT',
				'TS_OPERATION_CONSULTANT',
				'TS_VO',
				'CLIENT'
			]"
		>
			<button
				*ngIf="(_buttonState === 'process' || _buttonState === 'inactive') && canProcessPayment && !isDisabled"
				disabled="{{ _buttonState === 'inactive' }}"
				mat-raised-button
				class="process-button"
				id="processBtn"
				(click)="openDialog('Book Payments', false)"
			>
				<span class="xs-body-xsmall-semibold">PROCESS BOOKING</span>
			</button>
		</ng-template>

		<ng-template
			[appUserRolePermissions]
			[action]="'hide'"
			[roles]="[
				'TS_IMPLEMENTATION_LEAD',
				'TS_IMPLEMENTATION_CONSULTANT',
				'TS_OPERATION_CONSULTANT',
				'TS_VO',
				'CLIENT'
			]"
		>
			<button
				*ngIf="_buttonState === 'reprocess' && !isDisabled"
				disabled="{{ !reProcesMapPayoutsAndTxId.size }}"
				mat-raised-button
				class="process-button"
				id="processBtn"
				[matMenuTriggerFor]="reprocessOptions"
			>
				<app-icon
					class="reprocess-icon"
					[name]="'rotate-right'"
					[lineWidth]="'2px'"
					[size]="'20'"
					[color]="'var(--color-basic-100)'"
				></app-icon>
				<span class="xs-body-xsmall-semibold"> REPROCESS BOOKING</span>
			</button>
		</ng-template>

		<ng-template
			[appUserRolePermissions]
			[action]="'show'"
			[roles]="['TS_OPERATION_CONSULTANT', 'TS_OPERATION_LEAD', 'SUPER_ADMIN']"
		>
			<div class="button-container flex flex-align-end" *ngIf="_buttonState === 'cancel'">
				<button mat-raised-button class="process-button" (click)="cancelBookingClicked.emit()" id="processBtn">
					<span class="xs-body-xsmall-semibold">CANCEL BOOKING</span>
				</button>
			</div>
		</ng-template>

		<!-- Menu Items Process Button -->
		<mat-menu #processOptions class="process-menu">
			<ng-template matMenuContent>
				<!-- To upload Pay Electives -->
				<div *ngFor="let item of processPaymentMenu; last as isLast">
					<button *ngIf="item" (click)="openDialog(item, false)" mat-menu-item>
						<span class="process-button-menu-text"> {{ item | processPayoutMenu }}</span>
					</button>
					<mat-divider *ngIf="!isLast"></mat-divider>
				</div>
			</ng-template>
		</mat-menu>

		<!-- Menu Items ReProcess Button -->
		<mat-menu #reprocessOptions class="process-menu">
			<ng-template matMenuContent>
				<!-- To upload Pay Electives -->
				<div *ngFor="let item of reprocessPaymentMenu; last as isLast">
					<button *ngIf="item" (click)="openDialog(item, true)" mat-menu-item>
						<span class="process-button-menu-text"> {{ item | processPayoutMenu }}</span>
					</button>
					<mat-divider *ngIf="!isLast"></mat-divider>
				</div>
			</ng-template>
		</mat-menu>
	</div>

	<mat-menu #bankRoutesOptions class="bank-routes-menu">
		<ng-template matMenuContent>
			<div *ngFor="let item of bankOptions; index as i; last as isLast">
				<button
					*ngIf="item"
					mat-menu-item
					(click)="addSelection(item.text, bankOptionsPAs[i], PaymentMethodType.BANK)"
				>
					<span class="process-button-menu-text"> {{ bankOptionsPAs[i] }} - {{ item.text }}</span>
				</button>
				<mat-divider *ngIf="!isLast"></mat-divider>
			</div>
		</ng-template>
	</mat-menu>

	<mat-menu #cardRoutesOptions class="bank-routes-menu">
		<ng-template matMenuContent>
			<div *ngFor="let item of cardOptions; index as i; last as isLast">
				<button
					*ngIf="item && cardOptionsPAs[i]"
					mat-menu-item
					(click)="addSelection(item.text, cardOptionsPAs[i], PaymentMethodType.CARD)"
				>
					<span class="process-button-menu-text">{{ cardOptionsPAs[i] }} - {{ item.text }}</span>
				</button>
				<mat-divider *ngIf="!isLast"></mat-divider>
			</div>
		</ng-template>
	</mat-menu>
</div>
