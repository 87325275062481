import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "milestoneTypeString"
})
export class MilestoneTypeStringPipe implements PipeTransform {
	transform(milestone: string): { name: string; icon: string } {
		switch (milestone) {
			case "FUNDING_DATE":
				return { name: "Funding data", icon: "money-recive" };
				break;
			case "G2N_FILE":
				return { name: "Payment Orders", icon: "document-copy" };
				break;
			case "PAYMENT":
				return { name: "Booking Date", icon: "wallet-add" };
				break;
			case "DATA_INPUT":
				return { name: "Payment Orders", icon: "wallet-add" };
				break;
			case "NETS":
				return { name: "Net Payment", icon: "document-copy" };
				break;
		}

		return { name: "", icon: "" };
	}
}
