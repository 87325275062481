import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatPaginatorModule } from "@angular/material/paginator";
import { CustomerLegalEntitySelectModule } from "@shared/components/customer-legal-entity-select/customer-legal-entity-select.module";
import { CustomerListModule } from "@shared/components/customer-list/customer-list.module";
import { NoItemsLayoutModule } from "@shared/components/no-items-layout/no-items-layout.module";
import { PayElectiveListItemComponent } from "./_components/pay-elective-master-data/pay-elective-list-item/pay-elective-list-item.component";
import { PayElectiveMasterDataComponent } from "./_components/pay-elective-master-data/pay-elective-master-data.component";
import { PayElectivePayGroupsComponent } from "./_components/pay-elective-pay-groups/pay-elective-pay-groups.component";
import { PayElectiveComponent } from "./_components/pay-elective/pay-elective.component";
import { EmployeeDataComponent } from "./employee-data.component";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTabsModule } from "@angular/material/tabs";
import { FlexLayoutModule } from "ngx-flexible-layout";
import { InputSelectModule } from "@shared/components/input-select/input-select.module";
import { InputWrapperModule } from "@shared/components/input-wrapper/input-wrapper.module";
import { PayElectiveListSearchComponent } from "./_components/pay-elective-master-data/pay-elective-list-search/pay-elective-list-search.component";
import { PayElectiveBankAccountsComponent } from "./_components/pay-elective/pay-elective-bank-accounts/pay-elective-bank-accounts.component";
import { PayElectiveCreateBankAccountComponent } from "./_components/pay-elective/pay-elective-bank-accounts/pay-elective-create-bank-account/pay-elective-create-bank-account.component";
import { PayElectiveEmployeeComponent } from "./_components/pay-elective/pay-elective-employee/pay-elective-employee.component";

import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatTableModule } from "@angular/material/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BankAccountFieldsModule } from "@shared/components/bank-account-fields/bank-account-fields.module";
import { ModalWrapperModule } from "@shared/components/modal-wrapper/modal-wrapper.module";
import { TooltipModule } from "@shared/components/tooltip/tooltip.module";
import { SharedModule } from "@shared/shared.module";
import { PipesModule } from "src/app/shared/pipes/pipes.module";
import { EntityPaygroupSelectDialogModule } from "@shared/components/entity-paygroup-select-dialog/entity-paygroup-select-dialog.module";
import { InputAutocompleteModule } from "@shared/components/input-autocomplete/input-autocomplete.module";
import { PaginationModule } from "@shared/components/pagination/pagination.module";
import { PayElectiveAccountsListComponent } from "./_components/pay-elective-master-data/pay-elective-list-item/pay-elective-accounts-list/pay-elective-accounts-list.component";
import { PayElectiveInactivePaymentMethodsListComponent } from "./_components/pay-elective/pay-elective-bank-accounts/pay-elective-inactive-payment-methods-list/pay-elective-inactive-payment-methods-list.component";
import { PayElectiveTransactionComponent } from "./_components/pay-elective/pay-elective-transaction/pay-elective-transaction.component";

import { MatMenuModule } from "@angular/material/menu";
import { MatSelectModule } from "@angular/material/select";
import { PayElectivePayGroupListItemComponent } from "./_components/pay-elective-pay-groups/pay-elective-pay-group-list-items/pay-elective-pay-group-list-item/pay-elective-pay-group-list-item.component";
import { PayElectivePayGroupListItemsComponent } from "./_components/pay-elective-pay-groups/pay-elective-pay-group-list-items/pay-elective-pay-group-list-items.component";
import { EmployeeTransactionsFallbackPipe } from "./pipes/employee-transactions-fallback.pipe";

import { BreadcrumbsModule } from "@shared/components/breadcrumbs/breadcrumbs.module";
import { WpayAutoCompleteModule } from "@shared/components/wpay-ui/base-components/wpay-auto-complete/wpay-auto-complete.module";
import { PayElectivePaymentMethodsListComponent } from "./_components/pay-elective/pay-elective-bank-accounts/pay-elective-payment-methods-list/pay-elective-payment-methods-list.component";
import { PayElectiveTransactionCalculationComponent } from "./_components/pay-elective/pay-elective-transaction/pay-elective-transaction-list/pay-elective-transaction-calculation/pay-elective-transaction-calculation.component";
import { PayElectiveTransactionListComponent } from "./_components/pay-elective/pay-elective-transaction/pay-elective-transaction-list/pay-elective-transaction-list.component";
import { EmployeeDataRoutingModule } from "./employee-data-routing.module";

@NgModule({
	declarations: [
		EmployeeDataComponent,
		PayElectivePayGroupsComponent,
		PayElectiveComponent,
		PayElectiveMasterDataComponent,
		PayElectiveListItemComponent,
		PayElectiveListSearchComponent,
		PayElectiveEmployeeComponent,
		PayElectiveBankAccountsComponent,
		PayElectiveCreateBankAccountComponent,
		PayElectiveAccountsListComponent,
		PayElectivePaymentMethodsListComponent,
		PayElectiveInactivePaymentMethodsListComponent,
		PayElectiveTransactionComponent,
		PayElectiveTransactionListComponent,
		PayElectiveTransactionCalculationComponent,
		EmployeeTransactionsFallbackPipe,
		PayElectivePayGroupListItemsComponent,
		PayElectivePayGroupListItemComponent
	],
	imports: [
		CommonModule,
		InputWrapperModule,
		MatInputModule,
		MatFormFieldModule,
		FlexLayoutModule,
		FormsModule,
		ReactiveFormsModule,
		InputSelectModule,
		InputAutocompleteModule,
		CustomerListModule,
		CustomerLegalEntitySelectModule,
		NoItemsLayoutModule,
		MatIconModule,
		MatDividerModule,
		MatCardModule,
		MatButtonModule,
		MatSlideToggleModule,
		MatTabsModule,
		MatDatepickerModule,
		MatCheckboxModule,
		MatTableModule,
		BankAccountFieldsModule,
		EntityPaygroupSelectDialogModule,
		PaginationModule,
		MatPaginatorModule,
		MatTooltipModule,
		DragDropModule,
		PipesModule,
		SharedModule,
		ModalWrapperModule,
		TooltipModule,
		MatMenuModule,
		MatSelectModule,
		WpayAutoCompleteModule,
		EmployeeDataRoutingModule,
		BreadcrumbsModule
	]
})
export class EmployeeDataModule {}
