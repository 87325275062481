import { Pipe, PipeTransform } from "@angular/core";
import { DefinitionRoute } from "@modules/service-definition/_models/definitions-by-type.interface";
import {
	AccountsRoutesDefinitions,
	PaymentTypeCapabilities,
	PayoutAccountRoutesDefinition
} from "src/app/shared/models/service-provider.interface";

@Pipe({
	name: "paymentType"
})
export class PaymentTypePipe implements PipeTransform {
	transform(
		definition: DefinitionRoute,
		paymentType: string,
		paymentsTypeCapabillities: PaymentTypeCapabilities[]
	): string {
		let show = "none";

		const payoutAccount: string = definition.payoutAccount ? definition.payoutAccount : "";
		const paymentTypeElement: PaymentTypeCapabilities[] = paymentsTypeCapabillities.filter(
			(item: PaymentTypeCapabilities) => item.paymentType == paymentType
		);

		if (paymentTypeElement.length > 0) {
			const payoutAccountRoutes: PayoutAccountRoutesDefinition = paymentTypeElement[0].payoutAccountRoutes;
			const routeList: AccountsRoutesDefinitions[] = payoutAccountRoutes[payoutAccount];
			const route: AccountsRoutesDefinitions[] = routeList?.filter(
				(item: AccountsRoutesDefinitions) => item.name === definition.route
			);
			if (route.length > 0) show = route[0].paymentMethodType;
		}

		return show.toLocaleLowerCase();
	}
}
