import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, FormArray, AbstractControl } from "@angular/forms";
import { Observable, of, Subject } from "rxjs";
import { takeUntil, debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { ToastService } from "../../../../../shared/services/toast/toast.service";
import {
	BillingType,
	ServiceProviderCountryCapability
} from "../../../../../shared/models/service-provider-country-types";
import { PaymentTypeName } from "../../../../../shared/models/service-provider.type";
import { ServiceProviderCountryEditService } from "../../../_services/service-provider-country-edit/service-provider-country-edit.service";

@Component({
	selector: "app-headcount-configuration-edit",
	templateUrl: "./headcount-configuration-edit.component.html",
	styleUrls: ["./headcount-configuration-edit.component.scss"]
})
export class HeadcountConfigurationEditComponent implements OnInit, OnDestroy {
	@Input() isSuperUser: boolean = false;
	createdCapabilites$: Observable<ServiceProviderCountryCapability[]> = of([]);
	destroy$: Subject<void> = new Subject();
	headcountForm!: FormGroup;

	constructor(
		private readonly formBuilder: FormBuilder,
		private readonly serviceProviderCountryEditService: ServiceProviderCountryEditService,
		private readonly toastService: ToastService
	) {}

	ngOnInit(): void {
		this.initForm();
		this.initSubscriptions();
	}

	initForm(): void {
		this.headcountForm = this.formBuilder.group({});
	}

	initSubscriptions(): void {
		this.createdCapabilites$ = this.serviceProviderCountryEditService.updateCapabilityData$.pipe(
			map(() => {
				this.createCapabilityGroups();
				return this.serviceProviderCountryEditService.getPaymentTypeCapabilities();
			})
		);

		this.headcountForm.valueChanges
			.pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged())
			.subscribe(() => {
				this.serviceProviderCountryEditService.updateCostsForm(this.headcountForm.getRawValue());
			});
	}

	createCapabilityGroups(): void {
		this.serviceProviderCountryEditService?.getPaymentTypeCapabilities()?.filter(capabilityData => {
			const control = this.headcountForm.get(capabilityData.paymentType.toString())?.value;

			if (control) {
				this.headcountForm.removeControl(capabilityData.paymentType.toString());
			}

			const group = this.formBuilder.group({
				paymentType: capabilityData.paymentType,
				config: this.formBuilder.array([])
			});

			capabilityData.capabilities.filter(capability => {
				const configArray = group.get("config") as FormArray;
				let billingTypes: FormArray = this.formBuilder.array([]);

				// Format Billing Types
				capability.costs.filter(cost => {
					billingTypes.push(
						this.formBuilder.group({
							from: [{ value: cost.billingType?.from, disabled: !this.isSuperUser }],
							to: [{ value: cost.billingType?.to, disabled: !this.isSuperUser }]
						})
					);
				});

				// Add Billing Types to form
				let configControl: AbstractControl = this.formBuilder.group({
					payoutAccount: capability.payoutAccount,
					route: capability.route,
					billingTypes: billingTypes
				});

				configArray.push(configControl);
				this.headcountForm.addControl(capabilityData.paymentType.toString(), group);
			});
		});
	}

	getConfigFormGroupArray(paymentType: PaymentTypeName) {
		return this.headcountForm.get(`${paymentType}.config`) as FormArray;
	}

	getBillingTypesFormGroupArray(paymentType: PaymentTypeName, index: number): FormArray {
		const controls = this.getConfigFormGroupArray(paymentType).at(index) as FormArray;
		return controls.get("billingTypes") as FormArray;
	}

	getAccountRoute(paymentType: PaymentTypeName, index: number) {
		const payoutRoute = this.getConfigFormGroupArray(paymentType).at(index)?.value;
		return `${payoutRoute.payoutAccount} > ${payoutRoute.route.toString().replace("_", " ")}`;
	}

	isFixed(paymentType: PaymentTypeName, index: number, headcountIndex: number): boolean {
		return !!this.getBillingTypesFormGroupArray(paymentType, index)?.at(headcountIndex).value.to;
	}

	addConfig(paymentType: PaymentTypeName, index: number): void {
		const headCountArray = this.getBillingTypesFormGroupArray(paymentType, index) as FormArray;
		const currentHeadCount = headCountArray.value;
		const newFromValue: { from: number; to: number } = currentHeadCount.pop();

		if (!newFromValue.to) {
			this.toastService.showWarning("Please set a 'TO' value before adding a headcount configuration");
			return;
		}

		this.serviceProviderCountryEditService.getPaymentTypeCapabilities().filter(cap => {
			if (cap.paymentType === paymentType) {
				const test = this.getConfigFormGroupArray(paymentType).at(index).value;
				cap.capabilities.filter(v => {
					if (v.payoutAccount === test.payoutAccount && v.route === test.route) {
						let last: BillingType = v.costs.slice(-1)[0].billingType!;
						last.to = newFromValue.to;
						v.costs.push({
							billingType: { from: newFromValue.to + 1, to: null },
							currency: "USD",
							paymentFee: 0.0,
							monthlyFee: 0.0,
							notes: ""
						});
					}
				});
			}
		});

		headCountArray.push(
			this.formBuilder.group({
				from: [newFromValue.to + 1],
				to: [null]
			})
		);
	}

	removeConfig(paymentType: PaymentTypeName, index: number): void {
		const headCountArray: FormArray = this.getBillingTypesFormGroupArray(paymentType, index) as FormArray;
		const currentHeadCount = headCountArray.value;
		const latFormValue: { from: number; to: number } = currentHeadCount.pop();

		if (latFormValue.to) {
			this.toastService.showWarning("Please unset the 'TO' value before removing a headcount configuration");
			return;
		}

		this.serviceProviderCountryEditService.getPaymentTypeCapabilities().filter(cap => {
			if (cap.paymentType === paymentType) {
				const test = this.getConfigFormGroupArray(paymentType).at(index).value;
				cap.capabilities.filter(v => {
					if (v.payoutAccount === test.payoutAccount && v.route === test.route) {
						// Remove cost from cost array
						v.costs.pop();
						// Set last 'TO' as unlimited
						v.costs[v.costs.length - 1].billingType!.to = null;
					}
				});
			}
		});

		// Change values of form
		let newValue = headCountArray.value;
		newValue[newValue.length - 1].to = null;
		headCountArray.patchValue(newValue);

		// Delete last control
		headCountArray.removeAt(headCountArray.controls.length - 1);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
