<div class="popup">
	<div class="title">Edit recurring event</div>

	<mat-radio-group class="radio-group" [(ngModel)]="recurring">
		<mat-radio-button color="primary" value="THIS">This pay cycle</mat-radio-button>
		<mat-radio-button color="primary" value="FOLLOWING">This and the following pay cycles</mat-radio-button>
		<mat-radio-button color="primary" value="ALL">All pay cycles</mat-radio-button>
	</mat-radio-group>

	<div class="cta-container">
		<div class="cancel-button" (click)="cancel()">Cancel</div>
		<div class="save-button" (click)="save()">OK</div>
	</div>
</div>
