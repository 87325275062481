import { Injectable } from "@angular/core";
import {
	ApiTPPServiseDefinitionsSearchResponseV1,
	ServiceService,
	SubService as ApiSubService,
	Beneficiary,
	ServiceElement,
	ProviderStatus,
	SettlementAccountStatus
} from "../../../api/payment-providers/tpp-service-definition/model/response/ApiTppServiceDefinitionsSearchResponseV1";
import {
	BeneficiaryAccountAggregate,
	BeneficiaryProviderAssignment,
	SubServiceAggregate,
	SubServiceSummaryByProviderAggregate,
	TtpServiceAggregate,
	TtpServiceByServiceTypeAggregate
} from "../facade/model/TppServicesByByPayGroupAggregation";
import { GroupConfig } from "@modules/service-definition/_models/defintion-group";
import { group } from "@angular/animations";

interface FlatternedApiResponse {
	service: ServiceService;
	subService: ApiSubService;
	beneficiary: Beneficiary | null;
	group: string | null;
	groupConfig: GroupConfig[];
}

@Injectable({
	providedIn: "root"
})
export class TppServiceByServiceTypeMapperService {
	constructor() {}

	map(response: ApiTPPServiseDefinitionsSearchResponseV1): TtpServiceByServiceTypeAggregate[] {
		return this.toServiceByServiceType(response);
	}

	private toServiceByServiceType(
		response: ApiTPPServiseDefinitionsSearchResponseV1
	): TtpServiceByServiceTypeAggregate[] {
		var serviceByServiceType = [] as TtpServiceByServiceTypeAggregate[];
		var aggregate: Map<string, FlatternedApiResponse[]> = this.aggregateByServiceType(response);
		aggregate.forEach((serviceTypeAggregates: FlatternedApiResponse[], serviceType: string) => {
			var mapped = this.mapToServiceByServiceType(serviceType, serviceTypeAggregates);
			serviceByServiceType.push(mapped);
		});

		return serviceByServiceType;
	}

	private aggregateByServiceType(
		response: ApiTPPServiseDefinitionsSearchResponseV1
	): Map<string, FlatternedApiResponse[]> {
		var aggregation = new Map<string, FlatternedApiResponse[]>();

		response.items.forEach(item => {
			item.services.forEach(serviceElement => {
				const SERVICE_TYPE = serviceElement.service.type;
				var aggregate = this.flatten(serviceElement);

				if (aggregation.get(SERVICE_TYPE)) {
					var existingServiceType = aggregation.get(SERVICE_TYPE);
					existingServiceType?.push(aggregate);
				} else {
					aggregation.set(SERVICE_TYPE, [aggregate]);
				}

				aggregate.groupConfig = response.items[0].groupConfig;
			});
		});

		return aggregation;
	}

	private flatten(serviceElement: ServiceElement): FlatternedApiResponse {
		var aggregate = {} as FlatternedApiResponse;
		aggregate.service = serviceElement.service;
		aggregate.subService = serviceElement.subService;
		aggregate.beneficiary = serviceElement.beneficiary?.beneficiaryId ? serviceElement.beneficiary : null;
		aggregate.group = serviceElement.group;
		return aggregate;
	}

	private mapToServiceByServiceType(
		serviceType: string,
		flatResponse: FlatternedApiResponse[]
	): TtpServiceByServiceTypeAggregate {
		var service = {} as TtpServiceByServiceTypeAggregate;
		service.serviceType = serviceType;
		service.services = [];

		var serviceIdResponseMap: Map<String, FlatternedApiResponse[]> = this.aggregateByServiceId(flatResponse);

		var uniqueServiceIds = new Set<String>();
		flatResponse.forEach(item => {
			uniqueServiceIds.add(item.service.id);
		});

		uniqueServiceIds.forEach(serviceId => {
			var matchingItems = serviceIdResponseMap.get(serviceId);

			if (matchingItems) {
				// 1 ttp service has many sub services
				// its fine to grab the first item as all sub services are related to this service
				const FIRST_ITEM_SERVICE = matchingItems[0].service;
				var ttpService: TtpServiceAggregate = {} as TtpServiceAggregate;
				ttpService.id = FIRST_ITEM_SERVICE.id;
				ttpService.name = FIRST_ITEM_SERVICE.name;
				ttpService.description = FIRST_ITEM_SERVICE.description;
				ttpService.subService = [];
				ttpService.subServiceSummaryByProviderName = [];

				// Assumes that adhoc is defined at service level.
				ttpService.isAdhoc = FIRST_ITEM_SERVICE.payGroupId != null;

				// Add sub services
				matchingItems.forEach(flatResponse => {
					const ITEM_SUB_SERVICE = flatResponse.subService;
					const SERVICE_GROUP = this.toNumber(flatResponse.group);
					const IS_CONFIGURED = flatResponse.beneficiary?.beneficiaryId != null && SERVICE_GROUP != null;
					const IS_PARTIALLY_CONFIGURED =
						flatResponse.beneficiary?.beneficiaryId != null || SERVICE_GROUP != null;

					var beneficiary = {} as BeneficiaryAccountAggregate;

					if (flatResponse.beneficiary?.beneficiaryId != null) {
						beneficiary.id = flatResponse.beneficiary.beneficiaryId;
						beneficiary.providers = this.mapBeneficiaryProviders(flatResponse);
						beneficiary.linkId = flatResponse.beneficiary.id ? flatResponse.beneficiary.id : "";
					}

					var subService = {
						beneficiary: beneficiary,
						isConfigured: IS_CONFIGURED,
						isPartiallyConfigured: IS_PARTIALLY_CONFIGURED,
						group: SERVICE_GROUP,
						name: ITEM_SUB_SERVICE.name
					} as SubServiceAggregate;

					ttpService.subService.push(subService);
				});

				// calculate the groups aggregation
				var groups = new Set<number>();
				ttpService.subService.forEach(subService => {
					// regardless of whether a sub service has both a beneficiary and group
					// list it in the summary.
					if (subService.group != null) groups.add(subService.group);
				});
				// sort groups ascending
				ttpService.ttpGroups = Array.from(groups).sort((a, b) => a - b);
				service.services.push(ttpService);
			}
		});

		return service;
	}

	private mapBeneficiaryProviders(flatResponse: FlatternedApiResponse): BeneficiaryProviderAssignment[] {
		var beneficiaryProviderAssignments = [] as BeneficiaryProviderAssignment[];

		//COMMENTED OUT FOR REVIEWING PURPOSES:
		// flatResponse.beneficiary?.providerStatuses.forEach((provider: ProviderStatus) => {
		// 	flatResponse.groupConfig.forEach((groupConfig: GroupConfig) => {
		// 		const GROUP_CONFIG_SETTLEMENT_ACCOUNT_ID = groupConfig.settlementAccountId;

		// 		provider.settlementAccountStatuses.forEach((settlementAccountStatus: SettlementAccountStatus) => {
		// 			if (
		// 				settlementAccountStatus.settlementAccountId == GROUP_CONFIG_SETTLEMENT_ACCOUNT_ID &&
		// 				flatResponse.group == groupConfig.group
		// 			) {
		// 				beneficiaryProviderAssignments.push({
		// 					status: settlementAccountStatus.status,
		// 					providerName: provider.providerName,
		// 					settlementAccountId: settlementAccountStatus.settlementAccountId,
		// 					detials: settlementAccountStatus.details
		// 				} as BeneficiaryProviderAssignment);
		// 			}
		// 		});
		// 	});
		// });

		// get group from flatty
		const flatResponseGroup = flatResponse.group;

		// use group to find groupconfig
		const groupIndex = flatResponse.groupConfig.findIndex(groupConfig => {
			return groupConfig.group === flatResponseGroup;
		});

		// if groupconfig exists
		if (groupIndex > -1) {
			// find provider status with provider that matches groupconfig finding providername
			const providerStatusIndex = flatResponse.beneficiary?.providerStatuses.findIndex(status => {
				return status.providerName === flatResponse.groupConfig[groupIndex].providerName;
			});

			// for loop provider statuses' settlement account statuses to
			// find settlement account id that matches groupconfig finding's settlement account??

			beneficiaryProviderAssignments.push({
				status: flatResponse.beneficiary!.providerStatuses[providerStatusIndex!].settlementAccountStatuses[0]
					.status,
				providerName: flatResponse.beneficiary!.providerStatuses[providerStatusIndex!].providerName,
				settlementAccountId:
					flatResponse.beneficiary!.providerStatuses[providerStatusIndex!].settlementAccountStatuses[0]
						.settlementAccountId,
				detials:
					flatResponse.beneficiary!.providerStatuses[providerStatusIndex!].settlementAccountStatuses[0]
						.details
			} as BeneficiaryProviderAssignment);
		}

		return beneficiaryProviderAssignments;
	}

	private aggregateByServiceId(flatResponse: FlatternedApiResponse[]): Map<String, FlatternedApiResponse[]> {
		var aggregateByServiceId = new Map<String, FlatternedApiResponse[]>();

		flatResponse.forEach((value: FlatternedApiResponse) => {
			const CURRENT_SERVICE_ID = value.service.id;
			if (aggregateByServiceId.get(CURRENT_SERVICE_ID)) {
				var existingBySeviceIdAgg = aggregateByServiceId.get(CURRENT_SERVICE_ID);
				existingBySeviceIdAgg?.push(value);
			} else {
				aggregateByServiceId.set(CURRENT_SERVICE_ID, [value]);
			}
		});

		return aggregateByServiceId;
	}

	private toNumber(groupName: string | null): number | null {
		if (!groupName) return null; // SHOULD NEVER HAPPEN?

		const DIGIT_CAPTURING_GROUP = groupName.match(/\d+/);
		if (DIGIT_CAPTURING_GROUP && DIGIT_CAPTURING_GROUP.length > 0) {
			return parseInt(DIGIT_CAPTURING_GROUP[0]);
		}

		// this should never happen!
		return -1;
	}
}
