import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
	AbstractControl,
	FormBuilder,
	FormControl,
	FormGroup,
	ValidationErrors,
	ValidatorFn,
	Validators
} from "@angular/forms";
import { Observable, Subject, Subscription } from "rxjs";
import { map, shareReplay, take, takeUntil } from "rxjs/operators";

import { BankAccountFieldsComponent } from "@shared/components/bank-account-fields/bank-account-fields.component";
import { ToastService } from "@shared/services/toast/toast.service";
import { Employee } from "@shared/models/employee.interface";
import { BankAccountsService } from "@shared/services/bank-accounts/bank-accounts.service";
import { CountriesService } from "@shared/services/countries/countries.service";
import { CurrencyService } from "@shared/services/currency/currency.service";
import { SelectOption } from "@shared/models/select-option.interface";
import { PayElectiveBankAccountsService } from "@modules/employee-data/services/pay-elective-bank-accounts.service";
import { PayElectiveCommonService } from "@modules/employee-data/services/pay-elective-common.service";
import { PayMethodDetail, PaymentType } from "@modules/employee-data/types/accounts";
import { PermissionsService } from "@shared/services/permissions/permissions.service";

@Component({
	selector: "app-pay-elective-create-bank-account",
	templateUrl: "./pay-elective-create-bank-account.component.html",
	styleUrls: ["./pay-elective-create-bank-account.component.scss"]
})
export class PayElectiveCreateBankAccountComponent implements OnInit, OnDestroy {
	@Input() bankAccountDetails!: PayMethodDetail | null;
	@Input() newBankAccount: boolean = true;
	@Input() country!: string;
	@Input() selectedEmployee!: Employee;
	@Input() selectedPayGroupId!: string;
	@Input() payMethodsLength!: number;
	@Input() providerName!: string;
	@Input() paymentMethods!: Observable<PayMethodDetail[]>;
	@Input() supportedPayMethodTypes!: string[];

	paymentMethodsArray!: PayMethodDetail[];
	accountRank!: number;
	isTypeNet!: boolean;
	newBankAccountForm!: FormGroup;
	bankAccountValues!: Record<string, string | number | boolean>;
	destroy$: Subject<void> = new Subject();
	bankNameSubscription: Subscription = new Subscription();
	bankName: string = "";

	countries$: Observable<SelectOption[]> = new Observable<SelectOption[]>();
	currencies$: Observable<SelectOption[]> = new Observable<SelectOption[]>();

	rankingAmount!: number;
	rankingRank!: number;
	isAllRemaining: boolean = false;

	defaultCountry!: SelectOption;
	defaultCurrency!: SelectOption;

	formIsPristine: boolean = true;

	isToTPPUpdate!: boolean;
	isToNetUpdate: boolean = false;

	isCardUpdate: boolean = false;

	displayRanking: boolean = false;
	selectedPaymentMethod!: PayMethodDetail;

	rankingValueTypes: SelectOption[] = [
		{ text: "Amount", value: "AMOUNT" },
		{ text: "Percentage", value: "PERCENTAGE" },
		{ text: "Percentage Remaining", value: "PERCENTAGE_REMAINING" }
	];

	rankingValueTypeAllRemaining: SelectOption = { text: "All Remaining", value: "ALL_REMAINING" };

	rankingValueType!: SelectOption;

	updateToStatus: string = "";

	canCreateBankAccount: boolean = false;

	fallback: PaymentType = PaymentType.FALLBACK;
	//ViewChild
	@ViewChild(BankAccountFieldsComponent) bankAccountFieldsComponent: BankAccountFieldsComponent | undefined;

	constructor(
		private formBuilder: FormBuilder,
		private payElectiveBankAccountsService: PayElectiveBankAccountsService,
		private countryService: CountriesService,
		private currencyService: CurrencyService,
		private bankAccountService: BankAccountsService,
		private payElectiveCommonService: PayElectiveCommonService,
		private toastService: ToastService,
		private permissionsService: PermissionsService
	) {}

	ngOnInit(): void {
		this.permissionsService
			.canCreateEmployeeBankAccount()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => (this.canCreateBankAccount = res));

		this.getPaymentMethods();
		this.initializeForm();
		this.getCountries();
		this.getCurrencies();
		this.subscribeToBankAccount();
	}

	getPaymentMethods() {
		this.paymentMethods.pipe(takeUntil(this.destroy$)).subscribe(data => {
			this.paymentMethodsArray = data.sort((a, b) =>
				a.payMethod.ranking!.rank < b.payMethod.ranking!.rank ? -1 : 1
			);

			console.log("Pay method");
			console.log(this.paymentMethodsArray);
		});
	}

	initializeForm() {
		this.newBankAccountForm = this.formBuilder.group({
			paymentTypes: this.formBuilder.group({
				net: [false],
				tpp: [false],
				ewa: [false],
				fallback: [false]
			}),

			currency: [
				{ value: "", disabled: !this.newBankAccount || !this.canCreateBankAccount },
				Validators.required
			],
			country: [{ value: "", disabled: !this.newBankAccount || !this.canCreateBankAccount }, Validators.required],
			effectiveFrom: ["", Validators.required],
			effectiveTo: [""]
		});

		if (!this.canCreateBankAccount) {
			this.newBankAccountForm?.disable();
		}

		this.newBankAccountForm
			.get("country")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe((newCountry: string) => {
				if (newCountry) {
					newCountry ? this.bankAccountService.countryChanged$.emit(newCountry) : void 0;
					this.country = newCountry;
					if (this.bankAccountDetails?.details?.country !== this.country) {
						this.bankAccountValues = {};
						this.newBankAccountForm.get("paymentTypes.net")?.patchValue(false);
						this.newBankAccountForm.get("paymentTypes.tpp")?.patchValue(false);
						this.newBankAccountForm.get("paymentTypes.ewa")?.patchValue(false);
						this.newBankAccountForm.get("paymentTypes.fallback")?.patchValue(false);
						this.newBankAccountForm.get("effectiveFrom")?.patchValue("");
						this.newBankAccountForm.get("effectiveTo")?.patchValue("");
						this.newBankAccountForm.get("currency")?.patchValue("");
						// this.newBankAccountForm.get("country")?.patchValue(newCountry, { emitEvent: false });
						this.getCountries();
					}
				}
			});

		this.newBankAccountForm.valueChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => (this.formIsPristine = this.newBankAccountForm.pristine));

		this.bankAccountFieldsComponent?.bankAccountForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(val => {
			this.formIsPristine = this.bankAccountFieldsComponent?.bankAccountForm.pristine!;
		});

		this.newBankAccountForm
			.get("paymentTypes.net")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe(val => {
				this.isTypeNet = val;

				if (this.isTypeNet === true) {
					this.newBankAccountForm.addControl("rankingValueType", new FormControl("", Validators.required));
					this.newBankAccountForm.addControl(
						"rankingAmount",
						new FormControl(this.rankingAmount, [Validators.required, this.percentageValidator()])
					);
					this.newBankAccountForm.addControl("rank", new FormControl(this.rankingRank));

					this.initializeRankingValueType();
					this.subscribeToRankingValueType();
				} else {
					this.newBankAccountForm.removeControl("rankingValueType");
					this.newBankAccountForm.removeControl("rankingAmount");
					this.newBankAccountForm.removeControl("rank");
				}
			});

		this.newBankAccountForm
			.get("effectiveTo")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe(val => {
				const netCheckBox = this.newBankAccountForm.get("paymentTypes.net")?.value;
				const tppCheckBox = this.newBankAccountForm.get("paymentTypes.tpp")?.value;
				const ewaCheckBox = this.newBankAccountForm.get("paymentTypes.ewa")?.value;
				const fallback = this.newBankAccountForm.get("paymentTypes.fallback")?.value;
				this.updateToStatus = this.payElectiveCommonService.updateToStatus(
					val,
					netCheckBox,
					tppCheckBox,
					ewaCheckBox,
					fallback
				);
			});

		this.initializeFormWithValue();
	}

	percentageValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const rankingValueTypeControl = control?.parent?.get("rankingValueType");

			if (
				rankingValueTypeControl?.value === "PERCENTAGE" ||
				(rankingValueTypeControl?.value === "PERCENTAGE_REMAINING" && control.value)
			) {
				if (control?.value > 100 || control?.value < 1) {
					return { invalidPercentage: true };
				}
			}

			return null;
		};
	}

	initializeRankingValueType() {
		if (this.bankAccountDetails?.payMethod.ranking?.type) {
			if (this.bankAccountDetails?.payMethod.ranking?.type === "ALL_REMAINING") {
				this.rankingValueType = this.rankingValueTypeAllRemaining;
				this.newBankAccountForm.get("rankingValueType")?.patchValue(this.rankingValueType.value);
			} else {
				const findMatchingType = this.rankingValueTypes.find(x => {
					return x.value === this.bankAccountDetails?.payMethod.ranking?.type;
				});
				this.rankingValueType = findMatchingType!;
				this.newBankAccountForm.get("rankingValueType")?.patchValue(this.rankingValueType.value);
			}
		} else {
			if (this.paymentMethodsArray.length === 0) {
				this.isAllRemaining = true;
				this.rankingRank = 0;

				this.newBankAccountForm.get("rankingAmount")?.patchValue(0);
				this.newBankAccountForm.get("rankingAmount")?.disable();

				this.rankingValueType = this.rankingValueTypeAllRemaining;
				this.newBankAccountForm.get("rankingValueType")?.patchValue(this.rankingValueType.value);

				this.newBankAccountForm.get("rank")?.setValue(0, { emitEvent: false });
			} else {
				this.rankingValueType = this.rankingValueTypes[0];
				this.newBankAccountForm.get("rankingValueType")?.patchValue(this.rankingValueType.value);
			}
		}
	}

	subscribeToRankingValueType() {
		this.newBankAccountForm
			.get("rankingValueType")
			?.valueChanges.pipe(take(1))
			.subscribe(val => {
				const findMatchingType = this.rankingValueTypes.find(x => {
					return x.value === val;
				});

				this.rankingValueType = findMatchingType!;
				if (this.rankingValueType?.value === "ALL_REMAINING") {
					this.isAllRemaining = true;
					this.newBankAccountForm.get("rank")?.patchValue(0, { emitEvent: false });
					this.newBankAccountForm.get("rankingAmount")?.patchValue(0, { emitEvent: false });
					this.newBankAccountForm.get("rankingAmount")?.disable();
				} else {
					this.newBankAccountForm.get("rankingAmount")?.enable();
					this.isAllRemaining = false;
				}
			});
	}

	getCountries() {
		this.countries$ = this.countryService.getCountryOptions().pipe(
			takeUntil(this.destroy$),
			shareReplay(),
			map(result => {
				if (this.bankAccountDetails?.details.country) {
					this.defaultCountry = result.find(
						country => country.value === this.bankAccountDetails?.details.country
					)!;
				} else {
					this.defaultCountry = result.find(country => country.value === this.country)!;
				}
				this.newBankAccountForm.get("country")?.patchValue(this.defaultCountry, { emitEvent: false });

				return result;
			})
		);
	}

	getCurrencies() {
		this.currencies$ = this.currencyService.getCurrencyOptions().pipe(
			takeUntil(this.destroy$),
			map(result => {
				if (this.bankAccountDetails) {
					this.defaultCurrency = result.find(
						currency => currency.value === this.bankAccountDetails?.details.currency
					)!;
					this.newBankAccountForm
						.get("currency")
						?.patchValue(this.defaultCurrency.value, { emitEvent: false });
				}
				return result;
			})
		);
	}

	initializeFormWithValue() {
		if (this.bankAccountDetails) {
			this.bankAccountValues = this.bankAccountValues || {};
			this.bankName = this.bankAccountDetails?.details?.bankName;

			if (this.bankAccountDetails.details.fields) {
				this.bankAccountDetails.details.fields.map((x: any) => {
					this.bankAccountValues[x.key] = x.value;
				});
			} else {
				this.isCardUpdate = true;
			}

			// this.bankAccountDetails.details.fields.map((x: any) => {
			// 	this.bankAccountValues[x.key] = x.value;
			// });

			this.newBankAccountForm.patchValue({
				effectiveFrom: this.bankAccountDetails?.payMethod.effectiveFrom,
				effectiveTo: this.bankAccountDetails?.payMethod.effectiveTo
			});

			this.bankAccountDetails.payMethod.paymentTypes.forEach((paymentType: PaymentType) => {
				if (paymentType.toLowerCase() == "nets") {
					this.newBankAccountForm.get("paymentTypes.net")?.patchValue(true);
					this.displayRanking = true;
				}
				if (paymentType.toLowerCase() == "tpp") {
					this.newBankAccountForm.get("paymentTypes.tpp")?.patchValue(true);
				}
				if (paymentType.toLowerCase() == "ewa") {
					this.newBankAccountForm.get("paymentTypes.ewa")?.patchValue(true);
				}
				if (paymentType.toLowerCase() == "fallback") {
					this.newBankAccountForm.get("paymentTypes.fallback")?.patchValue(true);
				}
			});

			// if account has rank
			if (this.bankAccountDetails.payMethod.ranking) {
				// set rank to what we have

				if (this.bankAccountDetails?.payMethod.ranking!.type === "ALL_REMAINING") {
					this.isAllRemaining = true;
					this.newBankAccountForm.get("rankingAmount")?.patchValue(0);
					this.newBankAccountForm.get("rank")?.setValue(0, { emitEvent: false });
					this.newBankAccountForm.get("rankingAmount")?.disable();
					this.rankingRank = 0;
				} else {
					this.newBankAccountForm.get("rankingAmount")?.enable();
					this.isAllRemaining = false;
					this.rankingRank = this.bankAccountDetails?.payMethod.ranking!.rank + 1;
					this.newBankAccountForm.get("rank")?.patchValue(this.rankingRank, { emitEvent: false });

					this.rankingAmount = this.bankAccountDetails?.payMethod.ranking!.amount;
					this.newBankAccountForm.get("rankingAmount")?.patchValue(this.rankingAmount, { emitEvent: false });
				}
			}
		}
	}

	createAccount() {
		let effectiveTo = this.newBankAccountForm.get("effectiveTo")!.value;
		let netCheckBox = this.newBankAccountForm.get("paymentTypes.net")?.value;
		let tppCheckBox = this.newBankAccountForm.get("paymentTypes.tpp")?.value;
		let ewaCheckBox = this.newBankAccountForm.get("paymentTypes.ewa")?.value;
		let fallback = this.newBankAccountForm.get("paymentTypes.fallback")?.value;

		if (this.newBankAccount && this.bankAccountFieldsComponent?.bankAccountForm.controls) {
			this.updateToStatus = this.payElectiveCommonService.updateToStatus(
				effectiveTo,
				netCheckBox,
				tppCheckBox,
				ewaCheckBox,
				fallback
			);
			this.payElectiveBankAccountsService.createNewBankAccount(
				this.newBankAccountForm,
				this.selectedEmployee.id!,
				this.bankAccountFieldsComponent?.bankAccountForm.controls,
				this.paymentMethodsArray,
				this.updateToStatus
			);
		} else if (this.bankAccountFieldsComponent?.bankAccountForm.controls) {
			//If update is to  NET
			// We'll need to update the ranks of all existing accounts and slot new one in

			if (
				this.payElectiveBankAccountsService.vallidateFallBackChanges(
					this.newBankAccountForm,
					this.supportedPayMethodTypes
				)
			) {
				if (!this.updateToStatus) {
					//only run if it was set yet by effecto date change
					this.updateToStatus = this.payElectiveCommonService.updateToStatus(
						effectiveTo,
						netCheckBox,
						tppCheckBox,
						ewaCheckBox,
						fallback
					);
					this.payElectiveBankAccountsService.updateBankAccount(
						this.newBankAccountForm,
						this.bankAccountDetails!,
						this.paymentMethodsArray,
						this.updateToStatus,
						this.bankAccountFieldsComponent!.bankAccountForm.controls
					);
				} else {
					this.payElectiveBankAccountsService.updateBankAccount(
						this.newBankAccountForm,
						this.bankAccountDetails!,
						this.paymentMethodsArray,
						this.updateToStatus,
						this.bankAccountFieldsComponent!.bankAccountForm.controls
					);
				}
			} else {
				this.toastService.showError("Error updating your account, please try again");
				return;
			}
		} else {
			this.updateToStatus = this.payElectiveCommonService.updateToStatus(
				effectiveTo,
				netCheckBox,
				tppCheckBox,
				ewaCheckBox,
				fallback
			);

			this.payElectiveBankAccountsService.updateBankAccount(
				this.newBankAccountForm,
				this.bankAccountDetails!,
				this.paymentMethodsArray,
				this.updateToStatus
			);
		}
		this.updateToStatus = "";
	}

	subscribeToBankAccount() {
		this.bankNameSubscription = this.bankAccountService.bankNameChanged$
			.pipe(takeUntil(this.destroy$))
			.subscribe(val => {
				this.bankName = val;
			})!;

		this.bankAccountService.formUpdated$
			.pipe(takeUntil(this.destroy$))
			.subscribe(isPristine => (this.formIsPristine = isPristine));
	}

	closeForm() {
		this.payElectiveBankAccountsService.close.emit();
	}

	typeIsSelected(): boolean {
		return this.payElectiveCommonService.requireCheckboxesToBeCheckedValidator(
			this.newBankAccountForm.get("paymentTypes") as FormGroup
		);
	}

	clearDate() {
		this.newBankAccountForm.get("effectiveTo")?.patchValue("");
	}

	formatRankingValueType(valueType: string): string {
		return this.rankingValueTypes.find(type => type.value === valueType)?.text!;
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
