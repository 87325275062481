<app-breadcrumbs [headerTitle]="'NETS SERVICE DEFINITION'" *ngIf="!selectedLegalEntity" />
<app-breadcrumbs [headerTitle]="'NETS SERVICE DEFINITION'" *ngIf="selectedLegalEntity" />
<div class="net-service-def-paygroup-list-container">
	<!-- Content -->
	<div [hidden]="reloadingStepper">
		<app-entity-select *ngIf="!selectedLegalEntity" (selectedPath)="setPath($event)"></app-entity-select>
	</div>

	<mat-stepper
		class="service-definition-stepper"
		linear="true"
		#stepper
		[@.disabled]="true"
		*ngIf="selectedCustomer && selectedLegalEntity"
	>
		<!-- This change default 'edit' icon -->
		<ng-template matStepperIcon="edit">
			<app-icon [name]="'tick'" [color]="'white'" [size]="'34'"></app-icon>
		</ng-template>

		<!-- Step 1: Type routes -->
		<mat-step label="{{ firstActive }}" [completed]="stepOneComplete">
			<app-type-routes
				*ngIf="selectedLegalEntity && stepper.selectedIndex === 0"
				[legalEntityHasPayGroups]="legalEntityHasPayGroups"
				[legalEntity]="selectedLegalEntity"
				[refreshDefinitions]="refreshDefinitions"
				(paymentTypeDefinitions)="setTypeDefinitions($event)"
				(selectedPaymentProviders)="setProviders($event)"
				(isOriginalObject)="setOriginalObject($event)"
			>
			</app-type-routes>
		</mat-step>

		<!-- Step 2: Source accounts -->
		<mat-step label="{{ secondActive }}">
			<app-source-accounts
				*ngIf="paymentTypeDefinitions && stepper.selectedIndex === 1"
				[legalEntity]="selectedLegalEntity"
				[paymentTypes]="paymentTypeDefinitions"
				[serviceProviders]="serviceProviders"
				(goPrev)="prevStep()"
				(goNext)="nextStep()"
				(hideBreadCrumbs)="hideBreadCrumbs($event)"
			>
			</app-source-accounts>
		</mat-step>

		<!-- Step 3: Settlement accounts -->
		<mat-step label="{{ thirdActive }}">
			<app-settlement-accounts
				*ngIf="paymentTypeDefinitions && stepper.selectedIndex === 2"
				[legalEntity]="selectedLegalEntity"
				[paymentTypes]="paymentTypeDefinitions"
				[serviceProviders]="serviceProviders"
				(goPrev)="prevStep()"
				(goHome)="finishProcess()"
				(hideBreadCrumbs)="hideBreadCrumbs($event)"
			>
			</app-settlement-accounts>
		</mat-step>
	</mat-stepper>
</div>
