<expansion-panel-section [title]="title" [lines]="false">
	<div class="reference-search" [formGroup]="entitySelectForm">
		<input-wrapper class="select" label="LEGAL ENTITY">
			<input-autocomplete
				formControlName="legal"
				[options]="legalEntitiesSelectOptions"
				[label]="'All Legal Entities'"
			>
			</input-autocomplete>
		</input-wrapper>

		<input-wrapper class="select" label="SEARCH BY">
			<mat-form-field id="payGroupSearch" appearance="outline">
				<app-icon [name]="'search-normal-1'" matPrefix style="padding-right: 10px"></app-icon>
				<input formControlName="paygroup" matInput placeholder="Search by pay group name/ID" />
			</mat-form-field>
		</input-wrapper>
	</div>

	<ng-container *ngIf="busy">
		<span class="pulsate">fetching paygroups...</span>
	</ng-container>

	<div *ngIf="payGroupsList.length && !busy" fxLayout="column" fxLayoutGap="20px">
		<span class="container-header-text"> List of Paygroups </span>

		<mat-card
			*ngFor="let payGroup of payGroupsList"
			(click)="selectpayGroup(payGroup)"
			class="card mat-elevation-z0"
		>
			<!-- Logo -->
			<div class="title-container">
				<div class="logo-container" *ngIf="payGroup.customer.logo!.id">
					<img
						src="https://api-dev.wpay.wezaam.com/v1/resources/logos/{{
							payGroup.customer.logo!.id
						}}/200_200.jpg"
						class="logo"
					/>
				</div>

				<!-- Customer Information -->

				<span class="information-title legal-entity-flag">
					<img src="assets/svg-country-flags/svg/{{ payGroup.legalEntity.data.country.toLowerCase() }}.svg" />
				</span>

				<span
					class="information-title legal-entity-id"
					[matTooltip]="
						payGroup.legalEntity.data.externalId + '/' + payGroup.data.name + '/' + payGroup.externalId
					"
					[matTooltipPosition]="'above'"
					[matTooltipClass]="'serviceDef-paygroup-list-item'"
				>
					{{ payGroup.legalEntity.data.externalId }} /&nbsp;
				</span>
				<span
					class="information-title paygroup-name"
					[matTooltip]="
						payGroup.legalEntity.data.externalId + '/' + payGroup.data.name + '/' + payGroup.externalId
					"
					[matTooltipPosition]="'above'"
					[matTooltipClass]="'serviceDef-paygroup-list-item'"
					>{{ payGroup.data.name }}</span
				>
				<span
					class="information-title paygroup-id"
					[matTooltip]="
						payGroup.legalEntity.data.externalId + '/' + payGroup.data.name + '/' + payGroup.externalId
					"
					[matTooltipPosition]="'above'"
					[matTooltipClass]="'serviceDef-paygroup-list-item'"
				>
					&nbsp;/ {{ payGroup.externalId }}</span
				>
			</div>
		</mat-card>
	</div>

	<ng-container *ngIf="payGroupsList.length === 0 && !busy">
		<mat-card>
			<div class="card-content" fxLayout="row" fxLayoutAlign="space-between center">
				<div class="text-container">
					<span class="no-legal-entity-text">No Pay Groups associated to customer yet</span>
				</div>
				<div class="link-container" fxLayout="row" fxLayoutAlign="center flex-end">
					<span class="entity-link" (click)="viewEntitiesClicked()" color="primary"
						>Go to Legal Entities</span
					>
				</div>
			</div>
		</mat-card>
	</ng-container>
</expansion-panel-section>

<app-pagination
	*ngIf="totalPageCount"
	[length]="totalPageCount"
	[pageSizeOptions]="[5, 10, 25, 100]"
	[pageSize]="pageSize"
	[pageIndex]="pageIndex"
	[paginationType]="paginationType"
	aria-label="Items per page:"
>
</app-pagination>
