import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	ViewChild
} from "@angular/core";
import { map, mergeMap, take, takeUntil, tap } from "rxjs/operators";
import { AccessFieldsComponent } from "@modules/service-definition/_components/access-fields/access-fields.component";
import { BankAccountFieldsComponent } from "@shared/components/bank-account-fields/bank-account-fields.component";
import { MenuService } from "@modules/config/_services/menu/menu.service";
import { ServiceProviderService } from "@shared/services/service-provider/service-provider.service";
import { ToastService } from "@shared/services/toast/toast.service";
import { LegalEntity } from "src/app/shared/models/legal-entity.interface";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import {
	Definition,
	Definitions,
	DefinitionsData,
	ProviderCountry
} from "src/app/shared/models/service-definition.interface";
import { SettlementAccount } from "src/app/shared/models/settlement-account.interface";
import { SettlementAccountService } from "../../../_services/settlement-account/settlement-account.service";
import { CountriesService } from "@shared/services/countries/countries.service";
import { forkJoin, lastValueFrom, Observable, pipe, Subject } from "rxjs";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ServiceDefinitionService } from "../../../_services/service-definition/service-definition.service";
import { SettlementAccountPost, SettlementAccountPut } from "../../../_models/settlement-accounts.interface";
import { BankAccountPost, BankAccountPut } from "../../../_models/bank-account.interface";
import { Route } from "../../../_models/type-route.interface";
import { PermissionsService } from "@shared/services/permissions/permissions.service";

@Component({
	selector: "app-settlement-accounts",
	templateUrl: "./settlement-accounts.component.html",
	styleUrls: ["./settlement-accounts.component.scss"],
	providers: [SettlementAccountService]
})
export class SettlementAccountsComponent implements OnInit, OnChanges, OnDestroy {
	//Inputs
	@Input() paymentTypes: DefinitionsData[] | Array<any> = [];
	@Input() legalEntity!: LegalEntity;
	@Input() serviceProviders!: ProviderCountry[];

	// Outputs
	@Output() goPrev: EventEmitter<boolean> = new EventEmitter();
	@Output() goNext: EventEmitter<boolean> = new EventEmitter();

	@Output() goHome: EventEmitter<boolean> = new EventEmitter();

	@Output() hideBreadCrumbs: EventEmitter<boolean> = new EventEmitter();

	//ViewChild
	@ViewChild(BankAccountFieldsComponent) bankAccountFieldsComponent: BankAccountFieldsComponent | undefined;
	@ViewChild(AccessFieldsComponent) accessFieldsComponent: AccessFieldsComponent | undefined;

	private destroy$: Subject<void> = new Subject<void>();

	canEditSettlementAccounts: boolean = false;
	canAddSettlementAccounts: boolean = false;

	// UI variables
	public typeDefinitions!: any;
	public existingSettlementAccounts: Array<SettlementAccount> = [];
	public typeRoutes: Array<any> = [];
	public currencyOptions: Array<SelectOption[]> = [];
	public showForm: boolean = false;
	public selectedRoute: Route = {} as Route;
	public bankAccountValues: Record<string, string | number | boolean> | undefined;
	public accessFieldsValues: Record<string, string | number | boolean> | undefined;
	public selectOptionsCountries$: Observable<SelectOption[]> | undefined;
	public selectedCountry: string | undefined;
	public globalForm!: FormGroup;
	public editMode: boolean = false;

	providerCountriesList: string[] = [];

	constructor(
		private serviceProviderService: ServiceProviderService,
		private settlementAccountService: SettlementAccountService,
		private toastService: ToastService,
		private changeDetector: ChangeDetectorRef,
		private menuService: MenuService,
		private countriesService: CountriesService,
		private formBuilder: FormBuilder,
		private serviceDefinitionService: ServiceDefinitionService,
		private permissions: PermissionsService
	) {}

	ngOnInit(): void {
		this.permissions
			.canEditSettlementAccounts()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => (this.canEditSettlementAccounts = res));

		this.permissions
			.canAddSettlementAccounts()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => (this.canAddSettlementAccounts = res));

		this.init().subscribe();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	initForm(name: string, currency: string): void {
		this.globalForm = this.formBuilder.group({
			name: [name, [Validators.required]],
			country: [null],
			currency: [{ value: currency, disabled: true }]
		});

		this.globalForm
			.get("country")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe((country: string | SelectOption) => {
				if (typeof country === "string") {
					this.selectedCountry = country;
				} else {
					this.selectedCountry = country?.value || "";
				}

				if (this.selectedCountry !== this.selectedRoute?.account?.bankAccount?.country) {
					this.bankAccountValues = {};
					this.accessFieldsValues = {};
				}
			});

		if (!this.canEditSettlementAccounts) {
			this.globalForm.disable();
		}
	}

	init(): Observable<{ typeDefinitions: Definitions; existingSettlementAccounts: SettlementAccount[] }> {
		return forkJoin([
			this.serviceDefinitionService.loadDefinitions(this.legalEntity.id),
			this.settlementAccountService.getAccountsByLegalEntityId(this.legalEntity.id)
		]).pipe(
			map(([typeDefinitions, existingSettlementAccounts]: [Definitions, SettlementAccount[]]) => {
				this.typeDefinitions = typeDefinitions;
				this.existingSettlementAccounts = existingSettlementAccounts;
				this.bankAccountValues = {};
				this.accessFieldsValues = {};
				this.generateList(typeDefinitions);
				this.changeDetector.detectChanges();

				return { typeDefinitions: typeDefinitions, existingSettlementAccounts: existingSettlementAccounts };
			})
		);
	}

	async generateList(typeDefinitions: Definitions) {
		this.typeRoutes = [];

		let defs;

		if (typeDefinitions.paymentTypeDefinitions.length) {
			defs = this.typeDefinitions.paymentTypeDefinitions;
		} else {
			defs = this.paymentTypes;
		}

		defs.forEach((paymentType: DefinitionsData) => {
			const typeRouteObject = {
				type: paymentType.paymentType,
				routes: [] as Array<any>
			};

			paymentType.definitions.forEach(async (definition: Definition) => {
				// Load the full account for the route if it exists
				let definitionAvailableAccounts: Array<any> = [];
				let definitionAccount = this.existingSettlementAccounts.find((account: SettlementAccount) => {
					const defintitionAccounts = definition.settlementAccountIds as string[];
					if (account.id === defintitionAccounts[0]) {
						return account;
					} else {
						return false;
					}
				});

				if (definitionAccount) {
					definitionAvailableAccounts = this.existingSettlementAccounts.filter(
						(account: SettlementAccount) => {
							if (
								account.currency === definitionAccount?.currency &&
								account.providerCountryId === definitionAccount?.providerCountryId
							) {
								return account;
							} else {
								return false;
							}
						}
					);
				} else {
					definitionAccount = {} as SettlementAccount;
				}

				// Create custom route object for display
				const routeAccount = {
					paymentType: paymentType.paymentType,
					providerCountryId: definition.provider?.providerCountryId,
					providerName: this.serviceProviders?.find((provider: ProviderCountry) => {
						if (provider.providerCountryId === definition.provider?.providerCountryId) {
							return provider;
						} else {
							return false;
						}
					})?.name,
					payoutAccount: definition.payoutAccount,
					route: definition.route,
					currencies: await lastValueFrom(
						this.serviceDefinitionService.getProviderCurrencies(
							definition.provider?.providerCountryId as string
						)
					),
					account: definitionAccount,
					selectedCurrency: definitionAccount?.currency ? definitionAccount.currency : null,
					availableAccounts: definitionAvailableAccounts,
					selectedAccountId: definitionAccount.id ? definitionAccount.id : null
				};

				typeRouteObject.routes.push(routeAccount);
			});
			this.typeRoutes.push(typeRouteObject);
		});
	}

	getAvailableAccountsForRoute(typeRouteIndex: number, routeIndex: number) {
		const route = this.typeRoutes[typeRouteIndex].routes[routeIndex];
		this.typeRoutes[typeRouteIndex].routes[routeIndex].availableAccounts = this.existingSettlementAccounts.filter(
			(account: SettlementAccount) => {
				return (
					account.currency === route.selectedCurrency && account.providerCountryId === route.providerCountryId
				);
			}
		);

		const hasAccountSelected: boolean = !!this.typeRoutes[typeRouteIndex].routes[routeIndex].selectedAccountId;
		this.typeRoutes[typeRouteIndex].routes[routeIndex].account = {} as SettlementAccount;
		this.typeRoutes[typeRouteIndex].routes[routeIndex].selectedAccountId = null;

		this.updateDefinitions(null, this.typeRoutes[typeRouteIndex].routes[routeIndex], true)
			.pipe(take(1))
			.subscribe(() => {
				if (hasAccountSelected) {
					this.toastService.showInfo("Account removed after currency change");
				}
				this.changeDetector.detectChanges();
			});
	}

	selectAccountForRoute(typeRouteIndex: number, routeIndex: number) {
		const route = this.typeRoutes[typeRouteIndex].routes[routeIndex];
		this.typeRoutes[typeRouteIndex].routes[routeIndex].account = this.existingSettlementAccounts.find(
			(account: SettlementAccount) => {
				if (account.id === route.selectedAccountId) {
					return account;
				} else {
					return false;
				}
			}
		);

		this.updateDefinitions(this.typeRoutes[typeRouteIndex].routes[routeIndex].account.id, route, true)
			.pipe(take(1))
			.subscribe(() => {
				this.toastService.showSuccess("Account selected");
			});
	}

	clearRouteAccount(typeRouteIndex: number, routeIndex: number) {
		this.typeRoutes[typeRouteIndex].routes[routeIndex].account = {} as SettlementAccount;
		this.typeRoutes[typeRouteIndex].routes[routeIndex].selectedAccountId = null;
		this.typeRoutes[typeRouteIndex].routes[routeIndex].availableAccounts = [];
		this.typeRoutes[typeRouteIndex].routes[routeIndex].selectedCurrency = null;
		this.updateDefinitions(null, this.typeRoutes[typeRouteIndex].routes[routeIndex], true)
			.pipe(take(1))
			.subscribe(() => {
				this.toastService.showInfo("Route cleared");
				this.changeDetector.detectChanges();
			});
	}

	/**
	 * FORM METHODS
	 */
	openForm(route: any) {
		this.hideBread();

		this.selectedRoute = route;
		const selectedProviderName = this.selectedRoute.providerName;
		this.serviceProviderService
			.getServiceProviderCountryList(-1, 0, selectedProviderName)
			.pipe(take(1))
			.subscribe(res => {
				this.providerCountriesList = res;
				this.initilizeCountrySelector(countrySelected);
			});

		this.initForm(this.selectedRoute.account?.name, this.selectedRoute.selectedCurrency);

		this.selectedRoute.account?.bankAccount?.fields?.map((bankAccountField: any) => {
			this.bankAccountValues = this.bankAccountValues || {};
			this.bankAccountValues[bankAccountField.key] = bankAccountField.value;
		});

		if (this.selectedRoute.account?.bankAccount?.bankName) {
			this.bankAccountValues = this.bankAccountValues || {};
			this.bankAccountValues.bankName = this.selectedRoute.account?.bankAccount?.bankName;
		}

		this.selectedRoute.account?.accessFields?.map((accessField: any) => {
			this.accessFieldsValues = this.accessFieldsValues || {};
			this.accessFieldsValues[accessField.key] = accessField.value;
		});

		const countrySelected: string =
			this.selectedRoute.account?.bankAccount?.country || this.legalEntity.data.country;

		this.editMode = !!this.selectedRoute.account.id;

		this.showForm = true;
	}

	save() {
		if (this.selectedRoute.account?.id && this.bankAccountFieldsComponent) {
			this.settlementAccountService
				.updateSettlementAccount(this.prepareSettlementAccountToUpdate())
				.pipe(
					take(1),
					mergeMap((data: any) => this.updateDefinitions(data.id, this.selectedRoute))
				)
				.subscribe(data => {
					this.showForm = false;
					this.toastService.showSuccess("Bank account saved successfully");
					this.init().subscribe();
				});
		} else if (this.bankAccountFieldsComponent) {
			this.settlementAccountService
				.createSettlementAccount(this.prepareSettlementAccountToCreate())
				.pipe(
					take(1),
					mergeMap((data: any) => this.updateDefinitions(data.id, this.selectedRoute))
				)
				.subscribe(data => {
					this.showForm = false;
					this.toastService.showSuccess("Bank account created successfully");
					this.init().subscribe();
				});
		}
		this.menuService.setVisible(true);
		this.showBread();
	}

	updateDefinitions(id: any, route: any, onlyLoad?: boolean): Observable<any> {
		// Format Definitions
		this.typeDefinitions.paymentTypeDefinitions = this.typeDefinitions.paymentTypeDefinitions.map((type: any) => {
			if (type.paymentType === route.paymentType) {
				type.definitions = type.definitions.map((definition: any) => {
					if (
						definition.route === route.route &&
						definition.payoutAccount === route.payoutAccount &&
						definition.provider.providerCountryId === route.providerCountryId
					) {
						definition.settlementAccountIds = id ? [id] : [];
					}
					return definition;
				});
			}
			return type;
		});

		if (onlyLoad) {
			return this.serviceProviderService.updateDefinitions(this.typeDefinitions).pipe(
				mergeMap(() => this.serviceDefinitionService.loadDefinitions(this.legalEntity.id)),
				map((typeDefinitions: Definitions) => (this.typeDefinitions = typeDefinitions))
			);
		} else {
			return this.serviceProviderService
				.updateDefinitions(this.typeDefinitions)
				.pipe(mergeMap(() => this.init()));
		}
	}

	prepareSettlementAccountToCreate(): SettlementAccountPost {
		const settlementAccount: SettlementAccountPost = {
			legalEntityId: this.legalEntity.id,
			providerCountryId: this.selectedRoute.providerCountryId,
			currency: this.selectedRoute.selectedCurrency,
			name: this.globalForm.get("name")?.value,
			bankAccount: {} as BankAccountPost,
			accessFields: []
		};

		const bankAccount: { fields: { key: string; value: string }[] } | undefined =
			this.bankAccountFieldsComponent?.getBankAccount();
		if (bankAccount && this.selectedCountry) {
			settlementAccount.bankAccount = {
				...bankAccount,
				country: this.selectedCountry
			};
		}

		const accessFields: { key: string; value: string }[] | undefined =
			this.accessFieldsComponent?.getAccessFields();
		if (accessFields) {
			settlementAccount.accessFields = accessFields;
		}

		return settlementAccount;
	}

	prepareSettlementAccountToUpdate(): SettlementAccountPut {
		const settlementAccount: SettlementAccountPut = {
			id: this.selectedRoute.account?.id,
			version: this.selectedRoute.account?.version,
			name: this.globalForm.get("name")?.value,
			bankAccount: {} as BankAccountPut,
			accessFields: []
		};

		const bankAccount: { fields: { key: string; value: string }[] } | undefined =
			this.bankAccountFieldsComponent?.getBankAccount();
		if (bankAccount && this.selectedCountry) {
			settlementAccount.bankAccount = {
				...bankAccount,
				id: this.selectedRoute.account?.bankAccount?.id,
				version: this.selectedRoute.account?.bankAccount?.version,
				country: this.selectedCountry
			};
		}

		const accessFields: { key: string; value: string }[] | undefined =
			this.accessFieldsComponent?.getAccessFields();
		if (accessFields) {
			settlementAccount.accessFields = accessFields;
		}

		return settlementAccount;
	}

	initilizeCountrySelector(country: string) {
		this.selectOptionsCountries$ = this.countriesService.getCountryOptions().pipe(
			map((countries: SelectOption[]) => countries.filter(c => this.providerCountriesList?.includes(c.value))),

			tap((countries: SelectOption[]) =>
				this.globalForm.get("country")?.patchValue(this.countriesService.defaultCountry(countries, country))
			)
		);
	}

	/**
	 * UTILS
	 */
	ngOnChanges() {
		this.typeRoutes = [];
		this.changeDetector.detectChanges();
		this.ngOnInit();
	}

	goBack() {
		this.goPrev.next(true);
	}

	hideBread() {
		this.hideBreadCrumbs.emit(true);
	}

	showBread() {
		this.hideBreadCrumbs.emit(false);
		this.menuService.setVisible(true);
		this.showForm = !this.showForm;
	}

	goForward() {
		let allRoutescompleted = true;
		this.typeRoutes.forEach((type: any) => {
			type.routes.forEach((route: any) => {
				if (!route.account || !route.account.id) {
					allRoutescompleted = false;
				}
			});
		});
		if (allRoutescompleted) {
			this.goHome.next(true);
		} else {
			this.toastService.showError("Please complete all routes with an account");
		}
	}

	backFromModal() {
		this.showForm = !this.showForm;
		this.menuService.setVisible(true);
		this.showBread();
	}
}
