<div class="view-subservice-list-item-container">
	<div class="paygroup info-container" *ngIf="details.paymentType === 'PAY_GROUP'">
		<span class="subservice-name column-text" fxFlex="16">{{ details.subserviceName }}</span>

		<span class="reference column-text" fxFlex="14">{{ details.reference }}</span>

		<span class="amount column-text" fxFlex="14"
			>{{ details.amount.value }}{{ details.amount.currency | currencySymbol }}</span
		>

		<span class="value-date column-text" fxFlex="14">
			{{ details.valueDate | date : "MMM d"
			}}{{ details.valueDate | date : "d" | datePosition | lowercase }}</span
		>

		<span class="uploaded-by column-text" fxFlex="20">
			<span class="upload-type">
				<app-icon
					class="add-payment-icon"
					[name]="'document-text'"
					[color]="'var(--color-disabled-secondary)'"
					[lineWidth]="'2px'"
					[size]="'24'"
				></app-icon>
				{{ details.uploaded }}
			</span>
		</span>
	</div>

	<div class="employee info-container" *ngIf="details.paymentType === 'EMPLOYEE'">
		<span class="employee-id column-text" *ngIf="details.employeeId !== ''" fxFlex="10">{{
			details.employeeId
		}}</span>
		<span class="employee-id column-text" *ngIf="details.employeeId === ''" fxFlex="10">-</span>

		<span class="employee-name column-text" *ngIf="details.employeeName !== ''" fxFlex="16">{{
			details.employeeName
		}}</span>
		<span class="employee-name column-text" *ngIf="details.employeeName == ''" fxFlex="16">-</span>

		<span class="reference column-text" fxFlex="16">{{ details.reference }}</span>

		<span class="amount column-text" fxFlex="10"
			>{{ details.amount.value }}{{ details.amount.currency | currencySymbol }}</span
		>

		<span class="value-date column-text" fxFlex="10"
			>{{ details.valueDate | date : "MMM d"
			}}{{ details.valueDate | date : "d" | datePosition | lowercase }}</span
		>

		<span class="uploaded-by column-text" fxFlex="20">
			<span class="upload-type">
				<app-icon
					class="add-payment-icon"
					[name]="'document-text'"
					[color]="'var(--color-disabled-secondary)'"
					[lineWidth]="'2px'"
					[size]="'24'"
				></app-icon>
				{{ details.uploaded }}
			</span>
		</span>
	</div>
</div>
