import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PaymentTopCardsComponent } from "./payment-top-cards.component";
import { SharedModule } from "@shared/shared.module";
import { PipesModule } from "@shared/pipes/pipes.module";
import { FlexModule } from "ngx-flexible-layout";

@NgModule({
	declarations: [PaymentTopCardsComponent],
	imports: [CommonModule, SharedModule, PipesModule, FlexModule],
	exports: [PaymentTopCardsComponent]
})
export class PaymentTopCardsModule {}
