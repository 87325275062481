import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { take } from "rxjs/operators";

import { MilestonesService } from "@shared/services/milestones/milestones.service";
import { PayCycleService } from "@shared/services/pay-cycle/pay-cycle.service";
import { ToastService } from "@shared/services/toast/toast.service";
import { Milestone, OffCycleCreateDTO, PayCycle } from "src/app/shared/models/pay-cycle.interface";
import { UpdateSelectedCalendarMonth } from "../../../../store/actions/payCycleSelect.action";
import { AppState } from "../../../../store/models/state.model";
import { formatDateService } from "../../_services/formatDate.service";

@Component({
	selector: "app-create-offcycle-dialog",
	templateUrl: "./create-offcycle-dialog.component.html",
	styleUrls: ["./create-offcycle-dialog.component.scss"]
})
export class CreateOffcycleDialogComponent implements OnInit {
	createOffCycleForm!: FormGroup;
	destroy$: Subject<void> = new Subject();
	startDate!: Date;
	endDate!: Date;

	isEditing: boolean = false;
	milestones: Milestone[] = [];

	constructor(
		private formBuilder: FormBuilder,
		private payCycleService: PayCycleService,
		private milestonesService: MilestonesService,
		private toastService: ToastService,
		public dialogRef: MatDialogRef<CreateOffcycleDialogComponent>,
		private formatDateService: formatDateService,
		private store: Store<AppState>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	ngOnInit() {
		if (this.data.dialogType === "CREATE") {
			this.isEditing = false;
		} else {
			this.isEditing = true;
		}

		this.initializeForm();
	}

	initializeForm() {
		this.createOffCycleForm = this.formBuilder.group(
			{
				payrollCycleId: [""],
				payrollCycleName: [""],
				cycleId: ["", Validators.required],
				cycleName: ["", Validators.required],
				cycleStartDate: ["", Validators.required],
				cycleEndDate: ["", Validators.required],
				offCycleReason: [""]
			},
			{ validator: this.checkDates }
		);

		if (this.isEditing) {
			this.createOffCycleForm.get("payrollCycleId")!.patchValue(this.data.payCycle.payrollCycleId);
			this.createOffCycleForm.get("payrollCycleName")!.patchValue(this.data.payCycle.payrollCycleName);
			this.createOffCycleForm.get("cycleId")!.patchValue(this.data.externalId);
			this.createOffCycleForm.get("cycleName")!.patchValue(this.data.payCycle.name);
			this.createOffCycleForm.get("cycleStartDate")!.patchValue(this.data.payCycle.start);
			this.createOffCycleForm.get("cycleEndDate")!.patchValue(this.data.payCycle.end);
			this.createOffCycleForm.get("offCycleReason")!.patchValue(this.data.payCycle.comment);

			if (this.data.payCycle.attributes?.G2N_APPROVED && this.data.payCycle.attributes?.G2N_APPROVED === true) {
				this.createOffCycleForm.get("payrollCycleId")?.disable();
			}
		}
	}

	checkDates(group: FormGroup) {
		const today = new Date(new Date().setHours(0, 0, 0, 0));
		// end date is before start date
		if (new Date(group.controls.cycleEndDate.value) < new Date(group.controls.cycleStartDate.value)) {
			return { endDateNotValid: true };
		}

		// start date is in the past or after end date
		if (new Date(group.controls.cycleEndDate.value) < new Date(group.controls.cycleStartDate.value)) {
			return { startDateNotValid: true };
		}

		return null;
	}

	onNoClick(): void {
		this.dialogRef.close();
	}

	onCancel(): void {
		this.dialogRef.close(false);
	}

	onConfirm(): void {
		const formData = this.createOffCycleForm.getRawValue();

		const offCycleObject: OffCycleCreateDTO = {
			customerId: this.data.customerId,
			legalEntityId: this.data.legalEntityId,
			payGroupId: this.data.payGroupId,
			externalId: formData.cycleId,
			name: formData.cycleName,
			comment: formData.offCycleReason,
			payrollCycleId: formData.payrollCycleId,
			payrollCycleName: formData.payrollCycleName
		};

		if (this.isEditing) {
			offCycleObject.id = this.data.payCycle.id;
			offCycleObject.version = this.data.payCycle.version;
			//offCycleObject.milestones = this.milestones;

			if (!this.createOffCycleForm.get("cycleStartDate")?.dirty) {
				const date = new Date(formData.cycleStartDate);
				offCycleObject.start = date.toISOString().split("T")[0];
			} else {
				offCycleObject.start = this.formatDateService.formatDate(formData.cycleStartDate);
			}

			if (!this.createOffCycleForm.get("cycleEndDate")?.dirty) {
				const date = new Date(formData.cycleEndDate);
				offCycleObject.end = date.toISOString().split("T")[0];
			} else {
				offCycleObject.end = this.formatDateService.formatDate(formData.cycleEndDate);
			}

			this.editOffCycle(offCycleObject)
				.pipe(take(1))
				.subscribe({
					next: data => {
						this.toastService.showSuccess("Off Cycle Edited");
						this.dialogRef.close(data.id);
						this.setNewCalendarState(data);
					},
					error: _ => {
						this.toastService.showError("Error editing off cycle, please try again");
					}
				});
		} else {
			const startDate = this.formatDateService.formatDate(formData.cycleStartDate);
			const endDate = this.formatDateService.formatDate(formData.cycleEndDate);
			offCycleObject.start = startDate;
			offCycleObject.end = endDate;
			this.createOffCycle(offCycleObject)
				.pipe(take(1))
				.subscribe({
					next: data => {
						this.toastService.showSuccess("Off Cycle created");
						this.dialogRef.close(data.id);
						this.setNewCalendarState(data);
					},
					error: _ => {
						this.toastService.showError("Error creating off cycle, please try again");
					}
				});
		}
	}

	editOffCycle(offCycleObject: OffCycleCreateDTO): Observable<PayCycle> {
		return this.payCycleService.editOffCycle(offCycleObject);
	}

	createOffCycle(offCycleObject: OffCycleCreateDTO): Observable<PayCycle> {
		return this.payCycleService.createOffCycle(offCycleObject);
	}

	getMilestones(payCycleId: string): void {
		this.milestonesService
			.getMilestones(payCycleId)
			.pipe(take(1))
			.subscribe(x => {
				this.milestones = x;
			});
	}

	setNewCalendarState(data: PayCycle) {
		this.store.dispatch(
			new UpdateSelectedCalendarMonth({
				selectedMonth: {
					month: new Date(data.start).getMonth(),
					paygroupId: data.payGroupId,
					payCycle: data
				}
			})
		);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
