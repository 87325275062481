import { Component, Input, OnInit } from "@angular/core";

import { TppSubServiceDetails } from "../../models/tpp-data.interface";
import { EmployeeService } from "@shared/services/employee/employee.service";
import { take } from "rxjs";

@Component({
	selector: "app-view-subservice-details-list",
	templateUrl: "./view-subservice-details-list.component.html",
	styleUrls: ["./view-subservice-details-list.component.scss"]
})
export class ViewSubserviceDetailsListComponent implements OnInit {
	@Input() subServiceDetails!: TppSubServiceDetails[];
	@Input() paymentType!: string;

	subServiceDetailsWithExternalID!: TppSubServiceDetails[];

	idConvertedToExternalIds = false;

	constructor(private employeeService: EmployeeService) {}

	ngOnInit(): void {
		if (this.paymentType === "EMPLOYEE") {
			// Make an array of all the employeeIds, filtering out undefined values
			const employeeIdsList = this.subServiceDetails.map(payment => payment.employeeId).join(",");

			// Fetch employees by their IDs
			this.employeeService
				.getEmployeesByIds(employeeIdsList)
				.pipe(take(1))
				.subscribe(result => {
					const employees = result.items;

					// Create a map for quick lookup of employees by their IDs
					const employeeMap = new Map(
						employees.map(emp => [
							emp.id,
							{
								externalId: emp.externalId,
								fullName: `${emp.data.firstName} ${emp.data.lastName}`
							}
						])
					);

					// Update subServiceDetails with external IDs and employee names
					this.subServiceDetails = this.subServiceDetails.map(detail => {
						const employeeInfo = employeeMap.get(detail.employeeId);
						return employeeInfo
							? { ...detail, employeeId: employeeInfo.externalId, employeeName: employeeInfo.fullName }
							: detail;
					});

					this.idConvertedToExternalIds = true;
				});
		}
	}
}
