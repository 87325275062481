<sliding-panel class="sliding-panel-view-subservice-details" [exit]="exit">
	<div class="top-section">
		<!-- Header -->
		<div class="header">
			<div class="title-section">
				<div class="text">
					<span>{{ subService.subService.name }}</span>
				</div>
			</div>

			<div class="close-button" (click)="cancel()">
				<app-icon
					[name]="'close'"
					[color]="'var(--color-text-default)'"
					[size]="'65'"
					[lineWidth]="'1px'"
				></app-icon>
			</div>
		</div>

		<div class="Information-section">
			<div class="pay-by info-column">
				<span class="label">PAY BY</span>
				<span class="text">{{ subService.paymentBy | paymentByFormat }}</span>
			</div>

			<div class="reference-type info-column">
				<span class="label">REFERENCE TYPE</span>
				<span class="text">{{ subService.subService.frequency | frequencyFormat }}</span>
			</div>
		</div>

		<div [formGroup]="searchForm" *ngIf="subService.paymentBy === 'Employee'" class="searchbar-wrapper">
			<mat-form-field id="searchfield" appearance="outline">
				<app-icon
					[name]="'search-normal-1'"
					[color]="'var(--color-upload-button-dark-grey)'"
					[size]="'20'"
					matPrefix
				></app-icon>
				<input matInput placeholder="Search employee" formControlName="search" />
			</mat-form-field>
		</div>
	</div>

	<div class="details-container">
		<app-view-subservice-details-list
			*ngIf="subServiceDetails"
			[subServiceDetails]="subServiceDetails"
			[paymentType]="subService.paymentBy"
		></app-view-subservice-details-list>
	</div>

	<mat-paginator
		*ngIf="totalCount && subService.paymentBy === 'EMPLOYEE'"
		[length]="totalCount"
		[pageSizeOptions]="[5, 10, 25, 100]"
		[pageSize]="pageSize"
		[pageIndex]="pageIndex"
		(page)="paginate($event)"
		aria-label="Items per page"
	>
	</mat-paginator>
</sliding-panel>
