import { Pipe, PipeTransform } from "@angular/core";
import { PayMethodDetail } from "@modules/employee-data/types/accounts";

@Pipe({
	name: "paymentMethodStatus"
})
export class PaymentMethodStatusPipe implements PipeTransform {
	transform(method: PayMethodDetail, provider?: string): string {
		if (provider && method.payMethod.providersStatuses[provider]) {
			return method.payMethod.providersStatuses[provider].name;
		} else {
			return method.payMethod.status.name;
		}
	}
}
