import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CurrencyService } from "@shared/services/currency/currency.service";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { ServiceProviderService } from "../../../../shared/services/service-provider/service-provider.service";
import { ServiceProviderCountryCreateService } from "../../_services/service-provider-country-create/service-provider-country-create.service";

@Component({
	selector: "app-service-provider-capabilities",
	templateUrl: "./service-provider-capabilities.component.html",
	styleUrls: ["./service-provider-capabilities.component.scss"]
})
export class ServiceProviderCapabilitiesComponent implements OnInit, OnDestroy {
	currenciesForm!: FormGroup;
	currencyOptions$: Observable<SelectOption[]> = new Observable<SelectOption[]>();
	destroy$: Subject<void> = new Subject();

	@Input() providerId!: string;
	@Input() countryCode!: string;

	constructor(
		private readonly formBuilder: FormBuilder,
		private readonly serviceProviderService: ServiceProviderService,
		public readonly serviceProviderCountryCreateService: ServiceProviderCountryCreateService,
		private readonly currencyService: CurrencyService
	) {}

	ngOnInit(): void {
		this.currencyOptions$ = this.currencyService.getCurrencyOptions(true);
		this.initForm();
		this.initSubscriptions();
		this.onFormChanges();
		this.getProvider(this.providerId);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	// getProvider and initialize providerCountry
	getProvider(providerId: string): void {
		this.serviceProviderService
			.getServiceProviderById(providerId)
			.pipe(takeUntil(this.destroy$))
			.subscribe(result =>
				this.serviceProviderCountryCreateService.initProviderCountry(result, this.countryCode)
			);
	}

	initForm(): void {
		this.currenciesForm = this.formBuilder.group({
			currencies: [[], Validators.required],
			pushMethod: [true],
			pullMethod: [true]
		});
	}

	onFormChanges() {
		this.currenciesForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
			const isPushChecked = this.currenciesForm.get("pullMethod")?.value;
			const isPullChecked = this.currenciesForm.get("pushMethod")?.value;
			if (!isPullChecked && !isPushChecked) {
				this.currenciesForm.setErrors({ checkbox: "error" });
			} else {
				this.currenciesForm.setErrors(null);
			}
			this.serviceProviderCountryCreateService.updateCurrenciesFormState(this.currenciesForm.valid);
		});
	}

	initSubscriptions(): void {
		this.serviceProviderCountryCreateService.createCurrencyData$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.serviceProviderCountryCreateService.updateCurrencies(this.currenciesForm.getRawValue());
			this.serviceProviderCountryCreateService.emitCurrencies();
		});

		this.serviceProviderCountryCreateService.cancelReset$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.currenciesForm.reset();
		});
	}
}
