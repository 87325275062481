import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Milestone } from "src/app/shared/models/pay-cycle.interface";
import { setFormatDate } from "src/app/shared/utils/date.util";

@Component({
	selector: "milestone-details-row",
	templateUrl: "./milestone-details-row.component.html",
	styleUrls: ["./milestone-details-row.component.scss"]
})
export class MilestoneDetailsRowComponent implements OnInit {
	@Input() milestone: Milestone | undefined;

	showMilestonesState: boolean = false;

	@Input() set _showMilestonesState(val: boolean) {
		this.showMilestonesState = val;
	}

	@Output() onStatus: EventEmitter<void> = new EventEmitter<void>();
	@Output() onMenu: EventEmitter<void> = new EventEmitter<void>();
	@Output() cardClickedEvent: EventEmitter<void> = new EventEmitter<void>();
	@Output() viewIconClickedEvent: EventEmitter<void> = new EventEmitter<void>();

	constructor() {}

	ngOnInit(): void {}
}
