import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FileTypes, HandShakeResults, IntegrationError } from "@shared/models/file-manager";

import { PayGroup } from "src/app/shared/models/pay-groups";

@Component({
	selector: "app-file-erorr-dialog",
	templateUrl: "./file-erorr-dialog.component.html",
	styleUrls: ["./file-erorr-dialog.component.scss"]
})
export class FileErorrDialogComponent implements OnInit {
	taskId: string = "";
	errorType: FileTypes = FileTypes.G2N;
	payGroup!: PayGroup;
	integrationErrorMessages!: IntegrationError[];
	handShakeResults!: HandShakeResults;

	readonly Object = Object;

	constructor(public dialogRef: MatDialogRef<FileErorrDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

	ngOnInit(): void {
		this.taskId = this.data.taskId;

		if (this.data.errorType === FileTypes.PAYROLL_INTEGRATION) {
			this.integrationErrorMessages = this.data.integrationErrorMessages;
		}

		this.handShakeResults = this.data.handShakeResults;

		this.errorType = this.data.errorType;

		if (this.data.payGroup) this.payGroup = this.data.payGroup;
	}

	closeDialog(): void {
		this.dialogRef.close(false);
	}
}
