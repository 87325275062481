<app-breadcrumbs
	*ngIf="!payGroup"
	[headerTitle]="'PAY GROUPS'"
	[showAllCrumbs]="true"
	[externalCrumbs]="[{ crumb: externalCrumb }]"
/>
<app-breadcrumbs *ngIf="payGroup" [showAllCrumbs]="true" [headerTitle]="'PAY GROUPS'" />

<div class="main-container" *ngIf="formIsSetup()">
	<div class="c-title-item">Paygroup Setup</div>
	<div [formGroup]="form" fxLayout="column" fxLayoutAlign="space-evenly">
		<div class="input-container o-grid-3">
			<input-wrapper fxFlex="30" label="Payroll ID *">
				<input type="text" id="externalId" placeholder="Payroll ID" formControlName="externalId" />
				<mat-error>
					<div *ngIf="form.get('externalId')?.hasError('required') && form.get('externalId')?.touched">
						Payroll Id is a required field
					</div>
				</mat-error>
			</input-wrapper>

			<!-- pay group name -->
			<input-wrapper fxFlex="30" label="Name *">
				<input type="text" id="name" placeholder="Pay Group Name" formControlName="name" />
				<mat-error>
					<div *ngIf="form.get('name')?.hasError('required') && form.get('name')?.touched">
						Pay Group Name is a required field
					</div>
				</mat-error>
			</input-wrapper>

			<!-- pay group currency -->
			<input-wrapper fxFlex="30" label="Payment Currency *">
				<input-autocomplete
					id="currency"
					formControlName="currency"
					label="Pay Group Currency"
					[options]="currencies$ | async"
					[disabled]="!canEditPayGroups"
					[defaultValue]="form.get('currency')?.value"
				>
				</input-autocomplete>
				<mat-error>
					<div *ngIf="form.get('currency')?.hasError('required') && form.get('currency')?.touched">
						Pay Group currency is a required field
					</div>
				</mat-error>
			</input-wrapper>

			<!-- pay group frequency -->
			<input-wrapper fxFlex="30" label="Frequency *">
				<input-select
					id="frequency"
					formControlName="frequency"
					label="Frequency"
					[options]="frequency"
					[_disabled]="isEdit"
					(onSelectionChange)="changeFrquency($event)"
				>
				</input-select>
				<mat-error>
					<div *ngIf="form.get('frequency')?.hasError('required') && form.get('frequency')?.touched">
						Pay Group frequency is a required field
					</div>
				</mat-error>
			</input-wrapper>

			<!-- pay group runStartDay -->
			<ng-container
				*ngIf="
					this.form.get('frequency')?.value &&
					(this.form.get('frequency')?.value === 'BI_WEEKLY' ||
						this.form.get('frequency')?.value === 'FOUR_WEEKLY' ||
						this.form.get('frequency')?.value === 'WEEKLY')
				"
			>
				<input-wrapper fxFlex="30" label="Run Start Day *">
					<input-select
						id="runStartDay"
						formControlName="runStartDay"
						label="Run Start Day"
						[options]="runStartDay"
						[_disabled]="isEdit"
					>
					</input-select>
				</input-wrapper>
			</ng-container>

			<!-- pay group calendarYearStartWeek -->
			<ng-container
				*ngIf="
					this.form.get('frequency')?.value &&
					(this.form.get('frequency')?.value === 'BI_WEEKLY' ||
						this.form.get('frequency')?.value === 'FOUR_WEEKLY')
				"
			>
				<input-wrapper fxFlex="30" label="Calendar Year Start Week *">
					<input-select
						id="calendarYearStartWeek"
						formControlName="calendarYearStartWeek"
						label="Calendar Year Start Week"
						[options]="
							form.get('frequency')?.value === 'BI_WEEKLY'
								? calendarYearStartBiWeek
								: calendarYearStartFourWeek
						"
						[_disabled]="isEdit"
					>
					</input-select>
				</input-wrapper>
			</ng-container>

			<ng-template [appUserRolePermissions] [action]="'show'" [roles]="['SUPER_ADMIN']">
				<ng-container
					*ngIf="
						!this.form.get('frequency')?.value ||
						(this.form.get('frequency')?.value &&
							(this.form.get('frequency')?.value === 'BI_MONTHLY' ||
								this.form.get('frequency')?.value === 'MONTHLY' ||
								this.form.get('frequency')?.value === 'WEEKLY'))
					"
				>
					<input-wrapper fxFlex="30" label="PAYROLL INTEGRATION *">
						<input-select
							id="integration"
							label="Payroll integration"
							[options]="payrollFrequencies"
							formControlName="integrationFrequency"
						>
						</input-select>
						<mat-error>
							<div
								*ngIf="
									form.get('integrationFrequency')?.hasError('required') &&
									form.get('integrationFrequency')?.touched
								"
							>
								Payroll integration is a required field
							</div>
						</mat-error>
					</input-wrapper>
				</ng-container>
			</ng-template>
		</div>

		<div class="input-container o-grid-3">
			<!-- Payroll integration -->
			<ng-template [appUserRolePermissions] [action]="'show'" [roles]="['SUPER_ADMIN']">
				<ng-container
					*ngIf="
						this.form.get('frequency')?.value &&
						(this.form.get('frequency')?.value === 'FOUR_WEEKLY' ||
							this.form.get('frequency')?.value === 'BI_WEEKLY')
					"
				>
					<input-wrapper fxFlex="30" label="PAYROLL INTEGRATION *">
						<input-select
							id="integration"
							label="Payroll integration"
							[options]="payrollFrequencies"
							formControlName="integrationFrequency"
						>
						</input-select>
						<mat-error>
							<div
								*ngIf="
									form.get('integrationFrequency')?.hasError('required') &&
									form.get('integrationFrequency')?.touched
								"
							>
								Payroll integration is a required field
							</div>
						</mat-error>
					</input-wrapper>
				</ng-container>
			</ng-template>

			<!--Supported Pay Method-->
			<input-wrapper fxFlex="30" label="Payment Method *">
				<mat-radio-group
					id="supportedPayMethodTypes"
					aria-label="Select a payment method"
					formControlName="supportedPayMethodTypes"
					[ngStyle]="{ 'margin-top': '1.2rem' }"
				>
					<mat-radio-button
						*ngFor="let paymethod of paymentMethods"
						id="{{ paymethod.value }}"
						value="{{ paymethod.value }}"
						[checked]="form.get('supportedPayMethodTypes')?.value == paymethod.value ? true : false"
						>{{ paymethod.text }}</mat-radio-button
					>
				</mat-radio-group>
			</input-wrapper>

			<input-wrapper fxFlex="30" label="Avoid Penny Test">
				<mat-radio-group
					id="avoidPenny"
					aria-label="Select a payment method"
					formControlName="avoidPennyTest"
					[ngStyle]="{ 'margin-top': '1.2rem' }"
				>
					<mat-radio-button value="true">YES</mat-radio-button>
					<mat-radio-button value="false">NO</mat-radio-button>
				</mat-radio-group>
			</input-wrapper>
		</div>

		<expansion-panel-section
			[addContactButtonDisabled]="!canEditContacts"
			(addContactClicked)="onAddContactClicked()"
			[addContactButton]="true"
			[title]="'Contact Person'"
		>
			<app-contacts
				*ngIf="existingContacts"
				(contactFormData)="createContactFormData($event)"
				[addIsClicked$]="addContactClicked$"
				[contacts]="existingContacts"
				[objectLevel]="'pay-groups'"
				[objectId]="payGroup ? payGroup.id : ''"
			></app-contacts>
		</expansion-panel-section>

		<div fxLayout="row" fxLayoutAlign="space-between center">
			<div fxLayout="column">
				<div class="c-title-item">WPAY Paygroup Status</div>
				<div fxLayout="row" fxLayoutAlign="start center" class="details" fxLayoutGap="5px">
					<input-wrapper fxFlex="50" label="Status">
						<input-select
							class="status-selector"
							id="status"
							formControlName="status"
							[options]="statuses"
							[_disabled]="!userCanEditPayGroupStatus && isUpdateExistingPayGroup"
						></input-select>
						<mat-error>
							<div *ngIf="form.get('status')?.hasError('required') && form.get('status')?.touched">
								WPAY Status is a required field
							</div>
						</mat-error>
					</input-wrapper>
					<button
						[disabled]="!canViewHistory.statusHistory || showHistoryPanel"
						id="moreButton"
						class="more-vert-button"
						mat-icon-button
						(click)="setPanelState(true, 'STATUS')"
						[ngStyle]="{ visibility: canViewHistory.statusHistory ? 'visible' : 'hidden' }"
					>
						<app-icon class="live-date-more" [name]="'more'"></app-icon>
					</button>

					<input-wrapper [label]="'Go live date'" fxFlex="50">
						<mat-form-field appearance="outline" class="goLiveDate" id="goLiveDate" (click)="picker.open()">
							<input formControlName="goLiveAt" readonly matInput [matDatepicker]="picker" />
							<mat-datepicker-toggle matSuffix [for]="picker">
								<app-icon
									class="mat-toggle-icon"
									matDatepickerToggleIcon
									[name]="'calendar_month'"
								></app-icon>
							</mat-datepicker-toggle>
							<mat-datepicker #picker></mat-datepicker>
						</mat-form-field>
					</input-wrapper>
					<button
						id="moreButton"
						[ngStyle]="{ visibility: canViewHistory.goLiveDateHistory ? 'visible' : 'hidden' }"
						[disabled]="!canViewHistory.goLiveDateHistory || showHistoryPanel"
						class="more-vert-button"
						mat-icon-button
						(click)="setPanelState(true, 'GOLIVE')"
					>
						<app-icon class="live-date-more" [name]="'more'"></app-icon>
					</button>
				</div>
			</div>
			<div fxLayout="column">
				<div class="c-title-item">Treasury Paygroup Status</div>
				<div fxLayout="row" fxLayoutAlign="start center" class="details" fxLayoutGap="5px">
					<input-wrapper class="tStatus" fxFlex="50" label="Status">
						<input-select
							class="status-selector"
							id="treasuryStatus"
							formControlName="treasuryStatus"
							[options]="statuses"
							[_disabled]="!userCanEditPayGroupStatus && isUpdateExistingPayGroup"
						></input-select>
					</input-wrapper>

					<input-wrapper [label]="'Go live date'" fxFlex="50">
						<mat-form-field
							appearance="outline"
							class="goLiveDate"
							id="treasuryGoLiveAt"
							(click)="treasuryPicker.open()"
						>
							<input
								formControlName="treasuryGoLiveAt"
								readonly
								matInput
								[matDatepicker]="treasuryPicker"
							/>
							<mat-datepicker-toggle id="treasuryPickerId" matSuffix [for]="treasuryPicker">
								<app-icon
									class="mat-toggle-icon"
									matDatepickerToggleIcon
									[name]="'calendar_month'"
								></app-icon>
							</mat-datepicker-toggle>
							<mat-datepicker #treasuryPicker></mat-datepicker>
						</mat-form-field>
					</input-wrapper>
					<button
						id="moreButton"
						class="more-vert-button"
						mat-icon-button
						(click)="setPanelState(true, 'TREASURYGOLIVE')"
						[ngStyle]="{ visibility: canViewHistory.treasuryGoLiveDateHistory ? 'visible' : 'hidden' }"
						[disabled]="!canViewHistory.treasuryGoLiveDateHistory || showHistoryPanel"
					>
						<app-icon class="live-date-more" [name]="'more'"></app-icon>
					</button>
				</div>
			</div>
		</div>

		<div class="btn-container">
			<button id="cancel" class="cancel-btn" (click)="cancel()" action-buttons mat-stroked-button>Cancel</button>

			<ng-template [appUserRolePermissions] [action]="'hide'" [roles]="['TS_VO', 'CLIENT']">
				<button
					id="submit"
					class="submit-btn"
					(click)="savePayGroup()"
					action-buttons
					mat-flat-button
					[disabled]="form.invalid || !contactFormisValid || form.pristine"
				>
					{{ isUpdateExistingPayGroup ? "Update" : "Save" }}
				</button>
			</ng-template>
		</div>
	</div>

	<app-paygroup-status-history
		[_payGroupId]="payGroup!.id"
		[_type]="type"
		(cancelDetails)="setPanelState(false, '')"
		*ngIf="payGroup && showHistoryPanel"
	></app-paygroup-status-history>
</div>
