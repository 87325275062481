import { Pipe, PipeTransform } from "@angular/core";
import { PaygroupsService } from "@shared/services/paygroups/paygroups.service";
import { Observable, map, take } from "rxjs";

@Pipe({
	name: "paygroupidToLegalEntityId"
})
export class PaygroupidToLegalEntityIdPipe implements PipeTransform {
	constructor(private payGroupService: PaygroupsService) {}

	transform(payGroupId: string): Observable<string> {
		return this.payGroupService.getPaygroupWithPaygroupId(payGroupId).pipe(
			take(1),
			map(res => {
				return res.legalEntity.data.externalId;
			})
		);
	}
}
