<modal-wrapper
	title="{{ title }}"
	(onTitleButtonAction)="backFromModal()"
	[modalStyle]="'dark'"
	[noBreadCrumbs]="true"
	[exitIconBool]="true"
	[subHeaderColour]="'transparent'"
>
	<div id="editBeneificiaryPageContainer" fxLayout="column" fxLayoutGap="15px grid">
		<div
			id="genericFieldsContainer"
			class="generic-fields-container"
			fxLayout="column"
			*ngIf="selectedCountryName"
			[formGroup]="newBeneficiaryForm"
		>
			<span id="genericFieldsTitle" class="container-header-text"> Generic fields </span>
			<div id="genericFieldsRowContainer" fxLayout="row" class="generic-inputs-row" fxLayoutGap="60px">
				<input-wrapper [label]="'ACCOUNT NAME *'">
					<input
						id="accountNameInput"
						type="text"
						id="accountName"
						placeholder="Enter account client"
						formControlName="account_name"
					/>
				</input-wrapper>

				<input-wrapper class="select" label="SELECT A COUNTRY *">
					<input-autocomplete
						id="countrySelectInput"
						formControlName="country"
						[options]="countries$ | async"
						[label]="'Select country'"
						[disabled]="mode === 'edit' ? true : false"
					>
					</input-autocomplete>
				</input-wrapper>

				<input-wrapper class="select" label="BENEFICIARY CURRENCY *">
					<input-autocomplete
						id="currencySelectInputs"
						formControlName="currency"
						[options]="currencies$ | async"
						[label]="'Select currency'"
						[disabled]="readOnlyMode"
					>
					</input-autocomplete>
				</input-wrapper>
			</div>
		</div>

		<div
			id="editBankAccountFieldsContainer"
			class="bank-account-fields-container"
			fxLayout="column"
			*ngIf="mode === 'edit'"
		>
			<bank-account-fields
				class="account-fields"
				[country]="selectedCountryName"
				[values]="bankAccountValues"
				[bankName]="bankName"
				[editing]="true"
				[canEdit]="!readOnlyMode"
				[fromTPPService]="true"
			></bank-account-fields>
		</div>
		<div
			id="addBankAccountFieldsContainer"
			class="bank-account-fields-container"
			fxLayout="column"
			*ngIf="mode === 'add'"
		>
			<bank-account-fields
				class="account-fields"
				[country]="selectedCountryName"
				[editing]="false"
				[canEdit]="true"
				[fromTPPService]="true"
				*ngIf="selectedCountryName"
			></bank-account-fields>
		</div>
		<!-- !newBeneficiaryForm.valid || formIsPristine || !bankAccountFieldsValid || bankAccountFieldsPristine  -->
		<div id="buttonsContainer" fxLayout="row" class="generic-inputs-row" fxLayoutAlign="end center">
			<button
				id="saveBtn"
				[disabled]="disableButton"
				mat-button
				[ngClass]="enabledButtonColor ? 'save-button disabled' : 'save-button'"
				(click)="saveBeneficiary()"
				fxFlex="300px"
			>
				<span id="saveText" class="save-text" *ngIf="mode === 'add'">Save</span>
				<span id="updateText" class="save-text" *ngIf="mode === 'edit'">Update</span>
			</button>
		</div>
	</div>
</modal-wrapper>
