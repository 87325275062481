import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { MatDialog } from "@angular/material/dialog";

import { Booking, BookingEmployeeTypeDetails } from "../../models/tpp-payment.interface";
import { PermissionsService } from "@shared/services/permissions/permissions.service";
import { PayoutStatusHistoryDialogComponent } from "../../../_components/payouts/payout-list-item/payout-status-history-dialog/payout-status-history-dialog.component";
import { PayoutStatusName, Transaction } from "src/app/shared/models/payouts.types";
import { PaymentsApiService } from "@modules/dashboard/services/payments-api.service";

@Component({
	selector: "app-tpp-payment-payout-list-item",
	templateUrl: "./tpp-payment-payout-list-item.component.html",
	styleUrls: ["./tpp-payment-payout-list-item.component.scss"]
})
export class TppPaymentPayoutListItemComponent implements OnInit, OnDestroy {
	@Input() set _booking(booking: Booking) {
		this.setupPermissions();

		this.booking = booking;

		this.errorCounter = Object.values(booking.countByStatus)[0];

		let payoutStatuses: string[] = [];
		booking.payouts.forEach(payout => {
			this.payoutIds.push(payout.id);

			const status: string =
				payout.status.name !== PayoutStatusName.INACTIVE
					? payout.transactions[0].status.name.toString()
					: PayoutStatusName.INACTIVE;
			if (this.paymentApiService.canReprocess(status)) {
				this.reprocessableIds.push(payout.id);
			}
			this.payoutTransactions = payout.transactions;
			payoutStatuses.push(status);

			if (
				status === PayoutStatusName.INACTIVE ||
				status === PayoutStatusName.PROCESSING ||
				status === PayoutStatusName.PROCESSED
			) {
				this.disableToggle = true;
			}
		});
		booking.status = this.paymentApiService.getMostPessimisticStatus(payoutStatuses);

		if (booking.status === PayoutStatusName.INACTIVE) {
			this.activeToggle = false;
		}

		this.errorCounter =
			booking.paymentBy.toUpperCase() === "EMPLOYEE" ? this.paymentApiService.getErrorCount(payoutStatuses) : 0;
	}

	@Input() set _isCancelledRun(cancelled: boolean) {
		this.isCancelledRun = cancelled;
	}
	@Input() set _reprocessPayoutIdsList(ids: string[]) {
		this.reprocessPayoutIdsList = ids;
	}
	@Input() set _isLast(last: boolean) {
		this.isLast = last;
	}

	@Output() inActivatePayouts: EventEmitter<string[]> = new EventEmitter<string[]>();
	@Output() pushToReprocessList: EventEmitter<string[]> = new EventEmitter<string[]>();
	@Output() popFromReprocessList: EventEmitter<string[]> = new EventEmitter<string[]>();

	reprocessPayoutIdsList: string[] = [];
	booking!: Booking;
	showDetails: boolean = false;
	errorCounter!: number;
	employeesDetails!: BookingEmployeeTypeDetails[];
	payoutIds: string[] = [];
	reprocessableIds: string[] = [];
	payoutTransactions!: Transaction[];
	isLast: boolean = false;
	isCancelledRun: boolean = false;

	//toggle settings
	disableToggle: boolean = false;
	canDisablePayout: boolean = false;
	canReprocessPayment = false;
	activeToggle: boolean = true;
	canViewTransactionHistory: boolean = false;
	isDisabled = false;

	private destroy$: Subject<void> = new Subject();

	constructor(
		private permissions: PermissionsService,
		public dialog: MatDialog,
		private paymentApiService: PaymentsApiService
	) {}

	ngOnInit(): void {
		this.permissions
			.disableAccessToEditableFormField(["TS_IMPLEMENTATION_LEAD"])
			.subscribe(isDisabled => (this.isDisabled = isDisabled));
	}

	setupPermissions() {
		this.permissions
			.canViewTransactionHistoryTPP()
			.pipe(takeUntil(this.destroy$))
			.subscribe(viewRes => (this.canViewTransactionHistory = viewRes));

		this.permissions
			.canInactivatePaymentTPP()
			.pipe(takeUntil(this.destroy$))
			.subscribe(inactivateRes => {
				this.canDisablePayout = inactivateRes;
			});

		this.permissions
			.canReprocessBookings()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => (this.canReprocessPayment = res));
	}

	inactivateBooking(event: any) {
		//inactivate list of payouts
		if (event.source.checked === false) {
			this.inActivatePayouts.emit(this.payoutIds);
		}
	}

	setIds(event: MatCheckboxChange) {
		if (event.source.checked === true) {
			this.pushToReprocessList.emit(this.reprocessableIds);
		} else {
			this.popFromReprocessList.emit(this.reprocessableIds);
		}
	}

	isAllPayoutsSelected(): boolean {
		return (
			this.reprocessPayoutIdsList.filter(id => this.reprocessableIds.includes(id)).length ===
			this.reprocessableIds.length
		);
	}

	showHistory(): void {
		this.openDialog();
	}

	openDialog(): void {
		let dialogRefData = {
			payoutTransactions: this.payoutTransactions
		};

		const dialogRef = this.dialog.open(PayoutStatusHistoryDialogComponent, {
			width: "1100px",
			panelClass: "dialog-container",
			data: dialogRefData
		});

		dialogRef.afterClosed().subscribe((result: boolean) => {
			if (result) {
				//this.location.back();
			}
		});
	}

	showEmployeeDetails() {
		this.showDetails = !this.showDetails;
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
