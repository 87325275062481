import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { LegalEntity } from "../../shared/models/legal-entity.interface";
import { Subject } from "rxjs";
import { LegalEntityService } from "../../shared/services/legal-entity/legal-entity.service";
import { CustomerService } from "../../shared/services/customer/customer.service";
import { takeUntil } from "rxjs/operators";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { CountriesService } from "@shared/services/countries/countries.service";
import { CommonLegalEntityService } from "./_services/common/common-legal-entity.service";
import { SetupCustomersService } from "./_services/setup-customers/setup-customers.service";
import { formatDataForSelect } from "src/app/shared/utils/format-select-data.util";
import { createCustomerLogoPath } from "src/app/shared/utils/customer-logo-path.util";
import { ToastService } from "@shared/services/toast/toast.service";
import { Pagination } from "src/app/shared/models/pagination.interface";
import { Customer } from "src/app/shared/models/customer.interface";
import { Router } from "@angular/router";

@Component({
	selector: "app-legal-entity-setup",
	templateUrl: "./legal-entity-setup.page.html",
	styleUrls: ["./legal-entity-setup.page.scss"],
	providers: [SetupCustomersService, CommonLegalEntityService]
})
export class LegalEntitySetupPage implements OnInit {
	constructor(private router: Router) {}

	ngOnInit(): void {}

	customerSelected(customer: Customer) {
		this.router.navigate(["legal-entities/select"], { state: { customer } });
	}
}
