import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HistoryView, HistoryViewData } from "src/app/shared/models/history-view.interface";
import { environment } from "../../../../environments/environment";

@Injectable({
	providedIn: "root"
})
export class HistoryService {
	constructor(private readonly http: HttpClient) {}

	getBankAccountHistory(payMethodId: string): Observable<HistoryViewData[]> {
		return this.http.get<HistoryViewData[]>(
			`${environment.apiUri}/v2/employees/pay-methods/${payMethodId}/reports/penny-test`
		);
	}
}
