import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormArray, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { filter, map, take, takeUntil } from "rxjs/operators";

import { User } from "@modules/user-management/_models/user-management-api.interface";
import { ContactPerson, ContactPersonFormValue } from "src/app/shared/models/contact-person.interface";
import { ContactPersonService } from "@shared/services/contact-person/contact-person.service";
import { PermissionsService } from "@shared/services/permissions/permissions.service";
import { ToastService } from "@shared/services/toast/toast.service";
import { UserApiService } from "@shared/services/users/user-api.service";
import { SelectOption } from "@shared/models/select-option.interface";
import { RoleFormatService } from "./_services/roles-format.service";

@Component({
	selector: "app-contacts",
	templateUrl: "./contacts.component.html",
	styleUrls: ["./contacts.component.scss"]
})
export class ContactsComponent implements OnInit, OnDestroy {
	@Input() addIsClicked$: Observable<void> = new Observable();
	@Input() contacts!: ContactPerson[];
	@Input() objectLevel: string = "";
	@Input() objectId: string = "";

	@Output() contactFormData: EventEmitter<{ data: ContactPerson[]; valid: boolean }> = new EventEmitter<{
		data: ContactPerson[];
		valid: boolean;
	}>();

	canEditContactPersons = false;
	destroy$: Subject<void> = new Subject();
	form!: FormGroup;
	allUsers!: User[];
	userDataSource$: BehaviorSubject<SelectOption[]> = new BehaviorSubject<SelectOption[]>([]);
	systemRoles$: Observable<SelectOption[]> = new Observable<SelectOption[]>();
	users$ = this.userDataSource$.asObservable();

	constructor(
		private formBuilderServivce: UntypedFormBuilder,
		private toastService: ToastService,
		private permissions: PermissionsService,
		private userApiService: UserApiService,
		private roleFormat: RoleFormatService,
		private contactPersonService: ContactPersonService
	) {}

	ngOnInit(): void {
		this.userApiService
			.fetchUsers()
			.pipe(take(1))
			.subscribe(users => {
				this.allUsers = users;
				this.setupContacts();
			});
	}

	setupContacts(): void {
		this.permissions
			.canEditContactPersons()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => (this.canEditContactPersons = res));

		this.initForm();
		this.onAddClicked();
	}

	initForm(): void {
		this.form = this.formBuilderServivce.group({
			contactPersons: this.formBuilderServivce.array([])
		});

		this.setupOldContacts();
	}

	emitChanges(): void {
		const contacts: ContactPersonFormValue[] = this.form.getRawValue()?.contactPersons;

		if (contacts.length) {
			const contactsToReturn: ContactPerson[] = contacts.map(contact => {
				const newContact: ContactPerson = {
					name: contact.name,
					email: contact.email,
					phone: contact.phone,
					positions: contact.positions ? contact.positions.map(position => position.trim()) : [],
					userId: contact.userId
				};
				return newContact;
			});

			this.contactFormData.emit({
				data: contactsToReturn,
				valid: this.form.valid
			});
		} else {
			this.contactFormData.emit({
				data: [],
				valid: this.form.valid
			});
		}
	}

	setupOldContacts(): void {
		//Add old contacts
		this.contacts?.filter(contact => {
			if (contact.userId) {
				let user: User = this.userApiService.retrievUserFromList(this.allUsers, contact.userId!);
				let roleList: string[] = [];

				if (user?.roles) {
					user.roles.forEach(role => {
						if (roleList.length) {
							roleList.push(` ${this.roleFormat.transform(role)}`);
						} else {
							roleList.push(this.roleFormat.transform(role));
						}
					});

					const group = this.formBuilderServivce.group({
						name: [
							{ value: `${user.firstName} ${user.lastName}`, disabled: !this.canEditContactPersons },
							Validators.required
						],
						email: [
							{ value: user.email, disabled: !this.canEditContactPersons },
							[Validators.required, Validators.email]
						],
						phone: [{ value: user.phoneNumber, disabled: !this.canEditContactPersons }],
						positions: [{ value: roleList, disabled: true }],
						userRole: [true],
						userId: [contact.userId]
					});

					this.setupValueChangesForPerson(group);

					this.contactPersonsFormArray().push(group);
				}
			}
		});

		this.setUserSelection(this.allUsers);
	}

	contactPersonsFormArray(): FormArray {
		return this.form.get("contactPersons") as FormArray;
	}

	removeContactPerson(i: number): void {
		const user: ContactPerson = this.contactPersonsFormArray().at(i).value;
		if (this.objectId && user.userId) {
			this.contactPersonService.removeContact(this.objectLevel, this.objectId, user.userId).subscribe(res => {
				this.contactPersonsFormArray().removeAt(i);
				if (this.contactPersonsFormArray().controls.length === 0) {
					this.contacts = [];
				}
				this.setUserSelection(this.allUsers);
				this.emitChanges();
			});
		} else {
			this.contactPersonsFormArray().removeAt(i);
			if (this.contactPersonsFormArray().controls.length === 0) {
				this.contacts = [];
			}
			this.setUserSelection(this.allUsers);
			this.emitChanges();
		}
	}

	onAddClicked(): void {
		this.addIsClicked$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			if (this.form.valid) {
				const group = this.formBuilderServivce.group({
					name: ["", Validators.required],
					email: ["", [Validators.required, Validators.email]],
					phone: [""],
					positions: [[]],
					userRole: [false],
					userId: [""]
				});

				this.setupValueChangesForPerson(group);

				this.contactPersonsFormArray().push(group);
				this.emitChanges();

				if (!this.canEditContactPersons) {
					this.form.disable();
				}
				return;
			}
			this.toastService.showWarning("Current Contacts have invalid data.");
		});
	}

	setupValueChangesForPerson(group: UntypedFormGroup): void {
		group.get("name")?.valueChanges.subscribe(value => {
			const user = this.userApiService.retrievUserFromList(this.allUsers, value);
			if (user) this.addUserInfo(user, group);
		});

		group.get("email")?.valueChanges.subscribe(value => this.emitChanges());
		group.get("phone")?.valueChanges.subscribe(value => this.emitChanges());
	}

	setUserSelection(users: User[]): void {
		this.userDataSource$.next(
			users
				.filter(
					(user: User) =>
						user.firstName &&
						user.id &&
						(!user.hasOwnProperty("blocked") || !user.blocked) &&
						!(user.roles.includes("CLIENT") && user.contactPersons?.length) &&
						!this.isCurrentlyAssigned(user.id)
				)
				.map(user => {
					return {
						value: user.id!,
						text: `${user.firstName} ${user.lastName}`
					};
				})
				.sort((a, b) => a.text.localeCompare(b.text))
		);
	}

	isCurrentlyAssigned(userId: string): boolean {
		if (!this.form) return false;
		return this.form.getRawValue().contactPersons.filter((person: ContactPerson) => person.userId === userId).length
			? true
			: false;
	}

	addUserInfo(user: User, group: FormGroup): void {
		//Clear input fields
		group.get("positions")?.patchValue([]);
		group.get("email")?.patchValue("");
		group.get("phone")?.patchValue("");
		group.get("userId")?.patchValue("");

		if (user) {
			let roleList: string[] = [];

			if (user.roles) {
				user.roles.forEach(role => {
					if (roleList.length) {
						roleList.push(` ${this.roleFormat.transform(role)}`);
					} else {
						roleList.push(this.roleFormat.transform(role));
					}
				});
			}

			group.get("positions")?.patchValue(roleList ? roleList : []);
			group.get("email")?.patchValue(user.email ? user.email : "");
			group.get("phone")?.patchValue(user.phoneNumber ? user.phoneNumber : "");

			group.get("userId")?.patchValue(user.id);
			group.get("name")?.patchValue(`${user.firstName} ${user.lastName}`);

			group.get("positions")?.disable();

			if (!this.canEditContactPersons) {
				group.get("email")?.disable();
				group.get("phone")?.disable();
			}

			this.setUserSelection(this.allUsers);
			this.emitChanges();
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
