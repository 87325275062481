import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { of, Subject } from "rxjs";
import { map, switchMap, takeUntil } from "rxjs/operators";
import { ContactPersonService } from "@shared/services/contact-person/contact-person.service";
import { CustomerService } from "@shared/services/customer/customer.service";
import { ImagesService } from "@shared/services/images/images.service";
import { ContactPerson } from "src/app/shared/models/contact-person.interface";
import { Customer } from "src/app/shared/models/customer.interface";
import { Image } from "src/app/shared/models/image.interface";
import { CommonService } from "../../_services/common/common.service";
import generateLogoUtil from "src/app/shared/utils/generate-logo-util";
import { ToastService } from "../../../../shared/services/toast/toast.service";
import { Router } from "@angular/router";
import { PermissionsService } from "../../../../shared/services/permissions/permissions.service";
import { CustomerCacheService } from "@shared/services/customer/customer-cache.service";
import { BreadcrumbService } from "@shared/components/breadcrumbs/services/breadcrumb.service";

@Component({
	selector: "customer-creation",
	templateUrl: "./customer-creation.component.html",
	styleUrls: ["./customer-creation.component.scss"]
})
export class CustomerCreationComponent implements OnInit, OnDestroy {
	customerForm!: FormGroup;
	imagePath?: string;
	addContactClicked$: Subject<void> = new Subject<void>();
	customerCreated$: Subject<boolean> = new Subject<boolean>();
	contactData: ContactPerson[] = [];
	resetForm: boolean = false;
	context!: CanvasRenderingContext2D;
	contactFormisValid: boolean = true;
	customerName!: string;

	private destroy$: Subject<void> = new Subject<void>();

	@ViewChild("logoCanvas") myCanvas!: ElementRef<HTMLCanvasElement>;

	// Outputs
	@Output() customerCreated: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private formBuilder: FormBuilder,
		private imageService: ImagesService,
		private customerService: CustomerService,
		private commonService: CommonService,
		private contactPersonService: ContactPersonService,
		private toastService: ToastService,
		private router: Router,
		private permissions: PermissionsService,
		private customerCache: CustomerCacheService,
		private breadCrumbService: BreadcrumbService
	) {}

	ngOnInit(): void {
		document.body.scrollTop = 0;
		this.initForm();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	ngAfterViewInit(): void {
		this.context = this.myCanvas.nativeElement.getContext("2d")!;
	}

	private initForm(): void {
		this.customerForm = this.formBuilder.group({
			externalId: [null],
			id: [{ value: null, disabled: true }, Validators.required],
			name: [null, Validators.required],
			registrationId: [null],
			address: [null, Validators.required],
			image: [null],
			logo: [null],
			imagePath: [null]
		});

		this.customerForm
			.get("name")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: res => {
					this.customerName = res;
				}
			});
	}

	onImageUpload(image: Blob) {
		if (image) {
			if (image.size > 8388608) {
				this.imagePath = undefined;
				this.customerForm.get("image")?.patchValue(null);
				this.toastService.showError("Max file size of 8mb has been exceeded, please select a smaller image.");
			} else {
				this.getImagePath(image);
				this.customerForm.get("image")?.patchValue(image);
			}
		} else {
			this.imagePath = undefined;
			this.customerForm.get("image")?.patchValue(null);
		}
	}

	saveCustomerForm() {
		const customer: Customer = {
			...this.customerForm.getRawValue()
		};
		if (!customer.image) {
			this.createDefaultLogo(customer.name).then(file => {
				customer.image = file;
				this.createCustomer(customer);
			});
		} else {
			this.createCustomer(customer);
		}
	}

	createCustomer(customer: Customer) {
		this.contactData = this.contactData ? this.contactData : [];
		if (customer.image && this.customerForm.valid && this.contactData.length) {
			this.imageService
				.createCustomerLogo(customer.image)
				.pipe(
					switchMap((logo: Image) =>
						this.customerService
							.createCustomer(this.commonService.getCustomerApiDto(customer, logo.id, this.contactData))
							.pipe(
								switchMap((customer: Customer) =>
									this.contactData
										? this.contactPersonService
												.addContactPerson(
													this.commonService.contactPersonObject(
														customer.id,
														this.contactData
													)
												)
												.pipe(
													map((contacts: ContactPerson[]) =>
														contacts.filter((contact: ContactPerson) =>
															contact.userId
																? this.contactPersonService
																		.linkContactToCustomer(
																			customer.id,
																			contact.userId
																		)
																		.subscribe()
																: void 0
														)
													)
												)
										: of(customer)
								)
							)
					)
				)
				.subscribe({
					next: () => {
						console.warn("CusTOMER CREATED");
						this.customerCache.refreshCache();
						this.toastService.showSuccess("Customer Created Successfully");
						this.commonService.customerCreated();
						this.breadCrumbService.resetCustomerEntityGroupState();
						this.router.navigate(["/customers"]);
					},
					error: () => this.toastService.showError("Customer already exists")
				});
		}

		if (customer.image && this.customerForm.valid && !this.contactData.length) {
			this.imageService
				.createCustomerLogo(customer.image)
				.pipe(
					switchMap((logo: Image) =>
						this.customerService
							.createCustomer(this.commonService.getCustomerApiDto(customer, logo.id, this.contactData))
							.pipe(map((customer: Customer) => of(customer)))
					)
				)
				.subscribe({
					next: () => {
						this.customerCache.refreshCache();
						this.toastService.showSuccess("Customer Created Successfully");
						this.commonService.customerCreated();
						this.breadCrumbService.resetCustomerEntityGroupState();
						this.router.navigate(["/customers"]);
					},
					error: () => this.toastService.showError("Customer already exists")
				});
		}
	}

	contactFormData(event: { data: ContactPerson[]; valid: boolean }): void {
		this.customerForm.markAsDirty();
		this.contactFormisValid = event.valid;
		this.contactData = event.data;
	}

	cancelCreateCustomer() {
		this.resetForms();
		this.commonService.customerCreated();
		this.breadCrumbService.resetCustomerEntityGroupState();
		this.router.navigate(["/customers"]);
	}

	resetForms() {
		this.customerForm.reset();
		this.resetForm = true;
	}

	onAddContactClicked() {
		this.addContactClicked$.next();
	}

	private getImagePath(file: Blob) {
		const reader = new FileReader();
		reader.onload = () => {
			this.imagePath = reader.result as string;
		};
		reader.readAsDataURL(file);
	}

	createDefaultLogo(name: string): Promise<File> {
		return generateLogoUtil(name, this.context);
	}
}
