export const setFormatDate = (date: string | Date): Date => {
	return typeof date === "string" ? new Date(date) : date;
};

export const createISOStringFromDate = (date: string): string => {
	//const getDate = new Date(date).setUTCDate(new Date(date).getDate()); //set UTC date
	const getDate = new Date(date).setUTCDate(new Date(date).getDate()); //set UTC date
	const getYear = new Date(getDate).setUTCFullYear(new Date(date).getFullYear()); //set UTC date
	const getMonth = new Date(getYear).setUTCMonth(new Date(date).getMonth()); //set UTC date
	const newDate = new Date(getMonth).setUTCHours(0, 0, 0, 0); // set UTC Hours
	return new Date(newDate).toISOString();
};

export const createISOStringFromDateWithHours = (date: string): string => {
	//const getDate = new Date(date).setUTCDate(new Date(date).getDate()); //set UTC date
	const getDate = new Date(date).setUTCDate(new Date(date).getDate()); //set UTC date
	const getYear = new Date(getDate).setUTCFullYear(new Date(date).getFullYear()); //set UTC date
	const getMonth = new Date(getYear).setUTCMonth(new Date(date).getMonth()); //set UTC date
	const newDate = new Date(getMonth).setUTCHours(new Date(date).getHours(), new Date(date).getMinutes(), 0, 0); // set UTC Hours
	return new Date(newDate).toISOString();
};

export const createISOStringFromDateWithoutHours = (date: string): string => {
	const getDate = new Date(date).setUTCDate(new Date(date).getDate()); //set UTC date
	const getYear = new Date(getDate).setUTCFullYear(new Date(date).getFullYear()); //set UTC date
	const getMonth = new Date(getYear).setUTCMonth(new Date(date).getMonth()); //set UTC date
	const newDate = new Date(getMonth).setUTCHours(0, 0, 0, 0); // set UTC Hours
	return new Date(newDate).toISOString().split("T")[0];
};

export const createISOStringFromDateWithoutMilleseconds = (date: string): string => {
	//const getDate = new Date(date).setUTCDate(new Date(date).getDate()); //set UTC date
	const getDate = new Date(date).setUTCDate(new Date(date).getDate()); //set UTC date
	const getYear = new Date(getDate).setUTCFullYear(new Date(date).getFullYear()); //set UTC date
	const getMonth = new Date(getYear).setUTCMonth(new Date(date).getMonth()); //set UTC date
	const newDate = new Date(getMonth).setUTCHours(0, 0, 0, 0); // set UTC Hours
	return new Date(newDate).toISOString().replace(/\.\d+/, "");
};

export const correctConvertToISODate = (date: Date): Date => {
	//https://stackoverflow.com/a/45779965
	/*When you create a date with new Date(str) it creates a date object
	 	with a time zone. toISOString() makes it zero UTC offset, as denoted by the suffix "Z".
		getTimezoneOffset returns number of minutes and new Date expects number 
		//of milliseconds since January 1 1970 00:00:00 UTC, so multiplying by 60000 provides needed adjustment.
	*/
	return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
};

//General conversion

export const convertLocalTimeToUTCForBE = (localDate: Date): string => {
	// Extract year, month, day, etc. from the UTC timestamp
	const year = new Date(localDate).getUTCFullYear();
	const monthIndex = new Date(localDate).getUTCMonth();
	const day = new Date(localDate).getUTCDate();
	const hours = new Date(localDate).getUTCHours();
	const minutes = new Date(localDate).getUTCMinutes();
	const seconds = new Date(localDate).getUTCSeconds();
	const milliseconds = new Date(localDate).getUTCMilliseconds();

	// Create a new Date object with the extracted components
	const utcDate = new Date(Date.UTC(year, monthIndex, day, hours, minutes, seconds, milliseconds));

	return utcDate.toISOString(); // Convert to ISO string
};

export const convertUTCDateInLocalTimeForFE = (utcDate: Date): string => {
	const date = new Date(utcDate);
	const timezoneOffset = date.getTimezoneOffset();
	const localDate = new Date(date.getTime() - timezoneOffset * 60000);
	return localDate.toISOString();
};

//For milestone dates

export const mergeDateAndTime = (date: Date, time: string): Date => {
	let hours: number = +time.split(":")[0];
	hours = hours === 24 ? +"00" : hours;

	const minutes: number = +time.split(":")[1];

	date.setHours(12);
	date.setUTCHours(hours);
	date.setUTCMinutes(minutes);

	return convertDateToSemiUTC(date);
};

export const convertMilestoneDateToUTC = (date: Date, legalEntityTimezone: string): string => {
	return calculateUTCDateBySubtractingOffset(date, getOffsetFromLegalEntityTimezone(legalEntityTimezone, date));
};

export const getOffsetFromLegalEntityTimezone = (timezone: string, milestoneDate: Date): number => {
	const utcDate = correctConvertToISODate(milestoneDate);

	const timeZoneName = Intl.DateTimeFormat("ia", {
		timeZoneName: "shortOffset",
		timeZone: timezone
	}).format(utcDate);

	const offsetMatch = timeZoneName.match(/GMT([+-]\d+)/);

	if (!offsetMatch) return 0;
	const offset = parseInt(offsetMatch[1], 10);
	return offset;
};

export const calculateUTCDateBySubtractingOffset = (date: Date, offset: number): string => {
	return correctConvertToISODate(new Date(date.getTime() - offset * 60 * 60 * 1000)).toISOString();
};

export const convertDateToSemiUTC = (date: Date): Date => {
	return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
};
