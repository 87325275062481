import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { StateService } from "@shared/services/state/state.service";
import { Customer } from "../../shared/models/customer.interface";
import { Store, select } from "@ngrx/store";
import { AppState } from "@store/models/state.model";
import { getUserDataState } from "@store/index";
import { map } from "rxjs";
import { UserRole } from "@shared/constants/roles";

@Component({
	selector: "app-employee-data",
	templateUrl: "./employee-data.component.html",
	styleUrls: ["./employee-data.component.scss"]
})
export class EmployeeDataComponent implements OnInit {
	isClient!: boolean;
	isClientRole = false;

	constructor(private router: Router, private stateService: StateService, private store: Store<AppState>) {}
	ngOnInit(): void {
		this.store
			.pipe(
				select(getUserDataState),
				map(ngState => {
					if (ngState.roles?.includes(UserRole.CLIENT)) {
						this.isClientRole = true;
						this.router.navigate(["/employee-data/paygroups"]);
					}
				})
			)
			.subscribe();
	}

	customerSelected(customer: Customer) {
		this.stateService.setCustomer(customer);
		this.router.navigate(["/employee-data/paygroups"]);
	}
}
