<app-breadcrumbs
	*ngIf="!isFromGlobalDashboard"
	[headerTitle]="headerTitle"
	[externalCrumbs]="externalCrumbs"
	[showAllCrumbs]="true"
	(onDropDownCrumbItemSelected)="regress()"
	(onExternalDropDownCrumbItemSelected)="reselectEmployee($event)"
/>

<app-breadcrumbs
	*ngIf="isFromGlobalDashboard && payGroup && headerCrumbs"
	[headerTitleWithFlag]="headerCrumbs"
	[externalCrumbs]="externalCrumbs"
	[addOverviewTitle]="true"
	(onExternalDropDownCrumbItemSelected)="reselectEmployee($event)"
/>

<div class="main-container">
	<div class="content pe-tab-group">
		<mat-tab-group [@.disabled]="true" *ngIf="payGroup" [selectedIndex]="tabSelect" #tabGroup>
			<mat-tab id="masterDataTab" label="Master Data">
				<app-pay-elective-employee
					id="masterDataTab"
					[payGroup]="payGroup"
					[employee]="selectedEmployee"
					(employeeCreated)="next()"
					(employeeNameAltered)="alterBread($event)"
				></app-pay-elective-employee>
			</mat-tab>
			<mat-tab id="bankAccountTab" label="Payment methods" *ngIf="selectedEmployee">
				<app-pay-elective-bank-accounts
					id="payMethodsTab"
					[payGroup]="payGroup"
					[employee]="selectedEmployee"
					[_provider]="selectedProvider"
				></app-pay-elective-bank-accounts>
			</mat-tab>
			<mat-tab
				id="historyTab"
				label="History"
				*ngIf="selectedEmployee && (routerStatePayCycleId || routerStatePayCycleId === '')"
			>
				<app-pay-elective-transaction
					[payGroup]="payGroup"
					[selectedEmployee]="selectedEmployee"
					[routerStatePayCycleId]="routerStatePayCycleId"
				></app-pay-elective-transaction>
			</mat-tab>
		</mat-tab-group>
	</div>
</div>
