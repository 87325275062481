<ng-container *ngIf="bankAccountFields && bankAccountFields.length > 0">
	<div class="form-section-title">Bank account fields</div>

	<div class="full-width o-grid-3">
		<ng-container [formGroup]="bankAccountForm">
			<input-wrapper [label]="'Bank Name *'">
				<input
					id="bankNameInput"
					type="text"
					id="bankName"
					placeholder="Bank Name"
					formControlName="bankName"
				/>
				<div
					input-errors
					*ngIf="
						bankAccountForm.get('bankName')?.touched && bankAccountForm.get('bankName')?.errors?.required
					"
				>
					Bank Name is required
				</div>
			</input-wrapper>
			<input-wrapper
				*ngFor="let field of bankAccountFields"
				[label]="field.label"
				[icon]="field.unique ? 'vpn_key' : undefined"
			>
				<input
					id="{{ field.key }}"
					[type]="field.type"
					[placeholder]="field.name"
					[formControlName]="field.key"
				/>
				<div
					input-errors
					*ngIf="
						bankAccountForm.controls[field.key].touched &&
						bankAccountForm.controls[field.key].errors?.required
					"
				>
					{{ field.name }} is required
				</div>
				<div input-errors *ngIf="bankAccountForm.controls[field.key].errors?.minlength">
					Min length is {{ field.rules.MIN || field.rules.EXACT_LENGTH }}
				</div>
				<div input-errors *ngIf="bankAccountForm.controls[field.key].errors?.maxlength">
					Max length is {{ field.rules.MAX || field.rules.EXACT_LENGTH }}
				</div>
			</input-wrapper>
		</ng-container>
	</div>
</ng-container>
