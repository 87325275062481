export enum UserRole {
	SUPER_ADMIN = "SUPER_ADMIN",
	TS_ADMIN = "TS_ADMIN",
	TS_IMPLEMENTATION_CONSULTANT = "TS_IMPLEMENTATION_CONSULTANT",
	TS_IMPLEMENTATION_LEAD = "TS_IMPLEMENTATION_LEAD",
	TS_OPERATION_CONSULTANT = "TS_OPERATION_CONSULTANT",
	TS_OPERATION_LEAD = "TS_OPERATION_LEAD",
	TS_MANAGER = "TS_MANAGER",
	CLIENT = "CLIENT",
	TS_OCB = "TS_OCB",
	TS_VO = "TS_VO"
}
