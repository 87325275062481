import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { PayGroupDetails, PaygroupTableDetailItem } from "@modules/global-dashboard/models/global-dashboard-interface";
import { GlobalDashboardEventsService } from "@modules/global-dashboard/services/global-dashboard-events.service";
import { getTableExpandAndCollapseAnimation } from "@shared/utils/mat-table-animation.util";

@Component({
	selector: "app-landing-page-paygroup-table",
	templateUrl: "./landing-page-paygroup-table.component.html",
	styleUrls: ["./landing-page-paygroup-table.component.scss"],
	animations: [getTableExpandAndCollapseAnimation]
})
export class LandingPagePaygroupTableComponent {
	@Input() set legalEntities(legalEntities: PaygroupTableDetailItem[]) {
		this.dataSourceLegalEntities.data = legalEntities;
		this.legalEntityColumns = ["Legal Entities", "Paygroups", "expand"];
		this.paygroupColumns = ["first-padding", "Paygroups", "second-padding"];
	}

	@Output() fetchPaygroups: EventEmitter<[string, string]> = new EventEmitter<[string, string]>();

	dataSourceLegalEntities: MatTableDataSource<PaygroupTableDetailItem> = new MatTableDataSource();
	expandedLegalEntity!: PaygroupTableDetailItem;
	selectedLegalEntityElement: PaygroupTableDetailItem = {} as PaygroupTableDetailItem;
	legalEntityColumns: string[] = [];
	paygroupColumns: string[] = [];
	hoveredPaygroupId!: string;

	selectedLegalEntity: PaygroupTableDetailItem = {} as PaygroupTableDetailItem;
	constructor(private cdr: ChangeDetectorRef, private globalDashboardEventsService: GlobalDashboardEventsService) {}

	setHoveredPaygroup(paygroupId: string): void {
		this.hoveredPaygroupId = paygroupId;
	}

	toggleLegalEntity(element: PaygroupTableDetailItem): void {
		if (element) {
			this.selectedLegalEntityElement = element;
			element && element.legalEntityDetails.payGroups && element.legalEntityDetails.payGroups.length
				? this.setSelectedLegalEntity(element)
				: (this.fetchPaygroups.emit(["", this.selectedLegalEntityElement.legalEntityDetails.id]),
				  this.setSelectedLegalEntity(element));
			this.cdr.detectChanges();
		}
	}

	expandedState(row: PaygroupTableDetailItem): string {
		return row?.legalEntityDetails?.id &&
			this.expandedLegalEntity?.legalEntityDetails?.id === row.legalEntityDetails.id
			? "expanded"
			: "collapsed";
	}

	setSelectedLegalEntity(legalEnitity: PaygroupTableDetailItem): void {
		this.expandedLegalEntity?.legalEntityDetails?.id === legalEnitity?.legalEntityDetails?.id
			? (this.expandedLegalEntity = {} as PaygroupTableDetailItem)
			: (this.expandedLegalEntity = legalEnitity);
	}

	isLegalEntityExpanded(legal: PaygroupTableDetailItem): boolean {
		return legal?.legalEntityDetails?.id &&
			this.expandedLegalEntity?.legalEntityDetails?.id === legal.legalEntityDetails.id
			? true
			: false;
	}

	routeToPaymentsPage(row: PayGroupDetails): void {
		this.globalDashboardEventsService.routeFromGlobalDashboardToAnotherPage(row.id, "/global-dashboard/dashboard");
	}
}
