import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup, Validators, UntypedFormBuilder } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { IUserForm, User } from "../../_models/user-management-api.interface";
import { UserService } from "../../_services/user.service";

@Component({
	selector: "app-user-creation-dialog",
	templateUrl: "./user-creation-dialog.component.html",
	styleUrls: ["./user-creation-dialog.component.scss"]
})
export class UserCreationDialogComponent implements OnInit {
	userForm!: FormGroup;
	user!: User;
	title: string = "";
	multiple: boolean = true;

	roles!: string[];

	btnDisabled: boolean = true;
	seleccionados: string[] = [];
	hasClientConflictError = false;

	constructor(
		private userService: UserService,
		private fb: UntypedFormBuilder,
		private dialogRef: MatDialogRef<UserCreationDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: User
	) {
		this.title = "Create User";
	}

	ngOnInit() {
		this.userForm = this.fb.group({
			id: [],
			externalIds: [],
			email: [, Validators.required],
			phoneNumber: [],
			firstName: [, Validators.required],
			lastName: [, Validators.required],
			roles: [, Validators.required],
			blocked: [false]
		}) as IUserForm;

		if (this.data) {
			this.title = "Edit User";
			this.setDataForm(this.data);
			this.userForm.addControl("ticketNr", this.fb.control("", [Validators.required]));
			this.userForm.addControl("reason", this.fb.control("", [Validators.required]));
		}

		this.userForm.valueChanges.subscribe(() => {
			this.btnDisabled = !this.userForm.valid;
		});
	}

	get rolesList() {
		return this.userService.roles;
	}

	setDataForm(user: User) {
		this.userForm.patchValue({
			id: user.id,
			externalIds: user.externalIds,
			email: user.email,
			phoneNumber: user.phoneNumber,
			firstName: user.firstName,
			lastName: user.lastName,
			roles: user.roles,
			blocked: user.blocked
		});

		this.disableFieldsForExternalUser(user);

		this.roles = [...user.roles];
	}

	updateRoles(roles: SelectOption[]) {
		this.userForm.patchValue({
			roles: this.userService.setRolesModelData(roles)
		});
	}

	save() {
		this.dialogRef.close(this.userForm.value);
	}

	close() {
		this.dialogRef.close(null);
	}

	private disableFieldsForExternalUser(user: User): void {
		if (user.external) {
			this.userForm.controls["email"].disable();
			this.userForm.controls["phoneNumber"].disable();
			this.userForm.controls["firstName"].disable();
			this.userForm.controls["lastName"].disable();
		}
	}

	updateHasClientConflictErrors(bool: boolean): void {
		this.hasClientConflictError = bool;
	}
}
