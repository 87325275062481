import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppState } from "../../../../store/models/state.model";
import { Store, select } from "@ngrx/store";
import { take, takeUntil } from "rxjs/operators";
import { getSelectedTPPServiceState } from "../../../../store";
import { TppServiceManagementService } from "../../_services/management/tpp-service-management.service";
import { TppService, TppServicePagination } from "src/app/shared/models/tpp-service.interface";
import { breadcrumbDataObject } from "@shared/components/modal-wrapper/_models/breadcrumb.interface";
import { TppServicesService } from "@shared/services/tpp-services/tpp-services.service";
import { Observable, Subject } from "rxjs";
import { CountriesService } from "@shared/services/countries/countries.service";
import { MatDialog } from "@angular/material/dialog";
import { DeleteDialogComponent } from "@shared/components/delete-dialog/delete-dialog.component";
import { TppServiceDefinitionService } from "@modules/service-definition/_services/tpp-service-definition/tpp-service-definition.service";
import { ToastService } from "@shared/services/toast/toast.service";
import { DropDownDataExpanded } from "@shared/models/breadcrumbs.interface";
import { Country } from "@shared/models/country.interface";
import { UpdateSelectedTPPService } from "@store/actions/tppServiceSelect.action";

@Component({
	selector: "app-tpp-service",
	templateUrl: "./tpp-service.component.html",
	styleUrls: ["./tpp-service.component.scss"]
})
export class TppServiceComponent implements OnInit, OnDestroy {
	countryCode: string = "";
	countryName: string = "";
	oldtppService!: TppService;
	breadCrumbArray: breadcrumbDataObject[] = [];
	serviceName: string = "";
	btnDisabled: boolean = true;
	showBeneficiaryForm: boolean = false;
	showCreationComponent = true;

	serviceInfo!: TppService;

	isNewService: boolean = false;
	breadCrumbsHeaderTitle = "";

	countriesDropDownData: DropDownDataExpanded[] = [];
	servicesDropDownData: DropDownDataExpanded[] = [];

	private destroy$: Subject<void> = new Subject<void>();

	constructor(
		private router: Router,
		private countriesService: CountriesService,
		private tppServiceManagementService: TppServiceManagementService,
		private store: Store<AppState>,
		private tppServicesService: TppServicesService,
		private tppServiceDefitionService: TppServiceDefinitionService,
		public dialog: MatDialog,
		private toast: ToastService
	) {}

	ngOnInit(): void {
		this.store.pipe(takeUntil(this.destroy$), select(getSelectedTPPServiceState)).subscribe(state => {
			// country code

			this.countryCode = state.selectedTPPService ? state.selectedTPPService.countryCode : "";

			if (!this.countryCode) {
				this.router.navigate(["tpp-catalogue"]);
			} else {
				this.getCountryName(this.countryCode);
			}

			// Does service exist ?
			if (state.selectedTPPService && state.selectedTPPService.tppService) {
				this.tppServiceManagementService.setIsNewTppService(false);
				this.tppServiceManagementService.setTppServiceDetail(state.selectedTPPService.tppService);
				this.oldtppService = state.selectedTPPService.tppService;
			} else {
				this.isNewService = true;
				this.tppServiceManagementService.setIsNewTppService(true);
			}
		});

		this.tppServicesService.serviceNameChanged.subscribe(x => {
			this.serviceName = x;
		});

		this.tppServiceManagementService
			.getBeneficiaryAccountState()
			.subscribe(
				(enableBeneficiaryAccountForm: boolean) => (this.showBeneficiaryForm = enableBeneficiaryAccountForm)
			);

		this.getDropDownData();

		this.tppServicesService.catalogueContextSwitching$.next(false);
	}

	getDropDownData(): void {
		this.countriesService
			.getCountries()
			.pipe(take(1))
			.subscribe({
				next: res => {
					res.forEach((element: Country) => {
						this.countriesDropDownData.push({ id: element.country.code, name: element.country.name });
					});
				}
			});

		this.tppServicesService
			.searchTPPServiceByCountry(this.countryCode, "0", "-1")
			.pipe(take(1))
			.subscribe({
				next: (res: TppServicePagination) => {
					for (let i = 0; i < res.totalCount; i++) {
						if (res.items[i]) {
							this.servicesDropDownData.push({ id: res.items[i].id, name: res.items[i].name });
						}
					}
				}
			});
	}

	getCountryName(countryCode: string) {
		this.countriesService.getCountryByCode(countryCode).subscribe(countries => {
			this.countryName = countries[0].country.name;

			if (this.serviceName !== "") {
				this.breadCrumbArray = [
					{ display: true, crumb: this.countryName, link: "/tpp-catalogue" },
					{ display: true, crumb: this.serviceName }
				];
			} else {
				this.breadCrumbArray = [{ display: true, crumb: this.countryName, link: "/tpp-catalogue" }];
			}
		});
	}

	getNewServiceCreation(event: TppService): void {
		this.serviceInfo = event;

		this.serviceName = event.name;
		this.breadCrumbArray = [
			{ display: true, crumb: this.countryName, link: "/tpp-catalogue" },
			{ display: true, crumb: this.serviceName }
		];
	}

	openDialog(): void {
		if (this.isNewService && this.serviceInfo) {
			const dialogRef = this.dialog.open(DeleteDialogComponent, {
				panelClass: "select-modalbox",
				width: "660px",
				height: "398px",
				data: {
					text: "This service and all sub services will be deleted",
					btnNoText: "Cancel",
					btnYesText: "Continue"
				}
			});

			dialogRef.afterClosed().subscribe(result => {
				if (result) {
					this.removeAnySavedData();
				}
			});
		} else {
			this.router.navigate(["tpp-catalogue"]);
		}
	}

	removeAnySavedData(): void {
		let isDeleted: Observable<boolean> = this.tppServiceDefitionService.removeCurrentSavedData(
			this.serviceInfo,
			false
		);

		isDeleted.pipe(takeUntil(this.destroy$)).subscribe(success => {
			if (success) {
				this.toast.showSuccess("Service was successfully removed");

				this.router.navigate(["tpp-catalogue"]);
			} else {
				this.toast.showError("There was an error removing the service");
			}
		});
	}

	ngOnDestroy(): void {
		this.tppServiceManagementService.countryCodeServiceTPP = this.countryCode;
		this.destroy$.next();
		this.destroy$.complete();
	}

	save() {
		this.tppServicesService.resetSelectedEditAdHocService();
		this.tppServicesService.finishButtonConfigureServiceCatalogue();
		this.router.navigate(["tpp-catalogue"]);
	}

	finish(event: boolean) {
		this.btnDisabled = !event;
	}

	countryCrumbClicked(): void {
		this.router.navigate(["tpp-catalogue"]);
	}

	dropDownItemSelected(id: string): void {
		this.tppServicesService.catalogueContextSwitching$.next(true);
		if (id.length === 2) {
			this.resetFromCountry(id);
		} else {
			this.resetFromService(id);
		}
	}

	resetFromCountry(id: string): void {
		this.router.navigate(["tpp-catalogue"]);
		setTimeout(() => {
			this.tppServicesService.updateTPPCatalogueFilterCountry(id);
		}, 500);
	}

	resetFromService(id: string): void {
		this.tppServicesService
			.findTppServiceByServiceId(id)
			.pipe(take(1))
			.subscribe({
				next: res => {
					this.store.dispatch(
						new UpdateSelectedTPPService({
							selectedTPPService: {
								countryCode: this.countryCode,
								tppService: res
							}
						})
					);

					this.servicesDropDownData = [];
					this.countriesDropDownData = [];
					this.serviceName = res.name;
					this.showCreationComponent = false;
					this.getDropDownData();
					this.tppServicesService.catalogueContextSwitching$.next(false);

					setTimeout(() => {
						this.showCreationComponent = true;
						this.tppServicesService.stagedConfigureServiceDataArray = [];
					}, 500);
				}
			});
	}
}
