<app-breadcrumbs [headerTitle]="'CUSTOMERS'" />
<div class="content-container">
	<div class="information-container">
		<form [formGroup]="customerForm">
			<div class="container-section">
				<!-- Customer Setup Section -->
				<div class="title">
					<!--Custyomre setup-->
					Customer setup
				</div>

				<div class="o-grid-4">
					<!-- Customer reference -->
					<input-wrapper label="Customer reference" class="first-input-row">
						<input
							id="externalId"
							type="text"
							placeholder="Customer reference"
							formControlName="externalId"
							(input)="isChanged = true"
						/>
						<div id="externalId" input-errors *ngIf="customerForm.controls.externalId?.errors?.required">
							Customer reference is required
						</div>
					</input-wrapper>

					<!-- WPay Customer Reference -->
					<!-- <input-wrapper label="WPay Customer Reference">
							<input
								id="id"
								type="text"
								placeholder="WPay Customer Reference"
								formControlName="id"
								(input)="isChanged = true"
							/>
						</input-wrapper> -->

					<!-- Customer name -->
					<input-wrapper label="Customer name *">
						<input
							id="name"
							type="text"
							placeholder="Customer name"
							formControlName="name"
							(input)="isChanged = true"
						/>
						<div id="name" input-errors *ngIf="customerForm.controls.name?.errors?.required">
							Customer name is required
						</div>
					</input-wrapper>

					<!-- Customer address -->
					<input-wrapper label="Customer address *">
						<input
							id="address"
							type="text"
							placeholder="Customer address"
							formControlName="address"
							(input)="isChanged = true"
						/>
						<div id="address" input-errors *ngIf="customerForm.controls.address?.errors?.required">
							Customer address is required
						</div>
					</input-wrapper>

					<!-- Registration Number -->
					<input-wrapper label="Other customer info" class="last-input-row">
						<input
							id="registrationId"
							type="text"
							placeholder="Other customer info"
							formControlName="registrationId"
							(input)="isChanged = true"
						/>
						<div
							id="registrationId"
							input-errors
							*ngIf="customerForm.controls.registrationId?.errors?.required"
						>
							Customer registration number is required
						</div>
					</input-wrapper>

					<!-- Customer logo -->
					<input-wrapper label="Customer logo" class="first-input-row">
						<image-upload
							[isDisabled]="customerForm.disabled || customerForm.controls.image.disabled"
							[imagePath]="customerForm.controls.imagePath.value"
							(image)="onImageUploadEdit($event)"
							(input)="isChanged = true"
						></image-upload>
						<div input-errors *ngIf="customerForm.controls.imagePath?.errors?.required">
							Customer image is required
						</div>
					</input-wrapper>
					<canvas #logoCanvas class="no-canvas"></canvas>
				</div>
			</div>
			<hr />
			<div class="container-section">
				<!-- Contact Person -->
				<div class="title">
					<!--Custyomre setup-->
					Contact person (Optional)
				</div>
				<div class="button-container">
					<ng-template [appUserRolePermissions] [action]="'hide'" [roles]="['TS_VO', 'CLIENT']">
						<button
							class="btn-new-contact"
							mat-button
							[disabled]="customerForm.controls.contactPersons.invalid || !canEditContactPersons"
							(click)="onEditContactClicked()"
						>
							<app-icon
								id="add-customer-edition"
								[name]="'add'"
								[color]="'var(--color-primary-1000)'"
								[size]="'20px'"
							></app-icon
							>Create new contact person
						</button>
					</ng-template>
				</div>
				<app-contacts
					*ngIf="existingContacts"
					(contactFormData)="editContactFormData($event, customerForm)"
					[addIsClicked$]="editContactClicked$"
					[contacts]="existingContacts"
					[objectId]="customer.id"
					[objectLevel]="'customers'"
				></app-contacts>
			</div>
			<hr />
			<div class="container-section">
				<!-- Buttons -->
				<div class="btn-container">
					<ng-template [appUserRolePermissions] [action]="'hide'" [roles]="['TS_VO', 'CLIENT']">
						<button
							id="cancel"
							class="cancel-btn"
							(click)="cancelEditCustomer()"
							action-buttons
							mat-stroked-button
						>
							Cancel
						</button>
						<button
							id="submit"
							class="submit-btn"
							(click)="updateCustomerForm(customerForm)"
							action-buttons
							mat-flat-button
							[disabled]="customerForm.invalid || !contactFormisValid || customerForm.pristine"
						>
							Save
						</button>
					</ng-template>
				</div>
			</div>
		</form>
	</div>
</div>
