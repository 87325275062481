<div>
	<span *ngIf="!selectedCountry" class="no-country-text">Please select a Country to configure</span>
	<span *ngIf="selectedCountry && !hasFields()" class="no-country-text"
		>No fields have been created for <span class="selected-country">{{ selectedCountry }}. </span>
		<span class="add-field" (click)="addField()">Add a new field</span></span
	>

	<div *ngIf="selectedCountry && hasFields()">
		<div class="header">
			<h2>{{ selectedCountry }}</h2>

			<mat-icon *ngIf="canAddBankField" color="primary" class="add-button" (click)="addField()"
				>add_circle_outline</mat-icon
			>
		</div>
		<form [formGroup]="bankAccountFieldsForm">
			<div
				formArrayName="fields"
				class="form-container"
				*ngFor="let field of getBankAccountFields().controls; let i = index"
			>
				<div [formGroupName]="i">
					<div class="o-grid-3">
						<input-wrapper label="key *">
							<input type="text" formControlName="key" />
							<div
								input-errors
								*ngIf="
									(field.get('key')?.touched || field.get('key')?.dirty) &&
									field.get('key')?.hasError('required')
								"
							>
								Key is required
							</div>
						</input-wrapper>
						<input-wrapper label="name *">
							<input type="text" formControlName="name" />
							<div
								input-errors
								*ngIf="
									(field.get('name')?.touched || field.get('name')?.dirty) &&
									field.get('name')?.hasError('required')
								"
							>
								Name is required
							</div>
						</input-wrapper>
						<input-wrapper label="Type *">
							<input-select
								[options]="selectOptionsFieldTypes"
								formControlName="type"
								[required]="field.get('type')?.errors?.required"
								[_disabled]="!canEditBankField"
							>
							</input-select>
							<div
								input-errors
								*ngIf="
									(field.get('type')?.touched || field.get('type')?.dirty) &&
									field.get('type')?.hasError('required')
								"
							>
								Type is required
							</div>
						</input-wrapper>
					</div>
					<div class="o-grid-3">
						<input-wrapper label="Min Length">
							<input type="text" formControlName="minLength" />
						</input-wrapper>
						<input-wrapper label="Max Length">
							<input type="text" formControlName="maxLength" />
						</input-wrapper>
						<input-wrapper *ngIf="isString(i)" label="Exact Length">
							<input type="text" formControlName="exactLength" />
						</input-wrapper>
					</div>
					<div class="o-grid-3">
						<input-wrapper label="Aliases">
							<input type="text" formControlName="keyAliases" />
						</input-wrapper>
						<div class="checkbox-container">
							<mat-checkbox [checked]="shouldBeUnique(i)" formControlName="unique" color="primary"
								>Should Be Unique</mat-checkbox
							>
							<mat-checkbox
								#required
								(change)="setMinLength($event, i)"
								(indeterminateChange)="setMinLength($event, i)"
								[checked]="isRequired(i)"
								[disabled]="shouldBeUnique(i)"
								formControlName="required"
								color="primary"
								>Field Is Required</mat-checkbox
							>
						</div>
						<div></div>
					</div>
					<div class="o-grid-3">
						<div></div>
						<div></div>
						<button
							(click)="removeFields(i)"
							[disabled]="!canRemoveBankField"
							mat-button
							class="c-button-dashed delete"
						>
							<mat-icon>delete_outline</mat-icon>remove field set
						</button>
					</div>
				</div>
			</div>
		</form>
		<div class="button-container">
			<button class="cancel-btn" action-buttons mat-stroked-button>Cancel</button>
			<button [disabled]="!fieldsAreValid()" (click)="updateFields()" action-buttons mat-flat-button color="dark">
				Update
			</button>
		</div>
	</div>
</div>
