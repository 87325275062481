import { NgModule } from "@angular/core";
import { BankAccountsModule } from "./bank-accounts/bank-accounts.module";

// Pages
import { DatePipe } from "@angular/common";
import { HistoryLogSidePanelModule } from "@shared/components/history-log-side-panel/history-log-side-panel.module";
import { BreadcrumbsModule } from "@shared/components/breadcrumbs/breadcrumbs.module";
import { CalendarSetupFileHistoryModule } from "./calendar-setup-file-history/calendar-setup-file-history.module";
import { ClientLandingModule } from "./client-landing/client-landing.module";
import { CustomerSetupPageModule } from "./customer-setup/customer-setup.module";
import { PaymentsModule } from "./dashboard/payments.module";
import { EmployeeDataModule } from "./employee-data/employee-data.module";
import { ErrorManagementModule } from "./error-management/error-management.module";
import { FileManagementModule } from "./file-management/file-management.module";
import { GlobalDashboardModule } from "./global-dashboard/global-dashboard.module";
import { LegalEntitySetupPageModule } from "./legal-entity-setup/legal-entity-setup.module";
import { PayGroupsModule } from "./pay-groups/pay-groups.module";
import { PaymentCalendarModule } from "./payment-calendar/payment-calendar.module";
import { PennyTestModule } from "./penny-test/penny-test.module";
import { PermissionErrorModule } from "./permission-error/permission-error.module";
import { ReportingModule } from "./reporting/reporting.module";
import { ServiceDefinitionModule } from "./service-definition/service-definition.module";
import { ServiceProviderSetupPageModule } from "./service-provider-setup/service-provider-setup.module";
import { TppCatalogueModule } from "./tpp-catalogue/tpp-catalogue.module";
import { UserManagementModule } from "./user-management/user-management.module";
import { NetPaymentOrdersModule } from "./net-payment-orders/net-payment-orders.module";

@NgModule({
	providers: [DatePipe],
	imports: [
		BreadcrumbsModule,
		HistoryLogSidePanelModule,
		GlobalDashboardModule,
		CustomerSetupPageModule,
		LegalEntitySetupPageModule,
		ServiceProviderSetupPageModule,
		ServiceDefinitionModule,
		BankAccountsModule,
		FileManagementModule,
		PaymentsModule,
		PayGroupsModule,
		EmployeeDataModule,
		PaymentCalendarModule,
		ReportingModule,
		UserManagementModule,
		PermissionErrorModule,
		PennyTestModule,
		TppCatalogueModule,
		ErrorManagementModule,
		CalendarSetupFileHistoryModule,
		ClientLandingModule,
		NetPaymentOrdersModule
	],
	exports: [
		BreadcrumbsModule,
		HistoryLogSidePanelModule,
		GlobalDashboardModule,
		CustomerSetupPageModule,
		LegalEntitySetupPageModule,
		ServiceProviderSetupPageModule,
		ServiceDefinitionModule,
		BankAccountsModule,
		FileManagementModule,
		PaymentsModule,
		PayGroupsModule,
		PaymentCalendarModule,
		UserManagementModule,
		PermissionErrorModule,
		TppCatalogueModule,
		CalendarSetupFileHistoryModule,
		ClientLandingModule,
		NetPaymentOrdersModule
	]
})
export class PagesModule {}
