import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HistoryViewComponent } from "./history-view/history-view.component";
import { MatIconModule } from "@angular/material/icon";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatButtonModule } from "@angular/material/button";
import { ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { FlexLayoutModule } from "ngx-flexible-layout";
import { PipesModule } from "@shared/pipes/pipes.module";
import { SharedModule } from "@shared/shared.module";

@NgModule({
	declarations: [HistoryViewComponent],
	imports: [
		CommonModule,
		MatIconModule,
		MatToolbarModule,
		MatButtonModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		FlexLayoutModule,
		PipesModule,
		SharedModule
	]
})
export class HistoryViewModule {}
