<div id="serviceConfigStepTwoContainer-{{ index }}" class="container-style" fxLayout="column" fxLayoutGap="10px">
	<div id="subServiceContainerHeader" fxLayoutAlign="row" fxLayoutGap="14px">
		<div id="subServiceNameContainer" fxLayoutAlign="start center" fxFlex="50">
			<p id="subServiceNameText" class="title-style">{{ subService.name }}</p>
		</div>

		<div id="groupNameContainer" fxFlex="50" fxLayoutAlign="end end">
			<div id="groupNameTextContainer" class="{{ containerClass }}" fxLayoutAlign="center center">
				<span id="groupNameText" class="{{ textClass }}">GROUP {{ subService.tppGroup }}</span>
			</div>
		</div>
	</div>

	<div id="dividerContainer"><mat-divider></mat-divider></div>

	<div
		id="serviceConfigFormContainer"
		[formGroup]="form"
		fxLayout="row"
		*ngIf="beneficiarySelectOptions.length || frequencySelectOptions.length"
	>
		<div id="frequnecyInputContainer" fxFlex="30">
			<input-wrapper label="{{ firstInputLabel }}">
				<input-autocomplete
					id="frequencySelectInput"
					formControlName="frequency"
					label="Select frequency"
					[options]="frequencySelectOptions"
					[required]="true"
					[disabled]="!this.canEditSubServices"
				>
				</input-autocomplete>
			</input-wrapper>
		</div>

		<div id="arrowContainer" fxFlex="5" class="arrow-container">
			<div id="arrowContainerChild" class="arrow-container-child" fxLayout="row" fxLayoutAlign="center none">
				<div id="arrowLine" class="arrow-line" fxFlex="30"></div>
				<div id="arrowPoint" class="arrow-right"></div>
			</div>
		</div>

		<input-wrapper label="{{ secondInputLabel }}" fxFlex="30">
			<input-autocomplete
				id="managedInInput"
				formControlName="managed_in"
				label="Select run"
				[options]="managedInSelectOptions"
				[disabled]="!managedInEnabled"
				[required]="true"
				[defaultValue]="selectedManagedIn"
				[disabled]="!this.canEditSubServices"
			>
			</input-autocomplete>
		</input-wrapper>
	</div>
</div>
