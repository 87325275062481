<app-breadcrumbs [headerTitle]="'PENNY TEST'" />
<div class="main-container">
	<div class="parent-container flex flex-column" [formGroup]="pennyTestCurrencyForm">
		<div class="select-container o-grid-2">
			<input-wrapper class="select-currency" label="Select currency">
				<input-autocomplete
					id="currency"
					formControlName="currency"
					label="Select currency"
					[options]="currencies$ | async"
					[required]="true"
				>
				</input-autocomplete>
				<div
					id="currency_error"
					input-errors
					*ngIf="
						pennyTestCurrencyForm.controls.currency.touched &&
						pennyTestCurrencyForm.controls.currency.errors?.required
					"
				>
					A currency must be selected
				</div>
			</input-wrapper>

			<input-wrapper class="penny-test-value" label="Penny Test value">
				<input type="number" formControlName="amount" min="0" />
				<div
					id="amount"
					input-errors
					*ngIf="
						pennyTestCurrencyForm.controls.amount.touched &&
						pennyTestCurrencyForm.controls.amount.errors?.required
					"
				>
					Amount is required
				</div>
			</input-wrapper>
		</div>

		<div class="container-section">
			<!-- Buttons -->
			<div class="btn-container">
				<ng-template
					[appUserRolePermissions]
					[action]="'hide'"
					[roles]="[
						'TS_IMPLEMENTATION_CONSULTANT',
						'TS_IMPLEMENTATION_LEAD',
						'TS_OPERATION_CONSULTANT',
						'TS_OPERATION_LEAD',
						'TS_OCB',
						'TS_VO',
						'CLIENT'
					]"
				>
					<button
						id="submit"
						class="submit-btn"
						action-buttons
						color="dark"
						mat-flat-button
						(click)="savePennyTest()"
						[disabled]="
							pennyTestCurrencyForm.invalid ||
							pennyTestCurrencyForm.pristine ||
							!pennyTestCurrencyForm.controls.amount.touched ||
							!pennyTestCurrencyForm.controls.amount.dirty
						"
						[ngClass]="{
							disabled:
								pennyTestCurrencyForm.invalid ||
								pennyTestCurrencyForm.pristine ||
								!pennyTestCurrencyForm.controls.amount.touched ||
								!pennyTestCurrencyForm.controls.amount.dirty ||
								!canEditPennyTest
						}"
					>
						{{ isEdit ? "Update" : "Save" }}
					</button>
				</ng-template>
			</div>
		</div>
	</div>
</div>
