import { Injectable } from "@angular/core";
import { SelectOption } from "../../../../shared/models/select-option.interface";
import {
	PayoutAccountRoutes,
	ProviderCountrySettingsTemplate
} from "../../../../shared/models/service-provider-country-types";
import {
	PaymentRoute,
	PaymentRouteName,
	PaymentTypeName,
	PayoutAccountName
} from "../../../../shared/models/service-provider.type";

@Injectable({
	providedIn: "root"
})
export class ProviderCountryTemplateService {
	settingsTemplateData!: ProviderCountrySettingsTemplate;
	paymentTypes: PaymentTypeName[] = [];
	mappedPayoutAccount: Map<PaymentTypeName, SelectOption[]> = new Map<PaymentTypeName, SelectOption[]>([]);
	mappedRoutes: Map<PayoutAccountName, SelectOption[]> = new Map<PayoutAccountName, SelectOption[]>([]);

	constructor() {}

	setTemplateSettings(templateData: ProviderCountrySettingsTemplate) {
		this.settingsTemplateData = templateData;

		this.paymentTypes = templateData.capabilities.map(capability => capability.paymentType);

		templateData.capabilities.filter(capability => {
			const payoutAccOptions: SelectOption[] = [];
			Object.keys(capability.payoutAccountRoutes).map(payoutAccount => {
				const option = {
					text: payoutAccount.toString().replace("_", " "),
					value: payoutAccount.toString().replace("_", " "),
					selected: false
				} as SelectOption;
				payoutAccOptions.push(option);
			});
			this.mappedPayoutAccount.set(capability.paymentType, [...payoutAccOptions]);

			Object.entries(capability.payoutAccountRoutes).filter(entry => {
				const accountName = entry[0] as unknown as PayoutAccountName;
				const routes = entry[1] as unknown as PaymentRoute[];

				const routeOptions: SelectOption[] = [];
				routes.filter(route => {
					const option = {
						text: route.name.toString().replace("_", " "),
						value: route.name.toString().replace("_", " "),
						selected: false
					} as SelectOption;
					routeOptions.push(option);
				});
				this.mappedRoutes.set(accountName, [...routeOptions]);
			});
		});
	}

	getTemplateData() {
		return this.settingsTemplateData;
	}

	getPaymentTypes() {
		return this.paymentTypes;
	}

	getPayoutAccounts(paymentType: PaymentTypeName): PayoutAccountRoutes[] {
		const payoutAccounts = this.settingsTemplateData.capabilities.find(
			capability => capability.paymentType === paymentType
		);
		console.log(Object.keys(payoutAccounts?.payoutAccountRoutes!));
		return payoutAccounts?.payoutAccountRoutes!;
	}
}
