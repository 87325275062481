import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { BehaviorSubject, Observable, Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, map, takeUntil } from "rxjs/operators";
import { LoaderService } from "@shared/services/loader/loader.service";
import { StateService } from "@shared/services/state/state.service";
import { getCustomerEntityGroupState } from "src/app/store";
import { AppState } from "src/app/store/models/state.model";
import { breadcrumbDataObject, breadcrumbSelection } from "../_models/breadcrumb.interface";

import { DropdownService } from "../_services/dropdown.service";

class HeightAndWidth {
	height: number = 0;
	width: number = 0;
}

@Component({
	selector: "app-modal-dropdown",
	templateUrl: "./modal-dropdown.component.html",
	styleUrls: ["./modal-dropdown.component.scss"]
})
export class ModalDropdownComponent implements OnInit, OnDestroy {
	@Input() crumb!: breadcrumbDataObject;

	@Input() index: number = -1;

	@Input() showAllCrumbs: boolean = false;

	@Input() firstToDisplay: number = -1;

	addSearch: boolean = false;

	@ViewChild("dropdownHeader") dropDownHeader!: ElementRef;

	options$!: Observable<breadcrumbSelection[]>;
	allOptions$!: Observable<breadcrumbSelection[]>;

	dropDownSetWidth: number = 333;

	dropDownHeaderHeight: number = 0;

	dropDownHeaderWidth$: Subject<number> = new Subject<number>();
	dropDownMarginWidth$: Subject<number> = new Subject<number>();

	showDropDownbool: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	subscriptionDropDown!: Subscription;

	selectedCustomerId: string = "";
	selectedLegalEntityId: string = "";

	visibility$: Observable<boolean> = new Observable<boolean>();

	searchForm!: FormGroup;

	private destroy$: Subject<void> = new Subject<void>();

	constructor(
		private router: Router,
		private dropdownService: DropdownService,
		private store: Store<AppState>,
		private stateService: StateService,
		private loaderService: LoaderService,
		private formBuilder: FormBuilder
	) {
		this.visibility$ = this.loaderService.visibility$;
	}

	ngOnInit(): void {
		this.store.pipe(takeUntil(this.destroy$), select(getCustomerEntityGroupState)).subscribe(state => {
			this.selectedCustomerId = state.customerId;
			this.selectedLegalEntityId = state.legalEntityId;
		});

		this.getSeletion();

		if (this.crumb.type === "customer" || this.crumb.type == "customer-clear-selected-paygroup") {
			this.addSearch = true;
			this.initForm();
		}

		this.dropdownService.onDropDownClick.subscribe(value => this.showDropDownbool.next(value));
	}

	ngAfterViewInit(): void {
		// timeOut hack to resolve `ExpressionChangedAfterItHasBeenCheckedError`, generally bad practice
		setTimeout(() => {
			this.setupHeightMutationDropDownObserver();
			this.doDropDownHeightChange(this.getHeightAndWidthDropDownObject());
		});
	}

	initForm() {
		this.searchForm = this.formBuilder.group({
			search: [""]
		});
		this.searchForm.get("search")?.valueChanges.subscribe(searchValue => {
			this.options$ = this.filterResult(searchValue);
		});
	}

	filterResult(searchTerm: string): any {
		let filterSearch = this.allOptions$.pipe(
			map(result => {
				if (searchTerm == "") return result;

				return result.filter((result: breadcrumbSelection) =>
					result.text.toLowerCase().includes(searchTerm.toLowerCase())
				);
			})
		);

		return filterSearch;
	}

	getSeletion() {
		this.options$ = this.dropdownService.getSelection(this.crumb);
		this.allOptions$ = this.options$; //for searching
	}

	getHeightAndWidthDropDownObject(): HeightAndWidth {
		const newValues = new HeightAndWidth();
		newValues.height = this.dropDownHeader.nativeElement.offsetHeight;
		newValues.width = this.dropDownHeader.nativeElement.offsetWidth;
		return newValues;
	}

	doDropDownHeightChange(newValues: HeightAndWidth) {
		this.dropDownHeaderHeight = newValues.height;

		if (this.firstToDisplay === this.index) {
			if (this.showAllCrumbs) {
				this.dropDownMarginWidth$.next((newValues.width - this.dropDownSetWidth) / 2 + 60);
			} else {
				this.dropDownMarginWidth$.next((newValues.width - this.dropDownSetWidth) / 2 + 76);
			}
		} else {
			this.dropDownMarginWidth$.next((newValues.width - this.dropDownSetWidth) / 2);
		}

		this.dropDownHeaderWidth$.next(newValues.width);
	}

	setupHeightMutationDropDownObserver() {
		const HeightAndWidth$ = new Observable<HeightAndWidth>(observer => {
			const callback = () => {
				observer.next(this.getHeightAndWidthDropDownObject());
			};
			const elementObserver = new MutationObserver(callback);
			const config = { attributes: true, childList: true, subtree: true };
			elementObserver.observe(this.dropDownHeader.nativeElement, config);
		});

		this.subscriptionDropDown = HeightAndWidth$.pipe(debounceTime(50), distinctUntilChanged()).subscribe(
			newValues => {
				this.doDropDownHeightChange(newValues);
			}
		);
	}

	showDropDown() {
		if (!this.showDropDownbool.value) {
			this.dropdownService.onDropDownClick.emit(false);
			this.showDropDownbool.next(true);
		} else {
			this.showDropDownbool.next(false);
		}
	}

	getDropDown() {
		return this.showDropDownbool;
	}

	goTo(option: breadcrumbSelection) {
		this.showDropDownbool.next(false);

		switch (option.type) {
			case "customer-clear-selected-paygroup": {
				this.stateService.setCustomerById(option.id!);
				break;
			}
			case "customer": {
				//get first paygroup of customer
				this.stateService.setPaygroups(option.id!);
				break;
			}

			case "paygroup": {
				//set legal entity of chosen paygroup
				this.stateService.setLegalEntity(option);

				break;
			}
			default: {
				if (option.link.length > 0) {
					this.router.navigate([option.link]);
				}
			}
		}
	}

	isNumber(str: string): boolean {
		if (str.trim() === "") {
			return false;
		}

		return !Number.isNaN(Number(str));
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
