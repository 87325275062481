import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
	selector: "app-tpp-select-paygroup",
	templateUrl: "./tpp-select-paygroup.component.html",
	styleUrls: ["./tpp-select-paygroup.component.scss"]
})
export class TppSelectPaygroupComponent {
	constructor(private router: Router) {}

	handlePayGroupSelected() {
		this.router.navigate(["/service-definition/tpp/select-service"]);
	}
}
