import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "transactionStatuses"
})
export class TransactionStatusesPipe implements PipeTransform {
	transform(value: string, errors?: number): string {
		switch (value) {
			case "CREATED":
				return "CREATED";

			case "PENDING":
				return "PENDING";

			case "PROCESSING":
				return "PROCESSING";

			case "BOOKED":
				return "BOOKED";

			case "FAILED":
				return errors ? "FAILED" + " (" + errors + ")" : "FAILED";

			case "ATTEMPTS_LIMIT_EXCEEDED":
				return errors ? "FAILED" + " (" + errors + ")" : "FAILED";

			case "ERROR":
				return "ERROR";

			case "SUCCESS":
				return "PROCESSED";

			case "PAID_TO_FALLBACK":
				return "PAID TO FALLBACK";

			case "PROCESSED":
				return "PROCESSED";

			case "FAILED_TO_BOOK":
				return "FAILED TO BOOK";
			case "INACTIVE":
				return "INACTIVE";
		}

		return value;
	}
}
