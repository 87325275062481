import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import {
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	UntypedFormControl,
	UntypedFormGroup,
	Validators
} from "@angular/forms";
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { take, takeUntil } from "rxjs/operators";
import { PaygroupsService } from "@shared/services/paygroups/paygroups.service";
import { ToastService } from "@shared/services/toast/toast.service";
import { TppAccountsService } from "@shared/services/tpp-accounts/tpp-accounts.service";
import { TppServicesService } from "@shared/services/tpp-services/tpp-services.service";
import { FREQUENCIES } from "src/app/shared/constants/tpp-frequency";
import { PayGroup } from "src/app/shared/models/pay-groups";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { SubServicePaymentDefinition, TppAccount } from "src/app/shared/models/tpp-account.interface";
import {
	ClientSpecificSubService,
	TPPGroupDTO,
	TppService,
	TPPServiceDefinitionDTO
} from "src/app/shared/models/tpp-service.interface";
import { getCustomerEntityCountryState, getCustomerEntityGroupState, getSelectedTPPServiceState } from "src/app/store";
import { AppState } from "src/app/store/models/state.model";
import { TppaccountSelectOptionsPipe } from "../../pipes/tppaccount-select-options.pipe";
import { formSubService } from "../../_models/tpp-definition-form-subservices.interface";
import { TppServiceDefinitionService } from "../../_services/tpp-service-definition/tpp-service-definition.service";
import { Location } from "@angular/common";
import { Subject, Subscription } from "rxjs";
import { BreadcrumbsService } from "@shared/services/breadcrumbs/breadcrumbs.service";
import { breadcrumbDataObject } from "@shared/components/modal-wrapper/_models/breadcrumb.interface";
import { StateService } from "@shared/services/state/state.service";
import { TtpServiceSelectionService } from "../../_services/tpp-services/ttp-service-selection.service";
import {
	TppServicesByByPayGroupViewModel,
	TtpService
} from "src/app/shared/models/service-definition/TppServiceDefinitionViewModel";
import { DropDownDataExpanded } from "src/app/shared/models/breadcrumbs.interface";
import { UpdateSelectedTPPService } from "@store/actions/tppServiceSelect.action";

@Component({
	selector: "app-tpp-service-configuration",
	templateUrl: "./tpp-service-configuration.component.html",
	styleUrls: ["./tpp-service-configuration.component.scss"]
})
export class TppServiceConfigurationComponent implements OnInit, OnDestroy {
	public selectedCustomer!: string;
	public selectedCountry!: string;
	public selectedLegalEntity!: string;
	public selectedPayGroup!: string;
	public selectedPayGroupId!: string;
	nextColor: string = "#8992A3";

	selectedService!: TppService;

	tppServicesText = "TPP Services";

	title: string = "Created sub services";

	subServices!: SubServicePaymentDefinition[];
	formsInitialized = false;

	subServiceList: string[] = [];
	form!: FormGroup;

	definitionStep: number = 1;

	tppAccounts!: TppAccount[];

	beneficiarySelectOptions: SelectOption[] = [];

	selectedPayGroupFrequency: string = "";
	nextButtonTitle: string = "";

	frequencySelectOptions: SelectOption[] = FREQUENCIES;

	managedInSelectOptions: SelectOption[] = [];

	formSubServices: SubServicePaymentDefinition[] = [];

	selectedFormSubServices!: SubServicePaymentDefinition[];

	showInfo: boolean = false;

	formDataAvailable: boolean = false;
	serviceDefinitionServices: ClientSpecificSubService[] = [];

	private destroy$: Subject<void> = new Subject<void>();

	subServicesFormData!: ClientSpecificSubService[];

	tppGroupDTO!: TPPGroupDTO;

	servicesDropDownData!: DropDownDataExpanded[];

	SELECT_TTP_SERVICE_ROUTE = "/service-definition/tpp/select-service";
	breadCrumbArray: breadcrumbDataObject[] = [
		{
			display: false,
			type: "customer",
			crumb: "",
			link: "/service-definition/tpp"
		},
		{
			display: false,
			type: "paygroup",
			crumb: "",
			link: this.SELECT_TTP_SERVICE_ROUTE
		},
		{
			display: false,
			crumb: "",
			link: this.SELECT_TTP_SERVICE_ROUTE
		},
		{
			display: false,
			crumb: ""
		}
	];

	customerName: string = "";
	payGroupName: string = "";

	private stateSubscription!: Subscription;

	constructor(
		private store: Store<AppState>,
		private router: Router,
		private tppServicesService: TppServicesService,
		private formBuilder: FormBuilder,
		private tppServiceDefinitionService: TppServiceDefinitionService,
		private tppAccountService: TppAccountsService,
		private payGroupsService: PaygroupsService,
		private toastService: ToastService,
		private breadCrumbService: BreadcrumbsService,
		private stateService: StateService,
		private tppServiceSelectionService: TtpServiceSelectionService
	) {}

	ngOnInit(): void {
		this.filterServicesForDropDown(this.tppServiceSelectionService.getServicesArray());

		this.tppServiceDefinitionService.setFormSubServices([]);

		this.store.pipe(take(1), select(getSelectedTPPServiceState)).subscribe(state => {
			if (state && state.selectedTPPService && state.selectedTPPService.tppService) {
				this.selectedService = state.selectedTPPService.tppService;
			}
		});

		this.store.pipe(select(getCustomerEntityCountryState)).subscribe(state => {
			this.selectedCountry = state.country;
		});

		if (history.state.fromAdhoc) {
			this.skipStepOne(history.state.fromAdhoc);
		}

		this.stateSubscription = this.stateService.customerStateChanged.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.router.navigate([this.SELECT_TTP_SERVICE_ROUTE]);
		});

		this.store.pipe(select(getCustomerEntityGroupState), takeUntil(this.destroy$)).subscribe(state => {
			if (state.payGroupId && this.selectedService) {
				this.selectedPayGroupId = state.payGroupId;

				this.payGroupsService
					.getPaygroupWithPaygroupId(this.selectedPayGroupId)
					.pipe(take(1))
					.subscribe((paygroup: PayGroup) => {
						this.selectedPayGroupFrequency = paygroup.data.frequency;
					});

				this.breadCrumbService
					.getBreadCrumb(state.payGroupId)
					.pipe(takeUntil(this.destroy$))
					.subscribe(res => {
						this.customerName = res.customerName;
						this.payGroupName = res.paygroupName;
						this.breadCrumbArray = [
							{
								display: true,
								type: "customer",
								crumb: this.customerName,
								link: "/service-definition/tpp"
							},
							{
								display: true,
								type: "paygroup",
								crumb: this.payGroupName,
								link: "/service-definition/tpp/select-paygroup"
							},
							{
								display: true,
								crumb: this.tppServicesText,
								link: this.SELECT_TTP_SERVICE_ROUTE
							},
							{
								display: true,
								crumb: this.selectedService.name
							}
						];
					});
			}
		});

		if (this.selectedService) {
			this.tppServicesService
				.searchTppSubServices(this.selectedService.id, "0", "10")
				.pipe(take(1))
				.subscribe(response => {
					if (response.items.length) {
						this.subServices = response.items;

						this.definitionStepSetup();
					}
				});
		}
	}

	ngOnDestroy() {
		if (this.stateSubscription) {
			this.stateSubscription.unsubscribe();
		}
		this.destroy$.next();
		this.destroy$.complete();
	}

	definitionStepSetup() {
		if (this.definitionStep === 1) {
			this.nextButtonTitle = "NEXT";
			this.formSubServices = [];
		} else if (this.definitionStep === 2) {
			this.nextButtonTitle = "FINISH";

			this.tppServiceDefinitionService.getFormSubServices().forEach((element: formSubService) => {
				let targetSubService = this.subServices.find(subService => {
					return subService.id === element.subServiceId;
				});

				if (targetSubService) {
					let targetSubServiceName = targetSubService?.name;
					let targetSubServiceFrequency = targetSubService?.frequency;

					this.formSubServices.push({
						id: element.subServiceId,
						name: targetSubServiceName!,
						beneficiaryId: element.beneficiaryId ? element.beneficiaryId : undefined,
						externalId: element.externalId ? element.externalId : "",
						tppGroup: element.group,
						frequency: targetSubServiceFrequency
					});
				}
			});

			this.selectedFormSubServices = this.formSubServices;
		}

		this.initializeForm();
	}

	initializeForm(): void {
		this.form = this.formBuilder.group({
			services: this.formBuilder.array([])
		});

		this.getTPPAccounts();
	}

	get servicesForms() {
		return this.form.get("services") as FormArray;
	}

	addService(subServiceId: string) {
		const subServiceDef = this.formBuilder.group({
			serviceId: [this.selectedService.id, Validators.required],
			subServiceId: [subServiceId, Validators.required],
			group: ["", Validators.required],
			beneficiaryId: [],
			beneficiaryName: [],
			externalId: ["", Validators.required],
			frequency: ["", Validators.required],
			managedIn: ["", Validators.required]
		});

		this.servicesForms.push(subServiceDef);
	}

	deleteService(subServiceId: string) {
		let index = (<FormArray>this.form.get("services")).controls.findIndex(
			control => control.value.subServiceId === subServiceId
		);

		this.servicesForms.removeAt(index);
	}

	getTPPAccounts(): void {
		this.tppAccountService
			.searchTPPAccounts([this.selectedCountry], [], this.selectedService.id, 0, -1)
			.pipe(take(1))
			.subscribe((response: TppAccount[]) => {
				let filteredResponse = response.filter(obj => obj.service === this.selectedService.id);

				this.tppAccounts = filteredResponse;
				this.beneficiarySelectOptions = new TppaccountSelectOptionsPipe().transform(filteredResponse);

				this.getServiceDefinitions();
			});
	}

	getServiceDefinitions() {
		this.serviceDefinitionServices = [];

		this.tppServiceDefinitionService
			.getServiceDefinitions(this.selectedPayGroupId, "0", "-1")
			.pipe(takeUntil(this.destroy$))
			.subscribe(result => {
				if (result.items.length) {
					for (let i = 0; i < result.items.length; i++) {
						let object = result.items[i];
						for (let j = 0; j < object.services.length; j++) {
							this.serviceDefinitionServices.push(object.services[j]);
						}

						if (i === result.items.length - 1) {
							this.formsInitialized = true;
						}
					}
				} else {
					this.formsInitialized = true;
				}
			});
	}

	getSubServiceData(event: { action: string; subService: SubServicePaymentDefinition }) {
		if (event.action === "check") {
			const formArray = this.form.get("services") as FormArray;
			let index = formArray.controls.findIndex(control => control.value.subServiceId === event.subService.id);

			if (index === -1) {
				//add if subservice doesnt exist in service def yet
				this.addService(event.subService.id!);

				this.subServiceList.push(event.subService.id!);
			}

			this.onInvalidStepOneDisableNextButton();
		} else {
			this.subServiceList = this.subServiceList.filter(subService => {
				return subService !== event.subService.id;
			});

			this.deleteService(event.subService.id!);

			if (!this.subServiceList.length) {
				this.nextColor = "#8992A3";
				this.formDataAvailable = false;
			} else {
				this.onInvalidStepOneDisableNextButton();
			}
		}
	}

	patchChanges(event: {
		beneficiary?: string;
		beneficiaryName?: string;
		group?: string;
		frequency?: string;
		managed_in?: string;
		externalId?: string;
		subService: SubServicePaymentDefinition;
		changedInput: string;
	}) {
		const formArray = this.form.get("services") as FormArray;

		let index = formArray.controls.findIndex(control => control.value.subServiceId === event.subService.id);

		if (index !== -1) {
			if (event.changedInput === "beneficiary") {
				let data: ClientSpecificSubService[] = this.tppServiceDefinitionService.getFormSubServices();
				if (data.length) {
					this.formClearReset();
				}
				this.servicesForms.controls[index].patchValue({ beneficiaryId: event.beneficiary });
				this.servicesForms.controls[index].patchValue({ beneficiaryName: event.beneficiaryName });
			} else if (event.changedInput === "group") {
				//

				let data: ClientSpecificSubService[] = this.tppServiceDefinitionService.getFormSubServices();
				if (data.length) {
					this.formClearReset();
				}
				this.servicesForms.controls[index].patchValue({ group: event.group });
				this.nextColor = "#2E62FF";
			} else if (event.changedInput === "externalId") {
				let data: ClientSpecificSubService[] = this.tppServiceDefinitionService.getFormSubServices();
				if (data.length) {
					this.formClearReset();
				}
				this.servicesForms.controls[index].patchValue({ externalId: event.externalId });
				this.nextColor = "#2E62FF";
			} else if (event.changedInput === "frequency") {
				this.nextColor = "#2E62FF";

				this.formClearReset();

				formArray.controls[index].patchValue({ frequency: event.frequency });

				this.tppServiceDefinitionService.setFormSubServices(this.form.getRawValue().services);
			} else if (event.changedInput === "managed_in") {
				this.nextColor = "#2E62FF";

				this.formClearReset();

				formArray.controls[index].patchValue({ managedIn: event.managed_in });

				this.tppServiceDefinitionService.setFormSubServices(this.form.getRawValue().services);
			}

			this.onInvalidStepOneDisableNextButton();
		}
	}

	formClearReset() {
		const data: ClientSpecificSubService[] = this.tppServiceDefinitionService.getFormSubServices();
		const formArray = this.form.get("services") as FormArray;
		formArray.clear();

		data.forEach(formsubService => {
			const formGroup = new UntypedFormGroup({
				serviceId: new UntypedFormControl(formsubService.serviceId),
				subServiceId: new UntypedFormControl(formsubService.subServiceId),
				group: new UntypedFormControl(formsubService.group),
				beneficiaryId: new UntypedFormControl(formsubService.beneficiaryId),
				beneficiaryName: new UntypedFormControl(formsubService.beneficiaryName),
				externalId: new UntypedFormControl(formsubService.externalId),
				frequency: new UntypedFormControl(formsubService.frequency),
				managedIn: new UntypedFormControl(formsubService.managedIn)
			});
			formArray.push(formGroup);
		});
	}

	goPrevious() {
		if (this.definitionStep === 1) {
			this.router.navigate(["/service-definition/tpp/select-service"]);
		}

		if (this.definitionStep === 2) {
			this.definitionStep--;
			this.title = "Created sub services";
			const formArray = this.form.get("services") as FormArray;

			this.formClearReset();
		}

		this.definitionStepSetup();
	}

	goNext() {
		// check the service form array to make sure we have the data we need before we go next

		if (this.definitionStep === 1 && this.subServices.length) {
			if (this.formDataAvailable) {
				this.definitionStep++;
				this.tppServiceDefinitionService.setFormSubServices(this.form.getRawValue().services);
				this.title = "Sub services frequency details";
			}
			this.definitionStepSetup();

			this.subServicesFormData = this.tppServiceDefinitionService.getFormSubServices();
		} else {
			let servicesArray = this.form.getRawValue().services;

			this.tppServiceDefinitionService.tppServiceDefFormSubject$.pipe(take(1)).subscribe(res => {
				this.editServiceDefintion(res.formData);
			});

			this.tppServiceDefinitionService.subServiceFormDataSetup(this.selectedPayGroupId, servicesArray);
		}
	}

	createServiceDefintion(formData: TPPServiceDefinitionDTO) {
		this.tppServiceDefinitionService
			.createTPPServiceDefinition(formData)
			.pipe(take(1))
			.subscribe(res => {
				if (res) {
					this.reset("Created");
				}
			});
	}

	editServiceDefintion(formData: TPPServiceDefinitionDTO) {
		this.tppServiceDefinitionService
			.editTPPServiceDefinition(formData)
			.pipe(take(1))
			.subscribe(res => {
				if (res) {
					this.reset("Edited");
				}
			});
	}

	reset(action: string) {
		this.toastService.showSuccess(`Service Definition ${action} Successfully`);
		this.tppServiceDefinitionService.setFormSubServices([]);

		this.router.navigate([this.SELECT_TTP_SERVICE_ROUTE], {
			skipLocationChange: true
		});
	}

	skipStepOne(skip: boolean): void {
		if (skip) {
			this.goNext();
		}
	}

	goHome(): void {
		this.router.navigate(["/entity-select-tpp"]);
	}

	showInfoChange() {
		this.showInfo = !this.showInfo;
	}

	private onInvalidStepOneDisableNextButton() {
		this.formDataAvailable = this.isStepOneFormGroupValid();
	}

	private isStepOneFormGroupValid(): boolean {
		var formValid = true;

		this.servicesForms.controls.forEach(element => {
			if (!element.value.beneficiaryId || !element.value.group || !element.value.externalId) {
				formValid = false;
			}
			if (element.value.beneficiaryId == "" || element.value.group == "" || element.value.externalId == "") {
				formValid = false;
			}
		});

		return formValid;
	}

	filterServicesForDropDown(services: TppServicesByByPayGroupViewModel) {
		const providedData = services.servicesByServiceType;

		const filteredServices = providedData.reduce((acc: TtpService[], category) => {
			const filteredServicesInCategory = category.services.filter(service => !service.isAdhoc);
			if (filteredServicesInCategory.length > 0) {
				acc.push(...filteredServicesInCategory);
			}
			return acc;
		}, []);

		this.servicesDropDownData = filteredServices.map(res => ({
			id: res.id,
			name: res.name
		}));
	}

	regress(): void {
		this.router.navigate(["/service-definition/tpp/select-service"]);
	}

	reSelectService(id: string) {
		this.tppServicesService
			.findTppServiceByServiceId(id)
			.pipe(takeUntil(this.destroy$))
			.subscribe((service: TppService) => {
				this.store.dispatch(
					new UpdateSelectedTPPService({
						selectedTPPService: {
							countryCode: "",
							tppService: service,
							selectedServices: [service.type]
						}
					})
				);

				this.ngOnInit();
			});
	}
}
