import { TransactionStatus, Details } from "src/app/shared/models/payouts.types";

export enum AccountType {
	BANK = "BANK",
	CARD = "CARD"
}

export enum PaymentType {
	NET = "NETS",
	TPP = "TPP",
	EWA = "EWA",
	FALLBACK = "FALLBACK"
}

export type PayMethodStatus = {
	name: string;
	message: string[];
};

export enum CardType {
	"VISA" = "VISA",
	"MASTER_CARD" = "MASTER_CARD"
}

export type ProvidersStatuses = {
	[key: string]: {
		details: Details;
		name: string;
		messages: [];
	};
};

export type RankingMethodDTO = Pick<Method, "id" | "version" | "ranking">;

export type Method = {
	id: string;
	employeeId: string;
	payGroupId?: string;
	accountId: string;
	version: number;
	status: PayMethodStatus;
	providersStatuses: ProvidersStatuses;
	type: AccountType;
	paymentTypes: PaymentType[];
	effectiveFrom: string;
	effectiveTo?: string;
	ranking: {
		type: string;
		amount: number;
		rank: number;
	} | null;
};

export type Detail = {
	cardType?: CardType;
	expiry?: {
		month: number;
		year: number;
	};
	issuer?: {
		bankName: string;
		country: {
			code: string;
			flagPath: string;
			name: string;
		};
	};
	lastFour?: string;
	bankName: string;
	country: string;
	currency: string;
	fields: Record<string, string | number | boolean>[];
	version?: number;
	holder: {
		firstName: string;
		lastName: string;
	};
};

export type PayMethodDetail = {
	payMethod: Method;
	details: Detail;
};

export interface PayMethodsPagination {
	totalCount: number;
	totalPages: number;
	pageNumber: number;
	items: PayMethodDetail[];
}

export type EmployeeBankAccount = {
	id: string;
	version: number;
	bankName: string;
	country: string;
	currency: string;
	fields: Record<string, string>[];
};

export interface UpdateEmployeeBankAccountDTO extends Partial<Method> {
	bankAccount?: EmployeeBankAccount;
}

export interface UpdateEmployeeBankAccountRankDTO {
	payMethods: RankingMethodDTO[];
}

export interface Ranking {
	type: string;
	amount: number;
	rank: number;
}

export interface createEmployeeBankAccountDTO extends Partial<Method>, Partial<EmployeeBankAccount> {
	validateProviders: boolean;
}

export interface TransactionData {
	date: string;
	paymentMethod: string;
	paymentMethodType: string;
	amount: number;
	currency: string;
	type: string;
	payoutAmount: number;
	status?: TransactionStatus;
	ranking: Ranking;
}
