import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PermissionErrorComponent } from "./permission-error.component";
import { NoItemsLayoutModule } from "@shared/components/no-items-layout/no-items-layout.module";

@NgModule({
	declarations: [PermissionErrorComponent],
	imports: [CommonModule, NoItemsLayoutModule],
	exports: [PermissionErrorComponent]
})
export class PermissionErrorModule {}
