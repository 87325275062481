import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Customer } from "src/app/shared/models/customer.interface";

@Component({
	selector: "app-payment-calendar",
	templateUrl: "./payment-calendar.component.html",
	styleUrls: ["./payment-calendar.component.scss"]
})
export class PaymentCalendarComponent implements OnInit {
	constructor(private router: Router) {}

	ngOnInit(): void {}

	customerSelected(customer: Customer) {
		this.router.navigate(["/calendar/paygroup-list"], { state: { customer } });
	}
}
