<div [formGroup]="searchForm" class="search-section flex flex-align-center-space-between">
	<mat-form-field class="search-field" id="master-search-field" appearance="outline">
		<app-icon
			id="master-search-icon"
			[name]="'search-normal-1'"
			[size]="'25'"
			[lineWidth]="'2px'"
			[color]="'var(--color-search-icon)'"
			matPrefix
		></app-icon>
		<input id="search" matInput placeholder="Search by..." formControlName="search" />
	</mat-form-field>
</div>

<div class="history-header-container flex flex-row flex-align-center-start">
	<div class="flex-1" style="--max-width: 5%"></div>
	<div id="user" class="flex-1" style="--max-width: 30%">USER</div>
	<div id="created_at" class="flex-1" style="--max-width: 45%">CREATED AT</div>
	<div id="stauts" class="flex-1" style="--max-width: 20%">STATUS</div>
</div>
<div class="scroll-container">
	<div *ngIf="amlHistoryList$ | async as amlHistoryList">
		<ng-container *ngIf="!amlHistoryList.length; else errorTemplate">
			<div class="history-item-container flex flex-row flex-align-center-start">
				<p>There is no data to display</p>
			</div>
		</ng-container>
		<ng-template #errorTemplate>
			<ng-container *ngFor="let data of amlHistoryList$ | async">
				<div class="history-item-container flex flex-row flex-align-center-start">
					<div class="data-row flex flex-row" *ngIf="data">
						<div class="flex-1" style="--max-width: 5%"></div>
						<div id="user" class="flex-1" style="--max-width: 30%">
							{{ data.firstName }} {{ data.lastName }}
						</div>
						<div id="created_at" class="flex-1" style="--max-width: 45%">
							{{ data.createdAt.toDateString() }}
						</div>
						<div id="stauts" class="flex-1" style="--max-width: 20%">
							<div
								*ngIf="data.status"
								[ngClass]="{
									'text-success': data.status === 'APPROVED',
									'text-rejected': data.status === 'REJECTED',
									'text-on-review': data.status === 'ON REVIEW'
								}"
							>
								<span
									><b>{{ data.status }}</b></span
								>
							</div>
						</div>
					</div>
				</div>
			</ng-container>
		</ng-template>
	</div>
</div>
