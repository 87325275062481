<div class="payment-type-container" *ngFor="let typeRoute of typeRoutes; let typeRouteIndex = index">
	<div class="payment-type-title">
		{{ typeRoute.type }}
	</div>
	<div class="accounts-container">
		<div class="account-card" *ngFor="let route of typeRoute.routes; let routeIndex = index">
			<div class="route-container">{{ route.providerName }} > {{ route.payoutAccount }} > {{ route.route }}</div>
			<div class="content-container">
				<div class="main-col">
					<h4>Account Currency</h4>
					<mat-form-field class="select-settlement-account" appearance="outline">
						<mat-select
							id="{{
								'fundingCurrencyInput' +
									typeRoute.type +
									route.providerName +
									route.payoutAccount +
									route.route
							}}"
							[(ngModel)]="route.selectedCurrency"
							[disabled]="!canEditSettlementAccounts"
							(selectionChange)="getAvailableAccountsForRoute(typeRouteIndex, routeIndex)"
						>
							<mat-option *ngFor="let currency of route.currencies" [value]="currency">
								{{ currency }}
							</mat-option>
						</mat-select>
						<mat-select-trigger class="drop-down-trigger">
							<app-icon
								id="drop-down-arrow-select"
								[color]="'rgba(0, 0, 0, 0.54)'"
								[lineWidth]="'2.5'"
								[name]="'arrow-down'"
								[size]="'16'"
							></app-icon>
						</mat-select-trigger>
					</mat-form-field>
				</div>
				<div class="utility-col">
					<app-icon [name]="'arrow-right'"></app-icon>
				</div>
				<div class="main-col">
					<h4>Choose existing account</h4>
					<mat-form-field appearance="outline" class="select-settlement-account">
						<mat-select
							id="{{
								'chooseAccountInput' +
									typeRoute.type +
									route.providerName +
									route.payoutAccount +
									route.route
							}}"
							[disabled]="!canEditSettlementAccounts"
							[(ngModel)]="route.selectedAccountId"
							(selectionChange)="selectAccountForRoute(typeRouteIndex, routeIndex)"
						>
							<mat-option *ngFor="let account of route.availableAccounts" [value]="account.id">
								{{ account.name }}
							</mat-option>
						</mat-select>
						<mat-select-trigger class="drop-down-trigger">
							<app-icon
								id="drop-down-arrow-select"
								[color]="'rgba(0, 0, 0, 0.54)'"
								[lineWidth]="'2.5'"
								[name]="'arrow-down'"
								[size]="'16'"
							></app-icon>
						</mat-select-trigger>
					</mat-form-field>
					<button
						*ngIf="canAddSettlementAccounts"
						id="{{
							'newAccountButton' + typeRoute.type + route.providerName + route.payoutAccount + route.route
						}}"
						mat-button
						color="primary"
						class="add-button"
						(click)="openForm(route)"
						[disabled]="
							!!route.selectedCurrency === false ||
							!!route.account.id !== false ||
							!canAddSettlementAccounts
						"
					>
						+ Add new settlement account
					</button>
				</div>
				<div class="buttons-col">
					<button
						id="{{
							'viewAccountButton' +
								typeRoute.type +
								route.providerName +
								route.payoutAccount +
								route.route
						}}"
						mat-button
						(click)="openForm(route)"
						*ngIf="route.account && route.account?.name"
					>
						<app-icon id="viewAccountButtonnameIcon" [name]="'eye'"></app-icon>
					</button>
					<ng-template [appUserRolePermissions] [action]="'hide'" [roles]="['TS_VO', 'CLIENT']">
						<button
							id="{{
								'clearButton' + typeRoute.type + route.providerName + route.payoutAccount + route.route
							}}"
							mat-stroked-button
							(click)="clearRouteAccount(typeRouteIndex, routeIndex)"
							[disabled]="!canEditSettlementAccounts"
						>
							Clear
						</button>
					</ng-template>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="bottom-button-container">
	<button id="backButton" mat-stroked-button (click)="goBack()" class="button-start">Back</button>
	<button id="nextButton" mat-raised-button color="dark" (click)="goForward()" class="button-end">Finish</button>
</div>

<!-- <modal-wrapper
	*ngIf="showForm"
	title="Add new service providers settlement account"
	[breadcrumb]="[
		{ display: true, crumb: selectedRoute.providerName },
		{ display: true, crumb: selectedRoute.payoutAccount },
		{ display: true, crumb: selectedRoute.route },
		{ display: true, crumb: selectedRoute.selectedCurrency }
	]"
	[modalFullHeight]="true"
	(onTitleButtonAction)="showBread()"
> -->

<modal-wrapper
	*ngIf="showForm"
	title="{{ !this.editMode ? 'Add' : 'Edit' }} Settlement Account"
	(onTitleButtonAction)="showBread()"
	[modalStyle]="'dark'"
	[noBreadCrumbs]="true"
	[exitIconBool]="true"
	[subHeaderColour]="'transparent'"
	[modalFullHeight]="true"
	class="add-ben-modal-wrapper"
>
	<div class="form-content">
		<div class="form-section">
			<!-- GLOBAL FIELDS -->
			<div class="o-grid-3 full-width">
				<ng-container [formGroup]="globalForm">
					<!-- Account name -->
					<input-wrapper class="select" label="Account Name *">
						<input id="accountNameInput" type="text" placeholder="Account name" formControlName="name" />
						<div
							input-errors
							*ngIf="globalForm.controls.name.touched && globalForm.controls.name.errors?.required"
						>
							Account name is required
						</div>
					</input-wrapper>

					<!-- Country selector -->
					<input-wrapper class="select" label="Select a country">
						<input-autocomplete
							id="countrySelectInput"
							[options]="selectOptionsCountries$ | async"
							label="All countries"
							formControlName="country"
							[disabled]="editMode || !canEditSettlementAccounts"
						>
						</input-autocomplete>
					</input-wrapper>

					<!-- Default currency -->
					<input-wrapper class="select" label="Settlement currency">
						<input id="currencyInput" type="text" formControlName="currency" />
					</input-wrapper>
				</ng-container>
			</div>

			<!-- Separator -->
			<div class="separator" *ngIf="accessFieldsComponent && accessFieldsComponent.accessFields.length > 0"></div>

			<!-- ACCESS FIELDS -->
			<access-fields
				[canEdit]="canEditSettlementAccounts"
				[selectedRoute]="selectedRoute"
				[values]="accessFieldsValues"
			></access-fields>

			<!-- Separator -->
			<div class="separator"></div>

			<!-- BANK ACCOUNT FIELDS -->
			<bank-account-fields
				[country]="selectedCountry"
				[bankName]="selectedRoute.account.bankAccount ? selectedRoute.account.bankAccount.bankName : ''"
				[values]="bankAccountValues"
				[editing]="editMode"
				[canEdit]="canEditSettlementAccounts"
			>
			</bank-account-fields>

			<div class="buttons-container settle-account-buttons">
				<button id="cancelButton" mat-stroked-button class="button-start" (click)="showBread()">Cancel</button>
				<button
					id="saveButton"
					mat-raised-button
					color="dark"
					class="button-end"
					(click)="save()"
					[disabled]="
						bankAccountFieldsComponent?.bankAccountForm?.invalid ||
						accessFieldsComponent?.accessFieldsForm?.invalid ||
						globalForm.invalid
					"
				>
					Save
				</button>
			</div>
		</div>
	</div>
</modal-wrapper>
