import { Injectable } from "@angular/core";
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from "@ngrx/data";
import { ErrorModel, ErrorModelPagination } from "../models/error-management.models";

@Injectable({
	providedIn: "root"
})
export class ErrorManagementEntityService extends EntityCollectionServiceBase<ErrorModelPagination> {
	constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
		super("ErrorManagement", serviceElementsFactory);
	}
}
