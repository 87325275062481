import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";

import { ToastService } from "@shared/services/toast/toast.service";

@Injectable({
	providedIn: "root"
})
export class HttpErrorInterceptor implements HttpInterceptor {
	constructor(public toast: ToastService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError((error: HttpErrorResponse) => {
				if (Array.isArray(error.error)) {
					this.showManyErrors(error.error);
				} else {
					this.showSingleError(error);
				}

				return throwError(() => error);
			})
		);
	}

	private showManyErrors(errors: HttpErrorResponse[]): void {
		let errorsMessage = "";
		errors.forEach(singleError => {
			errorsMessage += singleError.message + ". ";
		});
		this.toast.showError(errorsMessage);
	}

	private showSingleError(error: HttpErrorResponse): Observable<never> | void {
		if (error.status === 403) {
			this.toast.showError("Your account has not been granted permissions. Please contact the administrator");

			return throwError(
				() => new Error("Your account has not been granted permissions. Please contact the administrator")
			);
		}

		if (error.status === 409) {
			console.log(error);
			this.toast.showError(error.error.message);
		}

		if (error.status === 400 && error.error.code === "TaskNotCompleted") {
			return throwError(() => error);
		}

		if (error.error.message && (error.status !== 422 || 200)) {
			if (
				error.error.message.includes("TPP service definition not found") ||
				error.error.message.includes("'TPPServiceDefinition' is not found by id")
			) {
			} else {
				this.toast.showError(error.error.message);
			}
		}
	}
}
