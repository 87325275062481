import { HttpParams } from "@angular/common/http";
import { ApiSearchTPPServiceDefinitionsRequestV1 } from "../model/request/ApiSearchTppServiceDefinitionsRequest";

export class TtpServiceDefintionClientMapper {
	static mapSearchRequest(request: ApiSearchTPPServiceDefinitionsRequestV1): HttpParams {
		let params = new HttpParams();
		params = params.set("payGroupIds", request.payGroupIds);
		params = params.set("pageNumber", request.pageNumber);
		params = params.set("pageSize", request.pageSize);
		return params;
	}
}
