import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function stringMatchValidator(externalId: string): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (externalId?.toLowerCase() === control?.value?.toLowerCase()) {
			return { duplicateId: { value: control.value } };
		}
		return null;
	};
}
