import { Injectable, OnDestroy } from "@angular/core";
import { filter, map, takeUntil, tap } from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import { FormGroup } from "@angular/forms";
import { SettlementAccount, SettlementAccountPagination } from "src/app/shared/models/settlement-account.interface";

import { AccountField } from "src/app/shared/models/account-field.interface";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { SettlementAccountPost, SettlementAccountPut } from "../../_models/settlement-accounts.interface";

@Injectable()
export class SettlementAccountService implements OnDestroy {
	destroy$: Subject<void> = new Subject<void>();
	paymentTypesValue$: Subject<string> = new Subject<string>();

	constructor(private readonly http: HttpClient) {}

	paymentTypesSelectEvent(settlementAccountForm: FormGroup) {
		//On select create new form with selected option as title header
		settlementAccountForm
			.get("paymentTypes")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe((paymentTypesValue: string) => {
				if (paymentTypesValue) {
					this.paymentTypesValue$.next(paymentTypesValue);
				}
			});
	}

	getAccountsByLegalEntityId(legalEntityId: string): Observable<SettlementAccount[]> {
		return this.http
			.get<SettlementAccountPagination>(
				`${environment.apiUri}/v1/accounts/settlement?legalEntityId=${legalEntityId}&pageSize=-1`
			)
			.pipe(
				//we don't need the pagination data so only return the items array from the object
				map((res: SettlementAccountPagination) => {
					return res.items;
				})
			);
	}

	getProviderAccountDetails(providerCountryId: string): Observable<AccountField[]> {
		return this.http.get<AccountField[]>(
			`${environment.apiUri}/v1/definition/payment-providers/provider-countries/${providerCountryId}/account-fields`
		);
	}

	createSettlementAccount(settlementAccount: SettlementAccountPost) {
		return this.http.post(`${environment.apiUri}/v1/accounts/settlement`, settlementAccount);
	}

	updateSettlementAccount(settlementAccount: SettlementAccountPut) {
		return this.http.put(`${environment.apiUri}/v1/accounts/settlement`, settlementAccount);
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
