<div
	class="counter white flex flex-column"
	(mouseenter)="setTooltip(true)"
	(mouseleave)="setTooltip(false)"
	*ngIf="type === 'white-milestones-day-counter' && date"
>
	<span class="count flex flex-row">{{ date.milestoneArray.length }}</span>
	<app-tooltip
		class="flex flex-row"
		*ngIf="showTooltip"
		[containerBorder]="true"
		[contanierColor]="'#FFFFFF'"
		[position]="'calendar-bottom'"
		[containerPadding]="'15px 110px 15px 32px'"
		[containerMinWidth]="230"
		[containerMarginLeft]="'-7rem'"
		[trianlePosition]="'-0.25rem'"
	>
		<div class="tooltip-content">
			<span class="tooltip-date flex flex-row"
				>{{ date.date | date : "MMMM" }} {{ date.date | date : "d" }}
				<div class="position">{{ date.date | date : "d" | datePosition | lowercase }}</div>
				&nbsp;{{ date.date | date : "yyyy" }}
			</span>
			<div class="tooltip-milestone flex flex-column" *ngFor="let mile of date.milestoneArray">
				<span class="tooltip-group">GROUP {{ mile.group | uppercase }}</span>
				<span class="tooltip-type">
					<app-icon
						class="icon"
						[size]="'15'"
						[color]="'var(--color-disabled)'"
						[name]="getIconName(mile.type)"
					></app-icon>

					{{ mile | milestoneTypes | uppercase }}
				</span>
			</div>
		</div>
	</app-tooltip>
</div>

<div
	class="counter black flex flex-column"
	(mouseenter)="setTooltip(true)"
	(mouseleave)="setTooltip(false)"
	*ngIf="type === 'dark-milestone-counter' && milestoneCounter > 0"
>
	<app-tooltip
		class="flex flex-row"
		*ngIf="showTooltip"
		[position]="'calendar-top'"
		[containerMarginLeft]="'-5rem'"
		[containerMarginTop]="'-' + milestoneTypeMargin + 'rem'"
		[trianlePosition]="'-0.4rem'"
	>
		<div class="tooltip-content" *ngIf="milestoneTypeCount">
			<div class="milestone-types" *ngFor="let milestone of this.milestoneTypeCount">
				<span class="count"> > {{ milestone.text }} ({{ milestone.count }})</span>
			</div>
		</div>
	</app-tooltip>
	<span class="count flex flex-row">{{ milestoneCounter }}</span>
</div>
