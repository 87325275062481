import { Component, EventEmitter, Output } from "@angular/core";

@Component({
	selector: "app-net-payment-actions",
	templateUrl: "./net-payment-actions.component.html",
	styleUrls: ["./net-payment-actions.component.scss"]
})
export class NetPaymentActionsComponent {
	isPaymentOrderMenuOpen = false;

	@Output() refreshHandler = new EventEmitter<void>();
	@Output() goToFileHistoryHandler = new EventEmitter<void>();

	refresh(): void {
		this.refreshHandler.emit();
	}

	goToFileHistory(): void {
		this.goToFileHistoryHandler.emit();
	}
}
