<mat-card class="card-legal" (click)="legalEntitySelected()">
	<div class="entity-legal-name">{{ legalEntity.data.name }}</div>
	<div class="entity-legal">
		<div class="entity-legal-flag">
			<img src="/assets/svg-country-flags/svg/{{ legalEntity.data.country | lowercase }}.svg" />
		</div>

		<div class="entity-legal-id">{{ legalEntity.data.externalId }}</div>
	</div>
</mat-card>
