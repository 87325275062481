import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { PaymentType, PayMethodDetail } from "@modules/employee-data/types/accounts";

@Component({
	selector: "app-pay-elective-inactive-payment-methods-list",
	templateUrl: "./pay-elective-inactive-payment-methods-list.component.html",
	styleUrls: ["./pay-elective-inactive-payment-methods-list.component.scss"]
})
export class PayElectiveInactivePaymentMethodsListComponent implements OnInit {
	@Input() inactivePaymentMethods!: Observable<PayMethodDetail[]>;
	@Input() providerName!: string;

	net: PaymentType = PaymentType.NET;
	ewa: PaymentType = PaymentType.EWA;
	ttp: PaymentType = PaymentType.TPP;

	private destroy$: Subject<void> = new Subject();

	@Output() toggleCreatingBankAccountEvent$ = new EventEmitter<any>();
	@Output() editBankAccountEvent$ = new EventEmitter<{ accountId: string; payMethodsLength: number }>();

	inactivePaymentMethodsArray!: any[];

	constructor() {}

	ngOnInit(): void {
		this.inactivePaymentMethods.pipe(takeUntil(this.destroy$)).subscribe(data => {
			this.inactivePaymentMethodsArray = data;
		});
	}

	onClickEditBankAccount(accountId: string, payMethodsLength: number): void {
		this.editBankAccountEvent$.emit({ accountId: accountId, payMethodsLength: payMethodsLength });
	}

	onClickToggleCreatingBankAccount(): void {
		this.toggleCreatingBankAccountEvent$.emit("toggle");
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
