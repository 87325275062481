import { Action } from "@ngrx/store";
import { ENetPaymentActions, NetPaymentActions } from "../actions/statusNetPayment.action";
import { INetPaymentState } from "../models/netPayment.model";

export const initialNetPaymentState: INetPaymentState = {
	statusPayment: []
};

export const netPaymentReducer = (state = initialNetPaymentState, action: Action): INetPaymentState => {
	const selectedAction = action as NetPaymentActions;

	switch (selectedAction.type) {
		case ENetPaymentActions.SetStatus: {
			return {
				...state,
				statusPayment: selectedAction.payload.statusPayment
			};
		}
		case ENetPaymentActions.ResetStatus: {
			return {
				...state,
				statusPayment: []
			};
		}
		default:
			return state;
	}
};
