<div
	#parent
	(mousedown)="startDragging($event, true)"
	(mouseup)="stopDragging($event, false)"
	(mouseleave)="stopDragging($event, false)"
	(mousemove)="moveEvent($event)"
	[ngStyle]="{ cursor: isKPICardsZero ? 'default' : 'grab' }"
	[@paddingChange]="isKPICardsZero ? 'reduced' : 'default'"
	@expandCollapse
	class="gb-card-container"
>
	<app-card
		*ngFor="let kpiCard of kpiCards; last as isLast"
		[kpiType]="getKpiCardName(kpiCard)"
		[selectedKpi]="getKpiCardName(kpiCard) === kpiSelected"
		[last]="isLast"
		[card]="kpiCard"
		[loadingCard]="showLoadingCards"
		[isOnGlobalDashboard]="isOnGlobalDashboard"
		(kpiSelected)="kpiSelectedEvent.emit($event)"
	></app-card>
</div>
