import { Action } from "@ngrx/store";
import { CalendarStoreSelect } from "src/app/shared/models/calendar-navigation";

export enum CalendarMonthSelectActionType {
	SET_SELECTED = "[PayCycleSelected] Set Selected"
}
export class UpdateSelectedCalendarMonth implements Action {
	readonly type = CalendarMonthSelectActionType.SET_SELECTED;

	//add an optional payload
	constructor(public payload: CalendarStoreSelect) {}
}
export type CalendarMonthSelectAction = UpdateSelectedCalendarMonth;
