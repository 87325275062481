import { Action } from "@ngrx/store";
import { TppAccount } from "src/app/shared/models/tpp-account.interface";

export enum BeneficiaryActionType {
	SET_SELECTED = "[BeneficiarySelected] Set Selected"
}
export class UpdateBeneficiary implements Action {
	readonly type = BeneficiaryActionType.SET_SELECTED;

	//add an optional payload
	constructor(public payload: { beneficiary: TppAccount }) {}
}
export type BeneficiarySetAction = UpdateBeneficiary;
