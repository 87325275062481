<h3>Edit Notes</h3>
<div class="content" mat-dialog-content>
	<mat-form-field id="note" class="full-width" appearance="fill">
		<textarea [(ngModel)]="data.notes" matInput rows="8" [cols]="20" [value]="data.notes"></textarea>
	</mat-form-field>
</div>
<div align="end" mat-dialog-actions>
	<button id="closeNote" action-buttons mat-stroked-button (click)="onNoClick()">Close</button>
	<button id="saveNote" action-buttons color="dark" mat-flat-button (click)="onClose()" cdkFocusInitial>Save</button>
</div>
