<form [formGroup]="countrySelectForm">
	<div class="o-grid-3 offset-2">
		<input-wrapper class="select" label="AVAILABLE COUNTRIES">
			<input-autocomplete formControlName="country" [options]="countries$ | async" [label]="'Select country'">
			</input-autocomplete>
		</input-wrapper>
	</div>

	<div class="o-grid-3 offset-1">
		<input-wrapper class="select" label="COPY FIELDS FROM">
			<input-autocomplete
				formControlName="copyFromCountry"
				[options]="countries$ | async"
				[label]="'Select country'"
			>
			</input-autocomplete>
		</input-wrapper>
		<div class="add-button">
			<button (click)="confirmCopy()" mat-button [disabled]="!canCopy" class="c-button-dashed" color="primary">
				<mat-icon>file_copy</mat-icon>copy fields
			</button>
		</div>
	</div>
</form>
