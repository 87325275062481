import { Injectable } from "@angular/core";
import { NgElement, WithProperties } from "@angular/elements";
import { MapPopupComponent } from "../_components/map-popup/map-popup.component";

@Injectable({
	providedIn: "root"
})
export class PopupService {
	makeCountryPopup(code: string, name: string): L.Content {
		const popupEl: NgElement & WithProperties<MapPopupComponent> = document.createElement("popup-element") as any;

		popupEl.addEventListener("closed", () => document.body.removeChild(popupEl));
		popupEl.countryCode = code;
		popupEl.countryName = name;

		document.body.appendChild(popupEl);
		return popupEl;
	}
}
