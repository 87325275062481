<div class="payment-orders-sticky-header">
	<div class="sticky-header-button-container">
		<app-icon
			name="exit-back-arrow"
			class="exit-arrow"
			[lineWidth]="'4'"
			[color]="'white'"
			[size]="'34'"
			(click)="goBack()"
		></app-icon>
	</div>
	<div class="sticky-header-title-container">
		<div class="sticky-header-title-container-column">
			<div class="xl-section-name">
				<span class="sub-title-flag">
					<img src="assets/svg-country-flags/svg/{{ flag.toLowerCase() }}.svg"
				/></span>
				{{ subtitle }}
			</div>
			<div class="xs-header-breadcrumb sticky-header-text">{{ title }}</div>
		</div>
	</div>
	<div class="sticky-header-button-container">
		<app-icon
			name="exit-button"
			class="back-btn"
			[lineWidth]="'4'"
			[color]="'white'"
			[size]="'28'"
			(click)="goBack()"
		></app-icon>
	</div>
</div>
