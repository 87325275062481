import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../../environments/environment";
import { PayElectivePayGroupBeneficiaryPagination } from "src/app/shared/models/employee.interface";
import {
	Employee,
	EmployeeCreateDTO,
	EmployeePagination,
	EmployeeReportPagination,
	EmployeeReportPayGroupData,
	PayElectivePayGroupPagination
} from "../../../shared/models/employee.interface";

@Injectable({
	providedIn: "root"
})
export class PayElectiveApiService {
	private sortField = "data.lastName";
	private sortDir = "ASC";

	constructor(private http: HttpClient) {}

	getEmployeesByPaygroupId(
		id: string,
		pageSize: number,
		pageNumber: number,
		provider: string = "",
		status: string = "",
		queryString: string = "",
		hasBank: boolean | null = null,
		hasCard: boolean | null = null
	): Observable<EmployeePagination> {
		return this.http.get<EmployeePagination>(
			`${
				environment.apiUri
			}/v1/employees?payGroupIds=${id}&pageSize=${pageSize}&pageNumber=${pageNumber}&statuses=${status}&providerName=${
				provider === "all" ? "" : provider
			}&queryString=${queryString}&havingBank=${hasBank}&havingCard=${hasCard}&sortField=${
				this.sortField
			}&sortDir=${this.sortDir}`
		);
	}

	getEmployeesPayGroupData(
		customerId: string,
		legalEntityId: string[],
		paygroups: string[]
	): Observable<PayElectivePayGroupBeneficiaryPagination> {
		return this.http.get<PayElectivePayGroupBeneficiaryPagination>(
			`${environment.apiUri}/v3/employees/reports/beneficiaries?customerIds=${customerId}&legalEntityIds=${legalEntityId}&payGroupIds=${paygroups}`
		);
	}

	getEmployeeReport(customerId: string, payGroupId: string): Observable<EmployeeReportPagination> {
		return this.http.get<EmployeeReportPagination>(
			`${environment.apiUri}/v3/employees/reports/beneficiaries?payGroupIds=${payGroupId}&customerIds=${customerId}`
		);
	}

	createNewEmployee(employee: EmployeeCreateDTO): Observable<Employee> {
		return this.http.post<Employee>(`${environment.apiUri}/v1/employees`, employee);
	}

	updateEmployee(employee: EmployeeCreateDTO): Observable<Employee> {
		return this.http.put<Employee>(`${environment.apiUri}/v1/employees`, employee);
	}
}
