import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "underscoreRemover"
})
export class UnderscoreRemoverPipe implements PipeTransform {
	transform(value: string): string {
		let newVal = value.replace(/_/g, " ");
		return newVal;
	}
}
