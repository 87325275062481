import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "checkCard"
})
export class CheckCardPipe implements PipeTransform {
	transform(value: string): boolean {
		return value === "CARD";
	}
}
