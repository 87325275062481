import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { SelectOption } from "../../../../shared/models/select-option.interface";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

@Component({
	selector: "app-error-list-filters",
	templateUrl: "./error-list-filters.component.html",
	styleUrls: ["./error-list-filters.component.scss"]
})
export class ErrorListFiltersComponent implements OnInit {
	form!: FormGroup;

	@Input() providers!: Observable<SelectOption[]>;
	@Input() errorTypes!: Observable<SelectOption[]>;
	@Output() filterData: EventEmitter<{ searchString: string; errorType: string; provider: string }> =
		new EventEmitter<{ searchString: string; errorType: string; provider: string }>();

	constructor(private formBuilder: FormBuilder) {}

	ngOnInit(): void {
		this.initForm();
	}

	initForm(): void {
		this.form = this.formBuilder.group({
			searchString: [""],
			errorType: [""],
			provider: []
		});

		this.form.valueChanges.pipe(debounceTime(750), distinctUntilChanged()).subscribe(res => {
			console.log(res);
			const searchFilters = {
				searchString: res.searchString ? res.searchString : "",
				errorType: res.errorType ? res.errorType : "",
				provider: res.provider ? res.provider : ""
			};
			this.filterData.emit(searchFilters);
		});
	}
}
