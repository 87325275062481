<div class="summary-container" *ngIf="summary && this.selectedFiltersForm" fxLayout="row" style="margin-top: 25px">
	<div fxLayout="row" fxLayoutAlign="space-between" fxLayout="'row'" fxFlex="100">
		<div fxLayout="'row'">
			<div class="status-section" fxLayout="column">
				<span class="header">BENEFICIARY STATUS</span>
				<div fxLayoutGap="20px" fxLayoutAlign="start center" [fxLayout]="'row'">
					<div class="status" fxLayout="row" fxLayoutAlign="center">
						<span class="dot failed"> </span>
						<div class="information-subtitle-text">
							({{ summary.beneficiaryStatus.totalFailedBeneficiaries }})
						</div>
					</div>
					<div class="status" fxLayout="row" fxLayoutAlign="center">
						<span class="dot active"> </span>
						<div class="information-subtitle-text">
							({{ summary.beneficiaryStatus.totalValidBeneficiaries }})
						</div>
					</div>
					<div class="status" fxLayout="row" fxLayoutAlign="center">
						<span class="dot processing"> </span>
						<div class="information-subtitle-text">
							({{ summary.beneficiaryStatus.totalProcessingBeneficiaries }})
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="summary.totalsByProvider.length > 0" class="vertical-hr"></div>
			<div *ngIf="summary.totalsByProvider.length > 0" class="status-section" fxLayout="column">
				<span class="header">SERVICE PROVIDER ERRORS</span>
				<div fxLayoutGap="20px" fxLayoutAlign="start center" [fxLayout]="'row'">
					<div
						*ngFor="let provider of summary.totalsByProvider"
						class="status"
						fxLayout="row"
						fxLayoutAlign="center"
					>
						<div class="information-subtitle-text">
							{{ provider.providerName }}
							<span [ngClass]="shouldShowProviderFailedClass(provider) ? providerFailedClass : ''">
								({{ provider.totalErrors }})</span
							>
						</div>
					</div>
				</div>
			</div>
		</div>

		<form [formGroup]="selectedFiltersForm" fxLayoutAlign="end center">
			<input-wrapper label="PROVIDER" style="margin-right: 15px">
				<input-select
					formControlName="providerFilter"
					[_options]="providerSelectOptions"
					[value]="DEFAULT_PROVIDER_SELECT_OPTION"
					[_disabled]="providerSelectOptions.length === 0"
				>
				</input-select>
			</input-wrapper>
			<input-wrapper label="STATUS">
				<input-select
					formControlName="beneficiaryStatusFilter"
					[_options]="STATUS_SELECT_OPTIONS"
					[value]="DEFAULT_STATUS_SELECT_OPTION"
				>
				</input-select>
			</input-wrapper>
		</form>
	</div>
</div>
