import { Component, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
	selector: "app-service-provider-costs",
	templateUrl: "./service-provider-costs.component.html",
	styleUrls: ["./service-provider-costs.component.scss"]
})
export class ServiceProviderCostsComponent implements OnInit {
	costsForm!: FormGroup;

	constructor() {}

	ngOnInit(): void {}
}
