import { Pipe, PipeTransform } from "@angular/core";

import { CalendarFileStatus } from "../model/calendar-file.model";

@Pipe({
	name: "calendarFileStatus"
})
export class CalendarFileStatusPipe implements PipeTransform {
	transform(value: string): string {
		switch (value) {
			case "CREATED":
				return "CREATED";
			case "PROCESSED":
				return "SUCCESSFUL";
			case "SUCCESS":
				return "SUCCESSFUL";
			case "FAILED":
				return "HAS ERRORS";
			case "PROCESSING":
				return "PENDING";
			case "PENDING":
				return "PENDING";
		}
		return "";
	}
}
