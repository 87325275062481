import { Injectable } from "@angular/core";
import { take } from "rxjs/operators";
import { ToastService } from "@shared/services/toast/toast.service";
import { TPPServiceDefinition, TPPServiceDefinitionDTO } from "src/app/shared/models/tpp-service.interface";
import { TppServiceDefinitionService } from "../tpp-service-definition/tpp-service-definition.service";
import { Router } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { SelectOption } from "@shared/models/select-option.interface";

@Injectable({
	providedIn: "root"
})
export class TppGroupConfigService {
	// Group Config
	private _serviceDefinition!: TPPServiceDefinition;

	public get serviceDefinition(): TPPServiceDefinition {
		return this._serviceDefinition;
	}

	public set serviceDefinition(value: TPPServiceDefinition) {
		this._serviceDefinition = value;
	}

	sourceAccountSelectOptions = new Subject<SelectOption[]>();

	constructor(
		private tppServiceDefinitionService: TppServiceDefinitionService,
		private toastService: ToastService,
		private router: Router
	) {}

	sourceAccountSelected(group: string, currency: string, sourceAccountId: string) {
		const config = this._serviceDefinition.groupConfig!.find(config => config.group === group);

		if (config) {
			config.fundingCurrency = currency;
			config.sourceAccountId = sourceAccountId;
		} else {
			this.serviceDefinition.groupConfig!.push({
				group: group,
				fundingCurrency: currency,
				sourceAccountId: sourceAccountId,
				settlementAccountId: "",
				paymentCurrency: "",
				providerCountryId: ""
			});
		}
	}

	sourceAccountUpdated(group: string, sourceAccountId: string) {
		const config = this._serviceDefinition.groupConfig!.find(config => config.group === group);

		if (config) {
			config.sourceAccountId = sourceAccountId;
		}
	}

	settlementAccountUpdated(group: string, settlementAccountId: string) {
		const config = this._serviceDefinition.groupConfig!.find(config => config.group === group);

		if (config) {
			config.settlementAccountId = settlementAccountId;
		}
	}

	settlementAccountSelected(
		group: string,
		settlementAccountId: string,
		paymentCurrency: string,
		providerCountry: string
	) {
		const config = this.serviceDefinition.groupConfig!.find(config => config.group === group)!;

		if (config) {
			config.paymentCurrency = paymentCurrency;
			config.providerCountryId = providerCountry;
			config.settlementAccountId = settlementAccountId;
		}
	}

	saveServiceDefinition(serviceDefinition: TPPServiceDefinition) {
		const formData: TPPServiceDefinitionDTO = serviceDefinition;

		this.tppServiceDefinitionService
			.editTPPServiceDefinition(formData)
			.pipe(take(1))
			.subscribe(res => {
				if (res) {
					this.toastService.showSuccess(`Service Definition Edited Successfully`);
					this.router.navigate(["service-definition/tpp/select-service"]);
				}
			});
	}

	setSelectOptions(selectOptions: SelectOption[]): void {
		this.sourceAccountSelectOptions.next(selectOptions);
	}

	getSourceAccountSelectOptions(): Observable<SelectOption[]> {
		return this.sourceAccountSelectOptions;
	}
}
