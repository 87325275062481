import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Customer } from "src/app/shared/models/customer.interface";

@Component({
	selector: "app-file-management",
	templateUrl: "./file-management.component.html",
	styleUrls: ["./file-management.component.scss"]
})
export class FileManagementComponent implements OnInit {
	constructor(private router: Router) {}
	ngOnInit(): void {}

	customerSelected(customer: Customer) {
		this.router.navigate(["/dashboard/file-management/customer-paygroups"], { state: { customer } });
	}
}
