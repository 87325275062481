export interface confirmationConfig {
	type: comfirmationPopUpTypes;
	confirmButtonText: string;
	backButtonText: string;
	contextMessage: string;
	confirmationMessage: string;
}

export enum comfirmationPopUpTypes {
	WARNING = "warning",
	SUCCESS = "success",
	INFO = "info"
}
