import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PaymentCalendarComponent } from "./payment-calendar.component";
import { PaymentCalendarPaygroupListComponent } from "./_components/payment-calendar-paygroup-list/payment-calendar-paygroup-list.component";
import { PaymentCalendarMilestoneListComponent } from "./_components/payment-calendar-milestone-list/payment-calendar-milestone-list.component";
import { CalendarSetupFileHistoryComponent } from "@modules/calendar-setup-file-history/calendar-setup-file-history/calendar-setup-file-history.component";

const routes: Routes = [
	{
		path: "",
		component: PaymentCalendarComponent
	},
	{
		path: "paygroup-list",
		component: PaymentCalendarPaygroupListComponent
	},
	{
		path: "milestone-list",
		component: PaymentCalendarMilestoneListComponent
	},

	{
		path: "files",
		component: CalendarSetupFileHistoryComponent
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class PaymentCalendarRoutingModule {}
