import { EventEmitter, Injectable } from "@angular/core";
import { MatStepper } from "@angular/material/stepper";
import { Router } from "@angular/router";
import { ReplaySubject, Subject } from "rxjs";
import { ServiceProviderService } from "@shared/services/service-provider/service-provider.service";
import { ToastService } from "@shared/services/toast/toast.service";
import {
	CapabilityCreateObject,
	Cost,
	ProviderCountrySettingsTemplate,
	CapabilitiesFormData,
	ServiceProviderCountryCapability,
	HeadcountFormData,
	CostFormData,
	CurrencyAndFundingFormData
} from "src/app/shared/models/service-provider-country-types";
import { CurrencyCode, FundingMethodName } from "src/app/shared/models/service-provider.type";
import { ServiceProviderFullObject } from "../../../../shared/models/service-provider-types.interface";
import { ServiceProviderCountry } from "../../models/ServiceProviderCountry";

@Injectable({
	providedIn: "root"
})
export class ServiceProviderCountryCreateService {
	createCurrencyData$: ReplaySubject<void> = new ReplaySubject<void>(1);
	createCapabilityData$: ReplaySubject<MatStepper> = new ReplaySubject<MatStepper>(1);
	cancelReset$: Subject<boolean> = new Subject<boolean>();

	createCostData$: EventEmitter<void> = new EventEmitter();

	changes$: EventEmitter<void> = new EventEmitter();

	currencyData$: EventEmitter<CurrencyCode[]> = new EventEmitter();
	capabilityData$: EventEmitter<ServiceProviderCountryCapability[] | null> = new EventEmitter();
	costData$: EventEmitter<ServiceProviderCountryCapability[] | null> = new EventEmitter();

	currencyFormState$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
	capabilityFormState$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
	costFormState$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

	//serviceProviderCountrySetup!: ServiceProviderCountrySetup;
	providerCountry!: ServiceProviderCountry;
	settingsTemplateData!: ProviderCountrySettingsTemplate;

	_currencies: CurrencyCode[] = [];
	_capabilities: CapabilityCreateObject[] = [];
	_costs: Cost[] = [];
	providerId: string = "";
	countryCode: string = "";

	constructor(
		private readonly serviceProviderService: ServiceProviderService,
		private readonly router: Router,
		private readonly toastService: ToastService
	) {}

	initProviderCountry(provider: ServiceProviderFullObject, country: string): void {
		this.providerCountry = new ServiceProviderCountry(null, null, provider, country, null, null, []);
	}

	updateCurrencies(form: CurrencyAndFundingFormData) {
		this._currencies = [];

		this._currencies = form.currencies;
		const fundingMethods: FundingMethodName[] = [];
		if (form.pullMethod) {
			fundingMethods.push("PULL");
		}
		if (form.pushMethod) {
			fundingMethods.push("PUSH");
		}
		this.providerCountry.setCurrencyAndFunding(this._currencies, fundingMethods);
	}

	updateCapabilities(capabilitiesFormData: CapabilitiesFormData) {
		this.providerCountry.setPaymentTypeCapabilitiesData(capabilitiesFormData);
	}

	getHeadcountConfig() {
		this.providerCountry.getPaymentTypeCapabilities();
	}

	updateCostsForm(formData: HeadcountFormData) {
		this.providerCountry.setHeadcostConfig(formData);
		this.emitCosts();
	}

	updateCurrenciesFormState(formState: boolean) {
		this.currencyFormState$.next(formState);
	}

	updateCapabilitiesFormState(formState: boolean) {
		this.capabilityFormState$.next(formState);
	}

	cancelReset() {
		this.cancelReset$.next(true);
	}

	updateCostFormState(formState: boolean) {
		this.costFormState$.next(formState);
	}

	createCostForm() {
		this.createCostData$.emit();
	}

	formChanges() {
		this.changes$.emit();
	}

	emitCurrencies() {
		this.currencyData$.emit(this._currencies);
	}

	emitCapabilities() {
		this.capabilityData$.emit(this.providerCountry.getPaymentTypeCapabilities());
	}

	emitCosts() {
		this.costData$.emit(this.providerCountry.getPaymentTypeCapabilities());
	}

	formatCost(cost: number): number {
		return +cost.toFixed(2);
	}

	createProviderCountry(costForm: CostFormData) {
		const createDTO = this.providerCountry.setCost(costForm);
		this.serviceProviderService.createServiceProviderCountry(createDTO).subscribe({
			next: _ => {
				this.router.navigate(["/service-provider-setup"]);
				this.toastService.showSuccess("Provider Country Setup Complete");
			},
			error: _ => {
				this.toastService.showError("There was a problem creating you costs, please try again");
			}
		});
	}
}
