<ng-container *ngIf="!loadingBeneficiaryDetails; else loading">
	<ng-container *ngIf="beneficiaryAccountStatusByPayGroup as beneficiaryAccountStatusByPayGroup">
		<div *ngFor="let accountStatus of beneficiaryAccountStatusByPayGroup; let i = index; last as isLast">
			<app-pay-elective-pay-group-list-item [accountStatusByPayGroup]="accountStatus">
			</app-pay-elective-pay-group-list-item>

			<mat-divider *ngIf="!isLast"></mat-divider>
		</div>
	</ng-container>
</ng-container>

<ng-template #loading><span class="pulsate loading-text">Fetching Paygroup Details...</span></ng-template>
