import { Component, Input, OnInit } from "@angular/core";
import { TppSubServiceDetails } from "../../models/tpp-data.interface";

@Component({
	selector: "app-view-subservice-details-list-item",
	templateUrl: "./view-subservice-details-list-item.component.html",
	styleUrls: ["./view-subservice-details-list-item.component.scss"]
})
export class ViewSubserviceDetailsListItemComponent implements OnInit {
	@Input() details!: TppSubServiceDetails;

	constructor() {}

	ngOnInit(): void {}
}
