<div class="accounts" *ngIf="payMethods$ | async as methods">
	<div class="c-text-sub-title" *ngIf="!methods.length">No methods found for Provider: {{ provider }}</div>
	<div class="account-column" *ngFor="let method of methods; index as i">
		<div class="account-row">
			<div class="payment-types-container">
				<span class="fall-back-toggle body-xsmall-normal">
					<mat-slide-toggle
						class="toggle-default"
						[ngClass]="{ 'toggle-checked': method.payMethod.paymentTypes.includes(fallback) }"
						[checked]="method.payMethod.paymentTypes.includes(fallback)"
						[disabled]="true"
						color="primary"
						>Fallback</mat-slide-toggle
					>
				</span>

				<span class="payment-type body-xsmall-normal">
					<mat-checkbox
						id="{{ payElective.externalId + '_bankTypeNET_' + i }}"
						[checked]="method.payMethod.paymentTypes.includes(net)"
						[disabled]="true"
					></mat-checkbox>
					NET</span
				>
				<span class="payment-type body-xsmall-normal">
					<mat-checkbox
						id="{{ payElective.externalId + '_bankTypeTTP_' + i }}"
						[checked]="method.payMethod.paymentTypes.includes(ttp)"
						[disabled]="true"
					></mat-checkbox>
					TPP</span
				>
				<span class="payment-type body-xsmall-normal">
					<mat-checkbox
						id="{{ payElective.externalId + '_bankTypeEWA_' + i }}"
						[checked]="method.payMethod.paymentTypes.includes(ewa)"
						[disabled]="true"
					></mat-checkbox>
					EWA</span
				>
			</div>

			<div class="badges">
				<div class="card-container" *ngIf="method.payMethod.type | checkCard">
					<span class="badge-item badge-card"><mat-icon>credit_cards</mat-icon></span>
					<span>
						<div id="{{ payElective.externalId + '_bankName_' + i }}" class="bank-account">
							{{ method.details.holder.firstName }} {{ method.details.holder.lastName }}
						</div>
						<div class="bank-account">**** **** **** {{ method.details.lastFour }}</div>
					</span>
				</div>
				<div class="bank-container" *ngIf="method.payMethod.type | checkBank">
					<span id="{{ payElective.externalId + '_accountBalance_' + i }}" class="badge-item badge-bank">
						<app-icon class="bank-icon" [name]="'bank-figma'" [lineWidth]="'2'"></app-icon>
					</span>
					<span id="{{ payElective.externalId + '_bankName_' + i }}" class="bank-account body-small-bold">
						{{ method.details.bankName }}

						<span *ngIf="method?.payMethod?.ranking" class="rank-payment-type body-small-bold">
							{{ method.payMethod.ranking?.type }}:
							{{ method.payMethod.ranking?.amount }}
						</span>
					</span>
				</div>
			</div>

			<div id="{{ payElective.externalId + '_bankStatus_' + i }}" class="status">
				<app-tooltip
					*ngIf="provider"
					class="status-tooltip"
					[position]="'calendar-bottom'"
					[trianlePosition]="'2rem'"
				>
					<span>
						{{ method | paymentMethodStatus : provider | payElectiveStatus }}
					</span>
					<div class="details" *ngIf="method.payMethod.providersStatuses[provider].details">
						<div
							class="details-container"
							*ngFor="
								let attribute of method.payMethod.providersStatuses[provider].details?.attributes
									| keyvalue
							"
						>
							<div class="detail-container">
								<span class="details-items" *ngIf="!isArray(attribute.value)">
									<span class="type">{{ attribute.key | formatString }}</span>
									: {{ attribute.value }}
								</span>
							</div>

							<span class="tag-container" *ngIf="isArray(attribute.value)">
								<span *ngFor="let tag of getArrayValue(attribute.value)" class="details-items">
									<span class="type">{{ attribute.key | formatString }}</span>
									: {{ tag | formatString }}
								</span>
							</span>
						</div>
					</div>
				</app-tooltip>

				<span
					class="dot"
					[ngClass]="{
						created: (method | paymentMethodStatus : provider) === 'CREATED',
						active: (method | paymentMethodStatus : provider) === 'ACTIVE',
						inactive: (method | paymentMethodStatus : provider) === 'INACTIVE',
						validating:
							(method | paymentMethodStatus : provider) === 'VALIDATING' ||
							(method | paymentMethodStatus : provider) === 'PROCESSING'
					}"
				>
				</span>
				<span class="account-status body-small-normal">{{
					method | paymentMethodStatus : provider | payElectiveStatus
				}}</span>
			</div>

			<div class="reprocess-button-container">
				<div
					class="reprocess-button"
					*ngIf="getPaymentMethodStatus(method) === 'INACTIVE' && canReprocessPayMethods; else notFailed"
					(click)="reProcess(method.payMethod.id)"
				>
					<app-icon
						class="refresh-icon"
						[name]="'rotate-right'"
						[color]="'var(--color-primary-1000)'"
						[lineWidth]="'2px'"
						[size]="'20'"
					></app-icon>
					<span class="reprocess-text-enabled body-small-bold">Reprocess</span>
				</div>
				<ng-template #notFailed>
					<div *ngIf="canReprocessPayMethods" class="reprocess-button">
						<app-icon
							class="refresh-icon"
							[name]="'rotate-right'"
							[color]="'var(--color-disabled)'"
							[lineWidth]="'2px'"
							[size]="'20'"
						></app-icon>
						<span class="reprocess-text-disabled body-small-bold">Reprocess</span>
					</div>
				</ng-template>
			</div>

			<div
				id="{{ payElective.externalId + '_bankHistory_' + i }}"
				(click)="showHistory(method.payMethod.id)"
				class="view-details"
			>
				<app-icon class="bank-more" [name]="'more'" [lineWidth]="'1'"></app-icon>
			</div>
		</div>
		<mat-divider class="mat-div-account-list" *ngIf="i !== methods.length - 1"></mat-divider>
	</div>
</div>
