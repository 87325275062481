import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
	selector: "app-description-modal",
	templateUrl: "./description-modal.component.html",
	styleUrls: ["./description-modal.component.scss"]
})
export class DescriptionModalComponent implements OnInit {
	constructor(
		@Inject(MAT_DIALOG_DATA)
		public data: {
			title: string;
			description: string;
		},
		public dialogRef: MatDialogRef<DescriptionModalComponent>
	) {}

	ngOnInit(): void {
		this.data.title = this.data.title.toUpperCase();
	}

	close() {
		this.dialogRef.close();
	}
}
