export const sortAsc = (array: any[], positions: string[]): any[] => {
	// Sort array
	array = array.sort((itemA: any, itemB: any) => {
		if (getItemValue(itemA, positions) < getItemValue(itemB, positions)) {
			return -1;
		}

		if (getItemValue(itemA, positions) > getItemValue(itemB, positions)) {
			return 1;
		}

		return 0;
	});

	return array;
};

export const sortDesc = (array: any[], positions: string[]): any[] => {
	// Sort array
	array = array.sort((itemA: any, itemB: any) => {
		if (getItemValue(itemA, positions) > getItemValue(itemB, positions)) {
			return -1;
		}

		if (getItemValue(itemA, positions) < getItemValue(itemB, positions)) {
			return 1;
		}

		return 0;
	});

	return array;
};

const getItemValue = (item: any, positions: string[]): any => {
	let value = item;

	// Acces to the internal value
	positions.forEach((position: string) => {
		value = value[position];
	});

	return value;
};
