<div
	class="flex flex-row flex-align-center-space-between"
	[formGroup]="form"
	[ngClass]="{
		'o-grid-3': gridClass === 3,
		'o-grid-2': gridClass === 2,
		'o-grid-2-paygroup': gridClass === 2 && enablePayGroupSearch
	}"
>
	<!-- Customer selector -->
	<input-wrapper *ngIf="!enablePayGroupSearch" class="input-field" label="CUSTOMER REFERENCE SEARCH">
		<wpay-auto-complete
			formControlName="customerId"
			label="Customer reference"
			[options]="customerSelectionOptions ? customerSelectionOptions : []"
			[defaultValue]="form.get('customerId')?.value"
			[loading]="!customerSelectionOptions"
		>
		</wpay-auto-complete>
	</input-wrapper>

	<!-- Legal entity selector -->
	<input-wrapper *ngIf="!enablePayGroupSearch" class="input-field" label="COUNTRY / LEGAL ENTITY">
		<wpay-auto-complete
			formControlName="legalEntityId"
			label="Select Country / Legal Entity"
			[options]="legalEntitySelectionOptions ? legalEntitySelectionOptions : []"
			[defaultValue]="form.get('legalEntityId')?.value"
			[loading]="!legalEntitySelectionOptions"
			[disabled]="!customerSelectionOptions"
		>
		</wpay-auto-complete>
	</input-wrapper>

	<!-- Legal entity search -->
	<input-wrapper *ngIf="enablePayGroupSearch" class="select">
		<mat-form-field id="payGroupSearch" appearance="outline">
			<app-icon [name]="'search-normal-1'" matPrefix style="padding-right: 10px"></app-icon>
			<input formControlName="payGroupSearch" matInput placeholder="Search by ID, name, code..." />
		</mat-form-field>
	</input-wrapper>

	<input-wrapper *ngIf="!enablePayGroupSearch" class="input-field" label="LEGAL ENTITIES">
		<wpay-auto-complete
			formControlName="legalEntityId"
			label="Legal Entity"
			[options]="legalEntitySelectionOptions ? legalEntitySelectionOptions : []"
			[defaultValue]="form.get('legalEntityId')?.value"
			[loading]="!legalEntitySelectionOptions"
			[disabled]="!customerSelectionOptions"
		>
		</wpay-auto-complete>
	</input-wrapper>

	<!-- Pay Group selector -->
	<ng-container *ngIf="payGroupSelector">
		<input-wrapper class="select" label="PAY GROUP">
			<wpay-auto-complete
				formControlName="payGroupId"
				label="Select pay group"
				[options]="payGroupSelectionOptions ? payGroupSelectionOptions : []"
				[defaultValue]="form.get('payGroupId')?.value"
				[loading]="!payGroupSelectionOptions"
			>
			</wpay-auto-complete>
		</input-wrapper>
	</ng-container>
</div>
