import { Component, OnInit } from "@angular/core";
import { take } from "rxjs/operators";
import { PermissionsService } from "../../../../shared/services/permissions/permissions.service";

@Component({
	selector: "app-service-providers-costs-edit",
	templateUrl: "./service-providers-costs-edit.component.html",
	styleUrls: ["./service-providers-costs-edit.component.scss"]
})
export class ServiceProvidersCostsEditComponent implements OnInit {
	isSuperUser: boolean = false;

	constructor(private permissions: PermissionsService) {}

	ngOnInit(): void {
		this.permissions
			.canEditServiceProviders()
			.pipe(take(1))
			.subscribe(res => (this.isSuperUser = res));
	}
}
