import { EventEmitter, Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { forkJoin, noop, Observable, of, Subject } from "rxjs";
import { catchError, map, mergeMap, switchMap, take, tap } from "rxjs/operators";
import { PayGroup, PayGroupPagination } from "src/app/shared/models/pay-groups";
import { environment } from "src/environments/environment";
import {
	ClientSpecificSubService,
	TPPServiceDefinition,
	TppService,
	TPPServiceDefinitionDTO,
	TppServiceDefinitionPagination,
	TppServicePagination,
	SubService,
	TppServiceBeneficiaryDTO
} from "src/app/shared/models/tpp-service.interface";
import { SubServicePaymentDefinition } from "src/app/shared/models/tpp-account.interface";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { formSubService } from "@modules/service-definition/_models/tpp-definition-form-subservices.interface";
import { DataNewSourOfFun } from "../../_models/defintion-group";
import { select, Store } from "@ngrx/store";
import { AppState } from "src/app/store/models/state.model";
import { UpdateSelectedTPPFormSubServices } from "src/app/store/actions/tppFormSubServicesSelect.action";
import { getTppFormSubServices } from "src/app/store";
import { FormGroup } from "@angular/forms";
import { ToastService } from "@shared/services/toast/toast.service";

@Injectable({
	providedIn: "root"
})
export class TppServiceDefinitionService {
	private sortField = "data.externalId";
	private sortDir = "DESC";
	private dataFormSourceFund!: DataNewSourOfFun;

	private tppServiceDefFormSubject = new Subject<any>();

	tppServiceDefFormSubject$ = this.tppServiceDefFormSubject.asObservable();

	serviceDefUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();

	refreshList: EventEmitter<boolean> = new EventEmitter<boolean>();

	latestServiceDefUpdated: EventEmitter<TPPServiceDefinitionDTO> = new EventEmitter<TPPServiceDefinitionDTO>();

	private removedServiceFormServiceDefinition: boolean = false;

	constructor(private http: HttpClient, private store: Store<AppState>, private toast: ToastService) {}

	getPayGroupsByCustomerId(customerId: string): Observable<PayGroupPagination> {
		return this.http
			.get<PayGroupPagination>(
				`${environment.apiUri}/v1/pay-groups?customerIds=${customerId}&sortField=${this.sortField}&sortDir=${this.sortDir}`
			)
			.pipe(
				map((res: PayGroupPagination) => {
					return res;
				})
			);
	}

	createTPPServiceDefinition(formData: TPPServiceDefinitionDTO): Observable<TPPServiceDefinitionDTO> {
		return this.http
			.post<TPPServiceDefinitionDTO>(`${environment.apiUri}/v1/tpp-service-definitions`, formData)
			.pipe(
				tap(res => {
					this.serviceDefUpdated.emit(true);
					this.latestServiceDefUpdated.emit(res);
				})
			);
	}

	editTPPServiceDefinition(formData: TPPServiceDefinitionDTO): Observable<TPPServiceDefinitionDTO> {
		return this.http
			.put<TPPServiceDefinitionDTO>(`${environment.apiUri}/v1/tpp-service-definitions`, formData)
			.pipe(
				tap(res => {
					this.serviceDefUpdated.emit(true);
					this.latestServiceDefUpdated.emit(res);
				})
			);
	}

	deleteServiceDefinition(payGroupId: string): Observable<TPPServiceDefinition> {
		return this.http.delete<TPPServiceDefinition>(`${environment.apiUri}/v1/tpp-service-definitions/${payGroupId}`);
	}

	subServiceFormDataSetup(payGroupId: string, formDataServices: []) {
		let formData: TPPServiceDefinitionDTO = {
			payGroupId: payGroupId,
			services: []
		};

		formDataServices.forEach((object: formSubService) => {
			delete object["beneficiaryName"];
		});

		formData.services = formDataServices;

		formData.services.forEach((formDataService, index) => {
			if (formDataService.managedIn) {
				if (!/\d/.test(formDataService.managedIn.toString())) {
					var updatedManagedIn = formDataService.managedIn
						.toString()
						.split(" ")
						.map(word => word.toUpperCase())
						.join("_");

					if (updatedManagedIn === "EVERY_MAIN_RUN") {
						formData.services[index].managedIn = "MAIN_RUN";
					} else {
						formData.services[index].managedIn = updatedManagedIn;
					}
				}
			}
		});

		let isCreate!: boolean;

		this.getServiceDefinitionTemp(payGroupId)
			.pipe(take(1))
			.subscribe({
				next: result => {
					if (result.status === 200) {
						let res: TPPServiceDefinitionDTO = result.body;

						formData.groupConfig = res.groupConfig;

						if (res.services.length) {
							formData.version = res.version!;
							// if we have a service def
							res.services.forEach(item => {
								// go through the service def services
								formData.services.forEach(formDataServiceObject => {
									// go through the form data sevices and try to find the same
									//subservices from service defs

									if (formDataServiceObject.subServiceId !== item.subServiceId) {
										let subServiceIndexformData = formData.services.findIndex(formDataService => {
											return formDataService.subServiceId === item.subServiceId;
										});

										// if it doesnt exist, push it up into form data services
										if (subServiceIndexformData === -1) {
											formData.services.push(item);
										}
									}
								});
							});

							// we are updating a service def, not creating
							isCreate = false;
							this.tppServiceDefFormSubject.next({
								formData: this.formatDTOFrequency(formData),
								isCreate: isCreate
							});
						} else {
							formData.version = res.version!;

							isCreate = false;
							this.tppServiceDefFormSubject.next({
								formData: this.formatDTOFrequency(formData),
								isCreate: isCreate
							});
						}
					} else {
						isCreate = true;
						this.tppServiceDefFormSubject.next({
							formData: this.formatDTOFrequency(formData),
							isCreate: isCreate
						});
					}
				},
				error: err => {
					if (err.status === 404) {
						isCreate = true;
					}
					this.tppServiceDefFormSubject.next({
						formData: this.formatDTOFrequency(formData),
						isCreate: isCreate
					});
				}
			});
	}
	formatDTOFrequency(formData: TPPServiceDefinitionDTO): TPPServiceDefinitionDTO {
		const formDTO = formData;
		for (let service of formDTO.services) {
			const frequency = service.frequency.toUpperCase().replace(/\s+/g, "_");
			service.frequency = frequency;

			if (typeof service.managedIn === "string") {
				const suffixes = ["st", "nd", "rd", "th"];
				const lastTwoChars = service.managedIn.slice(-2);
				if (suffixes.includes(lastTwoChars)) {
					const number = parseInt(service.managedIn.slice(0, -2));
					if (!isNaN(number)) {
						service.managedIn = number.toString();
					}
				}
			}
		}

		return formDTO;
	}

	searchTPPServiceDefinitionByPaygroupIdAndGroup(
		payGroupId: string,
		groups: string
	): Observable<TPPServiceDefinition[]> {
		const pageNumber = "0";
		const pageSize = "-1";
		return this.serchTPPServiceDefintion(payGroupId, groups, "", "", pageNumber, pageSize).pipe(
			map((response: TppServiceDefinitionPagination) => response.items)
		);
	}

	serchTPPServiceDefintion(
		payGroupIds: string,
		groups: string,
		statuses: string,
		frequencies: string,
		pageNumber: string,
		pageSize: string
	): Observable<TppServiceDefinitionPagination> {
		let params = new HttpParams();
		params = params.append("payGroupIds", payGroupIds);
		params = params.append("groups", groups);
		params = params.append("pageNumber", pageNumber);
		params = params.append("pageSize", pageSize);
		if (statuses != "") params = params.append("statuses", statuses);
		if (frequencies != "") params = params.append("frequencies", frequencies);

		return this.http.get<TppServiceDefinitionPagination>(`${environment.apiUri}/v1/tpp-service-definitions`, {
			params
		});
	}

	setDataFormSourceFund(
		paymentType: string,
		fundingCurrency: string,
		sourceOfFundId: string,
		tppGroup: string,
		legalEntityId?: string
	) {
		this.dataFormSourceFund = {
			paymentType,
			fundingCurrency,
			legalEntityId,
			tppGroup,
			sourceOfFundId
		};
	}

	getDataFormSourceFund() {
		return this.dataFormSourceFund;
	}

	resetDataSourceOfFund() {
		this.dataFormSourceFund = {
			paymentType: "",
			fundingCurrency: "",
			legalEntityId: "",
			sourceOfFundId: "",
			tppGroup: ""
		};
	}

	getSavedDataStepOne(
		subServicesData: formSubService[],
		tppGroupOptions: SelectOption[],
		subService: SubServicePaymentDefinition,
		beneficiarySelectOptions: SelectOption[]
	): { beneficiary: string; beneficiaryName: string; group: string; externalId: string } {
		let data = { beneficiary: "", beneficiaryName: "", group: "", externalId: "" };

		let index: number = subServicesData.findIndex((formSubService: formSubService) => {
			return formSubService.subServiceId === subService.id;
		});

		if (index !== -1) {
			// get the group that matches the one previously selected from the group select options
			let groupIndex = tppGroupOptions.findIndex(
				(element: SelectOption) => element.value === subServicesData[index].group
			);

			let externalId = subServicesData[index].externalId;

			// so that we can get its name
			//let group = tppGroupOptions[groupIndex]!.text;
			let groupValue = tppGroupOptions[groupIndex]!.value;
			// patch the name on this components form

			// do the same for beneficiary
			let benIndex = beneficiarySelectOptions.findIndex(
				element => element.value === subServicesData[index].beneficiaryId
			);
			let beneficiaryName = beneficiarySelectOptions[benIndex].text;

			let beneficiary = beneficiarySelectOptions[benIndex].value;

			data = {
				beneficiary: beneficiary,
				beneficiaryName: beneficiaryName,
				group: groupValue,
				externalId: externalId ? externalId : ""
			};
		}

		return data;
	}

	getFrequencyManagedIn(payGroupId: string, subServiceFrequency: string): Observable<SelectOption[]> {
		return this.http
			.get<[]>(
				`${environment.apiUri}/v1/tpp-services/frequencies/managed-in?payGroupId=${payGroupId}&subServiceFrequency=${subServiceFrequency}`
			)
			.pipe(
				map((res: []) => {
					let resultSelectOptions: SelectOption[] = [];

					if (res) {
						res.forEach((element: { managedIn: string; name: string }) => {
							resultSelectOptions.push({ text: element.name, value: element.managedIn });
						});
					}

					return resultSelectOptions;
				})
			);
	}

	getServiceDefinitions(
		payGroupId: string,
		pageNumber: string,
		pageSize: string
	): Observable<TppServiceDefinitionPagination> {
		return this.http.get<TppServiceDefinitionPagination>(
			`${environment.apiUri}/v1/tpp-service-definitions?payGroupIds=${payGroupId}&pageNumber=${pageNumber}&pageNumber=${pageSize}`
		);
	}

	getServiceDefinitionsWithPaygroupIds(payGroupIds: string[]): Observable<TppServiceDefinitionPagination> {
		return this.http.get<TppServiceDefinitionPagination>(
			`${environment.apiUri}/v1/tpp-service-definitions?payGroupIds=${payGroupIds}`
		);
	}

	getServiceDefinition(payGroupId: string): Observable<TPPServiceDefinition> {
		return this.http.get<TPPServiceDefinition>(`${environment.apiUri}/v1/tpp-service-definitions/${payGroupId}`);
	}

	//Replace to method once code is merged - Sean's getServiceDefinition

	getServiceDefinitionTemp(payGroupId: string): Observable<any> {
		return this.http
			.get<TPPServiceDefinitionDTO>(`${environment.apiUri}/v1/tpp-service-definitions/${payGroupId}`, {
				observe: "response"
			})
			.pipe(
				tap(
					res => {
						if (res.status === 200 && res.body) {
							this.latestServiceDefUpdated.emit(res.body);
						} else {
							this.latestServiceDefUpdated.emit({} as TPPServiceDefinition);
						}
					},

					error => {
						this.latestServiceDefUpdated.emit({} as TPPServiceDefinition);
					}
				)
			);
	}

	getSavedDataStepTwo(
		subServicesData: formSubService[],
		subService: SubServicePaymentDefinition,
		frequencySelectOptions: SelectOption[]
	): { frequency: string; managedIn: string } {
		const index = subServicesData.findIndex(f => f.subServiceId === subService.id);

		const indexedFrequency = subServicesData[index].frequency.replace(/ /g, "_").toUpperCase();

		const frequency =
			index !== -1 ? frequencySelectOptions.find(o => o.value === indexedFrequency)?.value || "" : "";
		return { frequency: frequency, managedIn: subServicesData[index].managedIn! };
	}

	getFormSubServices(): ClientSpecificSubService[] {
		let services: ClientSpecificSubService[] = [];

		this.store.pipe(take(1), select(getTppFormSubServices)).subscribe(state => {
			if (state && state.selectedTPPFormSubServices) {
				services = state.selectedTPPFormSubServices;
			}
		});

		return services;
	}

	setFormSubServices(value: ClientSpecificSubService[]) {
		this.store.dispatch(new UpdateSelectedTPPFormSubServices({ selectedTPPFormSubServices: value }));
	}

	removeFromServiceDefinition(selectedPayGroupId: string, subservice: SubServicePaymentDefinition): boolean {
		this.getServiceDefinitionTemp(selectedPayGroupId)
			.pipe(take(1))
			.subscribe({
				next: result => {
					let tppServiceDefinition: TPPServiceDefinitionDTO;

					if (result.status === 200) {
						tppServiceDefinition = result.body;
						tppServiceDefinition.services = tppServiceDefinition.services.filter(
							res => res.subServiceId !== subservice.id
						);

						this.editTPPServiceDefinition(tppServiceDefinition)
							.pipe(
								take(1),
								tap(() => {
									this.removedServiceFormServiceDefinition = true;
								})
							)
							.subscribe();
					}
				},
				error: _ => {
					console.log("Error getting service definition");
				}
			});

		return this.removedServiceFormServiceDefinition;
	}

	removeCurrentSavedData(newService: TppService, isAdhoc: boolean, selectedPayGroupId?: string): Observable<boolean> {
		//remove specific entry from service def

		if (isAdhoc) {
			return forkJoin([
				this.removeNewEntryServiceEntriesinServiceDef(selectedPayGroupId!, newService),
				this.removeSubservicesFromTPPSubservicesByServiceId(newService.id),
				this.removeServiceFromTPPServices(newService.id)
			]).pipe(
				map(([res1, res2, res3]) => {
					return true;
				}),
				catchError(error => {
					return of(false);
				})
			);
		} else {
			return forkJoin([
				this.removeSubservicesFromTPPSubservicesByServiceId(newService.id),
				this.removeServiceFromTPPServices(newService.id)
			]).pipe(
				map(([res1, res2]) => {
					return true;
				}),
				catchError(error => {
					return of(false);
				})
			);
		}
	}

	removeServiceFromTPPServices(serviceId: string): Observable<TppService> {
		//Check if new service has been saved already -> Delete new service
		return this.http.delete<TppService>(`${environment.apiUri}/v1/tpp-services/${serviceId}`);
	}

	removeSubservicesFromTPPSubservicesByServiceId(serviceId: string): Observable<SubService[]> {
		//Check if new subs exist -> Delete any new subs
		let temp: SubService[] = []; //send this back if no subs found
		return this.getTPPSubserviceByServiceId(serviceId).pipe(
			mergeMap((subservices: SubService[]) => {
				if (subservices.length > 0) {
					return forkJoin(
						subservices.map((subservice: SubService) => {
							return this.deleteSubService(subservice.id);
						})
					);
				} else {
					return of(temp);
				}
			})
		);
	}

	removeNewEntryServiceEntriesinServiceDef(
		selectedPayGroupId: string,
		service: TppService
	): Observable<TPPServiceDefinition> {
		let temp: TPPServiceDefinition = {} as TPPServiceDefinition;

		return this.getServiceDefinitionTemp(selectedPayGroupId).pipe(
			take(1),
			mergeMap(serviceDef => {
				let tppServiceDefinition: TPPServiceDefinition;

				tppServiceDefinition = serviceDef.body;

				let servivcesToKeep: ClientSpecificSubService[] = [];

				servivcesToKeep = tppServiceDefinition.services.filter(res => res.serviceId !== service.id);

				tppServiceDefinition.services = servivcesToKeep;

				return this.editTPPServiceDefinition(tppServiceDefinition);
			}),
			catchError(error => {
				console.log(error);
				return of(temp);
			})
		);
	}

	getTPPSubserviceByServiceId(serviceId: String): Observable<SubService[]> {
		return this.http.get<SubService[]>(`${environment.apiUri}/v1/tpp-sub-services/tpp-service/${serviceId}`);
	}

	deleteSubService(subServiceId: string): Observable<SubService> {
		return this.http.delete<SubService>(`${environment.apiUri}/v1/tpp-sub-services/${subServiceId}`);
	}

	addSelectedServiceToServiceDefinition(
		selectedPayGroupId: string,
		serviceToAdd: TppService,
		serviceDefinition: TPPServiceDefinition
	) {
		//if service def exists
		//yes - get Subservicees - edit service def
		// no - get Subservicees - add new service def

		this.getTPPSubserviceByServiceId(serviceToAdd.id)
			.pipe(take(1))
			.subscribe(res => {
				let subservices: SubService[] = res;
				let tPPserviceDef: TPPServiceDefinitionDTO = serviceDefinition;

				if (tPPserviceDef && tPPserviceDef.payGroupId && tPPserviceDef.payGroupId.length > 0) {
					let serviceFound = tPPserviceDef.services.find(service => service.serviceId === serviceToAdd.id);

					if (!serviceFound) {
						if (subservices.length > 0) {
							const addService: ClientSpecificSubService = {
								serviceId: serviceToAdd.id,
								subServiceId: subservices[0].id,
								frequency: subservices[0].frequency,
								externalId: subservices[0].externalId
							};

							tPPserviceDef.services.push(addService);

							this.editTPPServiceDefinition(tPPserviceDef).subscribe();
						} else {
							this.refreshList.emit(true);
							this.toast.showError("This service does not have any sub services");
						}
					} else {
						this.toast.showError("The service was not found");
					}
				} else if (!tPPserviceDef.payGroupId) {
					//create

					if (subservices && subservices.length > 0) {
						tPPserviceDef = {
							payGroupId: selectedPayGroupId,
							services: [
								{
									serviceId: serviceToAdd.id,
									subServiceId: subservices[0].id,
									frequency: subservices[0].frequency,
									externalId: ""
								}
							]
						};

						this.createTPPServiceDefinition(tPPserviceDef).subscribe();
					} else {
						this.refreshList.emit(true);
						this.toast.showError("This service does not have any sub services");
					}
				}
			});
	}

	saveToDefinitionWithNewBeneficaryValue(
		ben: TppServiceBeneficiaryDTO,
		subserviceId: string,
		paygroupId: string
	): Observable<TPPServiceDefinition> {
		return this.getServiceDefinitionTemp(paygroupId).pipe(
			take(1),
			mergeMap(serviceDef => {
				let tppServiceDefinition: TPPServiceDefinition;

				tppServiceDefinition = serviceDef.body;

				let index = tppServiceDefinition.services.findIndex(service => service.subServiceId === subserviceId);

				if (index !== -1) {
					tppServiceDefinition.services[index].beneficiaryId = ben.id;

					return this.editTPPServiceDefinition(tppServiceDefinition);
				} else {
					return of({} as TPPServiceDefinition);
				}
			}),
			catchError(error => {
				console.log(error);
				return of({} as TPPServiceDefinition);
			})
		);
	}

	saveToDefinition(
		form: FormGroup,
		subService: SubServicePaymentDefinition,
		selectedPayGroupId: string,
		selectedService: TppService,
		beneficiarySelectOptions: SelectOption[]
	): void {
		let selectedBeneficiary: string = form.get("beneficiaryId")?.value;

		let benIndex = beneficiarySelectOptions.findIndex(element => element.text === form.get("beneficiaryId")?.value);

		if (benIndex !== -1) {
			selectedBeneficiary = beneficiarySelectOptions[benIndex].value;
		}

		if (selectedPayGroupId !== "") {
			this.getServiceDefinitionTemp(selectedPayGroupId)
				.pipe(take(1))
				.subscribe({
					next: result => {
						if (result.status === 200) {
							let serviceDef: TPPServiceDefinitionDTO = result.body;

							if (serviceDef) {
								//Has service def

								let subserviceToEdit = serviceDef.services.find(
									service => service.subServiceId === subService.id
								);

								if (subserviceToEdit) {
									let subservice: ClientSpecificSubService = {
										serviceId: subserviceToEdit.serviceId,
										subServiceId: subserviceToEdit.subServiceId,
										group: form.get("group")!.value !== "" ? form.get("group")!.value : undefined,
										beneficiaryId: selectedBeneficiary ? selectedBeneficiary : undefined,
										frequency: subserviceToEdit.frequency,
										managedIn: subserviceToEdit.managedIn,
										externalId: form.get("externalId")!.value
									};

									let replaceIndex: number = serviceDef.services.findIndex(
										service => service.subServiceId === subService.id
									);

									serviceDef.services[replaceIndex] = subservice;

									this.editTPPServiceDefinition(serviceDef).pipe(take(1)).subscribe();
								} else {
									//add subservice

									let subservice: ClientSpecificSubService = {
										serviceId: selectedService.id,
										subServiceId: subService.id!,
										group: form.get("group")!.value !== "" ? form.get("group")!.value : undefined,
										beneficiaryId: selectedBeneficiary ? selectedBeneficiary : undefined,
										frequency: "PER_RUN",
										managedIn: "EVERY_RUN",
										externalId: form.get("externalId")!.value
									};

									serviceDef.services.push(subservice);

									this.editTPPServiceDefinition(serviceDef).pipe(take(1)).subscribe();
								}

								//edit service def
							}
						}
					},
					error: err => {
						if (err.status === 404) {
							//create service def

							const subservice: ClientSpecificSubService = {
								serviceId: selectedService.id,
								subServiceId: subService.id!,
								group: form.get("group")!.value !== "" ? form.get("group")!.value : undefined,
								beneficiaryId: selectedBeneficiary ? selectedBeneficiary : undefined,
								frequency: "PER_RUN",
								managedIn: "EVERY_RUN",
								externalId: form.get("externalId")!.value
							};

							let formData: TPPServiceDefinitionDTO = {
								payGroupId: selectedPayGroupId,
								services: [subservice],
								groupConfig: []
							};

							this.createTPPServiceDefinition(formData).pipe(take(1)).subscribe();
						}
					}
				});
		}
	}
}
