<sliding-panel class="sliding-panel-view-subservice-details" *ngIf="showResult" [exit]="exit">
	<div class="top-section">
		<!-- Header -->
		<div class="header">
			<div class="title-section">
				<div class="text">
					<span *ngIf="type.toString() === 'STATUS'">PAYGROUP STATUS HISTORY</span>
					<span *ngIf="type.toString() === 'GOLIVE'">PAYGROUP GO LIVE DATE HISTORY</span>
					<span *ngIf="type.toString() === 'TREASURYGOLIVE'">PAYGROUP TREASURY GO LIVE DATE HISTORY</span>
				</div>
			</div>

			<div class="close-button" (click)="cancel()">
				<app-icon
					[name]="'close'"
					[color]="'var(--color-text-default)'"
					[size]="'65'"
					[lineWidth]="'1px'"
				></app-icon>
			</div>
		</div>

		<div [formGroup]="searchForm" class="searchbar-wrapper">
			<mat-form-field id="searchfield" class="search-bar" appearance="outline">
				<app-icon
					[name]="'search-normal-1'"
					[color]="'var(--color-search-icon)'"
					[size]="'20'"
					matPrefix
				></app-icon>
				<input class="search-input" matInput placeholder="Search by..." formControlName="search" />
			</mat-form-field>
		</div>
	</div>

	<div class="details-container" *ngIf="filteredData && filteredData.length > 0; else noItems">
		<app-paygroup-status-history-item
			*ngFor="let historyItem of filteredData; last as isLast"
			[historyItem]="historyItem"
			[type]="type"
			[isLast]="isLast"
		></app-paygroup-status-history-item>
	</div>

	<ng-template #noItems>
		<no-items-layout message="No entry found"></no-items-layout>
	</ng-template>
</sliding-panel>
