import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { CommonLegalEntityService } from "@modules/legal-entity-setup/_services/common/common-legal-entity.service";
import { Store, select } from "@ngrx/store";
import { PaginationService } from "@shared/components/pagination/_services/pagination.service";
import { PaginationOptions } from "@shared/constants/pagination";
import { CountriesService } from "@shared/services/countries/countries.service";
import { LegalEntityService } from "@shared/services/legal-entity/legal-entity.service";
import { formatDataForSelect } from "@shared/utils/format-select-data.util";
import { PaginationData } from "@store/models/pagination.model";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, finalize, map, take, takeUntil } from "rxjs/operators";
import { LegalEntity, LegalEntityPagination } from "src/app/shared/models/legal-entity.interface";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { getCustomerEntityGroupState, getPagination } from "src/app/store";
import { UpdateCustomerEntityGroupAction } from "src/app/store/actions/customerEntityGroupSelect.action";
import { CustomerEntityGroupSelect } from "src/app/store/models/customerEnitityGroupSelection.model";
import { AppState } from "src/app/store/models/state.model";

@Component({
	selector: "app-entity-select",
	templateUrl: "./entity-select.component.html",
	styleUrls: ["./entity-select.component.scss"]
})
export class EntitySelectComponent implements OnInit, OnDestroy {
	@Output() selectedPath: EventEmitter<any> = new EventEmitter<any>();

	busy: boolean = true;
	entitySelectForm!: FormGroup;
	legalEntitiesList: LegalEntity[] = [];
	selectOptionsCountries: SelectOption[] = [];
	allCountries: SelectOption[] = [];
	title = "Countries and partners";

	customerId!: string;
	customerName!: string;
	searchQuery = "";
	countryCode = "";
	paginationType: PaginationOptions = PaginationOptions.LEGAL_ENTITIES;

	//pagination
	pageIndex!: number;
	pageSize!: number;
	totalPageCount!: number;

	readonly ALL_OPTION: SelectOption = {
		value: "ALL_OPTIONS",
		text: "All countries"
	};

	private destroy$: Subject<void> = new Subject<void>();

	constructor(
		private legalEntityService: LegalEntityService,
		private router: Router,
		private store: Store<AppState>,
		public commonLegalEntityService: CommonLegalEntityService,
		private paginationService: PaginationService,
		private countriesService: CountriesService
	) {}

	ngOnInit() {
		this.getCountries();
		this.fetchCustomerFromState();
		this.initForm();
	}

	getCountries() {
		this.countriesService
			.getCountries()
			.pipe(take(1), takeUntil(this.destroy$))
			.subscribe(countries => {
				countries.filter(country => {
					this.allCountries.push(
						formatDataForSelect({
							text: country.country.name,
							value: country.country.code,
							imagePath: `assets/svg-country-flags/svg/${country?.country.code.toLowerCase()}.svg`
						})
					);
				});

				this.setupPaginationSubscription();
			});
	}

	fetchCustomerFromState(): void {
		this.store
			.pipe(takeUntil(this.destroy$), select(getCustomerEntityGroupState))
			.subscribe((state: CustomerEntityGroupSelect) => {
				if (state?.customerId) {
					this.customerName = state.customerName!;
					this.customerId = state.customerId;
					this.getLegalEntitiesDropdownItems(this.customerId);
				}
			});
	}

	setupPaginationSubscription(): void {
		this.store.pipe(select(getPagination), takeUntil(this.destroy$)).subscribe({
			next: pagination => {
				if (pagination && pagination.hasOwnProperty("LEGAL_ENTITIES")) {
					const legalsPagination = pagination["LEGAL_ENTITIES"];
					this.pageSize = legalsPagination.pageSize;
					this.pageIndex = legalsPagination.pageIndex;

					this.getLegalEntities(
						this.customerId,
						legalsPagination.pageSize,
						legalsPagination.pageIndex,
						this.searchQuery,
						this.countryCode
					);
				}
			}
		});
	}

	initForm() {
		this.entitySelectForm = new UntypedFormGroup({
			legal_countries: new UntypedFormControl("", Validators.required),
			legal_search: new UntypedFormControl("")
		});

		this.entitySelectForm
			.get("legal_countries")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe((code: string) => {
				this.legalEntitiesList = [];
				this.searchQuery = "";
				this.formControlReset("legal_search");
				code === "ALL_OPTIONS" ? (this.countryCode = "") : (this.countryCode = code);
				this.resetPagination();
			});

		this.entitySelectForm
			.get("legal_search")
			?.valueChanges.pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.destroy$))
			.subscribe((search: string) => {
				this.legalEntitiesList = [];
				this.countryCode = "";
				this.searchQuery = search;
				this.formControlReset("legal_countries");
				this.resetPagination();
			});
	}

	resetPagination(): void {
		this.busy = true;
		const paginationSelection: PaginationData = {
			pageSize: this.pageSize,
			pageIndex: 0
		};

		this.paginationService.updatePaginationSate(this.paginationType, paginationSelection);
	}

	formControlReset(control: string): void {
		const isLegal = control === "legal_countries";
		this.entitySelectForm.get(control)?.setValue(isLegal ? "All Legal Entities" : "", { emitEvent: false });
	}

	getLegalEntities(
		customerId: string,
		pageSize: number,
		pageIndex: number,
		searchQuery: string,
		countryCode: string
	) {
		this.legalEntityService
			.getLegalEntitiesItems(customerId, pageIndex, pageSize, searchQuery, [], countryCode)
			.pipe(
				finalize(() => (this.busy = false)),
				map((res: LegalEntityPagination) => {
					this.totalPageCount = res.totalPages;
					return res.items;
				})
			)
			.subscribe(legalEntities => {
				this.legalEntitiesList = legalEntities;
			});
	}

	getLegalEntitiesDropdownItems(customerId: string) {
		this.legalEntityService
			.getLegalEntitiesForCustomer(customerId)
			.pipe(
				map((res: LegalEntityPagination) => {
					return res.items;
				}),
				finalize(() => (this.busy = false))
			)
			.subscribe(legalEntities => {
				if (legalEntities.length) {
					this.prepareDropDownOptions(legalEntities);
				} else {
					this.selectOptionsCountries = [];
				}
			});
	}

	prepareDropDownOptions(legalEntities: LegalEntity[]): void {
		// Create a Map to store unique values based on the 'value' key
		const uniqueValuesMap = new Map<string, SelectOption>();

		// Loop through legalEntities and add unique values to the Map
		legalEntities.forEach((entity: LegalEntity) => {
			const option: SelectOption = {
				value: entity.data.country,
				text: this.countryCodeToName(entity?.data?.country),
				imagePath: `assets/svg-country-flags/svg/${entity?.data?.country.toLowerCase()}.svg`
			};

			const formattedOption = formatDataForSelect(option);

			// Add to Map only if the 'value' key is not already present
			if (!uniqueValuesMap.has(formattedOption.value)) {
				uniqueValuesMap.set(formattedOption.value, formattedOption);
			}
		});

		// Convert Map values back to an array
		this.selectOptionsCountries = Array.from(uniqueValuesMap.values());
		this.selectOptionsCountries.unshift(this.ALL_OPTION);
	}

	countryCodeToName(code: string): string {
		return this.allCountries.find(country => {
			return country.value === code;
		})!.text;
	}

	selectLegalEntity(legalEntity: LegalEntity) {
		let customerEntity = new UpdateCustomerEntityGroupAction({
			customerId: this.customerId,
			legalEntityId: legalEntity.id,
			payGroupId: "",
			customerName: this.customerName
		});
		this.store.dispatch(customerEntity);

		this.selectedPath.emit({
			legalEntity: legalEntity,
			customer: {} as SelectOption,
			country: {} as SelectOption
		});
	}

	viewEntitiesClicked() {
		this.router.navigate(["/legal-entities/setup"]);
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
