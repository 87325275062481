import { Injectable } from "@angular/core";
import {
	BeneficiaryAccountAggregate,
	SubServiceAggregate,
	TppServicesByByPayGroupAggregation,
	TtpServiceAggregate,
	TtpServiceByServiceTypeAggregate
} from "@shared/services/adapter/ttp-service-definition/facade/model/TppServicesByByPayGroupAggregation";
import {
	BeneficiaryAccountAssignment,
	BeneficiaryStatus,
	InternalStatus,
	SubService,
	SubServiceSummary,
	TppServicesByByPayGroupViewModel,
	TtpService,
	TtpServiceByServiceType,
	mapInternalStatusToDisplayStatus
} from "src/app/shared/models/service-definition/TppServiceDefinitionViewModel";
import { BeneficiaryStatusFilterType } from "@modules/service-definition/_components/tpp-service-selection/beneficary-status-by-provider-summary/beneficary-status-by-provider-summary.component";

@Injectable({
	providedIn: "root"
})
export class ServiceDefinitionAggregationFilterService {
	constructor() {}

	filterAsViewModel(
		aggregation: TppServicesByByPayGroupAggregation,
		providerName: string,
		statusFilter: BeneficiaryStatusFilterType
	): TppServicesByByPayGroupViewModel {
		var mapped = {} as TppServicesByByPayGroupViewModel;

		const SERVICE_BY_SERVICE_TYPE = this.mapServicesByServiceType(aggregation, providerName, statusFilter);

		mapped.beneficiaryCounts = this.mapBeneficiaryCounts(aggregation, SERVICE_BY_SERVICE_TYPE);
		mapped.servicesByServiceType = SERVICE_BY_SERVICE_TYPE;

		return mapped;
	}

	private mapBeneficiaryCounts(
		aggregation: TppServicesByByPayGroupAggregation,
		mappedServices: TtpServiceByServiceType[]
	) {
		// Count the total beneficiaries that can be validated (based on current provider filter).
		var totalBeneficiarysWhichCanBeValidated = 0;

		mappedServices.forEach((serviceByServiceType: TtpServiceByServiceType) => {
			serviceByServiceType.services.forEach((service: TtpService) => {
				if (service.subServiceSummary.totalBeneficiariesWhichCanBeValidated != undefined) {
					totalBeneficiarysWhichCanBeValidated +=
						service.subServiceSummary.totalBeneficiariesWhichCanBeValidated;
				}
			});
		});

		return {
			totalBeneficiaries: aggregation.beneficiaryCountForPayGroup.totalBeneficiaries,
			totalBeneficiariesWhichCanBeValidated: totalBeneficiarysWhichCanBeValidated
		};
	}

	private mapServicesByServiceType(
		services: TppServicesByByPayGroupAggregation,
		providerName: string,
		statusFilter: BeneficiaryStatusFilterType
	): TtpServiceByServiceType[] {
		var mappedServices = [] as TtpServiceByServiceType[];

		services.servicesByServiceType.forEach((serviceByServiceTypeAggregate: TtpServiceByServiceTypeAggregate) => {
			var serviceByServiceType = {
				serviceType: serviceByServiceTypeAggregate.serviceType
			} as TtpServiceByServiceType;

			var tppServices = [] as TtpService[];

			serviceByServiceTypeAggregate.services.forEach((serviceAggregate: TtpServiceAggregate) => {
				var filteredSubServices = this.toSubServices(serviceAggregate, providerName, statusFilter);
				if (filteredSubServices.length > 0) {
					var tppService = {
						id: serviceAggregate.id,
						name: serviceAggregate.name,
						description: serviceAggregate.description,
						isExpanded: false,
						isAdhoc: serviceAggregate.isAdhoc,
						ttpGroups: serviceAggregate.ttpGroups,
						subServiceSummary: this.toSubServiceSummary(serviceAggregate, providerName),
						subService: filteredSubServices
					} as TtpService;
					tppServices.push(tppService);
				}
			});

			if (tppServices.length > 0) {
				// only include the service by service type if there are one or more subservices
				// matching the current filter
				serviceByServiceType.services = tppServices;
				mappedServices.push(serviceByServiceType);
			}
		});

		return mappedServices;
	}

	private toSubServiceSummary(serviceAggregate: TtpServiceAggregate, providerName: string): SubServiceSummary {
		var subServiceSumary = {
			totalSubServiceBeneficiaries: serviceAggregate.subServiceSummaryAggregate.totalSubServiceBeneficiaries,
			oneOrMoreSubServicesMissingConfiguration:
				serviceAggregate.subServiceSummaryAggregate.oneOrMoreSubServicesMissingConfiguration,
			totalPartiallyConfiguredSubServices:
				serviceAggregate.subServiceSummaryAggregate.totalPartiallyConfiguredSubServices,
			totalFailedBeneficiaries: 0,
			totalProcessingBeneficiaries: 0,
			totalValidBeneficiaries: 0
		} as SubServiceSummary;

		var subServiceProviderSummmary = serviceAggregate.subServiceSummaryByProviderName.find(
			p => p.providerName == providerName
		);
		if (subServiceProviderSummmary) {
			subServiceSumary.totalFailedBeneficiaries = subServiceProviderSummmary.totalFailedBeneficiaries;
			subServiceSumary.totalBeneficiariesWhichCanBeValidated =
				subServiceProviderSummmary.totalBeneficiariesWhichCanBeValidated;
			subServiceSumary.totalProcessingBeneficiaries = subServiceProviderSummmary.totalProcessingBeneficiaries;
			subServiceSumary.totalValidBeneficiaries = subServiceProviderSummmary.totalValidBeneficiaries;
		}

		return subServiceSumary;
	}

	private toSubServices(
		serviceAggregate: TtpServiceAggregate,
		providerName: string,
		statusFilter: BeneficiaryStatusFilterType
	): SubService[] {
		var subServices = [] as SubService[];
		serviceAggregate.subService.forEach((subServiceAggregate: SubServiceAggregate) => {
			subServices.push({
				name: subServiceAggregate.name,
				group: subServiceAggregate.group,
				isConfigured: subServiceAggregate.isConfigured,
				isPartiallyConfigured: subServiceAggregate.isPartiallyConfigured,
				beneficiary: this.toBeneficiaryAccountAssignment(subServiceAggregate.beneficiary, providerName)
			} as SubService);
		});

		const ONE_OR_MORE_SUB_SERVICE_BENEFICIARY_MATCHES_FITLER = subServices.some(p =>
			this.beneficiaryStatusMatchesFilter(statusFilter, p.beneficiary)
		);
		if (ONE_OR_MORE_SUB_SERVICE_BENEFICIARY_MATCHES_FITLER) {
			return subServices;
		} else {
			return [];
		}
	}

	private beneficiaryStatusMatchesFilter(
		statusFilter: BeneficiaryStatusFilterType,
		beneficiaryAccountAssignment: BeneficiaryAccountAssignment
	) {
		const DISPLAY_STATUS = beneficiaryAccountAssignment.beneficiaryStatus.displayStatus;

		switch (statusFilter) {
			case "ALL":
				return true;

			case "FAILED":
				return DISPLAY_STATUS == "FAILED" && beneficiaryAccountAssignment.id != undefined;

			case "SUCCESS":
				return DISPLAY_STATUS == "SUCCESS" && beneficiaryAccountAssignment.id != undefined;

			case "PROCESSING":
				return DISPLAY_STATUS == "PROCESSING" && beneficiaryAccountAssignment.id != undefined;

			default:
				return true;
		}
	}

	private toBeneficiaryAccountAssignment(
		accountAggregate: BeneficiaryAccountAggregate,
		providerName: string
	): BeneficiaryAccountAssignment {
		var beneficiary = this.mapBeneficiaryAccountAssignment(accountAggregate, providerName);
		beneficiary.beneficiaryStatus = this.toBeneficiaryStatus(beneficiary.beneficiaryStatus);
		return beneficiary;
	}

	private mapBeneficiaryAccountAssignment(
		accountAggregate: BeneficiaryAccountAggregate,
		providerName: string
	): BeneficiaryAccountAssignment {
		var beneficiaryStatus = {} as BeneficiaryStatus;
		var beneficiary = {
			id: accountAggregate.id,
			name: accountAggregate.name,
			providerName: "",
			beneficiaryStatus: beneficiaryStatus,
			settlementAccountId: "",
			linkId: accountAggregate.linkId
		} as BeneficiaryAccountAssignment;

		if (accountAggregate.providers == undefined) {
			beneficiary.beneficiaryStatus.internalStatus = "NO_PROVIDER_ASSIGNMENT";
			this.logBeneficiaryHasNoProviders(beneficiary);
			return beneficiary;
		}
		// COMMENTED OUT FOR REVIEWING PURPOSES:
		// const hasAssignmentToAnotherProvider = (element: any) => {
		// 	console.log("flatty0", element.providerName, providerName);
		// 	return element.providerName != providerName && element.providerName != "NO_PROVIDER_ASSIGNMENT";
		// };
		// var isAssignedToAnotherProvider = accountAggregate.providers.some(hasAssignmentToAnotherProvider);

		// if (isAssignedToAnotherProvider) {
		// 	beneficiary.beneficiaryStatus.internalStatus = "ASSIGNED_TO_ANOTHER_PROVIDER";
		// } else {
		if (accountAggregate.providers.some(p => p.providerName == providerName)) {
			var provider = accountAggregate.providers.find(p => p.providerName == providerName);

			if (provider == undefined) {
				//	console.warn("Provider is undefined this should not happen");
			} else {
				beneficiary.providerName = provider.providerName;
				beneficiary.beneficiaryStatus.internalStatus = provider.status;
				beneficiary.beneficiaryStatus.details = provider.detials ? provider.detials : null;
				beneficiary.settlementAccountId = provider.settlementAccountId;
			}
		} else {
			beneficiary.beneficiaryStatus.internalStatus = "NO_PROVIDER_ASSIGNMENT";
			this.logBeneficiaryHasNoProviders(beneficiary);
		}
		//}
		return beneficiary;
	}

	private toBeneficiaryStatus(beneficaryStatus: BeneficiaryStatus): BeneficiaryStatus {
		return {
			internalStatus: beneficaryStatus.internalStatus,
			displayStatus: mapInternalStatusToDisplayStatus(beneficaryStatus.internalStatus),
			details: beneficaryStatus.details
		} as BeneficiaryStatus;
	}

	private logBeneficiaryHasNoProviders(beneficiary: BeneficiaryAccountAssignment) {
		// Exists to aid in identifying beneficiaries didn't fit the happy path as the UI doesn't report these (Probably useful).
		// @See TppServiceByServiceTypeMapperService.mapBeneficiaryProviders
		// Any given beneficiary must have both a settlement account & group assignment.
		// To implmenent @see SubServiceBeneficiaryCalculator & count `NO_PROVIDER_ASSIGNMENT` as an error status, also update the view to show this `hidden status`.
		if (beneficiary.id == undefined) return; // handle no beneficiary case
	}
}
