import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import {
	ServiceProviderCountryItem,
	ServiceProviderObject
} from "src/app/shared/models/service-provider-types.interface";

@Component({
	selector: "app-available-provider-countries",
	templateUrl: "./available-provider-countries.component.html",
	styleUrls: ["./available-provider-countries.component.scss"]
})
export class AvailableProviderCountriesComponent implements OnInit {
	@Input() availableCountries: ServiceProviderCountryItem[] = [];
	@Input() provider!: ServiceProviderObject;

	searchForm!: FormGroup;
	providerPanelClosed: Observable<number> = new Observable<number>();
	filteredCountries: ServiceProviderCountryItem[] = [];

	constructor(private fb: FormBuilder) {}

	ngOnInit(): void {
		this.filteredCountries = this.availableCountries;
		this.searchForm = this.fb.group({
			search: new FormControl("")
		});
		this.getSearchFilterValue();
	}

	getSearchFilterValue() {
		this.searchForm.get("search")?.valueChanges.subscribe(value => {
			this.filteredCountries = this.filterCountries(value.toLowerCase());
		});
	}

	filterCountries(searchTerm: string): any {
		let filterSearch = this.availableCountries?.filter(
			(result: ServiceProviderCountryItem) =>
				result.country.name.toLowerCase().includes(searchTerm) ||
				result.country.code.toLowerCase().includes(searchTerm)
		);
		return filterSearch;
	}
}
