import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { MatMenuTrigger } from "@angular/material/menu";
import { Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { convertDateToSemiUTC } from "@shared/utils/date.util";
import { Observable, ReplaySubject, Subject } from "rxjs";
import { first, map, take, takeUntil } from "rxjs/operators";
import {
	PayCyclePageError,
	PayCycleYearPageable,
	isPayCycleYearPageable,
	isPayCylcePageError
} from "@modules/employee-data/services/facade/paycycle/model/PayCycleYearPageable";
import { PayElectiveTransactionsService } from "@modules/employee-data/services/pay-elective-transactions.service";
import { CalendarService } from "@shared/services/calendar/calendar.service";
import { MilestonesService } from "@shared/services/milestones/milestones.service";
import { PayCycleService } from "@shared/services/pay-cycle/pay-cycle.service";
import { MONTH_NAMES } from "src/app/shared/constants/months";
import { PaycycleWeek } from "src/app/shared/models/calendar-navigation";
import { Milestone, PayCycle } from "src/app/shared/models/pay-cycle.interface";
import { PayGroup } from "src/app/shared/models/pay-groups";
import { PermissionsService } from "@shared/services/permissions/permissions.service";
import { ToastService } from "@shared/services/toast/toast.service";
import { getSelectedCalendarMonthState } from "src/app/store";
import { UpdateSelectedCalendarMonth } from "src/app/store/actions/payCycleSelect.action";
import { AppState } from "src/app/store/models/state.model";
import { CalendarDay } from "./_models/calendar-day.model";

@Component({
	selector: "app-calendar",
	templateUrl: "./calendar.component.html",
	styleUrls: ["./calendar.component.scss"]
})
export class CalendarComponent implements OnInit, OnDestroy {
	payGroup!: PayGroup;
	payCycle!: PayCycle;

	@Input() set _payGroup(pg: PayGroup) {
		this.payGroup = pg;
		this.initialiseCalendar();
	}

	@Output() onDayClicked: EventEmitter<Date> = new EventEmitter<Date>();
	@Output() onPayCycleChanges: EventEmitter<PayCycle> = new EventEmitter<PayCycle>();
	@Output() onNoPayCyclesFound: EventEmitter<boolean> = new EventEmitter<boolean>(false);
	@Output() onEditCycle: EventEmitter<PayCycle> = new EventEmitter<PayCycle>();
	@ViewChild("triggerMenu") trigger!: MatMenuTrigger;

	canEditCalendar: boolean = false;
	canEditCycle = false;

	monthNames: Record<string, string>[] = MONTH_NAMES;
	organisedMonthNames: string[] = [];
	middle: number = 0;
	calendar: CalendarDay[] = [];
	selectedDate: Date = new Date();
	selectedCycleWeekId: string = "";
	selectedCycleWeek!: PaycycleWeek;

	//The indexs of the active day
	today: Date = new Date(new Date().setHours(0, 0, 0, 0));
	currentMonth: number = new Date().getMonth();
	selectedYear: number = new Date().getFullYear();

	payCycleSelectedDate: number = 0;
	payCycleSelectedMonth: number = 0;

	milestones: Milestone[] = [];
	overlappingMilestoneMonths$ = new ReplaySubject<number[]>(1);

	isCalendarRoute: boolean = false;

	navigationMenuYears: number[] = [];
	navigationMenuSelectedYear: number = 0;
	orderedPayCycles: PayCycle[] = [];
	orderedAllPayCycles: PayCycle[] = [];

	orderedAllPayCycles$!: Observable<PayCycle[]>;

	navigationMenuOffCycleId: string = "";

	payGroupFrequency: string = "";
	shownPayCycleWeeks: PaycycleWeek[] = [];
	frequencySpacingLayout: string = "";

	hasPaycyclesForNextYear: boolean = false;
	hasPaycyclesForPreviousYear: boolean = false;

	private destroy$: Subject<void> = new Subject();

	constructor(
		private payCycleService: PayCycleService,
		private calendarService: CalendarService,
		private milestonesService: MilestonesService,
		private store: Store<AppState>,
		private toast: ToastService,
		private router: Router,
		private permissions: PermissionsService,
		private transactionService: PayElectiveTransactionsService
	) {}

	ngOnInit(): void {
		this.permissions
			.canEditCalendarEvents()
			.pipe(take(1))
			.subscribe(res => {
				this.canEditCalendar = res;
			});

		this.permissions
			.canEditCycle()
			.pipe(take(1))
			.subscribe(res => {
				this.canEditCycle = res;
			});

		if (this.router.url === "/calendar/milestone-list") {
			this.isCalendarRoute = true;
		}
	}

	setupObservableSubscriptions() {
		this.payCycleService
			.createdOffCycleObservable()
			.pipe(take(1))
			.subscribe(res => {
				if (res.isReload === true) {
					this.initialiseCalendar();
					//this.getPayGroup(this.payGroup.id);
				}
			});

		this.calendarService
			.getMilestoneObservable()
			.pipe(takeUntil(this.destroy$))
			.subscribe(milestones => {
				if (milestones) {
					this.milestones = milestones;

					this.setMilestonesOnCalendar();
				}
			});
	}

	initialiseCalendar() {
		this.payCycle = {} as PayCycle;
		this.getPayCycles();
		this.setupObservableSubscriptions();
	}

	getPayCycles() {
		this.orderedAllPayCycles$ = this.payCycleService.getOrderedPayCycles(this.payGroup.id).pipe(take(1));

		this.orderedAllPayCycles$.subscribe(payCycles => {
			if (payCycles) {
				this.orderedAllPayCycles = payCycles;
				this.getNavigationMenuYears(this.orderedAllPayCycles$);
				this.checkForPayCycleInStore();
				if (this.payGroup.data && this.payGroup.data.frequency) {
					this.payGroupFrequency = this.payGroup.data.frequency;
				}
			}
		});
	}

	checkForPayCycleInStore() {
		//First check if there is anything in store - if not check for current year

		this.store.pipe(first(), select(getSelectedCalendarMonthState)).subscribe(state => {
			if (state) {
				if (this.payGroup && this.payGroup.id === state.selectedMonth?.paygroupId) {
					if (state.selectedMonth?.payCycle != null) {
						this.currentMonth = new Date(state.selectedMonth?.payCycle.start).getMonth();
						this.selectedYear = new Date(state.selectedMonth?.payCycle.start).getFullYear();
						this.setDataForPayCycle(state.selectedMonth.payCycle);
					} else {
						this.checkPayCyclesForCurrentYear();
					}
				} else {
					this.checkPayCyclesForCurrentYear();
				}
			} else {
				this.checkPayCyclesForCurrentYear();
			}
		});
	}

	checkPayCyclesForCurrentYear() {
		this.transactionService
			.getActivePayCycle(this.payGroup.id)
			.subscribe((result: PayCycleYearPageable | PayCyclePageError) => {
				let paycycle: PayCycle | undefined = this.handleResult(result);
				if (paycycle) {
					this.currentMonth = new Date(paycycle.start).getMonth();
					this.selectedYear = new Date(paycycle.start).getFullYear();
					this.setDataForPayCycle(paycycle);
				} else {
					this.setDataForPayCycle();
				}
			});
	}

	setDataForPayCycle(paycycle?: PayCycle) {
		this.navigationMenuSelectedYear = this.selectedYear;

		if (paycycle) {
			this.getOrderedPayCycles(this.selectedYear, true, paycycle);
		} else {
			this.getOrderedPayCycles(this.selectedYear, true);
		}

		if (this.payGroupFrequency === "MONTHLY" || this.payGroupFrequency === "BI_MONTHLY") {
			this.frequencySpacingLayout = "space-between center";
		} else {
			this.frequencySpacingLayout = "center center";
		}
	}

	handleResult(result: PayCycleYearPageable | PayCyclePageError): PayCycle | undefined {
		if (isPayCylcePageError(result)) {
			var error = result as PayCyclePageError;
			this.toast.showError(error.message);
			return undefined;
		} else if (isPayCycleYearPageable(result)) {
			var payCycleData = result as PayCycleYearPageable;

			this.hasPaycyclesForNextYear = payCycleData.hasNextYear;
			this.hasPaycyclesForPreviousYear = payCycleData.hasPreviousYear;

			return payCycleData.filteredPayCycle.cycle;
		}
		this.onNoPayCyclesFound.emit(true);
		return undefined;
	}

	getOrderedPayCycles(selectedYear: number, firstRun: boolean = false, payCycle?: PayCycle) {
		this.orderedAllPayCycles$.pipe(take(1)).subscribe(payCycles => {
			if (selectedYear) {
				payCycles = this.getPayCycleRange(payCycles, selectedYear);
			}

			this.orderedPayCycles = payCycles;

			payCycles.filter(cycle => {
				//get updated version of state paycycle
				if (cycle.id === payCycle?.id) {
					payCycle = cycle;
				}
			});

			if (payCycle) {
				this.generateCalendarDays(payCycle);
			} else {
				this.generateCalendarDays();
			}
		});
	}

	generateCalendarDays(payCycle?: PayCycle): void {
		let divSize = 9;
		this.middle = divSize / 2 - (divSize % 2) / 2;

		if (payCycle) {
			this.generateDays(convertDateToSemiUTC(new Date(payCycle.start)));

			this.store.dispatch(
				new UpdateSelectedCalendarMonth({
					selectedMonth: {
						month: new Date(payCycle.start).getMonth(),
						paygroupId: this.payGroup.id,
						payCycle: payCycle
					}
				})
			);

			this.selectPayCycle(payCycle);
		} else {
			this.reset();
		}
	}

	reset() {
		if (this.orderedPayCycles.length > 0) {
			this.generateCalendarDays(
				this.orderedPayCycles[this.calendarService.getResetPayCycleIndex(this.orderedPayCycles, this.payCycle!)]
			);
		} else {
			this.onNoPayCyclesFound.emit(true);
		}
	}

	generateDays(date: Date) {
		this.calendar = [];
		this.setSelectedDate(date);
		this.calendar = this.calendarService.generateCalendarDays(this.selectedDate);
		this.organisedMonthNames = this.calendarService.organizeMonths(date);
	}

	setSelectedDate(date: Date, clicked: boolean = false) {
		this.selectedDate = date;
		this.currentMonth = this.selectedDate.getMonth();
		this.selectedYear = this.selectedDate.getFullYear();

		if (clicked) {
			this.onDayClicked.emit(this.selectedDate);
		}
	}

	selectPayCycle(payCycle: PayCycle) {
		this.milestones = [];
		this.overlappingMilestoneMonths$.next([]);
		this.payCycle = payCycle;

		this.payCycleSelectedMonth = new Date(this.payCycle.start).getMonth();
		this.payCycleSelectedDate = new Date(this.payCycle.start).getDate();
		this.shownPayCycleWeeks = this.calendarService.organizeWeeks(payCycle, this.orderedPayCycles);

		const index = this.shownPayCycleWeeks.findIndex(cycleWeek => {
			return cycleWeek.id === payCycle!.id;
		});
		this.selectedCycleWeek = this.shownPayCycleWeeks[index];
		this.selectedCycleWeekId = payCycle!.id;

		this.payCycle = payCycle;
		this.onPayCycleChanges.emit(payCycle);

		if (payCycle) {
			this.setMilestonesOnCalendar();
			this.setOverlappingMilestones(payCycle.id);
		}
	}

	setOverlappingMilestones(payCycleId: string) {
		this.milestonesService
			.getOverlappingMilestones(payCycleId)
			.pipe(takeUntil(this.destroy$))
			.subscribe(milestone => {
				const overlappingMilestones: number[] = [];
				milestone.forEach(milestoneMonth => {
					// we get the milestones of the current paycycle, which are just the month names
					// then we look up the index of this month name in a list of the months we are currently seeing on screen
					// we then make a list of these indeces

					overlappingMilestones.push(this.organisedMonthNames.indexOf(milestoneMonth));
				});
				this.overlappingMilestoneMonths$.next(overlappingMilestones);
			});
	}

	overlappingCheck(month: number): boolean {
		let isOverlapping = false;
		this.overlappingMilestoneMonths$.pipe(take(1)).subscribe(milestoneMonth => {
			if (milestoneMonth.includes(month)) {
				isOverlapping = true;
			}
		});

		return isOverlapping;
	}

	setMilestonesOnCalendar(): void {
		this.calendar.map((day: CalendarDay) => {
			day.setMilestones(this.milestones);
		});
	}

	getNavigationMenuYears(orderedAllPayCycles$: Observable<PayCycle[]>) {
		this.navigationMenuYears = [];
		this.payCycleService
			.getNavigationMenuYears(orderedAllPayCycles$)
			.pipe(
				take(1),
				map(years => {
					this.navigationMenuYears = years;
				})
			)
			.subscribe();
	}

	checkPayCyclesForSelectedYear(newYear: number) {
		this.orderedAllPayCycles$.pipe(take(1)).subscribe(payCycles => {
			payCycles = this.getPayCycleRange(payCycles, newYear);

			if (payCycles.length > 0) {
				this.generateCalendarDays(payCycles[0]);
				this.selectedYear = newYear;
			} else {
				this.toast.showError("Sorry, you don't have any pay cycles for " + newYear);
			}
		});
	}

	getPayCycleRange(payCycles: PayCycle[], selectedYear: number): PayCycle[] {
		let rangeStart: string = "";
		let rangeEnd: string = "";

		if (selectedYear) {
			rangeStart = selectedYear.toString() + "-01-01";
			rangeEnd = selectedYear.toString() + "-12-31";
		}

		return payCycles.filter((payCycle: PayCycle) => {
			return new Date(payCycle.start) >= new Date(rangeStart) && new Date(payCycle.start) <= new Date(rangeEnd);
		});
	}

	navigateToCycle(cycle: PayCycle): void {
		this.generateCalendarDays(cycle);
	}

	nextCycle(): void {
		const index = this.orderedAllPayCycles.findIndex(payCycle => {
			return payCycle.id === this.payCycle!.id;
		});

		if (this.orderedAllPayCycles[index + 1]) {
			this.generateCalendarDays(this.orderedAllPayCycles[index + 1]);
		} else {
			this.toast.showInfo(
				`You don't have Pay Cycles for the Month of ${
					this.monthNames[new Date(this.orderedAllPayCycles[index].start).getMonth() + 1].full
				}`
			);
		}
	}

	previousCycle(): void {
		const index = this.orderedAllPayCycles.findIndex(payCycle => {
			return payCycle.id === this.payCycle!.id;
		});

		if (this.orderedAllPayCycles[index - 1]) {
			this.generateCalendarDays(this.orderedAllPayCycles[index - 1]);
		} else {
			let month = new Date(this.orderedAllPayCycles[index].start).getMonth() - 1;
			if (month < 0) {
				month = 11;
			}

			this.toast.showInfo(`You don't have Pay Cycles for the Month of ${this.monthNames[month].full}`);
		}
	}

	nextYear(): void {
		this.checkPayCyclesForSelectedYear(this.selectedYear + 1);
	}

	previousYear(): void {
		this.checkPayCyclesForSelectedYear(this.selectedYear - 1);
	}

	onCycleClick(cycleIndexClicked: number, payCycle: PayCycle, frequency: string): void {
		if (frequency === "monthly") {
			const index = MONTH_NAMES.findIndex(month => {
				return month.full === this.organisedMonthNames[cycleIndexClicked];
			});

			this.updateCalendarAfterCycleClick(payCycle, new Date(`${this.selectedYear}-${index + 1}-01`));
		} else {
			this.selectedCycleWeekId = this.shownPayCycleWeeks[cycleIndexClicked].id;
			this.selectedCycleWeek = this.shownPayCycleWeeks[cycleIndexClicked];

			this.updateCalendarAfterCycleClick(
				payCycle,
				new Date(`${this.shownPayCycleWeeks[cycleIndexClicked].startDate}`)
			);
		}
	}

	updateCalendarAfterCycleClick(payCycle: PayCycle, date: Date): void {
		this.onPayCycleChanges.emit(payCycle);
		this.generateDays(date);
		this.setOverlappingMilestones(payCycle.id);
		this.setMilestonesOnCalendar();
	}

	resetToToday(): void {
		const date = new Date(new Date().getFullYear(), new Date().getMonth(), this.today.getDay());

		const index = this.orderedPayCycles.findIndex(payCycle => {
			return (
				new Date(payCycle.start).getMonth() === new Date().getMonth() &&
				new Date(payCycle.start).getFullYear() === new Date().getFullYear()
			);
		});

		if (index === -1) {
			this.toast.showInfo(
				`You don't have Pay Cycles for the Month of ${this.monthNames[new Date().getMonth()].full}`
			);
		} else {
			this.today = new Date(new Date().setHours(0, 0, 0, 0));
			this.currentMonth = new Date().getMonth();
			this.selectedYear = new Date().getFullYear();

			this.generateCalendarDays(this.orderedPayCycles[index]);
		}
	}

	editCycle(payCycle: PayCycle): void {
		this.onEditCycle.emit(payCycle);
	}

	switchOrderedPayCycles(payCycle: PayCycle): void {
		this.orderedAllPayCycles$.pipe(take(1)).subscribe(payCycles => {
			if (this.selectedYear) {
				payCycles = this.getPayCycleRange(payCycles, this.selectedYear);
			}

			this.orderedPayCycles = payCycles;

			payCycles.filter(cycle => {
				if (cycle.id === payCycle?.id) {
					payCycle = cycle;
				}
			});
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
