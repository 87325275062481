<div
	*ngIf="(countrySelectOptions$ | async)?.length"
	class="country-content flex flex-column flex-align-stretch-space-between"
>
	<h2 class="form-title form-title-gap">{{ isSuperUser ? "Add A Country" : "Countries" }}</h2>
	<form [formGroup]="countryForm" *ngIf="isSuperUser">
		<div class="flex flex-row flex-align-center-space-around">
			<input-wrapper
				class="select country-select-container flex-px"
				style="--basis: calc(70% - 35px); --min-px-width: calc(70% - 35px)"
			>
				<input-autocomplete
					id="country"
					label="please select a country"
					[formControlName]="'country'"
					[options]="countrySelectOptions$ | async"
				>
				</input-autocomplete>
			</input-wrapper>
			<span
				class="button-container flex flex-align-stretch-end flex-px"
				style="--basis: calc(30% - 35px); --min-px-width: calc(30% - 35px)"
			>
				<button
					id="submitCountry"
					(click)="createCountry()"
					mat-button
					class="c-button-dashed"
					color="primary"
					[disabled]="!countryForm.controls.country.value"
				>
					Create Country
				</button>
			</span>
		</div>
	</form>
</div>
