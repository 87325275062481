import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { MatFormFieldModule } from "@angular/material/form-field";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { MatInputModule } from "@angular/material/input";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatIconModule } from "@angular/material/icon";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { WpayAutoCompleteComponent } from "./wpay-auto-complete.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { IconModule } from "@shared/components/icon/icon.module";
import { MatProgressBarModule } from "@angular/material/progress-bar";

@NgModule({
	declarations: [WpayAutoCompleteComponent],
	imports: [
		CommonModule,
		MatFormFieldModule,
		FormsModule,
		ReactiveFormsModule,
		MatTooltipModule,
		MatInputModule,
		MatIconModule,
		MatAutocompleteModule,
		MatCheckboxModule,
		IconModule,
		MatProgressBarModule
	],
	exports: [WpayAutoCompleteComponent]
})
export class WpayAutoCompleteModule {}
