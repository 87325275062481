import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { HistoryLogSidePanelRoutingModule } from "./history-log-side-panel-routing.module";
import { SlidingPanelModule } from "@shared/components/sliding-panel/sliding-panel.module";
import { HistoryLogSidePanelComponent } from "./container/history-log-side-panel.component";
import { IconModule } from "../icon/icon.module";
import { PipesModule } from "@shared/pipes/pipes.module";
import { MatFormFieldModule } from "@angular/material/form-field";
import { NoItemsLayoutModule } from "../no-items-layout/no-items-layout.module";
import { MatDividerModule } from "@angular/material/divider";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "@shared/shared.module";
import { MatInputModule } from "@angular/material/input";

@NgModule({
	declarations: [HistoryLogSidePanelComponent],
	exports: [HistoryLogSidePanelComponent],
	imports: [
		SharedModule,
		CommonModule,
		HistoryLogSidePanelRoutingModule,
		SlidingPanelModule,
		IconModule,
		PipesModule,
		MatFormFieldModule,
		NoItemsLayoutModule,
		MatDividerModule,
		FormsModule,
		ReactiveFormsModule,
		MatInputModule
	]
})
export class HistoryLogSidePanelModule {}
