import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { forkJoin, Observable, of } from "rxjs";
import { catchError, map, take, timeout } from "rxjs/operators";
import { ErrorDialogComponent } from "@shared/components/error-dialog/error-dialog.component";
import { ToastService } from "@shared/services/toast/toast.service";
import { Pagination } from "src/app/shared/models/pagination.interface";
import { environment } from "src/environments/environment";
import {
	PayoutStatus,
	TppSubService,
	TppSubServiceDetailsWithPagination,
	UploadMethod
} from "../tpp-payments/models/tpp-data.interface";
import { PayCycleService } from "@shared/services/pay-cycle/pay-cycle.service";
import { MilestoneGroupName, TppPayCycleSubServices } from "src/app/shared/models/pay-cycle.interface";
import { SubService } from "src/app/shared/models/tpp-service.interface";

import { TppPayoutsWithPagination } from "../tpp-payments/models/tpp-payment.interface";
import { TppPaymentsApiService } from "./tpp-payments-api.service";
import { GeneralErrorType } from "@shared/models/file-manager";

@Injectable({
	providedIn: "root"
})
export class TppDataInputApi {
	constructor(
		private toastService: ToastService,
		private http: HttpClient,
		public dialog: MatDialog,
		private payCycleService: PayCycleService,
		private tppPaymentsApiService: TppPaymentsApiService
	) {}

	getSubServices(milestoneId: string): Observable<TppSubService[]> {
		return this.http.get<TppSubService[]>(
			`${environment.apiUri}/v1/payouts/tpp/aggregated?milestoneId=${milestoneId}`
		);
	}

	getPayCycleSubServices(
		milestoneId: string,
		payCycleId: string,
		tppType: MilestoneGroupName
	): Observable<TppSubService[]> {
		//join aggri and paycycle subservices
		//filter paycycle subservices by current TPP
		//add subservices not in aggri to return val

		return forkJoin([
			this.getSubServices(milestoneId),
			this.payCycleService.getTPPGroupsForPaycycle(payCycleId)
		]).pipe(
			map(([agregatedSubServices, payCycleSubServices]: [TppSubService[], TppPayCycleSubServices[]]) => {
				const filteredPcSubservices: TppPayCycleSubServices[] = payCycleSubServices.filter(
					subService => subService.group === tppType
				);

				if (filteredPcSubservices.length > 0) {
					let subServiceIds: string[] = [];

					agregatedSubServices.forEach(aggSubService => {
						subServiceIds.push(aggSubService.subService.id);
					});

					const unConfiguredSubservices = filteredPcSubservices.filter(
						filteredSubservice => !subServiceIds.includes(filteredSubservice.subServiceId)
					);

					if (unConfiguredSubservices.length > 0) {
						unConfiguredSubservices.forEach(unConfiguredSubservice => {
							agregatedSubServices.push({
								subService: unConfiguredSubservice.subService
									? unConfiguredSubservice.subService
									: ({} as SubService),
								paymentBy:
									unConfiguredSubservice.subService &&
									unConfiguredSubservice.subService.paymentOriginator
										? unConfiguredSubservice.subService.paymentOriginator
										: "",
								reference:
									unConfiguredSubservice.subService && unConfiguredSubservice.subService.referenceType
										? unConfiguredSubservice.subService.referenceType
										: null,
								referenceOkay: false,
								amount: { value: 0, currency: "" },
								uploadedBy: [UploadMethod.PENDING],
								valueDate: null,
								countByStatus: {
									[PayoutStatus.FAILED]: 0,
									[PayoutStatus.PENDING]: 1,
									[PayoutStatus.SUCCESS]: 0,
									[PayoutStatus.PROCESSED]: 0,
									[PayoutStatus.PROCESSING]: 0,
									[PayoutStatus.CREATED]: 0,
									[PayoutStatus.INACTIVE]: 0,
									[PayoutStatus.CANCELLED]: 0
								}
							});
						});

						return agregatedSubServices;
					} else {
						return agregatedSubServices;
					}
				} else {
					return agregatedSubServices;
				}

				let temp: TppSubService[] = [];
				return temp;
			})
		);
	}

	getPaginition(): Pagination {
		return {
			pageSize: 10,
			pageIndex: 0,
			totalPageCount: 4
		};
	}

	uploadFile(files: any, payCycleId: string, group: string): Observable<void> {
		let formData = new FormData();

		const file: File = files[0];

		formData.append("file", file);
		formData.append("payCycleId", payCycleId);
		formData.append("fileType", "CSV");
		formData.append("group", group);

		return this.uploadTPPFile(formData).pipe(
			timeout(5000),
			map(res => {
				this.toastService.showInfo("The file has successfully been submitted for upload process");
				return res;
			}),
			catchError((err: any) => {
				if (err.name === "TimeoutError") {
					this.toastService.showInfo(
						"The upload process has started, but is taking longer then usual. You can periodically refresh the file list."
					);

					return of(err.status.text);
				} else {
					switch (err.status) {
						case 200:
							// uploaded with no errors
							this.toastService.showSuccess(
								"The file has successfully been submitted for upload process"
							);
							return of(err.error.message);

						case 422:
							// uploaded with missing headers errors

							let errorsMessages: string = "";
							err.error.forEach((error: GeneralErrorType) => {
								errorsMessages += `${error.message}<br>`;
							});
							this.toastService.showError(errorsMessages);

							return of(err.error.message);

						default:
							this.toastService.showError(err.error.message);
							return of(err.error.message);
					}
				}
			})
		);
	}

	uploadTPPFile(formData: FormData): Observable<any> {
		return this.http.post(`${environment.apiUri}/v1/tpp/payouts/files`, formData);
	}

	getSubserviceDetails(
		payCycleId: string,
		milestoneId: string,
		subService: TppSubService,
		pageSize: number,
		pageNumber: number
	): Observable<TppSubServiceDetailsWithPagination> {
		return this.tppPaymentsApiService
			.getPayouts(payCycleId, milestoneId, subService.subService.id, pageSize, pageNumber, [])
			.pipe(
				take(1),
				map((employeeData: TppPayoutsWithPagination) => {
					//convert payout data to employee data
					//add pagination details

					let subserviceEmployeeData: TppSubServiceDetailsWithPagination = {
						items: [],
						totalCount: 0,
						totalPages: 0,
						pageNumber: 0
					};

					subserviceEmployeeData.totalCount = employeeData.totalCount;
					subserviceEmployeeData.pageNumber = employeeData.pageNumber;
					subserviceEmployeeData.totalPages = employeeData.totalPages;

					employeeData.items.forEach(employee => {
						subserviceEmployeeData.items.push({
							paymentType: "EMPLOYEE",
							employeeId: employee.employeeId ? employee.employeeId : "",
							employeeName: "",
							subserviceName: subService.subService.id,
							reference: employee.reference,
							amount: employee.amount,
							valueDate: employee.valueDate,
							uploaded: employee.attributes.SOURCE
						});
					});

					return subserviceEmployeeData;
				})
			);
	}

	openDialog(errors: Record<string, string>[]): void {
		this.dialog.open(ErrorDialogComponent, {
			width: "750px",
			height: "75%",
			panelClass: "upload-modalbox",
			data: {
				errors
			}
		});
	}
}
