import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTableModule } from "@angular/material/table";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatFormFieldModule } from "@angular/material/form-field";

import { CalendarSetupFileHistoryComponent } from "./calendar-setup-file-history/calendar-setup-file-history.component";
import { BreadcrumbsModule } from "@shared/components/breadcrumbs/breadcrumbs.module";
import { CalendarSetupFileHistoryRoutingModule } from "./calendar-setup-file-history-routing.module";
import { SharedModule } from "@shared/shared.module";
import { PipesModule } from "@shared/pipes/pipes.module";
import { NoItemsLayoutModule } from "@shared/components/no-items-layout/no-items-layout.module";
import { ModalWrapperModule } from "@shared/components/modal-wrapper/modal-wrapper.module";
import { CalendarFileListComponent } from "./calendar-file-list/calendar-file-list.component";
import { CalendarFileHistoryErrorsComponent } from "./calendar-file-history-errors/calendar-file-history-errors.component";
import { SlidingPanelModule } from "@shared/components/sliding-panel/sliding-panel.module";
import { MatInputModule } from "@angular/material/input";
import { CalendarFileStatusPipe } from "./pipe/calendar-file-status.pipe";
import { IconModule } from "@shared/components/icon/icon.module";

@NgModule({
	declarations: [
		CalendarSetupFileHistoryComponent,
		CalendarFileListComponent,
		CalendarFileHistoryErrorsComponent,
		CalendarFileStatusPipe
	],
	imports: [
		CommonModule,
		BreadcrumbsModule,
		CalendarSetupFileHistoryRoutingModule,
		FormsModule,
		MatTableModule,
		ReactiveFormsModule,
		SharedModule,
		PipesModule,
		NoItemsLayoutModule,
		ModalWrapperModule,
		MatTooltipModule,
		MatPaginatorModule,
		SlidingPanelModule,
		MatFormFieldModule,
		MatInputModule,
		IconModule
	],
	exports: [CalendarSetupFileHistoryComponent]
})
export class CalendarSetupFileHistoryModule {}
