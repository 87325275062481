<form class="my-form" *ngIf="form" [formGroup]="form">
	<div
		formArrayName="contactPersons"
		*ngIf="allUsers && contactPersonsFormArray().controls.length > 0"
		class="wrapper"
	>
		<div
			*ngFor="let person of contactPersonsFormArray().controls; let i = index"
			class="contacts-container flex flex-row flex-align-stretch-center"
		>
			<div [formGroupName]="i" class="contact-person flex flex-row flex-align-center-space-between">
				<input-wrapper class="select custom-wrapper" label="Contact person">
					<input-autocomplete
						formControlName="name"
						[label]="'Contact person'"
						[disabled]="!this.canEditContactPersons"
						[options]="users$ | async"
					>
					</input-autocomplete>
					<div
						input-errors
						*ngIf="
							(person.get('name')?.dirty || person.get('name')?.touched) &&
							person.get('name')?.errors?.required
						"
					>
						Contact person name is required
					</div>
				</input-wrapper>

				<input-wrapper class="select" class="custom-wrapper" label="Contact position">
					<input
						[disabled]="true"
						formControlName="positions"
						readonly
						[placeholder]="'Contact position'"
						[matTooltip]="person.get('positions')?.value"
					/>
				</input-wrapper>

				<input-wrapper class="custom-wrapper" label="Contact email *">
					<input type="text" placeholder="Contact person email" formControlName="email" />
					<div
						input-errors
						*ngIf="
							(person.get('email')?.dirty || person.get('email')?.touched) &&
							person.get('email')?.errors?.required
						"
					>
						Contact person email is required
					</div>
				</input-wrapper>

				<input-wrapper class="custom-wrapper" label="Contact phone">
					<input type="number" placeholder="Contact phone" formControlName="phone" />
				</input-wrapper>

				<div *ngIf="canEditContactPersons" (click)="removeContactPerson(i)" class="delete-icon">
					<app-icon
						id="trash-contact"
						[name]="'trash'"
						[color]="'var(--inactive-text-red)'"
						[size]="'28'"
					></app-icon>
				</div>
			</div>
		</div>
	</div>
</form>
<div class="pulsate" *ngIf="!allUsers">Fetching users...</div>
