import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { GlobalDashboardEventsService } from "@modules/global-dashboard/services/global-dashboard-events.service";
import { getTableExpandAndCollapseAnimation } from "@shared/utils/mat-table-animation.util";
import {
	FailedPaymentOrdersTableDetailItem,
	PayGroupDetailsWithFailedPaymentOrdersCount
} from "@modules/global-dashboard/models/global-dashboard-interface";

@Component({
	selector: "app-failed-payment-orders-table",
	templateUrl: "./failed-payment-orders-table.component.html",
	styleUrls: ["./failed-payment-orders-table.component.scss"],
	animations: [getTableExpandAndCollapseAnimation]
})
export class FailedPaymentOrdersTableComponent {
	@Input() set failedPaymentOrders(failedPaymentOrders: FailedPaymentOrdersTableDetailItem[]) {
		this.dataSourceFailedPaymentOrders.data = failedPaymentOrders;
		this.customerColumns = ["Customers", "Paygroups", "Paycycles", "Payments", "Payment orders", "expand"];
		this.paygroupsColumns = [
			"first-padding",
			"Paygroups",
			"Paycycles",
			"Group",
			"Payment orders",
			"second-padding"
		];

		this.toggleElement(failedPaymentOrders[0]);
	}

	dataSourceFailedPaymentOrders: MatTableDataSource<FailedPaymentOrdersTableDetailItem> = new MatTableDataSource();
	customerColumns: string[] = [];
	paygroupsColumns: string[] = [];
	expandedElement!: FailedPaymentOrdersTableDetailItem | null;

	constructor(private cdr: ChangeDetectorRef, private globalDashboardEventsService: GlobalDashboardEventsService) {}

	toggleRow(element: FailedPaymentOrdersTableDetailItem): void {
		element.payGroups && element.payGroups.length ? this.toggleElement(element) : null;
		this.cdr.detectChanges();
	}

	expandedState(row: FailedPaymentOrdersTableDetailItem): string {
		return row &&
			row.customerDetails &&
			this.expandedElement &&
			this.expandedElement.customerDetails.id === row.customerDetails.id
			? "expanded"
			: "collapsed";
	}

	isExpanded(row: FailedPaymentOrdersTableDetailItem): boolean {
		return row &&
			row.customerDetails &&
			this.expandedElement &&
			this.expandedElement.customerDetails.id === row.customerDetails.id
			? true
			: false;
	}

	toggleElement(row: FailedPaymentOrdersTableDetailItem): void {
		row &&
		row.customerDetails &&
		this.expandedElement &&
		this.expandedElement.customerDetails.id === row.customerDetails.id
			? (this.expandedElement = null)
			: (this.expandedElement = row);
	}

	routeToPaymentOrders(row: PayGroupDetailsWithFailedPaymentOrdersCount) {
		this.globalDashboardEventsService.routeToSpecifiedScreen(
			"paymentOrders",
			row.payGroup.id,
			row.payCycle.id,
			row.group
		);
	}
}
