import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PayGroupsComponent } from "./pay-groups.component";
import { PayGroupListComponent } from "./_components/pay-group-list/pay-group-list.component";
import { PayGroupComponent } from "./_components/pay-group/pay-group.component";

const routes: Routes = [
	{
		path: "",
		component: PayGroupsComponent
	},
	{
		path: "list",
		component: PayGroupListComponent
	},
	{
		path: "group",
		component: PayGroupComponent
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class PayGroupsRoutingModule {}
