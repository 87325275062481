import { CurrencyPipe } from "@angular/common";
import { Component, Input } from "@angular/core";
import { MilestoneCard, MilestoneConfig } from "@shared/models/milestone-cards.interface";

@Component({
	selector: "app-milestone-cards",
	templateUrl: "./milestone-cards.component.html",
	styleUrls: ["./milestone-cards.component.scss"]
})
export class MilestoneCardsComponent {
	@Input() datasource: MilestoneCard[] = [];

	milestoneValues: MilestoneConfig[] = [
		{
			name: "totalCount",
			label: "headcount",
			icon: "profile-2user",
			iconClass: "icon-headcount"
		},
		{
			name: "totalAmount",
			label: "total payments",
			icon: "receipt-add",
			iconClass: "icon-payments"
		}
	];

	constructor(private currencyPipe: CurrencyPipe) {}

	ngOnInit(): void {}

	getIconClass(name: string): string {
		return this.milestoneValues.find(milestone => milestone.name === name)?.iconClass ?? "";
	}

	getIconName(name: string): string {
		return this.milestoneValues.find(milestone => milestone.name === name)?.icon ?? "";
	}

	getCardLabel(name: any): string {
		return this.milestoneValues.find(milestone => milestone.name === name)?.label ?? "";
	}

	getCardText(card: any): string {
		switch (card.milestoneLabel) {
			case "totalCount":
				return `${card.milestoneValue} Employee${card.milestoneValue != 1 ? "s" : ""}`;
			case "totalAmount":
				return `${this.currencyPipe.transform(
					card.milestoneValue.value,
					card.milestoneValue.currency,
					"symbol",
					"1.2-2"
				)}`;
			default:
				return "";
		}
	}
}
