import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable, Output } from "@angular/core";
import { Observable, forkJoin, of } from "rxjs";
import { catchError, map, take } from "rxjs/operators";

import { Pagination } from "src/app/shared/models/pagination.interface";
import { MilestoneReport, PayoutPagination, PayoutProcessingRoute } from "src/app/shared/models/payouts.types";
import { environment } from "src/environments/environment";
import { TppSubService } from "../tpp-payments/models/tpp-data.interface";
import {
	Amount,
	Booking,
	TppPayouts,
	TppPayoutsWithPagination,
	cancelReportWithPagination
} from "../tpp-payments/models/tpp-payment.interface";
import { PaymentsApiService } from "./payments-api.service";
import { EmployeeService } from "@shared/services/employee/employee.service";
import { Employee } from "@shared/models/employee.interface";

@Injectable({
	providedIn: "root"
})
export class TppPaymentsApiService {
	DEFAULT_MILESTONE_VALUES: MilestoneReport = {
		totalTransactionCount: 0,
		failedTransactionCount: 0,
		totalHeadCount: 0,
		totalPayoutAmount: {
			value: 0,
			currency: "GBP"
		},
		totalFailedAmount: {
			value: 0,
			currency: "GBP"
		},
		totalFundingAmount: {
			value: 0,
			currency: "GBP"
		}
	};

	constructor(
		private http: HttpClient,
		private paymentApiService: PaymentsApiService,
		private employeeService: EmployeeService
	) {}

	@Output() reloadPayoutAfterCancelling: EventEmitter<void> = new EventEmitter<void>();

	getMilestoneReport(milestoneId: string): Observable<MilestoneReport> {
		return this.http.get<MilestoneReport>(`${environment.apiUri}/v1/milestone/${milestoneId}/report`);
	}

	getBookings(
		milestoneId: string,
		payCycleId: string,
		subServiceId: string,
		pageSize: number,
		pageNumber: number,
		statusFilter: string[]
	): Observable<Booking[]> {
		//fork join getSubServices And searchPayouts

		//Group payouts data by subservice id -> make array of employee id's

		//Group the two endpoints data into one array interface Booking

		return forkJoin([
			this.getSubServices(milestoneId),
			this.getPayouts(payCycleId, milestoneId, subServiceId, pageSize, pageNumber, statusFilter)
		]).pipe(
			take(1),
			map(([subServices, payoutsWithPagination]) => {
				const payouts: TppPayouts[] = payoutsWithPagination.items;
				let bookings: Booking[] = [];

				subServices.forEach(subService => {
					const listOfMatchingPayouts: TppPayouts[] = payouts.filter(
						payout => payout.subServiceId === subService.subService.id
					);

					let totalAmount: Amount = { value: 0, currency: "" };

					listOfMatchingPayouts.forEach(payout => {
						totalAmount.value += payout.amount.value; //For total amount
						totalAmount.currency = payout.amount.currency; //For amount currency

						//get employee details

						if (payout.employeeId) {
							this.employeeService
								.getEmployeesById(payout.employeeId)
								.pipe(take(1))
								.subscribe((empData: Employee) => {
									payout.employeeName = empData.data.firstName;
									payout.employeeSurname = empData.data.lastName;
								});
						} else {
							payout.employeeName = "";
							payout.employeeSurname = "";
						}
						//Get latest transaction
						payout.transactions = this.paymentApiService.filterTransactions(payout.transactions);
					});

					let booking: Booking = {
						subService: subService.subService,
						paymentBy: subService.paymentBy,
						reference: subService.reference,
						referenceOkay: subService.referenceOkay,
						amount: totalAmount,
						uploadedBy: subService.uploadedBy,
						valueDate: subService.valueDate,
						countByStatus: subService.countByStatus,
						payouts: listOfMatchingPayouts
					};

					bookings.push(booking);
				});

				return bookings;
			}),
			catchError(error => {
				//Add error handeling

				let temp: Booking[] = [];
				return of(temp);
			})
		);
	}

	getPayouts(
		payCycleId: string,
		milestoneId: string,
		subServiceId: string,
		pageSize: number,
		pageNumber: number,
		statusFilter: string[]
	): Observable<TppPayoutsWithPagination> {
		return this.http.get<TppPayoutsWithPagination>(
			`${environment.apiUri}/v1/payouts/tpp?pageSize=${pageSize}&pageNumber=${pageNumber}&payCyclesIds=${payCycleId}&subServiceIds=${subServiceId}&milestoneIds=${milestoneId}&transactionStatuses=${statusFilter}&includeTransactions=true`
		);
	}

	getSubServices(milestoneId: string): Observable<TppSubService[]> {
		return this.http.get<TppSubService[]>(
			`${environment.apiUri}/v1/payouts/tpp/aggregated?milestoneId=${milestoneId}`
		);
	}

	inactivateTppPayout(payoutIds: string[]): Observable<any> {
		let postParams = {
			payoutIds: payoutIds
		};

		return this.http.post(`${environment.apiUri}/v1/payouts/tpp/inactivate`, postParams);
	}

	processBooking(milestoneId: string): Observable<any> {
		const route: PayoutProcessingRoute = {};
		const postParams: { route: PayoutProcessingRoute } = {
			route: route
		};

		return this.http.post(`${environment.apiUri}/v1/payouts/milestone/activate/${milestoneId}`, postParams);
	}

	reProcessBooking(transactionIds: string[]): Observable<any> {
		const postParams: { ids: string[] } = {
			ids: transactionIds
		};

		return this.http.post(`${environment.apiUri}/v1/payouts/tpp/reprocess`, postParams);
	}

	getCancelReport(
		payGroupId: string,
		g2nMilestoneId: string,
		pageSize: number,
		pageNumber: number
	): Observable<cancelReportWithPagination> {
		return this.http.get<cancelReportWithPagination>(
			`${environment.apiUri}/v1/cancellation/report?pageSize=${pageSize}&pageNumber=${pageNumber}&payGroupIds=${payGroupId}&milestoneIds=${g2nMilestoneId}`
		);
	}

	cancelBookings(milestoneId: string, reason: string) {
		let postParams = {
			milestoneId: milestoneId,
			reason: reason
		};

		return this.http.post(`${environment.apiUri}/v1/payouts/cancellation`, postParams);
	}

	getPaginition(): Pagination {
		return {
			pageSize: 10,
			pageIndex: 0,
			totalPageCount: 4
		};
	}

	getCancelledNetPayoutsWithPagination(
		cancelledId: string,
		pageSize: number,
		pageNumber: number
	): Observable<PayoutPagination> {
		return this.http.get<PayoutPagination>(
			`${environment.apiUri}/v1/cancellation/net-payouts?pageSize=${pageSize}&pageNumber=${pageNumber}&cancellationId=${cancelledId}&includeTransactions=true`
		);
	}

	//TPP Cancelled functions

	getCancelledBookings(cancelId: string, pageSize: number, pageNumber: number): Observable<Booking[]> {
		return forkJoin([
			this.getCancelledTppAggregatedSubservices(cancelId),
			this.getCancelledTppPayoutsWithPagination(cancelId, pageSize, pageNumber)
		]).pipe(
			take(1),
			map(([subServices, payoutsWithPagination]) => {
				const payouts: TppPayouts[] = payoutsWithPagination.items;
				let bookings: Booking[] = [];

				subServices.forEach(subService => {
					const listOfMatchingPayouts: TppPayouts[] = payouts.filter(
						payout => payout.subServiceId === subService.subService.id
					);

					let totalAmount: Amount = { value: 0, currency: "" };

					listOfMatchingPayouts.forEach(payout => {
						totalAmount.value += payout.amount.value; //For total amount
						totalAmount.currency = payout.amount.currency; //For amount currency

						//get employee details

						if (payout.employeeId) {
							this.employeeService
								.getEmployeesById(payout.employeeId)
								.pipe(take(1))
								.subscribe((empData: Employee) => {
									payout.employeeName = empData.data.firstName;
									payout.employeeSurname = empData.data.lastName;
								});
						} else {
							payout.employeeName = "";
							payout.employeeSurname = "";
						}
						//Get latest transaction
						payout.transactions = this.paymentApiService.filterTransactions(payout.transactions);
					});

					let booking: Booking = {
						subService: subService.subService,
						paymentBy: subService.paymentBy,
						reference: subService.reference,
						referenceOkay: subService.referenceOkay,
						amount: totalAmount,
						uploadedBy: subService.uploadedBy,
						valueDate: subService.valueDate,
						countByStatus: subService.countByStatus,
						payouts: listOfMatchingPayouts
					};

					bookings.push(booking);
				});

				return bookings;
			}),
			catchError(error => {
				//Add error handeling

				let temp: Booking[] = [];
				return of(temp);
			})
		);
	}

	getCancelledTppAggregatedSubservices(cancelledId: string): Observable<TppSubService[]> {
		return this.http.get<TppSubService[]>(
			`${environment.apiUri}/v1/payouts/tpp/aggregated/cancelled?cancellationId=${cancelledId}`
		);
	}

	getCancelledTppPayoutsWithPagination(
		cancelledId: string,
		pageSize: number,
		pageNumber: number
	): Observable<TppPayoutsWithPagination> {
		return this.http.get<TppPayoutsWithPagination>(
			`${environment.apiUri}/v1/cancellation/tpp-payouts?pageSize=${pageSize}&pageNumber=${pageNumber}&cancellationId=${cancelledId}&includeTransactions=true`
		);
	}
}
