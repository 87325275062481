import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { shareReplay, take, takeUntil } from "rxjs/operators";
import { CountriesService } from "@shared/services/countries/countries.service";
import { ToastService } from "@shared/services/toast/toast.service";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { BankAccountsCommonService } from "../../_services/bank-accounts-common.service";
import { PermissionsService } from "@shared/services/permissions/permissions.service";

@Component({
	selector: "app-country-select",
	templateUrl: "./country-select.component.html",
	styleUrls: ["./country-select.component.scss"]
})
export class CountrySelectComponent implements OnInit, OnDestroy {
	countries$!: Observable<SelectOption[]>;

	destroy$: Subject<void> = new Subject();
	canCopy: boolean = false;

	countrySelectForm!: FormGroup;
	canCopyBankFields = false;

	constructor(
		private readonly countriesService: CountriesService,
		@Inject(FormBuilder) private formBuilder: FormBuilder,
		private readonly bankAccountCommonService: BankAccountsCommonService,
		private readonly toastService: ToastService,
		private permissions: PermissionsService
	) {}

	ngOnInit(): void {
		this.permissions
			.canCopyBankFields()
			.pipe(take(1))
			.subscribe(res => {
				this.canCopyBankFields = res;
				this.countries$ = this.countriesService.getCountryOptions().pipe(shareReplay(1));
				this.initForm();
				this.initSubscriptions();
			});
	}

	initForm(): void {
		this.countrySelectForm = this.formBuilder.group({
			country: [null],
			copyFromCountry: [{ value: null, disabled: true }]
		});
	}

	initSubscriptions(): void {
		this.countrySelectForm
			.get("country")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe((val: string) => {
				if (val) {
					//get fields for country
					this.bankAccountCommonService.getFieldsByCountryCode(val);
					this.countries$.subscribe(countries => {
						countries.filter(country => {
							if (country.value === val) {
								this.bankAccountCommonService.selectedCountryCode = country.value;
								this.bankAccountCommonService.setSelectedCountry(country.text);
								this.countrySelectForm.get("copyFromCountry")?.enable();
							}
						});
					});
				}
			});

		this.countrySelectForm
			.get("copyFromCountry")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe(country => {
				if (country === this.bankAccountCommonService.selectedCountryCode) {
					this.toastService.showWarning("Can Not Copy From The Same Country");
					this.countrySelectForm
						.get("copyFromCountry")
						?.patchValue(null, { emitEvent: false, onlySelf: true });
					this.canCopy = false;
					return;
				}

				if (country) {
					this.bankAccountCommonService.fieldsToCopy = [];
					//get fields for country
					this.bankAccountCommonService
						.getFieldsToCopyByCountryCode(country)
						.pipe(takeUntil(this.destroy$))
						.subscribe({
							next: res => {
								this.canCopy = true;
								this.bankAccountCommonService.fieldsToCopy = res.fields;
							},
							error: () => {
								this.canCopy = false;
								this.toastService.showError("No Fields where copied");
							}
						});
				}
			});
	}

	fieldsToCopyCount(): number {
		return this.bankAccountCommonService.fieldsToCopy.length;
	}

	confirmCopy() {
		this.bankAccountCommonService.setCopiedFields();
		// this.countrySelectForm.get("copyFromCountry")?.disable();
		this.countrySelectForm.get("copyFromCountry")?.patchValue(null, { emitEvent: false, onlySelf: true });
		this.canCopy = false;
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
