import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InputWrapperComponent } from "./input-wrapper.component";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
	declarations: [InputWrapperComponent],
	imports: [CommonModule, MatIconModule],
	exports: [InputWrapperComponent]
})
export class InputWrapperModule {}
