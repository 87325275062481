import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, take, takeUntil } from "rxjs/operators";
import { ApiService } from "@modules/pay-groups/services/api.service";
import { BankAccountsService } from "@shared/services/bank-accounts/bank-accounts.service";
import { ToastService } from "@shared/services/toast/toast.service";
import { TppCommonServiceService } from "@shared/services/tpp-common-service/tpp-common-service.service";
import { TPP_GROUPS } from "src/app/shared/constants/tpp-groups";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { SubServicePaymentDefinition, TppAccount } from "src/app/shared/models/tpp-account.interface";
import { ClientSpecificSubService, TPPGroupDTO, TppService } from "src/app/shared/models/tpp-service.interface";
import { getSelectedTPPServiceState } from "src/app/store";
import { UpdateBeneficiary } from "src/app/store/actions/tppBeneficiarySelect.action";
import { AppState } from "src/app/store/models/state.model";
import { formSubService } from "../../../_models/tpp-definition-form-subservices.interface";
import { TppServiceDefinitionService } from "../../../_services/tpp-service-definition/tpp-service-definition.service";
import { PermissionsService } from "@shared/services/permissions/permissions.service";

@Component({
	selector: "app-sub-service-item",
	templateUrl: "./sub-service-item.component.html",
	styleUrls: ["./sub-service-item.component.scss"]
})
export class SubServiceItemComponent implements OnInit, OnDestroy {
	@Input() countryCode: string = "";
	@Input() index: number = 0;
	@Input() subService!: SubServicePaymentDefinition;

	@Input() beneficiarySelectOptions!: SelectOption[];
	@Input() frequencySelectOptions!: SelectOption[];
	@Input() tppAccounts!: TppAccount[];

	@Input() tppGroupDTO!: TPPGroupDTO;

	@Input() selectedPayGroupId!: string;

	@Input() selectedService!: TppService;

	@Input() serviceDefServices!: ClientSpecificSubService[];

	@Output() sendSubServiceData = new EventEmitter<{
		action: string;
		subService: SubServicePaymentDefinition;
	}>();

	@Output() skipStepOneAdhoc = new EventEmitter<boolean>();

	@Output() patchChanges = new EventEmitter<{
		group?: string;
		beneficiary?: string;
		beneficiaryName?: string;
		externalId?: string;
		subService: SubServicePaymentDefinition;
		changedInput: string;
	}>();

	selectedBeneficiary!: string;

	colorEye: string = "#231F20"; //#231F20

	beneficiaryId: string = "";

	form!: FormGroup;

	subServiceSelected: boolean = false;

	firstInputLabel: string = "";

	secondInputLabel: string = "";

	tppGroupOptions: SelectOption[] = TPP_GROUPS;

	selectedTPPGroupOptions: SelectOption[] = [];

	managedInSelectOptions: SelectOption[] = [];

	defaultBeneficiary: string = "";
	defaultGroup: string = "";

	selectedCountry: string = "";

	tppOptionsMenuList = false;

	containerClass: string = "";
	textClass: string = "";
	displayItems: boolean = false;

	editBeneficiaryButtonEnabled: boolean = false;
	canEditSubServices = false;

	private destroy$: Subject<void> = new Subject<void>();

	constructor(
		private formBuilder: FormBuilder,
		private bankAccountsService: BankAccountsService,
		private router: Router,
		private toastService: ToastService,
		private store: Store<AppState>,
		private tppServiceDefinitionService: TppServiceDefinitionService,
		private permissions: PermissionsService
	) {}

	ngOnInit(): void {
		this.permissions
			.canEditSubServices()
			.pipe(take(1))
			.subscribe(res => {
				this.canEditSubServices = res;
				this.initForm();
			});

		this.store.pipe(takeUntil(this.destroy$), select(getSelectedTPPServiceState)).subscribe(state => {
			this.selectedCountry = state.selectedTPPService.tppService?.country!;
		});

		this.firstInputLabel = "Beneficiary";
		this.secondInputLabel = "TPP Group";
	}

	initForm(): void {
		this.form = this.formBuilder.group({
			beneficiaryId: ["", Validators.required],
			externalId: ["", Validators.required],
			group: ["", Validators.required]
		});

		this.form.disable({ emitEvent: false });

		// emit valuchange data to parent to save in form
		this.form
			.get("group")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe(value => {
				this.patchChanges.emit({ group: value, subService: this.subService, changedInput: "group" });
				if (this.form.get("group")?.value !== "") {
					this.saveToDefinition();
				}
			});

		this.form
			.get("externalId")
			?.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(400), distinctUntilChanged())
			.subscribe(value => {
				this.patchChanges.emit({ externalId: value, subService: this.subService, changedInput: "externalId" });
				if (this.form.get("externalId")?.value !== "") {
					this.saveToDefinition();
				}
			});

		this.form
			.get("beneficiaryId")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe(value => {
				let benIndex = this.beneficiarySelectOptions.findIndex(element => element.value === value);

				if (benIndex !== -1) {
					let beneficiaryName = this.beneficiarySelectOptions[benIndex].text;

					this.patchChanges.emit({
						beneficiary: value,
						beneficiaryName: beneficiaryName,
						subService: this.subService,
						changedInput: "beneficiary"
					});

					if (this.form.get("beneficiaryId")?.value !== "") {
						this.editBeneficiaryButtonEnabled = true;
					}
					this.saveToDefinition();
				} else {
					let benIndex = this.beneficiarySelectOptions.findIndex(element => element.text === value);

					if (benIndex !== -1) {
						let beneficiaryValue = this.beneficiarySelectOptions[benIndex].value;

						this.patchChanges.emit({
							beneficiary: beneficiaryValue,
							beneficiaryName: value,
							subService: this.subService,
							changedInput: "beneficiary"
						});

						if (this.form.get("beneficiaryId")?.value !== "") {
							this.editBeneficiaryButtonEnabled = true;
						}
						this.saveToDefinition();
					}
				}
			});

		this.displayItems = true;
		this.checkSavedData();
	}

	saveToDefinition() {
		this.tppServiceDefinitionService.saveToDefinition(
			this.form,
			this.subService,
			this.selectedPayGroupId,
			this.selectedService,
			this.beneficiarySelectOptions
		);
	}

	checkSavedData() {
		let subServicesData = this.tppServiceDefinitionService.getFormSubServices();

		let existsInsideServiceDef: boolean = false;
		let serviceDefIndex: number = -1;

		if (this.serviceDefServices.length > 0) {
			//Check if this subservice is in serviceDefServices

			serviceDefIndex = this.serviceDefServices.findIndex((subService: formSubService) => {
				return subService.subServiceId === this.subService.id;
			});

			if (serviceDefIndex !== -1) {
				existsInsideServiceDef = true;
			}
		}

		if (existsInsideServiceDef) {
			if (
				this.serviceDefServices[serviceDefIndex].beneficiaryId ||
				this.serviceDefServices[serviceDefIndex].group ||
				this.serviceDefServices[serviceDefIndex].externalId !== ""
			) {
				let accountIndex: number = this.tppAccounts.findIndex(tppAccount => {
					return tppAccount.id === this.serviceDefServices[serviceDefIndex].beneficiaryId;
				});

				let group: string = "";
				let groupText: string = "";
				let externalId: string = "";

				if (this.serviceDefServices[serviceDefIndex]) {
					if (this.serviceDefServices[serviceDefIndex].group) {
						group = this.serviceDefServices[serviceDefIndex].group!;

						groupText = `TPP Group ${this.serviceDefServices[serviceDefIndex].group!.replace(
							/[^\d.-]/g,
							""
						)}`;
					}

					if (this.serviceDefServices[serviceDefIndex].externalId) {
						externalId = this.serviceDefServices[serviceDefIndex].externalId!;
					}
				}

				this.setupSavedData(
					this.tppAccounts[accountIndex] ? this.tppAccounts[accountIndex].name : undefined,
					groupText,
					group,
					externalId
				);
			} else {
				this.setDefualtValuesForInputs();
			}
		} else if (subServicesData && subServicesData.length > 0) {
			//Check if there is something stored in state

			let index: number = subServicesData.findIndex((subService: formSubService) => {
				return subService.subServiceId === this.subService.id;
			});
			if (index !== -1) {
				let savedData = this.tppServiceDefinitionService.getSavedDataStepOne(
					subServicesData,
					this.tppGroupOptions,
					this.subService,
					this.beneficiarySelectOptions
				);

				this.setupSavedData(
					savedData.beneficiaryName,
					`TPP Group ${savedData.group.replace(/[^\d.-]/g, "")}`,
					savedData.group,
					savedData.externalId
				);
			}
		} else {
			//Get defualt Values from TPP subservice -> aka what's been saved by TPP cat
			this.setDefualtValuesForInputs();
		}
	}

	setDefualtValuesForInputs() {
		let benIndex = this.beneficiarySelectOptions.findIndex(
			element => element.value === this.subService.beneficiaryId
		);

		//Dont patch these values (should not add to parent form yet) - only when selected -> patch through

		if (benIndex !== -1) {
			let beneficiaryName = this.beneficiarySelectOptions[benIndex].text;
			this.selectedBeneficiary = this.beneficiarySelectOptions[benIndex].value;
			this.form.get("beneficiaryId")?.setValue(beneficiaryName, { emitEvent: false });
		}

		if (this.subService.externalId !== "") {
			this.form.get("externalId")?.setValue(this.subService.externalId, { emitEvent: false });
		}
	}

	setupSavedData(savedBeneficiaryName?: string, savedGroupText?: string, savedGroup?: string, externalId?: string) {
		//Make Sure this subservice is added to the configuration's form

		if (savedBeneficiaryName || savedGroupText !== "" || externalId !== "") {
			this.updateControls("check", this.subService, "#231F20", false);
		}

		if (savedBeneficiaryName) {
			let benIndex = this.beneficiarySelectOptions.findIndex(element => element.text === savedBeneficiaryName);

			if (benIndex !== -1) {
				this.selectedBeneficiary = this.beneficiarySelectOptions[benIndex].value;

				//Dont emit, prevent service def getting saved
				this.form.get("beneficiaryId")?.setValue(savedBeneficiaryName, { emitEvent: false });

				if (this.form.get("beneficiaryId")?.value !== "") {
					this.editBeneficiaryButtonEnabled = true;

					//Add value to configuration's form to get formDataValid -> aka show next button

					this.patchChanges.emit({
						beneficiary: this.selectedBeneficiary,
						beneficiaryName: savedBeneficiaryName,
						subService: this.subService,
						changedInput: "beneficiary"
					});
				}
			}
		}

		if (externalId !== "") {
			this.form.get("externalId")?.setValue(externalId, { emitEvent: false });

			this.patchChanges.emit({
				externalId: externalId,
				subService: this.subService,
				changedInput: "externalId"
			});
		}

		if (savedGroupText !== "" && savedGroup !== "") {
			this.addSelection(
				{
					text: savedGroupText!,
					value: savedGroup!
				},
				true
			);
		}

		if (
			this.form.get("externalId")?.value ||
			this.form.get("group")?.value ||
			this.form.get("beneficiaryId")?.value
		) {
			if (this.canEditSubServices) {
				this.subServiceSelected = true;
				this.form.enable({ emitEvent: false });
			}
		}
	}

	toggleSelection(subService: SubServicePaymentDefinition, index: number) {
		//toggle the checkbox
		this.subServiceSelected = !this.subServiceSelected;

		if (this.subServiceSelected) {
			this.updateControls("check", subService, "#231F20", false);

			if (this.form.get("beneficiaryId")?.value !== "" && this.selectedBeneficiary !== "") {
				this.patchChanges.emit({
					beneficiary: this.selectedBeneficiary,
					beneficiaryName: this.form.get("beneficiaryId")?.value,
					subService: this.subService,
					changedInput: "beneficiary"
				});
			}

			if (this.form.get("externalId")?.value !== "") {
				this.patchChanges.emit({
					externalId: this.form.get("externalId")?.value,
					subService: this.subService,
					changedInput: "externalId"
				});
			}

			this.saveToDefinition();
			if (this.canEditSubServices) {
				this.form.enable({ emitEvent: false });
			}
		} else {
			//remove specific service from service def

			let reset: boolean = this.tppServiceDefinitionService.removeFromServiceDefinition(
				this.selectedPayGroupId,
				subService
			);

			if (reset) {
				this.updateControls("uncheck", subService, "#F3F3F3", false);
			}

			//Set values to defaults -> TPP cat

			this.setDefualtValuesForInputs();
			this.form.get("group")?.setValue("", { emitEvent: false });
			this.selectedTPPGroupOptions = [];
		}
	}

	updateControls(
		checkBoxState: string,
		subService: SubServicePaymentDefinition,
		eyeColor: string,
		checkSavedData: boolean = true
	) {
		this.sendSubServiceData.emit({ action: checkBoxState, subService: subService });

		if (checkBoxState === "check" && checkSavedData) {
			this.checkSavedData();
		}
		this.colorEye = eyeColor;
	}

	addNewBeneficiary() {
		if (this.canEditSubServices) {
			this.bankAccountsService
				.getBankAccountFieldsByCountryCode(this.selectedCountry)
				.pipe(take(1))
				.subscribe(
					res => {
						this.router.navigate(["/service-definition/tpp/service-configuration/edit-beneficiary"], {
							state: { mode: "add", subServiceId: this.subService.id }
						});
					},
					err => this.toastService.showError("No bank configuration for this service's country")
				);
		}
	}

	editBeneficiary() {
		let value = this.form.get("beneficiaryId")?.value;
		let benIndex = this.beneficiarySelectOptions.findIndex(element => element.value === value);

		if (benIndex !== -1) {
			let beneficiaryValue = this.beneficiarySelectOptions[benIndex].value;

			let accountIndex: number = this.tppAccounts.findIndex(tppAccount => {
				return tppAccount.id === beneficiaryValue;
			});

			this.store.dispatch(
				new UpdateBeneficiary({
					beneficiary: this.tppAccounts[accountIndex]
				})
			);

			this.router.navigate(["/service-definition/tpp/service-configuration/edit-beneficiary"], {
				state: { mode: "edit", readonly: !this.canEditSubServices }
			});
		} else {
			let benIndex = this.beneficiarySelectOptions.findIndex(element => element.text === value);

			if (benIndex !== -1) {
				let beneficiaryValue = this.beneficiarySelectOptions[benIndex].value;

				let accountIndex: number = this.tppAccounts.findIndex(tppAccount => {
					return tppAccount.id === beneficiaryValue;
				});

				this.store.dispatch(
					new UpdateBeneficiary({
						beneficiary: this.tppAccounts[accountIndex]
					})
				);
				this.router.navigate(["/service-definition/tpp/service-configuration/edit-beneficiary"], {
					state: { mode: "edit", readonly: !this.canEditSubServices }
				});
			}
		}
	}

	showList() {
		this.tppOptionsMenuList = true;
	}

	addSelection(item: SelectOption, skipEmit: boolean = false) {
		this.selectedTPPGroupOptions = [];
		this.selectedTPPGroupOptions.push(item);
		if (item.value === "TPP1") {
			this.containerClass = "group-1-container";
			this.textClass = "group-1-text";
		} else if (item.value === "TPP2") {
			this.containerClass = "group-2-container";
			this.textClass = "group-2-text";
		} else if (item.value === "TPP3") {
			this.containerClass = "group-3-container";
			this.textClass = "group-3-text";
		} else if (item.value === "TPP4") {
			this.containerClass = "group-4-container";
			this.textClass = "group-4-text";
		}
		if (skipEmit) {
			//Prevent service def form being saved on load
			this.form.get("group")?.setValue(item.value, { emitEvent: false });
			//Add value to configuration's form to get formDataValid -> aka show next button
			this.patchChanges.emit({ group: item.value, subService: this.subService, changedInput: "group" });
		} else {
			this.form.get("group")?.patchValue(item.value);
		}
	}

	delete() {}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
