<app-breadcrumbs [headerTitle]="'LEGAL ENTITIES'" [externalCrumbs]="[{ crumb: 'Create new Legal Entity' }]" />

<div class="content-container">
	<div class="information-container">
		<ng-container *ngIf="legalEntityForm">
			<form [formGroup]="legalEntityForm">
				<div class="container-section">
					<div class="title">
						<!--Legal Entity Setup -->
						Legal Entity Setup
					</div>

					<div class="o-grid-2">
						<!-- col 1 -->
						<div class="first-input-row">
							<!-- Legal Entity reference -->
							<input-wrapper label="Legal entity reference" class="first-input-row">
								<input
									type="text"
									id="externalId"
									placeholder="Legal entity reference"
									formControlName="externalId"
								/>
								<div
									id="externalId"
									input-errors
									*ngIf="legalEntityForm.controls.externalId.errors?.duplicateId"
								>
									Legal entity reference can not be the same as customer reference
								</div>
							</input-wrapper>

							<!-- Legal Entity address -->
							<input-wrapper label="Address" class="first-input-row">
								<input
									type="text"
									id="address"
									placeholder="Legal entity address"
									formControlName="address"
								/>
							</input-wrapper>

							<!-- Legal entity region -->
							<input-wrapper label="Legal entity region *" class="first-input-row">
								<input-select
									id="region"
									formControlName="region"
									label="Legal entity region"
									[options]="regions"
									[required]="true"
								>
								</input-select>
								<div
									id="region"
									input-errors
									*ngIf="
										legalEntityForm.controls.region.touched &&
										legalEntityForm.controls.region.errors?.required
									"
								>
									Legal entity region is required
								</div>
							</input-wrapper>

							<!-- Legal time zone -->
							<input-wrapper label="Timezone" class="first-input-row">
								<input-select
									id="timezone"
									formControlName="timeZone"
									label="Legal entity timezone"
									[options]="timeZones"
								>
								</input-select>
							</input-wrapper>
						</div>

						<!-- col 2 -->
						<div>
							<!-- Legal Entity name -->
							<input-wrapper label="Legal entity name *">
								<input id="name" type="text" placeholder="Legal entity name" formControlName="name" />
								<div
									id="name"
									input-errors
									*ngIf="
										legalEntityForm.controls.name.touched &&
										legalEntityForm.controls.name.errors?.required
									"
								>
									Legal entity name is required
								</div>
							</input-wrapper>

							<!-- Legal Entity country -->
							<input-wrapper label="Legal entity country *">
								<input-autocomplete
									id="country"
									formControlName="country"
									[options]="selectOptionsCountries"
									[label]="'Select country'"
									[required]="true"
								>
								</input-autocomplete>
								<div
									id="country"
									input-errors
									*ngIf="
										legalEntityForm.controls.country.touched &&
										legalEntityForm.controls.country.errors?.required
									"
								>
									Legal entity country is required
								</div>
							</input-wrapper>

							<!-- WPay Legal Entity Reference -->
							<input-wrapper label="WPay Legal Entity Reference">
								<input
									id="id"
									type="text"
									placeholder="WPay Legal entity Reference"
									formControlName="id"
								/>
							</input-wrapper>
						</div>
					</div>
				</div>
				<hr />
				<!-- Contact Person -->

				<div class="container-section">
					<!-- Contact Person -->
					<div class="title">Contact person (Optional)</div>
					<div class="button-container">
						<ng-template [appUserRolePermissions] [action]="'hide'" [roles]="['TS_VO', 'CLIENT']">
							<button
								class="btn-new-contact"
								mat-button
								[disabled]="!canEditContactPersons"
								(click)="onAddContactClicked()"
							>
								<app-icon
									id="add-legal-contact"
									[name]="'add'"
									[color]="'var(--color-primary-1000)'"
									[size]="'20px'"
								></app-icon>
								Create new contact person
							</button>
						</ng-template>
					</div>
					<app-contacts
						(contactFormData)="creationContactFormData($event)"
						[addIsClicked$]="addContactClicked$"
						[objectLevel]="'legal-entities'"
					></app-contacts>
				</div>
				<hr />
				<!-- AML -->
				<div class="container-section">
					<app-full-aml-form (onFormChange)="onAmlFormChange($event)" [disabled]="cantEditAmlStatus">
					</app-full-aml-form>
					<!-- Disabled until permissions resolved () -->
					<div class="btn-container">
						<button
							id="cancel"
							class="cancel-btn"
							(click)="cancelCreateLegalEntity()"
							action-buttons
							mat-stroked-button
						>
							Cancel
						</button>

						<button
							id="submit"
							class="submit-btn"
							(click)="saveLegalEntity()"
							action-buttons
							mat-flat-button
							[disabled]="legalEntityForm.invalid || isLoading || isCreated || !isCreationContactValid"
						>
							Save
						</button>
					</div>
				</div>
			</form>
		</ng-container>
	</div>
</div>
