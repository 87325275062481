import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Observable } from "rxjs";
import { downloadTPPFile, tppFile } from "../../models/tpp-data.interface";

@Component({
	selector: "app-tpp-file-history-list",
	templateUrl: "./tpp-file-history-list.component.html",
	styleUrls: ["./tpp-file-history-list.component.scss"]
})
export class TppFileHistoryListComponent implements OnInit {
	files!: tppFile[];

	lastResetIndex: number = 10;

	@Output() viewFileErrors: EventEmitter<string> = new EventEmitter<string>();
	@Output() downloadFile: EventEmitter<downloadTPPFile> = new EventEmitter<downloadTPPFile>();

	@Input() set _files(files: tppFile[]) {
		this.files = files;
	}

	@Input() set _lastResetIndex(i: number) {
		this.lastResetIndex = i;
	}

	constructor() {}

	ngOnInit(): void {}
}
