<div class="popup-container">
	<div class="popup-header" [ngStyle]="{ 'background-color': headerColor }">
		<app-icon
			class="header-icon"
			[color]="'var(--color-white)'"
			[size]="size"
			[viewbox]="viewBox"
			[name]="headerIcon"
			[lineWidth]="'2.75px'"
		></app-icon>
		<span class="header-text">{{ type | uppercase }}!</span>
	</div>

	<div class="message-container">
		<div class="contextMessage" [innerHTML]="contextMessage"></div>
		<div class="confirmationMessage" [innerHTML]="confirmationMessage"></div>
	</div>

	<div class="button-container">
		<button id="back" mat-button class="back-btn" (click)="cancel()">
			<span class="button-text"> {{ backButtonText }}</span>
		</button>
		<button id="confirm" mat-button class="confirm-btn" (click)="confirm()">
			<span class="button-text">{{ confirmButtonText }}</span>
		</button>
	</div>
</div>
