<div class="payout-container" #payoutContainer>
	<!-- <ng-container breadcrumb-sub-header> -->
	<app-payment-top-cards
		[milestoneReport]="milestoneReport"
		[_updating]="updating"
		[title]="title"
		[subTitle]="subtitle"
		[flag]="flag"
		[expandMilestoneGroupWithName]="milestoneGroup"
		[kpiSelected]="selectedKPI"
		[checkDistance]="emitScroll"
		(emitSticky)="setFiltersToFixed = $event"
	></app-payment-top-cards>
	<!-- </ng-container> -->

	<app-tpp-process-payments
		*ngIf="runSelection && runSelection.length > 0"
		(filterByStatus)="filterByStatus($event)"
		(processBookingClicked)="processBooking()"
		(reprocessBookingClicked)="reprocessBooking()"
		(cancelBookingClicked)="cancelBooking()"
		(runChanged)="changeRun($event)"
		(refresh)="reloadTppData()"
		[_runSelection]="runSelection"
		[_buttonState]="bookingButtonState"
		[_payoutIdList]="reprocessPayoutIdsList"
		[_bookingsHasBeeenProcessed]="bookingHasBeeenProcessed"
		[defualtStatusFilterValue]="statusFilter"
		[fixedContainerStyle]="setFiltersToFixed"
	></app-tpp-process-payments>

	<div class="column-labels" *ngIf="setFiltersToFixed" [ngClass]="{ 'column-labels-fixed': setFiltersToFixed }">
		<span fxFlex="5"></span>
		<span class="label" fxFlex="15">SUBSERVICE</span>
		<span class="label" fxFlex="15">REFERENCE</span>
		<span class="label" fxFlex="15">AMOUNT</span>
		<span class="label" fxFlex="15">PAYMENT BY</span>
		<span class="label" fxFlex="13">VALUE DATE</span>
		<span class="label" fxFlex="22">STATE</span>
	</div>

	<div class="container" #scrollContainer [ngStyle]="{ 'margin-top': setFiltersToFixed ? '95px' : '0px' }">
		<div class="cancelRunBannerTPP" *ngIf="isCancelledRun">
			<span class="bannerText"
				><strong>{{ runName }}:</strong> Cancelled on {{ cancelDate | date : "MMMM d"
				}}{{ cancelDate | date : "d" | datePosition | lowercase }} by
				{{ cancellerName | userFullName | async }}.
				<strong>Reason: </strong>
				{{ reason }}
			</span>
		</div>

		<div class="column-labels" [ngStyle]="{ visibility: setFiltersToFixed ? 'hidden' : 'visible' }">
			<span fxFlex="5"></span>
			<span class="label" fxFlex="15">SUBSERVICE</span>
			<span class="label" fxFlex="15">REFERENCE</span>
			<span class="label" fxFlex="15">AMOUNT</span>
			<span class="label" fxFlex="15">PAYMENT BY</span>
			<span class="label" fxFlex="13">VALUE DATE</span>
			<span class="label" fxFlex="22">STATE</span>
		</div>

		<app-tpp-payment-payout-list
			*ngIf="bookings && bookings.length > 0"
			[_bookings]="bookings"
			[_reprocessPayoutIdsList]="reprocessPayoutIdsList"
			[_isCancelledRun]="isCancelledRun"
			(inActivatePayouts)="inactivatePayouts($event)"
			(pushToReprocessList)="pushToReprocessList($event)"
			(popFromReprocessList)="popFromReprocessList($event)"
		></app-tpp-payment-payout-list>

		<no-items-layout
			*ngIf="!bookings || !bookings.length"
			message="No bookings have been uploaded"
		></no-items-layout>
	</div>

	<div fxLayout="row" fxLayoutAlign="end center" class="paginator-container">
		<mat-paginator
			*ngIf="totalCount"
			[length]="totalCount"
			[pageSizeOptions]="[5, 10, 25, 100]"
			[pageSize]="pageSize"
			[pageIndex]="pageNumber"
			(page)="paginate($event)"
			aria-label="Select page"
		>
		</mat-paginator>
	</div>
</div>
