import { Component, EventEmitter, OnInit, Output, QueryList, ViewChildren } from "@angular/core";
import { MatExpansionPanel } from "@angular/material/expansion";
import { PageEvent } from "@angular/material/paginator";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { ContactPersonService } from "@shared/services/contact-person/contact-person.service";
import { ServiceProviderService } from "@shared/services/service-provider/service-provider.service";
import { ContactPerson } from "src/app/shared/models/contact-person.interface";
import { ServiceProviderListItem } from "src/app/shared/models/service-provider-types.interface";
import { CreateServiceProviderService } from "../../_services/create-service-provider/create-service-provider.service";

@Component({
	selector: "app-service-provider-list",
	templateUrl: "./service-provider-list.component.html",
	styleUrls: ["./service-provider-list.component.scss"]
})
export class ServiceProviderListComponent implements OnInit {
	@Output() length: EventEmitter<number> = new EventEmitter<number>();

	serviceProviders: ServiceProviderListItem[] = [];
	subscription: Subscription = new Subscription();
	destroy$: Subject<void> = new Subject();
	contactData: ContactPerson[] = [];
	pageIndex: number = 0;
	pageSize: number = 10;
	totalPageCount!: number;

	@ViewChildren("matExpansionPanels") matExpansionPanels!: QueryList<MatExpansionPanel>;

	constructor(
		private readonly serviceProviderService: ServiceProviderService,
		private readonly createServiceProviderService: CreateServiceProviderService,
		private contactPersonService: ContactPersonService
	) {}

	ngOnInit(): void {
		this.getServiceProviderList(this.pageSize, this.pageIndex);

		this.subscription = this.createServiceProviderService.providerAdded$
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.getServiceProviderList(this.pageSize, this.pageIndex);
			});
	}

	getServiceProviderList(pageSize: number, pageNumber: number): void {
		this.serviceProviderService
			.getServiceProviderList(pageSize, pageNumber)
			.pipe()
			.subscribe((result: ServiceProviderListItem[]) => {
				this.totalPageCount = result.length;
				this.serviceProviders = result;
				this.length.emit(result.length);
			});
	}

	getContactPersons(providerItem: string): void {
		this.contactPersonService
			.getContactPersons(providerItem)
			.pipe(takeUntil(this.destroy$))
			.subscribe(result => {
				this.contactData = result;
			});
	}

	closeExpansionPanel(index: number): void {
		const panelToClose = this.matExpansionPanels.toArray()[index];
		if (panelToClose) {
			panelToClose.close();
		}
	}

	paginate(pagination: PageEvent): void {
		this.getServiceProviderList(pagination.pageSize, pagination.pageIndex);
	}

	getSelectedProvider(): string {
		return this.serviceProviderService.selectedProvider;
	}
}
