<modal-wrapper
	*ngIf="payGroup"
	[title]="pageTitle + ' / ' + payGroup.data.name"
	[subTitle]="payGroup.legalEntity.data.name + ' - ' + payCycleName + ' - Group ' + milestone.group"
	(onTitleButtonAction)="backFromModal()"
	[addFlag]="payGroup.legalEntity.data.country"
	[titleRows]="2"
	[modalStyle]="'dark'"
	[noBreadCrumbs]="true"
	[exitIconBool]="true"
	[dynamicallyHideSubHeader]="!bannerShow"
	[subHeaderColour]="'var(--pending-color)'"
>
	<ng-container sub-header>
		<div class="reset-notify" [ngClass]="{ hideBanner: !bannerShow }" fxLayoutAlign="space-between center">
			<span class="reset-notify-text" fxLayoutAlign="start center" *ngIf="bannerShowReset">
				Run has been reset.
			</span>
			<span class="reset-notify-text" fxLayoutAlign="start center" *ngIf="bannerShowCancel">
				Run has been cancelled.
			</span>

			<button mat-button class="exit-btn-dialog" (click)="closeBanner()">
				<app-icon id="exit-dialog" name="add" [color]="'var(--color-role-tic)'"></app-icon>
			</button>
		</div>
	</ng-container>

	<div class="file-man-container">
		<input
			id="g2nUploadButton"
			hidden
			type="file"
			#uploader
			(change)="uploadG2NFile(uploader.files)"
			(click)="uploader.value = ''"
		/>

		<app-file-list
			[payCycleExternalId]="payCycleExternalId"
			[payrollCycleId]="payrollCycleId"
			[payGroup]="payGroup"
			[payCycle]="payCycle"
			[milestone]="milestone"
			(onFileClick)="uploader.click()"
			(resetStatus)="showResetBanner($event)"
			(cancelStatus)="showCancelBanner($event)"
			(currentFileHistoryCount)="fileHistoryCurrentCount($event)"
			(totalFileHistoryCount)="fileHistoryTotalCount($event)"
		></app-file-list>

		<div
			class="load-more-btn-container flex flex-row flex-align-center-center"
			*ngIf="isShowLoadmore && !paymentOrderFileHistoryList?.isFetching"
		>
			<button
				class="load-more-btn"
				(click)="
					paymentOrderFileHistoryList?.loadMoreFileHistory(
						paymentOrderFileHistoryList!.defaultFileHistoryCount
					)
				"
			>
				Load more
			</button>
		</div>
		<div
			class="max-record-container flex flex-row flex-align-center-center"
			*ngIf="reachedAllFileHistory && !paymentOrderFileHistoryList?.isFetching"
		>
			<span class="max-record">You have reached the end of the records</span>
		</div>
	</div>
</modal-wrapper>
