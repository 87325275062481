import { Component, EventEmitter, Input, Output } from "@angular/core";
import { PayGroup } from "src/app/shared/models/pay-groups";

@Component({
	selector: "app-generic-pay-group-list-container",
	templateUrl: "./generic-pay-group-list-container.component.html",
	styleUrls: ["./generic-pay-group-list-container.component.scss"]
})
export class GenericPayGroupListContainerComponent {
	@Input() set paygroupList(payGroups: PayGroup[]) {
		this.payGroups = payGroups;
	}

	@Input() set isLoading(loading: boolean) {
		this.loading = loading;
	}

	loading = true;

	@Output() payGroupSelected: EventEmitter<PayGroup> = new EventEmitter<PayGroup>();

	payGroups!: PayGroup[];

	paygroupSelected(payGroup: PayGroup): void {
		this.payGroupSelected.emit(payGroup);
	}
}
