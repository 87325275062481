<!-- Items -->
<div
	class="parent-container flex flex-column"
	[ngClass]="{ 'is-active-parent': showAccounts, 'is-disabled-parent': !activeToggle }"
>
	<div class="header" [ngClass]="{ 'is-active': showAccounts }">
		<div class="header-content flex flex-row" [ngClass]="{ 'shift-up': showAccounts }">
			<div class="flex flex-1" style="--max-width: 4%">
				<mat-checkbox
					*ngIf="
						(payout.status.name.toString() === 'FAILED' ||
							payout.status.name.toString() === 'ATTEMPTS_LIMIT_EXCEEDED' ||
							payout.status.name.toString() === 'FAILED_TO_BOOK' ||
							payout.status.name.toString() === 'ERROR') &&
						!isCancelledRun &&
						canReprocessPayment
					"
					(change)="setIds($event, payout.id)"
					[checked]="isCheckBoxChecked"
					color="primary"
					class="checkbox"
					[ngClass]="{ hide: showAccounts }"
				></mat-checkbox>
			</div>

			<!-- Employee ID -->

			<div
				[matTooltip]="payout.employee.externalId"
				style="--max-width: 15%"
				[ngClass]="{ 'show-title': showAccounts, 'no-show-title': !showAccounts }"
				class="employee-id-column flex flex-align-center-start flex-1"
			>
				<div
					*ngIf="!showAccounts"
					[ngClass]="{ 'table-row-item': activeToggle, 'table-row-item-disabled': !activeToggle }"
				>
					{{ payout.employee.externalId }}
				</div>
			</div>

			<!-- Employee Firstname  -->
			<div
				id="{{ payout.employee.externalId + '_userNamePayElective' }}"
				[matTooltip]="payout.employee.data.firstName"
				class="flex flex-align-center-start flex-1"
				style="--max-width: 10%"
				[ngClass]="{ 'show-title': showAccounts, 'no-show-title': !showAccounts }"
			>
				<div
					[ngClass]="{
						'name table-row-item': activeToggle,
						'name table-row-item-disabled': !activeToggle,
						'blue-text': showAccounts
					}"
				>
					{{ payout.employee.data.firstName }}
				</div>
			</div>

			<!-- Employee lastname -->
			<div
				id="{{ payout.employee.externalId + '_userNamePayElective' }}"
				class="flex flex-align-center-start flex-1"
				style="--max-width: 13%"
				[ngClass]="{ 'show-title': showAccounts, 'no-show-title': !showAccounts }"
			>
				<div
					[ngClass]="{
						'name table-row-item': activeToggle,
						'name table-row-item-disabled': !activeToggle,
						'blue-text': showAccounts
					}"
				>
					{{ payout.employee.data.lastName }}
				</div>
			</div>

			<!-- Payment Methods -->
			<div
				class="flex flex-align-center-start flex-1"
				style="--max-width: 19%"
				[ngClass]="{ 'show-title': showAccounts, 'no-show-title': !showAccounts }"
			>
				<div *ngIf="!showAccounts" class="list-item">
					<div class="flex flex-row flex-gap" style="--gap: 10px" *ngIf="activeToggle && !isCancelledRun">
						<div class="badge-item badge-card" *ngIf="payMethodTypes.includes('CARD'); else noCards">
							<app-icon class="bank-card-icon" [name]="'card'" [lineWidth]="'2'" [size]="'25'"></app-icon>
						</div>

						<ng-template #noCards>
							<div class="badge-item badge-no-card">
								<app-icon
									class="bank-card-icon"
									[name]="'card'"
									[lineWidth]="'2'"
									[size]="'20'"
									[color]="'var(--no-items-poker-face-color)'"
								></app-icon>
							</div>
						</ng-template>

						<div
							class="badge-item badge-bank"
							*ngIf="payMethodTypes.includes('BANK') || payMethodTypes.includes('FALLBACK'); else noBanks"
						>
							<app-icon
								class="bank-icon"
								[name]="'bank-figma'"
								[lineWidth]="'2'"
								[size]="'20'"
								[color]="'var(--no-items-poker-face-color)'"
							></app-icon>
						</div>

						<ng-template #noBanks>
							<div class="badge-item badge-no-bank">
								<app-icon
									class="bank-icon"
									[name]="'bank-figma'"
									[lineWidth]="'2'"
									[size]="'20'"
									[color]="'var(--no-items-poker-face-color)'"
								></app-icon>
							</div>
						</ng-template>
					</div>

					<div class="flex flex-row flex-gap" style="--gap: 10px" *ngIf="!activeToggle || isCancelledRun">
						<div class="badge-item badge-card-disabled">
							<app-icon
								class="bank-card-icon"
								[name]="'card'"
								[lineWidth]="'2'"
								[size]="'20'"
								[color]="'white'"
							></app-icon>
						</div>

						<div class="badge-item badge-bank-disabled">
							<app-icon
								class="bank-icon"
								[name]="'bank-figma'"
								[lineWidth]="'2'"
								[size]="'20'"
								[color]="'white'"
							></app-icon>
						</div>
					</div>
				</div>
			</div>

			<!-- Status -->

			<div
				class="table-status flex flex-align-center-start flex-1"
				*ngIf="!isCancelledRun"
				style="--max-width: 13.2%"
			>
				<div
					*ngIf="activeToggle"
					[ngClass]="{
						'status-green body-bold':
							(payout.status.name | toString) === 'SUCCESS' ||
							(payout.status.name | toString) === 'PROCESSED',
						'status-red body-bold':
							(payout.status.name | toString) === 'FAILED' ||
							(payout.status.name | toString) === 'ATTEMPTS_LIMIT_EXCEEDED' ||
							(payout.status.name | toString) === 'DEPENDENT_ERROR' ||
							(payout.status.name | toString) === 'ERROR' ||
							(payout.status.name | toString) === 'FAILED_TO_BOOK',

						'status-pending body-bold':
							(payout.status.name | toString) === 'PENDING' ||
							(payout.status.name | toString) === 'PROCESSING',
						'status-booked body-bold':
							(payout.status.name | toString) === 'BOOKED' ||
							(payout.status.name | toString) === 'PAID_TO_FALLBACK',
						'status-created body-bold': (payout.status.name | toString) === 'CREATED',
						'status-cancelled body-bold': (payout.status.name | toString) === 'CANCELLED',
						'blue-text': showAccounts
					}"
				>
					{{ payout.status.name.toString() | transactionStatuses : errorCounter }}
				</div>

				<!---->
			</div>

			<span
				class="table-status flex flex-align-center-start flex-1"
				*ngIf="isCancelledRun"
				style="--max-width: 13.2%"
			>
				<span *ngIf="activeToggle" class="status-cancelled">CANCELLED</span>
			</span>

			<!--Amount -->
			<div
				class="flex flex-align-center-start flex-1"
				style="--max-width: 12%"
				[matTooltip]="'Total Net'"
				[ngClass]="{
					'body-bold': showAccounts,
					'no-show-accounts-net body-bold': !showAccounts,
					'blue-text': showAccounts
				}"
			>
				<div>
					{{ payout.amount.value.toString() | number : "1.2-2"
					}}{{ payout.amount.currency.toString() | currencySymbol }}
				</div>
			</div>

			<!-- Toggle -->
			<div
				id="table-header-text"
				class="flex flex-align-center-start flex-1"
				style="--max-width: 11%"
				(click)="$event.stopPropagation()"
			>
				<ng-template [appUserRolePermissions] [action]="'hide'" [roles]="['TS_OCB']">
					<div
						*ngIf="!showAccounts"
						[ngClass]="{ opacity: disableToggle || isCancelledRun || !canDisablePayout || isDisabled }"
					>
						<mat-slide-toggle
							[ngClass]="{
								'toggle-checked-alternative': activeToggle,
								'toggle-default': !activeToggle
							}"
							color="primary"
							[checked]="activeToggle"
							(change)="inactivateEmployeeCheck(payout, $event)"
							[disabled]="disableToggle || !canDisablePayout || isCancelledRun || isDisabled"
						>
							<div class="flex flex-column">
								<span class="toggle-text" *ngIf="activeToggle">Active</span>
								<span class="toggle-text" *ngIf="!activeToggle">Inactive</span>
							</div></mat-slide-toggle
						>
					</div>
				</ng-template>
			</div>

			<!-- End -->

			<div
				class="end"
				style="--max-width: 2%"
				class="view-details table-row-item fab-toggler flex flex-align-center-start flex-1"
			>
				<app-icon
					id="{{ payout.employee.externalId + '_KeyboardArrowDown' }}"
					class="show-account-drop-down"
					(click)="showAccountsToggle()"
					[name]="'arrow-down'"
					[size]="'20'"
					[lineWidth]="'3px'"
					[ngClass]="{ 'up-arrow': showAccounts }"
				></app-icon>
			</div>
		</div>
	</div>
	<div class="content" *ngIf="showAccounts">
		<app-payout-accounts-list
			class="accounts"
			[employeeExternalId]="payout.employee.externalId"
			[employeeId]="payout.employee.id!"
			[employeefirstName]="payout.employee.data.firstName"
			[employeelastName]="payout.employee.data.lastName"
			[payout]="payout"
			[payoutTransactions]="payoutTransactions"
			[_isCancelledRun]="isCancelledRun"
			(onShowEmployeeAction)="goToHistory($event)"
			[activeToggle]="activeToggle"
		></app-payout-accounts-list>
	</div>
	<mat-divider *ngIf="!last && !showAccounts"></mat-divider>
</div>
