import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NotesDialogData } from "../../../../shared/models/service-provider-country-types";

@Component({
	selector: "app-costs-notes-dialog",
	templateUrl: "./costs-notes-dialog.component.html",
	styleUrls: ["./costs-notes-dialog.component.scss"]
})
export class CostsNotesDialogComponent {
	constructor(
		public dialogRef: MatDialogRef<CostsNotesDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: NotesDialogData
	) {}

	onNoClick(): void {
		this.dialogRef.close();
	}

	onClose(): void {
		this.dialogRef.close(this.data.notes);
	}
}
