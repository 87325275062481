<div class="title" style="margin-bottom: 16px">AML Compliance</div>
<div class="status">
	<form [formGroup]="form" class="status-item">
		<div class="input-container">
			<input-wrapper class="input">
				<input-select
					formControlName="status"
					label="Full AML status"
					[value]="selectedValue"
					[options]="statusOptions"
					[_disabled]="disabled"
				>
				</input-select>
			</input-wrapper>
		</div>
	</form>
</div>
