import { Component, Input, OnInit } from "@angular/core";
import { Milestone, MilestoneTypeCount } from "src/app/shared/models/pay-cycle.interface";
import { CalendarDay } from "../calendar/_models/calendar-day.model";

@Component({
	selector: "app-hover-object",
	templateUrl: "./hover-object.component.html",
	styleUrls: ["./hover-object.component.scss"]
})
export class HoverObjectComponent implements OnInit {
	@Input() date!: CalendarDay;

	@Input() type: string = "white-milestones-day-counter";

	@Input() milestone!: Milestone[];

	@Input() month!: string;
	@Input() cycleWeekStart!: string;
	@Input() cycleWeekEnd!: string;

	showTooltip: boolean = false;
	milestoneCounter: number = 0;
	milestoneTypeCount: MilestoneTypeCount[] = [];
	milestoneTypeMargin: number = 0;

	constructor() {}

	ngOnInit(): void {
		if (this.type === "dark-milestone-counter") {
			if (!this.month && this.cycleWeekStart) {
				this.getMilestoneWeekCounter();
			} else {
				this.getMilestoneMonthCounter();
			}
		}
	}

	getMilestoneMonthCounter() {
		this.milestoneTypeCount = [];

		this.milestone.forEach(mile => {
			if (new Date(mile.date).getMonth() === this.getMonthNumberFromName(this.month)) {
				this.milestoneCounter++;

				if (this.milestoneTypeCount.find((milestone: MilestoneTypeCount) => milestone.type === mile.type)) {
					this.milestoneTypeCount[this.milestoneTypeCount.map(res => res.type).indexOf(mile.type)].count++;
				} else {
					this.milestoneTypeCount.push({
						type: mile.type,
						count: 1,
						text: this.getTextValue(mile.type)
					});
				}
			}
		});

		//UX purposes

		this.milestoneTypeMargin = this.milestoneTypeCount.length * 2;
		if (this.milestoneTypeMargin < 8) {
			this.milestoneTypeMargin++;
		}
	}

	getMilestoneWeekCounter() {
		this.milestoneTypeCount = [];

		this.milestone.forEach(mile => {
			const milestoneDate = new Date(mile.date);
			const startDate = new Date(this.cycleWeekStart);
			const endDate = new Date(this.cycleWeekEnd);

			const milestoneDateWithoutTime = new Date(
				milestoneDate.getFullYear(),
				milestoneDate.getMonth(),
				milestoneDate.getDate()
			);
			const startDateWithoutTime = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
			const endDateWithoutTime = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());

			// Check if milestoneDateWithoutTime is within the range defined by startDateWithoutTime and endDateWithoutTime
			if (
				(milestoneDateWithoutTime >= startDateWithoutTime && milestoneDateWithoutTime <= endDateWithoutTime) ||
				milestoneDateWithoutTime.getTime() === startDateWithoutTime.getTime() ||
				milestoneDateWithoutTime.getTime() === endDateWithoutTime.getTime()
			) {
				this.milestoneCounter++;

				if (this.milestoneTypeCount.find((milestone: MilestoneTypeCount) => milestone.type === mile.type)) {
					this.milestoneTypeCount[this.milestoneTypeCount.map(res => res.type).indexOf(mile.type)].count++;
				} else {
					this.milestoneTypeCount.push({
						type: mile.type,
						count: 1,
						text: this.getTextValue(mile.type)
					});
				}
			}
		});

		//UX purposes

		this.milestoneTypeMargin = this.milestoneTypeCount.length * 2;
		if (this.milestoneTypeMargin < 8) {
			this.milestoneTypeMargin++;
		}
	}

	getTextValue(type: string): string {
		if (type) {
			switch (type) {
				case "FUNDING_DATE":
					return "Funding date";
					break;
				case "G2N_FILE":
					return "G2N File";
					break;
				case "PAYMENT":
					return "TTP Payment";
					break;
				case "DATA_INPUT":
					return "Data Input";
					break;
				case "NETS":
					return "Net Payment";
					break;
			}
		}
		return "";
	}

	getMonthNumberFromName(monthName: string) {
		return new Date(`${monthName} 1, 2022`).getMonth();
	}

	setTooltip(state: boolean) {
		this.showTooltip = state;
	}

	getIconName(type: string): string {
		if (type) {
			switch (
				type // init value of title: Net payment
			) {
				case "FUNDING_DATE":
					return "money-recive";
					break;
				case "G2N_FILE":
					return "document-copy";
					break;
				case "PAYMENT":
					return "wallet-add";
					break;
				case "DATA_INPUT":
					return "document-copy";
				case "NETS":
					return "wallet-add";
			}
		}

		return "";
	}
}
