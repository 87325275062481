import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "input-wrapper",
	templateUrl: "./input-wrapper.component.html",
	styleUrls: ["./input-wrapper.component.scss"]
})
export class InputWrapperComponent implements OnInit {
	// Optional inputs
	@Input() label?: string;
	@Input() icon?: string;

	constructor() {}

	ngOnInit(): void {}
}
