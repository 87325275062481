<app-breadcrumbs
	[headerTitle]="'Tpp Service Definition'"
	[showAllCrumbs]="true"
	[externalCrumbs]="[{ crumb: 'TPP Group Configuration' }]"
	(onDropDownCrumbItemSelected)="resetPage()"
/>
<div class="main-container">
	<app-tpp-service-source-fund
		*ngIf="!lastPage && !enableAddSourceOfFund && configHasBeenSet && sourceAccountSelectOptions"
		[_selectedLegalEntityId]="selectedLegalEntityId"
		[_selectedPayGroupId]="selectedPayGroupId"
		[subServiceNameList]="tppSubServicesNameList"
		[_currencies]="currencies"
		[sourceAccountSelectOptions]="sourceAccountSelectOptions"
		[groupToConfig]="groupToConfig"
		(groupChanged)="getSubServicesNameList($event)"
		(currencyChanged)="getSourceAccounts($event)"
		(openSourceOfFund)="openSourceOfFund($event)"
		(continue)="next($event)"
	></app-tpp-service-source-fund>

	<app-add-source-of-fund
		*ngIf="enableAddSourceOfFund"
		[legalEntityId]="selectedLegalEntityId"
		[countryCode]="selectedCountryCode"
		[currency]="paymentCurrency"
		[sourceOfFundId]="sourceOfFundId"
		[groupToConfig]="groupToConfig"
		[readonly]="!canEditSourceOfFundsTPP"
		(closeAddNewSourceOfFund)="closeAddNewSourceOfFund($event)"
	></app-add-source-of-fund>

	<app-tpp-service-settlement-account
		*ngIf="lastPage && !enableAddSettlementAccount && selectedCountryCode"
		(openSettlementAccount)="openSettlementAccount($event)"
		(returnToSourceAccount)="returnToSourceAccount($event)"
		(resetAvailableAccounts)="getSettlementAccounts($event)"
		(providerSelected)="providerSelected($event)"
		(settlementAccountSelected)="settlementAccountSelected($event)"
		[serviceProviderId]="serviceProviderId"
		[settelementAccountId]="settlementAccountId"
		[serviceProvidersOptions]="serviceProvidersOptions"
		[paymentCurrencyOptions]="paymentCurrencyOptions"
		[settlementAccountOptions]="settlementAccountOptions"
		[groupToConfig]="groupToConfig"
		[payGroupId]="selectedPayGroupId"
		(resetPaymentCurrencies)="resetPaymentCurrenciesOptions($event)"
	></app-tpp-service-settlement-account>

	<app-add-settlement-account
		*ngIf="enableAddSettlementAccount"
		[provider]="addSettlementProvider"
		[settlementProviderName]="settlementProviderName"
		[providerCountryId]="serviceProviderId"
		[selectedCountryCode]="selectedCountryCode"
		[availableSettlementAccounts]="availableSettlementAccounts"
		[selectedSettlementAccount]="selectedSettlementAccount"
		[isSettlementEdit]="isSettlementEdit"
		[payGroupId]="selectedPayGroupId"
		[selectedLegalEntity]="selectedLegalEntityId"
		(closeAddSettlementAccount)="closeAddSettlementAccount($event)"
		(resetAvailableAccounts)="getSettlementAccounts($event)"
		[groupToConfig]="groupToConfig"
		[readonly]="!canEditSettlementAccountTPP"
	></app-add-settlement-account>
</div>
