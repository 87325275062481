<div id="tppGroupIputContainer" class="tpp-group-input-container" [formGroup]="form" fxLayout="row">
	<div class="input-container">
		<input-wrapper
			label="TPP GROUP"
			class="style-input-wrapper"
			[ngClass]="{
				'cursos-not-allowed': tppGroupSelectOptions && tppGroupSelectOptions.length === 1,
				'.cursos-pointer': tppGroupSelectOptions && tppGroupSelectOptions.length != 1
			}"
		>
			<app-input-select-tpp-group
				[groupName]="_groupToConfig"
				[tppGroupOptionsSelected]="tppGroupSelectOptions"
				[selectedPayGroupId]="selectedPayGroupId"
				(tppGroupChose)="setTppGroupChose($event)"
				[ngClass]="{
					'tppGroupSelector-block': tppGroupSelectOptions && tppGroupSelectOptions.length === 1,
					'tppGroupSelector-active': tppGroupSelectOptions && tppGroupSelectOptions.length != 1
				}"
			></app-input-select-tpp-group>
		</input-wrapper>
	</div>
	<div class="sub-service-list-container" *ngIf="_subServiceNameList.length" fxLayout="row">
		<div
			*ngFor="let subService of _subServiceNameList"
			class="sub-service-name-item"
			[matTooltip]="subService"
			[matTooltipClass]="'tooltip-option'"
			[matTooltipPosition]="'above'"
		>
			<span>{{ subService }}</span>
		</div>
	</div>
</div>
<div id="sourceOfFundTitleText" class="page-title">Source of Fund</div>
<div id="sourceOfFundStepContainer" class="container-source-funs">
	<div id="selectedTppGroupName" class="container-header">
		<div class="header-title">{{ displayGroupName }}</div>
	</div>
	<div id="sourceOfFunStepFormContainer" [formGroup]="formTppGroup" class="container-body" fxLayout="row">
		<div id="paymentCurrencyInputContainer" fxFlex="25">
			<input-wrapper label="DEFAULT PAYMENT CURRENCY" class="cursos-pointer">
				<input-autocomplete
					id="paymentCurrency"
					formControlName="paymentCurrency"
					[defaultValue]="selectedCurrency"
					label="Select payment currency"
					[options]="currencies | async"
					class="select-active"
					[disabled]="!canEditSourceOfFundsTPP || !groupChosen"
				>
				</input-autocomplete>
			</input-wrapper>
		</div>
		<div id="arrowContainer" fxFlex="10" class="arrow-container">
			<div id="arrowContainerChild" class="arrow-container-child" fxLayout="row" fxLayoutAlign="center none">
				<div id="arrowLine" class="arrow-line mg-tp" fxFlex="30"></div>
				<div id="arrowPoint" class="arrow-right"></div>
			</div>
		</div>
		<div id="sourceAccountInputContainer" fxFlex="25">
			<input-wrapper label="CHOOSE EXISTING ACCOUNT" class="cursos-pointer">
				<input-autocomplete
					id="sourceAccount"
					#existingAccount
					formControlName="existingAccount"
					label="Select existing account"
					[options]="sourceAccountSelectOptions"
					[defaultValue]="sourceOfFundId"
					class="select-active"
					[disabled]="!canEditSourceOfFundsTPP || !groupChosen"
				/>

				<button
					id="addNewSourceOfFundsButton"
					mat-button
					class="bt-source-of-funds"
					[disabled]="!addSourceAccountButtonEnabled || !canEditSourceOfFundsTPP"
					(click)="addNewSourceOfFunds()"
				>
					+&nbsp;&nbsp;&nbsp;Add new source of funds
				</button>
			</input-wrapper>
		</div>
		<div
			fxFlex="60"
			id="editSourceOfFundIconContainer"
			class="container-btn"
			fxLayout="row"
			fxLayoutAlign="end start"
		>
			<button
				id="editSourceOfFundButton"
				[color]="'none'"
				mat-mini-fab
				[ngClass]="editSourceAccountButtonEnabled ? 'bt-border-active' : 'bt-border-disabled'"
				aria-label="View Source of funds"
				[disabled]="!editSourceAccountButtonEnabled"
				(click)="updateSourceOfFundAccount()"
			>
				<app-icon *ngIf="editSourceAccountButtonEnabled" name="eye" [color]="'#231F20'"></app-icon>
				<app-icon *ngIf="!editSourceAccountButtonEnabled" name="eye" [color]="'#CDCED9'"></app-icon>
			</button>
		</div>
	</div>
</div>
<div fxLayout="row" id="nextStepButtonContainer" fxLayoutAlign="end center" class="bt-contanier">
	<button id="saveSubservice" class="mat-stroked-button submit-btn" [disabled]="btnDisabled" (click)="next()">
		NEXT
	</button>
</div>
<div class="bt-container-margin"></div>
