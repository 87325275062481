import {
	tppGroupConfigStepOneSelectAction,
	tppGroupConfigStepOneSelectActionType
} from "../actions/tppGroupConfigStepOneSelect.action";
import { Action } from "@ngrx/store";

const initialState: {
	tppGroupConfigStepOneData:
		| {
				paymentCurrency: "";
				existingAccount: "";
				sourceAccountId: "";
				tppGroup: "";
		  }
		| {};
} = {
	tppGroupConfigStepOneData: {}
};

export const tppGroupConfigStepOneSelectReducer = (
	state = initialState,
	action: Action
): {
	tppGroupConfigStepOneData:
		| {
				paymentCurrency: "";
				existingAccount: "";
				sourceAccountId: "";
				tppGroup: "";
		  }
		| {};
} => {
	const selectAction = action as tppGroupConfigStepOneSelectAction;

	switch (selectAction.type) {
		case tppGroupConfigStepOneSelectActionType.SET_SELECTED:
			return {
				...state,
				tppGroupConfigStepOneData: selectAction.payload.tppGroupConfigStepOneData
			};

		default:
			return state;
	}
};
