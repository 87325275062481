import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PayoutsService } from "@shared/services/payouts/payouts.service";
import { Payout, Transaction } from "src/app/shared/models/payouts.types";
import { takeUntil, map } from "rxjs/operators";
import { BehaviorSubject, Subject } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { PaymentsApiService } from "../../../../services/payments-api.service";
import { PayMethodDetail } from "@modules/employee-data/types/accounts";
import { PermissionsService } from "@shared/services/permissions/permissions.service";
import { HistoryLogSidePanelService } from "@shared/components/history-log-side-panel/services/history-log-side-panel.service";

@Component({
	selector: "app-payout-accounts-list",
	templateUrl: "./payout-accounts-list.component.html",
	styleUrls: ["./payout-accounts-list.component.scss"]
})
export class PayoutAccountsListComponent implements OnInit {
	@Input() employeeExternalId: string = "";
	@Input() employeeId: string = "";
	@Input() employeefirstName: string = "";
	@Input() employeelastName: string = "";
	@Input() payout!: Payout;
	@Input() activeToggle!: boolean;

	isCancelledRun: boolean = false;
	@Input() set _isCancelledRun(cancelled: boolean) {
		this.isCancelledRun = cancelled;
	}

	@Input() payoutTransactions!: Transaction[];

	@Output() onShowEmployeeAction: EventEmitter<string> = new EventEmitter();

	payoutBankNames: string[] = [];
	payoutPayMethods!: PayMethodDetail[];
	payMethods: PayMethodDetail[] = [];

	reprocessIds: string[] = [];
	reProcesMapPayoutsAndTxId: Map<string, string[]> = new Map();

	isCheckBoxSelected: boolean = false;

	canViewTransactionHistory: boolean = false;
	canReprocessPayment: boolean = false;

	private destroy$: Subject<void> = new Subject();
	private paymentMethodsDataSource$: BehaviorSubject<PayMethodDetail[]> = new BehaviorSubject<PayMethodDetail[]>([]);

	constructor(
		private payoutsService: PayoutsService,
		public dialog: MatDialog,
		private paymentApiService: PaymentsApiService,
		private permissions: PermissionsService,
		private historyLogService: HistoryLogSidePanelService
	) {}

	ngOnInit(): void {
		//Can view History
		this.permissions.canViewTransactionHistoryNETS().subscribe(res => {
			this.canViewTransactionHistory = res;
			this.getReprocessIds();
			this.loadPayMethods();
		});

		this.permissions
			.canReprocessPaymentsNETS()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => (this.canReprocessPayment = res));

		this.paymentApiService.reprocessTransactions().subscribe((res: boolean) => {
			if (res) {
				this.setData();
			}
		});
	}

	getReprocessIds() {
		this.payoutsService
			.reprocessIdsObservable()
			.pipe(takeUntil(this.destroy$))
			.subscribe((res: Map<string, string[]>) => {
				this.reProcesMapPayoutsAndTxId = res;
				if (this.reProcesMapPayoutsAndTxId.has(this.payout.id)) {
					this.reprocessIds = this.payoutsService.getTransactionIdsFormMapPayouts(
						this.reProcesMapPayoutsAndTxId
					);
					this.isCheckBoxSelected = true;
				}
				if (this.reProcesMapPayoutsAndTxId.size === 0) this.isCheckBoxSelected = false;
			});
	}

	loadPayMethods() {
		this.payoutsService
			.getEmployeePaymentMethods(this.employeeId)
			.pipe(
				takeUntil(this.destroy$),
				map(res => {
					this.payMethods = res;
					this.paymentMethodsDataSource$.next(res);
					this.setData();
				})
			)
			.subscribe();
	}

	setData(): void {
		this.loadTransactionHistory(this.payout);
	}

	loadTransactionHistory(payout: Payout) {
		this.payoutTransactions.forEach(item => {
			let bankName = this.payMethods.find(x => x.payMethod.accountId === item.accountId);

			if (bankName?.details.bankName) {
				this.payoutBankNames.push(bankName!.details.bankName);
			} else {
				this.payoutBankNames.push(bankName!.details.cardType!);
			}
		});
	}

	goToEmployeeHistory(): void {
		this.onShowEmployeeAction.emit(this.payout.payCycleId);
	}

	openHistoryDialog(payoutId: string, paymethodId: string): void {
		this.historyLogService.setTransactionData(payoutId, paymethodId);
	}

	setIds(event: any, payoutId: string, transactionId: string) {
		if (event.checked) {
			this.isCheckBoxSelected = true;
			this.addTransactionsToReproces(payoutId, transactionId);
			this.payoutsService.updateReprocessIds(this.reProcesMapPayoutsAndTxId);
		} else {
			this.isCheckBoxSelected = false;
			this.deleteTransactionToReproces(payoutId, transactionId);
			this.payoutsService.updateReprocessIds(this.reProcesMapPayoutsAndTxId);
		}
	}

	addTransactionsToReproces(payoutid: string, transactionId: string): void {
		let txIds: string[] | undefined;

		if (this.reProcesMapPayoutsAndTxId.has(payoutid)) {
			txIds = this.reProcesMapPayoutsAndTxId.get(payoutid);

			if (txIds && !txIds.includes(transactionId)) {
				txIds.push(transactionId);
				this.reProcesMapPayoutsAndTxId.set(payoutid, txIds);
			}
		} else this.reProcesMapPayoutsAndTxId.set(payoutid, [transactionId]);
	}

	deleteTransactionToReproces(payoutid: string, transactionId: string): void {
		let txIds: string[] | undefined;
		if (this.reProcesMapPayoutsAndTxId.has(payoutid)) {
			txIds = this.reProcesMapPayoutsAndTxId.get(payoutid);
			if (txIds && txIds.length > 0) {
				let x: number = txIds?.indexOf(transactionId);
				if (x != -1) {
					txIds.splice(x, 1);
					if (txIds.length > 0) this.reProcesMapPayoutsAndTxId.set(payoutid, txIds);
					else this.reProcesMapPayoutsAndTxId.delete(payoutid);
				}
			} else this.reProcesMapPayoutsAndTxId.delete(payoutid);
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
