<div class="subservice-list-item-container" (click)="viewDetails(true)">
	<div class="subservice-info-container">
		<span class="square-container" fxFlex="5">
			<button class="square-add-payment" [ngClass]="{ 'can-add-payment': subService.paymentBy === 'EMPLOYEE' }">
				<span
					class="plus xs-header-breadcrumb"
					[ngStyle]="{
						color: subService.paymentBy === 'EMPLOYEE' ? 'var(--arrow-selected)' : 'var(--color-disabled)'
					}"
					>+</span
				>
			</button>
		</span>

		<span class="subservice-name column-text" fxFlex="8">{{ subService.subService.name }}</span>

		<span class="payment-by column-text" *ngIf="subService.paymentBy === 'EMPLOYEE'" fxFlex="12">{{
			subService.paymentBy | titlecase
		}}</span>
		<span class="payment-by column-text" *ngIf="subService.paymentBy === 'PAY_GROUP'" fxFlex="12">Paygroup</span>

		<span class="reference-type column-text" fxFlex="13">{{
			subService.subService.referenceType | titlecase
		}}</span>

		<span class="reference column-text" fxFlex="12">
			<app-icon
				class="add-payment-icon"
				[name]="refrenceStatusIcon"
				[color]="refrenceStatusColor"
				[lineWidth]="'1.5px'"
				[size]="'24'"
			></app-icon>
		</span>

		<span class="amount column-text" fxFlex="10"
			>{{ subService.amount.value }}{{ subService.amount.currency | currencySymbol }}</span
		>

		<span class="uploaded-by column-text" fxFlex="15">
			<span class="upload-type" *ngIf="uploadedByFile">
				<app-icon
					class="add-payment-icon"
					[name]="'document-upload'"
					[color]="'var(--color-disabled-secondary)'"
					[lineWidth]="'2px'"
					[size]="'24'"
				></app-icon>
				FILE
			</span>

			<span class="upload-type" *ngIf="uploadedManually">
				<app-icon
					class="add-payment-icon"
					[name]="'document-text'"
					[color]="'var(--color-disabled-secondary)'"
					[lineWidth]="'2px'"
					[size]="'24'"
				></app-icon>
				MANUAL
			</span>

			<span *ngIf="!uploadedManually && !uploadedByFile" class="no-file-uploaded">PENDING</span>
		</span>
	</div>
	<mat-divider *ngIf="!hideMatDivider" class="row-divider"></mat-divider>
</div>
