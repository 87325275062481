import { Injectable } from "@angular/core";
import {
	ServiceProviderUpdateDto,
	ServiceProviderUpdateForm
} from "src/app/shared/models/service-provider-types.interface";
import { FundingMethodName } from "src/app/shared/models/service-provider.type";

@Injectable({
	providedIn: "root"
})
export class EditServiceProviderService {
	constructor() {}

	getServiceProviderUpdateDTO(formData: ServiceProviderUpdateForm): ServiceProviderUpdateDto {
		const fundingMethods: FundingMethodName[] = [];
		if (formData.pullMethod) {
			fundingMethods.push("PULL");
		}
		if (formData.pushMethod) {
			fundingMethods.push("PUSH");
		}

		const { id, version, logoId, currencies, accountFields } = formData;

		const serviceProvider: ServiceProviderUpdateDto = {
			id,
			version,
			logoId,
			currencies,
			fundingMethods,
			accountFields
		};

		return serviceProvider;
	}
}
