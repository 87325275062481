import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subject } from "rxjs";

@Component({
	selector: "app-payroll-integration-modal",
	templateUrl: "./payroll-integration-modal.component.html",
	styleUrls: ["./payroll-integration-modal.component.scss"]
})
export class PayrollIntegrationModalComponent implements OnInit, OnDestroy {
	form!: FormGroup;
	destroy$ = new Subject<void>();

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: number,
		public dialogRef: MatDialogRef<PayrollIntegrationModalComponent>,
		private formBuilder: FormBuilder
	) {}

	ngOnInit(): void {
		this.initForm();
	}

	initForm() {
		this.form = this.formBuilder.group({
			hours: [1, [Validators.required, this.integerValidator, Validators.min(1), Validators.max(999)]]
		});

		if (this.data && this.form) {
			this.form.get("hours")?.patchValue(this.data);
		}
	}

	integerValidator(control: AbstractControl): { [key: string]: boolean } | null {
		const value = control.value;
		if (value === null || value === undefined) {
			return null;
		}

		if (Number.isInteger(value)) {
			return null; // Value is an integer
		}

		return { integer: true }; // Value is not an integer
	}

	apply(): void {
		this.dialogRef.close(this.form.get("hours")?.value);
	}

	close() {
		this.dialogRef.close(false);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
