import { BeneficiarySetAction, BeneficiaryActionType } from "../actions/tppBeneficiarySelect.action";
import { Action } from "@ngrx/store";
import { TppAccount } from "src/app/shared/models/tpp-account.interface";

const initialState: { beneficiary: TppAccount } = {
	beneficiary: {
		id: "",
		version: "",
		bankAccount: {
			id: "",
			version: "",
			bankName: "",
			country: "",
			currency: "",
			fields: []
		},
		service: "",
		country: "",
		name: "",
		currency: ""
	}
};

export const beneficiarySelectReducer = (state = initialState, action: Action): { beneficiary: TppAccount } => {
	const selectAction = action as BeneficiarySetAction;

	switch (selectAction.type) {
		case BeneficiaryActionType.SET_SELECTED:
			return {
				...state,
				beneficiary: selectAction.payload.beneficiary
			};

		default:
			return state;
	}
};
