import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InputSelectCountryComponent } from "./input-select-country.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSelectCountryModule } from "@angular-material-extensions/select-country";

@NgModule({
	declarations: [InputSelectCountryComponent],
	imports: [
		CommonModule,
		MatFormFieldModule,
		MatSelectModule,
		FormsModule,
		ReactiveFormsModule,
		MatSelectCountryModule.forRoot("en")
	],
	exports: [InputSelectCountryComponent]
})
export class InputSelectCountryModule {}
