<div class="flex flex-column">
	<div class="flex flex-row">
		<span class="c-text-navigation-title" (click)="onPayElectives()">Pay Electives&nbsp;/&nbsp;</span
		><span class="c-text-navigation-title" (click)="onDashboard()"> Dashboard</span>
	</div>
	<div class="c-text-title c-text-title-page">File Management</div>

	<customer-legal-entity-select
		(customerEntitySelection)="selectionChange($event)"
		[enableSelectAll]="true"
		[payGroupStatus]="'LIVE, IMPLEMENTATION'"
	></customer-legal-entity-select>

	<div class="flex flex-column" *ngIf="payGroups$ | async as payGroups">
		<app-paygroup (paygroupSelected)="paygroupSelected($event)" [payGroups]="payGroups"></app-paygroup>
	</div>
</div>

<ng-container>
	<mat-paginator
		[length]="totalPageCount"
		[pageSizeOptions]="[5, 10, 25, 100]"
		[pageSize]="pageSize"
		[pageIndex]="pageIndex"
		(page)="paginate($event)"
		aria-label="Select page"
		[color]="'primary'"
	>
	</mat-paginator>
</ng-container>
