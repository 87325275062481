import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HoverObjectComponent } from "./hover-object.component";
import { TooltipModule } from "../tooltip/tooltip.module";
import { FlexLayoutModule } from "ngx-flexible-layout";
import { SharedModule } from "@shared/shared.module";
import { PipesModule } from "src/app/shared/pipes/pipes.module";

@NgModule({
	declarations: [HoverObjectComponent],
	imports: [CommonModule, TooltipModule, FlexLayoutModule, SharedModule, PipesModule],
	exports: [HoverObjectComponent]
})
export class HoverObjectModule {}
