<div class="payroll-dialog-conatiner">
	<div class="payroll-dialog-header">
		<span class="header-title">Payroll integration CUSTOM hours</span>

		<app-icon
			(click)="close()"
			id="closeIcon"
			[size]="'60'"
			[name]="'close'"
			color="var(--color-basic-100)"
			class="close"
			[lineWidth]="'1px'"
		>
		</app-icon>
	</div>
	<div class="payroll-dialog-content" [formGroup]="form" mat-dialog-content>
		<input-wrapper class="hours-wrapper">
			<input class="hours-selection" placeholder="1-999" step="1" formControlName="hours" type="number" />
			<mat-error>
				<div *ngIf="form.get('hours')?.hasError('required') && form.get('hours')?.touched">
					Requires an input to continue
				</div>
				<div *ngIf="form.get('hours')?.hasError('min') || form.get('hours')?.hasError('max')">
					Input needs to be between 1 - 999 hours
				</div>
				<div *ngIf="form.get('hours')?.hasError('integer') && form.get('hours')?.touched">
					Input must be a whole number
				</div>
			</mat-error>
		</input-wrapper>

		<button
			class="applyBtn"
			(click)="apply()"
			[ngClass]="{ disabled: !form.valid }"
			mat-button
			[disabled]="!form.valid"
		>
			APPLY
		</button>
	</div>
</div>
