import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { GenericPayGroupListContainerComponent } from "./generic-pay-group-list-container.component";
import { NoItemsLayoutModule } from "../../no-items-layout/no-items-layout.module";
import { MatDividerModule } from "@angular/material/divider";
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
	declarations: [GenericPayGroupListContainerComponent],
	exports: [GenericPayGroupListContainerComponent],
	imports: [CommonModule, NoItemsLayoutModule, MatDividerModule, MatTooltipModule]
})
export class GenericPayGroupListContainerModule {}
