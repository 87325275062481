import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "../../../../environments/environment";
import {
	FileHistoryPagination,
	FileManagerPlaceholder,
	FileManagerPlaceholdersPagination,
	FileManagerTask,
	FileManagerTaskPagination,
	FileReport,
	FileHistory
} from "../../models/file-manager";

@Injectable({
	providedIn: "root"
})
export class FileManagementService {
	constructor(private http: HttpClient) {}

	uploadPayElectiveFile(fileData: any) {
		return this.http.post(`${environment.apiUri}/v1/employees/files`, fileData);
	}

	uploadG2NFile(fileData: any) {
		return this.http.post(`${environment.apiUri}/v1/payrolls/files`, fileData);
	}

	refreshPayrollIntegration(payGroupId: string): Observable<any> {
		return this.http.post(`${environment.apiUri}/v1/uploading/refresh/integration/${payGroupId}`, null, {
			observe: "response"
		});
	}

	refreshPayrollIntegrationNets(payCycleId: string): Observable<any> {
		return this.http.post(`${environment.apiUri}/v1/uploading/refresh/integration/nets`, { payCycleId });
	}

	fetchG2NIntegrationHistory(payCycleId: string): Observable<FileManagerPlaceholdersPagination> {
		const endpointUrl = `${environment.apiUri}/v1/uploading/refresh/integration?type=NETS&sortField&sortDir&pageSize=-1&payCycleIds=${payCycleId}`;

		return this.http.get<FileManagerPlaceholdersPagination>(endpointUrl);
	}

	getFilesG2NByPayGroupID(
		payGroupId: string,
		payCycleId: string,
		pageSize: number,
		pageNumber: number
	): Observable<FileManagerTaskPagination> {
		return this.getFilesByPayGroupId(payGroupId, payCycleId, pageSize, pageNumber, "PAYROLLS");
	}

	getFilesG2NFileHistoryByPayGroupID(
		payGroupId: string,
		payCycleId: string,
		pageSize: number,
		pageNumber: number
	): Observable<FileHistoryPagination> {
		return this.getFilesByPayGroupId(payGroupId, payCycleId, pageSize, pageNumber, "PAYROLLS");
	}

	getFilesG2NFileHistoryByPaygroupIncludingPayCycleID(
		payGroupId: string,
		payCycleId: string,
		pageSize: number,
		pageNumber: number
	): Observable<FileHistoryPagination> {
		return this.getFilesByPayCycleId(payGroupId, payCycleId, pageSize, pageNumber, "PAYROLLS");
	}

	getFilesPayElectivesyPayGroupID(
		payGroupId: string,
		pageSize: number,
		pageNumber: number
	): Observable<FileManagerTaskPagination> {
		return this.getFilesByPayGroupId(payGroupId, "", pageSize, pageNumber, "EMPLOYEES");
	}

	getFilesPlaceholder(payGroupIds: string): Observable<FileManagerPlaceholdersPagination> {
		return this.getFilesPlaceholderByPayGroupId(payGroupIds);
	}

	getFilesByPayGroupId(
		payGroupId: string,
		payCycleID: string = "",
		pageSize: number,
		pageNumber: number,
		topic: string
	): Observable<FileManagerTaskPagination> {
		return this.http.get<FileManagerTaskPagination>(
			`${environment.apiUri}/v1/pay-groups/${payGroupId}/files?payCycleIds=${payCycleID}&pageSize=${pageSize}&pageNumber=${pageNumber}&topics=${topic}`
		);
	}

	getFilesByPayCycleId(
		payGroupId: string,
		payCycleID: string,
		pageSize: number,
		pageNumber: number,
		topic: string
	): Observable<FileManagerTaskPagination> {
		return this.http.get<FileManagerTaskPagination>(
			`${environment.apiUri}/v1/pay-groups/${payGroupId}/files?payCycleIds=${payCycleID}&pageSize=${pageSize}&pageNumber=${pageNumber}&topics=${topic}`
		);
	}

	getFilesPlaceholderByPayGroupId(payGroupIds: string): Observable<FileManagerPlaceholdersPagination> {
		return this.http.get<FileManagerPlaceholdersPagination>(
			`${environment.apiUri}/v1/uploading/refresh/integration?payGroupIds=${payGroupIds}&type=MASTER_DATA&sortField&sortDir`
		);
	}

	getFileByTaskId(taskId: string, pageSize: number, pageNumber: number): Observable<FileReport> {
		return this.http.get<FileReport>(`${environment.apiUri}/v1/files/tasks/${taskId}/reports`);
	}

	getFileObject(filedId: string): Observable<Blob> {
		let params = new HttpParams();
		params = params.append("fileId", filedId);
		params = params.append("fileGroup", "EMPLOYEES");

		return this.http.get<Blob>(`${environment.apiUri}/v1/private-resources/files/content`, {
			responseType: "blob" as "json",
			params
		});
	}

	isValidIntegrationTaskId(placeholder: FileManagerPlaceholder, fileItems: FileManagerTask[]): boolean {
		return (
			placeholder.integrationTaskId !== "" &&
			!fileItems.some(file => file.attributes && file.attributes.INTEGRATION_ID === placeholder.integrationTaskId)
		);
	}

	isValidIntegrationTaskHistoryId(placeholder: FileManagerPlaceholder, fileItems: FileHistory[]): boolean {
		return (
			placeholder.integrationTaskId !== "" &&
			!fileItems.some(file => file.attributes && file.attributes.INTEGRATION_ID === placeholder.integrationTaskId)
		);
	}

	isValidCreatedAt(placeholder: FileManagerPlaceholder, fileItems: FileManagerTask[], pageIndex: number): boolean {
		if (fileItems.length === 0) {
			return true;
		}

		const placeholderDate = new Date(placeholder.createdAt);
		const lastItemDate = new Date(fileItems[fileItems.length - 1]?.createdAt);
		const firstItemDate = new Date(fileItems[0]?.createdAt);

		return (
			(pageIndex === 0 && placeholderDate > lastItemDate) ||
			(pageIndex > 0 && placeholderDate > lastItemDate && placeholderDate < firstItemDate)
		);
	}

	isValidCreatedAtHistory(placeholder: FileManagerPlaceholder, fileItems: FileHistory[]): boolean {
		if (fileItems.length === 0) {
			return true;
		}

		const placeholderDate = new Date(placeholder.createdAt);
		const lastItemDate = new Date(fileItems[fileItems.length - 1]?.createdAt);

		return placeholderDate > lastItemDate;
	}
}
