import { NgModule } from "@angular/core";
import { CommonModule, CurrencyPipe } from "@angular/common";

import { MilestoneCardsComponent } from "./milestone-cards.component";
import { SharedModule } from "@shared/shared.module";

@NgModule({
	declarations: [MilestoneCardsComponent],
	imports: [CommonModule, SharedModule],
	exports: [MilestoneCardsComponent],
	providers: [CurrencyPipe]
})
export class MilestoneCardsModule {}
