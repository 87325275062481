import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EventEmitter, Injectable, Output } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import {
	PaymentProviderPagination,
	PaymentTypeCapabilities,
	PaymentProviderSettings
} from "src/app/shared/models/service-provider.interface";

import { environment } from "src/environments/environment";
import { Definitions } from "src/app/shared/models/service-definition.interface";
import { AccountField } from "src/app/shared/models/account-field.interface";
import { catchError, map, take, tap } from "rxjs/operators";
import {
	AvailableServiceProvider,
	ServiceProviderCreateDto,
	ServiceProviderFullObject,
	ServiceProviderListItem,
	ServiceProviderPagination,
	ServiceProviderUpdateDto
} from "src/app/shared/models/service-provider-types.interface";
import {
	PaymentProvider,
	ProviderCountrySettingsTemplate,
	ProviderPagination,
	ServiceProviderCountryCreateDTO,
	ServiceProviderCountrySetup,
	ServiceProviderCountryUpdateDTO
} from "src/app/shared/models/service-provider-country-types";
import { DefinitionsPOST } from "@modules/service-definition/_models/definition-post.interface";
import { PaymentTypeName } from "src/app/shared/models/service-provider.type";
import { DefinitionsPUT } from "@modules/service-definition/_models/definition-put.interface";
import { DefinitionsDELETE } from "@modules/service-definition/_models/definition-delete.interface";
import { SelectOption } from "../../models/select-option.interface";
import { formatDataForSelect } from "../../utils/format-select-data.util";

@Injectable({
	providedIn: "root"
})
export class ServiceProviderService {
	clearFields: EventEmitter<void> = new EventEmitter<void>();
	private _selectedProvider: string = "";

	constructor(private http: HttpClient) {}

	definitionsEvent: EventEmitter<Definitions> = new EventEmitter();

	getServiceProviderById(serviceProviderId: string): Observable<ServiceProviderFullObject> {
		return this.http.get<ServiceProviderFullObject>(
			`${environment.apiUri}/v1/payment-providers/${serviceProviderId}`
		);
	}

	getProviderCountrySetup(serviceProviderId: string, countryServiceProvider: string): Observable<ProviderPagination> {
		return this.http.get<ProviderPagination>(
			`${environment.apiUri}/v1/payment-provider-countries?country=${countryServiceProvider}&providerId=${serviceProviderId}`
		);
	}

	getProviderByProviderCountryId(providerCountrId: string): Observable<ServiceProviderCountrySetup> {
		return this.http.get<ServiceProviderCountrySetup>(
			`${environment.apiUri}/v1/payment-provider-countries/${providerCountrId}`
		);
	}

	getProviderSettingsTemplate(): Observable<ProviderCountrySettingsTemplate> {
		return this.http.get<ProviderCountrySettingsTemplate>(
			`${environment.apiUri}/v1/payment-providers/settings/template`
		);
	}

	getServiceProviderList(pageSize: number, pageNumber: number): Observable<ServiceProviderListItem[]> {
		return this.http
			.get<ServiceProviderPagination>(
				`${environment.apiUri}/v1/payment-providers?pageSize=${pageSize}&pageNumber=${pageNumber}&countriesPageSize=350`
			)
			.pipe(map((res: ServiceProviderPagination) => res.items.filter(list => list)));
	}

	createServiceProvider(serviceProvider: ServiceProviderCreateDto): Observable<ServiceProviderFullObject> {
		return this.http.post<ServiceProviderFullObject>(`${environment.apiUri}/v1/payment-providers`, serviceProvider);
	}

	createServiceProviderCountry(
		providerCountryData: ServiceProviderCountryCreateDTO
	): Observable<ServiceProviderCountrySetup> {
		return this.http.post<ServiceProviderCountrySetup>(
			`${environment.apiUri}/v1/payment-provider-countries`,
			providerCountryData
		);
	}

	updateServiceProviderCountry(
		providerCountryData: ServiceProviderCountryUpdateDTO
	): Observable<ServiceProviderCountrySetup> {
		return this.http.put<ServiceProviderCountrySetup>(
			`${environment.apiUri}/v1/payment-provider-countries`,
			providerCountryData
		);
	}

	updateServiceProvider(serviceProvider: ServiceProviderUpdateDto): Observable<ServiceProviderFullObject> {
		this.clearFields.emit();
		return this.http.put<ServiceProviderFullObject>(`${environment.apiUri}/v1/payment-providers`, serviceProvider);
	}

	//paymentProviders legal entities
	getDefinitionsByEntityId(entityId: string): Observable<Definitions> {
		return this.http.get<Definitions>(
			`${environment.apiUri}/v1/definition/payment-providers/legal-entities/${entityId}`
		);
	}

	getDefinitionVersion(entityId: string): Observable<Definitions> {
		return this.http.get<Definitions>(
			`${environment.apiUri}/v1/definition/payment-providers/legal-entities/${entityId}`
		);
	}

	getProviderNamesByEntityId(entityId: string): Observable<SelectOption[]> {
		return this.http
			.get<AvailableServiceProvider[]>(
				`${environment.apiUri}/v1/definition/payment-providers/legal-entities/${entityId}/providers`
			)
			.pipe(
				map(serviceProviders =>
					serviceProviders.map(provider => {
						const option: SelectOption = {
							value: provider.id,
							text: provider.name
						};

						return formatDataForSelect(option);
					})
				)
			);
	}

	getPaymentProviders(country: string, paymentType: PaymentTypeName): Observable<PaymentProvider[]> {
		return this.http
			.get<PaymentProviderPagination>(
				`${environment.apiUri}/v1/definition/payment-providers?country=${country}&paymentType=${paymentType}`
			)
			.pipe(
				//we don't need the pagination data so only return the items array from the object
				map((res: PaymentProviderPagination) => {
					return res.items;
				})
			);
	}

	getFullPaymentProvider(country: string): Observable<any> {
		return this.http.get(`${environment.apiUri}/v1/payment-providers/${country}`);
	}

	createDefinition(paymentDefintion: DefinitionsPOST): Observable<any> {
		return this.http.post<any>(`${environment.apiUri}/v1/definition/payment-providers`, paymentDefintion).pipe(
			catchError(error => {
				return of(error);
			})
		);
	}
	updateDefinitions(paymentDefintion: DefinitionsPUT): Observable<DefinitionsPUT> {
		return this.http.put<DefinitionsPUT>(`${environment.apiUri}/v1/definition/payment-providers`, paymentDefintion);
	}

	deleteDefinitions(paymentDefintionIds: DefinitionsDELETE) {
		const options = {
			headers: new HttpHeaders({
				"Content-Type": "application/json"
			}),
			body: paymentDefintionIds
		};

		return this.http.delete(`${environment.apiUri}/v1/definition/payment-providers`, options);
	}

	getServiceProviderAccountFields(countryId: string): Observable<AccountField[]> {
		return this.http.get<AccountField[]>(
			`${environment.apiUri}/v1/definition/payment-providers/provider-countries/${countryId}/account-fields`
		);
	}

	getProviders(): Observable<AvailableServiceProvider[]> {
		return this.http.get<AvailableServiceProvider[]>(`${environment.apiUri}/v1/payment-providers/supported`);
	}

	getProvidersAsSelectOptions(): Observable<SelectOption[]> {
		return this.getProviders().pipe(
			take(1),
			map(serviceProviders =>
				serviceProviders.map(provider => {
					const option: SelectOption = {
						value: provider.id,
						text: provider.name
					};

					return formatDataForSelect(option);
				})
			)
		);
	}

	getPaymentProvidersSettings(): Observable<PaymentTypeCapabilities[]> {
		return this.http
			.get<PaymentProviderSettings>(`${environment.apiUri}/v1/payment-providers/settings/template`)
			.pipe(
				//we don't need the pagination data so only return the items array from the object
				map((res: PaymentProviderSettings) => {
					return res.capabilities;
				})
			);
	}

	getTppProviderByCountry(country: string): Observable<ProviderPagination> {
		return this.http.get<ProviderPagination>(
			`${environment.apiUri}/v1/payment-provider-countries?pageNumber=0&pageSize=-1&country=${country}&paymentTypes=TPP`
		);
	}

	get selectedProvider(): string {
		return this._selectedProvider;
	}

	set selectedProvider(newValue: string) {
		this._selectedProvider = newValue;
	}

	getServiceProviderCountryList(pageSize: number, pageNumber: number, providerName: string): Observable<string[]> {
		return this.getServiceProviderList(pageSize, pageNumber).pipe(
			map(result => result.find(res => res.provider.name === providerName)!.providerCountries || []),
			map(providerCountries => providerCountries.items.map(item => item.country.code))
		);
	}
}
