import { EventEmitter, Injectable, OnDestroy, OnInit, Output } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { forkJoin, Observable, of, Subject, Subscription } from "rxjs";
import { map, switchMap, takeUntil } from "rxjs/operators";
import { ApiService } from "@modules/pay-groups/services/api.service";
import { Customer } from "src/app/shared/models/customer.interface";
import { PayGroup, PayGroupPagination } from "src/app/shared/models/pay-groups";
import { getCustomerEntityGroupState } from "src/app/store";
import { CustomerEntityGroupSelect } from "src/app/store/models/customerEnitityGroupSelection.model";
import { AppState } from "src/app/store/models/state.model";
import { breadcrumbDataObject, breadcrumbSelection } from "../_models/breadcrumb.interface";
import { CustomerCacheService } from "@shared/services/customer/customer-cache.service";

@Injectable({
	providedIn: "root"
})
export class DropdownService implements OnDestroy, OnInit {
	//close all drop downs
	@Output() onDropDownClick: EventEmitter<boolean> = new EventEmitter<boolean>();

	private destroy$: Subject<void> = new Subject<void>();

	payGroups$!: Observable<PayGroup[]>;
	customers$!: Observable<Customer[]>;

	constructor(
		private store: Store<AppState>,
		private payGroupService: ApiService,
		private customerCache: CustomerCacheService
	) {}

	ngOnInit(): void {}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	getSelection(crumb: breadcrumbDataObject): Observable<breadcrumbSelection[]> {
		return this.toCrumbSelection(crumb);
	}

	private toCrumbSelection(crumb: breadcrumbDataObject): Observable<breadcrumbSelection[]> {
		switch (crumb.type) {
			case "dashboard": {
				return of([
					{
						text: "Dashboard",
						link: ""
					},
					{
						text: "Employee Data",
						link: "/employee-data/pay-electives/master-data"
					},
					{
						text: "Calendar",
						link: "/calendar/milestone-list"
					}
				]);
			}
			case "employee-list": {
				return of([
					{
						text: "Employee Data",
						link: ""
					},
					{
						text: "Calendar",
						link: "/calendar/milestone-list"
					},
					{
						text: "Dashboard",
						link: "/payments/dashboard"
					}
				]);
			}
			case "calendar": {
				return of([
					{
						text: "Calendar",
						link: ""
					},
					{
						text: "Dashboard",
						link: "/payments/dashboard"
					},
					{
						text: "Employee Data",
						link: "/employee-data/master-data"
					}
				]);
			}
			case "dashboard-paygroups": {
				return of([
					{
						text: "Dashboard",
						link: ""
					},
					{
						text: "Employee Data",
						link: "/employee-data/paygroups"
					},
					{
						text: "Calendar",
						link: "/calendar/paygroup-list"
					}
				]);
			}
			case "pay-elective-pay-groups": {
				return of([
					{
						text: "Employee Data",
						link: ""
					},
					{
						text: "Dashboard",
						link: "/payments/paygroups"
					},
					{
						text: "Calendar",
						link: "/calendar/paygroup-list"
					}
				]);
			}
			case "calendar-paygroups": {
				return of([
					{
						text: "Calendar",
						link: ""
					},
					{
						text: "Employee Data",
						link: "/dashboard/pay-electives/paygroups"
					},
					{
						text: "Dashboard",
						link: "/payments/paygroups"
					}
				]);
			}
			case "paygroup": {
				return this.getPaygroups(crumb);
			}
			case "customer": {
				return this.getCustomers(crumb);
			}
			case "customer-clear-selected-paygroup": {
				return this.getCustomers(crumb);
			}
			default: {
				return of([
					{
						text: "Error - breadcrumb type invalid",
						link: "null"
					}
				]);
			}
		}
	}

	private getCustomers(crumb: breadcrumbDataObject): Observable<breadcrumbSelection[]> {
		const customerGroup$ = this.store.pipe(
			takeUntil(this.destroy$),
			select(getCustomerEntityGroupState),
			map(state => state)
		);

		return customerGroup$.pipe(
			switchMap((state: CustomerEntityGroupSelect) => {
				const customers$ = this.customerCache.getAllCustomers();
				return forkJoin([customers$, of(state)]);
			}),
			map((response: [Customer[], CustomerEntityGroupSelect]) => {
				const customers = response[0];
				const state = response[1];

				return this.mapCustomerCrumbSelections(customers, state, crumb);
			})
		);
	}

	private getPaygroups(crumb: breadcrumbDataObject): Observable<breadcrumbSelection[]> {
		const statuses: string = "LIVE, IMPLEMENTATION";
		const PAGE_SIZE = 1000;
		const PAGE_NUMBER = 0;
		const customerGroup$ = this.store.pipe(
			takeUntil(this.destroy$),
			select(getCustomerEntityGroupState),
			map(state => state)
		);

		let selection$: Observable<breadcrumbSelection[]> = customerGroup$.pipe(
			switchMap(state => {
				const payGroups$ = this.payGroupService.getPayGroups(
					state.customerId,
					"",
					statuses,
					"",
					"",
					PAGE_SIZE,
					PAGE_NUMBER
				);

				return forkJoin([payGroups$, of(state)]);
			}),
			map((response: [PayGroupPagination, CustomerEntityGroupSelect]) => {
				const payGroups = response[0].items;
				const customerGroup = response[1];

				return this.mapPayGroupCrumbSelections(payGroups, customerGroup, crumb);
			})
		);

		return selection$;
	}

	private mapPayGroupCrumbSelections(
		payGroups: PayGroup[],
		customerGroup: CustomerEntityGroupSelect,
		crumb: breadcrumbDataObject
	): breadcrumbSelection[] {
		let result: breadcrumbSelection[] = [];
		if (payGroups.length === 0) {
			result.push({
				text: "No Results.",
				link: "",
				id: customerGroup.payGroupId
			});
			return result;
		}

		if (!crumb.disableAutoDropdownSelection) {
			//add selected paygroup first
			result.push({
				text: crumb.crumb!,
				link: "",
				id: customerGroup.payGroupId
			});
		}

		payGroups.forEach(paygroup => {
			if (paygroup.id !== customerGroup.payGroupId) {
				result.push({
					text: paygroup.data.name,
					id: paygroup.id,
					type: "paygroup",
					link: crumb.link!,
					imagePath:
						"assets/svg-country-flags/svg/" + paygroup.legalEntity.data.country.toLowerCase() + ".svg",
					externalId: paygroup.externalId
				});
			} else {
				result[0].imagePath =
					"assets/svg-country-flags/svg/" + paygroup.legalEntity.data.country.toLowerCase() + ".svg";

				result[0].externalId = paygroup.externalId;
			}
		});
		return result;
	}

	private mapCustomerCrumbSelections(
		customers: Customer[],
		state: CustomerEntityGroupSelect,
		crumb: breadcrumbDataObject
	): breadcrumbSelection[] {
		let result: breadcrumbSelection[] = [];

		if (!crumb.disableAutoDropdownSelection) {
			//add selected customer first
			result.push({
				text: crumb.crumb!,
				link: "",
				id: state.customerId
			});
		}
		customers.forEach((customer: Customer) => {
			if (customer.id !== state.customerId) {
				result.push({
					text: customer.name,
					link: crumb.link!,
					id: customer.id,
					type: crumb.type,
					externalId: customer.externalId
				});
			} else {
				if (!crumb.disableAutoDropdownSelection) {
					result[0].externalId = customer.externalId;
				}
			}
		});

		return result;
	}
}
