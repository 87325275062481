export interface MilestoneEventHistoryLogData {
	milestoneId: string;
	milestoneType: string;
	customerExternalId: string;
	customerName: string;
	payGroupId: string;
	paygroupName: string;
	cycleName: string;
	group: string;
}

export interface TransactionHistoryLogData {
	payoutId: string;
	paymethodId: string;
}

export enum HistoryLogType {
	MILESTONE = "MILESTONE",
	TRANSACTION = "TRANSACTION"
}
