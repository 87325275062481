<div [formGroup]="form" class="pay-group-list-filters-container">
	<input-wrapper class="input-field" label="LEGAL ENTITIES">
		<wpay-auto-complete
			label="Select Legal Entity"
			[options]="legalEntitySelectionOptions"
			[loading]="!legalEntitySelectionOptions"
			formControlName="selectedLegalEntityId"
		>
		</wpay-auto-complete>
	</input-wrapper>
	<input-wrapper class="status-select" label="STATUS">
		<input-select
			id="status"
			[options]="statusOptions"
			formControlName="status"
			[disableOptionCentering]="true"
		></input-select>
	</input-wrapper>
	<div class="date-range-container">
		<input-wrapper class="date-select" label="TIMEFRAME">
			<mat-form-field id="date-container" (click)="picker.open()">
				<div class="calendar-label" fxLayoutAlign="space-around center">
					<div class="calendar-icon-container">
						<app-icon [name]="'calendar_month'" [color]="'black'"></app-icon>
					</div>
					<span class="caldendar-date" id="dateStart">{{ startDate }}</span> -
					<span class="caldendar-date" id="dateEnd">{{ endDate }}</span>

					<div class="date-range" fxLayoutAlign="end center">
						<mat-date-range-input class="range-picker" [rangePicker]="picker">
							<input
								id="startDate"
								matInput
								matStartDate
								formControlName="start"
								placeholder="Start with"
								readonly
							/>
							<input
								id="endDate"
								readonly
								matInput
								matEndDate
								formControlName="end"
								placeholder="End with"
							/>
						</mat-date-range-input>
						<mat-datepicker-toggle matSuffix [for]="picker">
							<app-icon
								matDatepickerToggleIcon
								class="date-drop-down"
								id="drop-down-arrow-select"
								[color]="'rgba(0, 0, 0, 0.54)'"
								[lineWidth]="'2.5'"
								[name]="'arrow-down'"
								[size]="'16'"
							></app-icon>
						</mat-datepicker-toggle>
						<mat-date-range-picker #picker>
							<mat-datepicker-actions fxLayoutAlign="space-between">
								<button mat-button matDateRangePickerCancel id="clearButton" (click)="clearDate()">
									Clear
								</button>
								<button mat-raised-button id="doneButton" matDatepickerApply>Done</button>
							</mat-datepicker-actions>
						</mat-date-range-picker>
					</div>
				</div>
			</mat-form-field>
		</input-wrapper>
	</div>
</div>
