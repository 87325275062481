import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { ReportsService } from "../../../../../../shared/services/reports/reports.service";
import { PayGroup } from "../../../../../../shared/models/pay-groups";
import { Pagination } from "../../../../../../shared/models/pagination.interface";
import {
	PayElectivePayGroupBeneficiaryData,
	PayElectivePayGroupBeneficiaryPagination
} from "src/app/shared/models/employee.interface";
import { ApiService } from "../../../../../pay-groups/services/api.service";

@Component({
	selector: "app-list-item",
	templateUrl: "./list-item.component.html",
	styleUrls: ["./list-item.component.scss"]
})
export class ListItemComponent implements OnInit, OnDestroy {
	@Input() payGroup!: PayGroup;
	destroy$: Subject<void> = new Subject();

	g2nErrorCount: number = 0;
	transactionErrorCount: number = 0;
	payMethodsErrorCount: number = 0;
	employeesWithNoCardCount: number = 0;
	employeesWithNoAccountCount: number = 0;

	constructor(private reportsService: ReportsService, private payGroupApiService: ApiService) {}

	ngOnInit(): void {
		this.getG2NErrorCount();
		this.getBeneficiariesReports();
		this.getCardAndAccountCounts();
	}

	getG2NErrorCount(): void {
		this.reportsService
			.getG2NErrorsByPayGroupId(this.payGroup.id)
			.pipe(
				takeUntil(this.destroy$),
				map((res: Pagination) => (this.g2nErrorCount = res.items!.length))
			)
			.subscribe();
	}

	getCardAndAccountCounts() {
		this.payGroupApiService
			.getEmployeeCountWithNoBandOrCard(this.payGroup.id)
			.pipe(
				takeUntil(this.destroy$),
				map(res => {
					this.employeesWithNoCardCount = res.employeesWithoutCards;
					this.employeesWithNoAccountCount = res.employeesWithoutBanks;
				})
			)
			.subscribe();
	}

	getTransactionsErrorCount(): void {
		//employeeIds,rangeStart,rangeEnd   <--- rest of parameters for new endpoint
		const start = new Date();
		start.setMonth(start.getMonth() - 3);
		const end = new Date();

		let employeeId: string = "";

		let rangeStart: string = start.toISOString().slice(0, start.toISOString().indexOf("T"));

		let rangeEnd: string = end.toISOString().slice(0, end.toISOString().indexOf("T")); //now

		this.reportsService
			.getTransactionsErrorsByPayGroupId(
				this.payGroup.customer.id,
				this.payGroup.legalEntityId,
				this.payGroup.id,
				employeeId,
				rangeStart,
				rangeEnd
			)
			.pipe(
				takeUntil(this.destroy$),
				map((res: Pagination) => (this.transactionErrorCount = res.items!.length))
			)
			.subscribe();
	}

	getBeneficiariesReports(): void {
		const customerId: string = "";
		this.reportsService
			.getBeneficiariesReports(this.payGroup.id, customerId)
			.pipe(
				map((response: PayElectivePayGroupBeneficiaryPagination) => {
					return response.items;
				})
			)
			.subscribe((res: PayElectivePayGroupBeneficiaryData[]) => {
				if (res.length > 0) this.payMethodsErrorCount = res[0].statusCounts.INACTIVE;
			});
	}

	getTransactionErrorCount(): void {}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
