import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { BankAccountsRoutingModule } from "./bank-accounts-routing.module";
import { BankAccountsPage } from "./bank-accounts.page";
import { CountrySelectComponent } from "./_components/country-select/country-select.component";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { FormsSharedModule } from "@shared/modules/forms-shared.module";
import { BankAccountFieldsComponent } from "./_components/bank-account-fields/bank-account-fields.component";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { BreadcrumbsModule } from "@shared/components/breadcrumbs/breadcrumbs.module";

@NgModule({
	declarations: [BankAccountsPage, CountrySelectComponent, BankAccountFieldsComponent],
	imports: [
		CommonModule,
		BankAccountsRoutingModule,
		FormsSharedModule,
		MatIconModule,
		MatButtonModule,
		MatSlideToggleModule,
		MatCheckboxModule,
		BreadcrumbsModule
	]
})
export class BankAccountsModule {}
