import { Action } from "@ngrx/store";
import {
	CustomerEntityCountryActionType,
	CustomerEntityCountrySelectAction
} from "../actions/customerEntityCountrySelect.action";
import { CustomerEntityCountrySelect } from "../models/customerEntityCountrySelection.model";

const initialState: CustomerEntityCountrySelect = {
	customer: "",
	payGroupName: "",
	country: ""
};

export const customerEntityCountrySelectReducer = (
	state = initialState,
	action: Action
): CustomerEntityCountrySelect => {
	const selectAction = action as CustomerEntityCountrySelectAction;

	switch (selectAction.type) {
		case CustomerEntityCountryActionType.SET_SELECTED:
			return {
				...state,
				customer: selectAction.payload.customer,
				payGroupName: selectAction.payload.payGroupName,
				country: selectAction.payload.country
			};

		default:
			return state;
	}
};
