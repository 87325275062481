import { EventEmitter, Injectable } from "@angular/core";

@Injectable({
	providedIn: "root"
})
export class InputSelectService {
	constructor() {}

	clearSelection: EventEmitter<void> = new EventEmitter<void>();

	resetSelectOption(): void {
		this.clearSelection.emit();
	}
}
