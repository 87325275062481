import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FloatingActionButtonComponent } from "./floating-action-button.component";
import { MatButtonModule } from "@angular/material/button";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatIconModule } from "@angular/material/icon";
import { SharedModule } from "@shared/shared.module";

@NgModule({
	declarations: [FloatingActionButtonComponent],
	imports: [CommonModule, MatButtonModule, MatTooltipModule, MatIconModule, SharedModule],
	exports: [FloatingActionButtonComponent]
})
export class FloatingActionButtonModule {}
