import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { floatingActionButtonAnimations } from "./floating-action-button-animations";
export interface FabButton {
	icon: string;
	tooltip: string;
}

export enum SpeedDialFabPosition {
	Top = "top",
	Bottom = "bottom",
	Left = "left",
	Right = "right"
}
@Component({
	selector: "app-floating-action-button",
	templateUrl: "./floating-action-button.component.html",
	styleUrls: ["./floating-action-button.component.scss"],
	animations: floatingActionButtonAnimations
})
export class FloatingActionButtonComponent implements OnInit {
	reverseColumnDirection: boolean = true;
	@Output() createEvent: EventEmitter<void> = new EventEmitter();

	constructor() {}

	ngOnInit(): void {}

	onCreate() {
		this.createEvent.emit();
	}
}
