import { Component, OnInit } from "@angular/core";

import { TabStateService } from "@shared/services/tab-state/tab-state.service";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
	constructor(private tabStateService: TabStateService) {}

	ngOnInit(): void {
		this.tabStateService.initialise();
	}
}
