import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Image } from "../../models/image.interface";

@Injectable({
	providedIn: "root"
})
export class ImagesService {
	private formData!: FormData;

	constructor(private http: HttpClient) {}

	createCustomerLogo(image: File): Observable<Image> {
		this.formData = new FormData();
		this.formData.append("file", image, image.name);
		return this.http.post<Image>(`${environment.apiUri}/v1/resources/images/logos`, this.formData);
	}

	updateCustomerLogo(image: File, logoId: string): Observable<Image> {
		this.formData = new FormData();
		this.formData.append("file", image, image.name);
		return this.http.post<Image>(`${environment.apiUri}/v1/resources/images/logos?logoId=${logoId}`, this.formData);
	}
}
