<mat-form-field class="autocomplete" appearance="fill">
	<mat-label class="placeholder">{{ label }}</mat-label>
	<input
		type="text"
		class="type-ahead"
		[ngClass]="{
			'input-item': _groupName.includes('TPP'),
			'group-item-color-1': _groupName === 'TPP1',
			'group-item-color-2': _groupName === 'TPP2',
			'group-item-color-3': _groupName === 'TPP3',
			'group-item-color-4': _groupName === 'TPP4'
		}"
		matInput
		[formControl]="control"
		[matAutocomplete]="auto"
	/>
	<!-- Arrow down icon -->
	<app-icon
		id="drop-down-arrow"
		[color]="'rgba(0, 0, 0, 0.54)'"
		[lineWidth]="'2.5'"
		[name]="'arrow-down'"
		[size]="'16'"
	></app-icon>

	<mat-autocomplete #auto="matAutocomplete">
		<div class="container-groups">
			<ng-container *ngFor="let option of _tppGroupOptionsSelected; index as i">
				<mat-option
					class="group-item"
					[ngClass]="{
						'group-item-color-1': option.value === 'TPP1',
						'group-item-color-2': option.value === 'TPP2',
						'group-item-color-3': option.value === 'TPP3',
						'group-item-color-4': option.value === 'TPP4'
					}"
					[value]="option.text"
				>
					{{ option.text }}
				</mat-option>
			</ng-container>
		</div>
	</mat-autocomplete>
</mat-form-field>
