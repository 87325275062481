import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Booking } from "../../models/tpp-payment.interface";

@Component({
	selector: "app-tpp-payment-payout-list",
	templateUrl: "./tpp-payment-payout-list.component.html",
	styleUrls: ["./tpp-payment-payout-list.component.scss"]
})
export class TppPaymentPayoutListComponent {
	bookings!: Booking[];

	@Output() inActivatePayouts: EventEmitter<string[]> = new EventEmitter<string[]>();
	@Output() pushToReprocessList: EventEmitter<string[]> = new EventEmitter<string[]>();
	@Output() popFromReprocessList: EventEmitter<string[]> = new EventEmitter<string[]>();

	@Input() set _bookings(bookings: Booking[]) {
		this.bookings = bookings;
	}

	isCancelledRun: boolean = false;
	@Input() set _isCancelledRun(cancelled: boolean) {
		this.isCancelledRun = cancelled;
	}

	reprocessPayoutIdsList: string[] = [];

	@Input() set _reprocessPayoutIdsList(ids: string[]) {
		this.reprocessPayoutIdsList = ids;
	}
}
