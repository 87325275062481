import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ModalWrapperComponent } from "./modal-wrapper.component";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { SharedModule } from "@shared/shared.module";
import { FlexLayoutModule } from "ngx-flexible-layout";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatGridListModule } from "@angular/material/grid-list";
import { InputSelectModule } from "../input-select/input-select.module";
import { ModalDropdownModule } from "./modal-dropdown/modal-dropdown.module";

@NgModule({
	declarations: [ModalWrapperComponent],
	imports: [
		CommonModule,
		MatIconModule,
		MatButtonModule,
		SharedModule,
		FlexLayoutModule,
		MatTooltipModule,
		MatGridListModule,
		InputSelectModule,
		ModalDropdownModule
	],
	exports: [ModalWrapperComponent]
})
export class ModalWrapperModule {}
