import { Pipe, PipeTransform } from "@angular/core";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { UserService } from "@modules/user-management/_services/user.service";

@Pipe({
	name: "userFullName"
})
export class UserFullNamePipe implements PipeTransform {
	constructor(private userService: UserService) {}

	transform(userId: string): Observable<string> {
		return this.userService.getUserById(userId).pipe(
			take(1),
			map(res => {
				let fullName: string = res.firstName + " " + res.lastName;
				return fullName.trim() !== "" ? fullName : "A user";
			})
		);
	}
}
