import { Component, Input } from "@angular/core";
import { NetPaymentReportPagination } from "@shared/models/net-payments.interface";

@Component({
	selector: "app-net-payment-data",
	templateUrl: "./net-payment-data.component.html",
	styleUrls: ["./net-payment-data.component.scss"]
})
export class NetPaymentDataComponent {
	@Input() datasource!: NetPaymentReportPagination;
}
