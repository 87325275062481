<div [formGroup]="searchForm" class="searchbar-wrapper">
	<mat-form-field id="searchfield" appearance="outline">
		<app-icon [name]="'search-normal-1'" matPrefix></app-icon>
		<input matInput placeholder="Enter your search" formControlName="search" />
	</mat-form-field>
</div>

<div class="list-title">Customer list</div>
<app-calendar-actions *ngIf="isCalendarPage"></app-calendar-actions>
<br />

<!-- If there are items -->
<ng-container *ngIf="customers.length > 0; else noItems">
	<!-- Items -->
	<mat-card
		(click)="selectCustomer(customer)"
		class="card mat-elevation-z0 flex flex-align-center-space-between"
		[ngClass]="
			(customer.dashboardData?.hasErrors && isDashboard ? 'hasErrors' : '') ||
			(payMethodsErrorCount.includes(customer.id) ? 'hasErrors' : '')
		"
		*ngFor="let customer of customers"
	>
		<!-- Logo -->
		<div class="flex flex-row flex-align-start flex-1" style="--max-width: 40%">
			<div *ngIf="customer.imagePath" class="logo-container">
				<img src="{{ customer.imagePath }}" class="logo" />
			</div>

			<!-- Customer Information -->
			<div class="information-container">
				<!-- Title -->
				<div *ngIf="customer.name" class="information-title">{{ customer.name }}</div>

				<!-- Subtitle -->
				<div class="information-subtitle">
					<div *ngIf="customer.externalId" class="information-subtitle-text">
						{{ customer.externalId }}
					</div>
				</div>
			</div>
		</div>

		<div
			fxLayout="row"
			fxFlex="60"
			fxLayoutGap="60px"
			*ngIf="isDashboard && customer.dashboardData"
			class="paygroups"
			fxLayoutAlign="end flex-end"
		>
			<div
				*ngIf="
					customer.dashboardData.statusPayGroups.LIVE &&
					customer.dashboardData.statusPayGroups.LIVE.length > 0
				"
				fxLayoutAlign="center flex-start"
				fxLayout="column"
				fxLayoutGap="15px"
			>
				<div fxLayout="row" fxFlexAlign="space-between center" fxLayoutGap="6px">
					<span class="dot live"></span>
					<span>LIVE</span>
				</div>
				<div fxLayout="row">
					<span>{{ customer.dashboardData.statusPayGroups.LIVE?.length }} pay groups</span>
				</div>
			</div>

			<div
				fxLayout="column"
				fxLayoutAlign="center flex-start"
				class="implementation-container"
				fxLayoutGap="15px"
			>
				<div
					fxLayout="row"
					fxFlexAlign="space-between center"
					fxLayoutGap="6px"
					*ngIf="
						customer.dashboardData.statusPayGroups.IMPLEMENTATION &&
						customer.dashboardData.statusPayGroups.IMPLEMENTATION.length > 0
					"
				>
					<span class="dot implemented"></span>
					<span>IMPLEMENTATION</span>
				</div>
				<div
					fxLayout="row"
					*ngIf="
						customer.dashboardData.statusPayGroups.IMPLEMENTATION &&
						customer.dashboardData.statusPayGroups.IMPLEMENTATION.length > 0
					"
				>
					<span>{{ customer.dashboardData.statusPayGroups.IMPLEMENTATION?.length }} pay groups</span>
				</div>
			</div>
		</div>
		<div></div>
	</mat-card>
</ng-container>

<app-pagination
	*ngIf="totalPageCount && !fetchingCustomers"
	[length]="totalPageCount"
	[pageSizeOptions]="[5, 10, 25, 100]"
	[pageSize]="pageSize"
	[pageIndex]="pageIndex"
	[paginationType]="paginationType"
	aria-label="Select page"
	class="customer-paginator"
>
</app-pagination>

<!-- If there are not items -->
<ng-template #noItems>
	<no-items-layout *ngIf="!fetchingCustomers" message="No customers found"></no-items-layout>
	<div class="pulsate" *ngIf="fetchingCustomers">Fetching customers...</div>
</ng-template>
