import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SlidingPanelComponent } from "./sliding-panel.component";

@NgModule({
	declarations: [SlidingPanelComponent],
	imports: [CommonModule],
	exports: [SlidingPanelComponent]
})
export class SlidingPanelModule {}
