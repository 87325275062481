import { CalendarMonthSelectAction, CalendarMonthSelectActionType } from "../actions/payCycleSelect.action";
import { Action } from "@ngrx/store";
import { CalendarStore, CalendarStoreSelect } from "src/app/shared/models/calendar-navigation";
import { createRehydrateReducer } from "./state-persistance";

const initialState: CalendarStoreSelect = { selectedMonth: {} as CalendarStore };

const localStorageKey = "PaycycleSelectedSelectState";

export const calendarMonthSelectReducerFn = (state = initialState, action: Action): CalendarStoreSelect => {
	const selectAction = action as CalendarMonthSelectAction;

	switch (selectAction.type) {
		case CalendarMonthSelectActionType.SET_SELECTED:
			return {
				...state,
				selectedMonth: selectAction.payload.selectedMonth
			};

		default:
			return state;
	}
};

export const calendarMonthSelectReducer = createRehydrateReducer<CalendarStoreSelect, Action>(
	localStorageKey,
	calendarMonthSelectReducerFn
);
