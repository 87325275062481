import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { Router } from "@angular/router";

import { SelectOption } from "src/app/shared/models/select-option.interface";
import { FundSource } from "src/app/shared/models/source-of-funds.interface";
import { TPPServiceDefinitionState } from "src/app/shared/models/tpp-service.interface";
import { TppGroupConfigService } from "../../../_services/tpp-group-config/tpp-group-config.service";
import { TppGroupConfigurationStore } from "../../../_services/store/tpp-group-configuration.service";
import { PermissionsService } from "@shared/services/permissions/permissions.service";

@Component({
	selector: "app-tpp-service-settlement-account",
	templateUrl: "./tpp-service-settlement-account.component.html",
	styleUrls: ["./tpp-service-settlement-account.component.scss"]
})
export class TppServiceSettlementAccountComponent implements OnInit {
	@Output() openSettlementAccount: EventEmitter<{
		open: boolean;
		edit: boolean;
		provider: string;
	}> = new EventEmitter<{
		open: boolean;
		edit: boolean;
		provider: string;
	}>();

	@Output() returnToSourceAccount: EventEmitter<{ lastPage: boolean; group: string }> = new EventEmitter<{
		lastPage: boolean;
		group: string;
	}>();

	@Output() resetAvailableAccounts: EventEmitter<{ providerCountryId: string; currency: string }> = new EventEmitter<{
		providerCountryId: string;
		currency: string;
	}>();

	@Output() resetPaymentCurrencies = new EventEmitter<string>();
	@Output() providerSelected = new EventEmitter<string>();
	@Output() settlementAccountSelected = new EventEmitter<string>();

	serviceDefinition$: Observable<TPPServiceDefinitionState> = new Observable<TPPServiceDefinitionState>();
	@Input() payGroupId!: string;
	@Input() set serviceProvidersOptions(providers: SelectOption[]) {
		this._serviceProvidersOptions = providers;
	}
	_serviceProvidersOptions!: SelectOption[];

	@Input() set serviceProviderId(providerId: string) {
		this._serviceProviderId = providerId;
	}
	_serviceProviderId: string = "";
	@Input() set settelementAccountId(accountId: string) {
		this._settlementAccountId = accountId;
	}
	_settlementAccountId: string = "";
	private _paymentCurrencyOptions: SelectOption[] | undefined;

	@Input()
	set paymentCurrencyOptions(options: SelectOption[] | undefined) {
		this._paymentCurrencyOptions = options;
	}

	get paymentCurrencyOptions(): SelectOption[] | undefined {
		return this._paymentCurrencyOptions;
	}
	@Input() set groupToConfig(group: string) {
		this._groupToConfig = group;
	}
	_groupToConfig!: string;

	_settlementAccountOptions!: SelectOption[];

	@Input() set settlementAccountOptions(value: SelectOption[]) {
		this._settlementAccountOptions = value;
		this.checkSettlementAccountData();
	}

	get settlementAccountOptions(): SelectOption[] {
		return this._settlementAccountOptions;
	}

	selectedLegalEntityId: string = "";
	tppServicesText = "TPP Services";
	groupName: string = "GROUPING NAME";
	btnDisabled: boolean = false;
	formSettlement!: FormGroup;
	currenciesSelectOptions!: SelectOption[];

	sourcesOfFunds$!: Observable<FundSource[]>;
	fundingCurrency!: string;
	sourceAccount!: string;
	sourceAccountId!: string;
	resetPaymentCurrencyInput = true;
	resetSettlementAccountsInput = true;
	canEditSettlementAccountTPP = false;
	editSettlementAccountEnabled = false;
	isActive = true;

	selectedSettlementAccount: string = "";
	private destroy$: Subject<void> = new Subject<void>();

	constructor(
		private formbuilder: FormBuilder,
		private tppGroupConfigService: TppGroupConfigService,
		private permissions: PermissionsService,
		private tppGroupConfigStore: TppGroupConfigurationStore,
		private router: Router
	) {}

	ngOnInit(): void {
		this.permissions
			.canEditSettlementAccountTPP()
			.pipe(take(1))
			.subscribe(res => {
				this.canEditSettlementAccountTPP = res;
				this.serviceDefinition$ = this.tppGroupConfigStore
					.select(state => state)
					.pipe(takeUntil(this.destroy$));
				this.initForm();
				this.checkServiceData();

				if (this.formSettlement.get("settlementAccount")?.getRawValue()?.length) {
					this.editSettlementAccountEnabled = true;
				}
			});
	}

	initForm() {
		const group = this.tppGroupConfigStore.getConfigByGroupName(this._groupToConfig);

		this._settlementAccountId = group?.settlementAccountId ? group.settlementAccountId : "";
		this._serviceProviderId = group?.providerCountryId ? group.providerCountryId : "";
		this.formSettlement = this.formbuilder.group({
			serviceProvider: ["", Validators.required],
			defaultPaymentCurrency: ["", Validators.required],
			settlementAccount: ["", Validators.required]
		});

		if (!this.canEditSettlementAccountTPP) {
			this.formSettlement.disable();
		}

		this.formSettlement
			.get("serviceProvider")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe(value => {
				if (value) {
					this.tppGroupConfigStore.setProviderId(this._groupToConfig, value);

					this.formSettlement.get("defaultPaymentCurrency")?.reset();
					this.formSettlement.get("defaultPaymentCurrency")?.setValue(null);
					this.formSettlement.get("settlementAccount")?.reset();
					this.formSettlement.get("settlementAccount")?.setValue(null);
					this.paymentCurrencyOptions = [];
					this.settlementAccountOptions = [];
					this.tppGroupConfigStore.setGroupPaymentCurrency(this._groupToConfig, "");
					this.tppGroupConfigStore.setGroupSettlementAccountId(this._groupToConfig, "");

					this.providerSelected.emit(value);
					this.resetPaymentCurrencies.emit(value);

					this.resetPaymentCurrencyInput = true;
				}
			});

		this.formSettlement
			.get("defaultPaymentCurrency")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe(value => {
				if (value) {
					this.isActive = false;

					this.tppGroupConfigStore.setGroupPaymentCurrency(this._groupToConfig, value);
					this.formSettlement.get("settlementAccount")?.reset();
					this.formSettlement.get("settlementAccount")?.setValue(null);

					this.resetAvailableAccounts.emit({
						providerCountryId: this._serviceProviderId,
						currency: value
					});

					setTimeout(() => {
						this.isActive = true;
					}, 1000);

					this.resetSettlementAccountsInput = true;
				}
			});

		this.formSettlement
			.get("settlementAccount")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe(value => {
				if (value) {
					this.tppGroupConfigStore.setGroupSettlementAccountId(this._groupToConfig, value);
					const provider = this.formSettlement.get("serviceProvider")!.value;
					const currency = this.formSettlement.get("defaultPaymentCurrency")!.value;
					this.settlementAccountSelected.emit(value);
					this.editSettlementAccountEnabled = true;
					if (provider !== "" && currency !== "" && value !== "") {
						this._settlementAccountId = value;
					}
				}
			});
	}

	checkServiceData() {
		const config = this.tppGroupConfigStore.getConfigByGroupName(this._groupToConfig);

		if (config) {
			this.resetPaymentCurrencyInput = false;
			this.resetSettlementAccountsInput = false;

			if (config.providerCountryId.length && this._serviceProvidersOptions.length) {
				this.formSettlement.get("serviceProvider")?.setValue(
					this._serviceProvidersOptions.find(res => {
						return res.value === config.providerCountryId;
					})!.text,
					{ emitEvent: false }
				);
			}

			this.resetPaymentCurrencies.emit(config.providerCountryId);

			this.formSettlement.get("defaultPaymentCurrency")?.setValue(config.paymentCurrency);

			if (config.settlementAccountId.length && this._settlementAccountOptions.length) {
				this.formSettlement.get("settlementAccount")?.setValue(
					this._settlementAccountOptions.find(res => {
						return res.value === config.settlementAccountId;
					})!.text,
					{ emitEvent: false }
				);
				this.settelementAccountId = config.settlementAccountId;
			}
		}
	}

	checkSettlementAccountData(): void {
		const config = this.tppGroupConfigStore?.getConfigByGroupName(this._groupToConfig);

		if (config) {
			if (config.settlementAccountId.length && this._settlementAccountOptions.length) {
				this.formSettlement.get("settlementAccount")?.setValue(
					this._settlementAccountOptions.find(res => {
						return res.value === config.settlementAccountId;
					})!.text,
					{ emitEvent: false }
				);
				this.settelementAccountId = config.settlementAccountId;
			}
		}
	}

	addNewSettlementAccount(edit: boolean) {
		const providerId = this.formSettlement.get("serviceProvider")?.value;

		let providerNameIndex;

		providerNameIndex = this._serviceProvidersOptions.findIndex(res => {
			return res.value === providerId;
		});

		if (providerNameIndex === -1) {
			providerNameIndex = this._serviceProvidersOptions.findIndex(res => {
				return res.text === providerId;
			});
		}
		this.openSettlementAccount.emit({
			open: true,
			edit: edit,
			provider: this._serviceProvidersOptions[providerNameIndex].text
		});
	}

	previous() {
		this.returnToSourceAccount.emit({ lastPage: true, group: this._groupToConfig });
	}

	save() {
		if (this.canEditSettlementAccountTPP) {
			this.serviceDefinition$.subscribe(res => {
				this.tppGroupConfigService.saveServiceDefinition(res.serviceDefinitionConfig);
			});
		} else {
			this.router.navigate(["service-definition/tpp/select-service"]);
		}
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
