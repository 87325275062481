import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
	selector: "app-error-dialog",
	templateUrl: "./error-dialog.component.html",
	styleUrls: ["./error-dialog.component.scss"]
})
export class ErrorDialogComponent implements OnInit {
	Array = Array;
	error: Record<string, string> = {};

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { errors: Record<string, string>[] },
		public dialogRef: MatDialogRef<ErrorDialogComponent>
	) {
		this.checkErrorResponse();
	}

	ngOnInit(): void {}

	onCancel(): void {
		this.dialogRef.close();
	}

	checkErrorResponse(): void {
		if (!Array.isArray(this.data.errors)) {
			this.error = this.data.errors;
		}
	}
}
