import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CalendarEventsComponent } from "./calendar-events.component";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { InputAutocompleteModule } from "@shared/components/input-autocomplete/input-autocomplete.module";
import { InputWrapperModule } from "@shared/components/input-wrapper/input-wrapper.module";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { FlexLayoutModule } from "ngx-flexible-layout";
import { PaginationModule } from "@shared/components/pagination/pagination.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CustomerListModule } from "@shared/components/customer-list/customer-list.module";
import { InputSelectModule } from "@shared/components/input-select/input-select.module";
import { MatCardModule } from "@angular/material/card";
import { MatSelectModule } from "@angular/material/select";
import { CustomerLegalEntitySelectModule } from "@shared/components/customer-legal-entity-select/customer-legal-entity-select.module";
import { MatListModule } from "@angular/material/list";
import { MatPaginatorModule } from "@angular/material/paginator";
import { CalendarModule } from "@shared/components/calendar/calendar.module";
import { ModalWrapperModule } from "@shared/components/modal-wrapper/modal-wrapper.module";
import { MatTooltipModule } from "@angular/material/tooltip";
import { PipesModule } from "src/app/shared/pipes/pipes.module";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { SharedModule } from "@shared/shared.module";
import { NoItemsLayoutModule } from "@shared/components/no-items-layout/no-items-layout.module";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { TooltipModule } from "@shared/components/tooltip/tooltip.module";
import { Iso3CountryToCurrencySymbolPipe } from "@modules/dashboard/pipes/iso3-country-to-currency-symbol.pipe";
import { MilestoneStatusDropdownComponent } from "src/app/standalone/milestone-status-dropdown/milestone-status-dropdown.component";

@NgModule({
	declarations: [CalendarEventsComponent],
	imports: [
		CommonModule,
		MatIconModule,
		MatButtonModule,
		InputAutocompleteModule,
		InputWrapperModule,
		MatFormFieldModule,
		MatInputModule,
		MatMenuModule,
		FlexLayoutModule,
		PaginationModule,
		FormsModule,
		ReactiveFormsModule,
		CustomerListModule,
		InputSelectModule,
		MatCardModule,
		MatListModule,
		MatSelectModule,
		CustomerLegalEntitySelectModule,
		MatPaginatorModule,
		CalendarModule,
		ModalWrapperModule,
		MatTooltipModule,
		PipesModule,
		MatCheckboxModule,
		SharedModule,
		NoItemsLayoutModule,
		MatSlideToggleModule,
		TooltipModule,
		PipesModule,
		Iso3CountryToCurrencySymbolPipe,
		MilestoneStatusDropdownComponent
	],
	exports: [CalendarEventsComponent]
})
export class CalendarEventsModule {}
