import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { map, take, takeUntil } from "rxjs/operators";
import { ServiceProviderService } from "@shared/services/service-provider/service-provider.service";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { AccountField } from "src/app/shared/models/account-field.interface";
import generateLogoUtil from "src/app/shared/utils/generate-logo-util";
import { ImagesService } from "@shared/services/images/images.service";
import { ContactPerson } from "src/app/shared/models/contact-person.interface";
import { ContactPersonService } from "@shared/services/contact-person/contact-person.service";
import { ToastService } from "@shared/services/toast/toast.service";
import { CurrencyService } from "@shared/services/currency/currency.service";
import { CreateServiceProviderService } from "../../_services/create-service-provider/create-service-provider.service";

@Component({
	selector: "service-provider-creation",
	templateUrl: "./service-provider-creation.component.html",
	styleUrls: ["./service-provider-creation.component.scss"]
})
export class ServiceProviderCreationComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild("logoCanvas")
	myCanvas!: ElementRef<HTMLCanvasElement>;

	public context!: CanvasRenderingContext2D;

	addContactClicked$: Subject<void> = new Subject<void>();
	serviceProviderCreated$: Subject<boolean> = new Subject<boolean>();
	loading: boolean = false;

	availableProvidersOptions$: Observable<SelectOption[]> = new Observable<SelectOption[]>();

	providerSettingsForm!: FormGroup;
	settletmentAccountFormStatus!: boolean;
	currencyOptions$: Observable<SelectOption[]> = new Observable<SelectOption[]>();
	contactData: ContactPerson[] = [];
	contactFormisValid: boolean = true;

	destroy$: Subject<void> = new Subject();

	constructor(
		private readonly formBuilder: FormBuilder,
		private readonly serviceProviderService: ServiceProviderService,
		private readonly imageService: ImagesService,
		private readonly toastService: ToastService,
		private readonly contactPersonService: ContactPersonService,
		private readonly currencyService: CurrencyService,
		private readonly createServiceProviderService: CreateServiceProviderService
	) {}

	ngOnInit(): void {
		this.initProviderSettingsForm();
		this.currencyOptions$ = this.currencyService.getCurrencyOptions(true);
		this.availableProvidersOptions$ = this.serviceProviderService.getProvidersAsSelectOptions();
	}

	ngAfterViewInit(): void {
		this.context = this.myCanvas.nativeElement.getContext("2d")!;
	}

	private initProviderSettingsForm() {
		this.providerSettingsForm = this.formBuilder.group({
			fullName: [""],
			name: ["", Validators.required],
			currencies: [[], Validators.required],
			accountFields: [[]],
			logoId: [""]
		});

		this.providerSettingsForm
			.get("name")
			?.valueChanges.pipe()
			.subscribe(val => {
				this.availableProvidersOptions$.pipe(take(1)).subscribe(options => {
					console.log("options", options);
					console.log("selected value", val);

					options.filter(option =>
						option.value === val
							? this.providerSettingsForm.get("fullName")?.patchValue(option.text)
							: void 0
					);
				});
			});
	}

	saveProvider(): void {
		this.loading = true;
		const formData = this.providerSettingsForm.getRawValue();

		const serviceProvider = this.createServiceProviderService.getServiceProviderCreateDTO(formData);

		generateLogoUtil(serviceProvider.fullName, this.context).then(file => {
			this.imageService
				.createCustomerLogo(file)
				.pipe(take(1))
				.subscribe(logo => {
					serviceProvider.logoId = logo.id;
					this.serviceProviderService
						.createServiceProvider(serviceProvider)
						.pipe(takeUntil(this.destroy$))
						.subscribe(providerData => {
							let contactPersonObject = this.createServiceProviderService.contactPersonObject(
								providerData.id,
								this.contactData
							);
							this.contactPersonService
								.addContactPerson(contactPersonObject)
								.pipe(takeUntil(this.destroy$))
								.subscribe(() => {
									//service provider list component will be updated
									this.createServiceProviderService.providerAdded();
									this.toastService.showSuccess("Service Provider Has Been Created Successfully");
									this.loading = false;
								});
						});
				});
		});
	}

	cancelCreate() {
		this.createServiceProviderService.cancelCreate();
	}

	onAddContactClicked() {
		this.addContactClicked$.next();
	}

	contactFormData(event: { data: ContactPerson[]; valid: boolean }): void {
		this.providerSettingsForm.markAsDirty();
		this.contactFormisValid = event.valid;
		this.contactData = event.data;
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	settlementAccountForm(settlementAccountForm: FormGroup) {
		this.providerSettingsForm.get("accountFields")?.setValue(settlementAccountForm.get("accountFields")?.value);
		this.settletmentAccountFormStatus = settlementAccountForm.invalid;
	}
}
