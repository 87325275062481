<div class="dialog-header">
	<mat-icon>warning_amber</mat-icon>
	<h3>Warning</h3>
</div>
<div class="mat-dialog-content flex flex-column" mat-dialog-content>
	<p>Are you sure?</p>
	<br />
	<span>{{ displayText }}</span>
</div>
<div class="mat-dialog-actions flex flex-align-stretch-end" mat-dialog-actions>
	<button action-buttons mat-stroked-button (click)="onCancelClick()">{{ btnNoText }}</button>
	<button action-buttons color="dark" mat-flat-button (click)="onDeleteClick()" cdkFocusInitial>
		{{ btnYesText }}
	</button>
</div>
