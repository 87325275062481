import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ServiceDefinitionPage } from "./service-definition.page";
import { ServiceDefinitionEntryComponent } from "./_components/service-definition-entry/service-definition-entry.component";
import { TppSelectPaygroupComponent } from "./_components/tpp-select-paygroup/tpp-select-paygroup.component";
import { TppServiceSelectionComponent } from "./_components/tpp-service-selection/tpp-service-selection.component";
import { TppServiceAdhocServicesComponent } from "./_components/tpp-service-adhoc-services/tpp-service-adhoc-services.component";
import { TppServiceConfigurationComponent } from "./_components/tpp-service-configuration/tpp-service-configuration.component";
import { TppServiceConfigEditBeneficiaryComponent } from "./_components/tpp-service-configuration/tpp-service-config-edit-beneficiary/tpp-service-config-edit-beneficiary.component";
import { TppServiceGroupConfigComponent } from "./_components/tpp-service-group-config/tpp-service-group-config.component";
import { AddSourceOfFundComponent } from "./_components/tpp-service-group-config/add-source-of-fund/add-source-of-fund.component";

const routes: Routes = [
	{
		path: "",
		component: ServiceDefinitionPage
	},
	{
		path: "entity-select",
		component: ServiceDefinitionEntryComponent
	},
	{
		path: "select-paygroup",
		component: TppSelectPaygroupComponent
	},
	{
		path: "select-service",
		data: { breadCrumb: "TPP Service Definition" },
		component: TppServiceSelectionComponent
	},
	{
		path: "adhoc-service",
		data: { breadCrumb: "TPP Service Definition" },
		component: TppServiceAdhocServicesComponent
	},
	{
		path: "service-configuration",
		data: { breadCrumb: "TPP Service Definition" },
		component: TppServiceConfigurationComponent
	},
	{
		path: "service-configuration/edit-beneficiary",
		component: TppServiceConfigEditBeneficiaryComponent
	},
	{
		path: "group-configuration",
		data: { breadCrumb: "TPP Service Definition" },
		component: TppServiceGroupConfigComponent
	},
	{
		path: "add-new-source-of-fund",
		component: AddSourceOfFundComponent
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ServiceDefinitionRoutingModule {}
