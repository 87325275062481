import { BreakPoint } from "ngx-flexible-layout";

/**
 * Custom breakpoints for the application (in an Angular Material context), these DUPLICATE the breakpoints in the `_breakpoints.settings.scss` file.
 *
 * Prefer to import and use `_breakpoints.settings.scss` where possible.
 * @see breakpoints.settings.scss
 */
const CUSTOM_BREAKPOINTS: BreakPoint[] = [
	{
		alias: "xs",
		mediaQuery: "(max-width: 575px)"
	},
	{
		alias: "sm",
		mediaQuery: "(min-width: 576px) and (max-width: 767px)"
	},
	{
		alias: "md",
		mediaQuery: "(min-width: 768px) and (max-width: 991px)"
	},
	{
		alias: "lg",
		mediaQuery: "(min-width: 992px) and (max-width: 1199px)"
	},
	{
		alias: "xl",
		mediaQuery: "(min-width: 1200px) and (max-width: 1399px)"
	},
	{
		alias: "xxl",
		mediaQuery: "(min-width: 1400px) and (max-width: 1779px)"
	},
	{
		alias: "cloudpay-laptop",
		mediaQuery: "(min-width: 1880px)"
	}
];

export const getCustomBreakpoints = () => {
	return CUSTOM_BREAKPOINTS;
};
