import { Pipe, PipeTransform } from "@angular/core";
import { setFormatDate } from "src/app/shared/utils/date.util";

@Pipe({
	name: "suffixAndAbbreviatedDate"
})
export class SuffixAndAbbreviatedDatePipe implements PipeTransform {
	transform(value: Date | string): string {
		return this.formatDate(value);
	}

	private formatDate(date: Date | string): string {
		const formattedDate: Date = setFormatDate(date);
		let month: string = formattedDate.toLocaleDateString("US", { month: "long" });

		if (month.length > 5) {
			month = month.substring(0, 3);
		}

		const day: string = formattedDate.toLocaleDateString("US", { day: "numeric" });
		const hour: string = this.formatHour(formattedDate);

		return `${month[0].toUpperCase() + month.substring(1).toLowerCase()} ${this.formatDay(day)} / ${hour}`;
	}

	private formatHour(date: Date): string {
		let hours: number = date.getHours();
		let minutes: string | number = date.getMinutes();
		const ampm: string = hours >= 12 ? "PM" : "AM";

		hours = hours % 12;
		hours = hours ? hours : 12; // the hour '0' should be '12'
		hours = String(hours)[0] === "0" ? Number(String(hours)[1]) : hours; // Remove first 0

		minutes = minutes < 10 ? "0" + minutes : minutes;
		minutes = minutes === "00" ? "" : ":" + minutes; // 00 don't show

		return hours + minutes + " " + ampm;
	}

	private formatDay(day: string): string {
		switch (day) {
			case "1":
			case "21":
			case "31":
				return day + "st";
			case "2":
			case "22":
				return day + "nd";
			case "3":
			case "23":
				return day + "rd";
			default:
				return day + "th";
		}
	}
}
