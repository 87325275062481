import { Component, EventEmitter, Input, Output } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { PaginationOptions } from "@shared/constants/pagination";
import { SelectOption } from "@shared/models/select-option.interface";
import { PaginationService } from "./_services/pagination.service";
import { PaginationData } from "@store/models/pagination.model";

@Component({
	selector: "app-pagination",
	templateUrl: "./pagination.component.html",
	styleUrls: ["./pagination.component.scss"]
})
export class PaginationComponent {
	constructor(private paginationService: PaginationService) {}

	@Input() set length(value: number) {
		this.maxPages = value;
	}

	@Input() set pageSizeOptions(sizes: number[]) {
		sizes.forEach(size => {
			this.pageSizes.push({ text: size.toString(), value: size.toString() });
		});
	}

	@Input() set pageSize(value: number) {
		this.currentPageSize = value;
	}

	@Input() set pageIndex(value: number) {
		this.currentPage = value;
	}

	@Input() paginationType!: PaginationOptions;

	@Output() page: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();

	currentPageSize = 10;
	pageSizes: SelectOption[] = [];
	currentPage = 0;
	maxPages!: number;

	incrementPage() {
		this.currentPage++;
		this.updateObject();
	}

	decrementPage() {
		this.currentPage--;
		this.updateObject();
	}

	updatePageSize(pageSize: string) {
		this.currentPage = this.calcNewPageIndex(this.currentPageSize, parseInt(pageSize), this.currentPage);
		this.currentPageSize = parseInt(pageSize);
		this.updateObject();
	}

	calcNewPageIndex(currentSize: number, newSize: number, currentIndex: number): number {
		return Math.floor(currentIndex / (newSize / currentSize));
	}

	updateObject() {
		//Note: Workaround - this.page eventEimitter will fall away once we've implemented pagination state on Employee date

		if (this.paginationType) {
			const paginationSelection: PaginationData = {
				pageSize: this.currentPageSize,
				pageIndex: this.currentPage
			};

			this.paginationService.updatePaginationSate(this.paginationType, paginationSelection);
		} else {
			this.page.emit({
				length: this.maxPages,
				pageSize: this.currentPageSize,
				pageIndex: this.currentPage
			});
		}
	}

	getStringValue(numberValue: number): string {
		return numberValue ? numberValue.toString() : "";
	}
}
