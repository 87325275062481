import { Component, EventEmitter, OnInit, Output } from "@angular/core";

@Component({
	selector: "milestone-edition-popup",
	templateUrl: "./milestone-edition-popup.component.html",
	styleUrls: ["./milestone-edition-popup.component.scss"]
})
export class MilestoneEditionPopupComponent implements OnInit {
	recurring: "CURRENT" | "FOLLOWING" | "ALL" = "CURRENT";

	@Output() onSave: EventEmitter<"CURRENT" | "FOLLOWING" | "ALL"> = new EventEmitter<
		"CURRENT" | "FOLLOWING" | "ALL"
	>();

	constructor() {}

	ngOnInit(): void {}

	cancel() {
		this.onSave.emit("CURRENT");
	}

	save() {
		this.onSave.emit(this.recurring);
	}
}
