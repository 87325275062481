import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ExpansionPanelHeaderTitleComponent } from "./expansion-panel-header-title/expansion-panel-header-title.component";
import { ExpansionPanelSectionComponent } from "./expansion-panel-section/expansion-panel-section.component";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatExpansionModule } from "@angular/material/expansion";
import { SharedModule } from "@shared/shared.module";

@NgModule({
	declarations: [ExpansionPanelHeaderTitleComponent, ExpansionPanelSectionComponent],
	imports: [CommonModule, MatIconModule, MatButtonModule, SharedModule],
	exports: [ExpansionPanelHeaderTitleComponent, ExpansionPanelSectionComponent, MatExpansionModule]
})
export class ExpansionPanelModule {}
