<!-- Header -->
<div class="header" *ngIf="title || addContactButton">
	<div class="c-title-item" *ngIf="title">{{ title }}</div>

	<div class="button" *ngIf="addAccountFieldButton">
		<button
			[disabled]="addAccountFieldButtonDisabled"
			(click)="addAccountField()"
			mat-button
			class="c-button-dashed"
			color="primary"
		>
			<mat-icon>add</mat-icon>
			<span class="button-text">ADD FIELD</span>
		</button>
	</div>
</div>
<div class="contact-button" *ngIf="addContactButton">
	<ng-template [appUserRolePermissions] [action]="'hide'" [roles]="['TS_VO', 'CLIENT']">
		<button [disabled]="addContactButtonDisabled" (click)="addContact()" mat-button class="btn-new-contact">
			<app-icon
				id="add-contact-icon"
				[name]="'add'"
				[color]="'var(--color-primary-1000)'"
				[size]="'20px'"
			></app-icon>
			<span class="button-text">CREATE NEW CONTACT PERSON</span>
		</button>
	</ng-template>
</div>

<!-- Content container -->
<div class="content">
	<ng-content></ng-content>
</div>

<!-- Button container -->
<div class="buttons-container">
	<ng-content select="[action-buttons]"></ng-content>
</div>
