import { SelectOption } from "../model/SelectOption";

export function extractOptionValue(value: SelectOption | string, fieldName: string): string {
	if (typeof value == "string") {
		if (value === "ALL_OPTIONS") return "";

		return value;
	} else if (typeof value == "undefined") {
		return "";
	} else if (typeof value === "object") {
		if (value.value === "ALL_OPTIONS") return "";

		return value.value;
	}

	throw new Error(`Invalid value type for ${fieldName}: ${value}`);
}
