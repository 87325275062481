<div
	*ngIf="!imagePath"
	class="customer-logo-container"
	[class.hovering]="isHovering"
	appImageUpload
	(dropped)="onImageDrop($event)"
	(click)="fileUpload.click()"
	(hovered)="toggleHover($event)"
>
	<input
		type="file"
		class="file-input"
		accept="image/png, image/jpeg"
		(change)="onImageSelected($event)"
		#fileUpload
	/>
	<div class="text-container">
		<div class="img">
			<app-icon [name]="'gallery-add'" [size]="'40'" [color]="'var(--color-disabled-secondary)'"></app-icon>
		</div>
		<p>Drop your image here, or select</p>
		<p>click and browse.</p>
	</div>
</div>

<div *ngIf="imagePath" class="image-upload" (mouseover)="changeText = true" (mouseleave)="changeText = false">
	<img class="image" [src]="imagePath" />
	<div *ngIf="imagePath" [hidden]="!changeText" class="delete-img-button" (click)="onImageSelected($event)">
		<span class="material-icons">close</span>
	</div>
</div>
