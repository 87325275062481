<div class="file-list-item-container" [ngClass]="{ setOpacity: shouldBeFaded }">
	<div class="file-info-container" *ngIf="file.fileId">
		<span fxFlex="1"> <div class="placeholder"></div> </span>
		<span class="date column-text" fxFlex="13">{{ file.createdAt | date : "MMM dd y" }}</span>

		<span class="file-name column-text" [matTooltip]="file.fileName!" fxFlex="12">{{ file.fileName }}</span>

		<div class="subservices" fxFlex="22">
			<div class="subservice-container" *ngFor="let subservice of file.meta!.SERVICES; let i = index">
				<span class="first-half">
					<span class="subservice-text column-text block-border" *ngIf="i === 0">{{ subservice }}</span>
				</span>

				<span class="second-half" *ngIf="i === 1">
					<span class="subservice-text column-text block-border add-margin">{{ subservice }}</span>

					<span
						*ngIf="extraSubserviceCounter > 0"
						(mouseenter)="showTooltip = true"
						(mouseleave)="showTooltip = false"
						class="extra-subservices column-text block-border add-margin"
					>
						+{{ extraSubserviceCounter }}
					</span>

					<app-tooltip
						*ngIf="showTooltip"
						[containerBorder]="false"
						[position]="'right'"
						[containerPadding]="'10px 10px 10px 10px'"
						[paragraphStyle]="true"
						[trianlePosition]="'1rem'"
						[flexStyle]="true"
					>
						<span class="mattool-tip-content" *ngFor="let subInfo of extraSubserviceMatTooltip">
							{{ subInfo }}
						</span>
					</app-tooltip>
				</span>
			</div>
		</div>

		<span class="status column-text" fxFlex="12" [ngClass]="statusClass">{{ file.status }}</span>

		<span class="operators column-text" fxFlex="15">
			<app-icon
				*ngIf="file.status === 'FAILED'"
				[name]="'eye'"
				id="eyeIcon"
				[matTooltip]="'Detail'"
				(click)="viewFileErrors.emit(file.taskId)"
			></app-icon>

			<button class="download-btn" (click)="downloadFile.emit(downloadInfo)">
				<app-icon
					class="download-icon"
					[name]="'document-download'"
					[color]="'var(--color-filter-button)'"
					[lineWidth]="'2px'"
					[size]="'24'"
				></app-icon>
			</button>
		</span>
	</div>
	<div class="data-row run-reset" fxLayoutAlign="center center" *ngIf="file.userReset">
		<span class="run-reset-text" fxFlexAlign="center start"
			>RUN HAS BEEN RESET LAST {{ file.createdAt | date : "MMMM" | uppercase }}
			{{ file.createdAt | date : "d" }}
			<div class="position">{{ file.createdAt | date : "d" | datePosition }}</div>
			&nbsp;BY {{ file.userReset | userFullName | async | uppercase }}</span
		>
	</div>

	<div class="data-row run-reset" fxLayoutAlign="center center" *ngIf="file.userCancelled">
		<span class="run-reset-text" fxFlexAlign="center start"
			>RUN HAS BEEN CANCELLED LAST {{ file.createdAt | date : "MMMM" | uppercase }}
			{{ file.createdAt | date : "d" }}
			<div class="position">{{ file.createdAt | date : "d" | datePosition }}</div>
			&nbsp;BY {{ file.userCancelled | userFullName | async | uppercase }}</span
		>
	</div>
	<mat-divider class="row-divider"></mat-divider>
</div>
