import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "datePosition"
})
export class DatePositionPipe implements PipeTransform {
	transform(day: string | null): string {
		switch (day) {
			case "1":
			case "21":
			case "31":
				return "ST";
			case "2":
			case "22":
				return "ND";
			case "3":
			case "23":
				return "RD";
			default:
				return "TH";
		}
	}
}
