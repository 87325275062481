import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
	selector: "app-legal-entity-aml-history-dialog",
	templateUrl: "./legal-entity-aml-history-dialog.component.html",
	styleUrls: ["./legal-entity-aml-history-dialog.component.scss"]
})
export class LegalEntityAmlHistoryDialogComponent implements OnInit {
	legalInteityId!: string;

	constructor(
		public dialogRef: MatDialogRef<LegalEntityAmlHistoryDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	ngOnInit(): void {
		this.legalInteityId = this.data;
	}

	closeDialog(): void {
		this.dialogRef.close(false);
	}
}
