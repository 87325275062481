<app-breadcrumbs [headerTitle]="'EMPLOYEE DATA'" [addOverviewTitle]="canSeeMap" />

<div class="main-container">
	<div class="main-container" Layout="column">
		<div class="reference-search" [formGroup]="entitySelectForm">
			<input-wrapper class="select" label="LEGAL ENTITY">
				<input-autocomplete
					formControlName="legal"
					[options]="legalEntitiesSelectOptions"
					[label]="'All Legal Entities'"
				>
				</input-autocomplete>
			</input-wrapper>

			<input-wrapper class="select" label="SEARCH BY">
				<mat-form-field id="payGroupSearch" appearance="outline">
					<app-icon [name]="'search-normal-1'" matPrefix style="padding-right: 10px"></app-icon>
					<input formControlName="paygroup" matInput placeholder="Search by pay group name/ID" />
				</mat-form-field>
			</input-wrapper>
		</div>

		<div class="flex flex-column">
			<ng-container *ngIf="payGroupsList.length && !busy; else noPayGroups">
				<div class="list-container">
					<!-- If there are items -->
					<div class="paygroup-items-container">
						<div class="paygroup-items-header flex flex-row flex-align-center-start">
							<h3>PAYGROUPS LIST</h3>
						</div>
						<app-pay-elective-pay-group-list-items
							[paygroups]="payGroupsList"
							[selectedCustomerId]="customerId"
						>
						</app-pay-elective-pay-group-list-items>
					</div>
				</div>
				<app-pagination
					*ngIf="totalPageCount"
					[length]="totalPageCount"
					[pageSizeOptions]="[5, 10, 25, 100]"
					[pageSize]="pageSize"
					[pageIndex]="pageIndex"
					[paginationType]="paginationType"
					aria-label="Select page"
				>
				</app-pagination>
			</ng-container>
			<ng-template #noPayGroups>
				<no-items-layout
					*ngIf="!payGroupsList.length && !legalEntityIds.length && !queryString && !busy"
					message="No legal entities / pay groups associated with this customer."
					buttonText="Setup A Legal Entity"
					(onClickButton)="navigateToEntitySetup()"
				>
				</no-items-layout>

				<no-items-layout
					*ngIf="!payGroupsList.length && (legalEntityIds.length || queryString) && !busy"
					message="No legal entities / pay groups match your current filter."
				>
					<div *ngIf="busy" class="pulsate">Fetching paygroups...</div>
				</no-items-layout>
			</ng-template>
		</div>
	</div>
</div>
