import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { filter, flatMap, map, take, takeUntil } from "rxjs/operators";
import { ToastService } from "../../../../../../shared/services/toast/toast.service";
import { Employee } from "../../../../../../shared/models/employee.interface";
import { PayElectiveBankAccountsService } from "../../../../services/pay-elective-bank-accounts.service";
import { PayElectiveCommonService } from "../../../../services/pay-elective-common.service";
import { Method, PaymentType, PayMethodDetail } from "../../../../types/accounts";
import { PermissionsService } from "@shared/services/permissions/permissions.service";

@Component({
	selector: "app-pay-elective-accounts-list",
	templateUrl: "./pay-elective-accounts-list.component.html",
	styleUrls: ["./pay-elective-accounts-list.component.scss"]
})
export class PayElectiveAccountsListComponent implements OnInit, OnDestroy {
	@Input() payElective!: Employee;
	@Input() set providerForPayElective(val: string) {
		this.provider = val;
		if (!!this.payElective) {
			this.getEmployeesAccounts();
		}
	}

	@Output() sendUpdatedPayMethods: EventEmitter<Method[]> = new EventEmitter<Method[]>();

	provider: string = "";

	destroy$: Subject<void> = new Subject();

	net: PaymentType = PaymentType.NET;
	ewa: PaymentType = PaymentType.EWA;
	ttp: PaymentType = PaymentType.TPP;
	fallback: PaymentType = PaymentType.FALLBACK;

	payMethods$: Observable<PayMethodDetail[]> = new Observable<PayMethodDetail[]>();

	canReprocessPayMethods = false;

	constructor(
		private payElectiveBankAccountsService: PayElectiveBankAccountsService,
		private toastService: ToastService,
		private payElectiveService: PayElectiveCommonService,
		private permissions: PermissionsService
	) {}

	ngOnInit(): void {
		this.permissions
			.canReprocessPayMethods()
			.pipe(take(1))
			.subscribe(res => {
				this.canReprocessPayMethods = res;
				this.getEmployeesAccounts();
				this.initSubscriptions();
			});
	}

	getEmployeesAccounts() {
		this.payMethods$ = this.payElectiveBankAccountsService.getEmployeePaymentMethods(this.payElective.id!).pipe(
			takeUntil(this.destroy$),
			map((res: PayMethodDetail[]) => {
				res = res.filter(details => details.payMethod.status.name !== "INACTIVE");
				const details: PayMethodDetail[] =
					this.provider === "" || this.provider === "all"
						? res
						: res.filter(provider => provider.payMethod.providersStatuses.hasOwnProperty(this.provider));
				if (details) {
					let detailsList: Method[] = [];
					details.forEach(details => detailsList.push(details.payMethod));
					this.sendUpdatedPayMethods.emit(detailsList);
				}

				return details;
			})
		);
	}

	onSelectAccount(event: any, accountId: string) {
		if (event.checked) {
			this.payElectiveBankAccountsService.selectedAccounts$.emit(accountId);
		} else {
			this.payElectiveBankAccountsService.unSelectAccounts$.emit(accountId);
		}
	}

	checkHasAccounts(): boolean {
		return !!this.payElective.payMethods?.some(method => method.type.toString() === "BANK");
	}

	checkHasCards(): boolean {
		return !!this.payElective.payMethods?.some(method => method?.type.toString() === "CARD");
	}

	initSubscriptions(): void {
		this.payElectiveBankAccountsService.reProcessComplete$.pipe(takeUntil(this.destroy$)).subscribe(val => {
			this.toastService.showSuccess("Bank Account submitted for processing");
			this.getEmployeesAccounts();
		});
	}

	showHistory(payMethodId: string): void {
		this.payElectiveService.openHistoryDialog(payMethodId, this.provider);
	}

	reProcess(payMethodId: string) {
		this.payElectiveBankAccountsService.reProcessAccounts([payMethodId], this.provider);
	}

	isArray(value: string | string[]): boolean {
		return Array.isArray(value);
	}

	getArrayValue(value: string | string[]): string[] {
		if (this.isArray(value)) {
			return Array.isArray(value) ? value : [value];
		}
		return [];
	}

	getPaymentMethodStatus(method: PayMethodDetail): string {
		if (this.provider) {
			return method.payMethod.providersStatuses[this.provider].name;
		} else {
			return method.payMethod.status.name;
		}
	}

	ngOnDestroy(): void {
		//Called once, before the instance is destroyed.
		//Add 'implements OnDestroy' to the class.
		this.destroy$.next();
		this.destroy$.complete();
	}
}
