import { EventEmitter, Injectable } from "@angular/core";
import { AbstractControl, FormGroup } from "@angular/forms";
import { BehaviorSubject, Observable, ReplaySubject, Subject } from "rxjs";
import { TppServiceBeneficiaryDTO, TPPServiceDefinitionDTO } from "src/app/shared/models/tpp-service.interface";
import { TppApiServiceService } from "@modules/tpp-catalogue/_services/api/tpp-api-service.service";
import { ToastService } from "@shared/services/toast/toast.service";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { SettlementAccount, SettlementAccountPagination } from "src/app/shared/models/settlement-account.interface";
import { environment } from "src/environments/environment";
import { map, take } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import {
	SourceFoundAccount,
	SourceFoundAccountPagination
} from "@modules/service-definition/_models/source-accounts.interface";
import { TppServiceDefinitionService } from "@modules/service-definition/_services/tpp-service-definition/tpp-service-definition.service";

@Injectable({
	providedIn: "root"
})
export class TppCommonServiceService {
	setSettlementAccountOptions$ = new BehaviorSubject<SelectOption[]>([]);

	constructor(
		private tppApiService: TppApiServiceService,
		private toastService: ToastService,
		private router: Router,
		private readonly _location: Location,
		private http: HttpClient,
		private tppServiceDefinitionService: TppServiceDefinitionService
	) {}

	saveNewBeneficiary(
		formData: { account_name: string; country: string; currency: string },
		fields: Record<string, AbstractControl>,
		serviceId: string,
		isEdit?: boolean,
		subserviceId?: string,
		paygroupId?: string,
		accountId?: string,
		accountVersion?: string,
		benId?: string,
		benVersion?: string
	) {
		const rawFormData = formData;

		let data: TppServiceBeneficiaryDTO;

		if (isEdit) {
			data = {
				id: accountId,
				version: accountVersion,
				service: serviceId,
				country: rawFormData.country,
				name: rawFormData.account_name,
				currency: rawFormData.currency,

				bankAccount: {
					id: benId,
					version: benVersion,
					bankName: fields.bankName.value,
					country: rawFormData.country,
					currency: rawFormData.currency,
					fields: []
				}
			};
		} else {
			data = {
				service: serviceId,
				country: rawFormData.country,
				name: rawFormData.account_name,
				currency: rawFormData.currency,

				bankAccount: {
					bankName: fields.bankName.value,
					country: rawFormData.country,
					currency: rawFormData.currency,
					fields: []
				}
			};
		}

		Object.keys(fields).forEach(field => {
			if (
				field !== "bankName" &&
				field !== "account_name" &&
				field !== "country" &&
				field !== "currency" &&
				field !== "client_id" &&
				fields[field].value !== null &&
				fields[field].value !== undefined &&
				fields[field].value !== ""
			) {
				data.bankAccount.fields.push({
					key: field,
					value: fields[field].value
				});
			}
		});

		if (isEdit) {
			this.tppApiService.editTPPAccount(data).subscribe({
				next: _ => {
					this.toastService.showSuccess("Account updated successfully");

					//this.router.navigate(["dashboard/tpp-catalogue/add-new-service"]);
					this._location.back();
				},
				error: _ => {
					this.toastService.showError("Error updating your account, please try again");
				}
			});
		} else {
			this.tppApiService.createTPPAccount(data).subscribe({
				next: data => {
					this.toastService.showSuccess("Account created successfully");

					//this.router.navigate(["dashboard/tpp-catalogue/add-new-service"]);

					//save new val
					this.tppServiceDefinitionService
						.saveToDefinitionWithNewBeneficaryValue(
							data,
							subserviceId ? subserviceId : "",
							paygroupId ? paygroupId : ""
						)
						.subscribe(_ => {
							this._location.back();
						});
				},
				error: _ => {
					this.toastService.showError("Error updating your creating, please try again");
				}
			});
		}
	}

	searchSettlementAccountsByLegalEntityId(
		legalEntityId: string,
		currencies: string
	): Observable<SettlementAccount[]> {
		return this.http
			.get<SettlementAccountPagination>(
				`${environment.apiUri}/v1/accounts/settlement?legalEntityId=${legalEntityId}&currencies=${currencies}&pageSize=-1`
			)
			.pipe(
				//we don't need the pagination data so only return the items array from the object

				map((res: SettlementAccountPagination) => {
					return res.items;
				})
			);
	}

	searchSourceOfFunds(legalEntityId: string, currencies: string): Observable<SourceFoundAccount[]> {
		return this.http
			.get<SourceFoundAccountPagination>(
				`${environment.apiUri}/v1/accounts/source?legalEntityId=${legalEntityId}&currencies=${currencies}&paymentTypes=TPP`
			)
			.pipe(
				//we don't need the pagination data so only return the items array from the object
				map((res: SourceFoundAccountPagination) => {
					return res.items;
				})
			);
	}

	setSettlementAccountOptions(options: SelectOption[]) {
		this.setSettlementAccountOptions$.next(options);
	}

	editTPPServiceDefinition(formData: TPPServiceDefinitionDTO): Observable<TPPServiceDefinitionDTO> {
		return this.http.put<TPPServiceDefinitionDTO>(`${environment.apiUri}/v1/tpp-service-definitions`, formData);
	}
}
