<app-breadcrumbs [headerTitle]="'LEGAL ENTITIES'" />

<div class="main-container">
	<div class="filter-container">
		<ng-container *ngIf="customerId && legalEntitiesList && selectedCustomer">
			<button
				*ngIf="canCreateLegal"
				id="newLegalEntityCreation"
				mat-fab
				class="fab-button-style"
				(click)="createLegalEntity()"
			>
				<app-icon
					id="add-legal-icon"
					[name]="'add'"
					[color]="'white'"
					[size]="'30'"
					[lineWidth]="'2px'"
				></app-icon>
			</button>
		</ng-container>

		<!-- Legal entity form -->
		<ng-container id="customer-select">
			<div [formGroup]="entitySelectForm">
				<div class="customer-and-country-form-group">
					<input-wrapper class="select" label="COUNTRY">
						<input-autocomplete
							formControlName="legal_countries"
							[options]="selectOptionsCountries"
							[label]="'All countries'"
						>
						</input-autocomplete>
					</input-wrapper>

					<input-wrapper class="select" label="SEARCH BY">
						<mat-form-field id="payGroupSearch" appearance="outline">
							<app-icon [name]="'search-normal-1'" matPrefix style="padding-right: 10px"></app-icon>
							<input
								formControlName="legal_search"
								matInput
								placeholder="Search by legal entity name/ID"
							/>
						</mat-form-field>
					</input-wrapper>
				</div>
			</div>
		</ng-container>
	</div>

	<ng-container *ngIf="legalEntitiesList && !busy; else noItemsLayout">
		<ng-container *ngFor="let legalEntity of legalEntitiesList">
			<app-legal-entity-item
				id="legalEntities"
				[legalEntity]="legalEntity"
				(newLegalEntitySelectedEvent)="legalEntitySelected($event)"
			>
			</app-legal-entity-item>
		</ng-container>
	</ng-container>

	<!-- Create new legal entity when legal list is empty -->
	<ng-template #noItemsLayout>
		<span class="pulsate" *ngIf="busy">fetching entities...</span>
		<no-items-layout
			*ngIf="!totalPageCount && canCreateLegal && !busy"
			message="No created legal entities yet"
			buttonText="Create new legal entity"
			(onClickButton)="createLegalEntity()"
		>
		</no-items-layout>
		<no-items-layout *ngIf="!totalPageCount && !canCreateLegal && !busy" message="No created legal entities yet">
		</no-items-layout>
	</ng-template>

	<app-pagination
		*ngIf="totalPageCount"
		[length]="totalPageCount"
		[pageSizeOptions]="[5, 10, 25, 100]"
		[pageSize]="pageSize"
		[pageIndex]="pageIndex"
		[paginationType]="paginationType"
		aria-label="Select page"
	/>
</div>
