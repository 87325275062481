import { SelectOption } from "src/app/shared/models/select-option.interface";

export const REGIONS: SelectOption[] = [
	{
		value: "EMEA",
		text: "EMEA"
	},
	{
		value: "APAC",
		text: "APAC"
	},
	{
		value: "AMER",
		text: "AMER"
	}
];
