import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "fieldTypes"
})
export class FieldTypesPipe implements PipeTransform {
	transform(accessField: any): string {
		if (!accessField.secret) {
			switch (accessField.type) {
				case "STRING":
					return "text";
				case "INT":
					return "number";
				case "DOUBLE":
					return "number";
				case "DATE":
					return "string";
				case "DATETIME":
					return "string";
				default:
					return "text";
			}
		} else {
			return "password";
		}
	}
}
