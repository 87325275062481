import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { SelectOption } from "src/app/shared/models/select-option.interface";

@Component({
	selector: "app-input-select-tpp-group",
	templateUrl: "./input-select-tpp-group.component.html",
	styleUrls: ["./input-select-tpp-group.component.scss"]
})
export class InputSelectTppGroupComponent implements OnInit, OnDestroy {
	label: string = "Select a TPP group";
	control = new UntypedFormControl("");
	private destroy$: Subject<void> = new Subject<void>();
	@Output() tppGroupChose = new EventEmitter<string>();
	@Input() icon: string = "";
	_groupName: string = "";
	@Input() set groupName(val: string) {
		this._groupName = val;
	}
	_selectedPayGroupId: string = "";
	@Input() set selectedPayGroupId(val: string) {
		if (this._selectedPayGroupId !== val) {
			this.breadCrumbChangeFlag = false;
		}

		this._selectedPayGroupId = val;
	}
	_tppGroupOptionsSelected: SelectOption[] = [];
	@Input() set tppGroupOptionsSelected(optionsSelected: SelectOption[]) {
		this._tppGroupOptionsSelected = optionsSelected.length ? optionsSelected : [];

		// on service def and group options updated

		if (this._tppGroupOptionsSelected.length === 1 && !this.breadCrumbChangeFlag) {
			this.selectDefaultTppGroupOption();
			this.breadCrumbChangeFlag = true;
		}
		if (this._groupName === "" && this._tppGroupOptionsSelected.length > 1) {
			this.control.reset();
		}
	}
	optionChose: string = "";
	breadCrumbChangeFlag: boolean = false;
	constructor() {}

	ngOnInit(): void {
		if (this._groupName && this._groupName != "GROUPING NAME") {
			this.control.patchValue(this._groupName);
		}

		// on manual group change
		this.control.valueChanges?.pipe(takeUntil(this.destroy$)).subscribe(value => {
			this.optionChose = value;

			//this._tppGroupOptionsSelected.filter(item => {});
			const groupSelected = this._tppGroupOptionsSelected.find(item => item.text === this.optionChose);
			if (groupSelected) {
				this.tppGroupChose.emit(groupSelected.value);
			}
		});
	}

	selectDefaultTppGroupOption() {
		this._groupName = this._tppGroupOptionsSelected[0].value;
		this.control.patchValue(this._tppGroupOptionsSelected[0].text);
		this.tppGroupChose.emit(this._tppGroupOptionsSelected[0].value);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
