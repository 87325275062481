import { SelectOption } from "../models/select-option.interface";

export const FREQUENCIES: SelectOption[] = [
	{
		text: "Main run",
		value: "MAIN_RUN"
	},
	{
		text: "Per run",
		value: "PER_RUN"
	},
	{
		text: "Monthly",
		value: "MONTHLY"
	},
	{
		text: "Quarterly",
		value: "QUARTERLY"
	},
	{
		text: "Yearly",
		value: "YEARLY"
	}
];
