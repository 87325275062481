import { Component, Inject, Input, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { EmployeeError, FileReport } from "../../../../../shared/models/file-manager";
import { FormBuilder, FormGroup } from "@angular/forms";
import { map, shareReplay, tap } from "rxjs/operators";
import { PageEvent } from "@angular/material/paginator";
import { FileManagementService } from "../../../../../shared/services/file-management/file-management.service";

@Component({
	selector: "app-file-detail",
	templateUrl: "./file-detail.component.html",
	styleUrls: ["./file-detail.component.scss"]
})
export class FileDetailComponent implements OnInit {
	reportData$!: Observable<FileReport>;
	filteredEmployees$!: Observable<EmployeeError[]>;
	originalEmployeeArray: EmployeeError[] = [];

	@Input() taskId: string = "";

	pageSize: number = 10;
	pageIndex: number = 0;

	searchForm!: FormGroup;

	constructor(private fileManagementApiService: FileManagementService, private formBuilder: FormBuilder) {}

	ngOnInit(): void {
		this.reportData$ = this.getFilesTaks(this.pageSize, this.pageIndex);
		this.filteredEmployees$ = this.reportData$.pipe(
			map(res => {
				return res.employeeErrors;
			})
		);
		this.initForm();
	}

	initForm(): void {
		this.searchForm = this.formBuilder.group({
			search: [""]
		});

		this.searchForm
			.get("search")
			?.valueChanges.pipe()
			.subscribe(searchValue => {
				if (!!searchValue) {
					this.filteredEmployees$ = this.reportData$.pipe(
						map((result: FileReport) => {
							return result.employeeErrors.filter(employeeErrors => {
								if (
									employeeErrors.employee.firstName
										.toLowerCase()
										.includes(searchValue.toLowerCase()) ||
									employeeErrors.employee.lastName
										.toLowerCase()
										.includes(searchValue.toLowerCase()) ||
									employeeErrors.employee.externalId.toLowerCase().includes(searchValue.toLowerCase())
								) {
									return true;
								} else {
									return null;
								}
							});
						})
					);
				} else {
					this.filteredEmployees$ = this.reportData$.pipe(map(res => res.employeeErrors));
				}
			});
	}

	getFilesTaks(pageSize: number, pageIndex: number): Observable<FileReport> {
		return this.fileManagementApiService.getFileByTaskId(this.taskId, pageSize, pageIndex).pipe(
			tap(result => {
				this.originalEmployeeArray = result.employeeErrors;
			}),
			shareReplay()
		);
	}

	paginate(pagination: PageEvent) {
		this.pageIndex = pagination.pageIndex;
		this.pageSize = pagination.pageSize;
		this.filteredEmployees$ = this.getFilesTaks(pagination.pageSize, pagination.pageIndex).pipe(
			map(res => res.employeeErrors)
		);
	}
}
