import { Component, Input, OnInit } from "@angular/core";

export interface BadgeDefinition {
	groupNumber: number;
	containerClass: string;
	textClass: string;
}

@Component({
	selector: "app-ttp-group-badge",
	templateUrl: "./ttp-group-badge.component.html",
	styleUrls: ["./ttp-group-badge.component.scss"]
})
export class TtpGroupBadgeComponent implements OnInit {
	@Input() groupNumbers: number[] = [];

	badges: BadgeDefinition[] = [];
	toolTipText = "";

	constructor() {}

	ngOnInit(): void {
		this.groupNumbers.forEach(groupNumber => {
			this.badges.push(this.toBadge(groupNumber));
		});
		this.toBadgeToolTipText();
	}

	private toBadge(groupNumber: number): BadgeDefinition {
		var containerClass = "";
		var textClass = "";

		switch (groupNumber) {
			case 1:
				containerClass = "group-1-container";
				textClass = "group-1-text";
				break;
			case 2:
				containerClass = "group-2-container";
				textClass = "group-2-text";
				break;
			case 3:
				containerClass = "group-3-container";
				textClass = "group-3-text";
				break;
			case 4:
				containerClass = "group-4-container";
				textClass = "group-4-text";
				break;
		}

		return { groupNumber: groupNumber, containerClass: containerClass, textClass: textClass };
	}

	private toBadgeToolTipText() {
		// for each group number greater than 2 append
		// "and group X" to the tooltip text
		var text = "";
		for (var i = 2; i < this.groupNumbers.length; i++) {
			text += " Group TTP " + this.groupNumbers[i];
			if (i < this.groupNumbers.length - 1) {
				text += ", ";
			}
		}
		this.toolTipText = text;
	}
}
