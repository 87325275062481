<expansion-panel-section [title]="title" [lines]="false">
	<!-- Selector -->
	<div class="reference-search" [formGroup]="entitySelectForm">
		<!-- Country Selector -->
		<input-wrapper class="select" label="COUNTRY">
			<input-autocomplete
				formControlName="legal_countries"
				[options]="selectOptionsCountries"
				[label]="'All countries'"
			>
			</input-autocomplete>
		</input-wrapper>

		<input-wrapper class="select" label="SEARCH BY">
			<mat-form-field id="payGroupSearch" appearance="outline">
				<app-icon [name]="'search-normal-1'" matPrefix style="padding-right: 10px"></app-icon>
				<input formControlName="legal_search" matInput placeholder="Search by legal entity name/ID" />
			</mat-form-field>
		</input-wrapper>
	</div>

	<ng-container *ngIf="busy">
		<span class="pulsate">fetching entities...</span>
	</ng-container>

	<ng-container *ngFor="let legalEntity of legalEntitiesList">
		<app-legal-entity-item
			id="legalEntities"
			[legalEntity]="legalEntity"
			(newLegalEntitySelectedEvent)="selectLegalEntity($event)"
		>
		</app-legal-entity-item>
	</ng-container>

	<!-- No Legal Entities -->
	<ng-container *ngIf="legalEntitiesList.length == 0 && !busy">
		<mat-card>
			<div class="card-content" fxLayout="row" fxLayoutAlign="space-between center">
				<div class="text-container">
					<span class="no-legal-entity-text">No Legal Entities associated to customer yet</span>
				</div>
				<div class="link-container" fxLayout="row" fxLayoutAlign="center flex-end">
					<span class="entity-link" (click)="viewEntitiesClicked()" color="primary"
						>Go to Legal Entities</span
					>
				</div>
			</div>
		</mat-card>
	</ng-container>

	<ng-container *ngIf="legalEntitiesList.length === 0 && entitySelectForm.get('legal')?.value !== ''">
		<no-items-layout message="No legal entities match your current filter."> </no-items-layout>
	</ng-container>
</expansion-panel-section>

<app-pagination
	*ngIf="totalPageCount"
	[length]="totalPageCount"
	[pageSizeOptions]="[5, 10, 25, 100]"
	[pageSize]="pageSize"
	[pageIndex]="pageIndex"
	[paginationType]="paginationType"
	aria-label="Select page"
/>
