<div class="descriptionModal">
	<div id="descriptionModalHeader" class="panel-header">
		<app-icon [name]="'info-circle'" [size]="'28px'" [color]="'var(--color-white)'"></app-icon>
		<span class="title">
			{{ data.title }}
		</span>

		<app-icon id="closeIcon" size="60" name="close" color="#ffffff" lineWidth="1" (click)="close()"> </app-icon>
	</div>

	<div id="descriptionModalContent" class="content">
		{{ data.description }}
	</div>
</div>
