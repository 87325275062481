<modal-wrapper
	title="{{ this.bankName === '' ? 'Add' : 'Edit' }} Settlement Account"
	[modalStyle]="'dark'"
	(onTitleButtonAction)="closeSettlementAccount()"
	[noBreadCrumbs]="true"
	[exitIconBool]="true"
	[subHeaderColour]="'transparent'"
>
	<div id="settlementAccountPageContainer" fxLayout="column" fxLayoutGap="15px grid" *ngIf="ready">
		<div id="genericFieldsContainer" class="generic-fields-container" fxLayout="column" [formGroup]="globalForm">
			<div id="genericFieldsContainerChild" fxLayout="row" class="generic-inputs-row" fxLayoutGap="60px">
				<input-wrapper [label]="'ACCOUNT NAME *'">
					<input
						id="accountNameInput"
						type="text"
						id="accountName"
						placeholder="Enter account client"
						formControlName="accountName"
					/>
				</input-wrapper>

				<input-wrapper class="select" label="SELECT A COUNTRY *">
					<input-autocomplete
						id="countrySelectInputs"
						formControlName="country"
						[options]="countryOptions"
						[label]="'Select country'"
						[defaultValue]="
							this.isSettlementEdit
								? this.selectedSettlementAccount!.bankAccount.country
								: this.selectedCountryCode
						"
						[disabled]="readonly || isSettlementEdit"
					>
					</input-autocomplete>
				</input-wrapper>

				<input-wrapper class="select" label="SETTLEMENT CURRENCY *">
					<input-autocomplete
						id="currencySelectInput"
						formControlName="currency"
						[options]="currencies$ | async"
						[label]="'Select currency'"
						[disabled]="readonly"
					>
					</input-autocomplete>
					<div input-errors *ngIf="!globalForm.get('currency')?.value">Currency is required</div>
				</input-wrapper>
			</div>
		</div>

		<!-- Separator -->

		<!-- ACCESS FIELDS -->
		<ng-container *ngIf="accessFields && accessFields.length > 0">
			<div
				id="accessFieldsContainer"
				class="bank-account-fields-container"
				fxLayout="column"
				[formGroup]="accessFieldsForm"
			>
				<div id="accessFiledsTitle" class="form-section-title">Access fields</div>

				<div id="accessFieldsContainer" class="o-grid-3 full-width">
					<ng-container>
						<input-wrapper
							*ngFor="let accessField of accessFields; let i = index"
							[label]="accessField.label"
						>
							<input
								id="{{ accessField.key }}"
								[type]="accessField | fieldTypes"
								[placeholder]="accessField.name"
								[formControlName]="accessField.key"
							/>
							<div
								id="accessFieldErrorContainer"
								input-errors
								*ngIf="
									accessFieldsForm.controls[accessField.key].touched &&
									accessFieldsForm.controls[accessField.key].errors?.required
								"
							>
								{{ accessField.name }} is required
							</div>
						</input-wrapper>
					</ng-container>
				</div>
			</div>
		</ng-container>

		<!-- BANK ACCOUNT FIELDS -->

		<div id="bankAccountFieldsContainer" class="bank-account-fields-container" fxLayout="column">
			<bank-account-fields
				class="account-fields"
				[country]="selectedCountryCode"
				[bankName]="bankName"
				[values]="bankAccountValues"
				[editing]="isSettlementEdit"
				[canEdit]="canEditSettlementAccounts"
				*ngIf="selectedCountryCode"
			></bank-account-fields>
		</div>
		<div
			id="buttonsContainer"
			*ngIf="selectedCountryCode"
			fxLayout="row"
			class="generic-inputs-row"
			fxLayoutAlign="end center"
		>
			<button id="cancelButton" mat-stroked-button class="button-start" (click)="closeSettlementAccount()">
				Cancel
			</button>
			<button
				id="saveBtn"
				*ngIf="globalForm && accessFieldsForm && bankAccountFieldsComponent?.bankAccountForm"
				[disabled]="
					globalForm.invalid ||
					accessFieldsForm.invalid ||
					bankAccountFieldsComponent?.bankAccountForm?.invalid
				"
				mat-button
				[ngClass]="
					globalForm.invalid ||
					accessFieldsForm.invalid ||
					bankAccountFieldsComponent?.bankAccountForm?.invalid
						? 'save-button disabled'
						: 'save-button'
				"
				(click)="save()"
				fxFlex="300px"
			>
				<span class="save-text">Save</span>
			</button>
		</div>
	</div>
</modal-wrapper>
