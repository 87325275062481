import { Pipe, PipeTransform } from "@angular/core";
import getSymbolFromCurrency from "currency-symbol-map";

@Pipe({
	name: "currencySymbol"
})
export class CurrencySymbolPipe implements PipeTransform {
	transform(value: string): string {
		const symbol = getSymbolFromCurrency(value);

		return symbol!;
	}
}
