<table class="table" mat-table [dataSource]="(errors | async)!">
	<ng-container matColumnDef="errorSystem">
		<th mat-header-cell *matHeaderCellDef>SYSTEM ERROR</th>
		<td [ngClass]="{ highlight: !element.outputMessage }" mat-cell *matCellDef="let element">
			<div class="input-message">
				<span class="input-message-text">{{ element.inputMessage }}</span>
				<app-icon
					class="input-message-icon"
					[name]="'eye'"
					[size]="'20'"
					(click)="openInputDialog(element)"
				></app-icon>
			</div>
		</td>
	</ng-container>

	<!-- provider Column -->
	<ng-container matColumnDef="serviceProvider">
		<th mat-header-cell *matHeaderCellDef>Service Provider</th>
		<td mat-cell *matCellDef="let element">{{ element.providerName }}</td>
	</ng-container>

	<!-- error Column -->
	<ng-container matColumnDef="errorType">
		<th mat-header-cell *matHeaderCellDef>Error Type</th>
		<td mat-cell *matCellDef="let element">
			<div class="error-type" *ngIf="updatingElement !== element.id">
				<span>{{ element.errorType }}</span>
				<app-icon
					class="error-type-icon"
					[name]="'edit-2'"
					[size]="'20'"
					*ngIf="canEditError"
					(click)="updateErrorTag(element)"
				></app-icon>
			</div>
			<div *ngIf="showInput && updatingElement === element.id">
				<div class="input-error" [formGroup]="form">
					<input-autocomplete
						(inputTextChange)="checkText($event)"
						formControlName="errorType"
						[label]="'Error Type'"
						[options]="errorTypes | async"
					>
					</input-autocomplete>
				</div>
			</div>
		</td>
	</ng-container>

	<!-- message column -->
	<ng-container matColumnDef="wpayMessage">
		<th mat-header-cell *matHeaderCellDef>WPAY Message</th>
		<td mat-cell *matCellDef="let element">
			<div class="output-message">
				<span class="output-message-text">{{ element.outputMessage }}</span>
				<app-icon
					*ngIf="canEditError"
					class="output-message-icon"
					[name]="'edit-2'"
					[size]="'20'"
					(click)="openOutputDialog(element)"
				></app-icon>
			</div>
		</td>
	</ng-container>

	<tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
	<tr class="make-visible" mat-row *matRowDef="let row; columns: tableColumns"></tr>
</table>

<!-- <div>{{ errors | async | json }}</div> -->
