import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { take } from "rxjs/operators";
import { SettlementAccountService } from "@modules/service-definition/_services/settlement-account/settlement-account.service";

@Component({
	selector: "access-fields",
	templateUrl: "./access-fields.component.html",
	styleUrls: ["./access-fields.component.scss"]
})
export class AccessFieldsComponent implements OnInit {
	@Input() selectedRoute: any;
	@Input() values: Record<string, string | number | boolean> | undefined;
	@Input() canEdit: boolean = false;

	public accessFields: Array<any> = [];
	public accessFieldsForm!: FormGroup;

	constructor(private formBuilder: FormBuilder, private settlementAccountService: SettlementAccountService) {}

	ngOnInit(): void {
		this.initializeForm();
	}

	initializeForm(): void {
		console.log("ACCOUNTS", this.canEdit);
		this.accessFieldsForm = this.formBuilder.group({});

		if (this.selectedRoute && this.selectedRoute.providerCountryId) {
			this.settlementAccountService
				.getProviderAccountDetails(this.selectedRoute.providerCountryId)
				.pipe(take(1))
				.subscribe((fields: { key: string; name: string; required: boolean; type: string }[]) => {
					fields.map((field: any) => {
						// Format fields
						const lower = field.name.toLowerCase();
						field.name = field.name.charAt(0).toUpperCase() + lower.slice(1);
						field.label = field.required ? field.name.toUpperCase() + " *" : field.name.toUpperCase();

						// Set up form control
						const value: any | null = this.values ? this.values[field.key] : null;
						this.accessFieldsForm.addControl(
							field.key,
							new FormControl({ value, disabled: !this.canEdit }, this.getFieldValidators(field))
						);
					});

					this.accessFields = fields;
				});
		}
		// if (!this.canEdit) {
		// 	this.accessFieldsForm.get(field.key)?.disable();
		// }
	}

	getFieldValidators(field: any): ValidatorFn[] {
		let validators: ValidatorFn[] = [];

		if (field.required) {
			validators.push(Validators.required);
		}

		return validators;
	}

	getAccessFields(): { key: string; value: string }[] | undefined {
		return Object.keys(this.accessFieldsForm.controls).map((field: any) => {
			return {
				key: field,
				value: this.accessFieldsForm.controls[field].value
			};
		});
	}
}
