import { Component, Input, OnInit } from "@angular/core";
import { forkJoin, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { PayElectiveBankAccountsService } from "@modules/employee-data/services/pay-elective-bank-accounts.service";
import { PayElectiveCommonService } from "@modules/employee-data/services/pay-elective-common.service";
import { PayElectiveTransactionsService } from "@modules/employee-data/services/pay-elective-transactions.service";
import { PayMethodDetail, TransactionData } from "@modules/employee-data/types/accounts";

import { PayoutsService } from "@shared/services/payouts/payouts.service";
import { Payout, Transaction, TransactionPagination } from "src/app/shared/models/payouts.types";

@Component({
	selector: "app-pay-elective-transaction-list",
	templateUrl: "./pay-elective-transaction-list.component.html",
	styleUrls: ["./pay-elective-transaction-list.component.scss"]
})
export class PayElectiveTransactionListComponent implements OnInit {
	@Input() payout!: Payout;
	dataSourceTransaction!: TransactionData[];
	enableTooltip: boolean = false;
	displayedColumns: string[] = ["date", "paymentMethod", "amount", "type"];
	amountList: number[] = [];

	constructor(
		private payoutsService: PayoutsService,
		private payElectiveBankAccountsService: PayElectiveBankAccountsService,
		private payElectiveCommonService: PayElectiveCommonService,
		private payElectiveTransactionService: PayElectiveTransactionsService
	) {}

	ngOnInit(): void {
		this.getTransactionData(this.payout.id);
	}

	getTransactionsByPayoutId(payoutId: string): Observable<Transaction[]> {
		return this.payoutsService.searchPayoutsTransactions(payoutId).pipe(
			map((response: TransactionPagination) => {
				return response.items;
			})
		);
	}

	getEmployeesAccounts(): Observable<PayMethodDetail[]> {
		return this.payElectiveBankAccountsService.getEmployeePaymentMethods(this.payout.employee.id!);
	}

	getTransactionData(payoutId: string) {
		forkJoin([this.getTransactionsByPayoutId(payoutId), this.getEmployeesAccounts()])
			.pipe(
				map((data: [Transaction[], PayMethodDetail[]]) => {
					const dataSourceTransaction: TransactionData[] = [];
					const tranasctions: Transaction[] = this.payElectiveCommonService.filterTransactions(data[0]);
					const employeeAccounts: PayMethodDetail[] = data[1];
					tranasctions.forEach((transaction: Transaction) => {
						const employeeAccount = employeeAccounts.find(
							(employeeAccount: PayMethodDetail) =>
								employeeAccount.payMethod.id === transaction.payMethodId
						);

						dataSourceTransaction.push({
							date: transaction.executeAt.slice(0, transaction.executeAt.indexOf("T")),
							paymentMethod: employeeAccount?.details.bankName ? employeeAccount.details.bankName : " ",
							paymentMethodType: employeeAccount?.payMethod.type ? employeeAccount?.payMethod.type : " ",
							amount: transaction.amount.value,
							currency: transaction.amount.currency,
							ranking: employeeAccount?.payMethod.ranking
								? employeeAccount?.payMethod.ranking
								: { type: "", amount: 0, rank: 0 },
							type: transaction.type,
							payoutAmount: this.payout.amount.value,
							status: transaction.status
						});
					});
					return dataSourceTransaction;
				})
			)
			.subscribe((res: TransactionData[]) => {
				res.sort((a, b) => {
					return a.ranking.rank - b.ranking.rank;
				});
				this.dataSourceTransaction = res;
				this.dataSourceTransaction.forEach(transaction => {
					this.amountList.push(transaction.amount);
				});
			});
	}

	isTransactionFailedOrUnableToDetermine(status: string) {
		return (
			this.payElectiveTransactionService.transactionStatusIsFailure(status) ||
			this.payElectiveTransactionService.unsupportedTransactionStatus(status)
		);
	}
}
