<app-breadcrumbs
	[headerTitle]="'SERVICE PROVIDERS'"
	[externalCrumbs]="[
		{ crumb: this.providerName, dropDownData: providersDropDownData, url: '1' },
		{ crumb: this.countryName, flag: this.countryCode, dropDownData: countriesDropDownData }
	]"
	(onRoutingCrumbClicked)="routingCrumbClicked($event)"
	(onExternalDropDownCrumbItemSelected)="externalCrumbDropDownSelection($event)"
/>

<div class="main-container">
	<mat-stepper class="stepper" linear #stepper>
		<mat-step color="primary" [stepControl]="firstFormGroup" [editable]="true">
			<ng-template matStepLabel><span>Service capabilities</span></ng-template>

			<mat-divider class="line"></mat-divider>

			<h2 class="header-text-label">Service capabilities</h2>

			<app-service-provider-capabilities
				[providerId]="providerId"
				[countryCode]="countryCode"
				*ngIf="hasCapabilities()"
			></app-service-provider-capabilities>
			<div class="actions">
				<button id="cancelBtn" class="cancel-btn" (click)="goBack()" action-buttons mat-stroked-button>
					Cancel
				</button>
				<button
					id="nextBtn"
					action-buttons
					mat-flat-button
					[disabled]="
						!(serviceProviderCountryCreateService.currencyFormState$ | async) ||
						!(serviceProviderCountryCreateService.capabilityFormState$ | async)
					"
					(click)="nextStep(stepper)"
					color="dark"
				>
					Next
				</button>
			</div>
		</mat-step>
		<mat-step [stepControl]="secondFormGroup" [editable]="isEditable">
			<ng-template color="#ff3d71" matStepLabel>
				<span>Service Providers Cost</span>
			</ng-template>

			<div class="breadcrumb">
				<mat-icon>home_outline</mat-icon>&nbsp; {{ providerName }}&nbsp;&nbsp; <span>></span>&nbsp;&nbsp;
				<span class="flag-wrapper"
					><img
						class="flag"
						src="assets/svg-country-flags/svg/{{ countryCode | lowercase }}.svg"
						alt="Country Flag"
					/>&nbsp; {{ countryName }}</span
				>
			</div>

			<mat-divider class="line"></mat-divider>

			<h2 class="header-text-label">Headcount configuration</h2>

			<app-headcount-configuration></app-headcount-configuration>

			<h2 class="header-text-label">Service providers cost</h2>

			<app-service-provider-costs></app-service-provider-costs>
			<div class="actions">
				<button id="previousBtn" class="cancel-btn" action-buttons mat-stroked-button matStepperPrevious>
					Previous
				</button>
				<button
					id="saveBtn"
					action-buttons
					mat-flat-button
					[disabled]="
						!(serviceProviderCountryCreateService.currencyFormState$ | async) ||
						!(serviceProviderCountryCreateService.capabilityFormState$ | async) ||
						!(serviceProviderCountryCreateService.costFormState$ | async)
					"
					(click)="createCountry()"
					color="dark"
				>
					Save
				</button>
			</div>
		</mat-step>
	</mat-stepper>
</div>
