<form [formGroup]="providerSettingsForm">
	<div class="form-content flex flex-column flex-align-stretch-space-between">
		<h2 class="form-title form-title-gap">General Information</h2>
		<!-- Service Provider Configuration -->

		<div class="flex flex-row flex-align-center-space-between">
			<!-- Service provider full name -->
			<input-wrapper class="input-gap" label="Select Service Providers *">
				<input-autocomplete
					id="providers"
					formControlName="name"
					label="Select Provider"
					[options]="availableProvidersOptions$ | async"
					[required]="true"
					[multiple]="false"
					[disabled]="true"
				>
				</input-autocomplete>
				<div
					id="providers"
					input-errors
					*ngIf="
						providerSettingsForm.controls.name?.errors?.required &&
						providerSettingsForm.controls.name?.touched
					"
				>
					Please select a Service Provider
				</div>
			</input-wrapper>

			<!-- Available funding currency [options]="currencyOptions$ | async"-->
			<input-wrapper label="Available funding currencies *">
				<input-autocomplete
					id="currencies"
					formControlName="currencies"
					label="Select funding currency"
					[options]="currencyOptions$ | async"
					[required]="true"
					[multiple]="true"
					[disabled]="!isSuperUser"
				>
				</input-autocomplete>
				<div
					id="currencies"
					input-errors
					*ngIf="
						providerSettingsForm.controls.currencies?.errors?.required &&
						providerSettingsForm.controls.currencies?.touched
					"
				>
					At least one funding currency must be selected
				</div>
			</input-wrapper>
		</div>
	</div>
	<!--Settlement Account Fields-->

	<settlement-account-fields
		*ngIf="accountFields"
		(removeField)="onRemoveField($event)"
		(settlementAccountForm)="settlementAccount($event)"
		[accessfields]="accountFields"
	></settlement-account-fields>

	<expansion-panel-section
		(addContactClicked)="onAddContactClicked()"
		[addContactButtonDisabled]="false"
		[addContactButton]="true"
		[title]="'Contact Person'"
		class="conact-persons separator-border"
	>
		<app-contacts
			*ngIf="existingContacts"
			(contactFormData)="contactFormData($event)"
			[addIsClicked$]="addContactClicked$"
			[contacts]="existingContacts"
		></app-contacts>

		<div class="buttons-container">
			<button id="cancel" class="cancel-btn" (click)="cancelCreate()" action-buttons mat-stroked-button>
				Cancel
			</button>
			<button
				id="submit"
				(click)="updateProvider()"
				action-buttons
				mat-flat-button
				[disabled]="
					providerSettingsForm.invalid ||
					settlementAccountForm.invalid ||
					providerSettingsForm.pristine ||
					!contactFormisValid ||
					!isSuperUser
				"
				color="dark"
			>
				Save
			</button>
		</div>
	</expansion-panel-section>
</form>
