import { Details } from "../payouts.types";

/*
 * This is the domain model for the TPP Service Definition page. which represents a list of services grouped by service type
 * for a given service provider.
 */
export interface TppServicesByByPayGroupViewModel {
	beneficiaryCounts: TppBeneficarysByPaygroupCount;
	servicesByServiceType: TtpServiceByServiceType[];
}

export interface TppBeneficarysByPaygroupCount {
	/*
	 * regardless of the beneficiary status, how many TPP beneficiaries are there.
	 */
	totalBeneficiaries: number;

	/**
	 * Aggregate count of all beneficiaries for this pay group that can be validated.
	 */
	totalBeneficiariesWhichCanBeValidated: number;
}

export interface TtpServiceByServiceType {
	serviceType: string;
	services: TtpService[];
}

export interface TtpService {
	id: string;
	name: string;
	description: string;
	isExpanded: boolean; // UI Attribute shouldn't be in the domain model!
	isAdhoc: boolean;
	ttpGroups: number[];
	subServiceSummary: SubServiceSummary;
	subService: SubService[];
}

export interface SubServiceSummary {
	/**
	 * The total number of beneficiaries in the sub services of this service.
	 * Aggreagate of all subService.beneficiaries.length
	 */
	totalSubServiceBeneficiaries: number;

	/**
	 * Aggregate count of all beneficiaries for this sub service that are invalid.
	 */
	totalFailedBeneficiaries: number;

	/**
	 * Aggregate count of all beneficiaries for this sub service that are processing.
	 */
	totalProcessingBeneficiaries: number;

	/**
	 * Aggregate count of all beneficiaries for this sub service that are valid
	 */
	totalValidBeneficiaries: number;

	/**
	 * Aggregate count of all beneficiaries for this sub service
	 * are considered to have a status which makes them candidates to be validated.
	 */
	totalBeneficiariesWhichCanBeValidated: number;

	/**
	 * Indictes if one or more sub-services for this service are missing a group / beneficiary configuration.
	 */
	oneOrMoreSubServicesMissingConfiguration: boolean;

	/**
	 * Total sub-services for this services that have either a group or beneficiary configured.
	 */
	totalPartiallyConfiguredSubServices: number;
}

export interface SubService {
	name: string;
	group: number;
	isConfigured: boolean; // does the sub-service have both a group and beneficiary configured?
	isPartiallyConfigured: boolean; // does the sub-service have a group or beneficiary configured?
	beneficiary: BeneficiaryAccountAssignment;
}

export interface BeneficiaryAccountAssignment {
	id: string;
	name: string;
	linkId: string;
	beneficiaryStatus: BeneficiaryStatus;
	providerName: string;
	settlementAccountId: string;
}

export interface BeneficiaryStatus {
	internalStatus: InternalStatus;
	displayStatus: DisplayStatus;
	details: Details | null;
}

export type InternalStatus =
	| "CREATED"
	| "ACTIVE"
	| "INACTIVE"
	| "REPROCESSED"
	| "ASSIGNED_TO_ANOTHER_PROVIDER"
	| "NO_PROVIDER_ASSIGNMENT";

export type DisplayStatus = "FAILED" | "SUCCESS" | "PROCESSING" | "UN_ASSIGNED";

export function mapInternalStatusToDisplayStatus(status: InternalStatus): DisplayStatus {
	switch (status) {
		case "ACTIVE":
			return "SUCCESS";

		case "INACTIVE":
			return "FAILED";

		case "ASSIGNED_TO_ANOTHER_PROVIDER":
		case "NO_PROVIDER_ASSIGNMENT":
			return "UN_ASSIGNED";

		case "CREATED":
			return "PROCESSING";

		default:
			console.warn("Unknown beneficiary status: " + status + ", assuming beneficiary is NOT VALID.");
			return "FAILED";
	}
}

/**
 * Can the beneficiary be sent for validation?
 */
export function isBenficiaryValidatable(status: InternalStatus): boolean {
	switch (status) {
		case "CREATED":
		case "ACTIVE":
		case "NO_PROVIDER_ASSIGNMENT":
		case "ASSIGNED_TO_ANOTHER_PROVIDER":
			return false;

		case "INACTIVE":
			return true;

		default:
			console.warn("Unknown beneficiary status: " + status + ", assuming beneficiary is NOT VALIDATEABLE.");
			return false;
	}
}

export function isBeneficiaryValid(status: InternalStatus): boolean {
	switch (status) {
		case "CREATED":
		case "ACTIVE":
		case "ASSIGNED_TO_ANOTHER_PROVIDER":
		case "NO_PROVIDER_ASSIGNMENT":
			return true;
		case "INACTIVE":
			return false;

		default:
			console.warn("Unknown beneficiary status: " + status + ", assuming beneficiary is NOT VALID.");
			return false;
	}
}
