import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import {
	TppAccount,
	TppAccountCreation,
	TppAccountsPagination,
	TppSearchAccoutns
} from "src/app/shared/models/tpp-account.interface";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: "root"
})
export class TppAccountsService {
	constructor(private http: HttpClient) {}

	searchTPPAccountsByTppServiceId(serviceId: string, pageNumber: number, pageSize: number): Observable<TppAccount[]> {
		return this.searchTPPAccounts([], [], serviceId, pageNumber, pageSize);
	}

	searchTPPAccounts(
		countries: string[],
		currencies: string[],
		services: string,
		pageNumber: number,
		pageSize: number
	): Observable<TppAccount[]> {
		let params = new HttpParams();
		params = params.append("countries", countries.toString());
		params = params.append("currencies", currencies.toString());
		params = params.append("services", services);
		params = params.append("pageSize", pageSize.toString());
		params = params.append("pageNumber", pageNumber.toString());
		return this.http
			.get<TppAccountsPagination>(`${environment.apiUri}/v1/accounts/tpp`, { params })
			.pipe(map((response: TppAccountsPagination) => response.items));
	}

	createTppAccount(newTppAccount: TppAccountCreation): Observable<TppAccountsPagination> {
		return this.http.post<TppAccountsPagination>(`${environment.apiUri}/v1/accounts/tpp`, newTppAccount);
	}
}
