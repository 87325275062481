import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatCardModule } from "@angular/material/card";

import { LegalEntityItemComponent } from "./legal-entity-item.component";

@NgModule({
	declarations: [LegalEntityItemComponent],
	imports: [CommonModule, MatCardModule],
	exports: [LegalEntityItemComponent]
})
export class LegalEntityItemModule {}
