import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ModalDropdownComponent } from "./modal-dropdown.component";
import { FlexLayoutModule } from "ngx-flexible-layout";
import { MatDividerModule } from "@angular/material/divider";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { SharedModule } from "@shared/shared.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
	declarations: [ModalDropdownComponent],
	imports: [
		CommonModule,
		FlexLayoutModule,
		MatDividerModule,
		MatProgressSpinnerModule,
		SharedModule,
		FormsModule,
		ReactiveFormsModule,
		MatTooltipModule
	],
	exports: [ModalDropdownComponent]
})
export class ModalDropdownModule {}
