import { SelectOption } from "../models/select-option.interface";

export const TPP_GROUPS: SelectOption[] = [
	{
		text: "TPP Group 1",
		value: "TPP1"
	},
	{
		text: "TPP Group 2",
		value: "TPP2"
	},
	{
		text: "TPP Group 3",
		value: "TPP3"
	},
	{
		text: "TPP Group 4",
		value: "TPP4"
	}
];
