import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import {
	CountryBankAccount,
	CountryBankAccountCreateDto,
	CountryBankAccountUpdateDto
} from "src/app/shared/models/bank-account.interface";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: "root"
})
export class BankAccountsService {
	bankNameChanged$: EventEmitter<string> = new EventEmitter<string>();
	countryChanged$: EventEmitter<string> = new EventEmitter<string>();
	formUpdated$: EventEmitter<boolean> = new EventEmitter<boolean>();
	formUpdatedValidity$: EventEmitter<boolean> = new EventEmitter<boolean>();

	constructor(private readonly http: HttpClient) {}

	getBankAccountFieldsByCountryCode(countryCode: string): Observable<CountryBankAccount> {
		return this.http.get<CountryBankAccount>(
			`${environment.apiUri}/v1/accounts/bank/countries/${countryCode}/fields`
		);
	}

	createBankAccountFields(data: CountryBankAccountCreateDto): Observable<CountryBankAccount> {
		return this.http.post<CountryBankAccount>(`${environment.apiUri}/v1/accounts/bank/countries/fields`, data);
	}

	updateBankAccountFields(data: CountryBankAccountUpdateDto): Observable<CountryBankAccount> {
		return this.http.put<CountryBankAccount>(`${environment.apiUri}/v1/accounts/bank/countries/fields`, data);
	}
}
