<mat-toolbar>
	<span class="title">PAYGROUP {{ title | uppercase }} HISTORY</span>
	<span class="example-spacer"></span>
	<button id="closeBtn" (click)="close()">
		<app-icon [name]="'close'" [color]="'white'" [size]="'50'"></app-icon>
	</button>
</mat-toolbar>
<div class="history-container">
	<div fxLayout="row" fxLayoutAlign="start center" class="history-header-container">
		<span class="heading" id="createdAtHeader" fxFlex="25">Updated At</span>
		<span class="heading" id="statusHeader" *ngIf="type.toString() === 'STATUS'" fxFlex="25">Status</span>
		<span class="heading" id="goliveHeader" *ngIf="type.toString() === 'GOLIVE'" fxFlex="25">Go Live At</span>
		<span class="heading" id="byHeader" fxFlex="25">By</span>
		<span class="heading" id="roleHeader" fxFlex="25">Role</span>
	</div>
	<div class="scroll-container">
		<div fxLayout="row" fxLayoutAlign="center" class="history-item-container" *ngIf="!history || !history.length">
			<p>No history data to display</p>
		</div>
		<div fxLayout="row" fxLayoutAlign="start center" class="history-item-container" *ngFor="let data of history">
			<span id="createdAt" class="data-item" fxFlex="25">{{ data.createdAt | date : "medium" }}</span>

			<div
				*ngIf="type === 'STATUS'"
				id="status"
				class="status data-item"
				fxFlex="25"
				fxLayoutAlign="start center"
			>
				<span
					class="dot"
					[ngClass]="{
						'text-live': data.status === 'LIVE',
						'text-implementation': data.status === 'IMPLEMENTATION',
						'text-inactive': data.status === 'INACTIVE'
					}"
				>
				</span>
				<span>{{ data.status }}</span>
			</div>

			<span
				*ngIf="type.toString() === 'GOLIVE'"
				id="golivedate"
				class="data-item"
				fxFlex="25"
				fxLayoutAlign="start center"
				>{{ data.goLiveAt | date : "medium" }}</span
			>

			<span id="by" fxFlex="25" class="data-item" style="word-break: break-word">
				{{ data.createdBy.value | userFullName | async }}
			</span>
			<span id="role" class="data-item" fxFlex="25" style="word-break: break-word">
				{{ data.createdBy.type | titlecase }}
			</span>
		</div>
	</div>
</div>
