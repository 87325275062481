<app-breadcrumbs [headerTitle]="'ERROR MANAGEMENT'" />
<div class="main-container">
	<app-error-list-filters
		(filterData)="filterErrors($event)"
		[providers]="providers$"
		[errorTypes]="errorTypes$"
	></app-error-list-filters>

	<app-error-list
		(pageUpdate)="getAllErrors()"
		[errorTypes]="errorTypes$"
		[errors]="errors$"
		(updateError)="storeError($event)"
	></app-error-list>

	<mat-paginator
		*ngIf="totalPageCount"
		[length]="totalPageCount"
		[pageSizeOptions]="[5, 10, 25, 100]"
		[pageSize]="pageSize"
		[pageIndex]="pageIndex"
		(page)="paginate($event)"
		aria-label="Select page"
	>
	</mat-paginator>
</div>
