import { Milestone, MilestoneDay } from "src/app/shared/models/pay-cycle.interface";
import { setFormatDate } from "src/app/shared/utils/date.util";

export class CalendarDay {
	public date: Date;
	public title: string = "";
	public isPastDate: boolean;
	public isToday: boolean;

	public milestoneArray: MilestoneDay[] = [];

	constructor(date: Date) {
		this.date = date;
		this.isPastDate = date.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);
		this.isToday = date.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0);
	}

	setMilestones(milestones: Milestone[]): void {
		milestones.map((milestone: Milestone) => {
			const formattedDate: Date = setFormatDate(milestone.date);
			if (this.date.setHours(0, 0, 0, 0) === formattedDate.setHours(0, 0, 0, 0)) {
				//enusre no duplicates
				let temp = this.milestoneArray.filter(
					mile => mile.group === milestone.group && mile.type === milestone.type
				);
				if (temp.length === 0) {
					this.milestoneArray.push({
						group: milestone.group,
						type: milestone.type
					});
				}
			}
		});
	}
}
