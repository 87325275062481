import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DefaultDataService, EntityHttpResourceUrls, HttpUrlGenerator, QueryParams } from "@ngrx/data";
import { environment } from "../../../../../environments/environment";
import { ErrorTypeAPIResponeModel, ErrorTypeModel } from "../../models/error-management.models";
import { map } from "rxjs/operators";

@Injectable({
	providedIn: "root"
})
export class ErrorTypesDataService extends DefaultDataService<ErrorTypeModel> {
	constructor(private httpClient: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
		super("ErrorManagement", httpClient, httpUrlGenerator);
		EntityHttpResourceUrls;
		httpUrlGenerator.registerHttpResourceUrls({
			ErrorManagement: {
				entityResourceUrl: `${environment.apiUri}/v1/provider-messages/error-types/`,
				collectionResourceUrl: `${environment.apiUri}/v1/provider-messages/error-types/`
			}
		});
	}

	getAll() {
		return this.httpClient
			.get<ErrorTypeAPIResponeModel>(`${environment.apiUri}/v1/provider-messages/error-types/`)
			.pipe(
				map((response: ErrorTypeAPIResponeModel) => {
					const data: ErrorTypeModel[] = response.errorTypes.map((error: string) => {
						return { type: error };
					});
					return data;
				})
			);
	}
}
