import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap, map } from "rxjs/operators";
import { Currency } from "src/app/shared/models/currency.interface";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { formatDataForSelect } from "src/app/shared/utils/format-select-data.util";
import { sortAsc } from "src/app/shared/utils/sort.util";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: "root"
})
export class CurrencyService {
	constructor(private readonly http: HttpClient) {}

	getAllCurrencies(): Observable<Currency[]> {
		return this.http.get<Currency[]>(`${environment.apiUri}/v1/resources/currencies`).pipe(
			tap((currency: Currency[]) => {
				sortAsc(currency, ["code"]);
			})
		);
	}

	getCurrencyOptions(selected: boolean = false): Observable<SelectOption[]> {
		return this.getAllCurrencies().pipe(
			map((currencies: Currency[]) => {
				return currencies.map(currency => {
					const option: SelectOption = {
						value: currency.code,
						text: currency.code + " - " + currency.name,
						placeholder: currency.code
					};

					if (selected) {
						option.selected = false;
					}

					return option;
				});
			})
		);
	}
}
