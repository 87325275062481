import { EventEmitter, Injectable } from "@angular/core";
import { FormControl } from "@angular/forms";
import { SelectAndFilterOptions } from "@shared/models/select-and-filter-options.interface";
import { take } from "rxjs/operators";
import { SelectOption } from "src/app/shared/models/select-option.interface";

@Injectable({
	providedIn: "root"
})
export class InputAutocompleteService {
	clearinputs: EventEmitter<boolean> = new EventEmitter<boolean>(false);

	constructor() {}

	setDisabledState(isDisabled: boolean, control: FormControl): FormControl {
		if (isDisabled) {
			control.disable();
		} else {
			control.enable();
		}

		return control;
	}

	writeValueById(filteredOptions: SelectOption[], defaultValue: string | string[]): SelectAndFilterOptions {
		if (filteredOptions.length > 0) {
			if (Array.isArray(defaultValue)) {
				let selectOptions: SelectOption[] | undefined;

				defaultValue.map((dv: string) => {
					let selectOption: SelectOption | undefined = filteredOptions.find(result => {
						if (result.value === dv) {
							result.selected = true;
							return true;
						} else {
							return false;
						}
					});

					if (selectOption?.value) {
						selectOptions = !selectOptions ? [] : selectOptions;
						selectOptions.push(selectOption);
					}
				});

				return {
					selectOptions: selectOptions,
					filteredOptions: filteredOptions
				};
			} else {
				let selectOption: SelectOption | undefined = filteredOptions.find(
					result => result.value === defaultValue
				);
				return {
					selectOptions: selectOption,
					filteredOptions: filteredOptions
				};
			}
		} else {
			return {
				selectOptions: undefined,
				filteredOptions: filteredOptions
			};
		}
	}

	displayMultipleFn(optionsSelected: SelectOption[] | string[]): string {
		let displayValue: string = "";

		if (optionsSelected && optionsSelected.length) {
			optionsSelected?.forEach((option: SelectOption | string, index: number) => {
				let value: string;

				if (typeof option === "string") {
					value = option;
				} else {
					value = option.placeholder ? option.placeholder : option.text;
				}

				if (index === 0) {
					displayValue = value;
				} else {
					displayValue += ", " + value;
				}
			});
		}
		return displayValue;
	}

	displayFn(option: SelectOption | string): string {
		// display text on input not object
		if (typeof option === "string") {
			return option;
		} else {
			return option && option.placeholder
				? option.placeholder
				: option && option.text
				? option.text
				: (undefined as unknown as string);
		}
	}

	formatSelectedOptions(
		option: SelectOption,
		selectedOptions: SelectOption[],
		dropDownType?: string
	): SelectOption[] {
		if (option.selected) {
			selectedOptions.push(option);
		} else {
			const i = selectedOptions.indexOf(option);
			selectedOptions.splice(i, 1);

			if (dropDownType) {
				//unselect all option if one element is unselected
				let allOptionIndex = selectedOptions.findIndex(
					option => option.value === dropDownType && option.selected === true
				);
				if (allOptionIndex > -1) {
					selectedOptions.splice(allOptionIndex, 1);
				}
			}
		}

		return selectedOptions;
	}
}
