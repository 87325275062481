import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TppServicesService } from "@shared/services/tpp-services/tpp-services.service";
import { SubService, SubServicePagination, TPPGroupDTO, TppService } from "src/app/shared/models/tpp-service.interface";
import { TppServiceManagementService } from "../../../_services/management/tpp-service-management.service";
import { PermissionsService } from "@shared/services/permissions/permissions.service";
import { take } from "rxjs";

@Component({
	selector: "app-tpp-sub-service-list",
	templateUrl: "./tpp-sub-service-list.component.html",
	styleUrls: ["./tpp-sub-service-list.component.scss"]
})
export class TppSubServiceListComponent implements OnInit {
	@Input() countryCode: string = "";
	@Input() forAdhocService: boolean = false;

	subServices: SubService[] = [];
	subServiceId: string = "";
	addNew: boolean = false;

	@Input() selectedPayGroupId: string = "";

	private _isEditService = false;

	@Input()
	set isEditService(value: boolean) {
		this._isEditService = value;
	}

	get isEditService(): boolean {
		return this._isEditService;
	}

	private _serviceToEdit!: TppService;

	@Input()
	set serviceToEdit(service: TppService) {
		// Add any additional validation or processing if needed
		this._serviceToEdit = service;
		this.getSubserviceFromTppServiceId();
	}

	get serviceToEdit(): TppService {
		return this._serviceToEdit;
	}

	finishColor: string = "#8992A3";
	@Output() goBack = new EventEmitter<boolean>();
	@Output() isComplete = new EventEmitter<boolean>();
	pageSize: number = -1; // put -1 because not show pagination
	pageIndex: number = 0;
	panelDisabled: boolean = false;
	panelOpenState: boolean = true;
	showBeneficiaryForm: boolean = false;
	canEditService = false;

	@Output() sendGroupData = new EventEmitter<TPPGroupDTO>();

	constructor(
		public tppServiceManagementService: TppServiceManagementService,
		private tppServicesService: TppServicesService,
		private permissions: PermissionsService
	) {}

	ngOnInit(): void {
		if (this.forAdhocService) {
			this.permissions
				.canEditAdhocService()
				.pipe(take(1))
				.subscribe(res => {
					this.canEditService = res;
					this.getSubserviceFromTppServiceId();
				});
		} else {
			this.permissions
				.canEditCatService()
				.pipe(take(1))
				.subscribe(res => {
					this.canEditService = res;
					this.getSubserviceFromTppServiceId();
				});
		}
	}

	getSubserviceFromTppServiceId() {
		this.tppServiceManagementService.getIsNewTppService().subscribe((isNew: boolean) => {
			if (!isNew) {
				this.panelDisabled = false;
				this.tppServicesService
					.searchSubServiceByTppServiceId(
						this.tppServiceManagementService.tppServiceData.id,
						this.pageIndex.toString(),
						this.pageSize.toString()
					)
					.subscribe((response: SubServicePagination) => {
						this.subServices = response.items;

						if (this.subServices.length > 0) {
							this.isComplete.emit(true);
						} else this.isComplete.emit(false);
					});
				if (
					this.tppServiceManagementService.getNewSubserviceForm() &&
					Object.keys(this.tppServiceManagementService.getNewSubserviceForm()).length > 0
				) {
					this.addNew = true;
				}
			} else this.panelDisabled = true;
		});
	}

	addNewSubservice() {
		if (this.tppServiceManagementService.tppServiceData && this.tppServiceManagementService.tppServiceData.id) {
			this.addNew = true;
			this.tppServiceManagementService.setIsNewSubService(true);
			this.isComplete.emit(false);
		}
	}

	getSubServiceData(event: SubService) {
		this.addNew = false;
		this.subServiceId = event.id;
		this.getSubserviceFromTppServiceId();
	}
}
