<div cdkDropList (cdkDropListDropped)="drop($event)">
	<div *ngIf="{ active: showData | async } as data">
		<div
			class="bank-account-type-container"
			*ngFor="let paymentMethodArray of data.active; let i = index"
			cdkDrag
			[cdkDragDisabled]="isDragDisabled"
		>
			<div *cdkDragPlaceholder class="example-custom-placeholder"></div>
			<!-- Payment Method: Bank account or card -->
			<div
				class="payment-method-container"
				(click)="onClickEditBankAccount(paymentMethodArray.payMethod.accountId, data.active!.length)"
			>
				<div
					class="drag-container"
					[ngClass]="{ 'display-menu': paymentType === 'Nets' }"
					[ngStyle]="{ visibility: 'hidden' }"
				>
					<app-icon [name]="'menu'"></app-icon>
				</div>
				<div class="left-box">
					<!-- Badge -->
					<div class="badge" [ngClass]="{ 'card-color': paymentMethodArray.payMethod.type === 'CARD' }">
						<!-- Card icon -->
						<span *ngIf="paymentMethodArray.payMethod.type === 'CARD'; else noCard"
							><app-icon class="bank-card-icon" [name]="'card'" [size]="'30'"></app-icon
						></span>

						<!-- Bank icon -->
						<ng-template #noCard>
							<!--<span id="accountBalance" class="material-icons icon">account_balance</span>-->
							<app-icon
								class="bank-icon"
								[name]="'bank-figma'"
								[lineWidth]="'2'"
								[size]="'30'"
							></app-icon>
						</ng-template>
					</div>

					<!-- Bank data -->
					<div class="data-box">
						<div class="title">{{ paymentMethodArray.details.bankName }}</div>
						<div
							class="subtitle"
							*ngIf="
								paymentMethodArray.payMethod.ranking &&
								paymentMethodArray.payMethod.ranking.type === 'AMOUNT'
							"
						>
							Rank {{ paymentMethodArray.payMethod.ranking.rank + 1 }}:
							{{ paymentMethodArray.payMethod.ranking.amount }}
							{{ paymentMethodArray.details.currency | currencySymbol }}
						</div>
						<div
							class="subtitle"
							*ngIf="
								paymentMethodArray.payMethod.ranking &&
								paymentMethodArray.payMethod.ranking.type === 'PERCENTAGE'
							"
						>
							Rank {{ paymentMethodArray.payMethod.ranking.rank + 1 }}:
							{{ paymentMethodArray.payMethod.ranking.amount }} %
						</div>
						<div
							class="subtitle"
							*ngIf="
								paymentMethodArray.payMethod.ranking &&
								paymentMethodArray.payMethod.ranking.type === 'PERCENTAGE_REMAINING'
							"
						>
							Rank {{ paymentMethodArray.payMethod.ranking.rank + 1 }}:
							{{ paymentMethodArray.payMethod.ranking.amount }} % Remaining
						</div>
						<div
							class="subtitle"
							*ngIf="
								paymentMethodArray.payMethod.ranking &&
								paymentMethodArray.payMethod.ranking.type === 'ALL_REMAINING'
							"
						>
							All Remaining
						</div>
					</div>

					<!-- If it is a card -->
					<ng-container *ngIf="paymentMethodArray.payMethod.type === 'CARD'">
						<!-- Card number -->
						<div class="data-box">
							<div class="title-2">Card number</div>
							<div class="subtitle-2">**** **** **** {{ paymentMethodArray.details.lastFour }}</div>
						</div>

						<!-- Card holder name -->
						<div class="data-box">
							<div class="title-2">Card holder name</div>
							<div class="subtitle-2">
								{{ paymentMethodArray.details.holder.firstName }}
								{{ paymentMethodArray.details.holder.lastName }}
							</div>
						</div>
					</ng-container>
				</div>

				<!--Toogle -->
				<div *ngIf="paymentMethodArray | employeeTransactionsFallback : supportedPayMethodTypes">
					<mat-slide-toggle
						class="toogle-style toggle-default pe_methods_fallback"
						disabled
						[checked]="paymentMethodArray.payMethod.paymentTypes.includes(fallback)"
						>Fall back</mat-slide-toggle
					>
				</div>

				<div class="middle-box">
					<!-- Payment Types -->
					<div class="data-container">
						<mat-checkbox
							id="netCheck"
							color="primary"
							[checked]="!!paymentMethodArray.payMethod.paymentTypes.includes(net)!"
							disabled
							>NET
						</mat-checkbox>
						<mat-checkbox
							id="ttpCheck"
							color="primary"
							[checked]="!!paymentMethodArray.payMethod.paymentTypes.includes(ttp)!"
							disabled
							>TPP
						</mat-checkbox>
						<mat-checkbox
							id="ewaCheck"
							color="primary"
							[checked]="!!paymentMethodArray.payMethod.paymentTypes.includes(ewa)!"
							disabled
							>EWA
						</mat-checkbox>
					</div>
				</div>

				<div class="right-box">
					<!-- If it is not a card -->
					<ng-container *ngIf="paymentMethodArray.payMethod.type !== 'CARD'">
						<!-- Status -->
						<div
							class="data-container"
							*ngIf="
								checkProviderStatusesExistance(paymentMethodArray.payMethod);
								else showProviderBasedStatus
							"
						>
							<!-- Badge -->
							<div
								class="status-badge"
								id="statusBadge"
								[ngClass]="payMethodStatusClass(paymentMethodArray.payMethod)"
							></div>

							<!-- Label -->
							<div id="beneficiaryStatus" class="status-label">
								{{
									getPayMethodStatusName(paymentMethodArray.payMethod) | payElectiveStatus | titlecase
								}}
							</div>
						</div>

						<ng-template #showProviderBasedStatus>
							<div class="data-container">
								<!-- Badge -->
								<div
									class="status-badge"
									id="statusBadge"
									[ngClass]="payMethodProviderStatusClass(paymentMethodArray.payMethod)"
								></div>

								<!-- Label -->
								<div id="beneficiaryStatus" class="status-label">
									{{
										getProviderStatusName(paymentMethodArray.payMethod)
											| payElectiveStatus
											| titlecase
									}}
								</div>
							</div>
						</ng-template>

						<div class="data-container"></div>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</div>
