<div class="history-item-container">
	<div class="status-container">
		<span id="date" class="date"> {{ historyItem.createdAt | date : "H:mm MMM d, y" }}</span>

		<div *ngIf="type.toString() === 'GOLIVE' || type.toString() === 'TREASURYGOLIVE'" class="record-event">
			<div class="event" *ngIf="isLast">
				<app-icon
					class="event-icon"
					[name]="'clock-1'"
					[color]="'var(--color-role-titl)'"
					[size]="'12px'"
				></app-icon>
				<span class="event-text">Time created</span>
			</div>
			<div class="event" *ngIf="!isLast">
				<app-icon
					class="event-icon"
					[name]="'group-11'"
					[color]="'var(--color-role-titl)'"
					[size]="'12px'"
				></app-icon>
				<span class="event-text">Time changed</span>
			</div>
		</div>

		<div id="status" *ngIf="type.toString() === 'STATUS'" class="status data-item" fxLayoutAlign="start center">
			<span
				class="dot"
				[ngClass]="{
					'text-live': historyItem.status === 'LIVE',
					'text-implementation': historyItem.status === 'IMPLEMENTATION',
					'text-inactive': historyItem.status === 'INACTIVE'
				}"
			>
			</span>
			<span>{{ historyItem.status }}</span>
		</div>
	</div>
	<div class="user-info-container">
		<span class="user-info-content" *ngIf="type.toString() === 'STATUS'">
			<span class="name blueText">{{ historyItem.createdBy.value }}</span> &nbsp;with the role&nbsp;
			<span class="role">{{ historyItem.createdBy.type | titlecase }}</span> &nbsp;
			<span *ngIf="isLast">set up the paygroup status as</span>
			<span *ngIf="!isLast">changed the paygroup status to</span>

			&nbsp;<span class="user-status blueText">{{ historyItem.status | toString | titlecase }}</span>
		</span>

		<span class="user-info-content" *ngIf="type.toString() === 'GOLIVE' || type.toString() === 'TREASURYGOLIVE'">
			<span class="name blueText">{{ historyItem.createdBy.value }}</span> &nbsp;with the role&nbsp;
			<span class="role">{{ historyItem.createdBy.type | titlecase }}</span> &nbsp;
			<span *ngIf="isLast">set up the paygroup Go live date</span>
			<span *ngIf="!isLast">changed the paygroup Go live date to</span>
			<span id="date" class="date blueText"> {{ historyItem.goLiveAt | date : "MMMM d, y" }}</span>
		</span>
	</div>
</div>
