<mat-accordion class="create-accordion">
	<mat-expansion-panel [expanded]="true">
		<!-- Header -->
		<mat-expansion-panel-header>
			<expansion-panel-header-title
				[title]="
					providerSettingsForm.controls.fullName.value
						? providerSettingsForm.controls.fullName.value
						: 'New Service Provider'
				"
			>
			</expansion-panel-header-title>
		</mat-expansion-panel-header>

		<!-- Service Provider -->
		<form [formGroup]="providerSettingsForm">
			<div class="form-content flex flex-column flex-align-stretch-space-between">
				<h2 class="form-title form-title-gap">General Information</h2>

				<div class="flex flex-row flex-align-center-space-between">
					<input-wrapper class="input-gap" label="Select Service Providers *">
						<input-autocomplete
							id="providers"
							formControlName="name"
							label="Select Provider"
							[options]="availableProvidersOptions$ | async"
							[required]="true"
							[multiple]="false"
						>
						</input-autocomplete>
						<div
							id="providers"
							input-errors
							*ngIf="
								providerSettingsForm.controls.name?.errors?.required &&
								providerSettingsForm.controls.name?.touched
							"
						>
							Please select a Service Provider
						</div>
					</input-wrapper>

					<!-- Available funding currency [options]="currencyOptions$ | async"-->
					<input-wrapper label="Available funding currencies *">
						<input-autocomplete
							id="currencies"
							formControlName="currencies"
							label="Select funding currency"
							[options]="currencyOptions$ | async"
							[required]="true"
							[multiple]="true"
						>
						</input-autocomplete>
						<div
							id="currencies"
							input-errors
							*ngIf="
								providerSettingsForm.controls.currencies?.errors?.required &&
								providerSettingsForm.controls.currencies?.touched
							"
						>
							At least one funding currency must be selected
						</div>
					</input-wrapper>
				</div>
				<!-- canvas -->
				<canvas #logoCanvas></canvas>
			</div>

			<settlement-account-fields
				(settlementAccountForm)="settlementAccountForm($event)"
				[expanded]="true"
			></settlement-account-fields>

			<expansion-panel-section
				[addContactButtonDisabled]="false"
				(addContactClicked)="onAddContactClicked()"
				[addContactButton]="true"
				[title]="'Contact person (Optional)'"
			>
				<app-contacts
					(contactFormData)="contactFormData($event)"
					[addIsClicked$]="addContactClicked$"
				></app-contacts>

				<div class="btn-container">
					<button id="cancel" class="cancel-btn" (click)="cancelCreate()" action-buttons mat-stroked-button>
						Cancel
					</button>
					<button
						id="submitProvider"
						(click)="saveProvider()"
						action-buttons
						mat-flat-button
						[disabled]="
							providerSettingsForm.invalid ||
							loading ||
							settletmentAccountFormStatus ||
							!contactFormisValid
						"
						color="dark"
					>
						Create Service Provider
					</button>
				</div>
			</expansion-panel-section>
		</form>
	</mat-expansion-panel>
</mat-accordion>
