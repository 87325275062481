import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "checkBank"
})
export class CheckBankPipe implements PipeTransform {
	transform(value: string): boolean {
		return value === "BANK";
	}
}
