<!-- Loader -->
<mat-spinner *ngIf="showPageLoadSpinner"></mat-spinner>

<mat-drawer-container class="main-drawer-container">
	<mat-drawer
		class="side-nav-container"
		[ngClass]="{'client-landing-page': isOnClientlandingPage}"
		[mode]="'side'"
		[opened]="isMenuOpen"
	>
		<app-side-nav
			*ngIf="roles"
			[version]="version"
			[user]="user"
			[menuOptions]="menu"
			[roles]="roles"
			[customerLogo]="customerLogo"
			[clientLanding]="isOnClientlandingPage"
			[legalEntityCountries]="legalEntityCountryList"
			(isBigScreen)="bigScreen = $event"
			(isSideNavOpen)="sideNavOpen = $event"
			(isMenuOpen)="isMenuOpen = $event"
			(legalEntitiesSelected)="populateGlobalDashboardFiltersStore('', $event, true)"
		></app-side-nav>
	</mat-drawer>
	<!-- Main content -->
	<mat-drawer-content
		class="mat-drawer-content"
		[ngClass]="{
			'mat-drawer-content-open-small': sideNavOpen && !bigScreen && isMenuOpen,			
			'mat-drawer-content-open-big': sideNavOpen && bigScreen && isMenuOpen,
			'mat-drawer-content-closed': !sideNavOpen && isMenuOpen
		}"
	>
		<div class="pages-container" *ngIf="loadPage">
			<router-outlet></router-outlet>
		</div>
	</mat-drawer-content>
	<!-- End of main content -->
</mat-drawer-container>
