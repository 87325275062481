import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import {
	BeneficiaryAccountAssignment,
	SubService,
	TppServicesByByPayGroupViewModel,
	TtpService,
	TtpServiceByServiceType
} from "src/app/shared/models/service-definition/TppServiceDefinitionViewModel";
import { TppAdapterImpl } from "@shared/services/adapter/ttp-service-definition/tpp-adapter-impl.service";
import { BeneficiaryStatusFilterType } from "../../_components/tpp-service-selection/beneficary-status-by-provider-summary/beneficary-status-by-provider-summary.component";
import { BeneficiaryStatusByProviderSummary } from "src/app/shared/models/service-definition/TtpBeneficiaryStatusByProviderSummary";
import { ModalLauncherService } from "@shared/components/wpay-ui/modal/modal-launcher.service";
import { PayElectiveHistoryModalData } from "@shared/components/wpay-ui/modal/templates/pay-elective-history/model/PayElectiveHistoryModalData";

@Injectable({
	providedIn: "root"
})
export class TtpServiceSelectionService {
	constructor(
		private ttpServiceDefinitionAdapter: TppAdapterImpl,
		private modalLauncherService: ModalLauncherService
	) {}

	getServicesByPayGroupAndProvider(
		payGroupId: string,
		providerName: string,
		statusFilter: BeneficiaryStatusFilterType
	) {
		return this.ttpServiceDefinitionAdapter.findServiceDefinitionsByPayGroupId(
			payGroupId,
			providerName,
			statusFilter
		);
	}

	getPaymentSummaryByPayGroup(
		payGroupId: string,
		providerName: string
	): Observable<BeneficiaryStatusByProviderSummary> {
		return this.ttpServiceDefinitionAdapter.findBeneficiaryStatusByProviderWithPayGroupId(payGroupId, providerName);
	}

	validateBeneficiary(beneficiaryId: string) {
		return this.ttpServiceDefinitionAdapter.validateBeneficiaries([beneficiaryId]);
	}

	validateBeneficiaries(services: TtpServiceByServiceType[]): Observable<any> {
		var beneficiaries = this.toBeneficiaryList(services);

		if (beneficiaries.length == 0) return of(new Error("No beneficiaries to validate"));

		return this.ttpServiceDefinitionAdapter.validateBeneficiaries(beneficiaries);
	}

	findAllTppPaymentProvidersAssignedToPayGroupId(payGroupId: string): Observable<string[]> {
		return this.ttpServiceDefinitionAdapter.findAllTppPaymentProvidersAssignedToPayGroupId(payGroupId);
	}

	showBeneficiaryHistoryModal(payGroupId: string, beneficiary: BeneficiaryAccountAssignment) {
		const PROVIDER_NAME = beneficiary.providerName;
		const BENEFICIARY_ID = beneficiary.id;
		const SETTLEMENT_ACCOUNT_ID = beneficiary.settlementAccountId;

		this.ttpServiceDefinitionAdapter
			.getTppBeneficiaryHistory(payGroupId, PROVIDER_NAME, BENEFICIARY_ID, SETTLEMENT_ACCOUNT_ID)
			.subscribe((historyModalData: PayElectiveHistoryModalData) => {
				this.modalLauncherService.showPayElectiveHistoryModal(historyModalData);
			});
	}

	private toBeneficiaryList(services: TtpServiceByServiceType[]): string[] {
		var beneficiaries = [] as string[];

		services.forEach((service: TtpServiceByServiceType) => {
			service.services.forEach((service: TtpService) => {
				service.subService.forEach((subService: SubService) => {
					if (
						subService.beneficiary !== null &&
						subService.beneficiary.linkId &&
						!beneficiaries.includes(subService.beneficiary.linkId)
					) {
						beneficiaries.push(subService.beneficiary.linkId);
					}
				});
			});
		});
		return beneficiaries;
	}

	private selectedServices!: TppServicesByByPayGroupViewModel;

	getServicesArray(): TppServicesByByPayGroupViewModel {
		return this.selectedServices;
	}

	setServicesArray(selectedService: TppServicesByByPayGroupViewModel): void {
		this.selectedServices = selectedService;
	}
}
