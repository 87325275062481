import { Pipe, PipeTransform } from "@angular/core";
import getSymbolFromCurrency from "currency-symbol-map";

@Pipe({
	name: "iso3CountryToCurrencySymbol",
	standalone: true
})
export class Iso3CountryToCurrencySymbolPipe implements PipeTransform {
	transform(iso3CountryCode: string): unknown {
		return getSymbolFromCurrency(iso3CountryCode);
	}
}
