import { Injectable, OnDestroy } from "@angular/core";
import { LegalEntityService } from "../../../../shared/services/legal-entity/legal-entity.service";
import { FormGroup } from "@angular/forms";
import { LegalEntityApi } from "../../_models/legal-entity-api.interface";
import { Observable, of, Subject } from "rxjs";
import { CommonLegalEntityService } from "../common/common-legal-entity.service";
import { mergeMap, switchMap, takeUntil } from "rxjs/operators";
import { SelectOption } from "../../../../shared/models/select-option.interface";
import { Country } from "../../../../shared/models/country.interface";
import { formatDataForSelect } from "../../../../shared/utils/format-select-data.util";
import { ContactPersonService } from "@shared/services/contact-person/contact-person.service";
import { ToastService } from "@shared/services/toast/toast.service";
import { LegalEntity } from "src/app/shared/models/legal-entity.interface";
import { CommonService } from "../../../customer-setup/_services/common/common.service";
import { ContactPerson } from "../../../../shared/models/contact-person.interface";

@Injectable()
export class CreateLegalEntityService implements OnDestroy {
	timeZones$: Subject<SelectOption[]> = new Subject<SelectOption[]>();

	private destroy$: Subject<void> = new Subject<void>();
	private timeZones: SelectOption[] = [];

	constructor(
		private commonLegalEntityService: CommonLegalEntityService,
		private legalEntityService: LegalEntityService,
		private contactPersonService: ContactPersonService,
		private commonService: CommonService,

		private toastService: ToastService
	) {}

	createLegalEntity(apiLegalEntity: LegalEntityApi): Observable<Boolean> {
		const result = new Subject<boolean>();
		let storedEntity!: LegalEntity;

		if (apiLegalEntity.contactPersons.length) {
			this.legalEntityService
				.createLegalEntity(apiLegalEntity)
				.pipe(
					takeUntil(this.destroy$),
					switchMap((entity: LegalEntity) => {
						storedEntity = entity;
						return this.contactPersonService
							.addContactPerson(
								this.commonService.contactPersonObject(entity.id, apiLegalEntity.contactPersons)
							)
							.pipe(
								switchMap((contacts: ContactPerson[]) =>
									contacts.filter((contact: ContactPerson) =>
										contact.userId
											? this.contactPersonService
													.linkContactToEntity(entity.id, contact.userId)
													.subscribe()
											: void 0
									)
								)
							);
					})
				)
				.subscribe(res => this.creationCompleted(result, storedEntity));
		} else {
			this.legalEntityService
				.createLegalEntity(apiLegalEntity)
				.pipe(
					takeUntil(this.destroy$),
					switchMap((entity: LegalEntity) => {
						storedEntity = entity;
						return of(entity);
					})
				)
				.subscribe(res => this.creationCompleted(result, storedEntity));
		}

		return result.asObservable();
	}

	creationCompleted(result: Subject<boolean>, newLegalEntity: LegalEntity) {
		result.next(true);
		result.complete();
		this.commonLegalEntityService.legalEntities.push(newLegalEntity);
		this.commonLegalEntityService.legalEntities$.next({
			value: this.commonLegalEntityService.legalEntities,
			type: "CREATE"
		});
	}

	setupLegalEntityFormEvents(legalEntityForm: FormGroup) {
		//Set functionality for when selecting country should autofill region & timezone
		legalEntityForm?.controls?.country?.valueChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe((value: string | SelectOption) => {
				if (value) {
					const countryCode: string = typeof value === "string" ? value : value.value;
					const country: Country = this.commonLegalEntityService.countries.find(
						(country: Country) => country.country.code === countryCode
					) as Country;

					if (country?.timeZones.length > 0) {
						legalEntityForm.controls.timeZone.enable();
						this.timeZones = [];
						this.timeZones$.next(this.timeZones);

						country.timeZones.map(timezone => {
							this.timeZones.push(
								formatDataForSelect({
									value: timezone.name,
									text: timezone.name
								})
							);
						});
						this.timeZones$.next(this.timeZones);

						legalEntityForm.controls.timeZone.setValue(country.timeZones[0].name);
						legalEntityForm.controls.timeZone.updateValueAndValidity();
					} else {
						legalEntityForm.controls.timeZone.disable();
						this.timeZones = [];
						this.timeZones$.next(this.timeZones);
						legalEntityForm.controls.timeZone.setValue(null);
						legalEntityForm.controls.timeZone.updateValueAndValidity();
					}
				}
			});
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
