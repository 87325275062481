import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup, Validators, UntypedFormBuilder } from "@angular/forms";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { select, Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, take, takeUntil } from "rxjs/operators";
import { ToastService } from "@shared/services/toast/toast.service";
import { TppServicesService } from "@shared/services/tpp-services/tpp-services.service";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { IServiceCreationFormGroup, TppServiceCreation, TppService } from "src/app/shared/models/tpp-service.interface";
import { AppState } from "src/app/store/models/state.model";
import { TppServiceManagementService } from "../../../_services/management/tpp-service-management.service";
import { TppDescriptionDialogComponent } from "./tpp-description-dialog/tpp-description-dialog.component";
import { UpdateSelectedTPPService } from "src/app/store/actions/tppServiceSelect.action";
import { getSelectedTPPServiceState } from "src/app/store";
import { CountriesService } from "@shared/services/countries/countries.service";
import { PermissionsService } from "@shared/services/permissions/permissions.service";

@Component({
	selector: "app-tpp-service-creation",
	templateUrl: "./tpp-service-creation.component.html",
	styleUrls: ["./tpp-service-creation.component.scss"]
})
export class TppServiceCreationComponent implements OnInit {
	@Input() countryCode: string = "";
	@Input() dontDispatch: boolean = false;
	@Input() forAdhocService: boolean = false;
	@Input() selectedPayGroupId: string = "";

	private _serviceToEdit!: TppService;

	@Input()
	set serviceToEdit(service: TppService) {
		// Add any additional validation or processing if needed
		this._serviceToEdit = service;
		this.initializeComponent();
	}

	get serviceToEdit(): TppService {
		return this._serviceToEdit;
	}

	@Output() getNewServiceCreation = new EventEmitter<TppService>();
	typeSelectOptions$!: Observable<SelectOption[]>;
	typeSelectOptions: SelectOption[] = [];
	form!: FormGroup;
	destroy$ = new Subject<void>();
	tppServiceObject!: TppService;
	serviceName = "";
	canEditService = false;
	isNewService = false;

	constructor(
		private formBuilder: UntypedFormBuilder,
		private tppServiceManagementService: TppServiceManagementService,
		private tppServicesService: TppServicesService,
		private toastService: ToastService,
		private dialog: MatDialog,
		private store: Store<AppState>,
		private countriesService: CountriesService,
		private permissions: PermissionsService
	) {}

	ngOnInit(): void {
		this.tppServiceManagementService
			.getIsNewTppService()
			.pipe(take(1))
			.subscribe(isNew => (this.isNewService = isNew));

		if (this.forAdhocService) {
			this.permissions
				.canEditAdhocService()
				.pipe(take(1))
				.subscribe(res => {
					this.canEditService = res;
					this.initializeComponent();
				});
		} else {
			this.permissions
				.canEditCatService()
				.pipe(take(1))
				.subscribe(res => {
					this.canEditService = res;
					this.initializeComponent();
				});
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	initializeComponent() {
		if (this.serviceToEdit && this.serviceToEdit.id) {
			this.tppServiceObject = this.serviceToEdit;
		}

		this.initForm();
		this.initSettingsType();
	}

	initForm(): void {
		this.form = this.formBuilder.group({
			type: ["", Validators.required],
			name: ["", Validators.required],
			description: ["", Validators.required],
			status: ["ACTIVE", Validators.required]
		}) as IServiceCreationFormGroup;

		this.formValueChangesCheck();

		this.updateTppServiceForm();

		this.serviceEditionSetup();

		if (this.serviceToEdit && this.serviceToEdit.id && !this.canEditService) {
			this.form.disable();
		} else if (!this.isNewService && !this.canEditService) {
			this.form.disable();
		}
	}

	serviceEditionSetup(): void {
		if (this.serviceToEdit && this.serviceToEdit.id) {
			const serviceData = {
				// create an object with the values you want to set
				type: this.serviceToEdit.type,
				name: this.serviceToEdit.name,
				description: this.serviceToEdit.description,
				status: this.serviceToEdit.status
			};

			this.serviceName = this.serviceToEdit.name;

			this.forAdhocService = true;

			this.form.patchValue(serviceData); // set the values for all controls
		}
	}

	formValueChangesCheck(): void {
		this.form
			.get("name")!
			.valueChanges.pipe(distinctUntilChanged(), debounceTime(500), takeUntil(this.destroy$))
			.subscribe((name: string) => {
				this.serviceName = name;
				this.tppServicesService.serviceNameChanged.emit(name);
			});

		this.form.valueChanges
			.pipe(takeUntil(this.destroy$), debounceTime(1500), distinctUntilChanged())
			.subscribe(values => {
				if (this.form.valid) {
					if (this.tppServiceObject && this.tppServiceObject.id) {
						if (this.forAdhocService) {
							this.tppServiceObject = {
								id: this.tppServiceObject.id,
								version: this.tppServiceObject.version,
								payGroupId: this.selectedPayGroupId,
								name: this.form.get("name")?.value,
								description: this.form.get("description")?.value,
								type: this.formatType(this.form.get("type")?.value),
								status: this.form.get("status")?.value
							};
						} else {
							this.tppServiceObject = {
								id: this.tppServiceObject.id,
								version: this.tppServiceObject.version,
								country: this.countryCode,
								name: this.form.get("name")?.value,
								description: this.form.get("description")?.value,
								type: this.formatType(this.form.get("type")?.value),
								status: this.form.get("status")?.value
							};
						}

						this.tppServicesService.updateTPPservice(this.tppServiceObject).subscribe({
							next: (response: TppService) => {
								this.updateServiceInfo(response);

								if (!this.form.untouched) {
									this.toastService.showSuccess("Service Updated Successfully");
								}
							},
							error: _ => {
								this.toastService.showError("Service Updated Error");
							}
						});
					} else {
						let createService: TppServiceCreation;

						if (this.forAdhocService) {
							createService = {
								name: this.form.get("name")?.value,
								payGroupId: this.selectedPayGroupId,
								description: this.form.get("description")?.value,
								type: this.form.get("type")?.value,
								status: this.form.get("status")?.value,
								services: []
							};
						} else {
							createService = {
								name: this.form.get("name")?.value,
								country: this.countryCode,
								description: this.form.get("description")?.value,
								type: this.form.get("type")?.value,
								status: this.form.get("status")?.value,
								services: []
							};
						}

						this.tppServicesService.createTPPService(createService).subscribe({
							next: (response: TppService) => {
								this.updateServiceInfo(response);
								this.toastService.showSuccess("Service Created Successfully");
							},
							error: _ => {
								this.toastService.showError("Service Created Error");
							}
						});
					}
				}
			});
	}

	formatType(type: string): string {
		return type
			.split(" ")
			.map(word => word.toUpperCase())
			.join(type.includes(" ") ? "_" : "");
	}

	formatDropDownText(text: string): string {
		if (text) {
			let format: string = text.replace("_", " ");
			format = format.charAt(0).toUpperCase() + format.slice(1);
			return format;
		}
		return text;
	}

	initSettingsType() {
		this.typeSelectOptions$ = this.tppServicesService.getTPPServiceSettingsTypes();

		this.typeSelectOptions$.pipe(take(1)).subscribe((types: SelectOption[]) => {
			this.typeSelectOptions = types;
		});
	}

	updateServiceInfo(updateTppService: TppService) {
		this.tppServiceObject = updateTppService;

		this.dispatchToStore(this.countryCode, updateTppService);
		this.tppServiceManagementService.setTppServiceDetail(this.tppServiceObject);
		this.tppServiceManagementService.setIsNewTppService(false);
	}

	updateTppServiceForm(): void {
		const isNewTppService$ = this.tppServiceManagementService.getIsNewTppService().subscribe((isNew: boolean) => {
			if (!isNew) {
				const tppService: TppService = this.tppServiceManagementService.tppServiceData;
				this.updateForm(tppService);
			}
		});

		isNewTppService$.unsubscribe();
	}

	updateForm(tppService: TppService) {
		this.form.patchValue({
			type: this.formatDropDownText(tppService.type),
			name: tppService.name,
			description: tppService.description,
			status: tppService.status
		});

		this.serviceName = tppService.name;

		this.tppServiceObject = { ...tppService };
		this.getNewServiceCreation.emit(this.tppServiceObject);
	}

	dispatchToStore(countryCode: string, tppService: TppService | null): void {
		//get all services first

		this.store.pipe(take(1), select(getSelectedTPPServiceState)).subscribe(state => {
			if (state.selectedTPPService) {
				if (state.selectedTPPService.selectedPayGroup && state.selectedTPPService.selectedServices) {
					let services: string[] = [];
					services.push(this.form.get("name")?.value);

					for (let i = 0; i < state.selectedTPPService.selectedServices.length; i++) {
						services.push(state.selectedTPPService.selectedServices[i]);
					}

					this.store.dispatch(
						new UpdateSelectedTPPService({
							selectedTPPService: {
								countryCode: countryCode,
								selectedPayGroup: state.selectedTPPService.selectedPayGroup,
								selectedServices: services,
								tppService: this.tppServiceObject
							}
						})
					);
					this.getNewServiceCreation.emit(this.tppServiceObject);
				} else {
					this.store.dispatch(
						new UpdateSelectedTPPService({
							selectedTPPService: {
								countryCode: countryCode,
								selectedServices: [this.form.get("name")?.value],
								tppService: this.tppServiceObject
							}
						})
					);
					this.getNewServiceCreation.emit(this.tppServiceObject);
				}
			} else {
				this.store.dispatch(
					new UpdateSelectedTPPService({
						selectedTPPService: {
							countryCode: countryCode,
							selectedServices: [this.form.get("name")?.value],
							tppService: this.tppServiceObject
						}
					})
				);
				this.getNewServiceCreation.emit(this.tppServiceObject);
			}
		});
	}

	openDescriptionDialog() {
		const dialogConfig = new MatDialogConfig();

		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.panelClass = "delete-dialog-container";
		dialogConfig.width = "995px";
		if (this.tppServiceObject && this.tppServiceObject.description)
			dialogConfig.data = this.tppServiceObject.description;

		const dialogRef = this.dialog.open(TppDescriptionDialogComponent, dialogConfig);
		dialogRef.afterClosed().subscribe(data => {
			if (data) {
				this.form.patchValue({
					description: data.description
				});
			}
		});
	}

	getCountryName(countryCode: string): string {
		let countryName: string = "";

		this.countriesService
			.getCountryByCode(countryCode)
			.pipe(take(1))
			.subscribe(countries => {
				countryName = countries[0]?.country.name;
			});

		return countryName;
	}
}
