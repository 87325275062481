import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

import { SelectOption } from "@shared/models/select-option.interface";

@Component({
	selector: "app-paygroups-report-dialog",
	templateUrl: "./paygroups-report-dialog.component.html",
	styleUrls: ["./paygroups-report-dialog.component.scss"]
})
export class PaygroupsReportDialogComponent {
	reportOptions: SelectOption[] = [
		{ value: "all", text: "Download report for all customers" },
		{ value: "filtered", text: "Donwload report only using the selected filters" }
	];

	selectedTypeReport = "";

	constructor(private dialogRef: MatDialogRef<PaygroupsReportDialogComponent>) {}

	close(confirm: boolean): void {
		this.dialogRef.close(confirm);
	}

	download(): void {
		this.dialogRef.close(this.selectedTypeReport);
	}

	chooseTypeReport(event: string): void {
		this.selectedTypeReport = event;
	}
}
