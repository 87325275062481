import { Action } from "@ngrx/store";
import { GlobalDashboardFilterSelect } from "../models/globalDashboardFilterSelection.model";

export enum GlobalDashboardFilterActionType {
	SET_SELECTED = "[GlobalDashboardFilter] Set Selected"
}
export class UpdateDashboardFilterAction implements Action {
	readonly type = GlobalDashboardFilterActionType.SET_SELECTED;

	//add an optional payload
	constructor(public payload: GlobalDashboardFilterSelect) {}
}
export type GlobalDashboardFilterSelectAction = UpdateDashboardFilterAction;
