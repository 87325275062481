<form [formGroup]="costForm">
	<div class="flex-table" role="rowgroup" *ngFor="let type of createdCapabilites" [formGroupName]="type.paymentType">
		<div class="flex-row rowspan">
			<input-wrapper>
				<input class="fix-width-input" formControlName="paymentType" type="text" />
			</input-wrapper>
		</div>
		<div class="column">
			<ng-container
				formArrayName="costs"
				*ngFor="let accountRoute of getCostFormGroupArray(type.paymentType).controls; let i = index"
			>
				<div class="flex-row" [formGroupName]="i">
					<div class="flex-cell" role="cell">
						<input-wrapper>
							<input class="fix-width-input" id="billingType" formControlName="billingType" type="text" />
						</input-wrapper>
					</div>
					<div class="flex-cell" role="cell">
						<input-wrapper>
							<input
								class="fix-width-input"
								id="payoutAccount"
								formControlName="payoutAccount"
								type="text"
							/>
						</input-wrapper>
					</div>
					<div class="flex-cell" role="cell">
						<input-wrapper>
							<input class="fix-width-input" id="route" formControlName="route" type="text" />
						</input-wrapper>
					</div>
					<div class="flex-cell" role="cell">
						<input-wrapper>
							<input-autocomplete
								formControlName="currency"
								label="Select funding currency"
								[options]="currencyOptions$ | async"
								width="120px"
								[defaultValue]="accountRoute.get('currency')?.value"
							>
							</input-autocomplete>
						</input-wrapper>
					</div>
					<div class="flex-cell" role="cell">
						<input-wrapper>
							<input class="fix-width-input" formControlName="paymentFee" type="number" />
							<div input-errors>
								<ng-container
									*ngIf="
										(accountRoute.get('paymentFee')?.touched ||
											accountRoute.get('paymentFee')?.dirty) &&
										accountRoute.get('paymentFee')?.errors?.required
									"
								>
									<div>Set a payment fee</div>
								</ng-container>
								<ng-container
									*ngIf="
										(accountRoute.get('paymentFee')?.touched ||
											accountRoute.get('paymentFee')?.dirty) &&
										accountRoute.get('paymentFee')?.errors?.min
									"
								>
									<div>The minimum value is 0</div>
								</ng-container>
								<ng-container *ngIf="accountRoute.hasError('atLeastOne')">
									<div>At least one fee must more than 0</div>
								</ng-container>
							</div>
						</input-wrapper>
					</div>
					<div class="flex-cell" role="cell">
						<input-wrapper>
							<input class="fix-width-input" formControlName="monthlyFee" type="number" />
							<div input-errors>
								<ng-container
									*ngIf="
										(accountRoute.get('monthlyFee')?.touched ||
											accountRoute.get('monthlyFee')?.dirty) &&
										accountRoute.get('monthlyFee')?.errors?.required
									"
								>
									<div>set a payment fee</div>
								</ng-container>
								<ng-container
									*ngIf="
										(accountRoute.get('monthlyFee')?.touched ||
											accountRoute.get('monthlyFee')?.dirty) &&
										accountRoute.get('monthlyFee')?.errors?.min
									"
								>
									<div>The minimum value is 0</div>
								</ng-container>
								<ng-container *ngIf="accountRoute.hasError('atLeastOne')">
									<div>At least one fee must more than 0</div>
								</ng-container>
							</div>
						</input-wrapper>
					</div>
					<div class="notes-cell" role="cell">
						<input-wrapper>
							<input class="notes-input" formControlName="notes" type="text" />
						</input-wrapper>
						<app-icon
							id="edit-icon"
							[name]="'edit-2'"
							(click)="openDialog(type.paymentType, accountRoute.get('notes')?.value, i)"
						></app-icon>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
</form>
