import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { PayGroup } from "../../../../../shared/models/pay-groups";
import { createCustomerLogoPath } from "../../../../../shared/utils/customer-logo-path.util";
import { Store, select } from "@ngrx/store";
import { AppState } from "src/app/store/models/state.model";
import { take } from "rxjs/operators";
import { getCustomerEntityGroupState } from "src/app/store";
import { CustomerEntityGroupSelect } from "src/app/store/models/customerEnitityGroupSelection.model";
import { UpdateCustomerEntityGroupAction } from "src/app/store/actions/customerEntityGroupSelect.action";

@Component({
	selector: "app-pay-group-list-item",
	templateUrl: "./pay-group-list-item.component.html",
	styleUrls: ["./pay-group-list-item.component.scss"]
})
export class PayGroupListItemComponent implements OnInit {
	@Input() payGroup!: PayGroup;

	constructor(private router: Router, private store: Store<AppState>) {}

	ngOnInit(): void {
		this.payGroup.customer = createCustomerLogoPath(this.payGroup.customer);
	}

	selectPayGroup() {
		this.router.navigate(["/pay-groups/group"], {
			state: {
				payGroup: this.payGroup
			}
		});

		this.store.pipe(take(1), select(getCustomerEntityGroupState)).subscribe({
			next: (state: CustomerEntityGroupSelect) => {
				let customerEntity = new UpdateCustomerEntityGroupAction({
					customerId: state.customerId,
					legalEntityId: state.legalEntityId,
					payGroupId: this.payGroup.id,
					customerName: state.customerName
				});
				this.store.dispatch(customerEntity);
			}
		});
	}
}
