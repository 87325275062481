import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { ServiceProviderService } from "@shared/services/service-provider/service-provider.service";
import { AccountField } from "src/app/shared/models/account-field.interface";
import { SelectOption } from "@shared/models/select-option.interface";
import { formatStringsForSelect } from "@shared/utils/format-strings-select";

@Component({
	selector: "settlement-account-fields",
	templateUrl: "./settlement-account-fields.component.html",
	styleUrls: ["./settlement-account-fields.component.scss"]
})
export class SettlementAccountFieldsComponent implements OnDestroy, OnInit {
	@Output() settlementAccountForm: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
	@Output() removeField: EventEmitter<string> = new EventEmitter();

	@Input() expanded: boolean = false;
	fields!: AccountField[];

	@Input() set accessfields(fields: AccountField[]) {
		this.fields = fields;
	}

	private fieldTypes = ["STRING", "INT", "DOUBLE", "DATE", "DATE TIME"];

	private destroy$: Subject<void> = new Subject<void>();

	accountFieldsForm!: FormGroup;

	index = -1;

	selectOptionsFieldTypes: SelectOption[] = [];

	constructor(private formBuilder: FormBuilder, private readonly serviceProviderService: ServiceProviderService) {}

	ngOnInit(): void {
		this.initForm();
	}

	initForm(): void {
		this.selectOptionsFieldTypes = formatStringsForSelect(this.fieldTypes);
		this.accountFieldsForm = this.formBuilder.group({
			accountFields: this.formBuilder.array([])
		});

		this.accountFieldsForm.valueChanges.subscribe(changes => {
			this.settlementAccountForm.emit(this.accountFieldsForm);
		});

		this.settlementAccountForm.emit(this.accountFieldsForm);

		this.serviceProviderService.clearFields.subscribe(val => {
			this.clearFields();
		});
	}

	clearFields(): void {
		this.accountField.clear();
	}

	onAddClicked(): void {
		const accountsField = this.formBuilder.group({
			key: ["", Validators.required],
			name: ["", Validators.required],
			type: ["", Validators.required],
			required: [true],
			secret: [false]
		});

		accountsField
			.get("secret")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe(changes => {
				if (changes) {
					accountsField.get("required")?.patchValue(true);
					accountsField.get("type")?.patchValue("STRING");
				}
			});

		this.accountField.push(accountsField);
	}

	cancelAccountFields(i: number): void {
		this.accountField.removeAt(i);
	}

	fieldRemoved(key: string): void {
		this.removeField.emit(key);
		let current = this.fields.filter(account => account.key !== key);
		this.fields = current;
	}

	get accountField(): FormArray {
		return this.accountFieldsForm.get("accountFields") as FormArray;
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
