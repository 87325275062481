import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { FileManagementComponent } from "./file-management.component";
import { MatButtonModule } from "@angular/material/button";
import { InputAutocompleteModule } from "@shared/components/input-autocomplete/input-autocomplete.module";
import { InputWrapperModule } from "@shared/components/input-wrapper/input-wrapper.module";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { FlexLayoutModule } from "ngx-flexible-layout";
import { PaginationModule } from "@shared/components/pagination/pagination.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FilesListComponent } from "./_components/file-manager/files-list/files-list.component";
import { CustomerListModule } from "@shared/components/customer-list/customer-list.module";
import { CustomerPaygroupsComponent } from "./_components/customer-paygroups/customer-paygroups.component";
import { InputSelectModule } from "@shared/components/input-select/input-select.module";
import { MatCardModule } from "@angular/material/card";
import { PaygroupComponent } from "./_components/customer-paygroups/paygroup/paygroup.component";
import { MatListModule } from "@angular/material/list";
import { FileManagerComponent } from "./_components/file-manager/file-manager.component";
import { UploadDialogComponent } from "./_components/file-manager/upload-dialog/upload-dialog.component";
import { FileDetailComponent } from "./_components/file-manager/file-detail/file-detail.component";
import { PipesModule } from "../../shared/pipes/pipes.module";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { ErrorViewComponent } from "./_components/file-manager/upload-dialog/error-view/error-view.component";
import { CustomerLegalEntitySelectModule } from "@shared/components/customer-legal-entity-select/customer-legal-entity-select.module";
import { MatDividerModule } from "@angular/material/divider";
import { NoItemsLayoutModule } from "@shared/components/no-items-layout/no-items-layout.module";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ErrorDialogModule } from "@shared/components/error-dialog/error-dialog.module";
import { SharedModule } from "@shared/shared.module";
import { ModalWrapperModule } from "@shared/components/modal-wrapper/modal-wrapper.module";
import { FileErorrDialogComponent } from "./_components/file-error-dialog/file-erorr-dialog/file-erorr-dialog.component";
import { PaymentsModule } from "../dashboard/payments.module";
import { FileManagementRoutingModule } from "./file-management-routing.module";

@NgModule({
	declarations: [
		FileManagementComponent,
		FilesListComponent,
		CustomerPaygroupsComponent,
		PaygroupComponent,
		FileManagerComponent,
		UploadDialogComponent,
		FileDetailComponent,
		ErrorViewComponent,
		FileErorrDialogComponent
	],
	imports: [
		CommonModule,
		MatIconModule,
		MatButtonModule,
		InputAutocompleteModule,
		InputWrapperModule,
		MatFormFieldModule,
		MatInputModule,
		MatMenuModule,
		FlexLayoutModule,
		PaginationModule,
		FormsModule,
		ReactiveFormsModule,
		CustomerListModule,
		InputSelectModule,
		MatCardModule,
		MatListModule,
		MatProgressSpinnerModule,
		MatProgressBarModule,
		PipesModule,
		PaginationModule,
		CustomerLegalEntitySelectModule,
		MatDividerModule,
		NoItemsLayoutModule,
		MatPaginatorModule,
		MatTooltipModule,
		ErrorDialogModule,
		SharedModule,
		ModalWrapperModule,
		PipesModule,
		PaymentsModule,
		FileManagementRoutingModule
	]
})
export class FileManagementModule {}
