import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

import { ToastComponent } from "@shared/components/toast/toast.component";

@Injectable({
	providedIn: "root"
})
export class ToastService {
	constructor(private readonly _snackBar: MatSnackBar) {}

	showSuccess(message: string) {
		const modifiedMessage = message.replace(/\n/g, "<br>"); // Convert '\n' to '<br>' for line breaks
		this._snackBar.openFromComponent(ToastComponent, {
			verticalPosition: "bottom",
			horizontalPosition: "center",
			duration: 5000,
			panelClass: ["success"],
			data: { message: modifiedMessage, snackType: "success" }
		});
	}

	showError(message: string) {
		const modifiedMessage = message.replace(/\n/g, "<br>");
		this._snackBar.openFromComponent(ToastComponent, {
			verticalPosition: "bottom",
			horizontalPosition: "center",
			duration: 5000,
			panelClass: ["danger"],
			data: { message: modifiedMessage, snackType: "danger" }
		});
	}

	showWarning(message: string) {
		const modifiedMessage = message.replace(/\n/g, "<br>");
		this._snackBar.openFromComponent(ToastComponent, {
			verticalPosition: "bottom",
			horizontalPosition: "center",
			duration: 4000,
			panelClass: ["warning"],
			data: { message: modifiedMessage, snackType: "warning" }
		});
	}

	showInfo(message: string) {
		const modifiedMessage = message.replace(/\n/g, "<br>");
		this._snackBar.openFromComponent(ToastComponent, {
			verticalPosition: "bottom",
			horizontalPosition: "center",
			duration: 4000,
			panelClass: ["info"],
			data: { message: modifiedMessage, snackType: "info" }
		});
	}
}
