import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable, Output } from "@angular/core";
import { DefaultDataService, EntityHttpResourceUrls, HttpUrlGenerator, MergeStrategy, QueryParams } from "@ngrx/data";
import { environment } from "../../../../environments/environment";
import { ErrorModel, ErrorModelPagination } from "../models/error-management.models";
import { map } from "rxjs/operators";
import { Update } from "@ngrx/entity";

@Injectable({
	providedIn: "root"
})
export class ErrorManagementDataService extends DefaultDataService<ErrorModel> {
	private pageSize: string = "10";
	private pageIndex: string = "0";
	private totalPageCount: number = 0;

	@Output() _pageSize: EventEmitter<string> = new EventEmitter<string>();
	@Output() _pageIndex: EventEmitter<string> = new EventEmitter<string>();
	@Output() _totalPageCount: EventEmitter<string> = new EventEmitter<string>();

	constructor(private httpClient: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
		super("ErrorManagement", httpClient, httpUrlGenerator);
		EntityHttpResourceUrls;
		httpUrlGenerator.registerHttpResourceUrls({
			ErrorManagement: {
				entityResourceUrl: `${environment.apiUri}/v1/provider-messages/`,
				collectionResourceUrl: `${environment.apiUri}/v1/provider-messages/`
			}
		});
	}

	public set setPageSize(pageSize: string) {
		this.pageSize = pageSize;
	}
	public set setPageIndex(pageIndex: string) {
		this.pageIndex = pageIndex;
	}

	//override getWithQuery so we only return the items array
	getWithQuery(queryParams: QueryParams) {
		const searchParams = `errorTypes=${queryParams.errorTypes ? queryParams.errorTypes : ""}&inputMessages=${
			queryParams.searchString ? queryParams.searchString : ""
		}&outputMessages=${queryParams.searchString ? queryParams.searchString : ""}&providerNames=${
			queryParams.providerNames ? queryParams.providerNames : ""
		}`;

		// errorTypes: searchFilters.errorType,
		// 	inputMessages: searchFilters.searchString,
		// 	outputMessages: searchFilters.searchString,
		// 	providerNames:

		return this.httpClient
			.get<ErrorModelPagination>(
				`${
					environment.apiUri
				}/v1/provider-messages/?pageNumber=${this.pageIndex.toString()}&pageSize=${this.pageSize.toString()}&${searchParams}`
			)
			.pipe(
				map((data: ErrorModelPagination) => {
					this.totalPageCount = data.totalCount;
					this._pageIndex.emit(this.pageIndex);
					this._pageSize.emit(this.pageSize);
					this._totalPageCount.emit(this.totalPageCount.toString());
					return data.items;
				})
			);
	}

	update(data: Update<ErrorModel>) {
		return this.httpClient.put<ErrorModel>(`${environment.apiUri}/v1/provider-messages`, data.changes);
	}
}
