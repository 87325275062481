<div class="header">{{ !readonly ? "Output Message" : "Input Message" }}</div>

<div class="content" [formGroup]="form">
	<mat-dialog-content *ngIf="!readonly">
		<mat-form-field id="note" class="full-width" appearance="outline">
			<textarea matInput rows="8" [cols]="20" formControlName="outputMessage"></textarea>
		</mat-form-field>
	</mat-dialog-content>
	<mat-dialog-content *ngIf="readonly">
		<mat-form-field id="note" class="full-width" appearance="outline">
			<textarea matInput rows="8" [cols]="20" formControlName="inputMessage"></textarea>
		</mat-form-field>
	</mat-dialog-content>

	<mat-dialog-actions *ngIf="!readonly" class="actions">
		<button id="closeButtonEditError" class="mat-stroked-button cancel-btn" (click)="close()">Close</button>
		<button id="saveButtonEditError" class="mat-stroked-button submit-btn" (click)="save()">Save</button>
	</mat-dialog-actions>
	<mat-dialog-actions *ngIf="readonly" class="actions">
		<button id="closeButtonEditError" class="mat-stroked-button cancel-btn" (click)="close()">Close</button>
	</mat-dialog-actions>
</div>
