import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "processPayoutMenu"
})
export class ProcessPayoutMenuPipe implements PipeTransform {
	transform(value: string): string | undefined {
		switch (value) {
			case "BANK":
				return "Pay to Bank";
			case "CARD":
				return "Pay to Card";
			case "FALLBACK":
				return "Pay to Fall back";
		}
		return void 0;
	}
}
