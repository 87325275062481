import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { map, take, takeUntil } from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import { Store } from "@ngrx/store";
import { ApiService } from "@modules/pay-groups/services/api.service";
import { LegalEntityService } from "@shared/services/legal-entity/legal-entity.service";
import { PayGroup, updateEmployeePayGroupDTO } from "@shared/models/pay-groups";
import { SelectOption } from "@shared/models/select-option.interface";
import { Employee } from "src/app/shared/models/employee.interface";
import { BreadcrumbService } from "@shared/components/breadcrumbs/services/breadcrumb.service";
import { AppState } from "@store/models/state.model";
import { ToastService } from "@shared/services/toast/toast.service";

@Component({
	selector: "app-entity-paygroup-select-dialog",
	templateUrl: "./entity-paygroup-select-dialog.component.html",
	styleUrls: ["./entity-paygroup-select-dialog.component.scss"]
})
export class EntityPaygroupSelectDialogComponent implements OnInit, OnDestroy {
	destroy$: Subject<void> = new Subject();
	entities$: Observable<SelectOption[]> = new Observable<SelectOption[]>();
	payGroups$: Observable<SelectOption[]> = new Observable<SelectOption[]>();
	payGroupOptions: SelectOption[] = [];
	employeeVersion!: number;
	form!: FormGroup;
	currentCustomerLegalEntities: string[] = [];

	constructor(
		@Inject(MAT_DIALOG_DATA)
		public data: { customerId: string; legalEntityId: string; payGroup: PayGroup; employeeId: string },
		public dialogRef: MatDialogRef<EntityPaygroupSelectDialogComponent>,
		private legalEntityService: LegalEntityService,
		private payGroupService: ApiService,
		private formBuilder: FormBuilder,
		private breadCrumbService: BreadcrumbService,
		private store: Store<AppState>,
		private toastService: ToastService
	) {}

	ngOnInit(): void {
		this.getEntities();
		this.initForm();
		this.initSubscriptions();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	initForm(): void {
		this.form = this.formBuilder.group({
			payGroup: [this.data.payGroup.id]
		});
	}

	initSubscriptions(): void {
		this.getEmployeeVersion(this.data.employeeId);
	}

	onCancel(): void {
		this.dialogRef.close(false);
	}

	getEmployeeVersion(employeeId: string) {
		this.payGroupService
			.getEmployee(employeeId)
			.pipe(
				take(1),
				map((employee: Employee) => {
					this.employeeVersion = employee.version!;
				})
			)
			.subscribe();
	}

	getEntities(): void {
		this.entities$ = this.legalEntityService.getLegalEntitiesForCustomerAsSelectOption(this.data.customerId);

		this.entities$.pipe(take(1)).subscribe({
			next: res => {
				res.forEach(element => {
					this.currentCustomerLegalEntities.push(element.value);
				});
				this.getPayGroups(this.currentCustomerLegalEntities);
			}
		});
	}

	getPayGroups(legalEntityIds: string[]): void {
		const statuses: string = "LIVE, IMPLEMENTATION";
		this.payGroupService
			.getPayGroupsByLegalEntityIdAsSelectOptions([], statuses, this.data.customerId)
			.pipe(
				takeUntil(this.destroy$),
				map(groups => {
					this.payGroupOptions = groups.filter(group => group.value !== this.data.payGroup.id);
				})
			)
			.subscribe();
	}

	onConfirm(): void {
		this.dialogRef.close(this.form.getRawValue());

		const updateEmployeePayGroupDTO: updateEmployeePayGroupDTO = {
			payGroupId: this.form.get("payGroup")?.value,
			version: this.employeeVersion,
			employeeId: this.data.employeeId
		};

		this.payGroupService
			.updateEmployeePayGroup(updateEmployeePayGroupDTO)
			.pipe(take(1))
			.subscribe({
				next: () => {
					// update customer entity group to
					this.breadCrumbService.updateCustomerEntityGroupStatePayGroup(this.form.get("payGroup")?.value);
					this.toastService.showSuccess("Employee updated successfully");
					console.log("PATCH request successful");
					location.reload();
				},
				error: error => {
					console.log("Error during PATCH request");
					console.error(error);
				}
			});
	}
}
