import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UserManagementComponent } from "./user-management.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTableModule } from "@angular/material/table";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlexLayoutModule } from "ngx-flexible-layout";
import { InputWrapperModule } from "@shared/components/input-wrapper/input-wrapper.module";
import { InputSelectModule } from "@shared/components/input-select/input-select.module";

import { UserManagementTableComponent } from "./_components/user-management-table/user-management-table.component";
import { UserCreationDialogModule } from "./_components/user-creation-dialog/user-creation-dialog.module";
import { DeleteDialogModule } from "@shared/components/delete-dialog/delete-dialog.module";
import { UserService } from "./_services/user.service";
import { SharedModule } from "@shared/shared.module";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { PipesModule } from "src/app/shared/pipes/pipes.module";
import { UserLogListComponent } from "./_components/user-log-list/user-log-list.component";
import { UserManagementRoutingModule } from "./user-management-routing.module";
import { BreadcrumbsModule } from "@shared/components/breadcrumbs/breadcrumbs.module";
import { NoItemsLayoutModule } from "@shared/components/no-items-layout/no-items-layout.module";
import { SlidingPanelModule } from "@shared/components/sliding-panel/sliding-panel.module";

@NgModule({
	declarations: [UserManagementComponent, UserManagementTableComponent, UserLogListComponent],
	providers: [UserService],
	imports: [
		CommonModule,
		DeleteDialogModule,
		FlexLayoutModule,
		FormsModule,
		InputWrapperModule,
		InputSelectModule,
		MatDialogModule,
		MatFormFieldModule,
		MatTooltipModule,
		MatIconModule,
		MatInputModule,
		MatTableModule,
		MatPaginatorModule,
		MatSlideToggleModule,
		ReactiveFormsModule,
		SharedModule,
		UserCreationDialogModule,
		PipesModule,
		UserManagementRoutingModule,
		BreadcrumbsModule,
		NoItemsLayoutModule,
		SlidingPanelModule
	]
})
export class UserManagementModule {}
