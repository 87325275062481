<!-- Label -->
<div *ngIf="label" class="c-text-label">
	{{ label }}<mat-icon class="icon" *ngIf="icon">{{ icon }}</mat-icon>
</div>

<!-- Input -->
<div class="input">
	<ng-content></ng-content>
</div>

<!-- Input errors -->
<div class="input-errors">
	<ng-content select="[input-errors]"></ng-content>
</div>
