import { SubService } from "src/app/shared/models/tpp-service.interface";

export interface downloadTPPFile {
	fileId: string;
	fileName: string;
}

export interface tppFileWithPagination {
	totalCount: number;
	totalPages: number;
	pageNumber: number;
	items: tppFile[];
}

export interface tppFile {
	taskId?: string;
	fileId?: string;
	createdAt: Date;
	processedAt?: Date;
	fileName?: string;
	fileType?: string;
	status?: FilesStatus;
	meta?: tppFileMetaData;
	subServiceName?: string;
	userReset?: string;
	userCancelled?: string;
}

export interface tppFileMetaData {
	SERVICES: string[];
}

export enum FilesStatus {
	FAILED = "FAILED",
	PENDING = "PENDING",
	SUCCESS = "SUCCESSFUL"
}

export interface TppSubService {
	subService: SubService;
	paymentBy: string;
	reference: string | null;
	referenceOkay: boolean | null;
	amount: Amount;
	uploadedBy: UploadMethod[];
	valueDate: Date | null;
	countByStatus: {
		[key in PayoutStatus]: number;
	};
}

export enum PayoutStatus {
	CREATED = "CREATED",
	FAILED = "FAILED",
	SUCCESS = "SUCCESS",
	PENDING = "PENDING",
	PROCESSED = "PROCESSED",
	PROCESSING = "PROCESSING",
	INACTIVE = "INACTIVE",
	CANCELLED = "CANCELLED"
}

export interface Amount {
	value: number;
	currency: string;
}

export enum ReferenceStatus {
	FAILED = "FAILED",
	PENDING = "PENDING",
	SUCCESS = "SUCCESS"
}

export enum UploadMethod {
	FILE = "FILE",
	MANUAL = "MANUAL",
	PENDING = "PENDING"
}

export interface TppSubServiceDetails {
	paymentType: string;
	employeeId?: string;
	employeeName?: string;
	subserviceName?: string;
	reference: string;
	amount: Amount;
	valueDate: Date | null;
	uploaded: UploadMethod[];
}

export interface TppSubServiceDetailsWithPagination {
	items: TppSubServiceDetails[];
	totalCount: number;
	totalPages: number;
	pageNumber: number;
}

export interface uploadDataInputDTO {
	file: any;
	payCycleId: string;
	requestId?: string;
	fileType?: string;
	executeAt?: string;
	sheetIndex?: number;
	headerIndex?: number;
	group: string;
}

export interface getEmployeeDataParams {
	subService: TppSubService;
	pageSize: number;
	pageNumber: number;
}
