<mat-form-field
	(click)="onClick()"
	class="autocomplete"
	appearance="fill"
	[ngClass]="{
		'no-label': !label,
		'label-disappears': hideLabel,
		'has-image': control.value?.imagePath
	}"
>
	<!-- Label -->
	<mat-label
		class="placeholder"
		*ngIf="label && hideLabel && (control.value?.length === 0 || control.value === null)"
	>
		{{ label }} {{ control.value?.text }}
	</mat-label>

	<mat-label class="placeholder" *ngIf="label && !hideLabel">{{ label }}</mat-label>

	<!-- Icon image -->
	<div class="icon" *ngIf="control.value?.imagePath"><img [src]="control.value?.imagePath" /></div>

	<!-- Icon element status-->
	<div
		*ngIf="control.value?.value === 'active' || control.value?.value === 'inactive'"
		class="status-circle"
		[ngClass]="{
			'status-circle--active': control.value?.value === 'active',
			'status-circle--inactive': control.value?.value === 'inactive'
		}"
	></div>

	<!-- Input type ahead -->
	<input
		class="type-ahead"
		type="text"
		autocomplete="off"
		matInput
		[formControl]="control"
		[matAutocomplete]="auto"
		#input
	/>

	<!-- Arrow down icon -->
	<app-icon
		id="drop-down-arrow"
		[color]="'rgba(0, 0, 0, 0.54)'"
		[lineWidth]="'2.5'"
		[name]="'arrow-down'"
		[size]="'16'"
	></app-icon>

	<!-- Clear icon -->
	<!-- <div class="clear-icon-container">
	<app-icon [name]="'close-circle-bold'" [fill]="'rgba(0, 0, 0, 0.54)'" [color]="'transaprent'"  (click)="clearInput($event)"></app-icon>
</div> -->

	<mat-autocomplete
		[displayWith]="formatOptionForDisplay"
		(optionSelected)="onOptionSelected($event.option.value)"
		#auto="matAutocomplete"
	>
		<!-- Options -->
		<mat-option
			*ngFor="let option of filteredOptions$ | async"
			[value]="option"
			[disabled]="option.disabled"
			class="custom-option"
		>
			<!-- With tooltip message -->
			<ng-container *ngIf="option.tooltipMessage">
				<div [matTooltip]="option.tooltipMessage">
					<div class="icon" *ngIf="option.imagePath"><img src="{{ option.imagePath }}" /></div>
					<div
						*ngIf="option.value === 'active' || option.value === 'inactive'"
						class="status-circle"
						[ngClass]="{
							'status-circle--active': option.value === 'active',
							'status-circle--inactive': option.value === 'inactive'
						}"
					></div>
					{{ option.text }}
				</div>
			</ng-container>

			<!-- Without tooltip message -->
			<ng-container *ngIf="!option.tooltipMessage">
				<div class="icon" *ngIf="option.imagePath"><img src="{{ option.imagePath }}" /></div>
				<div
					*ngIf="option.value === 'active' || option.value === 'inactive'"
					class="status-circle"
					[ngClass]="{
						'status-circle--active': option.value === 'active',
						'status-circle--inactive': option.value === 'inactive'
					}"
				></div>
				{{ option.text }}
			</ng-container>
		</mat-option>
	</mat-autocomplete>
</mat-form-field>
<mat-progress-bar *ngIf="loading" mode="indeterminate" style="border-radius: 5px; top: -3px"></mat-progress-bar>
