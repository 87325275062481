import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { PayGroupHistory, PayGroupHistoryReport, statusType } from "src/app/shared/models/pay-groups";

@Component({
	selector: "app-paygroup-status-history",
	templateUrl: "./paygroup-status-history.component.html",
	styleUrls: ["./paygroup-status-history.component.scss"]
})
export class PaygroupStatusHistoryComponent implements OnInit, OnDestroy {
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: PayGroupHistoryReport,
		public dialogRef: MatDialogRef<PayGroupHistoryReport>
	) {}

	history!: PayGroupHistory[];
	type!: statusType;
	title: string = "";
	destroy$ = new Subject<void>();

	ngOnInit(): void {
		//get Provider message and g2n/data input id

		this.history = this.data.history;
		this.type = this.data.type;
		this.title = this.data.title;
	}

	onCancelClick() {
		this.dialogRef.close(true);
	}

	onBackClick() {
		this.dialogRef.close(false);
	}

	close() {
		this.dialogRef.close(false);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
