<app-breadcrumbs [headerTitle]="'REPORTING'" />
<div class="main-container">
	<div class="flex flex-column">
		<customer-legal-entity-select
			(customerEntitySelection)="selectionChange($event)"
			[enableSelectAll]="true"
			[payGroupSelector]="true"
			[payGroupStatus]="'LIVE, IMPLEMENTATION'"
			[customerPaygroupStatus]="'LIVE, IMPLEMENTATION'"
			[updateCustomerSelectState]="false"
		></customer-legal-entity-select>

		<div [formGroup]="form" class="reporting">
			<div class="reporting-inputs">
				<input-wrapper [label]="'Start Date'" readonly>
					<mat-form-field appearance="outline" class="date-form-fields" (click)="picker1.open()">
						<input formControlName="startDate" matInput [matDatepicker]="picker1" readonly />
						<mat-datepicker-toggle matSuffix [for]="picker1">
							<app-icon matDatepickerToggleIcon [name]="'calendar_month'"></app-icon>
						</mat-datepicker-toggle>
						<mat-datepicker #picker1></mat-datepicker>
					</mat-form-field>
				</input-wrapper>

				<input-wrapper [label]="'End Date'" readonly clear>
					<mat-form-field appearance="outline" class="date-form-fields" (click)="picker2.open()">
						<input formControlName="endDate" matInput [matDatepicker]="picker2" readonly />
						<mat-datepicker-toggle matSuffix [for]="picker2">
							<app-icon matDatepickerToggleIcon [name]="'calendar_month'"></app-icon>
						</mat-datepicker-toggle>
						<mat-datepicker #picker2></mat-datepicker>
					</mat-form-field>
				</input-wrapper>
			</div>

			<div class="reporting-buttons">
				<button
					class="download-btn"
					mat-raised-button
					color="primary"
					id="submit"
					[matTooltip]="
						!canDownloadReport
							? 'You do not have the correct permissions to download this file'
							: 'Please fill in the required fields'
					"
					(click)="downloadTransactions()"
					[disabled]="form.invalid || !canDownloadReport"
				>
					<app-icon id="download-icon" [name]="'document-download'" [color]="'white'"></app-icon>Download
				</button>
			</div>
		</div>
	</div>
</div>
