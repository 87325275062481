<div class="flex flex-column">
	<div class="table-header flex flex-row">
		<div class="header-text flex flex-1" style="--max-width: 20%" id="date">DATE</div>
		<div class="header-text flex flex-1" style="--max-width: 35%" id="name">FILE NAME</div>
		<div class="header-text flex flex-1" style="--max-width: 20%" id="type">DATA SOURCE</div>
		<div class="header-text flex flex-1" style="--max-width: 15%" id="status">STATUS</div>
		<div class="header-text flex flex-1" style="--max-width: 5%" id="detail"></div>
		<div class="header-text flex flex-1" style="--max-width: 5%" id="download"></div>
	</div>

	<div class="active-list-item data-row flex flex-row" *ngFor="let file of _files">
		<div class="table-body flex flex-align-center-center">
			<div class="table-row-item flex flex-1" style="--max-width: 20%" id="date">
				{{ file.createdAt | date : "medium" }}
			</div>
			<div
				*ngIf="file.fileId !== ''"
				[matTooltip]="file.fileName"
				class="table-row-item flex flex-1"
				style="--max-width: 35%"
				id="name"
			>
				{{ file.fileName }}
			</div>
			<div *ngIf="file.fileId === ''" class="table-row-item flex flex-1" style="--max-width: 35%" id="name">
				-
			</div>
			<div
				*ngIf="file.fileId !== ''"
				[matTooltip]="file.fileType"
				class="table-row-item flex flex-1"
				style="--max-width: 20%"
				id="type"
			>
				{{ file.fileType | uploadFileType }}
			</div>
			<div
				*ngIf="file.fileId === ''"
				[matTooltip]="'Payroll integration'"
				class="table-row-item flex flex-1"
				style="--max-width: 20%"
				id="type"
			>
				Payroll Integration
			</div>
			<div
				class="table-row-item flex flex-1"
				style="--max-width: 15%"
				id="status"
				[ngClass]="{
					'status-green': file.status === 'SUCCESS',
					'status-red': file.status === 'FAILED',
					'status-pending': file.status === 'PENDING'
				}"
			>
				<span class="status-text" [matTooltip]="file.status === 'FAILED' ? 'HAS ERRORS' : file.status">
					{{ file.status === "FAILED" ? "HAS ERRORS" : file.status }}</span
				>
			</div>
			<div id="detail" class="table-row-item flex flex-1" style="--max-width: 5%">
				<app-icon
					*ngIf="file.status === 'FAILED'"
					[name]="'eye'"
					id="eyeIcon"
					[matTooltip]="'Detail'"
					(click)="onViewFile(file.taskId)"
				></app-icon>
			</div>
			<div id="download" class="table-row-item flex flex-1" style="--max-width: 5%">
				<app-icon
					*ngIf="file.fileId !== ''"
					[name]="'document-download'"
					id="downloadIcon"
					[matTooltip]="'Download'"
					class="material-icons-outlined"
					(click)="downloadFile(file.fileId, file.fileName)"
				></app-icon>
			</div>
		</div>
	</div>
</div>
