import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { PermissionsService } from "@shared/services/permissions/permissions.service";
import { isEqual } from "lodash";
import { Observable, Subject, forkJoin, of } from "rxjs";
import { catchError, map, take, takeUntil } from "rxjs/operators";

import { ModalLauncherService } from "@shared/components/wpay-ui/modal/modal-launcher.service";
import { ActionConfirmModalData } from "@shared/components/wpay-ui/modal/templates/action-confirm-dialog/action-confirm-dialog.component";
import { OrderServicesTypePipe } from "@modules/tpp-catalogue/pipes/order-services-type.pipe";
import { PaygroupsService } from "@shared/services/paygroups/paygroups.service";
import { ToastService } from "@shared/services/toast/toast.service";
import { TppServicesService } from "@shared/services/tpp-services/tpp-services.service";
import { PayGroup } from "src/app/shared/models/pay-groups";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { TppServicesByByPayGroupViewModel } from "src/app/shared/models/service-definition/TppServiceDefinitionViewModel";
import { BeneficiaryStatusByProviderSummary } from "src/app/shared/models/service-definition/TtpBeneficiaryStatusByProviderSummary";
import {
	ClientSpecificSubService,
	TPPServiceDefinitionDTO,
	TppService,
	TppServiceWithGroup
} from "src/app/shared/models/tpp-service.interface";
import { indicate } from "src/app/shared/utils/rxjs-util";
import { getCustomerEntityGroupState } from "src/app/store";
import { UpdateSelectedTPPService } from "src/app/store/actions/tppServiceSelect.action";
import { AppState } from "src/app/store/models/state.model";
import { TppServiceDefinitionService } from "../../_services/tpp-service-definition/tpp-service-definition.service";
import { TtpServiceSelectionService } from "../../_services/tpp-services/ttp-service-selection.service";
import { SelectedBeneficiaryStatusByProviderFilters } from "./beneficary-status-by-provider-summary/beneficary-status-by-provider-summary.component";
import { breadCrumbButtonIcon } from "@shared/models/breadcrumbs.interface";

@Component({
	selector: "app-tpp-service-selection",
	templateUrl: "./tpp-service-selection.component.html",
	styleUrls: ["./tpp-service-selection.component.scss"]
})
export class TppServiceSelectionComponent implements OnInit, OnDestroy {
	payGroup!: PayGroup;
	defaultSelectedProviderName: string = "";
	selectedServices!: Map<string, TppService[]>;
	availableServices: TppService[] = [];
	servicesWithGroups: TppServiceWithGroup[] = [];
	servicesAsSelectOption!: SelectOption[];
	serviceSelectForm!: FormGroup;
	formInitialized: boolean = false;
	groupsButtonDisabled: boolean = true;
	iso2CountryCode: string = "";
	serviceDefintionCheck!: TPPServiceDefinitionDTO;
	selectedServiceTypes: string[] = [];
	loading$ = new Subject<boolean>();
	paymentSummary$!: Observable<BeneficiaryStatusByProviderSummary>;
	ttpServices$!: Observable<TppServicesByByPayGroupViewModel>;
	providersAssignedToPayGroup$!: Observable<string[]>;
	destroy$: Subject<void> = new Subject<void>();
	countryHasNoServices: boolean = false;
	isLoading!: boolean;
	canEditListOfServices = false;
	canAddAdhocService = false;
	pgID = "";
	breadCrumbIcon: breadCrumbButtonIcon = {
		name: "add",
		lineWidth: "2.5",
		color: "var(--color-primary-1000)",
		size: "16"
	};

	constructor(
		private router: Router,
		private formBuilder: FormBuilder,
		private store: Store<AppState>,
		private tppServicesService: TppServicesService,
		private payGroupService: PaygroupsService,
		private toast: ToastService,
		private tppServiceDefitionService: TppServiceDefinitionService,
		private tppServiceSelectionService: TtpServiceSelectionService,
		private modalLauncherService: ModalLauncherService,
		private permissions: PermissionsService
	) {}

	ngOnInit(): void {
		this.permissions
			.canEditListOfServices()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => {
				this.canEditListOfServices = res;
				this.setupFromStoreState();
			});

		this.permissions
			.canAddAdhocService()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => {
				this.canAddAdhocService = res;
			});

		this.loading$.pipe(takeUntil(this.destroy$)).subscribe({
			next: res => {
				this.isLoading = res;
			}
		});
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	selectTppService(tppServiceId: string) {
		let tppService = this.availableServices.find(tppService => {
			return tppService.id === tppServiceId;
		});

		this.tppServicesService.searchTppSubServices(tppServiceId, "0", "10").subscribe(response => {
			if (response.items.length) {
				this.store.dispatch(
					new UpdateSelectedTPPService({
						selectedTPPService: {
							countryCode: "",
							tppService: tppService!,
							selectedServices: this.serviceSelectForm.get("service_type")!.value,
							selectedPayGroup: this.payGroup!
						}
					})
				);
				this.router.navigate(["/service-definition/tpp/service-configuration"]).then(() => {
					this.ngOnDestroy();
				});
			} else {
				this.toast.showInfo(`No Sub Services Associated with Service`);
			}
		});
	}

	addAdhocService() {
		if (this.canAddAdhocService) {
			this.router.navigate(["/service-definition/tpp/adhoc-service"]);
		}
	}

	navigateToGroupConfig() {
		this.router.navigate(["/service-definition/tpp/group-configuration"]);
	}

	onProviderFilterChange(event: SelectedBeneficiaryStatusByProviderFilters) {
		this.loadServiceDefinitions(event);
	}

	private setupFromStoreState() {
		this.store.pipe(takeUntil(this.destroy$), select(getCustomerEntityGroupState)).subscribe(state => {
			let PAY_GROUP_ID;
			if (state && state.payGroupId && state.payGroupId !== this.pgID) {
				PAY_GROUP_ID = state.payGroupId;
				this.pgID = state.payGroupId;

				if (PAY_GROUP_ID !== "") {
					const PAY_GROUP$ = this.payGroupService.getPaygroupWithPaygroupId(PAY_GROUP_ID);
					const PROVIDERS_ASSIGNED_TO_PAYGROUP$ =
						this.tppServiceSelectionService.findAllTppPaymentProvidersAssignedToPayGroupId(PAY_GROUP_ID);

					forkJoin([PAY_GROUP$, PROVIDERS_ASSIGNED_TO_PAYGROUP$])
						.pipe(take(1), indicate(this.loading$))
						.subscribe(([paygroup, providernames]) => {
							this.payGroup = paygroup;
							this.setupServiceDefinitions(paygroup);
						});
				}
			}
		});
	}

	private setupServiceDefinitions(paygroup: PayGroup) {
		this.selectedServices = new Map<string, TppService[]>();
		this.iso2CountryCode = paygroup?.legalEntity?.data?.country;

		this.tppServiceDefitionService
			.getServiceDefinitionTemp(paygroup.id)
			.pipe(
				take(1),
				catchError(error => {
					if (error.error.message === "TPP service definition not found") {
						this.servicesAsSelectOption = [];
						this.formInitialized = false;
					} else {
						this.setupservicesOptions(this.iso2CountryCode, this.payGroup.id);
						this.formInitialized = true;
					}

					return of(null);
				})
			)
			.subscribe();

		this.tppServiceDefitionService.latestServiceDefUpdated.pipe(takeUntil(this.destroy$)).subscribe(res => {
			if (!isEqual(this.serviceDefintionCheck, res)) {
				this.serviceDefintionCheck = res;
				this.loadServiceData();
			}
		});

		this.tppServiceDefitionService.refreshList.pipe(takeUntil(this.destroy$)).subscribe(res => {
			if (res) {
				this.loadServiceData();
			}
		});
	}

	loadServiceData() {
		this.tppServiceDefitionService
			.serchTPPServiceDefintion(this.payGroup.id, "", "", "", "0", "-1")
			.pipe(take(1))
			.subscribe({
				next: res => {
					const groupConfig = res?.items[0]?.groupConfig;

					if (groupConfig?.length) {
						// Extract unique provider names
						const uniqueProviderNames = [...new Set(groupConfig!.map(item => item.providerName))];

						this.defaultSelectedProviderName = uniqueProviderNames.map(
							providerName => ({ value: providerName, text: providerName } as SelectOption)
						)[0].text;
					} else {
						this.defaultSelectedProviderName = "";
					}

					this.setupservicesOptions(this.iso2CountryCode, this.payGroup.id);
					this.updateTppServices(this.payGroup.id, this.defaultSelectedProviderName);
				}
			});
	}

	private updateTppServices(payGroupId: string, provider: string) {
		if (payGroupId === this.pgID) {
			this.ttpServices$ = this.tppServiceSelectionService
				.getServicesByPayGroupAndProvider(payGroupId, provider, "ALL")
				.pipe(
					take(1),
					indicate(this.loading$),
					map(res => {
						this.tppServiceSelectionService.setServicesArray(res);
						return res;
					})
				);

			this.paymentSummary$ = this.tppServiceSelectionService
				.getPaymentSummaryByPayGroup(payGroupId, provider)
				.pipe(take(1), indicate(this.loading$));
		}
	}

	private setupservicesOptions(country: string, paygroupId: string) {
		const PAGE_INDEX: number = 0;
		const PAGE_SIZE: number = -1;

		const SERVICE_DEFINITIONS_BY_COUNTRY$ = this.tppServicesService.searchTPPServiceByCountry(
			country,
			PAGE_INDEX.toString(),
			PAGE_SIZE.toString()
		);

		const SERVICE_DEFINITIONS_BY_PAY_GROUP$ = this.tppServicesService.searchTPPServiceByPaygroupId(
			paygroupId,
			PAGE_INDEX.toString(),
			PAGE_SIZE.toString()
		);

		forkJoin([SERVICE_DEFINITIONS_BY_COUNTRY$, SERVICE_DEFINITIONS_BY_PAY_GROUP$, of(this.serviceDefintionCheck)])
			.pipe(
				take(1),
				indicate(this.loading$),
				map(([serviceByCountryPaginition, serviceByPaygroupIdPaginition, serviceDef]) => {
					let allservices: TppServiceWithGroup[] = serviceByPaygroupIdPaginition.items.concat(
						serviceByCountryPaginition.items
					);

					this.servicesWithGroups = [];

					if (allservices.length) {
						this.countryHasNoServices = false;
						if (serviceDef && serviceDef.services) {
							allservices.forEach(serviceByCountryAndPaygroup => {
								serviceByCountryAndPaygroup.groups = [];

								let filteredServices: ClientSpecificSubService[] = serviceDef.services.filter(
									service => service.serviceId === serviceByCountryAndPaygroup.id
								);

								filteredServices.map(service => {
									if (service.group) {
										serviceByCountryAndPaygroup.groups!.push(service.group);
									}
								});

								this.servicesWithGroups.push(serviceByCountryAndPaygroup);
							});
						}
						this.groupsButtonDisabled = this.servicesWithGroups.length > 0 ? false : true;
						this.getServicesByCountry(allservices);
					} else {
						this.groupsButtonDisabled = true;
						this.servicesAsSelectOption = [];
						this.countryHasNoServices = true;
					}
				}),
				catchError(error => {
					return of(false);
				})
			)
			.subscribe();
	}

	private getServicesByCountry(services: TppService[]) {
		this.availableServices = services;
		this.servicesAsSelectOption = [];

		this.availableServices.forEach(service => {
			this.servicesAsSelectOption.push({ text: service.name, value: service.id });
		});

		this.initializeForm();
	}

	private initializeForm() {
		this.serviceSelectForm = this.formBuilder.group({
			service_type: [[], Validators.required]
		});

		this.serviceSelectForm?.get("service_type")?.markAsUntouched();
		this.serviceSelectForm?.get("service_type")?.markAsPristine();

		this.formInitialized = true;

		this.serviceSelectForm
			?.get("service_type")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe(values => {
				if (values) {
					let mapSize =
						(this.selectedServices.get("OTHER_STATS")?.length ?? 0) +
						(this.selectedServices.get("TAX")?.length ?? 0) +
						(this.selectedServices.get("3RD_PARTY")?.length ?? 0);

					if (values.length < mapSize) {
						this.showDeleteSelectedServiceDialog();
					} else {
						this.addToServiceDef();
					}

					this.store.dispatch(
						new UpdateSelectedTPPService({
							selectedTPPService: {
								countryCode: "",
								tppService: null,
								selectedServices: this.serviceSelectForm.get("service_type")!.value,
								selectedPayGroup: this.payGroup!
							}
						})
					);
				}
			});

		this.checkSavedData();
	}

	private showDeleteSelectedServiceDialog(): void {
		const MODAL_DATA = {
			description: "The configuration for the selected service will be reset",
			confirmText: "Continue",
			cancelText: "Cancel"
		} as ActionConfirmModalData;

		const DIALOG_REF = this.modalLauncherService.showActionConfirmModal(MODAL_DATA);

		DIALOG_REF.afterClosed().subscribe(result => {
			if (result) {
				this.reconfigureServiceDef();
			} else {
				this.checkSavedData();
			}
		});
	}

	private checkSavedData() {
		if (
			this.serviceDefintionCheck &&
			this.serviceDefintionCheck.services &&
			this.serviceDefintionCheck.services.length > 0
		) {
			this.configuredSubServicesExistFunc(this.serviceDefintionCheck.services);
		} else {
			this.selectedServices.clear();
		}
	}

	private reconfigureServiceDef() {
		this.prepareDataForServiceDefinitionEdit(this.serviceDefintionCheck);
	}

	private prepareDataForServiceDefinitionEdit(tPPserviceDef: TPPServiceDefinitionDTO) {
		const selectedServicesFormValues = this.serviceSelectForm.getRawValue().service_type;

		let servicesToKeep: string[] = [];

		for (let i = 0; i < selectedServicesFormValues.length; i++) {
			let service = this.servicesAsSelectOption.filter(item => item.value === selectedServicesFormValues[i]);
			servicesToKeep.push(service[0].value);
		}

		tPPserviceDef.services = tPPserviceDef.services.filter(service => servicesToKeep.includes(service.serviceId));

		//Sends Filtered list to service def
		this.tppServiceDefitionService.editTPPServiceDefinition(tPPserviceDef).subscribe();
	}

	private addToServiceDef() {
		const selectedServicesFormValues = this.serviceSelectForm.getRawValue().service_type; //Gets you everything that is selected

		const selectedServices: TppService[] = [];

		for (let index = 0; index < selectedServicesFormValues.length; index++) {
			let newService: TppService | undefined = this.availableServices.find(
				availService => availService.id === selectedServicesFormValues[index]
			);

			if (newService) {
				selectedServices.push(newService);
			}
		}

		let serviceToAdd: TppService = {} as TppService;

		if (this.serviceDefintionCheck.services) {
			selectedServices.forEach(selectedService => {
				let found: ClientSpecificSubService | undefined = this.serviceDefintionCheck.services.find(
					service => service.serviceId === selectedService.id
				);

				if (!found) {
					serviceToAdd = selectedService;
				}
			});
		} else {
			//Service def is not created yet -> so it will use the first ever selected service to create it
			serviceToAdd = selectedServices[0];
		}
		const PAY_GROUP_ID = this.payGroup?.id;
		if (serviceToAdd) {
			this.tppServiceDefitionService.addSelectedServiceToServiceDefinition(
				PAY_GROUP_ID,
				serviceToAdd,
				this.serviceDefintionCheck
			);
		}
	}

	private configuredSubServicesExistFunc(servicesFromServiceDef: ClientSpecificSubService[]) {
		const selectedServices: TppService[] = [];

		servicesFromServiceDef.forEach(res => {
			const service = this.availableServices.find(service => {
				return service.id === res.serviceId;
			});
			if (service && !selectedServices.includes(service)) {
				selectedServices.push(service);
			}
		});

		this.selectedServices = new OrderServicesTypePipe().transform(selectedServices);

		let names: string[] = [];

		selectedServices.forEach(service => {
			names.push(service.id);
		});

		this.serviceSelectForm.get("service_type")!.patchValue(names, { emitEvent: false });
	}

	private loadServiceDefinitions(event: SelectedBeneficiaryStatusByProviderFilters) {
		const PAY_GROUP_ID = this.payGroup.id;

		const $TPP_SERVICES = this.tppServiceSelectionService
			.getServicesByPayGroupAndProvider(PAY_GROUP_ID, event.providerName, event.status)
			.pipe(takeUntil(this.destroy$));

		const $PAYMENT_SUMMARY = this.tppServiceSelectionService
			.getPaymentSummaryByPayGroup(PAY_GROUP_ID, event.providerName)
			.pipe(takeUntil(this.destroy$));

		forkJoin([$TPP_SERVICES, $PAYMENT_SUMMARY])
			.pipe(takeUntil(this.destroy$), indicate(this.loading$))
			.subscribe(
				([tppServices, providerSummary]: [
					TppServicesByByPayGroupViewModel,
					BeneficiaryStatusByProviderSummary
				]) => {
					// only update the component observables once loaded to prevent flickering.
					this.ttpServices$ = of(tppServices);
					this.paymentSummary$ = of(providerSummary);
				}
			);
	}

	resetPage() {
		this.ngOnInit();
	}
}
