import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "formatString"
})
export class FormatStringPipe implements PipeTransform {
	transform(value: string): string {
		// Replace underscores with spaces
		let transformedValue = value.replace(/_/g, " ");

		// Capitalize the string
		transformedValue = transformedValue.charAt(0).toUpperCase() + transformedValue.toLowerCase().slice(1);

		return transformedValue;
	}
}
