import { Component, EventEmitter, Inject, OnInit, Output } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { deleteDialog } from "src/app/shared/models/delete-dialog.interface";

@Component({
	selector: "app-delete-dialog",
	templateUrl: "./delete-dialog.component.html",
	styleUrls: ["./delete-dialog.component.scss"]
})
export class DeleteDialogComponent implements OnInit {
	@Output() onDelete: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() onCancel: EventEmitter<boolean> = new EventEmitter<boolean>();

	displayText!: string;
	btnNoText!: string;
	btnYesText!: string;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: deleteDialog,
		public dialogRef: MatDialogRef<DeleteDialogComponent>
	) {}

	ngOnInit(): void {
		this.displayText = this.data.text;
		this.btnNoText = this.data.btnNoText ? this.data.btnNoText : "No, thanks.";
		this.btnYesText = this.data.btnYesText ? this.data.btnYesText : "Yes, sure.";
	}

	onDeleteClick() {
		this.dialogRef.close(true);
	}

	onCancelClick() {
		this.dialogRef.close(false);
	}
}
