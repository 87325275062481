import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { GeoMap, HighlightCountry } from "@modules/client-landing/_models/client_landing-interface";
import { SelectOption } from "@shared/models/select-option.interface";
import { BehaviorSubject, Observable, of } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class WorldMapService {
	constructor(private http: HttpClient, private router: Router) {}

	geoJson = "/assets/leaflet-countries/allCountries.geo.json";

	countryList$: BehaviorSubject<SelectOption[]> = new BehaviorSubject<SelectOption[]>([]);
	highLightSelectOption$: EventEmitter<HighlightCountry> = new EventEmitter<HighlightCountry>();
	countrySelected$: EventEmitter<string> = new EventEmitter<string>();

	getGeoMap(): Observable<any> {
		return this.http.get(this.geoJson);
	}

	setCountryOptions(countries: SelectOption[]) {
		this.countryList$.next(countries);
	}

	getCountryOptionsAsObservable(): Observable<SelectOption[]> {
		return of(this.countryList$.value);
	}

	setHighLightCountrySelectOption(countryCode: string, highlight: boolean): void {
		if (this.isClientCountry(countryCode)) {
			this.highLightSelectOption$.emit({
				country: countryCode,
				highlight: highlight
			});
		}
	}

	setCountrySelectedFromMap(countryCode: string): void {
		if (this.isClientCountry(countryCode)) {
			this.countrySelected$.emit(countryCode);
		}
	}

	isClientCountry(country: string): boolean {
		return this.countryList$.value.some(clientCountry => clientCountry.value === country);
	}
}
