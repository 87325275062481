import { Injectable } from "@angular/core";
import { SelectOption } from "@shared/models/select-option.interface";
import { createCustomerLogoPath } from "@shared/utils/customer-logo-path.util";
import { Observable, Observer } from "rxjs";
import { take, map } from "rxjs/operators";
import { CustomerService } from "@shared/services/customer/customer.service";
import { Customer } from "src/app/shared/models/customer.interface";

@Injectable({
	providedIn: "root"
})
export class CustomerCacheService {
	allCustomersCache: Customer[] = [];

	constructor(private customerService: CustomerService) {}

	getAllCustomers(): Observable<Customer[]> {
		// Make it a little faster by caching the customers, with the tradeoff of potentially stale data.
		// (users must refresh if a new customer is added)
		return this.getFromCacheFallBackToApi().pipe(take(1));
	}

	getCustomerById(id: string): Observable<Customer> {
		return this.getAllCustomers().pipe(
			take(1),
			map(customers => customers.find(customer => customer.id === id) ?? ({} as Customer))
		);
	}

	refreshCache(): void {
		this.allCustomersCache = [];
		this.getFromCacheFallBackToApi(true).pipe(take(1)).subscribe();
	}

	getCustomersMapper(): Observable<SelectOption[]> {
		return this.getAllCustomers().pipe(
			map((customers: Customer[]) => {
				return customers.flatMap(customer => {
					const option: SelectOption = {
						value: customer.id,
						text: `${customer.externalId} - ${customer.name}`,
						imagePath: createCustomerLogoPath(customer).imagePath,
						placeholder: customer.name,
						externalId: customer.externalId ? customer.externalId : ""
					};

					return option;
				});
			})
		);
	}

	private getFromCacheFallBackToApi(refresh = false): Observable<Customer[]> {
		return new Observable(observer => {
			if (this.allCustomersCache.length === 0 || refresh) {
				this.customerService.getCustomers(false, true).subscribe(customers => {
					this.allCustomersCache = customers;
					observer.next(customers);
				});
			} else {
				observer.next(this.allCustomersCache);
			}
		});
	}
}
