import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Milestone, MilestoneGroup, MilestoneGroupName, PayCycle } from "src/app/shared/models/pay-cycle.interface";
import { PayGroup } from "src/app/shared/models/pay-groups";

@Component({
	selector: "app-calendar-event-milestone",
	templateUrl: "./calendar-event-milestone.component.html",
	styleUrls: ["./calendar-event-milestone.component.scss"]
})
export class CalendarEventMilestoneComponent implements OnInit {
	@Input() milestones: Milestone[] = [];
	@Input() group!: MilestoneGroup;
	@Input() payGroup!: PayGroup;
	@Input() payCycle!: PayCycle | undefined;
	@Input() set milestoneGroupSelected(milestoneGroup: MilestoneGroup | undefined) {
		this.selectedMilestoneGroup = milestoneGroup;
		if (this.group) {
			this.setExpandedState();
		}
	}

	@Input() set firstUnconfiguredMilestoneGroup(milestoneGroup: MilestoneGroup | undefined) {
		if (milestoneGroup && milestoneGroup.group) {
			this.selectedMilestoneGroup = milestoneGroup;
			if (this.group) {
				this.setExpandedState();
			}
		}
	}

	@Output() onMilestoneGroupSelected: EventEmitter<MilestoneGroup> = new EventEmitter<MilestoneGroup>();
	@Output() viewIconClickedEvent: EventEmitter<Milestone> = new EventEmitter<Milestone>();
	@Output() cardClickedEvent: EventEmitter<Milestone> = new EventEmitter<Milestone>();

	isMilestonesVisible: boolean = false;
	selectedMilestoneGroup!: MilestoneGroup | undefined;

	ngOnInit(): void {
		this.setExpandedState();
	}

	setExpandedState(): void {
		this.selectedMilestoneGroup && this.selectedMilestoneGroup.group === this.group.group
			? (this.isMilestonesVisible = true)
			: (this.isMilestonesVisible = false);
	}

	toggle(): void {
		this.isMilestonesVisible
			? this.onMilestoneGroupSelected.emit(undefined)
			: this.onMilestoneGroupSelected.emit(this.group);
	}

	onViewIconClicked(milestone: Milestone): void {
		this.viewIconClickedEvent.emit(milestone);
	}

	onCardClicked(milestone: Milestone): void {
		this.cardClickedEvent.emit(milestone);
	}
}
