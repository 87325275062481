<div id="serviceDescriptionModalHeader" class="header flex flex-row flex-align-center-space-between">
	<div id="serviceDescriptionModalText">SERVICE DESCRIPTION</div>
	<div id="closeIconContainer" (click)="close()" class="container-icon">
		<app-icon id="closeIcon" size="60" name="close" color="#ffffff" lineWidth="1"> </app-icon>
	</div>
</div>

<div id="serviceDescriptionModalContentContainer" class="content">
	<mat-dialog-content [formGroup]="form">
		<textarea placeholder="Enter description..." formControlName="description" id="description"> </textarea>
	</mat-dialog-content>
	<mat-dialog-actions class="flex flex-row flex-align-center-end">
		<button
			id="saveDescriptionService"
			class="mat-stroked-button submit-btn"
			[disabled]="btnDisabled"
			(click)="enter()"
		>
			ENTER
		</button>
	</mat-dialog-actions>
</div>
