<div class="dialog-header">
	<mat-icon>warning_amber</mat-icon>
	<h3>Warning</h3>
</div>
<div fxLayout="column" class="mat-dialog-content" mat-dialog-content>
	<p>Are you Sure?</p>
	<br />
	<span>{{ description }}</span>
</div>
<div fxLayoutAlign="end" class="mat-dialog-actions" mat-dialog-actions>
	<button action-buttons mat-stroked-button (click)="onCancelClick()">{{ cancelText }}</button>
	<button action-buttons color="dark" mat-flat-button (click)="onDeleteClick()" cdkFocusInitial>
		{{ confirmText }}
	</button>
</div>
