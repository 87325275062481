import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PaymentCalendarComponent } from "./payment-calendar.component";
import { CustomerListModule } from "@shared/components/customer-list/customer-list.module";
import { PaymentCalendarPaygroupListComponent } from "./_components/payment-calendar-paygroup-list/payment-calendar-paygroup-list.component";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatDividerModule } from "@angular/material/divider";
import { CustomerLegalEntitySelectModule } from "@shared/components/customer-legal-entity-select/customer-legal-entity-select.module";
import { NoItemsLayoutModule } from "@shared/components/no-items-layout/no-items-layout.module";
import { CalendarModule } from "@shared/components/calendar/calendar.module";
import { ModalWrapperModule } from "@shared/components/modal-wrapper/modal-wrapper.module";
import { MilestoneDetailsRowModule } from "@shared/components/milestone-details-row/milestone-details-row.module";
import { PaymentCalendarMilestoneListComponent } from "./_components/payment-calendar-milestone-list/payment-calendar-milestone-list.component";
import { PaymentCalendarMilestoneCreationComponent } from "./_components/payment-calendar-milestone-creation/payment-calendar-milestone-creation.component";
import { PaymentCalendarMilestoneEditionComponent } from "./_components/payment-calendar-milestone-edition/payment-calendar-milestone-edition.component";
import { MilestoneEditionPopupComponent } from "./_components/payment-calendar-milestone-edition/_components/milestone-edition-popup/milestone-edition-popup.component";
import { MatRadioModule } from "@angular/material/radio";
import { FormsSharedModule } from "@shared/modules/forms-shared.module";
import { MatIconModule } from "@angular/material/icon";
import { SlidingPanelModule } from "@shared/components/sliding-panel/sliding-panel.module";
import { MatButtonModule } from "@angular/material/button";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { SharedModule } from "@shared/shared.module";
import { FlexLayoutModule } from "ngx-flexible-layout";
import { MatDialogModule } from "@angular/material/dialog";
import { CreateOffcycleDialogComponent } from "./_components/create-offcycle-dialog/create-offcycle-dialog.component";
import { InputWrapperModule } from "@shared/components/input-wrapper/input-wrapper.module";
import { PaymentCalendarMilestonesContainerComponent } from "./_components/payment-calendar-milestones-container/payment-calendar-milestones-container.component";
import { PipesModule } from "src/app/shared/pipes/pipes.module";

import { CalendarEventMilestoneComponent } from "./_components/payment-calendar-milestone-list/calendar-event-milestone/calendar-event-milestone.component";
import { PaymentCalendarRoutingModule } from "./payment-calendar-routing.module";
import { BreadcrumbsModule } from "@shared/components/breadcrumbs/breadcrumbs.module";
import { GenericPayGroupListContainerModule } from "@shared/components/wpay-ui/generic-pay-group-list-container/generic-pay-group-list-container.module";
import { LegalEntityTimezoneDisclaimerComponent } from "./_components/legal-entity-timezone-disclaimer/legal-entity-timezone-disclaimer.component";
import { PaginationModule } from "@shared/components/pagination/pagination.module";

@NgModule({
	declarations: [
		PaymentCalendarComponent,
		PaymentCalendarPaygroupListComponent,
		PaymentCalendarMilestoneListComponent,
		PaymentCalendarMilestoneCreationComponent,
		PaymentCalendarMilestoneEditionComponent,
		MilestoneEditionPopupComponent,
		CreateOffcycleDialogComponent,
		PaymentCalendarMilestonesContainerComponent,
		CalendarEventMilestoneComponent,
		LegalEntityTimezoneDisclaimerComponent
	],
	imports: [
		CommonModule,
		CustomerListModule,
		MatPaginatorModule,
		MatDividerModule,
		CustomerLegalEntitySelectModule,
		NoItemsLayoutModule,
		ModalWrapperModule,
		CalendarModule,
		MilestoneDetailsRowModule,
		MatRadioModule,
		FormsSharedModule,
		MatIconModule,
		SlidingPanelModule,
		MatButtonModule,
		MatDatepickerModule,
		MatInputModule,
		SharedModule,
		FlexLayoutModule,
		MatDialogModule,
		InputWrapperModule,
		PipesModule,
		PaymentCalendarRoutingModule,
		BreadcrumbsModule,
		GenericPayGroupListContainerModule,
		PaginationModule
	]
})
export class PaymentCalendarModule {}
