import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map, tap } from "rxjs/operators";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { formatDataForSelect } from "src/app/shared/utils/format-select-data.util";
import { environment } from "src/environments/environment";
import {
	Customer,
	CustomerDashboarDataPagination,
	CustomerPagination,
	DashboardData
} from "../../models/customer.interface";
import { Pagination } from "../../models/pagination.interface";
import { createCustomerLogoPath } from "../../utils/customer-logo-path.util";

@Injectable({
	providedIn: "root"
})
export class CustomerService {
	customers$: BehaviorSubject<Customer[]> = new BehaviorSubject<Customer[]>([]);

	constructor(private http: HttpClient) {}

	getCustomer(customerId: string): Observable<Customer> {
		return this.http.get<Customer>(`${environment.apiUri}/v1/customers/${customerId}`);
	}

	getAllCustomersWithPagination(
		pageSize: number,
		pageNumber: number,
		onlyApproved: boolean
	): Observable<CustomerPagination> {
		return this.getCustomersWithPagination(pageSize, pageNumber, onlyApproved);
	}

	getCustomersWithPagination(
		pageSize: number,
		pageNumber: number,
		onlyApproved: boolean = true,
		payGroupStatuses: string = "",
		onlyWithLegalEnities: boolean = false
	): Observable<CustomerPagination> {
		return this.http.get<CustomerPagination>(
			`${environment.apiUri}/v1/customers?pageSize=${pageSize}&pageNumber=${pageNumber}&payGroupStatuses=${payGroupStatuses}`
		);
	}

	getCustomersFilteredByIdsWithPagination(
		pageSize: number,
		pageNumber: number,
		customerIds: string[]
	): Observable<CustomerPagination> {
		return this.http.get<CustomerPagination>(
			`${environment.apiUri}/v1/customers?pageSize=${pageSize}&pageNumber=${pageNumber}&customerIds=${customerIds}`
		);
	}

	getCustomersReportData(customerId: string) {
		return this.http
			.get<CustomerDashboarDataPagination>(
				`${environment.apiUri}/v1/customers/dashboard/reports?customerIds=${customerId}&pageNumber=0&pageSize=-1`
			)
			.pipe(map(response => response.items));
	}

	getAllCustomers(): Observable<Customer[]> {
		return this.http.get<CustomerPagination>(`${environment.apiUri}/v1/customers?pageSize=-1`).pipe(
			map((res: CustomerPagination) => {
				this.customers$.next(res.items);
				return res.items;
			})
		);
	}

	getCustomers(
		onlyWithPayGroups: boolean = false,
		onlyWithLegalEnities: boolean = false,
		payGroupStatuses: string = ""
	): Observable<Customer[]> {
		return this.http
			.get<CustomerPagination>(
				`${environment.apiUri}/v1/customers?pageSize=-1&payGroupStatuses=${payGroupStatuses}`
			)
			.pipe(
				map((res: CustomerPagination) => {
					this.customers$.next(res.items);

					return !onlyWithPayGroups ? res.items : res.items.filter(customer => customer.payGroupCount > 0);
				})
			);
	}

	/**
	 * @deprecated create an adapter or implement this in a feature module instead, Rest Client services should NOT transform responses.
	 */
	getCustomersAsSelectOptions(
		onlyWithPayGroups: boolean = false,
		onlyWithLegalEnities: boolean = false,
		payGroupStatuses: string = ""
	): Observable<SelectOption[]> {
		return this.getCustomers(onlyWithPayGroups, onlyWithLegalEnities, payGroupStatuses).pipe(
			map((customers: Customer[]) => {
				return customers.flatMap(customer => {
					const customerLogoPath = createCustomerLogoPath(customer);
					const option: SelectOption = {
						value: customer.id,
						text: `${customer.externalId} - ${customer.name}`,
						imagePath: customerLogoPath.imagePath
					};

					return formatDataForSelect(option);
				});
			})
		);
	}

	createCustomer(customer: Customer): Observable<Customer> {
		return this.http.post<Customer>(`${environment.apiUri}/v1/customers`, customer);
	}

	updateCustomer(customer: Customer): Observable<Customer> {
		return this.http.put<Customer>(`${environment.apiUri}/v1/customers`, customer);
	}

	deleteCustomer(customerId: string): Observable<any> {
		return this.http.delete<any>(`${environment.apiUri}/v1/customers/${customerId}`);
		// const deleteCustomer = this.customers.find(deleteCustomer => deleteCustomer.id === customer.id);
		// if (deleteCustomer) {
		// 	const index = this.customers.indexOf(deleteCustomer);
		// 	this.customers.splice(index, 1);
		// }
	}

	searchCustomers(queryString: string): Observable<CustomerPagination> {
		return this.http.get<CustomerPagination>(`${environment.apiUri}/v1/customers?queryString=${queryString}`);
	}

	searchCustomersWithPagination(
		queryString: string,
		pageSize: number,
		pageIndex: number,
		payGroupStatuses = ""
	): Observable<CustomerPagination> {
		return this.http.get<CustomerPagination>(
			`${environment.apiUri}/v1/customers?queryString=${queryString}&pageSize=${pageSize}&pageNumber=${pageIndex}&payGroupStatuses=${payGroupStatuses}`
		);
	}
}
