import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AccountField } from "src/app/shared/models/account-field.interface";

@Component({
	selector: "app-settlement-account-field-item",
	templateUrl: "./settlement-account-field-item.component.html",
	styleUrls: ["./settlement-account-field-item.component.scss"]
})
export class SettlementAccountFieldItemComponent implements OnInit {
	@Input() fields: AccountField[] = [];

	@Output() fieldRemoved: EventEmitter<string> = new EventEmitter();

	constructor() {}

	ngOnInit(): void {}

	removeField(key: string): void {
		this.fieldRemoved.emit(key);
	}
}
