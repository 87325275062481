import { SelectOption } from "src/app/shared/models/select-option.interface";

export const WEEKEND_OPTIONS: SelectOption[] = [
	{
		text: "Next working day",
		value: "NEXT"
	},
	{
		text: "Previous working day",
		value: "PREVIOUS"
	}
];
