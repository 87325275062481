<table
	class="failed-payment-orders table"
	mat-table
	[dataSource]="dataSourceFailedPaymentOrders"
	multiTemplateDataRows
	matSort
>
	<tr mat-header-row *matHeaderRowDef="customerColumns"></tr>
	<tr
		mat-row
		*matRowDef="let element; columns: customerColumns"
		#customerRow
		(click)="toggleRow(element)"
		[ngClass]="{ expanded: isExpanded(element), collapsed: !isExpanded(element) }"
	></tr>
	<tr mat-row *matRowDef="let row; columns: ['expandedDetail']"></tr>

	<!--First Layer-->
	<ng-container class="table-container">
		<ng-container matColumnDef="Customers">
			<th mat-header-cell *matHeaderCellDef>Customers</th>
			<td mat-cell class="customer-data customerName first-collumn" *matCellDef="let element">
				<span
					[matTooltip]="element.customerDetails.name"
					[matTooltipPosition]="'above'"
					[matTooltipClass]="'tooltip-failedPaymentOrders'"
					class="text"
					>{{ element.customerDetails.name }}</span
				>
			</td>
		</ng-container>

		<ng-container matColumnDef="Paygroups">
			<th mat-header-cell *matHeaderCellDef>Paygroups</th>
			<td class="customer-data paygroupCount second-collumn" mat-cell *matCellDef="let element">
				<span class="text" *ngIf="element.payGroupsCount < 2">{{ element.payGroupsCount }} Paygroup</span>
				<span class="text" *ngIf="element.payGroupsCount > 1">{{ element.payGroupsCount }} Paygroups</span>
			</td>
		</ng-container>

		<ng-container matColumnDef="Paycycles">
			<th mat-header-cell *matHeaderCellDef>Paycycles</th>
			<td class="customer-data payCycleCount third-collumn" mat-cell *matCellDef="let element">
				<span class="text" *ngIf="element.payCycleCount < 2">{{ element.payCycleCount }} Paycycle</span>
				<span class="text" *ngIf="element.payCycleCount > 1">{{ element.payCycleCount }} Paycycles</span>
			</td>
		</ng-container>

		<ng-container matColumnDef="Payments">
			<th mat-header-cell *matHeaderCellDef>Payments</th>
			<td class="customer-data payment-count fourth-collumn" mat-cell *matCellDef="let element">
				<span class="text" *ngIf="element.groupsCount < 2">{{ element.groupsCount }} Payment</span>
				<span class="text" *ngIf="element.groupsCount > 1">{{ element.groupsCount }} Payments</span>
			</td>
		</ng-container>

		<ng-container matColumnDef="Payment orders">
			<th mat-header-cell *matHeaderCellDef>Payment Orders</th>
			<td class="customer-data missing-bank-count fifth-collumn" mat-cell *matCellDef="let element">
				<span class="text" *ngIf="element.totalfailedPaymentOrdersCount < 2"
					>{{ element.totalfailedPaymentOrdersCount }} Failed Payment Order</span
				>
				<span class="text" *ngIf="element.totalfailedPaymentOrdersCount > 1"
					>{{ element.totalfailedPaymentOrdersCount }} Failed Payment Orders</span
				>
			</td>
		</ng-container>

		<ng-container matColumnDef="expand">
			<th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
			<td mat-cell *matCellDef="let element" class="button-container customer-data sixth-collumn">
				<button
					#expand
					mat-icon-button
					class="drop-down-arrow-button"
					aria-label="expand row"
					(click)="toggleRow(element); $event.stopPropagation()"
					element="element"
				>
					<app-icon
						class="drop-down-arrow"
						[ngClass]="{ down: isExpanded(element) }"
						[name]="'arrow-down'"
						[size]="'18px'"
						[lineWidth]="'2.5px'"
					></app-icon>
				</button>
			</td>
		</ng-container>
	</ng-container>

	<!--Second Layer-->
	<ng-container class="paygroup-container" matColumnDef="expandedDetail">
		<td
			mat-cell
			*matCellDef="let element"
			class="paygroup-row"
			[ngClass]="{
				'paygroups-expanded': isExpanded(element)
			}"
			[attr.colspan]="paygroupsColumns.length"
		>
			<div class="paygroup-detail" *ngIf="element.payGroups.length" [@detailExpand]="expandedState(element)">
				<div class="inner-table">
					<table
						class="paygroup-table"
						#innerTables
						mat-table
						[dataSource]="element.payGroups"
						multiTemplateDataRows
						matSort
					>
						<ng-container matColumnDef="first-padding">
							<th class="hide" mat-header-cell *matHeaderCellDef></th>
							<td class="padding first-collumn" mat-cell *matCellDef="let element">
								<span class="first padding text">{{ element.payGroup.name }}</span>
							</td>
						</ng-container>

						<ng-container matColumnDef="Paygroups">
							<th class="hide" mat-header-cell *matHeaderCellDef></th>
							<td class="paygroup-data paygroupName second-collumn" mat-cell *matCellDef="let element">
								<span
									class="payGroup-name text"
									[matTooltip]="element.payGroup.externalId + ' ' + element.payGroup.name"
									[matTooltipPosition]="'above'"
									[matTooltipClass]="'tooltip-failedPaymentOrders'"
								>
									<img
										src="assets/svg-country-flags/svg/{{
											element.payGroup.country?.toLowerCase()
										}}.svg"
									/>

									{{ element.payGroup.externalId }} {{ element.payGroup.name }}</span
								>
							</td>
						</ng-container>

						<ng-container matColumnDef="Paycycles">
							<th class="hide" mat-header-cell *matHeaderCellDef></th>
							<td class="paygroup-data third-collumn" mat-cell *matCellDef="let element">
								<span
									class="text"
									[matTooltip]="element.payCycle.name"
									[matTooltipPosition]="'above'"
									[matTooltipClass]="'tooltip-failedPaymentOrders'"
									>{{ element.payCycle.name }}</span
								>
							</td>
						</ng-container>

						<ng-container matColumnDef="Group">
							<th class="hide" mat-header-cell *matHeaderCellDef></th>
							<td class="paygroup-data fourth-collumn" mat-cell *matCellDef="let element">
								<div class="group-container">
									<span class="group text">{{ element.group }}</span>
								</div>
							</td>
						</ng-container>

						<ng-container matColumnDef="Payment orders">
							<th class="hide" mat-header-cell *matHeaderCellDef></th>
							<td
								class="paygroup-data failed-transaction-count fifth-collumn"
								mat-cell
								*matCellDef="let element"
							>
								<span class="text" *ngIf="element.failedPaymentOrdersCount > 1"
									>{{ element.failedPaymentOrdersCount }} Failed Payment Orders</span
								>
								<span class="text" *ngIf="element.failedPaymentOrdersCount < 2"
									>{{ element.failedPaymentOrdersCount }} Failed Payment Order</span
								>
							</td>
						</ng-container>

						<ng-container matColumnDef="second-padding">
							<th class="hide" mat-header-cell *matHeaderCellDef></th>
							<td mat-cell *matCellDef="let element" class="padding sixth-collumn">
								<button class="padding">
									<app-icon
										class="drop-down-arrow padding"
										[ngClass]="{ down: isExpanded(element) }"
										[name]="'arrow-down'"
									></app-icon>
								</button>
							</td>
						</ng-container>

						<tr mat-header-row *matHeaderRowDef="paygroupsColumns"></tr>
						<tr
							mat-row
							*matRowDef="let row; columns: paygroupsColumns"
							(click)="routeToPaymentOrders(row)"
						></tr>
					</table>
				</div>
			</div>
		</td>
	</ng-container>
</table>
