<div class="header-reprocess">
	<span id="serviceListTitleText" class="container-header-text"> List of Services </span>
	<ng-template
		[appUserRolePermissions]
		[action]="'hide'"
		[roles]="[
			'TS_IMPLEMENTATION_CONSULTANT',
			'TS_IMPLEMENTATION_LEAD',
			'TS_OPERATION_CONSULTANT',
			'TS_OPERATION_LEAD',
			'TS_OCB',
			'TS_VO',
			'CLIENT'
		]"
	>
		<button
			(click)="validateBeneficiaries()"
			[disabled]="disableValidateAllBeneficiarys()"
			class="mat-blue"
			style="float: right; margin-right: 0px"
			mat-button
		>
			REPROCESS ALL
		</button>
	</ng-template>
</div>
<div id="serviceListCardContainer">
	<div *ngFor="let topLevelService of services.servicesByServiceType">
		<div id="serviceTypeNameContainer" class="c-text-label">
			{{ topLevelService.serviceType | underscoreRemover }}
		</div>
		<mat-expansion-panel
			*ngFor="let service of topLevelService.services; let i = index"
			[disabled]="service.subServiceSummary.totalPartiallyConfiguredSubServices == 0"
			style="border-radius: 5px"
			hideToggle
			class="expansion-panel"
		>
			<mat-expansion-panel-header (click)="toggleServiceExpansionPanel(service)" style="cursor: initial">
				<div fxFlex="row" fxLayoutAlign="space-between center">
					<div
						class="first-column"
						fxLayout="column"
						fxLayoutAlign="center start"
						[ngClass]="{ 'expanded-card': service.isExpanded, 'collapsed-card': !service.isExpanded }"
					>
						<div fxLayout="row">
							<div fxLayout="column" fxLayoutAlign="center center" id="serviceNameText">
								<h4 *ngIf="service.isExpanded" class="panel-expanded-header" style="display: block">
									{{ service.name }}
								</h4>
								<h4 *ngIf="!service.isExpanded" class="service-header" style="display: block">
									<app-wpay-text-truncate
										[options]="{
											text: service.name,
											width: 'fit-content',
											maxWidth: '110px'
										}"
									>
									</app-wpay-text-truncate>
								</h4>
							</div>
							<div fxLayout="column" fxLayoutAlign="center center">
								<app-icon
									(click)="showDescriptionModal(service.name, service, $event)"
									*ngIf="!service.isExpanded"
									id="infoIcon"
									class="icon-info clickable-header-item"
									[name]="'info-circle'"
									[color]="'#767676'"
									[size]="'17'"
								>
								</app-icon>
							</div>
						</div>
						<div fxLayout="row" *ngIf="!service.isExpanded && service.isAdhoc">
							<h5 class="service-adhoc-sub-title">Service Ad Hoc</h5>
						</div>
					</div>

					<div
						class="second-column"
						fxLayout="column"
						fxLayoutAlign="center start"
						*ngIf="!service.isExpanded"
					>
						<app-ttp-group-badge [groupNumbers]="service.ttpGroups"></app-ttp-group-badge>
					</div>
					<div
						class="third-column"
						fxLayout="column"
						fxLayoutAlign="center start"
						*ngIf="!service.isExpanded && service.subServiceSummary.totalSubServiceBeneficiaries > 0"
					>
						<i style="color: #8992a3"
							>{{ service.subServiceSummary.totalSubServiceBeneficiaries }} Beneficiaries</i
						>
					</div>
					<div
						class="fourth-column"
						fxLayout="column"
						fxLayoutAlign="center center"
						*ngIf="!service.isExpanded"
					>
						<div class="status" fxLayout="row" fxLayoutAlign="center">
							<div
								*ngIf="
									service.subServiceSummary.totalFailedBeneficiaries == 0 &&
									service.subServiceSummary.totalProcessingBeneficiaries == 0 &&
									service.subServiceSummary.totalValidBeneficiaries > 0
								"
								fxLayout="row"
							>
								<span class="dot active"> </span>
								<div class="information-subtitle-text">Active</div>
							</div>
							<div *ngIf="service.subServiceSummary.totalFailedBeneficiaries > 0" fxLayout="row">
								<span class="dot failed"> </span>
								<div class="information-subtitle-text">
									Failed ({{ service.subServiceSummary.totalFailedBeneficiaries }})
								</div>
							</div>

							<div
								*ngIf="
									service.subServiceSummary.totalFailedBeneficiaries == 0 &&
									service.subServiceSummary.totalProcessingBeneficiaries > 0
								"
								fxLayout="row"
							>
								<span class="dot processing"> </span>
								<div class="information-subtitle-text">
									Processing ({{ service.subServiceSummary.totalProcessingBeneficiaries }})
								</div>
							</div>
						</div>
					</div>

					<div
						class="fifth-column"
						*ngIf="!service.isExpanded"
						fxLayout="column"
						fxLayoutAlign="end end"
					></div>
					<div class="sixth-column" fxLayout="column" fxLayoutAlign="end end">
						<div fxLayout="row">
							<div fxLayout="column">
								<app-icon
									*ngIf="
										(service.isExpanded && !service.isAdhoc) ||
										(!service.isExpanded && !isExpandable(service) && !service.isAdhoc)
									"
									(click)="selectTppService(service.id, topLevelService.serviceType, $event)"
									name="eye"
									size="30"
									class="clickable-header-item"
								>
								</app-icon>
								<app-icon
									*ngIf="
										(service.isExpanded && service.isAdhoc) ||
										(!service.isExpanded && !isExpandable(service) && service.isAdhoc)
									"
									(click)="editAdHocService(service.id, $event)"
									name="eye"
									size="30"
									class="clickable-header-item"
								>
								</app-icon>
							</div>
							<div *ngIf="isExpandable(service)" fxLayout="column">
								<div style="margin-left: 10px">
									<app-icon
										*ngIf="!service.isExpanded"
										size="30"
										name="arrow-down"
										class="clickable-header-item"
									>
									</app-icon>
									<app-icon
										*ngIf="service.isExpanded"
										size="30"
										name="arrow-up-2"
										class="clickable-header-item"
									>
									</app-icon>
								</div>
							</div>
						</div>
					</div>
				</div>
			</mat-expansion-panel-header>

			<div *ngFor="let subService of service.subService; let j = index" class="panel-content-border">
				<div
					*ngIf="subService.isPartiallyConfigured"
					fxLayout="row"
					fxLayoutAlign="space-between center"
					style="padding: 25px 8px"
				>
					<div class="sub-service-name first-column" fxLayout="column" fxLayoutAlign="center start">
						<app-wpay-text-truncate
							[options]="{
								text: subService.name,
								width: 'fit-content',
								maxWidth: '200px'
							}"
						>
						</app-wpay-text-truncate>
					</div>
					<div class="second-column" fxLayout="column" fxLayoutAlign="center start">
						<div class="group-1-container" fxLayoutAlign="center center">
							<app-ttp-group-badge
								*ngIf="subService.group != null"
								[groupNumbers]="[subService.group]"
							></app-ttp-group-badge>
						</div>
					</div>
					<div class="third-column" fxLayout="column" fxLayoutAlign="center start">
						<div *ngIf="!isEmptyObject(subService.beneficiary)" fxLayout="row">
							<div class="badge-item badge-bank">
								<app-icon class="bank-icon" [name]="'bank-figma'" [lineWidth]="'2'"></app-icon>
							</div>
							<h4 class="sub-service-beneficiary-name" style="margin-top: 10px; margin-left: 5px">
								<app-wpay-text-truncate
									[options]="{
										text: subService.beneficiary.name,
										width: '210px'
									}"
								>
								</app-wpay-text-truncate>
							</h4>
						</div>
					</div>
					<div class="fourth-column" fxLayout="column" fxLayoutAlign="center center">
						<div
							*ngIf="
								!isEmptyObject(subService.beneficiary) &&
								subService.beneficiary.beneficiaryStatus.internalStatus != 'CREATED' &&
								isBeneficiaryValid(subService.beneficiary.beneficiaryStatus.internalStatus) &&
								!isHiddenStatus(subService.beneficiary.beneficiaryStatus.internalStatus)
							"
							class="status"
							fxLayout="row"
							fxLayoutAlign="center"
						>
							<span class="dot active"> </span>
							<div class="information-subtitle-text" id="active">Success</div>
						</div>
						<div
							*ngIf="
								!isEmptyObject(subService.beneficiary) &&
								isBeneficiaryInvalid(subService.beneficiary.beneficiaryStatus.internalStatus) &&
								!isHiddenStatus(subService.beneficiary.beneficiaryStatus.internalStatus)
							"
							class="status failed-status"
							fxLayout="column"
							fxLayoutAlign="center"
						>
							<div class="staus-container">
								<span class="dot failed"> </span>
								<div class="information-subtitle-text" id="active">Failed</div>
							</div>

							<app-tooltip
								class="beneficairy-status-tooltip"
								[position]="'calendar-bottom'"
								[trianlePosition]="'2rem'"
							>
								<span>
									{{ subService.beneficiary.beneficiaryStatus.displayStatus }}
								</span>
								<div class="details" *ngIf="subService.beneficiary.beneficiaryStatus.details">
									<div
										class="details-container"
										*ngFor="
											let attribute of subService.beneficiary.beneficiaryStatus.details
												?.attributes | keyvalue
										"
									>
										<div class="detail-container">
											<span class="details-items" *ngIf="!isArray(attribute.value)">
												<span class="type">{{ attribute.key | formatString }}</span>
												: {{ attribute.value }}
											</span>
										</div>

										<span class="tag-container" *ngIf="isArray(attribute.value)">
											<span
												*ngFor="let tag of getArrayValue(attribute.value)"
												class="details-items"
											>
												<span class="type">{{ attribute.key | formatString }}</span>
												: {{ tag | formatString }}
											</span>
										</span>
									</div>
								</div>
							</app-tooltip>
						</div>
						<div
							*ngIf="
								!isEmptyObject(subService.beneficiary) &&
								subService.beneficiary.beneficiaryStatus.internalStatus == 'CREATED' &&
								!isHiddenStatus(subService.beneficiary.beneficiaryStatus.internalStatus)
							"
							class="status"
							fxLayout="row"
							fxLayoutAlign="center"
						>
							<span class="dot processing"> </span>
							<div class="information-subtitle-text" id="active">Processing</div>
						</div>
					</div>
					<div class="fifth-column" fxLayout="column" fxLayoutAlign="center center">
						<a
							*ngIf="
								!isEmptyObject(subService.beneficiary) &&
								canValidate(subService.beneficiary.beneficiaryStatus.internalStatus) &&
								!isHiddenStatus(subService.beneficiary.beneficiaryStatus.internalStatus)
							"
							(click)="validateBeneficiary(subService.beneficiary.linkId)"
							fxLayout="row"
						>
							<app-icon
								class="re-process-hover"
								[color]="'#8992A3'"
								size="20"
								[name]="'refresh-2'"
								[lineWidth]="'2'"
							></app-icon>
							<h4 style="margin-left: 10px">Reprocess</h4>
						</a>
					</div>
					<div fxLayout="column" class="sixth-column" fxLayoutAlign="center end" style="cursor: pointer">
						<!-- 
							Given that product doesn't care at the moment that a beneficiary may have NO_PROVIDER_ASSIGNMENT,
							only let me see the history if the beneficiary has a settlement account id. 

							AKA `happy path` this beneficiary is created in a given provider and the setlement account was also successfully created.
						-->
						<app-icon
							*ngIf="!isEmptyObject(subService.beneficiary) && subService.beneficiary.settlementAccountId"
							(click)="showElectiveHistory(subService.beneficiary)"
							size="25"
							class="rotate-90-deg"
							[name]="'more'"
							[lineWidth]="'1.5'"
						></app-icon>
					</div>
				</div>

				<mat-divider *ngIf="j < service.subServiceSummary.totalPartiallyConfiguredSubServices - 1">
				</mat-divider>
			</div>
		</mat-expansion-panel>
	</div>
</div>
