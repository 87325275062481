import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ReportingService } from "./_services/reporting.service";
import { Subscription, take } from "rxjs";
import { CustomerEntitySelection } from "src/app/shared/models/customer-entity-selection.interface";
import { PermissionsService } from "@shared/services/permissions/permissions.service";

@Component({
	selector: "app-reporting",
	templateUrl: "./reporting.component.html",
	styleUrls: ["./reporting.component.scss"]
})
export class ReportingComponent implements OnInit {
	// Variables
	form!: FormGroup;
	reportSubscription!: Subscription;
	selectedCustomerId!: string;
	selectedLegalEntityId!: string;
	selectedPayGroupId!: string;
	canDownloadReport = false;

	constructor(
		private formBuilder: FormBuilder,
		private reportingService: ReportingService,
		private permissions: PermissionsService
	) {}

	ngOnInit(): void {
		this.permissions
			.canDownloadReport()
			.pipe(take(1))
			.subscribe(res => {
				this.canDownloadReport = res;
				this.initForm();
			});
	}

	initForm(): void {
		this.form = this.formBuilder.group({
			startDate: [, Validators.required],
			endDate: [, Validators.required]
		});
	}

	//If pagination size is -1 return all the transactions
	downloadTransactions(): void {
		const startDate: string = this.reportingService.formatDate(this.form.value.startDate);
		const endDate: string = this.reportingService.formatDate(this.form.value.endDate);
		const customerId: string = this.selectedCustomerId ? this.selectedCustomerId : "";
		const legalEntityId: string = this.selectedLegalEntityId ? this.selectedLegalEntityId : "";
		const payGroupID: string = this.selectedPayGroupId ? this.selectedPayGroupId : "";
		//const filename: string = `Reports ${payGroupID} (${startDate} to ${endDate})`;

		this.reportSubscription = this.reportingService
			.getTransactionReports(startDate, endDate, customerId, legalEntityId, payGroupID)
			.subscribe(response => {
				const contentDisposition = response.headers.get("Content-Disposition");
				let fileName = "";
				if (contentDisposition) {
					const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
					const matches = fileNameRegex.exec(contentDisposition);
					if (matches != null && matches[1]) {
						fileName = matches[1].replace(/['"]/g, "");
					}
				}
				let downloadLink = document.createElement("a");
				downloadLink.href = window.URL.createObjectURL(response.body);
				if (fileName) downloadLink.setAttribute("download", fileName);
				document.body.appendChild(downloadLink);
				downloadLink.click();
			});
	}

	selectionChange(data: CustomerEntitySelection): void {
		this.selectedCustomerId = data.customerId;
		this.selectedLegalEntityId = data.legalEntityId;
		this.selectedPayGroupId = data.payGroupId;
	}

	ngOnDestroy() {
		if (this.reportSubscription) this.reportSubscription.unsubscribe();
	}
}
