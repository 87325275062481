import { Component, OnDestroy, OnInit } from "@angular/core";
import { Validators, UntypedFormBuilder } from "@angular/forms";
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { ContactPerson } from "src/app/shared/models/contact-person.interface";
import { LegalEntityData } from "src/app/shared/models/legal-entity-data.interface";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { REGIONS } from "../../_constants/regions";
import { ILegalEntityCreationFormGroup, LegalEntityApi } from "../../_models/legal-entity-api.interface";
import { CommonLegalEntityService } from "../../_services/common/common-legal-entity.service";
import { CreateLegalEntityService } from "../../_services/create-legal-entitites/create-legal-entity.service";
import { ToastService } from "../../../../shared/services/toast/toast.service";
import { Customer } from "src/app/shared/models/customer.interface";
import { stringMatchValidator } from "src/app/shared/validators/matching-string.validator";
import { Country } from "src/app/shared/models/country.interface";
import { createCustomerLogoPath } from "src/app/shared/utils/customer-logo-path.util";
import { Router } from "@angular/router";
import { PermissionsService } from "../../../../shared/services/permissions/permissions.service";
import { BreadcrumbService } from "@shared/components/breadcrumbs/services/breadcrumb.service";
import { CountriesService } from "@shared/services/countries/countries.service";
import { formatDataForSelect } from "@shared/utils/format-select-data.util";
import { Store, select } from "@ngrx/store";
import { AppState } from "@store/models/state.model";
import { getCustomerEntityGroupState } from "@store/index";
import { CustomerEntityGroupSelect } from "@store/models/customerEnitityGroupSelection.model";

@Component({
	selector: "create-legal-entity",
	templateUrl: "./create-legal-entity.component.html",
	styleUrls: ["./create-legal-entity.component.scss"],
	providers: [CreateLegalEntityService]
})
export class CreateLegalEntityComponent implements OnInit, OnDestroy {
	selectedCustomerId!: string;
	externalId!: string;

	// Public variables
	legalEntityForm!: ILegalEntityCreationFormGroup;
	addContactClicked$: Subject<void> = new Subject<void>();

	timeZones: SelectOption[] = [];
	isCreationContactValid: boolean = true;
	creationContactData: ContactPerson[] = [];
	regions: SelectOption[] = REGIONS;
	selectOptionsCountries: SelectOption[] = [];
	isLoading: boolean = false;
	isCreated: boolean = false;
	selectedCustomer!: Customer;
	resetForm: boolean = false;
	logoUrl: string | undefined;

	canEditContactPersons: boolean = false;
	cantEditAmlStatus: boolean = true;

	// Private variables
	private destroy$ = new Subject<void>();

	constructor(
		private formBuilder: UntypedFormBuilder,
		private createLegalEntityService: CreateLegalEntityService,
		public commonLegalEntityService: CommonLegalEntityService,
		private toastService: ToastService,
		private router: Router,
		private permissions: PermissionsService,
		private breadCrumbService: BreadcrumbService,
		private countriesService: CountriesService,
		private store: Store<AppState>
	) {}

	ngOnInit(): void {
		this.store
			.pipe(takeUntil(this.destroy$), select(getCustomerEntityGroupState))
			.subscribe((state: CustomerEntityGroupSelect) => {
				if (state?.customerId) {
					this.selectedCustomerId = state.customerId;
					this.externalId = history?.state?.customer?.externalId ? history.state.customer.externalId : "";
				}
			});

		this.logoUrl = createCustomerLogoPath(this.commonLegalEntityService.customerSelected).imagePath;
		this.fetchCountryOptions();

		this.permissions
			.canEditContactPersons()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => (this.canEditContactPersons = res));
	}

	fetchCountryOptions(): void {
		this.countriesService
			.getCountries()
			.pipe(take(1))
			.subscribe(countries => {
				this.commonLegalEntityService.countries = [...countries];
				countries.filter(country => {
					this.selectOptionsCountries.push(
						formatDataForSelect({
							text: country.country.name,
							value: country.country.code,
							imagePath: `assets/svg-country-flags/svg/${country?.country.code.toLowerCase()}.svg`
						})
					);
				});
				this.initForm();
			});
	}

	private async initForm(): Promise<void> {
		this.initializeAmlStatusEditability();

		const country: Country | undefined = await this.commonLegalEntityService.getCountrySelected();

		this.legalEntityForm = this.formBuilder.group({
			externalId: [null, stringMatchValidator(this.externalId)],
			id: [{ value: null, disabled: true }, Validators.required],
			name: [null, Validators.required],
			address: [null],
			payrollId: [null],
			country: [null, Validators.required],
			timeZone: [{ value: null, disabled: true }, Validators.required],
			region: [null, Validators.required],
			logoPath: [null],
			status: ["AML_WAITING"]
		}) as ILegalEntityCreationFormGroup;

		this.setupLegalEntityFormEvents();

		if (country) {
			const countryOption: SelectOption = {
				value: country?.country.code,
				text: country?.country.name,
				imagePath: `assets/svg-country-flags/svg/${country?.country.code.toLowerCase()}.svg`
			};
			this.legalEntityForm.controls.country.setValue(country?.country.code);
		}
	}

	onAddContactClicked() {
		this.addContactClicked$.next();
	}

	saveLegalEntity() {
		this.isLoading = true;
		if (this.legalEntityForm.valid && this.commonLegalEntityService.customerSelected) {
			const legalEntityFormData: LegalEntityData = {
				...this.legalEntityForm.getRawValue(),
				customerId: this.selectedCustomerId
			};

			const apiLegalEntity: LegalEntityApi = this.commonLegalEntityService.formatLegalEntityDataApi(
				legalEntityFormData,
				this.creationContactData
			);

			this.createLegalEntityService
				.createLegalEntity(apiLegalEntity)
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: result => {
						if (result) {
							this.router.navigate(["legal-entities/select"], {
								state: { customer: history.state.customer }
							});
							this.isLoading = false;
							this.isCreated = true;
							this.toastService.showSuccess("Legal entity has been created");
						}
					},
					error: _ => {
						this.isLoading = false;
						this.isCreated = false;
						this.toastService.showError("Error creating Legal entity");
					}
				});
		}
	}

	creationContactFormData(event: { data: ContactPerson[]; valid: boolean }): void {
		this.legalEntityForm.markAsDirty();
		this.isCreationContactValid = event.valid;
		this.creationContactData = event.data;
	}

	cancelCreateLegalEntity(): void {
		this.breadCrumbService.updateCustomerEntityGroupState();

		this.router.navigate(["legal-entities/select"], {
			state: { customer: history.state.customer }
		});
	}

	goHome(): void {
		this.router.navigate(["legal-entities"]);
	}

	amlFormData(data: { status: string; date: string }) {
		if (data) {
			this.resetForm = false;
			this.legalEntityForm.get("status")?.setValue(data.status);
		}
	}

	onAmlFormChange(status: string) {
		this.legalEntityForm.get("status")?.setValue(status);
	}

	private setupLegalEntityFormEvents() {
		//Set functionality for when selecting country should autofill region & timezone
		this.createLegalEntityService.setupLegalEntityFormEvents(this.legalEntityForm);

		//Listen to timezone change related to country selection
		this.createLegalEntityService.timeZones$.pipe(takeUntil(this.destroy$)).subscribe(timezones => {
			this.timeZones = timezones;
		});
	}

	private initializeAmlStatusEditability() {
		this.permissions
			.canEditCustomerCompliance()
			.pipe(takeUntil(this.destroy$))
			.subscribe(canEditAmlStatus => {
				this.cantEditAmlStatus = !canEditAmlStatus;
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
