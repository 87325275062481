import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { combineLatest, forkJoin, Observable, of, Subject } from "rxjs";
import { delay, map, mergeMap, take, takeUntil, tap } from "rxjs/operators";
import { BankAccountFieldsComponent } from "@shared/components/bank-account-fields/bank-account-fields.component";
import { MenuService } from "@modules/config/_services/menu/menu.service";
import { CountriesService } from "@shared/services/countries/countries.service";
import { ServiceProviderService } from "@shared/services/service-provider/service-provider.service";
import { ToastService } from "@shared/services/toast/toast.service";
import { LegalEntity } from "src/app/shared/models/legal-entity.interface";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import {
	Definition,
	Definitions,
	DefinitionsData,
	ProviderCountry
} from "src/app/shared/models/service-definition.interface";
import { SourceAccount } from "src/app/shared/models/source-of-funds.interface";
import { BankAccountPost, BankAccountPut } from "../../../_models/bank-account.interface";
import { RouteData } from "../../../_models/type-route.interface";
import { SourceAccountPost, SourceAccountPut } from "../../../_models/source-accounts.interface";
import { FundSourceService } from "../../../_services/fund-source/fund-source.service";
import { ServiceDefinitionService } from "../../../_services/service-definition/service-definition.service";
import { PermissionsService } from "../../../../../shared/services/permissions/permissions.service";

@Component({
	selector: "app-source-accounts",
	templateUrl: "./source-accounts.component.html",
	styleUrls: ["./source-accounts.component.scss"],
	providers: [FundSourceService]
})
export class SourceAccountsComponent implements OnInit, OnDestroy {
	//Inputs
	@Input() paymentTypes: DefinitionsData[] | Array<any> = [];
	@Input() legalEntity!: LegalEntity;
	@Input() serviceProviders!: ProviderCountry[];
	@Input() legalEntityHasPayGroups!: boolean;

	// Outputs
	@Output() goPrev: EventEmitter<boolean> = new EventEmitter();
	@Output() goNext: EventEmitter<boolean> = new EventEmitter();
	@Output() goHome: EventEmitter<boolean> = new EventEmitter();
	@Output() hideBreadCrumbs: EventEmitter<boolean> = new EventEmitter();

	//ViewChild
	@ViewChild(BankAccountFieldsComponent) bankAccountFieldsComponent: BankAccountFieldsComponent | undefined;

	private destroy$: Subject<void> = new Subject<void>();

	// UI variables
	public typeDefinitions!: any;
	public existingSourceAccounts: Array<SourceAccount> = [];
	public typeRoutes: Array<any> = [];
	public currencyOptions: Array<SelectOption[]> = [];
	public showForm: boolean = false;
	public selectedRoute: RouteData = {} as RouteData;
	public selectOptionsCountries$: Observable<SelectOption[]> | undefined;
	public selectedCountry: string | undefined;
	public bankAccountValues: Record<string, string | number | boolean> | undefined;
	public globalForm!: FormGroup;
	public editMode: boolean = false;
	canEditSourceOfFunds: boolean = false;
	canAddSourceOfFunds: boolean = false;

	serviceDefinition: Definitions = {} as Definitions;

	constructor(
		private serviceProviderService: ServiceProviderService,
		private sourceAccountService: FundSourceService,
		private toastService: ToastService,
		private menuService: MenuService,
		private countriesService: CountriesService,
		private formBuilder: FormBuilder,
		private serviceDefinitionService: ServiceDefinitionService,
		private changeDetector: ChangeDetectorRef,
		private permissions: PermissionsService
	) {}

	async ngOnInit() {
		this.permissions
			.canEditSourceOfFunds()
			.pipe(takeUntil(this.destroy$))
			.subscribe((res: boolean) => (this.canEditSourceOfFunds = res));

		this.permissions
			.canAddSourceOfFunds()
			.pipe(takeUntil(this.destroy$))
			.subscribe((res: boolean) => (this.canAddSourceOfFunds = res));

		this.init().subscribe();
	}

	initForm(): void {
		const previousAccount: boolean = this.selectedRoute?.account?.id ? true : false;
		let fundingPull = true;
		let fundingPush = true;

		if (previousAccount) {
			fundingPull = this.selectedRoute.account?.data?.fundingMethods?.includes("PULL") ? true : false;
			fundingPush = this.selectedRoute.account?.data?.fundingMethods?.includes("PUSH") ? true : false;
		}

		const provisionCompensation: string = this.selectedRoute?.account.data?.provisionCompensation
			? this.selectedRoute.account?.data?.provisionCompensation
			: "STANDARD";

		this.globalForm = this.formBuilder.group({
			country: [{ value: "", disabled: !this.canEditSourceOfFunds }],
			fundingPull: [{ value: fundingPull, disabled: !this.canEditSourceOfFunds }],
			fundingPush: [{ value: fundingPush, disabled: !this.canEditSourceOfFunds }],
			provisionCompensation: [{ value: provisionCompensation, disabled: !this.canEditSourceOfFunds }],
			name: [
				{ value: this.selectedRoute.account?.data?.name, disabled: !this.canEditSourceOfFunds },
				[Validators.required]
			],
			currency: [{ value: this.selectedRoute.selectedCurrency, disabled: true }]
		});

		this.globalForm
			.get("country")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe((country: string | SelectOption) => {
				if (typeof country === "string") {
					this.selectedCountry = country;
				} else {
					this.selectedCountry = country?.value || "";
				}

				if (this.selectedCountry !== this.selectedRoute?.account?.data?.bankAccount?.country) {
					this.bankAccountValues = {};
				}
			});

		if (!this.canEditSourceOfFunds) {
			this.globalForm.disable();
		}
	}

	init(): Observable<{ typeDefinitions: Definitions; existingSourceAccounts: SourceAccount[] }> {
		return this.serviceDefinitionService.loadDefinitions(this.legalEntity.id).pipe(
			mergeMap(typeDefinitions =>
				this.sourceAccountService
					.getAccountsByLegalEntityId(this.legalEntity.id)
					.pipe(map(existingSourceAccounts => ({ typeDefinitions, existingSourceAccounts })))
			),
			takeUntil(this.destroy$),
			map(
				({
					typeDefinitions,
					existingSourceAccounts
				}: {
					typeDefinitions: Definitions;
					existingSourceAccounts: SourceAccount[];
				}) => {
					this.typeDefinitions = typeDefinitions;
					this.existingSourceAccounts = existingSourceAccounts;
					this.bankAccountValues = {};
					this.generateList(typeDefinitions);
					this.changeDetector.detectChanges();

					return { typeDefinitions, existingSourceAccounts };
				}
			)
		);
	}

	async generateList(typeDefinitions: Definitions) {
		this.typeRoutes = [];

		let defs;

		if (typeDefinitions.paymentTypeDefinitions.length) {
			defs = typeDefinitions.paymentTypeDefinitions;
		} else {
			defs = this.paymentTypes;
		}

		defs.forEach((paymentType: DefinitionsData) => {
			const typeRouteObject = {
				type: paymentType.paymentType,
				routes: [] as Array<any>
			};

			paymentType.definitions.forEach(async (definition: Definition) => {
				// Load the full account for the route if it exists
				let definitionAvailableAccounts: Array<any> = [];
				let definitionAccount = this.existingSourceAccounts.find((account: SourceAccount) => {
					const defintitionAccounts = definition.sourceAccountIds as string[];
					if (account.id === defintitionAccounts[0]) {
						return account;
					} else {
						return false;
					}
				});

				if (definitionAccount) {
					definitionAvailableAccounts = this.existingSourceAccounts.filter((account: SourceAccount) => {
						if (
							account.currency === definitionAccount?.currency &&
							account.paymentType === definitionAccount?.paymentType
						) {
							return account;
						} else {
							return false;
						}
					});
				} else {
					definitionAccount = {} as SourceAccount;
				}

				// Create custom route object for display
				const routeAccount = {
					paymentType: paymentType.paymentType,
					providerCountryId: definition.provider?.providerCountryId,
					providerName: this.serviceProviders?.find((provider: ProviderCountry) => {
						if (provider.providerCountryId === definition.provider?.providerCountryId) {
							return provider;
						} else {
							return false;
						}
					})?.name,
					payoutAccount: definition.payoutAccount,
					route: definition.route,
					currencies: await this.serviceDefinitionService.getAllCurrencies(),
					account: definitionAccount,
					selectedCurrency: definitionAccount?.currency ? definitionAccount.currency : null,
					availableAccounts: definitionAvailableAccounts,
					selectedAccountId: definitionAccount.id ? definitionAccount.id : null
				};
				typeRouteObject.routes.push(routeAccount);
			});
			this.typeRoutes.push(typeRouteObject);
		});
	}

	getAvailableAccountsForRoute(typeRouteIndex: number, routeIndex: number) {
		this.typeRoutes[typeRouteIndex].routes[routeIndex].availableAccounts = [];
		const route = this.typeRoutes[typeRouteIndex].routes[routeIndex];
		this.typeRoutes[typeRouteIndex].routes[routeIndex].availableAccounts = this.existingSourceAccounts.filter(
			(account: SourceAccount) => {
				return account.currency === route.selectedCurrency && account.paymentType === route.paymentType;
			}
		);

		const hasAccountSelected: boolean = !!this.typeRoutes[typeRouteIndex].routes[routeIndex].selectedAccountId;
		this.typeRoutes[typeRouteIndex].routes[routeIndex].account = {} as SourceAccount;
		this.typeRoutes[typeRouteIndex].routes[routeIndex].selectedAccountId = null;

		this.updateDefinitions(null, this.typeRoutes[typeRouteIndex].routes[routeIndex], true)
			.pipe(take(1))
			.subscribe(() => {
				if (hasAccountSelected) {
					this.toastService.showInfo("Account removed after currency change");
				}
				this.changeDetector.detectChanges();
			});
	}

	selectAccountForRoute(typeRouteIndex: number, routeIndex: number) {
		const route = this.typeRoutes[typeRouteIndex].routes[routeIndex];
		this.typeRoutes[typeRouteIndex].routes[routeIndex].account = this.existingSourceAccounts.find(
			(account: SourceAccount) => {
				if (account.id === route.selectedAccountId) {
					return account;
				} else {
					return false;
				}
			}
		);

		this.updateDefinitions(this.typeRoutes[typeRouteIndex].routes[routeIndex].account.id, route, true)
			.pipe(take(1))
			.subscribe(() => {
				this.toastService.showSuccess("Account selected");
			});
	}

	clearRouteAccount(typeRouteIndex: number, routeIndex: number) {
		this.typeRoutes[typeRouteIndex].routes[routeIndex].account = {} as SourceAccount;
		this.typeRoutes[typeRouteIndex].routes[routeIndex].selectedAccountId = null;
		this.typeRoutes[typeRouteIndex].routes[routeIndex].availableAccounts = [];
		this.typeRoutes[typeRouteIndex].routes[routeIndex].selectedCurrency = null;

		this.updateDefinitions(null, this.typeRoutes[typeRouteIndex].routes[routeIndex], true)
			.pipe(take(1))
			.subscribe(() => {
				this.toastService.showInfo("Route cleared");
			});
	}

	/**
	 * FORM METHODS
	 */
	openForm(route: any) {
		this.hideBread();

		this.selectedRoute = route;

		this.initForm();

		this.selectedRoute.account?.data?.bankAccount?.fields?.map((bankAccountField: any) => {
			this.bankAccountValues = this.bankAccountValues || {};
			this.bankAccountValues[bankAccountField.key] = bankAccountField.value;
		});

		if (this.selectedRoute.account?.data?.bankAccount?.bankName) {
			this.bankAccountValues = this.bankAccountValues || {};
			this.bankAccountValues.bankName = this.selectedRoute.account?.data?.bankAccount?.bankName;
		}

		const countrySelected: string =
			this.selectedRoute.account?.data?.bankAccount?.country || this.legalEntity.data.country;
		this.initilizeCountrySelector(countrySelected);

		this.editMode = !!this.selectedRoute.account?.id;
		this.showForm = true;
	}

	initilizeCountrySelector(country: string) {
		this.selectOptionsCountries$ = this.countriesService.getCountryOptions().pipe(
			tap((countries: SelectOption[]) => {
				this.globalForm.get("country")?.patchValue(this.countriesService.defaultCountry(countries, country));
			})
		);
	}

	save() {
		if (this.selectedRoute.account?.id && this.bankAccountFieldsComponent) {
			this.sourceAccountService
				.updateFundSource(this.prepareSettlementAccountToUpdate())
				.pipe(
					take(1),
					mergeMap((data: any) => this.updateDefinitions(data.id, this.selectedRoute))
				)
				.subscribe(data => {
					this.showForm = false;
					this.toastService.showSuccess("Bank account saved successfully");
					this.init().subscribe();
				});
		} else if (this.bankAccountFieldsComponent) {
			this.sourceAccountService
				.createFundSource(this.prepareSettlementAccountToCreate())
				.pipe(
					take(1),
					mergeMap((data: any) => this.updateDefinitions(data.id, this.selectedRoute))
				)
				.subscribe(data => {
					this.showForm = false;
					this.toastService.showSuccess("Bank account created successfully");
					this.init().subscribe();
				});
		}
		this.showForm = false;
		this.menuService.setVisible(true);
		this.showBread();
	}

	prepareSettlementAccountToCreate(): SourceAccountPost {
		const sourceAccount: SourceAccountPost = {
			legalEntityId: this.legalEntity.id,
			paymentType: this.selectedRoute.paymentType,
			currency: this.selectedRoute.selectedCurrency,
			data: {
				name: this.globalForm?.get("name")?.value,
				provisionCompensation: this.globalForm?.get("provisionCompensation")?.value,
				fundingMethods: [],
				bankAccount: {} as BankAccountPost
			}
		};

		const fundingMethods: Array<string> = [];
		if (this.globalForm?.get("fundingPull")?.value) fundingMethods.push("PULL");
		if (this.globalForm?.get("fundingPush")?.value) fundingMethods.push("PUSH");
		sourceAccount.data.fundingMethods = fundingMethods;

		const bankAccount: { fields: { key: string; value: string }[] } | undefined =
			this.bankAccountFieldsComponent?.getBankAccount();
		if (bankAccount && this.selectedCountry) {
			sourceAccount.data.bankAccount = {
				...bankAccount,
				country: this.selectedCountry
			};
		}

		return sourceAccount;
	}

	prepareSettlementAccountToUpdate(): SourceAccountPut {
		const sourceAccount: SourceAccountPut = {
			id: this.selectedRoute.account?.id,
			version: this.selectedRoute.account?.version,
			data: {
				name: this.globalForm?.get("name")?.value,
				provisionCompensation: this.globalForm?.get("provisionCompensation")?.value,
				fundingMethods: [],
				bankAccount: {} as BankAccountPut
			}
		};

		const fundingMethods: string[] = [];
		if (this.globalForm?.get("fundingPull")?.value) fundingMethods.push("PULL");
		if (this.globalForm?.get("fundingPush")?.value) fundingMethods.push("PUSH");
		sourceAccount.data.fundingMethods = fundingMethods;

		const bankAccount: { fields: { key: string; value: string }[] } | undefined =
			this.bankAccountFieldsComponent?.getBankAccount();
		if (bankAccount && this.selectedCountry) {
			sourceAccount.data.bankAccount = {
				...bankAccount,
				id: this.selectedRoute.account?.data.bankAccount?.id,
				version: this.selectedRoute.account?.data.bankAccount?.version,
				country: this.selectedCountry
			};
		}

		return sourceAccount;
	}

	updateDefinitions(id: any, route: any, onlyLoad?: boolean): Observable<any> {
		// Format Definitions

		this.typeDefinitions.paymentTypeDefinitions = this.typeDefinitions.paymentTypeDefinitions.map((type: any) => {
			if (type.paymentType === route.paymentType) {
				type.definitions = type.definitions.map((definition: any) => {
					if (
						definition.route === route.route &&
						definition.payoutAccount === route.payoutAccount &&
						definition.provider.providerCountryId === route.providerCountryId
					) {
						definition.sourceAccountIds = id ? [id] : [];
					}
					return definition;
				});
			}
			return type;
		});

		if (onlyLoad) {
			return this.serviceProviderService.updateDefinitions(this.typeDefinitions).pipe(
				mergeMap(() => this.serviceDefinitionService.loadDefinitions(this.legalEntity.id)),
				map((typeDefinitions: Definitions) => {
					this.typeDefinitions = typeDefinitions;
				})
			);
		} else {
			return this.serviceProviderService
				.updateDefinitions(this.typeDefinitions)
				.pipe(mergeMap(() => this.init()));
		}
	}

	/**
	 * UTILS
	 */
	ngOnChanges() {
		this.typeRoutes = [];
		this.ngOnInit();
	}

	goBack() {
		this.goPrev.next(true);
	}

	hideBread() {
		this.hideBreadCrumbs.emit(true);
	}

	showBread() {
		this.hideBreadCrumbs.emit(false);
		this.menuService.setVisible(true);
		this.showForm = !this.showForm;
	}

	goForward() {
		let allRoutescompleted = true;

		this.typeRoutes.forEach((type: any) => {
			type.routes.forEach((route: any) => {
				if (!route.account || !route.account.id) {
					allRoutescompleted = false;
				}
			});
		});

		if (allRoutescompleted) {
			this.goNext.next(true);
		} else {
			this.toastService.showError("Please complete all routes with an account");
		}
	}

	backFromModal() {
		this.showForm = !this.showForm;
		this.menuService.setVisible(true);
		this.showBread();
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
