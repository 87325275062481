import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SelectComponent } from "./select.component";
import { FlexLayoutModule } from "ngx-flexible-layout";
import { SharedModule } from "@shared/shared.module";
import { MatListModule } from "@angular/material/list";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
	declarations: [SelectComponent],
	imports: [CommonModule, FlexLayoutModule, SharedModule, MatListModule, MatIconModule],
	exports: [SelectComponent]
})
export class SelectModule {}
