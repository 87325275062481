<!-- Items -->
<div class="milestone-container" fxLayout="column" (click)="toggle()">
	<div class="content" [ngClass]="{ 'is-active': isMilestonesVisible, 'not-active': !isMilestonesVisible }">
		<div
			class="header-content"
			[ngClass]="{ 'is-active': isMilestonesVisible }"
			fxLayout="row"
			fxLayoutAlign="start center"
		>
			<div class="left-side">
				<app-icon
					class="arrow-milestone-container"
					[ngClass]="{
						'close-group-drop-down': isMilestonesVisible
					}"
					id="{{ group.group + '_KeyboardArrowDown' }}"
					[name]="'arrow-right-1'"
					[size]="'20'"
					[lineWidth]="'3px'"
					[color]="'var(--color-role-titl)'"
					s
				>
				</app-icon>

				<!-- Milestone Group Badge -->
				<div class="group-container">
					<span class="group">{{ group.text }} Payments</span>
				</div>
			</div>

			<div class="right-side">
				<div
					class="milestone-summary"
					[fxLayout]="'column'"
					[fxFlexAlign]="'space-between center'"
					*ngFor="let milestone of group.milestone"
					fxFlex="23.5"
					[ngStyle]="isMilestonesVisible ? { visibility: 'hidden' } : { visibility: 'visible' }"
				>
					<div class="milestoneType" [fxLayout]="'row'">
						<span>{{ milestone | milestoneTypes | uppercase }}</span>
					</div>

					<div class="milestoneDate" *ngIf="milestone.date" [fxLayout]="'row'">
						{{ milestone.date | date : "MMM" }} {{ milestone.date | date : "d" }}
						<div class="position">{{ milestone.date | date : "d" | datePosition | lowercase }}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="sub-content">
			<!-- Events -->
			<ng-container *ngIf="group.milestone && group.milestone.length > 0; else noItems">
				<div class="event-items">
					<milestone-details-row
						*ngFor="let milestone of group.milestone"
						[_showMilestonesState]="isMilestonesVisible"
						[fxLayout]="'column'"
						fxLayoutAlign="center center"
						class="events"
						[milestone]="milestone"
						(viewIconClickedEvent)="onViewIconClicked(milestone)"
						(cardClickedEvent)="onCardClicked(milestone)"
					>
					</milestone-details-row>
				</div>
			</ng-container>

			<!-- No existing milestones message -->
			<ng-template #noItems>
				<ng-container *ngIf="!!payCycle">
					<div class="event-no-item">
						<div class="event-no-item-content">
							Please, select a date to set up the&nbsp;<span *ngIf="group.type === 'NETS'">Net</span
							><span *ngIf="group.type === 'TPP'">TPP</span>&nbsp;payment.
						</div>
					</div>
				</ng-container>
			</ng-template>

			<!-- No existing pay cycle message -->
		</div>
	</div>
</div>
