import { Action } from "@ngrx/store";

import { UserState } from "@modules/user-management/_models/user-management-api.interface";

export enum UserDataActionType {
	SET_USER = "[UserData] set user info",
	SET_ROLE = "[UserData] set role",
	SET_MAP_PERMISSION = "[UserData] set map permissions",
	SET_CLIENT_GLOBAL_FILTER_ENABLE = "[UserData] set client global filter enable"
}
export class UpdateUserData implements Action {
	readonly type = UserDataActionType.SET_USER;
	constructor(public payload: { userData: UserState }) {}
}
export class UpdateUserRole implements Action {
	readonly type = UserDataActionType.SET_ROLE;
	constructor(public payload: { userData: UserState }) {}
}

export class UpdateUserMapPermission implements Action {
	readonly type = UserDataActionType.SET_MAP_PERMISSION;
	constructor(public payload: { userData: UserState }) {}
}

export class UpdateClientGlobalFilterEnable implements Action {
	readonly type = UserDataActionType.SET_CLIENT_GLOBAL_FILTER_ENABLE;
	constructor(public payload: { userData: UserState }) {}
}

export type UserDataSetAction =
	| UpdateUserData
	| UpdateUserRole
	| UpdateUserMapPermission
	| UpdateClientGlobalFilterEnable;
