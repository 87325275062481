import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "uploadFileType"
})
export class FileTypePipe implements PipeTransform {
	transform(fileType: string): string {
		switch (fileType) {
			case "MASTER_DATA": {
				return "Master Data";
				break;
			}

			case "PAY ELECTIVES": {
				return "Master file";
				break;
			}

			default: {
				return "G2N";
				break;
			}
		}
	}
}
