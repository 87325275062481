<form [formGroup]="headcountForm">
	<div fxLayout="column" class="container" *ngFor="let type of createdCapabilites" [formGroupName]="type.paymentType">
		<div
			fxFlex
			fxLayout="column"
			formArrayName="config"
			*ngFor="let config of getConfigFormGroupArray(type.paymentType).controls; let i = index"
		>
			<span class="config-header"
				>{{ type.paymentType.toString() }} > {{ getAccountRoute(type.paymentType, i) }}</span
			>
			<div [formGroupName]="i">
				<div
					formArrayName="billingTypes"
					*ngFor="
						let billingType of getBillingTypesFormGroupArray(type.paymentType, i).controls;
						let f = index;
						last as lastRoute
					"
				>
					<mat-card class="card mat-elevation-z0" fxFlex [formGroupName]="f">
						<mat-card-content fxLayout="row" fxLayoutGap="25px" fxLayoutAlign="space-between center">
							<div fxLayout="column" fxFlex>
								<div fxLayout="row" fxLayoutAlign="space-between center">
									<div fxLayout="row" fxLayoutGap="35px" fxLayoutAlign="space-between center">
										<input-wrapper label="from">
											<input id="from" class="text-input" formControlName="from" type="number" />
										</input-wrapper>
										<input-wrapper label="to">
											<input id="to" class="text-input" formControlName="to" type="number" />
										</input-wrapper>
										<input-wrapper id="headcountCheckbox" class="checkbox">
											<label class="headcount"
												><input [checked]="!isFixed(type.paymentType, i, f)" type="checkbox" />
												<span class="prior-label">unlimited headcount</span>
											</label>
										</input-wrapper>
									</div>
								</div>

								<span
									id="addRangeBtn"
									*ngIf="lastRoute"
									(click)="addConfig(type.paymentType, i)"
									class="add-config"
								>
									<app-icon
										id="add-new-range"
										[name]="'add'"
										[color]="'var(--color-primary-1000)'"
									></app-icon>
									<span>Add New Range</span>
								</span>
							</div>
						</mat-card-content>
					</mat-card>
				</div>
			</div>
		</div>
	</div>
</form>
