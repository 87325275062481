import { Injectable, OnDestroy } from "@angular/core";
import { LegalEntityService } from "../../../../shared/services/legal-entity/legal-entity.service";
import { FormGroup } from "@angular/forms";
import { LegalEntityApi } from "../../_models/legal-entity-api.interface";
import { CommonLegalEntityService } from "../common/common-legal-entity.service";
import { Observable, of, Subject } from "rxjs";
import { map, mergeMap, switchMap, takeUntil } from "rxjs/operators";
import { Country } from "../../../../shared/models/country.interface";
import { SelectOption } from "../../../../shared/models/select-option.interface";
import { formatDataForSelect } from "../../../../shared/utils/format-select-data.util";
import { ContactPersonService } from "@shared/services/contact-person/contact-person.service";
import { LegalEntity } from "../../../../shared/models/legal-entity.interface";
import { Timezone } from "../../../../shared/models/legal-entity-data.interface";
import { ContactPerson } from "../../../../shared/models/contact-person.interface";

@Injectable()
export class UpdateLegalEntityService implements OnDestroy {
	private destroy$: Subject<void> = new Subject<void>();

	countryCode!: string;

	constructor(
		private legalEntityService: LegalEntityService,
		private commonLegalEntityService: CommonLegalEntityService,
		private contactPersonService: ContactPersonService
	) {}

	updateLegalEntity(legalEntityForm: FormGroup): Observable<LegalEntity> {
		// Format form data to api DTO

		const apiLegalEntity: LegalEntityApi = this.commonLegalEntityService.formatLegalEntityDataApi(
			legalEntityForm.getRawValue()
		);

		// Update Legal entity and assign local value
		if (apiLegalEntity.contactPersons) {
			return this.legalEntityService.updateLEgalEntity(apiLegalEntity).pipe(
				switchMap((updatedLegalEntity: LegalEntity) => {
					// Check if there are contacts to save
					if (apiLegalEntity.contactPersons) {
						let contactPersonObject = this.commonLegalEntityService.contactPersonObject(
							updatedLegalEntity.id,
							apiLegalEntity.contactPersons
						);

						return this.contactPersonService.addContactPerson(contactPersonObject).pipe(
							switchMap((contacts: ContactPerson[]) => {
								contacts
									? contacts.filter((contact: ContactPerson) =>
											contact.userId
												? this.contactPersonService
														.linkContactToEntity(updatedLegalEntity.id, contact.userId)
														.subscribe()
												: void 0
									  )
									: of([]);
								return of(updatedLegalEntity);
							})
						);
					} else {
						return of(updatedLegalEntity);
					}
				}),
				map((updatedLegalEntity: LegalEntity) => {
					// Replace legal entity locally
					const replaceLegalEntity = this.commonLegalEntityService.legalEntities.find(
						replaceLegalEntity => replaceLegalEntity.id === updatedLegalEntity.id
					);

					if (replaceLegalEntity) {
						const index = this.commonLegalEntityService.legalEntities.indexOf(replaceLegalEntity);
						this.commonLegalEntityService.legalEntities.splice(index, 1);
					}

					this.commonLegalEntityService.legalEntities.push(updatedLegalEntity);
					this.commonLegalEntityService.legalEntities$.next({
						value: this.commonLegalEntityService.legalEntities,
						type: "UPDATE"
					});

					return updatedLegalEntity;
				})
			);
		} else {
			return this.legalEntityService.updateLEgalEntity(apiLegalEntity).pipe(
				switchMap((updatedLegalEntity: LegalEntity) => {
					// Check if there are contacts to save
					return of(updatedLegalEntity);
				}),
				map((updatedLegalEntity: LegalEntity) => {
					// Replace legal entity locally
					const replaceLegalEntity = this.commonLegalEntityService.legalEntities.find(
						replaceLegalEntity => replaceLegalEntity.id === updatedLegalEntity.id
					);

					if (replaceLegalEntity) {
						const index = this.commonLegalEntityService.legalEntities.indexOf(replaceLegalEntity);
						this.commonLegalEntityService.legalEntities.splice(index, 1);
					}

					this.commonLegalEntityService.legalEntities.push(updatedLegalEntity);
					this.commonLegalEntityService.legalEntities$.next({
						value: this.commonLegalEntityService.legalEntities,
						type: "UPDATE"
					});

					return updatedLegalEntity;
				})
			);
		}
	}

	setupLegalEntityFormEventsUpdate(form: FormGroup, countries: Country[]) {
		//Set functionality for when selecting country should autofill region & timezone for update form
		form.controls.country.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((selectedCountry: string) => {
			if (selectedCountry) {
				const country: Country = countries.find(
					(country: Country) => country.country.code === selectedCountry
				) as Country;
				const timeZones: SelectOption[] = [];

				if (country?.timeZones.length > 0) {
					form.controls.timeZone.enable();

					country.timeZones.map(timezone => {
						timeZones.push(
							formatDataForSelect({
								value: timezone.name,
								text: timezone.name
							})
						);
					});
					form.get("timeZonesOptions")?.setValue(timeZones);
					form.controls.timeZone.setValue(country.timeZones[0].name);
					form.controls.timeZone.updateValueAndValidity();
				} else {
					form.controls.timeZone.disable();
					form.get("timeZonesOptions")?.setValue(timeZones);
					form.controls.timeZone.setValue(null);
					form.controls.timeZone.updateValueAndValidity();
				}
			}
		});
	}

	setInitialTimezone(form: FormGroup, countryCode: string, countries: Country[], timezoneInput: string): void {
		const country: Country = countries.find((country: Country) => country.country.code === countryCode) as Country;
		const timeZones: SelectOption[] = [];

		if (country?.timeZones.length > 0) {
			const currentTimezone: any = country.timeZones.find(
				(timezone: Timezone) => timezone.name === timezoneInput
			);

			country.timeZones.map(timezone => {
				timeZones.push(
					formatDataForSelect({
						value: timezone.name,
						text: timezone.name
					})
				);
			});

			form.get("timeZonesOptions")?.setValue(timeZones);
			form.controls.timeZone.setValue(currentTimezone?.name);
			form.controls.timeZone.updateValueAndValidity();
		} else {
			form.controls.timeZone.disable();
			form.get("timeZonesOptions")?.setValue(timeZones);
			form.controls.timeZone.setValue(null);
			form.controls.timeZone.updateValueAndValidity();
		}
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
