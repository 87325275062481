import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { GlobalDashboardEventsService } from "@modules/global-dashboard/services/global-dashboard-events.service";
import { getTableExpandAndCollapseAnimation } from "src/app/shared/utils/mat-table-animation.util";
import {
	MissingCardsTableDetailItem,
	PayGroupDetailsWithMissingCardCount
} from "../../../models/global-dashboard-interface";

@Component({
	selector: "app-missing-cards-table",
	templateUrl: "./missing-cards-table.component.html",
	styleUrls: ["./missing-cards-table.component.scss"],
	animations: [getTableExpandAndCollapseAnimation]
})
export class MissingCardsTableComponent {
	@Input() set missingCards(missingCards: MissingCardsTableDetailItem[]) {
		this.dataSourceMissingCards.data = missingCards;
		this.customerColumns = ["Customers", "Paygroups", "Missing Cards", "expand"];
		this.paygroupsColumns = ["first-padding", "Paygroups", "Missing Cards", "second-padding"];

		this.toggleElement(missingCards[0]);
	}

	dataSourceMissingCards: MatTableDataSource<MissingCardsTableDetailItem> = new MatTableDataSource();
	customerColumns: string[] = [];
	paygroupsColumns: string[] = [];
	expandedElement!: MissingCardsTableDetailItem | null;

	constructor(private cdr: ChangeDetectorRef, private globalDashboardEventsService: GlobalDashboardEventsService) {}

	toggleRow(element: MissingCardsTableDetailItem): void {
		element.payGroups && element.payGroups.length ? this.toggleElement(element) : null;
		this.cdr.detectChanges();
	}

	expandedState(row: MissingCardsTableDetailItem): string {
		return row &&
			row.customerDetails &&
			this.expandedElement &&
			this.expandedElement.customerDetails.id === row.customerDetails.id
			? "expanded"
			: "collapsed";
	}

	isExpanded(row: MissingCardsTableDetailItem): boolean {
		return row &&
			row.customerDetails &&
			this.expandedElement &&
			this.expandedElement.customerDetails.id === row.customerDetails.id
			? true
			: false;
	}

	toggleElement(row: MissingCardsTableDetailItem): void {
		row &&
		row.customerDetails &&
		this.expandedElement &&
		this.expandedElement.customerDetails.id === row.customerDetails.id
			? (this.expandedElement = null)
			: (this.expandedElement = row);
	}

	routeToEmployeeData(row: PayGroupDetailsWithMissingCardCount) {
		this.globalDashboardEventsService.routeFromGlobalDashboardToAnotherPage(
			row.payGroupDetails.id,
			"/global-dashboard/master-data"
		);
	}
}
