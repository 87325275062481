import { Injectable } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";
import { PayoutStatusName, Transaction } from "src/app/shared/models/payouts.types";

@Injectable({
	providedIn: "root"
})
export class PaymentsApiService {
	private reprocesTransactions$ = new ReplaySubject<boolean>();

	constructor() {}

	filterTransactions(tranasctions: Transaction[]): Transaction[] {
		const transactionsMap: Map<string, Transaction[]> =
			tranasctions.length > 0 ? this.groupTransactionsForPaymentMethods(tranasctions) : new Map();
		const idsFallbackTransactions: string[] =
			this.getTransactionsIdsForPayoutsWithPaidToFallbackStatus(tranasctions);
		const transactionsFilter: Transaction[] = this.getArrayWithDiferenteTransactions(
			transactionsMap,
			idsFallbackTransactions
		);

		return transactionsFilter;
	}

	getNewTransactionWithSamePaymentMethod(transactionsSamePayMethod: Transaction[]): Transaction[] {
		let date: Date;

		transactionsSamePayMethod.forEach((item: Transaction) => {
			if (!date) date = new Date(item.createdAt);

			if (date && date.getTime() < new Date(item.createdAt).getTime()) date = new Date(item.createdAt);
		});

		const transaction: Transaction | undefined = transactionsSamePayMethod.find(
			(item: Transaction) => new Date(item.createdAt).getTime() === date.getTime()
		);

		const transactions: Transaction[] = [];
		if (transaction) transactions.push(transaction);

		return transactions;
	}

	getArrayWithDiferenteTransactions(
		transactionMapByPMId: Map<string, Transaction[]>,
		idsFallbackTransactions: string[]
	): Transaction[] {
		let transactions: Transaction[] = [];

		transactionMapByPMId.forEach((value: Transaction[]) => {
			transactions = transactions.concat(
				this.splitTransactionsWithSamePaymentMethod(value, idsFallbackTransactions)
			);
		});
		return transactions;
	}

	splitTransactionsWithSamePaymentMethod(
		transactionsSameMP: Transaction[],
		idsFallbackTransactions: string[]
	): Transaction[] {
		const transactionsSplit: Transaction[] = [];
		const transactionsNoFallback: Transaction[] = [];

		if (transactionsSameMP.length === 1) transactionsSplit.push(transactionsSameMP[0]);
		else if (transactionsSameMP.length > 1) {
			idsFallbackTransactions.forEach((id: string) => {
				const filterid: Transaction[] = transactionsSameMP.filter((tx: Transaction) => tx.id === id);
				if (filterid.length > 0)
					transactionsSplit.push(this.getNewTransactionWithSamePaymentMethod(filterid)[0]);
			});

			transactionsSameMP.forEach((tx: Transaction) => {
				const isId: boolean = idsFallbackTransactions.includes(tx.id);
				if (!isId) transactionsNoFallback.push(tx);
			});

			transactionsSplit.push(this.getNewTransactionWithSamePaymentMethod(transactionsNoFallback)[0]);
		}

		return transactionsSplit;
	}

	getTransactionsIdsForPayoutsWithPaidToFallbackStatus(transactionsList: Transaction[]): string[] {
		const transactionsIds: string[] = [];
		const status = "PAID_TO_FALLBACK";

		const transactionsFallback: Transaction[] = transactionsList.filter(
			(tx: Transaction) => tx.status.name === status
		);

		transactionsFallback.forEach((tx: Transaction) => {
			if (transactionsIds.length == 0 && tx.attributes["FALLBACK_TRANSACTION_ID"])
				transactionsIds.push(tx.attributes["FALLBACK_TRANSACTION_ID"]);

			if (
				transactionsIds.length > 0 &&
				tx.attributes["FALLBACK_TRANSACTION_ID"] &&
				!transactionsIds.includes(tx.attributes["FALLBACK_TRANSACTION_ID"])
			)
				transactionsIds.push(tx.attributes["FALLBACK_TRANSACTION_ID"]);
		});

		return transactionsIds;
	}

	groupTransactionsForPaymentMethods(tranasctions: Transaction[]): Map<string, Transaction[] | undefined> {
		const transactionsMap: Map<string, Transaction[] | undefined> = new Map();

		tranasctions.forEach((item: Transaction) => {
			if (item.status.name != "PAID_TO_FALLBACK") {
				if (transactionsMap.has(item.payMethodId)) {
					const arrayTransactions = transactionsMap.get(item.payMethodId);
					arrayTransactions!.push(item);
					transactionsMap.set(item.payMethodId, arrayTransactions);
				} else transactionsMap.set(item.payMethodId, [item]);
			}
		});

		return transactionsMap;
	}

	reprocessTransactions(): Observable<boolean> {
		return this.reprocesTransactions$.asObservable();
	}

	updateReprocessTransactions(reprcess: boolean): void {
		this.reprocesTransactions$.next(reprcess);
	}

	getMostPessimisticStatus(transactionStatusses: string[], payoutStatus?: PayoutStatusName): PayoutStatusName {
		//From most pessimistic to least
		if (transactionStatusses.includes("INACTIVE")) {
			return PayoutStatusName.INACTIVE;
		} else if (transactionStatusses.includes("ERROR")) {
			return PayoutStatusName.ERROR;
		} else if (transactionStatusses.includes("FAILED")) {
			return PayoutStatusName.FAILED;
		} else if (transactionStatusses.includes("FAILED_TO_BOOK")) {
			return PayoutStatusName.FAILED_TO_BOOK;
		} else if (transactionStatusses.includes("DEPENDENT_ERROR")) {
			return PayoutStatusName.DEPENDENT_ERROR;
		} else if (transactionStatusses.includes("PAID_TO_FALLBACK")) {
			return PayoutStatusName.PAID_TO_FALLBACK;
		} else if (transactionStatusses.includes("PENDING")) {
			return PayoutStatusName.PENDING;
		} else if (transactionStatusses.includes("PROCESSING")) {
			return PayoutStatusName.PROCESSING;
		} else if (transactionStatusses.includes("CREATED")) {
			return PayoutStatusName.CREATED;
		} else if (transactionStatusses.includes("BOOKED")) {
			return PayoutStatusName.BOOKED;
		} else if (transactionStatusses.includes("PROCESSED")) {
			return PayoutStatusName.PROCESSED;
		} else {
			return payoutStatus ? payoutStatus : PayoutStatusName.UNKNOWN;
		}
	}

	getErrorCount(payoutStatuses: string[]): number {
		let count: number = 0;

		payoutStatuses.forEach(status => {
			if (this.canReprocess(status)) {
				count++;
			}
		});

		return count;
	}

	canReprocess(status: string): boolean {
		switch (status) {
			case "FAILED":
				return true;

			case "ATTEMPTS_LIMIT_EXCEEDED":
				return true;

			case "ERROR":
				return true;

			case "FAILED_TO_BOOK":
				return true;

			default:
				return false;
		}
	}
}
