import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../../environments/environment";
import {
	PayMethodsPagination,
	UpdateEmployeeBankAccountDTO,
	UpdateEmployeeBankAccountRankDTO
} from "../types/accounts";

@Injectable({
	providedIn: "root"
})
export class PayElectiveBankAccountsApiService {
	constructor(private http: HttpClient) {}

	createEmployeeBankAccount(bankAccount: any): Observable<any> {
		return this.http.post(`${environment.apiUri}/v1/employees/pay-methods/bank`, bankAccount);
	}

	updateEmployeeBankAccount(bankAccount: UpdateEmployeeBankAccountDTO): Observable<any> {
		return this.http.put(`${environment.apiUri}/v1/employees/pay-methods/bank`, bankAccount);
	}

	updateEmployeeBankAccountRank(bankAccount: UpdateEmployeeBankAccountRankDTO): Observable<any> {
		return this.http.post(`${environment.apiUri}/v1/employees/pay-methods/reorder`, bankAccount);
	}

	getEmployeeBankAccounts(employeeId: string): Observable<PayMethodsPagination> {
		return this.http.get<PayMethodsPagination>(
			`${environment.apiUri}/v1/employees/${employeeId}/pay-methods/details?payMethodTypes=BANK&pageSize=1000`
		);
	}

	getEmployeeCards(employeeId: string): Observable<PayMethodsPagination> {
		return this.http.get<PayMethodsPagination>(
			`${environment.apiUri}/v1/employees/${employeeId}/pay-methods/details?payMethodTypes=CARD&pageSize=1000`
		);
	}

	getEmployeePaymentMethods(employeeId: string): Observable<PayMethodsPagination> {
		return this.http.get<PayMethodsPagination>(
			`${environment.apiUri}/v1/employees/${employeeId}/pay-methods/details?payMethodTypes=CARD,BANK&pageSize=1000`
		);
	}

	deleteEmployeeBankAccount(employeeId: string, paymethodId: string): Observable<any> {
		return this.http.delete(`${environment.apiUri}/v1/employees/${employeeId}/pay-methods/${paymethodId}`);
	}

	reProcessEmployeeBankAccounts(payMethodIds: string[]) {
		return this.http.post(`${environment.apiUri}/v1/employees/pay-methods/reprocess`, {
			payMethodIds: [...payMethodIds]
		});
	}

	reProcessAll(payGroupId: string): Observable<any> {
		return this.http.post(`${environment.apiUri}/v1/employees/pay-methods/${payGroupId}/reprocess`, {});
	}
}
