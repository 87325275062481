import { EventEmitter, Injectable, OnDestroy } from "@angular/core";
import { forkJoin, Observable, Subject } from "rxjs";
import { map, take } from "rxjs/operators";
import { ToastService } from "@shared/services/toast/toast.service";
import {
	createEmployeeBankAccountDTO,
	Method,
	PaymentType,
	PayMethodDetail,
	PayMethodsPagination,
	Ranking,
	RankingMethodDTO,
	UpdateEmployeeBankAccountDTO,
	UpdateEmployeeBankAccountRankDTO
} from "../types/accounts";
import { PayElectiveBankAccountsApiService } from "./pay-elective-bank-accounts-api.service";
import { PayElectiveBankAccountsService } from "./pay-elective-bank-accounts.service";
import { PayElectiveCommonService } from "./pay-elective-common.service";
@Injectable({
	providedIn: "root"
})
export class PayElectiveAccountRankingService implements OnDestroy {
	constructor(
		private payElectiveCommonService: PayElectiveCommonService,
		private apiService: PayElectiveBankAccountsApiService,
		private toastService: ToastService
	) {}

	bankAccountsReordered: EventEmitter<boolean> = new EventEmitter();

	private destroy$: Subject<void> = new Subject<void>();
	private showRankUpdateBanner$ = new Subject<boolean>();

	showRankUpdateBannerObservable(): Observable<any> {
		return this.showRankUpdateBanner$.asObservable();
	}

	async updateBankAccountRank(
		employeeId: string,
		payGroupId: string,
		bankAccountData: PayMethodDetail[],
		payMethodsArrayLength: number
	): Promise<void> {
		const payMethods: RankingMethodDTO[] = [];

		let dataEmployeeAccounts: PayMethodsPagination[] = [];

		try {
			forkJoin([
				this.apiService.getEmployeeBankAccounts(employeeId),
				this.apiService.getEmployeeCards(employeeId)
			])
				.pipe(
					take(1),
					map((data: [PayMethodsPagination, PayMethodsPagination]) => {
						if (data[0].items.length) {
							dataEmployeeAccounts.push(data[0]);
						}

						if (data[1].items.length) {
							dataEmployeeAccounts.push(data[1]);
						}
					})
				)
				.subscribe(x => {
					bankAccountData.forEach((element, index) => {
						let rankingObject: Ranking;
						let updatedVersionNumber: number = 0;

						const accountsArray: UpdateEmployeeBankAccountRankDTO = {
							payMethods: payMethods
						};

						// Check every element make sure all remaining has the max index
						rankingObject = this.payElectiveCommonService.allRemainingCheck(
							element.payMethod.ranking!.type,
							element.payMethod.ranking!.amount,
							index,
							payMethodsArrayLength
						);

						// Update each elements version and add it to the paymethods array

						dataEmployeeAccounts.forEach(x => {
							x.items!.filter(function (obj) {
								return obj.payMethod.id === element.payMethod.id!;
							}).map(function (obj) {
								updatedVersionNumber = obj.payMethod.version;
							});
						});

						const accountObject: RankingMethodDTO = {
							id: element.payMethod.id,
							version: updatedVersionNumber,
							ranking: rankingObject!
						};

						payMethods.push(accountObject);

						// If accounts array has all the paymethods we call the rank update endpoint

						if (accountsArray.payMethods.length === payMethodsArrayLength) {
							this.apiService.updateEmployeeBankAccountRank(accountsArray).subscribe({
								next: _ => {
									this.toastService.showSuccess("Account updated successfully");
									this.bankAccountsReordered.emit(true);

									if (this.payElectiveCommonService.getShowUpdateBannerCount() > 0) {
										this.showRankUpdateBanner$.next(true);
										this.payElectiveCommonService.resetShowUpdateRankBannerCount();
									}
								},
								error: _ => {
									this.toastService.showError("Error updating your account, please try again");
								}
							});
						}
					});
				});
		} catch (error) {
			this.toastService.showError("Error updating your account, please try again");
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
