import { Component, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, takeUntil } from "rxjs/operators";
import { Capability, ServiceProviderCountryCapability } from "src/app/shared/models/service-provider-country-types";
import { ToastService } from "../../../../../shared/services/toast/toast.service";
import { PaymentTypeName } from "../../../../../shared/models/service-provider.type";
import { ServiceProviderCountryCreateService } from "../../../_services/service-provider-country-create/service-provider-country-create.service";

@Component({
	selector: "app-headcount-configuration",
	templateUrl: "./headcount-configuration.component.html",
	styleUrls: ["./headcount-configuration.component.scss"]
})
export class HeadcountConfigurationComponent implements OnInit {
	createdCapabilites: ServiceProviderCountryCapability[] = [];

	subscription: Subscription = new Subscription();
	destroy$: Subject<void> = new Subject();

	headcountForm!: FormGroup;

	mappedCapabilities: Map<PaymentTypeName, any[]> = new Map<PaymentTypeName, any[]>([]);

	constructor(
		private readonly formBuilder: FormBuilder,
		private readonly serviceProviderCountryCreateService: ServiceProviderCountryCreateService,
		private readonly toastService: ToastService
	) {}

	ngOnInit(): void {
		this.initForm();
		this.initSubscriptions();
	}

	initForm(): void {
		this.headcountForm = this.formBuilder.group({});
	}

	initSubscriptions(): void {
		this.subscription = this.serviceProviderCountryCreateService.capabilityData$
			.pipe(takeUntil(this.destroy$))
			.subscribe(capabilities => {
				if (capabilities) {
					this.createdCapabilites = capabilities;
					this.createCapabilityGroups(capabilities!);
				}
			});

		this.headcountForm.valueChanges
			.pipe(takeUntil(this.destroy$), debounceTime(400), distinctUntilChanged())
			.subscribe(() => {
				this.serviceProviderCountryCreateService.updateCostsForm(this.headcountForm.getRawValue());
			});
	}

	createCapabilityGroups(capabilities: ServiceProviderCountryCapability[]): void {
		console.log(capabilities);
		capabilities.filter(capabilityData => {
			const control = this.headcountForm.get(capabilityData.paymentType.toString())?.value;
			if (control) {
				this.headcountForm.removeControl(capabilityData.paymentType.toString());
			}
			const group = this.formBuilder.group({
				paymentType: capabilityData.paymentType,
				config: this.formBuilder.array([])
			});
			capabilityData.capabilities.filter(capability => {
				const configArray = group.get("config") as FormArray;

				capability.costs.filter(cost => {
					const configControl = this.formBuilder.group({
						payoutAccount: capability.payoutAccount,
						route: capability.route,
						billingTypes: this.formBuilder.array([
							this.formBuilder.group({
								from: [cost.billingType?.from],
								to: [cost.billingType?.to]
							})
						])
					});
					configArray.push(configControl);
				});
				// capability.costs.filter()
				this.headcountForm.addControl(capabilityData.paymentType.toString(), group);
			});
		});
	}

	getConfigFormGroupArray(paymentType: PaymentTypeName) {
		return this.headcountForm.get(`${paymentType.toString()}.config`) as FormArray;
	}

	getBillingTypesFormGroupArray(paymentType: PaymentTypeName, index: number): FormArray {
		const controls = this.getConfigFormGroupArray(paymentType).at(index) as FormArray;

		return controls.get("billingTypes") as FormArray;
	}

	getAccountRoute(paymentType: PaymentTypeName, index: number) {
		const payoutRoute = this.getConfigFormGroupArray(paymentType).at(index)?.value;
		return `${payoutRoute.payoutAccount} > ${payoutRoute.route}`;
	}

	isFixed(paymentType: PaymentTypeName, index: number, headcountIndex: number): boolean {
		return !!this.getBillingTypesFormGroupArray(paymentType, index)?.at(headcountIndex).value.to;
	}

	addConfig(paymentType: PaymentTypeName, index: number): void {
		const headCountArray = this.getBillingTypesFormGroupArray(paymentType, index) as FormArray;

		const currentHeadCount = headCountArray.value;

		const newFromValue: { from: number; to: number } = currentHeadCount.pop();

		if (!newFromValue.to) {
			this.toastService.showWarning("Please set a 'TO' value before adding a headcount configuration");
			return;
		}

		headCountArray.push(
			this.formBuilder.group({
				from: [newFromValue.to + 1],
				to: [null]
			})
		);
	}

	removeConfig(paymentType: PaymentTypeName, index: number, confIndex: number): void {
		this.getBillingTypesFormGroupArray(paymentType, index).removeAt(confIndex);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
