import { Component, OnInit } from "@angular/core";

import { Customer } from "src/app/shared/models/customer.interface";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { UpdateCustomerEntityGroupAction } from "src/app/store/actions/customerEntityGroupSelect.action";

import { AppState } from "src/app/store/models/state.model";
import { TabStateService } from "@shared/services/tab-state/tab-state.service";

@Component({
	selector: "app-service-definition",
	templateUrl: "./service-definition.page.html",
	styleUrls: ["./service-definition.page.scss"]
})
export class ServiceDefinitionPage implements OnInit {
	title: string = "";
	serviceDefinitionType!: string;

	constructor(private router: Router, private store: Store<AppState>, private tabStateService: TabStateService) {}

	ngOnInit() {
		this.tabStateService.setBreadcrumbIdentifier([]);
		if (this.router.url === "/service-definition/tpp") {
			this.title = "Tpp - Service Definition";
			this.serviceDefinitionType = "tpp";
		} else {
			this.title = "Service Definition";
			this.serviceDefinitionType = "net";
		}
	}

	customerSelected(customer: Customer) {
		// TODO WHY IS THIS NOT DEFINED IN THE ROUTING MODULE?
		if (this.serviceDefinitionType === "tpp") {
			this.router.navigate([`service-definition/${this.serviceDefinitionType}/select-paygroup`]);
		} else {
			this.router.navigate([`service-definition/${this.serviceDefinitionType}/entity-select`]);
		}

		this.store.dispatch(
			new UpdateCustomerEntityGroupAction({
				customerId: customer.id,
				customerName: customer.name,
				legalEntityId: "",
				payGroupId: ""
			})
		);
	}
}
