<div class="subservice-list-page-container">
	<div class="column-labels">
		<span fxFlex="1"> <div class="placeholder"></div> </span>
		<span class="label" fxFlex="13">DATE</span>
		<span class="label" fxFlex="12">FILE NAME</span>
		<span class="label" fxFlex="22">SUBSERVICE</span>
		<span class="label" fxFlex="12">STATUS</span>
		<span class="label" fxFlex="15"></span>
	</div>

	<app-tpp-file-history-list-item
		*ngFor="let file of files; let i = index"
		[_file]="file"
		[_opacity]="lastResetIndex < i ? true : false"
		(viewFileErrors)="viewFileErrors.emit($event)"
		(downloadFile)="downloadFile.emit($event)"
	></app-tpp-file-history-list-item>
</div>
