<ng-template [appUserRolePermissions] [action]="'hide'" [roles]="['TS_ADMIN']">
	<div class="calendar-actions-container">
		<button class="calendar-actions" mat-button (click)="showCalendarFiles()">
			<span class="uplaod-text">
				<app-icon
					[name]="'eye'"
					[color]="'var(--color-upload-button-dark-grey)'"
					[lineWidth]="'2px'"
				></app-icon>
				<span class="text-spacing body-small-bold">CALENDAR FILES</span>
			</span>
		</button>
		<button class="calendar-input" [cdkMenuTriggerFor]="menu" mat-button>
			<span class="uplaod-text">
				<app-icon [name]="'document-1'" [color]="'white'" [lineWidth]="'2px'"></app-icon>
				<span class="text-spacing white body-small-bold">CALENDAR INPUT</span>
			</span>
		</button>
		<input
			hidden
			type="file"
			#uploader
			(change)="uploadFile($event, uploader.files)"
			(click)="uploader.value = ''"
		/>
		<ng-template #menu>
			<div class="sub-menu" cdkMenu>
				<ng-template [appUserRolePermissions] [action]="'hide'" [roles]="['TS_VO']">
					<button class="calendar-actions" cdkMenuItem (cdkMenuItemTriggered)="uploader.click()">
						<span class="uplaod-text">
							<app-icon
								[name]="'document-upload'"
								[color]="'var(--color-upload-button-dark-grey)'"
								[lineWidth]="'1px'"
								[size]="'24'"
							></app-icon>
							<span class="text-spacing">Upload file</span>
						</span>
					</button>
				</ng-template>
				<button class="calendar-actions" cdkMenuItem (click)="downloadTemplate()">
					<span class="uplaod-text">
						<app-icon
							[name]="'document-download'"
							[color]="'var(--color-upload-button-dark-grey)'"
							[lineWidth]="'1px'"
							[size]="'24'"
						></app-icon>
						<span class="text-spacing">Download template</span>
					</span>
				</button>
			</div>
		</ng-template>
	</div>
</ng-template>
