import { Action } from "@ngrx/store";

import { UserState } from "@modules/user-management/_models/user-management-api.interface";
import { UserDataActionType, UserDataSetAction } from "@store/actions/userData.action";

const initialState: UserState = {
	firstName: "",
	lastName: "",
	email: "",
	isForgeRock: false,
	initials: "",
	roles: [],
	canSeeMap: false,
	isClientGlobalDashboard: false
};

export const userDataReducer = (state = initialState, action: Action): UserState => {
	const selectAction = action as UserDataSetAction;

	switch (selectAction.type) {
		case UserDataActionType.SET_USER:
			return {
				...state,
				firstName: selectAction.payload.userData.firstName,
				lastName: selectAction.payload.userData.lastName,
				email: selectAction.payload.userData.email,
				initials: selectAction.payload.userData.initials,
				isForgeRock: selectAction.payload.userData.isForgeRock
			};

		case UserDataActionType.SET_ROLE:
			return {
				...state,
				roles: selectAction.payload.userData.roles
			};

		case UserDataActionType.SET_MAP_PERMISSION:
			return {
				...state,
				canSeeMap: selectAction.payload.userData.canSeeMap
			};

		case UserDataActionType.SET_CLIENT_GLOBAL_FILTER_ENABLE:
			return {
				...state,
				isClientGlobalDashboard: selectAction.payload.userData.isClientGlobalDashboard
			};

		default:
			return state;
	}
};
