import { Action } from "@ngrx/store";
import { tppServiceSelect } from "../models/tppServiceSelection.model";

export enum tppServiceSelectActionType {
	SET_SELECTED = "[TPPServiceSelected] Set Selected"
}
export class UpdateSelectedTPPService implements Action {
	readonly type = tppServiceSelectActionType.SET_SELECTED;

	//add an optional payload
	constructor(public payload: { selectedTPPService: tppServiceSelect }) {}
}
export type tppServiceSelectAction = UpdateSelectedTPPService;
