import { Pipe, PipeTransform } from "@angular/core";
import { SelectOption } from "src/app/shared/models/select-option.interface";

@Pipe({
	name: "roles"
})
export class RolesPipe implements PipeTransform {
	transform(roles: string[]): SelectOption[] {
		let rolesSelection: SelectOption[] = [];
		if (roles) {
			roles.forEach((role: string) => {
				switch (role) {
					case "CS_ADMIN":
						rolesSelection.push({ value: "CS_ADMIN", text: "CS ADMIN", placeholder: "CSA" });
						break;

					case "EMPLOYEE":
						rolesSelection.push({ value: "EMPLOYEE", text: "EMPLOYEE", placeholder: "EMP" });
						break;

					case "IMPLEMENTATION_CONSULTANT":
						rolesSelection.push({
							value: "IMPLEMENTATION_CONSULTANT",
							text: "IMPLEMENTATION CONSULTANT",
							placeholder: "IMPC"
						});
						break;

					case "SUPER_ADMIN":
						rolesSelection.push({ value: "SUPER_ADMIN", text: "SUPER ADMIN", placeholder: "SA" });
						break;

					case "TS_ADMIN":
						rolesSelection.push({ value: "TS_ADMIN", text: "TS ADMIN", placeholder: "TA" });
						break;

					case "TS_IMPLEMENTATION_CONSULTANT":
						rolesSelection.push({
							value: "TS_IMPLEMENTATION_CONSULTANT",
							text: "TS IMPLEMENTATION CONSULTANT",
							placeholder: "TIC"
						});
						break;

					case "TS_IMPLEMENTATION_LEAD":
						rolesSelection.push({
							value: "TS_IMPLEMENTATION_LEAD",
							text: "TS IMPLEMENTATION LEAD",
							placeholder: "TITL"
						});
						break;

					case "TS_OPERATION_CONSULTANT":
						rolesSelection.push({
							value: "TS_OPERATION_CONSULTANT",
							text: "TS OPERATION CONSULTANT",
							placeholder: "TOC"
						});
						break;

					case "TS_OPERATION_LEAD":
						rolesSelection.push({
							value: "TS_OPERATION_LEAD",
							text: "TS OPERATION LEAD",
							placeholder: "TOL"
						});
						break;

					case "TS_OPERATION_MANAGER":
						rolesSelection.push({
							value: "TS_OPERATION_MANAGER",
							text: "TS OPERATION MANAGER",
							placeholder: "TOM"
						});
						break;

					case "CLIENT":
						rolesSelection.push({
							value: "CLIENT",
							text: "CLIENT",
							placeholder: "CLIENT"
						});
						break;
					case "TS_OCB":
						rolesSelection.push({
							value: "TS_OCB",
							text: "TS BOOKER",
							placeholder: "TOCB"
						});
						break;
					case "TS_VO":
						rolesSelection.push({
							value: "TS_VO",
							text: "TS VIEW ONLY",
							placeholder: "TVO"
						});
						break;
				}
			});
		}
		return rolesSelection;
	}
}
