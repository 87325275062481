<!-- Side menu -->

<div
	class="div"
	[ngClass]="{
		'main-drawer-open': sideNavOpen,
		'main-drawer-closed': !sideNavOpen
	}"
	(mouseover)="!bigScreen ? (sideNavOpen = true) : (hover = true)"
	(mouseleave)="!bigScreen ? (sideNavOpen = false) : (hover = false)"
	class="main-drawer"
>
	<div class="top-block">
		<!-- Header -->
		<div class="drawer-header" [ngClass]="{ divided: !sideNavOpen }">
			<div class="logo-container" *ngIf="!isClientRole()">
				<img class="logo-image" src="{{ cloudPayLogo }}" />
			</div>
			<div class="image-container" *ngIf="isClientRole() && customerLogo">
				<img
					class="logo-image"
					[ngStyle]="{ 'background-color': customerLogo.primaryColour }"
					[ngClass]="{ 'big-logo': sideNavOpen }"
					src="{{ customerLogo.logo }}"
				/>
			</div>
		</div>

		<!-- Navigation -->
		<div class="drawer-content">
			<!-- Separator -->
			<div class="side-menu-separator"></div>

			<ng-container *ngIf="!isClientPage">
				<ng-container *ngFor="let category of menu; let i = index">
					<!-- Side Navigation Open -->
					<ng-container *ngIf="sideNavOpen">
						<!-- Standalone CATEGORY navigation -->
						<ng-container *ngIf="category.shouldShow && !category.hideForUser">
							<ng-container *ngIf="category.standalone; else noCategoryStandalone">
								<!-- Navigation -->
								<mat-nav-list
									class="navigation-item-category"
									[ngClass]="{ active: category.sections[0].navigations[0].active }"
								>
									<a
										mat-list-item
										[routerLink]="
											category.isAdditionalNavigation
												? category.sections[0].navigations[0]?.additionalNavigation
												: category.sections[0].navigations[0].url
										"
										class="navigation-link"
										(click)="menuItemClicked()"
										id="{{ category.title }}"
									>
										<app-icon
											class="icon-menu-item"
											name="{{ category.icon }}"
											[color]="'#98f3e1'"
										></app-icon>
										<div>{{ category.title }}</div>
									</a>
								</mat-nav-list>
							</ng-container>
						</ng-container>

						<!-- No standalone CATEGORY navigation -->
						<ng-template #noCategoryStandalone>
							<mat-accordion *ngIf="sideNavOpen">
								<mat-expansion-panel [expanded]="category.expanded">
									<mat-expansion-panel-header expandedHeight="64px" collapsedHeight="68px">
										<mat-panel-title id="{{ category.title }}" class="menu-panel-title">
											<app-icon
												class="icon-menu-item"
												name="{{ category.icon }}"
												[color]="'#98f3e1'"
											></app-icon>
											<div>{{ category.title }}</div>
										</mat-panel-title>
									</mat-expansion-panel-header>

									<ng-container *ngFor="let section of category.sections">
										<!-- Standalone SECTION navigation -->
										<ng-container *ngIf="section.standalone; else noSectionStandalone">
											<!-- Navigation -->
											<mat-nav-list
												*ngIf="section.shouldShow || section.shouldShow === undefined"
												class="navigation-item-section"
												[ngClass]="{ active: section.navigations[0].active }"
											>
												<a
													mat-list-item
													[routerLink]="section.navigations[0].url"
													class="navigation-link"
													id="{{ section.title }}"
													(click)="menuItemClicked()"
												>
													{{ section.title }}
												</a>
											</mat-nav-list>
										</ng-container>

										<!-- No standalone SECTION navigation -->
										<ng-template #noSectionStandalone>
											<mat-accordion>
												<mat-expansion-panel
													[expanded]="section.expanded"
													class="sub-header"
													[ngClass]="{ active: section.active }"
												>
													<!-- Section -->
													<mat-expansion-panel-header
														expandedHeight="44px"
														collapsedHeight="48px"
													>
														<mat-panel-title class="menu-panel-title section">
															<div id="{{ section.title }}">{{ section.title }}</div>
														</mat-panel-title>
													</mat-expansion-panel-header>

													<!-- Navigations -->
													<mat-nav-list>
														<a
															mat-list-item
															*ngFor="let navigation of section.navigations"
															[routerLink]="navigation.url"
															class="navigation-item"
															id="{{ navigation.title }}"
															[ngClass]="{ active: navigation.active }"
															(click)="menuItemClicked()"
														>
															{{ navigation.title }}
														</a>
													</mat-nav-list>
												</mat-expansion-panel>
											</mat-accordion>
										</ng-template>
									</ng-container>
								</mat-expansion-panel>
							</mat-accordion>
						</ng-template>
					</ng-container>

					<!-- Side Navigation NOT Open -->
					<div
						*ngIf="!sideNavOpen && category.shouldShow && !category.hideForUser"
						class="side-nav-icon-container menu-panel-title"
						(click)="openNavItem(category)"
						[ngClass]="{ active: category.sections[0].navigations[0].active }"
					>
						<app-icon
							class="material-icons-outlined"
							name="{{ category.icon }}"
							[color]="'#98f3e1'"
						></app-icon>
					</div>

					<!-- Separator -->
					<div class="side-menu-separator"></div>
				</ng-container>
			</ng-container>

			<ng-container *ngIf="isClientPage">
				<div class="county-selection-container">
					<span
						class="country-option"
						*ngFor="let country of countrylist"
						[ngStyle]="{
							'justify-content': sideNavOpen ? 'flex-start' : 'center'
						}"
						(click)="legalEntitiesSelected.emit(country.valueArray)"
						id="{{ country.value }}"
						(mouseenter)="setHoverStyle(country.value, true)"
						(mouseleave)="setHoverStyle(country.value, false)"
					>
						<span class="flag" [ngClass]="{ 'big-flag': !sideNavOpen, 'small-flag': sideNavOpen }"
							><img src="assets/svg-country-flags/svg/{{ country.value.toLowerCase() }}.svg"
						/></span>
						<div class="text" *ngIf="sideNavOpen">{{ country.text }}</div>
					</span>
				</div>
			</ng-container>
		</div>
	</div>

	<div class="bottom-block" *ngIf="user">
		<div class="profile-section">
			<div
				class="profile-section-collapsed"
				[ngClass]="{ show: !sideNavOpen || isClientPage, hide: sideNavOpen && !isClientPage }"
			>
				<div class="avatar-section">
					<button
						mat-icon-button
						[matMenuTriggerFor]="menu"
						#menuTrigger="matMenuTrigger"
						(mouseover)="menuTrigger.openMenu()"
					>
						<div class="avatar">
							<span class="initials">{{ user.initials }}</span>
						</div>
					</button>
					<div class="image-container" *ngIf="isClientRole()">
						<img class="logo-image" src="{{ cloudPayClientLogo }}" />
					</div>
				</div>

				<mat-menu class="profile-menu" #menu="matMenu" [overlapTrigger]="false">
					<span (mouseleave)="menuTrigger.closeMenu()">
						<mat-list>
							<mat-list-item>
								<div matListAvatar class="avatar-small"></div>
								<div matLine class="text-user-name-container-menu">
									<span class="text-username-menu">{{ user.firstName }} {{ user.lastName }} </span>
								</div>
								<p matLine class="text-email-menu">{{ user.email }}</p>
							</mat-list-item>
						</mat-list>

						<mat-divider class="profile-divider"></mat-divider>

						<button (click)="logout()" mat-button class="mat-menu-logout-btn">
							<app-icon
								[name]="'login-1'"
								class="icon"
								[size]="'30px'"
								[color]="'var(--color-profile-menu-text)'"
							></app-icon
							>Log out
						</button>

						<div class="version-menu">v{{ version }}</div>
					</span>
				</mat-menu>
			</div>
			<div
				class="profile-section-full-view"
				*ngIf="!isClientPage"
				[ngClass]="{ show: sideNavOpen && !isClientPage, hide: !sideNavOpen || isClientPage }"
			>
				<button (click)="logout()" mat-button class="side-nav-logout-btn">
					<app-icon
						[name]="'login-1'"
						class="icon"
						[size]="'30px'"
						[color]="'var(--color-disabled)'"
					></app-icon
					>Log out
				</button>
				<mat-divider class="profile-divider"></mat-divider>
				<mat-list>
					<mat-list-item>
						<div matListAvatar class="avatar-small"></div>
						<div matLine class="text-user-name-container">
							<span class="text-username">{{ user.firstName }} {{ user.lastName }} </span>
						</div>
						<p matLine class="text-email" [matTooltip]="user.email!">{{ user.email }}</p>
					</mat-list-item>
				</mat-list>
				<!-- Current app version -->
				<div class="version">v{{ version }}</div>
			</div>
		</div>
	</div>

	<!-- Side navigation button -->
	<button
		mat-button
		class="side-nav-button"
		[ngClass]="{ opened: sideNavOpen, closed: !sideNavOpen }"
		(click)="toggleSizeNaveState()"
		*ngIf="bigScreen"
	>
		<app-icon
			id="side-nav-button-icon-left"
			class="icon"
			*ngIf="sideNavOpen"
			[name]="'arrow-left-1'"
			[color]="'#222'"
			[lineWidth]="'2px'"
			[size]="'20px'"
		></app-icon>
		<app-icon
			class="icon"
			*ngIf="!sideNavOpen"
			[name]="'arrow-right-1'"
			[color]="'#222'"
			[lineWidth]="'2px'"
			[size]="'20px'"
		></app-icon>
	</button>

	<!-- End of side menu -->
</div>
