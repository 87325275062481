import { CommonModule } from "@angular/common";
import { Injector, NgModule } from "@angular/core";
import { createCustomElement } from "@angular/elements";

import { BreadcrumbsModule } from "@shared/components/breadcrumbs/breadcrumbs.module";
import { SharedModule } from "@shared/shared.module";
import { WorldMapComponent } from "./_components/world-map/world-map.component";
import { ClientLandingComponent } from "./_container/client-landing.component";

import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { MapPopupComponent } from "./_components/map-popup/map-popup.component";
import { ClientLandingRoutingModule } from "./client-landing-routing.module";
import { MatButtonModule } from "@angular/material/button";
import { GlobalDashboardButtonComponent } from "src/app/standalone/global-dashboard-button/global-dashboard-button.component";

@NgModule({
	declarations: [ClientLandingComponent, WorldMapComponent, MapPopupComponent],
	imports: [
		CommonModule,
		SharedModule,
		ClientLandingRoutingModule,
		BreadcrumbsModule,
		LeafletModule,
		MatButtonModule,
		GlobalDashboardButtonComponent
	]
})
export class ClientLandingModule {
	constructor(private injector: Injector) {
		const PopupElement = createCustomElement(MapPopupComponent, { injector });
		if (!customElements.get("popup-element")) customElements.define("popup-element", PopupElement);
	}
}
