<div class="booking-list-page-container">
	<app-tpp-payment-payout-list-item
		*ngFor="let booking of bookings; let i = index; last as isLast"
		[_booking]="booking"
		[_isLast]="isLast"
		[_reprocessPayoutIdsList]="reprocessPayoutIdsList"
		[_isCancelledRun]="isCancelledRun"
		(inActivatePayouts)="inActivatePayouts.emit($event)"
		(pushToReprocessList)="pushToReprocessList.emit($event)"
		(popFromReprocessList)="popFromReprocessList.emit($event)"
	></app-tpp-payment-payout-list-item>
</div>
