import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { CalendarFilesHistoryService } from "@modules/calendar-setup-file-history/services/calendar-files-history.service";

import { FileManagementUploadService } from "@shared/services/file-management/file-management-upload.service";
import { ToastService } from "@shared/services/toast/toast.service";
import { take } from "rxjs";

@Component({
	selector: "app-calendar-actions",
	templateUrl: "./calendar-actions.component.html",
	styleUrls: ["./calendar-actions.component.scss"]
})
export class CalendarActionsComponent {
	constructor(
		private router: Router,
		private toastService: ToastService,
		private fileManagementUploadService: FileManagementUploadService,
		private calendarFilesHistoryService: CalendarFilesHistoryService
	) {}

	showCalendarFiles(): void {
		this.router.navigateByUrl("/calendar/files");
	}

	uploadFile(event: Event, files: any): void {
		event.stopPropagation();

		try {
			const file: File = files[0];

			let formData = new FormData();
			formData.append("file", file);
			formData.append("fileType", "EXCEL");
			formData.append("sheetIndex", "0");
			formData.append("headerIndex", "0");

			this.fileManagementUploadService.uploadCalendarFile(formData);

			this.router.navigateByUrl("/calendar/files");
		} catch (error) {
			this.toastService.showError("There was an error loading the file. Please try again");
		}
	}

	downloadTemplate(): void {
		this.calendarFilesHistoryService
			.createCalendarReportTask()
			.pipe(take(1))
			.subscribe({
				next: _ => void 0,
				error: (err: any) => {
					if (err.name === "TimeoutError") {
						this.toastService.showError(
							"An error occured while trying to download the template. Please try again"
						);
					} else {
						switch (err.status) {
							case 200:
								this.toastService.showSuccess("Template download request successfully initiated!");
								this.router.navigateByUrl("/calendar/files");
								break;

							default:
								this.toastService.showError(
									"An error occured while trying to download the template. Please try again later"
								);
								break;
						}
					}
				}
			});
	}
}
