<!-- Currency selector -->
<form [formGroup]="currenciesForm">
	<input-wrapper label="Available Payment Currencies *" class="currency-selector">
		<input-autocomplete
			formControlName="currencies"
			label="Select payment currency"
			[options]="currencyOptions$ | async"
			[required]="true"
			[multiple]="true"
			[disabled]="!isSuperUser"
		>
		</input-autocomplete>
		<div
			input-errors
			*ngIf="currenciesForm.controls.currencies?.errors?.required && currenciesForm.controls.currencies?.touched"
		>
			At least one funding currency must be selected
		</div>
	</input-wrapper>

	<!-- funding methods -->
	<input-wrapper label="Available funding methods *">
		<div
			id="fundingMethods"
			class="checkbox-container"
			[ngClass]="{ 'checkbox-container-error': currenciesForm.hasError('checkbox') }"
		>
			<label><input type="checkbox" formControlName="pushMethod" />PUSH</label>
			<label><input type="checkbox" formControlName="pullMethod" />PULL</label>
		</div>
		<div id="fundingMethodsError" input-errors *ngIf="currenciesForm.hasError('checkbox')">
			At least one funding method must be selected
		</div>
	</input-wrapper>
</form>

<!-- Table -->
<div class="table-container" role="table" aria-label="Capabilities">
	<!-- Header -->
	<div class="flex-table header" role="rowgroup">
		<div class="flex-row first" role="columnheader">Payment Types</div>
		<div class="flex-row" role="columnheader">Payout Account Type *</div>
		<div class="flex-row" role="columnheader">Payment Routes *</div>
		<div class="flex-row" role="columnheader">Days To Value *</div>
	</div>

	<app-capabilties-edit-form [isSuperUser]="isSuperUser" (onFormChecked)="onCheckForm()"></app-capabilties-edit-form>
</div>
