import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { GlobalDashboardEventsService } from "@modules/global-dashboard/services/global-dashboard-events.service";
import { getTableExpandAndCollapseAnimation } from "@shared/utils/mat-table-animation.util";
import {
	CustomerTableDetailItem,
	LegalEntityDetailsWithPaygroups,
	PayGroupDetails
} from "@modules/global-dashboard/models/global-dashboard-interface";

@Component({
	selector: "app-landing-page-customer-table",
	templateUrl: "./landing-page-customer-table.component.html",
	styleUrls: ["./landing-page-customer-table.component.scss"],
	animations: [getTableExpandAndCollapseAnimation]
})
export class LandingPageCustomerTableComponent {
	@Input() set customers(customers: CustomerTableDetailItem[]) {
		this.dataSourceCustomers.data = customers;
		this.columns = ["column-one", "column-two", "column-three", "column-four"];
	}

	@Output() fetchLegalEntities: EventEmitter<string> = new EventEmitter<string>();
	@Output() fetchPaygroups: EventEmitter<[string, string]> = new EventEmitter<[string, string]>();

	dataSourceCustomers: MatTableDataSource<CustomerTableDetailItem> = new MatTableDataSource();
	columns: string[] = [];
	expandedCustomer!: CustomerTableDetailItem;
	expandedLegalEntity!: LegalEntityDetailsWithPaygroups;
	selectedCustomerElement: CustomerTableDetailItem = {} as CustomerTableDetailItem;
	selectedLegalEntityElement: LegalEntityDetailsWithPaygroups = {} as LegalEntityDetailsWithPaygroups;
	hoveredPaygroupId!: string;
	customerNameLocator!: string;

	constructor(private cdr: ChangeDetectorRef, private globalDashboardEventsService: GlobalDashboardEventsService) {}

	setHoveredPaygroup(paygroupId: string): void {
		this.hoveredPaygroupId = paygroupId;
	}

	toggleCustomer(element: CustomerTableDetailItem): void {
		if (element) {
			this.customerNameLocator = element.customerDetails.name;
			this.selectedCustomerElement = element;
			element.customerDetails &&
			element.customerDetails.legalEntities &&
			element.customerDetails.legalEntities.length
				? this.setSelectedCustomer(element)
				: (this.fetchLegalEntities.emit(this.selectedCustomerElement.customerDetails.id),
				  this.setSelectedCustomer(element));
			this.cdr.detectChanges();
		}
	}

	setSelectedCustomer(customer: CustomerTableDetailItem): void {
		customer &&
		customer.customerDetails &&
		this.expandedCustomer &&
		this.expandedCustomer.customerDetails &&
		this.expandedCustomer.customerDetails.id === customer.customerDetails.id
			? ((this.expandedCustomer = {} as CustomerTableDetailItem),
			  (this.expandedLegalEntity = {} as LegalEntityDetailsWithPaygroups))
			: (this.expandedCustomer = customer);
	}

	isCustomerExpanded(customer: CustomerTableDetailItem): boolean {
		return customer &&
			customer.customerDetails &&
			this.expandedCustomer &&
			this.expandedCustomer.customerDetails &&
			this.expandedCustomer.customerDetails.id === customer.customerDetails.id
			? true
			: false;
	}

	toggleLegalEntity(element: LegalEntityDetailsWithPaygroups): void {
		if (element) {
			this.selectedLegalEntityElement = element;
			element && element.payGroups && element.payGroups.length
				? this.setSelectedLegalEntity(element)
				: (this.fetchPaygroups.emit([
						this.selectedCustomerElement.customerDetails.id,
						this.selectedLegalEntityElement.id
				  ]),
				  this.setSelectedLegalEntity(element));
			this.cdr.detectChanges();
		}
	}

	setSelectedLegalEntity(legalEnitity: LegalEntityDetailsWithPaygroups): void {
		legalEnitity && this.expandedLegalEntity && this.expandedLegalEntity.id === legalEnitity.id
			? (this.expandedLegalEntity = {} as LegalEntityDetailsWithPaygroups)
			: (this.expandedLegalEntity = legalEnitity);
	}

	isLegalEntityExpanded(legalEnitity: LegalEntityDetailsWithPaygroups): boolean {
		return legalEnitity && this.expandedLegalEntity && this.expandedLegalEntity.id === legalEnitity.id
			? true
			: false;
	}

	routeToPayments(row: PayGroupDetails): void {
		this.globalDashboardEventsService.routeFromGlobalDashboardToAnotherPage(row.id, "/global-dashboard/dashboard");
	}
}
