import { Observable, Subject, defer } from "rxjs";
import { finalize } from "rxjs/operators";

/**
 * Tie an observable to a subject to indicate when the observable is subscribed to / finalized.
 *
 * @example given a subject you want to toggle on and off when an observable is subscribed to / finalized.
 *
 * 	loading$ = new Subject<boolean>();
 * 	myCoolObservable$.pipe(indicate(this.loading$)); // pipe the observable to the indicate operator
 *
 *  The `loading$` subject will be set to true when the observable is subscribed to and false when it is finalized.
 */
export function indicate<T>(indicator: Subject<boolean>): (source: Observable<T>) => Observable<T> {
	return (source: Observable<T>): Observable<T> =>
		source.pipe(
			// on Prepare update this subject upon subscription to the actual source stream via indicator.next(true).
			prepare(() => indicator.next(true)),
			// Similarly, we use the finalize operator to inform it about the loading being completed via indicator.next(false):
			finalize(() => indicator.next(false))
		);
}

export function prepare<T>(callback: () => void): (source: Observable<T>) => Observable<T> {
	return (source: Observable<T>): Observable<T> =>
		defer(() => {
			// invoke callback upon subscription
			callback();
			return source;
		});
}
