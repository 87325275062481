import { Component, Input, OnInit } from "@angular/core";
import { PayGroup } from "@shared/models/pay-groups";
import { getOffsetFromLegalEntityTimezone } from "@shared/utils/date.util";

@Component({
	selector: "app-legal-entity-timezone-disclaimer",
	templateUrl: "./legal-entity-timezone-disclaimer.component.html",
	styleUrls: ["./legal-entity-timezone-disclaimer.component.scss"]
})
export class LegalEntityTimezoneDisclaimerComponent {
	paygroup!: PayGroup;
	date!: Date;
	@Input() set payGroup(pg: PayGroup) {
		this.paygroup = pg;

		if (this.date) {
			this.setupOffset(pg, this.date);
		}
	}

	@Input() set milestoneDate(date: Date) {
		this.date = date;
		if (this.paygroup) {
			this.setupOffset(this.paygroup, date);
		}
	}
	fullTimeZone = "";

	setupOffset(paygroup: PayGroup, date: Date) {
		const timezone = paygroup.legalEntity.data.timeZone;
		const timeZoneOffset = getOffsetFromLegalEntityTimezone(timezone, date);
		let timeZoneUTC = "UTC";

		if (timeZoneOffset > 0) {
			timeZoneUTC = `UTC+${timeZoneOffset}`;
		} else if (timeZoneOffset < 0) {
			timeZoneUTC = `UTC${timeZoneOffset}`;
		}

		this.fullTimeZone = this.formatTimezone(timezone) + ` (${timeZoneUTC})`;
	}

	formatTimezone(timezone: string): string {
		const parts = timezone.split("/");
		return `${parts[1]}, ${parts[0]}`;
	}
}
