<div class="no-items-container" [ngStyle]="{ 'min-height': minHeight }">
	<!-- Poker face -->
	<div class="poker-face">
		<div class="eyes-container">
			<div class="eye"></div>
			<div class="eye"></div>
		</div>
		<div class="mouth"></div>
	</div>

	<!-- Message -->
	<div *ngIf="message" class="message">{{ message }}</div>

	<!-- Call to action -->
	<div class="button-container">
		<button
			*ngIf="buttonText"
			id="customCTA"
			mat-button
			class="c-button-dashed"
			color="blue-sky"
			(click)="onClick()"
			[disabled]="disabled"
		>
			<app-icon
				style="line-height: 2px; margin-right: 8px"
				[size]="'18px'"
				[color]="'var(--color-primary-1000)'"
				[name]="'add'"
			></app-icon>
			<span>{{ buttonText }}</span>
		</button>
	</div>
</div>
