<!-- Items -->
<div class="parent-container" [ngClass]="{ 'is-disabled-parent': !activeToggle }" fxLayout="column">
	<div class="main">
		<div class="header" [ngClass]="{ active: showDetails }">
			<div
				class="header-content"
				[ngClass]="{ active: showDetails, 'is-disabled-parent': !activeToggle }"
				fxLayout="row"
			>
				<span fxFlex="5" fxLayoutAlign="start center">
					<div
						*ngIf="
							!isCancelledRun &&
							canReprocessPayment &&
							(booking.status?.toString() === 'FAILED' ||
								booking.status?.toString() === 'ERROR' ||
								booking.status?.toString() === 'FAILED_TO_BOOK' ||
								booking.status?.toString() === 'ATTEMPTS_LIMIT_EXCEEDED')
						"
						class="checkbox-container"
					>
						<mat-checkbox
							color="primary"
							class="checkbox"
							[checked]="isAllPayoutsSelected()"
							(change)="setIds($event)"
						></mat-checkbox>
					</div>
				</span>

				<span class="subservice-name table-row-item" fxFlex="15" fxLayoutAlign="start center">
					{{ booking.subService.name }}
				</span>

				<span
					fxFlex="15"
					*ngIf="booking.reference"
					fxLayoutAlign="start center"
					class="reference table-row-item"
				>
					{{ booking.reference }}
				</span>

				<span
					fxFlex="15"
					*ngIf="!booking.reference"
					fxLayoutAlign="start center"
					class="reference table-row-item"
				>
					<span *ngIf="!showDetails">-</span>
				</span>

				<span fxFlex="15" fxLayoutAlign="start center" class="amount table-row-item">
					{{ booking.amount.value | number : "1.2-2" }}{{ booking.amount.currency | currencySymbol }}
				</span>

				<span fxFlex="15" class="payment-by table-row-item" fxLayoutAlign="start center">
					{{ booking.paymentBy | paymentByFormat }}
				</span>

				<span fxFlex="13" class="value-date table-row-item" fxLayoutAlign="start center">
					{{ booking.valueDate | date : "MMM d"
					}}{{ booking.valueDate | date : "d" | datePosition | lowercase }}
				</span>

				<span *ngIf="!isCancelledRun" class="table-status" fxFlex="11" fxLayoutAlign="start center">
					<span
						*ngIf="activeToggle"
						[ngClass]="{
							'table-status status-red':
								booking.status?.toString() === 'FAILED' ||
								booking.status?.toString() === 'FAILED_TO_BOOK' ||
								booking.status?.toString() === 'ERROR' ||
								booking.status?.toString() === 'ATTEMPTS_LIMIT_EXCEEDED',
							'table-status status-green':
								booking.status?.toString() === 'SUCCESS' || booking.status?.toString() === 'PROCESSED',
							'table-status status-created': booking.status?.toString() === 'CREATED',
							'table-status status-pending':
								booking.status?.toString() === 'PENDING' || booking.status?.toString() === 'PROCESSING',
							'table-status status-booked': booking.status?.toString() === 'BOOKED',
							'table-status status-cancelled': booking.status?.toString() === 'CANCELLED'
						}"
						>{{ booking.status!.toString() | transactionStatuses : errorCounter }}</span
					>
				</span>
				<span *ngIf="isCancelledRun" class="table-status" fxFlex="11" fxLayoutAlign="start center">
					<span
						*ngIf="activeToggle"
						class="table-status status-cancelled"
						[ngClass]="{ 'blue-text': showDetails }"
						>CANCELLED</span
					>
				</span>

				<span fxFlex="11" class="options-conatainer">
					<div class="toggle-container" fxLayoutAlign="start center" (click)="$event.stopPropagation()">
						<ng-template [appUserRolePermissions] [action]="'hide'" [roles]="['TS_OCB']">
							<div
								*ngIf="!showDetails"
								[ngClass]="{
									opacity: disableToggle || isCancelledRun || !canDisablePayout || isDisabled
								}"
							>
								<mat-slide-toggle
									[ngClass]="{
										'toggle-checked-alternative': activeToggle,
										'toggle-default': !activeToggle
									}"
									color="primary"
									[checked]="activeToggle"
									(change)="inactivateBooking($event)"
									[disabled]="disableToggle || !canDisablePayout || isCancelledRun || isDisabled"
								>
									<div fxLayout="column">
										<span class="toggle-text" *ngIf="activeToggle">Active</span>
										<span class="toggle-text" *ngIf="!activeToggle">Inactive</span>
									</div></mat-slide-toggle
								>
							</div>
						</ng-template>
					</div>

					<mat-form-field
						class="view-details"
						fxLayoutAlign="start center"
						*ngIf="booking.paymentBy.toString() === 'PAY_GROUP' && canViewTransactionHistory"
					>
						<app-icon class="more-dots" [name]="'more'" #trigger [lineWidth]="'1'"></app-icon>

						<mat-select class="options-dropdown" trigger="trigger" #alarmSelect>
							<mat-option (click)="showHistory()"> History log </mat-option>
						</mat-select>
					</mat-form-field>

					<div
						class="view-employee-details"
						[ngStyle]="{ 'margin-right': showDetails ? '0.5rem' : '1rem' }"
						*ngIf="booking.paymentBy.toString() === 'EMPLOYEE'"
						(click)="showEmployeeDetails()"
					>
						<app-icon
							class="arrow"
							[ngClass]="{ 'up-arrow': showDetails }"
							[name]="'arrow-down'"
							[size]="'30px'"
							[lineWidth]="'2'"
						></app-icon>
					</div>
				</span>
			</div>
		</div>

		<app-tpp-payment-payout-item-detail-list
			*ngIf="showDetails && booking.payouts.length > 0 && booking.paymentBy.toString() === 'EMPLOYEE'"
			[_payouts]="booking.payouts"
			[_reprocessPayoutIdsList]="reprocessPayoutIdsList"
			[_isCancelledRun]="isCancelledRun"
			[_isInActivatedPayout]="activeToggle"
			(inActivatePayouts)="inActivatePayouts.emit($event)"
			(pushToReprocessList)="pushToReprocessList.emit($event)"
			(popFromReprocessList)="popFromReprocessList.emit($event)"
		></app-tpp-payment-payout-item-detail-list>

		<mat-divider *ngIf="!isLast && !showDetails"></mat-divider>
	</div>
</div>
