<div class="main-container">
	<div id="paymentDefintionContainer" class="list-margin">
		<div class="flex flex-row flex-align-center-space-between">
			<div id="paymentDefinitionTitle" class="c-title-item c-title-item-b">Payment Definition</div>

			<button
				*ngIf="!isEditService"
				id="addNewSubservice"
				class="btn-new-service"
				[disabled]="subServices.length === 0 || addNew"
				[ngClass]="{ disabled: subServices.length === 0 || addNew }"
				mat-button
				(click)="addNewSubservice()"
			>
				+&nbsp;&nbsp;&nbsp;ADD NEW SUBSERVICE
			</button>

			<ng-template
				[appUserRolePermissions]
				[action]="'hide'"
				[roles]="[
					'TS_IMPLEMENTATION_CONSULTANT',
					'TS_IMPLEMENTATION_LEAD',
					'TS_OPERATION_LEAD',
					'TS_VO',
					'CLIENT'
				]"
				*ngIf="isEditService"
			>
				<button
					id="addNewSubservice"
					class="btn-new-service"
					[disabled]="subServices.length === 0 || addNew || (!canEditService && isEditService)"
					[ngClass]="{ disabled: subServices.length === 0 || addNew }"
					mat-button
					(click)="addNewSubservice()"
				>
					+&nbsp;&nbsp;&nbsp;ADD NEW SUBSERVICE
				</button>
			</ng-template>
		</div>
		<div id="subServiceListContainer" class="list-contanier">
			<div id="subServiceList" *ngIf="subServices.length">
				<app-tpp-sub-service-item
					*ngFor="let subService of subServices; let i = index"
					[selectedPayGroupId]="selectedPayGroupId"
					[forAdhocService]="forAdhocService"
					[countryCode]="countryCode"
					[subService]="subService"
					[panelDisabled]="panelDisabled"
					[panelOpenState]="subService.id === subServiceId"
					(sendSubServiceData)="getSubServiceData($event)"
					(sendSubServiceGroupData)="sendGroupData.emit($event)"
					[_index]="i"
					[isEditService]="isEditService"
				></app-tpp-sub-service-item>
			</div>
			<div id="subServiceList" *ngIf="subServices.length === 0 || addNew">
				<app-tpp-sub-service-item
					[selectedPayGroupId]="selectedPayGroupId"
					[forAdhocService]="forAdhocService"
					[countryCode]="countryCode"
					[panelDisabled]="panelDisabled"
					[panelOpenState]="panelOpenState"
					(sendSubServiceData)="getSubServiceData($event)"
					[_index]="subServices.length"
				></app-tpp-sub-service-item>
			</div>
		</div>
	</div>
</div>
