import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { select, Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { distinctUntilChanged, take, takeUntil, debounceTime } from "rxjs/operators";
import { addDays } from "date-fns";

import { PermissionsService } from "@shared/services/permissions/permissions.service";
import { InputAutocompleteService } from "@shared/components/input-autocomplete/_services/input-autocomplete.service";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { createISOStringFromDateWithoutHours } from "src/app/shared/utils/date.util";
import { UpdateDashboardFilterAction } from "src/app/store/actions/globalDashboardFilterSelect.action";
import { AppState } from "src/app/store/models/state.model";
import {
	DashboardFilters,
	DashboardMultiSelectFilters,
	FilterMilestoneTypes,
	FilterStatusses,
	IDashboardFiltersFormGroup,
	IMilestoneTypesSelectionFormGroup,
	MilestoneTypes,
	StatusTypes
} from "../../models/global-dashboard-interface";
import { DataFormattingService } from "../../services/data-fomatting.service";
import { getGlobalDashboardFilterState } from "@store/index";
import { GlobalDashboardFilterSelect } from "@store/models/globalDashboardFilterSelection.model";

@Component({
	selector: "app-filters-container",
	templateUrl: "./filters-container.component.html",
	styleUrls: ["./filters-container.component.scss"]
})
export class FiltersContainerComponent implements OnDestroy {
	@Input() set customers(customers: SelectOption[]) {
		this.customerSelectOptions = customers;
	}
	@Input() set legalEnities(legalEntities: SelectOption[]) {
		this.checkIfSelectionIsStillValid("legalEntities", legalEntities);
	}
	@Input() set payGroups(payGroups: SelectOption[]) {
		this.checkIfSelectionIsStillValid("paygroups", payGroups);
	}

	@Input() set groups(groups: SelectOption[]) {
		this.groupSelectOptions = groups;
	}

	@Input() set statuses(statuses: SelectOption[]) {
		this.statusesSelectOptions = statuses;
	}

	@Input() set selectedKpi(kpi: string) {
		if (this.filters && kpi !== this.kpiSelected) this.setDisabledButton();
		this.kpiSelected = kpi;
		this.availableFilters = this.dataFormattingService.getAvailableFiltersLayers(kpi);
		this.disableFilterLayers(!this.availableFilters.includes("second"));
	}

	@Input() set stateFilters(filters: FormGroup) {
		if (filters) {
			this.filtersFromState = filters;
			this.setupFilters();
		}
	}

	@Output() scrollerReachedTheBottom: EventEmitter<boolean> = new EventEmitter<boolean>(false);

	@Output() fetchNewData: EventEmitter<DashboardFilters> = new EventEmitter<DashboardFilters>(false);

	@Output() applyFilters: EventEmitter<void> = new EventEmitter<void>();

	@ViewChild("filtersDiv", { static: false }) filtersDiv!: ElementRef;

	showFilters = false;
	disableClearFiltersButton = true;
	disableApplyButton = true;
	filters!: FormGroup;
	milestoneSelection!: FormGroup;
	filtersFromState!: FormGroup;
	kpiSelected: string = "";
	customerSelectOptions!: SelectOption[];
	legalEntitiesSelectOptions!: SelectOption[];
	payGroupSelectOptions!: SelectOption[];
	statusesSelectOptions!: SelectOption[];
	groupSelectOptions!: SelectOption[];
	availableFilters: string[] = [];
	defaultFormValues: DashboardFilters = {
		customers: [],
		legalEntities: [],
		paygroups: [],
		statuses: [],
		groups: [],
		deadline: "",
		milestoneTypes: [],
		milestoneStartDate: ""
	};
	isClientUser = false;
	canSeeMap = false;
	isEnableClientFilter = false;
	defualtMilestoneSelection: MilestoneTypes = {
		paymentOrders: false,
		funding: false,
		payments: false
	};

	dashboardMultiSelectFilters = DashboardMultiSelectFilters;
	milestoneTypesSelected: FilterMilestoneTypes[] = [];

	endDateString: string = "";
	startDateString: string = "";

	private destroy$: Subject<void> = new Subject();

	constructor(
		private formBuilder: FormBuilder,
		private store: Store<AppState>,
		private inputAutocompleteService: InputAutocompleteService,
		private dataFormattingService: DataFormattingService,
		private permissionsService: PermissionsService
	) {}

	setupFilters(): void {
		this.permissionsService
			.canSeeMapAndIsClient()
			.pipe(takeUntil(this.destroy$))
			.subscribe((res: { isClient: boolean; canSeeMap: boolean }) => {
				this.isClientUser = res.isClient;
				this.canSeeMap = res.canSeeMap;

				res.isClient ? this.getClientCustomer(res.canSeeMap) : this.initialiseFilterForm();
			});

		this.permissionsService
			.enableClientGlobalFilter()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => {
				this.isEnableClientFilter = res.isClientGlobalDashboard!;
			});
	}

	getClientCustomer(canSeeMap: boolean): void {
		const customerIds = this.filtersFromState.get("customers")?.value;
		const legalEnityIds = this.filtersFromState.get("legalEntities")?.value;

		this.defaultFormValues.customers = customerIds ? customerIds : [];

		if (legalEnityIds && canSeeMap) this.defaultFormValues.legalEntities = legalEnityIds ? legalEnityIds : [];

		this.initialiseFilterForm();
	}

	initialiseFilterForm(): void {
		this.filters = this.formBuilder.group({
			customers: new FormControl([]),
			legalEntities: new FormControl([]),
			paygroups: new FormControl([]),
			statuses: new FormControl([]),
			groups: new FormControl([]),
			deadline: new FormControl(""),
			milestoneTypes: new FormControl([]),
			milestoneStartDate: new FormControl("")
		}) as IDashboardFiltersFormGroup;

		this.initOtherForms();
		this.patchExistingValues();
	}

	initOtherForms(): void {
		this.milestoneSelection = this.formBuilder.group({
			paymentOrders: new FormControl(false),
			funding: new FormControl(false),
			payments: new FormControl(false)
		}) as IMilestoneTypesSelectionFormGroup;

		this.setupValueChanges();
	}

	disableFilterLayers(disable: boolean): void {
		if (this.filters && this.milestoneSelection) {
			if (disable) {
				this.milestoneSelection.disable({ emitEvent: false });
				this.filters.get("deadline")?.disable({ emitEvent: false });
			} else {
				this.milestoneSelection.enable({ emitEvent: false });
				this.filters.get("deadline")?.enable({ emitEvent: false });
			}
		}
	}

	patchExistingValues(): void {
		this.filters.patchValue(this.filtersFromState.value, { emitEvent: false });
		this.setDisabledButton();

		this.milestoneSelection.patchValue(this.getMilstoneTypesFromState(this.filtersFromState), {
			emitEvent: false
		});

		this.showFilter(true);
		this.disableClearFiltersButton = false;

		if (
			this.filters.get("deadline") &&
			this.filters.get("deadline")?.value !== "" &&
			this.filters.get("milestoneStartDate") &&
			this.filters.get("milestoneStartDate")?.value !== ""
		) {
			this.startDateString = this.filters.get("milestoneStartDate")?.value;
			this.endDateString = this.filters.get("deadline")?.value;
		} else {
			this.setInitialDeadlineDate();
		}
	}

	getStatussesFromState(filtersFromState: FormGroup<any>): StatusTypes {
		let selectedStatusses: StatusTypes = {} as StatusTypes;
		const statuses: FilterStatusses[] = filtersFromState.value.statuses;

		statuses.forEach(status => {
			if (status === FilterStatusses.COMPLETED) {
				selectedStatusses.completed = true;
			}
			if (status === FilterStatusses.HASERRORS) {
				selectedStatusses.hasErrors = true;
			}
			if (status === FilterStatusses.PENDING) {
				selectedStatusses.pending = true;
			}
		});

		return selectedStatusses;
	}
	getMilstoneTypesFromState(filtersFromState: FormGroup<any>): MilestoneTypes {
		let selectedMilestones: MilestoneTypes = {} as MilestoneTypes;
		const milestoneTypes: FilterMilestoneTypes[] = filtersFromState.value.milestoneTypes;

		milestoneTypes.forEach(type => {
			if (type === FilterMilestoneTypes.PAYMENTORDERS) {
				selectedMilestones.paymentOrders = true;
			}
			if (type === FilterMilestoneTypes.FUNDING) {
				selectedMilestones.funding = true;
			}
			if (type === FilterMilestoneTypes.PAYMENTS) {
				selectedMilestones.payments = true;
			}
		});

		return selectedMilestones;
	}

	setupValueChanges(): void {
		this.filters
			.get("deadline")
			?.valueChanges.pipe(debounceTime(300), distinctUntilChanged())
			.subscribe(deadline => {
				// Prevent Serialisation error for ngrx
				this.endDateString = deadline ? createISOStringFromDateWithoutHours(deadline) : "";
				this.setDisabledButton();
				this.dispatchToDashboardFiltersStore();
			});

		this.filters
			.get("milestoneStartDate")
			?.valueChanges.pipe(debounceTime(300), distinctUntilChanged())
			.subscribe(startDate => {
				// Prevent Serialisation error for ngrx
				this.startDateString = startDate ? createISOStringFromDateWithoutHours(startDate) : "";
				this.setDisabledButton();
				this.dispatchToDashboardFiltersStore();
			});

		this.filters
			.get("milestoneTypes")
			?.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged())
			.subscribe(types => {
				this.setDisabledButton();
				this.dispatchToDashboardFiltersStore();
			});

		this.milestoneSelection.valueChanges
			.pipe(takeUntil(this.destroy$), distinctUntilChanged())
			.subscribe((types: MilestoneTypes) => {
				this.updateSelectedMilestoneTypes(types);
			});
	}

	updateSelectedMilestoneTypes(types: MilestoneTypes) {
		this.milestoneTypesSelected = [];

		if (types.paymentOrders) {
			this.milestoneTypesSelected.push(FilterMilestoneTypes.PAYMENTORDERS);
		}

		if (types.funding) {
			this.milestoneTypesSelected.push(FilterMilestoneTypes.FUNDING);
		}

		if (types.payments) {
			this.milestoneTypesSelected.push(FilterMilestoneTypes.PAYMENTS);
		}

		this.filters.get("milestoneTypes")?.patchValue(this.milestoneTypesSelected);
	}

	emitNewData(): void {
		this.setDisabledButton();
		this.fetchNewData.emit(this.filters.getRawValue());
		this.dispatchToDashboardFiltersStore();
		this.filters?.valueChanges
			.pipe(debounceTime(500), take(1), distinctUntilChanged(), takeUntil(this.destroy$))
			.subscribe(_ => {
				this.applyFilters.emit();
			});
	}

	setDisabledButton(): void {
		this.disableApplyButton = !this.filters.get("customers")?.value.length;
	}

	dispatchToDashboardFiltersStore(): void {
		this.disableClearFiltersButton = !this.checkIfFormHasValues(this.filters);

		if (this.canSeeMap && !this.isEnableClientFilter) {
			/** Handle for Client user role */
			this.store.pipe(take(1), takeUntil(this.destroy$), select(getGlobalDashboardFilterState)).subscribe({
				next: (state: GlobalDashboardFilterSelect) => {
					this.filters.patchValue(state.globalDashboard.filters);
					this.setInitialDeadlineDate();
				}
			});
		} else {
			this.store.dispatch(
				new UpdateDashboardFilterAction({
					globalDashboard: {
						kpiSelected: this.kpiSelected,
						filters: {
							...this.filters.getRawValue(),
							deadline: this.endDateString,
							milestoneStartDate: this.startDateString
						}
					}
				})
			);
		}
	}

	filterSelection = async (key: string, options: SelectOption[]): Promise<boolean> => {
		if (this.filters) {
			const actions = {
				legalEntities: () => {
					let legalEntitySelection: string[] = this.getFilteredSelection(key, options);
					this.filters.get(key)?.patchValue(legalEntitySelection, { emitEvent: false });
					this.legalEntitiesSelectOptions = options;
					return true;
				},
				paygroups: () => {
					let paygroupSelection: string[] = this.getFilteredSelection(key, options);
					this.filters.get(key)?.patchValue(paygroupSelection, { emitEvent: false });
					this.payGroupSelectOptions = options;
					return true;
				}
			};

			const action = actions[key as keyof typeof actions];
			if (action) {
				return action();
			} else {
				return false;
			}
		}

		return false;
	};

	getFilteredSelection(key: string, options: SelectOption[]): string[] {
		return this.filters
			.get(key)
			?.value.filter((selection: string) => options.some(option => option.value === selection));
	}

	checkIfSelectionIsStillValid = async (key: string, options: SelectOption[]): Promise<void> => {
		(await this.filterSelection(key, options)) ? this.dispatchToDashboardFiltersStore() : null; // Wait for the for loop to complete // Now run this
	};

	clearFormProperties(propertyName: string): void {
		const formKeys = Object.keys(this.filters.controls);
		const startIndex = formKeys.indexOf(propertyName);

		if (startIndex !== -1) {
			for (let i = startIndex; i < formKeys.length; i++) {
				const key = formKeys[i];
				if (key === "deadline") break;
				this.filters.get(key)?.patchValue([], { emitEvent: false });
			}

			this.emitNewData();

			if (propertyName === this.dashboardMultiSelectFilters.CUSTOMERS) {
				this.applyFilters.emit();
			}
		}
	}

	checkIfFormHasValues(form: any): boolean {
		return Object.values(form.getRawValue()).some(prop => {
			if (Array.isArray(prop)) {
				return prop.length > 0; // Check for non-empty arrays
			} else if (typeof prop === "boolean" && prop === true) {
				return true; // Check if boolean value is explicitly true
			} else if (typeof prop === "string" && prop.trim() !== "") {
				return true; // Check for non-null and non-empty strings
			}
			return false; // For any other type, consider it as not having a value
		});
	}
	showFilter(show: boolean): void {
		this.showFilters = show;
	}

	clearFilters(): void {
		this.filters.patchValue(this.defaultFormValues);
		this.milestoneSelection.patchValue(this.defualtMilestoneSelection, { emitEvent: false });
		this.inputAutocompleteService.clearinputs.emit(true);
		this.setInitialDeadlineDate();
		this.dispatchToDashboardFiltersStore();
	}

	clearDate(event: any): void {
		event.stopPropagation();
		this.filters.get("deadline")?.setValue(null);
	}

	private setInitialDeadlineDate(): void {
		const defaultStartDate = createISOStringFromDateWithoutHours(addDays(new Date(), -61).toISOString());
		const defaultEndDate = createISOStringFromDateWithoutHours(addDays(new Date(), 31).toISOString());

		this.startDateString = defaultStartDate;
		this.endDateString = defaultEndDate;
		this.filters.get("milestoneStartDate")?.setValue(defaultStartDate);
		this.filters.get("deadline")?.setValue(defaultEndDate);
	}

	checkIfOnlyOneDateSelected(controlName: string, event: any): void {
		const selectedDate = event.value;
		const startDate = this.filters.get("startDate")?.getRawValue();

		if (controlName === "end" && !selectedDate && startDate) {
			this.filters.get("deadline")?.setValue(this.startDateString);
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
