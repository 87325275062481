import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PayGroup } from "@shared/models/pay-groups";
import { Subject, take, takeUntil } from "rxjs";
import { MilestonesService } from "@shared/services/milestones/milestones.service";
import { PermissionsService } from "@shared/services/permissions/permissions.service";
import { ToastService } from "@shared/services/toast/toast.service";
import { EVENT_TIMES } from "src/app/shared/constants/event-time-options";
import { PAY_DATES } from "src/app/shared/constants/pay-date-options";
import { WEEKEND_OFFSETS } from "src/app/shared/constants/weekend-offset-options";
import { Milestone, MilestoneUpdateDTO, PayCycle } from "src/app/shared/models/pay-cycle.interface";
import { convertMilestoneDateToUTC, mergeDateAndTime, setFormatDate } from "src/app/shared/utils/date.util";

@Component({
	selector: "payment-calendar-milestone-edition",
	templateUrl: "./payment-calendar-milestone-edition.component.html",
	styleUrls: ["./payment-calendar-milestone-edition.component.scss"]
})
export class PaymentCalendarMilestoneEditionComponent implements OnInit, OnDestroy {
	eventForm: FormGroup | undefined;
	exit: boolean = false;
	title: string = "Net payment";
	popupOpen: boolean = false; // By default it should be true, but for SPRINT 11 it will not be implemented so we set it to false.
	recurringSelected: "CURRENT" | "FOLLOWING" | "ALL" = "CURRENT";
	oldMilestone: Milestone | undefined;
	payDates: string[] = PAY_DATES;
	eventTimes: string[] = EVENT_TIMES;
	weekendOffets: string[] = WEEKEND_OFFSETS;
	canEdit: boolean = false;

	_milestone!: Milestone | undefined;
	private destroy$: Subject<void> = new Subject();

	milestoneDate!: Date;

	@Input() set milestone(milestone: Milestone | undefined) {
		this._milestone = milestone;
		this.oldMilestone = milestone;
		this.initForm();
	}

	@Input() payCycle: PayCycle | undefined;

	@Input() payGroup!: PayGroup;

	@Output() onClose: EventEmitter<{ reload: boolean }> = new EventEmitter<{ reload: boolean }>();

	constructor(
		private formBuilder: FormBuilder,
		private milestonesService: MilestonesService,
		private toastService: ToastService,
		private permissions: PermissionsService
	) {}

	ngOnInit(): void {}

	initForm(): void {
		this.permissions
			.canEditCalendarEvents()
			.pipe(take(1))
			.subscribe(res => {
				this.canEdit = res;
			});

		if (this._milestone && this._milestone.date) {
			const formattedDate: Date = setFormatDate(this._milestone!.date);
			const time: string = formattedDate.toLocaleTimeString("US", {
				hourCycle: "h23",
				hour: "2-digit",
				minute: "2-digit",
				second: "2-digit"
			});

			this.eventForm = this.formBuilder.group({
				date: [{ value: formattedDate, disabled: !this.canEdit }, [Validators.required]],
				time: [{ value: time, disabled: !this.canEdit }, [Validators.required]]
			});

			this.milestoneDate = formattedDate;

			this.eventForm
				.get("date")
				?.valueChanges.pipe(takeUntil(this.destroy$))
				.subscribe(
					date => (this.milestoneDate = mergeDateAndTime(new Date(date), this.eventForm?.controls.time.value))
				);

			this.eventForm
				.get("time")
				?.valueChanges.pipe(takeUntil(this.destroy$))
				.subscribe(
					time => (this.milestoneDate = mergeDateAndTime(new Date(this.eventForm?.controls.date.value), time))
				);
		}
	}

	refactorDate(date: Date): string {
		const weekday: string = date.toLocaleDateString("US", { weekday: "long" });
		const month: string = date.toLocaleDateString("US", { month: "long" });
		const day: string = date.toLocaleDateString("US", { day: "numeric" });
		const year: string = date.toLocaleDateString("US", { year: "numeric" });

		return this.capitalize(weekday) + ", " + this.capitalize(month) + " " + day + ", " + year;
	}

	savePopup(recurring: "CURRENT" | "FOLLOWING" | "ALL") {
		this.recurringSelected = recurring;
		this.popupOpen = false;
	}

	capitalize(sentence: string): string {
		return sentence[0].toUpperCase() + sentence.substring(1).toLowerCase();
	}

	resetAll() {
		if (this.oldMilestone) {
			const formattedDate: Date = setFormatDate(this.oldMilestone.date);
			const time: string = formattedDate.toLocaleTimeString();

			this.eventForm?.controls.date.patchValue(formattedDate);
			this.eventForm?.controls.time.patchValue(time);
		}
	}

	cancel(reload: boolean) {
		this.exit = true;
		setTimeout(() => {
			this.onClose.emit({ reload: reload });
		}, 655); // like in css
	}

	save() {
		if (this.payCycle) {
			const milestoneDate = convertMilestoneDateToUTC(
				this.milestoneDate,
				this.payGroup.legalEntity.data.timeZone
			);

			let milestoneUpdateDTO: MilestoneUpdateDTO = {
				milestones: [
					{
						id: this._milestone!.id,
						version: this._milestone!.version,
						group: this._milestone!.group,
						type: this._milestone!.type,
						date: milestoneDate,
						params: []
					}
				]
			};

			if (this._milestone?.status === "UNDEFINED") {
				milestoneUpdateDTO.milestones[0].status = "PENDING";
			} else if (this._milestone?.status === "HAS_ERRORS") {
				milestoneUpdateDTO.milestones[0].status = "ERROR";
			} else if (this._milestone?.status === "COMPLETE") {
				milestoneUpdateDTO.milestones[0].status = "COMPLETE";
			} else {
				milestoneUpdateDTO.milestones[0].status = this._milestone?.status;
			}

			this.milestonesService.updateMilestones(milestoneUpdateDTO).subscribe({
				next: _ => {
					this.toastService.showSuccess("Milestone updated");
					this.cancel(true);
				},
				error: _ => this.toastService.showError("Milestone update failed")
			});
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
