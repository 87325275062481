import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { HistoryViewData } from "src/app/shared/models/history-view.interface";
import { PayElectiveHistoryModalData } from "./model/PayElectiveHistoryModalData";
import {
	InternalStatus,
	isBeneficiaryValid
} from "src/app/shared/models/service-definition/TppServiceDefinitionViewModel";
import { DatePipe } from "@angular/common";

@Component({
	selector: "app-pay-elective-history",
	templateUrl: "./pay-elective-history.component.html",
	styleUrls: ["./pay-elective-history.component.scss"]
})
export class PayElectiveHistoryComponent implements OnInit {
	searchForm!: FormGroup;
	filteredData: HistoryViewData[] = [];

	title!: string;
	hasReasonColoumn: boolean = false;

	constructor(
		private dialog: MatDialog,
		private formBuilder: FormBuilder,
		private datePipe: DatePipe,
		@Inject(MAT_DIALOG_DATA) public historyData: PayElectiveHistoryModalData
	) {}

	ngOnInit(): void {
		this.initForm();

		let data: HistoryViewData[] = [];
		let emptyProvidersData: HistoryViewData[] = [];

		if (this.historyData && this.historyData.type !== "PAY_ELECTIVE") {
			for (let i = 1; i < this.historyData.data.length; i++) {
				if (this.historyData.data[i].status === "CREATED") {
					// Add a new element with 'status' set to 'Reprocessed'.
					this.historyData.data.splice(i, 0, {
						action: "",
						createdAt: this.historyData.data[i].createdAt,
						details: null,
						providerName: this.historyData.data[i].providerName,
						reason: "",
						status: "REPROCESSED",
						user: "WPAY SYSTEM"
					});
					i++; // Skip the newly added element.
				}
			}
		}

		data = this.historyData.data.filter(x => x.providerName === this.historyData.providerName);
		emptyProvidersData = this.historyData.data.filter(x => x.providerName === "");

		if (emptyProvidersData.length > 0) {
			emptyProvidersData.forEach(x => {
				data.push(x);
			});
		}

		this.filteredData = data.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
		this.title = this.historyData.title;
		this.checkForReasonField();
	}

	closeDialog(): void {
		this.dialog.closeAll();
	}

	toStatusColorClass(status: string): string {
		if (this.historyData.type == "TPP") return this.toStatusColorFromTppStatus(status);

		return this.toStatusColorFromPayElectiveStatus(status);
	}

	isArray(value: string | string[]): boolean {
		return Array.isArray(value);
	}

	getArrayValue(value: string | string[]): string[] {
		if (this.isArray(value)) {
			return Array.isArray(value) ? value : [value];
		}
		return [];
	}

	isEmptyObject(object: any): boolean {
		return Object.keys(object).length === 0;
	}

	private initForm(): void {
		this.searchForm = this.formBuilder.group({
			search: [""]
		});
		this.searchForm.get("search")?.valueChanges.subscribe(searchValue => {
			this.filteredData = this.filterResult(searchValue);
		});
	}

	private checkForReasonField(): void {
		this.filteredData.forEach((result: HistoryViewData) => {
			if (result.action) {
				this.hasReasonColoumn = true;
			}
		});
	}

	private filterResult(searchTerm: string): any {
		if (searchTerm == "") return this.historyData.data;
		let filterSearch = this.historyData.data.filter((result: HistoryViewData) => {
			if (this.historyData.type == "TPP") {
				return (
					result.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
					result.details?.message.toLowerCase().includes(searchTerm.toLowerCase()) ||
					this.filterAttributesBySearchTerm(result, searchTerm).length > 0 ||
					this.datePipe
						.transform(result.createdAt, "MMM d, y ")!
						.toString()
						.toLowerCase()
						.includes(searchTerm.toLowerCase())
				);
			} else {
				if (result.action == null) return false;

				return result.action.toLowerCase().includes(searchTerm.toLowerCase());
			}
		});
		return filterSearch;
	}

	filterAttributesBySearchTerm(result: HistoryViewData, searchTerm: string): string[] {
		const matchingKeys: string[] = [];

		if (result.details?.attributes) {
			for (const key in result.details.attributes) {
				const attributeValue = result.details.attributes[key];
				if (
					typeof attributeValue === "string" &&
					attributeValue.toLowerCase().includes(searchTerm.toLowerCase())
				) {
					matchingKeys.push(key);
				} else if (
					Array.isArray(attributeValue) &&
					attributeValue.some(
						item => typeof item === "string" && item.toLowerCase().includes(searchTerm.toLowerCase())
					)
				) {
					matchingKeys.push(key);
				}
			}
		}

		return matchingKeys;
	}

	private toStatusColorFromTppStatus(status: string): string {
		try {
			var mappedInternalStatus = status as InternalStatus;

			if (mappedInternalStatus === "REPROCESSED") {
				return "text-reprocessed";
			} else if (isBeneficiaryValid(mappedInternalStatus)) {
				return "text-success";
			} else {
				return "text-rejected";
			}
		} catch (error) {
			console.error("Unknown status: " + status + " assuming invalid.");
			return "text-rejected";
		}
	}

	private toStatusColorFromPayElectiveStatus(status: string): string {
		switch (status) {
			case "Created":
			case "Success":
			case "Active":
				return "text-success";
			case "Inactive":
			case "Failed":
				return "text-rejected";
			default:
				console.error("Unknown status: " + status + " assuming invalid.");
				return "text-rejected";
		}
	}
}
