import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { WpayMultiSelectAutoCompleteComponent } from "./wpay-multi-select-auto-complete.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatTooltipModule } from "@angular/material/tooltip";
import { SharedModule } from "@shared/shared.module";
import { MatDividerModule } from "@angular/material/divider";

@NgModule({
	declarations: [WpayMultiSelectAutoCompleteComponent],
	exports: [WpayMultiSelectAutoCompleteComponent],
	imports: [
		CommonModule,
		MatFormFieldModule,
		FormsModule,
		ReactiveFormsModule,
		MatTooltipModule,
		MatInputModule,
		MatIconModule,
		MatAutocompleteModule,
		MatCheckboxModule,
		SharedModule,
		MatDividerModule
	]
})
export class WpayMultiSelectAutoCompleteModule {}
