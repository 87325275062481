import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "ordinalDate"
})
export class OrdinalDatePipe implements PipeTransform {
	transform(value: string): string {
		const dateObject = new Date(value);
		const month = dateObject.toLocaleString("en-GB", { month: "long" });
		const day = dateObject.getDate();

		// Add ordinal suffix to the day
		const dayOrdinal = this.getOrdinal(day);

		// Format the date
		return `${month} ${dayOrdinal}`;
	}

	private getOrdinal(n: number): string {
		const suffixes = ["th", "st", "nd", "rd"];
		const v = n % 100;
		return n + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
	}
}
