import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TppAddBeneficiaryComponent } from "./tpp-add-beneficiary.component";
import { ModalWrapperModule } from "@shared/components/modal-wrapper/modal-wrapper.module";
import { SharedModule } from "@shared/shared.module";
import { InputWrapperModule } from "@shared/components/input-wrapper/input-wrapper.module";
import { InputAutocompleteModule } from "@shared/components/input-autocomplete/input-autocomplete.module";
import { InputSelectModule } from "@shared/components/input-select/input-select.module";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { FlexLayoutModule } from "ngx-flexible-layout";
import { BankAccountFieldsModule } from "@shared/components/bank-account-fields/bank-account-fields.module";

@NgModule({
	declarations: [TppAddBeneficiaryComponent],
	exports: [TppAddBeneficiaryComponent],
	imports: [
		CommonModule,
		ModalWrapperModule,
		SharedModule,
		InputWrapperModule,
		InputAutocompleteModule,
		InputSelectModule,
		ReactiveFormsModule,
		MatButtonModule,
		FlexLayoutModule,
		ModalWrapperModule,
		BankAccountFieldsModule
	]
})
export class TppAddBeneficiaryModule {}
