import { createSelector } from "@ngrx/store";
import { AppState } from "./models/state.model";

export const selectFeature = (state: AppState) => state;

export const getCustomerEntityGroupState = createSelector(selectFeature, (state: AppState) => {
	return state.customerEntityGroup;
});

export const getGlobalDashboardFilterState = createSelector(selectFeature, (state: AppState) => {
	return state.globalDashboardFilter;
});

export const getSelectedCalendarMonthState = createSelector(selectFeature, (state: AppState) => {
	return state.selectedCalendarMonth;
});

export const getProviderState = createSelector(selectFeature, (state: AppState) => {
	return state.provider;
});

export const getSelectedTPPServiceState = createSelector(selectFeature, (state: AppState) => {
	return state.selectedTPPService;
});

export const getCustomerEntityCountryState = createSelector(selectFeature, (state: AppState) => {
	return state.customerEntityCountry;
});

export const getTppBeneficiary = createSelector(selectFeature, (state: AppState) => {
	return state.beneficiary;
});

export const getTppFormSubServices = createSelector(selectFeature, (state: AppState) => {
	return state.selectedTPPFormSubServices;
});

export const getTppGroupConfigStepOneData = createSelector(selectFeature, (state: AppState) => {
	return state.tppGroupConfigStepOneData;
});

export const selectStatusNetPayment = createSelector(selectFeature, (state: AppState) => {
	return state.netPaymentState;
});

export const getUserDataState = createSelector(selectFeature, (state: AppState) => {
	return state.userData;
});

export const getPagination = createSelector(selectFeature, (state: AppState) => {
	return state.pagination;
});
