<div id="serviceConfigStepOneContainer-{{ index }}" class="container-style" fxLayout="column" fxLayoutGap="10px">
	<div id="subServiceContainer" fxLayoutGap="20px grid">
		<div id="subServiceContainerHeader" fxLayoutAlign="row">
			<div id="subServiceNameContainerRow" fxLayoutAlign="start center" fxFlex="50" fxLayoutGap="8px">
				<mat-checkbox
					id="checkBox-{{ index }}"
					class="checkbox"
					[checked]="subServiceSelected"
					(change)="toggleSelection(subService, index)"
					(click)="$event.stopPropagation()"
					[disabled]="!canEditSubServices"
				></mat-checkbox>
				<p id="serviceNameText" class="title-style">{{ subService.name }}</p>
			</div>

			<div fxFlex="50" fxLayoutAlign="end end"></div>
		</div>

		<div id="serviceConfigFormConainer" [formGroup]="form" fxLayout="row" *ngIf="displayItems">
			<div id="serviceConfigBeneficiaryConainer" fxFlex="30">
				<input-wrapper label="{{ firstInputLabel }}">
					<input-autocomplete
						id="{{ index }}-beneficiaryId"
						formControlName="beneficiaryId"
						label="Select beneficiary"
						[options]="beneficiarySelectOptions"
						[defaultValue]="selectedBeneficiary"
						[ngClass]="subServiceSelected ? 'select-ben' : 'select-ben-disabled'"
					>
					</input-autocomplete>
					<ng-template
						[appUserRolePermissions]
						[action]="'hide'"
						[roles]="['TS_OPERATION_LEAD', 'TS_VO', 'TS_OCB', 'CLIENT']"
					>
						<button
							id="addNewBeneficiaryButton"
							mat-button
							class="bt-new-beneficiary"
							[ngClass]="subServiceSelected ? 'bt-new-beneficiary' : 'bt-new-beneficiary-disabled'"
							(click)="addNewBeneficiary()"
						>
							+&nbsp;&nbsp;&nbsp;Add new beneficiary
						</button>
					</ng-template>
				</input-wrapper>
			</div>

			<div id="arrowContainer" fxFlex="5" class="arrow-container">
				<div id="arrowContainerChild" class="arrow-container-child" fxLayout="row" fxLayoutAlign="center none">
					<div id="arrowLine" class="arrow-line" fxFlex="30"></div>
					<div id="arrowPoint" class="arrow-right"></div>
				</div>
			</div>

			<input-wrapper label="Sub Service ID" fxFlex="30">
				<input
					[ngClass]="{
						'field-error-input':
							form.controls['externalId'].invalid && form.controls['externalId'].errors?.empty
					}"
					id="SubServiceId"
					type="text"
					placeholder="Type sub service Id"
					formControlName="externalId"
				/>
			</input-wrapper>

			<div id="arrowContainer" fxFlex="5" class="arrow-container">
				<div id="arrowContainerChild" class="arrow-container-child" fxLayout="row" fxLayoutAlign="center none">
					<div id="arrowLine" class="arrow-line" fxFlex="30"></div>
					<div id="arrowPoint" class="arrow-right"></div>
				</div>
			</div>

			<input-wrapper label="{{ secondInputLabel }}" fxFlex="30">
				<!-- <input-autocomplete
				id="{{ index }}-group"
				formControlName="group"
				label="Select a TPP Group"
				[options]="tppGroupOptions"
			>
			</input-autocomplete> -->

				<div
					id="groupSelectionContainer"
					class="route-area"
					[ngClass]="{ 'route-area-disabled': !subServiceSelected }"
					fxLayout="row"
				>
					<div id="groupSelectionContainerChild" fxLayout="row" class="route-container" fxFlex="90">
						<span id="groupSelectionTitle" *ngIf="!selectedTPPGroupOptions.length" class="group-placeholder"
							>Select group</span
						>
						<div
							id="groupSelectionChips"
							*ngFor="let tppGroup of selectedTPPGroupOptions; index as i"
							class="{{ containerClass }}"
							fxLayoutAlign="center center"
						>
							<span id="groupSelectionChipText" class="{{ textClass }}">{{ tppGroup.text }}</span>
						</div>
					</div>
					<button
						id="dropDownButton"
						class="drop-down-button"
						[matMenuTriggerFor]="tppOptionsMenu"
						(menuClosed)="tppOptionsMenuList = false"
						fxFlex="10"
						(click)="showList()"
						[disabled]="!subServiceSelected"
					>
						<app-icon
							id="arrowDown"
							[name]="'arrow-down'"
							[size]="'15'"
							[lineWidth]="'3px'"
							[ngClass]="{ 'up-arrow': tppOptionsMenuList }"
						></app-icon>
					</button>
				</div>
			</input-wrapper>

			<div id="editBeneficiaryButtonContainer" fxFlex="35" class="relative-container">
				<div id="editBeneficiaryButtonContainerChild" fxLayout="row" class="icons-container">
					<button
						id="editBeneficiaryButton"
						class="icon-button"
						disabled="{{ !editBeneficiaryButtonEnabled }}"
						(click)="editBeneficiary()"
						mat-mini-fab
					>
						<app-icon id="editBeneficiaryButtonIcon" name="eye" [color]="colorEye"></app-icon>
					</button>
				</div>
			</div>
		</div>
	</div>
</div>

<mat-menu #tppOptionsMenu class="tpp-options-menu" id="tppOptionsContainer">
	<ng-template matMenuContent>
		<!-- <div *ngFor="let item of tppGroupOptions; index as i; last as isLast">
			<button *ngIf="item" mat-menu-item (click)="addSelection(item)">
				<span class="process-button-menu-text"> {{ item.text }}</span>
			</button>
			<mat-divider *ngIf="!isLast"></mat-divider>
		</div> -->

		<div id="tppGroupChipsContainer" fxLayout="column" fxLayoutGap="4px">
			<div id="tppGroupChipsContainerRowTop" fxLayout="row" fxLayoutGap="4px">
				<div
					id="tppGroup1ChipContainer"
					class="group-1-container"
					fxLayoutAlign="center center"
					(click)="addSelection(tppGroupOptions[0])"
				>
					<span id="ttpGroup1ChipText" class="group-1-text">{{ tppGroupOptions[0].text }}</span>
				</div>
				<div
					id="tppGroup2ChipContainer"
					class="group-2-container"
					fxLayoutAlign="center center"
					(click)="addSelection(tppGroupOptions[1])"
				>
					<span id="ttpGroup2ChipText" class="group-2-text">{{ tppGroupOptions[1].text }}</span>
				</div>
			</div>
			<div id="tppGroupChipsContainerRowBottom" fxLayout="row" fxLayoutGap="4px">
				<div
					id="tppGroup3ChipContainer"
					class="group-3-container"
					fxLayoutAlign="center center"
					(click)="addSelection(tppGroupOptions[2])"
				>
					<span id="ttpGroup3ChipText" id="ttpGroup3ChipText" class="group-3-text">{{
						tppGroupOptions[2].text
					}}</span>
				</div>
				<div
					id="tppGroup4ChipContainer"
					class="group-4-container"
					fxLayoutAlign="center center"
					(click)="addSelection(tppGroupOptions[3])"
				>
					<span class="group-4-text">{{ tppGroupOptions[3].text }}</span>
				</div>
			</div>
		</div>
	</ng-template>
</mat-menu>
