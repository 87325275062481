import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";

import { FeatureService } from "@shared/services/feature/feature.service";
import { ToastService } from "../../../../shared/services/toast/toast.service";
import { MONTH_NAMES } from "../../../../shared/constants/months";
import { PayGroup } from "../../../../shared/models/pay-groups";
import { FileManagementUploadService } from "../../../../shared/services/file-management/file-management-upload.service";
import { Milestone, PayCycle } from "src/app/shared/models/pay-cycle.interface";
import { Store, select } from "@ngrx/store";
import { AppState } from "@store/models/state.model";
import { take } from "rxjs";
import { getGlobalDashboardFilterState } from "@store/index";
import { GlobalDashboardFilterSelect } from "@store/models/globalDashboardFilterSelection.model";
import { FileListComponent } from "./file-list/file-list.component";

@Component({
	selector: "app-file-history",
	templateUrl: "./file-history.component.html",
	styleUrls: ["./file-history.component.scss"]
})
export class FileHistoryComponent implements OnInit {
	@ViewChild(FileListComponent) paymentOrderFileHistoryList!: FileListComponent;

	canUploadFiles: boolean = false;
	payGroup!: PayGroup;
	payCycleId: string = "";
	payCycleExternalId: string = "";
	payrollCycleId: string = "";

	bannerShow = false;

	bannerShowCancel: boolean = false;
	bannerShowReset: boolean = false;
	milestone!: Milestone;
	payCycle!: PayCycle;
	kpiSelected!: string;
	payCycleName = "";
	pageTitle = "Payment Orders";

	isShowLoadmore = false;
	reachedAllFileHistory = false;
	currentFileHistoryListCount = 0;

	private netPaymentOrderScreenEnabled = false;

	constructor(
		private fileManagmentService: FileManagementUploadService,
		private toastService: ToastService,
		private router: Router,
		private store: Store<AppState>,
		private features: FeatureService
	) {}

	ngOnInit(): void {
		this.netPaymentOrderScreenEnabled = this.features.isFeatureEnabled("netPaymentOrderScreen");

		if (history.state) {
			this.payGroup = history.state.payGroup;
			this.payCycleId = history.state.payCycleId;
			this.payCycleExternalId = history.state.payCycleExternalId;
			this.payrollCycleId = history.state.payrollCycleId;
			this.milestone = history.state.milestone;
			this.payCycle = history.state.payCycle;
			this.payCycleName = this.payCycle?.name;
		}

		this.store.pipe(take(1), select(getGlobalDashboardFilterState)).subscribe({
			next: (state: GlobalDashboardFilterSelect) => {
				this.kpiSelected = state.globalDashboard.kpiSelected;
				if (this.router.url.includes("global-dashboard") && this.kpiSelected === "Failed Payment Orders")
					this.pageTitle = "Failed Payment orders";
			}
		});
	}

	backFromModal(): void {
		let route = "payments";

		if (this.router.url.includes("global-dashboard")) route = "global-dashboard";

		if ((this.kpiSelected === "Failed Payment Orders" || this.kpiSelected === "") && route === "global-dashboard") {
			this.router.navigate([`/${route}`]);
		} else {
			if (this.netPaymentOrderScreenEnabled) {
				this.router.navigate([`/${route}/payment-orders`], {
					state: {
						payGroup: this.payGroup,
						payCycleId: this.payCycle.id,
						payCycleExternalId: this.payCycle.externalId,
						payCycle: this.payCycle,
						milestone: this.milestone,
						payrollCycleId: this.payCycle.payrollCycleId
					}
				});
			} else {
				this.router.navigate([`/${route}/dashboard`], {
					state: { expandMilestoneGroupWithName: this.milestone.group }
				});
			}
		}
	}

	uploadG2NFile(files: any) {
		try {
			const file: File = files[0];

			let formData = new FormData();
			formData.append("file", file);
			formData.append("payGroupId", this.payGroup.id);
			formData.append("payCycleId", this.payCycleId);
			formData.append("fileType", "EXCEL");
			formData.append("sheetIndex", "0");
			formData.append("headerIndex", "4");

			if (file.name.includes(this.payrollCycleId)) {
				this.fileManagmentService.uploadG2NFile(formData);
			} else {
				if (file.name.includes(this.payCycleExternalId)) {
					this.fileManagmentService.uploadG2NFile(formData);
				} else {
					this.payrollCycleId
						? this.toastService.showWarning(
								`You have selected the wrong file for PayCycle: ${this.payrollCycleId}  or  ${this.payCycleExternalId}`
						  )
						: this.toastService.showWarning(
								`You have selected the wrong file for PayCycle: ${this.payCycleExternalId}`
						  );
				}
			}
		} catch (error) {
			this.toastService.showError("There was an error loading the file. Please try again");
		}
	}

	showResetBanner(show: boolean) {
		this.bannerShow = show;
		this.bannerShowReset = show;
	}

	showCancelBanner(show: boolean) {
		this.bannerShow = show;
		this.bannerShowCancel = show;
	}
	closeBanner(): void {
		this.bannerShow = false;
	}

	title(): string {
		return ` ${MONTH_NAMES[new Date(this.payCycleExternalId).getMonth()].full}/${this.payCycleExternalId}`;
	}

	/**
	 * Get the total number of file history list
	 * @param count: Number of total file history
	 */
	fileHistoryTotalCount(count: number) {
		const hasFiles = count !== 0;
		this.isShowLoadmore = this.currentFileHistoryListCount < count && hasFiles;
		this.reachedAllFileHistory = !this.isShowLoadmore && hasFiles;
	}

	/**
	 * Get current number of file history list
	 * @param count: Current number of file history list
	 */
	fileHistoryCurrentCount(count: number) {
		this.currentFileHistoryListCount = count;
	}
}
