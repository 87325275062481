import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: "root"
})
export class ReportingService {
	constructor(private http: HttpClient) {}

	getTransactionReports(
		rangeStart: string,
		rangeEnd: string,
		customerIds: string,
		legalEntityIds: string,
		payGroupIds: string
	): Observable<HttpResponse<any>> {
		return this.http.get<any>(
			`${environment.apiUri}/v1/transactional/report?customerIds=${customerIds}&legalEntityIds=${legalEntityIds}&payGroupIds=${payGroupIds}&rangeStart=${rangeStart}&rangeEnd=${rangeEnd}`,
			{ responseType: "blob" as "json", observe: "response" }
		);
	}

	getPayGroupsReports(
		rangeStart: string,
		rangeEnd: string,
		status: string,
		customerIds: string,
		legalEntityIds: string
	): Observable<Blob> {
		return this.http.get<Blob>(
			`${environment.apiUri}/v1/pay-groups/report?customerIds=${customerIds}&legalEntityIds=${legalEntityIds}&statuses=${status}&rangeStart=${rangeStart}&rangeEnd=${rangeEnd}`,
			{
				responseType: "blob" as "json"
			}
		);
	}

	formatDate(date: Date): string {
		const year: string = date.toLocaleDateString("US", { year: "numeric" });
		const month: string = date.toLocaleDateString("US", { month: "2-digit" });
		const day: string = date.toLocaleDateString("US", { day: "2-digit" });

		return year + "-" + month + "-" + day;
	}
}
