<mat-form-field
	appearance="fill"
	class="select-dropdown"
	[ngClass]="{
		'no-label': !label,
		'label-disappears': labelDisappears,
		'has-image': optionsObject[control.value]
	}"
	[ngStyle]="{ 'max-width': width }"
>
	<!-- Label -->
	<mat-label *ngIf="label && labelDisappears && (control.value?.length === 0 || control.value === null)"
		>{{ label }} {{ control.value }}</mat-label
	>
	<mat-label *ngIf="label && !labelDisappears">{{ label }}</mat-label>
	<span *ngIf="icon" matPrefix class="icon-margin-right">
		<app-icon
			id="iconInputSelect"
			[color]="'rgba(0, 0, 0, 0.54)'"
			[lineWidth]="'2.5'"
			[name]="icon"
			[size]="'16'"
		></app-icon>
	</span>

	<!-- Select -->
	<mat-select
		#mySelect
		[formControl]="control"
		(selectionChange)="onChange($event.value); onSelectionChange.emit($event.value)"
		(openedChange)="onTouched($event)"
		[multiple]="multiple"
		[disableOptionCentering]="disableOptionCentering"
		placeholder="{{ placeholder }}"
		floatlLabel="never"
	>
		<mat-select-trigger *ngIf="optionsObject[control.value]">
			<div *ngIf="optionsObject[control.value].imagePath" class="icon">
				<img src="{{ optionsObject[control.value].imagePath }}" />
			</div>
			<span>{{ optionsObject[control.value].text }}</span>
		</mat-select-trigger>

		<!-- Options -->
		<mat-option *ngIf="resetOption" (click)="checkToResetOption(mySelect)">NONE</mat-option>
		<mat-option class="input-select-option" *ngFor="let option of options; let i = index" [value]="option.value">
			<ng-container *ngIf="option.tooltipMessage">
				<div [matTooltip]="option.tooltipMessage">
					<div *ngIf="option.imagePath" class="icon"><img src="{{ option.imagePath }}" /></div>
					{{ option.text }}
				</div>
			</ng-container>
			<ng-container *ngIf="!option.tooltipMessage">
				<div *ngIf="option.imagePath" id="mat-option-{{ i }}" class="icon">
					<img src="{{ option.imagePath }}" />
				</div>
				{{ option.text }}
			</ng-container>
		</mat-option>
	</mat-select>

	<mat-select-trigger class="drop-down-trigger" *ngIf="!optionsObject[control.value]">
		<app-icon
			id="drop-down-arrow"
			[color]="'rgba(0, 0, 0, 0.54)'"
			[lineWidth]="'2.5'"
			[name]="'arrow-down'"
			[size]="'16'"
		></app-icon>
	</mat-select-trigger>
</mat-form-field>
