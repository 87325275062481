import { HttpClient, HttpBackend } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { filter, map, tap } from "rxjs/operators";
import { Country } from "src/app/shared/models/country.interface";
import { environment } from "src/environments/environment";
import { sortAsc } from "src/app/shared/utils/sort.util";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { formatDataForSelect } from "src/app/shared/utils/format-select-data.util";

@Injectable({
	providedIn: "root"
})
export class CountriesService {
	constructor(private http: HttpClient) {}

	getCountries(): Observable<Country[]> {
		return this.http.get<Country[]>(`${environment.apiUri}/v1/resources/countries`).pipe(
			tap((countries: Country[]) => {
				sortAsc(countries, ["country", "name"]);
			})
		);
	}

	getCountryOptions(): Observable<SelectOption[]> {
		return this.getCountries().pipe(
			map((countries: Country[]) => {
				return countries.map(country => {
					const option: SelectOption = {
						value: country?.country.code,
						text: country?.country.name,
						imagePath: `assets/svg-country-flags/svg/${country?.country.code.toLowerCase()}.svg`
					};

					return formatDataForSelect(option);
				});
			})
		);
	}

	getCountryByCode(countryCode: string = ""): Observable<Country[]> {
		return this.getCountries().pipe(
			map((countries: Country[]) => {
				return countries.filter(country => country.country.code === countryCode);
			})
		);
	}

	filterCountryByCode(countries: Country[], countryCode: string = ""): SelectOption {
		const country = countries.filter(country => country.country.code === countryCode)[0];

		return {
			value: country ? country.country.code : "",
			text: country ? country.country.name : "",
			imagePath: country ? `assets/svg-country-flags/svg/${country?.country.code.toLowerCase()}.svg` : "",
			valueArray: []
		};
	}

	defaultCountry(countries: SelectOption[], countryCode: string): SelectOption {
		let country: SelectOption | undefined = countries.find(country => country.value == countryCode);
		if (country) {
			return country;
		} else {
			return <SelectOption>{};
		}
	}
}
