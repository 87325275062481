<app-breadcrumbs
	[headerTitle]="'TPP CATALOGUE'"
	[showAllCrumbs]="true"
	[externalCrumbs]="[
		{ crumb: this.countryName, flag: this.countryCode, dropDownData: countriesDropDownData },
		{ crumb: this.serviceName, dropDownData: servicesDropDownData }
	]"
	(onExternalCrumbClicked)="countryCrumbClicked()"
	(onExternalDropDownCrumbItemSelected)="dropDownItemSelected($event)"
/>

<app-tpp-service-creation
	*ngIf="showCreationComponent"
	[countryCode]="countryCode"
	(getNewServiceCreation)="getNewServiceCreation($event)"
	[forAdhocService]="false"
></app-tpp-service-creation>
<app-tpp-sub-service-list
	*ngIf="showCreationComponent"
	[countryCode]="countryCode"
	[forAdhocService]="false"
	[isEditService]="!isNewService"
	(isComplete)="finish($event)"
></app-tpp-sub-service-list>
<div id="buttonContainer" class="bt-contanier" *ngIf="!showBeneficiaryForm">
	<button id="cancelSubservice" class="mat-stroked-button cancel-btn bt-mg-r" (click)="openDialog()">CANCEL</button>
	<button id="saveSubservice" class="mat-stroked-button submit-btn" [disabled]="btnDisabled" (click)="save()">
		FINISH
	</button>
</div>
