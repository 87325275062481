import { HttpClient, HttpParams } from "@angular/common/http";
import { EventEmitter, Injectable, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { Observable, Subject, of } from "rxjs";
import { map, switchMap, take } from "rxjs/operators";

import { ApiService } from "@modules/pay-groups/services/api.service";
import { TppServiceDefinitionService } from "@modules/service-definition/_services/tpp-service-definition/tpp-service-definition.service";
import { TppServiceManagementService } from "@modules/tpp-catalogue/_services/management/tpp-service-management.service";
import { PayGroupTPPService } from "src/app/shared/models/pay-groups";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import {
	ClientSpecificSubService,
	SettingType,
	SubService,
	SubServicePagination,
	SubServiceStoreDataForm,
	SubserviceCreation,
	TPPServiceDefinitionDTO,
	TppService,
	TppServiceCreation,
	TppServicePagination,
	TppSubServicePagination,
	adHocSubServiceStagedData,
	catalogueSubServiceStagedData
} from "src/app/shared/models/tpp-service.interface";
import { environment } from "src/environments/environment";
import { ToastService } from "../toast/toast.service";

@Injectable({
	providedIn: "root"
})
export class TppServicesService {
	@Output() serviceNameChanged = new EventEmitter<string>();

	@Output() sendSubServiceDataFromServices = new EventEmitter<SubService | undefined>();

	updateTPPCatalogueFilterCountry$: Subject<string> = new Subject<string>();
	catalogueContextSwitching$: Subject<boolean> = new Subject<boolean>();

	stagedConfigureServiceData: adHocSubServiceStagedData = {} as adHocSubServiceStagedData;
	stagedConfigureServiceDataArray: adHocSubServiceStagedData[] = [];
	SELECT_TTP_SERVICE_ROUTE = "/service-definition/tpp/select-service";

	constructor(
		private http: HttpClient,
		private toastService: ToastService,
		private tppServiceManagementService: TppServiceManagementService,
		private tppServiceDefinitionService: TppServiceDefinitionService,
		private router: Router,
		private payGroupApiService: ApiService
	) {}

	getTPPServiceSettingsTypes(): Observable<SelectOption[]> {
		return this.http.get<SettingType[]>(`${environment.apiUri}/v1/tpp-services/settings`).pipe(
			map((response: SettingType[]) => {
				const selectOptions: SelectOption[] = [];
				response.forEach((element: SettingType) =>
					selectOptions.push({ value: element.id, text: element.name })
				);
				return selectOptions;
			})
		);
	}

	findTppServiceByServiceId(id: string): Observable<TppService> {
		return this.http.get<TppService>(`${environment.apiUri}/v1/tpp-services/${id}`);
	}

	createTPPService(serviceCreation: TppServiceCreation): Observable<TppService> {
		return this.http.post<TppService>(`${environment.apiUri}/v1/tpp-services`, serviceCreation);
	}

	updateTPPservice(updateService: TppService) {
		return this.http.put<TppService>(`${environment.apiUri}/v1/tpp-services`, updateService);
	}

	searchTPPServiceByCountry(
		countryCode: string,
		pageNumber: string,
		pageSize: string
	): Observable<TppServicePagination> {
		pageNumber = pageNumber === "" ? "0" : pageNumber;
		pageSize = pageSize === "" ? "10" : pageSize;
		return this.searchTppServices(countryCode, null, "", "", pageNumber, pageSize);
	}

	searchTPPServiceByPaygroupId(
		paygroupId: string,
		pageNumber: string,
		pageSize: string
	): Observable<TppServicePagination> {
		pageNumber = pageNumber === "" ? "0" : pageNumber;
		pageSize = pageSize === "" ? "10" : pageSize;
		return this.searchTppServices(null, paygroupId, "", "", pageNumber, pageSize);
	}

	searchTppServices(
		countryCode: string | null,
		paygroupId: string | null,
		names: string,
		types: string,
		pageNumber: string,
		pageSize: string
	): Observable<TppServicePagination> {
		pageNumber = pageNumber === "" ? "0" : pageNumber;
		pageSize = pageSize === "" ? "10" : pageSize;

		let params = new HttpParams();
		if (countryCode) {
			params = params.append("countries", countryCode);
		}
		if (paygroupId) {
			params = params.append("payGroupIds", paygroupId);
		}

		params = params.append("statuses", "ACTIVE");
		params = params.append("pageNumber", pageNumber);
		params = params.append("pageSize", pageSize);

		if (names != "") params = params.append("names", names);
		if (types != "") params = params.append("types", types);

		return this.http.get<TppServicePagination>(`${environment.apiUri}/v1/tpp-services`, { params });
	}

	searchTppSubServices(
		tppServiceId: string,
		pageNumber: string,
		pageSize: string
	): Observable<TppSubServicePagination> {
		pageNumber = pageNumber === "" ? "0" : pageNumber;
		pageSize = pageSize === "" ? "10" : pageSize;

		let params = new HttpParams();
		params = params.append("tppServiceIds", tppServiceId);
		params = params.append("pageNumber", pageNumber);
		params = params.append("pageSize", pageSize);

		return this.http.get<TppSubServicePagination>(`${environment.apiUri}/v1/tpp-sub-services`, { params });
	}

	private _selectedServices!: [];

	get selectedServices() {
		return this._selectedServices;
	}

	set selectedServices(value: []) {
		this._selectedServices = value;
	}

	searchSubServiceByTppServiceId(
		tppServiceIds: string,
		pageNumber: string,
		pageSize: string
	): Observable<SubServicePagination> {
		let params = new HttpParams();
		params = params.append("tppServiceIds", tppServiceIds);
		params = params.append("pageNumber", pageNumber);
		params = params.append("pageSize", pageSize);

		return this.http.get<SubServicePagination>(`${environment.apiUri}/v1/tpp-sub-services`, { params });
	}

	createSubService(subServiceCreation: SubserviceCreation): Observable<SubService> {
		return this.http.post<SubService>(`${environment.apiUri}/v1/tpp-sub-services`, subServiceCreation);
	}

	getTPPSubserviceById(subServiceId: String): Observable<SubService> {
		return this.http.get<SubService>(`${environment.apiUri}/v1/tpp-sub-services/${subServiceId}`);
	}

	getTPPSubserviceByServiceId(serviceId: String): Observable<SubService[]> {
		return this.http.get<SubService[]>(`${environment.apiUri}/v1/tpp-sub-services/${serviceId}`);
	}

	updateSubservice(updateSubService: SubService): Observable<SubService> {
		return this.http.put<SubService>(`${environment.apiUri}/v1/tpp-sub-services`, updateSubService);
	}

	deleteSubService(subServiceId: string): Observable<SubService> {
		return this.http.delete<SubService>(`${environment.apiUri}/v1/tpp-sub-services/${subServiceId}`);
	}

	deleteSubServiceForAdhocService(subServiceId: string, selectedPayGroupId: string): Observable<SubService> {
		return this.tppServiceDefinitionService.getServiceDefinitionTemp(selectedPayGroupId).pipe(
			take(1),
			switchMap(result => {
				let serviceDef: TPPServiceDefinitionDTO = result.body;

				if (serviceDef) {
					let servicesToKeep: ClientSpecificSubService[] = [];
					serviceDef.services.forEach(service => {
						if (service.subServiceId !== subServiceId) {
							servicesToKeep.push(service);
						}
					});
					serviceDef.services = servicesToKeep;

					return this.tppServiceDefinitionService
						.editTPPServiceDefinition(this.formatFrequency(serviceDef))
						.pipe(
							take(1),
							switchMap(newSubService => {
								return this.deleteSubService(subServiceId);
							})
						);
				}

				return of({} as SubService);
			})
		);
	}

	getTppServicesGroups(paygroupId: string): Observable<PayGroupTPPService[]> {
		return this.http.get<PayGroupTPPService[]>(
			`${environment.apiUri}/v1/tpp-service-definitions/${paygroupId}/services`
		);
	}

	//If in adhoc
	// create service - TPP-services -> already done with previous card	-> and have id
	//Step 1 - make subservices -> tpp-sub-services/ -> (take in account frequency)
	//Step 2 - get subId
	//Setp 3 -> add the subservices to service def (take in account frequency and group )

	configureService(
		values: any,
		form: FormGroup,
		subService: SubService,
		forAdhocService: boolean,
		selectedPayGroupId: string,
		managedInSelection: SelectOption,
		tppServiceId: string,
		beneficiaryId: string | null
	) {
		delete values.beneficiaryName;

		if (subService && subService.hasOwnProperty("id") && beneficiaryId !== null) {
			//If subservice exists -> "edit" -> adhoc shouldn't go in here

			subService = {
				id: subService.id,
				externalId: subService.externalId,
				version: subService.version,
				tppServiceId: subService.tppServiceId,
				...values,
				beneficiaryId: beneficiaryId
			}; //setup object to save

			this.updateSubservice(subService)
				.pipe(take(1))
				.subscribe({
					next: (response: SubService) => {
						subService = response;
						if (forAdhocService && selectedPayGroupId !== "") {
							const subservice: ClientSpecificSubService = {
								serviceId: this.tppServiceManagementService!.tppServiceData.id,
								subServiceId: subService.id,
								group: form.get("group")!.value,
								beneficiaryId: beneficiaryId ? beneficiaryId : undefined,
								frequency: form.get("frequency")!.value,
								managedIn: managedInSelection.value,
								externalId: form.get("externalId")!.value
							};

							this.tppServiceDefinitionService
								.getServiceDefinitionTemp(selectedPayGroupId)
								.pipe(take(1))
								.subscribe(result => {
									let serviceDef: TPPServiceDefinitionDTO = result.body;
									if (serviceDef) {
										let idFound: boolean = false;

										let servicesToKeep: ClientSpecificSubService[] = [];

										serviceDef.services.forEach(service => {
											if (service.subServiceId === subService.id) {
												servicesToKeep.push(subservice);
												idFound = true;
											} else {
												servicesToKeep.push(service);
											}
										});

										if (!idFound) {
											serviceDef.services.push(subservice);
										} else {
											serviceDef.services = servicesToKeep;
										}

										this.tppServiceDefinitionService
											.editTPPServiceDefinition(this.formatFrequency(serviceDef))
											.pipe(take(1))
											.subscribe({
												next: _ => {
													this.sendSubServiceDataFromServices.emit(subService);

													this.toastService.showSuccess("SubService Updated Successfully");
												},
												error: err => {
													this.toastService.showError(`${err.error[0].message}`);
												}
											});
									}
								});
						} else {
							this.sendSubServiceDataFromServices.emit(subService);

							if (!this.getCatalogueContextSwitching()) {
								this.toastService.showSuccess("SubService Updated Successfully");
							}
						}
					},
					error: err => {
						this.toastService.showError(`${err.error[0].message}`);
					}
				});
		} else {
			//Create new subservice
			const subServiceCreation: SubserviceCreation = {
				beneficiaryId: beneficiaryId ? beneficiaryId : null,
				tppServiceId: tppServiceId,

				...values
			};
			this.tppServiceManagementService.setNewSubserviceData({} as SubServiceStoreDataForm);

			if (this.checkSubServiceTruthy(subServiceCreation)) {
				//Step 1: Create subService with freq
				this.createSubService(subServiceCreation)
					.pipe(take(1))
					.subscribe({
						next: (response: SubService) => {
							subService = response; //Step 2: get sub id

							//Step 3
							if (forAdhocService && selectedPayGroupId !== "") {
								const subservice: ClientSpecificSubService = {
									serviceId: this.tppServiceManagementService!.tppServiceData.id,
									subServiceId: subService.id,
									group: form.get("group")!.value,
									beneficiaryId: beneficiaryId ? beneficiaryId : undefined,
									frequency: form.get("frequency")!.value,
									managedIn: managedInSelection.value,
									externalId: form.get("externalId")!.value
								};
								this.tppServiceDefinitionService
									.getServiceDefinitionTemp(selectedPayGroupId)
									.pipe(take(1))
									.subscribe({
										next: result => {
											let serviceDef: TPPServiceDefinitionDTO = result.body;

											if (serviceDef) {
												//edit service def
												serviceDef.services.push(subservice);

												this.tppServiceDefinitionService
													.editTPPServiceDefinition(this.formatFrequency(serviceDef))
													.pipe(take(1))
													.subscribe({
														next: _ => {
															this.sendSubServiceDataFromServices.emit(subService);

															this.toastService.showSuccess(
																"SubService Created Successfully"
															);
														},
														error: err => {
															this.toastService.showError(`${err.error[0].message}`);
														}
													});
											}
										},
										error: err => {
											if (err.status === 404) {
												//create service def

												let formData: TPPServiceDefinitionDTO = {
													payGroupId: selectedPayGroupId,
													services: [subservice],
													groupConfig: []
												};

												this.tppServiceDefinitionService
													.createTPPServiceDefinition(this.formatFrequency(formData))
													.pipe(take(1))
													.subscribe({
														next: _ => {
															this.sendSubServiceDataFromServices.emit(subService);

															this.toastService.showSuccess(
																"SubService Created Successfully "
															);
														},
														error: err => {
															this.toastService.showError(`${err.error[0].message}`);
														}
													});
											} else {
												this.toastService.showError(`${err.error[0].message}`);
											}
										}
									});
							} else {
								this.sendSubServiceDataFromServices.emit(subService);

								this.toastService.showSuccess("SubService Created Successfully");
							}
						},
						error: err => {
							this.toastService.showError(`${err.error[0].message}`);
						}
					});
			}
		}
	}

	configureServiceCatalogue(stagedData: catalogueSubServiceStagedData[]): void {
		if (stagedData && stagedData.length) {
			stagedData.forEach((element, index) => {
				delete element.values.beneficiaryName;

				element.subService = {
					id: element.subService.id,
					version: element.subService.version,
					tppServiceId: element.subService.tppServiceId,
					...element.values,
					beneficiaryId: element.beneficiaryId!
				};

				// we need endpoint that can take in array of these to limit calls

				if (element.subService.hasOwnProperty("id") && element.subService.beneficiaryId !== null) {
					this.updateSubservice(element.subService)
						.pipe(take(1))
						.subscribe((response: SubService) => {
							this.sendSubServiceDataFromServices.emit(response);

							if (!this.getCatalogueContextSwitching()) {
								this.toastService.showSuccess("SubService Updated Successfully");
							}
						});
				}
			});
		}
	}

	configureServiceEditMode(stagedData?: adHocSubServiceStagedData[]) {
		if (stagedData && stagedData.length) {
			let serviceDef!: TPPServiceDefinitionDTO;

			this.tppServiceDefinitionService
				.getServiceDefinitionTemp(stagedData[0].selectedPayGroupId)
				.pipe(take(1))
				.subscribe(result => {
					serviceDef = result.body;

					let isDoneUpdatingSubServices = false;

					stagedData.forEach((element, index) => {
						delete element.values.beneficiaryName;

						element.subService = {
							id: element.subService.id,
							externalId: element.subService.externalId,
							version: element.subService.version,
							tppServiceId: element.subService.tppServiceId,
							managed_in: this.formatManagedIn(element.managedInSelection.value),
							...element.values,
							beneficiaryId: element.beneficiaryId
						};

						// we need endpoint that can take in array of these to limit calls

						if (element.subService.hasOwnProperty("id") && element.subService.beneficiaryId !== null) {
							this.updateSubservice(element.subService)
								.pipe(take(1))
								.subscribe((response: SubService) => {
									element.subService = response;

									let subService: ClientSpecificSubService = {
										serviceId: this.tppServiceManagementService!.tppServiceData.id,
										subServiceId: element.subService.id,
										group: element.form.get("group")!.value,
										beneficiaryId: element.beneficiaryId ? element.beneficiaryId : undefined,
										frequency: element.values.frequency,
										managedIn: element.managedInSelection.value,
										externalId: element.form.get("externalId")!.value
									};

									if (serviceDef) {
										let idFound: boolean = false;

										let servicesToKeep: ClientSpecificSubService[] = [];

										serviceDef.services.forEach(service => {
											if (service.subServiceId === element.subService.id) {
												servicesToKeep.push(subService);
												idFound = true;
											} else {
												servicesToKeep.push(service);
											}
										});

										if (!idFound) {
											serviceDef.services.push(subService);
										} else {
											serviceDef.services = servicesToKeep;
										}

										if (index === stagedData.length - 1) {
											isDoneUpdatingSubServices = true;
											// run something for the last iteration

											if (isDoneUpdatingSubServices) {
												this.stagedConfigureServiceDataArray = [];
												this.tppServiceDefinitionService
													.editTPPServiceDefinition(this.formatFrequency(serviceDef))
													.pipe(take(1))
													.subscribe({
														next: _ => {
															//	this.sendSubServiceDataFromServices.emit(subService);

															this.toastService.showSuccess(
																"Service Definition Updated Successfully"
															);
															this.router.navigate([this.SELECT_TTP_SERVICE_ROUTE]);
														},
														error: err => {
															this.toastService.showError(`${err.error[0].message}`);
														}
													});
											}
										}
									}
								}),
								(error: any) => {
									return;
								};
						}
					});
				});
		}
	}

	checkSubServiceTruthy(subServiceCreation: SubserviceCreation): boolean {
		return [
			subServiceCreation.name,
			subServiceCreation.paymentOriginator,
			subServiceCreation.referenceType,
			subServiceCreation.frequency
		].every(Boolean);
	}

	deleteTPPservice(serviceId: string): Observable<TppService> {
		return this.http.delete<TppService>(`${environment.apiUri}/v1/tpp-services/${serviceId}`);
	}

	tppService!: TppService;

	setSelectedEditAdHocService(tppService: TppService) {
		this.tppService = tppService;
	}

	getSelectedEditAdHocService(): TppService {
		return this.tppService;
	}

	resetSelectedEditAdHocService() {
		this.tppService = {} as TppService;
	}

	stageConfigureService(
		values: any,
		form: FormGroup,
		subService: SubService,
		forAdhocService: boolean,
		selectedPayGroupId: string,
		managedInSelection: SelectOption,
		tppServiceId: string,
		beneficiaryId: string | null
	) {
		this.stagedConfigureServiceData = {
			values: values,
			form: form,
			subService: subService,
			forAdhocService: forAdhocService,
			selectedPayGroupId: selectedPayGroupId,
			managedInSelection: managedInSelection,
			tppServiceId: tppServiceId,
			beneficiaryId: beneficiaryId
		};

		const index = this.stagedConfigureServiceDataArray.findIndex(
			element => element.subService.id === this.stagedConfigureServiceData.subService.id
		);

		if (index !== -1) {
			this.stagedConfigureServiceDataArray[index] = this.stagedConfigureServiceData;
		} else {
			this.stagedConfigureServiceDataArray.push(this.stagedConfigureServiceData);
		}
	}

	finishButtonConfigureService(selectedPayGroupId: string) {
		if (this.stagedConfigureServiceDataArray && this.stagedConfigureServiceDataArray.length) {
			this.configureServiceEditMode(this.stagedConfigureServiceDataArray);
		} else {
			if (selectedPayGroupId) {
				this.payGroupApiService
					.getPayGroup(selectedPayGroupId!)
					.pipe(take(1))
					.subscribe(paygroup => {
						if (paygroup) {
							this.router.navigate([this.SELECT_TTP_SERVICE_ROUTE]);
						}
					});
			}
		}
	}

	finishButtonConfigureServiceCatalogue(): void {
		if (this.stagedConfigureServiceDataArray && this.stagedConfigureServiceDataArray.length) {
			this.configureServiceCatalogue(this.stagedConfigureServiceDataArray);
		}
	}

	formatFrequency(formData: TPPServiceDefinitionDTO): TPPServiceDefinitionDTO {
		const formDTO = formData;
		for (let service of formDTO.services) {
			const frequency = service.frequency.toUpperCase().replace(/\s+/g, "_");
			service.frequency = frequency;

			if (typeof service.managedIn === "string") {
				const suffixes = ["st", "nd", "rd", "th"];
				const lastTwoChars = service.managedIn.slice(-2);
				if (suffixes.includes(lastTwoChars)) {
					const number = parseInt(service.managedIn.slice(0, -2));
					if (!isNaN(number)) {
						service.managedIn = number.toString();
					}
				}
			}
		}

		return formDTO;
	}

	formatManagedIn(managedIn: string): string {
		if (typeof managedIn === "string") {
			const suffixes = ["st", "nd", "rd", "th"];
			const lastTwoChars = managedIn.slice(-2);
			if (suffixes.includes(lastTwoChars)) {
				const number = parseInt(managedIn.slice(0, -2));
				if (!isNaN(number)) {
					return number.toString();
				}
			}
		}
		return managedIn;
	}

	updateTPPCatalogueFilterCountry(id: string): void {
		this.updateTPPCatalogueFilterCountry$.next(id);
	}

	getCatalogueContextSwitching(): boolean {
		let isSwitching!: boolean;

		this.catalogueContextSwitching$.pipe(take(1)).subscribe({
			next: res => {
				isSwitching = res;
			}
		});

		return isSwitching;
	}
}
