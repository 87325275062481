<app-breadcrumbs
	*ngIf="isServiceEdit"
	[headerTitle]="'Ad-Hoc Services'"
	[showAllCrumbs]="true"
	[externalCrumbs]="[
		{
			crumb: this.serviceToEdit.name,
			dropDownData: this.servicesDropDownData
		}
	]"
	(onDropDownCrumbItemSelected)="regress()"
	(onExternalDropDownCrumbItemSelected)="reSelectService($event)"
/>
<app-breadcrumbs
	*ngIf="!isServiceEdit"
	[headerTitle]="'Ad-Hoc Services'"
	[showAllCrumbs]="true"
	[externalCrumbs]="[
		{
			crumb: 'Create New Ad-Hoc Service',
		}
	]"
	(onDropDownCrumbItemSelected)="regress()"
	(onExternalDropDownCrumbItemSelected)="reSelectService($event)"
/>
<app-tpp-service-creation
	[countryCode]="_country"
	(getNewServiceCreation)="getNewServiceCreation($event)"
	[forAdhocService]="true"
	[dontDispatch]="true"
	[selectedPayGroupId]="selectedPayGroupId"
	[serviceToEdit]="serviceToEdit"
></app-tpp-service-creation>
<app-tpp-sub-service-list
	[forAdhocService]="true"
	[selectedPayGroupId]="selectedPayGroupId"
	[countryCode]="_country"
	(isComplete)="finish($event)"
	[isEditService]="isServiceEdit"
	[serviceToEdit]="serviceToEdit"
></app-tpp-sub-service-list>

<div fxLayout="row" id="buttonsContainer" fxLayoutAlign="end center" class="main-container">
	<button id="cancelSubservice" class="mat-stroked-button cancel-btn bt-mg-r" (click)="openDialog()">CANCEL</button>
	<button id="saveSubservice" class="mat-stroked-button submit-btn" [disabled]="btnDisabled" (click)="save()">
		FINISH
	</button>
</div>
