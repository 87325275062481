import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LegalEntitySetupPage } from "./legal-entity-setup.page";
import { LegalEntityEntryComponent } from "./_components/legal-entity-entry/legal-entity-entry.component";
import { CreateLegalEntityComponent } from "./_components/create-legal-entity/create-legal-entity.component";
import { UpdateLegalEntityComponent } from "./_components/update-legal-entity/update-legal-entity.component";

const routes: Routes = [
	{
		path: "",
		component: LegalEntitySetupPage
	},
	{ path: "select", component: LegalEntityEntryComponent },
	{
		path: "create",
		component: CreateLegalEntityComponent
	},
	{
		path: "update",
		component: UpdateLegalEntityComponent
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class LegalEntitySetupRoutingModule {}
