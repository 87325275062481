// Util function to create customer DTO logo path
import { Customer } from "../models/customer.interface";
import { environment } from "../../../environments/environment";

import { Observable, catchError, map, of } from "rxjs";
import { getPrimaryColorObservable } from "./image-primary-colour.util";

import { CustomerFilter } from "@modules/global-dashboard/models/global-dashboard-interface";
import { CustomerInfoOnSideNav } from "@modules/config/_models/dashboard.model";

export const createCustomerLogoPath = (customer: Customer): Customer => {
	if (customer.logo && customer.logo.items.length > 0) {
		// Append a random number to force the browser to reload the image so that cached images arent displayed
		customer.imagePath = toImagePath(customer) + "?" + Math.random();
	}

	return customer;
};

/**
 * When it's less important to show the latest image between page refreshes use a cached version.
 */
export const createCustomerLogoPathUseImgCache = (customer: Customer): Customer => {
	if (customer.logo && customer.logo.items.length > 0) {
		customer.imagePath = toImagePath(customer);
	}

	return customer;
};

function toImagePath(customer: Customer): string {
	if (customer.logo && customer.logo.items.length > 0) {
		return environment.apiUri + customer.logo.items[0].filePath;
	}
	console.log("");
	console.error("Developer Error: Customer logo not found, fallback to invalid URL.");
	console.error(customer);
	console.log("");

	return "";
}

//For customerFilter Interface
export const createCustomerFilterLogoPath = (customer: CustomerFilter): CustomerFilter => {
	if (customer.logo && customer.logo.items.length > 0) {
		// Append a random number to force the browser to reload the image so that cached images arent displayed
		customer.imagePath = toFilterImagePath(customer) + "?" + Math.random();
	}

	return customer;
};

function toFilterImagePath(customer: CustomerFilter): string {
	if (customer.logo && customer.logo.items.length > 0) {
		return environment.apiUri + customer.logo.items[0].filePath;
	}
	console.log("");
	console.error("Developer Error: Customer logo not found, fallback to invalid URL.");
	console.error(customer);
	console.log("");

	return "";
}

export const getCustomerLogoAndPrimaryColour = (customer: Customer): Observable<CustomerInfoOnSideNav> => {
	const image = createCustomerLogoPath(customer)?.imagePath;

	if (image) {
		return getPrimaryColorObservable(image).pipe(
			map(primaryColor => ({
				logo: image,
				primaryColour: primaryColor
			})),
			catchError(error => {
				console.error("Error occurred:", error);
				return of({
					logo: image,
					primaryColour: "var(--color-basic-100)"
				});
			})
		);
	} else {
		return of({
			logo: "",
			primaryColour: "var(--color-basic-100)"
		});
	}
};
