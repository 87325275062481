import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { FileManagementComponent } from "./file-management.component";
import { CustomerPaygroupsComponent } from "./_components/customer-paygroups/customer-paygroups.component";
import { FileManagerComponent } from "./_components/file-manager/file-manager.component";
import { FileDetailComponent } from "./_components/file-manager/file-detail/file-detail.component";

const routes: Routes = [
	{
		path: "",
		component: FileManagementComponent
	},
	{
		path: "customer-paygroups",
		component: CustomerPaygroupsComponent
	},
	{
		path: "files",
		component: FileManagerComponent
	},
	{
		path: "file-detail",
		component: FileDetailComponent
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class FileManagementRoutingModule {}
