import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { TppServiceBeneficiaryDTO } from "src/app/shared/models/tpp-service.interface";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: "root"
})
export class TppApiServiceService {
	constructor(private http: HttpClient) {}

	createTPPAccount(account: TppServiceBeneficiaryDTO): Observable<TppServiceBeneficiaryDTO> {
		return this.http.post<TppServiceBeneficiaryDTO>(`${environment.apiUri}/v1/accounts/tpp`, account);
	}

	editTPPAccount(account: TppServiceBeneficiaryDTO): Observable<TppServiceBeneficiaryDTO> {
		return this.http.put<TppServiceBeneficiaryDTO>(`${environment.apiUri}/v1/accounts/tpp`, account);
	}

	updateTPPAcctoun(account: TppServiceBeneficiaryDTO): Observable<TppServiceBeneficiaryDTO> {
		return this.http.put<TppServiceBeneficiaryDTO>(`${environment.apiUri}/v1/accounts/tpp`, account);
	}
}
