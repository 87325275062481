import { ProviderSetAction, ProviderActionType } from "../actions/providerSelect.action";
import { Action } from "@ngrx/store";

const initialState: { provider: string } = { provider: "" };

export const providerSelectReducer = (state = initialState, action: Action): { provider: string } => {
	const selectAction = action as ProviderSetAction;

	switch (selectAction.type) {
		case ProviderActionType.SET_SELECTED:
			return {
				...state,
				provider: selectAction.payload.provider
			};

		default:
			return state;
	}
};
