import { Component, OnDestroy, OnInit } from "@angular/core";
import { GeoFeature, GeoMap } from "@modules/client-landing/_models/client_landing-interface";
import { MarkerService } from "@modules/client-landing/_services/marker.service";
import { LoaderService } from "@shared/services/loader/loader.service";
import { WorldMapService } from "@shared/services/world-map/world-map.service";
import { Feature, GeoJsonObject, Geometry } from "geojson";
import * as L from "leaflet";
import { Subject, take, takeUntil } from "rxjs";

const iconRetinaUrl = "assets/marker-icon-2x.png";
const iconUrl = "assets/marker-icon.png";
const shadowUrl = "assets/marker-shadow.png";
const iconDefault = L.icon({
	iconRetinaUrl,
	iconUrl,
	shadowUrl,
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	tooltipAnchor: [16, -28],
	shadowSize: [41, 41]
});
L.Marker.prototype.options.icon = iconDefault;

@Component({
	selector: "app-world-map",
	template: `
		<div
			id="countriesMap"
			*ngIf="layers"
			leaflet
			[leafletOptions]="options"
			[leafletLayers]="layers"
			[leafletFitBounds]="bounds"
			(leafletMapReady)="mapReady($event)"
		></div>
	`,
	styleUrls: ["./world-map.component.scss"]
})
export class WorldMapComponent implements OnInit, OnDestroy {
	constructor(
		private markerService: MarkerService,
		private worldMapService: WorldMapService,
		private loaderService: LoaderService
	) {}

	bounds: L.LatLngBounds = L.latLngBounds(
		L.latLng(-90, -180), // Southwest corner (latitude, longitude)
		L.latLng(90, 180) // Northeast corner (latitude, longitude)
	);

	options: L.MapOptions = {
		zoomControl: false,
		minZoom: 2,
		maxBounds: new L.LatLngBounds(
			new L.LatLng(-70, -180), // Adjusted to include the South Pole and the International Date Line
			new L.LatLng(90, 180) // Adjusted to include the North Pole and the International Date Line
		),
		maxBoundsViscosity: 1.0,
		attributionControl: false,
		center: [0, 0]
	};

	layers!: L.Layer[];
	geoMap!: GeoMap;
	southWest = [-90, -180];
	northEast = [90, 180];

	private destroy$: Subject<void> = new Subject<void>();

	ngOnInit(): void {
		this.worldMapService
			.getGeoMap()
			.pipe(take(1))
			.subscribe(geoMap => {
				//geoMap where ISO_Q2 === selectCountry

				this.geoMap = geoMap;
				this.layers = [this.createGeoLayer(geoMap as GeoJsonObject)];
				this.setupCountrylisteners(this.layers[0] as L.GeoJSON<GeoJsonObject>);
			});

		//hide blue spinner
		this.loaderService.visibility$
			.pipe(takeUntil(this.destroy$))
			.subscribe(showSpinner => (showSpinner ? this.loaderService.visibility$.next(false) : false));
	}

	private setupCountrylisteners(geoMap: L.GeoJSON<GeoJsonObject>) {
		this.worldMapService.highLightSelectOption$.pipe(takeUntil(this.destroy$)).subscribe(selection => {
			geoMap.eachLayer((layer: L.Layer) => {
				const geoJSONLayer = layer as L.GeoJSON;
				const feature = geoJSONLayer.feature;
				if (feature && "properties" in feature && feature.id === selection.country) {
					const color = selection.highlight ? "var(--color-role-ta)" : "var(--color-header-blue-background)";

					(layer as L.Path).setStyle({
						fillColor: color
					});
				}
			});
		});
	}

	private createGeoLayer(geoMap: GeoJsonObject): L.GeoJSON<GeoJsonObject> {
		return L.geoJSON(geoMap, {
			style: () => ({
				weight: 0.67,
				fillColor: "var(--color-background-disabled)",
				fillOpacity: 1,
				color: "var(--color-service-details-background)",
				opacity: 1
			}),
			onEachFeature: (f, l) => {
				this.setupCountryStyle(f, l);
			}
		});
	}

	private setupCountryStyle(feature: Feature<Geometry, any>, layer: L.Layer): void {
		const featureId: string = feature.id ? feature.id.toString() : "";
		if (featureId !== "" && this.worldMapService.isClientCountry(featureId)) {
			(layer as L.Path).setStyle({
				fillColor: "var(--input-toggle-background)"
			});

			layer.on({
				mouseover: e => this.worldMapService.setHighLightCountrySelectOption(featureId, true),
				mouseout: e => this.worldMapService.setHighLightCountrySelectOption(featureId, false),
				click: e => this.worldMapService.setCountrySelectedFromMap(featureId)
			});
		}
	}

	private setInitialZoom(geoMap: GeoMap): void {
		geoMap.features.forEach((f: GeoFeature) => {
			if (this.worldMapService.isClientCountry(f.id)) {
				f.geometry.coordinates.forEach((countryAreasArray: any) => {
					countryAreasArray.forEach((countryAreaCoords: any) => {
						// Check if area coords are nested in arrays to iterate again
						if (isNaN(countryAreaCoords[0])) {
							countryAreaCoords.forEach((lngLatTuple: any) => {
								this.setBounds(lngLatTuple);
							});
						} else {
							this.setBounds(countryAreaCoords);
						}
					});
				});
			}
		});

		this.bounds = L.latLngBounds([this.southWest[0], this.southWest[1]], [this.northEast[0], this.northEast[1]]);
	}

	private setBounds(lngLatTuple: any): void {
		if (this.southWest[0] < lngLatTuple[1]) {
			this.southWest[0] = lngLatTuple[1];
		}
		if (this.southWest[1] < lngLatTuple[0]) {
			this.southWest[1] = lngLatTuple[0];
		}
		if (this.northEast[0] > lngLatTuple[1]) {
			this.northEast[0] = lngLatTuple[1];
		}
		if (this.northEast[1] > lngLatTuple[0]) {
			this.northEast[1] = lngLatTuple[0];
		}
	}

	mapReady(map: L.Map): void {
		map.addControl(L.control.zoom({ position: "bottomright" }));
		setTimeout(() => {
			map.invalidateSize();
		}, 0);

		//this.markerService.makeCountryMarkers(map);

		setTimeout(() => {
			this.setInitialZoom(this.geoMap);
		}, 200);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
